/**
 * Created by Kirill Mashchenko on 17.05.23.
 */
/**
 * Press object sprite animation types.
 * @enum {Number}
 */
var PressBoxAnimation = {
	Move: 0,
	Turn: 1
};

/**
 * @class Common sprite interface for press objects.
 * @interface
 * @extends pm.DynamicSprite
 */
var PressBoxSprite = pm.DynamicSprite.extend(/** @lends PressBoxSprite#*/{

	_idNumber: null,

	ctor: function()
	{
		this._super("Objects/PressBox.png");

		this._minPoint = cc.p(0, this.getSprite().getContentSize().height);
		this._maxPoint = cc.p(this.getSprite().getContentSize().width, 0);

		this._idNumber = new ccui.Text("", pm.settings.fontBoldName, 16);
		this._idNumber.setColor(cc.color(1, 1, 180));
		this._idNumber.setAnchorPoint(cc.p(0.5, 0.5));
		this._idNumber.setPosition(cc.p(this.getSprite().getContentSize().width/2, this.getSprite().getContentSize().height/2));

		this.sprite.addChild(this._idNumber);
	},

	updateNumber: function(id)
	{
		this._idNumber.setString(id)
	},

	setIdVisible: function()
	{
		this._idNumber.setVisible(false);
	},

	playAnimation: function(endCallBack, target, type, args)
	{
		switch(type)
		{
			case PressBoxAnimation.Move: this._move(args, endCallBack, target); break;
			case PressBoxAnimation.Turn: this._turn(args, endCallBack, target); break;
		}
	},

	getAnimation: function(endCallBack, target, type, args)
	{
		switch(type)
		{
			case PressBoxAnimation.Move: return this._getMoveAnimation(args, endCallBack, target);
		}
	},

	_move: function(target, endCallback, callbackTarget)
	{
		var sequenceAnimation = this._getMoveAnimation(target, endCallback, callbackTarget);

		this.sprite.runAction(sequenceAnimation);
	},

	_getMoveAnimation: function(target, endCallback, callbackTarget)
	{
		var targetPos = this.getRealPosition(target);

		var moveObject = cc.moveTo(pm.settings.getAnimationSpeed(), targetPos);

		var moveEnd = cc.callFunc(function() { this._needToUpdateZOrder = false; endCallback.call(callbackTarget); }, this);

		this._needToUpdateZOrder = true;

		return cc.sequence(moveObject, moveEnd);
	},

	_turn: function(clockwise, endCallback, callbackTarget)
	{
		var turnBox = cc.rotateBy(pm.settings.getAnimationSpeed(), 90);

		if (!clockwise)
			turnBox = turnBox.reverse();

		var turnNumber = cc.rotateBy(pm.settings.getAnimationSpeed(), 90);

		if (clockwise)
			turnNumber = turnNumber.reverse();

		var turnEnd = cc.callFunc(endCallback, callbackTarget);

		this.sprite.runAction(cc.sequence(cc.spawn(turnBox, cc.targetedAction(this._idNumber, turnNumber)), turnEnd));
	},

	reset: function ()
	{
		this.sprite.setRotation(0);
		this._idNumber.setRotation(0);
	}
});
