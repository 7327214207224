/**
 * Created by Kirill Mashchenko on 12.07.17.
 */

/**
 * @class Common interface for square map layers with walls.
 * @interface
 * @extends SquarableMapLayer
 */
var SquarableMapLayerWithWalls = SquarableMapLayer.extend(/** @lends SquarableMapLayer# */{

	ctor: function(map)
	{
		this._super(map);
	},

	_drawMapElements: function()
	{
		for(var x = 0; x < this._map.width; ++x)
		{
			for(var y = 0; y < this._map.height; ++y)
			{
				var p = cc.p(x, y);
				var terrainSprite = this._map.element(p).generateTerrainSprite();

				terrainSprite.getSprite().setPosition(this.realPosition(p, cc.p(0, 0)));
				this.addTerrainObject(terrainSprite, 0);

				if (this._map.element(p).walls[MapDirection4.Right])
				{
					var type = this._getWallType(MapDirection4.Right);
					this._drawWall(p, type);
				}
				if (this._map.element(p).walls[MapDirection4.Down])
				{
					var type = this._getWallType(MapDirection4.Down);
					this._drawWall(p, type);
				}

				if(this._map.element(p).startForRobot !== pm.MapElement.START_FOR_NO_ROBOT)
					this.drawRobotStartPositionMark(this._map.element(p).startForRobot, p);
			}
		}
	},

	_getWallType: function (direction)
	{
		var type = "None";
		switch (direction)
		{
			case MapDirection4.Down:
				type = pm.WallType4.Up;
				break;
			case MapDirection4.Right:
				type = pm.WallType4.Left;
				break;
		}
		return type;
	},

	_drawWall: function(pos, type)
	{
		var el = this._map.element(pos);

		var dx = type === pm.WallType4.Left ? this.getMapElementSize().width / 2 : 0;
		var dy = type === pm.WallType4.Up ? -this.getMapElementSize().height / 2 : 0;
		var del = cc.p(dx, dy);

		switch (type)
		{
			case pm.WallType4.Up:
				el.generateWallUpSprite(this._orientation);
				el.wallUpSprite.mapLayer = this;
				el.wallUpSprite.setRealPosition(pos, del);
				this.addObject(el.wallUpSprite, pos.x + pos.y);
				break;
			case pm.WallType4.Left:
				el.generateWallLeftSprite(this._orientation);
				el.wallLeftSprite.mapLayer = this;
				el.wallLeftSprite.setRealPosition(pos, del);
				this.addObject(el.wallLeftSprite, pos.x + pos.y);
				break;
		}
	},

	_removeWall: function (child)
	{
		this.removeObject(child);
		child = null;
	}

});
