/**
 * Created by Nikita Besshaposhnikov on 26.02.16.
 */

/**
 * This namespace is used for api calls of piktomir server.
 * @namespace
 */
pm.apiServerUtils = {

	/**
     * Ok network status
     * @const
     * @type {String}
     * @default
     */
	OK_STATUS: "ok",
	/**
     * Default timeout for requests
     * @const
     * @type {Number}
     * @default
     */
	DEFAULT_TIMEOUT: 10000,

	/**
     * Send request using XHR.
     * @param {Object} params
     * @param {Function} params.callback
	 * @param {String} params.method Method type for request
     * @param {String} params.url Url for request
     * @param {String} params.request Request path for specified url
	 * @param {Boolean} [params.withAuth] send with auth
	 * @param {Object} [params.data] Data for request
     * @param {Number} [params.timeout] Custom timeout for request
     * @private
     */
	_sendRequest: function(params)
	{
		var xhr = cc.loader.getXMLHttpRequest();

		var request = params.request +((params.request.indexOf("?") > -1) ? "&" : "?") + "app=";
		params.request = request + encodeURIComponent(pm.appConfig.appType);

		xhr.open(params.method, params.url + params.request, true);

		xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

		if(params.withAuth && pm.settings.getAccessToken())
			xhr.setRequestHeader("x-access-token", pm.settings.getAccessToken());

		var timeout = this.DEFAULT_TIMEOUT;

		if(cc.sys.isNative)
			pm.setHttpRequestTimeout(timeout);
		else
			xhr.timeout = timeout;

		xhr.ontimeout = function ()
		{
			if (params.hasOwnProperty("callback"))
				params.callback({text: "Timeout", status: xhr.status}, -1);
			else
				throw new Error("Timeout");
		};

		xhr.onerror = function (error)
		{
			var errorText = error ? JSON.stringify(error) : "Unknown XHR error";

			if (params.hasOwnProperty("callback"))
				params.callback({text: errorText, status: -1});
			else
				throw new Error(errorText);
		};

		xhr.onreadystatechange = function ()
		{
			if (xhr.readyState === 4 )
			{
				var response = null;

				try
				{
					response = JSON.parse(xhr.responseText);
				}
				catch(e)
				{
					response = null;
				}

				var hasCheckResponse = params.hasOwnProperty("checkResponse");

				if (!hasCheckResponse || (hasCheckResponse && params.checkResponse))
				{
					if (!response)
					{
						params.callback({text: "Incorrect response", status: xhr.status});
						return;
					}
				}

				if((xhr.status >= 200 && xhr.status <= 207))
				{
					if(params.hasOwnProperty("callback"))
						params.callback(null, response);
				}
				else if (xhr.status !== 0)
				{
					if (params.hasOwnProperty("callback"))
					{
						var text = response.error
							? response.error + ": " + response.error_description
							: response.message || "Empty error";

						params.callback({
							text: text,
							status: xhr.status
						});
					}

					xhr.status = 200;
				}
			}

		};

		xhr.send(params.hasOwnProperty("data") ? JSON.stringify(params.data) : "");
	},

	/**
     * Send request with access token, If server returns '401' tries to update it.
     * @param {Object} params
     * @param {Function} params.callback
     * @param {String} params.method Method type for request
     * @param {String} params.url Url for request
     * @param {String} params.request Request path for specified url
     * @param {?Object} [params.data] Data for request
     * @param {Number} [params.timeout] Custom timeout for request
     * @private
     */
	_sendRequestWithToken: function(params)
	{
		if (pm.settings.getAccessToken())
		{
			params.withAuth = true;

			var callback = params.callback;

			params.callback = function (error, response)
			{
				if (error && error.status === 401)
				{
					this.updateAccessToken(function (error, updateResponse)
					{
						if(!error)
						{
							pm.settings.setAccessToken(updateResponse.access_token);
							pm.settings.setRefreshToken(updateResponse.refresh_token);

							params.callback = callback;
							this._sendRequest(params);
						}
						else
						{
							callback(error);
						}

					}.bind(this)
					);
				}
				else if(error)
				{ callback(error); }
				else
				{ callback(null, response); }

			}.bind(this);

			this._sendRequest(params);
		}
		else
		{
			this._sendRequest(params);
		}
	},

	/**
     * Requests access and refresh token for server api.
     * @param {pm.apiServerUtils~loginCallback} callback
     * @param {String} login
     * @param {String} pass
     */
	login: function(callback, login, pass)
	{
		this._sendRequest({
			method: "POST",
			url: pm.appConfig.authAddress,
			request: "app/authorize/token",
			data: {
				login: login,
				password: pass,
				client_id: pm.appConfig.pmAppID
			},
			callback: callback
		});
	},

	/**
	 * Requests access and refresh token for server api.
	 * @param {pm.apiServerUtils~loginCallback} callback
	 * @param {String} code
	 * @param {String} codeVerifier
	 * @param {String} redirectUri
	 */
	loginByCode: function(callback, code, codeVerifier, redirectUri)
	{
		this._sendRequest({
			method: "POST",
			url: pm.appConfig.authAddress,
			request: "app/authorize/oauth/token",
			data: {
				code: code,
				code_verifier: codeVerifier,
				redirect_uri: redirectUri,
				client_id: pm.appConfig.pmAppID
			},
			callback: callback
		});
	},

	/**
	 * Requests access token by refresh token
	 * @param {pm.apiServerUtils~updateAccessTokenCallback} callback
	 */
	updateAccessToken: function(callback)
	{
		this._sendRequest({
			method: "POST",
			url: pm.appConfig.authAddress,
			request: "app/authorize/token/refresh",
			data: {
				refresh_token: pm.settings.getRefreshToken(),
				client_id: pm.appConfig.pmAppID
			},
			callback: callback
		});
	},

	/**
     *Registration.
     * @param {pm.apiServerUtils~standardCallback} callback
     * @param {String} mail
     * @param {String} password
     * @param {String} name
     * @param {String} surname
     */
	register: function(callback, mail, password, name, surname)
	{
		this._sendRequest({
			method: "POST",
			url: pm.appConfig.authAddress,
			request: "app/registration/register",
			data: {
				type: "user",
				mail: mail,
				password: password,
				name: name,
				surname: surname
			},
			callback: callback,
			checkResponse: false
		});
	},

	getMireraAuthorizationTokens: function (callback, id)
	{
		this._sendRequest({
			method: "POST",
			url: pm.appConfig.apiAddress,
			request: "auth/mirera",
			data: {id: id},
			callback: callback
		});
	},

	/**
     * Requests account  dta for current logged-in user
     * @param {pm.apiServerUtils~getUserAccountDataCallback} callback
     */
	getUserAccountData: function(callback)
	{
		this._sendRequestWithToken({
			method: "GET",
			url: pm.appConfig.authAddress,
			request: "api/users",
			callback: callback
		});
	},

	/**
     * Requests access and refresh token for server api.
     * @param {pm.apiServerUtils~getLevelsCacheCallback} callback
     * @param {String} worldID
     */
	getLevelsCache: function(callback, worldID)
	{
		this._sendRequestWithToken({
			method: "GET",
			url: pm.appConfig.apiAddress,
			request: "cache/" + encodeURIComponent(worldID),
			callback: callback
		});
	},

	/**
     * Requests access and refresh token for server api.
     * @param {pm.apiServerUtils~standardCallback} callback
     * @param {String} worldID
     * @param {Object} cache
     */
	updateLevelsCache: function(callback, worldID, cache)
	{
		this._sendRequestWithToken({
			method: "POST",
			url: pm.appConfig.apiAddress,
			request: "cache/" + encodeURIComponent(worldID),
			data: {cache: cache},
			callback: callback
		});
	},

	/**
     * Requests current PM version
     * @param {pm.apiServerUtils~getPMVersionCallback} callback
     */
	getPMVersion: function(callback)
	{
		this._sendRequest({
			method: "GET",
			url: pm.appConfig.apiAddress,
			request: "version",
			callback: callback
		});
	},
	/**
     * Requests public worlds
     * @param {pm.apiServerUtils~getWorldsCallback} callback
     */
	getPublicWorlds: function(callback)
	{
		this._sendRequest({
			method: "GET",
			url: pm.appConfig.apiAddress,
			request: "worlds/public",
			callback: callback
		});
	},
	/**
     * Requests marketable worlds
     * @param {pm.apiServerUtils~getWorldsCallback} callback
     */
	getMarketableWorlds: function(callback)
	{
		this._sendRequestWithToken({
			method: "GET",
			url: pm.appConfig.apiAddress,
			request: "worlds/marketable?uuid=" + encodeURIComponent(pm.marketHelper.getDeviceUUID()),
			callback: callback
		});
	},
	/**
     * Requests worlds granted for current user throw Mirera
     * @param {pm.apiServerUtils~getWorldsCallback} callback
     */
	getGrantedWorlds: function(callback)
	{
		this._sendRequestWithToken({
			method: "GET",
			url: pm.appConfig.apiAddress,
			request: "worlds/granted",
			callback: callback
		});
	},
	/**
     * Requests worlds purchased by current user
     * @param {pm.apiServerUtils~getWorldsCallback} callback
	 */
	getPurchasedWorlds: function(callback)
	{
		this._sendRequestWithToken({
			method: "GET",
			url: pm.appConfig.apiAddress,
			request: "worlds/purchased?uuid=" + encodeURIComponent(pm.marketHelper.getDeviceUUID()),
			callback: callback
		});
	},
	/**
     * Requests worlds shared to current user
     * @param {pm.apiServerUtils~getWorldsCallback} callback
     */
	getSharedWorlds: function(callback)
	{
		this._sendRequestWithToken({
			method: "GET",
			url: pm.appConfig.apiAddress,
			request: "worlds/shared",
			callback: callback
		});
	},
	/**
     * Download world data for certain id-version
     * @param {pm.apiServerUtils~downloadWorldCallback} callback
     * @param {String} worldID
     * @param {Number} [version] Maximum version to retrieve. If not present it's SUPPORTED_LEVEL_FORMAT_VERSION
     */
	getWorld: function(callback, worldID, version)
	{
		if (version === undefined)
			version = pm.appUtils.getSupportedLevelFormatVersion();

		this._sendRequestWithToken({
			method: "GET",
			url: pm.appConfig.apiAddress,
			request: "worlds/" + encodeURIComponent(worldID) + "?maxVersion=" + version + "&uuid=" + encodeURIComponent(pm.marketHelper.getDeviceUUID()),
			callback: callback
		});
	},

	/**
     * Download world metadata for certain id-version-revision
     * @param {pm.apiServerUtils~downloadWorldCallback} callback
     * @param {String} worldID
     * @param {Number} [version] Maximum version to retrieve. If not present it's SUPPORTED_LEVEL_FORMAT_VERSION
     */
	getWorldMeta: function(callback, worldID, version)
	{
		if (version === undefined)
			version = pm.appUtils.getSupportedLevelFormatVersion();

		this._sendRequestWithToken({
			method: "GET",
			url: pm.appConfig.apiAddress,
			request: "worlds/" + encodeURIComponent(worldID) + "/meta?maxVersion=" + version + "&uuid=" + encodeURIComponent(pm.marketHelper.getDeviceUUID()),
			callback: callback
		});
	},

	/**
     * Upload entire world
     * @param {pm.apiServerUtils~standardCallback} callback
     * @param {Object} worldData
     * @param {String} worldData.name
     * @param {Array<Object>} worldData.games
     */
	uploadWorld: function(callback, worldData)
	{
		this._sendRequestWithToken({
			method: "PUT",
			url: pm.appConfig.apiAddress,
			request: "worlds",
			data: {
				name: worldData.name,
				games: worldData.games
			},
			callback: callback
		});
	},

	/**
     * Updates world
     * @param {pm.apiServerUtils~standardCallback} callback
     * @param {String} worldID
     * @param {Object} updateQuery
     * @param {Array<Object>} updateQuery.actions
     * @param {Number} [version]  Maximum version to retrieve. If not present it's SUPPORTED_LEVEL_FORMAT_VERSION
     */
	updateWorld: function(callback, worldID, updateQuery, version)
	{
		if (version === undefined)
			version = pm.appUtils.getSupportedLevelFormatVersion();

		this._sendRequestWithToken({
			method: "POST",
			url: pm.appConfig.apiAddress,
			request: "worlds/" + encodeURIComponent(worldID) + "?version=" + version,
			data: updateQuery,
			callback: callback
		});
	},

	/**
	 * Get mirera parent courses
	 * @param {pm.apiServerUtils~standardCallback} callback
	 */
	getMireraCourses: function(callback)
	{
		this._sendRequestWithToken({
			method: "GET",
			url: pm.appConfig.apiAddress,
			request: "worlds/mirera/courses",
			data: {},
			callback: callback
		});
	},

	/**
	 * Links world with mirera
	 * @param {pm.apiServerUtils~standardCallback} callback
	 * @param {string} worldID
	 * @param {string} course
	 * @param {number} version
	 */
	linkWorldWithMirera: function(callback, worldID, course, version)
	{
		if (version === undefined)
			version = pm.appUtils.getSupportedLevelFormatVersion();

		this._sendRequestWithToken({
			method: "POST",
			url: pm.appConfig.apiAddress,
			request: "worlds/" + encodeURIComponent(worldID) + "/mirera",
			data: {
				course: course,
				version: version
			},
			callback: callback
		});
	},

	/**
	 * Unlinks world with mirera
	 * @param {pm.apiServerUtils~standardCallback} callback
	 * @param {string} worldID
	 */
	unlinkWorldWithMirera: function(callback, worldID)
	{
		this._sendRequestWithToken({
			method: "POST",
			url: pm.appConfig.apiAddress,
			request: "worlds/" + encodeURIComponent(worldID) + "/mirera/unlink",
			data: {},
			callback: callback
		});
	},

	/**
     * Gets level for world
     * @param {pm.apiServerUtils~standardCallback} callback
     * @param {Array<String>} levels List of levels to get
     * @param {Number} [version]  Maximum version to retrieve. If not present it's SUPPORTED_LEVEL_FORMAT_VERSION
     */
	getLevels: function(callback, levels, version)
	{
		if (version === undefined)
			version = pm.appUtils.getSupportedLevelFormatVersion();

		this._sendRequestWithToken({
			method: "POST",
			url: pm.appConfig.apiAddress,
			request: "worlds/levels?maxVersion=" + version + "&uuid=" + encodeURIComponent(pm.marketHelper.getDeviceUUID()),
			data: {levels: levels},
			callback: callback
		});
	},

	/**
     * Request for deleting world
     * @param {pm.apiServerUtils~standardCallback} callback
     * @param {String} worldID
     */
	deleteWorld: function(callback, worldID)
	{
		this._sendRequestWithToken({
			method: "DELETE",
			url: pm.appConfig.apiAddress,
			request: "worlds/" + worldID,
			callback: callback
		});
	},

	/**
     * Requests world list of current user
     * @param {pm.apiServerUtils~getWorldsCallback} callback
     */
	getUserWorldList: function(callback)
	{
		this._sendRequestWithToken({
			method: "GET",
			url: pm.appConfig.apiAddress,
			request: "worlds",
			callback: callback
		});
	},

	/**
     * Uploads user solutions to test in Mirera
     * @param {pm.apiServerUtils~standardCallback} callback
     * @param {Object} solution
	 * @param {String} solution.levelId
	 * @param {Number} solution.version
	 * @param {Number} solution.revision
	 * @param {Object.<Number, pm.data.ProgramData>} solution.data
     */
	uploadSolution: function(callback, solution)
	{
		this._sendRequestWithToken({
			method: "POST",
			url: pm.appConfig.apiAddress,
			request: "solutions",
			data: solution,
			callback: callback
		});
	},

	/**
     * Get solution test results from Mirera for world
     * @param {pm.apiServerUtils~standardCallback} callback
     * @param {String} gameID
     */
	getGameSolutionsResult: function(callback, gameID)
	{
		this._sendRequestWithToken({
			method: "GET",
			url: pm.appConfig.apiAddress,
			request: "solutions/game/" + encodeURIComponent(gameID),
			callback: callback
		});
	},

	/**
     * Sends purchase data to server.
     * @param {pm.apiServerUtils~standardCallback} callback
     * @param {String} data
     * @param {Boolean} restore
     */
	sendPurchaseData: function(callback, data, restore)
	{
		var purchasePlatform = "";

		if (cc.sys.os === cc.sys.OS_IOS)
			purchasePlatform = "ios";
		else if (cc.sys.os === cc.sys.OS_ANDROID)
			purchasePlatform = "android";

		this._sendRequestWithToken({
			method: "POST",
			url: pm.appConfig.apiAddress,
			request: "market/purchase/" + purchasePlatform,
			data: {
				uuid: encodeURIComponent(pm.marketHelper.getDeviceUUID()),
				restore: restore,
				paymentData: data
			},
			callback: callback
		});
	}

};

/**
 * @typedef {Object} pm.serverManager~RequestError
 * @property {Number} status XHR status of request
 * @property {String} text Description of error
 */

/**
 * @callback pm.serverManager~standardCallback
 * @param {?pm.apiServerUtils~RequestError} error
 * @param {?Object} [response]
 * @param {String} response.status
 */

/**
 * @callback pm.serverManager~loginCallback
 * @param {?pm.apiServerUtils~RequestError} error
 * @param {?Object} response
 * @param {String} response.access_token
 * @param {String} response.refresh_token
 */

/**
 * @callback pm.serverManager~getUserAccountDataCallback
 * @param {?pm.apiServerUtils~RequestError} error
 * @param {?Object} response
 * @param {String} response.status
 * @param {String} response.username
 * @param {String} response._id
 * @param {Number} response.accountType
 * @param {String} response.name
 * @param {String} response.surname
 */

/**
 * @callback pm.serverManager~updateAccessTokenCallback
 * @param {?pm.apiServerUtils~RequestError} error
 * @param {?Object} response
 * @param {String} response.status
 * @param {String} response.access_token
 * @param {String} response.refresh_token
 */

/**
 * @callback pm.serverManager~getLevelsCacheCallback
 * @param {?pm.apiServerUtils~RequestError} error
 * @param {Object} [response]
 * @param {Object} [response.cache]
 */

/**
 * @callback pm.serverManager~getPMVersionCallback
 * @param {?pm.apiServerUtils~RequestError} error
 * @param {?Object} [response]
 * @param {String} response.status
 * @param {String} response.version
 */

/**
 * @typedef {Object} pm.serverManager~WorldRecord
 * @property {String} name
 * @property {Object} owner
 * @property {String} owner.id
 * @property {String} owner.name
 * @property {Object.<String,String>} [groups]
 */

/**
 * @callback pm.serverManager~getWorldsCallback
 * @param {?pm.apiServerUtils~RequestError} error
 * @param {?Object} response
 * @param {Object.<String, pm.apiServerUtils~WorldRecord>} response.worlds
 */

/**
 * @callback pm.serverManager~createWorldCallback
 * @param {?pm.apiServerUtils~RequestError} error
 * @param {?Object} [response]
 * @param {String} response.status
 * @param {String} response.id Id of new world
 */

/**
 * @callback pm.serverManager~downloadWorldCallback
 * @param {?pm.apiServerUtils~RequestError} error
 * @param {?Object} [response]
 * @param {String} response.status
 * @param {String} response.data Stringified data of world
 * @param {String} response.date Date of downloaded world creation
 */

/**
 * @callback pm.serverManager~getWorldFormatVersionCallback
 * @param {?pm.apiServerUtils~RequestError} error
 * @param {?Object} [response]
 * @param {String} response.status
 * @param {Number} response.version Latest version of world
 */

/**
 * @callback pm.serverManager~getWorldRevisionCallback
 * @param {?pm.apiServerUtils~RequestError} error
 * @param {?Object} [response]
 * @param {String} response.status
 * @param {Number} response.revision Latest revision of world for format version
 */
