/**
 * Created by Nikita Besshaposhnikov on 10.11.14.
 */

/**
 * @class Common interface for 2D iso robot sprites.</br>
 * Provides move animations.
 * @interface
 * @extends pm.RobotSprite2D
 */
pm.IsoRobotSprite = pm.RobotSprite2D.extend(/** @lends pm.IsoRobotSprite#*/{
	ctor: function(frame)
	{
		this._super(frame);

		this.setOrientation(pm.MapLayer2D.Orientation.Iso);
	},

	_getTurnAnimationName: function(oldDirection, curDirection){ return ""; },

	setDirection: function(direction)
	{
		if(direction >= 0)
			this.sprite.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame(this._getFrameName(direction)));
		else
			cc.log("Incorrect robot direction");
	},

	_turn: function(oldDirection, curDirection, endCallback, target)
	{
		var animation = cc.animationCache.getAnimation(this._getTurnAnimationName(oldDirection, curDirection));
		animation.setDelayPerUnit(pm.settings.getAnimationSpeed()/animation.getTotalDelayUnits());
		var animate = new cc.Animate(animation);
		var turnEnd = new cc.CallFunc(endCallback, target);
		this.sprite.runAction( new cc.Sequence(animate, turnEnd));
	}
});
