/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.NTimesRepeaterNode = pmui.RepeaterNode.extend(/** @lends pmui.NodeLayer# */{
	_number: null,

	ctor: function (treeNode, parent, syntaxModule, type)
	{
		this._super(treeNode, parent, syntaxModule, type);

		pm.syntaxModules.constructNTimesRepeaterNode(this);
	},

	addChildNode: function (node)
	{
		if(node._treeNode.getTagName() === pm.BodyTag
			|| node._treeNode.type === pm.data.Node.Type.Body)
		{
			this._bodyNode = node;
			this.createALignSome(this._bodyNode, pmui.RepeaterNode.FIRST,  pmui.RepeaterNode.BODY_TAG, ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			this.addChild(this._bodyNode);
			return;
		}
		node._repTypes = [FunctionButton.Type.Number, FunctionButton.Type.Variable, FunctionButton.Type.Expression, FunctionButton.Type.Constant, FunctionButton.Type.IntMethod];
		var parent = this._treeNode;
		parent.removeChild(this._number._treeNode);

		var align = this._number.getLayoutParameter().clone();
		node.setLayoutParameter(align);

		this.removeChild(this._number);
		this._number = node;
		this._number._treeNode.setTagName(pm.NTimesNumTag);
		this.addChild(node);
	},

	_removeNode: function (node)
	{
		if (node._treeNode.getTagName() === pm.NTimesNumTag)
		{
			var num = pm.data.SyntaxTree.createNode(this._treeNode, pm.data.Node.Type.Number, 0);
			num.setTagName(pm.NTimesNumTag);
			pm.sendCustomEvent(pm.PARSE_NODE, {node: num, parent: this});
		}
	},

	getChildrenNodes: function ()
	{
		return [this._number, this._bodyNode];
	},

	clone: function ()
	{
		return pm.appUtils.generateFunctionButton(this.type, pm.LoopVals.nTimes);
	},

	calculateSize:function (forPar)
	{
		pm.syntaxModules.calculateSizeOfNTimesRepeaterNode(this);

		if(!forPar)
			this._parentNode.calculateSize();
	}
});

