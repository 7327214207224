/**
 * Created by Nikita Besshaposhnikov on 28.11.14.
 */

/**
 * This callback is used on pressing buttons in MenuEditorElement.
 * @callback MenuEditorElement~actionCallback
 */

/**
 * @class Element for select table view on editor with delete, copy buttons.
 * @extends cc.Layer
 * @constructor
 * @param {Number} [index] Index of element in sliding menu.
 * @param {cc.Node} [node] Node to show in element.
 * @param {Number} [cellWidth] Width of cell.
 * @param {String} [name] Name of element.
 * @param {Number} [maps] Number of maps of level.
 * @param {MenuEditorElement~actionCallback} [callback] Event callback for buttons.
 * @param {Object} [target] Target to call callbacks.
 */
var MenuEditorElement = ccui.Layout.extend(/** @lends MenuEditorElement# */{

	_callback: null,
	_target: null,
	_index: 0,

	_shiftRightButton: null,
	_shiftLeftButton: null,

	_pasteRightButton: null,
	_pasteLeftButton: null,

	ctor: function(index, maxIndex, node, cellWidth, name, maps, callback, showShiftButtons, showPasteButtons, target)
	{
		this._super();

		this._index = index;
		this._callback = callback;
		this._target = target;

		var scale = cellWidth / node.width;
		node.setScale(scale);
		node.setAnchorPoint(cc.p(0.5, 1));

		var text = new ccui.Text(name, pm.settings.fontName, pm.settings.fontSize);
		text.setTextAreaSize(cc.size(node.width*scale, 0));
		text.setTextHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
		text.setAnchorPoint(cc.p(0.5, 0));

		var remove = new pmui.Button(pm.spriteUtils.getIconName("deleteGame", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("deleteGame", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("deleteGame", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		remove.setAnchorPoint(1, 1);
		remove.addClickEventListener(this._remove.bind(this));

		var copy = new pmui.Button(pm.spriteUtils.getIconName("save", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("save", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("save", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		copy.setAnchorPoint(1, 1);
		copy.addClickEventListener(this._copy.bind(this));

		this.setContentSize(node.width*scale, remove.height + text.height + node.height*scale);

		if (maps !== null && maps > 1)
		{
			var mapsCounter = new cc.Sprite(pm.spriteUtils.getIconSpriteFrame("maps", pm.NORMAL_STATE));

			mapsCounter.setAnchorPoint(0, 1);
			mapsCounter.setPosition(0, this.height);

			var counterLabel = new ccui.Text(maps, pm.settings.fontName, pm.settings.fontSize);

			counterLabel.setAnchorPoint(cc.p(0, 0.5));
			counterLabel.setPosition(mapsCounter.width + 3, this.height - mapsCounter.height/2);

			this.addChild(mapsCounter);
			this.addChild(counterLabel);
		}

		node.setPosition(this.width/2, this.height - remove.height);
		remove.setPosition(this.width, this.height);
		copy.setPosition(this.width - remove.width - MenuEditorElement.SEPARATOR, this.height);
		text.setPosition(node.width*scale/2, 0);

		if(index > 0)
		{
			this._shiftLeftButton = new pmui.Button(pm.spriteUtils.getIconName("prevEditor", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("prevEditor", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("prevEditor", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			this._shiftLeftButton.addClickEventListener(this._shiftLeft.bind(this));

			this._shiftLeftButton.setAnchorPoint(0, 0.5);
			this._shiftLeftButton.setPosition(20, node.getPosition().y - node.height * scale * 4/5);

			if (!showShiftButtons)
				this._shiftLeftButton.setVisible(false);
			else
				this._shiftLeftButton.setVisible(true);

			this.addChild(this._shiftLeftButton, 2);
		}

		if(index < maxIndex - 1)
		{
			this._shiftRightButton = new pmui.Button(pm.spriteUtils.getIconName("nextEditor", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("nextEditor", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("nextEditor", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			this._shiftRightButton.addClickEventListener(this._shiftRight.bind(this));

			this._shiftRightButton.setAnchorPoint(1, 0.5);
			this._shiftRightButton.setPosition(this.width - 20, node.getPosition().y - node.height * scale * 4/5);

			if (!showShiftButtons)
				this._shiftRightButton.setVisible(false);
			else
				this._shiftRightButton.setVisible(true);

			this.addChild(this._shiftRightButton, 2);
		}

		this._pasteLeftButton = new pmui.Button(pm.spriteUtils.getIconName("prevEditor", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("prevEditor", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("prevEditor", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this._pasteLeftButton.addClickEventListener(this._pasteLeft.bind(this));

		this._pasteLeftButton.setAnchorPoint(0, 0.5);
		this._pasteLeftButton.setPosition(20, node.getPosition().y - node.height * scale * 4/5);

		if (!showPasteButtons)
			this._pasteLeftButton.setVisible(false);
		else
			this._pasteLeftButton.setVisible(true);

		this.addChild(this._pasteLeftButton, 2);


		this._pasteRightButton = new pmui.Button(pm.spriteUtils.getIconName("nextEditor", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("nextEditor", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("nextEditor", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this._pasteRightButton.addClickEventListener(this._pasteRight.bind(this));

		this._pasteRightButton.setAnchorPoint(1, 0.5);
		this._pasteRightButton.setPosition(this.width - 20, node.getPosition().y - node.height * scale * 4/5);

		if (!showPasteButtons)
			this._pasteRightButton.setVisible(false);
		else
			this._pasteRightButton.setVisible(true);

		this.addChild(this._pasteRightButton, 2);

		this.addChild(node, 0);
		this.addChild(remove, 1);
		this.addChild(copy, 1);
		this.addChild(text, 1);
	},

	_shiftRight: function()
	{
		this._callback.call(this._target, MenuEditorLayer.EventType.ShiftRight, this._index);
	},

	_shiftLeft: function()
	{
		this._callback.call(this._target, MenuEditorLayer.EventType.ShiftLeft, this._index);
	},

	_pasteRight: function()
	{
		this._callback.call(this._target, MenuEditorLayer.EventType.PasteRight, this._index);
	},

	_pasteLeft: function()
	{
		this._callback.call(this._target, MenuEditorLayer.EventType.PasteLeft, this._index);
	},

	_remove: function()
	{
		this._callback.call(this._target, MenuEditorLayer.EventType.Remove, this._index);
	},

	_copy: function()
	{
		this._callback.call(this._target, MenuEditorLayer.EventType.Copy, this._index);
	}
});

MenuEditorElement.SEPARATOR = 3;
