/**
 * Created by Kirill Mashchenko on 23.09.2017.
 */

/**
 * @class Layer for global robots settings.
 * @extends LevelSettingsLayer
 * @constructor
 * @param {pm.AbstractLevel} level Level settings need to display.
 */
var GlobalRobotsSettingsLayer = LevelSettingsLayer.extend(/** @lends GlobalRobotsSettingsLayer# */{

	_robotList: null,
	_availableRobotList: null,
	_addRobotSelect: null,
	_robot: null,

	ctor: function(level)
	{
		this._super(level);

		var label = new ccui.Text(LocalizedString("GlobalRobots"), pm.settings.fontName, pm.settings.fontSize);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		labelAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		labelAlign.setRelativeName("label");
		label.setLayoutParameter(labelAlign);

		this.addChild(label);

		var addLayout = new ccui.HBox();

		var addLayoutAlign = new ccui.RelativeLayoutParameter();
		addLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		addLayoutAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		addLayoutAlign.setRelativeName("select");
		addLayoutAlign.setRelativeToWidgetName("label");
		addLayout.setLayoutParameter(addLayoutAlign);

		this._addRobotSelect = new pmui.ComboBox([], this, this._selectRobotOnSelect, 20);

		var addButton = new pmui.Button(pm.spriteUtils.getIconName("add", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("add", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("add", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		// addButton.setScale(0.7);
		addButton.addClickEventListener(this._addRobot.bind(this));

		var addButtonAlign = new ccui.LinearLayoutParameter();
		addButtonAlign.setMargin(5, 5, 0, 0);
		addButton.setLayoutParameter(addButtonAlign);

		addLayout.addChild(this._addRobotSelect);
		addLayout.addChild(addButton);
		addLayout.setContentSize(addButton.width + this._addRobotSelect.width,
			Math.max(addButton.height, this._addRobotSelect.height));

		this.addChild(addLayout, 1);

		var height = (GlobalRobotsSettingsLayer.HEIGHT - label.height - addLayout.height) - 10;

		this._robotList = new ccui.ListView();
		this._robotList.setContentSize(LevelSettingsLayer.WIDTH, height);
		this._robotList.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._robotList.setScrollBarOpacity(255 * 0.9);
		this._robotList.setScrollBarWidth(5);
		this._robotList.setItemsMargin(5);

		var listAlign = new ccui.RelativeLayoutParameter();
		listAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		listAlign.setRelativeToWidgetName("select");
		this._robotList.setLayoutParameter(listAlign);

		this.addChild(this._robotList);

		this._fillRobotList();
		this._generateAvailableRobotList();

		this.updateSize(LevelSettingsLayer.WIDTH, GlobalRobotsSettingsLayer.HEIGHT);
	},

	_fillRobotList: function()
	{
		var existingRobotsTypes = [];

		for (var i = 0; i < this._level.globalRobots.length; ++i)
		{
			var robot = this._level.globalRobots[i];
			var robotType = robot.getType();

			var robotExist = false;

			for (var j = 0; j < existingRobotsTypes.length; ++j)
			{
				if (robotType === existingRobotsTypes[j])
				{
					robotExist = true;
					this._level.globalRobots.splice(i, 1);

					break;
				}
			}

			if (robotExist)
				continue;

			existingRobotsTypes.push(robotType);

			var layer = this._generateLayerForRobot(robot);

			if(layer)
				this._robotList.pushBackCustomItem(layer);
		}
	},

	_generateLayerForRobot: function(robot)
	{
		var robotType = robot.getType();
		this._robot = robot;

		switch (robotType)
		{
			case pm.GlobalRobotType.Counter:
				return new CounterLayer(this._level, robot, robotType);
			case pm.GlobalRobotType.ExtendedCounter:
				return new ExtendedCounterLayer(this._level, robot, robotType);
			case pm.GlobalRobotType.Output:
				return new OutputLayer(this._level, robot, robotType);
			case pm.GlobalRobotType.OrangeFlag:
			case pm.GlobalRobotType.GreenFlag:
				return new FlagLayer(this._level, robot, robotType);
		}
	},

	_generateAvailableRobotList: function()
	{
		this._availableRobotList = [];

		var availableRobotsInformation = pm.appUtils.getAvailableGlobalRobots();

		var labels = availableRobotsInformation.labels;

		for (var i = 0; i < availableRobotsInformation.globalRobots.length; ++i)
		{
			this._availableRobotList.push(availableRobotsInformation.globalRobots[i]);
		}

		var parent = this._addRobotSelect.getParent();
		var parentWidth = this._addRobotSelect.getParent().width;
		parentWidth -= this._addRobotSelect.width;

		this._addRobotSelect.setValueList(labels);
		parentWidth += this._addRobotSelect.width;

		this._addRobotSelect.getParent().setContentSize(parentWidth, parent.height);

		if(parent.getParent())
			parent.getParent().requestDoLayout();
	},

	_addRobot: function()
	{
		var robotType = this._availableRobotList[this._addRobotSelect.getSelectedIndex()];

		var items = this._robotList.getItems();
		var found = false;

		for(var i = 0 ; i < items.length; ++i)
		{
			if(items[i].getGlobalRobotType() === robotType)
			{
				found = true;
				break;
			}
		}

		if(!found)
		{
			switch (robotType)
			{
				case pm.GlobalRobotType.Counter:

					if (this._level.globalRobotConfig[pm.GlobalRobotType.Counter] ||
                        this._level.globalRobotConfig[pm.GlobalRobotType.ExtendedCounter])
						return;

					pm.spriteUtils.initRobotTextures(null, pm.GlobalRobotType.Counter);

					var counter = new pm.Counter();
					counter.parentLevel = this._level;

					this._level.globalRobots.push(counter);
					counter.id = this._level.globalRobots.indexOf(counter);

					this._level.globalRobotConfig[pm.GlobalRobotType.Counter] = {};

					pm.sendCustomEvent(pme.ADD_GLOBAL_ROBOT, counter);

					var layer = this._generateLayerForRobot(counter);
					this._robot = counter;
					break;
				case pm.GlobalRobotType.Output:

					if (this._level.globalRobotConfig[pm.GlobalRobotType.Output])
						return;

					pm.spriteUtils.initRobotTextures(null, pm.GlobalRobotType.Counter);

					var counter = new pm.Output();
					counter.parentLevel = this._level;

					this._level.globalRobots.push(counter);
					counter.id = this._level.globalRobots.indexOf(counter);

					this._level.globalRobotConfig[pm.GlobalRobotType.Output] = {};

					pm.sendCustomEvent(pme.ADD_GLOBAL_ROBOT, counter);

					var layer = this._generateLayerForRobot(counter);
					this._robot = counter;
					break;
				case pm.GlobalRobotType.ExtendedCounter:

					if (this._level.globalRobotConfig[pm.GlobalRobotType.ExtendedCounter] ||
                        this._level.globalRobotConfig[pm.GlobalRobotType.Counter])
						return;

					pm.spriteUtils.initRobotTextures(null, pm.GlobalRobotType.ExtendedCounter);
					var counter = new pm.ExtendedCounter();
					counter.parentLevel = this._level;

					this._level.globalRobots.push(counter);
					counter.id = this._level.globalRobots.indexOf(counter);

					this._level.globalRobotConfig[pm.GlobalRobotType.ExtendedCounter] = {useCompareConditions: true};

					counter.configure(this._level.globalRobotConfig[pm.GlobalRobotType.ExtendedCounter]);

					pm.sendCustomEvent(pme.ADD_GLOBAL_ROBOT, counter);

					var layer = this._generateLayerForRobot(counter);
					this._robot = counter;
					break;
				case pm.GlobalRobotType.GreenFlag:
				case pm.GlobalRobotType.OrangeFlag:
					pm.spriteUtils.initRobotTextures(null, robotType);
					var robot = null;
					if (robotType === pm.GlobalRobotType.GreenFlag)
						robot = new pm.GreenFlagRobot();
					else
						robot = new pm.OrangeFlagRobot();

					robot.parentLevel = this._level;

					this._level.globalRobots.push(robot);
					robot.id = this._level.globalRobots.indexOf(robot);

					this._level.globalRobotConfig[robotType] = {};

					pm.sendCustomEvent(pme.ADD_GLOBAL_ROBOT, robot);

					var layer = this._generateLayerForRobot(robot);
					this._robot = robot;
					break;
			}

			if(layer)
				this._robotList.pushBackCustomItem(layer);
		}
	},

	_selectRobotOnSelect: function(index) {}

});

GlobalRobotsSettingsLayer.HEIGHT = 250;
