/**
 * Created by VK on 18.03.21.
 */

/**
 * @class Functions for any syntax module.
 * @interface
 * @extends cc.Class
 */

pm.abstractSyntaxModule = cc.Class.extend(/** @lends pm.abstractSyntaxModule# */{

    _dotSpriteSize: 0,

    // ctor: function() {},

    getTreeCreator: function (programTree, robot, parent, outputRobot, syntaxModule) {},

    isTextual: function ()
    {
        return true;
    },

    calculateTabulationSize: function ()
    {
        if(this.isTextual())
            return this._dotSpriteSize;
        else
            return ProgramContainerLayer.SEPARATOR_SIZEX;
    },

    constructIfNode: function(node)
    {
        var elseBlock = new pmui.NodeLayer(node, null);
        elseBlock.select = node._firstElemSelect;
        elseBlock.setContentSize(pmui.BodyNode.FIRST_WIDTH, pmui.BodyNode.FIRST_HEIGHT);
        elseBlock._repTypes = [FunctionButton.Type.ElseBlock];
        elseBlock.setName(pmui.IfNode.ELSE_BLOCK_NAME);
        node.createALignSome(elseBlock, "body", "elseBlock", ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN,
            -ProgramContainerLayer.SEPARATOR_SIZEX, 0, 0, 0);
        node.addChild(elseBlock);
    },

    calculateSizeOfIfNode: function (node)
    {
        var bodyWidth = 0;
        var bodyHeight = 0;

        if(node._thenBody)
        {
            bodyWidth = node._thenBody.width;
            bodyHeight = node._thenBody.height;
        }
        var height = Math.max(node.getChildByTag(pmui.IfNode.IF_TEXT_TAG).height, node._condition.height) + bodyHeight - pmui.BodyNode.FIRST_HEIGHT

        if(node.getChildByName(pmui.IfNode.ELSE_BLOCK_NAME).isElseBlock())
        {
            node.getChildByName(pmui.IfNode.ELSE_BLOCK_NAME).calculateSize(true);
            height += pmui.NodeLayer.SEPARATORY;
        }

        return {
            bodyWidth : bodyWidth,
            height : height
        };
    },

    constructElseBlock: function (node, treeNode, parent)
    {
        var elseName = node.createText("else", pmui.NodeLayer.CONSTR_COLOR);
        elseName.setTag(pmui.IfNode.ELSE_WORD_TAG);
        node._elseBody = new pmui.BodyNode(treeNode, parent);
        node.addChild(elseName);
        node.addChild(node._elseBody);

        node.createALignSome(elseName, "", "else_text", ccui.RelativeLayoutParameter.PARENT_TOP_LEFT, 0, pmui.NodeLayer.SEPARATORY, 0, 0);
        node.createALignSome(node._elseBody, "else_text", "elseBody", ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);

        node.setContentSize(node._elseBody.width, node._elseBody.height + elseName.height);
        node._elseBody.set(treeNode);
    },

    constructRepeaterNode: function (node) {},

    constructForRepeaterNode: function (node) {},

    calculateSizeOfForRepeaterNode: function (node, bodyWidth, bodyHeight, sep) {},

    calculateSizeOfRepeaterNode: function (node)
    {
        var separator = pmui.BodyNode.FIRST_HEIGHT;
        if(this.isTextual())
            separator = pmui.BodyNode.FIRST_HEIGHT - pmui.NodeLayer.SEPARATORY;

        var bodyWidth = 0;
        var bodyHeight = 0;

        if(node._bodyNode)
        {
            bodyWidth = node._bodyNode.width;
            bodyHeight = node._bodyNode.height;
        }

        return {
            separator : separator,
            bodyWidth : bodyWidth,
            bodyHeight : bodyHeight
        };
    },

    constructWhileRepeaterCondition: function (node)
    {
        if (node._condition === null)
            node._condition = new pmui.ReplaceNode([FunctionButton.Type.Bool, FunctionButton.Type.Variable, FunctionButton.Type.LogicExpression, FunctionButton.Type.AndOrExpression, FunctionButton.Type.NotExpression], node);

        node.createALignSome(node._condition, pmui.RepeaterNode.FIRST, pm.WhileCondTag, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
        node.addChild(node._condition);
    },

    constructNTimesRepeaterNumber: function (node)
    {
        if (node._number === null)
            node._number = new pmui.ReplaceNode([FunctionButton.Type.Number, FunctionButton.Type.Variable, FunctionButton.Type.Expression], node);

        node.createALignSome(node._number, pmui.RepeaterNode.FIRST, pm.NTimesNumTag, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
        node.addChild(node._number);
    },

    calculateSizeOfNTimesRepeaterNode: function (node, bodyWidth, bodyHeight, sep) {},

    getStatementNodeSign: function (node) {},

    addChildNodeToAllocNode: function (node, typeName) {},

    constructBodyNode: function (node)
    {
        if(this.isTextual())
        {
            node._dotSprite = new ccui.VBox();
            node.createALignSome(node._dotSprite, "", "dots", ccui.RelativeLayoutParameter.PARENT_TOP_LEFT, 3, 0, 0, 0);
            node.addChild(node._dotSprite);

            ProgramContainerLayer.SEPARATOR_SIZEX = 0;
            pmui.BodyNode.FIRST_HEIGHT = 0;
            pmui.BodyNode.FIRST_WIDTH = 0;
        }
        else
        {
            node._dotSprite = null;

            ProgramContainerLayer.SEPARATOR_SIZEX = 40;
            pmui.BodyNode.FIRST_HEIGHT = 20;
            pmui.BodyNode.FIRST_WIDTH = 60;
        }

        var align = node.getLayoutParameter();
        if(!align)
            align = new ccui.RelativeLayoutParameter();
        align.setMargin(ProgramContainerLayer.SEPARATOR_SIZEX, - pmui.BodyNode.FIRST_HEIGHT + pmui.NodeLayer.SEPARATORY , 0, 0);
        node.setLayoutParameter(align);

        node._addTypes = [FunctionButton.Type.Method, FunctionButton.Type.IfStatement, FunctionButton.Type.Repeater,
            FunctionButton.Type.Alloc, FunctionButton.Type.Statement, FunctionButton.Type.Empty, FunctionButton.Type.MethodList, FunctionButton.Type.HorizontalBar];

        node._firstElem = new pmui.NodeLayer(node, null);
        node._firstElem.select = node._firstElemSelect;
        node._firstElem._id = node._id + pm.BodyFirstChildTag;

        node._firstElem.setContentSize(pmui.BodyNode.FIRST_WIDTH, pmui.BodyNode.FIRST_HEIGHT);
        node.addChild(node._firstElem);
    },

    resizeBodyNodeDotSprite: function (node)
    {
        node._dotSprite.removeAllChildren();
        node._dotSprite.setContentSize(0, 0);
        node.sizeOfVContainer(node);
        node._dotSprite.setContentSize(this._dotSpriteSize, node.height);
    },

    constructStartNodeBox: function (node, robotName) {},

    constructFunctionNode: function (node) {},

    addChildNodeToFunctionNode: function (node, childNode)
    {
        node._cont.addChild(childNode);
        node._params.push(childNode);
        node._paramsNumber++;

        node.sizeOfContainer(node._cont);
        //node.calculateSize();

        pm.sendCustomEvent(pm.FUNCTION_PARAMS_CHANGED, {name: node._name, params: node._paramsNumber});
    },

    removeChildNodeFromFunctionNode: function (node) {},

    constructEmptyParameter: function (node)
    {
        var first = new pmui.NodeLayer(node, null);
        first._id = node._id + pm.BodyFirstChildTag;
        first.select = node.paramsSelect;
        first.setName("firstElem" + node._id);

        node.addChild(first);
        node._params.push(first);

        return first;
    },

    getLocalization: function (language) {}
});