/**
 * Created by Kirill Mashchenko on 12.07.2018.
 */

pm.RobotProgramP = pm.RobotProgram.extend({

	ctor: function()
	{
		this._super();
	},

	setForceInstruction: function (methodId)
	{
		this.ended = false;
		this.instrPosition = this.labels[methodId];
	}
});

pm.programUtilsP = {

	_level: null,

	compileRobotProgram: function(robot, level)
	{
		var program = new pm.RobotProgramP();
		pm.programUtils.totalLabelCount = 0;
		this._level = level;

		var parsedProgram = this.parseProgram(robot.getProgramData());

		this._compileFunctionBlocks(program, parsedProgram, robot);

		program.ended = false;

		if (program.labels[pm.CMD_MAIN] !== undefined)
			program.instrPosition = program.labels[pm.CMD_MAIN];

		pm.programUtils.totalLabelCount = 0;
		this._level = null;

		return program;
	},

	parseProgram: function(program)
	{
		var parsedProgram = {type: pm.FunctionBlockType.Root, children: []};
		var currentDepth = 0;
		var currentMaxLength = 0;

		var parseFunction = {
			type: pm.FunctionBlockType.Function,
			value: pm.CMD_MAIN,
			leftUp: cc.p(0, 0),
			rightDown: cc.p(),
			parent: parsedProgram,
			children: [],
			depth: 0,
			maxLength: 0
		};

		var currentParent = parseFunction;

		var lastNotEmptyString = -1;
		var isMethodBlock = false;

		var methodBlock = {};
		var currentBlock = undefined;

		for (var i = 0; i < program.height; ++i)
		{
			var isEmptyRow = true;
			var extraIndent = 0;

			if (currentBlock)
			{
				if (currentBlock.type === pm.FunctionBlockType.Repeater || currentBlock.type === pm.FunctionBlockType.Condition || currentBlock.type === pm.FunctionBlockType.CondRepeater)
					extraIndent = currentBlock.leftUp.y - currentDepth;
			}

			for (var t = 0; t < currentDepth + extraIndent; ++t)
			{
				var empSymbol = program.symbols[i][t];

				if (empSymbol.type !== pm.SymbolType.Empty)
				{
					for (var e = 0; e < currentDepth; ++e)
					{
						if (t < currentParent.leftUp.y)
						{
							currentParent.rightDown = cc.p(lastNotEmptyString, currentParent.leftUp.y + currentParent.maxLength);
							currentParent = currentParent.parent;
						}
						else
						{
							break;
						}
					}

					currentDepth = currentDepth - e;
					isMethodBlock = false;
					break;
				}
			}

			for (var j = 0; j < program.width; ++j)
			{
				var symbol = program.symbols[i][j];

				switch (symbol.type)
				{
					case pm.SymbolType.Empty:
					case pm.SymbolType.Method:
						if (j === currentDepth)
						{
							if(!isMethodBlock || j === 0)
							{
								methodBlock = {
									type: pm.FunctionBlockType.Simple,
									value: -1,
									leftUp: cc.p(i, j),
									rightDown: cc.p(i, j),
									parent: currentParent,
									children: [],
									depth: currentDepth,
									maxLength: 0
								};

								currentParent.children.push(methodBlock);

								isMethodBlock = true;
							}
						}
						else if (currentBlock !== undefined)
						{
							if (currentBlock.type === pm.FunctionBlockType.Repeater || currentBlock.type === pm.FunctionBlockType.Condition || currentBlock.type === pm.FunctionBlockType.CondRepeater)
							{
								if(!isMethodBlock || j === 0)
								{
									methodBlock = {
										type: pm.FunctionBlockType.Simple,
										value: -1,
										leftUp: cc.p(i, j),
										rightDown: cc.p(i, j),
										parent: currentParent,
										children: [],
										depth: currentDepth,
										maxLength: 0
									};

									currentParent.children.push(methodBlock);

									isMethodBlock = true;
								}
							}
						}

						this._updateBorders(methodBlock, cc.p(i, j), isMethodBlock);

						if (symbol.type === pm.SymbolType.Method)
						{
							isEmptyRow = false;
							lastNotEmptyString = i;

							if (currentMaxLength + currentDepth < j)
								currentMaxLength = j - currentDepth;

							if (currentParent.maxLength < currentMaxLength)
								currentParent.maxLength = currentMaxLength;

							var tempCurrentParent = currentParent;

							for (var e = 1; e <= currentDepth; ++e)
							{
								tempCurrentParent = tempCurrentParent.parent;

								if (tempCurrentParent.maxLength < currentParent.maxLength + e)
									tempCurrentParent.maxLength = currentParent.maxLength + e;
							}
						}

						break;

					case pm.SymbolType.Condition:
					case pm.SymbolType.CondRepeater:
					case pm.SymbolType.Repeater:

						isEmptyRow = false;
						isMethodBlock = false;
						lastNotEmptyString = i;
						++currentDepth;

						var currentBlock = {
							type: this._getComplexBlockType(symbol.type),
							value: symbol.value,
							leftUp: cc.p(i, j+1),
							rightDown: cc.p(i, j+1),
							parent: currentParent,
							children: [],
							depth: currentDepth,
							extraIndent: j - currentDepth,
							maxLength: 0
						};

						currentParent.children.push(currentBlock);
						currentParent = currentBlock;
						currentMaxLength = 0;

						if (currentMaxLength + currentDepth + currentBlock.extraIndent < j)
							currentMaxLength = j - currentDepth - currentBlock.extraIndent;

						if (currentParent.maxLength < currentMaxLength)
							currentParent.maxLength = currentMaxLength;

						var tempCurrentParent = currentParent;

						if (j > tempCurrentParent.maxLength)
						{
							for (var e = 1; e <= currentDepth; ++e)
							{
								tempCurrentParent = tempCurrentParent.parent;

								if (tempCurrentParent.maxLength < currentParent.maxLength + currentParent.leftUp.y - tempCurrentParent.leftUp.y)
									tempCurrentParent.maxLength = currentParent.maxLength + currentParent.leftUp.y - tempCurrentParent.leftUp.y;
							}
						}
						break;

					case pm.SymbolType.Function:
						isEmptyRow = false;
						isMethodBlock = false;
						lastNotEmptyString = i;

						parseFunction = {
							type: pm.FunctionBlockType.Function,
							value: symbol.value,
							leftUp: cc.p(i+1, 0),
							rightDown: cc.p(),
							parent: {},
							children: [],
							depth: 0,
							maxLength: 0
						};

						currentParent = parseFunction;
						currentMaxLength = 0;

						break;
				}
			}

			if (isEmptyRow && i < program.height-1 && program.symbols[i+1][0].type === pm.SymbolType.Function
                || i === program.height-1)
			{
				for (var t = 0; t <= currentDepth; ++t)
				{
					currentParent.rightDown = cc.p(lastNotEmptyString, currentParent.leftUp.y + currentParent.maxLength);
					currentParent = currentParent.parent;
				}

				parsedProgram.children.push(parseFunction);

				currentDepth = 0;
				parseFunction = {};
			}
		}

		return parsedProgram;
	},

	beautifyTree: function(programTree, program, oldProgram, coords, maxWidthIndex)
	{
		switch (programTree.type)
		{
			case pm.FunctionBlockType.Root:
				for (var i = 0; i < programTree.children.length; i++)
				{
					this.beautifyTree(programTree.children[i], program, oldProgram, coords, maxWidthIndex);
				}

				return program;

			case pm.FunctionBlockType.Function:
				if (programTree.value !== pm.CMD_MAIN)
				{
					program.symbols[coords.x][coords.y] = oldProgram.symbols[programTree.leftUp.x-1][0];
					coords.x++;
				}

				for (var i = 0; i < programTree.children.length; i++)
				{
					this.beautifyTree(programTree.children[i], program, oldProgram, coords, maxWidthIndex);
				}

				coords.x += coords.x === coords.lastNotEmptyString ? 2 : 1;
				coords.y = 0;

				break;

			case pm.FunctionBlockType.Simple:
				for (var i = programTree.leftUp.y; i <= programTree.rightDown.y; i++)
				{
					if (oldProgram.symbols[programTree.leftUp.x][i].type !== pm.SymbolType.Empty)
					{
						program.symbols[coords.x][coords.y] = oldProgram.symbols[programTree.leftUp.x][i];
						coords.y++;
						coords.lastNotEmptyString = coords.x;

						if (coords.y > maxWidthIndex)
						{
							coords.x++;
							coords.y = programTree.depth;
						}
					}
				}

				break;

			case pm.FunctionBlockType.Condition:
			case pm.FunctionBlockType.Repeater:
			case pm.FunctionBlockType.CondRepeater:
				if (coords.x === coords.lastNotEmptyString)
				{
					if(program.symbols[coords.x][programTree.depth - 1].type !== pm.SymbolType.Empty)
						coords.x += (programTree.depth >= 1) ? 1 : 0;

					coords.y = programTree.depth - 1;
				}

				program.symbols[coords.x][coords.y] = oldProgram.symbols[programTree.leftUp.x][programTree.leftUp.y - 1];
				coords.lastNotEmptyString = coords.x;
				coords.y++;

				for (var i = 0; i < programTree.children.length; i++)
				{
					this.beautifyTree(programTree.children[i], program, oldProgram, coords, maxWidthIndex);
				}

				coords.y = programTree.depth - 1;

				if(coords.x === coords.lastNotEmptyString)
					coords.x++;

				break;

		}
	},

	_getComplexBlockType: function(symbolType)
	{
		switch(symbolType)
		{
			case pm.SymbolType.Repeater:
				return pm.FunctionBlockType.Repeater;
			case pm.SymbolType.Condition:
				return pm.FunctionBlockType.Condition;
			case pm.SymbolType.CondRepeater:
				return pm.FunctionBlockType.CondRepeater;
		}
	},

	_compileFunctionBlocks: function(program, currentBlock, robot)
	{
		if (currentBlock.type !== pm.FunctionBlockType.Root)
			return;

		for (var i = 0; i < currentBlock.children.length; ++i)
			this._compileFunction(program, currentBlock.children[i], robot);
	},

	_compileFunction: function(program, currentFunction, robot)
	{
		var instrList = program.instructions;

		var instLength = 0;

		program.labels[currentFunction.value] = instrList.length;

		for (var i = 0; i < currentFunction.children.length; ++i)
			instLength += this._compileBlock(program, currentFunction.children[i], robot, 0);

		var retInstr = new pm.Instruction(pm.Instruction.RETURN);

		instrList.push(retInstr);
		instLength++;

		return instLength;
	},

	_compileBlock: function(program, currentBlock, robot)
	{
		switch (currentBlock.type)
		{
			case pm.FunctionBlockType.Simple:
				return this._compileSimpleBlock(program, currentBlock, robot);
			case pm.FunctionBlockType.Repeater:
				return this._compileRepeaterBlock(program, currentBlock, robot);
			case pm.FunctionBlockType.Condition:
				return this._compileConditionBlock(program, currentBlock, robot);
			case pm.FunctionBlockType.CondRepeater:
				return this._compileCondRepeaterBlock(program, currentBlock, robot);
			default:

		}
	},

	_compileSimpleBlock: function(program, currentBlock, robot)
	{
		var length = 0;

		for (var h = currentBlock.leftUp.x; h <= currentBlock.rightDown.x; ++h)
		{
			for(var w = currentBlock.leftUp.y; w <= currentBlock.rightDown.y; ++w)
			{
				var symbol = robot.getProgramData().symbols[h][w];

				if (symbol.type !== pm.SymbolType.Empty && symbol.value !== pm.EMPTY_METHOD)
				{
					var instr = new pm.Instruction(pm.Instruction.EXECUTE);

					var nativeFunc = pm.programUtils.searchNativeRobotFunction(robot, symbol.value);

					instr.data.isNative = false;

					if(nativeFunc)
					{
						instr.data.robot = nativeFunc.robot;
						instr.data.isNative = true;
					}
					else
					{
						nativeFunc = pm.programUtils.searchGlobalNativeFunction(this._level, symbol.value);

						if(nativeFunc)
						{
							instr.data.robot = nativeFunc.robot;
							instr.data.isNative = true;
						}
					}

					if (symbol.value >= pm.CMD_A && symbol.value <= pm.CMD_E)
					{
						var functionExist = false;

						for (var i = 0; i < robot.getProgramData().height; ++i)
						{
							for (var j = 0; j < robot.getProgramData().width; ++j)
							{
								if (robot.getProgramData().symbols[i][j].value === symbol.value
									&& robot.getProgramData().symbols[i][j].type === pm.SymbolType.Function
									&& !(i === h && j === w))
									functionExist = true;
							}
						}

						if (!functionExist)
							continue;
					}

					instr.data.methodID = symbol.value;
					instr.data.methodPlace = cc.p(h, w);

					program.instructions.push(instr);
					length++;
				}
			}

		}

		return length;
	},

	_compileRepeaterBlock: function(program, currentBlock, robot)
	{
		var blockLength = 0;
		var repInstr;

		var loopBodyStartLabel = "";
		var blockEndLabel = "";

		loopBodyStartLabel = pm.programUtils.getLabel();
		blockEndLabel = pm.programUtils.getLabel();

		repInstr = new pm.Instruction(pm.Instruction.START_LOOP);

		if(!cc.isNumber(currentBlock.value))
		{
			var repeaterRobot = this._searchRobotForRepeater(robot, currentBlock.value);

			if (!repeaterRobot)
			{
				repeaterRobot = this._searchGlobalRobotForRepeater(currentBlock.value);
				repInstr.data.repArgs = robot.id;
			}

			repInstr.data.robot = repeaterRobot;
		}

		repInstr.data.repeater = currentBlock.value;
		repInstr.data.blockEndLabel = blockEndLabel;
		repInstr.data.iteratorTag = pm.variableList.addVariable(false);
		repInstr.data.repPlace = cc.p(currentBlock.leftUp.x, currentBlock.leftUp.y - 1);

		program.instructions.push(repInstr);
		program.labels[loopBodyStartLabel] = program.instructions.length;

		for (var i = 0; i < currentBlock.children.length; ++i)
			blockLength += this._compileBlock(program, currentBlock.children[i], robot);

		var endInstr = new pm.Instruction(pm.Instruction.END_LOOP);

		endInstr.data.repeater = currentBlock.value;

		if(repInstr.data.robot)
			endInstr.data.robot = repInstr.data.robot;

		if(repInstr.data.repArgs !== undefined)
			endInstr.data.repArgs = repInstr.data.repArgs;

		endInstr.data.jumpLabel = loopBodyStartLabel;
		endInstr.data.iteratorTag = repInstr.data.iteratorTag;
		endInstr.data.repPlace = repInstr.data.repPlace;

		program.instructions.push(endInstr);
		++blockLength;

		program.labels[blockEndLabel] = program.instructions.length;

		return blockLength;
	},

	_compileConditionBlock: function(program, currentBlock, robot)
	{
		var blockLength = 0;

		var condEndLabel = "";

		if(currentBlock.value !== pm.CONDITION_EMPTY)
		{
			condEndLabel = pm.programUtils.getLabel();

			var condInstr = new pm.Instruction(pm.Instruction.CHECK_CONDITION);

			condInstr.data.condition = currentBlock.value;

			var condRobot = pm.programUtils.searchRobotForCondition(robot, condInstr.data.condition);

			if(!condRobot)
			{
				condRobot = pm.programUtils.searchGlobalRobotForCondition(this._level, condInstr.data.condition);
				condInstr.data.condArgs = robot.id;
			}

			condInstr.data.robot = condRobot;
			condInstr.data.valueTag = pm.variableList.addVariable(false);
			condInstr.data.conditionPlace = cc.p(currentBlock.leftUp.x, currentBlock.leftUp.y - 1);

			program.instructions.push(condInstr);
			blockLength++;

			var instr = new pm.Instruction(pm.Instruction.JUMP_NIF);

			instr.data.conditionTag = condInstr.data.valueTag;
			instr.data.jumpLabel = condEndLabel;

			program.instructions.push(instr);
			++blockLength;
		}

		for (var i = 0; i < currentBlock.children.length; ++i)
			blockLength += this._compileBlock(program, currentBlock.children[i], robot);

		if(currentBlock.value !== pm.CONDITION_EMPTY)
			program.labels[condEndLabel] = program.instructions.length;

		return blockLength;
	},

	_compileCondRepeaterBlock: function(program, currentBlock, robot)
	{
		var blockLength = 0;

		var blockStartLabel = pm.programUtils.getLabel();
		program.labels[blockStartLabel] = program.instructions.length;

		if(currentBlock.value !== pm.CONDITION_EMPTY)
		{
			var blockEndLabel = pm.programUtils.getLabel();

			var condInstr = new pm.Instruction(pm.Instruction.CHECK_CONDITION);

			condInstr.data.condition = currentBlock.value;

			var condRobot = pm.programUtils.searchRobotForCondition(robot, condInstr.data.condition);

			if (!condRobot)
			{
				condRobot = pm.programUtils.searchGlobalRobotForCondition(this._level, condInstr.data.condition);
				condInstr.data.condArgs = robot.id;
			}

			condInstr.data.robot = condRobot;
			condInstr.data.valueTag = pm.variableList.addVariable(false);
			condInstr.data.conditionPlace= cc.p(currentBlock.leftUp.x, currentBlock.leftUp.y - 1);

			program.instructions.push(condInstr);
			blockLength++;

			var instr = new pm.Instruction(pm.Instruction.JUMP_NIF);

			instr.data.conditionTag = condInstr.data.valueTag;
			instr.data.jumpLabel = blockEndLabel;

			program.instructions.push(instr);
			++blockLength;
		}

		for (var i = 0; i < currentBlock.children.length; ++i)
			blockLength += this._compileBlock(program, currentBlock.children[i], robot);

		var jmpInstr = new pm.Instruction(pm.Instruction.JUMP);

		jmpInstr.data.jumpLabel = blockStartLabel;
		program.instructions.push(jmpInstr);
		++blockLength;

		if(currentBlock.value !== pm.CONDITION_EMPTY)
			program.labels[blockEndLabel] = program.instructions.length;

		return blockLength;
	},

	_searchRobotForRepeater: function(robot, repeater)
	{
		var searchRobot = robot;

		while(searchRobot instanceof pm.AbstractRobot)
		{
			if (searchRobot.repeaters.indexOf(repeater) !== -1)
				return searchRobot;
			else
				searchRobot = searchRobot.childRobot;
		}

		return null;
	},

	_searchGlobalRobotForRepeater: function(repeater)
	{
		for(var i = 0 ; i < this._level.globalRobots.length; ++i)
		{
			if (this._level.globalRobots[i].repeaters.indexOf(repeater) !== -1)
				return this._level.globalRobots[i];
		}

		return null;
	},

	_updateBorders: function(methodBlock, coords, isMethodBlock)
	{
		if (isMethodBlock)
		{
			if (coords.x > methodBlock.rightDown.x)
				methodBlock.rightDown.x	= coords.x;

			if (coords.y > methodBlock.rightDown.y)
				methodBlock.rightDown.y = coords.y;
		}
	}

};

pm.FunctionBlockType = {
	Root: -1,
	Simple: 0,
	Repeater: 1,
	Condition: 2,
	CondRepeater: 3,
	Function: 4
};
