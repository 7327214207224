/**
 * Created by Nikita Besshaposhnikov on 24.07.15.
 */

/**
 * Callback called on layer hide.
 * @callback pmui.PopupLayer~hideCallback
 */

/**
 * @class Popup oval layer with auto-removing.
 * @extends pmui.OvalLayout
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */
pmui.PopupLayer = pmui.OvalLayout.extend(/** @lends pmui.PopupLayer#*/{

	_callback: null,
	_target: null,

	ctor: function(hideDuration)
	{
		this._super(cc.size(0, 0), 0, pmui.OvalLayout.Type.SLIM);

		if(hideDuration === undefined)
			hideDuration = pmui.PopupLayer.STANDARD_HIDE_DURATION;

		if(hideDuration !== pmui.PopupLayer.HIDE_DURATION_NEVER)
			this._startSelfRemove(hideDuration);

		this.setTouchEnabled(true);

		this.addClickEventListener(this._selfRemove.bind(this));
	},

	/**
     * Sets callback on layer hide.
     * @param {pmui.PopupLayer~hideCallback} callback
     * @param {cc.Node} target Callback target
     */
	setHideCallback: function(callback, target)
	{
		this._callback = callback;
		this._target = target;
	},

	removeLayer: function()
	{
		this.stopAllActions();
		this._selfRemove();
	},

	_selfRemove: function()
	{
		this.removeFromParent(true);

		if(this._callback)
			this._callback.call(this._target);
	},

	_startSelfRemove: function(duration)
	{
		var delay = new cc.DelayTime(duration);
		var fadeLayer = new cc.FadeTo(pm.SYSTEM_ANIMATION_DELAY, 0);
		//var fadeColorLayer = new cc.TargetedAction(this.layerColor, fadeLayer);
		var end = new cc.CallFunc(this._selfRemove, this);

		this.runAction( new cc.Sequence(delay, fadeLayer, end) );
	}
});

pmui.PopupLayer.HIDE_DURATION_NEVER = -1;
pmui.PopupLayer.STANDARD_HIDE_DURATION = 10;
