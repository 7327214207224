/**
 * Created by Kirill Mashchenko on 23.09.2017.
 */

var CounterLayer = GlobalRobotLayer.extend(/** @lends CounterLayer# */{

	ctor: function (level, robot, robotType)
	{
		this._super(LocalizedString("SimpleCounter"), robot, robotType);
	}
});
