/**
 * Created by Nikita Besshaposhnikov on 09.04.15.
 */

/**
 * @class 2D map for {@link pm.data.PushLevel}
 * @implements SquarableMapWithWalls
 */
pm.data.PushMap = SquarableMapWithWalls.extend(/** @lends pm.data.PushMap# */{
	typeName: "PushMap",

	_pushTargetElements: [],

	ctor: function()
	{
		this._addNonEnumerableProps("_pushTargetElements");

		this._super();
	},

	load: function()
	{
		SquarableMap.prototype.load.call(this);

		this.updatePushTargetElements();
	},

	updatePushTargetElements: function()
	{
		this._pushTargetElements = [];

		for (var x = 0; x < this.width; ++x)
		{
			for (var y = 0; y < this.height; ++y)
			{
				if (this.element(cc.p(x, y)).originalType !== PushMapElementType.Grass)
				{
					this._pushTargetElements.push({
						point: cc.p(cc.p(x, y)),
						type: this.element(cc.p(x, y)).originalType
					});
				}
			}
		}
	},

	getType: function() { return pm.PushLevelModule.Type; },

	generateMapLayer: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (!pm.settings.isEditorMode)
				this.mapLayer = new PushMapLayer(this);
			else
				this.mapLayer = new PushMapEditorLayer(this);

			this.mapLayer.drawMap(previewDraw);

			return this.mapLayer;
		}
	},

	setSize: function(width, height)
	{
		this.mapElements.length = 0;

		for(var i = 0; i < height; ++i)
		{
			this.mapElements.push([]);

			for(var j = 0; j < width; ++j)
				this.mapElements[i].push(new pm.data.PushMapElement());
		}

		this.width = width;
		this.height = height;
	}
});
