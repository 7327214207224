/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.BodyNode = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{
	_nextNode: null,
	_firstElem: null,

	_dotSprite: null,
	ctor: function (treeNode, parent, syntaxModule)
	{
		this._super(parent, treeNode, syntaxModule);
		this.setLayoutType(ccui.Layout.RELATIVE);

		pm.syntaxModules.constructBodyNode(this);
	},

	_firstElemSelect: function()
	{
		if (!this._selected)
		{
			this.setBackGroundImage("PiktomirK/select-func-border.png");
			this.setBackGroundImageScale9Enabled(true);
			this.setCascadeOpacityEnabled(false);
			this.setBackGroundImageCapInsets(cc.rect(15, 15, 10, 10));
			this._selected = true;
			this.setTag(ProgramContainerLayer.SELECTED_TAG);
		}
	},

	set: function (treeNode)
	{
		this._treeNode = treeNode;
		return this;
	},

	calculateSize: function (isPar)
	{
		if(this._dotSprite)
			pm.syntaxModules.resizeBodyNodeDotSprite(this);
		else
			this.sizeOfVContainer(this);

		if (!isPar)
			this._parentNode.calculateSize();
	},

	_removeNode: function (node)
	{
		if (this._treeNode.getChildren().length > 1)
		{
			var prevNode = this.getChildByNext(node);
			if (prevNode !== null)
			{
				prevNode._nextNode = node._nextNode;
				var alignPREV = prevNode.getLayoutParameter();
				alignPREV.setRelativeToWidgetName(node._nextNode._id);
			}
			if (this.getChildren().indexOf(node) !== -1)
				this.removeChild(node);
		}
		else if(this._treeNode.getChildren().length === 1)
		{
			this.removeChild(node);
			var treeNode = pm.data.SyntaxTree.createNode(this._treeNode, pm.data.Node.Type.Empty, "", node._treeNode);
			pm.sendCustomEvent(pm.PARSE_NODE, {node: treeNode, parent: this});
		}
		if (node._treeNode)
		{
			var parent = this._treeNode;
			parent.removeChild(node._treeNode);
		}
		//this.forceDoLayout();
		return prevNode;
	},

	getNodeId: function ()
	{
		return this._parentNode._id + "_body";
	},

	getChildrenNodes: function ()
	{
		return this.getChildren();
	},

	addChildNode: function (node, selectedNode)
	{
		if (!selectedNode) // add last child
		{
			selectedNode = this.getChildren()[this.getChildrenCount() - 1];
		}
		var prevNode = this.getChildByNext(selectedNode);
		var nextNodeId = selectedNode._id;
		node._nextNode = selectedNode;

		this.createALignSome(node, nextNodeId, node._id, ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN, 0, pmui.NodeLayer.SEPARATORY, pmui.NodeLayer.SEPARATORX, 0);
		this.addChild(node);

		if (prevNode !== null)
		{
			prevNode._nextNode = node;
			var alignPREV = prevNode.getLayoutParameter();
			alignPREV.setRelativeToWidgetName(node._id);
		}
		return node;
	},

	getChildByNext: function (next)
	{
		for (var i = 0; i < this.getChildrenCount(); i++)
		{
			if (this.getChildren()[i]._nextNode === next)
			{
				return this.getChildren()[i];
			}
		}
		return null;
	},

	getFirstChild: function ()
	{
		for (var i = 0; i < this.getChildrenCount(); i++)
		{
			if (this.getChildren()[i].getLayoutParameter().getAlign() === ccui.RelativeLayoutParameter.PARENT_TOP_LEFT)
			{
				return this.getChildren()[i];
			}
		}
		return null;
	},

	isBody: function ()
	{
		return true;
	},

	select: function (node)
	{
	},

	deSelect: function (node)
	{
	},

	highlight: function()
	{
		this._parentNode.highlight();
	},

	removeHighlight: function()
	{
		this._parentNode.removeHighlight();
	}
});

pmui.BodyNode.FIRST_HEIGHT = 20;
pmui.BodyNode.FIRST_WIDTH = 60;

