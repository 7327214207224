/**
 * Created by Kirill Mashchenko on 22.07.19.
 */

/**
 * @class 2D robot for {@link pm.data.AquariusLevel}.</br>
 * Has a paint element native function.
 * @implements PlayerRobot4
 */
pm.data.AquariusRobot = pm.PlayerRobot2D.extend(/** @lends pm.data.AquariusRobot# */{
	typeName: "AquariusRobot",

	ctor: function()
	{
		this._super();

		this.nativeFunctionMap[pm.data.AquariusRobot.NativeMethod.AToB] = new pm.NativeFunction(this, this._AToB);
		this.nativeFunctionMap[pm.data.AquariusRobot.NativeMethod.BToA] = new pm.NativeFunction(this, this._BToA);

		this.nativeFunctionMap[pm.data.AquariusRobot.NativeMethod.BToC] = new pm.NativeFunction(this, this._BToC);
		this.nativeFunctionMap[pm.data.AquariusRobot.NativeMethod.CToB] = new pm.NativeFunction(this, this._CToB);

		this.nativeFunctionMap[pm.data.AquariusRobot.NativeMethod.AToC] = new pm.NativeFunction(this, this._AToC);
		this.nativeFunctionMap[pm.data.AquariusRobot.NativeMethod.CToA] = new pm.NativeFunction(this, this._CToA);

		this.nativeFunctionMap[pm.data.AquariusRobot.NativeMethod.FullA] = new pm.NativeFunction(this, this._fullA);
		this.nativeFunctionMap[pm.data.AquariusRobot.NativeMethod.EmptyA] = new pm.NativeFunction(this, this._emptyA);

		this.nativeFunctionMap[pm.data.AquariusRobot.NativeMethod.FullB] = new pm.NativeFunction(this, this._fullB);
		this.nativeFunctionMap[pm.data.AquariusRobot.NativeMethod.EmptyB] = new pm.NativeFunction(this, this._emptyB);

		this.nativeFunctionMap[pm.data.AquariusRobot.NativeMethod.FullC] = new pm.NativeFunction(this, this._fullC);
		this.nativeFunctionMap[pm.data.AquariusRobot.NativeMethod.EmptyC] = new pm.NativeFunction(this, this._emptyC);

		this.nativeFunctions.push(pm.data.AquariusRobot.NativeMethod.AToB, pm.data.AquariusRobot.NativeMethod.BToC, pm.data.AquariusRobot.NativeMethod.AToC,
			pm.data.AquariusRobot.NativeMethod.FullA, pm.data.AquariusRobot.NativeMethod.FullB, pm.data.AquariusRobot.NativeMethod.FullC,
			pm.data.AquariusRobot.NativeMethod.BToA, pm.data.AquariusRobot.NativeMethod.CToB, pm.data.AquariusRobot.NativeMethod.CToA,
			pm.data.AquariusRobot.NativeMethod.EmptyA, pm.data.AquariusRobot.NativeMethod.EmptyB, pm.data.AquariusRobot.NativeMethod.EmptyC);
	},

	updateNativeFunctions: function(map)
	{
		if (map === undefined)
			map = this.parentLevel.activeMap;

		this.nativeFunctions = [];
		this.nativeFunctions.push(pm.data.AquariusRobot.NativeMethod.AToB, pm.data.AquariusRobot.NativeMethod.BToC, pm.data.AquariusRobot.NativeMethod.AToC,
			pm.data.AquariusRobot.NativeMethod.FullA, pm.data.AquariusRobot.NativeMethod.FullB, pm.data.AquariusRobot.NativeMethod.FullC,
			pm.data.AquariusRobot.NativeMethod.BToA, pm.data.AquariusRobot.NativeMethod.CToB, pm.data.AquariusRobot.NativeMethod.CToA,
			pm.data.AquariusRobot.NativeMethod.EmptyA, pm.data.AquariusRobot.NativeMethod.EmptyB, pm.data.AquariusRobot.NativeMethod.EmptyC);

		if (map.tankA.availableCapacity < 2)
		{
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.AToB);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.BToA);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.AToC);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.CToA);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.FullA);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.EmptyA);
		}

		if (map.tankB.availableCapacity < 2)
		{
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.BToA);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.AToB);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.BToC);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.CToB);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.FullB);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.EmptyB);
		}

		if (map.tankC.availableCapacity < 2)
		{
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.CToA);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.AToC);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.CToB);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.BToC);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.FullC);
			this._deleteNativeFunction(pm.data.AquariusRobot.NativeMethod.EmptyC);
		}

		if(!CORE_BUILD)
			pm.sendCustomEvent(pm.COMMANDS_CHANGED, this.parentLevel);
	},

	_deleteNativeFunction: function(functionID)
	{
		var index = this.nativeFunctions.indexOf(functionID);
		if (index !== -1)
			this.nativeFunctions.splice(index, 1);
	},

	_tank1ToTank2: function(tank1, tank2)
	{
		var currentCapacity1 = tank1.currentCapacity;
		var currentCapacity2 = tank2.currentCapacity;
		var availableCapacity2 = tank2.availableCapacity;

		var dif = 0;

		if (currentCapacity1 + currentCapacity2 > availableCapacity2)
		{
			dif = availableCapacity2 - currentCapacity2;
			currentCapacity1 -= dif;
			currentCapacity2 += dif;
		}
		else
		{
			currentCapacity2 += currentCapacity1;
			currentCapacity1 = 0;
		}

		tank1.setCurrentCapacity(currentCapacity1);
		tank2.setCurrentCapacity(currentCapacity2);
	},

	_fullTank: function(tank)
	{
		tank.setCurrentCapacity(tank.availableCapacity);
	},

	_emptyTank: function(tank)
	{
		tank.setCurrentCapacity(0);
	},

	_AToB: function()
	{
		this._tank1ToTank2(this.parentLevel.activeMap.tankA, this.parentLevel.activeMap.tankB)
	},

	_BToA: function()
	{
		this._tank1ToTank2(this.parentLevel.activeMap.tankB, this.parentLevel.activeMap.tankA)
	},

	_BToC: function()
	{
		this._tank1ToTank2(this.parentLevel.activeMap.tankB, this.parentLevel.activeMap.tankC)
	},

	_CToB: function()
	{
		this._tank1ToTank2(this.parentLevel.activeMap.tankC, this.parentLevel.activeMap.tankB)
	},

	_AToC: function()
	{
		this._tank1ToTank2(this.parentLevel.activeMap.tankA, this.parentLevel.activeMap.tankC)
	},

	_CToA: function()
	{
		this._tank1ToTank2(this.parentLevel.activeMap.tankC, this.parentLevel.activeMap.tankA)
	},

	_fullA: function()
	{
		this._fullTank(this.parentLevel.activeMap.tankA);
	},

	_emptyA: function()
	{
		this._emptyTank(this.parentLevel.activeMap.tankA);
	},

	_fullB: function()
	{
		this._fullTank(this.parentLevel.activeMap.tankB);
	},

	_emptyB: function()
	{
		this._emptyTank(this.parentLevel.activeMap.tankB);
	},

	_fullC: function()
	{
		this._fullTank(this.parentLevel.activeMap.tankC);
	},

	_emptyC: function()
	{
		this._emptyTank(this.parentLevel.activeMap.tankC);
	},

	needToPlayAnimation: function()
	{
		return false;
	},

	getState: function ()
	{
		return {
			capacityA: this.parentLevel.activeMap.tankA.currentCapacity,
			capacityB: this.parentLevel.activeMap.tankB.currentCapacity,
			capacityC: this.parentLevel.activeMap.tankC.currentCapacity
		};
	},

	setState: function (state)
	{
		this.parentLevel.activeMap.tankA.setCurrentCapacity(state.capacityA);
		this.parentLevel.activeMap.tankB.setCurrentCapacity(state.capacityB);
		this.parentLevel.activeMap.tankC.setCurrentCapacity(state.capacityC);
	},

	getType: function() { return pm.AquariusLevelModule.RobotType; }
});

pm.data.AquariusRobot.NativeMethod =
{
	AToB: "aquarius_AToB",
	BToC: "aquarius_BToC",
	AToC: "aquarius_AToC",
	FullA: "aquarius_fullA",
	FullB: "aquarius_fullB",
	FullC: "aquarius_fullC",
	BToA: "aquarius_BToA",
	CToB: "aquarius_CToB",
	CToA: "aquarius_CToA",
	EmptyA: "aquarius_emptyA",
	EmptyB: "aquarius_emptyB",
	EmptyC: "aquarius_emptyC"
};
