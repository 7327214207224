/**
 * Created by Diana Agliamutdinova on 01.05.2019.
 */

/**
 * @class Represents program of one robot.
 */

var PiktoTreeCreator = ProgramTreeCreator.extend({

	_visualTree: null,

	ctor: function (programTree, robot, programContainer)
	{
		this._visualTree = new PiktoTree(
			programTree,
			robot,
			programContainer);
	},

	reset: function ()
	{
		ProgramTreeCreator.prototype.reset.call(this);

		this.reloadRepeaters();
	}
});
