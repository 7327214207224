/**
 * Created by Kirill Mashchenko on 22.07.19.
 */

var AquariusTankSprite = ccui.Layout.extend(/** @lends pm.Tank# */{

	_innerColumn: null,
	_extraColumn: null,

	_frontLayout: null,
	_topWater: null,
	_bottomWater: null,

	_scaleLayer: null,

	_frontFloat: null,
	_backFloat: null,

	_currentArrow: null,
	_currentCircle: null,

	_currentLabel: null,
	_currentAlign: null,
	_availableLabel: null,
	_availableAlign: null,

	ctor: function(name, availableCapacity, currentCapacity)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);
		this.setCascadeOpacityEnabled(true);
		this.setBackGroundImageScale9Enabled(true);
		this.setBackGroundImage("ATS_{0}_Back.png".format(name), ccui.Widget.PLIST_TEXTURE);
		this.setBackGroundImageCapInsets(cc.rect(35, 75, 40, 150));
		this.setContentSize(AquariusTankSprite.INNER_WIDTH, availableCapacity * AquariusTankSprite.UNIT_HEIGHT + AquariusTankSprite.BACK_ADDITION);

		if (!availableCapacity || availableCapacity < 2)
			this.setVisible(false);

		this._frontLayout = new ccui.Layout();
		this._frontLayout.setCascadeOpacityEnabled(true);
		this._frontLayout.setBackGroundImageScale9Enabled(true);
		this._frontLayout.setBackGroundImage("ATS_{0}_Front.png".format(name), ccui.Widget.PLIST_TEXTURE);
		this._frontLayout.setBackGroundImageCapInsets(cc.rect(35, 35, 40, 165));
		this._frontLayout.setContentSize(AquariusTankSprite.INNER_WIDTH, availableCapacity * AquariusTankSprite.UNIT_HEIGHT + AquariusTankSprite.FRONT_ADDITION);

		var frontAlign = new ccui.RelativeLayoutParameter();
		frontAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
		frontAlign.setMargin(0, 0, 0, -AquariusTankSprite.FRONT_SHIFT);
		this._frontLayout.setLayoutParameter(frontAlign);

		this.addChild(this._frontLayout, 2);

		this._topWater = new cc.Sprite(cc.spriteFrameCache.getSpriteFrame("ATS_Top.png"));

		this._topWater.setAnchorPoint(cc.p(0.5, 0.5));
		this._topWater.setPosition(this.width / 2, currentCapacity * AquariusTankSprite.UNIT_HEIGHT + AquariusTankSprite.TOP_MARGIN);

		this._topWater.setVisible(currentCapacity);

		this.addChild(this._topWater, 1);

		this._bottomWater = new cc.Sprite(cc.spriteFrameCache.getSpriteFrame("ATS_Bottom.png"));

		this._bottomWater.setAnchorPoint(cc.p(0.5, 0));
		this._bottomWater.setPosition(this.width / 2, AquariusTankSprite.BOTTOM_MARGIN);

		this._bottomWater.setVisible(currentCapacity);

		this.addChild(this._bottomWater);

		var nameLabel = new ccui.Text(name, pm.settings.fontName, pm.settings.fontSize);
		nameLabel.setColor(this.getNameColor(name));

		var nameAlign = new ccui.RelativeLayoutParameter();
		nameAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
		nameAlign.setMargin(0, 0, 0, -AquariusTankSprite.NAME_SHIFT);
		nameLabel.setLayoutParameter(nameAlign);

		this.addChild(nameLabel, 3);

		if (currentCapacity === availableCapacity)
		{
			var frontFloatName = "ATS_Top_Float_Front.png";
			var backFloatName = "ATS_Top_Float_Back.png";
		}
		else
		{
			var frontFloatName = "ATS_Float_Front.png";
			var backFloatName = "ATS_Float_Back.png";
		}

		this._frontFloat = new cc.Sprite(cc.spriteFrameCache.getSpriteFrame(frontFloatName));

		this._frontFloat.setAnchorPoint(cc.p(0.5, 1));
		this._frontFloat.setPosition(this.width / 2, currentCapacity * AquariusTankSprite.UNIT_HEIGHT + AquariusTankSprite.FLOAT_MARGIN);

		this.addChild(this._frontFloat, 5);

		this._backFloat = new cc.Sprite(cc.spriteFrameCache.getSpriteFrame(backFloatName));

		this._backFloat.setAnchorPoint(cc.p(0.5, 0));
		this._backFloat.setPosition(this.width / 2, currentCapacity * AquariusTankSprite.UNIT_HEIGHT + AquariusTankSprite.FLOAT_MARGIN);

		if (currentCapacity === availableCapacity)
			this.addChild(this._backFloat, 5);
		else
			this.addChild(this._backFloat, -1);

		this._currentArrow = new cc.Sprite(cc.spriteFrameCache.getSpriteFrame("ATS_Arrow.png"));

		this._currentArrow.setAnchorPoint(cc.p(0, 0.5));
		this._currentArrow.setPosition(this.width, currentCapacity * AquariusTankSprite.UNIT_HEIGHT + AquariusTankSprite.FLOAT_MARGIN);

		this.addChild(this._currentArrow);

		this._currentCircle = new cc.Sprite(cc.spriteFrameCache.getSpriteFrame("ATS_Circle.png"));

		this._currentCircle.setAnchorPoint(cc.p(0, 0.5));
		this._currentCircle.setPosition(this.width + AquariusTankSprite.CIRCLE_SEPARATOR, currentCapacity * AquariusTankSprite.UNIT_HEIGHT + AquariusTankSprite.FLOAT_MARGIN);

		this.addChild(this._currentCircle);

		this._currentLabel = new ccui.Text(currentCapacity, pm.settings.fontName, pm.settings.fontSize);

		this._currentLabel.setColor(this.getLabelColor(AquariusTankSprite.TEXT_COLOR));
		this._currentLabel.setAnchorPoint(cc.p(0.5, 0.6));
		this._currentLabel.setPosition(this._currentCircle.width / 2, this._currentCircle.height / 2);

		this._currentCircle.addChild(this._currentLabel);

		this._availableLabel = new ccui.Text(availableCapacity, pm.settings.fontName, pm.settings.fontSize);
		this._availableLabel.setColor(this.getLabelColor(AquariusTankSprite.TEXT_COLOR));

		this._availableAlign = new ccui.RelativeLayoutParameter();
		this._availableAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		this._availableAlign.setMargin(0, AquariusTankSprite.LABEL_MARGIN, 0, 0);
		this._availableLabel.setLayoutParameter(this._availableAlign);

		this.addChild(this._availableLabel);

		this._innerColumn = new ccui.Layout();

		this._innerColumn.setBackGroundColorType(ccui.Layout.BG_COLOR_SOLID);
		this._innerColumn.setBackGroundColor(cc.color.BLUE);
		this._innerColumn.setContentSize(AquariusTankSprite.WATER_WIDTH, AquariusTankSprite.UNIT_HEIGHT);
		this._innerColumn.setOpacity(200);
		this._innerColumn.setAnchorPoint(cc.p(0.5, 0));

		this._innerColumn.setScale(1, currentCapacity);

		var innerAlign = new ccui.RelativeLayoutParameter();
		innerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
		innerAlign.setMargin(0, 0, 0, AquariusTankSprite.INNER_MARGIN);
		this._innerColumn.setLayoutParameter(innerAlign);

		this.addChild(this._innerColumn);

		this._extraColumn = new ccui.Layout();

		this._extraColumn.setBackGroundColorType(ccui.Layout.BG_COLOR_SOLID);
		this._extraColumn.setBackGroundColor(cc.color.BLUE);
		this._extraColumn.setContentSize(AquariusTankSprite.WATER_WIDTH, AquariusTankSprite.EXTRA_HEIGHT);
		this._extraColumn.setOpacity(200);
		this._extraColumn.setAnchorPoint(cc.p(0.5, 0));

		this._extraColumn.setVisible(currentCapacity);

		var extraAlign = new ccui.RelativeLayoutParameter();
		extraAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
		this._extraColumn.setLayoutParameter(extraAlign);

		this.addChild(this._extraColumn);

		this._scaleLayer = new ccui.Layout();

		var scaleAlign = new ccui.RelativeLayoutParameter();
		scaleAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
		scaleAlign.setMargin(0, 0, 9, AquariusTankSprite.SCALE_MARGIN);
		this._scaleLayer.setLayoutParameter(scaleAlign);

		this.addChild(this._scaleLayer, 4);

		this._updateScaleLayer(availableCapacity);
	},

	_updateScaleLayer: function(availableCapacity)
	{
		this._scaleLayer.removeAllChildren();
		this._scaleLayer.setContentSize(AquariusTankSprite.INNER_WIDTH, availableCapacity * AquariusTankSprite.UNIT_HEIGHT);

		for (var i = 1; i < availableCapacity; ++i)
		{
			var spriteName = i % AquariusTankSprite.SCALE_STEP === 0 ? "ATS_Full_Scale.png" : "ATS_Scale.png";

			var mark = new cc.Sprite(cc.spriteFrameCache.getSpriteFrame(spriteName));

			mark.setAnchorPoint(cc.p(1, 0.5));
			mark.setPosition(this._scaleLayer.width, i * AquariusTankSprite.UNIT_HEIGHT);
			this._scaleLayer.addChild(mark);
		}
	},

	setAvailableCapacity: function(capacity)
	{
		if (capacity && capacity > 1)
		{
			this.setVisible(true);

			this.setContentSize(AquariusTankSprite.INNER_WIDTH, capacity * AquariusTankSprite.UNIT_HEIGHT + AquariusTankSprite.BACK_ADDITION);

			this._frontLayout.setContentSize(AquariusTankSprite.INNER_WIDTH, capacity * AquariusTankSprite.UNIT_HEIGHT + AquariusTankSprite.FRONT_ADDITION);

			this._updateScaleLayer(capacity);

			this._availableLabel.setString(capacity);

			this.forceDoLayout();
		}
		else
		{
			this.setVisible(false);
		}
	},

	setCurrentCapacity: function(capacity, callback, target)
	{
		if (callback === undefined && target === undefined)
		{
			if (capacity === Number(this._availableLabel.getString()))
			{
				this._frontFloat.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame("ATS_Top_Float_Front.png"));
				this._backFloat.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame("ATS_Top_Float_Back.png"));
				this._backFloat.setLocalZOrder(5);
			}
			else
			{
				this._frontFloat.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame("ATS_Float_Front.png"));
				this._backFloat.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame("ATS_Float_Back.png"));
				this._backFloat.setLocalZOrder(-1);
			}

			var posY = capacity * AquariusTankSprite.UNIT_HEIGHT;

			this._innerColumn.setScale(1, capacity);
			this._topWater.setPosition(this.width / 2, posY + AquariusTankSprite.TOP_MARGIN);
			this._frontFloat.setPosition(this.width / 2, posY + AquariusTankSprite.FLOAT_MARGIN);
			this._backFloat.setPosition(this.width / 2, posY + AquariusTankSprite.FLOAT_MARGIN);
			this._currentArrow.setPosition(this.width, posY + AquariusTankSprite.FLOAT_MARGIN);
			this._currentCircle.setPosition(this.width + AquariusTankSprite.CIRCLE_SEPARATOR, posY + AquariusTankSprite.FLOAT_MARGIN);

			this._currentLabel.setString(capacity);
			this._topWater.setVisible(capacity);
			this._bottomWater.setVisible(capacity);
			this._extraColumn.setVisible(capacity);
		}
		else
		{
			if (capacity)
			{
				this._topWater.setVisible(capacity);
				this._bottomWater.setVisible(capacity);
				this._extraColumn.setVisible(capacity);
			}

			if (Number(this._currentLabel.getString()) === Number(this._availableLabel.getString())
				&& capacity !== Number(this._currentLabel.getString()))
			{
				this._frontFloat.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame("ATS_Float_Front.png"));
				this._backFloat.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame("ATS_Float_Back.png"));
				this._backFloat.setLocalZOrder(-1);
			}

			var animationSpeed = pm.settings.getAnimationSpeed() * Math.abs(capacity - Number(this._currentLabel.getString()));
			var moveToY = capacity * AquariusTankSprite.UNIT_HEIGHT;

			var scaleLayer = cc.scaleTo(animationSpeed, 1, capacity);
			var moveWater = cc.moveTo(animationSpeed, this.width / 2, moveToY + AquariusTankSprite.TOP_MARGIN);
			var moveFloat = cc.moveTo(animationSpeed, this.width / 2, moveToY + AquariusTankSprite.FLOAT_MARGIN);
			var moveArrow = cc.moveTo(animationSpeed, this.width, moveToY + AquariusTankSprite.FLOAT_MARGIN);
			var moveCircle = cc.moveTo(animationSpeed, this.width + AquariusTankSprite.CIRCLE_SEPARATOR, moveToY + AquariusTankSprite.FLOAT_MARGIN);

			var scaleTargeted = cc.targetedAction(this._innerColumn, scaleLayer);
			var moveTargeted = cc.targetedAction(this._topWater, moveWater);
			var moveFrontTargeted = cc.targetedAction(this._frontFloat, moveFloat);
			var moveBackTargeted = cc.targetedAction(this._backFloat, moveFloat.clone());
			var moveArrowTargeted = cc.targetedAction(this._currentArrow, moveArrow);
			var moveCircleTargeted = cc.targetedAction(this._currentCircle, moveCircle);

			var changingEnd = cc.callFunc(function()
			{
				this._currentLabel.setString(capacity);

				if (!capacity)
				{
					this._topWater.setVisible(capacity);
					this._bottomWater.setVisible(capacity);
					this._extraColumn.setVisible(capacity);
				}
				callback.call(target);
			}, this);

			if (capacity === Number(this._availableLabel.getString()))
			{
				var changingTexture = cc.callFunc(function()
				{
					this._frontFloat.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame("ATS_Top_Float_Front.png"));
					this._backFloat.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame("ATS_Top_Float_Back.png"));
					this._backFloat.setLocalZOrder(5);
				}, this);

				changingEnd = cc.sequence(changingTexture, changingEnd);
			}

			this.runAction(cc.sequence(cc.spawn(scaleTargeted, moveTargeted, moveFrontTargeted, moveBackTargeted, moveArrowTargeted, moveCircleTargeted), changingEnd));
		}

		this.forceDoLayout();
	},

	getNameColor: function(name)
	{
		switch(name)
		{
			case "A": return this.getLabelColor(cc.color(239, 91, 48));
			case "B": return this.getLabelColor(cc.color(35, 88, 167));
			case "C": return this.getLabelColor(cc.color(29, 155, 82));
		}
	},

	getLabelColor: function(color)
	{
		var shift = 0;

		if (cc.sys.isNative)
			shift = AquariusTankSprite.COLOR_DIFF;

		return cc.color(color.r - shift, color.g - shift, color.b - shift);
	}
});

AquariusTankSprite.SEPARATOR = 15;
AquariusTankSprite.UNIT_HEIGHT = 25;
AquariusTankSprite.WATER_WIDTH = 70;
AquariusTankSprite.LABEL_MARGIN = 7;
AquariusTankSprite.BACK_ADDITION = 80;
AquariusTankSprite.FRONT_ADDITION = 57;
AquariusTankSprite.FRONT_SHIFT = 35;
AquariusTankSprite.NAME_SHIFT = 26;
AquariusTankSprite.SCALE_STEP = 5;
AquariusTankSprite.FLOAT_MARGIN = 20;
AquariusTankSprite.CIRCLE_SEPARATOR = 10;
AquariusTankSprite.TEXT_COLOR = cc.color(150, 150, 150);
AquariusTankSprite.COLOR_DIFF = 25;
AquariusTankSprite.INNER_WIDTH = 109;
AquariusTankSprite.INNER_MARGIN = 20;
AquariusTankSprite.BOTTOM_MARGIN = 4;
AquariusTankSprite.TOP_MARGIN = 20;
AquariusTankSprite.SCALE_MARGIN = 10;
AquariusTankSprite.EXTRA_HEIGHT = 20;
