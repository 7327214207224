/**
 * Created by Kirill Mashchenko on 22.07.19.
 */

var AquariusMapEditorLayer = AquariusMapLayer.extend(SquarableMapEditorLayerHelper).extend({

	drawMap: function(previewDraw)
	{
		AquariusMapLayer.prototype.drawMap.call(this, previewDraw);
	}

});
