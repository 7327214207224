/**
 * Created by Kirill Mashchenko on 30.08.18.
 */

/**
 * Button with selection
 * @class
 * @extends pmui.Button
 * @constructor
 * @param {String} normalImage
 * @param {String} [selectedImage=""]
 * @param {String} [disableImage=""]
 * @param {Number} [texType=ccui.Widget.LOCAL_TEXTURE]
 */
pmui.SelectionButton = pmui.Button.extend(/** @lends pmui.Button# */{

	_locked: false,
	_originalState: true,

	_normalImage: null,
	_selectedImage: null,
	_texType: null,
	selected: false,

	ctor: function(normalImage, selectedImage, disableImage, texType)
	{
		this._super(normalImage, selectedImage, disableImage, texType);

		this._normalImage = normalImage;
		this._selectedImage = selectedImage;
		this._texType = texType;
	},

	setSelectedTexture: function()
	{
		this.loadTextureNormal(this._selectedImage, this._texType);
		this.selected = true;
	},

	setNormalTexture: function()
	{
		this.loadTextureNormal(this._normalImage, this._texType);
		this.selected = false;
	}
});
