/**
 * Created by Kirill Mashchenko on 07.07.2018.
 */

var TrainMapEditorLayer = TrainMapLayer.extend(SquarableMapEditorLayerHelper).extend({

	createMapElement: function ()
	{
		var el = null;
		el = new pm.data.TrainMapElement();
		el.originalType = TrainMapElementType.Grass;
		el.initElement();
		return el;
	},

	moveElementsUp: function ()
	{
		for (var j = this._map.height - 2; j >= 0; --j)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i, j + 1)).setType(elementType);
				this._map.element(cc.p(i, j + 1)).originalType = elementType;

				var type = TrainMapElementType.Grass;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	},

	moveElementsDown: function ()
	{
		for (var j = 1; j < this._map.height; ++j)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i, j - 1)).setType(elementType);
				this._map.element(cc.p(i, j - 1)).originalType = elementType;

				var type = TrainMapElementType.Grass;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;

			}
		}
	},

	moveElementsLeft: function ()
	{
		for (var i = this._map.width - 2; i >= 0; --i)
		{
			for (var j = 0; j < this._map.height; ++j)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i + 1, j)).setType(elementType);
				this._map.element(cc.p(i + 1, j)).originalType = elementType;

				var type = TrainMapElementType.Grass;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	},

	moveElementsRight: function ()
	{
		for (var i = 1; i < this._map.width; ++i)
		{
			for (var j = 0; j < this._map.height; ++j)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i - 1, j)).setType(elementType);
				this._map.element(cc.p(i - 1, j)).originalType = elementType;

				var type = TrainMapElementType.Grass;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	},

	_addObject: function (pos, object)
	{
		object.visualId = this._map.objects.length+1;

		SquarableMapEditorLayerHelper._addObject.call(this, pos, object);
	},

	_removeObject: function (object)
	{
		SquarableMapEditorLayerHelper._removeObject.call(this, object);

		for (var i = this._dragedObject; i < this._map.parentLevel.activeMap.objects.length; ++i)
			this._map.objects[i].visualId--;

		for (i = 0; i < this._map.parentLevel.activeMap.objects.length; ++i)
			this._map.objects[i].updateNumberSprite()
	}

});
