/**
 * This enum contains all available states of robot.
 * @see {pm.AbstractRobot#_state}
 * @readonly
 * @enum {Number}
 */
pm.RobotState = {
	NoState: 0,
	EndedWork: 1,
	PlayingAnimation: 2,
	Broken: 3
};

/**
 * @class This is a common interface for all robots.
 * @interface
 * @extends pm.Class
 */
pm.AbstractRobot = pm.Class.extend( /** @lends pm.AbstractRobot# */{
	/**
	 * Parent level of robot.
	 * @type {pm.AbstractLevel}
	 */
	parentLevel: {},
	/**
	 * Unique id of robot.
	 * @type {Number}
	 */
	id: -1,
	/**
	 * Step count made by robot.
	 * @type {Number}
	 */
	stepCount: 0,

	/**
	 * Current state of robot
	 * @type {Number}
	 * @private
	 */
	_state: 0,

	/**
     * Clone group id of robot.
     * @type {Number}
     */
	groupID: -1,

	/**
	 * Parent robot reference.
	 * @type {pm.AbstractRobot}
	 */
	parentRobot: {},
	/**
	 * ID of child robot.</br>
	 * Robot can execute methods of child robot.
	 * @type {Number}
	 */
	childRobotID: -1,
	/**
	 * Child robot reference.
	 * @see {pm.AbstractRobot#childRobotID}
	 * @type {pm.AbstractRobot}
	 */
	childRobot: {},
	/**
	 * Array of function ID's which robot can execute.
	 * @type {Array<String>}
	 */
	nativeFunctions: [],
	/**
	 * Map of native functionID-NativeMethod
	 * @type {Object}
	 */
	nativeFunctionMap: {},
	/**
	 * Array of repeater represented by robot.
	 * @type {Array<Number|String>}
	 */
	repeaters: [],
	/**
	 * Array of conditions represented by robot.
	 * @type {Array<String>}
	 */
	conditions: [],
	/**
     * Array of condition opposites represented by robot.
     * @type {Array<String>}
     */
	conditionOpposites: [],

	_lastAnimation: null,

	ctor: function()
	{
		this._addNonEnumerableProps("parentLevel", "stepCount", "_state", "parentRobot",
			"childRobot", "nativeFunctions", "nativeFunctionMap",
			"repeaters", "conditions", "conditionOpposites", "programDataCache", "_lastAnimation");
		this._lastAnimation = null;
		this._super();
	},

	/**
	 * Returns program data of robot.
	 * @returns {?pm.data.ProgramData}
     */
	getProgramData: function()
	{
		if(!this.parentLevel)
			return null;

		if(CORE_BUILD)
		{
			if(!this.parentLevel.programDataCache[this.groupID])
				return this.parentLevel.programData[this.groupID].getCurrentProgramData();

			return this.parentLevel.programDataCache[this.groupID].getCurrentProgramData();
		}
		else if (!pm.settings.isEditorMode)
		{
			if (!this.parentLevel.programDataCache[this.groupID] ||
				!(this.parentLevel.programDataCache[this.groupID] instanceof pm.data.ProgramInfo))
			{
				var originalProgram = this.parentLevel.programData[this.groupID];
				var newCache = new pm.data.ProgramInfo();

				newCache.currentIndex = originalProgram.currentIndex;
				newCache.programDataArray = cc.clone(originalProgram.programDataArray);

				this.parentLevel.programDataCache[this.groupID] = newCache;
			}

			return this.parentLevel.programDataCache[this.groupID].getCurrentProgramData();
		}

		return this.parentLevel.programData[this.groupID].getCurrentProgramData();
	},

	/**
	 * Returns if symbol in program data can be edited
	 * @returns {Boolean}
     */
	canEditProgramSymbol: function(pos)
	{
		if (this.parentLevel.isProgramDataEditable)
		{
			return true;
		}
		else
		{
			var currentIndex;

			if (this.parentLevel.programDataCache[this.groupID])
				currentIndex = this.parentLevel.programDataCache[this.groupID].currentIndex;
			else
				currentIndex = this.parentLevel.programData[this.groupID].currentIndex;

			if (pos !== null)
				var symbol = this.parentLevel.programData[this.groupID].getProgramDataByIndex(currentIndex).symbols[pos.x][pos.y];

			return pm.settings.isEditorMode || pos !== null &&
				(symbol.type === pm.SymbolType.Function
				|| symbol.type === pm.SymbolType.Method && symbol.value === pm.EMPTY_METHOD
				|| symbol.type === pm.SymbolType.Condition && symbol.value === pm.CONDITION_EMPTY
				|| symbol.type === pm.SymbolType.Repeater && symbol.value === pm.REPEATER_EMPTY
				|| symbol.type === pm.SymbolType.CondRepeater && symbol.value === pm.CONDITION_EMPTY);
		}
	},

	/**
     * Sets program data of robot.
     * @param {pm.data.ProgramData} programData
     */
	setProgramData: function(programData, index)
	{
		if(!CORE_BUILD && !pm.settings.isEditorMode)
		{
			if (!this.parentLevel.programDataCache[this.groupID])
				this.parentLevel.programDataCache[this.groupID] = new pm.data.ProgramInfo();

			this.parentLevel.programDataCache[this.groupID].currentIndex = index;
			this.parentLevel.programDataCache[this.groupID].programDataArray[index] = programData;
		}
	},

	changeProgramData: function(programData)
	{
		if(!CORE_BUILD)
		{
			var index;

			if(pm.settings.isEditorMode)
			{
				index = this.parentLevel.programData[this.groupID].currentIndex;
				this.parentLevel.programData[this.groupID].programDataArray[index] = programData;
			}
			else
			{
				index = this.parentLevel.programDataCache[this.groupID].currentIndex;
				this.parentLevel.programDataCache[this.groupID].programDataArray[index] = programData;
			}
		}
	},

	/**
	 * Sets state flag.
	 * @param {pm.RobotState} flag
	 */
	setStateFlag: function(flag)
	{
		this._state |= 1 << flag;
	},

	/**
	 * Clears state flag.
	 * @param {pm.RobotState} flag
	 */
	clearStateFlag: function(flag)
	{
		this._state &= ~(1 << flag);
	},

	/**
	 * Checks if robot state is equal to {@link pm.RobotState.EndedWork}.
	 * @returns {Number}
	 */
	isEndedWork: function()
	{
		return this._state & (1 << pm.RobotState.EndedWork);
	},

	/**
	 * Checks if robot state is equal to {@link pm.RobotState.PlayingAnimation}.
	 * @returns {Number}
	 */
	isPlayingAnimation: function()
	{
		var physicalConnector = this._getPhysicalConnector();
		return this._state & (1 << pm.RobotState.PlayingAnimation) ||
			physicalConnector && physicalConnector.isBusy();
	},
	/**
	 * Checks if robot state is equal to {@link pm.RobotState.Broken}.
	 * @returns {Number}
	 */
	isBroken: function()
	{
		return this._state & (1 << pm.RobotState.Broken);
	},

	/**
	 * Clears all state flags.
	 */
	clearAllStateFlags: function()
	{
		this._state = 0;
	},

	/**
     * Returns true if robot has native function with id.
     * @param {String} functionID
     * @returns {Boolean}
     */
	hasNativeFunction: function(functionID)
	{
		return !!this.nativeFunctionMap[functionID];
	},

	/**
     * Returns robot's or native function with id.</br>
     * If no function returns null.
     * @param {String} functionID
     * @returns {pm.NativeFunction}
     */
	findNativeFunction: function(functionID)
	{
		if(this.nativeFunctionMap[functionID])
			return this.nativeFunctionMap[functionID];

		return null;
	},

	/**
	 * Executes native function by function ID.
	 * @param {String} functionID
	 * @param {*} [args] Additional arguments of executed function.
	 * @param {Boolean} skipPhysicalCommand Need to skip physical command.

	 */
	executeNativeFunction: function(functionID, args, skipPhysicalCommand)
	{
		if (skipPhysicalCommand === undefined)
			skipPhysicalCommand = false;

		if(this.nativeFunctionMap[functionID])
		{
			var res ;
			var pConnectorState = this._getPhysicalConnectorState();

			this.incStepCount();
			res = this.nativeFunctionMap[functionID].execute(args);

			if(this.isBroken())
				return;

			if(!CORE_BUILD)
			{
				var physicalConnector = this._getPhysicalConnector();

				if (physicalConnector && physicalConnector.isConnected() && this._checkPhysicalCommand(functionID) && !skipPhysicalCommand)
					physicalConnector.executeRobotCommand(this.id, functionID, pConnectorState);
			}
			return res;
		}
	},

	_checkPhysicalCommand: function(command)
	{
		var availablePhysicalCommands = pm.moduleUtils.getAvailablePhysicalCommands(this.parentLevel.getType());

		var commandFound = false;

		for (var i = 0; i < availablePhysicalCommands.length; ++i)
		{
			if (command === availablePhysicalCommands[i])
			{
				commandFound = true;
				break;
			}
		}

		return commandFound;
	},

	/**
	 * Returns list of native function of child robot. If no child return null.
	 * @returns {?Array<String>}
	 */
	getChildFunctions: function()
	{
		if(this.childRobot instanceof pm.AbstractRobot)
			return this.childRobot.nativeFunctions;
		else
			return null;
	},
	/**
	 * Returns list of repeaters of child robot. If no child return null.
	 * @returns {?Array<String>}
	 */
	getChildRepeaters: function()
	{
		if(this.childRobot instanceof pm.AbstractRobot)
			return this.childRobot.repeaters;
		else
			return null;
	},
	/**
	 * Returns list of conditions of child robot. If no child return null.
	 * @returns {?Array<String>}
	 */
	getChildConditions: function()
	{
		if(this.childRobot instanceof pm.AbstractRobot)
			return this.childRobot.conditions;
		else
			return null;
	},
	/**
     * Returns list of condition opposites of child robot. If no child return null.
     * @returns {?Array<String>}
     */
	getChildConditionOpposites: function()
	{
		if(this.childRobot instanceof pm.AbstractRobot)
			return this.childRobot.conditionOpposites;
		else
			return null;
	},
	/**
	 * Returns map where robot is placed.
	 * @returns {pm.AbstractMap}
	 */
	getMap: function()
	{
		return this.parentLevel.activeMap;
	},

	/**
	 * Increments stepCount and calls stepCallback.
	 */
	incStepCount: function()
	{
		++this.stepCount;

		if(!CORE_BUILD)
			pm.sendCustomEvent(pm.ROBOT_STEP_COUNT_EVENT_STR, this.stepCount);
	},

	/**
	 * Sets step count.
	 * @param {Number} stepCount
	 */
	setStepCount: function(stepCount)
	{
		this.stepCount = stepCount;

		if(!CORE_BUILD)
			pm.sendCustomEvent(pm.ROBOT_STEP_COUNT_EVENT_STR, this.stepCount);
	},

	/**
	 * Checks condition for this robot.
	 * @param {String} condition
	 * @param {*} [args] Additional arguments of checking condition.
	 * @returns {Boolean}
	 */
	checkCondition: function(condition, args)
	{
		if(this.conditions.indexOf(condition) !== -1 || this.conditionOpposites.indexOf(condition) !== -1)
		{
			this.incStepCount();

			if(!CORE_BUILD)
			{
				var pConnectorState = this._getPhysicalConnectorState();

				var physicalConnector = this._getPhysicalConnector();

				if (physicalConnector && physicalConnector.isConnected() && this._checkPhysicalCommand(condition))
					physicalConnector.executeRobotCommand(this.id, condition, pConnectorState);
			}

			return this._checkConditionInternal(condition, args);
		}
		else
		{
			return false;
		}
	},

	/**
     * Internal checking of condition. Implement ind end-point class.
     * @private
	 * @param {String} condition
	 * @param {*} [args] Additional arguments of checking condition.
     * @returns {Boolean}
     */
	_checkConditionInternal: function(condition, args) { return false; },

	/**
     * Return repeater value from this of child robot if repeater not a number.
     * @param {String} repeater
	 * @param {*} [args] Additional arguments of getting repeater.
	 * @returns {Number}
     */
	getRepeaterValue: function(repeater, args)
	{
		if(this.repeaters.indexOf(repeater) !== -1)
			return this._getRepeaterValueInternal(repeater, args);
		else
			return 0;
	},

	/**
     * @param {String} repeater
	 * @param {*} [args] Additional arguments of getting repeater.
     * @returns {Number}
     * @private
     */
	_getRepeaterValueInternal: function(repeater, args) { return 0; },

	/**
     * Actions when robot win.
     * @function
     * @name pm.AbstractRobot#win
     */
	win: function() {},
	/**
     * Actions when robot loose.
     * @function
     * @name pm.AbstractRobot#destroy
     */
	destroy: function() {},
	/**
     * Interact function between robots.
     * @function
     * @name pm.AbstractRobot#interact
     */
	interact: function(robot, callback) {},
	/**
     * Return true if robots are interactable.
     * @function
     * @name pm.AbstractRobot#interactable
     * @return {Boolean}
     */
	interactable: function() { return false; },
	/**
     * Return true if robots can move on eac other..
     * @function
     * @name pm.AbstractRobot#canMoveOn
     * @return {Boolean}
     */
	canMoveOn: function() { return false; },
	/**
     * Resets robot to original state.
     * @function
     * @name pm.AbstractRobot#reset
     */
	reset: function()
	{
		this.setStepCount(0);
		this.clearAllStateFlags();
	},
	/**
     * Returns type of robot.
     * @function
     * @name pm.AbstractRobot#getType
     * @returns {*}
     */
	getType: function() { return "abstract_robot"; },

	/**
     * Return number of conditions.
     * @returns {Number}
     */
	getConditionCount: function() { return this.conditions.length; },
	/**
     * Return number of repeaters.
     * @returns {Number}
     */
	getRepeaterCount: function() { return this.repeaters.length; },
	/**
	 * Callback for state changes of physical robot
	 * @param {pm.PhysicalConnector.STATE} state
	 * @private
	 */
	_physicalStateChanged: function(state) {},

	getState: function () {},

	setState: function (state) {},

	/**
	 * Get parent level physical connector
	 * @returns {?pm.PhysicalConnector}
	 * @private
	 */
	_getPhysicalConnector: function()
	{
		if(this.parentLevel && this.parentLevel.hasPhysicalConnector())
			return this.parentLevel.getPhysicalConnector();

		return null;
	},

	/**
	 * Get state of robot for physical connector
	 * @returns {Object}
	 * @private
	 */
	_getPhysicalConnectorState: function() { return {}; },

	getLastAnimation: function ()
	{
		return this._lastAnimation;
	},

	getRobotMethodName: function (methodName)
	{
		return LocalizedString(methodName);
	}
});
