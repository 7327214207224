/**
 * Created by Kirill Mashchenko on 07.09.2018.
 */

var MovingAfterKeyboardLayout = pmui.OvalLayout.extend(/** @lends MovingAfterKeybordLayer# */{

	_attached: false,
	_attachedInput: null,

	ctor: function(size, borderRadius, type)
	{
		this._super(size, borderRadius, type);
	},

	setInputAttach: function(input)
	{
		if(cc.sys.isMobile)
			input.setAttachCallback(this._textInputAttach, this);
	},

	_textInputAttach: function(sender, attached)
	{
		if (this._attached === attached)
		{
			this._attachedInput = sender;
			return;
		}

		if (!attached && sender !== this._attachedInput)
			return;
		else if (!attached)
			this._attachedInput = null;
		else
			this._attachedInput = sender;

		this._attached = attached;

		if (attached)
			this.runAction(cc.moveBy(1 / 2 * pm.SYSTEM_ANIMATION_DELAY, 0, pm.settings.getScreenSize().height / 4));
		else
			this.runAction(cc.moveBy(1 / 2 * pm.SYSTEM_ANIMATION_DELAY, 0, -pm.settings.getScreenSize().height / 4));
	},

	closeAttachKeyboard: function()
	{
		if(this._attachedInput)
		{
			this._attachedInput.closeKeyboard();
			this._attachedInput = null;
		}
	}
});
