/**
 * Created by Ekaterina Ermishkina on 25.08.16.
 */

/**
 * Possible direction indexes on light  maps.
 * @see LightMap
 * @readonly
 * @enum {Number}
 */

var LightDirection = {
	Up: 4,
	UpLeft: 5,
	Left: 6,
	DownLeft: 7,
	Down: 0,
	DownRight: 1,
	Right: 2,
	UpRight: 3
};

/**
 * @class 2D map for LightLevel.
 * @implements SquarableMap
 */

pm.data.LightMap = SquarableMap.extend(/** @lends pm.data.LightMap# */{
	typeName: "LightMap",

	ctor: function()
	{
		this._super();
	},

	hasWalls: function() { return false; },

	getType: function() { return pm.LightLevelModule.Type; },

	generateMapLayer: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (!pm.settings.isEditorMode)
				this.mapLayer = new LightMapLayer(this);
			else
				this.mapLayer = new LightMapEditorLayer(this);

			this.mapLayer.drawMap(previewDraw);

			return this.mapLayer;
		}
	},

	setSize: function(width, height)
	{
		this.mapElements.length = 0;

		for(var i = 0; i < height; ++i)
		{
			this.mapElements.push([]);

			for(var j = 0; j < width; ++j)

				this.mapElements[i].push(new pm.data.LightMapElement());

		}

		this.width = width;
		this.height = height;
	},

	clean: function()
	{
		var level = this.parentLevel;
		level.robots[0].paths = [];

		for(var i = 0; i < this.height; ++i)
		{
			for(var j = 0; j < this.width; ++j)
			{
				var element = this.element(cc.p(j, i));
				element.setNotCompleted(false);

				if(this === level.activeMap)
				{
					var startForRobot = element.startForRobot;

					if (startForRobot !== pm.MapElement.START_FOR_NO_ROBOT)
					{
						level.robots[startForRobot].setPosition(cc.p(j, i), element.startRobotData.direction);
						level.robots[startForRobot].course = LightRobotCourse.None;
					}
				}
			}
		}

		if(this === level.activeMap)
		{
			for (var i = 0; i < this.objects.length; ++i)
			{
				if (this.objects[i].getType() === pm.LightLevelModule.Lamp)
					this.objects[i].restore();
			}
		}

		pm.AbstractMap.prototype.clean.call(this);
	}

});
