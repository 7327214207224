/**
 * Created by Nikita Besshaposhnikov on 27.01.15.
 */

/**
 * @class Layer for showing current info of some World.
 * @extends pmui.PopupLayer
 * @constructor
 */
var WorldInfoLayer = pmui.PopupLayer.extend(/** @lends WorldInfoLayer# */{

	ctor: function()
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		var ownerName = pm.settings.getWorldRecord(world.id).owner.name;

		var ownerLabel = new ccui.Text(LocalizedString("WorldIssuer").format(ownerName), pm.settings.fontName, 17);
		ownerLabel.setTextHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

		var ownerLabelMargin = new ccui.RelativeLayoutParameter();
		ownerLabelMargin.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		ownerLabelMargin.setMargin(2 + WorldInfoLayer.BORDER, 2 + WorldInfoLayer.BORDER, 0, 0);
		ownerLabelMargin.setRelativeName("owner");
		ownerLabel.setLayoutParameter(ownerLabelMargin);

		var worldChanged = world.getEditLog().isEmpty();
		var status = worldChanged ? LocalizedString("WorldChanged") : LocalizedString("WorldActual");

		var statusLabel = new ccui.Text(LocalizedString("WorldStatus").format(status), pm.settings.fontName, 17);
		statusLabel.setTextHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

		var statusLabelMargin = new ccui.RelativeLayoutParameter();
		statusLabelMargin.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		statusLabelMargin.setRelativeToWidgetName("owner");
		statusLabelMargin.setRelativeName("status");
		statusLabel.setLayoutParameter(statusLabelMargin);

		var showEditLogButton = new pmui.InterfaceButton(this._showEditLog, this, LocalizedString("ShowEditLog"), 17);

		var buttonAlign = new ccui.RelativeLayoutParameter();
		buttonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		buttonAlign.setRelativeToWidgetName("status");

		showEditLogButton.setLayoutParameter(buttonAlign);

		this.addChild(showEditLogButton);

		this.setContentSizeWithBorder(
			Math.max(statusLabel.width, ownerLabel.width, showEditLogButton.width) + 2*WorldInfoLayer.BORDER,
			statusLabel.height + ownerLabel.height + showEditLogButton.height + 2*WorldInfoLayer.BORDER
		);

		this.addChild(statusLabel);
		this.addChild(ownerLabel);
	},

	_showEditLog: function()
	{
		if(cc.director.getRunningScene().getChildByTag(EditLogInfoLayer.TAG))
			return;

		var editLogInfoLayer = new EditLogInfoLayer(this, this._closeEditLog);

		cc.director.getRunningScene().addChild(editLogInfoLayer, 100, EditLogInfoLayer.TAG);
	},

	_closeEditLog: function()
	{
		cc.director.getRunningScene().removeChildByTag(EditLogInfoLayer.TAG);
	}
});

WorldInfoLayer.BORDER = 5;
