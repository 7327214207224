"use strict";

/**
 * Created by Nikita Besshaposhnikov on 07.04.17.
 */

/**
 * @class Sound physical robot. Extends BLE functionality by sound
 * @extends pm.BLEPhysicalConnector
 */
pm.SoundPhysicalConnector = pm.BLEPhysicalConnector.extend(/** @lends pm.SoundPhysicalConnector# */{

	executeRobotCommand: function(id, command, args)
	{
		if(!this._connected)
			return;

		cc.log("Sending command to Sound Physical robot:" + command);

		var timeout = 0;
		var realCommand = true;

		switch(command)
		{
			case PlayerRobot4.NativeMethod.Move:
				pm.audioUtils.playSound("res/Sounds/r4_move.wav");
				timeout = 1400;
				break;
			case PlayerRobot4.NativeMethod.TurnLeft:
				pm.audioUtils.playSound("res/Sounds/r4_turn_left.wav");
				timeout = 2000;
				break;
			case PlayerRobot4.NativeMethod.TurnRight:
				pm.audioUtils.playSound("res/Sounds/r4_turn_right.wav");
				timeout = 2600;
				break;
			case pm.CMD_INDICATE:
				pm.audioUtils.playSound("res/Sounds/indicate.wav");
				timeout = 3800;
				break;
			case pm.data.PressRobot.NativeMethod.Press:
				pm.audioUtils.playSound("res/Sounds/press_press.wav");
				timeout = 3200;
				break;
			case pm.data.PressRobot.Condition.NotBox:
				pm.audioUtils.playSound("res/Sounds/press_not_box.wav");
				timeout = 3200;
				break;
			case pm.data.PressRobot.Condition.Box:
				pm.audioUtils.playSound("res/Sounds/press_box.wav");
				timeout = 3200;
				break;
			case pm.data.PressRobot.NativeMethod.LoadBox:
				pm.audioUtils.playSound("res/Sounds/press_load_box.wav");
				timeout = 14000;
				realCommand = false;
				break;
			case pm.data.PressRobot.NativeMethod.UnloadBox:
				pm.audioUtils.playSound("res/Sounds/press_unload_box.wav");
				timeout = 14000;
				realCommand = false;
				break;
		}

		this._busy = true;

		var self = this;
		setTimeout(function()
		{
			if (realCommand)
			{
				pm.BLEPhysicalConnector.prototype.executeRobotCommand.call(self, id, command, args);
			}
			else
			{
				this._currentCommand = "";
				this._busy = false;
			}
		}.bind(this), timeout);
	}
});
