/**
 * Created by Kirill Mashchenko on 07.07.2018.
 */

/**
 * @class Layer for {@link pm.data.TrainMap}.
 * @implements SquarableMapLayerWithWalls
 */
var TrainMapLayer = SquarableMapLayerWithWalls.extend(/** @lends TrainMapLayer#*/{

	ctor: function(map)
	{
		this._super(map);
		this._orientation = pm.MapLayer2D.Orientation.Iso;
		this._mapElementSize = cc.size(64, 64);
	}
});
