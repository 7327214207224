/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

pmui.ReplaceNode = pmui.NodeLayer.extend(/** @lends ccui.Layout# */{

	_repId: 0,

	ctor: function (repTypes, parent, treeNode, syntaxModule)
	{
		this._super(parent, treeNode, syntaxModule);
		this._repTypes = repTypes;
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return;
		if (repTypes[0] === FunctionButton.Type.Condition)
		{
			var cond = new EmptyFunctionButton(FunctionButton.Type.Condition);
			this.addChild(cond);
		}
		else
		{
			this.setBackGroundImage("PiktomirK/replace-button.png");
			this.setBackGroundImageCapInsets(cc.rect(15, 15, 10, 10));
		}
		this.setContentSize(40, 40);

	},

	deSelect: function ()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return;
		if (this._selected)
		{
			this.setBackGroundImage("PiktomirK/replace-button.png");
			this.setBackGroundImageCapInsets(cc.rect(15, 15, 10, 10));
			this._selected = false;
			this.setTag(ProgramContainerLayer.NOT_SELECTED_TAG);
		}
	},

	select: function ()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return;
		if (!this._selected)
		{
			this.setBackGroundImage("PiktomirK/replace-button-select.png");
			this.setBackGroundImageCapInsets(cc.rect(15, 15, 10, 10));
			this._selected = true;
			this.setTag(ProgramContainerLayer.SELECTED_TAG);
		}
	},

	isReplaceable: function()
	{
		return true;
	},

	getTagName: function ()
	{
		return this._repId;
	},

	getChildrenNodes: function ()
	{
		return [];
	},

	cloneImage: function ()
	{
		return this.clone();
	},

	clone: function ()
	{
		return pm.appUtils.generateFunctionButton(FunctionButton.Type.Empty, "");
	},

	calculateSize: function (isPar)
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			this.setContentSize(40, 27);
		else
			this.setContentSize(40, 40);
		if (!isPar)
		{
			this._parentNode.calculateSize();
		}
	},

	drawBackground: function ()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return;
		if(!this.isEditable())
		{
			this.setBackGroundImage("PiktomirK/replace-button-select.png");
			this.setBackGroundImageCapInsets(cc.rect(15, 15, 10, 10));
		}
		else
		{
			this.setBackGroundImage("PiktomirK/replace-button.png");
			this.setBackGroundImageCapInsets(cc.rect(15, 15, 10, 10));
		}
	},

	isEmpty:function()
	{
		return true;
	}
});
