/**
 * Created by Nikita Besshaposhnikov on 18.12.14.
 */

/**
 * @class 2D map where robot can repair some kinds({@link RepairMapElementType}) of elements.
 * @implements SquarableMapWithWalls
 */
pm.data.RepairMap = SquarableMapWithWalls.extend(/** @lends pm.data.RepairMap# */{
	typeName: "RepairMap",

	ctor: function()
	{
		this._super();
	},

	getType: function() { return pm.RepairLevelModule.Type; },

	generateMapLayer: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (!pm.settings.isEditorMode)
				this.mapLayer = new RepairMapLayer(this);
			else
				this.mapLayer = new RepairMapEditorLayer(this);

			this.mapLayer.drawMap(previewDraw);

			return this.mapLayer;
		}
	},

	setSize: function(width, height)
	{
		this.mapElements.length = 0;

		for(var i = 0; i < height; ++i)
		{
			this.mapElements.push([]);

			for(var j = 0; j < width; ++j)
				this.mapElements[i].push(new pm.data.RepairMapElement());
		}

		this.width = width;
		this.height = height;
	},

	/**
     * Repairs element by element coordinate.
     * @param {Number} position in elements.
     */
	repairElement: function(pos)
	{
		var element = this.element(pos);
		element.repair();
	},

	/**
     * Returns element to broken state by element coordinate.
     * @param {Number} x X-coordinate in elements.
     * @param {Number} y Y-coordinate in elements.
     */
	disrepairElement: function(pos)
	{
		var element = this.element(pos);
		element.disrepair();

	}
});
