/**
 * Created by Nikita Besshaposhnikov on 11.11.14.
 */

/**
 * @class This class is a container for all tasks for one robot.
 * @extends pm.Class
 */
pm.data.TaskList = pm.Class.extend(/** @lends pm.data.TaskList# */{
	typeName: "TaskList",

	/**
     * Map "task_type-task" container for tasks.
     * @type {pm.data.MapClass}
     */
	tasks: {},

	ctor: function()
	{
		this._super();
		this.tasks = new pm.data.MapClass();
	},

	/**
     * Inits container with robots.
     * @param {pm.AbstractRobot} robot
     */
	initWithLevel: function(level)
	{
		for(var i in this.tasks)
		{
			if(!(this.tasks[i] instanceof pm.Task))
				continue;

			this.tasks[i].setLevel(level);
		}
	},

	/**
     * Returns number of available tasks.
     * @returns {number}
     */
	getTaskCount: function()
	{
		//except typename & others
		return Object.keys(this.tasks).length - 2;
	},

	addTask: function(type, task)
	{
		if(this.tasks[type] !== undefined)
			return false;

		this.tasks[type] = task;
		return true;
	},

	deleteTask: function(type)
	{
		delete this.tasks[type];
	},

	getTask: function(type)
	{
		if(this.tasks[type] === undefined)
			return null;

		return this.tasks[type];
	},

	/**
     * Check if task are completed.</br>
     * @param {Number} mapIndex
     * @returns {Array<MapElement>}
     */
	getNotCompletedObjects: function(mapIndex)
	{
		var retArray = [];

		if(this.getTaskCount() < 0)
			return retArray;

		for(var i in this.tasks)
		{
			if(!(this.tasks[i] instanceof pm.Task))
				continue;

			retArray = retArray.concat(this.tasks[i].getNotCompletedObjects(mapIndex));
		}

		return retArray;
	},

	/**
     * Returns true if there are no tasks available.
     * @returns {Boolean}
     */
	empty: function()
	{
		return this.getTaskCount() === 0;
	}

});
