/**
 * Created by Kirill Mashchenko on 30.10.18.
 */

/**
 * @class Layout for Repair Lesson page.
 * @extends CommonLessonPage
 */

var RepairLessonPage = CommonLessonPage.extend(/** @lends RepairLessonPage# */{

	ctor: function(target, cancelCallback)
	{
		this._super(target, cancelCallback);

		this._loadExtraInterface();
	},

	_loadExtraInterface: function()
	{
		pm.spriteUtils.addTempSpriteFrames(pm.moduleUtils.getRobotSpriteSheet(pm.RepairLevelModule.Type, pm.RepairLevelModule.RobotType));

		var repairSprite = new ccui.ImageView("rep_robot3.png", ccui.Widget.PLIST_TEXTURE);

		repairSprite.setAnchorPoint(cc.p(0, 1));

		repairSprite.setScale(2);

		var repairAlign = new ccui.RelativeLayoutParameter();
		repairAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		repairAlign.setMargin(3 * CommonLessonPage.SEPARATOR, CommonLessonPage.SEPARATOR, 0, 0);
		repairAlign.setRelativeName("repairSprite");
		repairSprite.setLayoutParameter(repairAlign);

		this._container.addChild(repairSprite);

		var repairLabel = new ccui.Text(LocalizedString("Repair"), pm.settings.fontName, 30);

		var repairLabelAlign = new ccui.RelativeLayoutParameter();
		repairLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		repairLabelAlign.setMargin(repairSprite.width - repairLabel.width / 2, (cc.sys.isNative) ? 0 : repairSprite.height, 0, 0);
		repairLabelAlign.setRelativeName("repairLabel");
		repairLabelAlign.setRelativeToWidgetName("repairSprite");
		repairLabel.setLayoutParameter(repairLabelAlign);

		this._container.addChild(repairLabel);

		var description = "Вертун отвечает за ремонт. Он закрашивает поврежденные плиты специальным огнеупорным составом. Состав заполняет трещины в поврежденной плите, и плита снова готова к работе.";
		var commonTextLabel = new ccui.Text(description, pm.settings.fontName, 25);
		commonTextLabel.setTextAreaSize(cc.size(this._container.width - 6 * CommonLessonPage.SEPARATOR - 2 * repairSprite.width, 0));

		var commonTextAlign = new ccui.RelativeLayoutParameter();
		commonTextAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		commonTextAlign.setMargin(cc.sys.isNative ? 2 * CommonLessonPage.SEPARATOR : repairSprite.width + 2 * CommonLessonPage.SEPARATOR, 3 * CommonLessonPage.SEPARATOR, 0, 0);
		commonTextAlign.setRelativeName("description");
		commonTextAlign.setRelativeToWidgetName("repairSprite");
		commonTextLabel.setLayoutParameter(commonTextAlign);

		this._container.addChild(commonTextLabel);

		pm.spriteUtils.addTempSpriteFrames(pm.moduleUtils.getMapTileset(pm.RepairLevelModule.Type, 0));

		var tableInfo = new ccui.HBox();

		var tableAlign = new ccui.RelativeLayoutParameter();
		tableAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		tableAlign.setRelativeName("tableInfo");

		var margin = Math.max(2 * repairSprite.height + repairLabel.height + CommonLessonPage.SEPARATOR, commonTextLabel.height + 3 * CommonLessonPage.SEPARATOR);

		tableAlign.setMargin(0, margin, 0, 0);
		tableInfo.setLayoutParameter(tableAlign);

		var height = 0;

		var finishBox = new ccui.VBox();

		var finishLabel = new ccui.Text("Элементы поля", pm.settings.fontName, 30);

		var finishLabelAlign = new ccui.LinearLayoutParameter();
		finishLabelAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
		finishLabelAlign.setMargin(0, CommonLessonPage.SEPARATOR, 0, 0);
		finishLabel.setLayoutParameter(finishLabelAlign);

		finishBox.addChild(finishLabel);

		for (var i = 1; i <= 3; ++i)
		{
			var finishContainer = new ccui.HBox();

			var containerMargin = new ccui.LinearLayoutParameter();
			containerMargin.setMargin(CommonLessonPage.TABLE_MARGIN, CommonLessonPage.SEPARATOR, 0, 0);
			finishContainer.setLayoutParameter(containerMargin);

			finishBox.addChild(finishContainer);

			if (i !== 3)
				var image = new ccui.ImageView("mapElement{0}.png".format(i + 1), ccui.Widget.PLIST_TEXTURE);
			else
				var image = new ccui.ImageView("targetElement.png", ccui.Widget.PLIST_TEXTURE);

			var imageAlign = new ccui.LinearLayoutParameter();
			imageAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
			image.setLayoutParameter(imageAlign);

			image.setAnchorPoint(cc.p(0, 1));
			image.setScale(CommonLessonPage.CELL_HEIGHT / image.height);

			finishContainer.addChild(image);

			var label = new ccui.Text(this._getObjectDescription(i), pm.settings.fontName, 25);

			var labelMargin = new ccui.LinearLayoutParameter();
			labelMargin.setGravity(ccui.LinearLayoutParameter.LEFT);
			var margin = 2 * CommonLessonPage.SEPARATOR;
			labelMargin.setMargin(margin, CommonLessonPage.CELL_HEIGHT / 2 - label.height/2, 0, 0);
			label.setLayoutParameter(labelMargin);

			finishContainer.addChild(label);

			finishContainer.setContentSize(image.width + CommonLessonPage.SEPARATOR + label.width, CommonLessonPage.CELL_HEIGHT);

			height += CommonLessonPage.SEPARATOR + finishContainer.height;
		}

		finishBox.setContentSize(this._container.width / 2, height);

		height = 0;

		var commandBox = new ccui.VBox();

		var commandLabel = new ccui.Text("Команды", pm.settings.fontName, 30);

		var commandLabelAlign = new ccui.LinearLayoutParameter();
		commandLabelAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
		commandLabelAlign.setMargin(0, CommonLessonPage.SEPARATOR, 0, 0);
		commandLabel.setLayoutParameter(commandLabelAlign);

		commandBox.addChild(commandLabel);

		for (var i = 0; i < 4; ++i)
		{
			var commandContainer = new ccui.HBox();

			var containerMargin = new ccui.LinearLayoutParameter();
			containerMargin.setMargin(CommonLessonPage.TABLE_MARGIN, CommonLessonPage.SEPARATOR, 0, 0);
			commandContainer.setLayoutParameter(containerMargin);

			commandBox.addChild(commandContainer);

			var name;

			pm.spriteUtils.initRobotTextures(pm.RepairLevelModule.Type);

			switch(i)
			{
				case 0:
					name = "r4_move-normal.png";
					break;
				case 1:
					name = "r4_turn_left-normal.png";
					break;
				case 2:
					name = "r4_turn_right-normal.png";
					break;
				case 3:
					name = "rep_repair-normal.png";
					break;
			}

			var image = new ccui.ImageView(name, ccui.Widget.PLIST_TEXTURE);

			var imageAlign = new ccui.LinearLayoutParameter();
			imageAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
			image.setLayoutParameter(imageAlign);

			image.setAnchorPoint(cc.p(0, 1));
			image.setScale(CommonLessonPage.CELL_HEIGHT / image.height);
			commandContainer.addChild(image);

			var label = new ccui.Text(this._getCommandDescription(i), pm.settings.fontName, 25);

			var labelMargin = new ccui.LinearLayoutParameter();
			labelMargin.setGravity(ccui.LinearLayoutParameter.LEFT);
			var margin = (cc.sys.isNative) ? 2 * CommonLessonPage.SEPARATOR : 2 * CommonLessonPage.SEPARATOR + 40;
			labelMargin.setMargin(margin, CommonLessonPage.CELL_HEIGHT / 2 - label.height/2, 0, 0);
			label.setLayoutParameter(labelMargin);

			commandContainer.addChild(label);

			commandContainer.setContentSize(image.width + CommonLessonPage.SEPARATOR + label.width, CommonLessonPage.CELL_HEIGHT);

			height += CommonLessonPage.SEPARATOR + commandContainer.height;
		}

		commandBox.setContentSize(this._container.width / 2, height);

		tableInfo.setContentSize(this._container.width, Math.max(finishBox.height, commandBox.height));

		tableInfo.addChild(finishBox);
		tableInfo.addChild(commandBox);
		this._container.addChild(tableInfo);

		var conditionLabel = new ccui.Text("Команды-вопросы", pm.settings.fontName, 30);

		var conditionLabelAlign = new ccui.RelativeLayoutParameter();
		conditionLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		conditionLabelAlign.setRelativeToWidgetName("tableInfo");
		conditionLabelAlign.setRelativeName("conditionLabel");

		var margin = 2 * CommonLessonPage.SEPARATOR + 40;

		conditionLabelAlign.setMargin(0, margin, 0, 0);
		conditionLabel.setLayoutParameter(conditionLabelAlign);

		this._container.addChild(conditionLabel);

		var conditionInfo = new ccui.HBox();

		var conditionAlign = new ccui.RelativeLayoutParameter();
		conditionAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		conditionAlign.setRelativeToWidgetName("conditionLabel");

		var margin = CommonLessonPage.SEPARATOR;

		conditionAlign.setMargin(0, margin, 0, 0);
		conditionInfo.setLayoutParameter(conditionAlign);

		height = 0;

		var conditionBox1 = new ccui.VBox();

		for (var i = 0; i < 4; ++i)
		{
			var conditionContainer = new ccui.HBox();

			var conditionContainerMargin = new ccui.LinearLayoutParameter();
			conditionContainerMargin.setMargin(CommonLessonPage.TABLE_MARGIN, CommonLessonPage.SEPARATOR, 0, 0);
			conditionContainer.setLayoutParameter(conditionContainerMargin);

			conditionBox1.addChild(conditionContainer);

			var name;

			pm.spriteUtils.initRobotTextures(pm.RepairLevelModule.Type);

			switch(i)
			{
				case 0:
					name = "cond-r4_clear-normal.png";
					break;
				case 1:
					name = "cond-rep_gib-normal.png";
					break;
				case 2:
					name = "cond-rep_gig-normal.png";
					break;
				case 3:
					name = "cond-rep_gilb-normal.png";
					break;
			}

			var image = new ccui.ImageView(name, ccui.Widget.PLIST_TEXTURE);

			var imageAlign = new ccui.LinearLayoutParameter();
			imageAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
			image.setLayoutParameter(imageAlign);

			image.setAnchorPoint(cc.p(0, 1));
			image.setScale(CommonLessonPage.CELL_HEIGHT / image.height);
			conditionContainer.addChild(image);

			var label = new ccui.Text(this._getConditionDescription(i), pm.settings.fontName, 25);
			label.setTextAreaSize(cc.size(this._container.width / 2 - image.width - 2 * CommonLessonPage.TABLE_MARGIN, 0));

			var labelMargin = new ccui.LinearLayoutParameter();
			labelMargin.setGravity(ccui.LinearLayoutParameter.LEFT);
			var margin = (cc.sys.isNative) ? 2 * CommonLessonPage.SEPARATOR : 2 * CommonLessonPage.SEPARATOR + 40;
			labelMargin.setMargin(margin, CommonLessonPage.CELL_HEIGHT / 2 - label.height/2, 0, 0);
			label.setLayoutParameter(labelMargin);

			conditionContainer.addChild(label);

			conditionContainer.setContentSize(image.width + CommonLessonPage.SEPARATOR + label.width, CommonLessonPage.CELL_HEIGHT);

			height += CommonLessonPage.SEPARATOR + conditionContainer.height;
		}

		conditionBox1.setContentSize(this._container.width / 2, height);

		height = 0;

		var conditionBox2 = new ccui.VBox();

		for (var i = 0; i < 4; ++i)
		{
			var conditionContainer = new ccui.HBox();

			var conditionContainerMargin = new ccui.LinearLayoutParameter();
			conditionContainerMargin.setMargin(CommonLessonPage.TABLE_MARGIN, CommonLessonPage.SEPARATOR, 0, 0);
			conditionContainer.setLayoutParameter(conditionContainerMargin);

			conditionBox2.addChild(conditionContainer);

			var name;

			pm.spriteUtils.initRobotTextures(pm.RepairLevelModule.Type);

			switch(i)
			{
				case 0:
					name = "cond-r4_wall-normal.png";
					break;
				case 1:
					name = "cond-rep_ginb-normal.png";
					break;
				case 2:
					name = "cond-rep_ging-normal.png";
					break;
				case 3:
					name = "cond-rep_ginlb-normal.png";
					break;
			}

			var image = new ccui.ImageView(name, ccui.Widget.PLIST_TEXTURE);

			var imageAlign = new ccui.LinearLayoutParameter();
			imageAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
			image.setLayoutParameter(imageAlign);

			image.setAnchorPoint(cc.p(0, 1));
			image.setScale(CommonLessonPage.CELL_HEIGHT / image.height);
			conditionContainer.addChild(image);

			var label = new ccui.Text(this._getConditionDescription(4 + i), pm.settings.fontName, 25);
			label.setTextAreaSize(cc.size(this._container.width / 2 - image.width - 2 * CommonLessonPage.TABLE_MARGIN, 0));

			var labelMargin = new ccui.LinearLayoutParameter();
			labelMargin.setGravity(ccui.LinearLayoutParameter.LEFT);
			var margin = (cc.sys.isNative) ? 2 * CommonLessonPage.SEPARATOR : 2 * CommonLessonPage.SEPARATOR + 40;
			labelMargin.setMargin(margin, CommonLessonPage.CELL_HEIGHT / 2 - label.height/2, 0, 0);
			label.setLayoutParameter(labelMargin);

			conditionContainer.addChild(label);

			conditionContainer.setContentSize(image.width + CommonLessonPage.SEPARATOR + label.width, CommonLessonPage.CELL_HEIGHT);

			height += CommonLessonPage.SEPARATOR + conditionContainer.height;
		}

		conditionBox2.setContentSize(this._container.width / 2, height);

		conditionInfo.setContentSize(this._container.width, Math.max(conditionBox1.height, conditionBox2.height));

		conditionInfo.addChild(conditionBox1);
		conditionInfo.addChild(conditionBox2);
		this._container.addChild(conditionInfo);

		this._container.setContentSize(this._container.width,
			Math.max(2 * repairSprite.height + repairLabel.height + CommonLessonPage.SEPARATOR,
				commonTextLabel.height + 4 * CommonLessonPage.SEPARATOR) + tableInfo.height + conditionInfo.height +
			conditionLabel.height + 8 * CommonLessonPage.SEPARATOR);
	},

	_getObjectDescription: function(number)
	{
		switch(number)
		{
			case 1:
				return "нужен ремонт";
			case 2:
				return "отремонтирована";
			case 3:
				return "финиш";
		}
	},

	_getCommandDescription: function(number)
	{
		switch(number)
		{
			case 0:
				return "вперед";
			case 1:
				return "налево";
			case 2:
				return "направо";
			case 3:
				return "закрасить";
		}
	},

	_getConditionDescription: function(number)
	{
		switch(number)
		{
			case 0:
				return "впереди свободно";
			case 1:
				return "клетка синяя";
			case 2:
				return "клетка серая";
			case 3:
				return "клетка голубая";
			case 4:
				return "впереди стена";
			case 5:
				return "клетка не синяя";
			case 6:
				return "клетка не серая";
			case 7:
				return "клетка не голубая";
		}
	}
});

