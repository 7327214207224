/**
 * Created by Kirill Mashchenko on 07.07.2018.
 */

/**
 * @class Car object for carrying by {@link pm.data.TrainRobot}
 * @implements PushObject
 */
pm.data.TrainCar = TrainObject.extend(/** @lends pm.data.TrainCar# */{

	typeName: "TrainCar",

	getType: function() { return pm.TrainLevelModule.ObjectTypes.TrainCar; },

	generateSprite: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (previewDraw === undefined)
				previewDraw = false;

			this.sprite = new TrainCarSprite();
			this.updateNumberSprite();

			if (previewDraw)
				this.sprite.setIdVisible();

			this.generateObjectConnections();

			return this.sprite;
		}
	}
});
