/**
 * Created by Nikita Besshaposhnikov on 15.06.16.
 */

/**
 * @class Layer for editing tutorial scenario.
 * @extends ccui.Layout
 * @constructor
 * @param {pm.data.TutorialScenario } scenario A scenario for editing
 */
var TutorialEditLayer = ccui.Layout.extend(/** @lends TutorialEditLayer# */{

	_scenario: null,

	_scenarioLayout: null,
	_stepListLayer: null,
	_headerLayout: null,
	_selectStepCombobox: null,
	_upButton: null,
	_downButton: null,

	_addStepType: null,
	_selectedStepIndex: -1,

	_drawLayer: null,
	_arrowSprite: null,

	_touchType: -1,
	_startPoint: null,
	_clickedObject: null,
	_newStepType: null,
	_emulatedTouch: null,
	_touchListener: null,

	ctor: function(scenario)
	{
		this._super();

		this.setContentSize(pm.settings.getScreenSize());
		this.setLayoutType(ccui.Layout.RELATIVE);

		this._scenario = scenario;

		var layoutSize = cc.size(TutorialEditLayer.SCENARIO_LAYOUT_WIDTH, this.height);

		this._scenarioLayout = new ccui.Layout();
		this._scenarioLayout.setLayoutType(ccui.Layout.RELATIVE);

		this._scenarioLayout.setBackGroundImage("System/TL_Border.png");
		this._scenarioLayout.setBackGroundImageScale9Enabled(true);
		this._scenarioLayout.setCascadeOpacityEnabled(true);
		this._scenarioLayout.setBackGroundImageCapInsets(cc.rect(80, 90, 100, 70));
		this._scenarioLayout.setContentSize(layoutSize);

		var scenarioAlign = new ccui.RelativeLayoutParameter();
		scenarioAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
		this._scenarioLayout.setLayoutParameter(scenarioAlign);

		this._backgroundLayer = new cc.LayerColor(TutorialEditLayer.BACKGROUND_COLOR, layoutSize.width - 2 * TutorialEditLayer.BACKGROUND_BORDER,
			this._scenarioLayout.height - 2 * TutorialEditLayer.BACKGROUND_BORDER);
		this._backgroundLayer.setPosition(TutorialEditLayer.BACKGROUND_BORDER, TutorialEditLayer.BACKGROUND_BORDER);
		this._scenarioLayout.addChild(this._backgroundLayer, -1);

		var closeButton = new pmui.Button(pm.spriteUtils.getIconName("add", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("add", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("add", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		closeButton.setRotation(45);
		closeButton.addClickEventListener(this.hide.bind(this));

		var closeButtonAlign = new ccui.RelativeLayoutParameter();
		closeButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		closeButtonAlign.setMargin(0, 0, -closeButton.width / 4, 0);
		closeButton.setLayoutParameter(closeButtonAlign);

		this._scenarioLayout.addChild(closeButton);

		var headerHeight = 0;
		this._headerLayout = new ccui.Layout();
		this._headerLayout.setLayoutType(ccui.Layout.RELATIVE);

		var headerAlign = new ccui.RelativeLayoutParameter();
		headerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		headerAlign.setRelativeName("header");
		headerAlign.setMargin(0, TutorialEditLayer.BORDER, 0, 0);
		this._headerLayout.setLayoutParameter(headerAlign);

		var label = new ccui.Text(LocalizedString("TutorialScenario"), pm.settings.fontName, pm.settings.fontSize);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
		labelAlign.setRelativeName("label");
		labelAlign.setMargin(TutorialEditLayer.BORDER, TutorialEditLayer.BORDER, 0, 0);
		label.setLayoutParameter(labelAlign);

		this._headerLayout.addChild(label);
		headerHeight = Math.max(headerHeight, label.height);

		this._upButton = new pmui.Button(pm.spriteUtils.getIconName("up", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("up", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("up", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		this._upButton.addClickEventListener(this._upStep.bind(this));

		var upButtonAlign = new ccui.RelativeLayoutParameter();
		upButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		upButtonAlign.setRelativeName("up");
		upButtonAlign.setRelativeToWidgetName("label");
		upButtonAlign.setMargin(TutorialEditLayer.SEPARATOR, 0, 0, 0);
		this._upButton.setLayoutParameter(upButtonAlign);

		this._downButton = new pmui.Button(pm.spriteUtils.getIconName("down", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("down", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("down", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		this._downButton.addClickEventListener(this._downStep.bind(this));

		var downButtonAlign = new ccui.RelativeLayoutParameter();
		downButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		downButtonAlign.setRelativeName("down");
		downButtonAlign.setRelativeToWidgetName("up");
		downButtonAlign.setMargin(TutorialEditLayer.SEPARATOR, 0, 0, 0);
		this._downButton.setLayoutParameter(downButtonAlign);

		this._headerLayout.addChild(this._upButton);
		this._headerLayout.addChild(this._downButton);
		this._setUpDownButtonStates();
		headerHeight = Math.max(headerHeight, this._upButton.height);

		var clearScenario = new pmui.Button(pm.spriteUtils.getIconName("clear", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("clear", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("clear", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		clearScenario.addClickEventListener(this._clearScenario.bind(this));

		var clearScenarioAlign = new ccui.RelativeLayoutParameter();
		clearScenarioAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		clearScenarioAlign.setRelativeToWidgetName("down");
		clearScenarioAlign.setMargin(TutorialEditLayer.SEPARATOR, 0, 0, 0);
		clearScenario.setLayoutParameter(clearScenarioAlign);

		this._headerLayout.addChild(clearScenario);
		headerHeight = Math.max(headerHeight, clearScenario.height * 0.8);

		this._headerLayout.setContentSize(TutorialEditLayer.SCENARIO_LAYOUT_WIDTH, headerHeight);

		var addStepLayout = new ccui.Layout();
		addStepLayout.setLayoutType(ccui.Layout.RELATIVE);

		var addStepAlign = new ccui.RelativeLayoutParameter();
		addStepAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		addStepAlign.setRelativeName("addStep");
		addStepAlign.setRelativeToWidgetName("header");
		addStepAlign.setMargin(0, TutorialEditLayer.SEPARATOR, 0, 0);
		addStepLayout.setLayoutParameter(addStepAlign);

		var stepMap = TutorialEditLayer.STEP_MAP;
		var strList = [];

		for (var i = 0; i < stepMap.length; ++i)
			strList.push(LocalizedString(stepMap[i].locString));

		this._addStepType = stepMap[0].step;

		this._selectStepCombobox = new pmui.ComboBox(strList, this, this._selectStepOnSelect, 20);

		var comboboxAlign = new ccui.RelativeLayoutParameter();
		comboboxAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		comboboxAlign.setRelativeName("combobox");
		comboboxAlign.setMargin(TutorialEditLayer.BORDER, TutorialEditLayer.SHIFT, 0, 0);
		this._selectStepCombobox.setLayoutParameter(comboboxAlign);

		addStepLayout.addChild(this._selectStepCombobox);

		var addStepButton = new pmui.Button(pm.spriteUtils.getIconName("add", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("add", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("add", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		addStepButton.addClickEventListener(this._addStepCallback.bind(this));

		var stepButtonAlign = new ccui.RelativeLayoutParameter();
		stepButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		stepButtonAlign.setRelativeToWidgetName("combobox");
		stepButtonAlign.setMargin(TutorialEditLayer.SEPARATOR, 0, 0, 0);
		addStepButton.setLayoutParameter(stepButtonAlign);

		addStepLayout.addChild(addStepButton);

		addStepLayout.setContentSize(TutorialEditLayer.SCENARIO_LAYOUT_WIDTH,
			Math.max(this._selectStepCombobox.height, addStepButton.height));
		addStepLayout.setScale(0.85);

		var middleBorder = new cc.Scale9Sprite("System/PL_Middle.png");

		middleBorder.setCapInsets(cc.rect(28, 18, 7, 4));
		middleBorder.setAnchorPoint(0, 0.5);
		middleBorder.setContentSize(layoutSize.width - 2 * TutorialEditLayer.BACKGROUND_BORDER, TutorialEditLayer.MIDDLE_BORDER);
		middleBorder.setPosition(TutorialEditLayer.BACKGROUND_BORDER, this.height - TutorialEditLayer.BACKGROUND_BORDER -
			TutorialEditLayer.BORDER - this._headerLayout.height - 2 * TutorialEditLayer.SEPARATOR - addStepLayout.height);

		this._stepListLayer = new ccui.ListView();

		this._stepListLayer.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._stepListLayer.setItemsMargin(TutorialEditLayer.LIST_ITEM_SEPARATOR);
		this._stepListLayer.setScrollBarEnabled(true);
		this._stepListLayer.setScrollBarOpacity(255 * 0.9);
		this._stepListLayer.setScrollBarWidth(ProgramLayer.SCROLL_BAR_WIDTH);

		this._stepListLayer.setContentSize(layoutSize.width - TutorialEditLayer.BACKGROUND_BORDER - TutorialEditLayer.BORDER, layoutSize.height - TutorialEditLayer.BORDER -
			headerHeight - addStepLayout.height - middleBorder.height - 3 * TutorialEditLayer.SEPARATOR - TutorialEditLayer.BACKGROUND_BORDER);

		var stepListMargin = new ccui.RelativeLayoutParameter();
		stepListMargin.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		stepListMargin.setMargin(TutorialEditLayer.BACKGROUND_BORDER + TutorialEditLayer.SEPARATOR, TutorialEditLayer.MIDDLE_SEPARATOR, 0, 0);
		stepListMargin.setRelativeToWidgetName("addStep");
		this._stepListLayer.setLayoutParameter(stepListMargin);

		this._scenarioLayout.addChild(this._headerLayout, 0);
		this._scenarioLayout.addChild(addStepLayout, 1);
		this._scenarioLayout.addChild(middleBorder, 0);
		this._scenarioLayout.addChild(this._stepListLayer, 0);

		this.addChild(this._scenarioLayout);

		this._fillStepList();

		this._drawLayer = new cc.DrawNode();

		this._drawLayer.setContentSize(this.getContentSize());
		this.addChild(this._drawLayer);

		this._arrowSprite = new cc.Scale9Sprite(pm.spriteUtils.getInterfaceElementFrame("arrow"),
			cc.rect(30 / 2, 8 / 2, 50 / 2, 6 / 2));

		this._arrowSprite.setColor(TutorialEditLayer.RENDER_COLOR);
		this._arrowSprite.setAnchorPoint(0, 0.5);
		this._arrowSprite.setVisible(false);
		this.addChild(this._arrowSprite);

		this._stepListLayer.addEventListener(this._onSelectStep.bind(this));

		this._touchListener = cc.EventListener.create({
			event: cc.EventListener.TOUCH_ONE_BY_ONE,
			swallowTouches: true,
			onTouchBegan: this._touchBegan.bind(this),
			onTouchMoved: this._touchMoved.bind(this),
			onTouchEnded: this._touchEnded.bind(this)
		});

		cc.eventManager.addListener(this._touchListener, this);
	},

	show: function()
	{
		if(this.enabled)
			return;

		this.enabled = true;
		this._scenarioLayout.setVisible(true);
		this._moveSelectionLayer();
		this._renderSelectedStep();
	},

	hide: function()
	{
		this.enabled = false;
		this._scenarioLayout.setVisible(false);
		this._clearSelection(false);

		pm.sendCustomEvent(pm.TUTORIAL_SCENARIO_CLOSED);
	},

	_selectStepOnSelect: function(index)
	{
		this._addStepType = TutorialEditLayer.STEP_MAP[index].step;
	},

	_addStepCallback: function()
	{
		var step = new pm.data.TutorialStep();
		step.type = this._addStepType;

		switch(this._addStepType)
		{
			case pm.TutorialStepType.WAIT_EVENT:
				step.data = pm.ROBOT_WIN_EVENT_STR;
				break;
		}

		this._addStep(step, true);
	},
	_setUpDownButtonStates: function()
	{
		if(this._selectedStepIndex === -1)
		{
			this._upButton.setEnabled(false);
			this._downButton.setEnabled(false);

			return;
		}

		var upState = true;
		var downState = true;

		if(this._selectedStepIndex === 0)
			upState = false;

		if(this._selectedStepIndex === this._scenario.steps.length - 1)
			downState = false;

		this._upButton.setEnabled(upState);
		this._downButton.setEnabled(downState);
	},

	_fillStepList: function()
	{
		for (var i = 0 ; i < this._scenario.steps.length; ++i)
		{
			var layer = this._generateStepLayer(this._scenario.steps[i], i);

			if (layer !== null)
				this._stepListLayer.pushBackCustomItem(layer);
		}
	},

	_upStep: function()
	{
		if(this._selectedStepIndex === -1)
			return;

		var step = this._scenario.steps[this._selectedStepIndex];
		var oldStepLayer = this._stepListLayer.getItem(this._selectedStepIndex);
		oldStepLayer.setSelectionVisible(false);

		this._scenario.steps.splice(this._selectedStepIndex, 1);
		this._scenario.steps.splice(this._selectedStepIndex - 1, 0, step);

		var stepLayer = this._stepListLayer.getItem(this._selectedStepIndex);
		var number = stepLayer.getStepNumber();

		stepLayer.retain();
		this._stepListLayer.removeChild(stepLayer, false);
		this._stepListLayer.insertCustomItem(stepLayer, this._selectedStepIndex - 1);
		stepLayer.release();

		stepLayer.setStepNumber(this._stepListLayer.getItem(this._selectedStepIndex).getStepNumber());
		this._stepListLayer.getItem(this._selectedStepIndex).setStepNumber(number);

		--this._selectedStepIndex;

		this._moveSelectionLayer();
	},

	_downStep: function()
	{
		if(this._selectedStepIndex === -1)
			return;

		var step = this._scenario.steps[this._selectedStepIndex];
		var oldStepLayer = this._stepListLayer.getItem(this._selectedStepIndex);
		oldStepLayer.setSelectionVisible(false);

		this._scenario.steps.splice(this._selectedStepIndex, 1);
		this._scenario.steps.splice(this._selectedStepIndex + 1, 0, step);

		var stepLayer = this._stepListLayer.getItem(this._selectedStepIndex);
		var number = stepLayer.getStepNumber();

		stepLayer.retain();
		this._stepListLayer.removeChild(stepLayer, false);
		this._stepListLayer.insertCustomItem(stepLayer, this._selectedStepIndex + 1);
		stepLayer.release();

		stepLayer.setStepNumber(this._stepListLayer.getItem(this._selectedStepIndex).getStepNumber());
		this._stepListLayer.getItem(this._selectedStepIndex).setStepNumber(number);

		++this._selectedStepIndex;

		this._moveSelectionLayer();
	},

	_addStep: function(step, moveSelection)
	{
		this._scenario.steps.push(step);

		if (this._selectedStepIndex !== -1)
		{
			var oldStepLayer = this._stepListLayer.getItem(this._selectedStepIndex);
			oldStepLayer.setSelectionVisible(false);
		}

		var layer = this._generateStepLayer(step, this._scenario.steps.length - 1);

		if(layer !== null)
			this._stepListLayer.pushBackCustomItem(layer);

		if(moveSelection)
		{
			this._selectedStepIndex = this._scenario.steps.length - 1;
			this._stepListLayer.forceDoLayout();

			this._moveSelectionLayer();
			this._renderSelectedStep();
		}

		this._processStep(step);
	},

	_processStep: function(step)
	{
		this._touchListener.setEnabled(false);

		switch (step.type)
		{
			case pm.TutorialStepType.CLICK_OBJECT:
				this._processClickStep(step);
				break;
			case pm.TutorialStepType.DRAG_OBJECT:
				this._processDragStep(step);
				break;
			case pm.TutorialStepType.MOVE_TO:
				this._processMoveToStep(step);
				break;
			case pm.TutorialStepType.CLICK_AREA:
				this._processClickAreaStep(step);
				break;
			case pm.TutorialStepType.WAIT_EVENT:
				//No need to do smth
				break;
		}

		this._touchListener.setEnabled(true);
	},

	_processClickStep: function(step)
	{
		var clickObject = pm.tutorialUtils._tutorialObjects[step.data].object;

		if (clickObject)
		{
			clickObject.unlock();
			var transform = clickObject.getParent().getNodeToWorldTransform();

			var objRect = cc.rectApplyAffineTransform(clickObject.getBoundingBox(), transform);

			var emulateFn = function() {
				this._emulateClick(cc.p(objRect.x + objRect.width / 2, objRect.y + objRect.height / 2), clickObject);
			};

			emulateFn.call(this);
		}
	},

	_emulateClick: function(pos, object)
	{
		this._createEmulatedTouch(1, pos.x, pos.y);

		this._dispatchTouchEvent(cc.EventTouch.EventCode.BEGAN);

		setTimeout(function ()
		{
			this._dispatchTouchEvent(cc.EventTouch.EventCode.ENDED);

			if(cc.sys.isNative)
				pm.destroyNativeTouch();

		}.bind(this), pm.tutorialUtils.TUTORIAL_DELAY);
	},

	_processDragStep: function(step)
	{
		var fromObject = pm.tutorialUtils._tutorialObjects[step.data.from].object;
		var toObject = pm.tutorialUtils._tutorialObjects[step.data.to].object;

		if (fromObject && toObject)
		{
			fromObject.unlock();

			var fromTransform = fromObject.getParent().getNodeToWorldTransform();
			var fromRect = cc.rectApplyAffineTransform(fromObject.getBoundingBox(), fromTransform);

			var toTransform = toObject.getParent().getNodeToWorldTransform();
			var toRect = cc.rectApplyAffineTransform(toObject.getBoundingBox(), toTransform);

			var emulateFn = function() {
				this._emulateMove(
					cc.p(fromRect.x + fromRect.width / 2, fromRect.y + fromRect.height / 2),
					cc.p(toRect.x + toRect.width / 2, toRect.y + toRect.height / 2)
				);
			};

			emulateFn.call(this);
		}
	},

	_emulateMove: function(fromPos, toPos)
	{
		this._createEmulatedTouch(1, fromPos.x, fromPos.y);

		this._dispatchTouchEvent(cc.EventTouch.EventCode.BEGAN);

		var totalTimeOutTime = 600;
		var totalStepCount = 50;

		var timePerMove = totalTimeOutTime / totalStepCount;

		var dx = (toPos.x - fromPos.x) / totalStepCount;
		var dy = (toPos.y - fromPos.y) / totalStepCount;

		var stepCount = 1;

		function moveEvent()
		{
			if(stepCount <= totalStepCount)
			{
				this._setEmulatedTouchLocation(fromPos.x + stepCount * dx, fromPos.y + stepCount * dy);
				this._dispatchTouchEvent(cc.EventTouch.EventCode.MOVED);

				++stepCount;

				setTimeout(moveEvent.bind(this), timePerMove);
			}
			else
			{
				this._setEmulatedTouchLocation(toPos.x, toPos.y);
				this._dispatchTouchEvent(cc.EventTouch.EventCode.ENDED);

				if(cc.sys.isNative)
					pm.destroyNativeTouch();
			}
		}

		setTimeout(moveEvent.bind(this), timePerMove);
	},

	_processMoveToStep: function(step)
	{
		var fromObject = pm.tutorialUtils._tutorialObjects[step.data.object].object;

		if (fromObject)
		{
			fromObject.unlock();

			var fromTransform = fromObject.getParent().getNodeToWorldTransform();
			var fromRect = cc.rectApplyAffineTransform(fromObject.getBoundingBox(), fromTransform);

			var fromPos = cc.p(fromRect.x, fromRect.y);
			var toPos = cc.pAdd(fromPos, step.data.delta);

			var emulateFn = function() {
				this._emulateMove(
					cc.p(fromRect.x + fromRect.width / 2, fromRect.y + fromRect.height / 2),
					cc.p(toPos.x, toPos.y)
				);
			};

			emulateFn.call(this);
		}
	},

	_processClickAreaStep: function(step)
	{
		var object = pm.tutorialUtils._tutorialObjects[step.data.object].object;

		if (object)
		{
			object.unlock();

			var destRect = cc.rectApplyAffineTransform(step.data.rect, object.getNodeToWorldTransform());
			var pos = cc.p(destRect.x + destRect.width / 2, destRect.y + destRect.height / 2);

			var emulateFn = function() {
				this._emulateClick(cc.p(pos.x, pos.y), object);
			};

			emulateFn.call(this);
		}
	},

	_createEmulatedTouch: function(id, x, y)
	{
		if(cc.sys.isNative)
		{
			var realPoint = cc.director.convertToUI(cc.p(x, y));
			pm.createNativeTouch(1, realPoint.x + 0.00001, realPoint.y + 0.00001); //strange correction for work convert on native
		}
		else
		{
			this._emulatedTouch = new cc.Touch(x, y, id);
		}
	},

	_setEmulatedTouchLocation: function(x, y)
	{
		if(cc.sys.isNative)
		{
			var realPoint = cc.director.convertToUI(cc.p(x, y));
			pm.setNativeTouchLocation(realPoint.x + 0.00001, realPoint.y + 0.00001); //strange correction for work convert on native
		}
		else
		{
			this._emulatedTouch.setTouchInfo(this._emulatedTouch.getID(), x, y);
		}
	},

	_dispatchTouchEvent: function(eventCode)
	{
		if(!cc.sys.isNative)
		{
			var touchEvent = new cc.EventTouch([this._emulatedTouch]);
			touchEvent._eventCode = eventCode;
			cc.eventManager.dispatchEvent(touchEvent);
		}
		else
		{
			pm.dispatchEventFromNativeTouch(eventCode);
		}
	},

	_generateStepLayer: function(step, stepNumber)
	{
		switch(step.type)
		{
			case pm.TutorialStepType.CLICK_OBJECT:
				return new TutorialStepClickObjectEditLayer(step, stepNumber, this._stepListLayer.width, this._onStepRemoved, this);
			case pm.TutorialStepType.DRAG_OBJECT:
				return new TutorialStepDragObjectEditLayer(step, stepNumber, this._stepListLayer.width, this._onStepRemoved, this);
			case pm.TutorialStepType.MOVE_TO:
				return new TutorialStepMoveToEditLayer(step, stepNumber, this._stepListLayer.width, this._onStepRemoved, this);
			case pm.TutorialStepType.CLICK_AREA:
				return new TutorialStepClickAreaEditLayer(step, stepNumber, this._stepListLayer.width, this._onStepRemoved, this);
			case pm.TutorialStepType.WAIT_EVENT:
				return new TutorialStepWaitEventEditLayer(step, stepNumber, this._stepListLayer.width, this._onStepRemoved, this);
				return new TutorialStepWaitEventEditLayer(step, stepNumber, this._stepListLayer.width, this._onStepRemoved, this);
		}

		return null;
	},

	_clearScenario: function()
	{
		this._scenario.steps = [];
		this._stepListLayer.removeAllChildren();

		this._clearSelection(true);
	},

	_onStepRemoved: function(stepNumber)
	{
		this._scenario.steps.splice(stepNumber, 1);
		this._stepListLayer.removeItem(stepNumber);

		for (var i = stepNumber; i < this._stepListLayer.getItems().length; ++i)
			this._stepListLayer.getItem(i).decreaseStepNumber();

		if(stepNumber === this._selectedStepIndex)
			this._clearSelection(true);
		else if(stepNumber < this._selectedStepIndex)
			--this._selectedStepIndex;
	},

	_onSelectStep: function(sender, event)
	{
		if(event === ccui.ListView.ON_SELECTED_ITEM_END)
		{
			if(this._selectedStepIndex !== this._stepListLayer.getCurSelectedIndex())
			{
				if (this._selectedStepIndex !== -1)
				{
					var oldStepLayer = this._stepListLayer.getItem(this._selectedStepIndex);
					oldStepLayer.setSelectionVisible(false);
				}

				this._selectedStepIndex = this._stepListLayer.getCurSelectedIndex();

				this._moveSelectionLayer();
				this._renderSelectedStep();
			}
		}
	},

	_moveSelectionLayer: function()
	{
		if(this._selectedStepIndex === -1)
			return;

		var stepLayer = this._stepListLayer.getItem(this._selectedStepIndex);
		this._stepListLayer.jumpToItem(this._selectedStepIndex, cc.p(0.5, 0.5), cc.p(0, 0));

		stepLayer.setSelectionVisible(true);

		this._setUpDownButtonStates();
	},

	_clearSelection: function(clearIndex)
	{
		if(clearIndex)
		{
			if (this._selectedStepIndex !== -1)
			{
				var oldStepLayer = this._stepListLayer.getItem(this._selectedStepIndex);

				if (oldStepLayer)
					oldStepLayer.setSelectionVisible(false);
			}

			this._selectedStepIndex = -1;
			this._setUpDownButtonStates();
		}

		this._drawLayer.clear();
		this._arrowSprite.setVisible(false);
	},

	_renderSelectedStep: function()
	{
		if(this._selectedStepIndex === -1)
			return;

		var step = this._scenario.steps[this._selectedStepIndex];
		this._arrowSprite.setVisible(false);

		switch(step.type)
		{
			case pm.TutorialStepType.CLICK_OBJECT:

				var object = pm.tutorialUtils.getTutorialObjectByName(step.data).object;

				if(!object)
					return;

				var rect = cc.rectApplyAffineTransform(object.getBoundingBox(), object.getParent().getNodeToWorldTransform());
				this._renderClickObjectOrAreaStep(rect);

				break;
			case pm.TutorialStepType.DRAG_OBJECT:
				var fromObject = pm.tutorialUtils.getTutorialObjectByName(step.data.from).object;
				var toObject = pm.tutorialUtils.getTutorialObjectByName(step.data.to).object;

				if(!fromObject || !toObject)
					return;

				var fromRect = cc.rectApplyAffineTransform(fromObject.getBoundingBox(), fromObject.getParent().getNodeToWorldTransform());
				var toRect = cc.rectApplyAffineTransform(toObject.getBoundingBox(), toObject.getParent().getNodeToWorldTransform());

				this._renderDragObjectStep(fromRect, toRect);

				break;
			case pm.TutorialStepType.MOVE_TO:
				var fromObject = pm.tutorialUtils.getTutorialObjectByName(step.data.object).object;

				if(!fromObject)
					return;

				var fromRect = cc.rectApplyAffineTransform(fromObject.getBoundingBox(), fromObject.getParent().getNodeToWorldTransform());

				this._renderMoveToStep(fromRect, step.data.delta, step.data.radius);
				break;
			case pm.TutorialStepType.CLICK_AREA:
				var object = pm.tutorialUtils.getTutorialObjectByName(step.data.object).object;

				if(!object)
					return;

				var rect = cc.rectApplyAffineTransform(step.data.rect, object.getNodeToWorldTransform());
				this._renderClickObjectOrAreaStep(rect);

				break;
			case pm.TutorialStepType.WAIT_EVENT:
				this._drawLayer.clear();
				break;
		}

	},

	_renderClickObjectOrAreaStep: function(objectRect)
	{
		this._drawLayer.clear();

		this._drawLayer.drawRect(
			cc.p(objectRect.x, objectRect.y),
			cc.p(objectRect.x + objectRect.width, objectRect.y + objectRect.height),
			null,
			TutorialEditLayer.RENDER_LINE_WIDTH,
			TutorialEditLayer.RENDER_COLOR
		);
	},

	_renderDragObjectStep: function(fromRect, toRect)
	{
		this._drawLayer.clear();

		this._drawLayer.drawRect(
			cc.p(fromRect.x, fromRect.y),
			cc.p(fromRect.x + fromRect.width, fromRect.y + fromRect.height),
			null,
			TutorialEditLayer.RENDER_LINE_WIDTH,
			TutorialEditLayer.RENDER_COLOR
		);

		this._drawLayer.drawRect(
			cc.p(toRect.x, toRect.y),
			cc.p(toRect.x + toRect.width, toRect.y + toRect.height),
			null,
			TutorialEditLayer.RENDER_LINE_WIDTH,
			TutorialEditLayer.RENDER_COLOR
		);

		this._drawArrow(
			cc.p(fromRect.x + fromRect.width / 2, fromRect.y + fromRect.height / 2),
			cc.p(toRect.x + toRect.width / 2, toRect.y + toRect.height / 2)
		);

	},

	_renderDragObject: function(fromRect, toPoint)
	{
		this._drawLayer.clear();

		this._drawLayer.drawRect(
			cc.p(fromRect.x, fromRect.y),
			cc.p(fromRect.x + fromRect.width, fromRect.y + fromRect.height),
			null,
			TutorialEditLayer.RENDER_LINE_WIDTH,
			TutorialEditLayer.RENDER_COLOR
		);
		this._drawArrow(
			cc.p(fromRect.x + fromRect.width / 2, fromRect.y + fromRect.height / 2),
			cc.p(toPoint.x, toPoint.y)
		);

	},

	_renderMoveToStep: function(fromRect, delta, radius)
	{
		this._drawLayer.clear();

		this._drawLayer.drawRect(
			cc.p(fromRect.x, fromRect.y),
			cc.p(fromRect.x + fromRect.width, fromRect.y + fromRect.height),
			null,
			TutorialEditLayer.RENDER_LINE_WIDTH,
			TutorialEditLayer.RENDER_COLOR
		);

		var fromPoint = cc.p(fromRect.x + fromRect.width / 2, fromRect.y + fromRect.height / 2);
		var toPoint = cc.pAdd(fromPoint, delta);

		this._drawLayer.drawCircle(
			toPoint, radius, 0, 50, false,
			TutorialEditLayer.RENDER_LINE_WIDTH,
			TutorialEditLayer.RENDER_COLOR
		);

		this._drawArrow(fromPoint, toPoint);
	},

	_drawArrow: function(from, to)
	{
		this._arrowSprite.setVisible(true);

		var length = cc.pDistance(from, to);

		this._arrowSprite.setPosition(from);
		this._arrowSprite.setContentSize(length, this._arrowSprite.height);

		var dx = to.x - from.x;
		var dy = to.y - from.y;

		var product = dx/Math.sqrt(dx * dx + dy * dy);
		var angle = Math.acos(product);

		if(dy > 0)
			angle = 2 * Math.PI - angle;

		this._arrowSprite.setRotation(cc.radiansToDegrees(angle));
	},

	_isObjectClickable: function(tutorialObj)
	{
		return tutorialObj.object instanceof ccui.Button;
	},

	_touchBegan: function(touch, event)
	{
		if(!this.enabled)
			return false;

		this._touchType = -1;
		var touchPoint = this.convertTouchToNodeSpace(touch);

		if(cc.rectContainsPoint(this._headerLayout.getBoundingBox(), this._scenarioLayout.convertTouchToNodeSpace(touch)))
		{
			this._touchType = TutorialEditLayer.TouchType.DRAG_SCENARIO_LAYOUT;
			return true;
		}

		if(cc.rectContainsPoint(this._scenarioLayout.getBoundingBox(), touchPoint))
			return false;

		var selectedStep = this._scenario.steps[this._selectedStepIndex];

		if(selectedStep)
		{
			this._touchType = TutorialEditLayer.TouchType.CLICK_NEW;

			switch(selectedStep.type)
			{
				case pm.TutorialStepType.WAIT_EVENT:
				case pm.TutorialStepType.CLICK_OBJECT:
				case pm.TutorialStepType.DRAG_OBJECT:
					break;

				case pm.TutorialStepType.MOVE_TO:
					var fromObject = pm.tutorialUtils.getTutorialObjectByName(selectedStep.data.object).object;

					if(!fromObject)
						return false;

					var fromRect = cc.rectApplyAffineTransform(fromObject.getBoundingBox(), fromObject.getParent().getNodeToWorldTransform());
					var fromPoint = cc.p(fromRect.x + fromRect.width / 2, fromRect.y + fromRect.height / 2);
					var toPoint = cc.pAdd(fromPoint, selectedStep.data.delta);
					var dx = touchPoint.x - toPoint.x;
					var dy = touchPoint.y - toPoint.y;

					if(dx * dx + dy * dy <= selectedStep.data.radius * selectedStep.data.radius)
						this._touchType = TutorialEditLayer.TouchType.CLICK_SELECTED;

					break;

				case pm.TutorialStepType.CLICK_AREA:
					var object = pm.tutorialUtils.getTutorialObjectByName(selectedStep.data.object).object;

					if(!object)
						return;

					var rect = cc.rectApplyAffineTransform(selectedStep.data.rect, object.getNodeToWorldTransform());

					if(cc.rectContainsPoint(cc.rect(rect.x + (rect.width - 10), rect.y, 10, 10), touchPoint))
						this._touchType = TutorialEditLayer.TouchType.RESIZE_SELECTED_AREA;
					else if(cc.rectContainsPoint(rect, touchPoint))
						this._touchType = TutorialEditLayer.TouchType.DRAG_SELECTED_AREA;

					break;
			}
		}
		else
		{
			this._touchType = TutorialEditLayer.TouchType.CLICK_NEW;
		}

		this._startPoint = touchPoint;

		if(this._touchType === TutorialEditLayer.TouchType.CLICK_NEW)
		{
			this._clickedObject = pm.tutorialUtils.getTutorialObjectByPoint(touchPoint);

			if(this._clickedObject)
			{
				this._clearSelection(true);

				if(this._isObjectClickable(this._clickedObject))
					this._newStepType = pm.TutorialStepType.CLICK_OBJECT;
				else
					this._newStepType = pm.TutorialStepType.CLICK_AREA;
			}
			else
			{ this._touchType = -1; }
		}

		return this._touchType !== -1;
	},

	_touchMoved: function(touch)
	{
		if(this._touchType === TutorialEditLayer.TouchType.DRAG_SCENARIO_LAYOUT)
		{
			var delta = touch.getDelta();
			this._scenarioLayout.setPosition(cc.pAdd(this._scenarioLayout.getPosition(), delta));
			this._moveSelectionLayer();

			return;
		}

		var touchPoint = this.convertTouchToNodeSpace(touch);
		var moved = cc.pDistance(this._startPoint, touchPoint) > 5;

		if(!moved)
			return;

		if(this._touchType === TutorialEditLayer.TouchType.CLICK_NEW)
		{
			if(this._clickedObject.isDraggable)
			{
				this._touchType = TutorialEditLayer.TouchType.DRAG_NEW;
				this._newStepType = pm.TutorialStepType.MOVE_TO;
			}
			else if(this._newStepType === pm.TutorialStepType.CLICK_AREA)
			{
				this._touchType = TutorialEditLayer.TouchType.DRAG_NEW;
			}
		}
		else if(this._touchType === TutorialEditLayer.TouchType.CLICK_SELECTED)
		{
			this._touchType = TutorialEditLayer.TouchType.DRAG_TO_AREA;
		}

		switch(this._touchType)
		{
			case TutorialEditLayer.TouchType.DRAG_NEW:
				if(this._newStepType === pm.TutorialStepType.MOVE_TO)
				{
					var fromRect = cc.rectApplyAffineTransform(
						this._clickedObject.object.getBoundingBox(),
						this._clickedObject.object.getParent().getNodeToWorldTransform()
					);

					this._renderDragObject(fromRect, touchPoint);
				}
				else if(this._newStepType === pm.TutorialStepType.CLICK_AREA)
				{
					var dx = touchPoint.x - this._startPoint.x;
					var dy = touchPoint.y - this._startPoint.y;

					var origin = cc.p(0, 0);

					if(dx > 0)
						origin.x = this._startPoint.x;
					else
						origin.x = touchPoint.x;

					if(dy > 0)
						origin.y = this._startPoint.y;
					else
						origin.y = touchPoint.y;

					var rect = cc.rect(origin.x, origin.y, Math.abs(dx), Math.abs(dy));

					this._renderClickObjectOrAreaStep(rect);
				}

				break;
			case TutorialEditLayer.TouchType.DRAG_TO_AREA:
				var step = this._scenario.steps[this._selectedStepIndex];
				step.data.delta = cc.pAdd(step.data.delta, touch.getDelta());

				this._renderSelectedStep();

				break;

			case TutorialEditLayer.TouchType.DRAG_SELECTED_AREA:
				var step = this._scenario.steps[this._selectedStepIndex];

				var delta = touch.getDelta();

				step.data.rect.x += delta.x;
				step.data.rect.y += delta.y;

				this._renderSelectedStep();

				break;

			case TutorialEditLayer.TouchType.RESIZE_SELECTED_AREA:
				var step = this._scenario.steps[this._selectedStepIndex];

				var delta = touch.getDelta();

				if(step.data.rect.width + delta.x > 0 && step.data.rect.height + delta.y > 0)
				{
					step.data.rect.width += delta.x;
					step.data.rect.height -= delta.y;
					step.data.rect.y += delta.y;
					this._renderSelectedStep();
				}

				break;
		}
	},

	_touchEnded: function(touch)
	{
		switch(this._touchType)
		{
			case TutorialEditLayer.TouchType.CLICK_NEW:

				if(this._newStepType === pm.TutorialStepType.CLICK_OBJECT)
				{
					var step = new pm.data.TutorialStep();
					step.type = this._newStepType;

					step.data = this._clickedObject.name;

					this._addStep(step, true);
				}

				break;

			case TutorialEditLayer.TouchType.DRAG_NEW:

				if(this._newStepType === pm.TutorialStepType.CLICK_AREA)
				{
					var step = new pm.data.TutorialStep();
					step.type = this._newStepType;

					var touchPoint = this._clickedObject.object.convertTouchToNodeSpace(touch);
					var startPoint = this._clickedObject.object.convertToNodeSpace(this._startPoint);

					var dx = touchPoint.x - startPoint.x;
					var dy = touchPoint.y - startPoint.y;

					var origin = cc.p(0, 0);

					if(dx > 0)
						origin.x = startPoint.x;
					else
						origin.x = touchPoint.x;

					if(dy > 0)
						origin.y = startPoint.y;
					else
						origin.y = touchPoint.y;

					step.data = {
						object: this._clickedObject.name,
						rect: cc.rect(origin.x, origin.y, Math.abs(dx), Math.abs(dy))
					};

					this._addStep(step, true);
				}
				else if(this._newStepType === pm.TutorialStepType.MOVE_TO)
				{
					var step = new pm.data.TutorialStep();
					var touchPoint = this.convertTouchToNodeSpace(touch);

					var dropObject = pm.tutorialUtils.getTutorialObjectByPoint(touchPoint);

					if(dropObject && dropObject.isDroppable)
					{
						step.type = pm.TutorialStepType.DRAG_OBJECT;
						step.data = {
							from: this._clickedObject.name,
							to: dropObject.name
						};
					}
					else
					{
						step.type = pm.TutorialStepType.MOVE_TO;
						step.data = {
							object: this._clickedObject.name,
							delta: cc.pSub(touchPoint, this._startPoint),
							radius: TutorialEditLayer.DEFAULT_MOVE_TO_RADIUS
						};
					}

					this._addStep(step, true);
				}

				break;

			case TutorialEditLayer.TouchType.CLICK_SELECTED:

				var step = this._scenario.steps[this._selectedStepIndex];

				if(step.type !== pm.TutorialStepType.MOVE_TO)
					return;

				step.data.radius += TutorialEditLayer.MOVE_TO_RADIUS_STEP;

				if(step.data.radius > TutorialEditLayer.MAX_MOVE_TO_RADIUS)
					step.data.radius = TutorialEditLayer.DEFAULT_MOVE_TO_RADIUS;

				this._renderSelectedStep();

				break;
		}

		this._touchType = -1;
		this._clickedObject = null;
		this._startPoint = null;
		this._newStepType = null;
	}
});

TutorialEditLayer.TouchType = {
	CLICK_SELECTED: 0,
	CLICK_NEW: 1,
	DRAG_SELECTED_AREA: 2,
	RESIZE_SELECTED_AREA: 3,
	DRAG_TO_AREA: 4,
	DRAG_NEW: 5,
	DRAG_SCENARIO_LAYOUT: 6
};

TutorialEditLayer.RENDER_LINE_WIDTH = 4;
TutorialEditLayer.RENDER_COLOR = cc.color(255, 0, 0);
TutorialEditLayer.LIST_ITEM_SEPARATOR = 10;
TutorialEditLayer.SCENARIO_LAYOUT_WIDTH = 405.0;
TutorialEditLayer.SELECTED_STEP_COLOR = cc.color(144, 144, 144);
TutorialEditLayer.DEFAULT_MOVE_TO_RADIUS = 40.0;
TutorialEditLayer.MOVE_TO_RADIUS_STEP = 10.0;
TutorialEditLayer.MAX_MOVE_TO_RADIUS = 100.0;
TutorialEditLayer.BORDER = 25;
TutorialEditLayer.MIDDLE_BORDER = 41;
TutorialEditLayer.BACKGROUND_BORDER = 15;
TutorialEditLayer.SEPARATOR = 5;
TutorialEditLayer.SHIFT = -4;
TutorialEditLayer.MIDDLE_SEPARATOR = 35;
TutorialEditLayer.BACKGROUND_COLOR = cc.color(135, 160, 180);

/**
 * Array of step mapping for select.
 * @type {Array<{event:String, string:String}>}
 */
TutorialEditLayer.STEP_MAP = [{
	step: pm.TutorialStepType.WAIT_EVENT, locString: "WaitForEvent"
}];
