/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class Layer for {@link pm.data.KumirMap}.
 * @implements SquarableMapLayerWithWalls
 */
var KumirMapLayer = SquarableMapLayerWithWalls.extend(/** @lends KumirMapLayer#*/{
	ctor: function(map)
	{
		this._super(map);
		this._orientation = pm.MapLayer2D.Orientation.Ortho;
		this._mapElementSize = cc.size(64, 64);
	},

	hasChangeOrientationOption: function()
	{
		return false;
	}
});
