/**
 * Created by Kirill Mashchenko on 09.11.18.
 */

/**
 * @class Layout for common market information.
 * @extends pmui.OvalLayout
 */
var InfoMarketLayer = pmui.OvalLayout.extend(/** @lends InfoMarketLayer# */{
    _target: null,
    _cancelCallback: null,

    ctor: function(target, cancelCallback)
    {
        this._super();
        this.setLayoutType(ccui.Layout.RELATIVE);

        this.setContentSizeWithBorder(InfoMarketLayer.WIDTH, InfoMarketLayer.HEIGHT);

        this.setAnchorPoint(cc.p(0.5, 0.5));
        this.setPosition(pm.settings.getScreenSize().width / 2, pm.settings.getScreenSize().height / 2);

        this.setTouchEnabled(true);

        this._target = target;
        this._cancelCallback = cancelCallback;

        var loadingLayer = new LoadingLayer(false);
        loadingLayer.setPosition((this.width - pm.settings.getScreenSize().width) / 2,
            (this.height - pm.settings.getScreenSize().height) / 2);

        this.addChild(loadingLayer, -100);

        this._loadInterface();
    },

    _loadInterface: function()
    {
        var closeButton = new pmui.Button(pm.spriteUtils.getIconName("close", pm.NORMAL_STATE),
            pm.spriteUtils.getIconName("close", pm.SELECTED_STATE),
            pm.spriteUtils.getIconName("close", pm.DISABLED_STATE),
            ccui.Widget.PLIST_TEXTURE
        );

        closeButton.addClickEventListener(this._cancelCallback.bind(this._target));

        var closeButtonAlign = new ccui.RelativeLayoutParameter();
        closeButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
        closeButtonAlign.setMargin(0, -closeButton.height/4, -closeButton.width/4, 0);
        closeButton.setLayoutParameter(closeButtonAlign);

        this.addChild(closeButton, 1);

        var mainLabel = new ccui.Text("Восстановление покупок", pm.settings.fontName, 30);

        var mainLabelAlign = new ccui.RelativeLayoutParameter();
        mainLabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
        mainLabelAlign.setMargin(0, InfoMarketLayer.MAIN_SEPARATOR + pmui.OvalLayout.STANDARD_BORDER_RADIUS, 0, 0);
        mainLabelAlign.setRelativeName("mainLabel");
        mainLabel.setLayoutParameter(mainLabelAlign);

        this.addChild(mainLabel);

        var commonLabel = new ccui.Text("Для доступа к ранее купленным Мирам необходимо:", pm.settings.fontName, pm.settings.fontSize);
        commonLabel.setTextAreaSize(cc.size(this.width - 2 * InfoMarketLayer.MARGIN, 0));

        var commonLabelAlign = new ccui.RelativeLayoutParameter();
        commonLabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
        commonLabelAlign.setMargin(InfoMarketLayer.MARGIN, 2 * InfoMarketLayer.MAIN_SEPARATOR + mainLabel.height + pmui.OvalLayout.STANDARD_BORDER_RADIUS, 0, 0);
        commonLabelAlign.setRelativeName("commonLabel");
        commonLabel.setLayoutParameter(commonLabelAlign);

        this.addChild(commonLabel);

        var firstLabel = new ccui.Text("1. Войти в аккаунт на устройстве где была совершена покупка.", pm.settings.fontName, pm.settings.fontSize);
        firstLabel.setTextAreaSize(cc.size(this.width - 2 * InfoMarketLayer.MARGIN, 0));

        var firstLabelAlign = new ccui.RelativeLayoutParameter();
        firstLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
        firstLabelAlign.setMargin(0, InfoMarketLayer.SEPARATOR, 0, 0);
        firstLabelAlign.setRelativeToWidgetName("commonLabel");
        firstLabelAlign.setRelativeName("firstLabel");
        firstLabel.setLayoutParameter(firstLabelAlign);

        this.addChild(firstLabel);

        var secondLabel = new ccui.Text("2. Зайти в магазин", pm.settings.fontName, pm.settings.fontSize);
        secondLabel.setTextAreaSize(cc.size(this.width - 2 * InfoMarketLayer.MARGIN, 0));

        var secondLabelAlign = new ccui.RelativeLayoutParameter();
        secondLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
        secondLabelAlign.setMargin(0, InfoMarketLayer.SEPARATOR, 0, 0);
        secondLabelAlign.setRelativeToWidgetName("firstLabel");
        secondLabelAlign.setRelativeName("secondLabel");
        secondLabel.setLayoutParameter(secondLabelAlign);

        this.addChild(secondLabel);

        var thirdLabel = new ccui.Text("3. Нажать кнопку \"Восстановить покупки\".", pm.settings.fontName, pm.settings.fontSize);
        thirdLabel.setTextAreaSize(cc.size(this.width - 2 * InfoMarketLayer.MARGIN, 0));

        var thirdLabelAlign = new ccui.RelativeLayoutParameter();
        thirdLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
        thirdLabelAlign.setMargin(0, InfoMarketLayer.SEPARATOR, 0, 0);
        thirdLabelAlign.setRelativeToWidgetName("secondLabel");
        thirdLabelAlign.setRelativeName("thirdLabel");
        thirdLabel.setLayoutParameter(thirdLabelAlign);

        this.addChild(thirdLabel);

        var fourthLabel = new ccui.Text("4. Войти в аккаунт на другом устройстве. Ранее купленные Миры будут добавлены автоматически.", pm.settings.fontName, pm.settings.fontSize);
        fourthLabel.setTextAreaSize(cc.size(this.width - 2 * InfoMarketLayer.MARGIN, 0));

        var fourthLabelAlign = new ccui.RelativeLayoutParameter();
        fourthLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
        fourthLabelAlign.setMargin(0, InfoMarketLayer.SEPARATOR, 0, 0);
        fourthLabelAlign.setRelativeToWidgetName("thirdLabel");
        fourthLabelAlign.setRelativeName("fourthLabel");
        fourthLabel.setLayoutParameter(fourthLabelAlign);

        this.addChild(fourthLabel);

        var okButton = new pmui.InterfaceButton(this._cancelCallback, this._target, "Понятно", 25);

        var okButtonAlign = new ccui.RelativeLayoutParameter();
        okButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
        okButtonAlign.setRelativeToWidgetName("fourthLabel");
        okButtonAlign.setMargin(0, 3 * InfoMarketLayer.SEPARATOR, 0, 0);
        okButton.setLayoutParameter(okButtonAlign);

        this.addChild(okButton);
    }
});

InfoMarketLayer.WIDTH = 500;
InfoMarketLayer.HEIGHT = 425;
InfoMarketLayer.MAIN_SEPARATOR = 30.0;
InfoMarketLayer.MARGIN = 40;
InfoMarketLayer.SEPARATOR = 5;

InfoMarketLayer.TAG = 8145;
