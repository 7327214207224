/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class pm.Task for painting elements.</br>
 * Contains number of elements to paint.
 * @implements pm.Task
 * @constructor
 * @param {pm.AbstractLevel} level
 */
pm.data.KumirTask = pm.Task.extend(/** @lends pm.data.KumirTask*/ {
	typeName: "KumirTask",

	ctor: function(level)
	{
		this._super(level);
	},

	getNotCompletedObjects: function (mapIndex)
	{
		var painted = 0;
		var badPainted = 0;
		var targetPainted = 0;

		var map = this._level.maps[mapIndex];

		var retArray = [];

		for (var x = 0; x < map.width; ++x)
		{
			for (var y = 0; y < map.height; ++y)
			{
				if (map.element(cc.p(x, y)).isPainted())
					++painted;
				else if (map.element(cc.p(x, y)).isBadPainted())
					++badPainted;
				else if (map.element(cc.p(x, y)).isTargetPainted())
					++targetPainted;
			}
		}

		if (!(badPainted === 0 && painted === this.data[mapIndex] && targetPainted === map._paintTargetElements.length))
		{
			for (var x = 0; x < map.width; ++x)
			{
				for (var y = 0; y < map.height; ++y)
				{
					if (map.element(cc.p(x, y)).getType() === KumirMapElementType.UnpaintedGrass)
						retArray.push(map.element(cc.p(x, y)));
					else if (map.element(cc.p(x, y)).isBadPainted())
						retArray.push(map.element(cc.p(x, y)));
					else if(map.element(cc.p(x, y))._paintTarget && !map.element(cc.p(x, y)).isTargetPainted())
						retArray.push(map.element(cc.p(x, y)));
				}
			}
		}

		return retArray;
	},

	fillWithInitialData: function(mapIndex, data)
	{
		if(data === undefined)
			data = 0;

		this.data[mapIndex] = data;
	}

});
