/**
 * Created by VK on 25.03.2021.
 */

/**
 * @class Represents program of one robot.
 */
var ProgramTreeCreator = cc.Class.extend({

    _visualTree: null,

    ctor: function (programTree, robot, programContainer) {},

    addOutput: function (outputRobot) {},

    highlightMethod: function (nodeView)
    {
        this._visualTree.highlightMethod(nodeView);
    },

    highlightMethodBroken: function (nodeView)
    {
        this._visualTree.highlightMethodBroken(nodeView);
    },

    clearLastHighlight: function ()
    {
        this._visualTree.clearLastHighlight();
    },

    clearAllHighlight: function ()
    {
        this._visualTree.clearAllHighlight();
    },

    getVisualTree: function()
    {
        return this._visualTree.getVisualTree();
    },

    updateTree: function (node, parent)
    {
        this._visualTree.updateTree(node, parent);
    },

    reset: function ()
    {
        this.clearAllHighlight();
    },

    switchPointsInRepeater: function (nodeView, num, rep)
    {
        this._visualTree.switchPointsInRepeater(nodeView, num, rep);
    },

    reloadRepeaters: function()
    {
        this._visualTree.reloadRepeaters();
    },

    pasteNode: function (parent, button, selectedElem) {},

    pasteNodeList: function (element, methodList) {},

    moveNode: function(node, selectedNode) {}
});
