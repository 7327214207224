/**
 * Created by Antony Orlovsky on 12.03.20.
 */

/**
 * @class Colored layout with circle bounds for dialog.
 * @extends ccui.Layout
 * @constructor
 * @param {cc.Size} [size] Size of layer.
 */
var DialogLayout = ccui.Layout.extend(/** @lends pmui.DialogLayout# */{

	_gradientLayer: null,
	_scroll: null,
	_text: null,

	ctor: function(size, text)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);
		this.setBackGroundImage("System/BL_Background.png");
		this.setBackGroundImageScale9Enabled(true);
		this.setCascadeOpacityEnabled(true);
		this.setBackGroundImageCapInsets(cc.rect(89, 89, 72, 72));

		this._gradientLayer = new ccui.Layout();
		this._gradientLayer.setContentSize(this.width - 4 * DialogLayout.BORDER_RADIUS, this.height - 2 * DialogLayout.BORDER_RADIUS);
		this._gradientLayer.setBackGroundColorType(ccui.Layout.BG_COLOR_GRADIENT);
		this._gradientLayer.setBackGroundColorVector(cc.p(0, -1));
		this._gradientLayer.setBackGroundColor(pmui.OvalLayout.GRAD_START_COLOR, pmui.OvalLayout.GRAD_END_COLOR);

		var gradientLayerAlign = new ccui.RelativeLayoutParameter();
		gradientLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		gradientLayerAlign.setRelativeName("gradient");
		gradientLayerAlign.setMargin(0, DialogLayout.BORDER_RADIUS, DialogLayout.BORDER_RADIUS, 0);
		this._gradientLayer.setLayoutParameter(gradientLayerAlign);

		this.addChild(this._gradientLayer, -1);

		this._scroll = new ccui.ListView();

		this._scroll.setBounceEnabled(true);
		this._scroll.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._scroll.setScrollBarEnabled(false);

		var scrollAlign = new ccui.RelativeLayoutParameter();
		scrollAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		scrollAlign.setRelativeName("scroll");
		scrollAlign.setMargin(0, 2 * DialogLayout.BORDER_RADIUS, 2 * DialogLayout.BORDER_RADIUS, 0);
		this._scroll.setLayoutParameter(scrollAlign);

		this.addChild(this._scroll);

		this._text = new ccui.Text(text || "", pm.settings.fontName, 20);
		this._text.setTextHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

		this._scroll.pushBackCustomItem(this._text);

		if(size)
			this.setContentSizeWithBorder(size);
	},

	setContentSizeWithBorder: function(size, height)
	{
		var width = size;
		if (height === undefined)
		{
			width = size.width + 4 * DialogLayout.BORDER_RADIUS;
			height = size.height + 2 * DialogLayout.BORDER_RADIUS;
		}
		else
		{
			width += 4 * DialogLayout.BORDER_RADIUS;
			height += 2 * DialogLayout.BORDER_RADIUS;
		}

		this.setContentSize(width, height);

		this._gradientLayer.setContentSize(this.width - 4 * DialogLayout.BORDER_RADIUS, this.height - 2 * DialogLayout.BORDER_RADIUS);
		this._text.setTextAreaSize(cc.size(this.width - 6 * DialogLayout.BORDER_RADIUS, 0));
		this._scroll.setContentSize(this.width - 6 * DialogLayout.BORDER_RADIUS, this.height - 4 * DialogLayout.BORDER_RADIUS);

	},

	setText: function(text)
	{
		this._text.setText(text);
	}
});

DialogLayout.BORDER_RADIUS = 14;