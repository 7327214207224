/**
 * Created by Kirill Mashchenko on 07.07.2018.
 */

/**
 * Types of {@link pm.data.PushMapElement}.
 * @enum {Number}
 * @readonly
 */
var TrainMapElementType = {
	Grass: 0,
	CarTarget: 1,
	BarrelTarget: 2,
	CommonTarget: 3,
};

/**
 * @class Elements of {@link pm.data.TrainMap}.
 * @implements pm.MapElement
 */
pm.data.TrainMapElement = pm.MapElement4WithWalls.extend(/** @lends pm.data.TrainapElement# */{
	typeName: "TrainMapElement",

	ctor: function ()
	{
		this._super();
	}
});
