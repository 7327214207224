/**
 * Created by Ekaterina Ermishkina on 24.08.16.
 */

/**
 * @class pm.Task for light elements.</br>
 * @implements pm.Task
 * @constructor
 * @param {pm.AbstractLevel} level
 */

pm.data.LightTask = pm.Task.extend(/** @lends pm.data.LightTask*/ {
	typeName: "LightTask",

	ctor: function(level)
	{
		this._super(level);
	},

	getNotCompletedObjects: function (mapIndex)
	{
		var objects = this._level.maps[mapIndex].objects;

		var retArray = [];

		for(var j = 0; j < objects.length; ++j)
		{
			var object = objects[j];

			if (!(object instanceof pm.data.LightLamp))
				continue;

			if (!object.isLightened())
				retArray.push(this._level.maps[mapIndex].element(object.position));
		}

		return retArray;
	}
});
