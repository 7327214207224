/**
 * Created by Antony Orlovsky on 19.07.19.
 */

/**
 * @class pm.Task for painting elements.</br>
 * Contains number of elements to paint.
 * @implements pm.Task
 * @constructor
 * @param {pm.AbstractLevel} level
 */
pm.data.GrasshopperTask = pm.Task.extend(/** @lends pm.data.GrasshopperTask*/ {
	typeName: "GrasshopperTask",

	ctor: function(level)
	{
		this._super(level);
	},

	getNotCompletedObjects: function (mapIndex)
	{
		var painted = 0;
		var badPainted = 0;

		var map = this._level.maps[mapIndex];

		var retArray = [];

		for (var x = 0; x < map.width; ++x)
		{
			if(map.element(cc.p(x, 0)).isPainted())
				++painted;
			else if(map.element(cc.p(x, 0)).isBadPainted())
				++badPainted;
		}

		if (!(badPainted === 0 && painted === this.data[mapIndex]))
		{
			for (var x = 0; x < map.width; ++x)
			{
				if (map.element(cc.p(x, 0)).getType() === GrasshopperMapElementType.UnpaintedGrass)
					retArray.push(map.element(cc.p(x, 0)));
				else if (map.element(cc.p(x, 0)).isBadPainted())
					retArray.push(map.element(cc.p(x, 0)));
			}
		}

		return retArray;
	},

	fillWithInitialData: function(mapIndex, data)
	{
		if(data === undefined)
			data = 0;

		this.data[mapIndex] = data;
	}
});
