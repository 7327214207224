/**
 * Created by Antony Orlovsky on 09.08.19.
 */

/**
 * @class Layer for turtle robot settings.
 * @extends PlayerRobotSettingsLayer
 * @constructor
 * @param {pm.AbstractRobot} robot Robot which settings need to display.
 */
var TurtleRobotSettingsLayer = PlayerRobotSettingsLayer.extend(/** @lends TurtleRobotSettingsLayer# */{

	ctor: function(robot)
	{
		this._super(robot);

		this._label.setString(LocalizedString("Turtle") + " " + robot.id + ", " + LocalizedString("Pattern") + " " + robot.parentLevel.programData[robot.groupID].currentIndex);
	}
});
