/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class 2D Sprite for {@link pm.data.DraftsmanRobot}
 * @implements pm.IsoRobotSprite
 */
var DraftsmanRobotSprite = pm.IsoRobotSprite.extend(/** @lends DraftsmanRobotSprite# */{

	_robot: null,

	ctor: function(robot)
	{
		this._robot = robot;
		cc.animationCache.addAnimations("Robot/draftsman_robot-animations.plist");

		pm.spriteUtils.registerTempSpriteFrames("Robot/draftsman_robot.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/draftsman_robot-broken.plist");

		this._super(cc.spriteFrameCache.getSpriteFrame(this._getFrameName()));

		this._minPoint = cc.p(25, 25);
		this._maxPoint = cc.p(25, 25);
		this.getSprite().setAnchorPoint(cc.p(0, 0));
	},

	_getActiveSpritePosition: function()
	{
		return cc.p(25, 50);
	},

	_getCommandsSpritePosition: function()
	{
		return cc.p(25, 80);
	},

	_getFrameName: function()
	{
		if (this._robot.getPenFlag())
			return "draftsman_robot_pen_down.png";
		else
			return "draftsman_robot_pen_up.png";
	},

	_getDestroyAnimationName: function()
	{
		if (this._robot.getPenFlag())
			return "draftsman_robot_pen_down_d";
		else
			return "draftsman_robot_pen_up_d";
	},

	_move: function(pos, endCallback, target)
	{
		if (!target.getPenFlag())
		{
			pm.RobotSprite2D.prototype._move.call(this, pos, endCallback, target);
			return;
		}

		var targetPos = this.getRealPosition(pos);
		var callback = cc.callFunc(endCallback, target);
		var moveEnd = cc.callFunc(function()
		{
			this._needToUpdateZOrder = false;
		}, this);

		var oldPos = this.getSprite().getPosition();
		var mapElementSize = target.parentLevel.activeMap.mapLayer.getMapElementSize();

		var diffPosX = (targetPos.x - oldPos.x) / mapElementSize.width;
		var diffPosY = (targetPos.y - oldPos.y) / mapElementSize.height;

		var speedRatio = Math.sqrt(diffPosX * diffPosX + diffPosY * diffPosY);
		var moveRobot = cc.moveTo(speedRatio * pm.settings.getAnimationSpeed(), targetPos);

		var length = Math.sqrt((targetPos.x - oldPos.x) * (targetPos.x - oldPos.x) + (targetPos.y - oldPos.y) * (targetPos.y - oldPos.y));
		var rotationAngle = cc.radiansToDegrees(Math.acos((targetPos.x - oldPos.x) / length));

		if (targetPos.y - oldPos.y > 0)
			rotationAngle *= -1;

		var line = new cc.Scale9Sprite("Robot/draftsman_line.png");

		line.setAnchorPoint(cc.p(0, 0.5));
		line.setRotation(rotationAngle);
		line.setPosition(cc.p(oldPos.x - (targetPos.x - oldPos.x) / length,oldPos.y - (targetPos.y - oldPos.y) / length));
		target.parentLevel.activeMap.mapLayer.drawLayer.addChild(line);

		var scaleLine = cc.scaleTo(speedRatio * pm.settings.getAnimationSpeed(), (length + 2) / line.width, 1);

		var moveAction = cc.targetedAction(this.getSprite(), moveRobot);
		var scaleAction = cc.targetedAction(line, scaleLine);

		this._needToUpdateZOrder = true;
		this.getSprite().runAction(cc.sequence(cc.spawn(moveAction, scaleAction), moveEnd, callback));
	}
});
