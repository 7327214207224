/**
 * Created by Kirill Mashchenko on 25.01.18.
 */

pm.GreenFlagRobot = pm.FlagRobot.extend(/** @lends pm.GreenFlagRobot# */{
	typeName: "GreenFlagRobot",

	ctor: function()
	{
		this._super();

		this.nativeFunctionMap[pm.GreenFlagRobot.NativeMethod.Up] = new pm.NativeFunction(this, this._up);
		this.nativeFunctionMap[pm.GreenFlagRobot.NativeMethod.Down] = new pm.NativeFunction(this, this._down);

		this.nativeFunctions.push(pm.GreenFlagRobot.NativeMethod.Up, pm.GreenFlagRobot.NativeMethod.Down);

		this.conditions.push(pm.GreenFlagRobot.Condition.IsUp, pm.GreenFlagRobot.Condition.IsDown);
	},

	_checkConditionInternal: function (condition, args)
	{
		switch(condition)
		{
			case pm.GreenFlagRobot.Condition.IsUp: return this._flagState === pm.FlagRobot.State.Up;
			case pm.GreenFlagRobot.Condition.IsDown: return this._flagState === pm.FlagRobot.State.Down;
		}

		return false;
	},

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new pm.FlagRobotSprite(pm.FlagRobotSprite.Type.Green);

			return this.sprite;
		}
	},

	getType: function ()
	{
		return pm.GlobalRobotType.GreenFlag;
	}
});

pm.GreenFlagRobot.Condition = {
	IsUp: "gflag_isup",
	IsDown: "gflag_isdown"
};

pm.GreenFlagRobot.NativeMethod = {
	Up: "gflag_up",
	Down: "gflag_down"
};
