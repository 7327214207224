/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.FunctionNode = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{
	_bodyNode: null,
	_params: [],
	_name: null,
	_paramsNumber: 0,
	_cont: null,
	_rBrace: null,
	_lBrace: null,

	ctor: function (treeNode, parent, syntaxModule, name)
	{
		this.type = FunctionButton.Type.Function;
		this.isPartlyClonable = true;
		this._name = name;
		if (name !== undefined)
		{
			this._withBorder = true;
		}
		this._super(parent, treeNode, syntaxModule);
		this.setLayoutType(ccui.Layout.RELATIVE);

		this._cont = new ccui.Layout();
		this._cont.setLayoutType(ccui.Layout.RELATIVE);

		pm.syntaxModules.constructFunctionNode(this);

		this.addChild(this._cont);

		this._addTypes = [FunctionButton.Type.Alloc];

		this._params = [];
		pm.syntaxModules.constructEmptyParameter(this);

		if(this._name)
			pm.sendCustomEvent(pm.FUNCTION_PARAMS_CHANGED, {name: this._name, params: this._paramsNumber});
	},

	addParameter: function(type)
	{

	},

	getBody: function()
	{
		return this._bodyNode;
	},

	select: function ()
	{
		if (!this._selected)
		{
			this.setBackGroundImage("PiktomirK/select-func-border.png");
			this.setBackGroundImageScale9Enabled(true);
			this.setCascadeOpacityEnabled(false);
			this.setBackGroundImageCapInsets(cc.rect(15, 15, 10, 10));
			this._selected = true;
			this.setTag(ProgramContainerLayer.SELECTED_TAG);
		}
	},

	removeLinks: function ()
	{
		pmui.FunctionNode.vals.delete(this._name);
		pm.sendCustomEvent(pm.FUNCTION_DEFINITION_REMOVED, this._name);
		this.makeCleanUp();
	},

	isFunction: function()
	{
		return true;
	},

	removeLink: function (link)
	{

	},

	getChildrenNodes: function ()
	{
		return this._params.concat([this._bodyNode]);
	},

	clone: function ()
	{
		if (!this.isMain())
			return new VarFunctionButton(FunctionButton.Type.Function, this._name);
	},

	getNodeId: function ()
	{
		if (!this._name)
			return "main";
		return this._name;
	},

	isMain: function ()
	{
		return this._treeNode.isMain();
	},

	highlight: function ()
	{
		if (!this.isMain())
		{
			this.setBackGroundImage("PiktomirK/highlight.png");
			this.setBackGroundImageCapInsets(cc.rect(15, 15, 10, 10));
			this.setBackGroundImageScale9Enabled(true);
			this.setCascadeOpacityEnabled(false);
		}
	},

	addChildNode: function (node)
	{
		pm.syntaxModules.addChildNodeToFunctionNode(this, node);
	},

	_removeNode: function (node)
	{
		var parent = this._treeNode;
		parent.removeChild(node._treeNode);
		this._cont.removeAllChildren();

		pm.syntaxModules.removeChildNodeFromFunctionNode(this);

		// this._params.splice(this._params.indexOf(node));
		this._params = [];
		this._paramsNumber = 0;
		this._treeNode.children.forEach(function (child){
			if(child.type !== pm.data.Node.Type.Body)
				pm.sendCustomEvent(pm.PARSE_NODE, {node: child, parent: this});
		}, this);

		if(this._params.length === 0)
			pm.syntaxModules.constructEmptyParameter(this);

		pm.sendCustomEvent(pm.FUNCTION_PARAMS_CHANGED, {name: this._name, params: this._paramsNumber});
	},

	canAdd: function(element)
	{
		if(this.isMain())
			return false;
		else {
			return pmui.NodeLayer.prototype.canAdd.call(this, element);
		}
	},

	paramsSelect: function ()
	{
		if (!this._selected)
		{
			this.setBackGroundImage("PiktomirK/select-border.png");
			this.setBackGroundImageScale9Enabled(true);
			this.setCascadeOpacityEnabled(false);
			this.setBackGroundImageCapInsets(cc.rect(15, 15, 10, 10));
			this._selected = true;
			this.setTag(ProgramContainerLayer.SELECTED_TAG);
		}
	},

	getNextParamName: function (type)
	{
		if (type === pm.AllocVals.INT)
		{
			var alfabet = "п";
			var name = alfabet + this._paramsNumber;
			return name;
		}
		else
		{
			var alfabet = "п";
			var name = alfabet + this._paramsNumber;
			return name;
		}
	},

	isFunction: function ()
	{
		return true;
	},

	calculateSize: function (forPar)
	{
		this.sizeOfContainer(this._cont);
		var size = this.sizeOfVContainer(this, true);
		this.setContentSize(size.width + ProgramContainerLayer.SEPARATOR_SIZEX, size.height);
		if (!forPar)
			this._parentNode.calculateSize();
	}
});

pmui.FunctionNode.vals = new SetClass([
]);
pmui.FunctionNode.piktoVals = new SetClass([
	pm.CMD_A,
	pm.CMD_B,
	pm.CMD_C,
	pm.CMD_D,
	pm.CMD_E
]);
pmui.FunctionNode.L_BRACE_TAG = "lbracetag";
pmui.FunctionNode.R_BRACE_TAG = "rbracetag";

