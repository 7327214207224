/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class 2D level where robot can paint some kinds({@link DraftsmanMapElementType}) of elements.
 * @extends pm.AbstractLevel
 */
pm.data.DraftsmanLevel = pm.AbstractLevel.extend(/** @lends pm.data.DraftsmanLevel# */{
	typeName: "DraftsmanLevel",

	ctor: function()
	{
		this._super();

		this.useMethodStack = false;
	},

	getType: function() { return pm.DraftsmanLevelModule.Type; }
});
