/**
 * Created by Nikita Besshaposhnikov on 08.07.15.
 */
/**
 * Push object sprite animation types.
 * @enum {Number}
 */
var PushObjectAnimation = {Move: 0};

/**
 * @class Common sprite interface for push objects.
 * @interface
 * @extends pm.DynamicSprite
 */
var PushObjectSprite = pm.DynamicSprite.extend(/** @lends PushObjectSprite#*/{

	ctor: function(frame)
	{
		this._super(frame);
	},

	playAnimation: function(endCallBack, target, type, args)
	{
		switch(type)
		{
			case PushObjectAnimation.Move: this._move(args, endCallBack, target); break;
		}
	},

	_move: function(target, endCallback, callbackTarget)
	{
		var targetPos = this.getRealPosition(target);

		var moveObject = cc.moveTo(pm.settings.getAnimationSpeed(), targetPos);

		var moveEnd = cc.callFunc(function() { this._needToUpdateZOrder = false; endCallback.call(callbackTarget); }, this);

		this._needToUpdateZOrder = true;

		this.sprite.runAction(cc.sequence(moveObject, moveEnd));
	}
});
