/**
 * Created by Nikita Besshaposhnikov on 16.11.15.
 */

/**
 * @class 2D sprite of {pm.data.PhysicalRobot}.
 * @implements pm.OrthoRobotSprite
 */
var PhysicalRobotSprite = pm.OrthoRobotSprite.extend(/** @lends PhysicalRobotSprite# */{

	_stateRedIndicator: null,
	_stateGreenIndicator: null,

	ctor: function ()
	{
		cc.animationCache.addAnimations("Robot/physical_robot-animations.plist");

		pm.spriteUtils.registerTempSpriteFrames("Robot/physical_robot.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/physical_robot-indicate.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/physical_robot-broken.plist");

		this._super(cc.spriteFrameCache.getSpriteFrame(this._getFrameName(0)));

		this._stateRedIndicator = new cc.Sprite("Robot/bleRobotLightRed.png");
		this._stateRedIndicator.setAnchorPoint(cc.p(0.5, 0.5));
		this._stateRedIndicator.setPosition(cc.p(23, 50));

		this._stateRedIndicator.runAction(cc.repeatForever(cc.sequence(
			cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.9), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.8 * 255)),
			cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, 1), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 255))
		)));

		this.getSprite().addChild(this._stateRedIndicator, 1);

		this._stateGreenIndicator = new cc.Sprite("Robot/bleRobotLightGreen.png");
		this._stateGreenIndicator.setAnchorPoint(cc.p(0.5, 0.5));
		this._stateGreenIndicator.setPosition(cc.p(57, 50));

		this._stateGreenIndicator.runAction(cc.repeatForever(cc.sequence(
			cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.9), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.8 * 255)),
			cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, 1), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 255))
		)));

		this.getSprite().addChild(this._stateGreenIndicator, 1);

		this.changeState(pm.PhysicalConnector.STATE.DISCONNECTED);
	},

	changeState: function(state)
	{
		switch (state)
		{
			case pm.PhysicalConnector.STATE.CONNECTED:
				this._stateRedIndicator.setVisible(false);
				this._stateGreenIndicator.setVisible(true);
				break;
			case pm.PhysicalConnector.STATE.DISCONNECTED:
				this._stateRedIndicator.setVisible(true);
				this._stateGreenIndicator.setVisible(false);
				break;
		}
	},

	_push: function(pos, direction, reverse, endCallback, target)
	{
		var targetBack = this.getRealPosition(pos);
		var targetPos = cc.p(pos.x, pos.y);

		if(!reverse)
		{
			switch (direction)
			{
				case MapDirection4.Up:
					targetPos.y *= 0.5;
					break;
				case MapDirection4.Left:
					targetPos.x *= 0.5;
					break;
				case MapDirection4.Down:
					targetPos.y *= 1.25;
					break;
				case MapDirection4.Right:
					targetPos.x *= 1.25;
					break;
			}

		}
		else
		{
			switch (direction)
			{
				case MapDirection4.Up:
					targetPos.y = (targetPos.y - 1) * 0.5;
					break;
				case MapDirection4.Left:
					targetPos.x = (targetPos.x - 1) * 0.5;
					break;
				case MapDirection4.Down:
					targetPos.y = (targetPos.y + 1) * 1.25;
					break;
				case MapDirection4.Right:
					targetPos.x = (targetPos.x + 1) * 1.25;
					break;
			}
		}

		targetPos = this.getRealPosition(targetPos);

		var callback = cc.callFunc(endCallback, target);

		if(!reverse)
		{
			var moveRobot = cc.moveTo(pm.settings.getAnimationSpeed() * 1.5, targetPos);
			var moveRobotBack = cc.moveTo(pm.settings.getAnimationSpeed() * 0.5, targetBack);

			this.sprite.runAction(cc.sequence(moveRobot, moveRobotBack, callback));
		}
		else
		{
			var moveRobot = cc.moveTo(pm.settings.getAnimationSpeed() * 0.5, targetPos);
			var moveRobotBack = cc.moveTo(pm.settings.getAnimationSpeed() * 1.5, targetBack);

			this.sprite.runAction(cc.sequence(moveRobot, moveRobotBack, callback));
		}
	},

	_getTurnAngle: function(direction)
	{
		return 180 - direction * 90;
	},

	_getFrameName: function (direction)
	{
		var connectionType = pm.settings.getPhysicalConnectorType();

		if(connectionType === pm.PhysicalConnectorType.Lego)
		{
			return "physical_robot_lego.png";
		}
		else
		{
			return "physical_robot_BLE.png";
		}
	},

	_getDestroyAnimationName: function (direction)
	{
		switch(pm.settings.getPhysicalConnectorType())
		{
			case pm.PhysicalConnectorType.Lego:
				return "physical_robot_lego_d";
			case pm.PhysicalConnectorType.Sound:
			case pm.PhysicalConnectorType.BLE:
			case pm.PhysicalConnectorType.None:
				return "physical_robot_BLE_d";

		}
	},

	_getIndicateAnimationName: function (direction)
	{
		switch(pm.settings.getPhysicalConnectorType())
		{
			case pm.PhysicalConnectorType.Lego:
				return "physical_robot_lego_i";
			case pm.PhysicalConnectorType.Sound:
			case pm.PhysicalConnectorType.BLE:
			case pm.PhysicalConnectorType.None:
				return "physical_robot_BLE_i";

		}
	}
});
