/**
 * @class This class represents native function of robot which is used in {@link pm.RobotManager}.
 * @extends cc.Class
 * @constructor
 * @param {pm.AbstractRobot} robot Which robot function is.
 * @param {Function} func Native function of robot.
 */
pm.NativeFunction = cc.Class.extend(/** @lends pm.NativeFunction# */{
	/**
     * Which robot function is.
     * @type {pm.AbstractRobot}
     */
	robot: null,
	/**
     * Reference to method which will be called on {@link pm.NativeFunction#execute} method.
     * @private
     */
	_paramsNumber: 0,
	_nativeMethod: null,
	_returnArgsNumber: 0,

	ctor: function(robot, func, paramsNumber, returnArgsNumber)
	{
		this.robot = robot;
		this._nativeMethod = func;

		if(paramsNumber)
			this._paramsNumber = paramsNumber;

		if(returnArgsNumber)
			this._returnArgsNumber = returnArgsNumber;
	},

	/**
     * Executes native function.
	 * @param {...*} [args] Arguments passed to native function call.
     */
	execute: function(args)
	{
		var res = this._nativeMethod.apply(this.robot, args);

		if(this._returnArgsNumber)
			return res;
		else
			return null;
	},

	getParamsNumber: function()
	{
		return this._paramsNumber;
	},

	returnArgs: function()
	{
		return this._returnArgsNumber;
	}
});

/**
 * Constant for empty robot method.
 * @const
 * @default
 * @type {string}
 */
pm.EMPTY_METHOD = "empty";
/**
 * Constant for main robot function.
 * @const
 * @default
 * @type {string}
 */
pm.CMD_MAIN = "main";
/**
 * Constant for global variables allocation and initialisation function.
 * @const
 * @default
 * @type {string}
 */
pm.CMD_GLOBALS = "global";
/**
 * Constant for "A" robot function.
 * @const
 * @default
 * @type {string}
 */
pm.CMD_A = "A";
/**
 * Constant for "B" robot function.
 * @const
 * @default
 * @type {string}
 */
pm.CMD_B = "B";
/**
 * Constant for "C" robot function.
 * @const
 * @default
 * @type {string}
 */
pm.CMD_C = "C";
/**
 * Constant for an indicate robot method.
 * @const
 * @default
 * @type {string}
 */
pm.CMD_D = "D";
/**
 * Constant for an indicate robot method.
 * @const
 * @default
 * @type {string}
 */
pm.CMD_E = "E";
/**
 * Constant for an indicate robot method.
 * @const
 * @default
 * @type {string}
 */
pm.CMD_INDICATE = "indicate";

/**
 * Constant for an empty function condition.
 * @const
 * @default
 * @type {string}
 */
pm.CONDITION_EMPTY = "empty";
/**
 * Constant for an disabled function condition.
 * @const
 * @default
 * @type {string}
 */
pm.CONDITION_DISABLE = "disable";

/**
 * Max repeater value.
 * @const
 * @default
 * @type {Number}
 */
pm.MAX_REPEATER = 1000;

/**
 * Constant for an empty function repeater.
 * @const
 * @default
 * @type {Number}
 */
pm.REPEATER_EMPTY = 1;
/**
 * Constant for disabled function repeater.
 * @const
 * @default
 * @type {Number}
 */
pm.REPEATER_DISABLE = -2;
/**
 * Maximum function count.
 * @const
 * @type {Number}
 * @default
 */
pm.MAX_FUNCTION_COUNT = 6;
/**
 * Maximum function block width.
 * @const
 * @type {Number}
 * @default
 */
pm.MAX_PROGRAM_WIDTH = 6;

/**
 * Enum for block type.
 * @see pm.data.Method
 * @readonly
 * @enum {Number}
 */
pm.SymbolType = {
	Empty: -1,
	Method: 0,
	Condition: 1,
	Repeater: 2,
	CondRepeater: 3,
	Function: 4
};

pm.data.Symbol = pm.Class.extend(/** @lends pm.data.Symbol# */{

	type: pm.SymbolType.Method,
	value: pm.EMPTY_METHOD
});

pm.data.ProgramDataK = pm.Class.extend(/** @lends pm.data.ProgramData# */{
	typeName: "ProgramData",
	/**
	 * Flag for edit program sizes possibility.
	 * @type {Boolean}
	 */
	canEditProgramPattern: false,
	/**
	 * tree of program
	 * @type {pm.data.SyntaxTree}
	 */
	programTree: null,

	loops: [],
	expressions: [],
	logicOperatorsLessGreater: [],
	logicOperatorsEquals: [],
	logicOperatorsAndOr: [],
	logicOperatorsNot: [],
	alloc: [],
	ifConstructions: [],
	funcs: [],
	conds: [],
	condsOpp: [],
	constants: [],
	intMethods: [],
	maxMethodCount: 10000,
	usedMethodsCount: 0,
	useGroupButtons: true,
	funcsMap: {},
	useCustomFuncs: false,

	ctor: function (isFilled)
	{
		if(isFilled)
			return;

		this.funcsMap = {};
		this.loops = [];
		this.expressions = [];
		this.logicOperatorsLessGreater = [];
		this.logicOperatorsEquals = [];
		this.logicOperatorsAndOr = [];
		this.logicOperatorsNot = [];
		this.alloc = [];
		this.ifConstructions = [];
		this.funcs = [];
		this.conds = [];
		this.condsOpp = [];
		this.constants = [];
		this.intMethods = [];
		this.maxMethodCount = 10000;
		this.usedMethodsCount = 0;
		this.useGroupButtons = true;
		this.useCustomFuncs = true;

		this.programTree = new pm.data.SyntaxTree();
		this.programTree.head = new pm.data.Node(pm.data.Node.Type.Prog, "all", null);
		this.programTree.createGlobals();

		var main = this.programTree.createMainAlg();
		pm.data.SyntaxTree.createNode(main, pm.data.Node.Type.Body, "");
	}

});

pm.data.ProgramDataP = pm.Class.extend(/** @lends pm.data.ProgramData# */{
	typeName: "ProgramData",

	/**
	 * Flag for edit program sizes possibility.
	 * @type {Boolean}
	 */
	canEditProgramPattern: false,

	width: 0,
	height: 0,

	maxFunctionCount: 0,
	maxMethodCount: 0,
	maxRepeaterCount: 0,
	maxConditionCount: 0,
	maxCondRepeaterCount: 0,
	maxDepthConstructCount: 0,

	symbols: [],

	ctor: function (isFilled)
	{
		if (isFilled)
			return;

		this.symbols = [];

		this.width = 6;
		this.height = 100;

		this.maxFunctionCount = 0;
		this.maxMethodCount = 0;
		this.maxRepeaterCount = 0;
		this.maxConditionCount = 0;
		this.maxCondRepeaterCount = 0;
		this.maxDepthConstructCount = 0;

		this.canEditProgramPattern = false;

		for (var i = 0; i < this.height; ++i)
		{
			var symbolString = [];
			for (var j = 0; j < this.width; ++j)
			{
				if (i === 0 && j === 0)
				{
					symbolString.push(new pm.data.Symbol());
				}
				else
				{
					var symbol = new pm.data.Symbol();
					symbol.type = pm.SymbolType.Empty;
					symbol.value = -1;

					symbolString.push(symbol);

				}
			}
			this.symbols.push(symbolString);
		}
	}
});

/**
 * @class This a data container for program data.
 * @extends pm.Class
 * @see pm.AbstractRobot
 */
pm.data.ProgramInfo = pm.Class.extend(/** @lends pm.data.ProgramInfo# */{
	typeName: "ProgramInfo",

	currentIndex: 0,

	programDataArray: [],

	getCurrentProgramData: function()
	{
		return this.programDataArray[this.currentIndex];
	},

	getProgramDataByIndex: function(index)
	{
		return this.programDataArray[index];
	}
});
