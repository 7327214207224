/**
 * Created by VK on 18.03.21.
 */

/**
 * @class Functions for any syntax module.
 * @interface
 * @extends cc.Class
 */

pm.piktoModule = pm.abstractSyntaxModule.extend(/** @lends pm.piktoModule# */{

    getTreeCreator: function (programTree, robot, parent, outputRobot, syntaxModule)
    {
        return new PiktoTreeCreator(programTree, robot, parent);
    },

    isTextual: function ()
    {
        return true;
    }
});

pm.syntaxModules.PIKTO_MODULE_ORDER = 2;
pm.syntaxModules.register(new pm.piktoModule, pm.syntaxModules.PIKTO_MODULE_ORDER);