/**
 * Created by Nikita Besshaposhnikov on 29.03.16.
 */

/**
 * @class Layer for push task.
 * @extends TaskLayer
 * @constructor
 * @param {pm.AbstractLevel} level
 * @param {pm.data.PushTask} task
 */

var PushTaskLayer = TaskLayer.extend(/** @lends PushTaskLayer# */{

	ctor: function(level, task)
	{
		this._super(LocalizedString("PushTask"), level, task, pm.PushLevelModule.Type);
		pm.registerCustomEventListener(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR, this._updateTaskData.bind(this), this);
	},

	_updateTaskData: function()
	{
		this._level.activeMap.updatePushTargetElements();
	}
});
