/**
 * Created by Nikita Besshaposhnikov on 16.11.15.
 */

/**
 * @class 2D robot which represents virtual physical robot.
 * @implements PlayerRobot4
 */
pm.data.PhysicalRobot = PlayerRobot4.extend(/** @lends pm.data.PhysicalRobot# */{
	typeName: "PhysicalRobot",

	ctor: function()
	{
		this._super();
	},

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new PhysicalRobotSprite();

			var state = pm.PhysicalConnector.STATE.DISCONNECTED;

			if(this._PhysicalConnector && this._PhysicalConnector.isConnected())
				state = pm.PhysicalConnector.STATE.CONNECTED;

			this.sprite.changeState(state);

			return this.sprite;
		}
	},

	_moveTo: function (target, reverse)
	{
		if (this.getMap().getVisitedCells().length === 0)
			this._updateTaskInformation();

		PlayerRobot4.prototype._moveTo.call(this, target, reverse);

		this._updateTaskInformation();
	},

	_updateTaskInformation: function()
	{
		var map = this.getMap();

		if (map.element(this.position).getType() >= PhysicalRobotMapElementType.NullElement &&
            map.element(this.position).getType() <= PhysicalRobotMapElementType.NineElement)
			map.getVisitedCells().push(map.element(this.position).getType());
	},

	physicalStateChanged: function(state)
	{
		if(this.sprite instanceof pm.ObjectSprite2D)
			this.sprite.changeState(state);
	},

	getType: function() { return pm.PhysicalRobotLevelModule.RobotType; }
});
