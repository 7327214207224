/**
 * Created by Kirill Mashchenko on 12.07.2018.
 */

/**
 * @class This is wrapper for one method in format for {@link pm.RobotManager}.</br>
 * @extends cc.Class
 * @constructor
 * @param {Number} command
 * @param {Object} [data]
 */
pm.Instruction = cc.Class.extend(/** @lends pm.Instruction#*/{
	/**
     * Type of instruction.</br>
     * @type {Number}
     */
	command: 0,
	/**
     * Data of instruction.
     * @type {Object}
     */
	data: {},

	ctor: function(command, data)
	{
		if(command !== undefined)
			this.command = command;

		if(data !== undefined)
			this.data = data;
		else
			this.data = {};
	}
});

/**
 * ID of execute instruction.
 * @const
 * @default
 * @type {number}
 */
pm.Instruction.EXECUTE = 0;
/**
 * ID of check condition instruction.
 * @see pm.Instruction
 * @const
 * @default
 * @type {number}
 */
pm.Instruction.CHECK_CONDITION = 1;
/**
 * ID of start loop instruction.
 * @const
 * @default
 * @type {number}
 */
pm.Instruction.START_LOOP = 2;
/**
 * ID of end loop instruction.
 * @see pm.Instruction
 * @const
 * @default
 * @type {number}
 */
pm.Instruction.END_LOOP = 3;
/**
 * ID of return instruction.
 * @const
 * @default
 * @type {number}
 */
pm.Instruction.RETURN = 4;

/**
 * ID of conditional jump instruction.
 * @const
 * @default
 * @type {number}
 */
pm.Instruction.JUMP_IF = 5;

/**
 * ID of conditional jump instruction.
 * @const
 * @default
 * @type {number}
 */
pm.Instruction.JUMP_NIF = 6;
/**
 * ID of conditional jump instruction.
 * @const
 * @default
 * @type {number}
 */
pm.Instruction.JUMP = 7;


/**
 * ID of allocate memory for variables instruction
 * @const
 * @default
 * @type {number}
 */
pm.Instruction.ALLOC = 8;
/**
 * ID of put the value of variable into stack instruction
 * @const
 * @default
 * @type {number}
 */
pm.Instruction.FETCH = 9;
/**
 * ID of push the value into stack instruction
 * @const
 * @default
 * @type {number}
 */
pm.Instruction.PUSH = 10;
/**
 * ID of save the value from stack into variable instruction
 * @const
 * @default
 * @type {number}
 */
pm.Instruction.STORE = 11;
/**
 * ID of pop the top value from stack instruction
 * @const
 * @default
 * @type {number}
 */
pm.Instruction.POP = 12;
/**
 * ID of calculate action between two top value from stack and push the result into stack instruction
 * @const
 * @default
 * @type {number}
 */
pm.Instruction.CALC = 13;


/**
 * @class This is wrapper for one function in format for {@link pm.RobotManager}.</br>
 * @extends cc.Class
 */
pm.RobotProgram = cc.Class.extend({
	instructions: [],
	labels: {},
	stack: [],

	instrPosition: 0,
	ended: false,
	paused: false,

	ctor: function()
	{
		this.instructions = [];
		this.labels = {};
		this.stack = [];

		this.instrPosition= 0;
		this.ended= false;
	},

	setForceInstruction: function (methodId) {}
});

pm.RobotProgram.LABEL_PATTERN = "LB_{0}";

pm.programUtils = {

	totalLabelCount: 0,

	compileRobotProgram: function(robot, level)
	{
		return pm.appUtils.compileRobotProgram(robot, level);
	},

	searchNativeRobotFunction: function(robot, functionID)
	{
		var searchRobot = robot;

		while(searchRobot instanceof pm.AbstractRobot)
		{
			var func = searchRobot.findNativeFunction(functionID);

			if (func)
				return func;
			else
				searchRobot = searchRobot.childRobot;
		}

		return null;
	},

	searchGlobalNativeFunction: function(level, functionID)
	{
		for(var i = 0 ; i < level.globalRobots.length; ++i)
		{
			var func = level.globalRobots[i].findNativeFunction(functionID);

			if (func)
				return func;
		}

		return null;
	},

	getLabel: function()
	{
		var label = pm.RobotProgram.LABEL_PATTERN.format(this.totalLabelCount);
		++this.totalLabelCount;

		return label;
	},

	searchRobotForCondition: function(robot, condition)
	{
		var searchRobot = robot;

		while(searchRobot instanceof pm.AbstractRobot)
		{
			if (searchRobot.conditions.indexOf(condition) !== -1 || searchRobot.conditionOpposites.indexOf(condition) !== -1)
				return searchRobot;
			else
				searchRobot = searchRobot.childRobot;
		}

		return null;
	},

	searchGlobalRobotForCondition: function(level, condition)
	{
		for(var i = 0 ; i < level.globalRobots.length; ++i)
		{
			if (level.globalRobots[i].conditions.indexOf(condition) !== -1 || level.globalRobots[i].conditionOpposites.indexOf(condition) !== -1)
				return level.globalRobots[i];
		}

		return null;
	}
};

