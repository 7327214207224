/**
 * Created by Kirill Mashchenko on 03.09.2018.
 */

/**
 * @class pm.Task for light elements.</br>
 * @implements pm.Task
 * @constructor
 * @param {pm.AbstractLevel} level
 */

pm.data.PhysicalTask = pm.Task.extend(/** @lends pm.data.LightTask*/ {
	typeName: "PhysicalTask",

	ctor: function(level)
	{
		this._super(level);
	},

	getNotCompletedObjects: function(mapIndex)
	{
		var map = this._level.maps[mapIndex];

		var correctArray = this.data[mapIndex];
		var currentArray = map.getVisitedCells();

		var completedFlag = true;
		var retArray = [];

		if (correctArray.length !== currentArray.length)
		{
			completedFlag = false;
		}
		else
		{
			for (var i = 0; i < correctArray.length; ++i)
			{
				if (correctArray[i] !== currentArray[i])
					completedFlag = false;
			}
		}

		if (!completedFlag)
		{
			for (var x = 0; x < map.width; ++x)
			{
				for (var y = 0; y < map.height; ++y)
				{
					if (map.element(cc.p(x, y)).getType() >= PhysicalRobotMapElementType.NullElement &&
                        map.element(cc.p(x, y)).getType() <= PhysicalRobotMapElementType.NineElement)

						retArray.push(map.element(cc.p(x, y)));

				}
			}
		}

		return retArray;
	}
});
