/**
 * Created by Kirill Mashchenko on 25.01.18.
 */

pm.FlagRobotSprite = pm.ObjectSprite2D.extend(/** @lends pm.FlagRobotSprite# */{

	_type: null,
	_commandsSprite: null,
	_commandsCount: null,

	ctor: function (type)
	{
		pm.spriteUtils.addTempSpriteFrames("Robot/{0}_flag_robot.plist".format(type));

		this._super(cc.spriteFrameCache.getSpriteFrame(this._getFrameName(type, pm.FlagRobot.State.Down)));

		this._type = type;

		this._commandsSprite = new cc.Sprite(pm.spriteUtils.getInterfaceElementFrame("commandsNumber"));

		if (this._type === pm.FlagRobotSprite.Type.Green)
			this._commandsSprite.setColor(RobotColorList2D.Green);
		else if (this._type === pm.FlagRobotSprite.Type.Orange)
			this._commandsSprite.setColor(RobotColorList2D.Orange);

		this._commandsSprite.setVisible(false);
		this._commandsSprite.setPosition(cc.p(27, 110));

		this.sprite.addChild(this._commandsSprite);

		this._commandsCount = new ccui.Text("", pm.settings.fontBoldName, pm.settings.fontSize);
		this._commandsCount.setColor(cc.color(1, 1, 180));
		this._commandsCount.setAnchorPoint(cc.p(0.5, 0.5));

		if (cc.sys.isNative)
			this._commandsCount.setNormalizedPosition(cc.p(0.5, 0.5));
		else
			this._commandsCount.setNormalizedPosition(cc.p(0.5, 0.42));

		this._commandsCount.setNormalizedPosition(cc.p(0.5, 0.46));
		this._commandsSprite.addChild(this._commandsCount);
	},

	_getFrameName: function (type, state)
	{
		return "{0}_flag_robot_{1}.png".format(type, state);
	},

	playAnimation: function(endCallBack, target, type, args)
	{
		switch(type)
		{
			case pm.FlagRobotSprite.Animation.Up: this._up(endCallBack, target); break;
			case pm.FlagRobotSprite.Animation.Down: this._down(endCallBack, target); break;
		}
	},

	_up: function (callback, target)
	{
		this.getSprite().setSpriteFrame(this._getFrameName(this._type, pm.FlagRobot.State.Up));
		callback.call(target);
	},

	_down: function (callback, target)
	{
		this.getSprite().setSpriteFrame(this._getFrameName(this._type, pm.FlagRobot.State.Down));
		callback.call(target);
	},

	showCommands: function(flag, count)
	{
		if (flag && MethodStackLayer.opened)
			return;

		if (flag)
			this._commandsCount.setString(count);

		this._commandsSprite.setVisible(flag);
	},

	indicateCommands: function(stepCount)
	{
		this.showCommands(true, stepCount);

		setTimeout(this.showCommands.bind(this), pm.RobotSprite2D.SHOW_COMMANDS_TIMEOUT, false)
	}
});

pm.FlagRobotSprite.Type = {
	Green: "green",
	Orange: "orange"
};

pm.FlagRobotSprite.Animation = {
	Up: "up",
	Down: "down"
};

