/**
 * Created by Nikita Besshaposhnikov on 10.03.16.
 */

/**
 * @class MethodListButtonP used by {@link MethodStackLayer}.
 * @extends FunctionButtonP
 * @constructor
 * @param {Number} value
 */
var MethodListButtonP = FunctionButtonP.extend(/** @lends MethodListButtonP#*/{

	_label: null,
	_getMethodListCallback: null,
	_getMethodListCallbackTarget: null,

	ctor: function(value)
	{
		this._label = new cc.LabelTTF(String(value), pm.settings.fontName, pm.settings.fontSize);

		this._super(FunctionButton.Type.MethodList, value);

		this.loadTextureNormal(this._getSpriteName(pm.NORMAL_STATE), ccui.Widget.PLIST_TEXTURE);
		this.loadTexturePressed(this._getSpriteName(pm.SELECTED_STATE), ccui.Widget.PLIST_TEXTURE);
		this.loadTextureDisabled(this._getSpriteName(pm.DISABLED_STATE), ccui.Widget.PLIST_TEXTURE);

		this._label.setPosition(this.width / 2, this.height / 2);
		this._label.setFontFillColor(pm.settings.levelFontColor);

		this.addChild(this._label);
	},

	cloneImage: function()
	{
		var image = new ccui.ImageView(pm.spriteUtils.getIconName("mslDrag"), ccui.Widget.PLIST_TEXTURE);
		image.setCascadeOpacityEnabled(true);

		var label = new cc.LabelTTF(String(this._value), pm.settings.fontName, pm.settings.fontSize);
		label.setPosition(image.width / 2, image.height / 2);
		label.setFontFillColor(pm.settings.levelFontColor);

		image.addChild(label);

		return image;
	},

	/**
     * Sets method list
     * @param callback
     * @param target
     */
	setMethodListCallback: function(callback, target)
	{
		this._getMethodListCallback = callback;
		this._getMethodListCallbackTarget = target;
	},

	/**
     * Retirns method list associated with button.
     * @returns {Array<String>}
     */
	getMethodList: function()
	{
		return this._getMethodListCallback.call(this._getMethodListCallbackTarget);
	},

	_valueChanged: function()
	{
		this._label.setString(String(this._value));
	},

	_getSpriteName: function(state)
	{
		return pm.spriteUtils.getIconName("mslDrag", state);
	}
});
