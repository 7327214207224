"use strict";

/**
 * Created by Nikita Besshaposhnikov on 19.12.14.
 */

/**
 * @class This is an common interface for all objects.
 * @interface
 * @extends pm.Class
 */
pm.Object = pm.Class.extend(/** @lends pm.Object# */{
	/**
     * Unique id of object.
     * @type {Number}
     */
	id: 0,

	/**
     * Map which contains this object.
     * @type {pm.AbstractMap}
     */
	parentMap: {},

	/**
     * Current object position.
     * @type {cc.Point}
     */
	position: cc.p(),
	/**
     * Start object position.
     * @type {cc.Point}
     */
	startPosition: cc.p(),

	/**
     * Sprite object representing this object.
     * @type {pm.ObjectSprite}
     */
	sprite: {},

	_isPlayingAnimation: false,

	visualId: 0,

	ctor: function()
	{
		this._addNonEnumerableProps("sprite", "position", "parentMap", "_isPlayingAnimation");

		this._isPlayingAnimation = false;

		this._super();
	},

	/**
     * Return type of object.
     * @function
     * @name pm.Object#getType
     */
	getType: function() { return 0; },

	isPlayingAnimation: function ()
	{
		var physicalConnector = this._getPhysicalConnector();

		return this._isPlayingAnimation || physicalConnector && physicalConnector.isBusy();
	},

	/**
     * Plays animation of object
     * @param animation
     * @param animationCallback
     * @param args
     * @param forced
     */
	playAnimation: function(animation, animationCallback, args, forced)
	{
		this._isPlayingAnimation = true;

		if(!CORE_BUILD && (!pm.settings.isAnimationDisabled() || forced))
			this.sprite.playAnimation(animationCallback, this, animation, args);
		else if(CORE_BUILD && animationCallback)
			animationCallback.call(this);
		else if(animationCallback)
			setTimeout(animationCallback.bind(this), 0);

		if(!CORE_BUILD)
		{
			var physicalConnector = this._getPhysicalConnector();

			if(physicalConnector)
				physicalConnector.playObjectAnimation(this.id, animation);
		}
	},

	getAnimation: function(animation, animationCallback, args, forced)
	{
		this._isPlayingAnimation = true;

		if(!CORE_BUILD && (!pm.settings.isAnimationDisabled() || forced))
			return this.sprite.getAnimation(animationCallback, this, animation, args);
	},

	/**
     * Stop animation of object
     */
	stopAnimation: function()
	{
		this._isPlayingAnimation = false;
		if(!CORE_BUILD)
			this.sprite.stopAnimation();
	},

	_endAnimation: function ()
	{
		this._isPlayingAnimation = false;
	},

	/**
     * Updates sprite of object
     */
	updateSprite: function() {},

	reset: function () {},

	/**
     * Generates sprite for this object.
     * @function
     * @name pm.Object#generateSprite
     * @returns {pm.ObjectSprite}
     */
	generateSprite: function(previewDraw) {},

	getState: function () {},

	setState: function (state) {},

	updateNumberSprite: function(){},

	/**
	 * Get parent level physical connector
	 * @returns {?pm.PhysicalConnector}
	 * @private
	 */
	_getPhysicalConnector: function()
	{
		if(this.parentMap && this.parentMap.parentLevel)
		{
			var level = this.parentMap.parentLevel;

			if(level.hasPhysicalConnector())
				return level.getPhysicalConnector();
		}

		return null;
	}
});
