/**
 * Created by danilaeremin on 14.08.15.
 */

/**
 * Created by Nikita Besshaposhnikov on 31.07.15.
 */

/**
 * @class Layer for display hints in editor. Hints are stacked.
 * @example
 * var hint = new HintEditorLayer("I'm hint!");
 * hint.show();
 * @extends pmui.PopupLayer
 * @constructor
 * @param {String} text Text of hint.
 */
var HintEditorLayer = pmui.PopupLayer.extend(/** @lends HintEditorLayer# */{
	_hint: null,
	_hintInput: null,

	_visible: true,

	ctor: function(hint)
	{
		this._super(pmui.PopupLayer.HIDE_DURATION_NEVER);

		this._hint = hint;

		var hintLayout = new ccui.Layout();
		var buttonsLayout = new ccui.Layout();
		this.setLayoutType(ccui.Layout.RELATIVE);

		hintLayout.setLayoutType(ccui.Layout.RELATIVE);
		buttonsLayout.setLayoutType(ccui.Layout.RELATIVE);

		var info = new ccui.ImageView(pm.spriteUtils.getIconName("info"), ccui.Widget.PLIST_TEXTURE);

		var infoMargin = new ccui.RelativeLayoutParameter();
		infoMargin.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		infoMargin.setRelativeName("info");
		infoMargin.setMargin(HintEditorLayer.HINT_SEPARATOR, HintEditorLayer.HINT_SEPARATOR, 0, 0);
		info.setLayoutParameter(infoMargin);

		this.addChild(info);

		var enter = new pmui.InterfaceButton(this._saveHint, this, LocalizedString("Save"));

		var enterMargin = new ccui.RelativeLayoutParameter();
		enterMargin.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
		enterMargin.setRelativeName("enter");
		enter.setLayoutParameter(enterMargin);

		buttonsLayout.addChild(enter);

		var cancel = new pmui.InterfaceButton(this._closeHint, this, LocalizedString("CancelButton"));

		var cancelMargin = new ccui.RelativeLayoutParameter();
		cancelMargin.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		cancelMargin.setRelativeToWidgetName("enter");
		cancelMargin.setMargin(HintEditorLayer.HINT_SEPARATOR, 0, 0, 0);
		cancel.setLayoutParameter(cancelMargin);

		buttonsLayout.addChild(cancel);

		this._hintInput = new pmui.TextInput(
			hint.text,
			LocalizedString("Hint"),
			pm.settings.fontSize,
			HintEditorLayer.WIDTH,
			HintEditorLayer.HEIGHT
		);

		var labelMargin = new ccui.RelativeLayoutParameter();
		labelMargin.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
		this._hintInput.setLayoutParameter(labelMargin);

		hintLayout.addChild(this._hintInput);

		var buttonsLayoutAlign = new ccui.RelativeLayoutParameter();
		buttonsLayoutAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		buttonsLayoutAlign.setRelativeName("hint");
		buttonsLayoutAlign.setMargin(0, HintEditorLayer.HINT_SEPARATOR, HintEditorLayer.HINT_SEPARATOR, 0);
		buttonsLayout.setLayoutParameter(buttonsLayoutAlign);

		var hintLayoutAlign = new ccui.RelativeLayoutParameter();
		hintLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		hintLayoutAlign.setRelativeToWidgetName("info");
		hintLayoutAlign.setMargin(0, HintEditorLayer.HINT_SEPARATOR, 0, 0);
		hintLayout.setLayoutParameter(hintLayoutAlign);

		hintLayout.setContentSize(this._hintInput.getContentSize().width, this._hintInput.getContentSize().height);

		buttonsLayout.setContentSize(cancel.getContentSize().width + enter.getContentSize().width +
			info.getContentSize().width + HintEditorLayer.HINT_SEPARATOR, cancel.getContentSize().height);

		this.addChild(hintLayout);
		this.addChild(buttonsLayout);

		this.setHideCallback(this._onHide, this);

		this.setContentSizeWithBorder(Math.max(hintLayout.width, buttonsLayout.width + info.getContentSize().width) + 3/2 * HintEditorLayer.HINT_SEPARATOR,
			buttonsLayout.height + hintLayout.height + 3 * HintEditorLayer.HINT_SEPARATOR);

		HintEditorLayer._hints.push(this);
	},

	_onHide: function()
	{
		this._visible = false;

		var index = HintEditorLayer._hints.indexOf(this);
		HintEditorLayer._hints.splice(index, 1);

		if(index === HintEditorLayer._hints.length)
			return;

		for(var i = index; i < HintEditorLayer._hints.length; ++i)
		{
			var x = HintEditorLayer._hints[i].getPositionX();
			var y = HintEditorLayer._hints[i].getPositionY() - this.getContentSize().height - HintEditorLayer.HINT_SEPARATOR;
			var move = cc.moveTo(pm.SYSTEM_ANIMATION_DELAY, x, y);

			HintEditorLayer._hints[i].runAction(move);
		}
	},

	/**
     * Shows hint on screen.
     */
	show: function()
	{
		if(HintEditorLayer._hints.length === 1)
			HintEditorLayer._lastY = 3 * pm.settings.getMapSize().height / 5;

		var positionX = pm.settings.getMapSize().width / 2 - this.getContentSize().width / 2;

		this.setPosition(positionX, HintEditorLayer._lastY);

		cc.director.getRunningScene().addChild(this, 10);
		this._visible = true;

		HintEditorLayer._lastY += this.getContentSize().height + HintEditorLayer.HINT_SEPARATOR;
	},

	_saveHint: function ()
	{
		this._hint.text = this._hintInput.getString();
		this._closeHint();
	},

	_closeHint: function ()
	{
		this._selfRemove();
	},

	/**
	 * Determines if the hint is visible.
	 * @returns {boolean} true if the hint is visible, false if the hint is hidden.
	 */
	isVisible: function()
	{
		return this._visible;
	}
});

HintEditorLayer._lastY = 0;
HintEditorLayer._hints = [];

/**
 * Separator between hints.
 * @const
 * @default
 * @type {Number}
 */
HintEditorLayer.HINT_SEPARATOR = 5;

/**
 * Width of hint.
 * @const
 * @default
 * @type {Number}
 */
HintEditorLayer.WIDTH = 300;
HintEditorLayer.HEIGHT = 200;
