/**
 * Created by Nikita Besshaposhnikov on 22.01.18.
 */

/**
 * @class Layer to be shown for robot step count result.
 * @extends pmui.OvalLayout
 * @constructor
 * @param {pm.AbstractLevel} level Current level.
 */
var RobotsStepCountLayer = pmui.OvalLayout.extend(/** @lends RobotsStepCountLayer# */{
	_hidden: true,
	_level: null,
	_resultList: null,

	ctor: function(level)
	{
		this._super(null, null, pmui.OvalLayout.Type.SLIM);
		this.setCascadeOpacityEnabled(true);

		this._level = level;
		this.setOpacity(0);

		this._resultList = new ccui.ListView();
		this._resultList.setCascadeOpacityEnabled(true);
		this._resultList.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._resultList.setItemsMargin(ProgramLayer.PROGRAM_SEPARATOR);
		this._resultList.setScrollBarEnabled(true);
		// this._resultList.setScrollBarOpacity(255 * 0.9);

		var resultListAlign = new ccui.RelativeLayoutParameter();
		resultListAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		resultListAlign.setMargin(2 + pm.SCREEN_BOUND/2, 2 + pm.SCREEN_BOUND/2, 0, 0);
		this._resultList.setLayoutParameter(resultListAlign);

		this.addChild(this._resultList, 10);
	},

	/**
     * Shows layer.
     */
	show: function()
	{
		this._resultList.removeAllItems();

		var width = 0;

		for(var i = 0; i < this._level.robots.length; ++i)
		{
			var text = "";
			var stepCount = this._level.robots[i].stepCount;

			var module10StepCount = stepCount % 10;
			var module100StepCount = stepCount % 100;

			if(module100StepCount > 10 && module100StepCount < 15)
				text = LocalizedString("StepCountPattern3");
			else if(module10StepCount === 1)
				text = LocalizedString("StepCountPattern1");
			else if(module10StepCount < 5 && module10StepCount > 1)
				text = LocalizedString("StepCountPattern2");
			else
				text = LocalizedString("StepCountPattern3");

			text = text.format(i + 1, stepCount);

			this._resultList.pushBackCustomItem(new ccui.Text(text, pm.settings.fontName, pm.settings.fontSize));

			width = Math.max(width, this._resultList.getItem(i).width);
		}

		this._resultList.forceDoLayout();
		var height = Math.min(this._resultList.getInnerContainerSize().height, RobotsStepCountLayer.HEIGHT);

		this._resultList.setContentSize(width, height);

		this.setContentSizeWithBorder(cc.size(width + pm.SCREEN_BOUND, height + pm.SCREEN_BOUND));

		this._hidden = false;

		var fadeLayer = cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY, 255);

		this.runAction(fadeLayer);
	},

	/**
     * Hides layer.
     */
	hide: function()
	{
		this._hidden = true;

		var fadeLayer = cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY, 0);

		this.runAction(fadeLayer);
	},

	/**
     * Returns if layer if hidden.
     * @returns {boolean}
     */
	isHidden: function()
	{
		return this._hidden;
	}

});

RobotsStepCountLayer.HEIGHT = 170;
