/**
 * This namespace contains functions to work with user cache database.</br>
 * It contains score and user programs for loaded world.
 * @namespace
 */
pm.userCache = {
	/**
     * Sync interval in seconds
     * @type {Number}
     * @default
     */
	CACHE_SYNC_INTERVAL: 3,

	/**
     * A storage for cache. Do not change by yourself.</br>
     * Format : _records[level_id][level_stage] -> {Number}.
     * @private
     * @default
     */
	_cache: {},

	_cacheUpdated: false,

	/**
     * Initializes use cache
     */
	init: function()
	{
		pm.registerCustomEventListener(pm.USER_PROGRAM_UPDATED_STR, function ()
		{
			if (!pm.settings.isEditorMode && !pm.tutorialUtils.isProcessingTutorial()
                && !world.runningLevel.isTutorial && !pm.networkUtils.isNetworkGame)
				this.saveLevelProgram(world.runningLevel);
		}.bind(this), 1);
	},

	/**
     * Returns a path where to store database.
     * @param {String} [worldID]
     * @returns {string}
     * @private
     */
	_getPath: function(worldID)
	{
		return pm.settings.getWorldDir(worldID) + "/cache.json";
	},

	_getLevelRobotGroupd: function(level)
	{
		var groups = [];

		for (var i = 0; i < level.robots.length; ++i)
		{
			if(groups.indexOf(level.robots[i].groupID) === -1)
				groups.push(level.robots[i].groupID);
		}

		return groups;
	},

	_loadLevelProgramCache: function(level, cache)
	{
		var data = new pm.Class();

		data = data.deserialize(cache, true);

		var groups = this._getLevelRobotGroupd(level);

		for (var i = 0; i < groups.length; ++i)
		{
			var groupID = groups[i];

			if (data[groupID] !== undefined)
				level.programDataCache[groupID] = data[groupID];
		}
	},

	_loadWorldProgramCache: function(updatedLevels)
	{
		for (var game = 0; game < world.getGameCount(); ++game)
		{
			for (var level = 0; level < world.getLevelCount(game); ++level)
			{
				var levelId = world.games[game].levels[level].id;

				if(updatedLevels && updatedLevels.indexOf(levelId) === -1)
					continue;

				if(!this._cache[levelId])
					continue;

				var levelProgramData = this._cache[levelId].programData;

				if (levelProgramData)
					this._loadLevelProgramCache(world.games[game].levels[level], levelProgramData);
			}
		}
	},

	_scheduleSync: function()
	{
		cc.director.getScheduler().schedule(
			this._syncCache, this, pm.userCache.CACHE_SYNC_INTERVAL,
			cc.REPEAT_FOREVER, 0, false, "cache_sync"
		);
	},

	/**
     * Loads cache from file syncs it with server if user logged in.
     * @param {String} worldID
     * @param {Function} callback
     */
	load: function(worldID, callback)
	{
		if(pm.fileUtils.isFileExist(this._getPath(worldID)))
			this._cache = pm.fileUtils.readObject(this._getPath(worldID));
		else
			pm.fileUtils.createDirectory(pm.settings.getWorldDir(worldID));

		this._cacheUpdated = false;
		this._scheduleSync();

		if(pm.settings.getAccessToken() === "")
		{
			this._loadWorldProgramCache();

			callback();

			return;
		}

		this._cacheUpdated = true;
		callback();
		this._syncCache(0, worldID, callback, true);
	},

	forceSyncCache: function(callback)
	{
		this._cacheUpdated = true;
		this._syncCache(0, pm.settings.getSelectedWorldID(), callback, true);
	},

	_syncCache: function(dt, worldID, callback, updateAllLevels)
	{
		if(this._cacheUpdated)
		{
			this._cacheUpdated = false;

			if(pm.settings.getAccessToken() !== "")
			{
				worldID = worldID ? worldID : pm.settings.getSelectedWorldID();

				pm.apiServerUtils.getLevelsCache(function (error, response)
				{
					var updatedLevels = [];

					if (!error)
					{
						if (response.cache)
						{
							for (var id in response.cache)
							{
								if (!this._cache[id] ||
									response.cache[id].levelVersion === this._cache[id].levelVersion &&
									response.cache[id].revision > this._cache[id].revision)
								{
									this._cache[id] = response.cache[id];
									updatedLevels.push(id);
								}
							}
						}

						pm.apiServerUtils.updateLevelsCache(function (error, response)
						{
							if (error)
								cc.error(error);

						}, worldID, this._cache);
					}
					else
					{
						cc.error(JSON.stringify(error));
					}

					if (updateAllLevels || updatedLevels.length > 0)
					{
						this._loadWorldProgramCache(updateAllLevels ? null : updatedLevels);
						this._save(false);
					}

					if (callback)
						callback();

				}.bind(this), worldID);

				return;
			}
		}

		if (callback)
			callback();
	},
	/**
     * Saves cache and sync it with server if user logged in.
     */
	_save: function(syncCache, worldID)
	{
		if(syncCache === undefined)
			syncCache = true;

		pm.fileUtils.saveObject(this._getPath(worldID), this._cache);

		if(syncCache)
			this._cacheUpdated = syncCache;
	},

	/**
     * clear cache data for level
     * @param {String} id Id of level
     */

	clearLevelData: function(id)
	{
		if(id !== "" && this._cache[id])
		{
			delete this._cache[id].programData;
			delete this._cache[id].score;

			this._incLevelCacheRevision(id);
		}

		this._save();
	},

	/**
     * Removes useless data from records.
     */
	clearUnusedData: function(worldID)
	{
		for(var id in this._cache)
		{
			var found = false;

			for(var i = 0; i < world.games.length; ++i)
			{
				for(var j = 0; j < world.games[i].levels.length; ++j)
				{
					var level = world.games[i].levels[j];

					if(level.id === id && level.version === this._cache[id].levelVersion)
						found = true;
				}
			}

			if(!found)
				delete this._cache[id];
		}

		this._save(true, worldID);
	},
	/**
     * Cleanup cache;
     */
	cleanup: function()
	{
		if(pm.fileUtils.isFileExist(this._getPath()))
		{
			for(var i = 0; i < world.games.length; ++i)
			{
				for(var j = 0; j < world.games[i].levels.length; ++j)
				{
					var level = world.games[i].levels[j];

					level.programDataCache = new pm.data.MapClass();

					if(this._cache[level.id])
					{
						delete this._cache[level.id].programData;
						delete this._cache[level.id].score;

						this._incLevelCacheRevision(level.id);
					}
				}
			}

			this._save();
		}
	},
	/**
	 * Totally removes cache
	 */
	remove: function()
	{
		if(pm.fileUtils.isFileExist(this._getPath()))
			pm.fileUtils.removeFile(this._getPath());

		this._cache = {};

		for(var i = 0; i < world.games.length; ++i)
		{
			for(var j = 0; j < world.games[i].levels.length; ++j)
			{
				var level = world.games[i].levels[j];

				level.programDataCache = new pm.data.MapClass();
			}
		}
	},
	/**
     * Sets score for level by map index.
     * @param {pm.AbstractLevel} level A level for which to save score
     * @param {Number} stage A map index of score to save.
     * @param {Number} score Score value.
     */
	setScore: function(level, stage, score)
	{
		if(level.id === "") // do no cache result for levels not synced with server
			return;

		if(!this._cache[level.id])
		{
			this._cache[level.id] = {
				levelVersion: level.version,
				levelRevision: level.revision
			};
		}

		this._incLevelCacheRevision(level.id);

		if(!this._cache[level.id].score)
			this._cache[level.id].score = {};

		this._cache[level.id].score[stage] = score;

		pm.settings.updateModifyDate();
		this._save();
	},

	_incLevelCacheRevision: function(id)
	{
		if (this._cache[id].revision)
			++this._cache[id].revision;
		else
			this._cache[id].revision = 0;
	},

	/**
     * Save levels' user program
     * @param {pm.AbstractLevel} level A level for which to save program
     */
	saveLevelProgram: function(level)
	{
		if (level.id === "") // do no cache result for levels not synced with server
			return;

		if (!this._cache[level.id])
		{
			this._cache[level.id] = {
				levelVersion: level.version,
				levelRevision: level.revision
			};
		}

		this._incLevelCacheRevision(level.id);

		if (!this._cache[level.id].programData)
			this._cache[level.id].programData = new pm.data.MapClass();

		var programData = this._cache[level.id].programData;

		var groups = this._getLevelRobotGroupd(level);

		for (var i = 0; i < groups.length; ++i)
		{
			var groupID = groups[i];
			programData[groupID] = level.programDataCache[groupID];
		}

		pm.settings.updateModifyDate();
		this._save();
	},

	_clearCurrentLevelScore: function()
	{
		var level = world.runningLevel;

		if(!level || !(level instanceof pm.AbstractLevel))
			return;

		if(level.id === "") // do no check result for levels not synced with server
			return;

		if(this.getCompleteMapCount(level) > 0)
		{
			delete this._cache[level.id];
			this._save();
		}
	},

	/**
     * Returns completed map count
     * @param {pm.AbstractLevel} level A level for getting completed map count
     * @returns {Number}
     */
	getCompleteMapCount: function(level)
	{
		if(!this._cache[level.id] || !this._cache[level.id].score)
			return 0;

		return Object.keys(this._cache[level.id].score).length;
	},
	/** Returns score for level's concrete map
     * @param {pm.AbstractLevel} level A level for getting score
     * @param {Number} stage Level's map index
     * @returns {Number}
     */
	getScore: function(level, stage)
	{
		if(!this._cache[level.id] || !this._cache[level.id].score ||
            this._cache[level.id].score[stage] === undefined || this._cache[level.id].score[stage] === null)
			return -1;

		return this._cache[level.id].score[stage];
	}
};
