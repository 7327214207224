"use strict";

/**
 * Created by Nikita Besshaposhnikov on 16.11.15.
 */

/**
 * @class 2D Level where robot is a copy pf physical robot and can connect to real physical robot.
 * @implements pm.AbstractLevel
 */
pm.data.PhysicalRobotLevel = pm.AbstractLevel.extend(/** @lends pm.data.PhysicalRobotLevel# */{
	typeName: "PhysicalRobotLevel",
	/**
	 * Robots can be indicated.
	 * @type {Boolean}
	 */
	robotsCanBeIndicated: false,

	ctor: function()
	{
		this._super();
	},

	load: function(preview, robotIndex)
	{
		pm.AbstractLevel.prototype.load.call(this, preview, robotIndex);
	},

	hasPhysicalConnector: function() { return true; },

	_supportsPhysicalConnector: function(type)
	{
		return type !== pm.PhysicalConnectorType.Magnet;
	},

	_physicalStateChanged: function(state)
	{
		for(var i = 0; i < this.robots.length; ++i)
			this.robots[i].physicalStateChanged(state);
	},

	getType: function() { return pm.PhysicalRobotLevelModule.Type; }
});
