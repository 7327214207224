/**
 * Created by Antony Orlovsky on 21.02.18.
 */

/**
 * @class Menu for changing level in editor.</br>
 * Can change to previous or next level. If new level is in another game, changes game.
 * @extends ChangeLevelMenu
 */
var ChangeLevelMenuEditor = ChangeLevelMenu.extend(/** @lends ChangeLevelMenu# */{

	ctor: function ()
	{
		this._super();
	},

	_transLevel: function ()
	{
		this.getParent()._level.clearCache();

		world.setLevelUpdated(pm.settings.getGame(), pm.settings.getLevel());
		world.save();
		pm.userCache.clearLevelData(this.getParent()._level.id);

		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY,
			new LevelEditorScene(pm.settings.getGame(), pm.settings.getLevel()));
		cc.director.runScene(trans);
	}
});
