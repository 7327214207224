/**
 * Created by danilaeremin on 10.08.15.
 */

/**
 * @class Layer for map settings.
 * @extends LevelSettingsLayer
 * @constructor
 * @param {Number} level Current level
 */
var MapElementsLayer = LevelSettingsLayer.extend(/** @lends MapElementsLayer# */{
	_mapElementTypes: [],
	mapElementsTable: null,
	_extraSettings: null,
	level: null,

	ctor: function(level)
	{
		this._super(level);

		this.level = level;
		this._mapElementTypes = [];

		var label = new ccui.Text(LocalizedString("Map"), pm.settings.fontName, pm.settings.fontSize);
		var height = label.height + LevelSettingsLayer.SEPARATOR;

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		labelAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		labelAlign.setRelativeName("label");
		label.setLayoutParameter(labelAlign);

		this.addChild(label);

		var delta = 0;

		if (level.activeMap instanceof Map2D && level.activeMap.hasWalls())
		{
			var editWalls = new pmui.CheckBox(LocalizedString("EditWalls"), function (flag) { for (var i = 0; i < level.maps.length; ++i) level.maps[i].editWalls = flag; }, this, 17, true);
			height += editWalls.height + LevelSettingsLayer.SEPARATOR;

			var wallsAlign = new ccui.RelativeLayoutParameter();
			wallsAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			wallsAlign.setRelativeName("walls");
			wallsAlign.setRelativeToWidgetName("label");
			wallsAlign.setMargin((-LevelSettingsLayer.WIDTH + label.width)/2 + 30, LevelSettingsLayer.SEPARATOR, 0, 0);
			editWalls.setLayoutParameter(wallsAlign);

			this.addChild(editWalls);

			delta = editWalls.height;
		}

		var borderMargin = (-LevelSettingsLayer.WIDTH + label.width) / 2 + 30;

		var mapElementsType = pm.moduleUtils.getMapElementsType(level.getType());

		if (mapElementsType && Object.keys(mapElementsType).length > 0)
		{
			var i = 0;

			var maxElementsCountPerRaw = MapElementsLayer.maxElementsCountPerRaw;

			var separator = cc.size(5, 5);
			var cellSize = cc.size((LevelSettingsLayer.WIDTH - 2 * 15 - 3 * separator.width - 2 * LevelSettingsLayer.BORDER_RADIUS) / maxElementsCountPerRaw,
				(LevelSettingsLayer.WIDTH - 2 * 15 - 3 * separator.width - 2 * LevelSettingsLayer.BORDER_RADIUS) / maxElementsCountPerRaw);

			var rows = Math.floor((Object.keys(mapElementsType).length) / maxElementsCountPerRaw) + (((Object.keys(mapElementsType).length) % maxElementsCountPerRaw === 0) ? 0 : 1);

			this.mapElementsTable = new pmui.SelectTableView(cellSize, separator, rows, maxElementsCountPerRaw);
			this.mapElementsTable.addSelectEventListener(this._selectMapElementCallback, this);
			this.mapElementsTable.setSelectFrame(pm.spriteUtils.getInterfaceElementFrame("functionBG"), true);
			this.mapElementsTable.setFitObjectsSizes(true);

			this.mapElementsTable.setContentSize(LevelSettingsLayer.WIDTH - 2 * borderMargin,
				cellSize.height * Math.min(rows, 3) + separator.height * Math.min(rows, 3) + LevelSettingsLayer.SEPARATOR);

			height += this.mapElementsTable.height + 2 * LevelSettingsLayer.SEPARATOR;

			var scrollAlign = new ccui.RelativeLayoutParameter();
			scrollAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			scrollAlign.setRelativeName("scroll");
			scrollAlign.setRelativeToWidgetName("label");
			scrollAlign.setMargin(borderMargin - 7, 2 * LevelSettingsLayer.SEPARATOR + delta, 0, 0);
			this.mapElementsTable.setLayoutParameter(scrollAlign);

			this.addChild(this.mapElementsTable);

			for (var el in mapElementsType)
			{
				if (!mapElementsType.hasOwnProperty(el) || mapElementsType[el] === mapElementsType.None) continue;

				this._mapElementTypes.push(mapElementsType[el]);

				var element = new ccui.ImageView(pm.spriteUtils.getMapGrassTileName(mapElementsType[el]), ccui.Widget.PLIST_TEXTURE);

				this.mapElementsTable.setCell(Math.floor(i / maxElementsCountPerRaw), i % maxElementsCountPerRaw, element);
				i++;
			}

			delta += this.mapElementsTable.height + 2 * LevelSettingsLayer.SEPARATOR;
		}
		this._extraSettings = pm.moduleUtils.getExtraMapSettings(level.getType(), this);

		if (this._extraSettings)
		{
			var extraAlign = new ccui.RelativeLayoutParameter();
			extraAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			extraAlign.setRelativeToWidgetName("label");
			extraAlign.setMargin(borderMargin, delta + LevelSettingsLayer.SEPARATOR, 0, 0);
			this._extraSettings.layout.setLayoutParameter(extraAlign);

			this.addChild(this._extraSettings.layout);

			height += this._extraSettings.layout.height + 2 * LevelSettingsLayer.SEPARATOR;
		}

		height += LevelSettingsLayer.SEPARATOR + LevelSettingsLayer.BORDER_RADIUS;
		this.updateSize(LevelSettingsLayer.WIDTH, height);

		pm.registerCustomEventListener(pme.ADD_ROBOT_SELECT_EVENT_STR, function(event)
		{
			if (this.mapElementsTable)
				this.mapElementsTable.deselectAll();
		}.bind(this), this);

		pm.registerCustomEventListener(pme.ADD_OBJECT_SELECT_EVENT_STR, function(event)
		{
			if (this.mapElementsTable)
				this.mapElementsTable.deselectAll();
		}.bind(this), this);

		pm.registerCustomEventListener(pme.MAP_LAYERS_COUNT_CHANGED_EVENT_STR, function(event)
		{
			if (this.mapElementsTable && event.getUserData() !== MapLayersEditActions.DeleteCurrent)
				this.mapElementsTable.deselectAll();
		}.bind(this), this);
	},

	_selectMapElementCallback: function (selectedMapElement)
	{
		if (this._extraSettings && this._extraSettings.callback)
			this._extraSettings.callback.call();

		if (selectedMapElement)
			pm.sendCustomEvent(pme.MAP_ELEMENT_SELECT_EVENT_STR, this._mapElementTypes[selectedMapElement.x * MapElementsLayer.maxElementsCountPerRaw + selectedMapElement.y]);
		else
			pm.sendCustomEvent(pme.MAP_ELEMENT_SELECT_EVENT_STR, undefined);
	}
});

MapElementsLayer.maxElementsCountPerRaw = 4;
