/**
 * Created by Diana Agliamutdinova on 01.05.2019.
 */

/**
 * @class Represents node of syntax tree
 */
pm.data.Node = pm.Class.extend(/** @lends pm.data.Node#*/{
	typeName: 'Node',
	type: null,
	value: null,
	children: [],
	parentNode: null,
	id: null,
	_isEditable: true,

	_nodeView: null,
	_nodeName: null,

	ctor: function (type, val, parent)
	{
		this._addNonEnumerableProps("_nodeView", "parentNode");

		this._super();

		if (type !== undefined && val !== undefined)
		{
			this.type = type;
			this.value = val;
			this.children = [];
		}
		if(parent !== undefined)
			this.parentNode = parent;
	},

	setNodeView: function (nodeView)
	{
		this._nodeView = nodeView;
	},

	getNodeView: function ()
	{
		return this._nodeView;
	},

	getTagName: function ()
	{
		return this._nodeName;
	},

	setTagName: function (name)
	{
		this._nodeName = name;
	},

	getChildren: function ()
	{
		return this.children;
	},

	removeChild: function (child)
	{
		for (var i = 0; i < this.children.length; i++)
		{
			if (this.children[i] === child)
			{
				this.children.splice(i, 1);
			}
		}
	},

	isMain: function ()
	{
		return this.value === pm.data.Node.MAIN;
	},

	setNonEditable: function ()
	{
		this._isEditable = !this._isEditable;
	},

	isEditable: function ()
	{
		return this._isEditable;
	},

	clone: function ()
	{
		if(this.type === pm.data.Node.Type.Alloc || this.type === pm.data.Node.Type.Func)
			return null;
		var newNode = new pm.data.Node(this.type, this.value, this.parentNode);
		for(var i = 0;i < this.children.length;i++)
		{
			var childClone = this.children[i].clone();
			if(childClone)
			{
				newNode.children.push(childClone);
				if (this.children[i]._nodeName)
					newNode.children[i].setTagName(this.children[i]._nodeName);
			}
		}
		return newNode;
	},

	getUsedMethodsCount: function ()
	{
		if(this.type === pm.data.Node.Type.Action)
			return 1;
		var methodsCount = 0;
		for(var i = 0;i < this.children.length;i++)
		{
			methodsCount += this.children[i].getUsedMethodsCount();
		}
		return methodsCount;
	}
});

/**
 * Created by Diana Agliamutdinova on 01.05.2019.
 */

/**
 * @class Represents program by syntax tree
 */
pm.data.SyntaxTree = pm.Class.extend(/** @lends pm.data.SyntaxTree#*/{
	typeName: 'SyntaxTree',
	/**
	 * Type of tree node.</br>
	 * @type {Object}
	 */
	head: null,

	ctor: function ()
	{
		this._super();
	},

	createMainAlg: function ()
	{
		var main = new pm.data.Node(pm.data.Node.Type.MainAlg, "main");
		this.head.children.push(main);
		return main;
	},

	createGlobals: function ()
	{
		var body = new pm.data.Node(pm.data.Node.Type.Globals, "");
		this.head.children.push(body);
		return body;
	},

	getUsedMethodsCount: function () {
		return this.head.getUsedMethodsCount();
	}
});
pm.data.SyntaxTree.moveNode = function(parent, node, index)
{
	var place = pm.data.SyntaxTree._getChildPlace(parent, index);
	parent.children.splice(place + 1, 0, node);
};

pm.data.SyntaxTree._getChildPlace =	function(parent, index)
{
	if (index === pm.BodyFirstChildTag)
		return -1;
	var place = parent.children.length - 1;
	if (index !== undefined && index !== null)
	{
		place = parent.children.indexOf(index);
	}
	return place;
};

pm.data.SyntaxTree.createNode = function(parent, type, value, index)
{
	var body = new pm.data.Node(type, value, parent);
	var place = pm.data.SyntaxTree._getChildPlace(parent, index);

	parent.children.splice(place + 1, 0, body);
	return body;
};


pm.data.Node.MAIN = "main";
pm.data.Node.Type = {
	Prog: 'prog',
	MainAlg: 'main',
	Globals: 'global variables',
	Func: 'function',
	Body: 'algorithm',
	Statement: 'statement',
	IfStatement: 'if',
	Loop: 'loop',
	Type: 'type',
	Identifier: 'id',
	Number: 'number',
	Bool: 'bool',
	Expression: "expression",
	Condition: 'condition',
	LogicExpression: "logic_expr",
	AndOrExpression: "andor_expr",
	NotExpression: "not_expr",
	Action: "action",
	Alloc: "alloc",
	Empty: 'empty',
	HorizontalBar: 'horizontal bar'
};
pm.LoopVals = {
	nTimes: 'nTimes',
	for: 'for',
	whileC: 'while'
	//until:'until'
};
pm.ArythmMethods = {
	Division: "devide",
	Addition: "add",
	Substraction: "sub",
	Multiplication: "mult"
};
pm.ArythmConditions = {
	LeftLRight: "llr",
	RightLLeft: "rll",
	Equal: "equal",
	NotEqual: "not-equal",
	LeftEqual: "llr-equal",
	RightEqual: "rll-equal"
};

pm.BasicLogic = {
	And: "and",
	Or: "or"
};

pm.NotFunction = {
	Not: "not"
}

pm.ArythmSignsTexts = {};
pm.ArythmSignsTexts[pm.ArythmConditions.LeftLRight] = "<";
pm.ArythmSignsTexts[pm.ArythmConditions.RightLLeft] = ">";
pm.ArythmSignsTexts[pm.ArythmConditions.Equal] = "=";
pm.ArythmSignsTexts[pm.ArythmConditions.NotEqual] = "!=";
pm.ArythmSignsTexts[pm.ArythmConditions.LeftEqual] = "<=";
pm.ArythmSignsTexts[pm.ArythmConditions.RightEqual] = ">=";
pm.ArythmSignsTexts[pm.ArythmMethods.Addition] = "+";
pm.ArythmSignsTexts[pm.ArythmMethods.Substraction] = "-";
pm.ArythmSignsTexts[pm.ArythmMethods.Multiplication] = "*";
pm.ArythmSignsTexts[pm.ArythmMethods.Division] = "/";
pm.ArythmSignsTexts[pm.BasicLogic.And] = "and";
pm.ArythmSignsTexts[pm.BasicLogic.Or] = "or";
pm.ArythmSignsTexts[pm.NotFunction.Not] = "not";

pm.NTimesNumTag = "number";
pm.StatementVarTag = "var";
pm.StatementValueTag = "value";
pm.BodyFirstChildTag = "first";
pm.StatementVarTag = "var";
pm.StatementValueTag = "value";
pm.CommanParamTag = "param";
pm.ExpressionLeftTag = "left";
pm.ExpressionRightTag = "right";
pm.ForIteratorTag = "iter";
pm.ForBegTag = "begVal";
pm.ForEndTag = "endVal";
pm.ForStepTag = "step";
pm.FuncParamTag = "param";
pm.IFThenTag = "then";
pm.IFElseTag = "else";
pm.BodyTag = "bodyTag";
pm.IFCondTag = "condition";
pm.WhileCondTag = "condition";
pm.ArythmConditionsButtons = [];
pm.ArythmMethodsButtons = [];

pm.BoolText = {
	1 : "yes",
	0 : "no"
}
pm.BoolTrue = 1;
pm.BoolFalse = 0;

pm.AllocVals = {
	INT : "int",
	BOOL : "bool"
};
pm.IfVals = {
	IF_ST : "if",
	IF_ELSE  : "if-else"
};
pm.Constants = {
	One: 1,
	Two: 2,
	Three: 3,
	Four: 4,
	Five: 5,
	Six: 6
};
pm.loops = [
	pm.LoopVals.for, pm.LoopVals.nTimes, pm.LoopVals.whileC//, pm.LoopVals.until
];
pm.funcs = [
	pm.CMD_A,
	pm.CMD_B,
	pm.CMD_C,
	pm.CMD_D,
	pm.CMD_E
];
pm.conds = [
];
pm.expressions = [
	pm.ArythmMethods.Division,
	pm.ArythmMethods.Addition,
	pm.ArythmMethods.Substraction,
	pm.ArythmMethods.Multiplication
];
pm.logicOperatorsLessGreater= [

];
pm.logicOperatorsEquals= [

];
pm.logicOperatorsAndOr= [

];
pm.logicOperatorsNot= [

];
pm.alloc = [
	pm.AllocVals.INT,
	pm.AllocVals.BOOL
];
pm.ifConstructions = [
	pm.IfVals.IF_ST,
	pm.IfVals.IF_ELSE
];
var MapClass = cc.Class.extend({
	_values: [],
	ctor: function ()
	{
		this._values = [];
	},
	set: function (key, val)
	{
		if(!this.has(key))
			this._values.push({key: key, val: val});
	},

	get: function (key)
	{
		var node = null;
		this._values.forEach(function (elem){
			if(elem.key === key)
			{
				node = elem.val;
				return;
			}
		});
		return node;
	},
	has: function (key)
	{
		return this._values.some(function (elem){
			return elem.key === key ? true : false;
		});
	},
	values: function ()
	{
		return this._values.map(function(elem) {
				return elem.val;
				});
	},
	keys: function ()
	{
		return this._values.map(function(elem) {
			return elem.key;
		});
	},
	delete: function (key)
	{
		if(!this.has(key))
			return;
		var deleteIndex = -1;
		this._values.forEach(function (elem, index){
			if(elem.key === key)
			{
				deleteIndex = index;
				return;
			}
		});
		this._values.splice(deleteIndex, 1);
	},
	size: function ()
	{
		return this._values.length;
	},
	clear: function ()
	{
		this._values = [];
	}

});
var SetClass = cc.Class.extend({
	_values: [],
	ctor: function (init)
	{
		this._values = init;
	},
	add: function (key)
	{
		if(!this.has(key))
			this._values.push(key);
	},
	has: function (key)
	{
		return this._values.indexOf(key) !== -1;
	},
	delete: function (key)
	{
		if(!this.has(key))
			return;
		var deleteIndex = this._values.indexOf(key);
		this._values.splice(deleteIndex, 1);
	},
	size: function ()
	{
		return this._values.length;
	},
	clear: function ()
	{
		this._values = [];
	},
	values: function ()
	{
		return this._values;
	}
});
