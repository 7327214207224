/**
 * Created by Diana Agliamutdinova on 01.05.2019.
 */

/**
 * @class Represents program of one robot.
 */
var TextualTreeCreator = ProgramTreeCreator.extend({

	_visualTree: null,
	_robot: null,
	_outputRobot: null,

	ctor: function (programTree, robot, programContainer, outputRobot, syntaxModule)
	{
		this._visualTree = new TextualTree(
			programTree,
			robot,
			programContainer,
			outputRobot,
			syntaxModule);

		this._robot = robot;
		if(outputRobot)
			this._outputRobot = outputRobot;
	},

	addOutput: function (outputRobot)
	{
		this._outputRobot = outputRobot;
		this._visualTree.addOutputRobot(outputRobot);
	},

	reset: function ()
	{
		ProgramTreeCreator.prototype.reset.call(this);

		this.changeParamsValues();
	},

	getNativeFunction: function (val)
	{
		return this._robot.nativeFunctionMap[val] ? this._robot.nativeFunctionMap[val]
			: this._outputRobot.nativeFunctionMap[val];
	},

	changeParamsValues: function ()
	{
		this._visualTree.changeParamsValues();
	},

	pasteNode: function (parent, button, selectedElem)
	{
		var index = null;
		if (selectedElem)
		{
			if (selectedElem._id === parent._id + pm.BodyFirstChildTag)
				index = pm.BodyFirstChildTag;
			else
				index = selectedElem._treeNode;
		}
		var object = null;
		var syntTreeNode = null;
		var updateSelectL = false;
		switch (button.type)
		{
			case FunctionButton.Type.Method:
				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.Action, button.value, index);

				var paramsN = 0;
				paramsN = button.isNativeMethod() ? this.getNativeFunction(button.value).getParamsNumber()
					: this._robot.getProgramData().funcsMap[button.value];

				for (var i = 0; i < paramsN; i++)
				{
					num = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Number, 0);
					num.setTagName(pm.CommanParamTag + i);
				}
				break;

			case FunctionButton.Type.IntMethod:
				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.Action, button.value, index);
				break;

			case FunctionButton.Type.Function:
				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.Func, button.value);
				var body = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Body, "");
				body.setTagName(pm.BodyTag);

				updateSelectL = true;
				break;

			case FunctionButton.Type.IfStatement:
				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.IfStatement, button.value, index);
				var body = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Body, pm.IFThenTag);
				body.setTagName(pm.IFThenTag);

				var boolNode = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Bool, pm.BoolTrue);
				boolNode.setTagName(pm.IFCondTag);

				if (button.value === pm.IfVals.IF_ELSE)
				{
					var elseBody = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Body, pm.IFElseTag);
					elseBody.setTagName(pm.IFElseTag);
				}
				break;

			case FunctionButton.Type.ElseBlock:
				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.Body, pm.IFElseTag);
				syntTreeNode.setTagName(pm.IFElseTag);
				break;

			case FunctionButton.Type.Condition:
				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.Condition, button.value);
				break;

			case FunctionButton.Type.LogicExpression:
				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.LogicExpression, button.value);

				var left = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Number, 0);
				var right = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Number, 0);
				left.setTagName(pm.ExpressionLeftTag);
				right.setTagName(pm.ExpressionRightTag);
				break;

			case FunctionButton.Type.AndOrExpression:
				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.AndOrExpression, button.value);

				var left = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Bool, pm.BoolTrue);
				var right = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Bool, pm.BoolTrue);
				left.setTagName(pm.ExpressionLeftTag);
				right.setTagName(pm.ExpressionRightTag);
				break;

			case FunctionButton.Type.NotExpression:
				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.NotExpression, button.value);

				var right = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Bool, pm.BoolTrue);
				right.setTagName(pm.ExpressionRightTag);
				break;

			case FunctionButton.Type.Repeater:
				var body = null;

				switch (button.value)
				{
					case pm.LoopVals.whileC:
						syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.Loop, button.value, index);

						body = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Body, "");
						body.setTagName(pm.BodyTag);
						var boolNode = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Bool, pm.BoolTrue);
						boolNode.setTagName(pm.WhileCondTag);
						break;

					case pm.LoopVals.nTimes:
						syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.Loop, button.value, index);
						body = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Body, "");
						body.setTagName(pm.BodyTag);
						var number = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Number, 0);
						number.setTagName(pm.NTimesNumTag);
						break;

					case pm.LoopVals.for:
						var name = "i";
						var i = 1;
						while(pmui.AllocNode.intVals.indexOf(name) !== -1)
						{
							name = "i{0}".format(i);
							i++;
						}
						pmui.AllocNode.intVals.push(name);
						var mainBody = parent;
						var selected = selectedElem;
						while(!(mainBody instanceof pmui.FunctionNode))
						{
							mainBody = mainBody._parentNode;
						}
						mainBody = mainBody.getBody();

						var alloc = pm.data.SyntaxTree.createNode(mainBody._treeNode, pm.data.Node.Type.Alloc, "", pm.BodyFirstChildTag);
						var type = pm.data.SyntaxTree.createNode(alloc, pm.data.Node.Type.Type, pm.AllocVals.INT);
						var id = pm.data.SyntaxTree.createNode(alloc, pm.data.Node.Type.Identifier, name);
						var allocView = this._visualTree.parseTree(alloc, mainBody, mainBody._firstElem);

						if(mainBody._firstElem === selected)
						{
							index = alloc;
						}

						syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.Loop, button.value, index);
						body = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Body, "");
						body.setTagName(pm.BodyTag);

						var indexVar = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Identifier, name);
						indexVar.setTagName(pm.ForIteratorTag);

						var num1 = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Number, 0);
						num1.setTagName(pm.ForBegTag);

						var num2 = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Number, 0);
						num2.setTagName(pm.ForEndTag);

						updateSelectL = true;
						break;
				}
				break;

			case FunctionButton.Type.Alloc:
				if(!parent._parentNode.isFunction() && !parent.isFunction())
					return ;
				var name = null;
				if(parent.isFunction())
				{
					var ind = 0;
					while(parent._treeNode.getChildren()[ind].type === pm.data.Node.Type.Alloc)
					{
						index = parent._treeNode.getChildren()[ind];
						ind++;
					}
				}
				name = button.value;

				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.Alloc, "", index);
				var varType = pmui.AllocNode.intVals.indexOf(button.value) !== -1 ? pm.AllocVals.INT : pm.AllocVals.BOOL;
				var type = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Type, varType);
				var id = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Identifier, name);

				updateSelectL = true;
				break;

			case FunctionButton.Type.Statement:
				var statType = button.getVarType();

				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.Statement, statType, index);
				var variable = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Identifier, button.value);
				variable.setTagName(pm.StatementVarTag);
				if (statType === pm.AllocVals.INT)
				{
					var num = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Number, 0);
					num.setTagName(pm.StatementValueTag);
				}
				else
				{
					var num = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Bool, pm.BoolTrue);
					num.setTagName(pm.StatementValueTag);
				}
				break;

			case FunctionButton.Type.Variable:
				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.Identifier, button.value);
				break;

			case FunctionButton.Type.Number:
				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.Number, button.value);
				break;

			case FunctionButton.Type.Expression:
				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.Expression, button.value);

				var num = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Number, 0);
				num.setTagName(pm.ExpressionLeftTag);

				var num2 = pm.data.SyntaxTree.createNode(syntTreeNode, pm.data.Node.Type.Number, 0);
				num2.setTagName(pm.ExpressionRightTag);
				break;

			case FunctionButton.Type.Empty:
				syntTreeNode = pm.data.SyntaxTree.createNode(parent._treeNode, pm.data.Node.Type.Empty, "", index);
				break;

			case FunctionButton.Type.Constant:
				object = selectedElem.setValue(button.value);
				break;
		}
		if(syntTreeNode)
			object = this._visualTree.parseTree(syntTreeNode, parent, selectedElem);

		if(updateSelectL || this.getMethodsCount() >= this._robot.getProgramData().maxMethodCount)
			pm.sendCustomEvent(pm.COMMANDS_CHANGED);
		return object;
	},

	pasteNodeList: function (element, methodList)
	{
		var selected = null;
		if (element._id === element._parentNode._id + pm.BodyFirstChildTag)
			selected = pm.BodyFirstChildTag;
		else
			selected = element._treeNode;

		var selectedNode = element;
		for (var i = 0; i < methodList.length && this.getMethodsCount() < this._robot.getProgramData().maxMethodCount; i++)
		{
			selected = pm.data.SyntaxTree.createNode(
				element._parentNode._treeNode,
				pm.data.Node.Type.Action,
				methodList[i],
				selected);
			selectedNode = this._visualTree.parseTree(selected, element._parentNode, selectedNode);
		}
		return selectedNode;
	},

	moveNode: function(node, selectedNode)
	{
		var index = null;
		if (selectedNode)
		{
			if (selectedNode._id === selectedNode._parentNode._id + pm.BodyFirstChildTag)
			{
				index = pm.BodyFirstChildTag;
				if(node.isEmpty() && node._parentNode._treeNode.getChildren().length === 1)
					return null;
			}
			else
				index = selectedNode._treeNode;
		}
		var treeNode = node._treeNode.clone();
		if(!treeNode || this.getMethodsCount() + treeNode.getUsedMethodsCount() > this._robot.getProgramData().maxMethodCount)
			return null;
		pm.data.SyntaxTree.moveNode(selectedNode._parentNode._treeNode, treeNode, index);

		var pasted = this._visualTree.parseTree(treeNode, selectedNode._parentNode, selectedNode);
		return pasted;
	},

	getMethodsCount: function ()
	{
		return this._robot.getProgramData().programTree.getUsedMethodsCount();
	},

	_isParentUpper: function(node, parent)
	{
		var curParent = node._parentNode;
		while(!curParent.isFunction())
		{
			if(curParent === parent)
				return true;
			else
				curParent = curParent._parentNode;
		}
		return false;
	},

	_isVariableLower: function(treeNode, varName)
	{
		if(!treeNode)
			return  false;
		var curParent = treeNode.parentNode;
		var curElem = treeNode;
		for (var i = 0; i < curParent.getChildren().length; i++)
		{
			if (curParent.getChildren()[i].type === pm.data.Node.Type.Identifier
				&& curParent.getChildren()[i].value === varName)
				return true;

			if (curParent.getChildren()[i].type === pm.data.Node.Type.Statement
				&& curParent.getChildren()[i].getNodeView().getVarName() === varName)
				return true;

			if (curParent.getChildren()[i] === curElem)
			{
				break;
			}
			if(curParent.getChildren()[i].getChildren().length !== 0)
				if(this._isVariableLower(curParent.getChildren()[i].getChildren()
					[curParent.getChildren()[i].getChildren().length - 1], varName))
					return true;
		}
		return false;
	}
});
