/**
 * Created by Kirill Mashchenko on 27.06.17.
 */

/**
 * @class Common interface for terrain sprites.
 * @interface
 * @extends cc.Class
 */
pm.TerrainSprite = cc.Class.extend(/** @lends pm.TerrainSprite#*/{

	sprite: null,

	ctor: function(frame)
	{
		this.sprite = new cc.Sprite(frame);
	},

	/**
     * Return Sprite of this object
     * @returns {cc.Node}
     */
	getSprite: function ()
	{
		return this.sprite;
	}
});

