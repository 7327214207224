/**
 * Created by MU on 20.04.2020.
 */
/**
 * @class Sprite for {@link pm.data.TrainBarrel}
 * @implements PushObjectSprite
 */
var TrainBarrelSprite = TrainObjectSprite.extend(/** @lends TrainBarrelSprite# */{

	ctor: function()
	{
		this._super("Objects/GreyBarrel.png", "Objects/GreenBarrel.png");

		this._minPoint = cc.p(35, 20);
		this._maxPoint = cc.p(this.getSprite().getContentSize().width / 2, 3);

		this._idSprite.setAnchorPoint(cc.p(0.72, 0.95));
	},

	_getIdNumberSpritePosition: function()
	{
		return cc.p(39, 60);
	}
});
