/**
 * Created by Kirill Mashchenko  on 27.08.18.
 */

pmui.ConfirmWindow = pmui.OvalLayout.extend(/** @lends pmui.ConfirmWindow#*/{

	_target: null,
	_okCallback: null,
	_cancelCallback: null,

	ctor: function(labelStr, extraInformation)
	{
		this._super(null, null, pmui.OvalLayout.Type.SLIM);

		this.setLayoutType(ccui.Layout.RELATIVE);

		var height = 0;

		var label = new ccui.Text(LocalizedString("ConfirmAction").format(labelStr), pm.settings.fontName, pm.settings.fontSize);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		labelAlign.setMargin(0, pmui.OvalLayout.STANDARD_BORDER_RADIUS, 0, 0);
		labelAlign.setRelativeName("label");
		label.setLayoutParameter(labelAlign);
		label.setTextAreaSize(cc.size(pmui.ConfirmWindow.WIDTH, 0));
		label.setTextHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
		this.addChild(label);

		height += label.height;

		if (extraInformation !== undefined)
		{
			var label = new ccui.Text(extraInformation, pm.settings.fontName, pm.settings.fontSize);

			var labelAlign = new ccui.RelativeLayoutParameter();
			labelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
			labelAlign.setMargin(0, pmui.ConfirmWindow.SEPARATOR, 0, 0);
			labelAlign.setRelativeName("extraInformation");
			labelAlign.setRelativeToWidgetName("label");

			label.setLayoutParameter(labelAlign);
			label.setTextAreaSize(cc.size(pmui.ConfirmWindow.WIDTH, 0));
			label.setTextHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
			this.addChild(label);

			height += label.height;
		}

		var buttonLayout = new ccui.HBox();

		var buttonLayoutAlign = new ccui.RelativeLayoutParameter();
		buttonLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		buttonLayoutAlign.setMargin(0, pmui.ConfirmWindow.SEPARATOR, 0, 0);

		if (extraInformation !== undefined)
			buttonLayoutAlign.setRelativeToWidgetName("extraInformation");
		else
			buttonLayoutAlign.setRelativeToWidgetName("label");

		buttonLayout.setLayoutParameter(buttonLayoutAlign);

		this.addChild(buttonLayout);

		var okButton = new pmui.InterfaceButton(this._ok, this, LocalizedString("OkButton"));
		buttonLayout.addChild(okButton);

		var cancelButton = new pmui.InterfaceButton(this._cancel, this, LocalizedString("CancelButton"));
		var cancelMargin = new ccui.LinearLayoutParameter();
		cancelMargin.setMargin(pmui.ConfirmWindow.SEPARATOR, 0, 0, 0);
		cancelButton.setLayoutParameter(cancelMargin);

		buttonLayout.addChild(cancelButton);

		buttonLayout.setContentSize(okButton.getContentSize().width + cancelButton.getContentSize().width + 10,
			okButton.getContentSize().height);

		height += buttonLayout.height + 2 * pmui.ConfirmWindow.SEPARATOR + pmui.OvalLayout.STANDARD_BORDER_RADIUS;

		this.setContentSizeWithBorder(pmui.ConfirmWindow.WIDTH + 2 * pmui.OvalLayout.STANDARD_BORDER_RADIUS, height);

		var loadingLayer = new LoadingLayer(false);
		loadingLayer.setPosition((this.width - pm.settings.getScreenSize().width) / 2,
			(this.height - pm.settings.getScreenSize().height) / 2);

		this.addChild(loadingLayer, -100);

		var listener = cc.EventListener.create({
			event: cc.EventListener.TOUCH_ONE_BY_ONE,
			swallowTouches: true,
			onTouchBegan: function()
			{
				return true;
			},
			onTouchMoved: function() {},
			onTouchEnded: function() {}
		});

		cc.eventManager.addListener(listener, this);
	},

	/**
     * Set callbacks for dialog.
     * @param target Target for callbacks.
     * @param {pmui.ConfirmWindow~okCallback} okCallback On "OK" button pressed.
     * @param {pmui.ConfirmWindow~cancelCallback} cancelCallback On "Cancel" button pressed.
     */
	setCallback: function (target, okCallback, cancelCallback)
	{
		this._target = target;
		this._okCallback = okCallback;
		this._cancelCallback = cancelCallback;
	},

	_ok: function(sender)
	{
		this._okCallback.call(this._target);
		this.removeFromParent();
	},

	_cancel: function(sender)
	{
		if (this._cancelCallback)
			this._cancelCallback.call(this._target);
		this.removeFromParent();
	}
});

pmui.ConfirmWindow.WIDTH = 250;
pmui.ConfirmWindow.SEPARATOR = 10.0;
