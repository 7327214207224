/**
 * Created by Nikita Besshaposhnikov on 13.03.18.
 */

pm.ControlledTeam = cc.Class.extend({

	game: 0,
	level: 0,

	hostStudent: null,

	students: [],

	state: pm.NetworkGameState.Develop,

	statesMap: {},

	markDeleted: false,

	robotIndexMap: null,

	index: -1,

	transitionFinished: true,

	ctor: function(host, name, game, level)
	{
		this.hostStudent = new pm.ControlledStudent(host, name);

		this.level = level;
		this.game = game;

		this.students = [];
	},

	clearPrograms: function()
	{
		this.hostStudent.program = null;

		for (var i = 0; i < this.students.length; ++i)
			this.students[i].program = null;
	}
});

pm.ControlledStudent = cc.Class.extend({

	host: 0,
	name: 0,
	client: null,

	state: -1,

	discoverDate: null,
	lastConnectPacketDate: null,

	program: null,

	ctor: function(host, name, state)
	{
		this.name = name;
		this.host = host;

		this.discoverDate = new Date();

		this.state = state || pm.ControlledStudent.State.Disconnected;
	}
	//
	// isConnected: function()
	// {
	//     return this.client !== null;
	// }

});

pm.ControlledStudent.State = {
	Disconnected: -1,
	Free: 0,
	Connecting: 1,
	Connected: 2,
	ConnectedToGame: 3,
	PendingReconnect: 4
};

pm.TeacherServerImpl = pm.GameServer.extend({

	teams: [],
	_clients: [],
	_refreshCallback: null,

	ctor: function()
	{
		this._super();
		this._clients = [];
		this.teams = [];

		this.setEventCallback(this._eventCallback.bind(this));
		this.setPacketCallback(this._packetCallback.bind(this));
	},

	setRefreshCallback: function(callback)
	{
		this._refreshCallback = callback;
	},

	start: function(port)
	{
		pm.GameServer.prototype.start.call(this, port);

		// cc.director.getScheduler().schedule(this._requestClientsInfo, this, 1, cc.REPEAT_FOREVER, 0, false, "clientsInfo");
	},

	// _requestClientsInfo: function()
	// {
	// 	pm.networkUtils.log("Teacher server requesting info");
	//
	// 	var packet = {type: pm.TEACHER_SERVER_PACKET_TYPE.STATUS_REQUEST};
	//
	// 	this.sendBroadcastPacket(JSON.stringify(packet));
	// },

	_sendStartServer: function(client, game, level, playerCount, programMap, robotIndexMap)
	{
		var packet = {
			type: pm.TEACHER_SERVER_PACKET_TYPE.START_SERVER,
			game: game,
			level: level,
			levelObject: world.games[game].levels[level],
			playerCount: playerCount,
			programMap: programMap,
			robotIndexMap: robotIndexMap
		};

		pm.networkUtils.log("Sending start server packet to client with id: "+client.getId(), pm.NetworkDebugSendTypes.TEACHER_SERVER, packet, client.getHost());

		this.sendPacketToClient(client.getId(), JSON.stringify(packet));
	},

	sendStopGame: function(client)
	{
		var packet = {type: pm.TEACHER_SERVER_PACKET_TYPE.STOP_GAME};

		pm.networkUtils.log("Sending stop game packet to client with id: "+client.getId(), pm.NetworkDebugSendTypes.TEACHER_SERVER, packet, client.getHost());

		this.sendPacketToClient(client.getId(), JSON.stringify(packet));
	},

	_sendBecomeServer: function(client, playerCount, robotIndexMap)
	{
		var packet = {
			type: pm.TEACHER_SERVER_PACKET_TYPE.BECOME_SERVER,
			playerCount: playerCount,
			robotIndexMap: robotIndexMap
		};

		pm.networkUtils.log("Sending become server packet to client with id: "+client.getId(), pm.NetworkDebugSendTypes.TEACHER_SERVER, packet, client.getHost());

		this.sendPacketToClient(client.getId(), JSON.stringify(packet));
	},

	sendConnectToServer: function(client, host, game, level, programMap, robotIndexMap)
	{
		var packet = {
			type: pm.TEACHER_SERVER_PACKET_TYPE.CONNECT_TO_SERVER,
			game: game,
			level: level,
			levelObject: world.games[game].levels[level],
			host: host,
			programMap: programMap,
			robotIndexMap: robotIndexMap
		};

		pm.networkUtils.log("Sending connect to server packet to client with id: "+client.getId(), pm.NetworkDebugSendTypes.TEACHER_SERVER, packet, client.getHost());

		this.sendPacketToClient(client.getId(), JSON.stringify(packet));
	},

	sendChangeLevel: function(client, game, level)
	{
		var packet = {
			type: pm.TEACHER_SERVER_PACKET_TYPE.CHANGE_LEVEL,
			game: game,
			level: level,
			levelObject: world.games[game].levels[level]
		};

		pm.networkUtils.log("Sending change level packet to client with id: "+client.getId(), pm.NetworkDebugSendTypes.TEACHER_SERVER, packet, client.getHost());

		this.sendPacketToClient(client.getId(), JSON.stringify(packet));
	},

	sendTimeInfo: function(client, solvingTimes)
	{
		var packet = {
			type: pm.TEACHER_SERVER_PACKET_TYPE.TIME_INFO,
			solvingTimes: solvingTimes
		};

		pm.networkUtils.log("Sending time info packet to client with id: "+client.getId(), pm.NetworkDebugSendTypes.TEACHER_SERVER, packet, client.getHost());

		this.sendPacketToClient(client.getId(), JSON.stringify(packet));
	},

	_sendReconnectToServer: function(client, host)
	{
		var packet = {
			type: pm.TEACHER_SERVER_PACKET_TYPE.RECONNECT_TO_SERVER,
			host: host
		};

		pm.networkUtils.log("Sending reconnect to server packet to client with id: "+client.getId(), pm.NetworkDebugSendTypes.TEACHER_SERVER, packet, client.getHost());

		this.sendPacketToClient(client.getId(), JSON.stringify(packet));
	},

	_reselectTeamHost: function(team, newHostIndex)
	{
		var newHost = team.students[newHostIndex];
		team.students.splice(newHostIndex, 1);

		team.students.push(team.hostStudent);

		pm.networkUtils.log("Reselecting team host from: " + team.hostStudent.host + " to: " + newHost.host, pm.NetworkDebugSendTypes.TEACHER_SERVER, null, newHost.host);

		team.hostStudent = newHost;

		if(team.robotIndexMap)
		{
			var indexMap = cc.clone(team.robotIndexMap);

			indexMap[pm.LOCAL_ADDRESS] = indexMap[team.hostStudent.host];
			delete indexMap[team.hostStudent.host];

			pm.networkUtils.log("Sending robot index map packet: " + JSON.stringify(indexMap), pm.NetworkDebugSendTypes.TEACHER_SERVER, null, newHost.host);

			this._sendBecomeServer(newHost.client, team.students.length + 1, indexMap);
		}
		else
		{
			this._sendBecomeServer(newHost.client, team.students.length + 1);
		}

		for (var s = 0; s < team.students.length; ++s)
		{
			if (team.students[s].client)
			{
				team.students[s].state = pm.ControlledStudent.State.PendingReconnect;
				this._sendReconnectToServer(team.students[s].client, team.hostStudent.host);
			}
		}
	},

	_eventCallback: function(event, client)
	{
		switch(Number(event))
		{
			case pm.GameServer.CLIENT_CONNECTED_EVENT:
				pm.networkUtils.log("Client connected to teacher server with id: " + client.getId(), pm.NetworkDebugSendTypes.TEACHER_SERVER, null, client.getHost());

				var student = null;

				for(var t = 0 ; t < this.teams.length; ++t)
				{
					var team = this.teams[t];

					if(team.markDeleted)
						continue;

					student = team.hostStudent;

					if(student.state === pm.ControlledStudent.State.Connecting && student.host === client.getHost())
					{
						team.hostStudent.state = pm.ControlledStudent.State.Connected;
						team.hostStudent.client = client;

						if(this._refreshCallback)
							this._refreshCallback(t);

						break;
					}

					for(var s = 0; s < team.students.length; ++s)
					{
						student = team.students[s];

						if(student.state === pm.ControlledStudent.State.Connecting && student.host === client.getHost())
						{
							team.students[s].state = pm.ControlledStudent.State.Connected;
							team.students[s].client = client;

							if(this._refreshCallback)
								this._refreshCallback(t);

							break;
						}
					}
				}

				break;

			case pm.GameServer.CLIENT_DISCONNECTED_EVENT:
				pm.networkUtils.log("Client disconnected from teacher server with id: " + client.getId(), pm.NetworkDebugSendTypes.TEACHER_SERVER, null, client.getHost());

				for(var t = 0 ; t < this.teams.length; ++t)
				{
					var team = this.teams[t];

					if(team.hostStudent.client && team.hostStudent.client.getId() === client.getId())
					{
						team.hostStudent.state = pm.ControlledStudent.State.Disconnected;
						team.hostStudent.client = null;

						if(!team.markDeleted)
						{
							for (var s = 0; s < team.students.length; ++s)
							{
								if (team.students[s].client)
								{
									this._reselectTeamHost(team, s);
									break;
								}
							}
						}

						if(this._refreshCallback)
							this._refreshCallback(t);

						break;
					}

					for(var s = 0; s < team.students.length; ++s)
					{
						if(team.students[s].client && team.students[s].client.getId() === client.getId())
						{
							team.students[s].state = pm.ControlledStudent.State.Disconnected;
							team.students[s].client = null;

							if(this._refreshCallback)
								this._refreshCallback(t);

							break;
						}
					}
				}

				break;
		}
	},

	stop: function ()
	{
		// cc.director.getScheduler().unschedule(this,  "clientsInfo");
		pm.GameServer.prototype.stop.call(this);
	},

	_packetCallback: function(client, packet)
	{
		var parsedPacket = undefined;

		try
		{
			parsedPacket = JSON.parse(packet);
		}
		catch (e)
		{
			pm.networkUtils.log("Incorrect packet from student: " + client.getId() + ". Packet data: \n" + packet, pm.NetworkDebugSendTypes.TEACHER_SERVER, packet);
			return;
		}

		if(parsedPacket !== null && !parsedPacket.type)
		{
			pm.networkUtils.log("Incorrect packet from student(no type field): " + client.getId() + ". Packet data: \n" + packet, pm.NetworkDebugSendTypes.TEACHER_SERVER, packet);
			return;
		}

		switch(parsedPacket.type)
		{
			case pm.STUDENT_CLIENT_PACKET_TYPE.LEVEL_STATUS_UPDATED:

				pm.networkUtils.log("Received update status packet from student " + client.getId(),pm.NetworkDebugSendTypes.TEACHER_SERVER, parsedPacket, client.getHost());

				var found = false;

				for(var t = 0 ; t < this.teams.length; ++t)
				{
					var team = this.teams[t];

					if(team.markDeleted)
						continue;

					if(team.hostStudent.client && team.hostStudent.client.getId() === client.getId())
					{
						team.statesMap[client.getId()] = parsedPacket.state;

						found = true;
					}

					for(var s = 0; s < team.students.length; ++s)
					{
						if(team.students[s].client && team.students[s].client.getId() === client.getId())
						{
							team.statesMap[client.getId()] = parsedPacket.state;

							found = true;

							break;
						}
					}

					if (found)
					{
						if(this._refreshCallback)
							this._refreshCallback(t);

						break;
					}
				}

				break;

			case pm.STUDENT_CLIENT_PACKET_TYPE.STATUS:

				pm.networkUtils.log("Received status packet from student " + client.getId() + " is connected to game: " + parsedPacket.connectedToGame, pm.NetworkDebugSendTypes.TEACHER_SERVER, parsedPacket, client.getHost());

				var foundTeam = null;
				var foundTeamIndex = -1;
				var foundStudent = null;
				var isHost = false;

				for(var t = 0 ; t < this.teams.length; ++t)
				{
					var team = this.teams[t];

					if(team.markDeleted)
						continue;

					if(team.hostStudent.client && team.hostStudent.client.getId() === client.getId())
					{
						foundTeam = team;
						foundTeamIndex = t;
						foundStudent = team.hostStudent;
						isHost = true;

						break;
					}

					for(var s = 0; s < team.students.length; ++s)
					{
						if(team.students[s].client && team.students[s].client.getId() === client.getId())
						{
							foundTeam = team;
							foundTeamIndex = t;
							foundStudent = team.students[s];

							break;
						}
					}
				}

				if(foundStudent)
				{
					var disconnected = foundStudent.state === pm.ControlledStudent.State.ConnectedToGame && !parsedPacket.connectedToGame;

					if(parsedPacket.connectedToGame)
						foundStudent.state = pm.ControlledStudent.State.ConnectedToGame;
					else if(foundStudent.state !== pm.ControlledStudent.State.PendingReconnect)
						foundStudent.state = pm.ControlledStudent.State.Connected;

					if(foundStudent.state === pm.ControlledStudent.State.Connected)
					{
						var programMap = {};

						pm.networkUtils.log("found team host student host: " + foundTeam.hostStudent.host, pm.NetworkDebugSendTypes.TEACHER_SERVER, null, foundTeam.hostStudent.host);
						// pm.networkUtils.log("found team host student program: " + JSON.stringify(foundTeam.hostStudent.program), pm.NetworkDebugSendTypes.TEACHER_SERVER);

						programMap[foundTeam.hostStudent.host] = foundTeam.hostStudent.program;

						for(var s = 0; s < foundTeam.students.length; ++s)
						{
							pm.networkUtils.log("found team student host: "+foundTeam.students[s].host, pm.NetworkDebugSendTypes.TEACHER_SERVER, null, foundTeam.students[s].host);
							// pm.networkUtils.log("found team student program: "+JSON.stringify(foundTeam.students[s].program), pm.NetworkDebugSendTypes.TEACHER_SERVER);

							programMap[foundTeam.students[s].host] = foundTeam.students[s].program;
						}

						pm.networkUtils.log("found team robot index map: "+JSON.stringify(foundTeam.robotIndexMap), pm.NetworkDebugSendTypes.TEACHER_SERVER, null, foundTeam.hostStudent.host);

						var indexMap = null;
						var programMapCopy = null;

						if (foundTeam.robotIndexMap)
						{
							indexMap = cc.clone(foundTeam.robotIndexMap);
							programMapCopy = cc.clone(programMap);

							indexMap[pm.LOCAL_ADDRESS] = indexMap[foundTeam.hostStudent.host];
							programMapCopy[pm.LOCAL_ADDRESS] = programMapCopy[foundTeam.hostStudent.host];

							delete indexMap[foundTeam.hostStudent.host];
							delete programMapCopy[foundTeam.hostStudent.host];
						}

						// pm.networkUtils.log("Program map: " + JSON.stringify(programMap), pm.NetworkDebugSendTypes.TEACHER_SERVER);

						if(isHost)
						{
							this._sendStartServer(client, foundTeam.game, foundTeam.level, foundTeam.students.length + 1, programMapCopy, indexMap);

							for (var s = 0; s < foundTeam.students.length; ++s)
							{
								if (foundTeam.students[s].state === pm.ControlledStudent.State.ConnectedToGame)
								{
									foundTeam.students[s].state = pm.ControlledStudent.State.PendingReconnect;
									this._sendReconnectToServer(foundTeam.students[s].client, foundTeam.hostStudent.host);
								}
							}
						}
						else if(!disconnected)
						{
							this.sendConnectToServer(client, foundTeam.hostStudent.host, foundTeam.game, foundTeam.level, programMapCopy, indexMap);
						}
						else
						{
							foundStudent.state = pm.ControlledStudent.State.PendingReconnect;
							this._sendReconnectToServer(client, foundTeam.hostStudent.host);
						}
					}

					foundTeam.state = parsedPacket.levelState;

					if(this._refreshCallback)
						this._refreshCallback(foundTeamIndex);
				}

				break;

			case pm.STUDENT_CLIENT_PACKET_TYPE.ROBOT_INDEX_MAP:
				pm.networkUtils.log("Received robot index map packet from student " + client.getId(), pm.NetworkDebugSendTypes.TEACHER_SERVER, parsedPacket, client.getHost());

				var foundTeam = null;

				for(var t = 0 ; t < this.teams.length; ++t)
				{
					var team = this.teams[t];

					if(team.markDeleted)
						continue;

					if(team.hostStudent.client && team.hostStudent.client.getId() === client.getId())
					{
						foundTeam = team;

						break;
					}
				}

				if(foundTeam)
				{
					var indexMap = parsedPacket.robotIndexMap;

					indexMap[client.getHost()] = indexMap[pm.LOCAL_ADDRESS];
					delete indexMap[pm.LOCAL_ADDRESS];

					pm.networkUtils.log("Received robot index map packet: " + JSON.stringify(indexMap), pm.NetworkDebugSendTypes.TEACHER_SERVER, null, client.getHost());

					foundTeam.robotIndexMap = indexMap;
				}

				break;

			case pm.STUDENT_CLIENT_PACKET_TYPE.PROGRAM:
				pm.networkUtils.log("Received program packet from student " + client.getId(), pm.NetworkDebugSendTypes.TEACHER_SERVER, parsedPacket, client.getHost());

				var foundTeam = null;
				var foundTeamIndex = -1;
				var foundStudent = null;
				var isHost = false;

				for(var t = 0 ; t < this.teams.length; ++t)
				{
					var team = this.teams[t];

					if(team.markDeleted)
						continue;

					if(team.hostStudent.client && team.hostStudent.client.getId() === client.getId())
					{
						foundTeam = team;
						foundTeamIndex = t;
						foundStudent = team.hostStudent;
						isHost = true;

						break;
					}

					for(var s = 0; s < team.students.length; ++s)
					{
						if(team.students[s].client && team.students[s].client.getId() === client.getId())
						{
							foundTeam = team;
							foundTeamIndex = t;
							foundStudent = team.students[s];

							break;
						}
					}
				}

				if(foundStudent)
				{
					pm.networkUtils.log("Saving student program", pm.NetworkDebugSendTypes.TEACHER_SERVER, parsedPacket, client.getHost());

					foundStudent.program = parsedPacket.program;
				}

				break;

			case pm.STUDENT_CLIENT_PACKET_TYPE.TIME_INFO:
				pm.networkUtils.log("Received time info packet from student " + client.getId(), pm.NetworkDebugSendTypes.TEACHER_SERVER, parsedPacket, client.getHost());

				var foundTeam = null;
				var foundTeamIndex = -1;

				for(var t = 0 ; t < this.teams.length; ++t)
				{
					var team = this.teams[t];

					if(team.markDeleted)
						continue;

					if(team.hostStudent.client && team.hostStudent.client.getId() === client.getId())
					{
						foundTeam = team;
						foundTeamIndex = t;

						break;
					}

					for(var s = 0; s < team.students.length; ++s)
					{
						if(team.students[s].client && team.students[s].client.getId() === client.getId())
						{
							foundTeam = team;
							foundTeamIndex = t;

							break;
						}
					}
				}

				if (foundTeam && parsedPacket.solvingTimes)
					foundTeam.solvingTimes = parsedPacket.solvingTimes;

				if(this._refreshCallback)
					this._refreshCallback(foundTeamIndex);

				break;

			case pm.STUDENT_CLIENT_PACKET_TYPE.LEVEL_SCENE_ENTERED:
				pm.networkUtils.log("Received level scene entered packet from student " + client.getId(), pm.NetworkDebugSendTypes.TEACHER_SERVER, parsedPacket, client.getHost());

				var foundTeam = null;
				var foundTeamIndex = -1;

				for(var t = 0 ; t < this.teams.length; ++t)
				{
					var team = this.teams[t];

					if(team.markDeleted)
						continue;

					if(team.hostStudent.client && team.hostStudent.client.getId() === client.getId())
					{
						foundTeam = team;
						foundTeamIndex = t;

						break;
					}

					for(var s = 0; s < team.students.length; ++s)
					{
						if(team.students[s].client && team.students[s].client.getId() === client.getId())
						{
							foundTeam = team;
							foundTeamIndex = t;

							break;
						}
					}
				}

				if (foundTeam)
					foundTeam.transitionFinished = true;

				if(this._refreshCallback)
					this._refreshCallback(foundTeamIndex);

				break;
		}
	}
});

