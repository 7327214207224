/**
 * Created by Nikita Besshaposhnikov on 05.12.14.
 */

/**
 * @class This layer contains view of whole level in editor.
 * @extends LevelLayer
 * @constructor
 * @param {Number} game Index of game for level.
 * @param {Number} level Index of level to load.
 * @param {GameType} gameType
 */
var LevelEditorLayer = LevelLayer.extend(/** @lends LevelEditorLayer# */{

	ctor: function(game, level)
	{
		this._super(GameType.Local, null, game, level);

		pm.registerCustomEventListener(pme.ADD_GLOBAL_ROBOT, function (event)
		{
			this._globalRobotsLayer.addRobot(event.getUserData());
		}.bind(this), this);

		pm.registerCustomEventListener(pme.REMOVE_GLOBAL_ROBOT, function (event)
		{
			this._globalRobotsLayer.removeRobot(event.getUserData());
		}.bind(this), this);

		pm.registerCustomEventListener(pme.REDRAW_GLOBAL_ROBOT, function (event)
		{
			this._globalRobotsLayer.redrawRobot(event.getUserData());
		}.bind(this), this);
	},

	_registerUpdateSceneEvent: function()
	{
		pm.registerCustomEventListener(pm.UPDATE_SCENE, function()
		{
			cc.director.runScene(new LevelEditorScene(pm.settings.getGame(), pm.settings.getLevel()));
		}.bind(this), this);
	},

	_loadLevel: function(robotIndex)
	{
		var screenBounds = pm.settings.getScreenBounds();

		this.setContentSize(pm.settings.getScreenSize());

		this._level = world.loadLevel(false, this._gameIndex, this._levelIndex, robotIndex);

		if(this._level !== null)
		{
			this._levelMenuLayer = new LevelMenuEditorLayer(this._level, this.isNetGame());

			var levelMenuAlign = new ccui.RelativeLayoutParameter();
			levelMenuAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
			this._levelMenuLayer.setLayoutParameter(levelMenuAlign);

			this.addChild(this._levelMenuLayer, 2);

			var baseRobot = this._level.getCurrentRobot();

			var useMethodStack = pm.moduleUtils.canUseMethodStack(this._level.getType()) && this._level.useMethodStack;

			this._programLayer = pm.appUtils.generateProgramEditorLayer(this._gameType, useMethodStack, baseRobot, this._level);

			var programLayerAlign = new ccui.RelativeLayoutParameter();
			programLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
			programLayerAlign.setRelativeName("programLayer");
			this._programLayer.setLayoutParameter(programLayerAlign);

			this.addChild(this._programLayer, 2);

			this._mapsContainerLayer = new MapsContainerEditorLayer(this._level);

			var mapsContainerLayerAlign = new ccui.RelativeLayoutParameter();
			mapsContainerLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
			this._mapsContainerLayer.setLayoutParameter(mapsContainerLayerAlign);

			this.addChild(this._mapsContainerLayer);

			if (this._level.activeMap.mapLayer instanceof pm.MapLayer2D && this._level.activeMap.mapLayer.hasChangeOrientationOption())
				this._levelMenuLayer.drawChangeOrientationButton();

			this._globalRobotsLayer = new GlobalRobotsLayer(this._level);

			var rightMargin = screenBounds.safeAreas.right ? screenBounds.right / 2 : 0;

			var globalRobotsLayerAlign = new ccui.RelativeLayoutParameter();
			globalRobotsLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
			globalRobotsLayerAlign.setMargin(-rightMargin, 0, 0, 0);
			this._globalRobotsLayer.setLayoutParameter(globalRobotsLayerAlign);

			this.addChild(this._globalRobotsLayer, 1);
		}
	},

	onEnterTransitionDidFinish: function()
	{
		ccui.Layout.prototype.onEnterTransitionDidFinish.call(this);
	}
});

/**
 * @class This class contains whole level layer in editor.
 * @extends cc.Scene
 * @constructor
 * @param {Number} game Index of game for level.
 * @param {Number} level Index of level to load.
 * @param {GameType} gameType
 */
var LevelEditorScene = cc.Scene.extend({
	ctor: function(game, level)
	{
		this._super();
		var layer = new LevelEditorLayer(game, level);
		this.addChild(layer);

		if (!cc.sys.isNative)
		    window.location.hash = "editor/worlds/" + world.id + "/games/" + game + "/levels/" + level;
	}
});
