/**
 * Created by Nikita Besshaposhnikov on 16.11.15.
 */

/**
 * Common animations of 2D robots.
 * @readonly
 * @enum {String}
 */
var RobotAnimation2D = {
	Turn: "turn",
	Move: "move",
	Destroy: "destroy",
	Indicate: "indicate"
};

/**
 * Enum for colors
 * @enum {String}
 */
var RobotColorList2D = {
	Green: cc.color(142, 241, 60),
	Orange: cc.color(255, 183, 98),
	Blue: cc.color(98, 255, 255),
	Pink: cc.color(255, 98, 255),
	Length: 4
};

/**
 * @class Common interface for 2D robot sprites.</br>
 * Provides move animations.
 * @interface
 * @extends pm.DynamicSprite
 */
pm.RobotSprite2D = pm.DynamicSprite.extend(/** @lends pm.RobotSprite2D#*/{

	_activeSprite: null,
	_activeCircleSprite: null,
	_commandsSprite: null,
	_commandsCount: null,

	ctor: function(frame)
	{
		this._super(frame);

		this._activeSprite = new cc.Sprite(pm.spriteUtils.getInterfaceElementFrame("activeRobotArrow"));

		this._activeSprite.setPosition(this._getActiveSpritePosition());
		this._activeSprite.setVisible(false);

		this.sprite.addChild(this._activeSprite);

		this._activeCircleSprite = new cc.Sprite(pm.spriteUtils.getInterfaceElementFrame("activeRobotCircle"));

		this._activeCircleSprite.setPosition(cc.p(this._getActiveSpritePosition().x,
			this._getActiveSpritePosition().y + (this._activeSprite.height + this._activeCircleSprite.height) / 2));
		this._activeCircleSprite.setVisible(false);

		this.sprite.addChild(this._activeCircleSprite);

		this._commandsSprite = new cc.Sprite(pm.spriteUtils.getInterfaceElementFrame("commandsNumber"));

		this._commandsSprite.setPosition(this._getCommandsSpritePosition());
		this._commandsSprite.setVisible(false);

		this.sprite.addChild(this._commandsSprite);

		this._commandsCount = new ccui.Text("", pm.settings.fontBoldName, pm.settings.fontSize);
		this._commandsCount.setColor(cc.color(1, 1, 180));
		this._commandsCount.setAnchorPoint(cc.p(0.5, 0.5));

		if (cc.sys.isNative)
			this._commandsCount.setNormalizedPosition(cc.p(0.5, 0.5));
		else
			this._commandsCount.setNormalizedPosition(cc.p(0.5, 0.42));

		this._commandsCount.setNormalizedPosition(cc.p(0.5, 0.46));
		this._commandsSprite.addChild(this._commandsCount);
	},

	_getFrameName: function(direction) { return ""; },

	_getDestroyAnimationName: function(direction) { return ""; },

	_getIndicateAnimationName: function(direction) { return ""; },

	setDirection: function(direction) {},

	_getActiveSpritePosition: function()
	{
		return cc.p(0, 0);
	},

	_getCommandsSpritePosition: function()
	{
		return cc.p(0, 0);
	},

	setActive: function(flag)
	{
		if (!this._commandsSprite.isVisible())
			this._activeCircleSprite.setVisible(flag);

		this._activeSprite.setVisible(flag);
	},

	showCommands: function(flag, count)
	{
		if (flag && MethodStackLayer.opened)
			return;

		if (flag)
			this._commandsCount.setString(count);

		this._commandsSprite.setVisible(flag);

		if (this._activeCircleSprite.isVisible() && flag === true)
			this._activeCircleSprite.setVisible(false);

		if (!this._activeCircleSprite.isVisible() && flag === false && this._activeSprite.isVisible())
			this._activeCircleSprite.setVisible(true);
	},

	indicateCommands: function(stepCount)
	{
		this.showCommands(true, stepCount);

		setTimeout(this.showCommands.bind(this), pm.RobotSprite2D.SHOW_COMMANDS_TIMEOUT, false)
	},

	setSpriteColor: function(id)
	{
		this._activeSprite.setColor(pm.RobotSprite2D.getRobotColor(id));
		this._activeCircleSprite.setColor(pm.RobotSprite2D.getRobotColor(id));
		this._commandsSprite.setColor(pm.RobotSprite2D.getRobotColor(id));
	},

	playAnimation: function(callback, target, type, args)
	{
		switch(type)
		{
			case RobotAnimation2D.Turn: this._turn(args[0], args[1], callback, target); break;
			case RobotAnimation2D.Move: this._move(args, callback, target); break;
			case RobotAnimation2D.Destroy: this._destroy(args, callback, target); break;
			case RobotAnimation2D.Indicate: this._indicate(args, callback, target); break;
		}
	},

	getAnimation: function(callback, target, type, args)
	{
		switch(type)
		{
			case RobotAnimation2D.Move: return this._getMoveAnimation(args, callback, target); break;
		}
	},

	_turn: function(oldDirection, curDirection, endCallback, target) {},

	_move: function(pos, endCallback, target)
	{
		var sequenceAnimation = this._getMoveAnimation(pos, endCallback, target);

		this.getSprite().runAction(sequenceAnimation);
	},

	_getMoveAnimation: function(pos, endCallback, target)
	{
		var targetPos = this.getRealPosition(pos);
		var callback = cc.callFunc(endCallback, target);
		var moveEnd = cc.callFunc(function()
		{
			this._needToUpdateZOrder = false;
		}, this);

		var moveRobot = cc.moveTo(pm.settings.getAnimationSpeed(), targetPos);

		this._needToUpdateZOrder = true;

		return cc.sequence(moveRobot, moveEnd, callback);
	},

	_transport: function(target)
	{
		var pos = this.getRealPosition(target);
		this.getSprite().setPosition(pos.x, pos.y);
	},

	_destroy: function(direction, endCallback, target)
	{
		var animation = cc.animationCache.getAnimation(this._getDestroyAnimationName(direction));
		animation.setDelayPerUnit(0.1);
		var animate = new cc.Animate(animation);

		var end = new cc.CallFunc(endCallback, target);
		this.sprite.runAction( new cc.Sequence(animate, end));

		this.showCommands(true, target.stepCount);
	},

	_indicate: function(direction, endCallback, target)
	{
		var animation = cc.animationCache.getAnimation(this._getIndicateAnimationName(direction));
		animation.setDelayPerUnit(pm.settings.getAnimationSpeed()/animation.getTotalDelayUnits());
		var animate = new cc.Animate(animation);

		var end = new cc.CallFunc(endCallback, target);
		this.sprite.runAction( new cc.Sequence(animate, end));
	}
});

pm.RobotSprite2D.getRobotColor = function(id)
{
	switch(id % RobotColorList2D.Length)
	{
		case 0: return RobotColorList2D.Green;
		case 1: return RobotColorList2D.Orange;
		case 2: return RobotColorList2D.Blue;
		case 3: return RobotColorList2D.Pink;
	}
}

pm.RobotSprite2D.SHOW_COMMANDS_TIMEOUT = 6000;
