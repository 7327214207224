/**
 * This class contains functions to work with world edit log
 * @namespace
 */
pm.WorldEditLog = cc.Class.extend({

	_editLog: {},
	_worldID: "",

	/**
     * Loads edit log from file
     * @param {String} worldID
     */
	ctor: function(worldID)
	{
		this._worldID = worldID;
		this._editLog = {};

		if(pm.fileUtils.isFileExist(pm.settings.getWorldEditLogPath(worldID)))
			this._editLog = pm.fileUtils.readObject(pm.settings.getWorldEditLogPath(worldID));
		else
			pm.fileUtils.createDirectory(pm.settings.getWorldDir(worldID));
	},

	clear: function()
	{
		this._editLog = {};
		pm.fileUtils.removeFile(pm.settings.getWorldEditLogPath(this._worldID));
	},

	/**
     * Returns if edit log is empty
     * @returns {Boolean}
     */
	isEmpty: function()
	{
		return Object.keys(this._editLog).length !== 0;
	},

	/**
     * Saves edit log.
     */
	_save: function()
	{
		pm.fileUtils.saveObject(pm.settings.getWorldEditLogPath(this._worldID), this._editLog);
	},

	setUpdatedFlag: function()
	{
		this._editLog.updated = true;
		this._save();
	},

	isUpdated: function()
	{
		return this._editLog.updated;
	},

	isGameUpdated: function(id)
	{
		return this._editLog.games !== undefined && this._editLog.games[id] !== undefined;
	},

	setUpdated: function()
	{
		this._editLog.updated = true;
		this._save();
	},

	setGameDeleted: function(id)
	{
		if(this._editLog.games && this._editLog.games[id])
			delete this._editLog.games[id];

		if(!this._editLog.deletedGames)
			this._editLog.deletedGames = [];

		this._editLog.deletedGames.push(id);

		this._save();
	},

	isGameDeleted: function(id)
	{
		return this._editLog.deletedGames && this._editLog.deletedGames.indexOf(id) !== -1;
	},

	setGameRenamed: function(id)
	{
		if(!this._editLog.games)
			this._editLog.games = {};

		if(!this._editLog.games[id])
		{
			this._editLog.games[id] = {
				renamed: false,
				reordered: false
			};
		}

		this._editLog.games[id].renamed = true;

		this._save();
	},

	isGameRenamed: function(id)
	{
		if(!this._editLog.games || !this._editLog.games[id])
			return false;

		return this._editLog.games[id].renamed;
	},

	setGameReordered: function(id)
	{
		if(!this._editLog.games)
			this._editLog.games = {};

		if(!this._editLog.games[id])
		{
			this._editLog.games[id] = {
				renamed: false,
				reordered: false
			};
		}

		this._editLog.games[id].reordered = true;

		this._save();
	},

	isGameReordered: function(id)
	{
		if(!this._editLog.games || !this._editLog.games[id])
			return false;

		return this._editLog.games[id].reordered;
	},

	setLevelDeleted: function(id)
	{
		if(this._editLog.levels && this._editLog.levels[id])
			delete this._editLog.levels[id];

		if(!this._editLog.deletedLevels)
			this._editLog.deletedLevels = [];

		this._editLog.deletedLevels.push(id);

		this._save();
	},

	isLevelDeleted: function(id)
	{
		return this._editLog.deletedLevels && this._editLog.deletedLevels.indexOf(id) !== -1;
	},

	setLevelUpdated: function(id)
	{
		if(!this._editLog.levels)
			this._editLog.levels = {};

		if(!this._editLog.levels[id])
		{
			this._editLog.levels[id] = {
				reordered: false,
				updated: false
			};
		}

		this._editLog.levels[id].updated = true;

		this._save();
	},

	isLevelUpdated: function(id)
	{
		if(!this._editLog.levels || !this._editLog.levels[id])
			return false;

		return this._editLog.levels[id].updated;
	},

	setLevelReordered: function(id)
	{
		if(!this._editLog.levels)
			this._editLog.levels = {};

		if(!this._editLog.levels[id])
		{
			this._editLog.levels[id] = {
				reordered: false,
				updated: false
			};
		}

		this._editLog.levels[id].updated = true;
		this._editLog.levels[id].reordered = true;

		this._save();
	},

	isLevelReordered: function(id)
	{
		if(!this._editLog.levels || !this._editLog.levels[id])
			return false;

		return this._editLog.levels[id].reordered;
	},

	getChangedGames: function()
	{
		return this._editLog.games;
	},

	getChangedLevels: function()
	{
		return this._editLog.levels;
	}
});
