/**
 * Created by Antony Orlovsky on 19.07.19.
 */

/**
 * @class Layer for grasshopper task.
 * @extends TaskLayer
 * @constructor
 * @param {pm.AbstractLevel} level
 * @param {pm.data.GrasshopperTask} task
 */

var GrasshopperTaskLayer = TaskLayer.extend(/** @lends GrasshopperTaskLayer# */{

	_paintLabel: null,
	_paintSlider: null,
	_paintValue: null,
	_allPaintValue: null,

	ctor: function(level, task)
	{
		this._super(LocalizedString("GrasshopperTask"), level, task, pm.GrasshopperLevelModule.Type);

		var height = this.height;

		this._paintLabel = new ccui.Text("0", pm.settings.fontName, pm.settings.fontSize);

		var paintLabelAlign = new ccui.LinearLayoutParameter();
		paintLabelAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
		paintLabelAlign.setMargin(LevelSettingsLayer.BORDER_RADIUS + 15, LevelSettingsLayer.SEPARATOR, 0, 0);
		this._paintLabel.setLayoutParameter(paintLabelAlign);

		this.addChild(this._paintLabel);
		height += this._paintLabel.height;

		this._paintSlider = new ccui.Slider();
		this._paintSlider.loadBarTexture(pm.spriteUtils.getInterfaceElementName("sliderTrack"), ccui.Widget.PLIST_TEXTURE);
		this._paintSlider.loadProgressBarTexture(pm.spriteUtils.getInterfaceElementName("sliderProgress"), ccui.Widget.PLIST_TEXTURE);
		this._paintSlider.loadSlidBallTextureNormal(pm.spriteUtils.getInterfaceElementName("sliderThumb"), ccui.Widget.PLIST_TEXTURE);
		this._paintSlider.addEventListener(this._paintSliderValueChanged.bind(this));

		var paintAlign = new ccui.LinearLayoutParameter();
		paintAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
		paintAlign.setMargin(LevelSettingsLayer.BORDER_RADIUS + 15, LevelSettingsLayer.SEPARATOR, 0, 0);
		this._paintSlider.setLayoutParameter(paintAlign);

		this.addChild(this._paintSlider);
		height += this._paintSlider.height + 20;

		pm.registerCustomEventListener(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR, this._updatePaintSliderValue.bind(this), this);

		this.setContentSize(this.width, height);

		var map = this._level.activeMap;

		if (this._task.data[this._level.getActiveMapIndex()])
		{
			this._allPaintValue = 0;

			for (var x = 0; x < map.width; ++x)
			{
				if (map.element(cc.p(x, 0)).originalType === GrasshopperMapElementType.UnpaintedGrass)
					++this._allPaintValue;
			}

			this._paintValue = this._task.data[this._level.getActiveMapIndex()];
			this._paintSlider.setPercent(this._paintValue/this._allPaintValue * 100);
			this._paintLabel.setString(LocalizedString("NeedToPaintPattern").format(this._paintValue));
		}
		else
		{
			this._createPaintSliderValue();
		}
	},

	_createPaintSliderValue: function ()
	{
		this._allPaintValue = 0;

		var map =this._level.activeMap;

		for (var x = 0; x < map.width; ++x)
		{
			if (map.element(cc.p(x, 0)).originalType === GrasshopperMapElementType.UnpaintedGrass)
				++this._allPaintValue;
		}

		this._paintSlider.setPercent(100);

		this._paintLabel.setString(LocalizedString("NeedToPaintPattern").format(this._allPaintValue));

		this._task.data[this._level.getActiveMapIndex()] = this._allPaintValue;
	},

	_updatePaintSliderValue: function()
	{
		this._allPaintValue = 0;

		var map =this._level.activeMap;

		for (var x = 0; x < map.width; ++x)
		{
			if (map.element(cc.p(x, 0)).originalType === GrasshopperMapElementType.UnpaintedGrass)
				++this._allPaintValue;
		}

		if (this._paintSlider.getPercent() === 100)
		{
			this._paintSlider.setPercent(100);
			this._paintLabel.setString(LocalizedString("NeedToPaintPattern").format(this._allPaintValue));
			this._task.data[this._level.getActiveMapIndex()] = this._allPaintValue;
		}
		else
		{
			this._paintSlider.setPercent(Math.floor(this._task.data[this._level.getActiveMapIndex()] / this._allPaintValue * 100));
		}
	},

	_paintSliderValueChanged: function (target, eventType)
	{
		if(eventType === ccui.Slider.EVENT_PERCENT_CHANGED)
		{
			var paintValue = Math.floor(this._paintSlider.getPercent() / 100 * this._allPaintValue);

			if (this._paintSlider.getPercent() !== 0 && paintValue !== this._allPaintValue)
				++paintValue;

			this._paintLabel.setString(LocalizedString("NeedToPaintPattern").format(paintValue));
			this._task.data[this._level.getActiveMapIndex()] = paintValue;
		}
	},

	refresh: function()
	{
		this._updatePaintSliderValue();
	}

});
