/**
 * Created by Nikita Besshaposhnikov on 10.11.14.
 */

/**
 * @class This an interface for all map layers.</br> Map creates it.
 * @interface
 */
pm.MapLayer = cc.Layer.extend(/** @lends pm.MapLayer# */{
	/**
     * @type {pm.AbstractMap}
     * @protected
     */
	_map: null,

	_enabled: true,

	_clicksEnabled: true,

	ctor: function(map)
	{
		this._super();

		this._map = map;

		this.setPosition(0, 0);

		this.setCascadeOpacityEnabled(true);
	},

	setEnabled: function(flag)
	{
		this._enabled = flag;
	},

	isEnabled: function()
	{
		return this._enabled;
	},

	enableClicks: function(flag)
	{
		this._clicksEnabled = flag;
	},

	/**
     * Adds Terrain Object to the map
     * @param {Object} child
     * @param {Number} zOrder
     * @param {Number} tag
     */
	addObject: function(child, zOrder, tag) {},

	/**
     * Removes Terrain Object to the map
     * @param {cc.Node} child
     * @param {Boolean} cleanup
     */
	removeObject: function(child, cleanup) {},

	/**
     * Checks for position tasks
     * @function
     * @name pm.MapLayer#checkPositionTasks
     */
	checkPositionTasks: function() {},

	/**
     * Removes all marks for position tasks
     * @function
     * @name pm.MapLayer#removePositionMarks
     */
	removePositionMarks: function() {},

	/**
     * Draws robot start position marks
     * @function
     * @name pm.MapLayer#drawRobotStartPositionMarks
     * @param {Number} robotID Id of robot
     * @param {cc.Point} markPosition It's position
     */
	drawRobotStartPositionMark: function(robotID, markPosition) {},

	/**
     * Draws map layer.
     * @function
     * @name pm.MapLayer#drawMap
     * @param {Boolean} previewDraw Draw in preview mode?
     */
	drawMap: function(previewDraw) {},

	/**
     * Updates map layer.
     * @function
     * @name pm.MapLayer#update
     */
	update: function() {},

	/**
     * Restores map layer to original state.
     * @function
     * @name pm.MapLayer#clean
     */
	clean: function() {},

	/**
     * Centers map by robots positions.
     * @function
     * @name pm.MapLayer#centerMap
     */
	centerMap: function() {},

	/**
     * Returns position in pixels by element position.
     * @function
     * @name pm.MapLayer#realPosition
     * @param {cc.Point} position in element position.
     * @param {Number} delta to all to real position
     */
	realPosition: function(position, delta) {},

	/**
     * Returns element position by real position.
     * @param {cc.Point}position in real position.
     */
	positionFromReal: function(position) {},

	/**
     * Restores element original type by element position.
     * @function
     * @name pm.MapLayer#restoreElement
     * @param {cc.Point} position in element position.
     */
	restoreElement: function(position) {},

	/**
     * Spawns objects on map.
     * @function
     * @name pm.MapLayer#spawnObjects11111
     */
	spawnObjects: function() {},

	/**
     * Checks if point is in layer.
     * @function
     * @name pm.MapLayer#containsPoint
     * @param {cc.Point} point A point to check
     * @returns {boolean}
     */
	containsPoint: function(point) { return false; },
	/**
     * Returns minimum point of map in world space
     * @function
     * @name MapLayer#getWorldMinimumPoint
     * @returns {cc.Point}
     */
	getWorldMinimumPoint: function() { return cc.p(); },

	/**
     * Draws robot sprites on layer.
     * @function
     * @name pm.MapLayer#placeRobots
     * @param {Boolean} firstPlace Is it a first place of robots on layer.
     */
	placeRobots: function(firstPlace) {},
	/**
     * Removes robot sprites from layer.
     * @function
     * @name pm.MapLayer#removeRobots
     */
	removeRobots: function() {},

	/**
     * Handles touch and returns true if it is handled.
     * @function
     * @name pm.MapLayer#handleTouch
     * @param {cc.Touch} touch A touch to handle.
     * @returns {boolean}
     */
	handleTouch: function(touch) { return false; },

	focus: function ()
	{
		this.scheduleUpdate();
	},

	removeFocus: function ()
	{
		this.unscheduleUpdate();
	},

	/**
	 * Clears DrawNodes of a MapLayer, frees GlobalVertexBuffer's space
	 * @function
	 * @name pm.MapLayer#freeBuffer
	 */
	freeBuffer: function () {}
});

var _p = pm.MapLayer.prototype;

cc.defineGetterSetter(_p, "enabled", _p.isEnabled, _p.setEnabled);
