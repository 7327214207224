/**
 * Created by Kirill Mashchenko on 30.07.18.
 */

/**
 * @class Layer for all settings.
 * @extends ccui.Layout
 * @constructor
 * @param {pm.AbstractLevel} level Level which settings shows
 */
var LevelSettingsLayer = ccui.Layout.extend(/** @lends LoginLayer# */{

	_level: null,

	_gradientLayer: null,

	ctor: function(level)
	{
		this._super();

		this._level = level;

		this.setLayoutType(ccui.Layout.RELATIVE);
		this.setBackGroundImage("System/SL_Border.png");
		this.setBackGroundImageScale9Enabled(true);
		this.setBackGroundImageCapInsets(cc.rect(84, 120, 85, 35));
		this.setCascadeOpacityEnabled(true);
	},

	updateSize: function(width, height)
	{
		height = Math.max(LevelSettingsLayer.HEIGHT, height);

		this.setContentSize(width, height);

		var settingsAlign = new ccui.RelativeLayoutParameter();
		settingsAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		settingsAlign.setMargin(0, -this.height, ProgramLayer.METHOD_STACK_WIDTH + 35, 0);
		this.setLayoutParameter(settingsAlign);

		if (this._gradientLayer)
		{
			this._gradientLayer.removeFromParent();
			this._gradientLayer = null;
		}

		this._gradientLayer = new ccui.Layout();
		this._gradientLayer.setContentSize(this.width - 2 * LevelSettingsLayer.BORDER_RADIUS, this.height - LevelSettingsLayer.BORDER_RADIUS);
		this._gradientLayer.setBackGroundColorType(ccui.Layout.BG_COLOR_GRADIENT);
		this._gradientLayer.setBackGroundColorVector(cc.p(0, -1));
		this._gradientLayer.setBackGroundColor(pmui.OvalLayout.GRAD_START_COLOR, pmui.OvalLayout.GRAD_END_COLOR);

		var gradientLayerAlign = new ccui.RelativeLayoutParameter();
		gradientLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		gradientLayerAlign.setRelativeName("gradient");
		gradientLayerAlign.setMargin(LevelSettingsLayer.BORDER_RADIUS, 0, 0, 0);
		this._gradientLayer.setLayoutParameter(gradientLayerAlign);

		this.addChild(this._gradientLayer, -1);
	},

	show: function()
	{
		var moveLayer = cc.moveBy(pm.SYSTEM_ANIMATION_DELAY, 0, -this.height);

		var moveEnd = cc.callFunc(this._showEnded, this);

		this.runAction(cc.sequence(moveLayer, moveEnd));
	},

	_showEnded: function()
	{
		var settingsAlign = new ccui.RelativeLayoutParameter();
		settingsAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		settingsAlign.setMargin(0, 0, ProgramLayer.METHOD_STACK_WIDTH + 35, 0);
		this.setLayoutParameter(settingsAlign);
	},

	hide: function()
	{
		var moveLayer = cc.moveBy(pm.SYSTEM_ANIMATION_DELAY, 0, this.height);

		var moveEnd = cc.callFunc(this._hideEnded, this);

		this.runAction(cc.sequence(moveLayer, moveEnd));
	},

	_hideEnded: function()
	{
		var settingsAlign = new ccui.RelativeLayoutParameter();
		settingsAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		settingsAlign.setMargin(0, -this.height, ProgramLayer.METHOD_STACK_WIDTH + 35, 0);
		this.setLayoutParameter(settingsAlign);
	}
});
/**
 * @default
 * @const
 * @type {Number}
 */
LevelSettingsLayer.WIDTH = 400.0;
/**
 * @default
 * @const
 * @type {Number}
 */
LevelSettingsLayer.HEIGHT = 200.0;
/**
 * @default
 * @const
 * @type {Number}
 */
LevelSettingsLayer.SEPARATOR = 10.0;
/**
 * @default
 * @const
 * @type {Number}
 */
LevelSettingsLayer.BORDER_RADIUS = 14.0;
