/** Created by Kirill Mashchenko on 13.03.18. ...*/

/**
 * @class Menu for changing level in Class Control
 * Can change to previous or next level. If new level is in another game, changes game.
 * @extends ChangeLevelMenu
 */
var ChangeLevelClassControl = ChangeLevelMenu.extend(/** @lends ChangeLevelClassControl# */{

	_target: null,

	buttonAnimated: false,

	ctor: function (target)
	{
		this._super();

		this._target = target;

		this._nextLevelButton.setEnabled(true);
		this._prevLevelButton.setEnabled(true);

	},

    _addLevelCountLabel: function() {},

	_nextLevel: function(sender)
	{
		this._target._nextLevel();
	},

	_prevLevel: function(sender)
	{
		this._target._prevLevel();
	},

	/**
	 * Sets enabled state of previous level button.
	 * @param {Boolean} flag
	 */
	setEnabledPrevLevelButton: function(flag)
	{
		this._prevLevelButton.setEnabled(flag);
	},

	/**
	 * Sets enabled state of next level button.
	 * @param {Boolean} flag
	 */
	setEnabledNextLevelButton: function(flag)
	{
		this._nextLevelButton.setEnabled(flag);
	}
});
