/**
 * Created by Nikita Besshaposhnikov on 21.08.18.
 */

/**
 * @callback ProductItemLayout~buyCallback
 * @param {Object} sender
 */

/**
 * @class Layer for world product item in {@link MarketLayer} worlds.
 * @extends pmui.OvalLayout
 * @constructor
 * @param {Number} index Index of item
 * @param {Object} item Info about purchased item
 * @param {String} item.title
 * @param {String} item.description
 * @param {String} item.price Localized price
 * @param {Boolean} itemMeta.purchased If item is purchases then buy button is not available
 * @param {ProductItemLayout~buyCallback} callback Callback on click item
 * @param {cc.Node} target Target for callback
*/
var ProductItemLayout = ccui.Layout.extend(/** @lends ProductItemLayout*/{

	_purchaseButton: null,

	ctor: function(index, item, callback, target)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		this._purchaseButton = new pmui.InterfaceButton(callback, target, "Buy");
		this._purchaseButton.setTag(index);

		var title = new pmui.Text(item.title);
		var price = new pmui.Text(item.price);

		if(item.purchased)
			this._purchaseButton.setEnabled(false);

		var purchaseAlign = new ccui.RelativeLayoutParameter();
		purchaseAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_CENTER_VERTICAL);
		purchaseAlign.setRelativeName("purchase");

		this._purchaseButton.setLayoutParameter(purchaseAlign);

		var priceAlign = new ccui.RelativeLayoutParameter();
		priceAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_LEFT_OF_CENTER);
		priceAlign.setMargin(0, 0, ProductItemLayout.SEPARATOR, 0);

		priceAlign.setRelativeToWidgetName("purchase");

		price.setLayoutParameter(priceAlign);

		var titleAlign = new ccui.RelativeLayoutParameter();
		titleAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);

		title.setLayoutParameter(titleAlign);

		this.addChild(title);
		this.addChild(price);
		this.addChild(this._purchaseButton);

		var width = this._purchaseButton.width + price.width + title.width + 2 * ProductItemLayout.SEPARATOR;

		this.setContentSize(width, this._purchaseButton.height);
	},

	setPurchased: function(flag)
	{
		this._purchaseButton.setEnabled(!flag);
	}
});

ProductItemLayout.SEPARATOR = 10;

/**
 * @class Layer for shopping worlds. Works only on ios and android
 * @extends pmui.OvalLayout
 */
var MarketLayer = pmui.OvalLayout.extend(/** @lends MarketLayer# */{

	_listView: null,

	_worlds: null,
	_products: null,

	_purchaseInProgress: false,

	_loadingLayer: null,

	ctor: function()
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setContentSizeWithBorder(MarketLayer.WIDTH, MarketLayer.HEIGHT);

		var loadingLayer = new LoadingLayer(false);
		loadingLayer.setPosition((this.width - pm.settings.getScreenSize().width) / 2,
			(this.height - pm.settings.getScreenSize().height) / 2);

		this.addChild(loadingLayer, -100);

		this._loadInterface();

		pm.apiServerUtils.getMarketableWorlds(this._onLoadMarketableWorlds.bind(this));

		this._addLoadingLayer();
	},

	_loadInterface: function()
	{
		this._listView = new ccui.ListView();

		var listViewAlign = new ccui.RelativeLayoutParameter();
		listViewAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		listViewAlign.setMargin(MarketLayer.BORDER, MarketLayer.BORDER, 0, 0);

		this._listView.setLayoutParameter(listViewAlign);

		this._listView.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._listView.setItemsMargin(MarketLayer.SEPARATOR);
		this._listView.setScrollBarEnabled(true);
		this._listView.setScrollBarOpacity(255 * 0.9);
		this._listView.setScrollBarWidth(20);

		var restoreButton = new pmui.InterfaceButton(this._restorePurchases, this, LocalizedString("RestorePurchases"));

		var restoreAlign = new ccui.RelativeLayoutParameter();
		restoreAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
		restoreAlign.setMargin(MarketLayer.BORDER, 0, 0, MarketLayer.BORDER);

		restoreButton.setLayoutParameter(restoreAlign);

		this._listView.setContentSize(
			MarketLayer.WIDTH - 2 * MarketLayer.BORDER,
			MarketLayer.HEIGHT - 2 * MarketLayer.BORDER - restoreButton.height - MarketLayer.SEPARATOR
		);

		this.addChild(this._listView);
		this.addChild(restoreButton);

		var closeButton = new pmui.Button(pm.spriteUtils.getIconName("close", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("close", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("close", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		closeButton.addClickEventListener(this.close.bind(this));

		var closeButtonAlign = new ccui.RelativeLayoutParameter();
		closeButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		closeButtonAlign.setMargin(0, -closeButton.height/4, -closeButton.width/4, 0);
		closeButton.setLayoutParameter(closeButtonAlign);

		this.addChild(closeButton);
	},

	_showMessage: function(message)
	{
		var messageBox = new pmui.MessageBox(this, message);
		var x = pm.settings.getScreenSize().width / 2 - messageBox.getContentSize().width / 2;
		var y = pm.settings.getScreenSize().height / 2 - messageBox.getContentSize().height / 2;

		messageBox.setPosition(x, y);

		cc.director.getRunningScene().addChild(messageBox, 500);
	},

	_getWorldProductID: function(worldMeta)
	{
		if (cc.sys.os === cc.sys.OS_IOS)
			return worldMeta.marketData.AppStoreID;
		else if (cc.sys.os === cc.sys.OS_ANDROID)
			return worldMeta.marketData.GooglePlayID;

		return "";
	},

	_onLoadMarketableWorlds: function(error, response)
	{
		if(error)
		{
			this._removeLoadingLayer();
			this.close();
			cc.log("Error loading store: " + error.text);
			this._showMessage(LocalizedString("ErrorLoadingMarket"));

			return;
		}

		this._worlds = response.worlds;

		var productIDs = [];

		for(var i = 0 ; i < this._worlds.length; ++i)
		{
			var id = this._getWorldProductID(this._worlds[i]);

			if(id && id !== "")
				productIDs.push(id);
		}

		pm.marketHelper.setProductInfoCallback(this._productInfoCallback.bind(this));
		pm.marketHelper.requestProductInfo(productIDs);
	},

	_productInfoCallback: function(products)
	{
		this._removeLoadingLayer();

		this._products = [];

		for(var p = 0; p < products.length; ++p)
		{
			for(var w = 0; w < this._worlds.length; ++w)
			{
				if(products[p].getID() === this._getWorldProductID(this._worlds[w]))
				{
					this._products.push({
						id: products[p].getID(),
						title: products[p].getTitle(),
						description: products[p].getDescription(),
						price: products[p].getPrice(),
						worldID: this._worlds[w].id,
						purchased: this._worlds[w].marketData.purchased
					});

					break;
				}
			}
		}

		var width = MarketLayer.WIDTH - 2 * MarketLayer.BORDER;

		for(var i = 0; i < this._products.length; ++i)
		{
			var item = new ProductItemLayout(i, this._products[i], this._purchaseCallback, this);

			width = Math.max(width, item.width);

			this._listView.pushBackCustomItem(item);
		}

		this._listView.setContentSize(width, this._listView.height);
		this.setContentSizeWithBorder(width + 2 * MarketLayer.BORDER, MarketLayer.HEIGHT);

		var listViewItems = this._listView.getItems();

		for(var i = 0; i < listViewItems.length; ++i)
			listViewItems[i].setContentSize(width, listViewItems[i].height);

		pm.marketHelper.setPaymentEventCallback(this._paymentEventCallback.bind(this));
	},

	_purchaseCallback: function(sender)
	{
		if(sender.getTag() >= this._products.length)
			return;

		if(!this._purchaseInProgress)
		{
			this._addLoadingLayer();

			this._purchaseInProgress = true;

			var product = this._products[sender.getTag()];

			pm.marketHelper.purchaseProduct(product.id);
		}
	},

	_restorePurchases: function()
	{
		this._addLoadingLayer();
		pm.marketHelper.restorePurchases();
	},

	_paymentEventCallback: function(event, error, paymentData)
	{
		if(error !== "")
		{
			this._removeLoadingLayer();

			cc.log("Payment event " + event + " error: " + error);
			var errorMessage = "";

			switch (event)
			{
				case pm.marketHelper.EVENT.PURCHASE:
					errorMessage = LocalizedString("ErrorPurchaseInMarketPattern").format(LocalizedString(error));
					break;
				case pm.marketHelper.EVENT.RESTORE:
					errorMessage = LocalizedString("ErrorRestoreInMarketPattern").format(LocalizedString(error));
					break;
			}

			this._cleanup();
			this._showMessage(errorMessage);
			return;
		}

		var restore = event === pm.marketHelper.EVENT.RESTORE;
		pm.apiServerUtils.sendPurchaseData(this._validatePurchaseCallback.bind(this), paymentData, restore);
	},

	_validatePurchaseCallback: function(error, response)
	{
		this._removeLoadingLayer();

		if(error)
		{
			this._cleanup();
			cc.log("Error validating store: " + error.text);
			this._showMessage(LocalizedString("ErrorPurchaseInMarketPattern").format(LocalizedString(error.text)));
			return;
		}

		if(this._purchaseInProgress)
			this._showMessage(LocalizedString("WorldSuccessfullyPurchased"));
		else
			this._showMessage(LocalizedString("PurchasesSuccessfullyRestored"));

		for(var p = 0; p < response.purchases.length; ++p)
		{
			for(var i = 0 ; i < this._products.length; ++i)
			{
				if(response.purchases[p].world === this._products[i].worldID)
				{
					var item = this._listView.getItem(i);

					if(item)
						item.setPurchased(true);

					break;
				}
			}
		}

		this._cleanup();
	},

	_addLoadingLayer: function()
	{
		if(this._loadingLayer)
			this._removeLoadingLayer();

		this._loadingLayer = new LoadingLayer(this.getContentSize());
		this._loadingLayer.show();
	},

	_removeLoadingLayer: function()
	{
		if (this._loadingLayer)
		{
			this._loadingLayer.remove();
			this._loadingLayer = null;
		}
	},

	show: function()
	{
		this.setAnchorPoint(cc.p(0.5, 0.5));
		this.setPosition(pm.settings.getScreenSize().width / 2, pm.settings.getScreenSize().height / 2);

		cc.director.getRunningScene().addChild(this, 500);
	},

	_cleanup: function()
	{
		this._purchaseInProgress = false;
		pm.marketHelper.cleanupTransaction();
	},

	close: function()
	{
		pm.marketHelper.cleanup();

		this.removeFromParent();
	}
});

MarketLayer.WIDTH = 460;
MarketLayer.HEIGHT = 440;
MarketLayer.TAG = 1001;

MarketLayer.SEPARATOR = 20;
MarketLayer.BORDER = 45;
