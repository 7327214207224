/**
 * Created by Nikita Besshaposhnikov on 11.03.16.
 */

/**
 * @class pm.Task for memory value of extended counter.</br>
 * @implements pm.Task
 * @constructor
 * @param {pm.AbstractLevel} level
 * @param {Object} memoryValue Target memory value.
 */
pm.data.ExCounterMemoryTask = pm.Task.extend(/** @lends pm.data.ExCounterMemoryTask*/ {
	typeName: "ExCounterMemoryTask",

	ctor: function(level)
	{
		this._super(level);
	},

	complete: function (mapIndex)
	{
		var complete = true;

		var targetGroupValues = this.data[mapIndex];
		var robotGroups = null;

		for(var i =0 ; i < this._level.globalRobots.length; ++i)
		{
			if(this._level.globalRobots[i].getType() === pm.GlobalRobotType.ExtendedCounter)
				robotGroups = this._level.globalRobots[i].getRobotDataGroups();
		}

		for (var i = 0; i < targetGroupValues.length; ++i)
		{
			if(targetGroupValues[i] !== robotGroups[i].data.memory)
				complete = false;
		}

		return complete;
	},

	fillWithInitialData: function(mapIndex, data)
	{
		if(data === undefined)
			data = 0;

		this.data[mapIndex] = [];

		var robotGroups = null;

		for(var i =0 ; i < this._level.globalRobots.length; ++i)
		{
			if(this._level.globalRobots[i].getType() === pm.GlobalRobotType.ExtendedCounter)
				robotGroups = this._level.globalRobots[i].getRobotDataGroups();
		}

		for(var i = 0; i < robotGroups.length; ++i)
			this.data[mapIndex][i] = data;
	}

});
