/**
 * Created by Kirill Mashchenko on 26.06.2017.
 */

/**
 * @class Common interface for object sprites.
 * @interface
 * @extends cc.Class
 */
pm.ObjectSprite2D = cc.Class.extend(/** @lends pm.ObjectSprite2D#*/{
	_orientation: pm.MapLayer2D.Orientation.Ortho,

	/**
     * Minimum projection point of sprite in sprites coordinates.
     * @type {cc.Point}
     * @protected
     */
	_minPoint: cc.p(),
	/**
     * Maximum projection point of sprite in sprites coordinates.
     * @type {cc.Point}
     * @protected
     */
	_maxPoint: cc.p(),

	_needToUpdateZOrder: false,
	_needClearUpdateZOrder: false,

	sprite: null,

	mapLayer: null,

	ctor: function(frame)
	{
		this.sprite = new cc.Sprite(frame);
	},

	/**
     * Set orientation of sprite
     * @param {pm.MapLayer2D.Orientation | Number} orientation
     */
	setOrientation: function(orientation)
	{
		this._orientation = orientation;
		this.sprite.setRotation(0);
		this.sprite.setScale(1);

		if(this._orientation === pm.MapLayer2D.Orientation.Iso)
		{
			this.sprite.setRotation(-45);
			this.sprite.setScaleY(2);
		}

		if(pm.MapLayer2D.ENABLE_DEBUG_DRAW_BB)
			this._debugDrawBB();
	},

	/**
     * Return orientation
     * @returns {pm.MapLayer2D.Orientation}
     */
	getOrientation: function()
	{
		return this._orientation;
	},

	/**
     * Return Sprite of this object
     * @returns {cc.Node}
     */
	getSprite: function ()
	{
		return this.sprite;
	},
	/**
     * Returns minimum projection point of sprite in layer coordinates.
     * @returns {cc.Point}
     */
	getMinPoint: function()
	{
		return cc.pointApplyAffineTransform(this._minPoint, this.sprite.getNodeToParentTransform());
	},
	/**
     * Returns maximum projection point of sprite in layer coordinates.
     * @returns {cc.Point}
     */
	getMaxPoint: function()
	{
		return cc.pointApplyAffineTransform(this._maxPoint, this.sprite.getNodeToParentTransform());
	},

	/**
     * Returns true if zorder of sprite must be changed.
     * @returns {Boolean}
     */
	needToUpdateZOrder: function() { return this._needToUpdateZOrder; },

	/**
     * Clears flags of updating zorder.
     */
	clearUpdateZOrderFlags: function()
	{
		this._needToUpdateZOrder = false;
		this._needClearUpdateZOrder = false;
	},

	/**
     * Returns true if update zOrder flag of sprite must be cleared after updating of zOrder.
     * @returns {Boolean}
     */
	needClearUpdateZOrderFlag: function() { return this._needClearUpdateZOrder; },

	/**
     * Set AABB point in sprite local coordinates.
     * @param minPoint
     * @param maxPoint
     */
	setLocalAABBPoints: function(minPoint, maxPoint)
	{
		this._minPoint = minPoint;
		this._maxPoint = maxPoint;

		if(pm.MapLayer2D.ENABLE_DEBUG_DRAW_BB)
			this._debugDrawBB();
	},
	/**
     * Sets position on map layer of sprite.
     * @function
     * @name pm.ObjectSprite#setRealPosition
     * @param {cc.Point} position
     * @param {cc.Point} delta

     */
	setRealPosition: function(pos, delta)
	{
		this._needToUpdateZOrder = true;
		this._needClearUpdateZOrder = true;

		this.sprite.setPosition(this.getRealPosition(pos, delta));
	},

	/**
     * Returns real position of sprites by element-position.
     * @function
     * @name ObjectSprite2D#getRealPosition
     * @param {cc.Point} pos X pos or point
     * @param {cc.Point} [delta]
     * @returns {cc.Point}
     */
	getRealPosition: function(pos, delta)
	{
		if(delta === undefined)
			delta = cc.p(0, 0);

		var centerPoint = cc.p(this.sprite.width / 2, this.sprite.height / 2);
		var bbCenterPoint = cc.p(this._maxPoint.x + (this._minPoint.x - this._maxPoint.x) / 2,
			this._maxPoint.y + (this._minPoint.y - this._maxPoint.y) / 2);

		if(this._orientation === pm.MapLayer2D.Orientation.Iso)
		{
			centerPoint = cc.pointApplyAffineTransform(centerPoint, pm.MapLayer2D.ISO_TRANSFORM_INV);
			bbCenterPoint = cc.pointApplyAffineTransform(bbCenterPoint, pm.MapLayer2D.ISO_TRANSFORM_INV);
		}

		return this.mapLayer.realPosition(pos, cc.pAdd(delta, cc.p(centerPoint.x - bbCenterPoint.x, centerPoint.y - bbCenterPoint.y)));
	},

	/**
     * Returns if touch hitting BB of robot.
     * @function
     * @name ObjectSprite2D#containsPoint
     * @param {cc.Touch} touch Touch point to check
=    * @returns {Boolean}
     */
	containsPoint: function(touch)
	{
		var rect = this.getSprite().getContentSize();
		var point = this.getSprite().convertTouchToNodeSpace(touch);

		return point.x > 0 && point.y > 0 && point.x < rect.width && point.y < rect.height;
	},

	_debugDrawBB: function()
	{
		this.getSprite().setOpacity(200);

		this.getSprite().removeAllChildren(true);

		if (this._orientation === pm.MapLayer2D.Orientation.Iso)
		{
			var bottomDraw = new cc.DrawNode();
			var topDraw = new cc.DrawNode();

			var height = 3 * this.height / 5;
			var bottomPoints = [];
			var leftPoints = [];
			var topPoints = [];
			var rightPoints = [];

			var transform = pm.MapLayer2D.ISO_TRANSFORM_INV;

			var invTransform = pm.MapLayer2D.ISO_TRANSFORM;

			var minPoint = cc.pointApplyAffineTransform(this._minPoint, transform);
			var maxPoint = cc.pointApplyAffineTransform(this._maxPoint, transform);

			var rightPoint = cc.pointApplyAffineTransform(cc.p(minPoint.x, maxPoint.y), invTransform);
			var leftPoint = cc.pointApplyAffineTransform(cc.p(maxPoint.x, minPoint.y), invTransform);

			bottomPoints.push(this._minPoint);
			bottomPoints.push(rightPoint);
			bottomPoints.push(this._maxPoint);
			bottomPoints.push(leftPoint);

			leftPoints.push(this._maxPoint);
			leftPoints.push(leftPoint);
			leftPoints.push(cc.p(leftPoint.x, leftPoint.y + height));
			leftPoints.push(cc.p(this._maxPoint.x, this._maxPoint.y + height));

			topPoints.push(cc.p(this._minPoint.x, this._minPoint.y + height));
			topPoints.push(cc.p(rightPoint.x, rightPoint.y + height));
			topPoints.push(cc.p(this._maxPoint.x, this._maxPoint.y + height));
			topPoints.push(cc.p(leftPoint.x, leftPoint.y + height));

			rightPoints.push(this._minPoint);
			rightPoints.push(rightPoint);
			rightPoints.push(cc.p(rightPoint.x, rightPoint.y + height));
			rightPoints.push(cc.p(this._minPoint.x, this._minPoint.y + height));

			bottomDraw.drawPoly(bottomPoints, cc.color(255, 0, 0, 50), 1, cc.color.RED);
			bottomDraw.drawPoly(rightPoints, cc.color(255, 0, 0, 50), 1, cc.color.RED);

			topDraw.drawPoly(topPoints, cc.color(255, 0, 0, 50), 1, cc.color.RED);
			topDraw.drawPoly(leftPoints, cc.color(255, 0, 0, 50), 1, cc.color.RED);

			this.getSprite().addChild(bottomDraw, -1);
			this.getSprite().addChild(topDraw, 0);
		}
		else
		{
			var draw = new cc.DrawNode();

			var points = [];
			//top
			points.push(this._minPoint);
			//right
			points.push(cc.p(this._minPoint.x, this._maxPoint.y));
			//bottom
			points.push(this._maxPoint);
			//left
			points.push(cc.p(this._maxPoint.x, this._minPoint.y));
			draw.drawPoly(points, null, 1, cc.color.RED);

			this.getSprite().addChild(draw, -2);
		}

	}
});

