/**
 * Created by Kirill Mashchenko on 31.08.18.
 */

/**
 * @class Layer to be shown on robot fail.
 * @extends pmui.OvalLayout
 * @constructor
 * @param {String} text Text of inform.
 * @param {Number} state State of inform.
 */
var StateInformLayer = pmui.OvalLayout.extend(/** @lends GameStateInformLayer# */{
	_hidden: true,

	ctor: function(text, state)
	{
		this._super();
		this.setCascadeOpacityEnabled(true);

		var brokenSprite = new cc.Sprite(this._getSpriteName(state));
		brokenSprite.setPosition(cc.p(pmui.OvalLayout.STANDARD_BORDER_RADIUS + StateInformLayer.SEPARATOR, pmui.OvalLayout.STANDARD_BORDER_RADIUS + StateInformLayer.SEPARATOR));
		brokenSprite.setAnchorPoint(cc.p(0, 0));

		this.addChild(brokenSprite);

		var label = new ccui.Text(text, pm.settings.fontName, 30);
		label.setTextAreaSize(cc.size(250, 0));
		label.setTextHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
		label.setAnchorPoint(cc.p(0, 0));

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
		labelAlign.setMargin(2*StateInformLayer.SEPARATOR + brokenSprite.width + pmui.OvalLayout.STANDARD_BORDER_RADIUS,
			0, 0, (brokenSprite.getContentSize().height - label.getContentSize().height)/2 + pmui.OvalLayout.STANDARD_BORDER_RADIUS + StateInformLayer.SEPARATOR);
		label.setLayoutParameter(labelAlign);
		this.addChild(label);

		this.setContentSizeWithBorder(label.getContentSize().width + 4 * StateInformLayer.SEPARATOR + brokenSprite.getContentSize().width,
			Math.max(label.getContentSize().height, brokenSprite.getContentSize().height) + 2 * StateInformLayer.SEPARATOR);

		this.setScale(0.9);
		this.setAnchorPoint(0.5, 0.5);
		this.setOpacity(0);
	},

	/**
     * Shows layer.
     */
	show: function()
	{
		this._hidden = false;

		var fadeLayer = cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY, 255);

		this.runAction(fadeLayer);
	},

	/**
     * Hides layer.
     */
	hide: function()
	{
		this._hidden = true;

		var fadeLayer = cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY, 0);

		this.runAction(fadeLayer);
	},

	/**
     * Returns if layer if hidden.
     * @returns {boolean}
     */
	isHidden: function()
	{
		return this._hidden;
	},

	/**
	 * Returns name of sprite depending on state.
	 * @param {Number} state State of inform.
	 * @returns {String}
	 */
	_getSpriteName: function(state)
	{
		switch (state)
		{
			case StateInformLayer.State.FAIL:
				return "System/looseRobotState.png";
			case StateInformLayer.State.WIN:
				return "System/winRobotState.png";
		}
	}
});

StateInformLayer.SEPARATOR = 15;
StateInformLayer.State = {
	FAIL : 0,
	WIN : 1
};
