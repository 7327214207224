/**
 * Created by Nikita Besshaposhnikov on 01.12.14.
 */

/**
 * @class Allows to input text in field.
 * @extends ccui.Layout
 * @constructor
 * @param {String} string Initial string of input.
 * @param {String} placeholder Placeholder.
 * @param {Number} fontSize Font size of input.
 * @param {Number} [width] Width of text input. If not passed, width will be width of string.
 */
pmui.TextInput = ccui.Layout.extend(/** @lends pmui.TextInput# */{
	_editing: false,
	_input: null,
	_cursor: null,
	_cursorShowingTime: 0,
	_cursorPosition: 0,

	_strings: [],
	_isMultiLine: false,

	_placeholder: "",

	_target: null,
	_insertTextCallback: null,

	_oldText: "",

	_attachCallback: null,
	_attachCallbackTarget: null,

	ctor: function(string, placeholder, fontSize, width, multiLineHeight)
	{
		this._super();

		this._placeholder = placeholder;

		this.setBackGroundImage(pm.spriteUtils.getInterfaceElementName("textBack"), ccui.Widget.PLIST_TEXTURE);
		this.setBackGroundImageScale9Enabled(true);

		this._input = new pm.TextFieldWrapper(placeholder, pm.settings.fontName, fontSize);
		this._input.setTextColor(pmui.TextInput.TEXT_COLOR);
		this._input.setPlaceHolderColor(pmui.TextInput.PLACEHOLDER_COLOR);

		this._input.setString(string);
		this._input.addEventListener(this._handleInputEvent, this);

		this._input.setAnchorPoint(cc.p(0, 0));

		if (width === undefined)
			width = this._input.width + 2 * pmui.TextInput.BORDER_X;

		if (width < pmui.TextInput.DEFAULT_WIDTH)
			width = pmui.TextInput.DEFAULT_WIDTH;

		if(multiLineHeight === undefined)
			multiLineHeight = 0;

		var height = this._input.height;

		if(multiLineHeight > 0)
		{
			this._isMultiLine = true;
			this._lineWidth = width - 2 * pmui.TextInput.BORDER_X;
			this._lineHeight = height;

			height = multiLineHeight;
		}

		var bordersY = pmui.TextInput.BORDER_TOP + pmui.TextInput.BORDER_BOTTOM;

		this.setContentSize(width, height + bordersY);

		var clipping = new ccui.Layout();

		clipping.setContentSize(width - 2 * pmui.TextInput.BORDER_X, height);
		clipping.setPosition(pmui.TextInput.BORDER_X, pmui.TextInput.BORDER_BOTTOM);
		clipping.setAnchorPoint(cc.p(0, 0));

		clipping.setClippingEnabled(true);

		clipping.addChild(this._input);

		this._input.setTouchAreaEnabled(true);
		this._input.setTouchSize(clipping.getContentSize());

		if(multiLineHeight > 0)
			this._input.getVirtualRenderer().setDimensions(clipping.width, 0);

		this._input.setPosition(0, 0);
		this.addChild(clipping);

		if(!cc.sys.isNative)
		{
			this._cursor = new cc.LabelTTF("|", pm.settings.fontLightName, fontSize);
			this._cursor.setAnchorPoint(0, 0);
			this._cursor.setPosition(this._input.width, 0);
			this._cursor.setFontFillColor(pmui.TextInput.TEXT_COLOR);

			this._cursor.setOpacity(0);

			clipping.addChild(this._cursor);

			this._cursorPosition = string.length;
			this._oldText = string;

			this._input.setPasswordStyleText("*");

			if(this._isMultiLine)
			{
				this._warpMultilineText();

				//Workaround for input height, because it's real height will be calculated on next tick
				var inputHeight = this._lineHeight * this._strings.length;
				this._input.setPosition(0, (this.height - bordersY) - inputHeight);
			}
		}
		else
		{
			this._input.setCursorEnabled(true);
		}
	},

	/**
     * Set callback for insert text event.str
     */
	setInsertTextCallback: function(target, callback)
	{
		this._target = target;
		this._insertTextCallback = callback;
	},

	/**
     * Sets string of input.
     * @param {String} str
     */
	setString: function(str)
	{
		this._input.setString(str);

		if (!cc.sys.isNative)
		{
			this._oldText = str;
			this._cursorPosition = str.length;
		}
	},

	/**
     * Returns current input value.
     * @returns {String}
     */
	getString: function()
	{
		return this._input.getString();
	},

	/**
     * Sets input to password style.
     * @param {Boolean} flag
     */
	setPasswordInput: function(flag)
	{
		this._input.setPasswordEnabled(flag);
	},

	/**
     * Sets callback for attach/detach event
     * @param {pm.TextInput~attachCallback} callback
     * @param {Object} target
     */
	setAttachCallback: function(callback, target)
	{
		this._attachCallback = callback;
		this._attachCallbackTarget = target;
	},

	closeKeyboard: function()
	{
		this._input.didNotSelectSelf();
	},

	_warpMultilineText: function()
	{
		if(!cc.sys.isNative && this._isMultiLine)
		{
			var scale = cc.director.getContentScaleFactor();
			var strings = [this._input.getString()];

			for (var i = 0; i < strings.length; ++i)
				this._input.getVirtualRenderer()._renderCmd._checkWarp(strings, i, this._input.width * scale);

			this._strings = strings;
		}
	},

	_getStringLength: function(str)
	{
		if(cc.sys.isNative)
			return 0.0;
		else //Some magic of cocos2d-html5 to determine text real length
			return this._input.getVirtualRenderer()._renderCmd._measure(str) / cc.director.getContentScaleFactor();
	},

	_getCursorPosition: function(str)
	{
		if(!cc.sys.isNative) //Some very black deep magic of cocos2d-html5 to determine word warp and real cursor position
		{
			if(this._isMultiLine)
			{
				var line = this._strings.length;
				var y = this.height - line * this._lineHeight - pmui.TextInput.BORDER_TOP - pmui.TextInput.CURSOR_CORRECTION;

				var x = 0;
				var strLength = 0;

				for(var i = 0 ; i < this._strings.length; ++i)
				{
					if(this._cursorPosition <= strLength + this._strings[i].length)
					{
						var pos = this._cursorPosition - strLength;
						x = this._getStringLength(this._strings[i].substr(0, pos));
						break;
					}
					else
					{
						strLength += this._strings[i].length;
					}
				}

				return cc.p(x, Math.max(pmui.TextInput.CURSOR_CORRECTION, y));
			}
			else
			{
				var stringLength = this._getStringLength(str.substr(0, this._cursorPosition));
				return cc.p(Math.min(stringLength, this.width - 3 * pmui.TextInput.BORDER_X), pmui.TextInput.CURSOR_CORRECTION);
			}

		}
		else
		{
			return cc.p();
		}
	},

	_handleInputEvent: function(sender, type)
	{
		var bordersY = pmui.TextInput.BORDER_TOP + pmui.TextInput.BORDER_BOTTOM;

		switch(type)
		{
			case ccui.TextField.EVENT_INSERT_TEXT:
			case ccui.TextField.EVENT_DELETE_BACKWARD:
				var string = this._input.getString();

				if(string === this._oldText)
					return;

				if (this._insertTextCallback)
					this._insertTextCallback.call(this._target, string);

				if(this._cursor)
				{
					var maxCompLength = type === ccui.TextField.EVENT_INSERT_TEXT ? this._oldText.length : string.length;

					this._cursorPosition = 0;

					for (var i = 0; i < string.length; ++i)
					{
						if (i < maxCompLength && this._oldText[i] !== string[i])
							break;

						this._cursorPosition = i + 1;
					}

					if(this._isMultiLine)
						this._warpMultilineText();
				}

				this._oldText = string;

			case ccui.TextField.EVENT_ATTACH_WITH_IME:
			{
				var x = 0;
				var y = 0;

				if(sender.width > this.width - 2*pmui.TextInput.BORDER_X)
					x = -2*pmui.TextInput.BORDER_X + this.width - sender.width - 5;

				if(this._isMultiLine)
				{

					if(sender.height <= this.height - bordersY)
						y = (this.height - bordersY) - sender.height;
				}

				sender.setPosition(x, y);

				if(type === ccui.TextField.EVENT_ATTACH_WITH_IME)
				{
					sender.setPlaceHolder("");

					if(this._cursor)
						this.scheduleUpdate();

					if(this._attachCallback && this._attachCallbackTarget)
						this._attachCallback.call(this._attachCallbackTarget, this, true);
				}

				break;
			}
			case ccui.TextField.EVENT_DETACH_WITH_IME:
			{
				sender.setPosition(0, (this.height - bordersY) - this._input.height);
				sender.setPlaceHolder(this._placeholder);

				if(this._attachCallback && this._attachCallbackTarget)
					this._attachCallback.call(this._attachCallbackTarget, this, false);

				if(this._cursor)
				{
					this._cursor.setOpacity(0);
					this.unscheduleUpdate();
				}
				break;
			}
		}
	},

	update: function(delta)
	{
		if (this._cursor)
		{
			this._cursorShowingTime -= delta;

			if (this._cursorShowingTime < -pmui.TextInput.CURSOR_TIME_SHOW_HIDE)
				this._cursorShowingTime = pmui.TextInput.CURSOR_TIME_SHOW_HIDE;

			if (this._cursorShowingTime >= 0.0)
				this._cursor.setOpacity(255);
			else
				this._cursor.setOpacity(0);

			var text = "";

			if(this._input.isPasswordEnabled())
			{
				var length = this._input.getString().length;
				var symbol = this._input.getPasswordStyleText();

				for(var i = 0; i < length; ++i)
					text += symbol;
			}
			else
			{
				text = this._input.getString();
			}

			this._cursor.setPosition(this._getCursorPosition(text));
		}
	}
});

/**
 * This callback is used when load of world is completed
 * @callback pmui.TextInput~attachCallback
 * @param {pmui.TextInput} sender Who sent attach callback
 * @param {Boolean} flag Flag if attached or detached
 */

/**
 * Default width of {@link pmui.TextInput}
 * @const
 * @default
 * @type {Number}
 */
pmui.TextInput.DEFAULT_WIDTH = 28;

/**
 * Text border by x of {@link pmui.TextInput}
 * @const
 * @default
 * @type {Number}
 */
pmui.TextInput.BORDER_X = 8.0;

/**
 * Text border by top y of {@link pmui.TextInput}
 * @const
 * @default
 * @type {Number}
 */
pmui.TextInput.BORDER_TOP = 6.0;

/**
 * Text border by bottom y of {@link pmui.TextInput}
 * @const
 * @default
 * @type {Number}
 */
pmui.TextInput.BORDER_BOTTOM = 4.0;

/**
 * Cursor correction for web version of {@link pmui.TextInput}
 * @const
 * @default
 * @type {Number}
 */
pmui.TextInput.CURSOR_CORRECTION = 2.0;

/**
 * Placeholder color of {@link pmui.TextInput}
 * @const
 * @default
 * @type {cc.Color}
 */
pmui.TextInput.PLACEHOLDER_COLOR = cc.color(127, 194, 232);
/**
 * Text color of {@link pmui.TextInput}
 * @const
 * @default
 * @type {cc.Color}
 */
pmui.TextInput.TEXT_COLOR = cc.color(41, 171, 226);
/**
 * Show hide time of cursor for {@link pmui.TextInput} in HTML5 version
 * @const
 * @default
 * @type {Number}
 */
pmui.TextInput.CURSOR_TIME_SHOW_HIDE = 0.5;
