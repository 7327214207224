/**
 * Created by Kirill Mashchenko on 17.05.23.
 */

/**
 * Types of {@link pm.data.PressRobotMapElement}.
 * @enum {Number}
 * @readonly
 */
var PressRobotMapElementType = {
	Green: "G",
	Blue: "B",
	Yellow: "Y",
	NullElement: 0,
	OneElement: 1,
	TwoElement: 2,
	ThreeElement: 3,
	FourElement: 4,
	FiveElement: 5,
	SixElement: 6,
	SevenElement: 7,
	EightElement: 8,
	NineElement: 9
};

/**
 * @class Elements of {@link pm.data.PressRobotMap}.
 * @implements pm.MapElement
 */
pm.data.PressRobotMapElement = pm.MapElement2D.extend(/** @lends pm.data.PressRobotMapElement# */{
	typeName: "PressRobotMapElement",

	ctor: function ()
	{
		this._super();
	},

	generateTerrainSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new pm.TerrainSprite(pm.spriteUtils.getMapGrassTileFrame(this._type));

			this._notCompletedSprite = new cc.Sprite("MapsPics/mapElement_error_selection.png");

			this._notCompletedSprite.setPosition(cc.p(this.sprite.getSprite().getContentSize().width/2, this.sprite.getSprite().getContentSize().height/2));
			this._notCompletedSprite.setAnchorPoint(cc.p(0.5, 0.5));
			this._notCompletedSprite.setVisible(false);
			this._notCompletedSprite.setScale(1.3);

			this.sprite.getSprite().addChild(this._notCompletedSprite);

			return this.sprite;
		}
	},

	setNotCompleted: function(flag)
	{
		if (this._notCompletedSprite)
		{
			this._notCompletedSprite.setVisible(flag);

			if (flag)
			{
				this._notCompletedSprite.runAction(cc.repeatForever(cc.sequence(
					cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, 1.2), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.8 * 255)),
					cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, 1.3), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 255))
				)));
			}
			else
			{
				this._notCompletedSprite.stopAllActions();
			}
		}
	}
});
