"use strict";

/**
 * Created by Nikita Besshaposhnikov on 07.04.17.
 */

/**
 * @class Lego physical robot.
 * @extends pm.PhysicalConnector
 */
pm.LegoPhysicalConnector = pm.PhysicalConnector.extend(/** @lends pm.LegoPhysicalRobot# */{

	_robotHost: "",

	ctor: function()
	{
		this._super();

		this._robotHost = "http://" + pm.LegoPhysicalConnector.DEFAULT_HOST + "/";
	},

	connect: function()
	{
		var xhr = cc.loader.getXMLHttpRequest();

		xhr.open("GET", this._robotHost, true);

		xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

		cc.log("Connecting to Lego Physical robot");

		if(cc.sys.isNative)
			pm.setHttpRequestTimeout(pm.LegoPhysicalConnector.DEFAULT_TIMEOUT);
		else
			xhr.timeout = pm.LegoPhysicalConnector.DEFAULT_TIMEOUT;

		this._connected = false;

		var self = this;

		xhr.onreadystatechange = function ()
		{

			if (xhr.readyState === 4 )
			{
				cc.log(xhr.status);
				cc.log(xhr.responseText);
				if((xhr.status >= 200 && xhr.status <= 207))
				{
					cc.log("Connecting to Lego Physical robot success");
					self._connected = true;

					if(self._stateCallback)
						self._stateCallback(pm.PhysicalConnector.STATE.CONNECTED);
				}
			}
		};

		xhr.onerror = function ()
		{
			cc.log("Error connecting to Lego Physical robot");

			if(self._stateCallback)
				self._stateCallback(pm.PhysicalConnector.STATE.DISCONNECTED);
		};

		xhr.send("");
	},

	executeRobotCommand: function(id, command, args)
	{
		if(!this._connected)
			return;

		this._busy = true;

		cc.log("Sending command to Lego Physical robot" + command);

		var xhr = cc.loader.getXMLHttpRequest();

		xhr.open("GET", this._robotHost + command, true);

		xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

		if(cc.sys.isNative)
			pm.setHttpRequestTimeout(100 * 1000);
		else
			xhr.timeout = timeout;

		var self = this;

		xhr.ontimeout = function ()
		{
			cc.log("Lego Physical robot HTTP request timeout");
			self._endCommand();
		};

		xhr.onerror = function (error)
		{
			cc.log("Lego Physical robot HTTP error" + error.message);
			self._endCommand();
		};

		xhr.onreadystatechange = function ()
		{
			if (xhr.readyState === 4)
				self._endCommand();
		};

		xhr.send("");
	}
});

pm.LegoPhysicalConnector.DEFAULT_HOST = "192.168.3.80";
pm.LegoPhysicalConnector.DEFAULT_TIMEOUT = 2000;
