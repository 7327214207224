
var PiktoTree = cc.Class.extend(/** @lends ProgramContainerLayer# */{

	_nodeTree: null,
	_maxPikts: 6,
	_robot: null,
	_variablesNumber: 0,
	_startedRepeatersStack: [],
	_highlightedMethodsStack: [],

	ctor: function (programTree, robot, parent)
	{
		this._robot = robot;
		this._startedRepeatersStack = [];
		this._highlightedMethodsStack = [];
		if(this.isParceable(programTree.head))
		{
			this.addCounterSprites();
			this._createVisualTree(programTree, parent);
		}
	},

	addCounterSprites: function()
	{
		if(this._variablesNumber > 0)
		{
			pm.spriteUtils.addTempSpriteFrames("Robot Methods/counter-methods.plist");
			pm.spriteUtils.addTempSpriteFrames("Conditions/counter-conditions.plist");
			pm.spriteUtils.addTempSpriteFrames("Repeaters/counter-repeaters.plist");
		}
	},

	_createVisualTree: function (programTree, parent)
	{
		this._nodeTree = this.parseTree(programTree.head, parent);
		parent.addChild(this._nodeTree);
	},

	getVisualTree: function()
	{
		return this._nodeTree;
	},

	switchPointsInRepeater: function(nodeView, num, rep)
	{
		var index = (num === rep + 1) ? 0 : num;

		if (num === 1)
			this._startedRepeatersStack.push(nodeView);
		else if (num === rep + 1)
			nodeView = this._startedRepeatersStack.pop();

		if (nodeView.value !== "cnt_value")
			nodeView.switchPointsNumberR(index);
		else
			nodeView.switchPointsNumber(rep, index);
	},

	reloadRepeaters: function()
	{
		while(this._startedRepeatersStack.length > 0)
		{
			var repPlace = this._startedRepeatersStack.pop();
			if (repPlace.value !== "cnt_value")
				repPlace.switchPointsNumberR(0);
			else
				repPlace.switchPointsNumber(0);
		}
	},

	highlightMethod: function (nodeView)
	{
		nodeView.setHighlighted(true);
		this._highlightedMethodsStack.push(nodeView);
	},

	highlightMethodBroken: function (nodeView)
	{
		nodeView.setHighlighted(true);
		this._highlightedMethodsStack.push(nodeView);
	},

	clearAllHighlight: function ()
	{
		this._highlightedMethodsStack.forEach(function (elem){
			elem.setHighlighted(false);
		});
		this._highlightedMethodsStack = [];
	},

	clearLastHighlight: function ()
	{
		var elem = this._highlightedMethodsStack.pop()
		if(elem)
			elem.setHighlighted(false);
	},

	isParceable: function(node)
	{
		if (!node)
			return;

		switch (node.type)
		{

			case pm.data.Node.Type.Func:
				if(!pmui.FunctionNode.piktoVals.has(node.value))
					return false;
			case pm.data.Node.Type.Prog:
			case pm.data.Node.Type.MainAlg:
			case pm.data.Node.Type.Body:
				var parsed = true;
				for (var i = 0; i < node.getChildren().length; i++)
				{
					parsed = this.isParceable(node.getChildren()[i]);
					if (!parsed)
						return false;
				}
				return true;

			case pm.data.Node.Type.Globals:
				return true;

			case pm.data.Node.Type.Empty:
			case pm.data.Node.Type.Bool:
			case pm.data.Node.Type.Condition:
				return true;

			case pm.data.Node.Type.Alloc:
				this._variablesNumber++;
				if(this._variablesNumber > 1)
					return false;
				else
					return true;

			case pm.data.Node.Type.Statement:
				var variable = null;
				var value = null;
				var statement = null;

				for (var i = 0; i < node.getChildren().length; i++)
				{
					if(node.getChildren()[i].getTagName() === pm.StatementValueTag)
						value = node.getChildren()[i];
				}
				if(value.type === pm.data.Node.Type.Number && value.value === 0)
					return true;
				else if(value.type === pm.data.Node.Type.Expression)
				{
					if(!this.isParceable(value))
					{
						return false;
					}
				}

				return true;

			case pm.data.Node.Type.Identifier:
				return true;

			case pm.data.Node.Type.Expression:
				if(node.value === pm.ArythmMethods.Addition || node.value === pm.ArythmMethods.Substraction)
				{
					var variable = null;
					for (var i = 0; i < node.getChildren().length; i++)
					{
						if(node.getChildren()[i].type === pm.data.Node.Type.Identifier)
							variable = node.getChildren()[i];
						else if(node.getChildren()[i].type === pm.data.Node.Type.Number)
						{
							if(node.getChildren()[i].value !== 1)
								return false;
						}
						else
							return false;
					}
					return true;
				}
				else
					return false;

			case pm.data.Node.Type.LogicExpression:
				return false;

			case pm.data.Node.Type.AndOrExpression:
				return false;

			case pm.data.Node.Type.NotExpression:
					return false;

			case pm.data.Node.Type.IfStatement:

				for (var i = 0; i < node.children.length; i++)
				{
					if (node.children[i].type === pm.data.Node.Type.Body)
					{
						if (node.children[i].value === pm.IFElseTag)
							return false;
					}
					parsed = this.isParceable(node.getChildren()[i]);
					if (!parsed)
						return false;
				}
				return true;

			case pm.data.Node.Type.Number:
				if(node.value > 6 || node.value < 1)
					return false;
				return true;

			case pm.data.Node.Type.Loop:

				switch (node.value)
				{
					case pm.LoopVals.nTimes:
						var repVal = node.children[1];
						if(repVal.type === pm.data.Node.Type.Expression)
						{
							return false;
						}

					case pm.LoopVals.whileC:
						for (var i = 0; i < node.getChildren().length; i++)
						{
							parsed = this.isParceable(node.getChildren()[i]);
							if (!parsed)
								return false;
						}
						return true;

					case pm.LoopVals.for:

						return false;
				}
				break;

			case pm.data.Node.Type.Action:

				var action = null;
				var tagName = "";

				if (this._robot.nativeFunctionMap[node.value])
				{
					if (this._robot.nativeFunctionMap[node.value].returnArgs())
					{
						return false;
					}
				}
				if (node.children.length > 0)
					return false;

				return true;
		}
	},

	parseTree: function (node, vTree)
	{
		if (!node)
			return;
		var nodeView = null;

		var marginParam = new ccui.LinearLayoutParameter();
		marginParam.setMargin(pmui.NodeLayer.SEPARATORX * 1.5, pmui.NodeLayer.SEPARATORX / 2, - pmui.NodeLayer.SEPARATORX / 2, pmui.NodeLayer.SEPARATORX / 2);
		var margin = marginParam.getMargin();

		var emptyParam = new ccui.LinearLayoutParameter();

		var width = 0;
		var height = 0;

		switch (node.type)
		{
			case pm.data.Node.Type.Prog:
				var mainAlg = null;
				var funcView = null;

				nodeView = new ccui.Layout();
				nodeView.setLayoutType(ccui.Layout.RELATIVE);

				var vBox = new ccui.VBox();
				var treeAlign = new ccui.RelativeLayoutParameter();
				treeAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
				treeAlign.setMargin(pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORX, 0);
				vBox.setLayoutParameter(treeAlign);

				for (var i = 0; i < node.getChildren().length; i++)
				{
					if (node.getChildren()[i].isMain())
						mainAlg = node.getChildren()[i];
				}

				var mainView = this.parseTree(mainAlg, vBox);
				width = mainView.width;
				height = mainView.height;

				for (var i = 0; i < node.getChildren().length; i++)
				{
					if (node.getChildren()[i].type === pm.data.Node.Type.Func)
					{
						funcView = this.parseTree(node.getChildren()[i], vBox);
						width = Math.max(funcView.width, width);
						height += funcView.height;
					}
				}
				nodeView.addChild(vBox);
				vBox.setContentSize(width, height);
				nodeView.setContentSize(width, height);
				return nodeView;

			case pm.data.Node.Type.MainAlg:
				var childView = null;
				nodeView = new ccui.VBox();

				for (var i = 0; i < node.getChildren().length; i++)
				{
					childView = this.parseTree(node.getChildren()[i], nodeView);
					if (childView)
					{
						height += childView.height;
						width += childView.width;
						childView.setContentSize(width + margin.left + margin.right, height + margin.top + margin.bottom);
					}
				}
				break;

			case pm.data.Node.Type.Func:
				var nameView = pm.appUtils.generateFunctionButton(FunctionButton.Type.Function, node.value);
				node.setNodeView(nameView);

				var childView = null;
				nodeView = new ccui.VBox();

				nodeView.addChild(nameView);
				for (var i = 0; i < node.getChildren().length; ++i)
				{
					childView = this.parseTree(node.getChildren()[i], nodeView);
					if (childView)
					{
						height += childView.height;
						width += childView.width;
						childView.setContentSize(width + margin.left + margin.right, height + margin.top + margin.bottom);
					}
				}
				height += nameView.height + 15;

				var linearLayoutParameter = new ccui.LinearLayoutParameter();
				linearLayoutParameter.setMargin(0, 15, 0, 0);
				nodeView.setLayoutParameter(linearLayoutParameter);
				node.setNodeView(nameView);
				break;

			case pm.data.Node.Type.Body:
				var horContainer = new ccui.HBox();
				var count = 0;
				var child = null;
				var maxCount = this._maxPikts;
				var horContWidth = 0;
				var childView = null;
				nodeView = new ccui.VBox;
				nodeView.setBackGroundImageScale9Enabled(true);
				nodeView.setBackGroundImage("System/algorithmBack.png");
				nodeView.setBackGroundImageCapInsets(cc.rect(20, 20, 20, 20));

				for (var i = 0; i < node.getChildren().length; i++)
				{
					child = node.getChildren()[i];
					if ((child.type === pm.data.Node.Type.Action || child.type === pm.data.Node.Type.Statement || child.type === pm.data.Node.Type.Empty) && count < maxCount)
					{
						childView = this.parseTree(child, horContainer);
						count++;
					}
					else if(child.type !== pm.data.Node.Type.Alloc)
					{
						if (i !== 0 && childView !== null)
						{
							nodeView.addChild(horContainer);

							horContainer.setContentSize(horContWidth,
								Math.max(ProgramLayer.BUTTON_HEIGHT,
									childView.height + childView.getLayoutParameter().getMargin().top + childView.getLayoutParameter().getMargin().bottom));

							height += horContainer.height;
							width = Math.max(width, horContWidth);

							count = 0;

						}
						horContainer = new ccui.HBox();
						horContWidth = 0;
						childView = this.parseTree(child, horContainer);
						count++;
						if (child.type !== pm.data.Node.Type.Action)
						{
							maxCount--;
							count = maxCount;
						}
					}
					else
					{
						child.setNodeView(null);
						continue;
					}

					horContWidth += childView.width + margin.left + margin.right;
				}
				nodeView.addChild(horContainer);
				if(!childView)
					break;
				horContainer.setContentSize(horContWidth,
					Math.max(ProgramLayer.BUTTON_HEIGHT,
						childView.height + childView.getLayoutParameter().getMargin().top + childView.getLayoutParameter().getMargin().bottom));
				height += horContainer.height;
				width = Math.max(width, horContWidth);
				break;

			case pm.data.Node.Type.Empty:
				nodeView = new EmptyFunctionButton(FunctionButton.Type.Method);
				nodeView.setLayoutParameter(marginParam.clone());
				width = nodeView.width + margin.left + margin.right;
				height = nodeView.height + margin.top + margin.bottom;
				break;

			case pm.data.Node.Type.Bool:
				nodeView = new EmptyFunctionButton(FunctionButton.Type.Condition);
				node.setNodeView(nodeView);
				nodeView.setLayoutParameter(marginParam.clone());
				width = nodeView.width + margin.left + margin.right;
				height = nodeView.height + margin.top + margin.bottom;
				break;

			case pm.data.Node.Type.Identifier:
				nodeView = new CounterRepeaterButton("cnt_value");
				node.setNodeView(nodeView);
				nodeView.setLayoutParameter(marginParam);
				width = nodeView.width + margin.left + margin.right;
				height = nodeView.height + margin.top + margin.bottom;
				break;

			case pm.data.Node.Type.Number:
				var number = node.value % 7;
				if (!number)
					number = 1;
				nodeView = pm.appUtils.generateFunctionButton(FunctionButton.Type.Constant, number);
				node.setNodeView(nodeView);
				nodeView.setLayoutParameter(marginParam);
				width = nodeView.width + margin.left + margin.right;
				height = nodeView.height + margin.top + margin.bottom;
				break;

			case pm.data.Node.Type.IfStatement:
				var thenBody = null;
				var conditionView = null;
				nodeView = new ccui.HBox();

				for (var i = 0; i < node.children.length; i++)
				{
					if (node.children[i].type === pm.data.Node.Type.Body)
					{
						if (node.children[i].value === pm.IFThenTag)
						{
							thenBody = node.children[i];
						}
					}
					else
					{
						conditionView = this.parseTree(node.getChildren()[i], nodeView);
					}
				}
				this._maxPikts--;
				var bodyView = this.parseTree(thenBody, nodeView);
				this._maxPikts++;

				width = conditionView.width + bodyView.width;
				height = bodyView.height;
				break;

			case pm.data.Node.Type.LogicExpression:
				var condName = "";
				if(node.value === pm.ArythmConditions.Equal)
					condName = "cnt_empty";
				else
					condName = "cnt_not_empty";
				nodeView = pm.appUtils.generateFunctionButton(FunctionButton.Type.Condition, condName);
				node.setNodeView(nodeView);
				nodeView.setLayoutParameter(marginParam.clone());
				width = nodeView.width + margin.left + margin.right;
				height = nodeView.height + margin.top + margin.bottom;
				break;

			case pm.data.Node.Type.Condition:

				nodeView = pm.appUtils.generateFunctionButton(FunctionButton.Type.Condition, node.value);
				node.setNodeView(nodeView);
				nodeView.setLayoutParameter(marginParam.clone());
				width = nodeView.width + margin.left + margin.right;
				height = nodeView.height + margin.top + margin.bottom;
				break;

			case pm.data.Node.Type.Loop:

				switch (node.value)
				{
					case pm.LoopVals.nTimes:
						var repVal = null;
						var repBody = null;

						nodeView = new ccui.HBox();
						repVal = node.children[1];
						repBody = node.children[0];

						var repView = this.parseTree(repVal, nodeView);
						repView.setLayoutParameter(marginParam.clone());
						this._maxPikts--;
						var bodyView = this.parseTree(repBody, nodeView);
						this._maxPikts++;

						width += repView.width + bodyView.width;
						height = Math.max(bodyView.height, repView.height);
						node.setNodeView(repView);
						break;

					case pm.LoopVals.whileC:
						var conditionView = null;
						var body = null;

						nodeView = new ccui.HBox();

						for (var i = 0; i < node.children.length; i++)
						{
							if (node.children[i].type === pm.data.Node.Type.Body)
							{
								body = node.children[i];
							}
							else conditionView = this.parseTree(node.children[i], nodeView);
						}

						var sprite = pm.spriteUtils.getRepeaterSprite("cond");
						sprite.setPosition(0, conditionView.height);
						sprite.setAnchorPoint(cc.p(0, 1));

						conditionView.addChild(sprite, -1, FunctionButton.BACKGROUND_SPRITE_TAG);
						conditionView.getVirtualRenderer().setScale(FunctionButton.COND_REPEATER_SCALE);
						node.setNodeView(conditionView);
						this._maxPikts--;
						var bodyView = this.parseTree(body, nodeView);
						this._maxPikts++;

						width = conditionView.width + bodyView.width;
						height = Math.max(bodyView.height, conditionView.height);

						break;
				}
				break;

			case pm.data.Node.Type.Action:

				if (this._robot.nativeFunctionMap[node.value])
				{
					nodeView = pm.appUtils.generateFunctionButton(FunctionButton.Type.Method, node.value);
				}
				else
				{
					nodeView = pm.appUtils.generateFunctionButton(FunctionButton.Type.Function, node.value);
				}
				node.setNodeView(nodeView);
				nodeView.setLayoutParameter(marginParam.clone());
				width = nodeView.width + margin.left + margin.right;
				height = nodeView.height + margin.top + margin.bottom;
				break;

			case pm.data.Node.Type.Statement:

				var methodName = "";
				var valueObj = null;
				for (var i = 0; i < node.getChildren().length; i++)
				{
					if(node.getChildren()[i].getTagName() === pm.StatementValueTag)
						valueObj = node.getChildren()[i];
				}
				if(valueObj.type === pm.data.Node.Type.Number)
					methodName = "empty_counter";
				else if(valueObj.type === pm.data.Node.Type.Expression)
				{
					if(valueObj.value === pm.ArythmMethods.Addition)
						methodName = "cnt_inc";
					else
						methodName = "cnt_dec";

				}
				nodeView = pm.appUtils.generateFunctionButton(FunctionButton.Type.Method, methodName);
				node.setNodeView(nodeView);
				for (var i = 0; i < node.getChildren().length; i++)
				{
					node.getChildren()[i].setNodeView(nodeView);
				}
				nodeView.setLayoutParameter(marginParam.clone());
				width = nodeView.width + margin.left + margin.right;
				height = nodeView.height + margin.top + margin.bottom;
				break;
		}
		if (!nodeView)
		{
			nodeView = new ccui.Layout();
			nodeView.setLayoutParameter(emptyParam);
			node.setNodeView(null);
			width = 0;
			height = 0;
			return nodeView;
		}

		nodeView.setContentSize(width, height);
		vTree.addChild(nodeView);
		return nodeView;
	}
});
