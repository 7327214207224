/**
 * Created by Antony Orlovsky on 09.08.19.
 */

/**
 * @class Functions for TurtleLevel module.
 * @interface
 * @extends pm.ModuleInfo
 */

pm.TurtleLevelModule = pm.ModuleInfo.extend(/** @lends pm.TurtleLevelModule# */{

	name: "Turtle",

	getType: function()
	{
		return pm.TurtleLevelModule.Type;
	},

	getBackgroundParameters: function ()
	{
		return{
			color: cc.color(254, 191, 124),
			drawStars: false,
			scaleFactor: 2,
			centerImage: "Background/Levels/turtle_center.png"
		};
	},

	generateEmptyMap: function (level)
	{
		var map = new pm.data.TurtleMap();

		map.parentLevel = level;

		var sqrtCount = Math.max(3, Math.floor(Math.sqrt(level.robots.length))+1);

		map.setSize(sqrtCount, sqrtCount);

		level.robots[0].startPosition = cc.p(0, 64);
		level.robots[0].startDirection = 0;

		// var x = 0;
		// var y = 64;
		//
		// for (var i = 1; i < level.robots.length; ++i)
		// {
		// 	var robot = level.robots[i];
		//
		// 	robot.startPosition = cc.p(x, y);
		// 	robot.startDirection = 0;
		//
		// 	x += 64;
		//
		// 	if (x === 0 && y === 1)
		// 		x += 64;
		//
		// 	if (x >= sqrtCount)
		// 	{
		// 		y += 64;
		// 		x = 0;
		// 	}
		// }

		return map;
	},

	generateEmptyLevel: function ()
	{
		var level = new pm.data.TurtleLevel();

		level.name = "Новая Черепаха";
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";
		level.robots.push(new pm.data.TurtleRobot());

		this._fillLevelData(level);

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();

		return level;
	},

	getObjectsInfo: function()
	{
		return {};
	},

	getRobotsInfo: function ()
	{
		var ret = {};
		ret[pm.TurtleLevelModule.RobotType] = "turtle_robot_tail_down.png";
		return ret;
	},

	getRobotSpriteSheet: function (robotType)
	{
		return "Robot/turtle_robot.plist";
	},

	getSettings: function (robotType, object)
	{
		return new TurtleRobotSettingsLayer(object);
	},

	getMapElementsType: function() {},

	getRobotDisplayedName: function (robotType)
	{
		return LocalizedString("Turtle");
	},

	getDisplayedNames: function()
	{
		return LocalizedString(this.name);
	},

	getMapTileset: function (tileset)
	{
		return "MapsPics/turtle_level-tileset{0}.plist".format(tileset);
	},

	getResources: function ()
	{
		return [
			"Robot Methods/turtle_robot-methods.plist"
		];
	},

	generateRobot: function (robotType)
	{
		return new pm.data.TurtleRobot();
	},

	canNotDragOrAddObject: function (map, point)
	{
		return false;
	},

	canUseMethodStack: function()
	{
		return false;
	},

	getLocalization: function(language)
	{
		switch (language)
		{
			case cc.sys.LANGUAGE_ENGLISH:
				return {
					"Turtle": "Turtle",
					"TurtleLevelType": "Turtle level",
					"TurtleTask": "Draw figure",
					"TurtleLevel": "Turtle",
					"turtle_move_forward": "forward",
					"turtle_move_backward": "backward",
					"turtle_turn_left": "left",
					"turtle_turn_right": "right",
					"turtle_tail_down": "tail down",
					"turtle_tail_up": "tail up"
				};
			case cc.sys.LANGUAGE_RUSSIAN:
				return {
					"Turtle": "Черепаха",
					"TurtleLevelType": "Черепаха",
					"TurtleTask": "Нарисовать фигуру",
					"TurtleLevel": "Черепаха",
					"turtle_move_forward": "вперед",
					"turtle_move_backward": "назад",
					"turtle_turn_left": "влево",
					"turtle_turn_right": "вправо",
					"turtle_tail_down": "опустить хвост",
					"turtle_tail_up": "поднять хвост"
				};
		}
	},

	getResourcesPlist: function ()
	{
		return "PlayerRobots/TurtleLevel.plist";
	},

	generateTaskLayer: function (level, task)
	{
		return new TurtleTaskLayer(level, task);
	},

	getTaskLabel: function ()
	{
		return "TurtleTask";
	},

	needToRotateRobot: function()
	{
		return false;
	},

	addLevelTask: function (level)
	{
		var task = new pm.data.TurtleTask(level);

		for (var i = 0; i < level.maps.length; ++i)
		{
			var lines = level.maps[i].lines;
			task.fillWithInitialData(i, lines);
		}

		level.taskList.addTask(pm.TurtleLevelModule.Type, task);

		return task;
	},

	addExtraMethods: function(robot, functions) {},

	getDepthLineSprites: function() {},

	getChangeableConditions: function(robot, callback, target) {},

	compareConditions: function(a, b) {},

	getLessonMapLayer: function()
	{
		return null;
	}
});

pm.TurtleLevelModule.RobotType = "turtle-robot";
pm.TurtleLevelModule.Type = "turtle";
pm.TurtleLevelModule.ObjectTypes = {};
