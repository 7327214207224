/**
 * Created by Kirill Mashchenko on 17.05.23.
 */

/**
 * @class 2D robot which represents virtual physical press robot.
 * @implements PlayerRobot4
 */
pm.data.PressRobot = PlayerRobot4.extend(/** @lends pm.data.PressRobot# */{
	typeName: "PressRobot",

	useClearCondition: true,
	useCanPressCondition: true,
	useBoxCondition: true,
	usePressMethod: true,
	useLoadingBoxes: true,

	ctor: function()
	{
		this._super();

		this.nativeFunctionMap[pm.data.PressRobot.NativeMethod.Press] = new pm.NativeFunction(this, this._press);
		this.nativeFunctionMap[pm.data.PressRobot.NativeMethod.LoadBox] = new pm.NativeFunction(this, this._loadBox);
		this.nativeFunctionMap[pm.data.PressRobot.NativeMethod.UnloadBox] = new pm.NativeFunction(this, this._unloadBox);

		this.updateMethods();
		this.updateConditions();
	},

	updateConditions: function()
	{
		this._changeCondition(pm.data.PressRobot.Condition.Clear, this.useClearCondition);
		this._changeConditionOpposite(pm.data.PressRobot.Condition.NotClear, this.useClearCondition);

		this._changeCondition(pm.data.PressRobot.Condition.CanPress, this.useCanPressCondition);
		this._changeConditionOpposite(pm.data.PressRobot.Condition.CanNotPress, this.useCanPressCondition);

		this._changeCondition(pm.data.PressRobot.Condition.Box, this.useBoxCondition);
		this._changeConditionOpposite(pm.data.PressRobot.Condition.NotBox, this.useBoxCondition);
	},

	updateMethods: function()
	{
		this._changeMethod(pm.data.PressRobot.NativeMethod.Press, this.usePressMethod);
		this._changeMethod(pm.data.PressRobot.NativeMethod.LoadBox, this.useLoadingBoxes);
		this._changeMethod(pm.data.PressRobot.NativeMethod.UnloadBox, this.useLoadingBoxes);
	},

	_checkConditionInternal: function(condition, args)
	{
		if (condition === pm.data.PressRobot.Condition.Clear)
		{
			return this._isNearClear();
		}
		else if (condition === pm.data.PressRobot.Condition.NotClear)
		{
			return !this._isNearClear();
		}
		else if (condition === pm.data.PressRobot.Condition.CanPress)
		{
			return this._canPress();
		}
		else if (condition === pm.data.PressRobot.Condition.CanNotPress)
		{
			return !this._canPress();
		}
		else if (condition === pm.data.PressRobot.Condition.Box)
		{
			return this._isClearForward();
		}
		else if (condition === pm.data.PressRobot.Condition.NotBox)
		{
			return !this._isClearForward();
		}
		else if (condition === pm.data.PressRobot.Condition.Empty)
		{
			return this._isEmpty();
		}
		else if (condition === pm.data.PressRobot.Condition.NotEmpty)
		{
			return !this._isEmpty();
		}
		else
		{
			return PlayerRobot4Walls.prototype._checkConditionInternal.call(this, condition);
		}
	},

	_isNearClear: function()
	{
		var map = this.getMap();
		var nextPos = map.getNextDirectionalElementPosition(this.position, this.direction);

		if (!nextPos)
			return false;

		var nextElement = map.element(nextPos);

		if (nextElement.getObjectCount() > 0)
		{
			for (var o in nextElement._objectsHere)
			{
				var object = nextElement._objectsHere[o];

				if (!(object instanceof pm.Object))
					continue;

				if (object.oldPosition.x === nextPos.x || object.oldPosition.y === nextPos.y)
					return false;
			}
		}

		if (nextElement.getRobotsCount() > 0)
		{
			for (var r in nextElement._robotsHere)
			{
				var robot = nextElement._robotsHere[r];

				if (!(robot instanceof pm.PlayerRobot2D))
					continue;

				if (robot._oldPosition.x === nextPos.x || robot._oldPosition.y === nextPos.y)
					return false;
			}
		}

		return true;
	},

	_canPress: function()
	{
		var map = this.getMap();
		var nextPos = map.getNextDirectionalElementPosition(this.position, this.direction);

		if (!nextPos)
			return false;

		var nextElement = map.element(nextPos);

		if (nextElement.getRobotsCount() > 0)
		{
			for (var r in nextElement._robotsHere)
			{
				var robot = nextElement._robotsHere[r];

				if (!(robot instanceof pm.PlayerRobot2D))
					continue;

				if (robot._oldPosition.x === nextPos.x || robot._oldPosition.y === nextPos.y)
					return false;
			}
		}

		if (nextElement.getObjectCount() === 0)
		{
			return false;
		}

		for (var o in nextElement._objectsHere)
		{
			var object = nextElement._objectsHere[o];

			if (!(object instanceof pm.data.PressBox))
				continue;

			if (object.isMoving())
				return false;

			var objectNextPosition = map.getNextDirectionalElementPosition(object.position, this.direction);

			if (!objectNextPosition)
			{
				return false;
			}

			if (map.element(objectNextPosition).getObjectCount() > 0)
			{
				return false;
			}
		}


		return true;
	},

	_isClearForward: function()
	{
		var map = this.getMap();
		var nextPos = map.getNextDirectionalElementPosition(this.position, this.direction);

		if (!nextPos)
			return false;

		var nextElement = map.element(nextPos);

		return (nextElement.getObjectCount() > 0);
	},

	_isEmpty: function()
	{
		var map = this.getMap();

		var currentElement = map.element(this.position);

		return (currentElement.getObjectCount() === 0);
	},

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new PressRobotSprite(this);

			var state = pm.PhysicalConnector.STATE.DISCONNECTED;

			if(this._PhysicalConnector && this._PhysicalConnector.isConnected())
				state = pm.PhysicalConnector.STATE.CONNECTED;

			this.sprite.changeState(state);

			return this.sprite;
		}
	},

	_press: function ()
	{
		var map = this.getMap();

		var nextPos = map.getNextDirectionalElementPosition(this.position, this.direction);

		var wasBroken = this.isBroken();

		if (!nextPos)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		var animations = new pm.AnimationTransaction();

		var nextElement = map.element(nextPos);

		if(!nextElement || nextElement.getObjectCount() === 0)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		for (var i in nextElement._objectsHere)
		{
			var object = nextElement._objectsHere[i];

			if (!(object instanceof pm.data.PressBox))
				continue;

			if (object.isMoving())
			{
				this.setStateFlag(pm.RobotState.Broken);
				return;
			}

			var objectNextPosition = map.getNextDirectionalElementPosition(object.position, this.direction);

			if (!objectNextPosition)
			{
				this.setStateFlag(pm.RobotState.Broken);
				return;
			}

			if (map.element(objectNextPosition).getObjectCount() > 0)
			{
				this.setStateFlag(pm.RobotState.Broken);
				return;
			}
		}

		var currentElement = map.element(this.position);

		var loadedBox = null;

		if (currentElement.getObjectCount() > 0)
		{
			for (var i in currentElement._objectsHere)
			{
				var object = currentElement._objectsHere[i];

				if (!(object instanceof pm.data.PressBox))
					continue;

				loadedBox = object;
			}
		}

		this._moveTo(nextPos, false, undefined, true);

		if(!CORE_BUILD && !pm.settings.isAnimationDisabled())
			this.setStateFlag(pm.RobotState.PlayingAnimation)

		var animationPositions = this._calculatePressFunctionPositions(this.direction, false);
		var pressRobotAnimations = this._getPressAnimations(animationPositions);
		var pressBoxAnimations = this._getPressAnimations(animationPositions);

		animations.addActionToPart(this, pressRobotAnimations["move"]);
		if (loadedBox)
			animations.addActionToPart(loadedBox, pressBoxAnimations["move"]);

		animations.addPart();

		if(wasBroken || this.isBroken())
			return;

		for (var i in nextElement._objectsHere)
		{
			var object = nextElement._objectsHere[i];

			if (!(object instanceof pm.data.PressBox))
				continue;

			var objectNextPosition = map.getNextDirectionalElementPosition(object.position, this.direction);

			object.moveTo(objectNextPosition, true);

			animations.addActionToPart(this, pressRobotAnimations["press"]);
			if (loadedBox)
				animations.addActionToPart(loadedBox, pressBoxAnimations["press"]);

			animations.addAnimationToPart(object, PressBoxAnimation.Move, object._endMoving, object.position);
		}

		if (loadedBox)
			loadedBox.moveTo(nextPos, true);

		animations.addPart();

		animations.addActionToPart(this, pressRobotAnimations["moveBack"]);
		if (loadedBox)
			animations.addActionToPart(loadedBox, pressBoxAnimations["moveBack"]);

		animations.addPart();

		if (loadedBox)
		{
			animations.addCallToPart(loadedBox._endMoving, loadedBox, null, true);
			animations.addPart();
		}

		animations.addCallToPart(this._endMove, this, null, true);
		animations.addPart();

		animations.playAnimations(this.getMap().mapLayer.objectLayer);
	},

	_loadBox: function()
	{
		var map = this.getMap();

		var nextPos = map.getNextDirectionalElementPosition(this.position, this.direction);

		var wasBroken = this.isBroken();

		if (!nextPos)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		var nextElement = map.element(nextPos);

		if(!nextElement || nextElement.getObjectCount() === 0)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		var currentElement = map.element(this.position);

		if(currentElement.getObjectCount() > 0)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		for (var i in nextElement._objectsHere)
		{
			var object = nextElement._objectsHere[i];

			if (!(object instanceof pm.data.PressBox))
				continue;

			if (object.isMoving())
			{
				this.setStateFlag(pm.RobotState.Broken);
				return;
			}
		}

		if(wasBroken || this.isBroken())
			return;

		for (var i in nextElement._objectsHere)
		{
			var object = nextElement._objectsHere[i];

			if (!(object instanceof pm.data.PressBox))
				continue;

			object.moveTo(this.position);
		}
	},

	_unloadBox: function()
	{
		var map = this.getMap();

		var nextPos = map.getNextDirectionalElementPosition(this.position, this.direction);

		var wasBroken = this.isBroken();

		if (!nextPos)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		var nextElement = map.element(nextPos);

		if(!nextElement || nextElement.getObjectCount() > 0)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		var currentElement = map.element(this.position);

		if(currentElement.getObjectCount() === 0)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		for (var i in currentElement._objectsHere)
		{
			var object = currentElement._objectsHere[i];

			if (!(object instanceof pm.data.PressBox))
				continue;

			if (object.isMoving())
			{
				this.setStateFlag(pm.RobotState.Broken);
				return;
			}
		}

		if(wasBroken || this.isBroken())
			return;

		for (var i in currentElement._objectsHere)
		{
			var object = currentElement._objectsHere[i];

			if (!(object instanceof pm.data.PressBox))
				continue;

			object.moveTo(nextPos);
		}
	},

	reset: function()
	{
		if(this.getMap().mapLayer)
		{
			this.getMap().mapLayer.objectLayer.stopAllActions();
		}
		PlayerRobot4.prototype.reset.call(this);
	},

	_move: function()
	{
		var map = this.getMap();

		var nextPos = map.getNextDirectionalElementPosition(this.position, this.direction);

		if (!nextPos)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		var nextElement = map.element(nextPos);

		if(!nextElement)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		if (nextElement.getObjectCount() > 0)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		var currentElement = map.element(this.position);

		this._moveTo(nextPos, false);

		if (currentElement.getObjectCount() > 0)
		{
			for (var i in currentElement._objectsHere)
			{
				var object = currentElement._objectsHere[i];

				if (!(object instanceof pm.data.PressBox))
					continue;

				object.moveTo(nextPos);
			}
		}
	},

	_turnLeft: function ()
	{
		pm.PlayerRobot2D.prototype._turnLeft.call(this);

		var map = this.getMap();

		var currentElement = map.element(this.position);

		if (currentElement.getObjectCount() > 0)
		{
			for (var i in currentElement._objectsHere)
			{
				var object = currentElement._objectsHere[i];

				if (!(object instanceof pm.data.PressBox))
					continue;

				object._turnLeft();
			}
		}
	},

	_turnRight: function ()
	{
		pm.PlayerRobot2D.prototype._turnRight.call(this);

		var map = this.getMap();

		var currentElement = map.element(this.position);

		if (currentElement.getObjectCount() > 0)
		{
			for (var i in currentElement._objectsHere)
			{
				var object = currentElement._objectsHere[i];

				if (!(object instanceof pm.data.PressBox))
					continue;

				object._turnRight();
			}
		}
	},
	
	pressStateChanged: function(state)
	{
		if(this.sprite instanceof pm.ObjectSprite2D)
			this.sprite.changeState(state);
	},

	hasChangeableConditions: function() { return true; },

	hasChangeableMethods: function() { return true; },

	getType: function() { return pm.PressRobotLevelModule.RobotType; },

	_calculatePressFunctionPositions: function (direction, reverse)
	{
		var targetPos1 = cc.p(0, 0);
		var targetPos2 = cc.p(0, 0);
		var targetPos3 = cc.p(0, 0);

		var cellSide = 89; //89
		var halfSide = 39; // 36;

		if(!reverse)
		{
			switch (direction)
			{
				case MapDirection4.Up:
					targetPos1.y = halfSide;
					targetPos2.y = cellSide;
					targetPos3.y = - halfSide;
					break;
				case MapDirection4.Left:
					targetPos1.x = - halfSide;
					targetPos2.x = - cellSide;
					targetPos3.x = halfSide;
					break;
				case MapDirection4.Down:
					targetPos1.y = - halfSide;
					targetPos2.y = - cellSide;
					targetPos3.y = halfSide;
					break;
				case MapDirection4.Right:
					targetPos1.x = halfSide;
					targetPos2.x = cellSide;
					targetPos3.x = - halfSide;
					break;
			}

		}
		else
		{
			switch (direction)
			{
				case MapDirection4.Up:
					targetPos1.y = - halfSide;
					targetPos2.y = - cellSide;
					targetPos3.y = halfSide;
					break;
				case MapDirection4.Left:
					targetPos1.x = halfSide;
					targetPos2.x = cellSide;
					targetPos3.x = - halfSide;
					break;
				case MapDirection4.Down:
					targetPos1.y = halfSide;
					targetPos2.y = cellSide;
					targetPos3.y = - halfSide;
					break;
				case MapDirection4.Right:
					targetPos1.x = - halfSide;
					targetPos2.x = - cellSide;
					targetPos3.x = halfSide;
					break;
			}
		}

		return [targetPos1, targetPos2, targetPos3];
	},

	_getPressAnimations: function(positions)
	{
		var pressAnimations = {};

		if(CORE_BUILD || pm.settings.isAnimationDisabled())
			return pressAnimations;

		pressAnimations["move"] = cc.moveBy(pm.settings.getAnimationSpeed() * 0.5, positions[0]);
		pressAnimations["press"] = cc.moveBy(pm.settings.getAnimationSpeed(), positions[1]);
		pressAnimations["moveBack"] = cc.moveBy(pm.settings.getAnimationSpeed() * 0.5, positions[2]);

		return pressAnimations;
	},
});

pm.data.PressRobot.Condition =
{
	Clear: "press_clear",
	NotClear: "press_not_clear",
	CanPress: "press_can_press",
	CanNotPress: "press_can_not_press",
	Box: "press_box",
	NotBox: "press_not_box"
};

pm.data.PressRobot.NativeMethod = {
	Press: "press_press",
	LoadBox: "press_load_box",
	UnloadBox: "press_unload_box"
};
