/**
 * Created by Antony Orlovsky on 13.03.2019.
 */

/**
 * @class CounterRepeaterButton used by {@link RobotManager} and {@link ProgramLayer}.
 * @extends RepeaterButton
 * @constructor
 * @param {String} value
 */
var CounterRepeaterButton = RepeaterButton.extend(/** @lends RepeaterButton#*/{

	_iterNum: 0,

	ctor: function(value, drawBackground)
	{
		this._super(value, drawBackground);

		this._iterNum = new ccui.Text("", pm.settings.fontName, pm.settings.fontSize);
		this._iterNum.setColor(CounterRepeaterButton.TEXT_COLOR);
		this._iterNum.setAnchorPoint(cc.p(0.5, 0.5));
		this._iterNum.setPosition(this.width / 2 - CounterRepeaterButton.LEFT_MARGIN, this.height / 2 - CounterRepeaterButton.UPPER_MARGIN);

		this.addChild(this._iterNum, 1);
	},

	switchPointsNumber: function(num, iterNumber)
	{
		RepeaterButton.prototype.switchPointsNumber.call(this, iterNumber);

		if (iterNumber === 0 || iterNumber === undefined)
			this._iterNum.setVisible(false);
		else
		{
			this._iterNum.setString(num - iterNumber + 1);
			this._iterNum.setVisible(true);
		}
	},

	_getSpriteName: function(state, iterNumber)
	{
		if (iterNumber === 0 || iterNumber === undefined)
			return pm.spriteUtils.getRepeaterSpriteName(this._value, state);

		return pm.spriteUtils.getRepeaterSpriteName("{0}_{1}".format(this._value, pm.REPEATER_EMPTY), state);
	}
});

CounterRepeaterButton.UPPER_MARGIN = 2.5;
CounterRepeaterButton.LEFT_MARGIN = 1.0;
CounterRepeaterButton.TEXT_COLOR = cc.color(255, 144, 0);
