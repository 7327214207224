/**
 * Created by Nikita Besshaposhnikov on 11.11.14.
 */
/**
 * Global world reference.
 * @type {pm.data.World}
 */
var world = {};

/**
 *
 * @enum {Number}
 */
var GameType = {
	Local: 0,
	Net: 1
};

/**
 * Class that contains levels array.
 * @class
 * @extends pm.Class
 * @constructor
 * @param {String} [name] Name of game.
 */
pm.data.Game = pm.Class.extend(/** @lends pm.data.Game# */{

	ctor: function(name, order)
	{
		this._super();

		if(name)
			this.name = name;

		if(order !== undefined)
			this.order = order;
	},

	typeName: "Game",
	/**
     * id of game.
     * @type {String}
     */
	id: "",
	/**
     * Name of game.
     * @type {String}
     */
	name: "",
	/**
     * Order of game.
     * @type {Number}
     */
	order: 0,
	/**
     * Array of levels.
     * @type {Array<pm.AbstractLevel>}
     */
	levels: []
});

/**
 * @class This class represents a "data"-class which contains all data of world.
 * @extends pm.Class
 */
pm.data.World = pm.Class.extend(/** @lends pm.data.World# */{
	typeName: "World",
	/**
     * id of world.
     * @type {String}
     */
	id: "",
	/**
     * Name of world.
     * @type {String}
     */
	name: "",
	/**
     * Description of world.
     * @type {String}
     */
	description: "",
	/**
     * Publicity of world.
     * @type {Boolean}
     */
	public: false,
	/**
	 * World is linked with Mirera
	 * @type {Boolean}
	 */
	linkedWithMirera: false,
	/**
     * Date of world creation.
     * @type {String}
     */
	createDate: (new Date()).toISOString(),
	/**
     * Array of games.
     * @type {Array<pm.data.Game>}
     */
	games: [],
	/**
     * Current running level.
     * @type {pm.AbstractLevel}
     */
	runningLevel: {},
	/**
     * @private
     */
	_clipboard: {},

	_editLog: null,

	ctor: function()
	{
		this._addNonEnumerableProps("_clipboard", "runningLevel", "_editLog");

		this._super();
	},

	/**
     * Load level and prepares it to game.
     * @function
     * @param {Boolean} preview Is a preview loading.
     * @param {Number} game Game index.
     * @param {Number} level Level index.
     * @param {Number} [robotIndex] Index of robot to use in level
     * @returns {pm.AbstractLevel}
     */
	loadLevel: function(preview, game, level, robotIndex)
	{
		var levelObject = this.games[game].levels[level];

		if(!levelObject)
			return null;

		levelObject.load(preview, robotIndex);

		this.runningLevel = levelObject;

		return levelObject;
	},

	/**
     * Load world from file.
     * @function
     * @param {String} worldID
     * @param {Object} [worldObject]
     * @param {Function} [callback] Callback on world loaded with cache. Not called in CORE_BUILD.
     */
	load: function(worldID, worldObject, callback)
	{
		this.games = [];

		if(!CORE_BUILD)
		{
			this.deserialize(worldObject ? worldObject : pm.settings.getWorldPath(worldID));

			pm.userCache.load(worldID, callback);

			this._editLog = new pm.WorldEditLog(worldID);
		}
		else
		{
			this.deserialize(worldID);
		}
	},

	/**
     * Save world to path.
     * @function
     * @param {String} [path]
     */
	save: function(path)
	{
		if(!CORE_BUILD)
			this.serialize((path === undefined) ? pm.settings.getWorldPath() : path);
	},

	/**
     * Renames world
     * @param {String} name A new name
     */
	rename: function(name)
	{
		this.name = name;

		this._editLog.setUpdatedFlag();
		this.save();
	},

	/**
     * Change description of world
     * @param {String} name A new description
     */
	changeDescription: function(description)
	{
		this.description = description;

		this._editLog.setUpdatedFlag();
		this.save();
	},

	/**
     * Change description of world
     * @param {Boolean} flag
     */
	changePublicity: function(flag)
	{
		this.public = flag;

		this._editLog.setUpdatedFlag();
		this.save();
	},

	/**
     * Returns edit log
     * @function
     * @returns {Array<pm.AbstractLevel>}
     */
	getEditLog: function()
	{
		return this._editLog;
	},

	/**
     * Returns games
     * @function
     * @returns {Array<pm.AbstractLevel>}
     */
	getGames: function()
	{
		return this.games;
	},

	/**
     * @function
     * @returns {Number}
     */
	getGameCount: function() { return this.games.length; },
	/**
     * @function
     * @param {Number} game Game Index
     * @returns {Number}
     */
	getLevelCount: function(game) { return this.games[game].levels.length; },
	/**
     * @function
     * @param {Number} game Game Index
     * @returns {string}
     */
	getGameName: function(game) { return this.games[game].name; },
	/**
     * @function
     * @param {Number} game Game Index
     * @param {Number} level Level Index
     * @returns {string}
     */
	getLevelName: function(game, level) { return this.games[game].levels[level].name; },

	/**
     * Removes level.
     * @function
     * @param {Number} game Game Index
     * @param {Number} level Level Index
     */
	removeLevel: function(game, level)
	{
		if(this.games[game].levels[level].id !== "")
			this._editLog.setLevelDeleted(this.games[game].levels[level].id);

		this.games[game].levels.splice(level, 1);

		for(var i = level; i < this.games[game].levels.length; ++i)
		{
			--this.games[game].levels[i].order;
			this.setLevelReordered(game, i);
		}

		this.save();
	},

	/**
     * Sets level updated flag for edit log
     */
	setLevelUpdated: function(game, level)
	{
		if(this.games[game].levels[level].id !== "")
			this._editLog.setLevelUpdated(this.games[game].levels[level].id);
	},

	setLevelReordered: function(game, level)
	{
		if(this.games[game].levels[level].id !== "")
			this._editLog.setLevelReordered(this.games[game].levels[level].id);
	},

	/**
     * @function
     * @param {Number} game Game Index
     * @param {Number} level1 Level Index
     * @param {Number} level2 Level Index
     */
	swapLevels: function(game, level1, level2)
	{
		this.setLevelReordered(game, level1);
		this.setLevelReordered(game, level2);

		var tmp = this.games[game].levels[level1];
		var tmpOrder = this.games[game].levels[level1].order;

		this.games[game].levels[level1].order = this.games[game].levels[level2].order;
		this.games[game].levels[level1] = this.games[game].levels[level2];

		this.games[game].levels[level2].order = tmpOrder;
		this.games[game].levels[level2] = tmp;

		this.save();
	},

	/**
     * @function
     * @param {Number} game Game Index
     * @param {String} levelType
     */
	addLevel: function(game, levelType)
	{
		this._editLog.setUpdated();

		var level = pm.moduleUtils.generateEmptyLevel(levelType);
		level.version = pm.appUtils.getSupportedLevelFormatVersion();
		level.order = this.games[game].levels.length;

		this.games[game].levels.push(level);
		this.save();
	},

	/**
     * Renames game
     * @function
     * @param {Number} game Game Index
     */
	renameGame: function(game, name)
	{
		if(this.games[game].name !== name)
		{
			if (this.games[game].id !== "")
				this._editLog.setGameRenamed(this.games[game].id);

			this.games[game].name = name;
			this.save();
		}
	},

	/**
     * @function
     * @param {Number} game Game Index
     */
	removeGame: function(game)
	{
		if(this.games[game].id !== "")
			this._editLog.setGameDeleted(this.games[game].id);

		this.games.splice(game, 1);

		for(var i = game; i < this.games.length; ++i)
		{
			--this.games[i].order;
			this._editLog.setGameReordered(this.games[i].id);
		}

		this.save();
	},

	/**
     * @function
     * @param {Number} game1 Game Index
     * @param {Number} game2 Game Index
     */
	swapGames: function(game1, game2)
	{
		if(this.games[game1].id !== "")
			this._editLog.setGameReordered(this.games[game1].id);

		if(this.games[game2].id !== "")
			this._editLog.setGameReordered(this.games[game2].id);

		var tmp = this.games[game1];
		var tmpOrder = this.games[game1].order;

		this.games[game1].order = this.games[game2].order;
		this.games[game1] = this.games[game2];

		this.games[game2].order = tmpOrder;
		this.games[game2] = tmp;

		this.save();
	},

	/**
     * @function
     */
	addGame: function()
	{
		this._editLog.setUpdated();

		var game = new pm.data.Game(LocalizedString("NewGame"));
		game.order = this.games.length;

		this.games.push(game);
		this.save();
	},

	/**
     * @function
     * @param {Number} game Game Index
     * @param {Number} level Level Index
     */
	copy: function(game, level)
	{
		if (level === undefined)
			this._clipboard = this.games[game];
		else
			this._clipboard = this.games[game].levels[level];
	},

	/**
     * @function
     * @param {Number} game Game Index
	 * @param {Number} index Insertion Place Index
     */
	paste: function(game, index)
	{
		if (this._clipboard instanceof pm.AbstractLevel && game !== -1)
		{
			if(index === undefined)
				index = this.games[game].levels.length;

			var clone = cloneClassObject(this._clipboard);
			clone.id = "";
			clone.revision = 1;
			clone.order = index;

			this._editLog.setUpdated();
			this.games[game].levels.splice(index, 0, clone);

			for (var i = index+1; i < this.games[game].levels.length; ++i)
			{
				++this.games[game].levels[i].order;

				this.setLevelReordered(game, i);
			}

			this.save();
		}
		else if (this._clipboard instanceof pm.data.Game)
		{
			if(index === undefined)
				index = this.games.length;

			var clone = cloneClassObject(this._clipboard);
			clone.id = "";
			clone.order = index;

			for(var i = 0; i < clone.levels.length; ++i)
			{
				clone.levels[i].id = "";
				clone.levels[i].revision = 1;
				clone.levels[i].order = i;
			}

			this._editLog.setUpdated();
			this.games.splice(index, 0, clone);

			for (var i = index+1; i < this.games.length; ++i)
			{
				++this.games[i].order;

				if(this.games[i].id !== "")
					this._editLog.setGameReordered(this.games[i].id);
			}

			this.save();
		}
	},

	getGameById: function(id)
	{
		for (var i = 0; i < this.games.length; ++i)
		{
			if (this.games[i].id === id)
				return this.games[i];
		}
	},

	getLevelById: function(id)
	{
		for (var i = 0; i < this.games.length; ++i)
		{
			for (var j = 0; j < this.games[i].levels.length; ++j)
			{
				if (this.games[i].levels[j].id === id)
					return this.games[i].levels[j];
			}
		}
	}
});
