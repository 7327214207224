/**
 * Created by Nikita Besshaposhnikov on 06.06.16.
 */

/**
 * Enum for tutorial step type
 * @enum {Number}
 */
pm.TutorialStepType = {
	CLICK_OBJECT: 0,
	DRAG_OBJECT: 1,
	MOVE_TO: 2,
	SHOW_PICTURES: 3,
	CLICK_AREA: 4,
	WAIT_EVENT: 5
};

/**
 * @class This a class which contains data of a {@link pm.data.TutorialScenario} step.
 * @extends pm.Class
 */
pm.data.TutorialStep = pm.Class.extend(/** @lends pm.data.TutorialStep# */{

	typeName: "tutorial_step",

	/**
     * Type of step
     * @type {pm.TutorialStepType}
     */
	type: pm.TutorialStepType.CLICK_OBJECT,

	/**
     * Data of step. <br/>
     * String for {@link pm.TutorialStepType.CLICK_OBJECT} or {@link pm.TutorialStepType.WAIT_EVENT}<br/>
     * Object {from:String,to:String} for {@link pm.TutorialStepType.DRAG_OBJECT}<br/>
     * Array of strings for {@link pm.TutorialStepType.SHOW_PICTURES}
     * Object {object:String, delta:cc.Point, radius: Number} {@link pm.TutorialStepType.MOVE_TO}
     * Object {object:String, rect: cc.Rect} for {@link pm.TutorialStepType.CLICK_AREA}
     * @type {String|{from:String,to:String}|{object:String,delta:cc.Point, radius: Number}|Array<String>|{object:String, rect: cc.Rect}}
     */
	data: ""
});

/**
 * @class This a class which contains data for tutorial steps.
 * @extends pm.Class
 * @constructor
 */
pm.data.TutorialScenario = pm.Class.extend(/** @lends pm.data.TutorialScenario# */{

	typeName: "tutorial",
	/**
     * Steps of scenario
     * @type {Array.<pm.data.TutorialStep>}
     */
	steps: [],
	_currentStep: -1,

	ctor: function()
	{
		this._addNonEnumerableProps("_currentStep");
		this._super();
	},

	/**
     * Starts tutorial.
     */
	start: function()
	{
		this._currentStep = 0;
	},

	/**
     * Returns next step of scenario
     * @returns {pm.data.TutorialStep}
     */
	getNextStep: function()
	{
		if(this._currentStep >= this.steps.length)
			return null;

		return this.steps[ this._currentStep++ ];
	},

	/**
     * Returns current step index of scenario
     * @returns {Number}
     */
	getCurrentStepIndex: function()
	{
		return this._currentStep - 1;
	},

	/**
     * Returns previous step of scenario
     * @returns {pm.data.TutorialStep}
     */
	getPrevStep: function()
	{
		if(this._currentStep === 0)
			return null;

		return this.steps[ this._currentStep-- ];
	},

	/**
     * Is tutorial empty
     * @returns {Boolean}
     */
	empty: function()
	{
		return this.steps.length === 0;
	}
});
