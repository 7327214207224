/**
 * Created by Antony Orlovsky on 09.08.19.
 */

/**
 * @class 2D map where robot can paint some figures.
 * @implements Map2D
 */
pm.data.TurtleMap = Map2D.extend(/** @lends pm.data.TurtleMap# */{
	typeName: "TurtleMap",
	lines: [],
	badLines: [],

	ctor: function()
	{
		this._super();
	},

	getType: function() { return pm.TurtleLevelModule.Type; },

	generateMapLayer: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (!pm.settings.isEditorMode)
				this.mapLayer = new TurtleMapLayer(this);
			else
				this.mapLayer = new TurtleMapEditorLayer(this);

			this.mapLayer.drawMap(previewDraw);

			return this.mapLayer;
		}
	},

	load: function() {},

	placeRobots: function(firstPlace)
	{
		var level = this.parentLevel;

		for (var i = 0;  i < level.robots.length; ++i)
		{
			var robot = level.robots[i];

			robot.setPosition(robot.startPosition, robot.startDirection);
			robot.setTailFlag(false);

			if(!CORE_BUILD)
				this.mapLayer._setRobotStartPositionMarkPosition(robot.id, robot.position);
		}

		pm.AbstractMap.prototype.placeRobots.call(this, firstPlace);
	},

	removeRobots: function()
	{
		pm.AbstractMap.prototype.removeRobots.call(this);
	},

	setSize: function(width, height)
	{
		this.mapElements.length = 0;

		this.width = width;
		this.height = height;
	},

	clean: function()
	{
		var level = this.parentLevel;

		for (var i = 0; i < level.robots.length; ++i)
		{
			var robot = level.robots[i];

			robot.setPosition(robot.startPosition, robot.startDirection);
			robot.setTailFlag(false);
		}

		if(!CORE_BUILD)
			this.setNotCompleted(false);

		this.lines = [];

		pm.AbstractMap.prototype.clean.call(this);
	},

	getState: function ()
	{
		var state = {
			mapElementsStates: {},
			objectsStates: {}
		};

		// var max = Math.max(this.width, this.height);
		//
		// for (var x = 0; x < this.width; ++x)
		// {
		// 	for (var y = 0; y < this.height; ++y)
		// 	{
		// 		var element = this.element(cc.p(x, y));
		// 		state.mapElementsStates[x * max + y] = element.getState();
		// 	}
		// }

		for (var i = 0; i < this.objects.length; ++i)
			state.objectsStates[i] = this.objects[i].getState();

		return state;
	},

	setState: function (state)
	{
		// var max = Math.max(this.width, this.height);
		//
		// for (var x = 0; x < this.width; ++x)
		// {
		// 	for (var y = 0; y < this.height; ++y)
		// 	{
		// 		var element = this.element(cc.p(x, y));
		// 		element.setState(state.mapElementsStates[x * max + y]);
		// 	}
		// }

		for (var i = 0; i < this.objects.length; ++i)
			this.objects[i].setState(state.objectsStates[i]);
	},

	setNotCompleted: function(flag)
	{
		if (!CORE_BUILD)
			this.mapLayer.setNotCompleted(flag);
		if(!flag)
			this.badLines = [];
	},

	element: function (pos)
	{
		return null;
	}
});
