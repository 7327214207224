/**
 * Created by Nikita Besshaposhnikov on 18.12.14.
 */

/**
 * @class 2D level where robot can repair some kinds({@link RepairMapElementType}) of elements.
 * @extends SquarableLevel
 */
pm.data.RepairLevel = SquarableLevel.extend(/** @lends pm.data.RepairLevel# */{
	typeName: "RepairLevel",
	/**
	 * Robots can be indicated.
	 * @type {Boolean}
	 */
	robotsCanBeIndicated: false,

	ctor: function()
	{
		this._super();
	},

	getType: function() { return pm.RepairLevelModule.Type; }
});
