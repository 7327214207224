/**
 * Created by Nikita Besshaposhnikov on 03.03.16.
 */

/**
 * @class Layer for loading animation for async processes.<br/>
 * Intercepts touch events.
 * @extends cc.LayerColor
 */
var LoadingLayer = cc.LayerColor.extend(/** @lends  LoadingLayer*/{

	_removed: false,

	ctor: function(animation)
	{
		animation = (animation === undefined) ? true : animation;

		var screenSize = pm.settings.getScreenSize();

		this._super(pm.settings.transparentColor, screenSize.width, screenSize.height);

		var loadLayer = new cc.Layer();
		loadLayer.setContentSize(screenSize.width, screenSize.height);
		loadLayer.setPosition(screenSize.width / 2, screenSize.height / 2);

		if (animation)
		{
			var satellite = new cc.Sprite("Background/Satellite.png");
			var planet = new cc.Sprite("Background/Planet.png");

			planet.setAnchorPoint(cc.p(0.5, 0.5));
			planet.setPosition(cc.p(screenSize.width/2, screenSize.height/2));

			var satelliteLayer = new cc.Layer();
			satelliteLayer.setContentSize(planet.width, planet.height);
			satelliteLayer.setPosition(cc.p(screenSize.width/2 - satelliteLayer.width/2, screenSize.height/2 - satelliteLayer.height/2));
			satelliteLayer.setScale(0.9);

			satellite.setAnchorPoint(cc.p(0.5, 0.5));
			satellite.setPosition(cc.p(satelliteLayer.width/2-75, satelliteLayer.height/2));

			satelliteLayer.addChild(satellite, 3);

			loadLayer.addChild(planet);
			loadLayer.addChild(satelliteLayer);

			var scale = Math.min(screenSize.width / planet.width, screenSize.height / planet.height);

			if (scale > 1)
				scale = 1;

			loadLayer.setScale(scale);
			loadLayer.setPosition(loadLayer.getPosition().x*(1 - scale), loadLayer.getPosition().y*(1 - scale));

			satelliteLayer.runAction(cc.repeatForever(cc.rotateBy(360 * 0.015, 360)));

			satellite.runAction(cc.repeatForever(cc.sequence(
				cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.6), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.5 * 255)),
				cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, 1), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 255))
			)));
		}

		this.addChild(loadLayer, 2);

		var touchListener = cc.EventListener.create({
			event: cc.EventListener.TOUCH_ONE_BY_ONE,
			swallowTouches: true,
			onTouchBegan: this._touchBegan.bind(this),
			onTouchMoved: this._touchMoved.bind(this),
			onTouchEnded: this._touchEnded.bind(this)
		});

		cc.eventManager.addListener(touchListener, this);
	},

	_touchBegan: function(touch, event)
	{
		return true;
	},

	_touchMoved: function() {},
	_touchEnded: function() {},

	/**
     * Shows layer
     */
	show: function()
	{
		var self = this;
		self.retain();

		//Workaround for loading scene. Skip one tick to change scene in cc.director.
		setTimeout(function()
		{
			if(!self._removed)
				cc.director.getRunningScene().addChild(self, LoadingLayer.ZORDER, LoadingLayer.TAG);
			self.release();
		}, 0);
	},

	/**
     * Removes layer.
     */
	remove: function()
	{
		this._removed = true;

		if(this.getParent())
			this.removeFromParent();
	}
});

/**
 * A tag for loading layer
 * @constant
 * @default
 * @type {number}
 */
LoadingLayer.TAG = 1000;
/**
 * A zorder for loading layer
 * @constant
 * @default
 * @type {number}
 */
LoadingLayer.ZORDER = 1000;

