/**
 * Created by Nikita Besshaposhnikov on 10.03.16.
 */

/**
 * @class RecognizeResultButton used for result of recognition.
 * @extends FunctionButtonP
 * @constructor
 * @param {Number} value
 */
var RecognizeResultButton = FunctionButtonP.extend(/** @lends RecognizeResultButton#*/{

	_label: null,

	ctor: function(value)
	{
		this._label = new cc.LabelTTF(String(0), pm.settings.fontName, pm.settings.fontSize);

		this._super(FunctionButton.Type.RecognizeResult, value);

		this.loadTextureNormal(this._getSpriteName(pm.NORMAL_STATE), ccui.Widget.PLIST_TEXTURE);
		this.loadTexturePressed(this._getSpriteName(pm.SELECTED_STATE), ccui.Widget.PLIST_TEXTURE);
		this.loadTextureDisabled(this._getSpriteName(pm.DISABLED_STATE), ccui.Widget.PLIST_TEXTURE);

		this._label.setPosition(this.width / 2, this.height / 2);
		this._label.setFontFillColor(pm.settings.levelFontColor);

		this.addChild(this._label);
	},

	cloneImage: function()
	{
		var image = new ccui.ImageView(pm.spriteUtils.getIconName("mslDrag"), ccui.Widget.PLIST_TEXTURE);

		var label = new cc.LabelTTF(String(this._value.length || 0), pm.settings.fontName, pm.settings.fontSize);
		label.setPosition(image.width / 2, image.height / 2);
		label.setFontFillColor(pm.settings.levelFontColor);

		image.addChild(label);

		return image;
	},

	_valueChanged: function()
	{
		this._label.setString(String(this._value.length || 0));
	},

	_getSpriteName: function(state)
	{
		return pm.spriteUtils.getIconName("mslDrag", state);
	}
});
