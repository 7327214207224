/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.ConditionNode = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{

	_condButton: null,

	ctor: function (treeNode, parent, syntaxModule, value, levelType)
	{
		this._super(parent, treeNode, syntaxModule);
		this.type = FunctionButton.Type.Condition;
		this.setLayoutType(ccui.Layout.RELATIVE);
		this.drawBackground();
		this._repTypes = [FunctionButton.Type.Condition, FunctionButton.Type.LogicExpression,
			FunctionButton.Type.Variable, FunctionButton.Type.AndOrExpression, FunctionButton.Type.NotExpression];
		// this._addTypes = [FunctionButton.Type.Condition];

		var align = new ccui.RelativeLayoutParameter();
		align.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
		align.setMargin(15, 10, 15, 0);
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
		{
			align.setMargin(5, 10, 5, 0);
		}
		this._condButton = this.createText(LocalizedString(pm.moduleUtils.getRobotConditionName(levelType, value)));
		this._condButton.setLayoutParameter(align);
		this.addChild(this._condButton);
	},

	select: function ()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return;
		if (!this._selected)
		{
			this.setBackGroundImage("PiktomirK/select-romb-border.png");
			this.setBackGroundImageCapInsets(cc.rect(28, 22, 70, 6));
			this._selected = true;
			this.setTag(ProgramContainerLayer.SELECTED_TAG);
		}
	},

	highlight: function ()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return;
		this.setBackGroundImage("PiktomirK/select-romb-border.png");
		this.setBackGroundImageCapInsets(cc.rect(28, 22, 70, 6));
	},

	drawBackground: function ()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return;
		if (!this.isEditable())
			this.setBackGroundImage("PiktomirK/romb-blocked.png");
		else
			this.setBackGroundImage("PiktomirK/romb-border.png");
		this.setBackGroundImageScale9Enabled(true);
		this.setCascadeOpacityEnabled(false);
		this.setBackGroundImageCapInsets(cc.rect(28, 22, 70, 6));
	},

	deSelect: function ()
	{
		this.drawBackground();
		this._selected = false;
		this.setTag(ProgramContainerLayer.NOT_SELECTED_TAG);
	},

	clone: function ()
	{
		return pm.appUtils.generateFunctionButton(FunctionButton.Type.Condition, this._treeNode.value);
	},

	calculateSize: function (forPar)
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			this.setContentSize(this._condButton.width, this._condButton.height);
		else
			this.sizeOfContainer(this);
		if (!forPar)
			this._parentNode.calculateSize();
	}
});


