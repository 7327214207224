/**
 * Created by Nikita Besshaposhnikov on 13.03.18.
 */

pm.StudentClientImpl = pm.GameClient.extend({

	ctor: function()
	{
		this._super();
	},

	sendCurrentLevelStatus: function(state)
	{
		var packet = {
			type: pm.STUDENT_CLIENT_PACKET_TYPE.LEVEL_STATUS_UPDATED,
			state: state
		};

		pm.networkUtils.log("Sending level status updated packet", pm.NetworkDebugSendTypes.STUDENT_CLIENT, packet);

		this.sendPacket(JSON.stringify(packet));
	},

	sendStatus: function(connectedToGame, levelState)
	{
		var packet = {
			type: pm.STUDENT_CLIENT_PACKET_TYPE.STATUS,
			connectedToGame: connectedToGame,
			levelState: levelState
		};

		pm.networkUtils.log("Sending status packet", pm.NetworkDebugSendTypes.STUDENT_CLIENT, packet);

		this.sendPacket(JSON.stringify(packet));
	},

	sendRobotIndexMap: function(robotIndexMap)
	{
		var packet = {
			type: pm.STUDENT_CLIENT_PACKET_TYPE.ROBOT_INDEX_MAP,
			robotIndexMap: robotIndexMap
		};

		pm.networkUtils.log("Sending robot index map packet", pm.NetworkDebugSendTypes.STUDENT_CLIENT, packet);

		this.sendPacket(JSON.stringify(packet));
	},

	sendProgram: function()
	{
		var program;

		var level = world.runningLevel;
		var robot = level.getCurrentRobot();

		if (!level.programDataCache[robot.groupID])
			program = level.programData[robot.groupID];
		else
			program = level.programDataCache[robot.groupID];

		var packet = {
			type: pm.STUDENT_CLIENT_PACKET_TYPE.PROGRAM,
			program: program
		};

		pm.networkUtils.log("Sending program packet", pm.NetworkDebugSendTypes.STUDENT_CLIENT, packet);

		this.sendPacket(JSON.stringify(packet));
	},

	sendTimeInfoToTeacher: function(solvingTimes)
	{
		var packet = {
			type: pm.STUDENT_CLIENT_PACKET_TYPE.TIME_INFO,
			solvingTimes: solvingTimes
		};

		pm.networkUtils.log("Sending time info packet", pm.NetworkDebugSendTypes.STUDENT_CLIENT, packet);

		this.sendPacket(JSON.stringify(packet));
	},

	sendLevelSceneEntered: function()
	{
		var packet = {
			type: pm.STUDENT_CLIENT_PACKET_TYPE.LEVEL_SCENE_ENTERED
		};

		pm.networkUtils.log("Sending level scene entered packet", pm.NetworkDebugSendTypes.STUDENT_CLIENT, packet);

		this.sendPacket(JSON.stringify(packet));
	}
});

