/**
 * Created by danilaeremin on 12.08.15.
 */

/**
 * This callback is used on select cell of pmui.TableView.<br/>
 * If clicked selected cell - cell will be deselected and callback called with null parameter.
 * @callback pmui.SelectTableView~selectCallback
 * @param {?cc.Point} cell Coordinates of selected cell.
 */

/**
 * @class This class represents a table with selected possibility.
 * @extends pmui.TableView
 * @property {Boolean} canDeselectCell Can selected cell be deselected.
 * @constructor
 * @param {cc.Size} cellSize Size of cells.
 * @param {cc.Size} cellSeparator Dimensions of cell separators.
 * @param {Number} [rowCount = 1] Number of rows.
 * @param {Number} [columnCount = 1] Number of columns.
 */
pmui.SelectTableView = pmui.TableView.extend(/** @lends pmui.SelectTableView# */{
	_callback: null,
	_target: null,
	_selectFrame: null,
	_selectFrameScale9: false,
	_selectedCell: null,
	_startTouchSelectedCell: null,
	canDeselectCell: true,

	ctor: function (cellSize, cellSeparator, rowCount, columnCount)
	{
		this._super(cellSize, cellSeparator, rowCount, columnCount, false);

		this._scroll.setClickEventCallback(this._onSelectItem.bind(this));
	},

	/**
     * Sets select frame image.
     * @param {ccui.Image|cc.SpriteFrame} frameOrImage If scale9Sprite use cc.SpriteFrame else ccui.Image.
     * @param {Boolean} scale9Sprite Is image Scale9Sprite.
     */
	setSelectFrame: function(frameOrImage, scale9Sprite)
	{
		this._selectFrameScale9 = scale9Sprite;

		if(scale9Sprite)
		{
			if(frameOrImage instanceof cc.SpriteFrame)
				this._selectFrame = new ccui.Scale9Sprite(frameOrImage);
		}
		else
		if(frameOrImage instanceof ccui.ImageView)
		{ this._selectFrame = frameOrImage; }

		if(this._selectFrame)
		{
			this._selectFrame.setVisible(false);
			this._selectFrame.setAnchorPoint(cc.p(0, 1));
			this._getCellContainer().addChild(this._selectFrame, 0);
			this._updateSelection();
		}
	},

	/**
     * Adds listener in select action.
     * @param {pmui.SelectTableView~selectCallback} callback
     * @param {cc.Node} target
     */
	addSelectEventListener: function(callback, target)
	{
		this._callback = callback;
		this._target = target;
	},

	_updateSelection: function ()
	{
		if(!this._selectFrame)
			return;

		if(this._selectFrameScale9)
		{
			var size = cc.size(this._cellSize.width, this._cellSize.height);
			size.width += this._cellSeparator.width;
			size.height += this._cellSeparator.height;

			this._selectFrame.setPreferredSize(size);
		}
		this._updateSelectionPosition();
	},

	_updateSelectionPosition: function()
	{
		if (this._selectedCell)
		{
			var dx = this._cellSize.width - this._selectFrame.getContentSize().width;
			var dy = this._cellSize.height - this._selectFrame.getContentSize().height;
			var pos = this._cellPosition(this._selectedCell.x, this._selectedCell.y);

			pos.x += dx / 2;
			pos.y -= dy / 2;
			this._selectFrame.setPosition(pos);
		}
	},

	setCellSize: function (cellSize)
	{
		pmui.TableView.prototype.setCellSize.call(this, cellSize);

		this._updateSelection();
	},

	setCellSeparator: function (cellSeparator)
	{
		pmui.TableView.prototype.setCellSize.call(this, cellSeparator);

		this._updateSelection();
	},

	/**
     * Selects cell by row and col.
     * @param {Number} row
     * @param {Number} col
     */
	selectCell: function (row, col)
	{
		for (var i = 0; i < this._placeholderRows.length; ++i)
		{
			if (this._placeholderRows[i].row === row)
				return;
		}

		this._selectCell(row, col);
	},

	_selectCell: function (row, col)
	{
		this._selectFrame.setVisible(true);
		this._selectedCell = cc.p(row, col);

		this._updateSelectionPosition();
	},

	/**
     * Returns selected cell position.
     * @returns {cc.Point}
     */
	getSelectedCell: function ()
	{
		return this._selectedCell;
	},

	/**
     * Removes selection from all items.
     */
	deselectAll: function ()
	{
		if(!this.canDeselectCell)
			return;

		this._selectFrame.setVisible(false);
		this._selectedCell = null;
	},

	_onSelectItem: function(sender)
	{
		var pos = this.cellAtPosition(sender.getPosition());

		if (!pos)
			return;

		for (var i = 0; i < this._placeholderRows.length; ++i)
		{
			if (this._placeholderRows[i].row === pos.y)
				return;
		}

		if (!this._selectedCell || this._selectedCell.x !== pos.x || this._selectedCell.y !== pos.y)
			this._selectCell(pos.x, pos.y);
		else if (this.canDeselectCell)
			this.deselectAll();

		if (this._callback)
			this._callback.call(this._target, this._selectedCell);
	},

	setCell: function (row, col, widget)
	{
		var result = pmui.TableView.prototype.setCell.call(this, row, col, widget);

		if(result)
			widget.setTouchEnabled(true);

		return result;
	},

	setInertiaScrollEnabled: function (flag)
	{
		this._scroll.setInertiaScrollEnabled(flag);
	},

	setBounceEnabled: function (flag)
	{
		this._scroll.setBounceEnabled(flag);
	}
});
