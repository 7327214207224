/**
 * This namespace contains functions for work with tutorial.
 * @namespace
 *
 */
pm.tutorialUtils =
{
	_tutorialObjects: {},
	_scenario: null,
	_processingTutorial: false,
	_overlay: null,
	_pointer: null,
	_currentStep: null,
	_currentWaitListener: null,
	_isEmulating: false,
	_emulateTouchTimer: null,
    _processStepTimer: null,
	_scene: null,

	_startPoint: null,
	_isTouchMoved: false,

	_emulatedTouch: null,
	_draggingObject: null,

	_showTutorial: false,
	_tutorialScale: 1,

	init: function()
	{},

	/**
     * Registers tutorial button with name
     * @param {String} name
     * @param {pmui.Layout | pmui.Button} object
     * @param {Boolean} [isDraggable = false]
     * @param {Boolean} [isDroppable = false]
     */
	registerTutorialObject: function(name, object, isDraggable, isDroppable)
	{
		if(object instanceof pmui.Layout || object instanceof pmui.Button)
		{
			this._tutorialObjects[name] = {
				object: object,
				isDraggable: isDraggable || false,
				isDroppable: isDroppable || false
			};
		}
		else
		{
			cc.warn("Can not register non-pmui tutorial object");
		}
	},

	/**
     * Gets tutorial button by name
     * @param {String} name
     * @returns {?{object : cc.Node, isDraggable: Boolean, isDroppable: Boolean}}
     */
	getTutorialObjectByName: function(name)
	{
		return this._tutorialObjects[name];
	},

	/**
     * Gets tutorial button by point
     * @param {cc.Point} point
     * @returns {?{object: cc.Node, name: String, isDraggable: Boolean, isDroppable: Boolean}}
     */
	getTutorialObjectByPoint: function(point)
	{
		for(var objName in this._tutorialObjects)
		{
			if(!this._tutorialObjects[objName].object.isVisible())
				continue;

			var rect = cc.rectApplyAffineTransform(
				this._tutorialObjects[objName].object.getBoundingBox(),
				this._tutorialObjects[objName].object.getParent().getNodeToWorldTransform()
			);

			if(cc.rectContainsPoint(rect, point))
			{
				return {
					object: this._tutorialObjects[objName].object,
					name: objName,
					isDraggable: this._tutorialObjects[objName].isDraggable,
					isDroppable: this._tutorialObjects[objName].isDroppable
				};
			}
		}

		return null;
	},

	/**
     * Is in tutorial mode
     * @returns {Boolean}
     */
	isProcessingTutorial: function()
	{
		return this._processingTutorial;
	},

	/**
     * Sets current tutorial scenario
     * @param {pm.data.TutorialScenario } scenario
     */
	setTutorialScenario: function(scenario)
	{
		this._scenario = scenario;
	},

	/**
     * Starts processing tutorial
     * @param {cc.Scene}  scene A scene where show tutorial.
     * @param {Object} tutorialData
     */
	startTutorial: function(scene, tutorialData)
	{
        if (tutorialData !== undefined)
		{
			this._showTutorial = tutorialData.show;
			this._tutorialScale = tutorialData.scale;
		}
		else
		{
			this._showTutorial = false;
			this._tutorialScale = 1;
		}

		this._processingTutorial = true;
		this._scenario.start();

		for(var objectName in this._tutorialObjects)
			this._tutorialObjects[objectName].object.lock();

		this._overlay = new ccui.Layout();
		this._overlay.setContentSize(pm.settings.getScreenSize());

		this._pointer = pm.spriteUtils.getInterfaceElementSprite("hand");
		this._pointer.setAnchorPoint(0.2, 1);
		this._pointer.setPosition(this._overlay.width / 2, this._overlay.height / 2);

		this._overlay.addChild(this._pointer);

		pm.runningSceneUtils.addChildWithOverlayOrder(this._overlay);

		var touchListener = cc.EventListener.create({
			event: cc.EventListener.TOUCH_ONE_BY_ONE,
			swallowTouches: false,
			onTouchBegan: this._touchBegan.bind(this),
			onTouchMoved: this._touchMoved.bind(this),
			onTouchEnded: this._touchEnded.bind(this)
		});

		cc.eventManager.addListener(touchListener, this._overlay);

		this._overlay.setScale(this._tutorialScale);
		this._overlay.setPosition(scene.getPosition());
		this._scene = scene;

		this._processNextStep();
	},

	/**
     * Starts editing of tutorial
     * @param {pm.data.TutorialScenario } scenario
     */
	editTutorial: function(scenario)
	{
		var runningScene = cc.director.getRunningScene();
		var editLayer = runningScene.getChildByTag(this.TUTORIAL_EDIT_LAYER_TAG);

		if (editLayer)
			editLayer.show();
		else
			runningScene.addChild(new TutorialEditLayer(scenario), 1000, this.TUTORIAL_EDIT_LAYER_TAG);
	},

	_dispatchTouchEvent: function(eventCode)
	{
		if(!cc.sys.isNative)
		{
			var touchEvent = new cc.EventTouch([this._emulatedTouch]);
			touchEvent._eventCode = eventCode;
			cc.eventManager.dispatchEvent(touchEvent);
		}
		else
		{
			pm.dispatchEventFromNativeTouch(eventCode);
		}
	},

	_createEmulatedTouch: function(id, x, y)
	{
		if(cc.sys.isNative)
		{
			var realPoint = cc.director.convertToUI(cc.p(x, y));
            pm.createNativeTouch(1, realPoint.x + 0.00001, realPoint.y + 0.00001); //strange correction for work convert on native
		}
		else
		{
			this._emulatedTouch = new cc.Touch(x, y, id);
		}
	},

	_setEmulatedTouchLocation: function(x, y)
	{
		if(cc.sys.isNative)
		{
			var realPoint = cc.director.convertToUI(cc.p(x, y));
			pm.setNativeTouchLocation(realPoint.x + 0.00001, realPoint.y + 0.00001); //strange correction for work convert on native
		}
		else
		{
			this._emulatedTouch.setTouchInfo(this._emulatedTouch.getID(), x, y);
		}
	},

	_emulateClick: function(pos, object)
	{
		if (this._overlay === null)
			return;

		this._isEmulating = true;

		this._createEmulatedTouch(1, pos.x, pos.y);

		this._dispatchTouchEvent(cc.EventTouch.EventCode.BEGAN);

		this._pointer.setSpriteFrame(pm.spriteUtils.getInterfaceElementFrame("handPress"));

		this._emulateTouchTimer = setTimeout(function ()
		{
			this._dispatchTouchEvent(cc.EventTouch.EventCode.ENDED);

			if(cc.sys.isNative)
				pm.destroyNativeTouch();

			if (this._pointer)
				this._pointer.setSpriteFrame(pm.spriteUtils.getInterfaceElementFrame("hand"));

			this._isEmulating = false;
		}.bind(this), this.TUTORIAL_DELAY);
	},

	_emulateMove: function(fromPos, toPos)
	{
		if (this._overlay === null)
			return;

		this._isEmulating = true;

		this._createEmulatedTouch(1, fromPos.x, fromPos.y);

		this._dispatchTouchEvent(cc.EventTouch.EventCode.BEGAN);

		this._pointer.setSpriteFrame(pm.spriteUtils.getInterfaceElementFrame("handPress"));

		var totalTimeOutTime = 600;
		var totalStepCount = 50;

		var timePerMove = totalTimeOutTime / totalStepCount;

		var dx = (toPos.x - fromPos.x) / totalStepCount;
		var dy = (toPos.y - fromPos.y) / totalStepCount;

		var pointerTransform = this._overlay.getWorldToNodeTransform();
		var pointerFrom = cc.pointApplyAffineTransform(fromPos, pointerTransform);
		var pointerTo = cc.pointApplyAffineTransform(toPos, pointerTransform);

		var pdx = (pointerTo.x - pointerFrom.x) / totalStepCount;
		var pdy = (pointerTo.y - pointerFrom.y) / totalStepCount;

		var stepCount = 1;

		function moveEvent()
		{
			if(stepCount <= totalStepCount)
			{
				this._setEmulatedTouchLocation(fromPos.x + stepCount * dx, fromPos.y + stepCount * dy);
				this._dispatchTouchEvent(cc.EventTouch.EventCode.MOVED);

				this._pointer.setPosition(this._pointer.x + pdx, this._pointer.y + pdy);
				++stepCount;

				this._emulateTouchTimer = setTimeout(moveEvent.bind(this), timePerMove);
			}
			else
			{
				this._pointer.setPosition(this._pointer.x + pdx, this._pointer.y + pdy);
				this._pointer.setSpriteFrame(pm.spriteUtils.getInterfaceElementFrame("hand"));

				this._setEmulatedTouchLocation(toPos.x, toPos.y);
				this._dispatchTouchEvent(cc.EventTouch.EventCode.ENDED);

				if(cc.sys.isNative)
					pm.destroyNativeTouch();

				this._isEmulating = false;
			}
		}

        this._emulateTouchTimer = setTimeout(moveEvent.bind(this), timePerMove);
	},

	restart: function()
	{
		this.stop();

		var tutorialData = {
			show: this._showTutorial,
			scale: this._tutorialScale
		};

		this.startTutorial(this._scene, tutorialData);
	},

	stop: function()
	{
		if (this._emulateTouchTimer)
			clearTimeout(this._emulateTouchTimer);

        if (this._processStepTimer)
            clearTimeout(this._processStepTimer);

		if (this._isEmulating)
		{
			this._dispatchTouchEvent(cc.EventTouch.EventCode.CANCELLED);

			if (cc.sys.isNative)
				pm.destroyNativeTouch();

			this._isEmulating = false;
		}

		if (this._pointer) {
            this._pointer.stopAllActions();
            this._pointer = null;
        }

		if (this._draggingObject)
		{
			this._draggingObject.stopAllActions();
			this._draggingObject.removeFromParent();
            this._draggingObject = null;
		}
		this._clear();
		pm.runningSceneUtils.cleanup();
	},

	_generateMoveAndClickAnimation: function(pos, emulate)
	{
		var func = cc.callFunc(function()
		{
			var animationFrames = [];
			animationFrames.push(pm.spriteUtils.getInterfaceElementFrame("handPress"));
			animationFrames.push(pm.spriteUtils.getInterfaceElementFrame("hand"));
			var animation = new cc.Animation(animationFrames, pm.SYSTEM_ANIMATION_DELAY * 2);

			this.runAction(cc.repeatForever(cc.animate(animation)));
		}, this._pointer);

		if(this._scenario.getCurrentStepIndex() > 0)
		{
			var move = cc.moveTo(pm.SYSTEM_ANIMATION_DELAY * 1.5, pos.x, pos.y);
			return (this._showTutorial || emulate) ? move : cc.sequence(move, func);
		}
		else
		{
			this._pointer.setPosition(pos);
			return (this._showTutorial || emulate) ? null : func;
		}
	},

	_generatePressAndMoveAnimation: function(fromPos, toPos, emulate)
	{
		var moveTo = cc.moveTo(pm.SYSTEM_ANIMATION_DELAY * 1.5, toPos);

		var animationFrames = [];
		animationFrames.push(pm.spriteUtils.getInterfaceElementFrame("hand"));
		animationFrames.push(pm.spriteUtils.getInterfaceElementFrame("handPress"));
		var animation = new cc.Animation(animationFrames, pm.SYSTEM_ANIMATION_DELAY * 2);
		var press = cc.animate(animation);
		var moveFrom = cc.moveTo(pm.SYSTEM_ANIMATION_DELAY * 0.5, fromPos);

		if(this._scenario.getCurrentStepIndex() > 0)
		{
			return (this._showTutorial || emulate) ?
				moveFrom :
				cc.repeatForever(cc.sequence(moveFrom, press, moveTo, press.reverse()));
		}
		else
		{
			this._pointer.setPosition(fromPos);
			return (this._showTutorial || emulate) ?
				null :
				cc.repeatForever(cc.sequence(press, moveTo, press.reverse(), moveFrom));
		}
	},

    _generateMoveAnimation: function(fromPos, toPos, emulate)
    {
        var moveTo = cc.moveTo(pm.SYSTEM_ANIMATION_DELAY * 1.5, toPos);
        var moveFrom = cc.moveTo(pm.SYSTEM_ANIMATION_DELAY * 0.5, fromPos);
        var delay = cc.delayTime(pm.SYSTEM_ANIMATION_DELAY * 2);
        var hide = cc.fadeTo(0, 0);
        var show = cc.fadeTo(0, 255);

        if (this._scenario.getCurrentStepIndex() > 0)
        {
            return (this._showTutorial || emulate) ?
				null :
				cc.repeatForever(cc.sequence(moveFrom, delay, show, delay, moveTo, delay, hide, delay));
        }
        else
        {
            if (this._draggingObject)
                this._draggingObject.setPosition(fromPos);

            return (this._showTutorial || emulate) ?
				null :
				cc.repeatForever(cc.sequence(delay, delay, moveTo, delay, hide, delay, moveFrom, show));
        }
    },

	processCurrentStep: function()
	{
		if(!this._currentStep || !this._overlay)
			return;

		if(this._pointer)
			this._pointer.stopAllActions();

		if (this._draggingObject)
		{
			this._draggingObject.stopAllActions();
			this._draggingObject.removeFromParent();
			this._draggingObject = null;
		}

		switch (this._currentStep.type)
		{
			case pm.TutorialStepType.CLICK_OBJECT:
				this._processClickStep(true);
				break;
			case pm.TutorialStepType.DRAG_OBJECT:
				this._processDragStep(true);
				break;
			case pm.TutorialStepType.MOVE_TO:
				this._processMoveToStep(true);
				break;
			case pm.TutorialStepType.CLICK_AREA:
				this._processClickAreaStep(true);
				break;
			case pm.TutorialStepType.WAIT_EVENT:
				//No need to do smth
				break;
		}
	},

	_handleProcessNextStep: function()
	{
		var nextStepIndex = this._scenario.getCurrentStepIndex() + 1;
        var nextStep = this._scenario.steps[nextStepIndex];

        if(this._showTutorial && nextStep && nextStep.type !== pm.TutorialStepType.WAIT_EVENT)
		{
			this._processStepTimer = setTimeout(function () {
				this._processNextStep();
			}.bind(this), this.TUTORIAL_SHOW_DELAY);
		}
		else
		{
            this._processStepTimer = null;
			this._processNextStep();
		}
	},

	_processNextStep: function()
	{
		if (!this._overlay)
			return;

		if(this._pointer)
			this._pointer.stopAllActions();

		if (this._draggingObject)
		{
			this._draggingObject.stopAllActions();
			this._draggingObject.removeFromParent();
			this._draggingObject = null;
		}

		this._currentStep = this._scenario.getNextStep();

		if(this._currentStep)
		{
            switch (this._currentStep.type)
			{
				case pm.TutorialStepType.CLICK_OBJECT:
					this._processClickStep();
					break;
				case pm.TutorialStepType.DRAG_OBJECT:
					this._processDragStep();
					break;
				case pm.TutorialStepType.MOVE_TO:
					this._processMoveToStep();
					break;
				case pm.TutorialStepType.CLICK_AREA:
					this._processClickAreaStep();
					break;
				case pm.TutorialStepType.WAIT_EVENT:
					this._pointer.setOpacity(0);
					this._currentWaitListener = pm.registerCustomEventListener(
						this._currentStep.data,
						this._processWaitEvent.bind(this),
						1
					);
					break;
			}
		}
		else
		{
			this._clear();
			pm.sendCustomEvent(pm.TUTORIAL_ENDED);
		}
	},

	_processClickStep: function(emulate)
	{
		if (emulate === undefined)
			emulate = false;

		var clickObject = this._tutorialObjects[this._currentStep.data].object;

		if (clickObject)
		{
			clickObject.unlock();
			var transform = clickObject.getParent().getNodeToWorldTransform();
			var pointerTransform = this._overlay.getWorldToNodeTransform();

			var objRect = cc.rectApplyAffineTransform(clickObject.getBoundingBox(), transform);
			var pointerRect = cc.rectApplyAffineTransform(objRect, pointerTransform);

			var action = this._generateMoveAndClickAnimation(
				cc.p(pointerRect.x + pointerRect.width / 2,
				pointerRect.y + pointerRect.height / 2),
				emulate
			);

			if (this._showTutorial || emulate)
			{
				var emulateFn = function() {
                    this._emulateClick(cc.p(objRect.x + objRect.width / 2, objRect.y + objRect.height / 2), clickObject);
                };

				if (action !== null)
                	this._pointer.runAction(cc.sequence(action, cc.callFunc(emulateFn, this)));
				else
                    emulateFn.call(this);
            }
            else if (action !== null)
            {
                this._pointer.runAction(action);
            }
		}
		else
		{
			this._handleProcessNextStep();
		}
	},

	_processDragStep: function(emulate)
	{
		if (emulate === undefined)
			emulate = false;

		var fromObject = this._tutorialObjects[this._currentStep.data.from].object;
		var toObject = this._tutorialObjects[this._currentStep.data.to].object;

		if(fromObject && toObject)
		{
			fromObject.unlock();

			var pointerTransform = this._overlay.getWorldToNodeTransform();
			var fromTransform = fromObject.getParent().getNodeToWorldTransform();
			var fromRect = cc.rectApplyAffineTransform(fromObject.getBoundingBox(), fromTransform);
			var pointerFromRect = cc.rectApplyAffineTransform(fromRect, pointerTransform);

			var toTransform = toObject.getParent().getNodeToWorldTransform();
			var toRect = cc.rectApplyAffineTransform(toObject.getBoundingBox(), toTransform);
			var pointerToRect = cc.rectApplyAffineTransform(toRect, pointerTransform);

			var action = this._generatePressAndMoveAnimation(
				cc.p(pointerFromRect.x + pointerFromRect.width / 2, pointerFromRect.y + pointerFromRect.height / 2),
				cc.p(pointerToRect.x + pointerToRect.width / 2, pointerToRect.y + pointerToRect.height / 2),
				emulate
			);

            if (this._showTutorial || emulate)
            {
                var emulateFn = function() {
                    this._emulateMove(
                        cc.p(fromRect.x + fromRect.width / 2, fromRect.y + fromRect.height / 2),
                        cc.p(toRect.x + toRect.width / 2, toRect.y + toRect.height / 2)
                    );
                };

                if (action !== null)
                    this._pointer.runAction(cc.sequence(action, cc.callFunc(emulateFn, this)));
                else
                    emulateFn.call(this);
            }
            else if (action !== null)
            {
                var draggingObjectAction = this._generateMoveAnimation(
                	cc.p(fromRect.x, fromRect.y),
					cc.p(toRect.x, toRect.y),
					emulate
				);

                if (draggingObjectAction !== null)
                {
                    this._draggingObject = fromObject.cloneImage();
                    this._draggingObject.setAnchorPoint(cc.p(0, 0));
                    this._draggingObject.setPosition(fromRect.x, fromRect.y);
                    this._overlay.addChild(this._draggingObject, -1);

                    this._draggingObject.runAction(draggingObjectAction);
                }

                this._pointer.runAction(action);
            }
		}
		else
		{
            this._handleProcessNextStep();
		}
	},

	_processMoveToStep: function(emulate)
	{
		if (emulate === undefined)
			emulate = false;

		var fromObject = this._tutorialObjects[this._currentStep.data.object].object;

		if(fromObject)
		{
			fromObject.unlock();

			var pointerTransform = this._overlay.getWorldToNodeTransform();
			var fromTransform = fromObject.getParent().getNodeToWorldTransform();
			var fromRect = cc.rectApplyAffineTransform(fromObject.getBoundingBox(), fromTransform);
			var pointerFromRect = cc.rectApplyAffineTransform(fromRect, pointerTransform);

			var fromPos = cc.p(fromRect.x, fromRect.y);
			var toPos = cc.pAdd(fromPos, this._currentStep.data.delta);

			var action = this._generatePressAndMoveAnimation(
				cc.p(pointerFromRect.x + pointerFromRect.width / 2, pointerFromRect.y + pointerFromRect.height / 2),
				cc.p(toPos.x - this._pointer.width / 2, toPos.y - this._pointer.height / 2),
				emulate
			);

            if (this._showTutorial || emulate)
            {
                var emulateFn = function() {
                    this._emulateMove(
                        cc.p(fromRect.x + fromRect.width / 2, fromRect.y + fromRect.height / 2),
                        cc.p(toPos.x - this._pointer.width / 2, toPos.y - this._pointer.height / 2)
                    );
                };

                if (action !== null)
                    this._pointer.runAction(cc.sequence(action, cc.callFunc(emulateFn, this)));
                else
                    emulateFn.call(this);
            }
            else if (action !== null)
            {
                var draggingObjectAction = this._generateMoveAnimation(
                	fromPos,
                    cc.p(
                    	toPos.x - (this._pointer.width + fromRect.width) / 2,
						toPos.y - (this._pointer.height + fromRect.height) / 2
					),
					emulate
				);

                if (draggingObjectAction !== null)
                {
                    this._draggingObject = fromObject.cloneImage();
                    this._draggingObject.setAnchorPoint(cc.p(0, 0));
                    this._draggingObject.setPosition(fromRect.x, fromRect.y);
                    this._overlay.addChild(this._draggingObject, -1);

                    this._draggingObject.runAction(draggingObjectAction);
                }

                this._pointer.runAction(action);
            }
		}
		else
		{
            this._handleProcessNextStep();
		}
	},

	_processClickAreaStep: function(emulate)
	{
		if (emulate === undefined)
			emulate = false;

		var object = this._tutorialObjects[this._currentStep.data.object].object;

		if(object)
		{
			object.unlock();

			var destRect = cc.rectApplyAffineTransform(this._currentStep.data.rect, object.getNodeToWorldTransform());
			var pos = cc.p(destRect.x + destRect.width / 2, destRect.y + destRect.height / 2);

			var action = this._generateMoveAndClickAnimation(pos);

            if (this._showTutorial || emulate)
            {
                var emulateFn = function() {
                    this._emulateClick(cc.p(pos.x - this._pointer.width / 2, pos.y - this._pointer.height / 2), object);
                };

                if (action !== null)
                    this._pointer.runAction(cc.sequence(action, cc.callFunc(emulateFn, this)));
                else
                    emulateFn.call(this);
            }
            else if (action !== null)
            {
                this._pointer.runAction(action);
            }
		}
		else
		{
			this._handleProcessNextStep();
		}
	},

	_processWaitEvent: function(event)
	{
		if(this._currentStep && this._currentStep.type === pm.TutorialStepType.WAIT_EVENT)
		{
			cc.eventManager.removeListener(this._currentWaitListener);
			this._pointer.setOpacity(255);
			this._processNextStep();
		}
	},

	/**
     * Called from game layers to indicate drop object.
     * @param {String} dropObjectName
     * @returns {Boolean}
     */
	onDropToObject: function(dropObjectName)
	{
		if(!this._processingTutorial)
			return true;

		if(this._currentStep.type === pm.TutorialStepType.DRAG_OBJECT)
		{
			if(dropObjectName === this._currentStep.data.to)
			{
				this._tutorialObjects[this._currentStep.data.from].object.unlock();

				this._handleProcessNextStep();

				return true;
			}
		}

		return false;
	},

	/**
     * Called from game layers to indicate drag cancelled.
     * @param {cc.Touch} cancelTouch
     * @returns {Boolean}
     */
	onDragCancelled: function(cancelTouch)
	{
		if(!this._processingTutorial)
			return true;

		if(this._currentStep.type === pm.TutorialStepType.MOVE_TO)
		{
			var fromObject = this._tutorialObjects[this._currentStep.data.object].object;
			var fromPos = fromObject.convertToWorldSpace(cc.p(0, 0));
			var toPos = cc.pAdd(fromPos, this._currentStep.data.delta);
			var radius = this._currentStep.data.radius;

			if(cc.pDistance(toPos, this._overlay.convertTouchToNodeSpace(cancelTouch)) < radius)
			{
				fromObject.lock();
				this._handleProcessNextStep();

				return true;
			}
		}

		return false;
	},

	clearTutorialObjects: function()
	{
		this._tutorialObjects = {};
	},

	reset: function()
	{
		this._clear();
		this._scenario = null;
		this._pointer = null;
		this._tutorialObjects = {};
	},

	_clear: function()
	{
		this._currentStep = null;
		this._processingTutorial = false;

		if(this._overlay && this._overlay.getParent())
			pm.runningSceneUtils.removeChild(this._overlay);

		this._overlay = null;

		for(var objectName in this._tutorialObjects)
			this._tutorialObjects[objectName].object.unlock();
	},

	_touchBegan: function(touch, event)
	{
		if (this._showTutorial)
			return true;

		this._startPoint = this._overlay.convertTouchToNodeSpace(touch);
		this._isTouchMoved = false;

		switch(this._currentStep.type)
		{
			case pm.TutorialStepType.CLICK_OBJECT:
				var object = this._tutorialObjects[this._currentStep.data].object;
				var touchPoint = object.getParent().convertTouchToNodeSpace(touch);
				var contains = cc.rectContainsPoint(object.getBoundingBox(), touchPoint);

				return contains;

			case pm.TutorialStepType.DRAG_OBJECT:
				var object = this._tutorialObjects[this._currentStep.data.from].object;
				return cc.rectContainsPoint(object.getBoundingBox(), object.getParent().convertTouchToNodeSpace(touch));

			case pm.TutorialStepType.MOVE_TO:
				var object = this._tutorialObjects[this._currentStep.data.object].object;
				return cc.rectContainsPoint(object.getBoundingBox(), object.getParent().convertTouchToNodeSpace(touch));

			case pm.TutorialStepType.CLICK_AREA:
				var object = this._tutorialObjects[this._currentStep.data.object].object;
				var touchPoint = object.convertTouchToNodeSpace(touch);
				var contains = cc.rectContainsPoint(this._currentStep.data.rect, touchPoint);

				return contains;
		}

		return false;
	},

	_touchMoved: function(touch)
	{
		if (this._showTutorial)
			return;

		var touchPoint = this._overlay.convertTouchToNodeSpace(touch);

		this._isTouchMoved = cc.pDistance(this._startPoint, touchPoint) > 5;
	},

	_touchEnded: function(touch)
	{
		// if (this._showTutorial)
		// 	return;

		switch(this._currentStep.type)
		{
			case pm.TutorialStepType.CLICK_OBJECT:

				var object = this._tutorialObjects[this._currentStep.data].object;
				var touchPoint = object.getParent().convertTouchToNodeSpace(touch);
				var isComplete = cc.rectContainsPoint(object.getBoundingBox(), touchPoint);

				if(isComplete)
				{
					object.lock();
					this._handleProcessNextStep();
				}

				break;
			case pm.TutorialStepType.CLICK_AREA:
                var object = this._tutorialObjects[this._currentStep.data.object].object;
                var touchPoint = object.convertTouchToNodeSpace(touch);
                var isComplete = cc.rectContainsPoint(this._currentStep.data.rect, touchPoint);

                if(isComplete)
                {
                    object.lock();
                    this._handleProcessNextStep();
                }
                break;
		}
	},

	/**
     * Enum for button names which can participate in tutorials
     * @enum {String}
     */
	OBJECT_NAMES: {
		START_PROGRAM: "start_pr",
		MAKE_STEP: "make_step",
		RESTART_PROGRAM: "restart_pr",
		START_ALL_PROGRAM: "start_al_pr",
		PREV_LEVEL: "prev_level",
		NEXT_LEVEL: "next_level",
		CHANGE_SPEED: "change_speed",
		MAX_SPEED: "max_speed",
		SETTINGS: "settings",
		BACK: "back",
		GLOBAL_HINT: "glob_hint",
		SHOW_MSL: "show_msl",
		HIDE_MSL: "hide_msl",
		CLEAR_MSL: "clear_msl",
		DRAG_STACK: "drag_stack",
		COUNTER_SHOW: "counter_show",
		COUNTER_HIDE: "counter_hide",
		GREEN_FLAG_SHOW_HIDE: "green_flag_show_hide",
		ORANGE_FLAG_SHOW_HIDE: "orange_flag_show_hide"
	},

	/**
     * Enum for button name patterns(multiple same buttons) which can participate in tutorials
     * @enum {String}
     */
	OBJECT_NAME_PATTERNS: {
		STAR: "star:{0}",
		SELECT: "select:{0}",
		REPEATER: "repeater:{0}_{1}",
		CONDITION: "condition:{0}_{1}",
		METHOD: "method:{0}_{1}",
		RESIZE_BLOCK: "res_block:{0}"
	},

	/**
     * Tag of tutorial edit layer
     * @type {Number}
     */
	TUTORIAL_EDIT_LAYER_TAG: 1001,
	TUTORIAL_SHOW_DELAY: 1000,
	TUTORIAL_DELAY: 500
};
