/**
 * @class This class processes and executes functions for robots in Piktomir-K.
 * @extends cc.Class
 * @property {pm.RobotManager.State} state
 */
pm.RobotManagerK = pm.RobotManager.extend(/** @lends pm.RobotManagerK# */{

	ctor:function ()
	{
		this._super();
	},

	_processRobotProgram: function(robot, program)
	{
		if (program.instructions.length === 0)
			return;

		var instr = program.instructions[program.instrPosition];

		switch (instr.command)
		{
			case pm.Instruction.EXECUTE:
			{
				var highlightEvent = pm.HIGHLIGHT_METHOD_EVENT_STR;
				if (this._state === pm.RobotManager.State.ProcessingForceFunction)
					this.states.push(this._level.getState());

				if (instr.data.isNative)
				{
					var params = [];
					for (var i = 0; i < instr.data.paramCount; i++)
					{
						params.push(program.stack.pop());
					}
					var res = instr.data.robot.executeNativeFunction(instr.data.methodID, params);

					if (res !== null && res !== undefined)
						program.stack.push(res);

					++program.instrPosition;
					if (robot.isBroken())
						highlightEvent = pm.HIGHLIGHT_BROKEN_METHOD_EVENT_STR;
				}
				else
				{
					if (program.labels[instr.data.methodID] !== null)
					{
						var params = [];
						for (var i = 0; i < instr.data.paramCount; i++)
						{
							params.push(program.stack.pop());
						}
						program.stack.push(program.instrPosition + 1);
						program.register.push(program.stack.length);

						for (var i = 0; i < instr.data.paramCount; i++)
						{
							program.argRegister.push(params[i]);
						}
						program.instrPosition = program.labels[instr.data.methodID];
						if (instr.data.methodID === pm.CMD_MAIN)
						{
							++program.instrPosition;
						}
					}
				}

				if (this.state === pm.RobotManager.State.ProcessingForceFunction)
				{
					++this._executedStepForceInstrCount[this._executedStepForceInstrCount.length - 1];
					for (var i = 0; i < this._level.robots.length; ++i)
					{
						var robot = this._level.robots[i];
						this.states[this.states.length - 1].robotStates[i].animation = robot.getLastAnimation();
					}
				}
				if (!CORE_BUILD)
				{
					if(instr.data.methodID !== pm.CMD_MAIN) {
						program.paused = true;
						if (this._state === pm.RobotManager.State.SteppedWorking)
							this._updateVisualState();
						pm.sendCustomEvent(pm.REM_LAST_HIGHLIGHT_EVENT_STR, {robotIndex: robot.id});
						pm.sendCustomEvent(highlightEvent, {
							robotIndex: robot.id,
							nodeView: instr.data.nodeView
						});
					}
				}
				break;
			}
			case pm.Instruction.START_LOOP:
			{
				var repeater = program.stack.pop();
				var iterator = program.stack.pop();
				pm.variableList.setValue(instr.data.repeaterTag, repeater);
				pm.variableList.setValue(instr.data.iteratorTag, iterator);

				if (instr.data.stepTag !== undefined)
					pm.variableList.setValue(instr.data.stepTag, program.stack.pop());

				if (instr.data.robot)
				{
					if (repeater === 0 || iterator
						> repeater)
					{
						program.instrPosition = program.labels[instr.data.blockEndLabel];
						return;
					}
				}
				program.stack.push(iterator);
				program.stack.push(repeater);
				program.stack.push(iterator);
				++program.instrPosition;

				if(!CORE_BUILD)
				{
					pm.sendCustomEvent(pm.HIGHLIGHT_POINT_IN_REP_EVENT, {
						iterNum: iterator,
						nodeView: instr.data.nodeView,
						robotIndex: robot.id,
						rep: repeater
					});
				}
				break;
			}
			case pm.Instruction.END_LOOP:
			{
				var step = 1;
				if (instr.data.stepTag !== undefined)
					step = pm.variableList.getValue(instr.data.stepTag);
				var repeater = program.stack.pop();
				var iterator = program.stack.pop() + step;

				if (iterator === pm.MAX_REPEATER)
				{
					robot.setStateFlag(pm.RobotState.EndedWork);
					program.ended = true;
					return;
				}


				if (iterator <= repeater)
				{
					program.instrPosition = program.labels[instr.data.jumpLabel];
					program.stack.push(iterator);
					program.stack.push(repeater);
					program.stack.push(iterator);
					if(!CORE_BUILD)
					{
						pm.sendCustomEvent(pm.HIGHLIGHT_POINT_IN_REP_EVENT, {
							iterNum: iterator,
							nodeView: instr.data.nodeView,
							robotIndex: robot.id,
							rep: repeater
						});
					}
				}
				else
				{
					if(!CORE_BUILD)
					{
						pm.sendCustomEvent(pm.HIGHLIGHT_POINT_IN_REP_EVENT, {
							iterNum: iterator,
							nodeView: instr.data.nodeView,
							robotIndex: robot.id,
							rep: repeater
						});
					}
					++program.instrPosition;
				}

				break;
			}
			case pm.Instruction.CHECK_CONDITION:
			{
				if (instr.data.isNative)
				{
					program.stack.push(instr.data.robot.checkCondition(instr.data.condition, instr.data.condArgs));
					++program.instrPosition;
				}
				if (!CORE_BUILD)
				{
					pm.sendCustomEvent(pm.REM_LAST_HIGHLIGHT_EVENT_STR, {robotIndex: robot.id});

					pm.sendCustomEvent(pm.HIGHLIGHT_METHOD_EVENT_STR, {
						robotIndex: robot.id,
						nodeView: instr.data.nodeView
					});
					program.paused = true;

					if (this._state === pm.RobotManager.State.SteppedWorking)
						this._updateVisualState();
				}
				break;
			}
			case pm.Instruction.JUMP_IF:
			{
				if (program.stack[program.stack.length-1])
					program.instrPosition = program.labels[instr.data.jumpLabel];
				else
					++program.instrPosition;
				break;
			}
			case pm.Instruction.JUMP_NIF:
			{
				if (!program.stack[program.stack.length-1])
					program.instrPosition = program.labels[instr.data.jumpLabel];
				else
					++program.instrPosition;
				break;
			}
			case pm.Instruction.JUMP:
			{
				program.instrPosition = program.labels[instr.data.jumpLabel];
				break;
			}
			/**
			 * instr.data.paramQuantity - number of return vals
			 */
			case pm.Instruction.RETURN:
			{
				if (!CORE_BUILD)
					pm.sendCustomEvent(pm.REM_LAST_HIGHLIGHT_EVENT_STR, {robotIndex: robot.id});

				var res = [];
				for (var i = 0; i < instr.data.paramQuantity; i++)
				{
					res.push(program.stack.pop());
				}
				while (program.stack.length > program.register[program.register.length - 1])
				{
					program.stack.pop();
				}
				program.register.pop();
				for (var i = 0; i < instr.data.paramQuantity; i++)
				{
					program.argRegister.push(res[i]);
				}

				if (program.register.length === 0)
				{
					program.ended = true;
					return;
				}
				program.instrPosition = program.stack.pop();
				break;
			}

			/**
			 * instr.data.varName - name of allocated variable
			 * instr.data.varType - type of variable
			 * instr.data.value - value of variable
			 */
			case pm.Instruction.ALLOC:
			{
				program.paused = true;
				if (!CORE_BUILD)
				{
					pm.sendCustomEvent(pm.REM_LAST_HIGHLIGHT_EVENT_STR, {robotIndex: robot.id});

					pm.sendCustomEvent(pm.HIGHLIGHT_METHOD_EVENT_STR, {
						robotIndex: robot.id,
						nodeView: instr.data.nodeView

					});
				}
				if (this._state === pm.RobotManager.State.SteppedWorking)
					this._updateVisualState();
				if (program.register.length === 0)
				{
					program.memory[instr.data.varName] = {
						type: instr.data.varType,
						val: instr.data.value
					};
				}
				else
				{
					program.stack.push({
						type: instr.data.varType,
						name: instr.data.varName,
						val: instr.data.value
					});
				}
				++program.instrPosition;
				break;
			}
			/**
			 * instr.data.varName - name of allocated variable
			 * instr.data.fromReg - save values from register
			 */
			case pm.Instruction.STORE:
			{
				var val = null;
				var saved = false;

				if (instr.data.fromReg)
				{
					val = program.argRegister.pop();
				}
				else
				{
					val = program.stack.pop();
				}
				if (!CORE_BUILD)
				{
					if (instr.data.nodeView)
					{
						pm.sendCustomEvent(pm.REM_LAST_HIGHLIGHT_EVENT_STR, {robotIndex: robot.id});
						pm.sendCustomEvent(pm.HIGHLIGHT_METHOD_EVENT_STR, {
							robotIndex: robot.id,
							nodeView: instr.data.nodeView

						});
						program.paused = true;

						if (this._state === pm.RobotManager.State.SteppedWorking)
							this._updateVisualState();

						pm.sendCustomEvent(pm.VARIABLE_VALUE_CHANGED, {name: instr.data.varName, val: val});
					}
				}


				if (program.register.length > 0)
					for (var i = program.register[program.register.length - 1]; i < program.stack.length; i++)
					{
						if (program.stack[i].name !== undefined && program.stack[i].name === instr.data.varName)
						{
							program.stack[i].val = val;
							saved = true;
							break;
						}
					}
				if (!saved)
				{
					program.memory[instr.data.varName].val = val;
				}
				++program.instrPosition;
				break;
			}
			/**
			 * instr.data.value - value to push
			 */

			case pm.Instruction.PUSH:
			{
				program.stack.push(instr.data.value);
				++program.instrPosition;
				break;
			}

			case pm.Instruction.POP:
			{
				program.stack.pop();
				++program.instrPosition;
				break;
			}
			/**
			 * instr.data.varName - name of variable
			 */
			case pm.Instruction.FETCH:
			{
				var got = false;
				if (program.memory[instr.data.varName])
				{
					program.stack.push(program.memory[instr.data.varName].val);
					got = true;
				}
				else
				{
					for (var i = program.register[program.register.length - 1]; i < program.stack.length; i++)
					{
						if (program.stack[i].name === instr.data.varName)
						{
							program.stack.push(program.stack[i].val);
							got = true;
							break;
						}
					}
				}
				++program.instrPosition;
				break;
			}
			/**
			 * instr.data.operation - name of operation
			 */
			case pm.Instruction.CALC:
			{
				var res = 0;

				if (!CORE_BUILD)
				{
					program.paused = true;
					pm.sendCustomEvent(pm.REM_LAST_HIGHLIGHT_EVENT_STR, {robotIndex: robot.id});
					pm.sendCustomEvent(pm.HIGHLIGHT_METHOD_EVENT_STR, {
						robotIndex: robot.id,
						nodeView: instr.data.nodeView

					});
					if (this._state === pm.RobotManager.State.SteppedWorking)
						this._updateVisualState();
				}
				switch (instr.data.operation)
				{
					case "sqrt":
						res = Math.sqrt(program.stack.pop());
						break;

					case "abs":
						res = Math.abs(program.stack.pop());
						break;

					case "sign":
						res = Math.sign(program.stack.pop());
						break;

					case "random":
						res = Math.random(program.stack.pop());
						break;

					case "min":
						res = Math.min(program.stack.pop(), program.stack.pop());
						break;

					case "max":
						res = Math.max(program.stack.pop(), program.stack.pop());
						break;

					case pm.ArythmMethods.Addition:
						res = program.stack.pop() + program.stack.pop();
						break;

					case pm.ArythmMethods.Substraction:
						res = program.stack.pop() - program.stack.pop();
						break;

					case pm.ArythmMethods.Multiplication:
						res = program.stack.pop() * program.stack.pop();
						break;

					case pm.ArythmMethods.Division:
						var op2 = program.stack.pop()
						res = op2 / program.stack.pop();
						break;

					case pm.ArythmConditions.LeftLRight:
						res = program.stack.pop() < program.stack.pop();
						break;

					case pm.ArythmConditions.RightLLeft:
						res = program.stack.pop() > program.stack.pop();
						break;

					case pm.ArythmConditions.Equal:
						res = program.stack.pop() === program.stack.pop();
						break;

					case pm.ArythmConditions.NotEqual:
						res = program.stack.pop() !== program.stack.pop();
						break;

					case pm.ArythmConditions.RightEqual:
						res = program.stack.pop() >= program.stack.pop();
						break;

					case pm.ArythmConditions.LeftEqual:
						res = program.stack.pop() <= program.stack.pop();
						break;

					case pm.BasicLogic.And:
						res = (program.stack.pop() && program.stack.pop());
						break;

					case pm.BasicLogic.Or:
						res = (program.stack.pop() || program.stack.pop())
						break;

					case pm.NotFunction.Not:
						res = !(program.stack.pop());
						break;
				}
				program.stack.push(res);
				++program.instrPosition;
				break;
			}
		}
	}

});
