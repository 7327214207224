/**
 * Created by Nikita Besshaposhnikov on 20.11.14.
 */
/**
 * This callback is used by combobox on show and close.
 * @callback pmui.ComboBox~callback
 */

/**
 * This callback is used by combobox on select object.
 * @callback pmui.ComboBox~selectCallback
 * @param {Number} index Index f selected element.
 */

/**
 * @class pmui.ComboBox is a drop-down list of strings with select possibility.
 * @extends ccui.Layout
 * @constructor
 * @param {Array<String>} values List of string value to init combobox.
 * @param {Object} target Target for callbacks.
 * @param {pmui.ComboBox~selectCallback} onSelect On select callback.
 * @param {pmui.ComboBox~callback} [onClose] On close callback.
 * @param {pmui.ComboBox~callback} [onShow] On show callback.
 */
pmui.ComboBox = ccui.Layout.extend(/** @lends pmui.ComboBox#*/{
	_onShow: null,
	_onSelect: null,
	_onClose: null,

	_target: null,

	_dropDownList: null,
	_selectedLabel: null,
	_selectedObject: 0,
	_backgroundSprite: null,
	_fontSize: null,
	_extraBorder: null,

	_objects: [],
	_objectsHeight: 0,

	_startPoint: cc.p(),

	_maxDropDownViewHeight: 0,

	_height: 0,

	_itemsSeparator: 0,

	ctor: function(values, target, onSelect, fontSize, extraBorder, onShow, onClose)
	{
		this._onShow = onShow;
		this._onSelect = onSelect;
		this._onClose = onClose;
		this._target = target;
		this._fontSize = fontSize;

		if (extraBorder === undefined)
			extraBorder = 0;

		this._extraBorder = extraBorder;

		this._height = pmui.ComboBox.DEFAULT_HEIGHT;
		this._itemsSeparator = pmui.ComboBox.SEPARATOR;

		if (this._extraBorder !== 0)
		{
			this._height += 40;
			this._itemsSeparator /= 2;
		}

		this._super();

		this.setValueList(values);

		var listener = cc.EventListener.create({
			event: cc.EventListener.TOUCH_ONE_BY_ONE,
			swallowTouches: true,
			onTouchBegan: this._touchBegan.bind(this),
			onTouchMoved: this._touchMoved.bind(this),
			onTouchEnded: this._touchEnded.bind(this)
		});

		cc.eventManager.addListener(listener, this);
	},

	/**
     *
     * @param {Array<String>} values List of string values to set in combobox.
     */
	setValueList: function(values)
	{
		this.removeAllChildren(true);

		this._selectedObject = 0;
		this._objects = [];

		var width = 0, maxHeight = 0;

		for(var i = 0; i < values.length; ++i)
		{
			var string = new ccui.Text(values[i], pm.settings.fontBoldName, this._fontSize);
			string.setTextColor(cc.color(41, 171, 226));

			this._objects.push(string);

			if(this._objects[i].getContentSize().width > width)
				width = this._objects[i].getContentSize().width;

			if(this._objects[i].getContentSize().height > maxHeight)
				maxHeight = this._objects[i].getContentSize().height;
		}

		if(values.length === 0)
		{
			width = pmui.ComboBox.DEFAULT_WIDTH;
			maxHeight = this._height;
		}

		var buttonSprite = new cc.Sprite(pm.spriteUtils.getInterfaceElementFrame("comboboxArrow"));

		if (this._extraBorder === 0)
			buttonSprite.setScale(0.7);

		width += this._extraBorder;

		if (this._extraBorder === 0)
			width += buttonSprite.width + 20;

		this._objectsHeight = maxHeight + this._itemsSeparator;

		buttonSprite.setAnchorPoint(cc.p(0, 0));

		if (this._extraBorder !== 0)
			buttonSprite.setPosition(cc.p(width - pmui.ComboBox.BOUND_X - buttonSprite.width - 10, (this._height + 2 * pmui.ComboBox.BOUND_Y - buttonSprite.height)/2));
		else
			buttonSprite.setPosition(cc.p(width - buttonSprite.width, (this._height + 2 * pmui.ComboBox.BOUND_Y - buttonSprite.height)/2 + 5));

		this.addChild(buttonSprite, 10);

		if(values.length > 0)
			this._selectedLabel = new ccui.Text(values[0], pm.settings.fontBoldName, this._fontSize);
		else
			this._selectedLabel = new ccui.Text(LocalizedString("ComboboxNoValue"), pm.settings.fontBoldName, this._fontSize);

		this._selectedLabel.setTextColor(cc.color(63, 63, 150));

		if (this._extraBorder !== 0)
			this._selectedLabel.setPosition(cc.p(pmui.ComboBox.BOUND_X + this._extraBorder/2 - 20, pmui.ComboBox.BOUND_Y + (this._height-this._selectedLabel.height)/2));
		else
			this._selectedLabel.setPosition(cc.p(pmui.ComboBox.BOUND_X, pmui.ComboBox.BOUND_Y + (this._height-this._selectedLabel.height)/2));

		this._selectedLabel.setAnchorPoint(cc.p(0, 0));

		this.addChild(this._selectedLabel, 5);

		if (this._extraBorder !== 0)
			this._backgroundSprite = new cc.Scale9Sprite(pm.spriteUtils.getInterfaceElementFrame("comboboxBack2"));
		else
			this._backgroundSprite = new cc.Scale9Sprite(pm.spriteUtils.getInterfaceElementFrame("comboboxBack"));

		this._backgroundSprite.setContentSize(cc.size(width, this._height + 2 * pmui.ComboBox.BOUND_Y));
		this._backgroundSprite.setAnchorPoint( cc.p(0, 1) );
		this._backgroundSprite.setPosition( cc.p(0, this._backgroundSprite.height) );

		if (this._extraBorder !== 0)
			this._backgroundSprite.setCapInsets(cc.rect(45, 45, 45, 45));
		else
			this._backgroundSprite.setCapInsets(cc.rect(25, 25, 25, 25));

		this.addChild(this._backgroundSprite, -2);

		this.setContentSize(this._backgroundSprite.getContentSize().width, this._height + 2 * pmui.ComboBox.BOUND_Y);

		this._dropDownList = new ccui.ListView();

		this._dropDownList.setPropagateTouchEvents(false);
		this._dropDownList.setBounceEnabled(true);
		this._dropDownList.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._dropDownList.setScrollBarEnabled(false);
		this._dropDownList.setItemsMargin(this._itemsSeparator);
		this._dropDownList.addEventListener(this._onSelectObject.bind(this));

		for(var i = 0; i < values.length; ++i)
		{
			this._objects[i].setTouchEnabled(true);
			this._objects[i].setColor(cc.color.WHITE);
			this._dropDownList.pushBackCustomItem(this._objects[i]);
		}

		var dropDownHeight = Math.min(this._objectsHeight * (values.length) - this._itemsSeparator, pm.settings.getScreenSize().height * 0.7);

		this._dropDownList.setContentSize(this._backgroundSprite.getContentSize().width - 2 * pmui.ComboBox.SCROLL_BORDER, dropDownHeight);
		this._dropDownList.setAnchorPoint(cc.p(0, 0));

		if (this._extraBorder !== 0)
			this._dropDownList.setPosition(this._selectedLabel.getPosition().x, -dropDownHeight + pmui.ComboBox.SCROLL_BORDER);
		else
			this._dropDownList.setPosition(this._selectedLabel.getPosition().x, -dropDownHeight + pmui.ComboBox.SCROLL_BORDER/2);

		this.addChild(this._dropDownList, -1);

		this._updateDropDownViewSize();

		this._dropDownList.setVisible(false);
	},

	getValueList: function()
	{
		return this._objects;
	},

	_updateDropDownViewSize: function ()
	{
		var labelHeight = this._selectedLabel.getContentSize().height;

		var preferredScrollSize = this._maxDropDownViewHeight - labelHeight;

		if(this._maxDropDownViewHeight !== 0 && this._dropDownList.height > preferredScrollSize)
		{
			this._dropDownList.setContentSize(this._dropDownList.width, preferredScrollSize);
			this._dropDownList.setPosition(0, -preferredScrollSize);
		}
	},

	/**
     * Sets maximum view height in drop down state(with selected object element).</br>
     * If height === 0 then there is no limits.
     * @param {Number} height
     */
	setMaximumDropDownViewHeight: function(height)
	{
		this._maxDropDownViewHeight = height;
		this._updateDropDownViewSize();
	},

	/**
     * Returns size of drop-down part.
     * @returns {cc.Size}
     */
	getDropdownSize: function()
	{
		return cc.size(this._dropDownList.getContentSize().width + 2*pmui.ComboBox.SCROLL_BORDER,
			this._dropDownList.getContentSize().height + 2*pmui.ComboBox.SCROLL_BORDER);
	},

	/**
     * Selects object by index.
     * @param {Number} index
     */
	selectObject: function(index)
	{
		this._selectedObject = index;

		if(index < this._objects.length)
			this._selectedLabel.setString(this._objects[this._selectedObject].getString());
	},

	/**
     * Returns string value in list of selected object.
     * @returns {?String}
     */
	getSelectedObjectValue: function()
	{
		if(this._selectedObject < this._objects.length)
			return this._objects[this._selectedObject].getString();
		else
			return null;
	},

	/**
     * Returns selected object index.
     * @returns {Number}
     */
	getSelectedIndex: function()
	{
		return this._selectedObject;
	},

	_onSelectObject: function(sender, event)
	{
		if(event === ccui.ListView.ON_SELECTED_ITEM_END)
		{
			var selectedObject = this._dropDownList.getCurSelectedIndex();

			if(this._selectedObject !== selectedObject)
			{
				this._selectedObject = selectedObject;

				this._selectedLabel.setString(this._objects[this._selectedObject].getString());

				this._onSelect.call(this._target, this._selectedObject);
			}
			else if(this._onClose)
			{
				this._onClose.call(this._target);
			}

			var width = this._backgroundSprite.width;
			var height = this._backgroundSprite.height - this._dropDownList.height - pmui.ComboBox.SCROLL_BORDER;

			this._backgroundSprite.setContentSize(width, height);

			this._backgroundSprite.setPosition( cc.p(0, this.height) );

			this._dropDownList.setVisible(false);
		}
	},

	_touchBegan: function(touch, event)
	{
		if(!this.enabled)
			return false;

		if(!this.isClippingParentContainsPoint(touch.getLocation()))
			return false;

		if(!this._dropDownList.isVisible())
		{
			var touchPoint = this.getParent().convertTouchToNodeSpace(touch);

			return cc.rectContainsPoint(this.getBoundingBox(), touchPoint);
		}
		else
		{
			var touchPoint = this.convertTouchToNodeSpace(touch);

			return !cc.rectContainsPoint(this._dropDownList.getBoundingBox(), touchPoint);
		}

		return false;
	},

	_touchMoved: function(touch, event) {},

	_touchEnded: function()
	{
		if(!this._dropDownList.isVisible())
		{
			var width = this._backgroundSprite.width;
			var height = this._backgroundSprite.height + this._dropDownList.height + pmui.ComboBox.SCROLL_BORDER;

			this._backgroundSprite.setContentSize(width, height);

			this._backgroundSprite.setPosition( cc.p(0, this.height) );

			this._dropDownList.setVisible(true);

			if(this._onShow)
				this._onShow.call(this._target);
		}
		else
		{
			var width = this._backgroundSprite.width;
			var height = this._backgroundSprite.height - this._dropDownList.height - pmui.ComboBox.SCROLL_BORDER;

			this._backgroundSprite.setContentSize(width, height);

			this._backgroundSprite.setPosition( cc.p(0, this.height) );

			this._dropDownList.setVisible(false);

			if(this._onClose)
				this._onClose.call(this._target);
		}
	}
});

/**
 * Bound of text in {@link pmui.ComboBox} by x.
 * @const
 * @default
 * @type {Number}
 */
pmui.ComboBox.BOUND_X = 10.0;
/**
 * Bound of text in {@link pmui.ComboBox} by y.
 * @const
 * @default
 * @type {Number}
 */
pmui.ComboBox.BOUND_Y = 5.0;
/**
 * Separator between objects in drop down of {@link pmui.ComboBox}.
 * @const
 * @default
 * @type {Number}
 */
pmui.ComboBox.SEPARATOR = 10.0;
/**
 * Default width of {@link pmui.ComboBox}.
 * @const
 * @default
 * @type {Number}
 */
pmui.ComboBox.DEFAULT_WIDTH = 130.0;
/**
 * Default height of {@link pmui.ComboBox}.
 * @const
 * @default
 * @type {Number}
 */
pmui.ComboBox.DEFAULT_HEIGHT = 40.0;

pmui.ComboBox.SCROLL_BORDER = 10;
