/**
 * This namespace contains functions used by {@link pm.RobotManager} to store variables.
 * @namespace
 */

pm.variableList = {

	_variables: [],
	_reservedTagsNum: 0,

	/**
     * Adds variable to namespace. Returns tag of variable.
     * @param {Boolean} reserved Is variable reserved by robot.
     * @returns {Number}
     */
	addVariable: function (reserved)
	{
		this._variables.push(0);

		if (reserved)
			++this._reservedTagsNum;

		return this._variables.length - 1;
	},

	/**
     * Sets variable value by tag.
     * @param {Number} tag
     * @param {*} value
     */
	setValue: function (tag, value)
	{
		this._variables[tag] = value;
	},

	/**
     * Returns variable value by tag.
     * @param {Number} tag
     * @returns {*}
     */
	getValue: function (tag)
	{
		return this._variables[tag];
	},

	/**
     * Clears all list of variables.
     */
	clearList: function ()
	{
		this._reservedTagsNum = 0;
		this._variables = [];
	},

	/**
     * Clears only reserved variables.
     */
	clearNotReservedList: function ()
	{
		for (var i = this._reservedTagsNum; i < this._variables.length; ++i)
			this._variables.pop();
	}
};
