/**
 * Created by danilaeremin on 17.04.15.
 */

/**
 * @class Layout for select type of new level in editor.
 * @extends pmui.OvalLayout
 * @constructor
 * @param {cc.Node} object Copy of MenuEditorLayer.
 */
var SelectLevelTypeLayer = pmui.OvalLayout.extend(/** @lends SelectLevelTypeLayer# */{
	_selectLevelTypeInput: null,

	target: null,

	levelTypesView: null,

	_selectedLevelType: 0,

	ctor: function(target)
	{
		this._super(null, null, pmui.OvalLayout.Type.SLIM);

		this.setLayoutType(ccui.Layout.RELATIVE);

		this.target = target;

		this.setContentSizeWithBorder(SelectLevelTypeLayer.WIDTH, SelectLevelTypeLayer.HEIGHT);

		var loadingLayer = new LoadingLayer(false);
		loadingLayer.setPosition((this.width - pm.settings.getScreenSize().width) / 2,
			(this.height - pm.settings.getScreenSize().height) / 2);

		this.addChild(loadingLayer, -100);

		var label = new ccui.Text(LocalizedString("SelectLevelType"), pm.settings.fontName, pm.settings.fontSize);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		labelAlign.setMargin(0, pmui.OvalLayout.STANDARD_BORDER_RADIUS, 0, 0);
		labelAlign.setRelativeName("label");
		label.setLayoutParameter(labelAlign);

		this.addChild(label, 0);

		this.levelTypesView = [];
		var levelTypes = pm.moduleUtils.getLevelNames();

		for (var levelType in levelTypes)
		{
			if (!levelTypes.hasOwnProperty(levelType))
				continue;
			this.levelTypesView.push(LocalizedString(levelTypes[levelType]));
		}

		this._selectLevelTypeInput = new pmui.ComboBox(this.levelTypesView, this, this._selectLevelTypeOnSelect, 20);

		var selectLevelTypeInputAlign = new ccui.RelativeLayoutParameter();
		selectLevelTypeInputAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		selectLevelTypeInputAlign.setMargin(0, SelectLevelTypeLayer.SEPARATOR, 0, 0);
		selectLevelTypeInputAlign.setRelativeToWidgetName("label");
		selectLevelTypeInputAlign.setRelativeName("combobox");
		this._selectLevelTypeInput.setLayoutParameter(selectLevelTypeInputAlign);

		this.addChild(this._selectLevelTypeInput, 2);

		var buttonLayout = new ccui.HBox();

		var buttonLayoutAlign = new ccui.RelativeLayoutParameter();
		buttonLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		buttonLayoutAlign.setRelativeToWidgetName("combobox");
		buttonLayoutAlign.setMargin(0, SelectLevelTypeLayer.SEPARATOR, 0, 0);
		buttonLayout.setLayoutParameter(buttonLayoutAlign);

		this.addChild(buttonLayout, 0);

		var enter = new pmui.InterfaceButton(this._selectLevelTypeButtonAction, this, LocalizedString("CreateButton"));
		buttonLayout.addChild(enter);

		var cancel = new pmui.InterfaceButton(this._cancelCreate, this, LocalizedString("CancelButton"));
		var cancelMargin = new ccui.LinearLayoutParameter();
		cancelMargin.setMargin(SelectLevelTypeLayer.SEPARATOR, 0, 0, 0);
		cancel.setLayoutParameter(cancelMargin);

		buttonLayout.addChild(cancel);

		buttonLayout.setContentSize(enter.getContentSize().width + cancel.getContentSize().width + SelectLevelTypeLayer.SEPARATOR,
			enter.getContentSize().height);
	},

	_selectLevelTypeOnSelect: function(index)
	{
		this._selectedLevelType = index;
	},

	_selectLevelTypeButtonAction: function(sender)
	{
		var levelTypes = pm.moduleUtils.getLevelTypes();
		this.target._addLevelWithType(levelTypes[this._selectedLevelType]);
	},

	_cancelCreate: function(sender)
	{
		this.target._cancelAddLevelWithType();
	}
});

/**
 * @default
 * @const
 * @type {Number}
 */
SelectLevelTypeLayer.WIDTH = 350.0;
/**
 * @default
 * @const
 * @type {Number}
 */
SelectLevelTypeLayer.HEIGHT = 150.0;
/**
 * @default
 * @const
 * @type {Number}
 */
SelectLevelTypeLayer.SEPARATOR = 7.0;
