/**
 * Created by Nikita Besshaposhnikov on 05.11.15.
 */

/**
 * Layer that contains global robots and it's functions/conditions/repeaters
 * @extends ccui.Layout
 * @constructor
 * @param {pm.AbstractLevel} level For what level construct.
 */
var GlobalRobotsLayer = ccui.Layout.extend({

	_level: null,

	_flagContainer: null,

	ctor: function(level)
	{
		this._super();
		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setContentSize(pm.settings.getMapSize());

		this._level = level;

		for(var i = 0; i < level.globalRobots.length; ++i)
		{

			switch(level.globalRobots[i].getType())
			{
				case pm.GlobalRobotType.Counter:
				case pm.GlobalRobotType.ExtendedCounter:
					this._placeCounter(level.globalRobots[i]); break;
				case pm.GlobalRobotType.Output:
					this._placeOutput(level.globalRobots[i]); break;
				case pm.GlobalRobotType.GreenFlag:
				case pm.GlobalRobotType.OrangeFlag:
					this._placeFlag(level.globalRobots[i]); break;
				default: break;
			}

		}
	},

	addRobot: function(robot)
	{
		switch(robot.getType())
		{
			case pm.GlobalRobotType.Output:
				this._placeOutput(robot); break;
			case pm.GlobalRobotType.Counter:
			case pm.GlobalRobotType.ExtendedCounter:
				this._placeCounter(robot); break;
			case pm.GlobalRobotType.GreenFlag:
			case pm.GlobalRobotType.OrangeFlag:
				this._placeFlag(robot);
				break;
			default: break;
		}
	},

	redrawRobot: function(robot)
	{
		switch(robot.getType())
		{
			case pm.GlobalRobotType.Output:
				this.removeChild(this.getChildByName(GlobalRobotsLayer.OUTPUT_LAYER_NAME));
				this._placeOutput(level.globalRobots[i]); break;
			case pm.GlobalRobotType.Counter:
			case pm.GlobalRobotType.ExtendedCounter:
				this.removeChild(this.getChildByName(GlobalRobotsLayer.COUNTER_LAYER_NAME));
				this._placeCounter(robot);
				break;
			case pm.GlobalRobotType.GreenFlag:
				this._flagContainer.removeChild(this._flagContainer.getChildByName(GlobalRobotsLayer.GREEN_FLAG_LAYER_NAME));
				this._placeFlag(robot);
				break;
			case pm.GlobalRobotType.OrangeFlag:
				this._flagContainer.removeChild(this._flagContainer.getChildByName(GlobalRobotsLayer.ORANGE_FLAG_LAYER_NAME));
				this._placeFlag(robot);
				break;
			default: break;
		}
	},

	removeRobot: function(robot)
	{
		switch(robot.getType())
		{
			case pm.GlobalRobotType.Output:
				this.removeChild(this.getChildByName(GlobalRobotsLayer.OUTPUT_LAYER_NAME));break;
			case pm.GlobalRobotType.Counter:
			case pm.GlobalRobotType.ExtendedCounter:
				this.removeChild(this.getChildByName(GlobalRobotsLayer.COUNTER_LAYER_NAME)); break;
			case pm.GlobalRobotType.GreenFlag:
				this._flagContainer.removeChild(this._flagContainer.getChildByName(GlobalRobotsLayer.GREEN_FLAG_LAYER_NAME));

				this._flagContainer.setContentSize(
					this._flagContainer.width - GlobalRobotsLayer.FLAG_LAYER_SIZE.WIDTH,
					Math.max(this._flagContainer.height, GlobalRobotsLayer.FLAG_LAYER_SIZE.HEIGHT)
				);

				break;
			case pm.GlobalRobotType.OrangeFlag:
				this._flagContainer.removeChild(this._flagContainer.getChildByName(GlobalRobotsLayer.ORANGE_FLAG_LAYER_NAME));

				this._flagContainer.setContentSize(
					this._flagContainer.width - GlobalRobotsLayer.FLAG_LAYER_SIZE.WIDTH,
					Math.max(this._flagContainer.height, GlobalRobotsLayer.FLAG_LAYER_SIZE.HEIGHT)
				);
				break;
			default: break;
		}
	},

	_placeCounter: function(counter)
	{
		var fLayer = new CounterRobotLayer(counter);

		var fLayerAlign = new ccui.RelativeLayoutParameter();
		fLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
		fLayerAlign.setRelativeName(GlobalRobotsLayer.COUNTER_LAYER_NAME);
		fLayer.setLayoutParameter(fLayerAlign);

		this.addChild(fLayer, 0, GlobalRobotsLayer.COUNTER_LAYER_NAME);
	},

	_placeOutput: function(counter)
	{
		var fLayer = new OutputRobotLayer(counter);

		var fLayerAlign = new ccui.RelativeLayoutParameter();
		fLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
		fLayerAlign.setRelativeName(GlobalRobotsLayer.OUTPUT_LAYER_NAME);
		fLayer.setLayoutParameter(fLayerAlign);

		this.addChild(fLayer, 0, GlobalRobotsLayer.OUTPUT_LAYER_NAME);
	},

	_placeFlag: function(robot)
	{
		if(!this._flagContainer)
		{
			this._flagContainer = new ccui.Layout();
			this._flagContainer.setLayoutType(ccui.Layout.RELATIVE);

			var containerAlign = new ccui.RelativeLayoutParameter();
			containerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);

			this._flagContainer.setLayoutParameter(containerAlign);
			this.addChild(this._flagContainer);
		}

		var fLayer = new FlagRobotLayer(robot);

		var fLayerAlign = new ccui.RelativeLayoutParameter();

		switch (robot.getType())
		{
			case pm.GlobalRobotType.GreenFlag:
				fLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
				fLayerAlign.setMargin(GlobalRobotsLayer.SHIFT, 0, 0, 0);
				fLayer.setLayoutParameter(fLayerAlign);
				this._flagContainer.addChild(fLayer, 0, GlobalRobotsLayer.GREEN_FLAG_LAYER_NAME);
				break;
			case pm.GlobalRobotType.OrangeFlag:
				fLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
				fLayerAlign.setMargin(0, 0, GlobalRobotsLayer.SHIFT, 0);
				fLayer.setLayoutParameter(fLayerAlign);
				this._flagContainer.addChild(fLayer, 0, GlobalRobotsLayer.ORANGE_FLAG_LAYER_NAME);
				break;
		}

		this._flagContainer.setContentSize(
			this._flagContainer.width + fLayer.width,
			Math.max(this._flagContainer.height, fLayer.height)
		);
	}

});

GlobalRobotsLayer.COUNTER_LAYER_NAME = "counterLayer";
GlobalRobotsLayer.OUTPUT_LAYER_NAME = "outputLayer";
GlobalRobotsLayer.GREEN_FLAG_LAYER_NAME = "greenFlagLayer";
GlobalRobotsLayer.ORANGE_FLAG_LAYER_NAME = "orangeFlagLayer";
GlobalRobotsLayer.FLAG_LAYER_SIZE = {
	WIDTH: 110,
	HEIGHT: 224.5
};
GlobalRobotsLayer.SHIFT = -5;

