/**
 * Created by Nikita Besshaposhnikov on 24.07.15.
 */

/**
 * @class Sound button which shows sound popup.</br>
 * Add only on Relative layouts! </br>
 * Don't call "setLayoutParameter" by yourself.
 * @extends pmui.Button
 * @constructor
 * @param {ccui.RelativeLayoutParameter} relativeParameter
 */
var SoundButton = pmui.Button.extend(/** @lends SoundButton# */{
	ctor: function(relativeParameter)
	{
		this._super(pm.spriteUtils.getIconName("soundOn", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("soundOn", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("soundOn", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this.setName(SoundButton.WIDGET_NAME);

		this.addClickEventListener(this._showSoundPopup.bind(this));

		relativeParameter.setRelativeName(SoundButton.WIDGET_NAME);
		this.setLayoutParameter(relativeParameter);
	},

	_showSoundPopup: function()
	{
		if(!this.getParent().getChildByTag(SoundButton.POPUP_TAG))
		{
			var layer = new SoundPopupLayer();

			var soundButtonAlign = new ccui.RelativeLayoutParameter();
			soundButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
			soundButtonAlign.setMargin(0, 5, 0, 0);
			soundButtonAlign.setRelativeToWidgetName(SoundButton.WIDGET_NAME);
			layer.setLayoutParameter(soundButtonAlign);

			this.getParent().addChild(layer, this.getLocalZOrder(), SoundButton.POPUP_TAG);
		}
		else
		{
			this.getParent().removeChildByTag(SoundButton.POPUP_TAG);
		}
	}
});

SoundButton.WIDGET_NAME = "sound_button";
SoundButton.POPUP_TAG = 100;
