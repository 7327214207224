/**
 * Created by danilaeremin on 11.08.15.
 */

/**
 * @class Layer for new objects.
 * @extends LevelSettingsLayer
 * @constructor
 * @param {pm.AbstractLevel} level Level which settings shows
 */
var ObjectsSettingsLayer = LevelSettingsLayer.extend(/** @lends ObjectsSettingsLayer# */{
	_robotsTable: null,
	_objectsTable: null,
	_robots: [],
	_objects: [],

	ctor: function(level)
	{
		this._super(level);

		var levelType = level.getType();

		this._robots = [];
		this._objects = [];

		var label = new ccui.Text(LocalizedString("Objects"), pm.settings.fontName, pm.settings.fontSize);
		var height = label.height + LevelSettingsLayer.SEPARATOR;

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		labelAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		labelAlign.setRelativeName("label");
		label.setLayoutParameter(labelAlign);

		this.addChild(label);

		var objects = pm.moduleUtils.getObjectsInfo(levelType);
		var robots = pm.moduleUtils.getRobotsInfo(levelType);

		var maxElementsCountPerRaw = 3;

		var separator = cc.size(5, 5);
		var cellSize = cc.size( ( LevelSettingsLayer.WIDTH - 2*20 - 2*separator.width - 2*LevelSettingsLayer.BORDER_RADIUS ) / maxElementsCountPerRaw,
			( LevelSettingsLayer.WIDTH - 2*20 - 2*separator.width - 2*LevelSettingsLayer.BORDER_RADIUS ) / maxElementsCountPerRaw);

		var robotRows = Math.floor((Object.keys(robots).length + 1) / maxElementsCountPerRaw) + (((Object.keys(robots).length + 1) % maxElementsCountPerRaw === 0) ? 0 : 1 );
		var objectRows = Math.floor(Object.keys(objects).length / maxElementsCountPerRaw) + ((Object.keys(objects).length % maxElementsCountPerRaw === 0) ? 0 : 1 );

		this._robotsTable = new pmui.SelectTableView(cellSize, separator, robotRows, maxElementsCountPerRaw);
		this._robotsTable.setSelectFrame(pm.spriteUtils.getInterfaceElementFrame("functionBG"), true);
		this._robotsTable.setFitObjectsSizes(true);
		this._robotsTable.addSelectEventListener(this._selectRobot, this);

		this._robotsTable.setContentSize(LevelSettingsLayer.WIDTH - 4*LevelSettingsLayer.BORDER_RADIUS,
			cellSize.height * Math.min(robotRows, 1) + separator.height * Math.min(robotRows, 1) + LevelSettingsLayer.SEPARATOR);
		height += this._robotsTable.height + LevelSettingsLayer.SEPARATOR;

		var robotScrollAlign = new ccui.RelativeLayoutParameter();
		robotScrollAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		robotScrollAlign.setRelativeName("robotScroll");
		robotScrollAlign.setRelativeToWidgetName("label");
		robotScrollAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		this._robotsTable.setLayoutParameter(robotScrollAlign);

		this.addChild(this._robotsTable);

		this._objectsTable = new pmui.SelectTableView(cellSize, separator, objectRows, maxElementsCountPerRaw);
		this._objectsTable.setSelectFrame(pm.spriteUtils.getInterfaceElementFrame("functionBG"), true);
		this._objectsTable.setFitObjectsSizes(true);
		this._objectsTable.addSelectEventListener(this._selectObject, this);

		this._objectsTable.setContentSize(LevelSettingsLayer.WIDTH - 4*LevelSettingsLayer.BORDER_RADIUS,
			cellSize.height * Math.min(objectRows, 1) + separator.height * Math.min(objectRows, 1) + LevelSettingsLayer.SEPARATOR);
		height += this._objectsTable.height + LevelSettingsLayer.SEPARATOR;

		var objectScrollAlign = new ccui.RelativeLayoutParameter();
		objectScrollAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		objectScrollAlign.setRelativeToWidgetName("robotScroll");
		objectScrollAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		this._objectsTable.setLayoutParameter(objectScrollAlign);

		this.addChild( this._objectsTable);

		for (var robot in robots)
			pm.spriteUtils.addTempSpriteFrames(pm.moduleUtils.getRobotSpriteSheet(levelType, robot));

		var i = 0;

		for (var robot in robots)
		{
			this._robots.push(robot);
			var robotSprite = new ccui.ImageView(robots[robot], ccui.Widget.PLIST_TEXTURE);
			this._robotsTable.setCell( Math.floor(i / maxElementsCountPerRaw), i % maxElementsCountPerRaw, robotSprite);
			++i;
		}

		this._robots.push("clone");
		this._updateCloneSprite(robots[level.getCurrentRobot().getType()]);

		i = 0;

		for (var object in objects)
		{
			this._objects.push(object);
			var objectSprite = new ccui.ImageView(objects[object], ccui.Widget.LOCAL_TEXTURE);
			this._objectsTable.setCell( Math.floor(i / maxElementsCountPerRaw), i % maxElementsCountPerRaw, objectSprite);
			++i;
		}

		height += LevelSettingsLayer.SEPARATOR + LevelSettingsLayer.BORDER_RADIUS;
		this.updateSize(LevelSettingsLayer.WIDTH, height);

		pm.registerCustomEventListener(pme.MAP_ELEMENT_SELECT_EVENT_STR, function(event)
		{
			this._objectsTable.deselectAll();
			this._robotsTable.deselectAll();
		}.bind(this), this);

		pm.registerCustomEventListener(pm.ROBOT_TOUCH_EVENT_STR, function(event)
		{
			var robot = event.getUserData();
			var robotSprite = robots[robot.getType()];

			this._updateCloneSprite(robotSprite);
		}.bind(this), this);
	},

	_selectRobot: function (selectedRobot)
	{
		this._objectsTable.deselectAll();

		if (selectedRobot)
		{
			if (selectedRobot.x + selectedRobot.y !== this._robots.length - 1)
				pm.sendCustomEvent(pme.ADD_ROBOT_SELECT_EVENT_STR, this._robots[selectedRobot.x * 3 + selectedRobot.y]);
			else
				pm.sendCustomEvent(pme.ADD_CLONE);
		}
		else
		{
			pm.sendCustomEvent(pme.ADD_ROBOT_SELECT_EVENT_STR, undefined);
		}
	},

	_selectObject: function (selectedObject)
	{
		this._robotsTable.deselectAll();

		if (selectedObject)
			pm.sendCustomEvent(pme.ADD_OBJECT_SELECT_EVENT_STR, this._objects[selectedObject.x * 3 + selectedObject.y]);
		else
			pm.sendCustomEvent(pme.ADD_OBJECT_SELECT_EVENT_STR, undefined);
	},

	_updateCloneSprite: function(robotImage)
	{
		var robotSprite = new ccui.ImageView(robotImage, ccui.Widget.PLIST_TEXTURE);
		var clone = new ccui.ImageView(pm.spriteUtils.getInterfaceElementName("clone"), ccui.Widget.PLIST_TEXTURE);

		clone.setAnchorPoint(cc.p(1, 0));
		clone.setPosition(robotSprite.width, 0);
		robotSprite.addChild(clone);

		var index = this._robots.length - 1;
		var maxElementsCountPerRaw = 3;
		this._robotsTable.setCell(Math.floor(index / maxElementsCountPerRaw), index % maxElementsCountPerRaw, robotSprite);
	}
});
