/**
 * Created by Nikita Besshaposhnikov on 16.11.15.
 */

/**
 * @class Common interface for 2D ortho robot sprites.</br>
 * Provides move animations.
 * @interface
 * @extends pm.RobotSprite2D
 */
pm.OrthoRobotSprite = pm.RobotSprite2D.extend(/** @lends pm.OrthoRobotSprite#*/{
	ctor: function(frame)
	{
		this._super(frame);

		this.setOrientation(pm.MapLayer2D.Orientation.Ortho);

		this._minPoint = cc.p(0, this.sprite.height);
		this._maxPoint = cc.p(this.sprite.width, 0);
	},

	_getTurnAngle: function(direction) {},

	setDirection: function(direction)
	{
		if(direction >= 0)
		{
			this.sprite.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame(this._getFrameName(direction)));

			this.sprite.setRotation(this._getTurnAngle(direction));
			this._commandsSprite.setRotation(-this._getTurnAngle(direction));
		}
		else
		{
			cc.log("Incorrect robot direction");
		}
	},

	_turn: function(oldDirection, curDirection, endCallback, target)
	{
		var turn = cc.rotateTo(pm.settings.getAnimationSpeed(), this._getTurnAngle(curDirection));
		var turnEnd = cc.callFunc(endCallback, target);
		this.sprite.runAction(cc.sequence(turn, turnEnd));

		this._commandsSprite.runAction(cc.rotateTo(pm.settings.getAnimationSpeed(), -this._getTurnAngle(curDirection)));
	}
});
