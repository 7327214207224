/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.LogicExpressionNode = pmui.ExpressionNode.extend(/** @lends pmui.NodeLayer# */{

	ctor: function (treeNode, parent, syntaxModule, sign)
	{
		this._super(treeNode, parent, syntaxModule, sign);
		this.type = FunctionButton.Type.LogicExpression;

		this.drawBackground();
		this._repTypes = [FunctionButton.Type.Condition, FunctionButton.Type.LogicExpression,
			FunctionButton.Type.Variable, FunctionButton.Type.AndOrExpression, FunctionButton.Type.NotExpression];
	},

	getNodeMargin: function()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return 2;
		return 13;
	},

	select: function()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return;
		if(!this._selected)
		{
			this.setBackGroundImage("PiktomirK/select-romb-border.png");
			this.setBackGroundImageCapInsets(cc.rect(28, 22, 70, 6));
			this._selected = true;
			this.setTag(ProgramContainerLayer.SELECTED_TAG);
		}
	},

	highlight: function()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
		{
			//this._sign.setColor(cc.BLUE);
			return;
		}
		this.setBackGroundImage("PiktomirK/select-romb-border.png");
		this.setBackGroundImageCapInsets(cc.rect(28, 22, 70, 6));
	},

	removeHighlight: function()
	{
		this.drawBackground();
	},

	clickCallback: function ()
	{
		var valN = pmui.LogicExpressionNode.values.indexOf(this._treeNode.value);
		var value = "";
		if(valN < 4)
		{
			valN = (valN + 1) % 4;
		}
		else
		{
			valN > 4 ? valN = 4: valN ++;
		}
		value = pmui.LogicExpressionNode.values[valN];
		this.changeSign(this.getSign(value));
		//this._sign.addClickEventListener(this._changeSign.bind(this));
		this._treeNode.value = value;
	},

	drawBackground: function()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
		{
			return;
		}
		if(!this.isEditable())
			this.setBackGroundImage("PiktomirK/romb-blocked.png");
		else
			this.setBackGroundImage("PiktomirK/romb-border.png");
		this.setBackGroundImageScale9Enabled(true);
		this.setCascadeOpacityEnabled(false);
		this.setBackGroundImageCapInsets(cc.rect(28, 22, 70, 6));
	},

	clone: function ()
	{
		return pm.appUtils.generateFunctionButton(this.type, pm.ArythmConditions.LeftLRight);
	}
});

pmui.LogicExpressionNode.values = [
	pm.ArythmConditions.LeftLRight,
	pm.ArythmConditions.RightLLeft,
	pm.ArythmConditions.LeftEqual,
	pm.ArythmConditions.RightEqual,
	pm.ArythmConditions.Equal,
	pm.ArythmConditions.NotEqual
];
