/**
 * Created by Kirill Mashchenko on 10.07.2018.
 */

/**
 * @extends FunctionButtonP
 * @constructor
 * @param {String} value
 */
var EmptyFunctionButton = FunctionButtonP.extend(/** @lends EmptyFunctionButton#*/{

	ctor: function (type)
	{
		this._super(type, "empty");

		switch (type)
		{
			case FunctionButton.Type.Repeater:
				this._value = pm.REPEATER_EMPTY;
				break;
			case FunctionButton.Type.Condition:
			case FunctionButton.Type.CondRepeater:
				this._value = pm.CONDITION_EMPTY;
				break;
			case FunctionButton.Type.Method:
				this._value = pm.EMPTY_METHOD;
				break;
		}
	},

	cloneImage: function ()
	{
		if (this._type === FunctionButton.Type.CondRepeater)
			return new ccui.ImageView(pm.spriteUtils.getRepeaterSpriteName("cond"), ccui.Widget.PLIST_TEXTURE);
		else
			return new ccui.ImageView(this._getSpriteName(pm.NORMAL_STATE), ccui.Widget.PLIST_TEXTURE);
	},

	_getSpriteName: function (state)
	{
		switch (this._type)
		{
			case FunctionButton.Type.Method:
				return pm.spriteUtils.getRobotMethodSpriteName("shadow", state);
			case FunctionButton.Type.Condition:
				return pm.spriteUtils.getRobotConditionSpriteName("shadow", state);
			case FunctionButton.Type.Repeater:
				return pm.spriteUtils.getRepeaterSpriteName("shadow", state);
			case FunctionButton.Type.CondRepeater:
				return pm.spriteUtils.getRobotConditionSpriteName("empty", state);
		}
		return "";
	}
});
