/**
 * Created by Kirill Mashchenko on 22.07.19.
 */

pm.data.AquariusTank = pm.Class.extend(/** @lends pm.Tank# */{

	typeName: "AquariusTank",

	availableCapacity: 0,
	currentCapacity: 0,
	startCapacity: 0,

	mapLayer: null,
	sprite: {},
	_name: "",

	ctor: function(name)
	{
		this._addNonEnumerableProps("sprite", "mapLayer", "_isPlayingAnimation");

		this._super();

		this._isPlayingAnimation = false;
		this._name = name;
	},

	generateSprite: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (!previewDraw)
			{
				this.sprite = new AquariusTankSprite(this._name, this.availableCapacity, this.startCapacity);
			}
			else
			{
				this.sprite = new cc.Sprite(cc.spriteFrameCache.getSpriteFrame("ATS_{0}_Back.png".format(this._name)));
				var frontSprite = new cc.Sprite(cc.spriteFrameCache.getSpriteFrame("ATS_{0}_Front.png".format(this._name)));

				frontSprite.setAnchorPoint(cc.p(0, 0));
				frontSprite.setPosition(0, -25);

				this.sprite.addChild(frontSprite);
				this.sprite.setAnchorPoint(cc.p(0, 0));

				if (!this.availableCapacity || this.availableCapacity < 2)
					this.sprite.setVisible(false);
			}

			return this.sprite;
		}
	},

	setAvailableCapacity: function(capacity)
	{
		this.availableCapacity = capacity;
		this.sprite.setAvailableCapacity(capacity);

		this.mapLayer.updateTankPosition();
	},

	setCurrentCapacity: function(capacity, withoutAnimation)
	{
		this.currentCapacity = capacity;

		if (!CORE_BUILD &&( withoutAnimation || pm.settings.isAnimationDisabled()))
		{
			this.sprite.setCurrentCapacity(capacity);
		}
		else
		{
			this._isPlayingAnimation = true;
			if(!CORE_BUILD)
				this.sprite.setCurrentCapacity(capacity, this._endChangingCurrentCapacity, this);
			else
			{
				this._endChangingCurrentCapacity.call(this);
			}
		}
	},

	setStartCapacity: function(capacity)
	{
		this.currentCapacity = capacity;
		this.startCapacity = capacity;
		this.sprite.setCurrentCapacity(capacity);
	},

	isPlayingAnimation: function ()
	{
		return this._isPlayingAnimation;
	},

	_endChangingCurrentCapacity: function()
	{
		this._isPlayingAnimation = false;
	},

	stopAnimation: function()
	{
		this._isPlayingAnimation = false;
		if(!CORE_BUILD)
			this.sprite.stopAllActions();
	},

	setNotCompleted: function(flag){}
});
