/**
 * Created by Nikita Besshaposhnikov on 01.07.16.
 */

/**
 * Functions from this namespace are used to validate worlds.
 * @namespace
 */
pm.validationUtils = {

	/**
     * Pattern for world schema's id
     * @const
     * @type {String}
     * @default
     */
	WORLD_SCHEMA_PATTERN: "world_{0}",

	_supportedLevelFormatVersions: [],
	//_supportedLevelFormatVersions: [9, 10, 11,12],

	_ajv: null,

	/**
     * Loads initial data
     */
	load: function()
	{
		this._ajv = new Ajv({ v5: true, ownProperties: true });

		for(var i = 0; i < this._supportedLevelFormatVersions.length; ++i )
		{
			var worldSchemaName = this.WORLD_SCHEMA_PATTERN.format(this._supportedLevelFormatVersions[i]);

			var schema = pm.fileUtils.getFileContent("ValidationSchemas/" + worldSchemaName + ".json");

			this._ajv.addSchema(schema, worldSchemaName);
		}
	},

	/**
     * Validates world object with schema for world format
     * @param {Object} worldData
     * @param {Number} formatVersion
     * @returns {?Array<Error>}
     */
	validateWorld: function(worldData, formatVersion)
	{
		if(this._supportedLevelFormatVersions.indexOf(formatVersion) < 0)
		{
			cc.warn("No validation schema for world format: ", formatVersion);
			return null;
		}

		var validator = this._ajv.getSchema(this.WORLD_SCHEMA_PATTERN.format(formatVersion));

		if(!validator)
		{
			cc.warn("Validation schema not compiled for world format: ", formatVersion);
			return null;
		}

		if(validator(worldData))
			return null;
		else
			return validator.errors;
	},

	/**
     * Validates world cache object with schema for world format
     * @param {Object} worldDataCache
     * @param {Number} formatVersion
     * @returns {?Array<Error>}
     */
	validateWorldUserCache: function(worldDataCache, formatVersion)
	{
		if(this._supportedLevelFormatVersions.indexOf(formatVersion) < 0)
		{
			cc.warn("No validation schema for world format: ", formatVersion);
			return null;
		}

		var worldSchemaName = this.WORLD_SCHEMA_PATTERN.format(formatVersion);

		try
		{
			var validator = this._ajv.compile({$ref: worldSchemaName + "#/definitions/worldUserCache"});

			if (!validator)
			{
				cc.warn("Validation schema not compiled for world format: ", formatVersion);
				return null;
			}

			if (validator(worldDataCache))
				return null;
			else
				return validator.errors;
		}
		catch(error)
		{
			cc.warn(
				"Error with compilation of validation schema for world format ",
				formatVersion, ": ", error.message
			);

			return null;
		}

		return null;
	}
};
