/** Created by Kirill Mashchenko on 09.08.17. ...*/

var TeamGameLayout = ccui.Layout.extend({

	team: null,
	_teamList: null,
	_callback: null,
	stateOfRobot: null,
	stateText: null,
	changeLevel: null,
	gameLabel: null,
	levelLabel: null,
	controlLayout: null,
	_autoChange: true,
	_autoChangeCheckbox: null,
	chooseGameLevelLayout: null,
	lastDate: null,
	timeList: null,
	timeLayout: null,

	ctor: function (team, index, autoChange)
	{
		this._super();

		this.team = team;

		this.team.index = index;

		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setBackGroundImageCapInsets(cc.rect(14, 14, 14, 14));
		this.setBackGroundImage("System/interfaceSmallBG.png");
		this.setBackGroundImageScale9Enabled(true);
		this.setCascadeOpacityEnabled(true);

		var teamLayout = new ccui.Layout();
		teamLayout.setLayoutType(ccui.Layout.RELATIVE);

		var teamAlign = new ccui.RelativeLayoutParameter();
		teamAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		teamAlign.setRelativeName("team");
		teamAlign.setMargin(pmui.OvalLayout.SLIM_BORDER_RADIUS, pmui.OvalLayout.SLIM_BORDER_RADIUS, 0, 0);
		teamLayout.setLayoutParameter(teamAlign);

		this._teamList = new ccui.ListView();
		this._teamList.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._teamList.setItemsMargin(3);
		this._teamList.setGravity(0);
		this._teamList.setScrollBarWidth(TeamGameLayout.SCROLL_BAR_WIDTH);
		this._teamList.setPropagateTouchEvents(false);

		var teamListAlign = new ccui.RelativeLayoutParameter();
		teamListAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		teamListAlign.setMargin(0, TeamGameLayout.SMALL_SEPARATOR, 0, 0);
		this._teamList.setLayoutParameter(teamListAlign);

		var serverChild = new TeamStudentLayout(team.hostStudent.name);
		this._teamList.pushBackCustomItem(serverChild);

		var maxWidth = serverChild.width;

		for (var i = 0; i < team.students.length; ++i)
		{
			var child = new TeamStudentLayout(team.students[i].name);
			this._teamList.pushBackCustomItem(child);

			maxWidth = Math.max(maxWidth, child.width);
		}

		this.controlLayout = new ccui.Layout();
		this.controlLayout.setLayoutType(ccui.Layout.RELATIVE);

		var controlAlign = new ccui.RelativeLayoutParameter();
		controlAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		controlAlign.setRelativeName("control");
		controlAlign.setRelativeToWidgetName("team");
		controlAlign.setMargin(TeamGameLayout.BORDER, 0, 0, 0);
		this.controlLayout.setLayoutParameter(controlAlign);

		var cutNumber = 35;

		if(cc.sys.isMobile)
			cutNumber = 31;

		this.gameLabel = new ccui.Text(world.getGameName(team.game).substring(0, cutNumber), pm.settings.fontName, 18);
		var gameAlign = new ccui.RelativeLayoutParameter();
		gameAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		gameAlign.setMargin(0, TeamGameLayout.SMALL_SEPARATOR, 0, 0);
		gameAlign.setRelativeName("Game");
		this.gameLabel.setLayoutParameter(gameAlign);
		this.controlLayout.addChild(this.gameLabel);

		var controlHeight = TeamGameLayout.SMALL_SEPARATOR + this.gameLabel.height;

		this.levelLabel = new ccui.Text(world.getLevelName(team.game, team.level).substring(0, cutNumber), pm.settings.fontName, 18);
		var levelAlign = new ccui.RelativeLayoutParameter();
		levelAlign.setRelativeToWidgetName("Game");
		levelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		levelAlign.setMargin(0, 0, 0, 0);
		levelAlign.setRelativeName("Level");
		this.levelLabel.setLayoutParameter(levelAlign);
		this.controlLayout.addChild(this.levelLabel);

		controlHeight += this.levelLabel.height;

		this.stateText = new ccui.Text(LocalizedString(pm.NetworkGameState.Develop), pm.settings.fontName, 18);
		var stateAlign = new ccui.RelativeLayoutParameter();
		stateAlign.setRelativeToWidgetName("Level");
		stateAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		stateAlign.setMargin(0, TeamGameLayout.SMALL_SEPARATOR, 0, 0);
		stateAlign.setRelativeName("State");
		this.stateText.setLayoutParameter(stateAlign);
		this.controlLayout.addChild(this.stateText);

		controlHeight += TeamGameLayout.SMALL_SEPARATOR + this.stateText.height;

		this._autoChange = autoChange;

		this._autoChangeCheckbox = new pmui.CheckBox(LocalizedString("AutoChange"), this._autoChangeLevel, this, 18, this._autoChange);

		var autoChangeAlign = new ccui.RelativeLayoutParameter();
		autoChangeAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		autoChangeAlign.setRelativeName("autoChange");
		autoChangeAlign.setRelativeToWidgetName("State");
		autoChangeAlign.setMargin(0, TeamGameLayout.SMALL_SEPARATOR, 0, 0);
		this._autoChangeCheckbox.setLayoutParameter(autoChangeAlign);
		this.controlLayout.addChild(this._autoChangeCheckbox);

		controlHeight += TeamGameLayout.SMALL_SEPARATOR + this._autoChangeCheckbox.height;

		this._teamList.setContentSize(maxWidth + TeamGameLayout.BORDER, controlHeight);
		teamLayout.addChild(this._teamList);
		teamLayout.setContentSize(maxWidth, controlHeight);
		this.addChild(teamLayout);

		this.controlLayout.setContentSize(this._autoChangeCheckbox.width, controlHeight);
		this.addChild(this.controlLayout);

		var buttonLayout = new ccui.Layout();
		buttonLayout.setLayoutType(ccui.Layout.RELATIVE);

		var buttonAlign = new ccui.RelativeLayoutParameter();
		buttonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_CENTER_VERTICAL);
		buttonAlign.setMargin(0, 0, pmui.OvalLayout.SLIM_BORDER_RADIUS, 0);
		buttonLayout.setLayoutParameter(buttonAlign);

		this.addChild(buttonLayout);

		this.changeLevel = new ChangeLevelClassControl(this);

		var changeLevelAlign = new ccui.RelativeLayoutParameter();
		changeLevelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		changeLevelAlign.setMargin(0, TeamGameLayout.SMALL_SEPARATOR, TeamGameLayout.SMALL_SEPARATOR, 0);
		changeLevelAlign.setRelativeName("Change");
		this.changeLevel.setLayoutParameter(changeLevelAlign);

		this.changeLevel.setScale(0.8);
		this.changeLevel.setEnabledPrevLevelButton(this.team.level !== 0);
		this.changeLevel.setEnabledNextLevelButton(this.team.level !== world.getLevelCount(this.team.game) - 1);

		buttonLayout.addChild(this.changeLevel);

		this.lastDate = new Date();
		this.timeLayout = null;

		var showTimeButton = new pmui.Button(pm.spriteUtils.getIconName("time", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("time", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("time", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		var timeAlign = new ccui.RelativeLayoutParameter();
		timeAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_RIGHTALIGN);
		timeAlign.setMargin(0, TeamGameLayout.SMALL_SEPARATOR, 0, 0);
		timeAlign.setRelativeName("Time");
		timeAlign.setRelativeToWidgetName("Change");
		showTimeButton.setLayoutParameter(timeAlign);

		showTimeButton.addClickEventListener(this.drawTimeLayout.bind(this));
		buttonLayout.addChild(showTimeButton, 0);

		var editLevel = new pmui.Button(pm.spriteUtils.getIconName("edit", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("edit", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("edit", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		editLevel.addClickEventListener(this._editLevel.bind(this));
		editLevel.setScale(0.8);

		var editLevelAlign = new ccui.RelativeLayoutParameter();
		editLevelAlign.setRelativeName("Edit");
		editLevelAlign.setRelativeToWidgetName("Time");
		editLevelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_LEFT_OF_CENTER);
		editLevelAlign.setMargin(0, 0, TeamGameLayout.BUTTON_SEPARATOR, 0);
		editLevel.setLayoutParameter(editLevelAlign);

		buttonLayout.addChild(editLevel);

		var destroyTeam = new pmui.Button(pm.spriteUtils.getIconName("deleteTeam", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("deleteTeam", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("deleteTeam", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		destroyTeam.addClickEventListener(this._destroyTeam.bind(this));

		var destroyTeamAlign = new ccui.RelativeLayoutParameter();
		destroyTeamAlign.setRelativeToWidgetName("Edit");
		destroyTeamAlign.setRelativeName("Destroy");
		destroyTeamAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_LEFT_OF_CENTER);
		destroyTeamAlign.setMargin(0, 0, TeamGameLayout.BUTTON_SEPARATOR, 0);
		destroyTeam.setLayoutParameter(destroyTeamAlign);

		buttonLayout.addChild(destroyTeam);

		buttonLayout.setContentSize(3*destroyTeam.width + 2 * TeamGameLayout.BUTTON_SEPARATOR,
				this.changeLevel.height + destroyTeam.height + TeamGameLayout.SMALL_SEPARATOR);

		var workSpaceWidth = pm.settings.getScreenSize().width - ClassControlLayer.SEPARATOR_NUMBER * ClassControlLayer.SEPARATOR;
		var teamLayoutWidth = workSpaceWidth * ClassControlLayer.GAME_WIDTH_PERCENT - 2 * ClassControlLayer.BORDER;
		this.setContentSize(teamLayoutWidth - 2 * TeamGameLayout.BORDER, controlHeight + TeamGameLayout.MEDIUM_SEPARATOR);
	},

	drawTimeLayout: function ()
	{
		if (this.timeLayout)
		{
			this.timeLayout.removeFromParent();
			this.timeLayout = null;
		}
		else
		{
			this.timeLayout = new pmui.OvalLayout(null, null, pmui.OvalLayout.Type.SLIM);

			var timeAlign = new ccui.RelativeLayoutParameter();
			timeAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
			timeAlign.setMargin(ClassControlLayer.MEDIUM_SEPARATOR, 0, 0, 0);
			this.timeLayout.setLayoutParameter(timeAlign);

			this.timeList = new ccui.ListView();
			this.timeList.setDirection(ccui.ScrollView.DIR_VERTICAL);
			this.timeList.setItemsMargin(TeamGameLayout.ITEM_SEPARATOR);
			this.timeList.setGravity(0);
			this.timeList.setScrollBarWidth(TeamGameLayout.SCROLL_BAR_WIDTH);
			this.timeList.setPropagateTouchEvents(false);

			var timeListAlign = new ccui.RelativeLayoutParameter();
			timeListAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
			timeListAlign.setMargin(pmui.OvalLayout.SLIM_BORDER_RADIUS, pmui.OvalLayout.SLIM_BORDER_RADIUS, 0, 0);
			this.timeList.setLayoutParameter(timeListAlign);

			var maxWidth = 0;

			if (this.team.solvingTimes)
			{
				for (var o in this.team.solvingTimes)
				{
					var timeOfSolving = this.team.solvingTimes[o];
					var solvingSeconds = timeOfSolving % 60;

					var timeOfSolvingMinutes = Math.floor(timeOfSolving / 60);
					var solvingMinutes = timeOfSolvingMinutes % 60;

					var timeOfSolvingHours = Math.floor(timeOfSolvingMinutes / 60);
					var solvingHours = timeOfSolvingHours % 60;

					var string = solvingHours+":"+solvingMinutes+":"+solvingSeconds+";";

					var child = new ccui.Text(LocalizedString("Level")+": "+o+"; "+string,  pm.settings.fontName, 18);
					this.timeList.pushBackCustomItem(child);

					maxWidth = Math.max(maxWidth, child.width);
				}
			}
			else
			{
				var noTimeLabel = new ccui.Text(LocalizedString("NoCompleted"), pm.settings.fontName, 18);
				this.timeList.pushBackCustomItem(noTimeLabel);

				maxWidth = Math.max(maxWidth, noTimeLabel.width);
			}

			this.timeList.setContentSize(maxWidth, this.controlLayout.height - TeamGameLayout.MEDIUM_SEPARATOR);
			this.timeLayout.setContentSizeWithBorder(maxWidth, this.controlLayout.height - TeamGameLayout.MEDIUM_SEPARATOR);

			this.timeLayout.addChild(this.timeList);
			this.addChild(this.timeLayout, 100);
		}
	},

	_autoChangeLevel: function(flag)
	{
		this._autoChange = flag;

		if (this._autoChange && this.team.state === pm.NetworkGameState.ReadyToChangeLevel)
			this._nextLevel();
	},

	setAutoConnect: function(flag)
	{
		this._autoChangeCheckbox.setChecked(flag);
		this._autoChangeLevel(flag);
	},

	setIndexCallback: function (callback)
	{
		this._callback = callback;
	},

	refresh: function()
	{
		if (!this.team.state)
			this.team.state = pm.NetworkGameState.Develop;

		var state = pm.NetworkGameState.Develop;

		var changeState = true;

		if(this.team.hostStudent.client && this.team.hostStudent.client.getId())
			state = this.team.statesMap[this.team.hostStudent.client.getId()];

		for(var s = 0; s < this.team.students.length; ++s)
		{
			if(this.team.students[s].client && this.team.students[s].client.getId())
			{
				if (this.team.statesMap[this.team.students[s].client.getId()] !== state)
					changeState = false;
			}
		}

		if (changeState)
		{
			if (!state)
				state = pm.NetworkGameState.Develop;

			this.stateText.setString(LocalizedString(this.team.markDeleted ? "NetTeamDeleted" : state));
			this.team.state = state;
		}
		else
		{
			this.stateText.setString(LocalizedString(this.team.markDeleted ? "NetTeamDeleted" : this.team.state));
		}

		this.controlLayout.forceDoLayout();

		var isLastLevel = this.team.level === world.getLevelCount(this.team.game) - 1;
		this.changeLevel.setEnabledPrevLevelButton(this.team.transitionFinished && this.team.level !== 0);
		this.changeLevel.setEnabledNextLevelButton(this.team.transitionFinished && !isLastLevel);

		if (this.team.transitionFinished && this._callback)
			this._callback(TeamGameLayout.EVENT.TRANSITION_FINISHED, this.team.index);

		if (this.team.state === pm.NetworkGameState.ReadyToChangeLevel)
		{
			var currentDate = new Date();

			var timeOfSolving = Math.floor((currentDate - this.lastDate) / 1000);

			if (!this.team.solvingTimes)
				this.team.solvingTimes = {};

			this.team.solvingTimes[this.team.level] = timeOfSolving;

			this.lastDate = new Date();

			pm.sendCustomEvent(pm.UPDATE_TIME_TABLE);

			if (!isLastLevel)
			{
				if (this._autoChange)
					this._nextLevel();
				else
					this.changeLevel.animateNextLevelButton();
			}

			if(this._callback)
				this._callback(TeamGameLayout.EVENT.SEND_TIME_INFO, this.team.index);
		}
		else if (!isLastLevel)
		{
			this.changeLevel.stopAnimateNextLevelButton();
		}

		var serverItem = this._teamList.getItem(0);

		if(serverItem)
		{
			serverItem.label.setString(this.team.hostStudent.name);
			serverItem.status.loadTexture(this._getStudentStateSprite(this.team.hostStudent.state), ccui.Widget.PLIST_TEXTURE);
			serverItem.host.setVisible(true);
			serverItem.refresh();
		}

		for(var i = 0; i < this.team.students.length; ++i)
		{
			var item = this._teamList.getItem(i + 1);

			if(!item)
			{
				item = new TeamStudentLayout(this.team.students[i].name);
				this._teamList.pushBackCustomItem(item);
			}

			item.label.setString(this.team.students[i].name);
			item.status.loadTexture(this._getStudentStateSprite(this.team.students[i].state), ccui.Widget.PLIST_TEXTURE);
			item.host.setVisible(false);
			item.refresh();
		}
	},

	_getStudentStateSprite: function(state)
	{
		switch (state)
		{
			case pm.ControlledStudent.State.ConnectedToGame: return pm.spriteUtils.getInterfaceElementName("lightGreen");
			case pm.ControlledStudent.State.Disconnected: return pm.spriteUtils.getInterfaceElementName("lightRed");
			case pm.ControlledStudent.State.Connected:
			case pm.ControlledStudent.State.Connecting:
			case pm.ControlledStudent.State.PendingReconnect: return pm.spriteUtils.getInterfaceElementName("lightBlue");
		}

		return null;
	},

	setGameName: function(gameIndex)
	{
		var cutNumber = 40;
		if(cc.sys.isMobile)
			cutNumber = 31;

		this.gameLabel.setString(world.getGameName(gameIndex).substring(0, cutNumber));
		this.controlLayout.forceDoLayout();
	},

	setLevelName: function (gameIndex, levelIndex)
	{
		var cutNumber = 40;
		if(cc.sys.isMobile)
			cutNumber = 31;

		this.levelLabel.setString(world.getLevelName(gameIndex, levelIndex).substring(0, cutNumber));
		this.controlLayout.forceDoLayout();
	},

	_destroyTeam: function ()
	{
		if(this._callback)
			this._callback(TeamGameLayout.EVENT.DESTROY_TEAM, this.team.index);
	},

	_nextLevel: function(sender)
	{
		this.stateText.setString(LocalizedString(pm.NetworkGameState.Develop));
		this.changeLevel.stopAnimateNextLevelButton();
		this.changeLevel.setEnabledPrevLevelButton(false);
		this.changeLevel.setEnabledNextLevelButton(false);

		if(this._callback)
			this._callback(TeamGameLayout.EVENT.NEXT_LEVEL, this.team.index);
	},

	_prevLevel: function(sender)
	{
		this.stateText.setString(LocalizedString(pm.NetworkGameState.Develop));
		this.changeLevel.stopAnimateNextLevelButton();
		this.changeLevel.setEnabledPrevLevelButton(false);
		this.changeLevel.setEnabledNextLevelButton(false);

		if(this._callback)
			this._callback(TeamGameLayout.EVENT.PREV_LEVEL, this.team.index);
	},

	_editLevel: function()
	{
		this.stateText.setString(LocalizedString(pm.NetworkGameState.Develop));

		this.chooseGameLevelLayout = new ChooseLevelLayer(this._okEditLevel, this);
		cc.director.getRunningScene().addChild(this.chooseGameLevelLayout, 1000);
	},

	_okEditLevel: function()
	{
		this.team.game = this.chooseGameLevelLayout.gameIndex;
		this.team.level = this.chooseGameLevelLayout.levelIndex;

		this.setGameName(this.team.game);
		this.setLevelName(this.team.game, this.team.level);

		this.chooseGameLevelLayout.removeFromParent();

		this.team.state = pm.NetworkGameState.Develop;
		this.team.statesMap = {};

		if(this._callback)
			this._callback(TeamGameLayout.EVENT.EDIT_LEVEL, this.team.index);
	}
});

TeamGameLayout.EVENT = {
	DESTROY_TEAM: 0,
	NEXT_LEVEL: 1,
	PREV_LEVEL: 2,
	EDIT_LEVEL: 3,
	SEND_TIME_INFO: 4,
	TRANSITION_FINISHED: 5
};

var TeamStudentLayout = ccui.Layout.extend({

	label: null,
	status: null,
	host: null,

	ctor: function (studentName)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		this.host = new ccui.ImageView(pm.spriteUtils.getInterfaceElementName("starHost"), ccui.Widget.PLIST_TEXTURE);
		this.host.setVisible(false);

		var hostAlign = new ccui.RelativeLayoutParameter();
		hostAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
		hostAlign.setRelativeName("host");
		hostAlign.setMargin(TeamGameLayout.MEDIUM_SEPARATOR, 0, 0, 0);
		this.host.setLayoutParameter(hostAlign);

		this.addChild(this.host);

		this.label = new ccui.Text(studentName, pm.settings.fontName, 18);
		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		labelAlign.setRelativeName("label");
		labelAlign.setRelativeToWidgetName("host");
		labelAlign.setMargin(TeamGameLayout.MEDIUM_SEPARATOR, 0, 0, 0);
		this.label.setLayoutParameter(labelAlign);

		this.addChild(this.label);

		this.status = new ccui.ImageView(pm.spriteUtils.getInterfaceElementName("lightRed"), ccui.Widget.PLIST_TEXTURE);
		var statusAlign = new ccui.RelativeLayoutParameter();
		statusAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_CENTER_VERTICAL);
		statusAlign.setMargin(0, 0, TeamGameLayout.MEDIUM_SEPARATOR, 0);
		this.status.setLayoutParameter(statusAlign);

		this.setContentSize(this.host.width + TeamGameLayout.NAME_LIMIT + this.status.width + 4 * TeamGameLayout.MEDIUM_SEPARATOR,
			Math.max(this.host.height, this.label.height, this.status.height));

		this.addChild(this.status);
	},

	refresh: function()
	{
		this.forceDoLayout();
	}
});

TeamGameLayout.SMALL_SEPARATOR = 5;
TeamGameLayout.MEDIUM_SEPARATOR = 10;
TeamGameLayout.BUTTON_SEPARATOR = 8;
TeamGameLayout.ITEM_SEPARATOR = 1;
TeamGameLayout.BORDER = 15;
TeamGameLayout.NAME_LIMIT = 120;
TeamGameLayout.SCROLL_BAR_WIDTH = 8;
