/**
 * Created by Ekaterina Ermishkina on 18.08.16.
 */

/**
 * @class Light Level
 * @implements pm.AbstractLevel
 */
pm.data.LightLevel = pm.AbstractLevel.extend(/** @lends pm.data.LightLevel# */{
	typeName: "LightLevel",
	/**
	 * Robots can be indicated.
	 * @type {Boolean}
	 */
	robotsCanBeIndicated: false,

	ctor: function()
	{
		this._super();
	},

	getType: function() { return pm.LightLevelModule.Type; }
});
