/**
 * Created by Kirill Mashchenko on 06.12.2017.
 */

/**
 * @class 2D sprite of {pm.data.PullRobot}.
 * @implements pm.IsoRobotSprite
 */
var PullRobotSprite = pm.IsoRobotSprite.extend(/** @lends PullRobotSprite# */{
	ctor: function()
	{
		cc.animationCache.addAnimations("Robot/pull_robot-animations.plist");

		pm.spriteUtils.registerTempSpriteFrames("Robot/pull_robot.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/pull_robot-indicate.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/pull_robot-broken.plist");

		this._super(cc.spriteFrameCache.getSpriteFrame(this._getFrameName(0)));

		this._minPoint = cc.p(52, 35);
		this._maxPoint = cc.p(52, 5);
	},

	_getActiveSpritePosition: function()
	{
		return cc.p(50, 82);
	},

	_getCommandsSpritePosition: function()
	{
		return cc.p(50, 113);
	},

	_getFrameName: function(direction)
	{
		return "pull_robot{0}.png".format(4 * direction);
	},

	_getTurnAnimationName: function(oldDirection, curDirection)
	{
		return "pull_robot_{0}->{1}".format(oldDirection, curDirection);
	},

	_getDestroyAnimationName: function(direction)
	{
		return "pull_robot_d_{0}".format(direction);
	},

	_getIndicateAnimationName: function(direction)
	{
		return "pull_robot_i_{0}".format(direction);
	}
});
