/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class View for replace places on program layer
 * @extends ccui.Layout
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.NodeLayer = pmui.Layout.extend(/** @lends pmui.NodeLayer# */{
	_id: 0,
	type: 0,
	_syntaxModule: null,
	_treeNode: null,
	_selected: false,
	_parentNode: null,
	_lastNodeId: 0,
	_repTypes: [],
	_addTypes: [],
	_withBorder: false,
	_withRoundBorder: false,
	_isEditable: true,
	isPartlyClonable: false,

	ctor: function (parentNode, treeNode, syntaxModule)
	{
		this._super();
		this.setLayoutType(ccui.Layout.LINEAR_VERTICAL);

		if (syntaxModule !== undefined)
			this._syntaxModule = syntaxModule;
		else
			this._syntaxModule = parentNode.getSyntaxModule();

		pmui.NodeLayer.SEPARATORY = pm.syntaxModules.isTextual(this.getSyntaxModule()) ? 0 : 5;

		this._treeNode = treeNode;
		this._parentNode = parentNode;
		this._id = this.getNodeId();

		if(treeNode)
		{
			this._treeNode.setNodeView(this);
			this._treeNode.id = this._id;
		}
		pm.registerCustomEventListener(pm.RESET_HIGHLIGHT, function (event)
		{
			this.drawBackground();
			this._selected = false;
		}.bind(this), this);
		pm.registerCustomEventListener(pm.DESELECT_NODE, function (event)
		{
			this.deSelect();
		}.bind(this), this);
		this.drawBackground();
	},

	drawBackground: function()
	{
		if (pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return;

		this.setBackGroundImageScale9Enabled(true);
		if (this.isEditable() && this._withBorder)
		{
			this.setBackGroundImage("PiktomirK/border.png");
			this.setBackGroundImageCapInsets(cc.rect(15, 15, 10, 10));
		}
		else if (this.isEditable() && this._withRoundBorder)
		{
			this.setBackGroundImage("PiktomirK/round-border.png");
			this.setBackGroundImageCapInsets(cc.rect(20, 20, 1, 1));
		}
		else if (!this.isEditable() && this._withRoundBorder)
		{
			this.setBackGroundImage("PiktomirK/round-blocked.png");
			this.setBackGroundImageCapInsets(cc.rect(19, 19, 2, 2));
		}
		else if (!this.isEditable() && this._withBorder)
		{
			this.setBackGroundImage("PiktomirK/blocked.png");
			this.setBackGroundImageCapInsets(cc.rect(10, 10, 20, 20));
		}
		else {
			this.removeBackGroundImage();
		}
	},

	highlight: function()
	{
		this.setBackGroundImageScale9Enabled(true);
		if(this._withRoundBorder)
		{
			this.setBackGroundImage("PiktomirK/round-border-select.png");
			this.setBackGroundImageCapInsets(cc.rect(19, 19, 2, 2));
		}
		else
		{
			this.setBackGroundImage("PiktomirK/highlight.png");
			this.setBackGroundImageCapInsets(cc.rect(15, 15, 10, 10));
		}
	},

	highlightBroken: function()
	{
		this.setBackGroundImageScale9Enabled(true);
		if(this._withRoundBorder)
		{
			this.setBackGroundImage("PiktomirK/round-broken.png");
			this.setBackGroundImageCapInsets(cc.rect(19, 19, 2, 2));
		}
		else
		{
			this.setBackGroundImage("PiktomirK/broken.png");
			this.setBackGroundImageCapInsets(cc.rect(15, 15, 10, 10));
		}
	},

	removeHighlight: function()
	{
		pm.syntaxModules.isTextual(this.getSyntaxModule()) ? this.removeBackGroundImage() : this.drawBackground();
	},

	select: function()
	{
		if(!this._selected)
		{
			this.setBackGroundImageScale9Enabled(true);
			if(this._withRoundBorder)
			{
				this.setBackGroundImage("PiktomirK/round-border-select.png");
				this.setBackGroundImageCapInsets(cc.rect(19, 19, 2, 2));
			}
			else
			{
				this.setBackGroundImage("PiktomirK/select-border.png");
				this.setBackGroundImageCapInsets(cc.rect(15, 15, 10, 10));
			}
			this._selected = true;
			this.setTag(ProgramContainerLayer.SELECTED_TAG);
		}
	},

	deSelect: function()
	{
		if(this._selected)
		{
			this.drawBackground();
			this._selected = false;
			this.setTag(ProgramContainerLayer.NOT_SELECTED_TAG);
		}
	},

	removeCallbackAction: function ()
	{
		var action = cc.moveBy(0.1, cc.p(15, 0));
		var revAction = cc.moveBy(0.1, cc.p(-15, 0));

		this.runAction( new cc.Sequence(revAction, action));
	},

	calculateSize: function (isPar)
	{
		this.sizeOfVContainer(this);
		if(!isPar)
			this._parentNode.calculateSize();
	},

	createText: function(text, color, fontSize)
	{
		var text = new ccui.Text(
			LocalizedString(text),
			ProgramContainerLayer.FONT_NAME,
			fontSize? fontSize : ProgramContainerLayer.FONT_SIZE
		);
		text.setColor(color ? color : pmui.NodeLayer.MAIN_COLOR);
		text.setTextVerticalAlignment(cc.TEXT_ALIGNMENT_CENTER);
		return text;
	},

	canAdd: function(element)
	{
		if(this._addTypes.indexOf(element) !== -1 )
			return true;
		return false;
	},

	canReplace: function(element)
	{
		if(this._repTypes.indexOf(element) !== -1 )
			return true;
		return false;
	},

	sizeOfContainer: function (cont, setSize)
	{
		var width = 0;
		var height = 0;
		var mX = 0;
		var mY = 0;
		cont.getChildren().forEach(function(child)
		{
			mX = 0;
			mY = 0;
			var margin = child.getLayoutParameter().getMargin();
			if (margin)
			{
				mY = margin.top + margin.bottom;
				mX = margin.left + margin.right;
			}
			width += child.width + mX;
			height = Math.max(height, child.height + mY);
		});
		if(!setSize)
			cont.setContentSize(width, height);
		return cc.size(width, height);
	},

	sizeOfVContainer: function (cont, setSize)
	{
		var width = 0;
		var height = 0;
		var mX = 0;
		var mY = 0;
		cont.getChildren().forEach(function(child)
		{
			mX = 0;
			mY = 0;
			var margin = child.getLayoutParameter().getMargin();
			if (margin)
			{
				mY = margin.top + margin.bottom;
				mX = margin.left + margin.right;
			}
			width = Math.max(child.width + mX, width);
			height += child.height + mY;
		});
		if(!setSize)
			cont.setContentSize(width, height);
		return cc.size(width, height);
	},

	_removeNode: function(node)
	{
		var parent = node._parentNode._treeNode;
		parent.removeChild(node._treeNode);
		this.removeChild(node);
		node = null;
	},

	_removeNodeFromTree: function()
	{
		this.removeLinks();
		if (this.isMain() || this.isBody() || this.isProgram())
			return;

		var prevNode = this._parentNode._removeNode(this);

		var focusNode = prevNode ? prevNode : this._parentNode;
		if(focusNode)
			focusNode.calculateSize();
		return focusNode;
	},

	getNodeId: function()
	{
		return this._parentNode._id + "_" + this._parentNode._lastNodeId++;
	},

	setBody: function(node)
	{
		this._bodyNode.set(node);
		this._bodyNode.calculateSize(true);
		return this._bodyNode;
	},

	removeLinks: function ()
	{
		this.makeCleanUp();
	},

	makeCleanUp: function()
	{
		this.getChildrenNodes().forEach(function (node){
			if(node instanceof pmui.NodeLayer)
				node.makeCleanUp();
		});
	},

	getTagName: function()
	{
		return this._treeNode.getTagName();
	},

	createALignSome: function(layer, neighbour, name, type, left, top, right, bottom)
	{
		var align = layer.getLayoutParameter();
		if(!align)
			align = new ccui.RelativeLayoutParameter();

		align.setAlign(type);
		if(neighbour !== "")
			align.setRelativeToWidgetName( neighbour);
		if(name !== "")
			align.setRelativeName(name);
		if(top !== undefined)
			align.setMargin(left, top, right, bottom);
		layer.setLayoutParameter(align);
	},

	setNonEditable: function()
	{
		if(pm.settings.isEditorMode)
		{
			if(this._treeNode) {
				this._treeNode.setNonEditable();
				this.drawBackground();
				return true;
			}
			else return false;
		}
		return false;
	},

	isEditable: function()
	{
		return this._treeNode ? this._treeNode.isEditable() : true;
	},

	isSelected: function ()
	{
		return this._selected;
	},

	getSyntaxModule: function ()
	{
		return this._syntaxModule;
	},

	getChildrenNodes: function() { return [];},

	isMain: function() { return false;},
	isBody: function() { return false;},
	isFunction: function () { return false;},
	isHorizontalBar: function() { return false;},
	isProgram: function() { return false;},
	isAlloc: function() {return false;},
	isNumber:function() { return false;},
	isMethod: function() { return false;},
	isEmpty: function() { return false;},
	isElseBlock: function() { return false;},
	isExpression: function() { return false;},
	isReplaceable: function() { return false;},

	_drawNode: function() {},
	clickCallback: function () {},
	clone: function() {},
});

pmui.NodeLayer.SEPARATORY = 5;
pmui.NodeLayer.SEPARATORX = 5;
pmui.NodeLayer.IMAGE_TAG = 999;
pmui.NodeLayer.MAIN_COLOR = cc.color(0, 0, 204);
pmui.NodeLayer.CONSTR_COLOR = cc.color(0, 0, 0);
pmui.NodeLayer.CONSTR_COLOR = cc.color(0, 0, 0);
pmui.NodeLayer.ALLOC_COLOR = cc.color(175, 90, 0, 0);
pmui.NodeLayer.CONST_COLOR = cc.color(60,175,255);
pmui.NodeLayer.USE_COLOR = cc.color(0, 178, 0);
pmui.NodeLayer.VAR_COLOR = cc.color(92, 108, 118);
