/**
 * Created by danilaeremin on 11.08.15.
 */

/**
 * @class Layer for common player robot settings.
 * @extends ccui.Layout
 * @constructor
 * @param {pm.AbstractRobot} robot Robot which settings need to display.
 */
var PlayerRobotInterfaceLayerP = ccui.Layout.extend(/** @lends PlayerRobotSettingsLayer# */{
	_robot: null,
	_maxFunctions: null,
	_maxMethods: null,
	_maxRepeaters: null,
	_maxConditions: null,
	_maxCondRepeaters: null,
	_maxDepthConstruct: null,

	ctor: function(robot)
	{
		this._super();

		this._robot = robot;

		this.setLayoutType(ccui.Layout.RELATIVE);

		this._fillSettingsP();
	},

	_fillSettingsP: function()
	{
		var maxFunctionLabel = new pmui.Text(LocalizedString("MaxFunctions"), 15);

		var maxFunctionsLabelAlign = new ccui.RelativeLayoutParameter();
		maxFunctionsLabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		maxFunctionsLabelAlign.setRelativeName("MaxFunctions");
		maxFunctionsLabelAlign.setMargin(0, 10, 0, 0);
		maxFunctionLabel.setLayoutParameter(maxFunctionsLabelAlign);
		this.addChild(maxFunctionLabel);

		this._maxFunctions = new pmui.TextInput(this._robot.getProgramData().maxFunctionCount, "0", 16, 40);
		this._createTextInputAlign("MaxFunctions", this._maxFunctions);

		this._createLabel("MaxMethods", "MaxFunctions");
		this._maxMethods = new pmui.TextInput(this._robot.getProgramData().maxMethodCount, "0", 16, 40);
		this._createTextInputAlign("MaxMethods", this._maxMethods);

		this._createLabel("MaxRepeaters", "MaxMethods");
		this._maxRepeaters = new pmui.TextInput(this._robot.getProgramData().maxRepeaterCount, "0", 16, 40);
		this._createTextInputAlign("MaxRepeaters", this._maxRepeaters);

		this._createLabel("MaxConditions", "MaxRepeaters");
		this._maxConditions = new pmui.TextInput(this._robot.getProgramData().maxConditionCount, "0", 16, 40);
		this._createTextInputAlign("MaxConditions", this._maxConditions);

		this._createLabel("MaxCondRepeaters", "MaxConditions");
		this._maxCondRepeaters = new pmui.TextInput(this._robot.getProgramData().maxCondRepeaterCount, "0", 16, 40);
		this._createTextInputAlign("MaxCondRepeaters", this._maxCondRepeaters);

		this._createLabel("MaxDepthConstruct", "MaxCondRepeaters");
		this._maxDepthConstruct = new pmui.TextInput(this._robot.getProgramData().maxDepthConstructCount, "0", 16, 40);
		this._createTextInputAlign("MaxDepthConstruct", this._maxDepthConstruct);

		this._maxFunctions.setInsertTextCallback(this, this._maxFunctionsChanged);
		this._maxMethods.setInsertTextCallback(this, this._maxMethodsChanged);
		this._maxRepeaters.setInsertTextCallback(this, this._maxRepeatersChanged);
		this._maxConditions.setInsertTextCallback(this, this._maxConditionsChanged);
		this._maxCondRepeaters.setInsertTextCallback(this, this._maxCondRepeatersChanged);
		this._maxDepthConstruct.setInsertTextCallback(this, this._maxDepthConstructChanged);

		var height =
			this._maxFunctions.height + this._maxMethods.height
			+ this._maxRepeaters.height + this._maxConditions.height
			+ this._maxCondRepeaters.height + this._maxDepthConstruct.height;

		this.setContentSize(LevelSettingsLayer.WIDTH, height);
	},

	_createLabel: function(name, relativeToName)
	{
		var maxFunctionLabel = new pmui.Text(LocalizedString(name), 15);

		var maxFunctionsLabelAlign = new ccui.RelativeLayoutParameter();
		maxFunctionsLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		maxFunctionsLabelAlign.setRelativeName(name);
		maxFunctionsLabelAlign.setRelativeToWidgetName(relativeToName);
		maxFunctionsLabelAlign.setMargin(0, 10, 0, 0);
		maxFunctionLabel.setLayoutParameter(maxFunctionsLabelAlign);
		this.addChild(maxFunctionLabel);
	},

	_createTextInputAlign: function(name, obj)
	{
		var maxMethodsAlign = new ccui.RelativeLayoutParameter();
		maxMethodsAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		maxMethodsAlign.setRelativeToWidgetName(name);
		maxMethodsAlign.setMargin(5, 0, 0, 0);
		obj.setLayoutParameter(maxMethodsAlign);
		this.addChild(obj);
	},

	_maxFunctionsChanged: function ()
	{
		this._robot.getProgramData().maxFunctionCount = Number(this._maxFunctions.getString());
	},

	_maxMethodsChanged: function ()
	{
		this._robot.getProgramData().maxMethodCount = Number(this._maxMethods.getString());
	},

	_maxRepeatersChanged: function()
	{
		this._robot.getProgramData().maxRepeaterCount = Number(this._maxRepeaters.getString());
	},

	_maxConditionsChanged: function()
	{
		this._robot.getProgramData().maxConditionCount = Number(this._maxConditions.getString());
	},

	_maxCondRepeatersChanged: function()
	{
		this._robot.getProgramData().maxCondRepeaterCount = Number(this._maxCondRepeaters.getString());
	},

	_maxDepthConstructChanged: function()
	{
		this._robot.getProgramData().maxDepthConstructCount = Number(this._maxDepthConstruct.getString());
	}
});

PlayerRobotSettingsLayer.MAX_PATTERN_COUNT = 12;
