/**
 * Created by Nikita Besshaposhnikov on 07.11.14.
 */

/**
 * @class This a interface for all map elements.
 * @see pm.AbstractMap#mapElements
 * @interface
 * @extends pm.Class
 */
pm.MapElement = pm.Class.extend(/** @lends pm.MapElement#*/{
	/**
     * Current type of map element
     * @private
     * @type {Number | String}
     */
	_type: -1,
	/**
     * Original type of map element
     * @type {Number | String}
     */
	originalType: -1,

	/**
     * "Map" robot.id-robot of all robots that are in this element.
     * @protected
     * @type {Object}
     */
	_robotsHere: {},
	/**
     * "Map" object.id-object of all objects that are in this element.
     * @protected
     * @type {Object}
     */
	_objectsHere: {},

	/**
     * Robot id for which this is a start element.</br>
     * If this element is not start for robot then value is {@link pm.MapElement.START_FOR_NO_ROBOT}
     * @default
     * @type {Number}
     */
	startForRobot: -1,
	/**
     * Free-form data for robot positioning, if this element is start for robot.
     * @see pm.MapElement#startForRobot
     * @type {Object}
     */
	startRobotData: {},

	sprite: null,

	_notCompletedSprite: null,

	/**
     * Generates Terrain sprite
     * @returns {TerrainSprite}
     */
	generateTerrainSprite: function() {},

	/**
     * Sets Type
     * @param {String} type Type
     */
	setType: function (type){},

	/**
     * Gets Type
     */
	getType: function (){},

	ctor: function()
	{
		this._addNonEnumerableProps("_type", "_robotsHere", "_objectsHere", "sprite", "_notCompletedSprite");

		this._super();
		this._type = this.originalType;
	},

	/**
     * Inits element with type.</br> Sets type and originalType.
     */
	initElement: function()
	{
		this._type = this.originalType;
	},

	/**
     * Sets type to original.Also removes robots from this element.
     */
	restore: function()
	{
		this._robotsHere = {};
		this.setType(this.originalType);
	},

	/**
     * Adds robot here.
     * @param {pm.AbstractRobot} robot
     */
	addRobot: function(robot)
	{
		this._robotsHere[robot.id] = robot;
	},
	/**
     * Removes robot here.
     * @param {pm.AbstractRobot} robot
     */
	removeRobot: function(robot)
	{
		delete this._robotsHere[robot.id];
	},

	/**
     * Removes all robots here.
     */
	removeAllRobots: function()
	{
		this._robotsHere = {};
	},

	/**
     * Checks if element has a robot.
     * @param {pm.AbstractRobot} robot
     * @returns {boolean}
     */
	containsRobot: function(robot)
	{
		return this._robotsHere.hasOwnProperty(robot.id);
	},

	/**
     * Checks if element has a robot id.
     * @param {Number|String} id
     * @returns {boolean}
     */
	containsRobotID: function(id)
	{
		return this._robotsHere.hasOwnProperty(id);
	},

	/**
     * Adds object here.
     * @param {pm.Object} object
     */
	addObject: function(object)
	{
		this._objectsHere[object.id] = object;
	},

	/**
     * Removes object here.
     * @param {pm.Object} object
     */
	removeObject: function(object)
	{
		delete this._objectsHere[object.id];
	},

	/**
     * Returns object count here.
     */
	getObjectCount: function()
	{
		return Object.keys(this._objectsHere).length;
	},

	/**
     * Returns robot count here.
     */
	getRobotsCount: function()
	{
		return Object.keys(this._robotsHere).length;
	},

	/**
     * Removes all objects here.
     */
	removeAllObjects: function()
	{
		this._objectsHere = {};
	},

	/**
     * Returns object by index in containing array.
     * @param {Number} index Array index.
     * @returns {pm.Object}
     */
	getObjectByKeyIndex: function(index)
	{
		return this._objectsHere[Object.keys(this._objectsHere)[index]];
	},

	/**
     * Checks if element has an objects.
     * @param {pm.Object} object
     * @returns {boolean}
     */
	containsObject: function(object)
	{
		return this._objectsHere.hasOwnProperty(object.id);
	},

	getState: function () {},

	setState: function () {},

	setNotCompleted: function(flag)
	{
		if (this._notCompletedSprite)
		{
			this._notCompletedSprite.setVisible(flag);

			if (flag)
			{
				this._notCompletedSprite.runAction(cc.repeatForever(cc.sequence(
					cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.9), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.8 * 255)),
					cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, 1), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 255))
				)));
			}
			else
			{
				this._notCompletedSprite.stopAllActions();
			}
		}
	}

});

/**
 * This constant is used when pm.MapElement is not a start for robot.
 * @const
 * @default
 * @see pm.MapElement
 * @type {Number}
 */
pm.MapElement.START_FOR_NO_ROBOT = -1;
