/**
 * Created by Kirill Mashchenko on 25.01.18.
 */

pm.OrangeFlagRobot = pm.FlagRobot.extend(/** @lends pm.OrangeFlagRobot# */{
	typeName: "OrangeFlagRobot",

	ctor: function()
	{
		this._super();

		this.nativeFunctionMap[pm.OrangeFlagRobot.NativeMethod.Up] = new pm.NativeFunction(this, this._up);
		this.nativeFunctionMap[pm.OrangeFlagRobot.NativeMethod.Down] = new pm.NativeFunction(this, this._down);

		this.nativeFunctions.push(pm.OrangeFlagRobot.NativeMethod.Up, pm.OrangeFlagRobot.NativeMethod.Down);

		this.conditions.push(pm.OrangeFlagRobot.Condition.IsUp, pm.OrangeFlagRobot.Condition.IsDown);
	},

	_checkConditionInternal: function (condition, args)
	{
		switch(condition)
		{
			case pm.OrangeFlagRobot.Condition.IsUp: return this._flagState === pm.FlagRobot.State.Up;
			case pm.OrangeFlagRobot.Condition.IsDown: return this._flagState === pm.FlagRobot.State.Down;
		}

		return false;
	},

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new pm.FlagRobotSprite(pm.FlagRobotSprite.Type.Orange);

			return this.sprite;
		}
	},

	getType: function ()
	{
		return pm.GlobalRobotType.OrangeFlag;
	}
});

pm.OrangeFlagRobot.NativeMethod = {
	Up: "oflag_up",
	Down: "oflag_down"
};

pm.OrangeFlagRobot.Condition = {
	IsUp: "oflag_isup",
	IsDown: "oflag_isdown"
};
