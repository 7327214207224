/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class Layer for {@link pm.data.DraftsmanMap}.
 * @implements SquarableMapLayer
 */
var DraftsmanMapLayer = SquarableMapLayer.extend(/** @lends DraftsmanMapLayer#*/{

	drawLayer: null,
	badDrawLayer: null,
	taskDrawLayer: null,

	ctor: function(map)
	{
		this._super(map);
		this._orientation = pm.MapLayer2D.Orientation.Ortho;
		this._mapElementSize = cc.size(64, 64);

		pm.registerCustomEventListener(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR, this._updateMapElementsNumbers.bind(this), this);
	},

	hasChangeOrientationOption: function()
	{
		return false;
	},

	_drawMapElements: function()
	{
		for (var x = 0; x < this._map.width; ++x)
		{
			for (var y = 0; y < this._map.height; ++y)
			{
				var p = cc.p(x, y);
				var terrainSprite = this._map.element(p).generateTerrainSprite();

				terrainSprite.getSprite().setPosition(this.realPosition(p, cc.p(0, 0)));
				this.addTerrainObject(terrainSprite, 0);

				if (this._map.element(p).startForRobot !== pm.MapElement.START_FOR_NO_ROBOT)
					this.drawRobotStartPositionMark(this._map.element(p).startForRobot, p);
			}
		}

		this._updateMapElementsNumbers();
		this.drawTask();
	},

	_updateMapElementsNumbers: function()
	{
		if (this._previewDraw)
			return;

		var startElementPos = this._map.getStartElementPos();

		for (var x = 0; x < this._map.width; ++x)
		{
			for (var y = 0; y < this._map.height; ++y)
			{
				var p = cc.p(x, y);
				this._map.element(p).updateElementNumber(cc.p(x - startElementPos.x, startElementPos.y - y));
			}
		}
	},

	_drawOrthoLayers: function(previewDraw)
	{
		pm.MapLayer2D.prototype._drawOrthoLayers.call(this, previewDraw);

		this.drawLayer = new cc.Layer();

		this.drawLayer.setCascadeOpacityEnabled(true);
		this.drawLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.drawLayer.setPosition(this._containerShift);

		this.badDrawLayer = new cc.DrawNode();

		this.badDrawLayer.setVisible(false);
		this.badDrawLayer.setCascadeOpacityEnabled(true);
		this.badDrawLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.badDrawLayer.setPosition(this._containerShift);

		this.taskDrawLayer = new cc.DrawNode();

		this.taskDrawLayer.setCascadeOpacityEnabled(true);
		this.taskDrawLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.taskDrawLayer.setPosition(this._containerShift);

		this.objectLayer.setLocalZOrder(2);
		this.addChild(this.taskDrawLayer, 1);
		this.addChild(this.drawLayer, 1);
		this.addChild(this.badDrawLayer, 1);
	},

	setNotCompleted: function(flag)
	{
		if (flag)
		{
			for (var i = 0; i < this._map.badLines.length; ++i)
			{
				var line = this._map.badLines[i];
				this.badDrawLayer.drawSegment(this.realPosition(line.oldPos, cc.p(0, 0)),
					this.realPosition(line.newPos, cc.p(0, 0)), DraftsmanMapLayer.BAD_LINE_WIDTH, cc.color.RED);
			}
		}
		else
		{
			this.badDrawLayer.clear();
			this._map.badLines = [];
		}

		this.badDrawLayer.setVisible(flag);
	},

	drawTask: function ()
	{
		var task = this._map.parentLevel.taskList.getTask(pm.DraftsmanLevelModule.Type);

		if (!task)
			return;

		var mapIndex = this._map.parentLevel.getActiveMapIndex();

		for (var i = 0; i < task.data[mapIndex].length; ++i)
		{
			var line = task.data[mapIndex][i];
			this.taskDrawLayer.drawSegment(this.realPosition(line.oldPos, cc.p(0, 0)),
				this.realPosition(line.newPos, cc.p(0, 0)), DraftsmanMapLayer.LINE_WIDTH, DraftsmanMapLayer.TASK_LINE_COLOR);
		}
	},

	clean: function()
	{
		pm.MapLayer2D.prototype.clean.call(this);

		this.drawLayer.removeAllChildren();
	}
});

DraftsmanMapLayer.LINE_WIDTH = 4;
DraftsmanMapLayer.BAD_LINE_WIDTH = 2;
DraftsmanMapLayer.TASK_LINE_COLOR = cc.color(0, 0, 255);
