/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.HorizontalBarNode = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{

	ctor: function (treeNode, parent, syntaxModule, childNumber)
	{
		this._withBorder = true;
		this._super(parent, treeNode, syntaxModule);
		this.setLayoutType(ccui.Layout.RELATIVE);

		this._addTypes = [FunctionButton.Type.Method];
		this.forceDoLayout();
		this.calculateSize(true);
	},

	addChildNode: function (node, placementTag)
	{
		if(!placementTag)
		{
			if(this.getChildrenCount())
			{
				var childCount = this.getChildrenCount();
				this.createALignSome(node, pmui.HorizontalBarNode.CHILD_TAG.format(childCount - 1), pmui.HorizontalBarNode.CHILD_TAG.format(childCount), ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER,
					pmui.NodeLayer.SEPARATORX, 0,0,0);
				node._treeNode.setTagName(pmui.HorizontalBarNode.CHILD_TAG.format(childCount));
				this.addChild(node);
			}
			else
			{
				this.createALignSome(node, "", pmui.HorizontalBarNode.CHILD_TAG.format(0), ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL, pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORY,pmui.NodeLayer.SEPARATORX,pmui.NodeLayer.SEPARATORY);
				node._treeNode.setTagName(pmui.HorizontalBarNode.CHILD_TAG.format(0));
				this.addChild(node);
			}
		}
		else
		{
			var order = placementTag._treeNode.getTagName().replace(pmui.HorizontalBarNode.CHILD_TAG_name, "");
			var child = placementTag;

			var parent = this._treeNode;

			var align = child.getLayoutParameter().clone();
			node.setLayoutParameter(align);

			node._treeNode.setTagName(child._treeNode.getTagName());

			this.addChild(node);
		}
		this.calculateSize(true);
		return node;
	},

	_removeNode: function (node, isReplaced)
	{
		var order = node._treeNode.getTagName().replace(pmui.HorizontalBarNode.CHILD_TAG_name, "");
		if(isReplaced === false)
		{
			var treeNode = pm.data.SyntaxTree.createNode(this._treeNode, pm.data.Node.Type.Empty, "", node._treeNode);
			var replace = new pmui.ReplaceNode(this._addTypes,
				this,
				treeNode);
			var align = node.getLayoutParameter().clone();
			replace.setLayoutParameter(align);
			treeNode.setTagName(node._treeNode.getTagName());
			this.addChild(replace);
		}

		var parent = this._treeNode;
		parent.removeChild(node._treeNode);
		this.removeChild(node);
		this.calculateSize(true);
	},

	calculateSize: function (forParent)
	{
		var width = 0;
		var height = 0;
		for(var i = 0;i < this.getChildrenCount();i++)
		{
			width += this.getChildren()[i].width + pmui.NodeLayer.SEPARATORX;
			height = Math.max(height, this.getChildren()[i].height)
		}
		this.setContentSize(width + 30, height + pmui.NodeLayer.SEPARATORY * 2);
		if (!forParent)
			this._parentNode.calculateSize();
	},

	getChildrenNodes: function ()
	{
		return this.getChildren();
	},

	isHorizontalBar: function()
	{
		return true;
	},

	clone: function ()
	{
		var horBar = new pmui.HorizontalBarNode(null, this._parentNode);
		horBar.setContentSize(this.width, this.height);
		return horBar;
	},

	canAdd: function()
	{
		return false;
	}
});

pmui.HorizontalBarNode.CHILD_TAG = "hor_bar_kid{0}";
pmui.HorizontalBarNode.CHILD_TAG_name = "hor_bar_kid";
