/**
 * Created by MU on 20.04.2020.
 */

/**
 * @class Car object for carrying by {@link pm.data.TrainRobot}
 * @implements PushObject
 */
pm.data.TrainBarrel = TrainObject.extend(/** @lends pm.data.TrainBarrel# */{

	typeName: "TrainBarrel",

	getType: function() { return pm.TrainLevelModule.ObjectTypes.TrainBarrel; },

	generateSprite: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (previewDraw === undefined)
				previewDraw = false;

			this.sprite = new TrainBarrelSprite();
			this.updateNumberSprite();

			if (previewDraw)
				this.sprite.setIdVisible();

			this.generateObjectConnections();

			return this.sprite;
		}
	},

	generateObjectConnections: function()
	{
		TrainObject.prototype.generateObjectConnections.call(this);

		var width = this.sprite.sprite.getContentSize().width;
		var height = this.sprite.sprite.getContentSize().height;

		this.connectionSprites[MapDirection4.Left].setPosition(cc.p(width * 0.1, height * 0.95));
		this.connectionSprites[MapDirection4.Right].setPosition(cc.p(width * 0.9, height * 0.6));
		this.connectionSprites[MapDirection4.Up].setPosition(cc.p(width * 0.9, height * 0.95));
		this.connectionSprites[MapDirection4.Down].setPosition(cc.p(width * 0.1, height * 0.6));
	}
});
