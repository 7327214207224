/**
 * Created by Nikita Besshaposhnikov on 15.03.17.
 */

/**
 * @class Layer for displaying discovered clients in network.
 * @extends pmui.OvalLayout
 * @constructor
 */
var NetDiscoverLayer = pmui.OvalLayout.extend(/** @lends NetDiscoverLayer# */{

	_hidden: true,
	_clients: null,
	_clientHosts: [],

	_level: null,

	ctor: function(level)
	{
		this._super();
		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setCascadeOpacityEnabled(true);
		this.setContentSizeWithBorder(NetDiscoverLayer.WIDTH, NetDiscoverLayer.HEIGHT);

		this._level = level;

		this._clients = new ccui.ListView();

		this._clients.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._clients.setItemsMargin(NetDiscoverLayer.ROW_SEPARATOR);
		this._clients.setScrollBarEnabled(true);
		this._clients.setScrollBarOpacity(255 * 0.9);
		this._clients.setScrollBarWidth(5);

		var clientsAlign = new ccui.RelativeLayoutParameter();
		clientsAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		clientsAlign.setMargin(0, pmui.OvalLayout.STANDARD_BORDER_RADIUS, 0, 0);

		this._clients.setLayoutParameter(clientsAlign);

		var button = new pmui.InterfaceButton(this._sendGameRequest, this, LocalizedString("RequestGame"));

		var buttonAlign = new ccui.RelativeLayoutParameter();
		buttonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
		buttonAlign.setMargin(0, 0, 0, pmui.OvalLayout.STANDARD_BORDER_RADIUS);

		button.setLayoutParameter(buttonAlign);

		this._clients.setContentSize(NetDiscoverLayer.WIDTH, NetDiscoverLayer.HEIGHT - button.height - NetDiscoverLayer.ROW_SEPARATOR);

		this.addChild(button);
		this.addChild(this._clients);

		this.setOpacity(0);
	},

	/**
     * Shows layer.
     */
	show: function()
	{
		this.enabled = true;
		this._hidden = false;
		this.setOpacity(255);

		pm.networkUtils.startDiscover(this._discoverCallback.bind(this));
	},

	/**
     * Hides layer.
     */
	hide: function()
	{
		this.enabled = false;
		this._hidden = true;
		this.setOpacity(0);

		this._clients.removeAllChildren(true);
		this._clientHosts.length = 0;

		pm.networkUtils.stopDiscover();
	},

	/**
     * Returns if layer if hidden.
     * @returns {boolean}
     */
	isHidden: function()
	{
		return this._hidden;
	},

	_sendGameRequest: function()
	{
		var sendHosts = [];

		for(var i = 0; i < this._clientHosts.length; ++i)
		{
			if(this._clients.getItem(i).getChecked())
				sendHosts.push(this._clientHosts[i]);
		}

		if(sendHosts.length < this._level.robots.length - 1)
			return;

		for(var i = 0; i < sendHosts.length; ++i)
			pm.networkUtils.sendConnectRequest(sendHosts[i]);

		var waitLayer = new WaitNetworkGameLayer(true, this._level.robots.length);
		waitLayer.show();

		this.hide();
	},

	_discoverCallback: function(clients)
	{
		var checkedMap = {};

		for(var i = 0; i < this._clientHosts.length; ++i)
			checkedMap[this._clientHosts[i]] = this._clients.getItem(i).getChecked();

		this._clientHosts = [];

		this._clients.removeAllChildren(true);

		for(var i = 0; i < clients.length; ++i)
		{
			var clientBox = new pmui.CheckBox(clients[i].getData(), null, null, 15, checkedMap[clients[i].getHost()]);
			this._clients.pushBackCustomItem(clientBox);

			this._clientHosts.push(clients[i].getHost());
		}
	}

});

NetDiscoverLayer.WIDTH = 150;
NetDiscoverLayer.HEIGHT = 150;
NetDiscoverLayer.ROW_SEPARATOR = 5;
NetDiscoverLayer.ROW_HIEGHT = 20;
