/**
 * @class This is a common interface for all maps.
 * @interface
 * @extends pm.Class
 */
pm.AbstractMap = pm.Class.extend(/** @lends pm.AbstractMap#*/{

	/**
     * Tileset is set of images which represents displayed part of Map
     * @see pm.AbstractMap#mapLayer
     * @type {Number}
     */
	tileset: 0,

	/**
     * Parent level for map.
     * @type {pm.AbstractLevel}
     */
	parentLevel: {},
	/**
     * Array of MapElements
     * @type {Array<Array<pm.MapElement>>}
     */
	mapElements: [],
	/**
     * Array of objects
     * @type {Array<pm.Object>}
     */
	objects: [],
	/**
     * Displayed part of map.
     * @type {pm.MapLayer}
     */
	mapLayer: {},

	/**
	 * Object for patterns completed.
	 * Used only if there's one robot on the map!
	 * @type {Object}
	 */
	patternsCompleted: {},

	ctor: function()
	{
		this._addNonEnumerableProps("parentLevel", "mapLayer");

		this._super();
	},

	/**
     * Load initial data of level
     * @name pm.AbstractMap#load
     */
	load: function() {},
	/**
     * Cleans up level
     * @name pm.AbstractMap#clean
     */
	clean: function()
	{
		for(var j = 0; j < this.objects.length; ++j)
		{
			var object = this.objects[j];
			object.reset();
		}

		if(!CORE_BUILD)
		{
			if(this.mapLayer instanceof pm.MapLayer)
				this.mapLayer.clean();
		}
	},

	/**
     * Place robots on map layer.
     * @name pm.AbstractMap#placeRobots
     */
	spawnObjects: function()
	{
		for(var j = 0; j < this.objects.length; ++j)
		{
			var object = this.objects[j];

			object.parentMap = this;
			object.setPosition(object.startPosition);
		}

		if(!CORE_BUILD)
		{
			if(this.mapLayer instanceof pm.MapLayer)
				this.mapLayer.spawnObjects();

			for(var j = 0; j < this.objects.length; ++j)
			{
				var object = this.objects[j];
				object.reset();
			}
		}
	},

	/**
     * Place robots on map layer.
     * @param {Boolean} firstPlace Is it a first placing of robots on map layer.
     * @name pm.AbstractMap#placeRobots
     */
	placeRobots: function(firstPlace)
	{
		if(!CORE_BUILD)
		{
			if(this.mapLayer instanceof pm.MapLayer)
				this.mapLayer.placeRobots(firstPlace);
		}
	},

	/**
     * Removes robots from map.
     * @function
     * @name pm.AbstractMap#removeRobots
     */
	removeRobots: function()
	{
		if(!CORE_BUILD)
		{
			if(this.mapLayer instanceof pm.MapLayer)
				this.mapLayer.removeRobots();
		}
	},

	/**
     * Function for generating of mapLayer.
     * @function
     * @name pm.AbstractMap#generateMapLayer
     * @param {Boolean} previewDraw Is a preview draw of map.
     * @returns {?pm.MapLayer}
     */
	generateMapLayer: function(previewDraw){ return null; },

	/**
     * Return type of map.
     * @see pm.MapType
     * @function
     * @name pm.AbstractMap#getType
     * @returns {pm.MapType}
     */
	getType: function() { return 0; }
});
