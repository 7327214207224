/**
 * Created by Mashchenko Kirill on 21.04.2017.
 */
var LightMapEditorLayer = LightMapLayer.extend(SquarableMapEditorLayerHelper).extend({

	_needDrawFishes: function()
	{
		return false;
	},

	_mapElementClicked: function (pos)
	{
		if (this._tapType === EditorTapsType.ChangeMapElement)
		{
			var x = pos.x, y = pos.y;

			var type = this._map.element(pos).originalType;
			var flag = 0;

			if (((this._selectedObjectForTapType === LightMapElementType.BottomWater) &&
                ((x >= 1) && (x < this._map.width - 1) && (y >= 1) && (y < this._map.height - 1))) ||
                (this._selectedObjectForTapType !== LightMapElementType.BottomWater))
				flag = 1;

			if (flag === 1)
			{
				if (type !== this._selectedObjectForTapType)
				{
					this.setElement(pos, this._selectedObjectForTapType);
					pm.sendCustomEvent(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR);
				}
			}
		}
		else if (this._tapType === EditorTapsType.AddRobot)
		{
			if (this._map.element(pos).getRobotsCount() !== 0)
				return;

			var robot = pm.moduleUtils.generateRobot(this._map.getType(), this._selectedObjectForTapType);
			this._addRobot(pos, robot);
			this._onAddRobot(robot);

			this._setActiveRobot(robot);
		}
		else if (this._tapType === EditorTapsType.AddObject)
		{
			if (!pm.moduleUtils.canNotDragOrAddObject(this._map.getType(), this._map, pos) && (this._map.element(pos).getObjectCount() === 0))
				this._addObject(pos, pm.moduleUtils.generateObject(this._map.getType(), this._selectedObjectForTapType));
		}
		else if (this._tapType === EditorTapsType.AddClone)
		{
			if (this._map.element(pos).getRobotsCount() !== 0)
				return;

			var robot = pm.moduleUtils.generateRobot(this._map.getType(), this._activeRobot.getType());
			this._addClone(pos, robot);
			this._onAddRobot(robot);

			this._setActiveRobot(robot);
		}
	},

	_drawMapElement: function(pos)
	{
		var element = this._map.element(pos);

		var type = element.getType();
		element._type = type;
		element.originalType = type;

		if (type === LightMapElementType.Road || type === LightMapElementType.StopPoint)
		{
			var grassSprite = new pm.TerrainSprite(pm.spriteUtils.getMapGrassTileFrame(LightMapElementType.GreenGrass));
			grassSprite.getSprite().setPosition(this.realPosition(pos, cc.p(0, 0)));

			if (!(element.grass))
			{
				this.addTerrainObject(grassSprite, 0);
				element.grass = grassSprite;
			}

			var terrainSprite = element.generateTerrainSprite();
			terrainSprite.getSprite().setPosition(this.realPosition(pos, cc.p(-6, 6)));

			if (!(element.mainRoad))
			{
				this.addTerrainObject(terrainSprite, 1);
				element.mainRoad = terrainSprite;
			}

		}
		else if (type === LightMapElementType.GreenGrass)
		{
			var sprite = element.generateTerrainSprite();
			sprite.getSprite().setPosition(this.realPosition(pos, cc.p(0, 0)));

			if (!(element.grass))
			{
				this.addTerrainObject(sprite, 0);
				element.grass = sprite;
			}
		}
	},

	getMapElementSize: function()
	{
		if(this._previewDraw)
			return cc.size(this._mapElementSize.width, this._mapElementSize.height);
		else
			return cc.size(this._mapElementSize.width + 1, this._mapElementSize.height + 1);
	},

	createMapElement: function ()
	{
		var el = null;
		el = new pm.data.LightMapElement();
		el.originalType = LightMapElementType.GreenGrass;
		el.initElement();
		return el;
	},

	_deleteMapElement: function (pos)
	{
		var element = this._map.element(pos);

		if (element.grass)
		{
			this.removeTerrainObject(element.grass);
			element.grass = null;
		}

		if (element.mainRoad)
		{
			this.removeTerrainObject(element.mainRoad);
			element.mainRoad = null;
		}
	},

	moveElementsUp: function ()
	{
		for (var j = this._map.height - 2; j >= 0; --j)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this.setElement(cc.p(i, j + 1), elementType);
				this.setElement(cc.p(i, j), LightMapElementType.GreenGrass);
			}
		}
	},

	moveElementsDown: function ()
	{
		for (var j = 1; j < this._map.height; ++j)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this.setElement(cc.p(i, j - 1), elementType);
				this.setElement(cc.p(i, j), LightMapElementType.GreenGrass);
			}
		}
	},

	moveElementsLeft: function ()
	{
		for (var i = this._map.width - 2; i >= 0; --i)
		{
			for (var j = 0; j < this._map.height; ++j)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this.setElement(cc.p(i + 1, j), elementType);
				this.setElement(cc.p(i, j), LightMapElementType.GreenGrass);
			}
		}
	},

	moveElementsRight: function ()
	{
		for (var i = 1; i < this._map.width; ++i)
		{
			for (var j = 0; j < this._map.height; ++j)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this.setElement(cc.p(i - 1, j), elementType);
				this.setElement(cc.p(i, j), LightMapElementType.GreenGrass);
			}
		}
	}

});
