/**
 * Created by Nikita Besshaposhnikov on 10.11.14.
 */

/**
 * @class Common interface for all 2D robots.</br>
 * Provides move native functions and element&wall-checking conditions.
 * @interface
 * @extends pm.PlayerRobot2D
 */
var PlayerRobot4 = pm.PlayerRobot2D.extend(/** @lends PlayerRobot4# */{

	ctor: function()
	{
		this._super();

		this.nativeFunctionMap[PlayerRobot4.NativeMethod.Move] = new pm.NativeFunction(this, this._move);
		this.nativeFunctionMap[PlayerRobot4.NativeMethod.TurnLeft] = new pm.NativeFunction(this, this._turnLeft);
		this.nativeFunctionMap[PlayerRobot4.NativeMethod.TurnRight] = new pm.NativeFunction(this, this._turnRight);

		this.nativeFunctions.push(PlayerRobot4.NativeMethod.TurnLeft, PlayerRobot4.NativeMethod.TurnRight, PlayerRobot4.NativeMethod.Move);
	},

	getDirectionCount: function() { return 4; },

	setState: function (state)
	{
		if (state.direction !== this.direction)
		{
			var k = (state.direction - this.direction + 4) % 4;

			if (k === 1)
				this._turnLeft();
			else
				this._turnRight();
		}
		if (state.position !== this.position || this.isBroken())
			this._moveTo(state.position, true);
	},

	_getPhysicalConnectorState: function()
	{
		return {
			position: this.position,
			direction: this.direction
		};
	}
});

PlayerRobot4.Condition =
{
	Clear: "r4_clear",
	Wall: "r4_wall"
};

PlayerRobot4.NativeMethod =
{
	Move: "r4_move",
	TurnLeft: "r4_turn_left",
	TurnRight: "r4_turn_right",
	MoveReverse: "r4_move_reverse"
};
