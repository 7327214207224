/**
 * Created by Nikita Besshaposhnikov on 23.07.15.
 */

/**
 * Constant for a AppDelegate::applicationDidEnterBackground
 * @const
 * @default
 * @type {String}
 */
pm.GAME_ON_HIDE_EVENT = "game_on_hide";
/**
 * Constant for a AppDelegate::applicationWillEnterForeground
 * @const
 * @default
 * @type {String}
 */
pm.GAME_ON_SHOW_EVENT = "game_on_show";

/**
 * Constant for a robot win event by {@link pm.RobotManager}
 * @const
 * @default
 * @type {String}
 */
pm.ROBOT_WIN_EVENT_STR = "robot_win";

/**
 * Constant for a robot loose event by {@link pm.RobotManager}
 * @const
 * @default
 * @type {String}
 */
pm.ROBOT_LOOSE_EVENT_STR = "robot_loose";

/**
 * Constant for a robot failure event by {@link pm.RobotManager}
 * @const
 * @default
 * @type {String}
 */
pm.ROBOT_FAILURE_EVENT_STR = "robot_failure";

/**
 * Constant for a robot step count changed event.
 * @const
 * @default
 * @type {String}
 */
pm.ROBOT_STEP_COUNT_EVENT_STR = "robot_step_count";

/**
 * Constant for a highlight method button of {@link ProgramLayer} event by {@link pm.RobotManager}
 * @const
 * @default
 * @type {String}
 */
pm.HIGHLIGHT_METHOD_EVENT_STR = "highlight_method";

/**
 * Constant for a highlight method button of {@link ProgramLayer} event by {@link pm.RobotManager}
 * @const
 * @default
 * @type {String}
 */
pm.HIGHLIGHT_CONDITION_EVENT_STR = "highlight_condition";

/**
 * Constant for a highlight points in repeater button of {@link ProgramLayer} event by {@link pm.RobotManager}
 * @const
 * @default
 * @type {String}
 */
pm.HIGHLIGHT_POINT_IN_REP_EVENT = "highlight_rep";

/**
 * Constant for removing highlighted points in repeater button of {@link ProgramLayer} event by {@link pm.RobotManager}
 * @const
 * @default
 * @type {String}
 */
pm.RELOAD_REPEATERS_EVENT_STR = "reload_repeaters";

/**
 * Constant for a remove highlight method button of {@link ProgramLayer} event by {@link pm.RobotManager}
 * @const
 * @default
 * @type {String}
 */

pm.REM_LAST_HIGHLIGHT_EVENT_STR = "rem_block_highlight";

/**
 * Constant for highlight as broken method button of {@link ProgramLayer} event by {@link pm.RobotManager}
 * @const
 * @default
 * @type {String}
 */
pm.HIGHLIGHT_BROKEN_METHOD_EVENT_STR = "highlight_broken_method";

/**
 * Constant for a touch event on robots sprite.
 * @const
 * @default
 * @type {String}
 */
pm.ROBOT_TOUCH_EVENT_STR = "robot_touch_event";

/**
 * Constant for start program.
 * @const
 * @default
 * @type {String}
 */
pm.PROGRAM_START_EVENT_STR = "program_start_event";

/**
 * Constant for start program on all maps event.
 * @const
 * @default
 * @type {String}
 */
pm.PROGRAM_TEST_ALL_MAPS_EVENT_STR = "program_test_all_maps_event";

/**
 * Constant for test current robot program
 * @const
 * @default
 * @type {String}
 */
pm.TEST_CURRENT_ROBOT_EVENT_STR = "test_current_robot_event";
/**
 * Constant for test current robot program
 * @const
 * @default
 * @type {String}
 */
pm.CURRENT_ROBOT_TESTED_EVENT_STR = "current_robot_tested_event";

/**
 * Constant for start network game
 * @const
 * @default
 * @type {String}
 */
pm.START_NETWORK_GAME_EVENT_STR = "start_network_game_event";
/**
 * Constant for map completed event.
 * @const
 * @default
 * @type {String}
 */
pm.MAP_COMPLETED_STR = "map_completed_event";
/**
 * Constant for pattern completed event.
 * @const
 * @default
 * @type {String}
 */
pm.PATTERN_COMPLETED_STR = "pattern_completed_event";

pm.LAYER_ZOOM_IN_EVENT = "layer_zoom_in_event";

pm.LAYER_ZOOM_OUT_EVENT = "layer_zoom_out_event";

pm.ANIMATE_HIDE_BUTTON = "animate_hide_button";

pm.ANIMATE_RESTART_BUTTON = "animate_restart_button";
/**
 * Constant for stop program.
 * @const
 * @default
 * @type {String}
 */
pm.PROGRAM_STOP_EVENT_STR = "program_stop_event";

/**
 * Constant for restart program.
 * @const
 * @default
 * @type {String}
 */
pm.PROGRAM_RESTART_EVENT_STR = "program_restart_event";

/**
 * Constant for make step of program.
 * @const
 * @default
 * @type {String}
 */
pm.PROGRAM_MAKE_STEP_EVENT_STR = "program_make_step_event";

/**
 * Constant for a pattern changed event by {@link RobotManager}
 * @const
 * @default
 * @type {String}
 */
pm.PATTERN_CHANGED_EVENT_STR = "pattern_changed_event";

/**
 * Constant for a map changed event by {@link SelectMapMenu}
 * @const
 * @default
 * @type {String}
 */
pm.MAP_CHANGED_EVENT_STR = "map_changed_event";

/**
 * Constant for a map dragged event by {@link pm.MapsContainerLayer}
 * @const
 * @default
 * @type {String}
 */
pm.MAP_DRAGGED_EVENT_STR = "map_dragged_event";

pm.BACK_TO_MENU = "back_to_menu";

pm.CLEAR_CACHE = "clear_cache";

pm.CLEAN_CLIENTS = "clean_clients";

pm.UPDATE_INNER_PROGRAM_LAYER_CONTAINER = "update_inner_program_layer_container";
pm.UPDATE_SCROLL_POS = "update_inner_program_layer_scroll_position";

pm.UPDATE_LEVEL_LAYER = "update_level_layer_container";

/**
 * Constant for a enable controlled mode event by {@link MethodStackLayer, pm.ControlledModeData}
 * @const
 * @default
 * @type {String}
 */
pm.ENABLE_CONTROLLED_MODE_STR = "enable_controlled_mode";

/**
 * Constant for a disable controlled mode event by {@link MethodStackLayer}
 * @const
 * @default
 * @type {String}
 */
pm.DISABLE_CONTROLLED_MODE_STR = "disable_controlled_mode";

/**
 * Constant for a updating of program by user event in
 * {@link ProgramContainerLayer}, {@link ProgramLayer}
 * @const
 * @default
 * @type {String}
 */
pm.USER_PROGRAM_UPDATED_STR = "user_program_updated";

pm.READY_TO_CHANGE_LEVEL_STR = "ready_to_change_level";

/**
 * Constant for updating change level menu
 * @const
 * @default
 * @type {String}
 */
pm.LEVEL_SCENE_ENTERED = "level_scene_entered";
/**
 * Constant for changing state of global robot
 * @const
 * @default
 * @type {String}
 */
pm.GLOBAL_ROBOT_STATE_UPDATED = "global_robot_state_updated";

/**
 * Constant for resetting level
 * @const
 * @default
 * @type {String}
 */
pm.RESET_LEVEL_EVENT_STR = "reset_level_event";

/**
 * Constant for open event oc CounterRobotLayer
 * @const
 * @default
 * @type {String}
 */
pm.COUNTER_LAYER_OPENED_EVENT_STR = "counter_layer_opened";

/**
 * Constant for close event oc CounterRobotLayer
 * @const
 * @default
 * @type {String}
 */
pm.COUNTER_LAYER_CLOSED_EVENT_STR = "counter_layer_closed";

pm.GREEN_FLAG_LAYER_OPENED_EVENT_STR = "green_flag_layer_opened";

pm.GREEN_FLAG_LAYER_CLOSED_EVENT_STR = "green_flag_layer_closed";

pm.ORANGE_FLAG_LAYER_OPENED_EVENT_STR = "orange_flag_layer_opened";

pm.ORANGE_FLAG_LAYER_CLOSED_EVENT_STR = "orange_flag_layer_closed";

/**
 * Constant for open event of MethodStackLayer
 * @const
 * @default
 * @type {String}
 */
pm.METHOD_STACK_LAYER_OPENED_EVENT_STR = "method_stack_layer_opened";

/**
 * Constant for close event of MethodStackLayer
 * @const
 * @default
 * @type {String}
 */
pm.METHOD_STACK_LAYER_CLOSED_EVENT_STR = "method_stack_layer_closed";
/**
 * Constant for become active event of MethodStackLayer
 * @const
 * @default
 * @type {String}
 */
pm.METHOD_STACK_LAYER_ACTIVE_EVENT_STR = "method_stack_layer_active";
/**
 * Constant for ended commands of method stack layer
 * @const
 * @default
 * @type {String}
 */
pm.FORCED_COMMANDS_ENDED_EVENTS_STR = "forced_commands_ended";
/**
 * Constant for loaded buttons in program Layer in Tab {@link ProgramLayer}
 * @const
 * @default
 * @type {String}
 */
pm.TAB_LOADED_STR = "ButtonsInTabLoaded";
/**
 * Constant for set robot program
 * @const
 * @default
 * @type {String}
 */
pm.SET_ROBOT_PROGRAM_EVEN_STR = "set_robot_program";

/**
 * Constant for a focusing to robot when it's moving in map layer. cc.Point expected in data {@link MapLayer2D}
 * @const
 * @default
 * @type {String}
 */
pm.FOCUS_TO_ROBOT_IN_MAP = "focus_to_robot_in_map";

/**
 * Constant for updating select layout
 * @const
 * @default
 * @type {String}
 */
pm.COMMANDS_CHANGED = "commands_changed";
/**
 * Constant for updating select layout
 * @const
 * @default
 * @type {String}
 */
pm.ADD_FUNCTION_METHOD = "add_method_call";
/**
 * Constant for updating select layout
 * @const
 * @default
 * @type {String}
 */
pm.REMOVE_FUNCTION = "remove_func";
/**
 * Constant for updating select layout
 * @const
 * @default
 * @type {String}
 */
pm.VARIABLES_CHANGED = "variables_changed";

/**
 * Constant for pattern changing
 * @const
 * @default
 * @type {String}
 */
pm.PATTERN_CHANGED = "pattern_changed";

/**
 * Constant for launching recognizing
 * @const
 * @default
 * @type {String}
 */
pm.RECOGNIZE_PROGRAM = "recognize_program";

/**
 * Constant for showing info about level
 * @const
 * @default
 * @type {String}
 */
pm.SHOW_INFO = "show_info";

/**
 * Constant for updating current scene
 * @const
 * @default
 * @type {String}
 */
pm.UPDATE_SCENE = "update_scene";

/**
 * Constant for updating physical robot
 * @const
 * @default
 * @type {String}
 */
pm.PHYSICAL_ROBOT_TYPE_CHANGED = "physical_robot_type_changed";

/**
 * Constant for updating program control
 * @const
 * @default
 * @type {String}
 */
pm.TURN_TURBO_MODE = "turn_turbo_mode";

/**
 * Constant for updating clear program button
 * @const
 * @default
 * @type {String}
 */
pm.UPDATE_CLEAR_PROGRAM_BUTTON = "update_clear_program_button";

/**
 * Constant for restarting tutorial in help
 * @const
 * @default
 * @type {String}
 */
pm.TUTORIAL_ENDED = "tutorial_ended";

/**
 * Constant for closing tutorial scenario
 * @const
 * @default
 * @type {String}
 */
pm.TUTORIAL_SCENARIO_CLOSED = "tutorial_scenario_closed";

/**
 * Constant for updating time table in class control
 * @const
 * @default
 * @type {String}
 */
pm.UPDATE_TIME_TABLE = "update_time_table";

/**
 * Constant for a select map element event in editor menu {@link MapElementsLayer}
* @const
    * @default
* @type {String}
*/
pme.MAP_ELEMENT_SELECT_EVENT_STR = "map_element_select_event";

pme.PAGE_CHANGED = "page_changed";

/**
 * Constant for a repair map if grass count changed event in editor {@link MapElementsLayer}
 * @const
 * @default
 * @type {String}
 */
pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR = "map_grass_count_changed_event";

/**
 * Constant for add robot select event in editor {@link ObjectsSettingsLayer}
 * @const
 * @default
 * @type {String}
 */
pme.ADD_ROBOT_SELECT_EVENT_STR = "add_robot_select_event";

/**
 * Constant for add robot select event in editor {@link ObjectsSettingsLayer}
 * @const
 * @default
 * @type {String}
 */
pme.ADD_OBJECT_SELECT_EVENT_STR = "add_object_select_event";

/**
 * Constant for showing or not start all button. Contains bool.
 * @const
 * @default
 * @type {String}
 */
pme.SHOW_START_ALL_BUTTON = "show_start_all_button_event";

/**
 * Constant for pressing buttons to add/delete map layers in editor {@link LevelSettings}
 * @const
 * @default
 * @type {String}
 */
pme.MAP_LAYERS_COUNT_CHANGED_EVENT_STR = "map_layers_count_changed_event";

/**
 * Constant for pressing buttons to add/delete program patterns in editor {@link PlayerRobotSettingsLayer}
 * @const
 * @default
 * @type {String}
 */
pme.PATTERNS_COUNT_CHANGED_EVENT_STR = "patterns_count_changed_event";

/**
 * Constant for remove global robot
 * @const
 * @default
 * @type {String}
 */
pme.REMOVE_GLOBAL_ROBOT = "remove_global_robot";
/**
 * Constant for adding robot. Contains robot data
 * @const
 * @default
 * @type {String}
 */
pme.ADD_ROBOT = "add_robot";
/**
 * Constant for remove robot. Contains robot data
 * @const
 * @default
 * @type {String}
 */
pme.REMOVE_ROBOT = "remove_robot";
/**
 * Constant for enter/exit cooperative mode. Contains bool.
 * @const
 * @default
 * @type {String}
 */
pme.COOPERATIVE_MODE = "cooperative_mode"
/**
 * Constant for redraw global robot
 * @const
 * @default
 * @type {String}
 */
pme.REDRAW_GLOBAL_ROBOT = "redraw_global_robot";

/**
 * Constant for remove global robot
 * @const
 * @default
 * @type {String}
 */
pme.ADD_GLOBAL_ROBOT = "add_global_robot";

/**
 * Constant for adding clone
 * @const
 * @default
 * @type {String}
 */
pme.ADD_CLONE = "add_clone";

/**
 * Constant for a adding or removal rows from program in editor mode {@link ProgramContainerLayer} event by {@link pm.ProgramControlLayer}
 * @const
 * @default
 * @type {String}
 */
pm.ADD_OR_REMOVE_ROWS = "change_rows";

/**
 * Constant for removing arrows for rows in editor mode {@link ProgramContainerLayerP} event by {@link pm.ProgramControlLayerP}
 * @const
 * @default
 * @type {String}
 */
pm.REMOVE_ALL_ARROWS = "remove_arrows";
/**
 *
 * Constant for updating syntax of program in piktomir-k
 * @const
 * @default
 * @type {String}
 */
pm.CHANGE_SYNTAX = "change_syntax";
/**
 * Constant for sending event to Program Layer to open numberInputLayer {@link NumberInputLayer} event by {@link pmui.NumberNode}
 * @const
 * @default
 * @type {String}
 */
pm.CHANGE_NUMBER_IN_INPUT_NODE = "change_number";

/**
 * Constant for sending event to Program Layer to open numberInputLayer {@link NumberInputLayer} event by {@link pmui.NumberNode}
 * @const
 * @default
 * @type {String}
 */
pm.CLOSE_NUMBER_INPUT_LAYER = "close_number_layer";

/**
 * Constant for sending event to AllocNode to change variable debug info
 * @const
 * @default
 * @type {String}
 */
pm.VARIABLE_VALUE_CHANGED = "var_value_changed";
/**
 * Constant for a enable controlled mode event by {@link MethodStackLayer, pm.ControlledModeData}
 * @const
 * @default
 * @type {String}
 */
pm.FUNCTION_DEFINITION_REMOVED = "function_removed";
/**
 * Constant for a enable controlled mode event by {@link MethodStackLayer, pm.ControlledModeData}
 * @const
 * @default
 * @type {String}
 */
pm.VARIABLE_DEFINITION_REMOVED = "alloc_variable_removed";

pm.COMMAND_REMOVED = "command_removed";

pm.FUNCTION_PARAMS_CHANGED = "function_params_changed";
/**
 * Constant for a enable controlled mode event by {@link MethodStackLayer, pm.ControlledModeData}
 * @const
 * @default
 * @type {String}
 */
pm.HIDE_SELECT_LAYOUT = "hide_select_layout";
/**
 * Constant for a enable controlled mode event by {@link MethodStackLayer, pm.ControlledModeData}
 * @const
 * @default
 * @type {String}
 */
pm.SHOW_SELECT_LAYOUT = "show_select_layout";
/**
 * Constant for a enable controlled mode event by {@link MethodStackLayer, pm.ControlledModeData}
 * @const
 * @default
 * @type {String}
 */
pm.RESET_HIGHLIGHT = "removehightlight";
/**
 * Constant for a enable controlled mode event by {@link MethodStackLayer, pm.ControlledModeData}
 * @const
 * @default
 * @type {String}
 */
pm.DESELECT_NODE = "deselect_node_layer";
/**
 * Constant for stop animation of FunctionButton {@link FunctionButtonK}
 * @const
 * @default
 * @type {String}
 */
pm.DESELECT_FUNCTIONBUTTON = "deselect_function_button";
/**
 * Constant for stop animation of FunctionButton {@link FunctionButtonK}
 * @const
 * @default
 * @type {String}
 */
pm.PARSE_NODE = "parse_node";
/**
 * Constant for stop animation of FunctionButton {@link FunctionButtonK}
 * @const
 * @default
 * @type {String}
 */
pm.CHANGE_NUMBER_NODE = "change_number_in_node";
/**
 * Sends custom event throw cocos dispatcher.
 * @param {String} eventName Name of event.
 * @param {*} [data] Custom data for event.
 */

pm.sendCustomEvent = function(eventName, data)
{
	var event = new cc.EventCustom(eventName);

	if(data !== undefined)
		event.setUserData(data);

	cc.eventManager.dispatchEvent(event);
};

/**
 * Registers custom event listener.
 * @param {String} eventName
 * @param {Function} callback
 * @param {ccui.Widget|cc.Node|Number} nodeOrPriority
 * @returns {cc.EventListener}
 */
pm.registerCustomEventListener = function(eventName, callback, nodeOrPriority)
{
	var listener = cc.EventListener.create({
		event: cc.EventListener.CUSTOM,
		eventName: eventName,
		callback: callback
	});

	cc.eventManager.addListener(listener, nodeOrPriority);

	return listener;
};
