/**
 * Created by Antony Orlovsky on 09.08.19.
 */

/**
 * @class 2D level where robot can paint some figures.
 * @extends pm.AbstractLevel
 */
pm.data.TurtleLevel = pm.AbstractLevel.extend(/** @lends pm.data.TurtleLevel# */{
	typeName: "TurtleLevel",

	ctor: function()
	{
		this._super();

		this.useMethodStack = false;
	},

	getType: function() { return pm.TurtleLevelModule.Type; }
});
