/**
 * Created by Antony Orlovsky on 05.07.19.
 */

/**
 * @class Layer for selecting pattern of program.
 * @extends ccui.Layout
 * @constructor
 */
var SelectPatternMenu = ccui.Layout.extend(/** @lends SelectPatternMenu# */{

	_selectedPatternIndex: -1,
	_level: null,
	_robot: null,

	_target: null,
	_callback: null,

	ctor: function(level, robot, target, callback)
	{
		this._level = level;
		this._robot = robot;
		this._target = target;
		this._callback = callback;

		this._super();

		this.setBackGroundImageScale9Enabled(true);
		this.setBackGroundImageCapInsets(cc.rect(60, 15, 40, 15));
		this.setCascadeOpacityEnabled(true);

		this.setLayoutType(ccui.Layout.LINEAR_HORIZONTAL);

		pm.registerCustomEventListener(pm.PATTERN_CHANGED_EVENT_STR, function(event)
		{
			var data = event.getUserData();
			this._selectPattern(this.getChildByTag(data.index));
		}.bind(this), this);
	},

	updateCircles: function()
	{
		var programData = null;

		if (pm.settings.isEditorMode)
		{
			programData = this._level.programData[this._robot.groupID];
		}
		else
		{
			if (this._level.programDataCache[this._robot.groupID])
				programData = this._level.programDataCache[this._robot.groupID];
			else
				programData = this._level.programData[this._robot.groupID];
		}

		var patternCount = programData.programDataArray.length;

		this.removeAllChildren(true);

		var circle = pm.spriteUtils.getInterfaceElementSprite("unchecked");

		var width = patternCount * (circle.getContentSize().width + SelectPatternMenu.SEPARATOR_SIZE);
		width -= SelectPatternMenu.SEPARATOR_SIZE;

		this.setContentSize(cc.size(width, circle.getContentSize().height));

		var buttonMargin = new ccui.LinearLayoutParameter();
		buttonMargin.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
		buttonMargin.setMargin(SelectPatternMenu.SEPARATOR_SIZE, 0, 0, 0);

		var buttonMargin0 = new ccui.LinearLayoutParameter();
		buttonMargin0.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
		buttonMargin0.setMargin(0, 0, 0, 0);

		for (var i = 0; i < patternCount; ++i)
		{
			var icon = "unchecked";

			if (i === programData.currentIndex)
			{
				icon = "checked";
				this._selectedPatternIndex = i;
			}

			circle = new pmui.Button(pm.spriteUtils.getInterfaceElementName(icon),
				pm.spriteUtils.getInterfaceElementName(icon),
				pm.spriteUtils.getInterfaceElementName(icon),
				ccui.Widget.PLIST_TEXTURE);

			if(i === 0)
				circle.setLayoutParameter(buttonMargin0);
			else
				circle.setLayoutParameter(buttonMargin);

			circle.setTag(i);
			circle.addClickEventListener(this._selectPattern.bind(this));

			this.addChild(circle);
		}

		this._callback.call(this._target);
	},

	/**
	 * Sets layer enabled.
	 * @param {Boolean} flag
	 */
	setEnabled: function(flag)
	{
		ccui.Layout.prototype.setEnabled.call(this, flag);

		for (var i = 0; i < this.getChildrenCount(); ++i)
			this.getChildren()[i].setTouchEnabled(flag);
	},

	_selectPattern: function(sender)
	{
		var index = sender.getTag();

		if (index !== this._selectedPatternIndex)
		{
			var oldCircle = this.getChildByTag(this._selectedPatternIndex);

			this._updateTextures(oldCircle, sender);

			this._selectedPatternIndex = index;

			if (pm.settings.isEditorMode)
			{
				this._level.programData[this._robot.groupID].currentIndex = index;
				pm.sendCustomEvent(pm.PATTERN_CHANGED, this._robot);
			}
			else
			{
				this._level.programDataCache[this._robot.groupID].currentIndex = index;
			}

			this._callback.call(this._target);
			pm.sendCustomEvent(pm.USER_PROGRAM_UPDATED_STR);
			pm.sendCustomEvent(pm.COMMANDS_CHANGED);
		}
	},

	_updateTextures: function(oldCircle, newCircle)
	{
		oldCircle.loadTextures(pm.spriteUtils.getInterfaceElementName("unchecked"),
			pm.spriteUtils.getInterfaceElementName("unchecked"),
			pm.spriteUtils.getInterfaceElementName("unchecked"),
			ccui.Widget.PLIST_TEXTURE);
		oldCircle.setTouchEnabled(true);

		newCircle.loadTextures(pm.spriteUtils.getInterfaceElementName("checked"),
			pm.spriteUtils.getInterfaceElementName("checked"),
			pm.spriteUtils.getInterfaceElementName("checked"),
			ccui.Widget.PLIST_TEXTURE);
		newCircle.setTouchEnabled(false);
	}
});

SelectPatternMenu.LEFT_MARGIN = -15;
SelectPatternMenu.SEPARATOR_SIZE = 5;
SelectPatternMenu.HEIGHT = 30;
SelectPatternMenu.LEFT_BOUND = 65;
