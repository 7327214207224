/**
 * Created by Nikita Besshaposhnikov on 31.07.15.
 */

/**
 * @class Layer for display hints. Hints are stacked.
 * @example
 * var hint = new HintLayer("I'm hint!");
 * hint.show();
 * @extends pmui.PopupLayer
 * @constructor
 * @param {String} text Text of hint.
 */
var HintLayer = pmui.PopupLayer.extend(/** @lends HintLayer# */{
	_visible: true,

	ctor: function(text, isNetGame)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		var worldLabelText = "";
		var gameLabelText = "";

		if(!isNetGame)
		{
			worldLabelText = LocalizedString("WorldPattern").format(pm.settings.getWorldRecord().name);
			gameLabelText = world.getGameName(pm.settings.getGame());
		}
		else
		{
			worldLabelText = LocalizedString("NetGame");
			gameLabelText = world.runningLevel.name;
		}

		var worldLabel = new ccui.Text(worldLabelText, pm.settings.fontBoldName, 25);
		var gameLabel = new ccui.Text(gameLabelText, pm.settings.fontBoldName, 15);

		var worldLabelAlign = new ccui.RelativeLayoutParameter();
		worldLabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		worldLabelAlign.setMargin(0, pmui.OvalLayout.SLIM_BORDER_RADIUS + HintLayer.HINT_SEPARATOR, 0, 0);
		worldLabelAlign.setRelativeName("worldLabel");
		worldLabel.setLayoutParameter(worldLabelAlign);

		var gameLabelAlign = new ccui.RelativeLayoutParameter();
		gameLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		gameLabelAlign.setRelativeName("gameLabel");
		gameLabelAlign.setRelativeToWidgetName("worldLabel");
		gameLabel.setLayoutParameter(gameLabelAlign);

		this.addChild(worldLabel);
		this.addChild(gameLabel);

		var width = Math.max(worldLabel.getContentSize().width, gameLabel.getContentSize().width) + 2 * HintLayer.HINT_SEPARATOR;
		var height = worldLabel.getContentSize().height + gameLabel.getContentSize().height + 2 * HintLayer.HINT_SEPARATOR;

        if (!isNetGame)
        {
            var levelLabelText = world.getLevelName(pm.settings.getGame(), pm.settings.getLevel());
            var levelLabel = new ccui.Text(levelLabelText, pm.settings.fontBoldName, 15);

            width = Math.max(width, levelLabel.getContentSize().width + 2 * HintLayer.HINT_SEPARATOR);
            height += levelLabel.getContentSize().height;

            var levelLabelAlign = new ccui.RelativeLayoutParameter();
            levelLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
            levelLabelAlign.setRelativeName("levelLabel");
            levelLabelAlign.setRelativeToWidgetName("gameLabel");
            levelLabel.setLayoutParameter(levelLabelAlign);

            this.addChild(levelLabel);
        }

		if (text && text !== "")
		{
			var label = new ccui.Text(text, pm.settings.fontName, pm.settings.fontSize);
			label.setTextAreaSize(cc.size(HintLayer.TEXT_WIDTH, 0));
			label.setTextHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

			width = Math.max(width, label.getContentSize().width);
			height += label.getContentSize().height;

			var relativeName = isNetGame ? "gameLabel" : "levelLabel";

			var labelMargin = new ccui.RelativeLayoutParameter();
			labelMargin.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
			labelMargin.setRelativeToWidgetName(relativeName);
			labelMargin.setMargin(0, HintLayer.HINT_SEPARATOR, 0, 0);
			label.setLayoutParameter(labelMargin);

			this.addChild(label);
		}

		this.setContentSizeWithBorder(width, height);

		this.setHideCallback(this._onHide, this);

		HintLayer._hints.push(this);
	},

	_onHide: function()
	{
		this._visible = false;

		var index = HintLayer._hints.indexOf(this);
		HintLayer._hints.splice(index, 1);

		if(index === HintLayer._hints.length)
			return;

		for(var i = index; i < HintLayer._hints.length; ++i)
		{
			var x = HintLayer._hints[i].getPositionX();
			var y = HintLayer._hints[i].getPositionY() - this.getContentSize().height - HintLayer.HINT_SEPARATOR;
			var move = cc.moveTo(pm.SYSTEM_ANIMATION_DELAY, x, y);

			HintLayer._hints[i].runAction(move);
		}
	},

	/**
     * Shows hint on screen.
     */
	show: function()
	{
		if(HintLayer._hints.length === 1)
			HintLayer._lastY = pm.settings.getMapSize().height - this.getContentSize().height - 15;

		var positionX = pm.settings.getMapSize().width / 2 - this.getContentSize().width / 2;

		this.setPosition(positionX, HintLayer._lastY);

		cc.director.getRunningScene().addChild(this, 10);
		this._visible = true;

		HintLayer._lastY += this.getContentSize().height + HintLayer.HINT_SEPARATOR;
	},

	/**
	 * Determines if the hint is visible.
	 * @returns {boolean} true if the hint is visible, false if the hint is hidden.
	 */
	isVisible: function()
	{
		return this._visible;
	}
});

HintLayer._lastY = 0;
HintLayer._hints = [];

/**
 * Separator between hints.
 * @const
 * @default
 * @type {Number}
 */
HintLayer.HINT_SEPARATOR = 5;
/**
 * Limit for width of text.
 * @const
 * @default
 * @type {Number}
 */
HintLayer.TEXT_WIDTH = 300;
