/**
 * Created by Kirill Mashchenko on 13.05.17.
 */

/**
 * @class Layer for {@link pm.data.LightMap}.
 * @implements SquarableMapLayer
 */

var LightMapLayer = SquarableMapLayer.extend(/** @lends LightMapLayer#*/{

	_fishes: [],

	ctor: function(map)
	{
		this._fishes=[];

		this._super(map);
		this._orientation = pm.MapLayer2D.Orientation.Iso;
		this._mapElementSize = cc.size(64, 64);
		cc.animationCache.addAnimations("MapsPics/light_fish-animations.plist");
		pm.spriteUtils.registerTempSpriteFrames("MapsPics/light_fish.plist");
	},

	_findDirectionFish: function(i)
	{
		var map = this._map;
		var pos = this._fishes[i].position;
		var fishDirection = this._fishes[i].direction;

		var possibleShifts = [];

		var x = pos.x, y = pos.y;
		var periodOfRandom = 0;

		for (var q = x - 1; q <= x + 1; ++q)
		{
			for (var p = y - 1; p <= y + 1; ++p)
			{
				var fishFound = false;
				var el = map.element(cc.p(q, p));

				for (var t = 0; t < this._fishes.length; ++t)
				{
					if (q === this._fishes[t].position.x && p === this._fishes[t].position.y)
						fishFound = true;
				}

				if (!fishFound && el._type === LightMapElementType.BottomWater)
				{
					if (this._getDirectionByShift(cc.p(q - x, p - y)) === fishDirection)
					{
						periodOfRandom += LightMapLayer.FISH_MOVE_STRAIGHT_WEIGHT;
					}
					else
					{
						possibleShifts.push(cc.p(q - x, p - y));
						++periodOfRandom;
					}
				}
			}
		}

		var direction = LightMapLayer.FISH_NO_WAY_DIR;

		var rand = Math.floor(cc.rand()) % periodOfRandom;

		if(periodOfRandom >=LightMapLayer.FISH_MOVE_STRAIGHT_WEIGHT)
		{
			if (rand <= LightMapLayer.FISH_MOVE_STRAIGHT_WEIGHT)
				direction = fishDirection;
			else
				direction = this._getDirectionByShift(possibleShifts[rand - LightMapLayer.FISH_MOVE_STRAIGHT_WEIGHT]);
		}
		else if (periodOfRandom > 0)
		{
			direction = this._getDirectionByShift(possibleShifts[rand]);
		}

		return direction;
	},

	_getDirectionByShift: function (shift)
	{
		var x = shift.x;
		var y = shift.y;

		switch (x)
		{
			case 0:
				switch (y)
				{
					case 0: return LightMapLayer.FISH_NO_WAY_DIR;
					case 1: return LightDirection.Down;
					case -1: return LightDirection.Up;
				}
			case 1:
				switch (y)
				{
					case 0: return LightDirection.Right;
					case 1: return LightDirection.DownRight;
					case -1: return LightDirection.UpRight;
				}
			case -1:
				switch (y)
				{
					case 0: return LightDirection.Left;
					case 1: return LightDirection.DownLeft;
					case -1: return LightDirection.UpLeft;
				}
		}

		return LightMapLayer.FISH_NO_WAY_DIR;
	},

	_decideFunction: function()
	{
		if (this._fishes.length === 0)
			return;

		var animationList = [];

		for (var i = 0; i < this._fishes.length; ++i)
		{
			var newDirection = this._findDirectionFish(i);

			if (newDirection === LightMapLayer.FISH_NO_WAY_DIR)
				continue;

			var fishAnimation = [];

			var directionR1 = 12 + newDirection - this._fishes[i].direction % 8;
			var directionR2 = 12 - newDirection + this._fishes[i].direction % 8;

			var k = 1;

			if (directionR1 < directionR2)
				k = 7;

			var speed = LightMapLayer.FISH_SPEED;
			var turnCount = Math.abs(newDirection - this._fishes[i].direction);

			if (turnCount === 1)
			{
				var q = this._fishes[i].direction;

				var animation = cc.animationCache.getAnimation("light_fish_{0}->{1}".format(q, (q + k) % 8));
				animation.setDelayPerUnit(speed * 0.5 / animation.getTotalDelayUnits());

				var animate = new cc.Animate(animation);
				fishAnimation.push(animate);
			}
			else if (turnCount > 1)
			{
				for (var j = this._fishes[i].direction; j !== newDirection; j = (j + k) % 8)
				{
					var animation = cc.animationCache.getAnimation("light_fish_{0}->{1}".format(j, (j + k) % 8));
					animation.setDelayPerUnit((speed - speed / turnCount) / animation.getTotalDelayUnits() / turnCount);

					var animate = new cc.Animate(animation);
					fishAnimation.push(animate);
				}
			}

			this._fishes[i].direction = newDirection;

			var dx = 0;
			var dy = 0;

			switch (newDirection)
			{
				case LightDirection.Up: dx = 0; dy = -1; break;
				case LightDirection.UpLeft: dx = -1; dy = -1; break;
				case LightDirection.Left: dx = -1; dy = 0; break;
				case LightDirection.DownLeft: dx = -1; dy = 1; break;
				case LightDirection.Down: dx = 0; dy = 1; break;
				case LightDirection.DownRight: dx = 1; dy = 1; break;
				case LightDirection.Right: dx = 1; dy = 0; break;
				case LightDirection.UpRight: dx = 1; dy = -1; break;
			}

			var position = this._fishes[i].position;

			var moveFish = null;

			switch (turnCount)
			{
				case 1:
					moveFish = cc.moveTo(speed * 0.5, this.realPosition(cc.p(position.x + dx, position.y + dy), cc.p(0, 0)));
					break;
				case 0:
					moveFish = cc.moveTo(speed, this.realPosition(cc.p(position.x + dx, position.y + dy), cc.p(0, 0)));
					break;
				default:
					moveFish = cc.moveTo(speed/turnCount, this.realPosition(cc.p(position.x + dx, position.y + dy), cc.p(0, 0)));
			}

			this._fishes[i].position.x += dx;
			this._fishes[i].position.y += dy;

			fishAnimation.push(moveFish);
			animationList.push(cc.targetedAction(this._fishes[i].sprite, cc.sequence(fishAnimation)));
		}

		if(animationList.length > 0)
		{
			var end = cc.callFunc(this._decideFunction, this);
			this.runAction(cc.sequence(cc.spawn(animationList), end));
		}
	},

	_needDrawFishes: function()
	{
		return !this._previewDraw;
	},

	_drawMapElements: function()
	{
		var countFishes = 0;

		for(var x = 0; x < this._map.width; ++x)
		{
			for(var y = 0; y < this._map.height; ++y)
			{
				var pos = cc.p(x, y);

				var element = this._map.element(pos);
				element.wallsHere = [];
				element.roadsHere = [];
				element.topWater = null;
				element.botWater = null;
				element.mainRoad = null;
				element.grass = null;

				var type = element.getType();

				if (type !== LightMapElementType.BottomWater)
				{
					if (type === LightMapElementType.Road || type === LightMapElementType.StopPoint)
					{
						var grassSprite = new pm.TerrainSprite(pm.spriteUtils.getMapGrassTileFrame(LightMapElementType.GreenGrass));
						grassSprite.getSprite().setPosition(this.realPosition(pos, cc.p(0, 0)));

						this.addTerrainObject(grassSprite, 0);
						element.grass = grassSprite;

						var terrainSprite = element.generateTerrainSprite();
						terrainSprite.getSprite().setPosition(this.realPosition(pos, cc.p(-6, 6)));

						this.addTerrainObject(terrainSprite, 1);
						element.mainRoad = terrainSprite;
					}
					else
					{
						var terrainSprite = element.generateTerrainSprite();
						terrainSprite.getSprite().setPosition(this.realPosition(pos, cc.p(0, 0)));

						this.addTerrainObject(terrainSprite, 0);
						element.grass = terrainSprite;
					}
				}
				else
				{
					var waterBot = new pm.TerrainSprite(pm.spriteUtils.getMapGrassTileFrame(LightMapElementType.BottomWater));
					waterBot.getSprite().setPosition(this.realPosition(pos, cc.p(0, 0)));

					this.addTerrainObject(waterBot, 1);
					element.botWater = waterBot;

					var topSprite = new cc.Sprite(pm.spriteUtils.getMapGrassTileFrame(LightMapLayer.TOP_WATER_ELEMENT));
					topSprite.setPosition(this.realPosition(pos, cc.p(0, 0)));

					this.terrainLayer.addChild(topSprite, 4);
					element.topWater = topSprite;

					if (this._needDrawFishes())
					{
						if (countFishes === LightMapLayer.COUNT_SQUARES_TO_FISH)
						{
							var direction = Math.floor(cc.rand()) % 8;
							var fish = new pm.TerrainSprite(cc.spriteFrameCache.getSpriteFrame("light_fish{0}.png".format(2 * direction)));

							fish.getSprite().setRotation(-45);
							fish.getSprite().setScaleY(2);

							fish.getSprite().setPosition(this.realPosition(pos, cc.p(0, 0)));
							this.addTerrainObject(fish, 3);

							this._fishes.push({sprite: fish.getSprite(), direction: direction, position: pos});

							countFishes = 0;
						}
						else { ++countFishes; }
					}
				}

				this._drawAroundWater(pos);
				this._drawAroundRoad(pos);

				if(this._map.element(cc.p(x, y)).startForRobot !== pm.MapElement.START_FOR_NO_ROBOT)
					this.drawRobotStartPositionMark(this._map.element(cc.p(x, y)).startForRobot, cc.p(x, y));
			}
		}

		if(this._needDrawFishes())
			this._decideFunction();
	},

	_checkForWater: function (el)
	{
		return el && el.getType() === LightMapElementType.BottomWater;
	},

	_checkForRoads: function (el)
	{
		return el && (
			el.getType() === LightMapElementType.Road ||
            el.getType() === LightMapElementType.StopPoint
		);
	},

	_drawAroundWater: function (pos)
	{
		var x = pos.x, y = pos.y;
		var type = this._map.element(pos).getType();

		if (type === LightMapElementType.BottomWater)
		{
			for (var i = x - 1; i <= x + 1; ++i)
			{
				for (var j = y - 1; j <= y + 1; ++j)
				{
					var currentElement = this._map.element(cc.p(i, j));

					if (currentElement.getType() !== LightMapElementType.BottomWater)
					{
						var curElements = [];

						curElements.push(this._map.element(cc.p(i, j - 1)));
						curElements.push(this._map.element(cc.p(i + 1, j - 1)));
						curElements.push(this._map.element(cc.p(i + 1, j)));
						curElements.push(this._map.element(cc.p(i + 1, j + 1)));
						curElements.push(this._map.element(cc.p(i, j + 1)));
						curElements.push(this._map.element(cc.p(i - 1, j + 1)));
						curElements.push(this._map.element(cc.p(i - 1, j)));
						curElements.push(this._map.element(cc.p(i - 1, j - 1)));

						this._evolutionOfWall(curElements, cc.p(i, j), pos);
					}
				}
			}
		}
	},

	_evolutionOfWall: function (curElements, curPos, originalPos)
	{
		var wall = 0;
		var continueEvolution = true;
		var variant1 = LightMapLayer.WALLS.RIGHT_SMALL_ANGLE;
		var variant2 = LightMapLayer.WALLS.DOWN_WALL;
		var variant3 = LightMapLayer.WALLS.LEFT_BIG_ANGLE;

		for (var t = 0; t < 8; t += 2)
		{
			var t1 = (t + 1) % 8;
			var t2 = (t + 2) % 8;

			if (continueEvolution)
			{
				if (this._checkForWater(curElements[t]))
				{
					if (this._checkForWater(curElements[t2]))
						wall = variant1;
					else
						wall = variant2;

					continueEvolution = false;
				}
				else if (this._checkForWater(curElements[t1]))
				{
					wall = variant3;
				}

				++variant1;
				if (variant1 === LightMapLayer.WALLS.DOWN_WALL)
					variant1 = LightMapLayer.WALLS.LEFT_SMALL_ANGLE;

				variant2 += 2;
				if (variant2 >= LightMapLayer.WALLS_COUNT)
					variant2 = LightMapLayer.WALLS.UP_WALL;

				++variant3;
			}
		}

		if (this._checkForWater(curElements[0]) && this._checkForWater(curElements[6]))
			wall = LightMapLayer.WALLS.UP_SMALL_ANGLE;

		var wallString = "_w{0}".format(wall);
		var wallSprite = new cc.Sprite(pm.spriteUtils.getMapGrassTileFrame(wallString));
		wallSprite.setPosition(this.realPosition(curPos, cc.p(0, 0)));

		if (this._map.element(originalPos).wallsHere.indexOf(wallSprite) === -1)
		{
			this.terrainLayer.addChild(wallSprite, 2);
			this._map.element(originalPos).wallsHere.push(wallSprite);
		}
	},

	_drawAroundRoad: function (pos)
	{
		var x = pos.x, y = pos.y;
		var type = this._map.element(pos).getType();

		if (type === LightMapElementType.Road || type === LightMapElementType.StopPoint)
		{
			for (var i = x - 1; i <= x + 1; ++i)
			{
				for (var j = y - 1; j <= y + 1; ++j)
				{
					if (i < 0 || i >= this._map.width || j < 0 || j >= this._map.height || this._checkForWall(cc.p(i, j)))
						continue;

					var currentElement = this._map.element(cc.p(i, j));

					if (currentElement.getType() !== LightMapElementType.Road &&
                        currentElement.getType() !== LightMapElementType.StopPoint)
					{
						var curElements = [];

						curElements.push(this._map.element(cc.p(i, j - 1)));
						curElements.push(this._map.element(cc.p(i + 1, j - 1)));
						curElements.push(this._map.element(cc.p(i + 1, j)));
						curElements.push(this._map.element(cc.p(i + 1, j + 1)));
						curElements.push(this._map.element(cc.p(i, j + 1)));
						curElements.push(this._map.element(cc.p(i - 1, j + 1)));
						curElements.push(this._map.element(cc.p(i - 1, j)));
						curElements.push(this._map.element(cc.p(i - 1, j - 1)));

						this._evolutionOfRoad(curElements, cc.p(i, j), pos);
					}
				}
			}
		}
	},

	_evolutionOfRoad: function (curElements, curPos, originalPos)
	{
		var flag = LightMapLayer.ROAD_EVOLUTION_FLAGS.NO_ROAD;

		for (var t = 0; t <= 6; t += 2)
		{
			var t1 = (t + 1 + 8) % 8;
			var t2 = (t + 2 + 8) % 8;
			var t3 = (t + 3 + 8) % 8;
			var t4 = (t + 4 + 8) % 8;

			if (this._checkForRoads(curElements[t]) && this._checkForRoads(curElements[t2]) && this._checkForRoads(curElements[t4]))
			{
				if (!this._checkForRoads(curElements[t1] || this._checkForRoads(curElements[t3])))
					flag = LightMapLayer.ROAD_EVOLUTION_FLAGS.NO_EVOLUTION;
			}
		}

		var roadCount = 0;

		for (var t = 0; t < curElements.length; ++t)
		{
			if (this._checkForRoads(curElements[t]))
				++roadCount;
		}

		var flag2 = LightMapLayer.ROAD_EVOLUTION_FLAGS.NO_ROAD;

		if (flag !== LightMapLayer.ROAD_EVOLUTION_FLAGS.NO_EVOLUTION)
		{
			if (this._checkForRoads(curElements[0]) && this._checkForRoads(curElements[2]))
			{
				flag = LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_DOWN_LEFT;

				if (this._checkForRoads(curElements[1]))
					flag = LightMapLayer.ROAD_EVOLUTION_FLAGS.NO_ROAD;
				else
					flag2 = LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_DOWN_LEFT;
			}

			if (this._checkForRoads(curElements[2]) && this._checkForRoads(curElements[4]))
			{
				if (flag !== LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_DOWN_LEFT)
					flag = LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_UP_LEFT;

				if (this._checkForRoads(curElements[3]) && flag2 !== LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_DOWN_LEFT)
					flag = LightMapLayer.ROAD_EVOLUTION_FLAGS.NO_ROAD;
				else
					flag2 = LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_UP_LEFT;
			}

			if (this._checkForRoads(curElements[4]) && this._checkForRoads(curElements[6]))
			{
				if (flag !== LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_DOWN_LEFT &&
                    flag !== LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_UP_LEFT)

					flag = LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_UP_RIGHT;

				if (this._checkForRoads(curElements[5]) &&
                    flag2 !== LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_DOWN_LEFT &&
                    flag2 !== LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_UP_LEFT)

					flag = LightMapLayer.ROAD_EVOLUTION_FLAGS.NO_ROAD;

				else

					flag2 = LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_UP_RIGHT;

			}

			if (this._checkForRoads(curElements[6]) && this._checkForRoads(curElements[0]))
			{
				if (flag !== LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_DOWN_LEFT &&
                    flag !== LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_UP_LEFT &&
                    flag !== LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_UP_RIGHT)

					flag = LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_DOWN_RIGHT;

				if (this._checkForRoads(curElements[7]) &&
                    flag2 !== LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_DOWN_LEFT &&
                    flag2 !== LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_UP_LEFT &&
                    flag2 !== LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_UP_RIGHT)

					flag = LightMapLayer.ROAD_EVOLUTION_FLAGS.NO_ROAD;

			}
		}

		if (flag > 0)
		{
			var del = cc.p(0, 0);

			var roadSprite = new cc.Sprite(pm.spriteUtils.getMapGrassTileFrame("1-1"));

			var elWidth = this.getMapElementSize().width;
			var elHeight = this.getMapElementSize().height;

			var spWidth = roadSprite.width;
			var spHeight = roadSprite.height;

			switch (flag)
			{
				case LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_DOWN_LEFT:
					del = cc.p((elWidth - spWidth)*2/3, (elHeight - spHeight)*2/3);
					break;
				case LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_UP_LEFT:
					del = cc.p((elWidth - spWidth)*2/3, -(elHeight - spHeight)*2/3);
					break;
				case LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_UP_RIGHT:
					del = cc.p(-(elWidth - spWidth)*2/3, -(elHeight - spHeight)*2/3);
					break;
				case LightMapLayer.ROAD_EVOLUTION_FLAGS.EVOLUTION_DOWN_RIGHT:
					del = cc.p(-(elWidth - spWidth)*2/3, (elHeight - spHeight)*2/3);
					break;
				case LightMapLayer.ROAD_EVOLUTION_FLAGS.NO_EVOLUTION:
					del = cc.p(0, 0);
					break;
			}

			roadSprite.setPosition(this.realPosition(curPos, del));

			if (this._map.element(originalPos).roadsHere.indexOf(roadSprite) === -1)
			{
				this.terrainLayer.addChild(roadSprite, 1);
				this._map.element(originalPos).roadsHere.push(roadSprite);
			}
		}
	},

	_checkForWall: function (pos)
	{
		var x = pos.x;
		var y = pos.y;
		for (var i = x - 1; i <= x + 1; ++i)
		{
			for (var j = y - 1; j <= y + 1; ++j)
			{
				if (this._map.element(cc.p(i, j)))
				{
					if (this._map.element(cc.p(i, j)).getType() === LightMapElementType.BottomWater)
						return true;
				}
			}
		}
		return false;
	},

	_cleanWallSprites: function (pos)
	{
		var borders = this._getBordersForElementPosition(pos);

		var x1 = borders.x1;
		var y1 = borders.y1;
		var x2 = borders.x2;
		var y2 = borders.y2;

		for (var i = x1; i <= x2; ++i)
		{
			for (var j = y1; j <= y2; ++j)
			{
				var currentType = this._map.element(cc.p(i, j)).getType();

				if ((currentType === LightMapElementType.BottomWater) || (currentType === LightMapElementType.GreenGrass))
				{
					var walls = this._map.element(cc.p(i, j)).wallsHere;

					for (var k = 0; k < walls.length; ++k)

						this.terrainLayer.removeChild(walls[k]);

					this._map.element(cc.p(i, j)).wallsHere = [];
				}
			}
		}
	},

	_cleanTopRoadSprites: function (pos)
	{
		var borders = this._getBordersForElementPosition(pos);

		var x1 = borders.x1;
		var y1 = borders.y1;
		var x2 = borders.x2;
		var y2 = borders.y2;

		for (var i = x1; i <= x2; ++i)
		{
			for (var j = y1; j <= y2; ++j)
			{
				var currentType = this._map.element(cc.p(i, j)).getType();

				if (currentType === LightMapElementType.GreenGrass ||
                    currentType === LightMapElementType.StopPoint ||
                    currentType === LightMapElementType.Road)
				{
					var roads = this._map.element(cc.p(i, j)).roadsHere;

					for (var k = 0; k < roads.length; ++k)
						this.terrainLayer.removeChild(roads[k]);

					this._map.element(cc.p(i, j)).roadsHere = [];
				}
			}
		}
	},

	setElement: function(pos, type)
	{
		var x = pos.x, y = pos.y;

		var element = this._map.element(pos);

		if (type === LightMapElementType.BottomWater)
		{
			if (element.getType() === LightMapElementType.Road ||
                element.getType() === LightMapElementType.StopPoint)
				return;

			for (var i = x - 1; i <= x + 1; ++i)
			{
				for (var j = y - 1; j <= y + 1; ++j)
				{
					var curType = this._map.element(cc.p(i, j)).getType();

					if (curType === LightMapElementType.Road || curType === LightMapElementType.StopPoint)
						return;
				}
			}

			this._cleanWallSprites(pos);
			this._cleanTopRoadSprites(pos);

			if (element.grass)
			{
				this.removeTerrainObject(element.grass);
				element.grass = null;
			}

			var waterBot = new pm.TerrainSprite(pm.spriteUtils.getMapGrassTileFrame(LightMapElementType.BottomWater));
			waterBot.getSprite().setPosition(this.realPosition(pos, cc.p(0, 0)));

			if (!(element.botWater))
			{
				this.addTerrainObject(waterBot, 1);
				element.botWater = waterBot;
			}

			var topSprite = new cc.Sprite(pm.spriteUtils.getMapGrassTileFrame(LightMapElementType.BottomWater));
			topSprite.setPosition(this.realPosition(pos, cc.p(0, 0)));

			if (!(element.topWater))
			{
				this.terrainLayer.addChild(topSprite, 4);
				element.topWater = topSprite;
			}

			element._type = type;
			element.originalType = type;

			var x1 = x > 1 ? x - 2 : x;
			var x2 = x < this._map.width - 2 ? x + 2 : x;
			var y1 = y > 1 ? y - 2 : y;
			var y2 = y < this._map.height - 2 ? y + 2 : y;

			for (var i = x1; i <= x2; ++i)
			{
				for (var j = y1; j <= y2; ++j)
				{
					this._drawAroundWater(cc.p(i, j));
					this._drawAroundRoad(cc.p(i, j));
				}
			}
		}
		else if (type === LightMapElementType.GreenGrass)
		{
			this._cleanWallSprites(pos);
			this._cleanTopRoadSprites(pos);

			if (element.topWater)
			{
				this.terrainLayer.removeChild(element.topWater);
				element.topWater = null;
			}

			if (element.botWater)
			{
				this.removeTerrainObject(element.botWater);
				element.botWater = null;
			}

			if (element.mainRoad)
			{
				this.removeTerrainObject(element.mainRoad);
				element.mainRoad = null;
			}

			var terrainSprite = new pm.TerrainSprite(pm.spriteUtils.getMapGrassTileFrame(type));
			terrainSprite.getSprite().setPosition(this.realPosition(pos, cc.p(0, 0)));

			if (!element.grass)
			{
				this.addTerrainObject(terrainSprite, 0);
				element.grass = terrainSprite;
			}

			element._type = type;
			element.originalType = type;

			var borders = this._getBordersForElementPosition(pos);

			var x1 = borders.x1;
			var y1 = borders.y1;
			var x2 = borders.x2;
			var y2 = borders.y2;

			for (var i = x1; i <= x2; ++i)
			{
				for (var j = y1; j <= y2; ++j)
				{
					this._drawAroundWater(cc.p(i, j));
					this._drawAroundRoad(cc.p(i, j));
				}
			}
		}
		else
		{
			if (element.getType() === LightMapElementType.BottomWater)
				return;

			var x1 = x > 0 ? x - 1 : x;
			var x2 = x < this._map.width - 1 ? x + 1 : x;
			var y1 = y > 0 ? y - 1 : y;
			var y2 = y < this._map.height - 1 ? y + 1 : y;

			for (var i = x1; i <= x2; ++i)
			{
				for (var j = y1; j <= y2; ++j)
				{
					var curType = this._map.element(cc.p(i, j)).getType();

					if (curType === LightMapElementType.BottomWater)
						return;
				}
			}

			this._cleanTopRoadSprites(pos);

			var terrainSprite = new pm.TerrainSprite(pm.spriteUtils.getMapGrassTileFrame(type));
			terrainSprite.getSprite().setPosition(this.realPosition(pos, cc.p(-6, 6)));

			var grassSprite = new pm.TerrainSprite(pm.spriteUtils.getMapGrassTileFrame(LightMapElementType.GreenGrass));
			grassSprite.getSprite().setPosition(this.realPosition(pos, cc.p(0, 0)));

			if (!element.grass)
			{
				this.addTerrainObject(grassSprite, 0);
				element.grass = grassSprite;
			}

			if (element._type !== type)
			{
				if (element.mainRoad)
				{
					this.removeTerrainObject(element.mainRoad);
					element.mainRoad = null;
				}

				this.addTerrainObject(terrainSprite, 1);
				element.mainRoad = terrainSprite;
			}

			if (!element.mainRoad)
			{
				this.addTerrainObject(terrainSprite, 1);
				element.mainRoad = terrainSprite;
			}

			element._type = type;
			element.originalType = type;

			var borders = this._getBordersForElementPosition(pos);

			var x1 = borders.x1;
			var y1 = borders.y1;
			var x2 = borders.x2;
			var y2 = borders.y2;

			for (var i = x1; i <= x2; ++i)
			{
				for (var j = y1; j <= y2; ++j)

					this._drawAroundRoad(cc.p(i, j));

			}
		}
	},

	_getBordersForElementPosition: function (pos)
	{
		var x = pos.x, y = pos.y;
		var x1, x2, y1, y2;

		if (x > 1)
			x1 = x - 2;
		else if (x > 0)
			x1 = x - 1;
		else
			x1 = x;

		if (x < this._map.width - 2)
			x2 = x + 2;
		else if (x < this._map.width - 1)
			x2 = x + 1;
		else
			x2 = x;

		if (y > 1)
			y1 = y - 2;
		else if (y > 0)
			y1 = y - 1;
		else
			y1 = y;

		if (y < this._map.height - 2)
			y2 = y + 2;
		else if (y < this._map.height - 1)
			y2 = y + 1;
		else
			y2 = y;

		return ({x1: x1, y1: y1, x2: x2, y2: y2});
	}

});

LightMapLayer.FISH_SPEED = 2;
LightMapLayer.FISH_NO_WAY_DIR = -10;
LightMapLayer.COUNT_SQUARES_TO_FISH = 5;
LightMapLayer.FISH_MOVE_STRAIGHT_WEIGHT = 20;

LightMapLayer.ROAD_EVOLUTION_FLAGS = {
	NO_ROAD: 0,
	NO_EVOLUTION: 1,
	EVOLUTION_DOWN_LEFT: 13,
	EVOLUTION_UP_LEFT: 35,
	EVOLUTION_UP_RIGHT: 57,
	EVOLUTION_DOWN_RIGHT: 71
};

LightMapLayer.WALLS = {
	LEFT_BIG_ANGLE: 0,
	UP_BIG_ANGLE: 1,
	RIGHT_BIG_ANGLE: 2,
	DOWN_BIG_ANGLE: 3,
	LEFT_SMALL_ANGLE: 4,
	UP_SMALL_ANGLE: 5,
	RIGHT_SMALL_ANGLE: 6,
	DOWN_SMALL_ANGLE: 7,
	DOWN_WALL: 8,
	UP_WALL: 9,
	LEFT_WALL: 10,
	RIGHT_WALL: 11
};

LightMapLayer.WALLS_COUNT = 12;

LightMapLayer.TOP_WATER_ELEMENT = 4;
