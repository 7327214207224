/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class 2D level where robot can paint some kinds({@link KumirMapElementType}) of elements.
 * @extends pm.AbstractLevel
 */
pm.data.KumirLevel = pm.AbstractLevel.extend(/** @lends pm.data.KumirLevel# */{
	typeName: "KumirLevel",

	ctor: function()
	{
		this._super();
	},

	getType: function() { return pm.KumirLevelModule.Type; }
});
