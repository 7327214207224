/**
 * Created by Nikita Besshaposhnikov on 18.12.14.
 */

/**
 * @class 2D robot for {@link pm.data.RepairLevel}.</br>
 * Has a repair element native function.
 * @implements PlayerRobot4
 */
pm.data.RepairRobot = PlayerRobot4Walls.extend(/** @lends pm.data.RepairRobot# */{
	typeName: "RepairRobot",

	useGreyCondition: false,
	useLightBlueCondition: true,
	useBlueCondition: true,

	ctor: function()
	{
		this._super();

		this.nativeFunctionMap[pm.data.RepairRobot.NativeMethod.Repair] = new pm.NativeFunction(this, this._repair);

		this.nativeFunctions.push(pm.data.RepairRobot.NativeMethod.Repair);
		this.updateConditions();
	},

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new RepairRobotSprite();

			return this.sprite;
		}
	},

	_repair: function()
	{
		this.playAnimation(RepairRobotAnimation.Repair, this._endRepair, this.direction);
	},

	_disRepair: function()
	{
		this.playAnimation(RepairRobotAnimation.Repair, this._endDisRepair, this.direction);
	},

	_endRepair: function()
	{
		var map = this.getMap();

		map.repairElement(this.position);

		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_endDisRepair: function()
	{
		var map = this.getMap();

		map.disrepairElement(this.position);

		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_checkConditionInternal: function(condition, args)
	{
		var map = this.getMap();

		if (condition === pm.data.RepairRobot.Condition.GrassIsGrey)
		{
			return map.element(this.position).getType() === RepairMapElementType.BrokenGrass;
		}
		else if (condition === pm.data.RepairRobot.Condition.GrassIsNotGrey)
		{
			return map.element(this.position).getType() !== RepairMapElementType.BrokenGrass;
		}
		else if (condition === pm.data.RepairRobot.Condition.GrassIsLightBlue)
		{
			return map.element(this.position).getType() === RepairMapElementType.LightBlueGrass;
		}
		else if (condition === pm.data.RepairRobot.Condition.GrassIsNotLightBlue)
		{
			return map.element(this.position).getType() !== RepairMapElementType.LightBlueGrass;
		}
		else if (condition === pm.data.RepairRobot.Condition.GrassIsBlue)
		{
			return map.element(this.position).getType() === RepairMapElementType.RepairedGrass ||
                map.element(this.position).getType() === RepairMapElementType.BlueGrass;
		}
		else if (condition === pm.data.RepairRobot.Condition.GrassIsNotBlue)
		{
			return map.element(this.position).getType() !== RepairMapElementType.RepairedGrass &&
                map.element(this.position).getType() !== RepairMapElementType.BlueGrass;
		}
		else
		{
			return PlayerRobot4Walls.prototype._checkConditionInternal.call(this, condition);
		}
	},

	updateConditions: function()
	{
		this._changeCondition(pm.data.RepairRobot.Condition.GrassIsGrey, this.useGreyCondition);
		this._changeConditionOpposite(pm.data.RepairRobot.Condition.GrassIsNotGrey, this.useGreyCondition);
		this._changeCondition(pm.data.RepairRobot.Condition.GrassIsLightBlue, this.useLightBlueCondition);
		this._changeConditionOpposite(pm.data.RepairRobot.Condition.GrassIsNotLightBlue, this.useLightBlueCondition);
		this._changeCondition(pm.data.RepairRobot.Condition.GrassIsBlue, this.useBlueCondition);
		this._changeConditionOpposite(pm.data.RepairRobot.Condition.GrassIsNotBlue, this.useBlueCondition);
	},

	getState: function ()
	{
		return {
			direction: this.direction,
			position: this.position,
			animation: this.getLastAnimation()
		};
	},

	setState: function (state)
	{
		if (state.direction !== this.direction)
		{
			var k = (state.direction - this.direction + 4) % 4;

			if (k === 1)
				this._turnLeft();
			else
				this._turnRight();
		}
		else if (state.position !== this.position || this.isBroken())
			this._moveTo(state.position, true);
		else if (state.animation === RepairRobotAnimation.Repair)
			this._disRepair();
	},

	hasChangeableConditions: function() { return true; },

	getType: function() { return pm.RepairLevelModule.RobotType; }
});

pm.data.RepairRobot.Condition =
{
	GrassIsGrey: "rep_gig",
	GrassIsNotGrey: "rep_ging",
	GrassIsLightBlue: "rep_gilb",
	GrassIsNotLightBlue: "rep_ginlb",
	GrassIsBlue: "rep_gib",
	GrassIsNotBlue: "rep_ginb"
};

pm.data.RepairRobot.NativeMethod =
{
	Repair: "rep_repair",
	DisRepair: "rep_disrepair"
};
