/**
 * Created by Nikita Besshaposhnikov on 28.02.15.
 */

/**
 * This class represents a "Map" container
 * @class
 * @extends pm.Class
 */
pm.data.MapClass = pm.Class.extend(/** @lends pm.data.MapClass */{
	typeName: "map",

	ctor: function() { this._super(); }
});
