/**
 * Created by Antony Orlovsky on 23.10.18.
 */

/**
 * @class Page for help about program algorithm.
 * @extends ccui.Layout
 */
var ProgramAlgorithmLayer = ccui.Layout.extend(/** @lends ProgramAlgorithmLayer# */{

	_curWorldID: null,
	_loadingLayer: null,

	_showLayer: null,
	_descriptionLayer: null,
	_typesTable: null,

	_backButtonWidth: 0,

	ctor: function(worldID)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);
		this.setContentSize(pm.settings.getScreenSize());

		this._curWorldID = worldID;
		pm.settings.setLevel(ProgramAlgorithmLayer.TUTORIAL_LEVELS.LINEAR);

		this._loadInterface();
		pm.registerCustomEventListener(pm.TUTORIAL_ENDED, function()
		{
			this._drawTutorialLevel(false);
		}.bind(this), this);
	},

	_loadInterface: function()
	{
		var screenBounds = pm.settings.getScreenBounds();

		var backButton = new pmui.Button(pm.spriteUtils.getIconName("backLevel", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("backLevel", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("backLevel", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		backButton.addClickEventListener(this._startScreen.bind(this));

		var backButtonAlign = new ccui.RelativeLayoutParameter();
		backButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		backButtonAlign.setRelativeName("back");
		backButtonAlign.setMargin(screenBounds.left, screenBounds.top, 0, 0);
		backButton.setLayoutParameter(backButtonAlign);

		this._backButtonWidth = backButton.width;
		this.addChild(backButton);

		var selectPageMenu = new SelectPageMenu(SelectPageMenu.PAGES.PROGRAM_ALGORITHM, this._stopTutorial.bind(this), this);

		var selectPageAlign = new ccui.RelativeLayoutParameter();
		selectPageAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		selectPageAlign.setMargin(SelectPageMenu.LEFT_MARGIN, -screenBounds.top, 0, 0);
		selectPageAlign.setRelativeName("selectPage");
		selectPageAlign.setRelativeToWidgetName("back");
		selectPageMenu.setLayoutParameter(selectPageAlign);

		this.addChild(selectPageMenu);

		var description = LocalizedString("ProgramDescriptionHelp");

		var descriptionLabel = new ccui.Text(description, pm.settings.fontName, ProgramAlgorithmLayer.DESCRIPTION_FONT_SIZE);

		descriptionLabel.setTextAreaSize(cc.size(this.width - backButton.width -
			screenBounds.left - screenBounds.right, 0));

		var descriptionAlign = new ccui.RelativeLayoutParameter();
		descriptionAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		descriptionAlign.setMargin(ProgramAlgorithmLayer.STANDARD_SEPARATOR, ProgramAlgorithmLayer.STANDARD_SEPARATOR, 0, 0);
		descriptionAlign.setRelativeName("description");
		descriptionAlign.setRelativeToWidgetName("selectPage");
		descriptionLabel.setLayoutParameter(descriptionAlign);

		this.addChild(descriptionLabel);

		this._drawTutorialLevel(true, ProgramAlgorithmLayer.PAGES.LINEAR);

		var typesOfAlg = new ccui.Text(LocalizedString("Types of algorithms"), pm.settings.fontName, pm.settings.fontSize);

		var typesOfAlgAlign = new ccui.RelativeLayoutParameter();
		typesOfAlgAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		typesOfAlgAlign.setMargin(0, ProgramAlgorithmLayer.STANDARD_SEPARATOR, 0, 0);
		typesOfAlgAlign.setRelativeName("typesOfAlgorithms");
		typesOfAlgAlign.setRelativeToWidgetName("level");
		typesOfAlg.setLayoutParameter(typesOfAlgAlign);

		this.addChild(typesOfAlg);

		var linearLayout = new ccui.Layout();
		linearLayout.setLayoutType(ccui.Layout.RELATIVE);

		var linear = new ccui.Text(LocalizedString("Linear"), pm.settings.fontName, pm.settings.fontSize);

		var linearAlign = new ccui.RelativeLayoutParameter();
		linearAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		linearAlign.setMargin(0, 0, 0, 0);
		linearAlign.setRelativeName("linear");
		linear.setLayoutParameter(linearAlign);

		linearLayout.addChild(linear);

		var linearButton = new EmptyFunctionButton(FunctionButton.Type.Method);
		var linearBar = this._createAlgorithmBar(linearButton);

		var linearBarAlign = new ccui.RelativeLayoutParameter();
		linearBarAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		linearBarAlign.setMargin(0, ProgramAlgorithmLayer.SMALL_SEPARATOR, 0, 0);
		linearBarAlign.setRelativeToWidgetName("linear");
		linearBar.setLayoutParameter(linearBarAlign);

		linearLayout.addChild(linearBar);

		linearLayout.setContentSize(Math.max(linearBar.width, linear.width), linearBar.height + ProgramAlgorithmLayer.SMALL_SEPARATOR + linear.height);

		var ifElseLayout = new ccui.Layout();
		ifElseLayout.setLayoutType(ccui.Layout.RELATIVE);

		var ifElse = new ccui.Text(LocalizedString("If else"), pm.settings.fontName, pm.settings.fontSize);

		var ifElseAlign = new ccui.RelativeLayoutParameter();
		ifElseAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		ifElseAlign.setMargin(0, 0, 0, 0);
		ifElseAlign.setRelativeName("ifElse");
		ifElse.setLayoutParameter(ifElseAlign);

		ifElseLayout.addChild(ifElse);

		var ifElseButton = new EmptyFunctionButton(FunctionButton.Type.Condition);
		var ifElseBar = this._createAlgorithmBar(ifElseButton);

		var ifElseBarAlign = new ccui.RelativeLayoutParameter();
		ifElseBarAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		ifElseBarAlign.setMargin(0, ProgramAlgorithmLayer.SMALL_SEPARATOR, 0, 0);
		ifElseBarAlign.setRelativeToWidgetName("ifElse");
		ifElseBar.setLayoutParameter(ifElseBarAlign);

		ifElseLayout.addChild(ifElseBar);

		ifElseLayout.setContentSize(Math.max(ifElseBar.width, ifElse.width), ifElseBar.height + ProgramAlgorithmLayer.SMALL_SEPARATOR + ifElse.height);

		var repeaterLayout = new ccui.Layout();
		repeaterLayout.setLayoutType(ccui.Layout.RELATIVE);

		var withRepeater = new ccui.Text(LocalizedString("Cycle with repeater"), pm.settings.fontName, pm.settings.fontSize);

		var withRepeaterAlign = new ccui.RelativeLayoutParameter();
		withRepeaterAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		withRepeaterAlign.setMargin(0, 0, 0, 0);
		withRepeaterAlign.setRelativeName("withRepeater");
		withRepeater.setLayoutParameter(withRepeaterAlign);

		repeaterLayout.addChild(withRepeater);

		var repeaterButton = new RepeaterButton(1);
		var repeaterBar = this._createAlgorithmBar(repeaterButton);

		var repeaterBarAlign = new ccui.RelativeLayoutParameter();
		repeaterBarAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		repeaterBarAlign.setMargin(0, ProgramAlgorithmLayer.SMALL_SEPARATOR, 0, 0);
		repeaterBarAlign.setRelativeToWidgetName("withRepeater");
		repeaterBar.setLayoutParameter(repeaterBarAlign);

		repeaterLayout.addChild(repeaterBar);

		repeaterLayout.setContentSize(Math.max(repeaterBar.width, withRepeater.width), repeaterBar.height + ProgramAlgorithmLayer.SMALL_SEPARATOR + withRepeater.height);

		var cycleLayout = new ccui.Layout();
		cycleLayout.setLayoutType(ccui.Layout.RELATIVE);

		var cycleWhile = new ccui.Text(LocalizedString("Cycle while"), pm.settings.fontName, pm.settings.fontSize);

		var cycleWhileAlign = new ccui.RelativeLayoutParameter();
		cycleWhileAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		cycleWhileAlign.setMargin(0, 0, 0, 0);
		cycleWhileAlign.setRelativeName("while");
		cycleWhile.setLayoutParameter(cycleWhileAlign);

		cycleLayout.addChild(cycleWhile);

		var cycleButton = new EmptyFunctionButton(FunctionButton.Type.CondRepeater);
		var cycleBar = this._createAlgorithmBar(cycleButton);

		var cycleBarAlign = new ccui.RelativeLayoutParameter();
		cycleBarAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		cycleBarAlign.setMargin(0, ProgramAlgorithmLayer.SMALL_SEPARATOR, 0, 0);
		cycleBarAlign.setRelativeToWidgetName("while");
		cycleBar.setLayoutParameter(cycleBarAlign);

		cycleLayout.addChild(cycleBar);

		cycleLayout.setContentSize(Math.max(cycleBar.width, cycleWhile.width), cycleBar.height + ProgramAlgorithmLayer.SMALL_SEPARATOR + cycleWhile.height);

		var cellSize = cc.size(Math.max(linearLayout.width, ifElseLayout.width, repeaterLayout.width, cycleLayout.width),
			Math.max(linearLayout.height, ifElseLayout.height, repeaterLayout.height, cycleLayout.height));

		linearLayout.setContentSize(cellSize);
		ifElseLayout.setContentSize(cellSize);
		repeaterLayout.setContentSize(cellSize);
		cycleLayout.setContentSize(cellSize);

		var separator = cc.size(ProgramAlgorithmLayer.TYPES_SEPARATOR, ProgramAlgorithmLayer.TYPES_SEPARATOR);

		this._typesTable = new pmui.SelectTableView(cellSize, separator, ProgramAlgorithmLayer.TYPES_ROWS, ProgramAlgorithmLayer.TYPES_COLUMNS);

		this._typesTable.setSelectFrame(pm.spriteUtils.getInterfaceElementFrame("functionBG"), true);
		this._typesTable.setFitObjectsSizes(true);
		this._typesTable.setInertiaScrollEnabled(false);
		this._typesTable.setBounceEnabled(false);
		this._typesTable.addSelectEventListener(this._selectType, this);
		this._typesTable.canDeselectCell = false;

		this._typesTable.setContentSize(cellSize.width * ProgramAlgorithmLayer.TYPES_COLUMNS + separator.width * (ProgramAlgorithmLayer.TYPES_COLUMNS + 1),
			cellSize.height * ProgramAlgorithmLayer.TYPES_ROWS + 2 * separator.height * Math.min(ProgramAlgorithmLayer.TYPES_ROWS, 1));

		var scale = (pm.settings.getScreenSize().width - backButton.width - screenBounds.left - screenBounds.right) / this._typesTable.width;

		if (scale > 1)
			scale = 1;

		this._typesTable.setScale(scale);

		var margin = 0;
		if (!cc.sys.isNative)
			margin = -this._typesTable.height * (1 - scale);

		var tableAlign = new ccui.RelativeLayoutParameter();
		tableAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		tableAlign.setMargin(0, ProgramAlgorithmLayer.STANDARD_SEPARATOR * scale + margin, 0, 0);
		tableAlign.setRelativeToWidgetName("typesOfAlgorithms");
		this._typesTable.setLayoutParameter(tableAlign);

		this.addChild(this._typesTable);

		this._typesTable.setCell(0, ProgramAlgorithmLayer.PAGES.LINEAR, linearLayout);
		this._typesTable.setCell(0, ProgramAlgorithmLayer.PAGES.IF_ELSE, ifElseLayout);
		this._typesTable.setCell(0, ProgramAlgorithmLayer.PAGES.REPEATERS, repeaterLayout);
		this._typesTable.setCell(0, ProgramAlgorithmLayer.PAGES.WHILE, cycleLayout);

		this._typesTable.selectCell(0, ProgramAlgorithmLayer.PAGES.LINEAR);
	},

	_drawTutorialLevel: function(redraw, page)
	{
		if (!this._showLayer)
		{
			this._showLayer = new ccui.Layout();
			this._showLayer.setLayoutType(ccui.Layout.RELATIVE);
		}

		if (this._showLayer.getChildByTag(ProgramAlgorithmLayer.TUTORIAL_ID))
			this._showLayer.removeChildByTag(ProgramAlgorithmLayer.TUTORIAL_ID);

		var tutorialData = {
			show: true,
			scale: ProgramAlgorithmLayer.TUTORIAL_SCALE
		};

		var levelContainer = new ccui.Layout();
		levelContainer.setContentSize(this.width * tutorialData.scale, this.height * tutorialData.scale);

		var levelContainerAlign = new ccui.RelativeLayoutParameter();
		levelContainerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		levelContainerAlign.setMargin(0, 0, 0, 0);
		levelContainerAlign.setRelativeName("level");
		levelContainer.setLayoutParameter(levelContainerAlign);

		var levelLayer = new LevelLayer(GameType.Local, null, pm.settings.getGame(), pm.settings.getLevel(), null, tutorialData);

		levelContainer.addChild(levelLayer);
		this._showLayer.addChild(levelContainer, 0, ProgramAlgorithmLayer.TUTORIAL_ID);

		if (redraw)
		{
			this._showLayer.setContentSize(levelContainer.width, levelContainer.height);
			this._drawDescription(page);

			var screenBounds = pm.settings.getScreenBounds();
			var widthScale = (pm.settings.getScreenSize().width - this._backButtonWidth -
				screenBounds.left - screenBounds.right) / this._showLayer.width;
			var heightScale = (pm.settings.getScreenSize().height - this._backButtonWidth -
				screenBounds.top - screenBounds.bottom - ProgramAlgorithmLayer.ADDITIONAL_HEIGHT) / this._showLayer.height;

			if (widthScale > 1)
				widthScale = 1;
			if (heightScale > 1)
				heightScale = 1;

			var scale = Math.min(widthScale, heightScale);

			this._showLayer.setScale(scale);

			var margin = 0;
			if (!cc.sys.isNative)
				margin = -this._showLayer.height * (1 - scale);

			var showAlign = new ccui.RelativeLayoutParameter();
			showAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			showAlign.setMargin(0, ProgramAlgorithmLayer.STANDARD_SEPARATOR * scale + margin, 0, 0);
			showAlign.setRelativeName("level");
			showAlign.setRelativeToWidgetName("description");
			this._showLayer.setLayoutParameter(showAlign);
		}

		if (!this._showLayer.getParent())
			this.addChild(this._showLayer);

		this.forceDoLayout();
	},

	_drawDescription: function(page)
	{
		if (!this._descriptionLayer)
		{
			this._descriptionLayer = new ccui.Layout();
			this._descriptionLayer.setLayoutType(ccui.Layout.RELATIVE);

			var descriptionLayerAlign = new ccui.RelativeLayoutParameter();
			descriptionLayerAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
			descriptionLayerAlign.setMargin(ProgramAlgorithmLayer.STANDARD_SEPARATOR, 0, 0, 0);
			descriptionLayerAlign.setRelativeToWidgetName("level");
			this._descriptionLayer.setLayoutParameter(descriptionLayerAlign);

			this._showLayer.addChild(this._descriptionLayer);
		}

		this._descriptionLayer.removeAllChildren();

		switch(page)
		{
			case ProgramAlgorithmLayer.PAGES.LINEAR:
				this._drawLinearDescription();
				break;
			case ProgramAlgorithmLayer.PAGES.IF_ELSE:
				this._drawIfElseDescription();
				break;
			case ProgramAlgorithmLayer.PAGES.REPEATERS:
				this._drawRepeaterDescription();
				break;
			case ProgramAlgorithmLayer.PAGES.WHILE:
				this._drawWhileDescription();
				break;
		}

		this._showLayer.setContentSize(this._showLayer.width + this._descriptionLayer.width + ProgramAlgorithmLayer.STANDARD_SEPARATOR,
			Math.max(this._showLayer.height, this._descriptionLayer.height));
	},

	_drawLinearDescription: function()
	{
		var margins = ProgramAlgorithmLayer.MARGINS.LINEAR;

		var controlLabel = new ccui.Text(LocalizedString("Program control"), pm.settings.fontName, pm.settings.fontSize);

		var controlAlign = new ccui.RelativeLayoutParameter();
		controlAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		controlAlign.setMargin(0, margins.CONTROL, 0, 0);
		controlAlign.setRelativeName("control");
		controlLabel.setLayoutParameter(controlAlign);

		var width = controlLabel.width;
		var height = margins.CONTROL + controlLabel.height;
		this._descriptionLayer.addChild(controlLabel);

		var commandsLabel = new ccui.Text(LocalizedString("Commands"), pm.settings.fontName, pm.settings.fontSize);

		var commandsLabelAlign = new ccui.RelativeLayoutParameter();
		commandsLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		commandsLabelAlign.setMargin(0, margins.COMMANDS, 0, 0);
		commandsLabelAlign.setRelativeName("commands");
		commandsLabelAlign.setRelativeToWidgetName("control");
		commandsLabel.setLayoutParameter(commandsLabelAlign);

		width = Math.max(width, commandsLabel.width);
		height += margins.COMMANDS + commandsLabel.height;
		this._descriptionLayer.addChild(commandsLabel);

		var mainAlgorithm = new ccui.Text(LocalizedString("Main algorithm"), pm.settings.fontName, pm.settings.fontSize);

		var mainAlgorithmAlign = new ccui.RelativeLayoutParameter();
		mainAlgorithmAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		mainAlgorithmAlign.setMargin(0, margins.MAIN, 0, 0);
		mainAlgorithmAlign.setRelativeName("mainAlgorithm");
		mainAlgorithmAlign.setRelativeToWidgetName("commands");
		mainAlgorithm.setLayoutParameter(mainAlgorithmAlign);

		width = Math.max(width, mainAlgorithm.width);
		height += margins.MAIN + mainAlgorithm.height;
		this._descriptionLayer.addChild(mainAlgorithm);

		var algoritmA = new ccui.Text(LocalizedString("Algorithm A"), pm.settings.fontName, pm.settings.fontSize);

		var algoritmAAlign = new ccui.RelativeLayoutParameter();
		algoritmAAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		algoritmAAlign.setMargin(0, margins.A, 0, 0);
		algoritmAAlign.setRelativeName("algorithmA");
		algoritmAAlign.setRelativeToWidgetName("mainAlgorithm");
		algoritmA.setLayoutParameter(algoritmAAlign);

		width = Math.max(width, algoritmA.width);
		height += margins.A + algoritmA.height;
		this._descriptionLayer.addChild(algoritmA);

		this._descriptionLayer.setContentSize(width, height);
	},

	_drawIfElseDescription: function()
	{
		var margins = ProgramAlgorithmLayer.MARGINS.IF_ELSE;

		var controlLabel = new ccui.Text(LocalizedString("Program control"), pm.settings.fontName, pm.settings.fontSize);

		var controlAlign = new ccui.RelativeLayoutParameter();
		controlAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		controlAlign.setMargin(0, margins.CONTROL, 0, 0);
		controlAlign.setRelativeName("control");
		controlLabel.setLayoutParameter(controlAlign);

		var width = controlLabel.width;
		var height = margins.CONTROL + controlLabel.height;
		this._descriptionLayer.addChild(controlLabel);

		var commandsLabel = new ccui.Text(LocalizedString("Commands"), pm.settings.fontName, pm.settings.fontSize);

		var commandsLabelAlign = new ccui.RelativeLayoutParameter();
		commandsLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		commandsLabelAlign.setMargin(0, margins.COMMANDS, 0, 0);
		commandsLabelAlign.setRelativeName("commands");
		commandsLabelAlign.setRelativeToWidgetName("control");
		commandsLabel.setLayoutParameter(commandsLabelAlign);

		width = Math.max(width, commandsLabel.width);
		height += margins.COMMANDS + commandsLabel.height;
		this._descriptionLayer.addChild(commandsLabel);

		var conditionsLabel = new ccui.Text(LocalizedString("Conditions"), pm.settings.fontName, pm.settings.fontSize);

		var conditionsLabelAlign = new ccui.RelativeLayoutParameter();
		conditionsLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		conditionsLabelAlign.setMargin(0, margins.CONDITIONS, 0, 0);
		conditionsLabelAlign.setRelativeName("conditions");
		conditionsLabelAlign.setRelativeToWidgetName("commands");
		conditionsLabel.setLayoutParameter(conditionsLabelAlign);

		width = Math.max(width, conditionsLabel.width);
		height += margins.CONDITIONS + conditionsLabel.height;
		this._descriptionLayer.addChild(conditionsLabel);

		var mainAlgorithm = new ccui.Text(LocalizedString("Main algorithm"), pm.settings.fontName, pm.settings.fontSize);

		var mainAlgorithmAlign = new ccui.RelativeLayoutParameter();
		mainAlgorithmAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		mainAlgorithmAlign.setMargin(0, margins.MAIN, 0, 0);
		mainAlgorithmAlign.setRelativeName("mainAlgorithm");
		mainAlgorithmAlign.setRelativeToWidgetName("conditions");
		mainAlgorithm.setLayoutParameter(mainAlgorithmAlign);

		width = Math.max(width, mainAlgorithm.width);
		height += margins.MAIN + mainAlgorithm.height;
		this._descriptionLayer.addChild(mainAlgorithm);

		var algoritmA = new ccui.Text(LocalizedString("Algorithm A"), pm.settings.fontName, pm.settings.fontSize);

		var algoritmAAlign = new ccui.RelativeLayoutParameter();
		algoritmAAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		algoritmAAlign.setMargin(0, margins.A, 0, 0);
		algoritmAAlign.setRelativeName("algorithmA");
		algoritmAAlign.setRelativeToWidgetName("mainAlgorithm");
		algoritmA.setLayoutParameter(algoritmAAlign);

		width = Math.max(width, algoritmA.width);
		height += margins.A + algoritmA.height;
		this._descriptionLayer.addChild(algoritmA);

		this._descriptionLayer.setContentSize(width, height);
	},

	_drawRepeaterDescription: function()
	{
		var margins = ProgramAlgorithmLayer.MARGINS.REPEATERS;

		var controlLabel = new ccui.Text(LocalizedString("Program control"), pm.settings.fontName, pm.settings.fontSize);

		var controlAlign = new ccui.RelativeLayoutParameter();
		controlAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		controlAlign.setMargin(0, margins.CONTROL, 0, 0);
		controlAlign.setRelativeName("control");
		controlLabel.setLayoutParameter(controlAlign);

		var width = controlLabel.width;
		var height = margins.CONTROL + controlLabel.height;
		this._descriptionLayer.addChild(controlLabel);

		var repeatersLabel = new ccui.Text(LocalizedString("Repeaters"), pm.settings.fontName, pm.settings.fontSize);

		var repeatersLabelAlign = new ccui.RelativeLayoutParameter();
		repeatersLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		repeatersLabelAlign.setMargin(0, margins.REPEATERS, 0, 0);
		repeatersLabelAlign.setRelativeName("repeaters");
		repeatersLabelAlign.setRelativeToWidgetName("control");
		repeatersLabel.setLayoutParameter(repeatersLabelAlign);

		width = Math.max(width, repeatersLabel.width);
		height += margins.REPEATERS + repeatersLabel.height;
		this._descriptionLayer.addChild(repeatersLabel);

		var commandsLabel = new ccui.Text(LocalizedString("Commands"), pm.settings.fontName, pm.settings.fontSize);

		var commandsLabelAlign = new ccui.RelativeLayoutParameter();
		commandsLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		commandsLabelAlign.setMargin(0, margins.COMMANDS, 0, 0);
		commandsLabelAlign.setRelativeName("commands");
		commandsLabelAlign.setRelativeToWidgetName("repeaters");
		commandsLabel.setLayoutParameter(commandsLabelAlign);

		width = Math.max(width, commandsLabel.width);
		height += margins.COMMANDS + commandsLabel.height;
		this._descriptionLayer.addChild(commandsLabel);

		var mainAlgorithm = new ccui.Text(LocalizedString("Main algorithm"), pm.settings.fontName, pm.settings.fontSize);

		var mainAlgorithmAlign = new ccui.RelativeLayoutParameter();
		mainAlgorithmAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		mainAlgorithmAlign.setMargin(0, margins.MAIN, 0, 0);
		mainAlgorithmAlign.setRelativeName("mainAlgorithm");
		mainAlgorithmAlign.setRelativeToWidgetName("commands");
		mainAlgorithm.setLayoutParameter(mainAlgorithmAlign);

		width = Math.max(width, mainAlgorithm.width);
		height += margins.MAIN + mainAlgorithm.height;
		this._descriptionLayer.addChild(mainAlgorithm);

		this._descriptionLayer.setContentSize(width, height);
	},

	_drawWhileDescription: function()
	{
		var margins = ProgramAlgorithmLayer.MARGINS.WHILE;

		var controlLabel = new ccui.Text(LocalizedString("Program control"), pm.settings.fontName, pm.settings.fontSize);

		var controlAlign = new ccui.RelativeLayoutParameter();
		controlAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		controlAlign.setMargin(0, margins.CONTROL, 0, 0);
		controlAlign.setRelativeName("control");
		controlLabel.setLayoutParameter(controlAlign);

		var width = controlLabel.width;
		var height = margins.CONTROL + controlLabel.height;
		this._descriptionLayer.addChild(controlLabel);

		var commandsLabel = new ccui.Text(LocalizedString("Commands"), pm.settings.fontName, pm.settings.fontSize);

		var commandsLabelAlign = new ccui.RelativeLayoutParameter();
		commandsLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		commandsLabelAlign.setMargin(0, margins.COMMANDS, 0, 0);
		commandsLabelAlign.setRelativeName("commands");
		commandsLabelAlign.setRelativeToWidgetName("control");
		commandsLabel.setLayoutParameter(commandsLabelAlign);

		width = Math.max(width, commandsLabel.width);
		height += margins.COMMANDS + commandsLabel.height;
		this._descriptionLayer.addChild(commandsLabel);

		var conditionsLabel = new ccui.Text(LocalizedString("Conditions"), pm.settings.fontName, pm.settings.fontSize);

		var conditionsLabelAlign = new ccui.RelativeLayoutParameter();
		conditionsLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		conditionsLabelAlign.setMargin(0, margins.CONDITIONS, 0, 0);
		conditionsLabelAlign.setRelativeName("conditions");
		conditionsLabelAlign.setRelativeToWidgetName("commands");
		conditionsLabel.setLayoutParameter(conditionsLabelAlign);

		width = Math.max(width, conditionsLabel.width);
		height += margins.CONDITIONS + conditionsLabel.height;
		this._descriptionLayer.addChild(conditionsLabel);

		var mainAlgorithm = new ccui.Text(LocalizedString("Main algorithm"), pm.settings.fontName, pm.settings.fontSize);

		var mainAlgorithmAlign = new ccui.RelativeLayoutParameter();
		mainAlgorithmAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		mainAlgorithmAlign.setMargin(0, margins.MAIN, 0, 0);
		mainAlgorithmAlign.setRelativeName("mainAlgorithm");
		mainAlgorithmAlign.setRelativeToWidgetName("conditions");
		mainAlgorithm.setLayoutParameter(mainAlgorithmAlign);

		width = Math.max(width, mainAlgorithm.width);
		height += margins.MAIN + mainAlgorithm.height;
		this._descriptionLayer.addChild(mainAlgorithm);

		var algoritmA = new ccui.Text(LocalizedString("Algorithm A"), pm.settings.fontName, pm.settings.fontSize);

		var algoritmAAlign = new ccui.RelativeLayoutParameter();
		algoritmAAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		algoritmAAlign.setMargin(0, margins.A, 0, 0);
		algoritmAAlign.setRelativeName("algorithmA");
		algoritmAAlign.setRelativeToWidgetName("mainAlgorithm");
		algoritmA.setLayoutParameter(algoritmAAlign);

		width = Math.max(width, algoritmA.width);
		height += margins.A + algoritmA.height;
		this._descriptionLayer.addChild(algoritmA);

		this._descriptionLayer.setContentSize(width, height);
	},

	_stopTutorial: function()
	{
		pm.tutorialUtils.stop();
		pm.sendCustomEvent(pm.PROGRAM_RESTART_EVENT_STR);
	},

	_startScreen: function()
	{
		pm.settings.isHelpMode =  false;

		this._stopTutorial();

		this._downloadWorld(this._curWorldID);

		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new StartMenuScene());
		cc.director.runScene(trans);
	},

	_createAlgorithmBar: function(mainButton)
	{
		var layout = new ccui.Layout();

		layout.setLayoutType(ccui.Layout.LINEAR_HORIZONTAL);
		layout.setBackGroundColorType(ccui.Layout.BG_COLOR_SOLID);
		layout.setBackGroundColor(ProgramLayer.BAR_COLOR);

		var buttonMargin = new ccui.LinearLayoutParameter();
		buttonMargin.setMargin(ProgramLayer.MENU_SEPARATOR, ProgramLayer.MENU_SEPARATOR, 0, 0);
		mainButton.setLayoutParameter(buttonMargin);

		layout.addChild(mainButton);

		var width = 2 * ProgramLayer.MENU_SEPARATOR + ProgramContainerLayer.BUTTON_SIZE;

		for (var i = 0; i < ProgramAlgorithmLayer.BAR_LENGTH - 1; ++i)
		{
			var button = new EmptyFunctionButton(FunctionButton.Type.Method);

			button.setLayoutParameter(buttonMargin.clone());

			layout.addChild(button);

			width += ProgramLayer.MENU_SEPARATOR + ProgramContainerLayer.BUTTON_SIZE;
		}

		layout.setContentSize(width, ProgramContainerLayer.BUTTON_SIZE + 2 * ProgramLayer.MENU_SEPARATOR);

		return layout;
	},

	_downloadWorld: function(worldID)
	{
		this._loadingLayer = new LoadingLayer();
		this._loadingLayer.show();

		pm.worldUtils.loadWorld({
			worldID: worldID,
			callback: this._endDownLoadWorld,
			callbackTarget: this,
			reloadBuiltinOnError: true
		});
	},

	_endDownLoadWorld: function(error, loadedWorldID)
	{
		if (!error && loadedWorldID.length > 0 && loadedWorldID[0])
			pm.settings.setSelectedWorldID(loadedWorldID[0]);

		this._loadingLayer.remove();
	},

	getCurrentWorldID: function()
	{
		return this._curWorldID;
	},

	_selectType: function(selectedType)
	{
		if (selectedType)
		{
			switch(selectedType.y)
			{
				case ProgramAlgorithmLayer.PAGES.LINEAR:
					this._linearBarCall();
					return;
				case ProgramAlgorithmLayer.PAGES.IF_ELSE:
					this._ifElseBarCall();
					return;
				case ProgramAlgorithmLayer.PAGES.REPEATERS:
					this._repeaterBarCall();
					return;
				case ProgramAlgorithmLayer.PAGES.WHILE:
					this._cycleBarCall();
					return;
			}
		}
	},

	_linearBarCall: function()
	{
		if (pm.settings.getLevel() === ProgramAlgorithmLayer.TUTORIAL_LEVELS.LINEAR)
			return;

		this._stopTutorial();

		pm.settings.setLevel(ProgramAlgorithmLayer.TUTORIAL_LEVELS.LINEAR);
		this._drawTutorialLevel(true, ProgramAlgorithmLayer.PAGES.LINEAR);
	},

	_ifElseBarCall: function()
	{
		if (pm.settings.getLevel() === ProgramAlgorithmLayer.TUTORIAL_LEVELS.IF_ELSE)
			return;

		this._stopTutorial();

		pm.settings.setLevel(ProgramAlgorithmLayer.TUTORIAL_LEVELS.IF_ELSE);
		this._drawTutorialLevel(true, ProgramAlgorithmLayer.PAGES.IF_ELSE);
	},

	_repeaterBarCall: function()
	{
		if (pm.settings.getLevel() === ProgramAlgorithmLayer.TUTORIAL_LEVELS.REPEATERS)
			return;

		this._stopTutorial();

		pm.settings.setLevel(ProgramAlgorithmLayer.TUTORIAL_LEVELS.REPEATERS);
		this._drawTutorialLevel(true, ProgramAlgorithmLayer.PAGES.REPEATERS);
	},

	_cycleBarCall: function()
	{
		if (pm.settings.getLevel() === ProgramAlgorithmLayer.TUTORIAL_LEVELS.WHILE)
			return;

		this._stopTutorial();

		pm.settings.setLevel(ProgramAlgorithmLayer.TUTORIAL_LEVELS.WHILE);
		this._drawTutorialLevel(true, ProgramAlgorithmLayer.PAGES.WHILE);
	}
});

ProgramAlgorithmLayer.TYPES_ROWS = 1;
ProgramAlgorithmLayer.TYPES_COLUMNS = 4;
ProgramAlgorithmLayer.TYPES_SEPARATOR = 15;
ProgramAlgorithmLayer.STANDARD_SEPARATOR = 15;
ProgramAlgorithmLayer.SMALL_SEPARATOR = 5;
ProgramAlgorithmLayer.BAR_LENGTH = 4;
ProgramAlgorithmLayer.DESCRIPTION_FONT_SIZE = 17;
ProgramAlgorithmLayer.TUTORIAL_SCALE = 0.64;
ProgramAlgorithmLayer.TUTORIAL_ID = 1001;
ProgramAlgorithmLayer.ADDITIONAL_HEIGHT = 200;
ProgramAlgorithmLayer.TUTORIAL_LEVELS = {
	LINEAR: 1,
	IF_ELSE: 3,
	REPEATERS: 0,
	WHILE: 4
};
ProgramAlgorithmLayer.PAGES = {
	LINEAR: 0,
	IF_ELSE: 1,
	REPEATERS: 2,
	WHILE: 3
};
ProgramAlgorithmLayer.MARGINS = {
	LINEAR: {
		CONTROL: 6,
		COMMANDS: 16,
		MAIN: 22,
		A: 19
	},
	IF_ELSE: {
		CONTROL: 6,
		COMMANDS: 16,
		CONDITIONS: 5,
		MAIN: 48,
		A: 19
	},
	REPEATERS: {
		CONTROL: 6,
		REPEATERS: 16,
		COMMANDS: 5,
		MAIN: 22
	},
	WHILE: {
		CONTROL: 6,
		COMMANDS: 16,
		CONDITIONS: 5,
		MAIN: 48,
		A: 44
	}
};

/**
 * @class Scene for {@link ProgramAlgorithmLayer}
 * @extends cc.Scene
 * @constructor
 */
var ProgramAlgorithmScene = cc.Scene.extend(/** @lends ProgramAlgorithmScene# */{
	ctor:function (worldID)
	{
		this._super();

		var layer = new ProgramAlgorithmLayer(worldID);
		this.addChild(layer);

		var backLayer = pm.backgroundUtils.generateBackground();
		this.addChild(backLayer, -1);
	}
});
