/**
 * This property is used to store constructors for all "data-classes".
 * Do not change anything in this namespace.
 */
pm.classMapping = {};
/**
 * This function loads "data-classes" constructors from data namespace
 * @see data
 */
pm.loadClassMapping = function()
{
	for(var key in pm.data)
		pm.classMapping[pm.data[key].prototype.typeName] = pm.data[key];
};
