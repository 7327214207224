/**
 * Created by Nikita Besshaposhnikov on 16.11.15.
 */

/**
 * @class 2D map for {@link pm.data.PhysicalRobotLevel}
 * @implements SquarableMap
 */
pm.data.PhysicalRobotMap = SquarableMap.extend(/** @lends pm.data.PhysicalRobotMap# */{
	typeName: "PhysicalRobotMap",
	_visitedCells: [],

	ctor: function()
	{
		this._addNonEnumerableProps("_visitedCells");
		this._super();
	},

	hasWalls: function() { return false; },

	getType: function() { return pm.PhysicalRobotLevelModule.Type; },

	generateMapLayer: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (!pm.settings.isEditorMode)
				this.mapLayer = new PhysicalRobotMapLayer(this);
			else
				this.mapLayer = new PhysicalRobotMapEditorLayer(this);

			this.mapLayer.drawMap(previewDraw);

			return this.mapLayer;
		}
	},

	setSize: function(width, height)
	{
		this.mapElements.length = 0;

		for(var i = 0; i < height; ++i)
		{
			this.mapElements.push([]);

			for(var j = 0; j < width; ++j)
				this.mapElements[i].push(new pm.data.PhysicalRobotMapElement());
		}

		this.width = width;
		this.height = height;
	},

	clean: function()
	{
		this._visitedCells = [];

		Map2D.prototype.clean.call(this);
	},

	getVisitedCells: function()
	{
		return this._visitedCells;
	}
});
