/**
 * Created by Nikita Besshaposhnikov on 05.12.14.
 */

/**
 * @class Layer for displaying program of robot in editor.
 * @extends ProgramLayer
 * @constructor
 * @param {GameType} gameType
 * @param {pm.AbstractRobot} [robot] For which robot construct layer.
 */
var ProgramEditorLayerK = ProgramLayerK.extend(/** @lends ProgramEditorLayer# */{

	ctor: function(gameType, useMethodStack, robot, level)
	{
		this._super(gameType, useMethodStack, robot, level);
	},

	_drawClearProgramButton: function() {},
	isMethodCountLimited: function() { return false; },
	_canAddFunctions: function() { return true; },
	isProgramPatternEditable: function() { return true; }
});
