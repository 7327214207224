/**
 * Created by Nikita Besshaposhnikov on 13.11.14.
 */

/**
 * @class Common interface for hints.
 * @interface
 * @extends pm.Class
 */
pm.Hint = pm.Class.extend(/** @lends pm.Hint#*/{
	ctor: function()
	{
		this._super();
	},

	/**
     * Returns true if hint is empty.
     * @function
     * @name pm.Hint#empty
     * @returns {Boolean}
     */
	empty: function()
	{
		return false;
	}
});

/**
 * @class pm.Hint for functions.</br>
 * Contains text part and some function part.
 * @implements pm.Hint
 */
pm.data.FunctionHint = pm.Hint.extend(/** @lends pm.data.FunctionHint# */{
	typeName: "FunctionHint",

	/**
     * Text part of hint.
     * @type {String}
     */
	text: "",
	/**
     * Function part of hint.
     * @type {Array<Array<pm.data.Method>>}
     */
	funcBlockHints: [],

	ctor: function()
	{
		this._super();

		this.funcBlockHints = [];
	},

	empty: function()
	{
		var emptyBlockHints = true;

		for(var i = 0 ; i < this.funcBlockHints.length; ++i)
		{
			if(this.funcBlockHints[i].length !== 0)
				emptyBlockHints = false;
		}

		return this.text.length === 0 && emptyBlockHints;
	}
});

/**
 * @class pm.Hint for whole level.</br>
 * Contains only text part.
 * @implements pm.Hint
 */
pm.data.GlobalHint = pm.Hint.extend(/** @lends pm.data.GlobalHint# */{
	typeName: "GlobalHint",

	/**
     * Text part of hint
     * @type {String}
     */
	text: "",

	ctor: function()
	{
		this._super();
	},

	empty: function()
	{
		return this.text.length === 0;
	}
});
