/**
 * Created by Nikita Besshaposhnikov on 08.07.15.
 */

/**
 * @class Common interface for objects pushed by {@link pm.data.PushRobot}
 * @interface
 * @extends pm.Object
 */
var PushObject = pm.Object.extend(/** @lends PushObject#*/{

	oldPosition: cc.p(),

	ctor: function()
	{
		this._addNonEnumerableProps("oldPosition");
		this._super();
	},

	/**
     * Sets position of object by element position.
     * @param {cc.Point} point Point in element coordinates.
     */
	setPosition: function(point)
	{
		this._removeFromCurrentPosition();

		this.position = point;
		this.oldPosition = this.position;

		this.parentMap.element(point).addObject(this);
	},

	/**
     * Returns if push object is moving
     */
	isMoving: function()
	{
		return this.oldPosition.x !== this.position.x || this.oldPosition.y !== this.position.y;
	},

	/**
     * Moves object to element position.
     * @param {cc.Point} target Move coordinate in elements.
     */
	moveTo: function(target)
	{
		if (this.position.x === target.x && this.position.y === target.y)
			return;

		this.oldPosition = this.position;
		this.position = target;

		this.parentMap.element(target).addObject(this);

		this.playAnimation(PushObjectAnimation.Move, this._endMoving, this.position);
	},

	_endMoving: function()
	{
		this._isPlayingAnimation = false;

		this._removeFromOldPosition();
		this.oldPosition = this.position;
	},

	_removeFromOldPosition: function()
	{
		if(this.oldPosition.x < this.parentMap.width && this.oldPosition.y < this.parentMap.height)
		{
			if(this.parentMap.element(this.oldPosition).containsObject(this))
				this.parentMap.element(this.oldPosition).removeObject(this);
		}
	},

	_removeFromCurrentPosition: function()
	{
		if(this.position.x < this.parentMap.width && this.position.y < this.parentMap.height)
		{
			if(this.parentMap.element(this.position).containsObject(this))
				this.parentMap.element(this.position).removeObject(this);
		}
	},

	updateSprite: function(isBroken)
	{
		if (isBroken === undefined)
			isBroken = false;

		if (!isBroken)
			this.sprite.setRealPosition(this.position);
		else
			this.sprite.setRealPosition(this.oldPosition);
	},

	reset: function ()
	{
		this._isPlayingAnimation = false;
		this._removeFromOldPosition();
		this.setPosition(this.startPosition);
	},

	generateSprite: function(previewDraw) {},

	getState: function ()
	{
		return this.position;
	},

	setState: function (state)
	{
		if (this.position !== state)
			this.moveTo(state);
	}
});
