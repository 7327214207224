/**
 * Created by Diana Agliamutdinova on 01.05.2019.
 */

/**
 * @class Represents program of one robot.
 */
var ProgramContainerLayerK = ProgramContainerLayer.extend(/** @lends ProgramContainerLayer# */{

	_visualType: 0,
	_nodeTree: null,
	_programTree: null,
	shift: 0,

	ctor: function (programLayer, program)
	{
		this._super(programLayer, program);

		this._createNodeTree(this._program.programTree);

		pm.registerCustomEventListener(pm.CHANGE_SYNTAX, function (event)
		{
			this._visualType = (this._visualType + 1) % pm.syntaxModules.getModulesCount();

			this.updateProgram(this._program, this._programLayer);
		}.bind(this), this);

		pm.registerCustomEventListener(pme.ADD_GLOBAL_ROBOT, function (event)
		{
			this._programTree.addOutput(event.getUserData());
		}.bind(this), this);
	},

	/** makes Visual tree from ProgramData
	 * adds it to ProgramContainer
	 * @param programTree syntax tree from ProgramData
	 * @private
	 */
	_createNodeTree: function (programTree)
	{
		!this._programTree
			? this._programTree = new ProgramTree(
									this._visualType,
									programTree,
									this._programLayer.getRobot(),
									this,
									this._programLayer.getOutputRobot())
			: this._programTree.createTree(
									this._visualType,
									programTree,
									this._programLayer.getRobot(),
									this,
									this._programLayer.getOutputRobot());

		this._nodeTree = this._programTree.getTree();
		this._visualType = this._programTree.getMode();

		var treeAlign = new ccui.RelativeLayoutParameter();
		treeAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		treeAlign.setMargin(0, 0, pmui.NodeLayer.SEPARATORX, 0);
		this._nodeTree.setLayoutParameter(treeAlign);

		this._recalculateSizes();
	},

	getTree: function ()
	{
		return this._nodeTree;
	},

	_recalculateSizes: function ()
	{
		var oldHeight = this.height;
		var height = 0;
		var width = 0;
		if (this._programTree)
		{
			width = pm.appUtils.getProgramLayerWidth() - ProgramLayer.SCROLL_BAR_WIDTH;
			height += this._nodeTree.height;
		}

		this.shift = height - oldHeight;
		this.setContentSize(width, height);
		pm.sendCustomEvent(pm.UPDATE_INNER_PROGRAM_LAYER_CONTAINER);
	},

	_cloneElement: function (element, touch)
	{
		if(pm.syntaxModules.isTextual(this._visualType))
			return false;
		if (element.isPartlyClonable)
		{
			var touchCoords = element.getParent().convertTouchToNodeSpace(touch);
			var box = element.getBoundingBox();
			return cc.rectContainsPoint(new cc.rect(box.x, box.y, box.width/2, box.height), touchCoords)
					? element.clone()
					: null;
		}
		return element.clone();
	},

	_dragCallback: function (element, eventType, touch, clonedElem)
	{
		var activeObject = null;
		switch (eventType)
		{
			case pmui.DragAndDropLayout.Event.CLICKED:
				activeObject = this._onClick(element, touch);
				break;
			case pmui.DragAndDropLayout.Event.DRAG_CANCELLED:
				if (pm.tutorialUtils.onDragCancelled(touch))
					activeObject = this._onDragCancel(element, touch);
				break;
			case pmui.DragAndDropLayout.Event.DROP:
				activeObject = this._onDropButton(element, touch);
				break;
			case pmui.DragAndDropLayout.Event.DRAG_START:
				if(!pm.syntaxModules.isTextual(this._visualType))
					this._programLayer.onDragStart(clonedElem, touch);
				break;
		}
		if(activeObject)
		{
			pm.sendCustomEvent(pm.USER_PROGRAM_UPDATED_STR);
			var pos = this.convertToWorldSpace(this.convertTouchToNodeSpace(touch));
			this._recalculateSizes();
			this._programLayer.updateView(activeObject.height, pos.y);
		}
	},

	_onClick: function (button, touch)
	{
		var element = this._programLayer._findDropBody(touch, null);
		if(!element)
			return;
		if(this._program.canEditProgramPattern && element.setNonEditable()) // todo: what is this line for?
			return;

		var selectedElement = FunctionButton.selectedButton;
		if(!selectedElement)
			selectedElement = this.getSelectedNode();

		if(selectedElement)
		{
			ProgramContainerLayer.select(element);

			if ((element.canReplace(selectedElement.type) && element.isEditable())
				|| (element._parentNode && element._parentNode.canAdd(selectedElement.type)))
			{
				FunctionButton.deselect();
				return this._onDropButton(selectedElement, touch);
			}
			ProgramContainerLayer.deselect();
		}
		else if (element.canReplace(element.type) && element.isEditable() && !element.isExpression()
			|| element._treeNode && element._parentNode && element._parentNode.canAdd(element.type) && !element.isFunction())
		{
			ProgramContainerLayer.select(element);
		}
		return element.clickCallback();
	},

	canAdd: function()
	{
		return false;
	},

	_onDropButton: function (button, touch)
	{
		var pasteObject = null;
		var element  = this.getSelectedNode();
		// var element  = this._programLayer._findDropBody(touch, null);

		if(!element)
			return;

		if(button instanceof pmui.NodeLayer)
			pasteObject = this._programTree.moveNode(button, element);
		else if (button.type === FunctionButton.Type.MethodList)
			pasteObject = this._programTree.pasteNodeList(element, button.getMethodList());
		else
			pasteObject = this._programTree.pasteNode(element._parentNode, button, element);

		if (element instanceof pmui.ReplaceNode && button.type !== FunctionButton.Type.Empty)
			element._removeNodeFromTree();

		if (pasteObject)
			pasteObject.calculateSize();

		if(this._programTree.getTree().width > this.width)
		{
			var elem = pasteObject._removeNodeFromTree();
			pm.sendCustomEvent(pm.RESET_HIGHLIGHT);
			elem.calculateSize();
		}
		if(this._programTree.getMethodsCount() >= this._program.maxMethodCount)
			this._programLayer._formatButs(ProgramLayer.FUNCTIONS_BUTTON_TAG);

		ProgramContainerLayer.deselect();
		return pasteObject;
	},

	_findElementByTouch: function (touch, button)
	{
		if(!pm.syntaxModules.isTextual(this._visualType))
			return this._programLayer._findNode(touch, this._nodeTree, button);
	},

	_isElementClickable: function(element)
	{
		if(pm.syntaxModules.isTextual(this._visualType) ||
			(element instanceof pmui.NodeLayer &&
				(element.isBody() || element.isProgram() || element.isMain())))
			return false;
		return true;
	},

	_isElementDraggable: function (element, touch)
	{
		if(element instanceof pmui.NodeLayer &&
			(element.isBody() || element.isProgram() || element.isMain()))
			return false;
		if(element.isPartlyClonable)
		{
			var box = element.getBoundingBox();
			var touchCoords = element.getParent().convertTouchToNodeSpace(touch);
			return cc.rectContainsPoint(
				new cc.rect(box.x, box.y, box.width / 2, box.height), touchCoords);
		}
		return true;
	},

	_onDragCancel: function (button, touch)
	{
		FunctionButton.deselect();
		ProgramContainerLayer.deselect();
		if(!(pm.settings.isEditorMode || button.isEditable()))
		{
			button.removeCallbackAction();
			return;
		}

		var par = button._removeNodeFromTree();
		pm.sendCustomEvent(pm.RESET_HIGHLIGHT);
		return par;
	},

	updateProgram: function (program, programLayer)
	{
		this._programLayer = programLayer;
		this._program = program;

		this._createNodeTree(this._program.programTree);
	},

	reset: function ()
	{
		this._programTree.reset();
	},

	getSelectedNode: function ()
	{
		return this._findSelected(this._nodeTree);
	},

	_findSelected: function (node)
	{
		if (node.getTag() === ProgramContainerLayer.SELECTED_TAG)
			return node;

		var selectedNode = null;
		node.getChildren().forEach(function (kid){
			var res = this._findSelected(kid);
			if(res)
			{
				selectedNode = res;
				return;
			}
		}, this);
		return selectedNode;
	}
});

ProgramContainerLayer.FONT_NAME = pm.fonts.getRealFontName("Evolventa");
ProgramContainerLayer.FONT_SIZE = 20;
ProgramContainerLayer.SEPARATOR_SIZEX = 40;
ProgramContainerLayer.BORDER_X = 10;

ProgramContainerLayer.SELECTED_TAG = 199;
ProgramContainerLayer.NOT_SELECTED_TAG = 299;

ProgramContainerLayer.deselect = function()
{
	pm.sendCustomEvent(pm.DESELECT_NODE);
};

ProgramContainerLayer.select = function(elem)
{
	ProgramContainerLayer.deselect();
	elem.select();
};
