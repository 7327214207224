"use strict";

/**
 * Created by Nikita Besshaposhnikov on 07.04.17.
 */

/**
 * Callback called on change state of physical connector.
 * @callback pm.PhysicalConnector~connectionCallback
 * @param {pm.PhysicalConnector.STATE} status
 */

/**
 * @class Interface for physical representation of levels.
 * @interface
 * @extends cc.Class
 */
pm.PhysicalConnector = cc.Class.extend(/** @lends pm.PhysicalConnector# */{

	/**
	 * Has connected to physical representation
	 * @type {Boolean}
	 * @private
	 */
	_connected: false,
	/**
	 * Connected to physical representation callback
	 * @type {pm.PhysicalConnector~connectionCallback}
	 * @private
	 */
	_stateCallback: null,

	/**
	 * Is processing commands or animations
	 * @type {Boolean}
	 * @private
	 */
	_busy: false,

	/**
	 * @type {pm.AbstractLevel}
	 * @private
	 */
	_level: null,

	ctor: function(level)
	{
		this._level = level;
	},

	/**
     * Connects to physical robot
     */
	connect: function() {},
	/**
     * Disconnects to physical robot
     */
	disconnect: function() {},
	/**
     * Executes command of robot in physical environment
	 * @param {Number} id Robot id
     * @param {String} command
     * @param {*} [state] State of robot on execute of function.
     */
	executeRobotCommand: function(id, command, state) {},
	/**
	 * Play animation of object in physical environment
	 * @param {Number} id Object id
	 * @param {String} animation
	 */
	playObjectAnimation: function(id, animation) {},
	/**
     * Returns if connected to physical robot
     * @returns {Boolean}
     */
	isConnected: function()
	{
		return this._connected;
	},

	/**
     * Returns if physical robot is running command
     * @returns {Boolean}
     */
	isBusy: function()
	{
		return this._busy;
	},

	/**
     * Sets connections status callback
     * @param {pm.PhysicalConnector~connectionCallback}callback
     */
	setStateCallback: function(callback)
	{
		this._stateCallback = callback;
	},

	_endCommand: function ()
	{
		this._busy = false;
	}
});

/**
 * Connections state of physical robot
 * @enum {Number}
 * @readonly
 */
pm.PhysicalConnector.STATE = {
	CONNECTED: 0,
	DISCONNECTED: 1,
	CONNECTING: 2
};

/**
 * Type for physical robots.
 * @enum {String}
 * @readonly
 */
pm.PhysicalConnectorType = {
	None: "none",
	Lego: "lego",
	BLE: "ble",
	Sound: "sound",
	Magnet: "magnet"
};
/**
 * Returns physical robot for type.
 * @static
 * @param {pm.PhysicalConnectorType} type
 * @param {pm.AbstractLevel} level
 * @returns {?pm.PhysicalConnector}
 */
pm.PhysicalConnector.getConnectorForType = function(type, level)
{
	switch(type)
	{
		case pm.PhysicalConnectorType.Lego:
			return new pm.LegoPhysicalConnector(level);
		case pm.PhysicalConnectorType.BLE:
			return new pm.BLEPhysicalConnector(level);
		case pm.PhysicalConnectorType.Sound:
			return new pm.SoundPhysicalConnector(level);
		case pm.PhysicalConnectorType.Magnet:
			return new pm.MagnetPhysicalConnector(level);
		default:
			return null;
	}
};
