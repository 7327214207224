/**
 * Created by danilaeremin on 11.08.15.
 */

/**
 * @class Layer for robot settings.
 * @extends LevelSettingsLayer
 * @constructor
 * @param {pm.AbstractLevel} level Level which settings shows
 */
var ActiveObjectSettingsLayer = LevelSettingsLayer.extend(/** @lends LoginLayer# */{
	_object: null,
	_settings: null,

	ctor: function(level)
	{
		this._super(level);

		var object = level.robots[level.startRobotIndex];

		this.setObject(object);

		pm.registerCustomEventListener(pm.ROBOT_TOUCH_EVENT_STR, function(event)
		{
			this.setObject(event.getUserData());
		}.bind(this), this);

		pm.registerCustomEventListener(pm.MAP_DRAGGED_EVENT_STR, function(event)
		{
			this.setObject(this._object);
		}.bind(this), this);

		pm.registerCustomEventListener(pm.PATTERN_CHANGED, function(event)
		{
			this.setObject(event.getUserData());
		}.bind(this), this);
	},

	/**
     * Show settings of object
     * @param object Robot which settings needs to show
     */
	setObject: function(object)
	{
		this._object = object;

		this._drawSettings(object);
	},

	_drawSettings: function (object)
	{
		if (this._settings !== null)
			this.removeChild(this._settings);

		this._settings = pm.moduleUtils.getSettings(this._object.parentLevel.getType(), this._object.getType(), object);

		var settingsAlign = new ccui.LinearLayoutParameter();
		settingsAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
		settingsAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, LevelSettingsLayer.BORDER_RADIUS);
		this._settings.setLayoutParameter(settingsAlign);

		this.addChild(this._settings);
		this.updateSize(LevelSettingsLayer.WIDTH, this._settings.height);
	}
});
