/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.ExpressionNode = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{
	_lOp: null,
	_rOp: null,
	_sign: null,

	ctor: function (treeNode, parent, syntaxModule, sign)
	{
		this._super(parent, treeNode, syntaxModule);

		this.setLayoutType(ccui.Layout.RELATIVE);
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
		{
			var leftBrace = this.createText("(");
			var rightBrace = this.createText(")");
			this.createALignSome(leftBrace, "lOp", "lbrace", ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL, 0, 0, 0, 0);
			this.createALignSome(rightBrace, "right", "rbrace", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, this.getNodeMargin(), 0, 0, 0);
			this.addChild(leftBrace);
			this.addChild(rightBrace);
		}

		this._sign = this.getSign(sign);//.clone();
		this._sign.setSwallowTouches(true);
		this._sign.setPropagateTouchEvents(true);

		var centerAlign = new ccui.RelativeLayoutParameter();
		centerAlign.setMargin(5, 2, 0, 2);
		centerAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		centerAlign.setRelativeName("center");
		centerAlign.setRelativeToWidgetName("lOp");
		this._sign.setLayoutParameter(centerAlign);
		this.addChild(this._sign);
	},

	changeSign: function(sign)
	{
		var align = this._sign.getLayoutParameter().clone();
		this._sign.removeFromParent();
		this._sign = sign;
		sign.setLayoutParameter(align);
		this.addChild(this._sign);
		this._treeNode.setNodeView(this);
	},

	getSign: function(val)
	{
		var sign;
		if (pm.syntaxModules.isTextual(this.getSyntaxModule()))
			sign = this.createText(pm.ArythmSignsTexts[val]);
		else
			sign = new ccui.ImageView(pm.spriteUtils.getSignSpriteName(val, pm.NORMAL_STATE), ccui.Widget.PLIST_TEXTURE);
		return sign;
	},

	addChildNode: function (node)
	{
		var tag = node._treeNode.getTagName();
		if (tag === pm.ExpressionLeftTag)
		{
			if(this._lOp)
			{
				var parent = this._treeNode;
				parent.removeChild(this._lOp._treeNode);

				var align = this._lOp.getLayoutParameter().clone();
				node.setLayoutParameter(align);
				this.removeChild(this._lOp);
			}
			else
			{
				if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
				{
					this.createALignSome(node, "lbrace", "lOp", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, this.getNodeMargin(), 2, 0, 2);
				}
				else
				{
					this.createALignSome(node, "lbrace", "lOp", ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL, this.getNodeMargin(), 2, 0, 2);
				}
			}
			this._lOp = node;
			this.addChild(node);
			//this._cont.addChild(this._lOp);
		}
		else if (tag === pm.ExpressionRightTag)
		{
			if(this._rOp)
			{
				var parent = this._treeNode;
				parent.removeChild(this._rOp._treeNode);

				var align = this._rOp.getLayoutParameter().clone();
				node.setLayoutParameter(align);
				this.removeChild(this._rOp);
			}
			else
			{
				var rightAlign = new ccui.RelativeLayoutParameter();
				rightAlign.setMargin(5, 2, this.getNodeMargin(), 2);
				rightAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
				rightAlign.setRelativeName( "right");
				rightAlign.setRelativeToWidgetName("center");
				node.setLayoutParameter(rightAlign);
			}

			this._rOp = node;
			this.addChild(node);
		}
		this.calculateSize(true);
	},

	_removeNode: function (node)
	{
		var treeNode = pm.data.SyntaxTree.createNode(this._treeNode, pm.data.Node.Type.Number, 0);
		treeNode.setTagName(node._treeNode.getTagName());
		pm.sendCustomEvent(pm.PARSE_NODE, {node: treeNode, parent: this});
	},

	calculateSize: function (forPar)
	{
		this.sizeOfContainer(this);
		if (!forPar)
			this._parentNode.calculateSize();
	},

	isExpression:function()
	{
		return true;
	},

	getChildrenNodes: function ()
	{
		return [this._rOp, this._lOp];
	}
});

