/**
 * Created by Kirill Mashchenko on 23.09.2017.
 */

/**
 * @class Common layer for global robot settings.
 * @extends ccui.Layout
 */
var GlobalRobotLayer = ccui.Layout.extend(/** @lends GlobalRobotLayer# */{

	_robotType: null,

	_robot: null,

	ctor: function(header, robot, robotType)
	{
		this._super();

		this._robotType = robotType;

		this._robot = robot;

		this.setLayoutType(ccui.Layout.LINEAR_VERTICAL);

		var headerLayout = new ccui.HBox();
		var headerAlign = new ccui.LinearLayoutParameter();
		headerAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
		headerLayout.setLayoutParameter(headerAlign);

		var label = new ccui.Text(header, pm.settings.fontName, pm.settings.fontSize);
		var labelAlign = new ccui.LinearLayoutParameter();
		labelAlign.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
		label.setLayoutParameter(labelAlign);

		var deleteButton = new pmui.Button(pm.spriteUtils.getIconName("clear", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("clear", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("clear", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		deleteButton.setScale(0.7);
		deleteButton.addClickEventListener(this._delete.bind(this));

		headerLayout.addChild(label);
		headerLayout.addChild(deleteButton);
		this.addChild(headerLayout);

		headerLayout.setContentSize(deleteButton.width + label.width, Math.max(deleteButton.height, label.height));
		this.setContentSize(LevelSettingsLayer.WIDTH, headerLayout.height);
	},

	getGlobalRobotType: function()
	{
		return this._robotType;
	},

	_delete: function()
	{
		this._onRobotRemoved();
		this._removeRobot();
	},

	_removeRobot: function()
	{
		var level = this._robot.parentLevel;
		delete level.globalRobotConfig[this._robot.getType()];

		for (var i = 0; i < level.globalRobots.length; ++i)
		{
			if (this._robot.id === level.globalRobots[i].id)
				level.globalRobots.splice(i, 1);
		}

		pm.sendCustomEvent(pme.REMOVE_GLOBAL_ROBOT, this._robot);

		this.getParent().getParent().removeChild(this);
	},

	_onRobotRemoved: function(){},

	refresh: function() {}

});
