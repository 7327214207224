/**
 * Created by danilaeremin on 14.08.15.
 */

var MapLayersEditActions = {
	None: 0,
	AddUp: 1,
	AddDown: 2,
	DeleteCurrent: 3
};

/**
 * @class This class provides a container for map layers in editor.</br>
 * @extends pm.MapsContainerLayer
 * @constructor
 * @param {pm.AbstractLevel} level Level which maps will be represented.
 * @param {Boolean} previewDraw Preview draw of maps.
 */
var MapsContainerEditorLayer = pm.MapsContainerLayer.extend(/** @lends MapsContainerEditorLayer# */{
	_layersListener: null,

	ctor: function(level, previewDraw)
	{
		this._super(level, previewDraw);
		this._enableScroll = true;

		this._layersListener = pm.registerCustomEventListener(pme.MAP_LAYERS_COUNT_CHANGED_EVENT_STR, function(event)
		{
			switch (event.getUserData())
			{
				case MapLayersEditActions.AddUp:
					this._addUpLayer();
					break;
				case MapLayersEditActions.AddDown:
					this._addDownLayer();
					break;
				case MapLayersEditActions.DeleteCurrent:
					this._deleteLayer();
					break;
			}

		}.bind(this), 1);
	},

	_mapChangedEventCallback: function(event)
	{
		this.changeActiveMap(event.getUserData().index, event.getUserData().sendEvent, event.getUserData().rebaseRobots);
		this._level.startMapIndex = event.getUserData().index;
	},

	onExit: function ()
	{
		this._super();

		cc.eventManager.removeListener(this._layersListener);
	},

	_addUpLayer: function ()
	{
		var maps = this._level.maps;

		var addMap = this._createNewLayer();

		maps.splice(this.activeMapIndex + 1, 0, addMap);

		this._parallax.addChild(addMap.mapLayer, this.activeMapIndex + 1);

		for(var i = 0; i < this._level.maps.length; ++i)
			this._level.maps[i].mapLayer.checkPositionTasks();

		this._updateLayers();

		this._level.startMapIndex = this.activeMapIndex + 1;
		this.changeActiveMap(this.activeMapIndex + 1, false, true);
	},

	_addDownLayer: function ()
	{
		var maps = this._level.maps;

		var addMap = this._createNewLayer();

		maps.splice(this.activeMapIndex, 0, addMap);

		this._parallax.addChild(addMap.mapLayer, this.activeMapIndex);

		for(var i = 0; i < this._level.maps.length; ++i)
			this._level.maps[i].mapLayer.checkPositionTasks();

		this._updateLayers();

		this.activeMapIndex++;

		this._level.startMapIndex = this.activeMapIndex - 1;
		this.changeActiveMap(this.activeMapIndex - 1, false, true);
	},

	_deleteLayer: function ()
	{
		if (this._level.maps.length === 1)
			return;

		var index = this.activeMapIndex;
		var maps = this._level.maps;

		var to = this.activeMapIndex - 1;

		if (to === -1)
		{
			to = 1;
			this._level.startMapIndex = 0;
		}
		else
		{
			this._level.startMapIndex = to;
		}

		this._rebaseRobots(this.activeMapIndex, to);

		this._parallax.removeChild(maps[index].mapLayer, true);

		maps.splice(index, 1);

		this.activeMapIndex--;

		if (this.activeMapIndex === -1)
			this.activeMapIndex++;

		if (this._level.startMapIndex >= this._level.maps.length)
			this._level.startMapIndex = this._level.maps.length - 1;

		this._updateLayers();

		pm.sendCustomEvent(pm.MAP_DRAGGED_EVENT_STR, {index: this.activeMapIndex} );
	},

	_createNewLayer: function ()
	{
		var map = pm.moduleUtils.generateEmptyMap(
			this._level.getType(),
			this._level
		);

		if (map)
		{
			map.load();
			map.generateMapLayer(false);
		}

		return map;
	}
});
