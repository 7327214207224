/**
 * This function deserializes an array from raw array with "data-classes"
 * @param {Array} sourceArray A raw array
 */
Array.prototype.deserialize = function(sourceArray)
{
	for (var i = 0; i < sourceArray.length; ++i)
	{
		if (cc.isArray(sourceArray[i]))
		{
			this[i] = new Array();
			this[i].deserialize(sourceArray[i]);
		}
		else if (sourceArray[i] && cc.isObject(sourceArray[i]))
		{
			try {
				var o = new pm.classMapping[sourceArray[i].typeName]();

				o.deserialize(sourceArray[i]);
			}
			catch(exception){
				console.log(exception);
			}
			this.push(o);
		}
		else { this.push(sourceArray[i]); }
	}
};

if(CORE_BUILD || cc.sys.isNative)
{
	cc.clone = function (obj)
	{

		var newObj = (obj.constructor) ? new obj.constructor : {};

		for (var key in obj)
		{
			var copy = obj[key];
			// Beware that typeof null === "object" !
			if (((typeof copy) === "object") && copy)
				newObj[key] = cc.clone(copy);
			else
				newObj[key] = copy;
		}
		return newObj;
	};
}

/**
 * This functions create a full clone of an Class object
 * @param {pm.Class} obj An object to clone
 * @returns {pm.Class} Cloned object
 */
var cloneClassObject = function(obj)
{
	var newObj = (obj.constructor) ? new obj.constructor : {};

	for (var key in obj)
	{
		var copy = obj[key];
		// Beware that typeof null==="object" !
		if (copy && (cc.isObject(copy) || cc.isArray(copy)))
		{
			if(obj.propertyIsEnumerable(key))
				newObj[key] = cloneClassObject(copy);
		}
		else
		{
			newObj[key] = copy;
		}
	}
	return newObj;
};
/**
 * @class This is the base class for all serializable objects.</br>
 * Object is serialized to json format and deserialized from json with all class functions.<br/>
 * Use "_addNonEnumerableProps" method in ctor of class before calling "super"-method to <br/>
 * specify properties excluded from serialization.
 * @extends cc.Class
 */
pm.Class = cc.Class.extend(/** @lends Class# */{

	ctor: function()
	{
		//try {

		//if(this.typeName === undefined)
		//	return;

		var nonEnumerableProps = this.__nonEnumerableProps;

		for (var key in this)
		{
			var obj = this[key];

			if (key !== "__nonEnumerableProps" && key !== "__pid" && key !== "__instanceId"&& !cc.isFunction(obj))
			{

				var definedObject;

				if(obj && (cc.isArray(obj) || cc.isObject(obj)))
					definedObject = cc.clone(obj);
				else
					definedObject = obj;

				Object.defineProperty(this, key, {
					enumerable: nonEnumerableProps ? (nonEnumerableProps.indexOf(key) < 0) : true,
					configurable: false,
					writable: true,
					value: definedObject
				});
			}
		}

		Object.defineProperty(this, "__instanceId", {
			enumerable: false,
			configurable: false,
			writable: true,
			value: this.__instanceId
		});
		//}
		//catch(e)
		//{
		//	console.log(this.typeName);
		//}
	},
	/**
	 * This function will serialize class to JSON file by filePath
	 * @param {String} filePath path to file
	 */
	serialize: function(filePath)
	{
		if(!CORE_BUILD)
			pm.fileUtils.saveObject(filePath, this);
	},
	/**
	 * This function will construct class based know "data-classes" by raw JSON data.
	 * @param {String | Object} object File path or an object to deserialize
	 * @param {Boolean} [createNewObject=false] If createNewObject is true,
	 * function will return a new copy if "this" object
	 * @returns {pm.Class | undefined}
	 */
	deserialize: function(object, createNewObject)
	{
		if(createNewObject === undefined)
			createNewObject = false;

		var data;

		if(typeof object === "string")
		{
			if(!CORE_BUILD)
				data = pm.fileUtils.readObject(object);
			else
				data = JSON.parse(object);
		}
		else
		{
			data = object;
		}

		var self = this;

		if(createNewObject)
			self = new pm.classMapping[data.typeName]();

		for(var key in data)
		{

			if(data[key] === undefined || data[key] === null)
				continue;

			if(cc.isArray(data[key]))
			{
				self[key] = new Array();
				self[key].deserialize(data[key]);
			}
			else if (cc.isObject(data[key]))
			{

				if(data[key].typeName !== undefined && pm.classMapping[data[key].typeName])
				{
					self[key] = new pm.classMapping[data[key].typeName]();

					self[key].deserialize(data[key]);
				}
				else
				{
					self[key] = cc.clone(data[key]);
				}

			}
			else { self[key] = data[key]; }

		}

		if(createNewObject)
			return self;
	},

	_addNonEnumerableProps: function(props)
	{
		if(!this.hasOwnProperty("__nonEnumerableProps"))
		{
			Object.defineProperty(this, "__nonEnumerableProps", {
				enumerable: false,
				configurable: false,
				writable: true,
				value: []
			});
		}

		for(var i = 0; i < arguments.length; ++i)
		{
			if(this["__nonEnumerableProps"].indexOf(arguments[i]) < 0)
				this["__nonEnumerableProps"].push(arguments[i]);
		}
	}

});
