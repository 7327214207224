/**
 * Created by danilaeremin on 11.08.15.
 */

/**
 * @class Layer for repair robot settings.
 * @extends PlayerRobotSettingsLayer
 * @constructor
 * @param {pm.AbstractRobot} robot Robot which settings need to display.
 */
var RepairRobotSettingsLayer = PlayerRobotSettingsLayer.extend(/** @lends RepairRobotSettingsLayer# */{
	_repairSlider: null,
	_repairLabel: null,
	_repairValue: 0,

	ctor: function(robot)
	{
		this._super(robot);

		this._label.setString(LocalizedString("Repair") + " " + robot.id + ", " + LocalizedString("Pattern") + " " + robot.parentLevel.programData[robot.groupID].currentIndex);
	}
});
