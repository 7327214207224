/**
 * Created by Nikita Besshaposhnikov on 16.02.18.
 */

/**
 * This callbacks are used on Ok and Cancel buttons.
 * @callback LevelConflictLayer~okCallback
 * @param {Array<Object>} levelsToUpdate Levels selected to update
 * @param {Array<String>} levelsToSync Levels selected to sync
 */

/**
 * @class Layout for login in editor.
 * @extends pmui.OvalLayout
 * @constructor
 * @param {LevelConflictLayer~actionCallback?} callback Called on Ok button clicked.
 */
var LevelConflictLayer = pmui.OvalLayout.extend(/** @lends LevelConflictLayer# */{
	_object: null,
	_callback: null,
	cancel_callback: null,

	_levelsUpdate: [],
	_levelsSync: [],

	_levelUpdateList: null,
	_levelSyncList: null,

	_selectLayout: null,
	_levelUpdateContainer: null,
	_levelSyncContainer: null,

	ctor: function(levels, callback)
	{
		this._super();

		this._callback = callback;

		this._levelsUpdate = [];
		this._levelsSync = [];

		this.setLayoutType(ccui.Layout.RELATIVE);

		var buttonAlign = new ccui.RelativeLayoutParameter();
		buttonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
		buttonAlign.setMargin(0, 0, 0, pmui.OvalLayout.STANDARD_BORDER_RADIUS);

		var okButton = new pmui.InterfaceButton(this._ok, this, LocalizedString("OkButton"));
		okButton.setLayoutParameter(buttonAlign);

		this.addChild(okButton);

		var header = new ccui.Text(LocalizedString("LevelConflictResolve"), pm.settings.fontName, 20);

		var headerAlign = new ccui.RelativeLayoutParameter();
		headerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		headerAlign.setMargin(pmui.OvalLayout.STANDARD_BORDER_RADIUS, pmui.OvalLayout.STANDARD_BORDER_RADIUS, 0, 0);
		headerAlign.setRelativeName("header");

		header.setLayoutParameter(headerAlign);

		this.addChild(header);

		this._selectLayout = new ccui.HBox();

		var selectLayoutAlign = new ccui.RelativeLayoutParameter();
		selectLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		selectLayoutAlign.setMargin(0, LevelConflictLayer.SEPARATOR, 0, 0);
		selectLayoutAlign.setRelativeToWidgetName("header");

		this._selectLayout.setLayoutParameter(selectLayoutAlign);

		var labels = [];

		for(var i = 0; i < levels.length; ++i)
		{
			this._levelsUpdate.push(levels[i]);
			labels.push("{0} / {1}".format(levels[i].game.name, levels[i].level.name));
		}

		this._levelUpdateList = new pmui.SelectionListView(
			labels,
			cc.size(LevelConflictLayer.SELECT_WIDTH, LevelConflictLayer.SELECT_HEIGHT)
		);

		this._levelSyncList = new pmui.SelectionListView(
			[],
			cc.size(LevelConflictLayer.SELECT_WIDTH, LevelConflictLayer.SELECT_HEIGHT)
		);

		this._levelUpdateContainer = new ccui.VBox();
		var levelUpdateLabel = new ccui.Text(LocalizedString("UseLocal"), pm.settings.fontName, pm.settings.fontSize);
		this._levelUpdateContainer.addChild(levelUpdateLabel);

		this._levelUpdateContainer.addChild(this._levelUpdateList);

		this._levelUpdateContainer.setContentSize(
			Math.max(this._levelUpdateList.width, levelUpdateLabel.width),
			this._levelUpdateList.height + levelUpdateLabel.height
		);

		this._selectLayout.addChild(this._levelUpdateContainer);

		var buttonsLayout = new ccui.RelativeBox();
		var syncButtons = new ccui.VBox();

		var addToSync = new pmui.InterfaceButton(this._addToSync, this, "-->");
		var addToUpdate = new pmui.InterfaceButton(this._addToUpdate, this, "<--");

		var syncButtonsAlign = new ccui.RelativeLayoutParameter();
		syncButtonsAlign.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);

		syncButtons.setLayoutParameter(syncButtonsAlign);

		syncButtons.addChild(addToSync);
		syncButtons.addChild(addToUpdate);

		syncButtons.setContentSize(Math.max(addToSync.width, addToUpdate.width), addToSync.height + addToUpdate.height);

		buttonsLayout.addChild(syncButtons);
		buttonsLayout.setContentSize(Math.max(addToSync.width, addToUpdate.width), LevelConflictLayer.SELECT_HEIGHT);

		this._selectLayout.addChild(buttonsLayout);

		this._levelSyncContainer = new ccui.VBox();
		var levelSyncLabel = new ccui.Text(LocalizedString("UseServer"), pm.settings.fontName, pm.settings.fontSize);
		this._levelSyncContainer.addChild(levelSyncLabel);
		this._levelSyncContainer.addChild(this._levelSyncList);

		this._levelSyncContainer.setContentSize(
			Math.max(this._levelSyncList.width, levelSyncLabel.width),
			this._levelSyncList.height + levelSyncLabel.height
		);

		this._selectLayout.addChild(this._levelSyncContainer);

		this._selectLayout.setContentSize(
			this._levelUpdateList.width + buttonsLayout.width + this._levelSyncList.width,
			Math.max(this._levelUpdateContainer.height, this._levelSyncContainer.height)
		);

		this.addChild(this._selectLayout);

		this.setContentSizeWithBorder(this._selectLayout.getContentSize().width, this._selectLayout.getContentSize().height +
            okButton.getContentSize().height + 2 * LevelConflictLayer.SEPARATOR);

		var listener = cc.EventListener.create({
			event: cc.EventListener.TOUCH_ONE_BY_ONE,
			swallowTouches: true,
			onTouchBegan: function()
			{
				return true;
			},
			onTouchMoved: function() {},
			onTouchEnded: function() {}
		});

		cc.eventManager.addListener(listener, this);
	},

	_addToSync: function()
	{
		var index = this._levelUpdateList.getSelectedItemIndex();

		if(index !== -1)
		{
			var width = this._selectLayout.width - this._levelSyncList.width;

			var level = this._levelsUpdate[index];

			this._levelsUpdate.splice(index, 1);

			this._levelsSync.push(level);

			this._levelUpdateList.clearSelection();
			this._levelUpdateList.removeItem(index);

			this._levelSyncList.addItem("{0} / {1}".format(level.game.name, level.level.name));

			this._selectLayout.setContentSize(width + this._levelSyncList.width, this._selectLayout.height);
			this._levelSyncContainer.setContentSize(this._levelSyncList.width, this._levelSyncContainer.height);

			this.setContentSize(this._selectLayout.width + 2 * pmui.OvalLayout.STANDARD_BORDER_RADIUS, this.height);
			this._selectLayout.forceDoLayout();
			this.forceDoLayout();
		}
	},

	_addToUpdate: function()
	{
		var index = this._levelSyncList.getSelectedItemIndex();

		if(index !== -1)
		{
			var width = this._selectLayout.width - this._levelUpdateList.width;

			var level = this._levelsSync[index];

			this._levelsSync.splice(index, 1);

			this._levelsUpdate.push(level);

			this._levelSyncList.clearSelection();
			this._levelSyncList.removeItem(index);

			this._levelUpdateList.addItem("{0} / {1}".format(level.game.name, level.level.name));

			this._selectLayout.setContentSize(width + this._levelUpdateList.width, this._selectLayout.height);
			this._levelUpdateContainer.setContentSize(this._levelUpdateList.width, this._levelUpdateContainer.height);

			this.setContentSize(this._selectLayout.width + 2 * pmui.OvalLayout.STANDARD_BORDER_RADIUS, this.height);
			this._selectLayout.forceDoLayout();
			this.forceDoLayout();
		}
	},

	_ok: function(sender)
	{
		this.removeFromParent();

		if(this._callback)
			this._callback(this._levelsUpdate, this._levelsSync);
	}
});

/**
 * @default
 * @const
 * @type {Number}
 */
LevelConflictLayer.SEPARATOR = 10.0;

LevelConflictLayer.SELECT_WIDTH = 300;
LevelConflictLayer.SELECT_HEIGHT = 500;
