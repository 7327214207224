/**
 * Created by Kirill Mashchenko on 23.04.20.
 */

pm.KModule = {

	getProgramLayerWidth: function()
	{
		return 500;
	},

	compileRobotProgram: function(robot, level)
	{
		return pm.programUtilsK.compileRobotProgram(robot, level);
	},

	generateProgramLayer: function(gameType, useMethodStack, robot, level)
	{
		return new ProgramLayerK(gameType, useMethodStack, robot, level);
	},

	generateProgramEditorLayer: function(gameType, useMethodStack, robot, level)
	{
		return new ProgramEditorLayerK(gameType, useMethodStack, robot, level);
	},

	generateRobotManager: function()
	{
		return new pm.RobotManagerK();
	},

	generateProgramData: function(isFilled)
	{
		return new pm.data.ProgramDataK(isFilled);
	},

	getButtonTypes: function()
	{
		return {
			Empty: -1,
			Repeater: 0,
			Condition: 1,
			CondRepeater: 2,
			Method: 3,
			MethodList: 4,
			RecognizeResult: 5,
			IfStatement: 6,
			LogicExpression: 7,
			Alloc: 8,
			Variable: 9,
			Statement: 10,
			Number: 11,
			Expression: 12,
			Function: 13,
			Constant: 14,
			HorizontalBar: 15,
			IntMethod: 16,
			Bool: 17,
			ElseBlock: 18,
			AndOrExpression: 19,
			NotExpression: 20
		}
	},

	getDeselectFunction: function()
	{
		return function()
		{
			pm.sendCustomEvent(pm.DESELECT_FUNCTIONBUTTON);
			FunctionButton.selectedButton = null;
		};
	},

	getFunctionButtonPrototype: function()
	{
		return FunctionButtonK.prototype;
	},

	getProgramLayerPrototype: function()
	{
		return ProgramLayerK.prototype;
	},

	generateFunctionButton: function(type, value)
	{
		return new FunctionButtonK(type, value);
	},

	createMethodListButton: function(value)
	{
		return new MethodListButtonK(value);
	},

	generatePlayerRobotInterfaceLayer: function (robot)
	{
		return new PlayerRobotInterfaceLayerK(robot);
	},

	getAvailableGlobalRobots: function()
	{
		return {
			labels: [
				LocalizedString("AddOutput")
			],
			globalRobots: [
				pm.GlobalRobotType.Output
			]
		};
	},

	getAvailableRobots: function()
	{
		return [
			new pm.KumirLevelModule,
			new pm.AquariusLevelModule,
			new pm.GrasshopperLevelModule,
			new pm.RepairLevelModule,
			new pm.PushLevelModule,
			new pm.TrainLevelModule,
			new pm.DraftsmanLevelModule,
			new pm.TurtleLevelModule,
			new pm.PhysicalRobotLevelModule,
			new pm.PressRobotLevelModule,
			new pm.LightLevelModule
		]
	},

	useExtraMethodsInKumirRobot: function()
	{
		return true;
	},

	getAppVersion: function()
	{
		return pmkVersion + '.' + build;
	},

	getSupportedLevelFormatVersion: function()
	{
		return PMK_SUPPORTED_LEVEL_FORMAT_VERSION;
	},

	getWorldConvertFunctions: function()
	{
		return pm.worldConvertFunctionsK;
	},

	getLoadText: function ()
	{
		return new cc.Sprite("Background/LoadLayer/LoadText-k.png");
	},

	getExtraAliases: function ()
	{
		return [
			pm.settings.isRetinaEnabled ? "res/Aliases-hd/Piktomir-K.plist" : "res/Aliases/Piktomir-K.plist"
		];
	},

	getBuiltinWorldsAliases: function ()
	{
		if (cc.sys.isNative)
			return "res/worlds-pmk.plist";
		else
			return "res/worlds-pmk-default.plist";
	},

	addExtraSpriteFrames: function ()
	{
		cc.spriteFrameCache.addSpriteFrames("PiktomirK/pictomirKbuttons.plist");
		cc.spriteFrameCache.addSpriteFrames("PiktomirK/piktomirKbuttonsNew.plist");
		cc.spriteFrameCache.addSpriteFrames("PiktomirK/piktomirKbuttons_en.plist");
	},

	getImagesLocalization: function (language)
	{
		var localization = {};

		for(var cond in pm.IfVals)
			localization["condStat-{0}".format(pm.IfVals[cond])] = "{0}condStat-{1}".format(pm.LANGUAGE_PREFIXES[language], pm.IfVals[cond]);

		for(var loop in pm.LoopVals)
			localization["rep-{0}".format(pm.LoopVals[loop])] = "{0}rep-{1}".format(pm.LANGUAGE_PREFIXES[language], pm.LoopVals[loop]);

		return localization;
	}
};

pm.appUtils.register(pm.KModule, "pm-k");

