/**
 * Created by Antony Orlovsky on 31.11.18.
 */

/**
 * @class Layer for selecting help page.
 * @extends ccui.Layout
 * @constructor
 */
var SelectPageMenu = ccui.Layout.extend(/** @lends SelectPageMenu# */{

	_selectedPageIndex: -1,
	_callback: null,
	_target: null,

	ctor: function(index, callback, target)
	{
		this._selectedPageIndex = index;

		if (callback !== undefined && target !== undefined)
		{
			this._callback = callback;
			this._target = target;
		}

		this._super();

		this.setBackGroundImageScale9Enabled(true);
		this.setBackGroundImage("System/SML_Background.png");
		this.setBackGroundImageCapInsets(cc.rect(60, 15, 40, 15));
		this.setCascadeOpacityEnabled(true);

		this.setLayoutType(ccui.Layout.LINEAR_HORIZONTAL);

		this._updateCircles();

		pm.settings.isHelpMode =  true;
	},

	_updateCircles: function()
	{
		this.removeAllChildren(true);

		var circle = pm.spriteUtils.getInterfaceElementSprite("selectHelp-normal");

		var width = SelectPageMenu.PAGE_NUMBER * (circle.getContentSize().width + SelectPageMenu.SEPARATOR_SIZE);
		width -= SelectPageMenu.SEPARATOR_SIZE;
		width += SelectPageMenu.LEFT_BOUND;

		this.setContentSize(cc.size(width, SelectPageMenu.HEIGHT));

		var buttonMargin = new ccui.LinearLayoutParameter();
		buttonMargin.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
		buttonMargin.setMargin(SelectPageMenu.SEPARATOR_SIZE, 0, 0, 0);

		var buttonMargin0 = new ccui.LinearLayoutParameter();
		buttonMargin0.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
		buttonMargin0.setMargin(SelectPageMenu.BUTTON_MARGIN, 0, 0, 0);

		for (var i = 0; i < SelectPageMenu.PAGE_NUMBER; ++i)
		{
			var state = (i === this._selectedPageIndex) ? "selected" : "normal";

			circle = new pmui.Button(pm.spriteUtils.getInterfaceElementName("selectHelp-{0}".format(state)),
				pm.spriteUtils.getInterfaceElementName("selectHelp-{0}".format(state)),
				pm.spriteUtils.getInterfaceElementName("selectHelp-{0}".format(state)),
				ccui.Widget.PLIST_TEXTURE);

			if (i === 0)
				circle.setLayoutParameter(buttonMargin0);
			else
				circle.setLayoutParameter(buttonMargin);

			circle.setTag(i);
			circle.addClickEventListener(this._selectPage.bind(this));

			var icon = new ccui.ImageView(pm.spriteUtils.getInterfaceElementName("{0}-{1}".format(this._getIconByTag(i), state)), ccui.Widget.PLIST_TEXTURE);
			icon.setAnchorPoint(cc.p(0, 0));
			icon.setPosition((circle.width - icon.width) / 2, (circle.height - icon.height) / 2);
			icon.setTag(i);

			circle.addChild(icon);
			this.addChild(circle);
		}
	},

	_selectPage: function(sender)
	{
		var index = sender.getTag();

		if (index !== this._selectedPageIndex)
		{
			var oldCircle = this.getChildByTag(this._selectedPageIndex);

			this._updateTextures(oldCircle, sender);

			if (this._callback)
				this._callback.call(this.target);

			var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, this._getSceneByTag(index));
			cc.director.runScene(trans);
		}
	},

	_updateTextures: function(oldCircle, newCircle)
	{
		oldCircle.loadTextures(pm.spriteUtils.getInterfaceElementName("selectHelp-normal"),
			pm.spriteUtils.getInterfaceElementName("selectHelp-normal"),
			pm.spriteUtils.getInterfaceElementName("selectHelp-normal"),
			ccui.Widget.PLIST_TEXTURE);
		oldCircle.setTouchEnabled(true);

		var tag = oldCircle.getTag();
		var icon = oldCircle.getChildByTag(tag);
		icon.loadTexture(pm.spriteUtils.getInterfaceElementName("{0}-normal".format(this._getIconByTag(tag))), ccui.Widget.PLIST_TEXTURE);

		newCircle.loadTextures(pm.spriteUtils.getInterfaceElementName("selectHelp-selected"),
			pm.spriteUtils.getInterfaceElementName("selectHelp-selected"),
			pm.spriteUtils.getInterfaceElementName("selectHelp-selected"),
			ccui.Widget.PLIST_TEXTURE);
		newCircle.setTouchEnabled(false);

		tag = newCircle.getTag();
		icon = newCircle.getChildByTag(tag);
		icon.loadTexture(pm.spriteUtils.getInterfaceElementName("{0}-selected".format(this._getIconByTag(tag))), ccui.Widget.PLIST_TEXTURE);
	},

	_getIconByTag: function(index)
	{
		switch (index)
		{
			case SelectPageMenu.PAGES.CHOOSE_LESSON:
				return "robotsHelp";
			case SelectPageMenu.PAGES.PROGRAM_ALGORITHM:
				return "algorithmHelp";
			case SelectPageMenu.PAGES.METHOD_STACK:
				return "methodStackHelp";
			case SelectPageMenu.PAGES.COUNTER:
				return "counterHelp";
		}
	},

	_getSceneByTag: function(index)
	{
		var worldID = this.getParent().getCurrentWorldID();

		switch (index)
		{
			case SelectPageMenu.PAGES.CHOOSE_LESSON:
				return new ChooseLessonScene(worldID);
			case SelectPageMenu.PAGES.PROGRAM_ALGORITHM:
				return new ProgramAlgorithmScene(worldID);
			case SelectPageMenu.PAGES.METHOD_STACK:
				return new MethodStackLessonScene(worldID);
			case SelectPageMenu.PAGES.COUNTER:
				return new CounterLessonScene(worldID);
		}
	}
});

SelectPageMenu.LEFT_MARGIN = -15;
SelectPageMenu.BUTTON_MARGIN = 35;
SelectPageMenu.SEPARATOR_SIZE = 5;
SelectPageMenu.HEIGHT = 50;
SelectPageMenu.LEFT_BOUND = 45;
SelectPageMenu.PAGE_NUMBER = 4;
SelectPageMenu.PAGES = {
	CHOOSE_LESSON: 0,
	PROGRAM_ALGORITHM: 1,
	METHOD_STACK: 2,
	COUNTER: 3
};
