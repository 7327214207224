/**
 * Created by Kirill Mashchenko on 03.07.2018.
 */

/**
 * @class Represents program of one robot.
 */
var ProgramContainerLayer = pmui.DragAndDropLayout.extend(/** @lends ProgramContainerLayer# */{

	_programLayer: null,
	_program: null,

	_highlightedMethodsStack: [],
	_startedRepeatersStack: [],

	_buttonClickCallback: null,
	_buttonClickCallbackTarget: null,

	ctor: function (programLayer, program)
	{
		this._super(pmui.DragAndDropLayout.Type.DRAG_AND_DROP, ProgramContainerLayer.DNDNAME);
		this.addDNDEventListener(this._dragCallback, this);

		this._programLayer = programLayer;
		this._program = program;

		this.setLayoutType(ccui.Layout.RELATIVE);

		this._highlightedMethodsStack = [];

		pm.registerCustomEventListener(pm.ENABLE_CONTROLLED_MODE_STR, function (event)
		{
			var data = event.getUserData();
			if (data !== undefined)
				this._enableControlledMode(data);

		}.bind(this), this);

		pm.registerCustomEventListener(pm.DISABLE_CONTROLLED_MODE_STR, this._disableControlledMode.bind(this), this);
	},

	/**
     * Enables Controlled mode of function block layer.</br>
     * In this mode buttons animates once and called click callback passed to function.
     * @param {pm.ControlledModeData} controlledModeData
     * @private
     */
	_enableControlledMode: function (controlledModeData)
	{
		this._buttonClickCallback = controlledModeData.addMethodCallback;
		this._buttonClickCallbackTarget = controlledModeData.callbackTarget;
	},

	/**
     * Disables Controlled mode of function block layer.
     * @private
     */
	_disableControlledMode: function ()
	{
		this._buttonClickCallback = null;
		this._buttonClickCallbackTarget = null;
	},

	_parseProgram: function () {},

	updateProgram: function(program) {},

	_recalculateSizes: function() {},

	_findElementByTouch: function(touch, button) {},

	_isElementDraggable: function(element, touch) {},

	_isElementClickable: function(element) {},

	_cloneElement: function(element) {},

	_isEmptyButton: function(button) {},

	_dragCallback: function(element, eventType, touch) {},

	highlightMethodBroken: function(methodPlace) {},

	highlightMethod: function(methodPlace) {},

	switchPointsInRepeater: function(repPlace, num, rep) {},

	reloadRepeaters: function() {},

	clearLastHighlight: function() {},

	clearAllHighlights: function() {},

	_isEditable: function()
	{
		return this._programLayer.isProgramPatternEditable();
	}
});

ProgramContainerLayer.EMPTY_ROW_SEPARATOR_HEIGHT = 10;
ProgramContainerLayer.REPEATER_BUTTON_NAME = "repeater";
ProgramContainerLayer.RESIZE_SPRITE_NAME = "resize";

ProgramContainerLayer.SEPARATOR_COLOR = cc.color(255, 255, 255, 144);
ProgramContainerLayer.SEPARATOR_LWIDTH = 0.5;
ProgramContainerLayer.BUTTON_SIZE = 40.0;
ProgramContainerLayer.BUTTON_SEPARATOR = 4.0;
ProgramContainerLayer.SYMBOL_TABLE_MARGIN = 0.25;
ProgramContainerLayer.DNDNAME = "function_drag_and_drop";
ProgramContainerLayer.BOTTOM_BORDER = 43.0;
ProgramContainerLayer.ID_PATTERN = "{0}_{1}";
ProgramContainerLayer.NORMAL_STATE = 0;
ProgramContainerLayer.ADD_REMOVE_ROWS_STATE = 1;
