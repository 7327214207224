/**
 * Created by Nikita Besshaposhnikov on 22.03.17.
 */

/**
 * @class Layer for waiting all players in network game are connected processes.<br/>
 * Intercepts touch events.
 * @extends cc.LayerColor
 */
var WaitNetworkGameLayer = cc.LayerColor.extend(/** @lends WaitNetworkGameLayer*/{

	ctor: function()
	{
		var screenSize = pm.settings.getScreenSize();
		this._super(pm.settings.transparentColor, screenSize.width, screenSize.height);

		var touchListener = cc.EventListener.create({
			event: cc.EventListener.TOUCH_ONE_BY_ONE,
			swallowTouches: true,
			onTouchBegan: this._touchBegan.bind(this),
			onTouchMoved: this._touchMoved.bind(this),
			onTouchEnded: this._touchEnded.bind(this)
		});

		cc.eventManager.addListener(touchListener, this);

		var label = new cc.LabelTTF(LocalizedString("WaitForOtherPlayers"), pm.settings.fontBoldName, 30);
		label.setPosition(screenSize.width / 2, screenSize.height / 2);

		this.addChild(label);
	},

	_touchBegan: function(touch, event)
	{
		return true;
	},

	_touchMoved: function() {},
	_touchEnded: function() {},

	/**
     * Shows layer
     */
	show: function()
	{
		cc.director.getRunningScene().addChild(this, LoadingLayer.ZORDER, LoadingLayer.TAG);
	},

	/**
     * Removes layer.
     */
	remove: function()
	{
		if(this.getParent())
			this.removeFromParent();
	}
});

/**
 * A tag for wait network game layer
 * @constant
 * @default
 * @type {number}
 */
WaitNetworkGameLayer.TAG = 1000;
/**
 * A zorder for wait network game
 * @constant
 * @default
 * @type {number}
 */
WaitNetworkGameLayer.ZORDER = 1000;

