/**
 * Created by Nikita Besshaposhnikov on 29.03.16.
 */

/**
 * @class Layer for extended counter memory  task.
 * @extends TaskLayer
 * @constructor
 * @param {pm.AbstractLevel} level
 * @param {pm.data.PushTask} task
 */

var ExCounterMemoryTaskLayer = TaskLayer.extend(/** @lends ExCounterMemoryTaskLayer# */{

	_memoryValue: null,

	ctor: function(level, task)
	{
		this._super(LocalizedString("ExCounterMemoryTask"), level, task, pm.GlobalTaskType.ExCounterMemory);

		var layout = new ccui.HBox();
		var layoutAlign = new ccui.LinearLayoutParameter();
		layoutAlign.setMargin(5, 5, 0, 0);
		layout.setLayoutParameter(layoutAlign);

		var valueLabel = new pmui.Text(LocalizedString("MemoryValue"), pm.settings.fontSize);
		layout.addChild(valueLabel);

		//TODO Support robot groups
		var value = this._task.data[this._level.getActiveMapIndex()][0];

		this._memoryValue = new pmui.TextInput(String(value), "0", 15, 40);

		var memoryValueAlign = new ccui.LinearLayoutParameter();
		memoryValueAlign.setMargin(5, 0, 0, 0);
		this._memoryValue.setLayoutParameter(memoryValueAlign);
		layout.addChild(this._memoryValue);

		this._memoryValue.setInsertTextCallback(this, this._memoryValueChanged);

		layout.setContentSize(this.width, Math.max(valueLabel.height, this._memoryValue.height));
		this.addChild(layout);

		var height = this.height + layout.height + 10;

		this.setContentSize(this.width, height);
	},

	_memoryValueChanged: function()
	{
		this._task.data[this._level.getActiveMapIndex()][0] = Number(this._memoryValue.getString());
	},

	refresh: function()
	{
		var value = this._task.data[this._level.getActiveMapIndex()][0];

		this._memoryValue.setString(String(value));
	}
});
