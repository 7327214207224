/**
 * Created by Nikita Besshaposhnikov on 01.12.14.
 */

/**
 * this callback is used on login and cancel actions of layer.
 * @callback LoginLayer~actionCallback
 */

/**
 * @class Layout for login in editor.
 * @extends pmui.OvalLayout
 * @constructor
 * @param {cc.Node} target Target for callbacks.
 * @param {LoginLayer~actionCallback} loginCallback Called on successful login.
 * @param {LoginLayer~actionCallback} cancelCallback Called on cancel button clicked.
 */
var LoginLayer = ccui.Layout.extend(/** @lends LoginLayer# */{
	_inputLayout: null,
	_loginInput: null,
	_passwordInput: null,
	_errorLabel: null,

	_autoLogin: false,

	_loginCallback: null,
	_cancelCallback: null,
	_target: null,

	ctor: function(target, loginCallback)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setContentSize(pm.settings.getScreenSize());

		var loadingLayer = new LoadingLayer(false);
		loadingLayer.setPosition((this.width - pm.settings.getScreenSize().width) / 2,
			(this.height - pm.settings.getScreenSize().height) / 2);

		this.addChild(loadingLayer, -100);

		this._target = target;
		this._loginCallback = loginCallback;

		this._inputLayout = new MovingAfterKeyboardLayout();
		this.setLayoutType(ccui.Layout.RELATIVE);

		this._inputLayout.setContentSizeWithBorder(LoginLayer.WIDTH, LoginLayer.HEIGHT);

		var closeButton = new pmui.Button(pm.spriteUtils.getIconName("close", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("close", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("close", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		closeButton.addClickEventListener(this._close.bind(this));

		var closeButtonAlign = new ccui.RelativeLayoutParameter();
		closeButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		closeButtonAlign.setMargin(0, -closeButton.height/4, -closeButton.width/4, 0);
		closeButton.setLayoutParameter(closeButtonAlign);

		this._inputLayout.addChild(closeButton);

		var loginVKButton = new pmui.Button(pm.spriteUtils.getIconName("vk", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("vk", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("vk", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		loginVKButton.addClickEventListener(this._loginVK.bind(this));

		var orLabel = new ccui.Text(LocalizedString("OR"), pm.settings.fontName, 15);

		var orLabelAlign = new ccui.RelativeLayoutParameter();
		orLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		orLabelAlign.setMargin(0, LoginLayer.SEPARATOR, 0, 0);
		orLabelAlign.setRelativeName("or");
		orLabelAlign.setRelativeToWidgetName("vk");
		orLabel.setLayoutParameter(orLabelAlign);

		this._inputLayout.addChild(orLabel);

		this._loginInput = new pmui.TextInput(pm.settings.getUserData().login, LocalizedString("Login"), 20, LoginLayer.WIDTH - LoginLayer.WIDTH_DELTA);

		var loginAlign = new ccui.RelativeLayoutParameter();
		loginAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		loginAlign.setMargin(0, LoginLayer.SEPARATOR, 0, 0);
		loginAlign.setRelativeName("login");
		loginAlign.setRelativeToWidgetName("or");
		this._loginInput.setLayoutParameter(loginAlign);

		this._inputLayout.addChild(this._loginInput);

		this._passwordInput = new pmui.TextInput("", LocalizedString("Password"), 20, LoginLayer.WIDTH - LoginLayer.WIDTH_DELTA);
		this._passwordInput.setPasswordInput(true);

		var passwordAlign = new ccui.RelativeLayoutParameter();
		passwordAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		passwordAlign.setMargin(0, LoginLayer.SEPARATOR, 0, 0);
		passwordAlign.setRelativeName("password");
		passwordAlign.setRelativeToWidgetName("login");
		this._passwordInput.setLayoutParameter(passwordAlign);

		this._inputLayout.addChild(this._passwordInput);

		var stripName = "System/Strip.png";

		var leftStrip = new cc.Sprite(stripName);

		var leftStripLayout = new ccui.Layout();
		leftStripLayout.setLayoutType(ccui.Layout.RELATIVE);
		leftStripLayout.addChild(leftStrip);

		var leftStripLayoutAlign = new ccui.RelativeLayoutParameter();
		leftStripLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_ABOVE_LEFTALIGN);
		leftStripLayoutAlign.setMargin(LoginLayer.BOTTOM_BORDER + 15, 0, 0, LoginLayer.SEPARATOR + 10);
		leftStripLayoutAlign.setRelativeToWidgetName("login");
		leftStripLayout.setLayoutParameter(leftStripLayoutAlign);

		this._inputLayout.addChild(leftStripLayout);

		var rightStrip = new cc.Sprite(stripName);
		rightStrip.setRotation(180);

		var rightStripLayout = new ccui.Layout();
		rightStripLayout.setLayoutType(ccui.Layout.RELATIVE);
		rightStripLayout.addChild(rightStrip);

		var rightStripLayoutAlign = new ccui.RelativeLayoutParameter();
		rightStripLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_ABOVE_RIGHTALIGN);
		rightStripLayoutAlign.setMargin(0, 0, LoginLayer.BOTTOM_BORDER + 15, LoginLayer.SEPARATOR + 10);
		rightStripLayoutAlign.setRelativeToWidgetName("login");
		rightStripLayout.setLayoutParameter(rightStripLayoutAlign);

		this._inputLayout.addChild(rightStripLayout);

		this._autoLogin = pm.settings.isUserAutoLogin();

		var check = new pmui.CheckBox(LocalizedString("AutoLogin"), this._onCheckClicked, this, pm.settings.fontDefinition.fontSize, this._autoLogin);

		var checkAlign = new ccui.RelativeLayoutParameter();
		checkAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		checkAlign.setMargin(0, LoginLayer.SEPARATOR, 0, 0);
		checkAlign.setRelativeName("check");
		checkAlign.setRelativeToWidgetName("password");
		check.setLayoutParameter(checkAlign);

		this._inputLayout.addChild(check);

		var enter = new pmui.InterfaceButton(this._login, this, LocalizedString("LoginButton"));
		enter.setContentSize(LoginLayer.WIDTH - LoginLayer.WIDTH_DELTA, enter.height);
		enter.setTitleFontName(pm.settings.fontName);

		var enterAlign = new ccui.RelativeLayoutParameter();
		enterAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		enterAlign.setMargin(0, 2 * LoginLayer.SEPARATOR + check.height, 0, 0);
		enterAlign.setRelativeName("enter");
		enterAlign.setRelativeToWidgetName("password");
		enter.setLayoutParameter(enterAlign);

		this._inputLayout.addChild(enter);

		var register = new pmui.InterfaceButton(this._register, this, LocalizedString("RegisterButton"));
		register.setContentSize(LoginLayer.WIDTH - LoginLayer.WIDTH_DELTA, register.height);
		register.setTitleFontName(pm.settings.fontName);

		var registerAlign = new ccui.RelativeLayoutParameter();
		registerAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		registerAlign.setMargin(0, LoginLayer.SEPARATOR, 0, 0);
		registerAlign.setRelativeToWidgetName("enter");
		register.setLayoutParameter(registerAlign);

		this._inputLayout.addChild(register);

		var border = (LoginLayer.HEIGHT - (loginVKButton.height + orLabel.height + 2*this._loginInput.height + check.height + 2*enter.height + 6*LoginLayer.SEPARATOR))/2;

		var loginVKButtonAlign = new ccui.RelativeLayoutParameter();
		loginVKButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		loginVKButtonAlign.setMargin(0, border + pmui.OvalLayout.STANDARD_BORDER_RADIUS, 0, 0);
		loginVKButtonAlign.setRelativeName("vk");
		loginVKButton.setLayoutParameter(loginVKButtonAlign);

		this._inputLayout.addChild(loginVKButton);

		var inputLayoutAlign = new ccui.RelativeLayoutParameter();
		inputLayoutAlign.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
		this._inputLayout.setLayoutParameter(inputLayoutAlign);

		this.addChild(this._inputLayout);

		this._inputLayout.setTouchEnabled(true);

		this._inputLayout.setInputAttach(this._loginInput);
		this._inputLayout.setInputAttach(this._passwordInput);

		var touchListener = cc.EventListener.create({
			event: cc.EventListener.TOUCH_ONE_BY_ONE,
			swallowTouches: true,
			onTouchBegan: this._touchBegan.bind(this),
			onTouchMoved: this._touchMoved.bind(this),
			onTouchEnded: this._touchEnded.bind(this)
		});

		cc.eventManager.addListener(touchListener, this);
	},

	_touchBegan: function(touch, event)
	{
		var touchPoint = this.convertTouchToNodeSpace(touch);
		return !cc.rectContainsPoint(this._inputLayout.getBoundingBox(), touchPoint);
	},

	_touchMoved: function() {},
	_touchEnded: function() {},

	_onCheckClicked: function(flag)
	{
		this._autoLogin = flag;
	},

	_login: function(sender)
	{
		if (this._passwordInput.getString().length === 0 || this._loginInput.getString().length === 0)
			return;

		this._inputLayout.closeAttachKeyboard();

		pm.settings.setUserLogin(this._loginInput.getString());
		var wait = new WaitLayer();

		var align = new ccui.RelativeLayoutParameter();
		align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
		wait.setLayoutParameter(align);

		this.addChild(wait, 100);

		pm.apiServerUtils.login(function (error, response)
		{
			wait.hide();
			if(error)
			{
				if(error.status === -1 || error.status === 0)
				//     this._errorLabel.setString(LocalizedString("CheckInternetConnection"));
				// else
				//     this._errorLabel.setString(LocalizedString("WrongLoginPassword"));
				// this._errorLabel.setVisible(true);
					var message = new pmui.MessageBox(this, LocalizedString("CheckInternetConnection"));
				else
					var message = new pmui.MessageBox(this, LocalizedString("WrongLoginPassword"));

				var x = pm.settings.getScreenSize().width / 2 - message.getContentSize().width / 2;
				var y = pm.settings.getScreenSize().height / 2 - message.getContentSize().height / 2;
				message.setPosition(x, y);
				cc.director.getRunningScene().addChild(message, LoginLayer.TAG);

				cc.log(JSON.stringify(error));
			}
			else
			{
				pm.settings.setAccessToken(response.access_token);
				pm.settings.setRefreshToken(response.refresh_token);
				pm.settings.setUserAutoLogin(this._autoLogin);

				pm.settings.userLoggedIn = true;

				this._loginCallback.call(this._target);
				this._close();
			}
		}.bind(this), this._loginInput.getString(), this._passwordInput.getString());
	},

	_randomBase64String: function(length) {
		var string = "";

		if(cc.sys.isNative) // on native it's std c++ random
		{
			for (var i = 0; i <length; i++)
				string += String.fromCharCode(cc.rand());
		}
		else { // on web its window crypto api.
			var array = new Uint8Array(length);
			window.crypto.getRandomValues(array);

			for (var i = 0; i < array.length; i++)
				string += String.fromCharCode(array[i]);
		}

		return this._formatBase64(Base64.encode(string));
	},

	_formatBase64: function(base64) {
		return base64
			.replace(/\+/g, '-')
			.replace(/\//g, '_')
			.replace(/=/g, '');
	},

	_sha256: function(string) {
		var bits = sjcl.codec.utf8String.toBits(string);
		var base64 = sjcl.codec.base64.fromBits(sjcl.hash.sha256.hash(bits));
		return this._formatBase64(base64);
	},

	_loginVK: function ()
	{
		this._state = this._randomBase64String(16);
		this._codeVerifier = this._randomBase64String(32);
		this._codeChallenge = this._sha256(this._codeVerifier);

		pm.utils.socialLogin(
			pm.utils.SOCIAL_TYPE.VK,
			this._socialLogin.bind(this),
			this._state,
			this._codeChallenge
		);
	},

	_socialLoginError: function (error) {
		console.log("Error login in social:" + typeof error === "string" ? error : JSON.stringify(error));

		var message = new pmui.MessageBox(this, LocalizedString("SocialLoginError"));
		var x = pm.settings.getScreenSize().width / 2 - message.getContentSize().width / 2;
		var y = pm.settings.getScreenSize().height / 2 - message.getContentSize().height / 2;

		message.setPosition(x, y);

		cc.director.getRunningScene().addChild(message, LoginLayer.TAG);
	},

	_socialLogin: function(error, response)
	{
		if(!error)
		{
			if(response.state !== this._state) {
				this._socialLoginError("Potential CSRF attack on login");
				return;
			}

			pm.apiServerUtils.loginByCode(function(error, response) {

				if(error) {
					this._socialLoginError(error);
					return;
				}

				pm.settings.setAccessToken(response.access_token);
				pm.settings.setRefreshToken(response.refresh_token);
				pm.settings.setUserAutoLogin(true);

				pm.settings.userLoggedIn = true;

				this._loginCallback.call(this._target);
				this._close();
			}.bind(this), response.code, this._codeVerifier, response.redirectUri)
		}
		else
		{
			this._socialLoginError(error);
		}
	},
	//
	// _getUserAccountDataCallback: function(error, response)
	// {
	// 	if(!error && response)
	// 	{
	// 		pm.settings.userLoggedIn = true;
	//
	// 		pm.settings.setUserName(response.username);
	//
	// 		var accountType = response.permissions.pm || pm.USER_TYPE.USER;
	//
	// 		pm.settings.setUserData(response._id, accountType,
	// 			response.name, response.surname);
	//
	// 		pm.userCache.forceSyncCache();
	// 	}
	// 	else
	// 	{
	// 		pm.settings.setAccessToken("");
	// 		pm.settings.setRefreshToken("");
	//
	// 		pm.settings.userLoggedIn = false;
	//
	// 		if(pm.settings.getUserData())
	// 			pm.settings.getUserData().accountType = pm.USER_TYPE.USER;
	//
	// 		cc.error(JSON.stringify(error));
	// 	}
	// },

	_register: function()
	{
		if(cc.director.getRunningScene().getChildByTag(RegisterLayer.TAG))
			return;

		var registerLayer = new RegisterLayer(this, this._closeRegister);

		cc.director.getRunningScene().addChild(registerLayer, 100, RegisterLayer.TAG);
	},

	_closeRegister: function()
	{
		cc.director.getRunningScene().removeChildByTag(RegisterLayer.TAG);
	},

	_close: function()
	{
		this.removeFromParent();

		this._inputLayout.closeAttachKeyboard();
	}
});
/**
 * @default
 * @const
 * @type {Number}
 */
LoginLayer.WIDTH = 400.0;
/**
 * @default
 * @const
 * @type {Number}
 */
LoginLayer.HEIGHT = 370.0;
/**
 * @default
 * @const
 * @type {Number}
 */
LoginLayer.SEPARATOR = 15.0;
/**
 * @default
 * @const
 * @type {Number}
 */
LoginLayer.BOTTOM_BORDER = 55.0;
/**
 * @default
 * @const
 * @type {Number}
 */
LoginLayer.WIDTH_DELTA = 70.0;
/**
 * @default
 * @const
 * @type {Number}
 */
LoginLayer.TAG = 1002;
