/** Created by Kirill Mashchenko on 02.04.18. ...*/

/**
 * @class Layer for choosing level
 * @extends ccui.Layout
 */

var ChooseLevelLayer = pmui.OvalLayout.extend(/** @lends ChooseGameLevelLayout# */{

	gameArray: [],
	gameIndex: -1,
	levelIndex: -1,
	_callback: null,
	_target: null,
	chooseLevelLayout: null,

	ctor: function (callback, target)
	{
		this._super(null, null, pmui.OvalLayout.Type.THIN);

		this._callback = callback;
		this._target = target;

		var screenSize = pm.settings.getScreenSize();

		var chooseGameLevelWidth = ChooseLevelLayer.WIDTH_PERCENT * screenSize.width;
		var chooseGameLevelHeight = ChooseLevelLayer.HEIGHT_PERCENT * screenSize.height;

		this.setContentSizeWithBorder(chooseGameLevelWidth, chooseGameLevelHeight);
		this.setPosition((screenSize.width - this.width) / 2, (screenSize.height - this.height) / 2);

		this.gameArray = [];

		for(var i = 0; i < world.getGameCount(); ++i)
			this.gameArray.push(world.getGameName(i));

		var chooseGameLayout = new ccui.Layout();
		chooseGameLayout.setLayoutType(ccui.Layout.RELATIVE);
		chooseGameLayout.setContentSize(chooseGameLevelWidth / 2, chooseGameLevelHeight);

		var chooseGameAlign = new ccui.RelativeLayoutParameter();
		chooseGameAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		chooseGameAlign.setMargin(ChooseLevelLayer.BORDER_RADIUS, ChooseLevelLayer.BORDER_RADIUS, 0, 0);
		chooseGameLayout.setLayoutParameter(chooseGameAlign);

		var cancel = new pmui.InterfaceButton(this._cancel, this, LocalizedString("CancelButton"));

		var cancelAlign = new ccui.RelativeLayoutParameter();
		cancelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
		cancelAlign.setMargin(ChooseLevelLayer.BORDER, 0, 0, ChooseLevelLayer.BORDER - ChooseLevelLayer.SMALL_SEPARATOR);
		cancel.setLayoutParameter(cancelAlign);

		chooseGameLayout.addChild(cancel);

		var chooseGameList = new pmui.SelectionListView(this.gameArray,
			cc.size(chooseGameLevelWidth * ChooseLevelLayer.WIDTH_GAME_PERCENT - 2 * ChooseLevelLayer.BORDER,
				chooseGameLevelHeight - cancel.height - 2 * ChooseLevelLayer.BORDER));
		chooseGameList.setIndexCallback(this.gameSelected, this);

		var chooseGameListAlign = new ccui.RelativeLayoutParameter();
		chooseGameListAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		chooseGameListAlign.setMargin(ChooseLevelLayer.BORDER, ChooseLevelLayer.BORDER, 0, 0);
		chooseGameList.setLayoutParameter(chooseGameListAlign);

		chooseGameLayout.addChild(chooseGameList);

		this.addChild(chooseGameLayout);
	},

	_cancel: function ()
	{
		if (this.chooseLevelLayout)
		{
			this.chooseLevelLayout.removeFromParent();
			this.chooseLevelLayout = null;
		}

		this.removeFromParent();
	},

	gameSelected: function (index)
	{
		var game = index;
		this.gameIndex = index;
		this.levelIndex = 0;
		this.levelArray = [];

		for (var i = 0; i < world.getLevelCount(game); ++i)
			this.levelArray.push(world.getLevelName(game, i) + ", " + world.games[game].levels[i].robots.length + " " + LocalizedString("RobotCount"));

		if (this.chooseLevelLayout)
		{
			this.chooseLevelLayout.removeFromParent();
			this.chooseLevelLayout = null;
		}

		var screenSize = pm.settings.getScreenSize();
		var chooseLevelWidth = ChooseLevelLayer.WIDTH_PERCENT * screenSize.width * ChooseLevelLayer.WIDTH_LEVEL_PERCENT;
		var chooseLevelHeight = ChooseLevelLayer.HEIGHT_PERCENT * screenSize.height;

		this.chooseLevelLayout = new ccui.Layout();
		this.chooseLevelLayout.setLayoutType(ccui.Layout.RELATIVE);
		this.chooseLevelLayout.setContentSize(chooseLevelWidth, chooseLevelHeight);

		var chooseLevelAlign = new ccui.RelativeLayoutParameter();
		chooseLevelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		chooseLevelAlign.setMargin(0, ChooseLevelLayer.BORDER_RADIUS, ChooseLevelLayer.BORDER_RADIUS, 0);
		this.chooseLevelLayout.setLayoutParameter(chooseLevelAlign);

		var okLevel = new pmui.InterfaceButton(this._okLevel, this, LocalizedString("OK"));

		var okLevelAlign = new ccui.RelativeLayoutParameter();
		okLevelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
		okLevelAlign.setMargin(0, 0, ChooseLevelLayer.BORDER, ChooseLevelLayer.BORDER - ChooseLevelLayer.SMALL_SEPARATOR);
		okLevel.setLayoutParameter(okLevelAlign);

		this.chooseLevelLayout.addChild(okLevel);

		var chooseLevelList = new pmui.SelectionListView(this.levelArray,
			cc.size(chooseLevelWidth - 2 * ChooseLevelLayer.BORDER, chooseLevelHeight - okLevel.height - 2 * ChooseLevelLayer.BORDER));
		chooseLevelList.setIndexCallback(this.levelSelected, this);

		var chooseLevelListAlign = new ccui.RelativeLayoutParameter();
		chooseLevelListAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		chooseLevelListAlign.setMargin(0, ChooseLevelLayer.BORDER, 0, 0);
		chooseLevelList.setLayoutParameter(chooseLevelListAlign);

		this.chooseLevelLayout.addChild(chooseLevelList);
		this.addChild(this.chooseLevelLayout);
	},

	levelSelected: function (index)
	{
		this.levelIndex = index;
	},

	_okLevel: function()
	{
		if(this._callback)
			this._callback.call(this._target);
	}

});

ChooseLevelLayer.WIDTH_PERCENT = 0.7;
ChooseLevelLayer.HEIGHT_PERCENT = 0.53;
ChooseLevelLayer.WIDTH_GAME_PERCENT = 0.4;
ChooseLevelLayer.WIDTH_LEVEL_PERCENT = 0.6;
ChooseLevelLayer.BORDER = 10;
ChooseLevelLayer.BORDER_RADIUS = 15;
ChooseLevelLayer.SMALL_SEPARATOR = 5;
