/**
 * Created by Kirill Mashchenko on 03.07.17.
 */

/**
 * How modulation system works
 * Firstly ypu need to create new module file, which will contain functions from pm.ModuleInfo and which extends it
 * In each of them you need to write features of your module.
 * Then you need to register your module.
 * For that you need after class of your module call function pm.moduleUtils.register(new <name of your module>).
 * This function adds your module to dictionary _modules of all modules and bind it with type of module.
 * Then in project, when function from pm.moduleUtils from type will be called, it will automatically called and made
 * in module of this type, because function will be called in module, which key is type: return this._modules[type].<function>
 */

/**
 * Functions, which each module need to contain
 * @namespace
 */
pm.moduleUtils = {
	_modules: {},

	/**
	 * Adds your module to dictionary _modules of all modules and
	 * bind it with type of module.
	 * @param {cc.Class} module
	 */
	register: function (module)
	{
		this._modules[module.getType()] = module;
	},

	getModuleType: function (type)
	{
		return this._modules[type].getType();
	},

	getBackgroundParameters: function (type)
	{
		return this._modules[type].getBackgroundParameters();
	},

	/**
     * Generates empty level of this level type.
     * @param {String} type Type of this level
     * @returns {pm.data.AbstractLevel}
     */
	generateEmptyLevel: function (type)
	{
		return this._modules[type].generateEmptyLevel();
	},

	/**
     * Generates empty map for level with robot of this level type.
     * @param {String} type Type of this level
     * @param {pm.data.AbstractLevel} level
     * @returns {pm.data.AbstractMap}
     */
	generateEmptyMap: function (type, level)
	{
		return this._modules[type].generateEmptyMap(level);
	},

	/**
     * Returns Map ObjectType->Object sprite filename of this level type.
     * @param {String} type Type of this level
     * @returns {Object}
     */
	getObjectsInfo: function (type)
	{
		return this._modules[type].getObjectsInfo();
	},

	/**
     * Returns RobotType->Robot sprite filename of this level type.
     * @param {String} type Type of this level
     * @returns {Object}
     */
	getRobotsInfo: function (type)
	{
		return this._modules[type].getRobotsInfo();
	},

	/**
     * Returns robot sprite sheet of this level type.
     * @param {String} type Type of this level
     * @param {String} robotType type of Robot
     * @returns {String}
     */
	getRobotSpriteSheet: function (type, robotType)
	{
		return this._modules[type].getRobotSpriteSheet(robotType);
	},

	/**
     * Returns setting layer for robot in editor of this level type.
     * @param {String} type Type of this level
     * @param {String} robotType type of Robot
     * @param {Object} object Robot
     * @returns {PlayerRobotSettingsLayer}
     */
	getSettings: function (type, robotType, object)
	{
		return this._modules[type].getSettings(robotType, object);
	},

	/**
	 * Returns extra settings for level in editor of this level type.
	 * @param {String} type Type of this level
	 * @param {String} level Level
	 */
	getExtraLevelSettings: function (type, level)
	{
		return this._modules[type].getExtraLevelSettings(level);
	},

	/**
	 * Returns extra settings for map in editor of this level type.
	 * @param {String} type Type of this level
	 * @param {*} target Target
	 */
	getExtraMapSettings: function (type, target)
	{
		return this._modules[type].getExtraMapSettings(target);
	},

	/**
	 * Returns extra info for level of this level type.
	 * @param {String} type Type of this level
	 * @param {String} level Level
	 */
	getExtraInfo: function (type, level)
	{
		return this._modules[type].getExtraInfo(level);
	},

	/**
     * Returns dictionary with map elements types of this level type.
     * @param {String} type Type of this level
     * @returns {Object}
     */
	getMapElementsType: function(type)
	{
		return this._modules[type].getMapElementsType();
	},

	/**
     * Returns Localized name of robot of this level type.
     * @param {String} type Type of this level
     * @param {String} robotType type of robot
     * @returns {String}
     */
	getRobotDisplayedName: function (type, robotType)
	{
		return this._modules[type].getRobotDisplayedName(robotType);
	},
	/**
	 * Returns robot mathod name
	 * @param {String} methodName
	 * @returns {String}
	 */

	getRobotMethodName: function (type, methodName, level)
	{
		return this._modules[type].getRobotMethodName(methodName, level);
	},

	/**
	 * Returns robot condiiton name
	 * @param {String} condName
	 * @returns {String}
	 */

	getRobotConditionName: function (type, condName)
	{
		return this._modules[type].getRobotConditionName(condName);
	},

	/**
     * Returns array of names of levels
     * @returns {Array}
     */
	getLevelNames: function ()
	{
		var ret = [];
		for(var module in this._modules)
			ret.push(this._modules[module].getDisplayedNames());
		return ret;
	},

	/**
     * Returns array of types of levels
     * @returns {Array}
     */
	getLevelTypes: function ()
	{
		var ret = [];
		for(var module in this._modules)
			ret.push(this._modules[module].getType());
		return ret;
	},

	/**
	 * Returns array of lessonMapLayers
	 * @returns {Array}
	 */
	getLessonMapLayers: function ()
	{
		var ret = [];

		for(var module in this._modules)
		{
			var mapLayer = this._modules[module].getLessonMapLayer();

			if (mapLayer)
				ret.push(mapLayer);
		}

		return ret;
	},

	/**
     * Returns path to tileset of this level type.
     * @param {String} type Type of this level
     * @param {String} tileset
     * @returns {String}
     */
	getMapTileset: function (type, tyleset)
	{
		return this._modules[type].getMapTileset(tyleset);
	},

	/**
     * Returns array of Methods and conditions of robot of this level type.
     * @param {String} type Type of this level
     * @param {String} robotType Type of robot
     * @returns {String}
     */
	getResources: function (type)
	{
		return this._modules[type].getResources();
	},

	/**
     * Generates Object for type of this level type.
     * @param {String} type Type of this level
     * @param {String} objectType Type of Object
     * @returns {Object}
     */
	generateObject: function (type, objectType)
	{
		return this._modules[type].generateObject(objectType);
	},

	/**
     * Generates Robot for type of this level type.
     * @param {String} type Type of this level
     * @param {String} robotType Type of robot
     * @returns {Robot}
     */
	generateRobot: function (type, robotType)
	{
		return this._modules[type].generateRobot(robotType);
	},

	stopResizingWidth: function (type, map, side)
	{
		return this._modules[type].stopResizingWidth(map, side);
	},

	stopResizingHeight: function (type, map, side)
	{
		return this._modules[type].stopResizingHeight(map, side);
	},

	stopDecreasingHeight: function (type, map, side)
	{
		return this._modules[type].stopDecreasingHeight(map, side);
	},

	canNotDragOrAddObject: function (type, map, point)
	{
		return this._modules[type].canNotDragOrAddObject(map, point);
	},

	canUseMethodStack: function (type)
	{
		return this._modules[type].canUseMethodStack();
	},

	getAffineVector: function (type, dif)
	{
		return this._modules[type].getAffineVector(dif);
	},

	/**
     * Adds localized strings of all modules for language.
     * @param {Object} localizedStrings
     * @param {String} language Localized string language
     * @returns {String}
     */
	extendLocalization: function(localizedStrings, language)
	{
		if (pm.settings.blockExit)
		{
			var module = world.games[pm.settings.getGame()].levels[pm.settings.getLevel()].getType();
			this._extendLocalizationForModule(localizedStrings, language, module);
		}
		else
		{
			for (var module in this._modules)
				this._extendLocalizationForModule(localizedStrings, language, module);
		}
	},

	_extendLocalizationForModule: function (localizedStrings, language, module)
	{
		var moduleLoc = this._modules[module].getLocalization(language);

		for (var loc in moduleLoc)
		{
			if (localizedStrings[loc])
				cc.warn("Duplicate localization from module {0} for key: {1}!".format(module, loc));

			localizedStrings[loc] = moduleLoc[loc];
		}
	},

	getResourcesPlist: function (type)
	{
		return this._modules[type].getResourcesPlist();
	},

	generateTaskLayer: function (type, level, task)
	{
		return this._modules[type].generateTaskLayer(level, task);
	},

	getTaskLabel: function (type)
	{
		return this._modules[type].getTaskLabel();
	},

	needToRotateRobot: function(type)
	{
		return this._modules[type].needToRotateRobot();
	},

	addLevelTask: function (type, level)
	{
		return this._modules[type].addLevelTask(level);
	},

	addExtraMethods: function(type, robot, functions)
	{
		return this._modules[type].addExtraMethods(robot, functions);
	},

	getDepthLineSprites: function(type)
	{
		return this._modules[type].getDepthLineSprites();
	},

	getChangeableConditions: function(type, robot, callback, target)
	{
		return this._modules[type].getChangeableConditions(robot, callback, target);
	},

	updateChangeableConditions: function(type, robot, name)
	{
		return this._modules[type].updateChangeableConditions(robot, name);
	},

	compareConditions: function(type)
	{
		return this._modules[type].compareConditions;
	},

	getChangeableMethods: function(type, robot, callback, target)
	{
		return this._modules[type].getChangeableMethods(robot, callback, target);
	},

	updateChangeableMethods: function(type, robot, name)
	{
		return this._modules[type].updateChangeableMethods(robot, name);
	},

	compareMethods: function(type)
	{
		return this._modules[type].compareMethods;
	},

	getAvailablePhysicalCommands: function(type)
	{
		return this._modules[type].getAvailablePhysicalCommands(type);
	},

	getLayerPositionDelta: function(type, i)
	{
		return this._modules[type].getLayerPositionDelta(i);
	}
};
