/**
 * Created by Kirill Mashchenko on 07.07.2018.
 */

/**
 * @class 2D map for {@link pm.data.TrainLevel}
 * @implements SquarableMapWithWalls
 */
pm.data.TrainMap = SquarableMapWithWalls.extend(/** @lends pm.data.TrainMap# */{
	typeName: "TrainMap",

	_trainTargetElements: [],

	ctor: function()
	{
		this._addNonEnumerableProps("_trainTargetElements");

		this._super();
	},

	load: function()
	{
		SquarableMap.prototype.load.call(this);

		this.updateTrainTargetElements(); 
	},

	updateTrainTargetElements: function()
	{
		this._trainTargetElements = [];

		for (var x = 0; x < this.width; ++x)
		{
			for (var y = 0; y < this.height; ++y)
			{
				if (this.element(cc.p(x, y)).originalType !== TrainMapElementType.Grass)
				{
					this._trainTargetElements.push({
						point: cc.p(cc.p(x, y)),
						type: this.element(cc.p(x, y)).originalType
					});
				}
			}
		}
	},

	getType: function()
	{
		return pm.TrainLevelModule.Type;
	},

	generateMapLayer: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (!pm.settings.isEditorMode)
				this.mapLayer = new TrainMapLayer(this);
			else
				this.mapLayer = new TrainMapEditorLayer(this);

			this.mapLayer.drawMap(previewDraw);

			return this.mapLayer;
		}
	},

	setSize: function(width, height)
	{
		this.mapElements.length = 0;

		for(var i = 0; i < height; ++i)
		{
			this.mapElements.push([]);

			for(var j = 0; j < width; ++j)
				this.mapElements[i].push(new pm.data.TrainMapElement());
		}

		this.width = width;
		this.height = height;
	}

});
