/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class Layer for draftsman task.
 * @extends TaskLayer
 * @constructor
 * @param {pm.AbstractLevel} level
 * @param {pm.data.DraftsmanTask} task
 */

var DraftsmanTaskLayer = TaskLayer.extend(/** @lends DraftsmanTaskLayer# */{

	ctor: function(level, task)
	{
		this._super(LocalizedString("DraftsmanTask"), level, task, pm.DraftsmanLevelModule.Type);

		var updateButton = new pmui.Button(pm.spriteUtils.getIconName("updateWorld", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("updateWorld", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("updateWorld", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		updateButton.addClickEventListener(this._updateTask.bind(this));

		var updateButtonAlign = new ccui.LinearLayoutParameter();
		updateButtonAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
		updateButtonAlign.setMargin(LevelSettingsLayer.BORDER_RADIUS + 15, LevelSettingsLayer.SEPARATOR, 0, 0);
		updateButton.setLayoutParameter(updateButtonAlign);

		this.addChild(updateButton);
		this.setContentSize(this.width, this.height + updateButton.height + LevelSettingsLayer.SEPARATOR);
	},

	_updateTask: function()
	{
		this._task.data[this._level.getActiveMapIndex()] = this._level.activeMap.lines;

		this._level.activeMap.mapLayer.taskDrawLayer.clear();
		this._level.activeMap.mapLayer.drawTask();
	}
});
