/**
 * Created by Nikita Besshaposhnikov on 08.07.15.
 */

/**
 * @class Barrel object for pushing by {@link pm.data.PushRobot}
 * @implements PushObject
 */
pm.data.PushBarrel = PushObject.extend(/** @lends pm.data.PushBarrel#*/{

	typeName: "PushBarrel",

	getType: function() { return pm.PushLevelModule.ObjectTypes.PushBarrel; },

	generateSprite: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			this.sprite = new PushBarrelSprite();

			return this.sprite;
		}
	}
});
