/**
 * Created by Kirill Mashchenko on 13.07.17.
 */

/**
 * @class Common interface for all 2D Maps.
 * @interface
 * @extends pm.AbstractMap
 */
var Map2D = pm.AbstractMap.extend(/** @lends Map2D#*/{

	/**
	 * Width of map in elements
	 * @see pm.MapElement
	 * @type {Number}
	 */
	width: 0,
	/**
	 * Height of map in elements
	 * @see pm.MapElement
	 * @type {Number}
	 */
	height: 0,

	/**
     * Return if level needs to draw wall
     * @returns {Boolean}
     */
	hasWalls: function() {},

	/**
     * Sets size of map.
     * @param {Number} width
     * @param {Number} height
     */
	setSize: function(width, height)
	{
		this.mapElements.length = 0;

		for(var i = 0; i < height; ++i)
		{
			this.mapElements.push([]);

			for(var j = 0; j < width; ++j)
				this.mapElements[i].push(new pm.MapElement(4));
		}

		this.width = width;
		this.height = height;
	},

	/**
     * Returns map element
     * @param {Number} position
     * @returns {pm.MapElement}
     */
	element: function(pos) {},

	load: function()
	{
		for(var x = 0; x < this.width; ++x)
		{
			for(var y = 0; y < this.height; ++y)
				this.element(cc.p(x, y)).initElement();
		}
	},

	/**
     * Returns element by cc.Point coordinate
     * @param {cc.Point} p Point
     * @returns {pm.MapElement}
     */
	elementFromPoint: function(p)
	{
		return this.element(p);
	},

	/**
	 * Gets next dicrectional element position in map coordinates
	 * @param {cc.Point} pos
	 * @param {Number} direction
	 * @returns {cc.Point}
	 */
	getNextDirectionalElementPosition: function(pos, direction) { return pos; },

	/**
	 * Gets prev dicrectional element position in map coordinates
	 * @param {cc.Point} pos
	 * @param {Number} direction
	 * @returns {cc.Point}
	 */
	getPrevDirectionalElementPosition: function(pos, direction) { return pos; },

	/**
     * Restores element type
     * @param {Number} position
     */
	restoreElement: function(pos)
	{
		this.element(pos).restore();
	},

	removeRobots: function()
	{
		for(var x = 0; x < this.width; ++x)
			for(var y = 0; y < this.height; ++y)
				this.element(cc.p(x, y)).removeAllRobots();

		pm.AbstractMap.prototype.removeRobots.call(this);
	},

	placeRobots: function(firstPlace)
	{
		var level = this.parentLevel;

		for (var x = 0; x < this.width; ++x)
		{
			for (var y = 0; y < this.height; ++y)
			{
				var startForRobot = this.element(cc.p(x, y)).startForRobot;

				if (startForRobot !== pm.MapElement.START_FOR_NO_ROBOT)
					level.robots[startForRobot].setPosition(cc.p(x, y), this.element(cc.p(x, y)).startRobotData.direction);
			}
		}

		pm.AbstractMap.prototype.placeRobots.call(this, firstPlace);
	},

	clean: function()
	{
		var level = this.parentLevel;

		for(var i = 0; i < this.height; ++i)
		{
			for(var j = 0; j < this.width; ++j)
			{
				this.restoreElement(cc.p(j, i));
				this.element(cc.p(j, i)).setNotCompleted(false);

				if(this === level.activeMap)
				{
					var startForRobot = this.element(cc.p(j, i)).startForRobot;

					if (startForRobot !== pm.MapElement.START_FOR_NO_ROBOT)
						level.robots[startForRobot].setPosition(cc.p(j, i), this.element(cc.p(j, i)).startRobotData.direction);
				}
			}
		}
		pm.AbstractMap.prototype.clean.call(this);
	},

	getState: function ()
	{
		var state = {
			mapElementsStates: {},
			objectsStates: {}
		};

		var max = Math.max(this.width, this.height);

		for (var x = 0; x < this.width; ++x)
		{
			for (var y = 0; y < this.height; ++y)
			{
				var element = this.element(cc.p(x, y));
				state.mapElementsStates[x * max + y] = element.getState();
			}
		}

		for (var i = 0; i < this.objects.length; ++i)
			state.objectsStates[i] = this.objects[i].getState();

		return state;
	},

	setState: function (state)
	{
		var max = Math.max(this.width, this.height);

		for (var x = 0; x < this.width; ++x)
		{
			for (var y = 0; y < this.height; ++y)
			{
				var element = this.element(cc.p(x, y));
				element.setState(state.mapElementsStates[x * max + y]);
			}
		}

		for (var i = 0; i < this.objects.length; ++i)
			this.objects[i].setState(state.objectsStates[i]);
	}
});
