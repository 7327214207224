/**
 * Created by Ekaterina Ermishkina on 29.08.16.
 */

/**x

 * @class Common sprite interface for light objects.
 * @interface
 * @extends pm.DynamicSprite
 */
var LightObjectSprite = pm.DynamicSprite.extend(/** @lends LightObjectSprite#*/{

	ctor: function(type)
	{
		this._super("Objects/" + type + ".png");
		switch (type)
		{
			case pm.LightLevelModule.ObjectTypes.LightObject0:
				this._minPoint = cc.p(65, 5);
				this._maxPoint = cc.p(65, -5);
				break;
			case pm.LightLevelModule.ObjectTypes.LightObject1:
				this._minPoint = cc.p(80, 15);
				this._maxPoint = cc.p(80, -2);
				break;
			default: break;
		}

	}

});
