/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.AllocNode = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{
	_variableName: "",
	_variableValue: null,
	_typeName: null,
	_name: null,
	_treeLinks: [],

	ctor: function (treeNode, parent, syntaxModule)
	{
		this._withBorder = true;
		this.type = FunctionButton.Type.Alloc;
		this._super(parent, treeNode, syntaxModule);
		this.setLayoutType(ccui.Layout.RELATIVE);

		pm.registerCustomEventListener(pm.VARIABLE_VALUE_CHANGED, function (event)
		{
			if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
				return;
			if (this._variableName === event.getUserData().name)
			{
				if(this.isInt())
					this._variableValue.setString(parseInt(event.getUserData().val));
				else
					this._variableValue.setString(event.getUserData().val);
			}
		}.bind(this), this);
	},

	addChildNode: function(node)
	{
		this._variableName = node.getName();
		this._typeName = node.getVarType();
		this._name = node;

		this._name._repTypes = [];
		this._variableValue = this.createText("0");
		this._variableValue.setFontSize(16);

		pm.syntaxModules.addChildNodeToAllocNode(this);
	},

	clone: function ()
	{
		return new VarFunctionButton(this.type, this.getVarName(), this.getTypeName());
	},

	makeCleanUp: function ()
	{
		pm.sendCustomEvent(pm.VARIABLE_DEFINITION_REMOVED, this._variableName);
	},

	getChildrenNodes: function ()
	{
		return [this._name];
	},

	calculateSize: function (forPar)
	{
		var size = this.sizeOfContainer(this, true);
		if(!pm.syntaxModules.isTextual(this.getSyntaxModule()) && this._variableValue)
			this.setContentSize(size.width + pmui.AllocNode.DEBUG_WIDTH - this._variableValue.width, size.height);
		else
			this.setContentSize(size);
		if (!forPar)
			this._parentNode.calculateSize();
	},

	getTypeName: function ()
	{
		return this._typeName;
	},

	setTypeName: function (type)
	{
		return this._typeName = type;
	},

	getVarName: function()
	{
		return this._variableName;
	},

	isBool: function ()
	{
		return this._name.isBool();
	},

	isAlloc: function()
	{
		return true;
	},

	isInt: function ()
	{
		return this._name.isInt();
	},

	removeLink: function (link)
	{
	},

	_removeNode: function (node)
	{
		return;
	}
});

pmui.AllocNode.intVals = [
	"x",
	"y",
	"k",
	"a",
	"b",
	"c"
];
pmui.AllocNode.boolVals = [
];

pmui.AllocNode.INT_NAMING = "intName";
pmui.AllocNode.BOOL_NAMING = "boolName";
pmui.AllocNode.DEBUG_WIDTH = 15;
