/**
 * Created by Kirill Mashchenko on 07.07.2018.
 */

/**
 * @class Layer for train task.
 * @extends TaskLayer
 * @constructor
 * @param {pm.AbstractLevel} level
 * @param {pm.data.PushTask} task
 */

var TrainTaskLayer = TaskLayer.extend(/** @lends PushTaskLayer# */{

	ctor: function(level, task)
	{
		this._super(LocalizedString("TrainTask"), level, task, pm.TrainLevelModule.Type);

		pm.registerCustomEventListener(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR, this._updateTaskData.bind(this), this);
	},

	_updateTaskData: function()
	{
		this._level.activeMap.updateTrainTargetElements();
	}
});
