/**
 * Created by Antony Orlovsky on 09.08.19.
 */

/**
 * @class 2D Sprite for {@link pm.data.TurtleRobot}
 * @implements pm.IsoRobotSprite
 */
var TurtleRobotSprite = pm.OrthoRobotSprite.extend(/** @lends TurtleRobotSprite# */{

	_robot: null,

	ctor: function(robot)
	{
		this._robot = robot;
		cc.animationCache.addAnimations("Robot/turtle_robot-animations.plist");

		pm.spriteUtils.addTempSpriteFrames("Robot/turtle_robot.plist"); // TODO registerTempSpriteFrames
		pm.spriteUtils.registerTempSpriteFrames("Robot/turtle_robot-broken.plist");

		this._super(cc.spriteFrameCache.getSpriteFrame(this._getFrameName()));

		this._minPoint = cc.p(28, 35);
		this._maxPoint = cc.p(28, 35);
		this.getSprite().setAnchorPoint(cc.p(0.5, 0.5));
	},

	_getActiveSpritePosition: function()
	{
		return cc.p(28, 70);
	},

	_getCommandsSpritePosition: function()
	{
		return cc.p(28, 100);
	},

	_getFrameName: function()
	{
		if (this._robot.getTailFlag())
			return "turtle_robot_tail_down.png";
		else
			return "turtle_robot_tail_up.png";
	},

	_getDestroyAnimationName: function()
	{
		if (this._robot.getTailFlag())
			return "turtle_robot_tail_down_d";
		else
			return "turtle_robot_tail_up_d";
	},

	_turn: function(params, curDirection, endCallback, target)
	{
		var speedRatio = Math.abs(params.angle) / 90;

		var turn = cc.rotateBy(speedRatio * pm.settings.getAnimationSpeed(), params.angle);
		if (!params.clockwise)
			turn = turn.reverse();

		var turnEnd = cc.callFunc(endCallback, target);
		this.sprite.runAction(cc.sequence(turn, turnEnd));

		this._commandsSprite.runAction(cc.rotateTo(speedRatio * pm.settings.getAnimationSpeed(), -this._getTurnAngle(curDirection)));
	},

	_move: function(pos, endCallback, target)
	{
		if (!target.getTailFlag())
		{
			pm.RobotSprite2D.prototype._move.call(this, pos, endCallback, target);
			return;
		}

		var targetPos = this.getRealPosition(pos);
		var callback = cc.callFunc(endCallback, target);
		var moveEnd = cc.callFunc(function()
		{
			this._needToUpdateZOrder = false;
		}, this);

		var oldPos = this.getSprite().getPosition();
		var mapElementSize = target.parentLevel.activeMap.mapLayer.getMapElementSize();

		var diffPosX = (targetPos.x - oldPos.x) / mapElementSize.width;
		var diffPosY = (targetPos.y - oldPos.y) / mapElementSize.height;

		var speedRatio = Math.sqrt(diffPosX * diffPosX + diffPosY * diffPosY);
		var moveRobot = cc.moveTo(speedRatio * pm.settings.getAnimationSpeed(), targetPos);

		var length = Math.sqrt((targetPos.x - oldPos.x) * (targetPos.x - oldPos.x) + (targetPos.y - oldPos.y) * (targetPos.y - oldPos.y));
		var rotationAngle = cc.radiansToDegrees(Math.acos((targetPos.x - oldPos.x) / length));

		if (targetPos.y - oldPos.y > 0)
			rotationAngle *= -1;

		var line = new cc.Scale9Sprite("Robot/turtle_line.png");

		line.setAnchorPoint(cc.p(0, 0.5));
		line.setRotation(rotationAngle);
		line.setPosition(cc.p(oldPos.x - (targetPos.x - oldPos.x) / length,oldPos.y - (targetPos.y - oldPos.y) / length));
		target.parentLevel.activeMap.mapLayer.drawLayer.addChild(line);

		var scaleLine = cc.scaleTo(speedRatio * pm.settings.getAnimationSpeed(), (length + 2) / line.width, 1);

		var moveAction = cc.targetedAction(this.getSprite(), moveRobot);
		var scaleAction = cc.targetedAction(line, scaleLine);

		this._needToUpdateZOrder = true;
		this.getSprite().runAction(cc.sequence(cc.spawn(moveAction, scaleAction), moveEnd, callback));
	},

	_getTurnAngle: function(direction)
	{
		return 360 - direction;
	}
});
