/**
 * Created by danilaeremin on 11.08.15.
 */

/**
 * @class Layer for common player robot settings.
 * @extends ccui.Layout
 * @constructor
 * @param {pm.AbstractRobot} robot Robot which settings need to display.
 */
var PlayerRobotSettingsLayer = ccui.Layout.extend(/** @lends PlayerRobotSettingsLayer# */{
	_robot: null,
	_label: null,
	_settingsLayout: null,
	_canEditProgramPattern: null,

	ctor: function(robot)
	{
		this._super();

		this._robot = robot;

		this.setLayoutType(ccui.Layout.RELATIVE);

		this._label = new ccui.Text(LocalizedString("Robot"), pm.settings.fontName, pm.settings.fontSize);
		var height = this._label.height + LevelSettingsLayer.SEPARATOR;

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		labelAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		labelAlign.setRelativeName("label");
		this._label.setLayoutParameter(labelAlign);

		this.addChild(this._label);

		this._settingsLayout = new ccui.Layout();
		this._settingsLayout.setLayoutType(ccui.Layout.RELATIVE);

		var settingsLayoutAlign = new ccui.RelativeLayoutParameter();
		settingsLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		settingsLayoutAlign.setRelativeToWidgetName("label");
		settingsLayoutAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		this._settingsLayout.setLayoutParameter(settingsLayoutAlign);

		this.addChild(this._settingsLayout);

		this._canEditProgramPattern = new pmui.CheckBox(LocalizedString("CanEditProgramPattern"), this._canEditProgramPatternChanged,
			this, 15, this._robot.getProgramData().canEditProgramPattern);
		height += this._canEditProgramPattern.height + LevelSettingsLayer.SEPARATOR;

		var canEditAlign = new ccui.RelativeLayoutParameter();
		canEditAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		canEditAlign.setRelativeName("canEditProgramPattern");
		canEditAlign.setMargin(30, 10, 0, 0);
		this._canEditProgramPattern.setLayoutParameter(canEditAlign);
		this._settingsLayout.addChild(this._canEditProgramPattern);

		var settingsInterface = pm.appUtils.generatePlayerRobotInterfaceLayer(robot);

		var settingInterfaceAlign = new ccui.RelativeLayoutParameter();
		settingInterfaceAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		settingInterfaceAlign.setRelativeName("settings");
		settingInterfaceAlign.setRelativeToWidgetName("canEditProgramPattern");
		settingInterfaceAlign.setMargin(0, 10, 0, 0);
		settingsInterface.setLayoutParameter(settingInterfaceAlign);
		this._settingsLayout.addChild(settingsInterface);
		height += settingsInterface.height + LevelSettingsLayer.SEPARATOR;

		var patternLayout = this._createPatternLayout("settings");
		height += patternLayout.height + LevelSettingsLayer.SEPARATOR;
		this._settingsLayout.addChild(patternLayout);

		this._settingsLayout.setContentSize(LevelSettingsLayer.WIDTH, height + LevelSettingsLayer.BORDER_RADIUS);
		this.setContentSize(LevelSettingsLayer.WIDTH, height + 2 * LevelSettingsLayer.BORDER_RADIUS + LevelSettingsLayer.SEPARATOR);
	},

	_createPatternLayout: function(relativeToName)
	{
		var patternLayout = new ccui.Layout();
		patternLayout.setLayoutType(ccui.Layout.LINEAR_HORIZONTAL);

		var patternLayoutAlign = new ccui.RelativeLayoutParameter();
		patternLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		patternLayoutAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		patternLayoutAlign.setRelativeName("pattern");
		patternLayoutAlign.setRelativeToWidgetName(relativeToName);
		patternLayout.setLayoutParameter(patternLayoutAlign);

		var addPatternLeftButton = new pmui.Button(pm.spriteUtils.getIconName("addPatternLeft", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("addPatternLeft", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("addPatternLeft", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		addPatternLeftButton.addClickEventListener(this._addLeftPattern.bind(this));

		var addPatternRightButton = new pmui.Button(pm.spriteUtils.getIconName("addPatternRight", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("addPatternRight", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("addPatternRight", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		addPatternRightButton.addClickEventListener(this._addRightPattern.bind(this));

		var deleteButton = new pmui.Button(pm.spriteUtils.getIconName("deletePattern", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("deletePattern", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("deletePattern", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		deleteButton.addClickEventListener(this._deletePattern.bind(this));

		var addPatternRightButtonAlign = new ccui.LinearLayoutParameter();
		addPatternRightButtonAlign.setMargin(8, 0, 8, 0);
		addPatternRightButton.setLayoutParameter(addPatternRightButtonAlign);

		patternLayout.addChild(addPatternLeftButton);
		patternLayout.addChild(addPatternRightButton);
		patternLayout.addChild(deleteButton);

		patternLayout.setContentSize(LevelSettingsLayer.WIDTH, addPatternLeftButton.height);
		return patternLayout;
	},

	_canEditProgramPatternChanged: function(flag)
	{
		this._robot.getProgramData().canEditProgramPattern = flag;
	},

	_getRobotPatternCount: function()
	{
		return this._robot.parentLevel.programData[this._robot.groupID].programDataArray.length;
	},

	_addLeftPattern: function ()
	{
		if (this._getRobotPatternCount() === PlayerRobotSettingsLayer.MAX_PATTERN_COUNT)
			return;

		var index = this._robot.parentLevel.programData[this._robot.groupID].currentIndex;

		var programData = pm.appUtils.generateProgramData();

		this._robot.parentLevel.programData[this._robot.groupID].programDataArray.splice(index, 0, programData);

		pm.sendCustomEvent(pme.PATTERNS_COUNT_CHANGED_EVENT_STR);
		this.getParent().setObject(this._robot);
	},

	_addRightPattern: function ()
	{
		if (this._getRobotPatternCount() === PlayerRobotSettingsLayer.MAX_PATTERN_COUNT)
			return;

		var index = this._robot.parentLevel.programData[this._robot.groupID].currentIndex;

		var programData = pm.appUtils.generateProgramData();

		++this._robot.parentLevel.programData[this._robot.groupID].currentIndex;

		this._robot.parentLevel.programData[this._robot.groupID].programDataArray.splice(index+1, 0, programData);

		pm.sendCustomEvent(pme.PATTERNS_COUNT_CHANGED_EVENT_STR);
		this.getParent().setObject(this._robot);
	},

	_deletePattern: function ()
	{
		if (this._getRobotPatternCount() === 1)
			return;

		var index = this._robot.parentLevel.programData[this._robot.groupID].currentIndex;

		var to = index - 1;

		if (to !== -1)
			this._robot.parentLevel.programData[this._robot.groupID].currentIndex = to;

		this._robot.parentLevel.programData[this._robot.groupID].programDataArray.splice(index, 1);

		pm.sendCustomEvent(pme.PATTERNS_COUNT_CHANGED_EVENT_STR);
		this.getParent().setObject(this._robot);
	}
});

PlayerRobotSettingsLayer.MAX_PATTERN_COUNT = 12;
