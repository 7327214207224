/**
 * Created by Nikita Besshaposhnikov on 09.04.15.
 */
/**
 * @class Sprite for {@link pm.data.PushBox}
 * @implements PushObjectSprite
 */
var PushBoxSprite = PushObjectSprite.extend(/** @lends PushBoxSprite# */{

	ctor: function()
	{
		this._super("Objects/PushBox.png");

		this._minPoint = cc.p(40, 35);
		this._maxPoint = cc.p(this.getSprite().getContentSize().width / 2, 3);
	}
});
