/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class 2D map where robot can paint some kinds({@link DraftsmanMapElementType}) of elements.
 * @implements SquarableMap
 */
pm.data.DraftsmanMap = SquarableMap.extend(/** @lends pm.data.DraftsmanMap# */{
	typeName: "DraftsmanMap",
	_startElementPos: cc.p(),
	lines: [],
	badLines: [],

	ctor: function()
	{
		this._super();
	},

	getType: function() { return pm.DraftsmanLevelModule.Type; },

	generateMapLayer: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (!pm.settings.isEditorMode)
				this.mapLayer = new DraftsmanMapLayer(this);
			else
				this.mapLayer = new DraftsmanMapEditorLayer(this);

			this.mapLayer.drawMap(previewDraw);

			return this.mapLayer;
		}
	},

	setNotCompleted: function(flag)
	{
		if(!CORE_BUILD)
			this.mapLayer.setNotCompleted(flag);
		else if(!flag)
			this.badLines = [];
	},

	placeRobots: function(firstPlace)
	{
		for (var i = 0; i < this.parentLevel.robots.length; ++i)
			this.parentLevel.robots[i].setPenFlag(false);

		Map2D.prototype.placeRobots.call(this, firstPlace);
	},

	clean: function()
	{
		if(!CORE_BUILD)
			this.mapLayer.setNotCompleted(false);

		this.lines = [];

		for (var i = 0; i < this.parentLevel.robots.length; ++i)
			this.parentLevel.robots[i].setPenFlag(false);

		Map2D.prototype.clean.call(this);
	},

	setSize: function(width, height)
	{
		this.mapElements.length = 0;

		for(var i = 0; i < height; ++i)
		{
			this.mapElements.push([]);

			for(var j = 0; j < width; ++j)
				this.mapElements[i].push(new pm.data.DraftsmanMapElement());
		}

		this.width = width;
		this.height = height;
	},

	setStartElementPos: function(pos)
	{
		this._startElementPos = pos;
	},

	getStartElementPos: function()
	{
		return this._startElementPos;
	}
});
