/**
 * Created by Nikita Besshaposhnikov on 16.11.15.
 */

/**
 * @class Layer for {@link pm.data.PushMap}.
 * @implements SquarableMapLayerWithWalls
 */
var PushMapLayer = SquarableMapLayerWithWalls.extend(/** @lends PushMapLayer#*/{
	ctor: function(map)
	{
		this._super(map);
		this._orientation = pm.MapLayer2D.Orientation.Iso;
		this._mapElementSize = cc.size(64, 64);
	}
});
