/**
 * Created by Nikita Besshaposhnikov on 13.11.14.
 */

/**
 * @class Layer for displaying program of robot.
 * @extends ccui.VBox
 * @property {pm.AbstractRobot} robot
 * @property {Number} curMethodCount
 * @constructor
 * @param {GameType} gameType
 * @param {Boolean} useMethodStack Use method stack or not.
 * @param {pm.AbstractRobot} [robot] For which robot construct layer.
 */
var ProgramLayerK = ProgramLayer.extend(/** @lends ProgramLayer# */{

	_buttonGroup: null,
	_butCont: null,
	_programData: null,
	_numberInputLayer: null,
	_clearProgramButton: null,
	_changeSyntaxButton: null,

	_oldScrollPos : 0,

	ctor: function (gameType, useMethodStack, robot, level)
	{
		this._super(gameType, robot);
		this._programData = robot.getProgramData();
		this._programData.usedMethodsCount = this._programData.programTree.getUsedMethodsCount();

		this._middleBorder = new cc.Scale9Sprite("System/PL_Middle.png");
		this._middleBorder.setCapInsets(cc.rect(28, 18, 7, 4));
		this._middleBorder.setAnchorPoint(0, 0.5);

		this._workLayout.addChild(this._middleBorder, -1);

		if (level.getRobotCount() >= 2)
		{
			this._colorBorder = new cc.LayerColor(pm.RobotSprite2D.getRobotColor(robot.id), this.getContentSize().width, 15);
			this._workLayout.addChild(this._colorBorder, -2);
		}

		if (useMethodStack)
		{
			this._methodStackLayer = new MethodStackLayer(this.getContentSize().height - 350, robot.parentLevel);

			var msShift = ProgramLayer.METHOD_STACK_WIDTH - this._methodStackLayer.width;
			msShift += MethodStackLayer.INNER_WIDTH;
			msShift -= MethodStackLayer.OUTER_RIGHT_MARGIN;

			var methodStackLayerAlign = new ccui.RelativeLayoutParameter();
			methodStackLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
			methodStackLayerAlign.setMargin(msShift + 5, ProgramLayer.COMPONENT_SEPARATOR, 0, 0);
			this._methodStackLayer.setLayoutParameter(methodStackLayerAlign);

			this.addChild(this._methodStackLayer, -100);
		}

		this._numberInputLayer = new NumberInputLayer();
		var numberInputLayerAlign = new ccui.RelativeLayoutParameter();
		numberInputLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
		numberInputLayerAlign.setMargin(ProgramLayer.METHOD_STACK_WIDTH, 0, 0, this._numberInputLayer.height);
		this._numberInputLayer.setLayoutParameter(numberInputLayerAlign);
		this.addChild(this._numberInputLayer, -100);

		this._selectLayout = new ccui.Layout();
		this._selectLayout.setBackGroundColorType(ccui.Layout.BG_COLOR_SOLID);
		this._selectLayout.setBackGroundColor(cc.color(135, 160, 180));
		this._selectLayout.setLayoutType(ccui.Layout.RELATIVE);

		var selectLayoutAlign = new ccui.RelativeLayoutParameter();
		selectLayoutAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		selectLayoutAlign.setRelativeName("selectLayout");
		selectLayoutAlign.setMargin(ProgramLayer.BORDER_X, 3 * ProgramLayer.BORDER_Y, 0, 0);
		this._selectLayout.setLayoutParameter(selectLayoutAlign);

		this._workLayout.addChild(this._selectLayout, -2);

		this._functionScroll = new ccui.ListView();
		this._functionScroll.setBackGroundColor(cc.color(224, 224, 224));
		this._functionScroll.setBackGroundColorType(ccui.Layout.BG_COLOR_SOLID);
		this._functionScroll.setScrollBarOpacity(255 * 0.9);
		this._functionScroll.setScrollBarWidth(ProgramLayer.SCROLL_BAR_WIDTH);
		this._functionScroll.setItemsMargin(5);
		this._functionScroll.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._functionScroll.setInnerContainerSize(cc.size(480, 350));
		this._functionScroll.setSwallowTouches(true);

		var functionsAlign = new ccui.RelativeLayoutParameter();
		functionsAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		functionsAlign.setMargin(0, ProgramLayer.PROGRAM_SEPARATOR, 0, 0);
		functionsAlign.setRelativeToWidgetName("selectLayout");
		this._functionScroll.setLayoutParameter(functionsAlign);

		this._workLayout.addChild(this._functionScroll, -2);

		if (robot)
			this.robot = robot;

		this._drawEditorButtons();

		pm.registerCustomEventListener(pm.UPDATE_INNER_PROGRAM_LAYER_CONTAINER, function (event)
		{
			this._recalculateSizes(event.getUserData());
		}.bind(this), this);


		pm.registerCustomEventListener(pm.ADD_FUNCTION_METHOD, function(event)
		{
			var data = event.getUserData();
			pmui.FunctionNode.vals.add(data.name);
		}.bind(this), this);

		pm.registerCustomEventListener(pm.VARIABLES_CHANGED, this._formatButs.bind(this, ProgramLayer.OPTIONAL_BUTTON_TAG), this);

		pm.registerCustomEventListener(pm.FUNCTION_DEFINITION_REMOVED, function (event)
		{
			var data = event.getUserData();
			if (data !== undefined)
			{
				if(pmui.FunctionNode.piktoVals.has(data) && this._programData.funcs.indexOf(data) === -1)
					this._programData.funcs.push(data);
				this._drawSelectLayout();
			}
		}.bind(this), this);

		pm.registerCustomEventListener(pm.FUNCTION_PARAMS_CHANGED, function (event)
		{
			var data = event.getUserData();
			if (data !== undefined)
			{
				this._robot.getProgramData().funcsMap[data.name] = data.params;
			}
		}.bind(this), this);

		pm.registerCustomEventListener(pm.VARIABLE_DEFINITION_REMOVED, function (event)
		{
			var data = event.getUserData();
			if (data !== undefined)
			{
				if (this._programData.alloc.indexOf(data) === -1)
					this._programData.alloc.push(data);
				pmui.StatementNode.vals.delete(data);
				this._drawSelectLayout(ProgramLayer.OPTIONAL_BUTTON_TAG);
			}
		}.bind(this), this);

		pm.registerCustomEventListener(pm.COMMAND_REMOVED, function (event)
		{
			this._programData.usedMethodsCount--;
			this.setEnabledKids(this._buttonGroup, this._programData.usedMethodsCount < this._programData.maxMethodCount);
		}.bind(this), this);

		pm.registerCustomEventListener(pm.UPDATE_SCROLL_POS, function (event)
		{
			var data = event.getUserData();
			this.updateView(data.height, data.posY);
		}.bind(this), this);

		pm.registerCustomEventListener(pm.UPDATE_CLEAR_PROGRAM_BUTTON, function()
		{
			this._updateEditorButtons();
		}.bind(this), this);
	},

	/**
	 * Sets robot and redraws interface.
	 * @param {pm.AbstractRobot} robot
	 */
	setRobot: function (robot)
	{
		var isControlled = this._methodStackLayer && !this._methodStackLayer.hidden;

		if (isControlled)
			this._methodStackLayer.drawRobotMethodList(robot.groupID);

		this._robot = robot;
		this._robot.parentLevel.setCurrentRobot(this._robot);

		this._updateSelectPatternMenu();

		if (isControlled)
			pm.robotManager.setControlledMode(true);

		if (this._colorBorder)
		{
			var width = this._colorBorder.getContentSize().width;
			var height = this._colorBorder.getContentSize().height;

			this._colorBorder.removeFromParent();
			this._colorBorder = new cc.LayerColor(pm.RobotSprite2D.getRobotColor(this._robot.id), width, height);
			this._colorBorder.setPosition(cc.p(this._middleBorder.getPosition().x, this._middleBorder.getPosition().y - 12));
			this._workLayout.addChild(this._colorBorder, -2);
		}
	},

	_drawEditorButtons: function()
	{
		if (pm.settings.getUseClearProgramButton() && !pm.settings.isEditorMode)
		{
			var backClearButton = new cc.Sprite("System/background_extra.png");

			backClearButton.setPosition(ProgramLayerK.POSITION_BACK_X, ProgramLayer.POSITION_BACK_Y);
			backClearButton.setName(ProgramLayer.BACK_CLEAR_NAME);

			this._workLayout.addChild(backClearButton, 4);

			this._clearProgramButton = new pmui.Button(pm.spriteUtils.getIconName("clearProgram", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("clearProgram", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("clearProgram", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			this._clearProgramButton.addClickEventListener(this._clearProgramConfirm.bind(this));

			var clearProgramButtonAlign = new ccui.RelativeLayoutParameter();
			clearProgramButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
			clearProgramButtonAlign.setMargin(ProgramLayer.METHOD_STACK_WIDTH - this._clearProgramButton.width, 0, ProgramLayer.EDITOR_BUTTONS_MARGIN_RIGHT, ProgramLayer.EDITOR_BUTTONS_MARGIN_BOTTOM);
			this._clearProgramButton.setLayoutParameter(clearProgramButtonAlign);

			this._workLayout.addChild(this._clearProgramButton, 4);
		}

		var backPositionX = ProgramLayerK.POSITION_BACK_X;
		var backPositionY = ProgramLayer.POSITION_BACK_Y;
		var buttonPositionX = ProgramLayer.EDITOR_BUTTONS_MARGIN_RIGHT;

		if(this._clearProgramButton !== null && !pm.settings.isEditorMode)
		{
			backPositionX -= ProgramLayer.POSITION_BACK_MARGIN;
			buttonPositionX += ProgramLayer.POSITION_BACK_MARGIN;
		}

		var backSyntaxButton = new cc.Sprite("System/background_extra.png");

		backSyntaxButton.setPosition(backPositionX, backPositionY);
		backSyntaxButton.setName(ProgramLayer.BACK_CHANGE_SYNTAX_NAME);

		this._workLayout.addChild(backSyntaxButton, 4);

		this._changeSyntaxButton = new pmui.Button(pm.spriteUtils.getIconName("changeSyntax", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("changeSyntax", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("changeSyntax", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this._changeSyntaxButton.addClickEventListener(this._changeSyntax.bind(this));

		var changeSyntaxAlign = new ccui.RelativeLayoutParameter();
		changeSyntaxAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
		changeSyntaxAlign.setMargin(ProgramLayer.METHOD_STACK_WIDTH, 0, buttonPositionX, ProgramLayer.EDITOR_BUTTONS_MARGIN_BOTTOM);
		this._changeSyntaxButton.setLayoutParameter(changeSyntaxAlign);

		this._workLayout.addChild(this._changeSyntaxButton, 4);

	},

	_changeSyntax: function()
	{
		pm.sendCustomEvent(pm.CHANGE_SYNTAX);
	},

	_removeBackground: function()
	{
		if (this._workLayout.getChildByName(ProgramLayer.BACK_CLEAR_NAME))
			this._workLayout.getChildByName(ProgramLayer.BACK_CLEAR_NAME).removeFromParent();

		if (this._workLayout.getChildByName(ProgramLayer.BACK_CHANGE_SYNTAX_NAME))
			this._workLayout.getChildByName(ProgramLayer.BACK_CHANGE_SYNTAX_NAME).removeFromParent();

	},

	_updateEditorButtons: function ()
	{
		if (this._clearProgramButton)
		{
			this._clearProgramButton.removeFromParent();
			this._clearProgramButton = null;
		}

		if(this._changeSyntaxButton)
		{
			this._changeSyntaxButton.removeFromParent();
			this._changeSyntaxButton = null;
		}

		this._removeBackground();
		this._drawEditorButtons();
	},

	_fillButtonsGroup: function(tag)
	{
		var height = 0;
		var width = 0;
		this._buttonGroup.removeAllChildren();
		if(!this._programData.useGroupButtons) {
			var buttonsGroup = new ccui.VBox();
			var methods = this._drawRobotMethods();

			buttonsGroup.addChild(methods);
			buttonsGroup.addChild(this._drawRobotConditions());
			buttonsGroup.addChild(this._drawCommonRepeaters());
			buttonsGroup.addChild(this._drawOptionalButtons());

			for (var i = 0; i < buttonsGroup.getChildrenCount(); i++)
			{
				height += buttonsGroup.getChildren()[i].height;
				width = Math.max(width, buttonsGroup.getChildren()[i].width);
			}
			height += ProgramLayer.BORDER_Y;

			buttonsGroup.setContentSize(width, height);
			this._buttonGroup.addChild(buttonsGroup);
		}
		else
		{
			var buttonsGroup = null;
			switch (tag) {
				case ProgramLayer.FUNCTIONS_BUTTON_TAG:
					buttonsGroup = this._drawRobotMethods();
					break;
				case ProgramLayer.CONDITIONS_BUTTON_TAG:
					buttonsGroup = this._drawRobotConditions();
					break;
				case ProgramLayer.CONSTRUCTIONS_BUTTON_TAG:
					buttonsGroup = this._drawCommonRepeaters();
					break;
				case ProgramLayer.OPTIONAL_BUTTON_TAG:
					buttonsGroup = this._drawOptionalButtons();
					break;
			}

			if (buttonsGroup !== null) {
				buttonsGroup.forceDoLayout();
				this._buttonGroup.addChild(buttonsGroup);
				height = buttonsGroup.height;
				width = buttonsGroup.width;
			}
			if(this._butCont.getChildByTag(tag))
			{
				for(var i = 0;i < this._butCont.getChildren().length;i++)
					this._butCont.getChildren()[i].setNormalTexture();
				this._butCont.getChildByTag(tag).setSelectedTexture();
			}
		}
		this.setEnabledKids(this._buttonGroup, this._programData.programTree.getUsedMethodsCount() < this._programData.maxMethodCount);
		this._buttonGroup.setContentSize(width, height);
	},

	_drawSelectLayout: function (tag)
	{
		if(!tag)
			tag = ProgramLayer.FUNCTIONS_BUTTON_TAG;

		this._selectLayout.removeAllChildren();
		this._programData = this._robot.getProgramData();

		if (this._getRobotPatternCount(this._robot) > 1)
			this._selectPatternMenu.setPosition((this._patternContainer.width - this._selectPatternMenu.width) / 2,
				(this._patternContainer.height - this._selectPatternMenu.height) / 2 + 2);

		if(this._programData.useGroupButtons)
		{
			this._drawTabButtons();
			this._buttonGroup = new ccui.VBox();
			var buttonGroupAlign = new ccui.RelativeLayoutParameter();
			buttonGroupAlign.setRelativeName("buttonGroup");
			buttonGroupAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
			buttonGroupAlign.setRelativeToWidgetName("sectionButtons");
			buttonGroupAlign.setMargin(ProgramLayer.BORDER_X, ProgramLayer.BORDER_Y, 0, 0);
			this._buttonGroup.setLayoutParameter(buttonGroupAlign);
		}
		else
		{
			this._butCont = null;
			this._buttonGroup = new ccui.VBox();
			var buttonGroupAlign = new ccui.RelativeLayoutParameter();
			buttonGroupAlign.setRelativeName("buttonGroup");
			buttonGroupAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
			buttonGroupAlign.setMargin(ProgramLayer.BORDER_X, ProgramLayer.BORDER_Y, 0, 0);
			this._buttonGroup.setLayoutParameter(buttonGroupAlign);
		}
		this._selectLayout.addChild(this._buttonGroup);
		this._fillButtonsGroup(tag);
		var height = this._butCont ? Math.max(this._butCont.height, this._buttonGroup.height) : this._buttonGroup.height;
		var width = pm.appUtils.getProgramLayerWidth() - ProgramLayer.BORDER_X - ProgramLayer.METHOD_STACK_WIDTH;

		if(this._butCont)
			this._butCont.setContentSize(this._butCont.width, height);
		this._middleBorder.setPosition(cc.p(15, this._workLayout.height - height - 20));
		if(this._colorBorder)
			this._colorBorder.setPosition(cc.p(this._middleBorder.getPosition().x, this._middleBorder.getPosition().y - 12));
		this._middleBorder.setContentSize(width - 15, 41);

		this._selectLayout.setContentSize(width, height);
		this._recalculateSizes();
	},

	_formatButs: function (tag)
	{
		var oldHeight = this._selectLayout.height;
		this._fillButtonsGroup(tag);
		FunctionButton.deselect();
		var height = this._butCont ? Math.max(this._butCont.height, this._buttonGroup.height) : this._buttonGroup.height;

		this._selectLayout.setContentSize(this._selectLayout.width, height);
		if(this._butCont)
		{
			this._butCont.setContentSize(this._butCont.width, height);
			this._butCont.forceDoLayout();
		}
		this._middleBorder.setPosition(cc.p(15, this._workLayout.height - this._selectLayout.height - 20));
		if(this._colorBorder)
			this._colorBorder.setPosition(cc.p(this._middleBorder.getPosition().x, this._middleBorder.getPosition().y - 12));

		this._selectLayout.forceDoLayout();

		if (oldHeight !== this._selectLayout.height || oldHeight === 0)
			this._recalculateSizes();
	},

	_drawTabButtons: function()
	{
		this._butCont = new ccui.VBox();
		this._butCont.setBackGroundImage("PiktomirK/grey-panel.png");
		this._butCont.setBackGroundImageScale9Enabled(true);
		this._butCont.setCascadeOpacityEnabled(false);
		this._butCont.setBackGroundImageCapInsets(cc.rect(10, 10, 20, 20));
		var contAlign = new ccui.RelativeLayoutParameter();
		contAlign.setRelativeName("sectionButtons");
		contAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		this._butCont.setLayoutParameter(contAlign);

		var func = new pmui.SelectionButton(pm.spriteUtils.getButtonGroupName("methods", pm.NORMAL_STATE),
			pm.spriteUtils.getButtonGroupName("methods", pm.SELECTED_STATE),
			pm.spriteUtils.getButtonGroupName("methods", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		var tabButtonAlign = new ccui.LinearLayoutParameter();
		tabButtonAlign.setMargin(ProgramLayer.TAB_BUTTON_MARGIN, ProgramLayer.TAB_BUTTON_MARGIN, ProgramLayer.TAB_BUTTON_MARGIN, 0);
		func.setLayoutParameter(tabButtonAlign);
		func.addClickEventListener(this._formatButs.bind(this, ProgramLayer.FUNCTIONS_BUTTON_TAG));

		this._butCont.addChild(func, 0, ProgramLayer.FUNCTIONS_BUTTON_TAG);

		if (this._programData.conds.length + this._programData.condsOpp.length + this._programData.logicOperatorsEquals.length + this._programData.logicOperatorsLessGreater.length + this._programData.logicOperatorsAndOr.length + this._programData.logicOperatorsNot.length!== 0)
		{
			var cond = new pmui.SelectionButton(pm.spriteUtils.getButtonGroupName("conds", pm.NORMAL_STATE),
				pm.spriteUtils.getButtonGroupName("conds", pm.SELECTED_STATE),
				pm.spriteUtils.getButtonGroupName("conds", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);
			this._butCont.addChild(cond, 0, ProgramLayer.CONDITIONS_BUTTON_TAG);
			cond.setLayoutParameter(tabButtonAlign.clone());
			cond.addClickEventListener(this._formatButs.bind(this, ProgramLayer.CONDITIONS_BUTTON_TAG));
		}
		if (this._programData.loops.length + this._programData.ifConstructions.length + this._programData.funcs.length !== 0)
		{
			var rep = new pmui.SelectionButton(pm.spriteUtils.getButtonGroupName("constr", pm.NORMAL_STATE),
				pm.spriteUtils.getButtonGroupName("constr", pm.SELECTED_STATE),
				pm.spriteUtils.getButtonGroupName("constr", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);
			this._butCont.addChild(rep, 0, ProgramLayer.CONSTRUCTIONS_BUTTON_TAG);
			rep.setLayoutParameter(tabButtonAlign.clone());
			rep.addClickEventListener(this._formatButs.bind(this, ProgramLayer.CONSTRUCTIONS_BUTTON_TAG));
		}
		if (this._programData.alloc.length +
			pmui.StatementNode.vals.size() +
			this._programData.expressions.length !== 0
			|| pm.settings.isEditorMode)
		{
			var optional = new pmui.SelectionButton(pm.spriteUtils.getButtonGroupName("optional", pm.NORMAL_STATE),
				pm.spriteUtils.getButtonGroupName("optional", pm.SELECTED_STATE),
				pm.spriteUtils.getButtonGroupName("optional", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);
			this._butCont.addChild(optional, 0, ProgramLayer.OPTIONAL_BUTTON_TAG);
			optional.setLayoutParameter(tabButtonAlign.clone());
			optional.addClickEventListener(this._formatButs.bind(this, ProgramLayer.OPTIONAL_BUTTON_TAG));
		}

		this._butCont.setContentSize(this._butCont.getChildrenCount() ? ProgramLayer.BUTTON_WIDTH + ProgramLayer.TAB_BUTTON_MARGIN * 2 : 0,
			ProgramLayer.BUTTON_HEIGHT * this._butCont.getChildrenCount() + ProgramLayer.TAB_BUTTON_MARGIN * (this._butCont.getChildrenCount() + 1));
		this._selectLayout.addChild(this._butCont);
	},

	_createSelectButtonsBar: function (types, values, isVarFunctionButton)
	{
		if (isVarFunctionButton === undefined)
			isVarFunctionButton = false;

		if (!Array.isArray(types))
		{
			var type = types;
			types = [];

			for (var i = 0; i < values.length; ++i)
				types.push(type);
		}

		var layout = new FunctionButtonBar(ProgramContainerLayer.DNDNAME, this._dragCallback, this._canDragButtons, this);

		var width = 0;
		var height = 0;

		for (var i = 0; i < values.length; ++i)
		{
			var button = isVarFunctionButton ? new VarFunctionButton(types[i], values[i]) :pm.appUtils.generateFunctionButton(types[i], values[i]);

			var buttonMargin = new ccui.LinearLayoutParameter();

			if (i !== 0)
			{
				buttonMargin.setMargin(ProgramLayer.MENU_SEPARATOR, 0, 0, 0);
				width += ProgramLayer.MENU_SEPARATOR;
			}

			button.setLayoutParameter(buttonMargin);

			layout.addChild(button);
			width += button.width;
			height = Math.max(button.height, height);
		}
		layout.setContentSize(width, height);
		return layout;
	},

	_drawRobotMethods: function ()
	{
		var layout = new ccui.VBox();
		var separator = new ccui.Layout();
		separator.setContentSize(ProgramLayer.MENU_SEPARATOR, ProgramLayer.MENU_SEPARATOR);

		this._robot.updateMethods();

		var compare = pm.moduleUtils.compareMethods(this._robot.parentLevel.getType());
		this._robot.nativeFunctions.sort(compare.bind(this));

		var exportedFunctions = cc.clone(this._robot.nativeFunctions);

		pm.moduleUtils.addExtraMethods(this._robot.parentLevel.getType(), this._robot, exportedFunctions);

		if (this._robot.parentLevel.robotsCanBeIndicated)
			exportedFunctions.push(pm.CMD_INDICATE);

		for (var i = 0; i < exportedFunctions.length; i++)
		{
			if (this._robot.nativeFunctionMap[exportedFunctions[i]].returnArgs())
			{
				exportedFunctions.splice(i, 1);
				i--;
			}
		}

		var methods = this._createSelectButtonsBar(FunctionButton.Type.Method, exportedFunctions.slice(0, Math.min(6, exportedFunctions.length)));
		methods.forceDoLayout();
		layout.addChild(methods);
		layout.addChild(separator.clone());

		if (exportedFunctions.length > 6)
		{
			var methods2 = this._createSelectButtonsBar(FunctionButton.Type.Method, exportedFunctions.slice(Math.min(6, exportedFunctions.length), exportedFunctions.length));
			methods2.forceDoLayout();
			layout.addChild(methods2);
			layout.addChild(separator.clone());
		}

		if (this._programData.intMethods.length !== 0)
		{
			var intMethodsLayer = this._createSelectButtonsBar(FunctionButton.Type.IntMethod, this._robot.getProgramData().intMethods);
			intMethodsLayer.forceDoLayout();
			layout.addChild(intMethodsLayer);
			layout.addChild(separator.clone());
		}

		if(pmui.FunctionNode.vals.size())
		{
			layout.addChild(this._createSelectButtonsBar(FunctionButton.Type.Method, pmui.FunctionNode.vals.values(), true));
			layout.addChild(separator.clone());
		}

		if (this._programData.constants.length !== 0)
		{
			layout.addChild(this._createSelectButtonsBar(FunctionButton.Type.Constant, this._programData.constants));
			layout.addChild(separator.clone());
		}

		layout.addChild(separator.clone());

		var width = 0;
		var height = 0;
		for (var i = 0; i < layout.getChildrenCount(); i++)
		{
			width = Math.max(layout.getChildren()[i].width, width);
			height += layout.getChildren()[i].height;
		}
		layout.setContentSize(width, height);
		return layout;
	},

	_addCustomFunc: function()
	{
		if(this._controlledMode)
			return;
		var addCustomFuncLayer = new pmui.TextInputDialog(LocalizedString("AlgorythmName"), "", LocalizedString("NewName"));
		addCustomFuncLayer.setCallback(this, function (name) {
			if(name.trim() === "" || this._programData.funcs.indexOf(name) !== -1 || pmui.FunctionNode.vals.has(name))
				return;
			this._programData.funcs.push(name);
			this._drawSelectLayout();
		}, function ()
		{
		});
		var align = new ccui.RelativeLayoutParameter();
		align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
		addCustomFuncLayer.setLayoutParameter(align);

		this.addChild(addCustomFuncLayer, 100, ProgramLayerK.POPUP_LAYER_TAG);
	},

	_drawCommonRepeaters: function ()
	{
		var cont = new ccui.VBox();

		var separator = new ccui.Layout();
		separator.setContentSize(ProgramLayer.MENU_SEPARATOR, ProgramLayer.MENU_SEPARATOR);

		if (this._programData.loops.length !== 0)
		{
			cont.addChild(this._createSelectButtonsBar(FunctionButton.Type.Repeater, this._programData.loops));
			cont.addChild(separator.clone());
		}

		if(this._programData.ifConstructions.length !== 0)
		{
			var elseBlocksContainer = new FunctionButtonBar(ProgramContainerLayer.DNDNAME, this._dragCallback, this._canDragButtons, this);
			var i = 0;
			var width = 0;
			var height = 0;
			if(this._programData.ifConstructions.indexOf(pm.IfVals.IF_ST) !== -1)
			{
				var button = pm.appUtils.generateFunctionButton(FunctionButton.Type.IfStatement, pm.IfVals.IF_ST);
				elseBlocksContainer.addChild(button);
				width += button.width;
				height = button.height;
				i++;
			}
			if(this._programData.ifConstructions.indexOf(pm.IfVals.IF_ELSE) !== -1)
			{
				var buttonMargin = new ccui.LinearLayoutParameter();
				var button = pm.appUtils.generateFunctionButton(FunctionButton.Type.ElseBlock, pm.IfVals.IF_ELSE);
				elseBlocksContainer.addChild(button);
				if (i !== 0)
				{
					buttonMargin.setMargin(ProgramLayer.MENU_SEPARATOR, 0, 0, 0);
					width += ProgramLayer.MENU_SEPARATOR;
				}
				width += button.width;
				height = Math.max(button.height, height);
				button.setLayoutParameter(buttonMargin);
			}
			elseBlocksContainer.setContentSize(width, height);
			cont.addChild(elseBlocksContainer);
			cont.addChild(separator.clone());
		}

		if (this._robot.getProgramData().funcs.length !== 0)
		{
			this._robot.getProgramData().funcs.sort();
			cont.addChild(this._createSelectButtonsBar(FunctionButton.Type.Function, this._robot.getProgramData().funcs, true));
			cont.addChild(separator.clone());
		}
		if(this._programData.useCustomFuncs)
		{
			var addButton = new pmui.Button(pm.spriteUtils.getIconName("add", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("add", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("add", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			addButton.addClickEventListener(this._addCustomFunc.bind(this));
			cont.addChild(addButton);
			cont.addChild(separator.clone());
		}

		var width = 0;
		var height = 0;
		for (var i = 0; i < cont.getChildrenCount(); i++)
		{
			width = Math.max(cont.getChildren()[i].width, width);
			height += cont.getChildren()[i].height;
		}
		cont.setContentSize(width, height);
		return cont;
	},

	_drawRobotConditions: function ()
	{
		var width = 0;
		var height = 0;

		var separator = new ccui.Layout();
		separator.setContentSize(5, 5);

		this._robot.updateConditions();

		var compare = pm.moduleUtils.compareConditions(this._robot.parentLevel.getType());
		this._robot.conditions.sort(compare.bind(this));

		var layout = new ccui.Layout();
		layout.setLayoutType(ccui.Layout.LINEAR_VERTICAL);

		var programData = this._robot.getProgramData();

		this._programData.conds.sort(compare.bind(this));
		var firstRow = this._createSelectButtonsBar(FunctionButton.Type.Condition, programData.conds);

		this._programData.condsOpp.sort(compare.bind(this));
		var secondRow = this._createSelectButtonsBar(FunctionButton.Type.Condition, programData.condsOpp);

		var optCondsValues = [];

		if(programData.logicOperatorsLessGreater.length)
		{
			optCondsValues.push(programData.logicOperatorsLessGreater[0]);
		}
		if(programData.logicOperatorsEquals.length)
		{
			optCondsValues.push(programData.logicOperatorsEquals[0]);
		}

		var andOrNotCondsValues = [];
		var andOrNotCondsTypes = [];

		if (programData.logicOperatorsAndOr.length)
		{
			andOrNotCondsValues.push(programData.logicOperatorsAndOr[0]);
			andOrNotCondsTypes.push(FunctionButton.Type.AndOrExpression);
		}

		if (programData.logicOperatorsNot.length)
		{
			andOrNotCondsValues.push(programData.logicOperatorsNot[0]);
			andOrNotCondsTypes.push(FunctionButton.Type.NotExpression);
		}

		var optCond = this._createSelectButtonsBar(FunctionButton.Type.LogicExpression, optCondsValues);
		var andOrNotCond = this._createSelectButtonsBar(andOrNotCondsTypes, andOrNotCondsValues);

		var rowAlign = new ccui.LinearLayoutParameter();
		rowAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
		rowAlign.setMargin(ProgramContainerLayer.BUTTON_SIZE / 2 + ProgramLayer.MENU_SEPARATOR / 2, -ProgramContainerLayer.BUTTON_SIZE / 2 + ProgramLayer.SECOND_ROW_MARGIN + ProgramLayer.MENU_SEPARATOR, 0, 0);

		if (programData.conds.length !== 0)
		{
			layout.addChild(firstRow);

			if (programData.condsOpp && programData.condsOpp.length !== 0)
			{
				secondRow.setLayoutParameter(rowAlign);

				layout.addChild(secondRow);
			}
			layout.addChild(separator);
		}
		else if (programData.condsOpp && programData.condsOpp.length !== 0)
		{
			layout.addChild(secondRow);
			layout.addChild(separator);
		}

		if (optCondsValues.length !== 0)
		{
			layout.addChild(optCond);
		}

		if (andOrNotCondsValues.length !== 0)
		{
			if (optCondsValues.length !== 0)
			{
				andOrNotCond.setLayoutParameter(rowAlign);
			}
			layout.addChild(andOrNotCond);
		}

		for (var i = 0; i < layout.getChildrenCount(); i++)
		{
			width = Math.max(layout.getChildren()[i].width, width);
			height += layout.getChildren()[i].height;
		}
		layout.setContentSize(width, height);

		return layout;
	},

	_drawOptionalButtons: function ()
	{
		var cont = new ccui.VBox();

		var separator = new ccui.Layout();
		separator.setContentSize(5, 5);

		if (this._robot.getProgramData().alloc.length)
		{
			cont.addChild(this._createSelectButtonsBar(FunctionButton.Type.Alloc, this._robot.getProgramData().alloc, true));
			cont.addChild(separator.clone());
		}
		if(pmui.StatementNode.vals.size())
		{
			cont.addChild(this._createSelectButtonsBar(FunctionButton.Type.Statement, Array.from(pmui.StatementNode.vals.keys()), true));
			cont.addChild(separator.clone());
		}
		if (this._robot.getProgramData().expressions.length)
		{
			cont.addChild(this._createSelectButtonsBar(FunctionButton.Type.Expression, [this._robot.getProgramData().expressions[0]]));
			cont.addChild(separator.clone());
		}
		if (pm.settings.isEditorMode)
		{
			cont.addChild(this._createSelectButtonsBar(FunctionButton.Type.Empty, [""]));
			cont.addChild(separator.clone());
		}

		var width = 0;
		var height = 0;
		for (var i = 0; i < cont.getChildrenCount(); i++)
		{
			width = Math.max(cont.getChildren()[i].width, width);
			height += cont.getChildren()[i].height;
		}
		cont.setContentSize(width, height);
		return cont;
	},

	/**
	 * Updates interface.
	 * @param {?Number} offset Offset fo correct scroll position after changes
	 */
	updateView: function (height, posY)
	{
		var oldY = this._oldScrollPos + (ProgramLayer.BORDER_Y < posY - height
			? -this._programContainer.shift
			: 0);
		var curY = this._functionScroll.getInnerContainer().y;

		if(curY === oldY && curY === 0)
			return;
		this._functionScroll.jumpToPercentVertical(
			100 - Math.min(
					Math.max((oldY)/ (curY) * 100, 0),
					100)
		);
	},

	_recalculateSizes: function ()
	{
		this._oldScrollPos = this._functionScroll.getInnerContainer().y;

		var functionScrollHeight = this._workLayout.height;
		functionScrollHeight -= this._middleBorder.height;
		functionScrollHeight -= this._selectLayout.height;
		functionScrollHeight += 5;
		var funcScrollWidth = this.getContentSize().width - ProgramLayer.METHOD_STACK_WIDTH - ProgramLayer.BORDER_X * 2;

		this._functionScroll.setContentSize(funcScrollWidth, functionScrollHeight);
		this._functionScroll.forceDoLayout();
		this._workLayout.forceDoLayout();
		this._selectLayout.forceDoLayout();
	},

	_dragCallback: function (element, eventType, touch)
	{
		if (eventType === pmui.DragAndDropLayout.Event.CLICKED)
		{
			if (this._controlledMode)
			{
				if (this._robot.isPlayingAnimation())
					return;

				if(element.isMethod())
				{
					if(this._robot.getProgramData().funcsMap && this._robot.getProgramData().funcsMap[element.value] && this._robot.getProgramData().funcsMap[element.value] !== 0)
						return;
				}
				if (this._buttonClickCallback)
					this._buttonClickCallback.call(this._buttonClickCallbackTarget, element);

				return;
			}
			element.select();
		}
		else if (eventType === pmui.DragAndDropLayout.Event.DRAG_START && !this.getRobot().parentLevel.isTutorial)
		{
			this.onDragStart(element, touch);
		}
		else if (eventType === pmui.DragAndDropLayout.Event.DRAG_CANCELLED)
		{
			ProgramContainerLayer.deselect();
		}
	},

	onDragStart: function(element, touch)
	{
		if(pm.syntaxModules.isTextual(this._programContainer.getTree().getSyntaxModule()))
			return;

		ProgramContainerLayer.deselect();
		if (element.type === FunctionButton.Type.Function)
		{
			var elem = this._findFuncPlace(touch);
			if (elem && elem instanceof pmui.FunctionNode)
				ProgramContainerLayer.select(elem);
		}
		else
		{
			var elem = this._findDropBody(touch, element);
			if (!elem || !(elem instanceof pmui.NodeLayer))
				return;

			while(!elem.isProgram())
			{
				if (elem._parentNode.canAdd(element.type))
				{
					ProgramContainerLayer.select(elem);
					return;
				}
				if (elem.canReplace(element.type))
				{
					if(elem.isEditable())
						ProgramContainerLayer.select(elem);
					return;
				}
				elem = elem._parentNode;
			}
		}
	},

	/**
	 * Enables Controlled mode of program layer.</br>
	 * In this mode buttons animates once and called click callback passed to function.
	 * @param {pm.ControlledModeData} controlledModeData
	 * @private
	 */
	_enableControlledMode: function(controlledModeData)
	{
		this._controlledMode = true;
		this._buttonClickCallback = controlledModeData.addMethodCallback;
		this._buttonClickCallbackTarget = controlledModeData.callbackTarget;

		this._programControlLayer.setButtonsEnabled(false);
	},

	/**
	 * Resets layer
	 */
	reset: function ()
	{
		this._programContainer.reset();
		this._programControlLayer.setButtonsEnabled(true);
		this.setEnabled(true);
		this._formatButs(ProgramLayer.FUNCTIONS_BUTTON_TAG);
		pmui.StatementNode.vals.keys().forEach(function (variable){
			pm.sendCustomEvent(pm.VARIABLE_VALUE_CHANGED, {
				name: variable,
				val: 0
			});
		});
	},

	setEnabled: function (flag, steppedWorking)
	{
		this._programContainer.enabled = flag;

		if(this._programData.useGroupButtons)
			this.setEnabledKids(this._butCont, flag);

		this.setEnabledKids(this._buttonGroup, this._programData.programTree.getUsedMethodsCount() < this._programData.maxMethodCount);

		if(this._methodStackLayer)
		{
			if (steppedWorking)
				this._methodStackLayer.setEnabled(true);
			else
				this._methodStackLayer.setEnabled(flag);

		}
	},

	setEnabledKids: function (parent, flag)
	{
		for (var i = 0; i < parent.getChildrenCount(); i++)
		{
			parent.getChildren()[i].setEnabled(flag);
			if (!(parent.getChildren()[i] instanceof FunctionButtonBar))
			{
				this.setEnabledKids(parent.getChildren()[i], flag);
			}
		}
	},

	_findDropBody: function (touch, button)
	{
		if(this._programContainer.getTree().convertTouchToNodeSpace(touch).y + this._functionScroll.getInnerContainer().y > this._workLayout.height - this._selectLayout.height - 35)
			return null;

		return this._findNode(
				touch,
				this._programContainer.getTree(),
				button);
	},

	_findNode: function (touch, node, button)
	{
		if (!node instanceof pmui.NodeLayer)
			return null;
		var childNodes = node.getChildrenNodes();
		var found = 0;
		var child = null;
		var intersec = null;
		var butWidth = button ? button.width : 5;
		var butHeight = button ? button.height : 5;
		var touchCoords = null;
		childNodes.filter(function (elem){
			return elem;
		}).forEach(function (childNode){
			touchCoords = childNode.getParent().convertTouchToNodeSpace(touch);
			intersec = cc.rectIntersection(childNode.getBoundingBox(),
				new cc.rect(
					touchCoords.x - 0.5 * butWidth ,
					touchCoords.y -  0.5 * butHeight,
					butWidth,
					butHeight));

			if (intersec.width > 0 &&
				intersec.width * intersec.height > found)
			{
				found = intersec.width * intersec.height;
				child = childNode;
			}
		});
		return child ? this._findNode(touch, child, button) : node;
	},

	_findFuncPlace: function (touch)
	{
		var node = this._programContainer.getTree();
		var childNodes = node.getFuncs();
		for (var i = 0; i < childNodes.length; i++)
		{
			var butWidth = ProgramLayer.BUTTON_WIDTH;
			var butHeight = ProgramLayer.BUTTON_HEIGHT;
			var touchCoords = childNodes[i].getParent().convertTouchToNodeSpace(touch);
			if (cc.rectIntersectsRect(childNodes[i].getBoundingBox(), new cc.rect(touchCoords.x - 0.65 * butWidth , touchCoords.y -  0.65 * butHeight, butWidth * 1.3, butHeight * 1.3)))
			{
				return childNodes[i];
			}
		}
		return null;
	},

	_updateSelectPatternMenu: function ()
	{
		if (this._patternContainer)
		{
			this._workLayout.setContentSize(this._workLayout.width, this._workLayout.height + this._patternContainer.height);

			this._patternContainer.removeFromParent();
			this._patternContainer = null;
		}
		if (this._getRobotPatternCount(this._robot) > 1)
		{
			this._patternContainer = new cc.LayerColor(ProgramLayer.PATTERN_COLOR, this._workLayout.width, ProgramLayer.PATTERN_HEIGHT);
			this._patternContainer.setPosition(this.width - this._patternContainer.width, 0);

			this._selectPatternMenu = new SelectPatternMenu(this._robot.parentLevel, this._robot, this, this.updateProgramContainer);
			this._selectPatternMenu.updateCircles();

			this._patternContainer.addChild(this._selectPatternMenu, 1);
			this.addChild(this._patternContainer);
			this._workLayout.setContentSize(this._workLayout.width, this._workLayout.height - this._patternContainer.height);
		}
		else
		{
			this.updateProgramContainer();
		}
		this._drawSelectLayout();
	},

	updateProgramContainer: function ()
	{
		if (this._programContainer)
		{
			this._programContainer.updateProgram(this._robot.getProgramData(), this);
		}
		else
		{
			this._functionScroll.removeAllChildren();
			this._programContainer = new ProgramContainerLayerK(this, this._robot.getProgramData());
			this._functionScroll.pushBackCustomItem(this._programContainer);
		}

		if (this._colorBorder)
		{
			var width = this._colorBorder.getContentSize().width;
			var height = this._colorBorder.getContentSize().height;

			this._colorBorder.removeFromParent();
			this._colorBorder = new cc.LayerColor(pm.RobotSprite2D.getRobotColor(this._robot.id), width, height);
			this._workLayout.addChild(this._colorBorder, -2);
		}
	},

	getOutputRobot: function ()
	{
		return this._robot.parentLevel.globalRobots.find(function (elem){
			return elem.getType() === pm.GlobalRobotType.Output;
		});
	}
});
var _p = ProgramLayerK.prototype;

/** @expose */
_p.robot;
cc.defineGetterSetter(_p, "robot", _p.getRobot, _p.setRobot);
ProgramLayer.FUNCTIONS_BUTTON_TAG = 1;
ProgramLayer.CONDITIONS_BUTTON_TAG = 2;
ProgramLayer.OPTIONAL_BUTTON_TAG = 3;
ProgramLayer.CONSTRUCTIONS_BUTTON_TAG = 4;
ProgramLayer.BUTTON_HEIGHT = 40;
ProgramLayer.BUTTON_WIDTH = 40;
ProgramLayer.TAB_BUTTON_MARGIN = 5;

ProgramLayerK.POSITION_BACK_X = 407;
