/**
 * Created by Kirill Mashchenko on 06.12.2017.
 */

/**
 * @class 2D robot
 * @implements PlayerRobot4
 */
pm.data.PullRobot = PlayerRobot4Walls.extend(/** @lends pm.data.PullRobot# */{
	typeName: "PullRobot",

	ctor: function()
	{
		this._super();

		this.nativeFunctionMap[pm.data.PullRobot.NativeMethod.Pull] = new pm.NativeFunction(this, this._pull);

		this.nativeFunctions.push(pm.data.PullRobot.NativeMethod.Pull);
	},

	reset: function()
	{
		PlayerRobot4.prototype.reset.call(this);
	},

	_canMoveTo: function(target, direction)
	{
		var element = this.getMap().element(target);
		return !element.walls[(direction + this.getDirectionCount()/2) % this.getDirectionCount()] &&
            element.getObjectCount() === 0;
	},

	_pull: function()
	{
		var map = this.getMap();

		var nextPos = map.getNextDirectionalElementPosition(this.position, this.direction);
		var prevPos = map.getPrevDirectionalElementPosition(this.position, this.direction);
		var objectPosition = this.position;

		var wasBroken = this.isBroken();

		var prevElement = map.element(prevPos);

		if(!prevElement || prevElement.getObjectCount() === 0 || prevElement.walls[this.direction])
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		for (var i in prevElement._objectsHere)
		{
			var object = prevElement._objectsHere[i];

			if (!(object instanceof PushObject))
				continue;

			if (object.isMoving())
			{
				this.setStateFlag(pm.RobotState.Broken);
				return;
			}
		}

		this._moveTo(nextPos, false);

		if(wasBroken || this.isBroken())
			return;

		for (var i in prevElement._objectsHere)
		{
			var object = prevElement._objectsHere[i];

			if (!(object instanceof PushObject))
				continue;

			object.moveTo(objectPosition);
		}
	},

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new PullRobotSprite();

			return this.sprite;
		}
	},

	getType: function() { return pm.PushLevelModule.RobotTypes.PullRobot; }
});

pm.data.PullRobot.NativeMethod = {Pull: "push_pull"};

