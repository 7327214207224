/**
 * Created by Nikita Besshaposhnikov on 05.12.14.
 */

var SquarableMapEditor_touchType = {
	None: 0,
	Resize: 1,
	DragRobot: 2,
	DragStartPoint: 3,
	EditMap: 4,
	MoveMap: 5,
	DragObject: 6,
	DeleteObject: 7,
	ResizeLeft: 8
};

var EditorTapsType = {
	None: 0,
	ChangeMapElement: 1,
	AddRobot: 2,
	AddObject: 3,
	AddClone: 4
};

var SquarableMapEditor_resizeSide = {
	Left: 0,
	Right: 1
};

/**
 * @class Common interface for square map layers in editor.
 * @interface
 */
var SquarableMapEditorLayerHelper = {
	_resizeLeftSprite: null,
	_resizeSprite: null,
	_startPoint: cc.p(),
	_startDragPoint: cc.p(),
	_touchType: SquarableMapEditor_touchType.None,
	_dragedObject: -1,
	_selectedObjectForTapType: null,
	_tapType: EditorTapsType.None,

	_disableEditing: false,

	_activeRobot: null,

	ctor: function(map)
	{
		this._super(map);

		this._disableEditing = false;

		this._tapType = EditorTapsType.None;

		pm.registerCustomEventListener(pme.PAGE_CHANGED, function(event)
		{
			this._selectedObjectForTapType = null;
			this._tapType = EditorTapsType.None;
		}.bind(this), this);

		pm.registerCustomEventListener(pme.MAP_ELEMENT_SELECT_EVENT_STR, function(event)
		{
			this._selectedObjectForTapType = event.getUserData();
			this._tapType = EditorTapsType.ChangeMapElement;
		}.bind(this), this);

		pm.registerCustomEventListener(pme.ADD_ROBOT_SELECT_EVENT_STR, function(event)
		{
			this._selectedObjectForTapType = event.getUserData();
			this._tapType = EditorTapsType.AddRobot;
		}.bind(this), this);

		pm.registerCustomEventListener(pme.ADD_OBJECT_SELECT_EVENT_STR, function(event)
		{
			this._selectedObjectForTapType = event.getUserData();
			this._tapType = EditorTapsType.AddObject;
		}.bind(this), this);

		pm.registerCustomEventListener(pm.ENABLE_CONTROLLED_MODE_STR, function(event)
		{
			this._disableEditing = true;

		}.bind(this), this);

		pm.registerCustomEventListener(pm.DISABLE_CONTROLLED_MODE_STR, function(event)
		{
			this._disableEditing = false;

		}.bind(this), this);

		pm.registerCustomEventListener(pme.ADD_CLONE, function(event)
		{
			this._selectedObjectForTapType = this._activeRobot.getType();
			this._tapType = EditorTapsType.AddClone;
		}.bind(this), this);

		this._activeRobot = this._map.parentLevel.robots[this._map.parentLevel.startRobotIndex];
	},

	_drawPositionMark: function(robotID, point)
	{
		var markName = pm.MapLayer2D.POSITION_MARK_NAME_PATTERN.format(robotID);
		var markSprite = this.terrainLayer.getChildByName(markName);

		if(!markSprite)
		{
			markSprite = pm.spriteUtils.getMapTile("targetElement");
			markSprite.setName(markName);

			if(robotID !== this._activeRobot.id)
				markSprite.setOpacity(pm.MapLayer2D.INACTIVE_ROBOT_OPACITY);

			this.terrainLayer.addChild(markSprite, 10);
		}

		this._setMarkPosition(robotID, point);
	},

	_touchBegan: function (touch, pEvent)
	{
		if (this._map.parentLevel.activeMap !== this._map || this._disableEditing)
			return false;

		var level = this._map.parentLevel;

		// resize

		var p = this._resizeSprite.convertTouchToNodeSpace(touch);
		this._startPoint = touch.getLocation();

		var l = this._resizeLeftSprite.convertTouchToNodeSpace(touch);

		var rect = this._resizeSprite.getContentSize();
		var rectL = this._resizeLeftSprite.getContentSize();

		if (p.x > -20 && p.y > -20 && p.x < rect.width + 20 && p.y < rect.height + 20)
		{
			for(var i = 0; i < level.robots.length; ++i)
			{
				if (level.robots[i].isPlayingAnimation() || level.robots[i].isEndedWork())
					return;
			}

			this._touchType = SquarableMapEditor_touchType.Resize;
			this._startPoint = this.convertTouchToNodeSpace(touch);

			return true;
		}

		if (l.x > -20 && l.y > -20 && l.x < rectL.width + 20 && l.y < rectL.height + 20)
		{
			for(var i = 0; i < level.robots.length; ++i)
			{
				if (level.robots[i].isPlayingAnimation() || level.robots[i].isEndedWork())
					return;
			}

			this._touchType = SquarableMapEditor_touchType.ResizeLeft;
			this._startPoint = this.convertTouchToNodeSpace(touch);

			return true;
		}

		if (!this.containsPoint(touch))
			return false;

		// drag robot

		if (!level.robotsPlayingAnimation())
		{
			for (var i = 0; i < level.robots.length; ++i)
			{
				if (level.robots[i].sprite.containsPoint(touch) && level.robots[i].getType() !== pm.PlayerRobotType.Counter)
				{
					if (level.activeMap.element(level.robots[i].position).startForRobot === level.robots[i].id &&
						level.activeMap.element(level.robots[i].position).startRobotData.direction === level.robots[i].direction)
					{
						this.dragedRobot = i;
						this._touchType = SquarableMapEditor_touchType.DragRobot;
						this._startPoint = this.convertTouchToNodeSpace(touch);
						this._startDragPoint = this.objectLayer.convertTouchToNodeSpace(touch);

						if (!level.robots[i].isEndedWork())
						{
							this._map.element(level.robots[this.dragedRobot].position).startForRobot = pm.MapElement.START_FOR_NO_ROBOT;
							delete this._map.element(level.robots[this.dragedRobot].position).startRobotData.direction;
						}

						return true;
					}
					else
					{
						pm.sendCustomEvent(pm.ANIMATE_RESTART_BUTTON);
					}
				}
			}
		}

		//drag objects
		for(var i = 0; i < level.activeMap.objects.length; ++i)
		{
			if (level.activeMap.objects[i].sprite.containsPoint(touch))
			{
				this._dragedObject = i;
				this._touchType = SquarableMapEditor_touchType.DragObject;
				this._startPoint = this.convertTouchToNodeSpace(touch);
				this._startDragPoint = this.objectLayer.convertTouchToNodeSpace(touch);

				return true;
			}
		}

		// drag startPoint
		var startPoint = this.terrainLayer.getChildByName(pm.MapLayer2D.POSITION_MARK_NAME_PATTERN.format(this._activeRobot.id));

		if (startPoint !== null)
		{
			p = startPoint.convertTouchToNodeSpace(touch);
			rect = startPoint.getContentSize();

			if (p.x > 0 && p.y > 0 && p.x < rect.width && p.y < rect.height)
			{
				this._dragedObject = this._activeRobot;
				this._touchType = SquarableMapEditor_touchType.DragStartPoint;
				this._startPoint = this.convertTouchToNodeSpace(touch);

				return true;
			}
		}

		this._startPoint = this.convertTouchToNodeSpace(touch);
		this._touchType = SquarableMapEditor_touchType.EditMap;
		return true;
	},

	resizeTouchMoved: function (touch, side, pEvent)
	{
		if (this._orientation === pm.MapLayer2D.Orientation.Iso)
		{
			var dif = cc.pSub(this.convertTouchToNodeSpace(touch), this._startPoint);

			var affineVector = pm.moduleUtils.getAffineVector(this._map.getType(), dif);

			var dx = affineVector.x / (this.getMapElementSize().width * Math.sqrt(5 / 8));
			var dy = affineVector.y / (this.getMapElementSize().height * Math.sqrt(5 / 8));
		}
		else if (this._orientation === pm.MapLayer2D.Orientation.Ortho)
		{
			var dif = cc.pSub(this.convertTouchToNodeSpace(touch), this._startPoint);

			var dx = dif.x / this.getMapElementSize().width;
			var dy = dif.y / this.getMapElementSize().height;
		}

		if (dx > 1)
		{
			this._startPoint = this.convertTouchToNodeSpace(touch);
			if (side === SquarableMapEditor_resizeSide.Left)
				this._resizeWidthLeft(-1);
			else
				this._resizeWidthRight(1);
			pm.sendCustomEvent(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR);
		}
		else if (dx < -1)
		{
			this._startPoint = this.convertTouchToNodeSpace(touch);
			if (side === SquarableMapEditor_resizeSide.Left)
				this._resizeWidthLeft(1);
			else
				this._resizeWidthRight(-1);
			pm.sendCustomEvent(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR);
		}

		if (dy > 1)
		{
			this._startPoint = this.convertTouchToNodeSpace(touch);
			if (side === SquarableMapEditor_resizeSide.Left)
				this._resizeHeightLeft(-1);
			else
				this._resizeHeightRight(1);
			pm.sendCustomEvent(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR);
		}
		else if (dy < -1)
		{
			this._startPoint = this.convertTouchToNodeSpace(touch);
			if (side === SquarableMapEditor_resizeSide.Left)
				this._resizeHeightLeft(1);
			else
				this._resizeHeightRight(-1);
			pm.sendCustomEvent(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR);
		}
	},

	_dragRobotMoved: function (touch, pEvent)
	{
		var level = this._map.parentLevel;
		var robot = level.robots[this.dragedRobot];

		if (level.robotsEndedWork())
			return;

		var p = this.objectLayer.convertTouchToNodeSpace(touch);
		var p_normal = this.positionFromReal(p);

		var startPosition = this.positionFromReal(this._startDragPoint);

		if (this._map.element(p_normal).getObjectCount() !== 0 &&
            (startPosition.x !== p_normal.x || startPosition.y !== p_normal.y))
			return;

		if (this._map.element(p_normal).getRobotsCount() !== 0 &&
            (startPosition.x !== p_normal.x || startPosition.y !== p_normal.y))
			return;

		if (!(robot.direction >= 0 && robot.direction < robot.getDirectionCount()))
		{
			cc.log("Incorrect robot4 direction");
			robot.direction = 0;
		}

		robot.setPosition(p_normal, robot.direction);
		robot.sprite.setRealPosition(robot.position);

		this._setRobotStartPositionMarkPosition(robot.id, robot.position);

		var opacity = 255;

		if (this._positionOutOfMap(p))
			opacity = 50;

		robot.sprite.getSprite().setOpacity(opacity);
	},

	_dragObjectMoved: function (touch, pEvent)
	{
		var level = this._map.parentLevel;
		var object = level.activeMap.objects[this._dragedObject];

		var point = this.objectLayer.convertTouchToNodeSpace(touch);
		var normalPoint = this.positionFromReal(point);

		if (pm.moduleUtils.canNotDragOrAddObject(this._map.getType(), this._map, normalPoint))
			return;

		var startPosition = this.positionFromReal(this._startDragPoint);

		if (this._map.element(normalPoint).getObjectCount() !== 0 &&
            (startPosition.x !== normalPoint.x || startPosition.y !== normalPoint.y))
			return;

		if (this._map.element(normalPoint).getRobotsCount() !== 0 &&
            (startPosition.x !== normalPoint.x || startPosition.y !== normalPoint.y))
			return;

		object.setPosition(normalPoint);
		object.startPosition = normalPoint;
		object.position = normalPoint;
		object.sprite.setRealPosition(object.startPosition);

		if (this._positionOutOfMap(point))
			object.sprite.getSprite().setOpacity(100);
		else
			object.sprite.getSprite().setOpacity(255);
	},

	_dragStartPointMoved: function (touch, pEvent)
	{
		var p = this.objectLayer.convertTouchToNodeSpace(touch);
		var p_normal = this.positionFromReal(p);

		var level = this._map.parentLevel;
		var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
		var mapIndex = level.getActiveMapIndex();

		if(task)
		{
			var robotID = this._dragedObject.id;

			task.data[mapIndex][robotID] = p_normal;

			this._setMarkPosition(robotID, p_normal);

			if (this._orientation === pm.MapLayer2D.Orientation.Iso)
				this.objectLayer.updateOrderGraph();
		}
	},

	_positionOutOfMap: function(pos)
	{
		var x = Math.floor(pos.x/this.getMapElementSize().width + 0.5);
		if (x < 0) return true;
		if (x >= this._map.width) return true;

		var y = Math.floor((this.getRealHeight() - pos.y)/this.getMapElementSize().height + 0.5);
		if (y < 0) return true;
		if (y >= this._map.height) return true;

		return false;
	},

	_touchMoved: function (touch, pEvent)
	{
		switch (this._touchType)
		{
			case SquarableMapEditor_touchType.EditMap:
			{
				var dif = cc.pCompOp(cc.pSub(this._startPoint, this.convertTouchToNodeSpace(touch)), Math.abs);

				if (dif.x > 10 || dif.y > 10)
					this._touchType = SquarableMapEditor_touchType.MoveMap;
				break;
			}

			case SquarableMapEditor_touchType.Resize:
				this.resizeTouchMoved(touch, 1, pEvent);
				break;

			case SquarableMapEditor_touchType.ResizeLeft:
				this.resizeTouchMoved(touch, 0, pEvent);
				break;

			case SquarableMapEditor_touchType.DragRobot:
				this._dragRobotMoved(touch, pEvent);
				break;

			case SquarableMapEditor_touchType.DragObject:
				this._dragObjectMoved(touch, pEvent);
				break;

			case SquarableMapEditor_touchType.DragStartPoint:
				this._dragStartPointMoved(touch, pEvent);
				break;

			case SquarableMapEditor_touchType.MoveMap:
				this.setPosition(cc.pAdd(this.getPosition(), touch.getDelta()));
				break;
		}
	},

	_changeWallsForTouch: function (touch)
	{
		if (!this._map.editWalls)
			return false;

		if (this._map.hasWalls())
		{
			var start = this._startPoint;
			var end = this.convertTouchToNodeSpace(touch);

			var dif = cc.pSub(start, end);
			dif = cc.pCompOp(dif, Math.abs);

			if (dif.x < 10 && dif.y < 10)
			{
				var p = this.terrainLayer.convertTouchToNodeSpace(touch);
				var p_normal = this.positionFromReal(p);
				var p_left = this.positionFromReal(cc.p(p.x + 10, p.y));
				var p_up = this.positionFromReal(cc.p(p.x, p.y - 10));
				var p_right = this.positionFromReal(cc.p(p.x - 10, p.y));
				var p_down = this.positionFromReal(cc.p(p.x, p.y + 10));

				var walls = false;

				if (p_normal.x < p_left.x)
				{
					walls = true;
					this._map.elementFromPoint(p_normal).walls[MapDirection4.Right] = !this._map.elementFromPoint(p_normal).walls[MapDirection4.Right];
					this._map.elementFromPoint(p_left).walls[MapDirection4.Left] = this._map.elementFromPoint(p_normal).walls[MapDirection4.Right];

					var type = this._getWallType(MapDirection4.Right);

					if (this._map.elementFromPoint(p_normal).walls[MapDirection4.Right])
						this._drawWall(p_normal, type);
					else
						this._removeWall(this._map.element(p_normal).wallLeftSprite);
				}
				else if (p_normal.x > p_right.x)
				{
					walls = true;
					this._map.elementFromPoint(p_normal).walls[MapDirection4.Left] = !this._map.elementFromPoint(p_normal).walls[MapDirection4.Left];
					this._map.elementFromPoint(p_right).walls[MapDirection4.Right] = this._map.elementFromPoint(p_normal).walls[MapDirection4.Left];

					var type = this._getWallType(MapDirection4.Right);

					if (this._map.elementFromPoint(p_right).walls[MapDirection4.Right])
						this._drawWall(p_right, type);
					else
						this._removeWall(this._map.element(p_right).wallLeftSprite);
				}
				else if (p_normal.y < p_up.y)
				{
					walls = true;
					this._map.elementFromPoint(p_normal).walls[MapDirection4.Down] = !this._map.elementFromPoint(p_normal).walls[MapDirection4.Down];
					this._map.elementFromPoint(p_up).walls[MapDirection4.Up] = this._map.elementFromPoint(p_normal).walls[MapDirection4.Down];

					var type = this._getWallType(MapDirection4.Down);

					if (this._map.elementFromPoint(p_normal).walls[MapDirection4.Down])
						this._drawWall(p_normal, type);
					else
						this._removeWall(this._map.element(p_normal).wallUpSprite);
				}
				else if (p_normal.y > p_down.y)
				{
					walls = true;
					this._map.elementFromPoint(p_normal).walls[MapDirection4.Up] = !this._map.elementFromPoint(p_normal).walls[MapDirection4.Up];
					this._map.elementFromPoint(p_down).walls[MapDirection4.Down] = this._map.elementFromPoint(p_normal).walls[MapDirection4.Up];

					var type = this._getWallType(MapDirection4.Down);

					if (this._map.elementFromPoint(p_down).walls[MapDirection4.Down])
						this._drawWall(p_down, type);
					else
						this._removeWall(this._map.element(p_down).wallUpSprite);
				}

				if (walls)
				{
					if (this._orientation === pm.MapLayer2D.Orientation.Iso)
						this.objectLayer.updateOrderGraph();

					return true;
				}
				return false;
			}
		}
		return false;
	},

	_setActiveRobot: function(newRobot)
	{
		if(!newRobot)
			return;

		if(this._map.parentLevel.robots.length > 1)
		{
			for (var i = 0; i < this._map.parentLevel.robots.length; ++i)
			{
				var robot = this._map.parentLevel.robots[i];

				if (robot.groupID === newRobot.groupID)
				{
					robot.sprite.setSpriteColor(this.getRobotIndex(robot.groupID));
					robot.sprite.setActive(true);
				}
				else
				{
					robot.sprite.setActive(false);
				}
			}
		}

		var startMarkName = pm.MapLayer2D.START_MARK_NAME_PATTERN.format(this._activeRobot.id);
		var startMarkSprite = this.terrainLayer.getChildByName(startMarkName);

		if(startMarkSprite)
			startMarkSprite.setOpacity(pm.MapLayer2D.INACTIVE_ROBOT_OPACITY);

		startMarkName = pm.MapLayer2D.START_MARK_NAME_PATTERN.format(newRobot.id);
		startMarkSprite = this.terrainLayer.getChildByName(startMarkName);

		if(startMarkSprite)
			startMarkSprite.setOpacity(255);

		var posMarkName = pm.MapLayer2D.POSITION_MARK_NAME_PATTERN.format(this._activeRobot.id);
		var posMarkSprite = this.terrainLayer.getChildByName(posMarkName);

		if(posMarkSprite)
			posMarkSprite.setOpacity(pm.MapLayer2D.INACTIVE_ROBOT_OPACITY);

		posMarkName = pm.MapLayer2D.POSITION_MARK_NAME_PATTERN.format(newRobot.id);
		posMarkSprite = this.terrainLayer.getChildByName(posMarkName);

		if(posMarkSprite)
			posMarkSprite.setOpacity(255);

		this._activeRobot = newRobot;

		pm.sendCustomEvent(pm.ROBOT_TOUCH_EVENT_STR, this._activeRobot);
	},

	_touchEnded: function (touch, pEvent)
	{
		var p = this.objectLayer.convertTouchToNodeSpace(touch);
		var p_normal = this.positionFromReal(p);
		var level = this._map.parentLevel;

		if(this._touchType === SquarableMapEditor_touchType.DragRobot)
		{
			var robot = level.robots[this.dragedRobot];

			if (!level.robotsEndedWork())
			{
				this._map.element(robot.position).startForRobot = robot.id;
				this._map.element(robot.position).startRobotData.direction = robot.direction;

				this._setRobotStartPositionMarkPosition(robot.id, robot.position);
			}

			var start = this._startPoint;
			var end = this.convertTouchToNodeSpace(touch);

			var dif = cc.pSub(start, end);
			dif = cc.pCompOp(dif, Math.abs);

			if (dif.x < 10 && dif.y < 10)
			{
				if (this._activeRobot.id === robot.id && !level.robotsEndedWork())
				{
					if (!(robot.direction >= 0 && robot.direction < robot.getDirectionCount()))
					{
						cc.log("Incorrect robot4 direction");
						robot.direction = 0;
					}

					if (pm.moduleUtils.needToRotateRobot(this._map.getType()))
					{
						var newDirection = (robot.direction + 1) % robot.getDirectionCount();

						robot.setPosition(robot.position, newDirection);
						robot.sprite.setDirection(newDirection);
						robot.sprite.setRealPosition(robot.position);
						this._map.element(robot.position).startRobotData.direction = robot.direction;
					}
				}
				else
				{
					this._setActiveRobot(robot);
				}
			}
			else if (this._positionOutOfMap(p) && level.robots.length > 1)
			{
				var newActiveRobot = null;

				if(robot.id === this._activeRobot.id)
				{
					var activeIndex = level.robots.indexOf(robot);
					for (var t = 0; t < level.robots.length; ++t)
					{
						if (t !== activeIndex)
						{
							newActiveRobot = level.robots[t];
							break;
						}
					}
				}

				this._map.element(robot.position).removeRobot(robot);
				this.removeObject(robot.sprite, false);
				this._removeRobotStartPositionMark(robot.id);
				this._removePositionMark(robot.id);

				this._onRemoveRobot(robot);

				for (var k = 0; k < level.maps.length; ++k)
				{
					var map = level.maps[k];

					for (var i = 0; i < map.height; ++i)
					{
						for (var j = 0; j < map.width; ++j)
						{
							if (map.element(cc.p(j, i)).startForRobot === robot.id)
							{
								map.element(cc.p(j, i)).startForRobot = pm.MapElement.START_FOR_NO_ROBOT;
								delete map.element(cc.p(j, i)).startRobotData.direction;
							}
						}
					}
				}

				var deleteRobotGroupID = level.robots[this.dragedRobot].groupID;

				level.robots.splice(this.dragedRobot, 1);

				var needToUpdateGroupID = true;

				for (var r = 0; r < level.robots.length; ++r)
				{
					if (level.robots[r].groupID === deleteRobotGroupID)
						needToUpdateGroupID = false;
				}

				if (needToUpdateGroupID)
				{
					for (var r = 0; r < level.robots.length; ++r)
					{
						if (level.robots[r].groupID > deleteRobotGroupID)
							--level.robots[r].groupID;
					}
				}

				for (var r = this.dragedRobot; r < level.robots.length; ++r)
				{
					var markSprite = this.terrainLayer.getChildByName(pm.MapLayer2D.START_MARK_NAME_PATTERN.format(level.robots[r].id));

					map.element(level.robots[r].position).removeRobot(level.robots[r]);
					--level.robots[r].id;

					map.element(level.robots[r].position).startForRobot--;
					map.element(level.robots[r].position).startRobotData.direction = level.robots[r].direction;
					map.element(level.robots[r].position).addRobot(level.robots[r]);

					markSprite.setName(pm.MapLayer2D.START_MARK_NAME_PATTERN.format(level.robots[r].id));
				}

				if (newActiveRobot)
					this._setActiveRobot(newActiveRobot);

				if (this._map.parentLevel.robots.length === 1)
					this._activeRobot.sprite.setActive(false);

				if (this._orientation === pm.MapLayer2D.Orientation.Iso)
					this.objectLayer.forceUpdate();
			}
			else if (this._positionOutOfMap(p) && level.robots.length === 1)
			{
				robot.sprite.getSprite().setOpacity(255);
			}

			if (this._map.parentLevel.robots.length > 1 && this._positionOutOfMap(p) && this.dragedRobot === this._map.parentLevel.startRobotIndex)
				robot.sprite.setActive(robot.id === this._activeRobot.id);
		}

		if(this._touchType === SquarableMapEditor_touchType.DragObject)
		{
			if (this._positionOutOfMap(p))
			{
				var object = level.activeMap.objects[this._dragedObject];

				this._removeObject(object);

				if (this._orientation === pm.MapLayer2D.Orientation.Iso)
					this.objectLayer.forceUpdate();
			}
		}

		if(this._touchType === SquarableMapEditor_touchType.EditMap &&
            !this._changeWallsForTouch(touch) &&
            this._selectedObjectForTapType !== undefined &&
            this._selectedObjectForTapType !== null)

			this._mapElementClicked(p_normal);
	},

	_removeObject: function(object)
	{
		this._map.element(object.position).removeObject(object);
		this.removeObject(object.sprite, false);

		this._map.parentLevel.activeMap.objects.splice(this._dragedObject, 1);

		for (var i = this._dragedObject; i < this._map.parentLevel.activeMap.objects.length; ++i)
		{
			this._map.element(this._map.objects[i].position).removeObject(this._map.objects[i]);
			this._map.parentLevel.activeMap.objects[i].id--;
			this._map.element(this._map.objects[i].position).addObject(this._map.objects[i]);
		}
	},

	_mapElementClicked: function (pos)
	{
		if (this._tapType === EditorTapsType.ChangeMapElement)
		{
			var element = this._map.element(pos);
			element.setType(this._selectedObjectForTapType);
			element.originalType = this._selectedObjectForTapType;
			pm.sendCustomEvent(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR);
		}
		else if (this._tapType === EditorTapsType.AddRobot)
		{
			if (this._map.element(pos).getRobotsCount() !== 0)
				return;

			var robot = pm.moduleUtils.generateRobot(this._map.getType(), this._selectedObjectForTapType);
			this._addRobot(pos, robot);
			this._onAddRobot(robot);

			this._setActiveRobot(robot);
		}
		else if (this._tapType === EditorTapsType.AddObject)
		{
			if (this._map.element(pos).getObjectCount() !== 0)
				return;

			if (!pm.moduleUtils.canNotDragOrAddObject(this._map.getType(), this._map, pos))
				this._addObject(pos, pm.moduleUtils.generateObject(this._map.getType(), this._selectedObjectForTapType));
		}
		else if (this._tapType === EditorTapsType.AddClone)
		{
			if (this._map.element(pos).getRobotsCount() !== 0)
				return;

			var robot = pm.moduleUtils.generateRobot(this._map.getType(), this._activeRobot.getType());
			this._addClone(pos, robot);
			this._onAddRobot(robot);

			this._setActiveRobot(robot);
		}
	},

	_addObject: function (pos, object)
	{
		object.id = this._map.objects.length+1;
		object.startPosition = pos;
		object.parentMap = this._map;
		object.setPosition(object.startPosition);
		object.generateSprite(false);
		object.sprite.setOrientation(this._orientation);

		this._map.objects.push(object);
		object.sprite.mapLayer = this._map.mapLayer;
		this.addObject(object.sprite);
		object.sprite.setRealPosition(object.startPosition);

		if (this._orientation === pm.MapLayer2D.Orientation.Iso)
			this.objectLayer.updateOrderGraph();
	},

	_addRobot: function (pos, robot)
	{
		var groupID = 0;
		var isFound = false;
		var indexes = [];
		var robots = this._map.parentLevel.robots;

		for (var i = 0; i < robots.length; ++i)
			indexes[i] = robots[i].groupID;

		indexes.sort();

		for (var i = 1; i < indexes.length; ++i)
		{
			if (indexes[i] - indexes[i-1] > 1)
			{
				groupID = indexes[i-1] + 1;
				isFound = true;
				break;
			}
		}

		if (robots.length > 0 && !isFound)
			groupID = indexes[indexes.length-1] + 1;

		robot.childRobot = null;
		robot.childRobotID = -1;
		robot.parentLevel = this._map.parentLevel;

		this._map.parentLevel.robots.push(robot);
		robot.id = this._map.parentLevel.robots.indexOf(robot);

		robot.groupID = groupID;

		robot.parentLevel.programData[robot.groupID] = new pm.data.ProgramInfo();
		robot.parentLevel.programData[robot.groupID].currentIndex = 0;
		robot.parentLevel.programData[robot.groupID].programDataArray[0] = pm.appUtils.generateProgramData();

		robot.startPosition = pos;
		robot.setPosition(robot.startPosition, MapDirection4.Down);

		this._map.element(pos).startForRobot = robot.id;
		this._map.element(pos).startRobotData.direction = robot.direction;

		robot.generateRobotSprite();


		robot.sprite.mapLayer = this._map.mapLayer;
		robot.sprite.setDirection(robot.direction);
		robot.sprite.setOrientation(this._orientation);

		this.addObject(robot.sprite);
		robot.sprite.setRealPosition(robot.position);

		this.drawRobotStartPositionMark(robot.id, robot.position);

		robot.sprite.setSpriteColor(this.getRobotIndex(robot.groupID));
	},

	_addClone: function(pos, robot)
	{
		robot.childRobot = null;
		robot.childRobotID = -1;
		robot.parentLevel = this._map.parentLevel;

		this._map.parentLevel.robots.push(robot);
		robot.id = this._map.parentLevel.robots.indexOf(robot);

		robot.groupID = this._activeRobot.groupID;

		robot.startPosition = pos;
		robot.setPosition(robot.startPosition, this._activeRobot.direction);

		this._map.element(pos).startForRobot = robot.id;
		this._map.element(pos).startRobotData.direction = robot.direction;

		robot.generateRobotSprite();

		robot.sprite.mapLayer = this._map.mapLayer;
		robot.sprite.setDirection(robot.direction);
		robot.sprite.setOrientation(this._orientation);

		this.addObject(robot.sprite);
		robot.sprite.setRealPosition(robot.position);

		this.drawRobotStartPositionMark(robot.id, robot.position);

		robot.sprite.setSpriteColor(this.getRobotIndex(robot.groupID));
	},

	_onAddRobot: function(robot)
	{
		pm.sendCustomEvent(pme.ADD_ROBOT, robot);

		if (this._map.parentLevel.getRobotCount() === 2)
			pm.sendCustomEvent(pme.COOPERATIVE_MODE, true);
	},

	_onRemoveRobot: function(robot)
	{
		pm.sendCustomEvent(pme.REMOVE_ROBOT, robot);

		if (this._map.parentLevel.getRobotCount() === 2)
			pm.sendCustomEvent(pme.COOPERATIVE_MODE, false);
	},

	_resizeHeightRight: function (sgn)
	{
		if (pm.moduleUtils.stopResizingHeight(this._map.getType(), this._map, SquarableMapEditor_resizeSide.Right))
			return;

		if (sgn === -1)
		{
			var robots = this._map.parentLevel.robots;

			var level = this._map.parentLevel;
			var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
			var mapIndex = level.getActiveMapIndex();

			for (var i = 0; i < robots.length; ++i)
			{
				if (robots[i].position.y === 0)
					return;

				if (task && task.data[mapIndex][robots[i].id].y === 0)
					return;
			}

			var objects = this._map.objects;
			for (var i = 0; i < objects.length; ++i)
			{
				if (objects[i].position && objects[i].position.y === 0)
					return;
			}

			if (pm.moduleUtils.stopDecreasingHeight(this._map.getType(), this._map, SquarableMapEditor_resizeSide.Right))
				return;
		}

		if (sgn === -1)
		{
			if (this._map.height === 1)
				return;

			if (this._map.hasWalls())
			{
				var wallsRemoved = false;

				for (var i = 0; i < this._map.width; ++i)
				{
					if (this._map.element(cc.p(i, 0)).walls[MapDirection4.Right])
					{
						this._removeWall(this._map.element(cc.p(i, 0)).wallLeftSprite);
						this._map.element(cc.p(i, 0)).walls[MapDirection4.Right] = false;

						if (this._map.element(cc.p(i + 1, 0)))
							this._map.element(cc.p(i + 1, 0)).walls[MapDirection4.Left] = false;

						wallsRemoved = true;
					}

					if (this._map.element(cc.p(i, 0)).walls[MapDirection4.Down])
					{
						this._removeWall(this._map.element(cc.p(i, 0)).wallUpSprite);
						this._map.element(cc.p(i, 0)).walls[MapDirection4.Down] = false;

						if (this._map.element(cc.p(i, 1)))
							this._map.element(cc.p(i, 1)).walls[MapDirection4.Up] = false;

						wallsRemoved = true;
					}
				}

				if (wallsRemoved && this._orientation === pm.MapLayer2D.Orientation.Iso)
					this.objectLayer.updateOrderGraph();
			}

			for (var j = 0; j < this._map.width; ++j)
				this._deleteMapElement(cc.p(j, 0));

			this._map.mapElements.shift();
			this._map.height--;

			for (var i = 0; i < robots.length; ++i)
			{
				robots[i].position.y--;
				robots[i].setPosition(robots[i].position, robots[i].direction);
				robots[i].updateSprite();

				if (task)
				{
					--task.data[mapIndex][robots[i].id].y;

					if (robots[i] === this._activeRobot)
						this._setMarkPosition(task.data[mapIndex][robots[i].id]);
				}
			}

			var specialTask = level.taskList.tasks[this._map.getType()];
			if (specialTask)
				specialTask.moveDown(mapIndex);

			for (var i = 0; i < objects.length; ++i)
			{
				objects[i].startPosition.y--;
				objects[i].setPosition(objects[i].startPosition);
				objects[i].updateSprite();
			}
		}
		else if (sgn === 1)
		{
			var newLine = [];

			for (var j = 0; j < this._map.width; ++j)

				newLine.push(this.createMapElement());

			this._map.mapElements.unshift(newLine);

			this._map.height++;

			for (var j = 0; j < this._map.width; ++j)
				this._drawMapElement(cc.p(j, 0));

			var robots = this._map.parentLevel.robots;

			var level = this._map.parentLevel;
			var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
			var mapIndex = level.getActiveMapIndex();

			for (var i = 0; i < robots.length; ++i)
			{
				robots[i].position.y++;
				robots[i].setPosition(robots[i].position, robots[i].direction);
				robots[i].sprite.setRealPosition(robots[i].position);

				if (task)
				{
					++task.data[mapIndex][robots[i].id].y;

					if (robots[i] === this._activeRobot)
						this._setMarkPosition(task.data[mapIndex][robots[i].id]);
				}
			}

			var specialTask = level.taskList.tasks[this._map.getType()];
			if (specialTask)
				specialTask.moveUp(mapIndex);

			var objects = this._map.objects;
			for (var i = 0; i < objects.length; ++i)
			{
				objects[i].startPosition.y++;
				objects[i].setPosition(objects[i].startPosition);
				objects[i].sprite.setRealPosition(objects[i].startPosition);
			}
		}

		this._update();
	},

	_resizeHeightLeft: function (sgn)
	{
		if (pm.moduleUtils.stopResizingHeight(this._map.getType(), this._map, SquarableMapEditor_resizeSide.Left))
			return;

		if (sgn === -1)
		{
			var robots = this._map.parentLevel.robots;

			var level = this._map.parentLevel;
			var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
			var mapIndex = level.getActiveMapIndex();

			for (var i = 0; i < robots.length; ++i)
			{
				if (robots[i].position.y + 1 === this._map.height)
					return;

				if (task && task.data[mapIndex][robots[i].id].y + 1 === this._map.height)
					return;
			}

			var objects = this._map.objects;
			for (var i = 0; i < objects.length; ++i)
			{
				if (objects[i].position && objects[i].position.y + 1 === this._map.height)
					return;
			}

			if (pm.moduleUtils.stopDecreasingHeight(this._map.getType(), this._map, SquarableMapEditor_resizeSide.Left))
				return;
		}

		if (sgn === -1)
		{
			var map = this._map;

			if (map.height === 1)
				return;

			if (map.hasWalls())
			{
				var wallsRemoved = false;

				for (var i = 0; i < map.width; ++i)
				{
					if (map.element(cc.p(i, map.height - 1)).walls[MapDirection4.Right])
					{
						this._removeWall(map.element(cc.p(i, map.height - 1)).wallLeftSprite);
						map.element(cc.p(i, map.height - 1)).walls[MapDirection4.Right] = false;

						if (map.element(cc.p(i + 1, map.height - 1)))
							map.element(cc.p(i + 1, map.height - 1)).walls[MapDirection4.Left] = false;

						wallsRemoved = true;
					}

					if (map.element(cc.p(i, map.height - 1)).walls[MapDirection4.Down])
					{
						this._removeWall(map.element(cc.p(i, map.height - 1)).wallUpSprite);
						map.element(cc.p(i, map.height - 1)).walls[MapDirection4.Down] = false;

						wallsRemoved = true;
					}
				}

				if (wallsRemoved && this._orientation === pm.MapLayer2D.Orientation.Iso)
					this.objectLayer.updateOrderGraph();

				if (map.hasWalls())
					this._moveWallsUp();
			}

			this.moveElementsUp();

			for (var j = 0; j < map.width; ++j)
				this._deleteMapElement(cc.p(j, 0));

			map.mapElements.shift();
			map.height--;

			for (var i = 0; i < robots.length; ++i)
			{
				robots[i].updateSprite();
				robots[i].setPosition(robots[i].position, robots[i].direction);

				var newPos = cc.p(robots[i].position.x, robots[i].position.y);
				var oldPos = cc.p(robots[i].position.x, robots[i].position.y - 1);

				if (map.element(oldPos))
				{
					if (this._map.element(oldPos).startForRobot === robots[i].id)
						map.element(oldPos).startForRobot = -1;
					map.element(oldPos).removeRobot(robots[i]);
				}

				map.element(newPos).addRobot(robots[i]);
				map.element(newPos).startForRobot = robots[i].id;
				map.element(newPos).startRobotData.direction = robots[i].direction;

				this._setRobotStartPositionMarkPosition(robots[i].id, newPos);

				if (task)
				{
					var pos = cc.p(task.data[mapIndex][robots[i].id].x, task.data[mapIndex][robots[i].id].y);

					this._removePositionMark(robots[i].id);
					this._drawPositionMark(robots[i].id, pos);

					if (robots[i] === this._activeRobot)
						this._setMarkPosition(robots[i].id, pos);
				}
			}

			for (var i = 0; i < objects.length; ++i)
			{
				objects[i].updateSprite();
				map.element(objects[i].position).addObject(objects[i]);

				var pos = objects[i].position;

				if (map.element(cc.p(pos.x, pos.y - 1)))
					map.element(cc.p(pos.x, pos.y - 1)).removeObject(objects[i]);
			}
		}
		else if (sgn === 1)
		{
			var map = this._map;
			var mapElements = map.mapElements;

			var newLine = [];

			for (var j = 0; j < map.width; ++j)

				newLine.push(this.createMapElement());

			mapElements.unshift(newLine);

			map.height++;

			for (var j = 0; j < map.width; ++j)
				this._drawMapElement(cc.p(j, 0));

			if (map.hasWalls())
				this._moveWallsDown();

			this.moveElementsDown();

			var robots = this._map.parentLevel.robots;

			var level = this._map.parentLevel;
			var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
			var mapIndex = level.getActiveMapIndex();

			for (var i = 0; i < robots.length; ++i)
			{
				robots[i].updateSprite();
				robots[i].setPosition(robots[i].position, robots[i].direction);

				var newPos = cc.p(robots[i].position.x, robots[i].position.y);
				var oldPos = cc.p(robots[i].position.x, robots[i].position.y + 1);

				map.element(oldPos).startForRobot = -1;
				map.element(oldPos).removeRobot(robots[i]);

				map.element(newPos).addRobot(robots[i]);
				map.element(newPos).startForRobot = robots[i].id;
				map.element(newPos).startRobotData.direction = robots[i].direction;

				this._setRobotStartPositionMarkPosition(robots[i].id, newPos);

				if (task)
				{
					var pos = cc.p(task.data[mapIndex][robots[i].id].x, task.data[mapIndex][robots[i].id].y);

					this._removePositionMark(robots[i].id);
					this._drawPositionMark(robots[i].id, pos);

					if (robots[i] === this._activeRobot)
						this._setMarkPosition(robots[i].id, pos);
				}
			}

			var objects = this._map.objects;
			for (var i = 0; i < objects.length; ++i)
			{
				objects[i].updateSprite();
				map.element(objects[i].position).addObject(objects[i]);

				var pos = objects[i].position;

				map.element(cc.p(pos.x, pos.y + 1)).removeObject(objects[i]);
			}
		}

		this._update();
	},

	_moveWallsUp: function ()
	{
		var map = this._map;

		var wallsRemoved = false;

		for (var j = map.height - 2; j >= 0; --j)
		{
			for (var i = 0; i < map.width; ++i)
			{
				if (map.element(cc.p(i, j)).walls[MapDirection4.Right])
				{
					wallsRemoved = true;

					this._removeWall(map.element(cc.p(i, j)).wallLeftSprite);

					map.element(cc.p(i, j + 1)).walls[MapDirection4.Right] = true;
					if (map.element(cc.p(i + 1, j + 1)))
						map.element(cc.p(i + 1, j + 1)).walls[MapDirection4.Left] = true;

					map.element(cc.p(i, j)).walls[MapDirection4.Right] = false;
					if (map.element(cc.p(i + 1, j)))
						map.element(cc.p(i + 1, j)).walls[MapDirection4.Left] = false;

					var type = this._getWallType(MapDirection4.Right);

					this._drawWall(cc.p(i, j + 1), type);
				}

				if (map.element(cc.p(i, j)).walls[MapDirection4.Down])
				{
					wallsRemoved = true;

					this._removeWall(map.element(cc.p(i, j)).wallUpSprite);

					if ((j + 1) !== (map.height - 1))
					{
						map.element(cc.p(i, j + 1)).walls[MapDirection4.Down] = true;
						if ((map.element(cc.p(i, j + 2))) && ((j + 2) !== (map.height - 1)))
							map.element(cc.p(i, j + 2)).walls[MapDirection4.Up] = true;

						var type = this._getWallType(MapDirection4.Down);

						this._drawWall(cc.p(i, j + 1), type);
					}

					map.element(cc.p(i, j)).walls[MapDirection4.Down] = false;
					if (map.element(cc.p(i, j + 1)))
						map.element(cc.p(i, j + 1)).walls[MapDirection4.Up] = false;
				}
			}

			if (wallsRemoved && this._orientation === pm.MapLayer2D.Orientation.Iso)
				this.objectLayer.updateOrderGraph();
		}
	},

	_moveWallsDown: function ()
	{
		var map = this._map;

		var wallsRemoved = false;

		for (var j = 1; j < map.height; ++j)
		{
			for (var i = 0; i < map.width; ++i)
			{
				if (map.element(cc.p(i, j)).walls[MapDirection4.Right])
				{
					wallsRemoved = true;

					this._removeWall(map.element(cc.p(i, j)).wallLeftSprite);

					map.element(cc.p(i, j - 1)).walls[MapDirection4.Right] = true;
					if (map.element(cc.p(i + 1, j - 1)))
						map.element(cc.p(i + 1, j - 1)).walls[MapDirection4.Left] = true;

					map.element(cc.p(i, j)).walls[MapDirection4.Right] = false;
					if (map.element(cc.p(i + 1, j)))
						map.element(cc.p(i + 1, j)).walls[MapDirection4.Left] = false;

					var type = this._getWallType(MapDirection4.Right);

					this._drawWall(cc.p(i, j - 1), type);
				}

				if (map.element(cc.p(i, j)).walls[MapDirection4.Down])
				{
					wallsRemoved = true;

					this._removeWall(map.element(cc.p(i, j)).wallUpSprite);

					map.element(cc.p(i, j - 1)).walls[MapDirection4.Down] = true;
					if (map.element(cc.p(i, j)))
						map.element(cc.p(i, j)).walls[MapDirection4.Up] = true;

					map.element(cc.p(i, j)).walls[MapDirection4.Down] = false;
					if (map.element(cc.p(i, j + 1)))
						map.element(cc.p(i, j + 1)).walls[MapDirection4.Up] = false;

					var type = this._getWallType(MapDirection4.Down);

					this._drawWall(cc.p(i, j - 1), type);
				}
			}

			if (wallsRemoved && this._orientation === pm.MapLayer2D.Orientation.Iso)
				this.objectLayer.updateOrderGraph();
		}
	},

	_moveWallsRight: function ()
	{
		var map = this._map;

		var wallsRemoved = false;

		for (var i = 1; i < map.width; ++i)
		{
			for (var j = 0; j < map.height; ++j)
			{
				if (map.element(cc.p(i, j)).walls[MapDirection4.Right])
				{
					wallsRemoved = true;

					this._removeWall(map.element(cc.p(i, j)).wallLeftSprite);

					map.element(cc.p(i - 1, j)).walls[MapDirection4.Right] = true;
					map.element(cc.p(i, j)).walls[MapDirection4.Left] = true;

					map.element(cc.p(i, j)).walls[MapDirection4.Right] = false;
					if (map.element(cc.p(i + 1, j)))
						map.element(cc.p(i + 1, j)).walls[MapDirection4.Left] = false;

					var type = this._getWallType(MapDirection4.Right);

					this._drawWall(cc.p(i - 1, j), type);
				}

				if (map.element(cc.p(i, j)).walls[MapDirection4.Down])
				{
					wallsRemoved = true;

					this._removeWall(map.element(cc.p(i, j)).wallUpSprite);

					map.element(cc.p(i - 1, j)).walls[MapDirection4.Down] = true;
					if (map.element(cc.p(i - 1, j + 1)))
						map.element(cc.p(i - 1, j + 1)).walls[MapDirection4.Up] = true;

					var type = this._getWallType(MapDirection4.Down);

					this._drawWall(cc.p(i - 1, j), type);

					map.element(cc.p(i, j)).walls[MapDirection4.Down] = false;
					if (map.element(cc.p(i, j + 1)))
						map.element(cc.p(i, j + 1)).walls[MapDirection4.Up] = false;
				}
			}

			if (wallsRemoved && this._orientation === pm.MapLayer2D.Orientation.Iso)
				this.objectLayer.updateOrderGraph();
		}
	},

	_moveWallsLeft: function ()
	{
		var map = this._map;

		var wallsRemoved = false;

		for (var i = map.width - 2; i >= 0; --i)
		{
			for (var j = 0; j < map.height; ++j)
			{
				if (map.element(cc.p(i, j)).walls[MapDirection4.Right])
				{
					wallsRemoved = true;

					this._removeWall(map.element(cc.p(i, j)).wallLeftSprite);

					map.element(cc.p(i + 1, j)).walls[MapDirection4.Right] = true;
					if (map.element(cc.p(i + 2, j)))
						map.element(cc.p(i + 2, j)).walls[MapDirection4.Left] = true;

					var type = this._getWallType(MapDirection4.Right);

					this._drawWall(cc.p(i + 1, j), type);

					map.element(cc.p(i, j)).walls[MapDirection4.Right] = false;
					if (map.element(cc.p(i + 1, j)))
						map.element(cc.p(i + 1, j)).walls[MapDirection4.Left] = false;
				}

				if (map.element(cc.p(i, j)).walls[MapDirection4.Down])
				{
					wallsRemoved = true;

					this._removeWall(map.element(cc.p(i, j)).wallUpSprite);

					map.element(cc.p(i + 1, j)).walls[MapDirection4.Down] = true;
					if (map.element(cc.p(i + 1, j + 1)))
						map.element(cc.p(i + 1, j + 1)).walls[MapDirection4.Up] = true;

					map.element(cc.p(i, j)).walls[MapDirection4.Down] = false;
					map.element(cc.p(i, j + 1)).walls[MapDirection4.Up] = false;

					var type = this._getWallType(MapDirection4.Down);

					this._drawWall(cc.p(i + 1, j), type);
				}
			}

			if (wallsRemoved && this._orientation === pm.MapLayer2D.Orientation.Iso)
				this.objectLayer.updateOrderGraph();
		}
	},

	createMapElement: function ()
	{
	},

	_drawMapElement: function (pos)
	{
		var sprite = this._map.element(pos).generateTerrainSprite();
		sprite.getSprite().setPosition(this.realPosition(pos, cc.p(0, 0)));

		this.addTerrainObject(sprite, 0);
	},

	_deleteMapElement: function (pos)
	{
		var sprite = this._map.element(pos).sprite;
		this.removeTerrainObject(sprite);
	},

	_resizeWidthRight: function (sgn)
	{
		if (sgn === -1)
		{
			var robots = this._map.parentLevel.robots;

			var level = this._map.parentLevel;
			var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
			var mapIndex = level.getActiveMapIndex();

			for (var i = 0; i < robots.length; ++i)
			{
				if (robots[i].position.x + 1 === this._map.width)
					return;

				if (task && task.data[mapIndex][robots[i].id].x + 1 === this._map.width)
					return;
			}

			var objects = this._map.objects;
			for (var i = 0; i < objects.length; ++i)
			{
				if (objects[i].position && objects[i].position.x + 1 === this._map.width)
					return;
			}

			if (pm.moduleUtils.stopResizingWidth(this._map.getType(), this._map, SquarableMapEditor_resizeSide.Right))
				return;
		}

		if (sgn === -1)
		{
			var map = this._map;

			if (map.width === 1)
				return;

			if (map.hasWalls())
			{
				var wallsRemoved = false;

				for (var i = 0; i < map.height; ++i)
				{

					if (map.element(cc.p(map.width - 1, i)).walls[MapDirection4.Right])
					{
						this._removeWall(map.element(cc.p(map.width - 1, i)).wallLeftSprite);
						map.element(cc.p(map.width - 1, i)).walls[MapDirection4.Right] = false;

						wallsRemoved = true;
					}

					if (map.element(cc.p(map.width - 2, i)).walls[MapDirection4.Right])
					{
						this._removeWall(map.element(cc.p(map.width - 2, i)).wallLeftSprite);
						map.element(cc.p(map.width - 2, i)).walls[MapDirection4.Right] = false;

						if (map.element(cc.p(map.width - 1, i)))
							map.element(cc.p(map.width - 1, i)).walls[MapDirection4.Left] = false;

						wallsRemoved = true;
					}

					if (map.element(cc.p(map.width - 1, i)).walls[MapDirection4.Down])
					{
						this._removeWall(map.element(cc.p(map.width - 1, i)).wallUpSprite);
						map.element(cc.p(map.width - 1, i)).walls[MapDirection4.Down] = false;

						if (map.element(cc.p(map.width - 1, i + 1)))
							map.element(cc.p(map.width - 1, i + 1)).walls[MapDirection4.Up] = false;

						wallsRemoved = true;
					}
				}

				if (wallsRemoved && this._orientation === pm.MapLayer2D.Orientation.Iso)
					this.objectLayer.updateOrderGraph();
			}

			var mapElements = map.mapElements;

			for (var j = 0; j < map.height; ++j)
			{
				this._deleteMapElement(cc.p(map.width - 1, j));
				mapElements[j].pop();
			}

			map.width--;
		}
		else if (sgn === 1)
		{
			var map = this._map;
			var mapElements = map.mapElements;

			map.width++;

			for (var j = 0; j < map.height; ++j)
			{
				var el = this.createMapElement();
				mapElements[j].push(el);

				this._drawMapElement(cc.p(map.width - 1, j));
			}
		}

		this._update();
	},

	_resizeWidthLeft: function (sgn)
	{
		if (sgn === -1)
		{
			var robots = this._map.parentLevel.robots;

			var level = this._map.parentLevel;
			var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
			var mapIndex = level.getActiveMapIndex();

			for (var i = 0; i < robots.length; ++i)
			{
				if (robots[i].position.x === 0)
					return;

				if (task && task.data[mapIndex][robots[i].id].x === 0)
					return;
			}

			var objects = this._map.objects;
			for (var i = 0; i < objects.length; ++i)
			{
				if (objects[i].position && objects[i].position.x === 0)
					return;
			}

			if (pm.moduleUtils.stopResizingWidth(this._map.getType(), this._map, SquarableMapEditor_resizeSide.Left))
				return;
		}

		if (sgn === -1)
		{
			var map = this._map;

			if (map.width === 1)
				return;

			if (map.hasWalls())
			{
				var wallsRemoved = false;

				for (var i = 0; i < map.height; ++i)
				{
					if (map.element(cc.p(0, i)).walls[MapDirection4.Right])
					{
						this._removeWall(map.element(cc.p(0, i)).wallLeftSprite);
						map.element(cc.p(0, i)).walls[MapDirection4.Right] = false;

						map.element(cc.p(1, i)).walls[MapDirection4.Left] = false;

						wallsRemoved = true;
					}

					if (map.element(cc.p(0, i)).walls[MapDirection4.Down])
					{
						this._removeWall(map.element(cc.p(0, i)).wallUpSprite);
						map.element(cc.p(0, i)).walls[MapDirection4.Down] = false;

						map.element(cc.p(0, i + 1)).walls[MapDirection4.Up] = false;

						wallsRemoved = true;
					}
				}

				if (wallsRemoved && this._orientation === pm.MapLayer2D.Orientation.Iso)
					this.objectLayer.updateOrderGraph();

				if (map.hasWalls())
					this._moveWallsRight();
			}

			this.moveElementsRight();

			for (var j = 0; j < map.height; ++j)
			{
				this._deleteMapElement(cc.p(map.width - 1, j));
				map.mapElements[j].pop();
			}

			map.width--;

			for (var i = 0; i < robots.length; ++i)
			{
				robots[i].position.x--;
				robots[i].setPosition(robots[i].position, robots[i].direction);
				robots[i].updateSprite();

				var newPos = cc.p(robots[i].position.x, robots[i].position.y);
				var oldPos = cc.p(robots[i].position.x + 1, robots[i].position.y);

				if (map.element(oldPos))
				{
					if (this._map.element(oldPos).startForRobot === robots[i].id)
						map.element(oldPos).startForRobot = -1;
					map.element(oldPos).removeRobot(robots[i]);
				}

				map.element(newPos).addRobot(robots[i]);
				map.element(newPos).startForRobot = robots[i].id;
				map.element(newPos).startRobotData.direction = robots[i].direction;

				this._setRobotStartPositionMarkPosition(robots[i].id, newPos);

				if (task)
				{
					--task.data[mapIndex][robots[i].id].x;

					var pos = cc.p(task.data[mapIndex][robots[i].id].x, task.data[mapIndex][robots[i].id].y);

					this._removePositionMark(robots[i].id);
					this._drawPositionMark(robots[i].id, pos);

					if (robots[i] === this._activeRobot)
						this._setMarkPosition(robots[i].id, pos);
				}
			}

			var specialTask = level.taskList.tasks[this._map.getType()];
			if (specialTask)
				specialTask.moveRight(mapIndex);

			for (var i = 0; i < objects.length; ++i)
			{
				objects[i].position.x--;
				objects[i].updateSprite();
				map.element(objects[i].position).addObject(objects[i]);

				var pos = objects[i].position;

				if (map.element(cc.p(pos.x + 1, pos.y)))
					map.element(cc.p(pos.x + 1, pos.y)).removeObject(objects[i]);
			}
		}
		else if (sgn === 1)
		{
			var map = this._map;
			var mapElements = map.mapElements;

			map.width++;

			for (var j = 0; j < map.height; ++j)
			{
				var el = this.createMapElement();
				mapElements[j].push(el);

				this._drawMapElement(cc.p(map.width - 1, j));
			}

			if (map.hasWalls())
				this._moveWallsLeft();

			this.moveElementsLeft();

			var robots = this._map.parentLevel.robots;

			var level = this._map.parentLevel;
			var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
			var mapIndex = level.getActiveMapIndex();

			for (var i = 0; i < robots.length; ++i)
			{
				robots[i].position.x++;
				robots[i].setPosition(robots[i].position, robots[i].direction);
				robots[i].updateSprite();

				var newPos = cc.p(robots[i].position.x, robots[i].position.y);
				var oldPos = cc.p(robots[i].position.x - 1, robots[i].position.y);

				if (this._map.element(oldPos).startForRobot === robots[i].id)
					map.element(oldPos).startForRobot = -1;
				map.element(oldPos).removeRobot(robots[i]);

				map.element(newPos).addRobot(robots[i]);
				map.element(newPos).startForRobot = robots[i].id;
				map.element(newPos).startRobotData.direction = robots[i].direction;

				this._setRobotStartPositionMarkPosition(robots[i].id, newPos);

				if (task)
				{
					++task.data[mapIndex][robots[i].id].x;

					var pos = cc.p(task.data[mapIndex][robots[i].id].x, task.data[mapIndex][robots[i].id].y);

					this._removePositionMark(robots[i].id);
					this._drawPositionMark(robots[i].id, pos);

					if (robots[i] === this._activeRobot)
						this._setMarkPosition(robots[i].id, pos);
				}
			}

			var specialTask = level.taskList.tasks[this._map.getType()];
			if (specialTask)
				specialTask.moveLeft(mapIndex);

			var objects = this._map.objects;
			for (var i = 0; i < objects.length; ++i)
			{
				objects[i].position.x++;
				objects[i].updateSprite();

				var pos = objects[i].position;
				map.element(cc.p(pos.x - 1, pos.y)).removeObject(objects[i]);

				map.element(pos).addObject(objects[i]);
			}
		}

		this._update();
	},

	/**
     * Draw map on layer.
     */
	drawMap: function(previewDraw)
	{
		SquarableMapLayer.prototype.drawMap.call(this, previewDraw);

		if (!previewDraw)
		{
			var listener = cc.EventListener.create({
				event: cc.EventListener.TOUCH_ONE_BY_ONE,
				swallowTouches: true,
				onTouchBegan: this._touchBegan.bind(this),
				onTouchMoved: this._touchMoved.bind(this),
				onTouchEnded: this._touchEnded.bind(this)
			});

			cc.eventManager.addListener(listener, this);

			this._resizeSprite = pm.spriteUtils.getInterfaceElementSprite("unchecked");
			this.terrainLayer.addChild(this._resizeSprite, 1);
			var mapSize = this.terrainLayer.getContentSize();
			this._resizeSprite.setPosition(mapSize.width - this.getMapElementSize().width / 2,
				mapSize.height + this.getMapElementSize().height / 2);

			this._resizeLeftSprite = pm.spriteUtils.getInterfaceElementSprite("unchecked");
			this.terrainLayer.addChild(this._resizeLeftSprite, 1);
			this._resizeLeftSprite.setPosition(- this.getMapElementSize().width / 2,
				this.getMapElementSize().height / 2);
		}

	},

	/**
     * Update size and position of container.
     */
	_update: function()
	{
		this.resizeContainer();

		var mapSize = this.terrainLayer.getContentSize();

		this._resizeSprite.setPosition(mapSize.width - this.getMapElementSize().width / 2,
			mapSize.height + this.getMapElementSize().height / 2);

		this._resizeLeftSprite.setPosition(- this.getMapElementSize().width / 2,
			this.getMapElementSize().height / 2);
	},

	/**
     * Update size of container.
     */
	resizeContainer: function()
	{
		this.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.terrainLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.objectLayer.setContentSize(this.getRealWidth(), this.getRealHeight());

		if (this._orientation === pm.MapLayer2D.Orientation.Iso)
		{
			this.depthLayer.removeAllChildren();
			this.depthLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
			this.container.setContentSize(this.getRealWidth(), this.getRealHeight());

			var rect = this.terrainLayer.getBoundingBox();
			this.setContentSize(rect.width, rect.height / 2);

			var elementRect = cc.rect(0, 0, this.getMapElementSize().width, this.getMapElementSize().height);
			elementRect = cc.rectApplyAffineTransform(elementRect, this.terrainLayer.getNodeToParentTransform());

			this._containerShift = cc.p((this.width - this.getRealWidth()) / 2,
				(this.height - this.getRealHeight()) / 2 - elementRect.height / 4);

			this.container.setPosition(this._containerShift);
			this.depthLayer.setPosition(this._containerShift.x, this._containerShift.y + elementRect.height / 4 + 1);

			this._drawDepthLines();
		}
	}
};

