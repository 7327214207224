/**
 * Created by Nikita Besshaposhnikov on 09.02.15.
 */
/**
 * @class This robot can store some value, inc && dec && clear this value.</br>
 * Provides own conditions, repeaters and methods.
 * @implements pm.GlobalRobot
 */
pm.Output = pm.GlobalRobot.extend(/** @lends pm.Output# */{
	typeName: "Output",

	_value: 0,

	ctor: function()
	{
		this._super();

		this.nativeFunctionMap[pm.Output.NativeMethod.Write] = new pm.NativeFunction(this, this._write, 1);
		this.nativeFunctions.push(pm.Output.NativeMethod.Write);
		},

	_write: function(number)
	{
		this._value = number;
		this.playAnimation(pm.CounterSprite.Animation.IncValue, this._endPlayingAnimation, number);
	},

	getValue: function()
	{
		return this._value;
	},

	reset: function()
	{
		this._value = 0;
	},

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new pm.CounterSprite();

			return this.sprite;
		}
	},

	_checkConditionInternal: function(condition, args)
	{
		switch(condition)
		{
			case pm.Counter.Condition.Empty: return this._counter === 0;
			case pm.Counter.Condition.NotEmpty: return this._counter !== 0;
		}

		return false;
	},

	_getRepeaterValueInternal: function(repeater, args)
	{
		if(repeater === pm.Counter.Repeater.Value)
			return this._counter;

		return 0;
	},

	updateSprite: function()
	{
		this.playAnimation(pm.CounterSprite.Animation.Set, null, this._counter, true);
		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	getType: function() { return pm.GlobalRobotType.Output; },

	getState: function ()
	{
		return this._counter;
	},

	setState: function (state)
	{
		if (state !== this._counter)
		{
			if (state === this._counter - 1)
			{
				this._dec();
			}
			else if (state === this._counter + 1)
			{
				this._inc();
			}
			else if (state === 0)
			{
				this._counter = 0;
				this.playAnimation(pm.CounterSprite.Animation.Empty, this._endPlayingAnimation);
			}
			else
			{
				this._counter = state;
				this.playAnimation(pm.CounterSprite.Animation.IncValue, this._endPlayingAnimation, this._counter);
			}
		}
	},

	_endPlayingAnimation: function()
	{
		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_setStonesByValue: function(value)
	{
		if (value < 1)
		{
			this._stonesSprite.setOpacity(0);
			return;
		}
		else
		{ this._stonesSprite.setOpacity(255); }

		if(value === 1)
			this._stonesSprite.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame("counter_stones0.png"));
		else if (value === 2)
			this._stonesSprite.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame("counter_stones1.png"));
		else if (value > 2)
			this._stonesSprite.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame("counter_stones2.png"));
	},

	setNotCompleted: function(flag){

	}
});

pm.Output.NativeMethod =
{
	Write: "output_write"
};
