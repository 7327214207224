/**
 * Created by Kirill Mashchenko on 07.07.2018.
 */

/**
 * @class Functions for TrainLevel module.
 * @interface
 * @extends pm.ModuleInfo
 */

pm.TrainLevelModule = pm.ModuleInfo.extend(/** @lends pm.TrainLevelModule# */{

	name: "Train",

	getType: function()
	{
		return pm.TrainLevelModule.Type;
	},

	getBackgroundParameters: function ()
	{
		return{
			color: cc.color(102, 0, 255),
			drawStars: true,
			scaleFactor: 4,
			centerImage: "Background/Levels/push_center.png"
		};
	},

	generateEmptyMap: function (level)
	{
		var map = new pm.data.TrainMap();

		map.parentLevel = level;

		var sqrtCount = Math.max(3, Math.floor(Math.sqrt(level.robots.length))+1);

		map.setSize(sqrtCount, sqrtCount);

		for(var x = 0; x < sqrtCount; ++x)
		{
			for(var y = 0; y < sqrtCount; ++y)
				map.element(cc.p(x, y)).originalType = TrainMapElementType.Grass;
		}

		map.element(cc.p(1, 1)).startForRobot = level.robots[0].id;
		map.element(cc.p(1, 1)).startRobotData.direction = 0;

		var x = 0;
		var y = 0;

		for (var i = 1; i < level.robots.length; ++i)
		{
			map.element(cc.p(x, y)).startForRobot = level.robots[i].id;
			map.element(cc.p(x, y)).startRobotData.direction = 0;

			++x;

			if (x === 1 && y === 1)
				++x;

			if (x >= sqrtCount)
			{
				++y;
				x = 0;
			}
		}

		return map;
	},

	generateEmptyLevel: function ()
	{
		var level = new pm.data.TrainLevel();

		level.name = "Паровозик";
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";
		level.robots.push(new pm.data.TrainRobot());

		this._fillLevelData(level);

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();
		level.taskList.tasks[pm.TrainLevelModule.Type] = new pm.data.TrainTask(level);

		return level;
	},

	getRobotSpriteSheet: function (robotType)
	{
		switch (robotType)
		{
			case pm.TrainLevelModule.RobotTypes.TrainRobot:
				return "Robot/train_robot.plist";
		}
	},

	getObjectsInfo: function()
	{
		var ret = {};
		ret[pm.TrainLevelModule.ObjectTypes.TrainCar] = "Objects/GreenCar.png";
		ret[pm.TrainLevelModule.ObjectTypes.TrainBarrel] = "Objects/GreenBarrel.png";
		return ret;
	},

	getRobotsInfo: function ()
	{
		var ret = {};
		ret[pm.TrainLevelModule.RobotTypes.TrainRobot] = "train_robot0.png";
		return ret;
	},

	getSettings: function (robotType, object)
	{
		return new TrainRobotSettingsLayer(object);
	},

	getMapElementsType: function()
	{
		return TrainMapElementType;
	},

	getRobotDisplayedName: function (robotType)
	{
		switch (robotType)
		{
			case pm.TrainLevelModule.RobotTypes.TrainRobot:
				return LocalizedString("Train");
		}
	},

	getDisplayedNames: function()
	{
		return LocalizedString(this.name);
	},

	getMapTileset: function (tileset)
	{
		return "MapsPics/push_level-tileset{0}.plist".format(tileset);
	},

	getResources: function ()
	{
		return[
			"Robot Methods/robot4-methods.plist",
			"Conditions/robot4-conditions.plist",
			"Conditions/push_robot-conditions.plist",
			"Robot Methods/pull_robot-methods.plist",
			"Robot Methods/train_robot-methods.plist",
			"Conditions/train_robot-conditions.plist"
		];
	},

	generateObject: function (objectType)
	{
		switch (objectType)
		{
			case pm.TrainLevelModule.ObjectTypes.TrainCar:
				return new pm.data.TrainCar();
			case pm.TrainLevelModule.ObjectTypes.TrainBarrel:
				return new pm.data.TrainBarrel();
		}
	},

	generateRobot: function (robotType)
	{
		switch (robotType)
		{
			case pm.TrainLevelModule.RobotTypes.TrainRobot:
				return new pm.data.TrainRobot();
		}
	},

	canNotDragOrAddObject: function (map, point)
	{
		return false;
	},

	getLocalization: function(language)
	{
		switch (language)
		{
			case cc.sys.LANGUAGE_ENGLISH:
				return {
					"Train": "Train",
					"TrainLevelType": "Train level",
					"TrainTask": "Carry objects to targets",
					"train_link": "link",
					"train_unlink": "unlink",
					"train_link_all": "link all",
					"train_unlink_all": "unlink all",
					"train_can_link": "can link",
					"train_can_not_link": "can not link"
				};
			case cc.sys.LANGUAGE_RUSSIAN:
				return {
					"Train": "Паровозик",
					"TrainLevelType": "Паровозик",
					"TrainTask": "Развезти предметы",
					"train_link": "примагнитить",
					"train_unlink": "отмагнитить",
					"train_link_all": "примагнитить все",
					"train_unlink_all": "отмагнитить все",
					"train_can_link": "можно примагнитить",
					"train_can_not_link": "нельзя пригмагнитить"
				};
		}
	},

	getResourcesPlist: function ()
	{
		return "PlayerRobots/TrainLevel.plist";
	},

	generateTaskLayer: function (level, task)
	{
		return new TrainTaskLayer(level, task);
	},

	getTaskLabel: function ()
	{
		return "TrainTask";
	},

	addLevelTask: function (level)
	{
		var task = new pm.data.TrainTask(level);
		level.taskList.addTask(pm.TrainLevelModule.Type, task);

		return task;
	},

	addExtraMethods: function(robot, functions) {},

	getDepthLineSprites: function()
	{
		return {
			horizontal: pm.spriteUtils.getMapTileFrame("push-depth_horizontal"),
			vertical: pm.spriteUtils.getMapTileFrame("push-depth_vertical")
		};
	},

	getChangeableConditions: function(robot, callback, target) {},

	compareConditions: function() {},

	getLessonMapLayer: function()
	{
		return null;
	}
});

pm.TrainLevelModule.RobotTypes = {TrainRobot: "train-robot"};
pm.TrainLevelModule.Type = "train";
pm.TrainLevelModule.ObjectTypes = {TrainCar: "TrainCar", TrainBarrel: "TrainBarrel"};
