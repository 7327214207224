/**
 * Created by Kirill Mashchenko on 30.10.18.
 */

/**
 * @class Layout for Light Lesson page.
 * @extends CommonLessonPage
 */

var LightLessonPage = CommonLessonPage.extend(/** @lends LightLessonPage# */{

	ctor: function(target, cancelCallback)
	{
		this._super(target, cancelCallback);

		this._loadExtraInterface();
	},

	_loadExtraInterface: function()
	{
		pm.spriteUtils.addTempSpriteFrames(pm.moduleUtils.getRobotSpriteSheet(pm.LightLevelModule.Type, pm.LightLevelModule.RobotType));

		var lightSprite = new ccui.ImageView("light_robot4.png", ccui.Widget.PLIST_TEXTURE);

		lightSprite.setAnchorPoint(cc.p(0, 1));

		lightSprite.setScale(2);

		var lightAlign = new ccui.RelativeLayoutParameter();
		lightAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		lightAlign.setMargin(3 * CommonLessonPage.SEPARATOR, -CommonLessonPage.SEPARATOR, 0, 0);
		lightAlign.setRelativeName("lightSprite");
		lightSprite.setLayoutParameter(lightAlign);

		this._container.addChild(lightSprite);

		var lightLabel = new ccui.Text(LocalizedString("Light"), pm.settings.fontName, 30);

		var lightLabelAlign = new ccui.RelativeLayoutParameter();
		lightLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		lightLabelAlign.setMargin(lightSprite.width - lightLabel.width / 2, (cc.sys.isNative) ? 0 : lightSprite.height, 0, 0);
		lightLabelAlign.setRelativeName("lightLabel");
		lightLabelAlign.setRelativeToWidgetName("lightSprite");
		lightLabel.setLayoutParameter(lightLabelAlign);

		this._container.addChild(lightLabel);

		var description = "Между полетами космонавты отдыхают в садах. За этими садами ухаживает Зажигун. Проходя по дорожкам сада, он зажигает и тушит фонари";
		var commonTextLabel = new ccui.Text(description, pm.settings.fontName, 25);
		commonTextLabel.setTextAreaSize(cc.size(this._container.width - 6 * CommonLessonPage.SEPARATOR - 2 * lightSprite.width, 0));

		var commonTextAlign = new ccui.RelativeLayoutParameter();
		commonTextAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		commonTextAlign.setMargin(cc.sys.isNative ? 2 * CommonLessonPage.SEPARATOR : lightSprite.width + 2 * CommonLessonPage.SEPARATOR, 7 * CommonLessonPage.SEPARATOR, 0, 0);
		commonTextAlign.setRelativeName("description");
		commonTextAlign.setRelativeToWidgetName("lightSprite");
		commonTextLabel.setLayoutParameter(commonTextAlign);

		this._container.addChild(commonTextLabel);

		pm.spriteUtils.addTempSpriteFrames(pm.moduleUtils.getMapTileset(pm.LightLevelModule.Type, 0));

		var tableInfo = new ccui.HBox();

		var tableAlign = new ccui.RelativeLayoutParameter();
		tableAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);

		var margin = Math.max(2 * lightSprite.height + lightLabel.height - CommonLessonPage.SEPARATOR, commonTextLabel.height + 7 * CommonLessonPage.SEPARATOR);

		tableAlign.setMargin(0, margin, 0, 0);
		tableInfo.setLayoutParameter(tableAlign);

		var height = 0;

		var finishBox = new ccui.VBox();

		var finishLabel = new ccui.Text("Элементы поля", pm.settings.fontName, 30);

		var finishLabelAlign = new ccui.LinearLayoutParameter();
		finishLabelAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
		finishLabelAlign.setMargin(0, CommonLessonPage.SEPARATOR, 0, 0);
		finishLabel.setLayoutParameter(finishLabelAlign);

		finishBox.addChild(finishLabel);

		for (var i = 1; i <= 2; ++i)
		{
			var finishContainer = new ccui.HBox();

			var containerMargin = new ccui.LinearLayoutParameter();
			containerMargin.setMargin(CommonLessonPage.TABLE_MARGIN, CommonLessonPage.SEPARATOR, 0, 0);
			finishContainer.setLayoutParameter(containerMargin);

			finishBox.addChild(finishContainer);

			var image1 = null;

			if (i === 1)
			{
				var image = new ccui.ImageView("mapElement2.png".format(i), ccui.Widget.PLIST_TEXTURE);
			}
			else if (i === 2)
			{
				var image = new ccui.ImageView("targetElement.png", ccui.Widget.PLIST_TEXTURE);
				image1 = new ccui.ImageView("mapElement1.png", ccui.Widget.PLIST_TEXTURE);
			}

			image.setAnchorPoint(cc.p(0.5, -0.5));
			image.setScale(CommonLessonPage.CELL_HEIGHT / image.width);

			var imageContainer = new ccui.Layout();
			imageContainer.setContentSize(image.getContentSize().width * Math.sqrt(2), image.getContentSize().height * Math.sqrt(2));
			imageContainer.setScaleY(0.5);
			image.setRotation(45);
			imageContainer.addChild(image, 0);

			if(image1)
			{
				image1.setAnchorPoint(cc.p(0.6, -0.6));
				image1.setScale(CommonLessonPage.CELL_HEIGHT / image1.width);
				image1.setRotation(45);
				imageContainer.addChild(image1, -1);
			}

			var imageAlign = new ccui.LinearLayoutParameter();
			imageAlign.setGravity(ccui.LinearLayoutParameter.LEFT);

			var shift = cc.sys.isNative ? 14 : -imageContainer.height/2 + 14;
			imageAlign.setMargin(-30, shift, 0, 0);
			imageContainer.setLayoutParameter(imageAlign);

			finishContainer.addChild(imageContainer);

			var label = new ccui.Text(this._getObjectDescription(i), pm.settings.fontName, 25);

			var labelMargin = new ccui.LinearLayoutParameter();
			labelMargin.setGravity(ccui.LinearLayoutParameter.LEFT);
			var margin = 2 * CommonLessonPage.SEPARATOR;
			labelMargin.setMargin(margin, CommonLessonPage.CELL_HEIGHT / 2 - label.height/2, 0, 0);
			label.setLayoutParameter(labelMargin);

			finishContainer.addChild(label);

			finishContainer.setContentSize(image.width + CommonLessonPage.SEPARATOR + label.width, CommonLessonPage.CELL_HEIGHT);

			height += CommonLessonPage.SEPARATOR + finishContainer.height;
		}

		finishBox.setContentSize(this._container.width / 2, height);

		height = 0;

		var commandBox = new ccui.VBox();

		var commandLabel = new ccui.Text("Команды", pm.settings.fontName, 30);

		var commandLabelAlign = new ccui.LinearLayoutParameter();
		commandLabelAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
		commandLabelAlign.setMargin(0, CommonLessonPage.SEPARATOR, 0, 0);
		commandLabel.setLayoutParameter(commandLabelAlign);

		commandBox.addChild(commandLabel);

		for (var i = 0; i < 4; ++i)
		{
			var commandContainer = new ccui.HBox();

			var containerMargin = new ccui.LinearLayoutParameter();
			containerMargin.setMargin(CommonLessonPage.TABLE_MARGIN, CommonLessonPage.SEPARATOR, 0, 0);
			commandContainer.setLayoutParameter(containerMargin);

			commandBox.addChild(commandContainer);

			var name;

			pm.spriteUtils.initRobotTextures(pm.LightLevelModule.Type);

			switch(i)
			{
				case 0:
					name = "light_turn_left-normal.png";
					break;
				case 1:
					name = "light_turn_right-normal.png";
					break;
				case 2:
					name = "light_turn_on-normal.png";
					break;
				case 3:
					name = "light_turn_off-normal.png";
					break;
			}

			var image = new ccui.ImageView(name, ccui.Widget.PLIST_TEXTURE);

			var imageAlign = new ccui.LinearLayoutParameter();
			imageAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
			image.setLayoutParameter(imageAlign);

			image.setAnchorPoint(cc.p(0, 1));
			image.setScale(CommonLessonPage.CELL_HEIGHT / image.height);
			commandContainer.addChild(image);

			var label = new ccui.Text(this._getCommandDescription(i), pm.settings.fontName, 25);

			var labelMargin = new ccui.LinearLayoutParameter();
			labelMargin.setGravity(ccui.LinearLayoutParameter.LEFT);
			var margin = (cc.sys.isNative) ? 2 * CommonLessonPage.SEPARATOR : 2 * CommonLessonPage.SEPARATOR + 40;
			labelMargin.setMargin(margin, CommonLessonPage.CELL_HEIGHT / 2 - label.height/2, 0, 0);
			label.setLayoutParameter(labelMargin);

			commandContainer.addChild(label);

			commandContainer.setContentSize(image.width + CommonLessonPage.SEPARATOR + label.width, CommonLessonPage.CELL_HEIGHT);

			height += CommonLessonPage.SEPARATOR + commandContainer.height;
		}

		commandBox.setContentSize(this._container.width / 2, height);

		tableInfo.setContentSize(this._container.width, Math.max(finishBox.height, commandBox.height));

		tableInfo.addChild(finishBox);
		tableInfo.addChild(commandBox);
		this._container.addChild(tableInfo);

		this._container.setContentSize(this._container.width,
			Math.max(2 * lightSprite.height + lightLabel.height - CommonLessonPage.SEPARATOR,
				commonTextLabel.height + 7 * CommonLessonPage.SEPARATOR) + tableInfo.height + 5 * CommonLessonPage.SEPARATOR);
	},

	_getObjectDescription: function(number)
	{
		switch(number)
		{
			case 1:
				return "остановка";
			case 2:
				return "финиш";
		}
	},

	_getCommandDescription: function(number)
	{
		switch(number)
		{
			case 0:
				return "левее";
			case 1:
				return "правее";
			case 2:
				return "зажечь";
			case 3:
				return "потушить";
		}
	}
});
