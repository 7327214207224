/**
 * Created by antonded on 10.11.14.
 */
/**
 * @class Layer used for changing language of game.
 * @extends ccui.HBox
 * @constructor
 * @param {cc.Scene} object Constructor of scene.
 */
var LanguageLayer = ccui.HBox.extend(/** @lends LanguageLayer# */{
	ctor: function ()
	{
		this._super();

		var russianLanguage = new pmui.Button("Languages/russian_flag.png",
			"Languages/russian_flag_Selected.png",
			"Languages/russian_flag_Selected.png");

		var enLanguage = new pmui.Button("Languages/en_flag.png",
			"Languages/en_flag_Selected.png",
			"Languages/en_flag_Selected.png");

		russianLanguage.setName(cc.sys.LANGUAGE_RUSSIAN);

		enLanguage.setName(cc.sys.LANGUAGE_ENGLISH);

		var buttonMargin = new ccui.LinearLayoutParameter();
		buttonMargin.setMargin(10, 0, 0, 0);

		enLanguage.setLayoutParameter(buttonMargin);

		enLanguage.addClickEventListener(this._selectLanguage.bind(this));
		russianLanguage.addClickEventListener(this._selectLanguage.bind(this));

		this.addChild(russianLanguage);
		this.addChild(enLanguage);

		this.setContentSize(cc.size(2 * russianLanguage.getContentSize().width + 10,
			russianLanguage.getContentSize().height));
	},

	_selectLanguage: function(sender)
	{
		ResetLanguage();

		pm.settings.setLanguage(sender.getName());

		pm.sendCustomEvent(pm.UPDATE_SCENE);
	}
});
