/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.WhileRepeaterNode = pmui.RepeaterNode.extend(/** @lends pmui.NodeLayer# */{

	_condition: null,

	ctor: function (treeNode, parent, syntaxModule, type)
	{
		this._super(treeNode, parent, syntaxModule, type);

		pm.syntaxModules.constructWhileRepeaterNode(this);
	},

	_removeNode: function (node)
	{
		if (!node._treeNode)
			return;
		if (node._treeNode.getTagName() === pm.WhileCondTag)
		{
			var boolNode = pm.data.SyntaxTree.createNode(this._treeNode, pm.data.Node.Type.Bool, pm.BoolTrue);
			boolNode.setTagName(pm.WhileCondTag);
			pm.sendCustomEvent(pm.PARSE_NODE, {node: boolNode, parent: this});
		}
	},

	addChildNode: function(node)
	{
		if(node._treeNode.getTagName() === pm.BodyTag
			|| node._treeNode.type === pm.data.Node.Type.Body)
		{
			this._bodyNode = node;
			this.createALignSome(this._bodyNode, pmui.RepeaterNode.FIRST,  pmui.RepeaterNode.BODY_TAG, ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			this.addChild(this._bodyNode);
			return;
		}
//		node._repTypes = [FunctionButton.Type.Number, FunctionButton.Type.Variable, FunctionButton.Type.Expression];
		var parent = this._treeNode;
		parent.removeChild(this._condition._treeNode);

		var align = this._condition.getLayoutParameter().clone();
		node.setLayoutParameter(align);

		this.removeChild(this._condition);
		this._condition = node;
		this._condition._treeNode.setTagName(pm.WhileCondTag);
		this.addChild(node);

	},

	getChildrenNodes: function ()
	{
		return [this._condition, this._bodyNode];
	},

	clone: function ()
	{
		return pm.appUtils.generateFunctionButton(this.type, pm.LoopVals.whileC);
	},

	calculateSize:function (forPar)
	{
		pm.syntaxModules.calculateSizeOfWhileRepeaterNode(this);

		if(!forPar)
			this._parentNode.calculateSize();
	}

});

pmui.WhileRepeaterNode.WHILE_TAG = "while";
