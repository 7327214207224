/**
 * Created by Nikita Besshaposhnikov on 22.03.17.
 */

pm.GameClientImpl = pm.GameClient.extend({

	_loadLevelCallback: null,

	_robotIndex: 0,

	ctor: function()
	{
		this._super();

		this.setEventCallback(this._eventCallback.bind(this));
		this.setPacketCallback(this._packetCallback.bind(this));

		pm.registerCustomEventListener(pm.USER_PROGRAM_UPDATED_STR, this._programChangedCallback.bind(this), 10);
	},

	setLoadLevelCallback: function(callback)
	{
		this._loadLevelCallback = callback;
	},

	sendReadyForNetworkGame: function(ready)
	{
		var packet = {
			type: pm.GAME_CLIENT_PACKET_TYPE.READY_FOR_GAME,
			ready: ready
		};

		pm.networkUtils.log("Sending ready for network game packet", pm.NetworkDebugSendTypes.GAME_CLIENT, packet);

		this.sendPacket(JSON.stringify(packet));
	},

	_eventCallback: function(event)
	{
		switch(Number(event))
		{
			case pm.GameClient.CONNECTED_EVENT:
				pm.networkUtils.log("Game client connected to server", pm.NetworkDebugSendTypes.GAME_CLIENT);
				break;

			case pm.GameClient.DISCONNECTED_EVENT:
				pm.networkUtils.log("Game client disconnected from server", pm.NetworkDebugSendTypes.GAME_CLIENT);
				break;
		}
	},

	_packetCallback: function(packet)
	{
		var parsedPacket = undefined;
		cc.log(packet);

		try
		{
			parsedPacket = JSON.parse(packet);
		}
		catch (e)
		{
			pm.networkUtils.log("Incorrect packet from network: " + packet, pm.NetworkDebugSendTypes.GAME_CLIENT, packet);
			return;
		}

		if(parsedPacket !== null && !parsedPacket.type)
		{
			pm.networkUtils.log("Incorrect packet from network(no type field): " + packet, pm.NetworkDebugSendTypes.GAME_CLIENT, packet);
			return;
		}

		switch(parsedPacket.type)
		{
			case pm.GAME_SERVER_PACKET_TYPE.LOAD_LEVEL:
				pm.networkUtils.log("Received load level packet", pm.NetworkDebugSendTypes.GAME_CLIENT, parsedPacket);

				this._robotIndex = parsedPacket.robotIndex;

				if(this._loadLevelCallback)
					this._loadLevelCallback(parsedPacket.robotIndex);

				break;

			case pm.GAME_SERVER_PACKET_TYPE.PROGRAM_UPDATED:
				pm.networkUtils.log("Received program packet", pm.NetworkDebugSendTypes.GAME_CLIENT, parsedPacket);

				if(this._robotIndex !== parsedPacket.robotIndex)
					pm.sendCustomEvent(pm.SET_ROBOT_PROGRAM_EVEN_STR, parsedPacket);

				break;
			case pm.GAME_SERVER_PACKET_TYPE.START_GAME:
				pm.networkUtils.log("Received start game packet", pm.NetworkDebugSendTypes.GAME_CLIENT, parsedPacket);

				pm.sendCustomEvent(pm.START_NETWORK_GAME_EVENT_STR);

				break;
			default:
				pm.networkUtils.log("Unknown packet type: " + parsedPacket.type, pm.NetworkDebugSendTypes.GAME_CLIENT, parsedPacket);

		}
	},

	_programChangedCallback: function()
	{
		var packet = {
			type: pm.GAME_CLIENT_PACKET_TYPE.PROGRAM_UPDATED,
			program: world.runningLevel.getCurrentRobot().getProgramData(),
			programDataIndex: world.runningLevel.programData[world.runningLevel.getCurrentRobot().groupID].currentIndex
		};

		pm.networkUtils.log("Sending program updated packet", pm.NetworkDebugSendTypes.GAME_CLIENT, packet);

		this.sendPacket(JSON.stringify(packet));
	}
});

