/**
 * Created by VK on 25.02.21.
 */

pm.pythonModule = pm.abstractSyntaxModule.extend(/** @lends pm.pythonModule# */{

    _dotSpriteSize: 20,

    getTreeCreator: function (programTree, robot, parent, outputRobot, syntaxModule)
    {
        return  new TextualTreeCreator(programTree, robot, parent, outputRobot, syntaxModule);
    },

    isTextual: function ()
    {
        return true;
    },

    constructIfNode: function(node)
    {
        var ifText = node.createText("if_py", pmui.NodeLayer.CONSTR_COLOR);
        ifText.setTag(pmui.IfNode.IF_TEXT_TAG);
        node.createALignSome(ifText, "", pm.IFThenTag, ccui.RelativeLayoutParameter.PARENT_TOP_LEFT, pmui.NodeLayer.SEPARATORX, 2 * pmui.NodeLayer.SEPARATORY, 0, 0);
        node.addChild(ifText);

        node.createALignSome(node._condition, pm.IFThenTag, "cond", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
        node.addChild(node._condition);

        var doubleDotSign = node.createText(":");
        node.createALignSome(doubleDotSign, "cond", "", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
        node.addChild(doubleDotSign);

        pm.abstractSyntaxModule.prototype.constructIfNode.call(this, node);
    },

    calculateSizeOfIfNode: function (node)
    {
        var data = pm.abstractSyntaxModule.prototype.calculateSizeOfIfNode.call(this, node);

        var width = Math.max(data.bodyWidth + this.calculateTabulationSize(),
            node.getChildByTag(pmui.IfNode.IF_TEXT_TAG).width + node._condition.width + node.createText(":").width + 2 * pmui.NodeLayer.SEPARATORX);
        width = Math.max(width, node.getChildByName(pmui.IfNode.ELSE_BLOCK_NAME).width);

        data.height += node.getChildByName(pmui.IfNode.ELSE_BLOCK_NAME).height;
        node.setContentSize(width + 2 * pmui.NodeLayer.SEPARATORX, data.height + 2 * pmui.NodeLayer.SEPARATORY);
    },

    constructElseBlock: function (node, treeNode, parent)
    {
        pm.abstractSyntaxModule.prototype.constructElseBlock.call(this, node, treeNode, parent);

        var elseName = node.getChildByTag(pmui.IfNode.ELSE_WORD_TAG);
        elseName.setString(LocalizedString("else_py"));

        var doubleDotSign = node.createText(":");
        node.createALignSome(doubleDotSign, "else_text", "", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
        node.addChild(doubleDotSign);
    },

    constructRepeaterNode: function (node) {},

    constructForRepeaterNode: function (node)
    {
        var text1 = node.createText("for_py", pmui.NodeLayer.CONSTR_COLOR);
        var text2 = node.createText("range_py", pmui.NodeLayer.CONSTR_COLOR);
        var text3 = node.createText(",");

        node.addChild(text1);
        node.createALignSome(text1, "", pmui.RepeaterNode.FIRST, ccui.RelativeLayoutParameter.PARENT_TOP_LEFT, pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORY * 2, 0, 0);

        node.addChild(node._iterator);
        node.createALignSome(node._iterator, pmui.RepeaterNode.FIRST, pm.ForIteratorTag, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
        
        node.addChild(text2);
        node.createALignSome(text2, pm.ForIteratorTag, pmui.ForRepeaterNode.FROM_TAG, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);

        node.addChild(node._begVal);
        node.createALignSome(node._begVal, pmui.ForRepeaterNode.FROM_TAG, pm.ForBegTag, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, 0, 0, 0, 0);

        node.addChild(text3);
        node.createALignSome(text3, pm.ForBegTag, pmui.ForRepeaterNode.TO_TAG, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, 0, 0, pmui.NodeLayer.SEPARATORX, 0);

        node.addChild(node._endVal);
        node.createALignSome(node._endVal, pmui.ForRepeaterNode.TO_TAG, pm.ForEndTag, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);

        var doubleDotSign = node.createText(") :");
        node.createALignSome(doubleDotSign, pm.ForEndTag, "", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
        node.addChild(doubleDotSign);
    },

    calculateSizeOfForRepeaterNode: function (node)
    {
        var data = this.calculateSizeOfRepeaterNode(node);

        node.setContentSize(Math.max(data.bodyWidth + this.calculateTabulationSize(),
            node.createText("for_py").width + node._iterator.width + node.createText("range_py").width
            + node.createText("(,) :").width + node._begVal.width + node._endVal.width + pmui.NodeLayer.SEPARATORX * 4) + pmui.NodeLayer.SEPARATORX * 2,
            node._iterator.height + data.bodyHeight + pmui.NodeLayer.SEPARATORY * 2 - data.separator);
    },

    constructWhileRepeaterNode: function (node)
    {
        var while_text = node.createText("while_py", pmui.NodeLayer.CONSTR_COLOR);
        node.createALignSome(while_text, "", pmui.RepeaterNode.FIRST, ccui.RelativeLayoutParameter.PARENT_TOP_LEFT, pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORY * 2, 0, 0);
        node.addChild(while_text);

        this.constructWhileRepeaterCondition(node);

        var doubleDotSign = node.createText(":");
        node.createALignSome(doubleDotSign, pm.WhileCondTag, "", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER,
            pmui.NodeLayer.SEPARATORX, 0, 0, 0);
        node.addChild(doubleDotSign);
    },

    calculateSizeOfWhileRepeaterNode: function (node)
    {
        var data = this.calculateSizeOfRepeaterNode(node);

        node.setContentSize(Math.max(data.bodyWidth + this.calculateTabulationSize(),
            node.createText("while_py").width + node._condition.width + node.createText(":").width + pmui.NodeLayer.SEPARATORX * 2) + pmui.NodeLayer.SEPARATORX * 2,
            node._condition.height + data.bodyHeight + pmui.NodeLayer.SEPARATORY * 2.5 + 5 - data.separator);
    },

    constructNTimesRepeaterNode: function (node)
    {
        var text = node.createText("repeatNTimes_py", pmui.NodeLayer.CONSTR_COLOR);
        node.createALignSome(text, "", pmui.RepeaterNode.FIRST, ccui.RelativeLayoutParameter.PARENT_TOP_LEFT, pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORY * 2, 0, 0);
        node.addChild(text);

        this.constructNTimesRepeaterNumber(node);

        var doubleDotSign = node.createText(") :");
        node.createALignSome(doubleDotSign, pm.NTimesNumTag, "", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
        node.addChild(doubleDotSign);
    },

    calculateSizeOfNTimesRepeaterNode: function (node)
    {
        var data = this.calculateSizeOfRepeaterNode(node);

        node.setContentSize(Math.max(data.bodyWidth + this.calculateTabulationSize(),
            node.createText("repeatNTimes_py").width + node._number.width + node.createText(":").width + 2 * node.createText(")").width + pmui.NodeLayer.SEPARATORX * 3) + pmui.NodeLayer.SEPARATORX * 2,
            Math.max(node.createText("repeatNTimes_py").height, node._number.height) + data.bodyHeight + pmui.NodeLayer.SEPARATORY * 2 - data.separator);
    },

    getStatementNodeSign: function (node)
    {
        return  node.createText("=");
    },

    addChildNodeToAllocNode: function (node)
    {
        node.createALignSome(node._name, "", "name", ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL,
            pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORY, 1.5 * pmui.NodeLayer.SEPARATORY, pmui.NodeLayer.SEPARATORY);

        if(!node._parentNode.isFunction())
        {
            var sign = node.createText("= 0");
            node.createALignSome(sign, "name", "center", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
            node.addChild(sign);
        }

        node.addChild(node._name);
    },

    constructBodyNode: function (node)
    {
        pm.abstractSyntaxModule.prototype.constructBodyNode.call(this, node);

        var firstElemAlignType;
        if(pm.syntaxModules.isTextual(node.getSyntaxModule()) && !node._parentNode.isMain())
            firstElemAlignType = ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN;
        else
            firstElemAlignType = ccui.RelativeLayoutParameter.PARENT_TOP_LEFT;

        node.createALignSome(node._firstElem, "dots", node._id + pm.BodyFirstChildTag, firstElemAlignType,
            0, pmui.NodeLayer.SEPARATORY, 0, 0);

        if(node._parentNode.isMain())
            node._dotSprite = null;
    },

    resizeBodyNodeDotSprite: function (node)
    {
        pm.abstractSyntaxModule.prototype.resizeBodyNodeDotSprite.call(this, node);
    },

    constructStartNodeBox: function (node, robotName)
    {
        var hBox = new ccui.HBox();
        var sep = new ccui.Layout();
        sep.setContentSize(5,5);

        var robotUsageText = node.createText("from_py");
        hBox.addChild(robotUsageText);
        hBox.addChild(sep);
        hBox.addChild(robotName);

        hBox.addChild(sep.clone());
        robotUsageText = node.createText("import_py");
        hBox.addChild(robotUsageText);

        return hBox;
    },

    getFunctionNodeSeparatorX: function ()
    {
        return 0;
    },

    getFunctionNodeSeparatorY: function ()
    {
        return 0;
    },

    constructFunctionNode: function (node)
    {
        var text = null;

        if (node._name !== undefined)
        {
            text = node.createText("def_py",pmui.NodeLayer.CONSTR_COLOR);
            node.createALignSome(text, "", "def", ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
            node._cont.addChild(text);

            var but = node.createText(LocalizedString(node._name));
            node.createALignSome(but, "def", "name", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, this.getFunctionNodeSeparatorY());
            node._cont.addChild(but);
        }

        node.createALignSome(node._cont, "", "begin_", ccui.RelativeLayoutParameter.PARENT_TOP_LEFT, pmui.NodeLayer.SEPARATORX, 0, 0, 0);

        if(node._name !== undefined)
        {
            node._lBrace = node.createText("(");
            node.createALignSome(node._lBrace, "name", pmui.FunctionNode.L_BRACE_TAG, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, 0, 0, this.getFunctionNodeSeparatorX() * 2, 0);
            node._cont.addChild(node._lBrace);

            node._rBrace = node.createText(") :");
            node.createALignSome(node._rBrace, "", pmui.FunctionNode.R_BRACE_TAG, ccui.RelativeLayoutParameter.PARENT_RIGHT_CENTER_VERTICAL, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
            node._cont.addChild(node._rBrace);
        }
    },

    addChildNodeToFunctionNode: function (node, childNode)
    {
        if(childNode._treeNode.getTagName() === pm.BodyTag
            || childNode._treeNode.type === pm.data.Node.Type.Body)
        {
            node._bodyNode = childNode;

            node.createALignSome(node._bodyNode, "beg_", "body_", ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
            node.addChild(node._bodyNode);
            return;
        }

        if (node._paramsNumber === 0)
        {
            node._params.pop();
            node.createALignSome(node._lBrace, "name", pmui.FunctionNode.L_BRACE_TAG, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, 0, 0, 0, 0);

            node.createALignSome(childNode, pmui.FunctionNode.L_BRACE_TAG, pm.FuncParamTag + "0", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER,
                this.getFunctionNodeSeparatorX(), pmui.NodeLayer.SEPARATORY, 0, pmui.NodeLayer.SEPARATORY);
        }
        else
        {
            var comma = node.createText(",");
            node.createALignSome(comma, pm.FuncParamTag + (node._paramsNumber - 1), pmui.CommandNode.COMMAND_COMMA_TAG + node._paramsNumber, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, 0, 0, 0, 0);
            node._cont.addChild(comma);

            node.createALignSome(childNode, pmui.CommandNode.COMMAND_COMMA_TAG + node._paramsNumber, pm.FuncParamTag + node._paramsNumber, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER,
                this.getFunctionNodeSeparatorX() + pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORY, 0, pmui.NodeLayer.SEPARATORY);
        }

        pm.abstractSyntaxModule.prototype.addChildNodeToFunctionNode.call(this, node, childNode);
    },

    removeChildNodeFromFunctionNode: function (node)
    {
        this.constructFunctionNode(node);
    },

    constructEmptyParameter: function (node)
    {
        var first = pm.abstractSyntaxModule.prototype.constructEmptyParameter.call(this, node);

        first.setContentSize(40,40);
        node.createALignSome(first, pmui.FunctionNode.L_BRACE_TAG, "first", ccui.RelativeLayoutParameter.PARENT_TOP_LEFT, 65, 0, 0, -40);
    },

    getLocalization: function (language)
    {
        return {
            "yes_py" : "True",
            "no_py" : "False",
            "from_py" : "from",
            "import_py" : "import *",
            "for_py" : "for",
            "range_py" : "in range(",
            "repeatNTimes_py": "for _ in range(",
            "while_py" : "while",
            "def_py" : "def",
            "if_py" : "if",
            "else_py" : "else",
            "and_py" : "and",
            "or_py" : "or",
            "not_py" : "not"
        };
    }
});

pm.pythonEditableModule = pm.pythonModule.extend({

    isTextual: function ()
    {
        return false;
    },

    addChildNodeToAllocNode: function (node)
    {
        pm.pythonModule.prototype.addChildNodeToAllocNode.call(this, node);

        node.createALignSome(node._variableValue, "", "", ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL, -pmui.AllocNode.DEBUG_WIDTH, 0, pmui.NodeLayer.SEPARATORX, 0);
        node.addChild(node._variableValue);
    },

    constructBodyNode: function (node)
    {
        pm.pythonModule.prototype.constructBodyNode.call(this, node);

        if(node._parentNode.isMain())
            node.getLayoutParameter().setMargin(pmui.NodeLayer.SEPARATORX/2, - pmui.BodyNode.FIRST_HEIGHT + pmui.NodeLayer.SEPARATORY , 0, 0);
    },

    getFunctionNodeSeparatorX: function ()
    {
        return pmui.NodeLayer.SEPARATORX * 3;
    },

    getFunctionNodeSeparatorY: function ()
    {
        return pmui.NodeLayer.SEPARATORY * 3;
    },

    constructFunctionNode: function (node)
    {
        pm.pythonModule.prototype.constructFunctionNode.call(this, node);

        var align = new ccui.RelativeLayoutParameter();
        align.setMargin(pmui.NodeLayer.SEPARATORX/2, 0, 0, 0);
        node.setLayoutParameter(align);
    },

    getLocalization: function (language) {}
});


pm.syntaxModules.PYTHON_EDITABLE_MODULE_ORDER = 3;
pm.syntaxModules.PYTHON_TEXTUAL_MODULE_ORDER = 4;

pm.syntaxModules.register(new pm.pythonEditableModule, pm.syntaxModules.PYTHON_EDITABLE_MODULE_ORDER);
pm.syntaxModules.register(new pm.pythonModule, pm.syntaxModules.PYTHON_TEXTUAL_MODULE_ORDER);

