/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 *
 */
pmui.NumericExpressionNode = pmui.ExpressionNode.extend(/** @lends pmui.NodeLayer# */{

	ctor: function (treeNode, parent, syntaxModule, sign)
	{
		this._withRoundBorder = true;
		this._super(treeNode, parent, syntaxModule, sign);
		this.type = FunctionButton.Type.Expression;

		this._sign.addClickEventListener(this.clickCallback.bind(this));
	},

	getNodeMargin: function()
	{
		return 2;
	},

	clickCallback: function()
	{
		var valN = (pmui.NumericExpressionNode.values.indexOf(this._treeNode.value) + 1) % pmui.NumericExpressionNode.values.length;
		this.changeSign(this.getSign(pmui.NumericExpressionNode.values[valN]));
		this._sign.addClickEventListener(this.clickCallback.bind(this));
		this._treeNode.value = pmui.NumericExpressionNode.values[valN];
	},

	clone: function ()
	{
		return pm.appUtils.generateFunctionButton(this.type, pm.ArythmMethods.Addition);
	}
});

pmui.NumericExpressionNode.values = [
	pm.ArythmMethods.Addition,
	pm.ArythmMethods.Substraction,
	pm.ArythmMethods.Multiplication,
	pm.ArythmMethods.Division
];
