/**
 * Created by Kirill Mashchenko on 03.09.2018.
 */

/**
 * @class Layer for light task.
 * @extends TaskLayer
 * @constructor
 * @param {pm.AbstractLevel} level
 * @param {pm.data.PushTask} task
 */

var PhysicalTaskLayer = TaskLayer.extend(/** @lends LightTaskLayer# */{

	_cellsInput: null,
	_cellsTaskArray: [],

	ctor: function(level, task)
	{
		this._super(LocalizedString("PhysicalTask"), level, task, pm.PhysicalRobotLevelModule.Type);

		this._cellsTaskArray = [];

		var height = this.height;

		this._cellsInput = new pmui.TextInput("", LocalizedString("EnterNumberOfCells"), 20, LevelSettingsLayer.WIDTH - PhysicalTaskLayer.WIDTH_DELTA);
		this._cellsInput.setAttachCallback(this._updateTaskFromInput, this);

		var cellsAlign = new ccui.LinearLayoutParameter();
		cellsAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
		cellsAlign.setMargin(LevelSettingsLayer.BORDER_RADIUS + 15, LevelSettingsLayer.SEPARATOR, 0, 0);
		this._cellsInput.setLayoutParameter(cellsAlign);

		this.addChild(this._cellsInput);

		height += this._cellsInput.height + 20;

		this.setContentSize(this.width, height);

		pm.registerCustomEventListener(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR, this._updateCellsInputValue.bind(this), this);

		if (this._task.data[this._level.getActiveMapIndex()])
			this._updateCellsInputString(this._task.data[this._level.getActiveMapIndex()]);
		else
			this._updateCellsInputValue();
	},

	_updateCellsInputValue: function()
	{
		var map = this._level.activeMap;

		this._cellsTaskArray = [];

		for (var x = 0; x < map.width; ++x)
		{
			for (var y = 0; y < map.height; ++y)
			{
				var p = cc.p(x, y);

				if (map.element(p).getType() >= PhysicalRobotMapElementType.NullElement &&
                    map.element(p).getType() <= PhysicalRobotMapElementType.NineElement)
				{
					this._cellsTaskArray.push(map.element(p).getType());
				}
			}
		}

		this._cellsTaskArray.sort();
		this._updateCellsInputString(this._cellsTaskArray);
		this._task.data[this._level.getActiveMapIndex()] = this._cellsTaskArray;
	},

	_updateTaskFromInput: function()
	{
		var array = [];

		for (var i = 0; i < this._cellsInput.getString().length; ++i)
			array.push(Number(this._cellsInput.getString()[i]));

		this._task.data[this._level.getActiveMapIndex()] = array;
	},

	_updateCellsInputString: function(taskArray)
	{
		var string = "";

		for (var i = 0; i < taskArray.length; ++i)
			string += taskArray[i];

		this._cellsInput.setString(string);
	}
});

PhysicalTaskLayer.WIDTH_DELTA = 70.0;
