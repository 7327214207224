/**
 * @class Layer for light task.
 * @extends TaskLayer
 * @constructor
 * @param {pm.AbstractLevel} level
 * @param {pm.data.PushTask} task
 */

var LightTaskLayer = TaskLayer.extend(/** @lends LightTaskLayer# */{

	ctor: function(level, task)
	{
		this._super(LocalizedString("LightTask"), level, task, pm.LightLevelModule.Type);
	}
});
