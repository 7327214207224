/**
 * Created by Antony Orlovsky on 29.10.18.
 */

/**
 * @class Page for help about method stack.
 * @extends ccui.Layout
 */
var MethodStackLessonLayer = ccui.Layout.extend(/** @lends MethodStackLessonLayer# */{

	_curWorldID: null,
	_loadingLayer: null,

	ctor: function(worldID)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);
		this.setContentSize(pm.settings.getScreenSize());

		this._curWorldID = worldID;
		pm.settings.setLevel(MethodStackLessonLayer.TUTORIAL_LEVEL_ID);

		this._loadInterface();
		pm.registerCustomEventListener(pm.TUTORIAL_ENDED, this._drawTutorialLevel.bind(this), this);
	},

	_loadInterface: function()
	{
		var screenBounds = pm.settings.getScreenBounds();

		var backButton = new pmui.Button(pm.spriteUtils.getIconName("backLevel", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("backLevel", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("backLevel", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		backButton.addClickEventListener(this._startScreen.bind(this));

		var backButtonAlign = new ccui.RelativeLayoutParameter();
		backButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		backButtonAlign.setRelativeName("back");
		backButtonAlign.setMargin(screenBounds.left, screenBounds.top, 0, 0);
		backButton.setLayoutParameter(backButtonAlign);

		this.addChild(backButton);

		var selectPageMenu = new SelectPageMenu(SelectPageMenu.PAGES.METHOD_STACK, this._stopTutorial.bind(this), this);

		var selectPageAlign = new ccui.RelativeLayoutParameter();
		selectPageAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		selectPageAlign.setMargin(SelectPageMenu.LEFT_MARGIN, -screenBounds.top, 0, 0);
		selectPageAlign.setRelativeName("selectPage");
		selectPageAlign.setRelativeToWidgetName("back");
		selectPageMenu.setLayoutParameter(selectPageAlign);

		this.addChild(selectPageMenu);

		var methodStackLabel = new ccui.Text(LocalizedString("Bottomless method stack"), pm.settings.fontName,
			MethodStackLessonLayer.TITLE_FONT_SIZE);
		methodStackLabel.setAnchorPoint(0, 0.5);

		var methodStackLabelAlign = new ccui.RelativeLayoutParameter();
		methodStackLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		methodStackLabelAlign.setRelativeName("methodStackLabel");
		methodStackLabelAlign.setRelativeToWidgetName("selectPage");
		methodStackLabelAlign.setMargin((pm.settings.getScreenSize().width - backButton.width - selectPageMenu.width - methodStackLabel.width)/2, screenBounds.top, 0, 0);
		methodStackLabel.setLayoutParameter(methodStackLabelAlign);

		this.addChild(methodStackLabel);

		var methodStackDescription = LocalizedString("MethodStackDescriptionHelp");

		var methodStackDescriptionLabel = new ccui.Text(methodStackDescription, pm.settings.fontName,
			MethodStackLessonLayer.DESCRIPTION_FONT_SIZE);

		methodStackDescriptionLabel.setTextAreaSize(cc.size(this.width * (1 - MethodStackLessonLayer.TUTORIAL_SCALE)
			- MethodStackLessonLayer.TEXT_AREA_WIDTH, 0));

		var methodStackDescriptionAlign = new ccui.RelativeLayoutParameter();
		methodStackDescriptionAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		methodStackDescriptionAlign.setRelativeName("methodStackDescription");
		methodStackDescriptionAlign.setRelativeToWidgetName("back");
		methodStackDescriptionAlign.setMargin(MethodStackLessonLayer.STANDARD_SEPARATOR, MethodStackLessonLayer.STANDARD_SEPARATOR, 0, 0);
		methodStackDescriptionLabel.setLayoutParameter(methodStackDescriptionAlign);

		this.addChild(methodStackDescriptionLabel);

		this._drawTutorialLevel();
	},

	_drawTutorialLevel: function()
	{
		if (this.getChildByTag(MethodStackLessonLayer.TUTORIAL_ID))
			this.removeChildByTag(MethodStackLessonLayer.TUTORIAL_ID);

		var tutorialData = {
			show: true,
			scale: MethodStackLessonLayer.TUTORIAL_SCALE
		};

		var levelContainer = new ccui.Layout();
		levelContainer.setContentSize(this.width * tutorialData.scale, this.height * tutorialData.scale);

		var levelContainerAlign = new ccui.RelativeLayoutParameter();
		levelContainerAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		levelContainerAlign.setMargin(MethodStackLessonLayer.STANDARD_SEPARATOR, 0, 0, 0);
		levelContainerAlign.setRelativeName("level");
		levelContainerAlign.setRelativeToWidgetName("methodStackDescription");
		levelContainer.setLayoutParameter(levelContainerAlign);

		this.addChild(levelContainer, 0, MethodStackLessonLayer.TUTORIAL_ID);

		var levelLayer = new LevelLayer(GameType.Local, null, pm.settings.getGame(), pm.settings.getLevel(), null, tutorialData);

		levelContainer.addChild(levelLayer);
	},

	_stopTutorial: function()
	{
		pm.tutorialUtils.stop();
		pm.sendCustomEvent(pm.PROGRAM_RESTART_EVENT_STR);
	},

	_startScreen: function()
	{
		pm.settings.isHelpMode =  false;

		this._stopTutorial();

		this._downloadWorld(this._curWorldID);

		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new StartMenuScene());
		cc.director.runScene(trans);
	},

	_downloadWorld: function(worldID)
	{
		this._loadingLayer = new LoadingLayer();
		this._loadingLayer.show();

		pm.worldUtils.loadWorld({
			worldID: worldID,
			callback: this._endDownLoadWorld,
			callbackTarget: this,
			reloadBuiltinOnError: true
		});
	},

	_endDownLoadWorld: function(error, loadedWorldID)
	{
		if (!error && loadedWorldID.length > 0 && loadedWorldID[0])
			pm.settings.setSelectedWorldID(loadedWorldID[0]);

		this._loadingLayer.remove();
	},

	getCurrentWorldID: function()
	{
		return this._curWorldID;
	}
});

MethodStackLessonLayer.STANDARD_SEPARATOR = 8;
MethodStackLessonLayer.TEXT_AREA_WIDTH = 100;
MethodStackLessonLayer.TITLE_FONT_SIZE = 45;
MethodStackLessonLayer.DESCRIPTION_FONT_SIZE = 17;
MethodStackLessonLayer.TUTORIAL_SCALE = 0.6;
MethodStackLessonLayer.TUTORIAL_LEVEL_ID = 2;
MethodStackLessonLayer.TUTORIAL_ID = 1001;

/**
 * @class Scene for {@link MethodStackLessonLayer}
 * @extends cc.Scene
 * @constructor
 */
var MethodStackLessonScene = cc.Scene.extend(/** @lends MethodStackLessonScene# */{
	ctor:function (worldID)
	{
		this._super();

		var layer = new MethodStackLessonLayer(worldID);
		this.addChild(layer);

		var backLayer = pm.backgroundUtils.generateBackground();
		this.addChild(backLayer, -1);
	}
});
