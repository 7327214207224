/**
 * Created by Kirill Mashchenko on 12.07.17.
 */

/**
 * @class Common interface for all 2D robots.</br>
 * Provides move native functions and element&wall-checking conditions.
 * @interface
 * @extends PlayerRobot4
 */

var PlayerRobot4Walls = PlayerRobot4.extend(/** @lends PlayerRobot4Walls# */{

	ctor: function()
	{
		this._super();

		this.conditions.push(PlayerRobot4.Condition.Clear);
		this.conditionOpposites.push(PlayerRobot4.Condition.Wall);
	},

	_checkConditionInternal: function(condition, args)
	{
		if (condition === PlayerRobot4.Condition.Wall)
		{
			return !this._isNearClear();
		}
		else if (condition === PlayerRobot4.Condition.Clear)
		{
			return this._isNearClear();
		}

		return false;
	},

	_isNearClear: function()
	{
		var map = this.getMap();

		var nextPos = map.getNextDirectionalElementPosition(this.position, this.direction);

		if (!nextPos)
			return false;

		var nextElement = map.element(nextPos);

		if (this._isNearWall())
			return false;

		if (nextElement.getObjectCount() > 0)
		{
			for (var o in nextElement._objectsHere)
			{
				var object = nextElement._objectsHere[o];

				if (!(object instanceof pm.Object))
					continue;

				if (object.oldPosition.x === nextPos.x || object.oldPosition.y === nextPos.y)
					return false;
			}
		}

		if (nextElement.getRobotsCount() > 0)
		{
			for (var r in nextElement._robotsHere)
			{
				var robot = nextElement._robotsHere[r];

				if (!(robot instanceof pm.PlayerRobot2D))
					continue;

				if (robot._oldPosition.x === nextPos.x || robot._oldPosition.y === nextPos.y)
					return false;
			}
		}

		return true;
	},

	_isNearWall: function ()
	{
		return this.getMap().element(this.position).walls[this.direction];
	},

	_canMoveTo: function(target, direction)
	{
		var map = this.getMap();

		return !map.element(target).walls[(direction + this.getDirectionCount()/2) % this.getDirectionCount()];
	}

});
