/**
 * Created by Kirill Mashchenko on 17.05.23.
 */

/**
 * @class Common interface for boxes pressed by {@link pm.data.PressRobot}
 * @interface
 * @extends pm.Object
 */
pm.data.PressBox = pm.Object.extend(/** @lends pm.data.PressBox#*/{

	typeName: "PressBox",
	oldPosition: cc.p(),

	ctor: function()
	{
		this._addNonEnumerableProps("oldPosition");
		this._super();
	},

	getType: function() { return pm.PressRobotLevelModule.ObjectTypes.PressBox; },

	/**
     * Sets position of object by element position.
     * @param {cc.Point} point Point in element coordinates.
     */
	setPosition: function(point)
	{
		this._removeFromCurrentPosition();

		this.position = point;
		this.oldPosition = this.position;

		this.parentMap.element(point).addObject(this);
	},

	/**
	 * false argument is for turning not clockwise
	 */
	_turnLeft: function ()
	{
		this.playAnimation(PressBoxAnimation.Turn, this._endTurning, false);
	},

	/**
	 * true argument is for turning clockwise
	 */
	_turnRight: function ()
	{
		this.playAnimation(PressBoxAnimation.Turn, this._endTurning, true);
	},

	_endTurning: function()
	{
		this._isPlayingAnimation = false;
	},

	/**
     * Returns if press object is moving
     */
	isMoving: function()
	{
		return this.oldPosition.x !== this.position.x || this.oldPosition.y !== this.position.y;
	},

	/**
     * Moves object to element position.
	 * @param {cc.Point} target Move coordinate in elements.
	 * @param {Boolean} skipAnimation Skip animation or not.
     */
	moveTo: function(target, skipAnimation)
	{
		if (this.position.x === target.x && this.position.y === target.y)
			return;

		this.oldPosition = this.position;
		this.position = target;

		this.parentMap.element(target).addObject(this);

		if (!skipAnimation)
			this.playAnimation(PressBoxAnimation.Move, this._endMoving, this.position);
	},

	_endMoving: function()
	{
		this._isPlayingAnimation = false;

		this._removeFromOldPosition();
		this.oldPosition = this.position;
	},

	_removeFromOldPosition: function()
	{
		if(this.oldPosition.x < this.parentMap.width && this.oldPosition.y < this.parentMap.height)
		{
			if(this.parentMap.element(this.oldPosition).containsObject(this))
				this.parentMap.element(this.oldPosition).removeObject(this);
		}
	},

	_removeFromCurrentPosition: function()
	{
		if(this.position.x < this.parentMap.width && this.position.y < this.parentMap.height)
		{
			if(this.parentMap.element(this.position).containsObject(this))
				this.parentMap.element(this.position).removeObject(this);
		}
	},

	updateSprite: function(isBroken)
	{
		if (isBroken === undefined)
			isBroken = false;

		if (!isBroken)
			this.sprite.setRealPosition(this.position);
		else
			this.sprite.setRealPosition(this.oldPosition);
	},

	reset: function ()
	{
		this._isPlayingAnimation = false;
		this._removeFromOldPosition();
		this.setPosition(this.startPosition);

		if(!CORE_BUILD)
			this.sprite.reset();
	},

	generateSprite: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (previewDraw === undefined)
				previewDraw = false;

			this.sprite = new PressBoxSprite();
			this.updateNumberSprite();

			if (previewDraw)
				this.sprite.setIdVisible();

			return this.sprite;
		}
	},

	getState: function ()
	{
		return this.position;
	},

	setState: function (state)
	{
		if (this.position !== state)
			this.moveTo(state);
	},

	updateNumberSprite: function ()
	{
		this.sprite.updateNumber(this.visualId);
	}
});
