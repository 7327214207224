/**
 * Created by Nikita Besshaposhnikov on 09.02.15.
 */

/**
 * @class Sprite for counter robot.
 * @implements pm.ObjectSprite2D
 */
pm.CounterSprite = pm.ObjectSprite2D.extend(/** @lends pm.CounterSprite# */{
	_countLabel: null,

	_stonesSprite: null,
	_lightSprite: null,
	_counterValue: null,
	_commandsSprite: null,
	_commandsCount: null,

	ctor: function()
	{
		pm.spriteUtils.addTempSpriteFrames(this._getSpriteFramesFile());
		pm.spriteUtils.addTempSpriteFrames("Robot/counter_stones.plist");

		this._super();

		var front = new cc.Sprite(cc.spriteFrameCache.getSpriteFrame(this._getFrontFrameName()));
		front.setAnchorPoint(0, 0);
		front.setPosition(0, 1);

		this.getSprite().setContentSize(front.getContentSize());

		this.getSprite().addChild(front, 2);

		var back = new cc.Sprite(cc.spriteFrameCache.getSpriteFrame(this._getBackFrameName()));
		back.setAnchorPoint(0, 0);
		back.setPosition(0, 0);

		this.getSprite().addChild(back, 0);

		this._lightSprite = new cc.Sprite(cc.spriteFrameCache.getSpriteFrame(this._getLightFrameName()));
		this.getSprite().addChild(this._lightSprite, -1);

		this._lightSprite.setAnchorPoint(0.5, 0);
		this._lightSprite.setPosition(this._getLightX(), this._getLightY());

		this._lightSprite.setOpacity(0);

		this.getSprite().setAnchorPoint(0, 0);

		this._countLabel = new ccui.Text("0", pm.CounterSprite.FONT_NAME, pm.CounterSprite.FONT_SIZE);
		this._countLabel.setTextColor(pm.settings.levelFontColor);
		this._countLabel.setPosition(this._getCenterX(), this._getValueLabelY());

		this.getSprite().addChild(this._countLabel, 2);

		this._stonesSprite = new cc.Sprite();
		this.getSprite().addChild(this._stonesSprite, 1);

		this._stonesSprite.setAnchorPoint(0.5, 0);
		this._stonesSprite.setPosition(this._getCenterX(), this._getBottomY());
		this._commandsSprite = new cc.Sprite(pm.spriteUtils.getInterfaceElementFrame("commandsNumber"));

		this._commandsSprite.setColor(RobotColorList2D.Orange);
		this._commandsSprite.setPosition(cc.p(50, 195));
		this._commandsSprite.setVisible(false);

		this.sprite.addChild(this._commandsSprite);

		this._commandsCount = new ccui.Text("", pm.settings.fontBoldName, pm.settings.fontSize);
		this._commandsCount.setColor(cc.color(1, 1, 180));
		this._commandsCount.setAnchorPoint(cc.p(0.5, 0.5));

		if (cc.sys.isNative)
			this._commandsCount.setNormalizedPosition(cc.p(0.5, 0.5));
		else
			this._commandsCount.setNormalizedPosition(cc.p(0.5, 0.42));

		this._commandsCount.setNormalizedPosition(cc.p(0.5, 0.46));
		this._commandsSprite.addChild(this._commandsCount);
	},

	_getSpriteFramesFile: function()
	{
		return "Robot/counter.plist";
	},

	_getBackFrameName: function()
	{
		return "counter_back.png";
	},

	_getFrontFrameName: function()
	{
		return "counter_front.png";
	},

	_getLightFrameName: function()
	{
		return "counter_light.png";
	},

	_getCenterX: function() { return 51; },
	_getValueLabelY: function() { return 112; },
	_getBottomY: function() { return 45; },
	_getBottomStoneAniY: function() { return 45; },
	_getTopY: function() { return 300; },
	_getLightX: function() { return this.getSprite().getContentSize().width / 2 - 1; },
	_getLightY: function() { return 2; },

	playAnimation: function(endCallBack, target, type, args)
	{
		switch(type)
		{
			case pm.CounterSprite.Animation.IncValue: this._incValue(args, endCallBack, target); break;
			case pm.CounterSprite.Animation.DecValue: this._decValue(args, endCallBack, target); break;
			case pm.CounterSprite.Animation.Empty: this._empty(endCallBack, target); break;
			case pm.CounterSprite.Animation.Set: this._setValue(args); break;
			case pm.CounterSprite.Animation.Reset: this._reset(); break;
		}
	},

	_setStonesByValue: function(value)
	{
		if (value < 1)
		{
			this._stonesSprite.setOpacity(0);
			return;
		}
		else
		{ this._stonesSprite.setOpacity(255); }

		if(value === 1)
			this._stonesSprite.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame("counter_stones0.png"));
		else if (value === 2)
			this._stonesSprite.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame("counter_stones1.png"));
		else if (value > 2)
			this._stonesSprite.setSpriteFrame(cc.spriteFrameCache.getSpriteFrame("counter_stones2.png"));
	},

	_incValue: function(value, callback, target)
	{
		var stone = new cc.Sprite(cc.spriteFrameCache.getSpriteFrame("counter_stone{0}.png".format(this._counterValue % 3)));

		this.getSprite().addChild(stone, 1);

		stone.setPosition(this._getCenterX(), this._getTopY());
		stone.setOpacity(0);

		var showIn = new cc.FadeIn(pm.settings.getAnimationSpeed());
		var move = new cc.MoveTo(3 * pm.settings.getAnimationSpeed(), this._getCenterX(), this._getBottomStoneAniY());
		var remove = new cc.CallFunc(function() { this._setStonesByValue(value);stone.removeFromParent();callback.call(target); }, this);

		this._counterValue = value;

		var finish = cc.callFunc(this._dysplayValue, this);

		var action = cc.sequence(cc.spawn(showIn, move), remove, finish);

		stone.runAction(action);
	},

	_dysplayValue: function()
	{
		this._countLabel.setString("{0}".format(this._counterValue));
	},

	_decValue: function(value, callback, target)
	{
		var stone = new cc.Sprite(cc.spriteFrameCache.getSpriteFrame("counter_stone{0}.png".format((this._counterValue - 1) % 3)));

		this.getSprite().addChild(stone, 1);

		stone.setPosition(this._getCenterX(), this._getBottomY());

		this._setStonesByValue(value);

		var showOut = new cc.FadeOut(3*pm.settings.getAnimationSpeed());
		var move = new cc.MoveTo(3 * pm.settings.getAnimationSpeed(), this._getCenterX(), this._getTopY());
		var remove = new cc.CallFunc(function() { stone.removeFromParent();callback.call(target); }, this);

		this._counterValue = value;

		var start = cc.callFunc(this._dysplayValue, this);

		var action = cc.sequence(start, cc.spawn(showOut, move), remove);

		stone.runAction(action);
	},

	_lightAnimation: function(callback)
	{
		var fadeIn = cc.fadeIn(pm.settings.getAnimationSpeed());
		var fadeOut = cc.fadeOut(pm.settings.getAnimationSpeed());
		var end = cc.callFunc(callback, this._lightSprite);

		this._lightSprite.runAction(cc.sequence(fadeIn, fadeOut, end));
	},

	showCommands: function(flag, count)
	{
		if (flag && MethodStackLayer.opened)
			return;

		if (flag)
			this._commandsCount.setString(count);

		this._commandsSprite.setVisible(flag);
	},

	indicateCommands: function(stepCount)
	{
		this.showCommands(true, stepCount);

		setTimeout(this.showCommands.bind(this), pm.RobotSprite2D.SHOW_COMMANDS_TIMEOUT, false)
	},

	_setValue: function(value)
	{
		this._countLabel.setString("{0}".format(value));
		this._setStonesByValue(value);
	},

	_empty: function(callback, target)
	{
		this._countLabel.setString("0");

		this._lightAnimation(function() { this._setStonesByValue(0); callback.call(target); }.bind(this));
	},

	_reset: function()
	{
		this._countLabel.setString("0");
		this._setStonesByValue(0);
	}
});

/**
 * Enum for counter robot animations.
 * @readonly
 * @enum {Number}
 */
pm.CounterSprite.Animation = {
	IncValue: "inc",
	DecValue: "dec",
	Empty: "empty",
	Reset: "reset",
	Set: "set"
};

pm.CounterSprite.FONT_NAME = pm.fonts.getRealFontName("Comfortaa-Regular");
pm.CounterSprite.FONT_SIZE = 24;

pm.CounterSprite.FONT_COLOR = cc.color(51, 0, 102, 255);
