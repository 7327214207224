/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.BoolNode = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{
	text: null,

	ctor: function (treeNode, parent, syntaxModule)
	{
		this._withRoundBorder = true;
		this._super(parent, treeNode, syntaxModule);
		this.setLayoutType(ccui.Layout.RELATIVE);
		var align = new ccui.RelativeLayoutParameter();
		align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
		align.setMargin(15, 5, 15, 5);
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
		{
			align.setMargin(5, 10, 5, 0);
		}
		this.text = this.createText(pm.BoolText[this._treeNode.value], pmui.NodeLayer.CONST_COLOR, 18);

		this.addChild(this.text);
		this.text.setLayoutParameter(align);
		this._repTypes = [FunctionButton.Type.Variable, FunctionButton.Type.Condition, FunctionButton.Type.LogicExpression, FunctionButton.Type.AndOrExpression, FunctionButton.Type.NotExpression];
	},

	select: function ()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return;
		if (!this._selected)
		{
			this.setBackGroundImage("PiktomirK/select-romb-border.png");
			this.setBackGroundImageCapInsets(cc.rect(28, 22, 70, 6));
			this._selected = true;
			this.setTag(ProgramContainerLayer.SELECTED_TAG);
		}
	},

	highlight: function ()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return;
		this.setBackGroundImage("PiktomirK/select-romb-border.png");
		this.setBackGroundImageCapInsets(cc.rect(28, 22, 70, 6));
	},

	drawBackground: function ()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return;
		if (!this.isEditable())
			this.setBackGroundImage("PiktomirK/romb-blocked.png");
		else
			this.setBackGroundImage("PiktomirK/romb-border.png");
		this.setBackGroundImageScale9Enabled(true);
		this.setCascadeOpacityEnabled(false);
		this.setBackGroundImageCapInsets(cc.rect(28, 22, 70, 6));
	},

	clickCallback: function ()
	{
		this._treeNode.value = !this._treeNode.value ? 1 : 0;
		this.text.setString(LocalizedString(pm.BoolText[this._treeNode.value]));
	},

	calculateSize: function (forParent)
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			this.setContentSize(this.text.getContentSize());
		else
			this.sizeOfContainer(this);

		if(!forParent)
			this._parentNode.calculateSize();
	}
});


