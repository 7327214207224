/**
 * Created by Nikita Besshaposhnikov on 16.11.15.
 */

/**
 * @class Layer for {@link pm.data.PhysicalRobotMap}.
 * @implements SquarableMapLayer
 */
var PhysicalRobotMapLayer = SquarableMapLayer.extend(/** @lends PhysicalRobotMapLayer#*/{

	ctor: function(map)
	{
		this._super(map);
		this._orientation = pm.MapLayer2D.Orientation.Ortho;
		this._mapElementSize = cc.size(96 - 7, 96- 7);
	},

	hasChangeOrientationOption: function()
	{
		return false;
	}
});
