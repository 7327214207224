/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.IfNode = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{

	_condition: null,
	_thenBody: null,

	ctor: function (treeNode, parent, syntaxModule, type)
	{
		this.isPartlyClonable = true;
		this._withBorder = true;
		this.type = FunctionButton.Type.IfStatement;
		this._super(parent, treeNode, syntaxModule);

		this.setLayoutType(ccui.Layout.RELATIVE);

		this._type = type;
		if (this._condition === null)
			this._condition = new pmui.ReplaceNode([FunctionButton.Type.Bool, FunctionButton.Type.Variable, FunctionButton.Type.AndOrExpression, FunctionButton.Type.LogicExpression, FunctionButton.Type.NotExpression], this);

		pm.syntaxModules.constructIfNode(this);
	},

	_firstElemSelect: function()
	{
		if (!this._selected)
		{
			this.setBackGroundImage("PiktomirK/select-func-border.png");
			this.setBackGroundImageScale9Enabled(true);
			this.setCascadeOpacityEnabled(false);
			this.setBackGroundImageCapInsets(cc.rect(15, 15, 10, 10));
			this._selected = true;
			this.setTag(ProgramContainerLayer.SELECTED_TAG);
		}
	},

	addChildNode: function(node)
	{
		var tag = node._treeNode.getTagName();
		if(tag === pm.IFCondTag)
		{
			var parent = this._treeNode;
			parent.removeChild(this._condition._treeNode);

			var align = this._condition.getLayoutParameter().clone();
			node.setLayoutParameter(align);

			this.removeChild(this._condition);
			this._condition = node;
			this._condition._treeNode.setTagName(pm.IFCondTag);
			this.addChild(node);
		}
		else if(tag === pm.IFThenTag)
		{
			this._thenBody = node;
			this.createALignSome(this._thenBody, "then", "body", ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			this.addChild(this._thenBody);
		}
		else if(tag === pm.IFElseTag)
		{
			if(this.getChildByName(pmui.IfNode.ELSE_BLOCK_NAME).isElseBlock())
				return;
			this.createALignSome(node, "body", "elseBlock", ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN,
				-ProgramContainerLayer.SEPARATOR_SIZEX, pmui.NodeLayer.SEPARATORY, 0, 0);
			this.getChildByName(pmui.IfNode.ELSE_BLOCK_NAME).removeFromParent();
			node.setName(pmui.IfNode.ELSE_BLOCK_NAME);
			this.addChild(node);
		}
	},

	setElseBody: function (node)
	{

	},

	_removeNode: function (node)
	{
		if (!node._treeNode)
			return;
		if (node._treeNode.getTagName() === pm.IFCondTag)
		{
			var boolNode = pm.data.SyntaxTree.createNode(this._treeNode, pm.data.Node.Type.Bool, pm.BoolTrue);
			boolNode.setTagName(pm.IFCondTag);
			pm.sendCustomEvent(pm.PARSE_NODE, {node: boolNode, parent: this});
		}
		else if (node._treeNode.getTagName() === pm.IFElseTag)
		{
			var elseBlock = this.getChildByName(pmui.IfNode.ELSE_BLOCK_NAME);
			this._treeNode.removeChild(elseBlock.getBody()._treeNode);
			elseBlock.removeFromParent();
			this._treeNode.value = pm.IfVals.IF_ST;

			var newElseBlock = new pmui.NodeLayer(this, null);
			newElseBlock.select = this._firstElemSelect;
			newElseBlock.setContentSize(pmui.BodyNode.FIRST_WIDTH, pmui.BodyNode.FIRST_HEIGHT);
			newElseBlock._repTypes = [FunctionButton.Type.ElseBlock];
			this.createALignSome(newElseBlock, "body", "elseBlock", ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN,
				-ProgramContainerLayer.SEPARATOR_SIZEX, 0, 0, 0);
			this.addChild(newElseBlock);
			newElseBlock.setName(pmui.IfNode.ELSE_BLOCK_NAME);
		}
		else {
			return;
		}
	},

	calculateSize: function (isPar)
	{
		pm.syntaxModules.calculateSizeOfIfNode(this);

		this.forceDoLayout();
		if (!isPar)
			this._parentNode.calculateSize();
	},

	clone: function ()
	{
		return pm.appUtils.generateFunctionButton(this.type, this._type);
	},

	getChildrenNodes: function ()
	{
		if (this.getChildByName(pmui.IfNode.ELSE_BLOCK_NAME))
			return [this._condition, this._thenBody, this.getChildByName(pmui.IfNode.ELSE_BLOCK_NAME)];
		else
			return [this._condition, this._thenBody];
	},

	canAdd: function(element)
	{
		if(this._addTypes.indexOf(element) !== -1 )
			return true;
		return false;
	}
});


pmui.IfNode.vals = [
	pm.IfVals.IF_ST,
	pm.IfVals.IF_ELSE
]
pmui.IfNode.ELSE_BLOCK_NAME = "else_block_name";
pmui.IfNode.END_TAG = 2;
pmui.IfNode.IF_TEXT_TAG = 3;
pmui.IfNode.IF_COND_TAG = 4;
pmui.IfNode.THEN_TEXT_TAG = 5;
pmui.IfNode.ELSE_WORD_TAG = 3;
