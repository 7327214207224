/**
 * Created by Kirill Mashchenko on 23.09.2017.
 */

var OutputLayer = GlobalRobotLayer.extend(/** @lends CounterLayer# */{

	ctor: function (level, robot, robotType)
	{
		this._super(LocalizedString("OutputLayer"), robot, robotType);
	}
});
