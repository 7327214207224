/**
 * Created by antonded on 13.11.14.
 */

/**
 * @class Pop-up layer for configuring sound settings.
 * @extends cc.LayerColor
 *
 */
var SoundPopupLayer = pmui.PopupLayer.extend(/** @lends SoundPopupLayer#*/{

	ctor: function ()
	{

		this._super(pmui.PopupLayer.HIDE_DURATION_NEVER);
		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setContentSizeWithBorder(100, 100);

		var backgroundVolumeLabel = new ccui.Text(LocalizedString("BackgroundVolume"), pm.settings.fontName, pm.settings.fontSize);
		var effectVolumeLabel = new ccui.Text(LocalizedString("EffectVolume"), pm.settings.fontName, pm.settings.fontSize);

		this._backgroundSlider = new ccui.Slider();
		this._backgroundSlider.loadBarTexture(pm.spriteUtils.getInterfaceElementName("sliderTrack"), ccui.Widget.PLIST_TEXTURE);
		this._backgroundSlider.loadProgressBarTexture(pm.spriteUtils.getInterfaceElementName("sliderProgress"), ccui.Widget.PLIST_TEXTURE);
		this._backgroundSlider.loadSlidBallTextureNormal(pm.spriteUtils.getInterfaceElementName("sliderThumb"), ccui.Widget.PLIST_TEXTURE);

		this._backgroundSlider.addEventListener(this._backgroundVolumeChanged.bind(this));

		this._backgroundSlider.setPercent(100 * pm.settings.getBackgroundVolume());

		this._effectSlider = new ccui.Slider();
		this._effectSlider.loadBarTexture(pm.spriteUtils.getInterfaceElementName("sliderTrack"), ccui.Widget.PLIST_TEXTURE);
		this._effectSlider.loadProgressBarTexture(pm.spriteUtils.getInterfaceElementName("sliderProgress"), ccui.Widget.PLIST_TEXTURE);
		this._effectSlider.loadSlidBallTextureNormal(pm.spriteUtils.getInterfaceElementName("sliderThumb"), ccui.Widget.PLIST_TEXTURE);

		this._effectSlider.addEventListener(this._effectVolumeChanged.bind(this));

		this._effectSlider.setPercent(100 * pm.settings.getEffectVolume());

		var backgroundVolumeLabelAlign = new ccui.RelativeLayoutParameter();
		backgroundVolumeLabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		backgroundVolumeLabelAlign.setMargin(pmui.OvalLayout.STANDARD_BORDER_RADIUS, pmui.OvalLayout.STANDARD_BORDER_RADIUS/2, 0, 0);
		backgroundVolumeLabelAlign.setRelativeName("background");
		backgroundVolumeLabel.setLayoutParameter(backgroundVolumeLabelAlign);

		var effectVolumeLabelAlign = new ccui.RelativeLayoutParameter();
		effectVolumeLabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
		effectVolumeLabelAlign.setMargin(pmui.OvalLayout.STANDARD_BORDER_RADIUS, 0, 0, pmui.OvalLayout.STANDARD_BORDER_RADIUS/2);
		effectVolumeLabelAlign.setRelativeName("effect");
		effectVolumeLabel.setLayoutParameter(effectVolumeLabelAlign);

		var backgroundSliderAlign = new ccui.RelativeLayoutParameter();
		backgroundSliderAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		backgroundSliderAlign.setMargin(5, 0, 0, 0);
		backgroundSliderAlign.setRelativeToWidgetName("background");
		this._backgroundSlider.setLayoutParameter(backgroundSliderAlign);

		var effectSliderAlign = new ccui.RelativeLayoutParameter();
		effectSliderAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		effectSliderAlign.setMargin(5, 0, 0, 0);
		effectSliderAlign.setRelativeToWidgetName("effect");
		this._effectSlider.setLayoutParameter(effectSliderAlign);

		this.addChild(backgroundVolumeLabel);
		this.addChild(this._backgroundSlider);
		this.addChild(effectVolumeLabel);
		this.addChild(this._effectSlider);

		var height = effectVolumeLabel.getContentSize().height + backgroundVolumeLabel.getContentSize().height + 10.0;
		var width = Math.max(backgroundVolumeLabel.getContentSize().width, effectVolumeLabel.getContentSize().width);
		width += 5 + this._effectSlider.getContentSize().width;
		width += 2*pmui.OvalLayout.STANDARD_BORDER_RADIUS;

		this.setContentSize(width, height);

	},

	_backgroundVolumeChanged: function(target, eventType)
	{
		if(eventType === ccui.Slider.EVENT_PERCENT_CHANGED)
		{
			pm.settings.setBackgroundVolume(target.getPercent() / 100);
			pm.audioUtils.setBackgroundMusicVolume(target.getPercent() / 100);
		}
	},
	_effectVolumeChanged: function(target, eventType)
	{
		if(eventType === ccui.Slider.EVENT_PERCENT_CHANGED)
		{
			pm.settings.setEffectVolume(target.getPercent() / 100);
			pm.audioUtils.setEffectVolume(target.getPercent() / 100);
		}
	},

	_backgroundSlider: 0,
	_effectSlider: 0
});
