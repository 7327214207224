/**
 * Created by Nikita Besshaposhnikov on 13.06.15.
 */

/**
 * @class Sprite wrapper for static sprites for {@link pm.TopologicalSortLayer}.
 * @extends pm.ObjectSprite2D
 * @constructor
 * @param {cc.SpriteFrame} frame
 * @param {Boolean} [isIsometric = false] is sprite drawn in isometric style.
 */
pm.StaticSprite = pm.ObjectSprite2D.extend(/** @lends pm.StaticSprite#*/{

	_realMinPoint: cc.p(),
	_realMaxPoint: cc.p(),

	ctor: function(frame)
	{
		this._super(frame);
	},

	getMinPoint: function()
	{
		return this._realMinPoint;
	},

	getMaxPoint: function()
	{
		return this._realMaxPoint;
	},

	_calculateRealBBPoints: function()
	{
		this._realMaxPoint = cc.pointApplyAffineTransform(this._maxPoint, this.sprite.getNodeToParentTransform());
		this._realMinPoint = cc.pointApplyAffineTransform(this._minPoint, this.sprite.getNodeToParentTransform());
	},

	setRealPosition: function(pos, delta)
	{
		pm.ObjectSprite2D.prototype.setRealPosition.call(this, pos, delta);
		this._calculateRealBBPoints();
	},

	/**
     * Stops animation
     * @function
     * @name pm.DynamicSprite#stopAnimation
     */
	stopAnimation: function()
	{
		if(!CORE_BUILD)
			this.sprite.stopAllActions();
	}
});
