/**
 * Created by Kirill Mashchenko on 07.07.2018.
 */

/**
 * @class Common interface for objects pushed by {@link pm.data.TrainRobot}
 * @interface
 * @extends pm.Object
 */
var TrainObject = pm.Object.extend(/** @lends TrainObject#*/{

	oldPosition: cc.p(),

	parentObject: null,
	childObject: null,
	isMagnet: false,
	magnetRobotId: null,
	firstForRobotId: null,

	connectionSprites: {},

	ctor: function()
	{
		this._addNonEnumerableProps("oldPosition", "parentObject", "childObject", "isMagnet", "magnetRobotId",
			"firstForRobotId", "connectionSprites");
		this.parentObject = null;
		this.childObject = null;
		this._super();
	},

	/**
     * Sets position of object by element position.
     * @param {cc.Point} point Point in element coordinates.
     */
	setPosition: function(point)
	{
		this._removeFromCurrentPosition();

		this.position = point;
		this.oldPosition = this.position;

		this.parentMap.element(point).addObject(this);
	},

	/**
     * Returns if push object is moving
     */
	isMoving: function()
	{
		return this.oldPosition.x !== this.position.x || this.oldPosition.y !== this.position.y;
	},

	/**
     * Moves object to element position.
	 * @param {cc.Point} target Move coordinate in elements.
	 * @param {boolean} skipAnimation Need to play animation.
     */
	moveTo: function(target)
	{
		if (this.position.x === target.x && this.position.y === target.y)
			return;

		this.oldPosition = this.position;
		this.position = target;

		this.parentMap.element(target).addObject(this);
	},

	_endMoving: function()
	{
		this._isPlayingAnimation = false;

		this._removeFromOldPosition();
		this.oldPosition = this.position;
	},

	_removeFromOldPosition: function()
	{
		if(this.oldPosition.x < this.parentMap.width && this.oldPosition.y < this.parentMap.height)
		{
			if(this.parentMap.element(this.oldPosition).containsObject(this))
				this.parentMap.element(this.oldPosition).removeObject(this);
		}
	},

	_removeFromCurrentPosition: function()
	{
		if(this.position.x < this.parentMap.width && this.position.y < this.parentMap.height)
		{
			if(this.parentMap.element(this.position).containsObject(this))
				this.parentMap.element(this.position).removeObject(this);
		}
	},

	updateSprite: function(isBroken)
	{
		if (isBroken === undefined)
			isBroken = false;

		if (!isBroken)
		{
			this.sprite.setRealPosition(this.position);

			this._updateConnectionSprites();
		}
		else
		{
			this.sprite.setRealPosition(this.oldPosition);

			var childObjectPosition, parentObjectPosition;
			if (this.childObject)
				childObjectPosition = this.childObject.oldPosition;
			if (this.parentObject)
				parentObjectPosition = this.parentObject.oldPosition;

			this._updateConnectionSprites(this.oldPosition, childObjectPosition, parentObjectPosition);
		}
	},

	reset: function ()
	{
		this._isPlayingAnimation = false;
		this._removeFromOldPosition();
		this.setPosition(this.startPosition);
		this.parentObject = null;
		this.childObject = null;
		this.isMagnet = false;
		this.magnetRobotId = null;
		this.firstForRobotId = null;

		for (var s in this.connectionSprites)
			this.connectionSprites[s].setVisible(false);

		this.sprite.activeSprite.setVisible(false);
	},

	generateSprite: function(previewDraw) {},

	generateObjectConnections: function()
	{
		var width = this.sprite.sprite.getContentSize().width;
		var height = this.sprite.sprite.getContentSize().height;

		this.connectionSprites[MapDirection4.Left] = new cc.Sprite("Objects/TrainObjectConnectionLeftRight.png");
		this.connectionSprites[MapDirection4.Left].setVisible(false);
		this.connectionSprites[MapDirection4.Left].setPosition(cc.p(width * 0.2, height * 0.9));

		this.sprite.sprite.addChild(this.connectionSprites[MapDirection4.Left]);


		this.connectionSprites[MapDirection4.Right] = new cc.Sprite("Objects/TrainObjectConnectionLeftRight.png");
		this.connectionSprites[MapDirection4.Right].setVisible(false);
		this.connectionSprites[MapDirection4.Right].setPosition(cc.p(width * 0.8, height * 0.6));

		this.sprite.sprite.addChild(this.connectionSprites[MapDirection4.Right]);


		this.connectionSprites[MapDirection4.Up] = new cc.Sprite("Objects/TrainObjectConnectionUpDown.png");
		this.connectionSprites[MapDirection4.Up].setVisible(false);
		this.connectionSprites[MapDirection4.Up].setPosition(cc.p(width * 0.8, height * 0.9));

		this.sprite.sprite.addChild(this.connectionSprites[MapDirection4.Up]);


		this.connectionSprites[MapDirection4.Down] = new cc.Sprite("Objects/TrainObjectConnectionUpDown.png");
		this.connectionSprites[MapDirection4.Down].setVisible(false);
		this.connectionSprites[MapDirection4.Down].setPosition(cc.p(width * 0.2, height * 0.6));

		this.sprite.sprite.addChild(this.connectionSprites[MapDirection4.Down]);
	},

	getState: function ()
	{
		return {
			position: this.position,
			parentObject: this.parentObject,
			childObject: this.childObject,
			isMagnet: this.isMagnet,
			magnetRobotId: this.magnetRobotId,
			firstForRobotId: this.firstForRobotId
		};
	},

	setState: function (state) {
		if (this.position !== state.position)
			this.moveTo(state.position);

		this.parentObject = state.parentObject;
		this.childObject = state.childObject;
		this.isMagnet = state.isMagnet;
		this.magnetRobotId = state.magnetRobotId;
		this.firstForRobotId = state.firstForRobotId;

		this._updateConnectionSprites();
	},

	_updateConnectionSprites: function(position, childObjectPosition, parentObjectPosition)
	{
		if (position === undefined)
			position = this.position;

		this.connectionSprites[MapDirection4.Left].setVisible(false);
		this.connectionSprites[MapDirection4.Up].setVisible(false);
		this.connectionSprites[MapDirection4.Right].setVisible(false);
		this.connectionSprites[MapDirection4.Down].setVisible(false);

		if (this.childObject)
		{
			if(childObjectPosition === undefined)
				childObjectPosition = this.childObject.position;

			this.setConnectionSpritesAtPosition(childObjectPosition, position, true, true);
		}

		if (this.parentObject)
		{
			if(parentObjectPosition === undefined)
				parentObjectPosition = this.parentObject.position;

			this.setConnectionSpritesAtPosition(parentObjectPosition, position, true, true);
		}

		if (this.firstForRobotId !== null)
		{
			for (var i = 0; i < this.parentMap.parentLevel.robots.length; ++i)
			{
				var robot = this.parentMap.parentLevel.robots[i];
				if (robot.id === this.firstForRobotId)
				{
					this.setConnectionSpritesAtPosition(robot.position, position, true, true);
					this.setConnectionSpritesAtPositionRightDown(robot.position, position, true, true);
				}
			}
		}

		this.sprite.activeSprite.setVisible(false);

		if (this.magnetRobotId !== null)
			this.sprite.activeSprite.setVisible(true);
	},

	setActiveVisible: function (flag)
	{
		if (!CORE_BUILD && !pm.settings.isAnimationDisabled())
			this.sprite.activeSprite.setVisible(flag);
	},

	setConnectionVisible: function (connection, flag)
	{
		if (!CORE_BUILD && !pm.settings.isAnimationDisabled())
			this.connectionSprites[connection].setVisible(flag);
	},

	setConnectionSpritesAtPosition: function(firstObjectPosition, secondObjectPosition, flag, forced)
	{
		if (CORE_BUILD || (!forced && pm.settings.isAnimationDisabled()))
			return;

		if (flag === undefined)
			flag = true;

		if (secondObjectPosition === undefined)
			secondObjectPosition = this.position;

		if (secondObjectPosition.x === firstObjectPosition.x)
		{
			if (secondObjectPosition.y > firstObjectPosition.y)
				this.connectionSprites[MapDirection4.Up].setVisible(flag);
		}
		else if (secondObjectPosition.y === firstObjectPosition.y)
		{
			if (secondObjectPosition.x > firstObjectPosition.x)
				this.connectionSprites[MapDirection4.Left].setVisible(flag);
		}
	},

	setConnectionSpritesAtPositionRightDown: function(firstObjectPosition, secondObjectPosition, flag, forced)
	{
		if (CORE_BUILD || (!forced && pm.settings.isAnimationDisabled()))
			return;

		if (flag === undefined)
			flag = true;

		if (secondObjectPosition === undefined)
			secondObjectPosition = this.position;

		if (secondObjectPosition.x === firstObjectPosition.x)
		{
			if (secondObjectPosition.y < firstObjectPosition.y)
				this.connectionSprites[MapDirection4.Down].setVisible(flag);
		}
		else if (secondObjectPosition.y === firstObjectPosition.y)
		{
			if (secondObjectPosition.x < firstObjectPosition.x)
				this.connectionSprites[MapDirection4.Right].setVisible(flag);
		}
	},

	updateNumberSprite: function ()
	{
		this.sprite.updateNumber(this.visualId);
	}
});
