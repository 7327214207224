/**
 * Created by Kirill Mashchenko on 25.01.18.
 */

var FlagRobotLayer = ccui.Layout.extend({

	_innerLayout: null,
	_functionButtonLayout: null,
	_colorLayer: null,
	_borderLayout: null,
	_showHideButton: null,

	_hidden: true,

	_controlledMode: false,
	_buttonClickCallback: null,
	_buttonClickCallbackTarget: null,

	ctor: function (robot)
	{
		this._super();

		this._robot = robot;

		this._innerLayout = new ccui.Layout();
		this._innerLayout.setLayoutType(ccui.Layout.RELATIVE);

		this._borderLayout = new ccui.Layout();
		this._borderLayout.setContentSize(200, 200);

		this._borderLayout.setBackGroundImageScale9Enabled(true);
		this._borderLayout.setBackGroundImage("System/FFBL_Frame.png");
		this._borderLayout.setBackGroundImageCapInsets(cc.rect(80, 90, 50, 20));

		this._functionButtonLayout = new ccui.VBox();
		this._functionButtonLayout.setContentSize(200, 200);

		var fButtonLayoutAlign = new ccui.RelativeLayoutParameter();
		fButtonLayoutAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
		fButtonLayoutAlign.setRelativeName("fButtonLayout");

		this._functionButtonLayout.setLayoutParameter(fButtonLayoutAlign);

		this._colorLayer = new ccui.Layout();
		this._colorLayer.setContentSize(200 - 2*FlagRobotLayer.FBL_BORDER, 200 - 2*FlagRobotLayer.FBL_BORDER);
		this._colorLayer.setBackGroundColorType(ccui.Layout.BG_COLOR_SOLID);
		this._colorLayer.setBackGroundColor(ProgramLayer.PROGRAM_COLOR);

		var colorAlign = new ccui.RelativeLayoutParameter();
		colorAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);

		this._colorLayer.setLayoutParameter(colorAlign);

		this._innerLayout.addChild(this._borderLayout);
		this._innerLayout.addChild(this._colorLayer, -10);
		this._innerLayout.addChild(this._functionButtonLayout);

		var showHideButtonAlign = new ccui.RelativeLayoutParameter();
		showHideButtonAlign.setRelativeToWidgetName("fButtonLayout");

		var borderAlign = new ccui.RelativeLayoutParameter();
		borderAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);

		if(this._robot.getType() === pm.GlobalRobotType.OrangeFlag)
		{
			this._borderLayout.setScaleX(-1);
			borderAlign.setMargin(this._borderLayout.width / 2 + FlagRobotLayer.SCALE_SHIFT, 0, 0, 0);

			showHideButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_ABOVE_CENTER);
			showHideButtonAlign.setMargin(FlagRobotLayer.POSITION_CORRECTION, 0, 0, -FlagRobotLayer.SH_POSITION_CORRECTION);

			this._showHideButton = new pmui.Button(pm.spriteUtils.getIconName("offblShow", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("offblShow", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("offblShow", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.ORANGE_FLAG_SHOW_HIDE, this._showHideButton);
		}
		else
		{
			showHideButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_ABOVE_CENTER);
			showHideButtonAlign.setMargin(FlagRobotLayer.POSITION_CORRECTION, 0, 0, -FlagRobotLayer.SH_POSITION_CORRECTION);

			this._showHideButton = new pmui.Button(pm.spriteUtils.getIconName("gffblShow", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("gffblShow", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("gffblShow", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.GREEN_FLAG_SHOW_HIDE, this._showHideButton);
		}

		this._borderLayout.setLayoutParameter(borderAlign);
		this._showHideButton.setLayoutParameter(showHideButtonAlign);

		this._showHideButton.addClickEventListener(this._showHide.bind(this));

		this._innerLayout.addChild(this._showHideButton);

		var robotSprite = this._robot.generateRobotSprite();
		robotSprite.getSprite().setAnchorPoint(cc.p(0, 0));

		var robotLayout = new ccui.Layout();
		robotLayout.setContentSize(robotSprite.getSprite().getContentSize());

		var robotLayoutAlign = new ccui.RelativeLayoutParameter();

		if(this._robot.getType() === pm.GlobalRobotType.GreenFlag)
			robotLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_ABOVE_RIGHTALIGN);
		else
			robotLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_ABOVE_LEFTALIGN);

		robotLayoutAlign.setRelativeToWidgetName("fButtonLayout");
		robotLayout.setLayoutParameter(robotLayoutAlign);

		robotLayout.addChild(robotSprite.getSprite());

		this._innerLayout.addChild(robotLayout);

		this._drawFunctionButtons();

		var width = this._functionButtonLayout.width;
		var height = this._functionButtonLayout.height + this._showHideButton.height + robotSprite.getSprite().height;

		this._innerLayout.setContentSize(width, height);
		this.setContentSize(width, height);

		this.addChild(this._innerLayout);

		this._innerLayout.setPosition(0, -this._functionButtonLayout.height);

		pm.registerCustomEventListener(pm.ENABLE_CONTROLLED_MODE_STR, function(event)
		{
			var data = event.getUserData();
			if(data !== undefined)
				this._enableControlledMode(data);

		}.bind(this), this);

		pm.registerCustomEventListener(pm.DISABLE_CONTROLLED_MODE_STR, this._disableControlledMode.bind(this), this);
	},

	_drawFunctionButtons: function()
	{
		var height = 0;
		var width = 0;

		if (this._robot.nativeFunctions.length > 0)
		{
			var methodBar = this._createFunctionButtonsBar(FunctionButton.Type.Method, this._robot.nativeFunctions);

			height += methodBar.height + FlagRobotLayer.COMPONENT_SEPARATOR;
			width = Math.max(width, methodBar.width);

			this._functionButtonLayout.addChild(methodBar);
		}

		if (this._robot.conditions.length > 0)
		{
			var conditionBar = this._createFunctionButtonsBar(FunctionButton.Type.Condition, this._robot.conditions);

			height += conditionBar.height + FlagRobotLayer.COMPONENT_SEPARATOR;
			width = Math.max(width, conditionBar.width);

			this._functionButtonLayout.addChild(conditionBar);
		}

		for(var i = 0; i < this._functionButtonLayout.getChildrenCount(); ++i)
		{
			var align = new ccui.LinearLayoutParameter();
			align.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);

			if(i === 0)
			{
				align.setMargin(FlagRobotLayer.POSITION_CORRECTION,
					FlagRobotLayer.FBL_TOP_MARGIN + FlagRobotLayer.FBL_BORDER, 0, 0);
			}
			else
			{ align.setMargin(FlagRobotLayer.POSITION_CORRECTION, FlagRobotLayer.COMPONENT_SEPARATOR, 0, 0); }

			this._functionButtonLayout.getChildren()[i].setLayoutParameter(align);
		}

		width += 2 * FlagRobotLayer.FBL_LEFT_MARGIN;
		height += 2* FlagRobotLayer.FBL_TOP_MARGIN;

		this._colorLayer.setContentSize(width, height);

		width += 2 * FlagRobotLayer.FBL_BORDER;
		height += FlagRobotLayer.FBL_BORDER;

		this._borderLayout.setContentSize(width, height);
		this._functionButtonLayout.setContentSize(width, height);
	},

	_createFunctionButtonsBar: function(type, values)
	{
		var layout = new FunctionButtonBar(ProgramContainerLayer.DNDNAME, this._dragCallback, this._canDragButtons, this);

		for(var i = 0; i < values.length; ++i)
		{
			var button = pm.appUtils.generateFunctionButton(type, values[i]);

			pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAME_PATTERNS.SELECT.format(values[i]), button, true);

			var buttonMargin = new ccui.LinearLayoutParameter();

			if(i !== 0)
				buttonMargin.setMargin(ProgramLayer.MENU_SEPARATOR, 0, 0, 0);

			button.setLayoutParameter(buttonMargin);

			layout.addChild(button);
		}

		layout.setContentSize(values.length * (ProgramContainerLayer.BUTTON_SIZE + ProgramLayer.MENU_SEPARATOR),
			ProgramContainerLayer.BUTTON_SIZE);

		return layout;
	},

	_showHide: function()
	{
		FunctionButton.deselect();

		var y = this._functionButtonLayout.height;

		if (this._hidden)
		{
			var moveLayer = cc.moveBy(pm.SYSTEM_ANIMATION_DELAY, cc.p(0, y));

			var type = this._robot.getType();

			var onShow = cc.callFunc(function (button)
			{
				if(type === pm.GlobalRobotType.OrangeFlag)
				{
					pm.sendCustomEvent(pm.ORANGE_FLAG_LAYER_OPENED_EVENT_STR);

					this._showHideButton.loadTextures(pm.spriteUtils.getIconName("offblHide", pm.NORMAL_STATE),
						pm.spriteUtils.getIconName("offblHide", pm.SELECTED_STATE),
						pm.spriteUtils.getIconName("offblHide", pm.DISABLED_STATE),
						ccui.Widget.PLIST_TEXTURE);
				}
				else
				{
					pm.sendCustomEvent(pm.GREEN_FLAG_LAYER_OPENED_EVENT_STR);

					this._showHideButton.loadTextures(pm.spriteUtils.getIconName("gffblHide", pm.NORMAL_STATE),
						pm.spriteUtils.getIconName("gffblHide", pm.SELECTED_STATE),
						pm.spriteUtils.getIconName("gffblHide", pm.DISABLED_STATE),
						ccui.Widget.PLIST_TEXTURE);
				}
			}, this);

			this._innerLayout.runAction(cc.sequence(moveLayer, onShow));

			this._hidden = false;
		}
		else
		{
			var moveLayer = cc.moveBy(pm.SYSTEM_ANIMATION_DELAY, cc.p(0, -y));

			var type = this._robot.getType();

			var onClose = cc.callFunc(function ()
			{
				if(type === pm.GlobalRobotType.OrangeFlag)
				{
					pm.sendCustomEvent(pm.ORANGE_FLAG_LAYER_CLOSED_EVENT_STR);

					this._showHideButton.loadTextures(pm.spriteUtils.getIconName("offblShow", pm.NORMAL_STATE),
						pm.spriteUtils.getIconName("offblShow", pm.SELECTED_STATE),
						pm.spriteUtils.getIconName("offblShow", pm.DISABLED_STATE),
						ccui.Widget.PLIST_TEXTURE);
				}
				else
				{
					pm.sendCustomEvent(pm.GREEN_FLAG_LAYER_CLOSED_EVENT_STR);

					this._showHideButton.loadTextures(pm.spriteUtils.getIconName("gffblShow", pm.NORMAL_STATE),
						pm.spriteUtils.getIconName("gffblShow", pm.SELECTED_STATE),
						pm.spriteUtils.getIconName("gffblShow", pm.DISABLED_STATE),
						ccui.Widget.PLIST_TEXTURE);
				}
			}, this);

			this._innerLayout.runAction(cc.sequence(moveLayer, onClose));

			this._hidden = true;
		}
	},

	_dragCallback: function(element, eventType, touch)
	{
		if(eventType === pmui.DragAndDropLayout.Event.CLICKED)
		{
			if(this._controlledMode)
			{
				if(this._buttonClickCallback)
					this._buttonClickCallback.call(this._buttonClickCallbackTarget, element);

				return;
			}

			element.select();
		}
	},

	_canDragButtons: function()
	{
		return !this._controlledMode;
	},

	_enableControlledMode: function(controlledModeData)
	{
		this._controlledMode = true;
		this._buttonClickCallback = controlledModeData.addMethodCallback;
		this._buttonClickCallbackTarget = controlledModeData.callbackTarget;
	},

	_disableControlledMode: function()
	{
		this._controlledMode = false;
		this._buttonClickCallback = null;
		this._buttonClickCallbackTarget = null;
	}

});

FlagRobotLayer.FBL_LEFT_MARGIN = 10;
FlagRobotLayer.FBL_TOP_MARGIN = 10;
FlagRobotLayer.FBL_BORDER = 7;
FlagRobotLayer.SH_POSITION_CORRECTION = 1;
FlagRobotLayer.COMPONENT_SEPARATOR = 5;
FlagRobotLayer.SCALE_SHIFT = 25;
FlagRobotLayer.POSITION_CORRECTION = 3;
