/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.CommandNode = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{
	_params: [],
	_prevWidth: 0,
	_name: null,
	_rBrace: null,
	_robotType: null,

	ctor: function (treeNode, parent, syntaxModule, commandName, robotType, isIntMethod)
	{
		this.type = FunctionButton.Type.Method;
		this._robotType = robotType;
		if(isIntMethod)
		{
			this._withRoundBorder = true;
			this.type = FunctionButton.Type.IntMethod;
			this._repTypes = [FunctionButton.Type.IntMethod];
		}
		else
			this._withBorder = true;

		this._super(parent, treeNode, syntaxModule);
		if(treeNode)
			this._params = new Array(treeNode.getChildren().length);
		this.setLayoutType(ccui.Layout.RELATIVE);

		this._name = treeNode.value;
		var name = this.createText(commandName);

		var leftMargin = this._params.length === 0 ? Math.max((ProgramLayer.BUTTON_WIDTH - name.width) / 2, pmui.NodeLayer.SEPARATORX) : pmui.NodeLayer.SEPARATORX;
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			leftMargin = pmui.NodeLayer.SEPARATORX;
		this.createALignSome(name, "", pmui.CommandNode.COMMAND_NAME_TAG, ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL,
			leftMargin, 0, pmui.NodeLayer.SEPARATORX, 0);
		this.addChild(name);

		pm.registerCustomEventListener(pm.FUNCTION_DEFINITION_REMOVED, function (event)
		{
			var data = event.getUserData();
			if (data  === this._name)
				this._removeNodeFromTree();
		}.bind(this), this);

		pm.registerCustomEventListener(pm.FUNCTION_PARAMS_CHANGED, function (event)
		{
			var data = event.getUserData();
			if (data.name  === this._name)
			{
				this._removeNodeFromTree();
			}
		}.bind(this), this);

	},

	clone: function ()
	{
		if(this._withRoundBorder)
			return pm.appUtils.generateFunctionButton(FunctionButton.Type.IntMethod, this._treeNode.value);
		else if(this._robotType === pm.GlobalRobotType.Output || pmui.FunctionNode.vals.has(this._treeNode.value))
			return new VarFunctionButton(FunctionButton.Type.Method, this._treeNode.value);
		else
			return pm.appUtils.generateFunctionButton(FunctionButton.Type.Method, this._treeNode.value);
	},

	addChildNode: function (node)
	{
		var tag = node._treeNode.getTagName();
		if (tag.indexOf(pm.CommanParamTag) !== -1)
		{
			var paramOrder = Number(tag.replace(pm.CommanParamTag, ""));
			var parent = this._treeNode;
			if (!this._params[paramOrder] && paramOrder > 0) //first adding of the second and else elems ", 0"
			{
				var comma = this.createText(",");
				this.createALignSome(comma, pm.CommanParamTag + (paramOrder - 1), pmui.CommandNode.COMMAND_COMMA_TAG + paramOrder, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER,
					0, 0, 0, 0);
				this.addChild(comma);

				this.createALignSome(node, pmui.CommandNode.COMMAND_COMMA_TAG + paramOrder, tag, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER,
					pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORY, 0, pmui.NodeLayer.SEPARATORY);
			}
			else if (paramOrder === 0 && !this._params[paramOrder]) //first adding of first elem "(0)"
			{
				var lBrace = this.createText("(");
				this.createALignSome(lBrace, pmui.CommandNode.COMMAND_NAME_TAG, pmui.CommandNode.COMMAND_L_BRACE_TAG, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER,
					0, 0, 0, 0);

				this.createALignSome(node, pmui.CommandNode.COMMAND_L_BRACE_TAG, tag, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER,
					pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORY, 0, pmui.NodeLayer.SEPARATORY);

				this._rBrace = this.createText(")");
				this.createALignSome(this._rBrace, "", pmui.CommandNode.COMMAND_R_BRACE_TAG, ccui.RelativeLayoutParameter.PARENT_RIGHT_CENTER_VERTICAL,
					0, 0, pmui.NodeLayer.SEPARATORX, 0);

				this.addChild(lBrace);
				this.addChild(this._rBrace);
			}
			else if (this._params[paramOrder]) // param replace
			{
				var param = this._params[paramOrder];
				parent.removeChild(param._treeNode);
				var align = param.getLayoutParameter().clone();
				node.setLayoutParameter(align);
				this.removeChild(param);
			}
			this._params[paramOrder] = node;
			this.addChild(node);
		}
	},

	_removeNode: function (node)
	{
		var treeNode = pm.data.SyntaxTree.createNode(this._treeNode, pm.data.Node.Type.Number, 0);
		treeNode.setTagName(node._treeNode.getTagName());
		pm.sendCustomEvent(pm.PARSE_NODE, {node: treeNode, parent: this});
	},

	getChildrenNodes: function ()
	{
		return this._params;
	},

	calculateSize: function (notCalculateSize)
	{
		var size = this.sizeOfContainer(this, true);
		if(!pm.syntaxModules.isTextual(this.getSyntaxModule()))
		{
			this.setContentSize(Math.max(ProgramLayer.BUTTON_WIDTH, size.width), Math.max(ProgramLayer.BUTTON_HEIGHT, size.height));
		}
		else
			this.setContentSize(size);
		if(!notCalculateSize)
			this._parentNode.calculateSize();
	},

	isMethod: function()
	{
		return true;
	},

	makeCleanUp: function()
	{
		pm.sendCustomEvent(pm.COMMAND_REMOVED);
	},

	getName: function()
	{
		return this._name;
	}
});


pmui.CommandNode.COMMAND_NAME_TAG = "commandName";
pmui.CommandNode.COMMAND_COMMA_TAG = "comma";
pmui.CommandNode.COMMAND_L_BRACE_TAG = "lBrace";
pmui.CommandNode.COMMAND_R_BRACE_TAG = "rBrace";
