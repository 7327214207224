/**
 * Created by Antony Orlovsky on 19.07.19.
 */

/**
 * @class Layer for grasshopper robot settings.
 * @extends PlayerRobotSettingsLayer
 * @constructor
 * @param {pm.AbstractRobot} robot Robot which settings need to display.
 */
var GrasshopperRobotSettingsLayer = PlayerRobotSettingsLayer.extend(/** @lends GrasshopperRobotSettingsLayer# */{
	_paintSlider: null,
	_paintLabel: null,
	_paintValue: 0,

	ctor: function(robot)
	{
		this._super(robot);

		this._label.setString(LocalizedString("Grasshopper") + " " + robot.id + ", " + LocalizedString("Pattern") + " " + robot.parentLevel.programData[robot.groupID].currentIndex);
	}
});
