/**
 * Created by Nikita Besshaposhnikov on 17.11.14.
 */

/**
 * This callback is used when last item is clicked and need to bee popped.
 * @callback MethodStackScrollLayer~popCallback
 */

/**
 * @class Scroll layer for method stack.</br>
 * Displays items as vertical stack. Supports pushing item.</br>
 * If last item is clicked popCallback is called.
 * @extends ccui.Layout
 * @constructor
 * @param {cc.Size} [size]
 */
var MethodStackScrollLayer = ccui.Layout.extend(/** @lends MethodStackScrollLayer# */{

	_background: null,
	_scroll: null,
	_minimalPlaceholderHeight: 0,

	_itemsHeight: 0,

	_itemsPopCallback: null,
	_itemsPopCallbackTarget: null,

	ctor: function(size)
	{
		this._super();

		this.setCascadeOpacityEnabled(true);

		this._background = new ccui.Layout();
		this._background.setContentSize(size);

		this.addChild(this._background);

		this._scroll = new ccui.ListView();

		this._scroll.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._scroll.setContentSize(size);

		this._scroll.setScrollBarEnabled(false);

		this.addChild(this._scroll);

		var placeholder = new ccui.Layout();

		this._scroll.pushBackCustomItem(placeholder);

		if(size)
			this.setContentSize(size);

		this._fitPlaceholderSize();

		this._scroll.addEventListener(this._onClick.bind(this));
	},

	/**
     * Adds items to stack.
     * @param {ccui.Widget} item
     */
	pushItem: function(item)
	{
		item.setTouchEnabled(true);
		this._scroll.insertCustomItem(item, 1);

		this._itemsHeight += item.getContentSize().height;
		this._fitPlaceholderSize();
	},

	/**
     * @sets minimal placeholder height, which is placed at the top of stack.
     * @param {Number} height
     */
	setMinimalPlaceholderHeight: function(height)
	{
		this._minimalPlaceholderHeight = height;
		this._fitPlaceholderSize();
	},

	/**
     * Sets callback on pop item click.
     * @param {MethodStackScrollLayer~popCallback} callback
     * @param {*} target
     */
	setItemsPopCallback: function(callback, target)
	{
		this._itemsPopCallback = callback;
		this._itemsPopCallbackTarget = target;
	},

	/**
     * Removes all items.
     */
	clear: function()
	{
		var childrenCount = this._scroll.getChildrenCount();

		for(var i = 1 ; i < childrenCount; ++i)
			this._scroll.removeItem(1);

		this._itemsHeight = 0;
		this._fitPlaceholderSize();
	},

	_onClick: function(sender, event)
	{
		if(event === ccui.ListView.ON_SELECTED_ITEM_END)
		{
			var deleteCount = this._scroll.getCurSelectedIndex();
			var deleted = true;

			if(this._itemsPopCallback)
				deleted = this._itemsPopCallback.call(this._itemsPopCallbackTarget, deleteCount);

			if(deleted)
			{
				for(var i = 0; i < deleteCount; ++i)
				{
					this._itemsHeight -= this._scroll.getItem(1).getContentSize().height;

					var self = this;

					setTimeout(function ()
					{
						self._scroll.removeItem(1);
					}, 0);
				}
				setTimeout(function ()
				{
					self._fitPlaceholderSize();
				}, 0);
			}
		}
	},

	_fitPlaceholderSize: function()
	{
		var thisSize = this.getContentSize();

		var height = thisSize.height - this._itemsHeight;

		if(height < this._minimalPlaceholderHeight)
			height = this._minimalPlaceholderHeight;

		this._scroll.getItem(0).setContentSize(thisSize.width, height);
		this._scroll.forceDoLayout();

		if(height === this._minimalPlaceholderHeight && this._scroll.getItem(1))
		{
			var firstItemHeight = this._scroll.getItem(1).getContentSize().height;

			var ratio = (firstItemHeight) / (this._scroll.innerHeight - thisSize.height);

			this._scroll.jumpToPercentVertical(100 * ratio);

			this._scroll.scrollToPercentVertical(0, 0.7, true);
		}
	}
});
