
/**
 * This callback is used when new world uploaded.
 * @callback NewWorldLayer~addCallback
 * @param {String} newWorldName
 */

/**
 * @class Layout for new world dialog.
 * @extends pmui.OvalLayout
 * @constructor
 * @param {Object} target Target for callback.
 * @param {NewWorldLayer~addCallback} callback Called on successful login.
 */
var UploadWorldLayer = pmui.OvalLayout.extend(/** @lends NewWorldLayer# */{
	_worldNameInput: null,
	_target: null,
	_callback: null,

	_worldData: null,
	_world: null,

	ctor: function(target, callback)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		this._target = target;
		this._callback = callback;

		this.setContentSizeWithBorder(NewWorldLayer.WIDTH, NewWorldLayer.HEIGHT);

		var loadingLayer = new LoadingLayer(false);
		loadingLayer.setPosition((this.width - pm.settings.getScreenSize().width) / 2,
			(this.height - pm.settings.getScreenSize().height) / 2);

		this.addChild(loadingLayer, -100);

		var label = new ccui.Text(LocalizedString("EnterNewWorldName"), pm.settings.fontName, pm.settings.fontSize);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		labelAlign.setMargin(0, pmui.OvalLayout.STANDARD_BORDER_RADIUS + NewWorldLayer.SEPARATOR, 0, 0);
		labelAlign.setRelativeName("label");
		label.setLayoutParameter(labelAlign);

		this.addChild(label);

		this._worldNameInput = new pmui.TextInput(LocalizedString("NewWorldName"), LocalizedString("NewWorldName"), 20, 200);

		var worldNameAlign = new ccui.RelativeLayoutParameter();
		worldNameAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		worldNameAlign.setRelativeName("worldNameInput");
		worldNameAlign.setRelativeToWidgetName("label");
		worldNameAlign.setMargin(0, 5, 0, 0);
		this._worldNameInput.setLayoutParameter(worldNameAlign);

		this.addChild(this._worldNameInput);

		var buttonLayout = new ccui.HBox();

		var buttonLayoutAlign = new ccui.RelativeLayoutParameter();
		buttonLayoutAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
		buttonLayoutAlign.setMargin(0, 0, 0, pmui.OvalLayout.STANDARD_BORDER_RADIUS + NewWorldLayer.SEPARATOR);
		buttonLayout.setLayoutParameter(buttonLayoutAlign);

		this.addChild(buttonLayout);

		var enter = new pmui.InterfaceButton(this._addWorld, this, LocalizedString("CreateButton"));
		buttonLayout.addChild(enter);

		var cancel = new pmui.InterfaceButton(this._cancelCreate, this, LocalizedString("CancelButton"));
		var cancelMargin = new ccui.LinearLayoutParameter();
		cancelMargin.setMargin(NewWorldLayer.SEPARATOR, 0, 0, 0);
		cancel.setLayoutParameter(cancelMargin);

		buttonLayout.addChild(cancel);

		buttonLayout.setContentSize(enter.getContentSize().width + cancel.getContentSize().width + LoginLayer.SEPARATOR,
			enter.getContentSize().height);

		FileSaver.upload( function (world) {
			try {
				this._world = JSON.parse(world);
				this._worldNameInput.setString(this._world.name);
			}
			catch (e) {
				this._callback.call(this._target, e);
			}
		}.bind(this), this,
			{bottom: pm.settings.getScreenSize().height / 2,
				left: pm.settings.getScreenSize().width / 2}
				);
	},

	_addWorld: function(sender)
	{
		if(!this._world)
		{
			this._cancelCreate(this);
			return;
		}
		this._worldData = new pm.data.World();
		this._worldData.name = this._worldNameInput.getString();
		pm.apiServerUtils.uploadWorld(this._onWorldAdded.bind(this), this._worldData);
	},

	_onWorldAdded: function(error, response)
	{
		if(!error) {
			this._world.id = response.id;
			var user = pm.settings.getUserData();

			this._world.games.forEach(function (game) {
				game.id = "";
				game.levels.forEach(function (level) {
					level.id = "";
					level.revision = 1;
				});
			});
			pm.settings.setWorldMetaData(response.id, {
				name: this._worldData.name,
				description: this._world.description,
				createDate: this._world.createDate,
				owner: {id: user.id, name: user.username}
			});

			pm.fileUtils.createDirectory(pm.settings.getWorldDir(response.id));

			pm.settings.addWorld(response.id);
			pm.fileUtils.saveObject(pm.settings.getWorldPath(response.id), this._world);
			pm.fileUtils.saveObject(pm.settings.getWorldEditLogPath(response.id), this._world);

			this._callback.call(this._target, null, response.id);
		}
		else
		{
			this._callback.call(this._target, error);
		}
		FileSaver.removeFileReader();
		this.removeFromParent();
	},

	_cancelCreate: function(sender)
	{
		FileSaver.removeFileReader();
		this.removeFromParent();
	}
});

/**
 * @default
 * @const
 * @type {Number}
 */
NewWorldLayer.WIDTH = 350.0;
/**
 * @default
 * @const
 * @type {Number}
 */
NewWorldLayer.HEIGHT = 150.0;
/**
 * @default
 * @const
 * @type {Number}
 */
NewWorldLayer.SEPARATOR = 15.0;
