/**
 * Created by Nikita Besshaposhnikov on 15.06.16.
 */

/**
 * This callback is used on self remove of TutorialStepEditLayer.
 * @callback TutorialStepEditLayer~removeCallback
 * @param {Number} stepNumber A number of removed step.
 */

/**
 * @class Layer for editing tutorial scenario step.
 * @interface
 * @extends ccui.Layout
 * @constructor
 * @param {pm.data.TutorialStep } step A step for editing
 * @param {Number} stepNumber A number of step for editing
 * @param {Number} width Width of layer
 * @param {TutorialStepEditLayer~removeCallback} removeCallback A callback for self-removing
 * @param {ccui.Widget} removeCallbackTarget A target of callback for self-removing
 */
var TutorialStepEditLayer = ccui.Layout.extend(/** @lends TutorialStepEditLayer# */{

	_step: null,
	_stepNumber: -1,
	_removeCallback: null,
	_removeCallbackTarget: null,

	_upLayout: null,
	_downLayout: null,
	_selectedStepLayout: null,

	ctor: function(step, stepNumber, width, removeCallback, removeCallbackTarget)
	{
		this._super();
		this.setLayoutType(ccui.Layout.RELATIVE);
		this.setTouchEnabled(true);

		this._step = step;
		this._stepNumber = stepNumber;
		this._removeCallback = removeCallback;
		this._removeCallbackTarget = removeCallbackTarget;

		this._upLayout = new ccui.HBox();
		this._downLayout = new ccui.HBox();

		var label = new ccui.Text(this._getLabelText(), pm.settings.fontName, TutorialStepEditLayer.FONT_SIZE);

		this._upLayout.addChild(label);

		this._fillUpLayout();
		this._fillDownLayout();

		var upHeight = 0;
		var upChildren = this._upLayout.getChildren();

		var align = new ccui.LinearLayoutParameter();
		align.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);

		for (var i = 0 ; i < upChildren.length; ++i)
		{
			upHeight = Math.max(upHeight, upChildren[i].height);
			upChildren[i].setLayoutParameter(align.clone());
		}

		var downHeight = 0;
		var downChildren = this._downLayout.getChildren();

		for(var i = 0 ; i < downChildren.length; ++i)
		{
			downHeight = Math.max(downHeight, downChildren[i].height);
			downChildren[i].setLayoutParameter(align.clone());
		}

		var remove = new pmui.Button(pm.spriteUtils.getIconName("clear", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("clear", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("clear", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		remove.setScale(0.8);
		remove.addClickEventListener(this._remove.bind(this));

		remove.setLayoutParameter(align.clone());

		this._upLayout.addChild(remove);

		upHeight = Math.max(upHeight, remove.height * 0.8);

		this._upLayout.setContentSize(width, upHeight);
		this._downLayout.setContentSize(width, downHeight);

		var upAlign = new ccui.RelativeLayoutParameter();
		upAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		upAlign.setRelativeName("up");
		this._upLayout.setLayoutParameter(upAlign);

		var downAlign = new ccui.RelativeLayoutParameter();
		downAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		downAlign.setRelativeToWidgetName("up");
		this._downLayout.setLayoutParameter(downAlign);

		this.addChild(this._upLayout);
		this.addChild(this._downLayout);

		this._selectedStepLayout = new ccui.Layout();
		this._selectedStepLayout.setBackGroundColorType(ccui.Layout.BG_COLOR_SOLID);
		this._selectedStepLayout.setBackGroundColor(TutorialEditLayer.SELECTED_STEP_COLOR);

		this._selectedStepLayout.setVisible(false);
		this._selectedStepLayout.setContentSize(width, upHeight + downHeight + TutorialEditLayer.LIST_ITEM_SEPARATOR);
		this.addChild(this._selectedStepLayout, -1);

		this.setContentSize(width, upHeight + downHeight);
	},

	_remove: function()
	{
		this._removeCallback.call(this._removeCallbackTarget, this._stepNumber);
	},

	_getLabelText: function()
	{
		switch(this._step.type)
		{
			case pm.TutorialStepType.CLICK_OBJECT:
				return LocalizedString("ClickObject");
			case pm.TutorialStepType.DRAG_OBJECT:
				return LocalizedString("DragObject");
			case pm.TutorialStepType.MOVE_TO:
				return LocalizedString("MoveObjectTo");
			case pm.TutorialStepType.CLICK_AREA:
				return LocalizedString("ClickArea");
			case pm.TutorialStepType.WAIT_EVENT:
				return LocalizedString("WaitForEvent");
		}

		return "";
	},

	getStepNumber: function()
	{
		return this._stepNumber;
	},

	setStepNumber: function(stepNumber)
	{
		this._stepNumber = stepNumber;
	},

	decreaseStepNumber: function()
	{
		--this._stepNumber;
	},

	setSelectionVisible: function(state)
	{
		this._selectedStepLayout.setVisible(state);
	},

	_fillUpLayout: function() {},
	_fillDownLayout: function() {},

	refresh: function() {}
});

TutorialStepEditLayer.FONT_SIZE = 16;

/**
 * @class Layer for editing tutorial scenario step for type {@link pm.TutorialStepType.CLICK_OBJECT}.
 * @implements TutorialStepEditLayer
 * @constructor
 * @param {pm.data.TutorialStep } step A step for editing
 * @param {Number} stepNumber A number of step for editing
 * @param {Number} width Width of layer
 * @param {TutorialStepEditLayer~removeCallback} removeCallback A callback for self-removing
 * @param {ccui.Widget} removeCallbackTarget A target of callback for self-removing
 */
var TutorialStepClickObjectEditLayer = TutorialStepEditLayer.extend(/** @lends TutorialStepClickObjectEditLayer# */{

	_label: null,

	_fillUpLayout: function()
	{
		this._label = new ccui.Text(this._step.data, pm.settings.fontName, TutorialStepEditLayer.FONT_SIZE);

		this._upLayout.addChild(this._label);
	},

	refresh: function()
	{
		this._label.setString(this._step.data);
	}
});

/**
 * @class Layer for editing tutorial scenario step for type {@link pm.TutorialStepType.DRAG_OBJECT}.
 * @implements TutorialStepEditLayer
 * @constructor
 * @param {pm.data.TutorialStep } step A step for editing
 * @param {Number} stepNumber A number of step for editing
 * @param {Number} width Width of layer
 * @param {TutorialStepEditLayer~removeCallback} removeCallback A callback for self-removing
 * @param {ccui.Widget} removeCallbackTarget A target of callback for self-removing
 */
var TutorialStepDragObjectEditLayer = TutorialStepEditLayer.extend(/** @lends TutorialStepDragObjectEditLayer# */{

	_label: null,

	_fillDownLayout: function()
	{
		var text = LocalizedString("FromToPattern").format(this._step.data.from, this._step.data.to);
		this._label = new ccui.Text(text, pm.settings.fontName, TutorialStepEditLayer.FONT_SIZE);

		this._downLayout.addChild(this._label);
	},

	refresh: function()
	{
		var text = LocalizedString("FromToPattern").format(this._step.data.from, this._step.data.to);

		this._label.setString(text);
	}
});

/**
 * @class Layer for editing tutorial scenario step for type {@link pm.TutorialStepType.MOVE_TO}.
 * @implements TutorialStepEditLayer
 * @constructor
 * @param {pm.data.TutorialStep } step A step for editing
 * @param {Number} stepNumber A number of step for editing
 * @param {Number} width Width of layer
 * @param {TutorialStepEditLayer~removeCallback} removeCallback A callback for self-removing
 * @param {ccui.Widget} removeCallbackTarget A target of callback for self-removing
 */
var TutorialStepMoveToEditLayer = TutorialStepEditLayer.extend(/** @lends TutorialStepMoveToEditLayer# */{

	_label: null,

	_fillUpLayout: function()
	{
		this._label = new ccui.Text(this._step.data.object, pm.settings.fontName, TutorialStepEditLayer.FONT_SIZE);

		this._upLayout.addChild(this._label);
	},

	refresh: function()
	{
		this._label.setString(this._step.data.object);
	}
});

/**
 * @class Layer for editing tutorial scenario step for type {@link pm.TutorialStepType.CLICK_AREA}.
 * @implements TutorialStepEditLayer
 * @constructor
 * @param {pm.data.TutorialStep } step A step for editing
 * @param {Number} stepNumber A number of step for editing
 * @param {Number} width Width of layer
 * @param {TutorialStepEditLayer~removeCallback} removeCallback A callback for self-removing
 * @param {ccui.Widget} removeCallbackTarget A target of callback for self-removing
 */
var TutorialStepClickAreaEditLayer = TutorialStepEditLayer.extend(/** @lends TutorialStepClickAreaEditLayer# */{

	_label: null,

	_fillUpLayout: function()
	{
		this._label = new ccui.Text(this._step.data.object, pm.settings.fontName, TutorialStepEditLayer.FONT_SIZE);

		this._upLayout.addChild(this._label);
	},

	refresh: function()
	{
		this._label.setString(this._step.data.object);
	}
});

/**
 * @class Layer for editing tutorial scenario step for type {@link pm.TutorialStepType.WAIT_EVENT}.
 * @implements TutorialStepEditLayer
 * @constructor
 * @param {pm.data.TutorialStep } step A step for editing
 * @param {Number} stepNumber A number of step for editing
 * @param {Number} width Width of layer
 * @param {TutorialStepEditLayer~removeCallback} removeCallback A callback for self-removing
 * @param {ccui.Widget} removeCallbackTarget A target of callback for self-removing
 */
var TutorialStepWaitEventEditLayer = TutorialStepEditLayer.extend(/** @lends TutorialStepWaitEventEditLayer# */{

	_select: null,

	_fillDownLayout: function()
	{
		var eventMap = TutorialStepWaitEventEditLayer.EVENT_MAP;
		var strList = [];
		var selectIndex = 0;

		for(var i = 0 ; i < eventMap.length; ++i)
		{
			strList.push(LocalizedString(eventMap[i].locString));

			if(eventMap[i].event === this._step.data)
				selectIndex = i;
		}

		this._select = new pmui.ComboBox(
			strList, this,
			this._selectTaskOnSelect,
			20, 0,
			this._selectTaskOnShow,
			this._selectTaskOnClose
		);

		this._select.setScale(0.85);

		this._select.selectObject(selectIndex);

		this._downLayout.addChild(this._select);
	},

	_selectTaskOnSelect: function(index)
	{
		this._step.data = TutorialStepWaitEventEditLayer.EVENT_MAP[index].event;
		this._selectTaskOnClose();
	},

	_selectTaskOnShow: function()
	{
		this.setContentSize(this.width, this.height + 0.85 * this._select.getDropdownSize().height);
		this.getParent().getParent().forceDoLayout();
		this.getParent().getParent().scrollToItem(this._stepNumber, cc.p(0.5, 0.5), cc.p(0, 0.5));
	},

	_selectTaskOnClose: function()
	{
		this.setContentSize(this.width, this.height - 0.85 * this._select.getDropdownSize().height);
		this.getParent().getParent().forceDoLayout();
		this.getParent().getParent().scrollToItem(this._stepNumber, cc.p(0.5, 0.5), cc.p(0, 0.5));
	},

	refresh: function()
	{
		this._label.setString(this._step.data.object);
	}
});

/**
 * Array of events mapping for select.
 * @type {Array<{event:String, string:String}>}
 */
TutorialStepWaitEventEditLayer.EVENT_MAP = [
	{event: pm.ROBOT_WIN_EVENT_STR, locString: "RobotWinEvent"},
	{event: pm.ROBOT_LOOSE_EVENT_STR, locString: "RobotLooseEvent"},
	{event: pm.ROBOT_FAILURE_EVENT_STR, locString: "RobotFailureEvent"},
	{event: pm.MAP_COMPLETED_STR, locString: "MapCompleteEvent"},
	{event: pm.COUNTER_LAYER_OPENED_EVENT_STR, locString: "CounterLayerOpenedEvent"},
	{event: pm.COUNTER_LAYER_CLOSED_EVENT_STR, locString: "CounterLayerClosedEvent"},
	{event: pm.GREEN_FLAG_LAYER_OPENED_EVENT_STR, locString: "GreenFlagLayerOpenedEvent"},
	{event: pm.GREEN_FLAG_LAYER_CLOSED_EVENT_STR, locString: "GreenFlagLayerClosedEvent"},
	{event: pm.ORANGE_FLAG_LAYER_OPENED_EVENT_STR, locString: "OrangeFlagLayerOpenedEvent"},
	{event: pm.ORANGE_FLAG_LAYER_CLOSED_EVENT_STR, locString: "OrangeFlagLayerClosedEvent"},
	{event: pm.METHOD_STACK_LAYER_OPENED_EVENT_STR, locString: "MethodStackLayerOpenedEvent"},
	{event: pm.METHOD_STACK_LAYER_CLOSED_EVENT_STR, locString: "MethodStackLayerClosedEvent"},
    {event: pm.METHOD_STACK_LAYER_ACTIVE_EVENT_STR, locString: "MethodStackLayerActiveEvent"},
	{event: pm.FORCED_COMMANDS_ENDED_EVENTS_STR, locString: "ForcedCommandsEnded"}
];
