/**
 * Created by Kirill Mashchenko on 30.10.18.
 */

/**
 * @class Layout for Physical Lesson page.
 * @extends CommonLessonPage
 */

var PhysicalLessonPage = CommonLessonPage.extend(/** @lends PhysicalLessonPage# */{

	ctor: function(target, cancelCallback)
	{
		this._super(target, cancelCallback);

		this._loadExtraInterface();
	},

	_loadExtraInterface: function()
	{
		pm.spriteUtils.addTempSpriteFrames(pm.moduleUtils.getRobotSpriteSheet(pm.PhysicalRobotLevelModule.Type, pm.PhysicalRobotLevelModule.RobotType));

		var physicalSprite = new ccui.ImageView("physical_robot_BLE.png", ccui.Widget.PLIST_TEXTURE);

		physicalSprite.setAnchorPoint(cc.p(0, 1));

		physicalSprite.setScale(2);

		var physicalAlign = new ccui.RelativeLayoutParameter();
		physicalAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		physicalAlign.setMargin(2 * CommonLessonPage.SEPARATOR, 2 * CommonLessonPage.SEPARATOR, 0, 0);
		physicalAlign.setRelativeName("physicalSprite");
		physicalSprite.setLayoutParameter(physicalAlign);

		this._container.addChild(physicalSprite);

		var physicalLabel = new ccui.Text(LocalizedString("Physical"), pm.settings.fontName, 30);

		var physicalLabelAlign = new ccui.RelativeLayoutParameter();
		physicalLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		physicalLabelAlign.setMargin(physicalSprite.width - physicalLabel.width / 2, (cc.sys.isNative) ? 0 : physicalSprite.height, 0, 0);
		physicalLabelAlign.setRelativeName("physicalLabel");
		physicalLabelAlign.setRelativeToWidgetName("physicalSprite");
		physicalLabel.setLayoutParameter(physicalLabelAlign);

		this._container.addChild(physicalLabel);

		var description = "Ползунов два. Один живет на экране планшета, другой — сделан из фанеры и ползает по коврикам на полу. " +
			"Программу вначале составляем для Экранного Ползуна. Когда она готова, включаем Фанерного Ползуна на полу. " +
			"Теперь наша программа управляет сразу двумя Ползунами.";
		var commonTextLabel = new ccui.Text(description, pm.settings.fontName, 25);
		commonTextLabel.setTextAreaSize(cc.size(this._container.width - 7 * CommonLessonPage.SEPARATOR - 2 * physicalSprite.width, 0));

		var commonTextAlign = new ccui.RelativeLayoutParameter();
		commonTextAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		commonTextAlign.setMargin(cc.sys.isNative ? 2 * CommonLessonPage.SEPARATOR : physicalSprite.width + 2 *CommonLessonPage.SEPARATOR, 0, 0, 0);
		commonTextAlign.setRelativeName("description");
		commonTextAlign.setRelativeToWidgetName("physicalSprite");
		commonTextLabel.setLayoutParameter(commonTextAlign);

		this._container.addChild(commonTextLabel);

		pm.spriteUtils.addTempSpriteFrames(pm.moduleUtils.getMapTileset(pm.PhysicalRobotLevelModule.Type, 0));

		var tableInfo = new ccui.HBox();

		var tableAlign = new ccui.RelativeLayoutParameter();
		tableAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);

		var margin = Math.max(2 * physicalSprite.height + physicalLabel.height + CommonLessonPage.SEPARATOR, commonTextLabel.height + 3 * CommonLessonPage.SEPARATOR);

		tableAlign.setMargin(0, margin, 0, 0);
		tableInfo.setLayoutParameter(tableAlign);

		var height = 0;

		var finishBox = new ccui.VBox();

		var finishLabel = new ccui.Text("Элементы поля", pm.settings.fontName, 30);

		var finishLabelAlign = new ccui.LinearLayoutParameter();
		finishLabelAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
		finishLabelAlign.setMargin(0, CommonLessonPage.SEPARATOR, 0, 0);
		finishLabel.setLayoutParameter(finishLabelAlign);

		finishBox.addChild(finishLabel);

		for (var i = 1; i <= 3; ++i)
		{
			var finishContainer = new ccui.HBox();

			var containerMargin = new ccui.LinearLayoutParameter();
			containerMargin.setMargin(CommonLessonPage.TABLE_MARGIN, CommonLessonPage.SEPARATOR, 0, 0);
			finishContainer.setLayoutParameter(containerMargin);

			finishBox.addChild(finishContainer);

			switch (i)
			{
				case 1:
					var image = new ccui.ImageView("mapElement{0}.png".format(i), ccui.Widget.PLIST_TEXTURE);
					break;
				case 2:
					var image = new ccui.ImageView("targetElement.png", ccui.Widget.PLIST_TEXTURE);
					break;
				case 3:
					var image = new ccui.ImageView("mapElementG.png", ccui.Widget.PLIST_TEXTURE);
					break;
			}

			var imageAlign = new ccui.LinearLayoutParameter();
			imageAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
			image.setLayoutParameter(imageAlign);

			image.setAnchorPoint(cc.p(0, 1));
			image.setScale(CommonLessonPage.CELL_HEIGHT / image.height);

			finishContainer.addChild(image);

			var label = new ccui.Text(this._getObjectDescription(i), pm.settings.fontName, 25);

			var labelMargin = new ccui.LinearLayoutParameter();
			labelMargin.setGravity(ccui.LinearLayoutParameter.LEFT);
			var margin = 2 * CommonLessonPage.SEPARATOR;
			labelMargin.setMargin(margin, CommonLessonPage.CELL_HEIGHT / 2 - label.height/2, 0, 0);
			label.setLayoutParameter(labelMargin);

			finishContainer.addChild(label);

			finishContainer.setContentSize(image.width + CommonLessonPage.SEPARATOR + label.width, CommonLessonPage.CELL_HEIGHT);

			height += CommonLessonPage.SEPARATOR + finishContainer.height;
		}

		finishBox.setContentSize(this._container.width / 2, height);

		height = 0;

		var commandBox = new ccui.VBox();

		var commandLabel = new ccui.Text("Команды", pm.settings.fontName, 30);

		var commandLabelAlign = new ccui.LinearLayoutParameter();
		commandLabelAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
		commandLabelAlign.setMargin(0, CommonLessonPage.SEPARATOR, 0, 0);
		commandLabel.setLayoutParameter(commandLabelAlign);

		commandBox.addChild(commandLabel);

		for (var i = 0; i < 3; ++i)
		{
			var commandContainer = new ccui.HBox();

			var containerMargin = new ccui.LinearLayoutParameter();
			containerMargin.setMargin(CommonLessonPage.TABLE_MARGIN, CommonLessonPage.SEPARATOR, 0, 0);
			commandContainer.setLayoutParameter(containerMargin);

			commandBox.addChild(commandContainer);

			var name;

			pm.spriteUtils.initRobotTextures(pm.PhysicalRobotLevelModule.Type);

			switch(i)
			{
				case 0:
					name = "r4_move-normal.png";
					break;
				case 1:
					name = "r4_turn_left-normal.png";
					break;
				case 2:
					name = "r4_turn_right-normal.png";
					break;
			}

			var image = new ccui.ImageView(name, ccui.Widget.PLIST_TEXTURE);

			var imageAlign = new ccui.LinearLayoutParameter();
			imageAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
			image.setLayoutParameter(imageAlign);

			image.setAnchorPoint(cc.p(0, 1));
			image.setScale(CommonLessonPage.CELL_HEIGHT / image.height);
			commandContainer.addChild(image);

			var label = new ccui.Text(this._getCommandDescription(i), pm.settings.fontName, 25);

			var labelMargin = new ccui.LinearLayoutParameter();
			labelMargin.setGravity(ccui.LinearLayoutParameter.LEFT);
			var margin = (cc.sys.isNative) ? 2 * CommonLessonPage.SEPARATOR : 2 * CommonLessonPage.SEPARATOR + 40;
			labelMargin.setMargin(margin, CommonLessonPage.CELL_HEIGHT / 2 - label.height/2, 0, 0);
			label.setLayoutParameter(labelMargin);

			commandContainer.addChild(label);

			commandContainer.setContentSize(image.width + CommonLessonPage.SEPARATOR + label.width, CommonLessonPage.CELL_HEIGHT);

			height += CommonLessonPage.SEPARATOR + commandContainer.height;
		}

		commandBox.setContentSize(this._container.width / 2, height);

		tableInfo.setContentSize(this._container.width, Math.max(finishBox.height, commandBox.height));

		tableInfo.addChild(finishBox);
		tableInfo.addChild(commandBox);
		this._container.addChild(tableInfo);

		this._container.setContentSize(this._container.width, commonTextLabel.height + tableInfo.height + 8 * CommonLessonPage.SEPARATOR);
	},

	_getObjectDescription: function(number)
	{
		switch(number)
		{
			case 1:
				return "цифра";
			case 2:
				return "финиш";
			case 3:
				return "коврик-клетка"
		}
	},

	_getCommandDescription: function(number)
	{
		switch(number)
		{
			case 0:
				return "вперед";
			case 1:
				return "налево";
			case 2:
				return "направо";
		}
	}
});
