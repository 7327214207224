/**
 * Created by Nikita Besshaposhnikov on 29.03.16.
 */

/**
 * This callback is used when task layer emitted deleting.
 * @callback TaskLayer~deleteCallback
 * @param {TaskLayer} sender Who called callback
 */

/**
 * @class Common layer for tasks.
 * @extends ccui.Layout
 */
var TaskLayer = ccui.Layout.extend(/** @lends TaskLayer# */{

	_task: null,
	_taskType: null,
	_level: null,

	_deleteCallback: null,
	_deleteTarget: null,

	ctor: function(header, level, task, taskType)
	{
		this._super();

		this._task = task;
		this._level = level;
		this._taskType = taskType;

		this.setLayoutType(ccui.Layout.LINEAR_VERTICAL);

		var headerLayout = new ccui.HBox();
		var headerAlign = new ccui.LinearLayoutParameter();
		headerAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
		headerLayout.setLayoutParameter(headerAlign);

		var label = new ccui.Text(header, pm.settings.fontName, pm.settings.fontSize);
		var labelAlign = new ccui.LinearLayoutParameter();
		labelAlign.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
		label.setLayoutParameter(labelAlign);

		var deleteButton = new pmui.Button(pm.spriteUtils.getIconName("clear", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("clear", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("clear", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		deleteButton.setScale(0.7);
		deleteButton.addClickEventListener(this._delete.bind(this));

		headerLayout.addChild(label);
		headerLayout.addChild(deleteButton);
		this.addChild(headerLayout);

		headerLayout.setContentSize(deleteButton.width + label.width, Math.max(deleteButton.height, label.height));
		this.setContentSize(LevelSettingsLayer.WIDTH, headerLayout.height);
	},

	/**
     *
     * @param {TaskLayer~deleteCallback} callback
     * @param {*} target
     */
	addDeleteCallback: function(callback, target)
	{
		this._deleteCallback = callback;
		this._deleteTarget = target;
	},

	getTaskType: function() { return this._taskType; },

	_delete: function()
	{
		this._onTaskRemoved();

		this._deleteCallback.call(this._deleteTarget, this);
	},

	_onTaskRemoved: function(){},

	refresh: function() {}

});
