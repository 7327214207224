/**
 * Created by Kirill Mashchenko on 17.05.23.
 */

/**
 * @class Layer for {@link pm.data.PressRobotMap}.
 * @implements SquarableMapLayer
 */
var PressRobotMapLayer = SquarableMapLayer.extend(/** @lends PressRobotMapLayer#*/{

	ctor: function(map)
	{
		this._super(map);
		this._orientation = pm.MapLayer2D.Orientation.Ortho;
		this._mapElementSize = cc.size(96 - 7, 96 - 7);
	},

	hasChangeOrientationOption: function()
	{
		return false;
	},

	/**
	 * Spawns objects on map.
	 */
	spawnObjects: function()
	{
		for(var i = 0; i < this._map.objects.length; ++i)
		{
			var object = this._map.objects[i];

			object.generateSprite(this._previewDraw);

			if (!(object.sprite instanceof pm.ObjectSprite2D))
				continue;

			object.sprite.setOrientation(this._orientation);
			object.sprite.mapLayer = this;

			this.addObject(object.sprite, 1);

			object.sprite.setRealPosition(object.startPosition);
		}
	},

	placeRobots: function(firstPlace)
	{
		var level = this._map.parentLevel;

		for(var i = 0; i < level.robots.length; ++i)
		{
			var robot = level.robots[i];

			if (firstPlace)
				robot.generateRobotSprite();

			robot.sprite.mapLayer = this;
			robot.sprite.setOrientation(this._orientation);
			robot.sprite.setDirection(robot.direction);

			this.addObject(robot.sprite, 0);

			robot.sprite.setRealPosition(robot.position);

			robot.sprite.setSpriteColor(this.getRobotIndex(robot.groupID));

			if(level.robots.length > 1 && robot.groupID === level.getCurrentRobot().groupID)
				robot.sprite.setActive(true);
		}

		if(!firstPlace)
		{
			for(var i = 0; i < level.robots.length; ++i)
			{
				var robot = level.robots[i];

				if (!(robot.sprite instanceof pm.ObjectSprite2D))
					continue;

				robot.sprite.getSprite().release();
			}
		}
	},
});
