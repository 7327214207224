/**
 * Created by Nikita Besshaposhnikov on 20.09.16.
 */

/**
 * @class Layer which contains buttons for controlling tutorial process.
 * @extends ccui.HBox
 * @constructor
 */
var TutorialControlLayer = ccui.HBox.extend(/** @lends TutorialControlLayer# */{

	ctor: function ()
	{
		this._super();

		this.setBackGroundImage("System/TCL_Background.png");
		this.setContentSize(this.getBackGroundImageTextureSize());

		var restartButton = new pmui.Button(pm.spriteUtils.getIconName("prevTutorialStep", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("prevTutorialStep", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("prevTutorialStep", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE );

		var processStepButton = new pmui.Button(pm.spriteUtils.getIconName("nextTutorialStep", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("nextTutorialStep", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("nextTutorialStep", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		var stopButton = new pmui.Button(pm.spriteUtils.getIconName("pauseTutorial", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("pauseTutorial", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("pauseTutorial", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		processStepButton.addClickEventListener(this._processStep.bind(this));
		restartButton.addClickEventListener(this._restart.bind(this));
		stopButton.addClickEventListener(this._stop.bind(this));

		var restartMargin = new ccui.LinearLayoutParameter();
		restartMargin.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
		restartMargin.setMargin(TutorialControlLayer.BORDER_X, 0, 0, 0);

		restartButton.setLayoutParameter(restartMargin);

		var buttonMargin = new ccui.LinearLayoutParameter();
		buttonMargin.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
		buttonMargin.setMargin(TutorialControlLayer.BUTTON_SEPARATOR, 0, 0, 0);

		stopButton.setLayoutParameter(buttonMargin);
		processStepButton.setLayoutParameter(buttonMargin.clone());

		this.addChild(restartButton);
		this.addChild(stopButton);
		this.addChild(processStepButton);
	},

	_processStep: function()
	{
		pm.tutorialUtils.processCurrentStep();
	},

	_restart: function()
	{
		pm.tutorialUtils.restart();
	},

	_stop: function()
	{
		pm.tutorialUtils.stop();
	}
});

/**
 * Separator for buttons in {@link TutorialControlLayer}.
 * @const
 * @default
 * @type {Number}
 */
TutorialControlLayer.BUTTON_SEPARATOR = 30.0;
TutorialControlLayer.BORDER_X = 16.0;

