/**
 * Created by Nikita Besshaposhnikov on 09.04.15.
 */

/**
 * @class 2D square level.
 * @implements pm.AbstractLevel
 */
var SquarableLevel = pm.AbstractLevel.extend(/** @lends SquarableLevel# */{
	typeName: "PushLevel",

	ctor: function()
	{
		this._super();
	},

	hasPhysicalConnector: function() { return true; },

	_supportsPhysicalConnector: function(type)
	{
		return type === pm.PhysicalConnectorType.Magnet;
	}
});
