/**
 * Created by Diana Agliamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */
pmui.StartNode = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{
	_mainFunc: null,
	_globalCont: null,
	_funcReplace: null,
	_firstFunc: null,
	_funcCont: null,
	_nextNode: null,

	ctor: function (treeNode, parent, syntaxModule, levelType, robotType)
	{
		this._super(parent, treeNode, syntaxModule);
		var robotName = this.createText(LocalizedString(pm.moduleUtils.getRobotDisplayedName(levelType, robotType)), pmui.NodeLayer.USE_COLOR);

		var hBox = pm.syntaxModules.constructStartNodeBox(this, robotName);

		var param = new ccui.LinearLayoutParameter();
		param.setMargin(pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORY, 0, 0);
		hBox.setLayoutParameter(param);

		this.sizeOfContainer(hBox);
		this._funcCont = new ccui.Layout();
		this._globalCont = new pmui.GlobalBodyNode(this);

		this.addChild(hBox);
		this.addChild(this._globalCont);
		this.addChild(this._funcCont);

		this._addTypes = [FunctionButton.Type.Function];
		this._funcCont.setLayoutType(ccui.Layout.RELATIVE);

		this._firstFunc = new pmui.NodeLayer(this, null);
		this._firstFunc._id = "functions_first";
		this.createALignSome(this._firstFunc, "", this._firstFunc._id, ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		this._funcCont.addChild(this._firstFunc);
	},

	setMain: function (node)
	{
		this._mainFunc = node;
	},

	getMain: function()
	{
		return this._mainFunc;
	},

	setGlobals: function (treeNode)
	{
		return this._globalCont.set(treeNode);
	},

	calculateSize: function (forPar)
	{
		this.sizeOfVContainer(this._funcCont);

		var s = this.sizeOfVContainer(this, true);
		this.setContentSize(s.width, s.height + 10);
	},

	getMainBody: function ()
	{
		return this._mainFunc._bodyNode;
	},

	_removeNode: function (node)
	{
		var parent = this._treeNode;
		parent.removeChild(node._treeNode);
		var prevNode = this.getChildByNext(node);
		if (prevNode !== null)
		{
			prevNode._nextNode = node._nextNode;
			var alignPREV = prevNode.getLayoutParameter();
			alignPREV.setRelativeToWidgetName(node._nextNode._id);
		}
		else
			prevNode = this._funcCont.getChildren().forEach(function (elem){
				if(elem._id === node._nextNode._id)
					return elem;
			});
		this._funcCont.removeChild(node);
		return prevNode;
	},

	getNodeId: function ()
	{
		return 0;
	},

	addChildNode: function (node)
	{
		var selectedNode = this._funcCont.getChildren()[this._funcCont.getChildrenCount() - 1];
		var prevNode = this.getChildByNext(selectedNode);
		var nextNodeId = selectedNode._id;
		node._nextNode = selectedNode;

		this.createALignSome(node, nextNodeId, node._id, ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		this._funcCont.addChild(node, 2);

		if (prevNode !== null)
		{
			prevNode._nextNode = node;
			var alignPREV = prevNode.getLayoutParameter();
			alignPREV.setRelativeToWidgetName(node._id);
		}
		this.calculateSize(true);
	},

	getChildByNext: function (next)
	{
		for (var i = 0; i < this._funcCont.getChildrenCount(); i++)
		{
			if (this._funcCont.getChildren()[i]._nextNode === next)
			{
				return this._funcCont.getChildren()[i];
			}
		}
		return null;
	},

	getChildrenNodes: function ()
	{
		return this._funcCont.getChildren();
	},

	getFuncs: function()
	{
		return this._funcCont.getChildren().slice(1, this._funcCont.getChildrenCount());
	},

	getChildCont: function ()
	{
		return this._funcCont;
	},

	isProgram: function ()
	{
		return true;
	},

	highlight: function()
	{}
});

