/**
 * Created by VK on 25.02.21.
 */

pm.syntaxModules = {

    _modules: {},

    register: function(module, type)
    {
        this._modules[type] = module;
    },

    getModulesCount: function ()
    {
        var count = 0;
        for(var module in this._modules)
            ++count;

        return count;
    },

    getTreeCreator: function (type, programTree, robot, parent, outputRobot)
    {
        return this._modules[type].getTreeCreator(programTree, robot, parent, outputRobot, type);
    },

    isTextual: function (type)
    {
        return this._modules[type].isTextual();
    },

    constructIfNode: function (node)
    {
        return this._modules[node.getSyntaxModule()].constructIfNode(node);
    },

    calculateSizeOfIfNode: function (node)
    {
        return this._modules[node.getSyntaxModule()].calculateSizeOfIfNode(node);
    },

    constructElseBlock: function (node, treeNode, parent)
    {
        return this._modules[node.getSyntaxModule()].constructElseBlock(node, treeNode, parent);
    },

    constructRepeaterNode: function (node)
    {
        return this._modules[node.getSyntaxModule()].constructRepeaterNode(node);
    },

    constructForRepeaterNode: function (node)
    {
        return this._modules[node.getSyntaxModule()].constructForRepeaterNode(node);
    },

    calculateSizeOfForRepeaterNode: function (node)
    {
        return this._modules[node.getSyntaxModule()].calculateSizeOfForRepeaterNode(node);
    },

    constructWhileRepeaterNode: function (node)
    {
        return this._modules[node.getSyntaxModule()].constructWhileRepeaterNode(node);
    },

    calculateSizeOfWhileRepeaterNode: function (node)
    {
        return this._modules[node.getSyntaxModule()].calculateSizeOfWhileRepeaterNode(node);
    },

    constructNTimesRepeaterNode: function (node)
    {
        return this._modules[node.getSyntaxModule()].constructNTimesRepeaterNode(node);
    },

    calculateSizeOfNTimesRepeaterNode: function (node)
    {
        return this._modules[node.getSyntaxModule()].calculateSizeOfNTimesRepeaterNode(node);
    },

    getStatementNodeSign: function (node)
    {
        return this._modules[node.getSyntaxModule()].getStatementNodeSign(node);
    },

    addChildNodeToAllocNode: function (node)
    {
        return this._modules[node.getSyntaxModule()].addChildNodeToAllocNode(node);
    },

    constructBodyNode: function (node)
    {
        return this._modules[node.getSyntaxModule()].constructBodyNode(node);
    },

    resizeBodyNodeDotSprite: function (node)
    {
        return this._modules[node.getSyntaxModule()].resizeBodyNodeDotSprite(node);
    },

    constructStartNodeBox: function (node, robotName)
    {
        return this._modules[node.getSyntaxModule()].constructStartNodeBox(node, robotName);
    },

    constructFunctionNode: function (node)
    {
        return this._modules[node.getSyntaxModule()].constructFunctionNode(node);
    },

    addChildNodeToFunctionNode: function (node, childNode)
    {
        return this._modules[node.getSyntaxModule()].addChildNodeToFunctionNode(node, childNode);
    },

    removeChildNodeFromFunctionNode: function (node)
    {
        return this._modules[node.getSyntaxModule()].removeChildNodeFromFunctionNode(node);
    },

    constructEmptyParameter: function (node)
    {
        return this._modules[node.getSyntaxModule()].constructEmptyParameter(node);
    },

    /**
     * Adds localized strings of all syntax modules for language.
     * @param {Object} localizedStrings
     * @param {String} language Localized string language
     * @returns {String}
     */
    extendLocalization: function(localizedStrings, language)
    {
        for(var module in this._modules)
        {
            var moduleLoc = this._modules[module].getLocalization(language);

            for (var loc in moduleLoc)
            {
                if (localizedStrings[loc])
                    cc.warn("Duplicate localization from syntax module {0} for key: {1}!".format(module, loc));

                localizedStrings[loc] = moduleLoc[loc];
            }
        }
    },
};
