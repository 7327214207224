/**
 * Created by Kirill Mashchenko on 17.05.23.
 */

/**
 * @class 2D map for {@link pm.data.PressRobotLevel}
 * @implements SquarableMap
 */
pm.data.PressRobotMap = SquarableMap.extend(/** @lends pm.data.PressRobotMap# */{
	typeName: "PressRobotMap",

	_pressTargetElements: [],

	ctor: function()
	{
		this._addNonEnumerableProps("_pressTargetElements");

		this._super();
	},

	hasWalls: function() { return false; },

	getType: function() { return pm.PressRobotLevelModule.Type; },

	generateMapLayer: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (!pm.settings.isEditorMode)
				this.mapLayer = new PressRobotMapLayer(this);
			else
				this.mapLayer = new PressRobotMapEditorLayer(this);

			this.mapLayer.drawMap(previewDraw);

			return this.mapLayer;
		}
	},

	setSize: function(width, height)
	{
		this.mapElements.length = 0;

		for(var i = 0; i < height; ++i)
		{
			this.mapElements.push([]);

			for(var j = 0; j < width; ++j)
				this.mapElements[i].push(new pm.data.PressRobotMapElement());
		}

		this.width = width;
		this.height = height;
	},

	load: function()
	{
		SquarableMap.prototype.load.call(this);

		this.updatePressTargetElements();
	},

	updatePressTargetElements: function()
	{
		this._pressTargetElements = [];

		for (var x = 0; x < this.width; ++x)
		{
			for (var y = 0; y < this.height; ++y)
			{
				if (!isNaN(this.element(cc.p(x, y)).originalType)
					&& this.element(cc.p(x, y)).originalType >= 0
					&& this.element(cc.p(x, y)).originalType <= 9)
				{
					this._pressTargetElements.push({
						point: cc.p(cc.p(x, y)),
						type: this.element(cc.p(x, y)).originalType
					});
				}
			}
		}
	}
});
