/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.NumberNode = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{
	_number: null,
	_repId: 0,
	_changeLayout: null,
	text: null,

	ctor: function (treeNode, parent, syntaxModule)
	{
		this._withRoundBorder = true;
		this._super(parent, treeNode, syntaxModule);
		this.setLayoutType(ccui.Layout.RELATIVE);
		this.text = this.createText(this._treeNode.value, pmui.NodeLayer.CONST_COLOR);
		pm.syntaxModules.isTextual(this.getSyntaxModule())
			? this.createALignSome(this.text, "", "", ccui.RelativeLayoutParameter.PARENT_TOP_LEFT)
			: this.createALignSome(this.text, "", "", ccui.RelativeLayoutParameter.CENTER_IN_PARENT);

		this.addChild(this.text);
		this._repTypes = [FunctionButton.Type.Number, FunctionButton.Type.Variable, FunctionButton.Type.Expression, FunctionButton.Type.Constant, FunctionButton.Type.IntMethod];
		pm.registerCustomEventListener(pm.CHANGE_NUMBER_NODE, function (event)
		{
			if(this.isSelected())
			{
				var data = event.getUserData();
				if(data.code === NumberInputLayer.NUMBER_CODE)
					this._addNumber(data.number);
				else if(data.code === NumberInputLayer.MINUS_CODE)
					this._changeSignNumber();
				else if(data.code === NumberInputLayer.REMOVE_CODE)
					this._removeNumber();
			}
		}.bind(this), this);
	},


	_addNumber: function (num)
	{
		Number(this.getValue())
			? this.setValue(this.getValue() + num)
			: this.setValue(num);
	},

	_removeNumber: function ()
	{
		var str = this.getValue().slice(0, -1);
		if (str === "")
			this.setValue(0);
		else if (str === NumberInputLayer.MINUS)
			this.setValue(NumberInputLayer.MINUS + "0");
		else
			this.setValue(str);
	},

	_changeSignNumber: function ()
	{
		this.getValue().charAt(0) === NumberInputLayer.MINUS
		? this.setValue(this.getValue().slice(1))
		: this.setValue(NumberInputLayer.MINUS + this.getValue());
	},

	calculateSize: function(isPar)
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
		{
			this.setContentSize(this.text.width, this.text.height);
		}
		else
		{
			this.setContentSize(Math.max(pmui.NumberNode.DEFAULT_WIDTH, this.text.width + pmui.NodeLayer.SEPARATORX * 4), pmui.NumberNode.DEFAULT_HEIGHT);
		}
		if (!isPar)
		{
			this._parentNode.calculateSize();
			this._parentNode.forceDoLayout();
		}
	},

	clickCallback: function ()
	{
		if(!NumberInputLayer.isOpened)
		{
			ProgramContainerLayer.select(this);
			pm.sendCustomEvent(pm.CHANGE_NUMBER_IN_INPUT_NODE, this);
		}
		else if(NumberInputLayer.isOpened)
		{
			if(this._selected)
			{
				pm.sendCustomEvent(pm.CLOSE_NUMBER_INPUT_LAYER, this);
				ProgramContainerLayer.deselect();
			}
			else
			{
				ProgramContainerLayer.select(this);
			}
		}
	},

	setValue: function (val)
	{
		if(Number(val) > 999999999)
			return;
		this._treeNode.value = Number(val);

		if(this.text.width + pmui.NodeLayer.SEPARATORX * 2 >= pmui.NumberNode.DEFAULT_WIDTH || this.width !== pmui.NumberNode.DEFAULT_WIDTH)
		{
			this.text.setString(this._treeNode.value);
			this.calculateSize();
			var transform = this.getParent().getNodeToWorldTransform();
			var objRect = cc.rectApplyAffineTransform(this.getBoundingBox(), transform);
			pm.sendCustomEvent(pm.UPDATE_SCROLL_POS,{height: pmui.NumberNode.DEFAULT_HEIGHT, posY: objRect.y})
		}
		else
			this.text.setString(this._treeNode.value);

		return this;
	},

	getValue: function ()
	{
		return String(this._treeNode.value);
	},

	select: function()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
		{
			return;
		}
		 this.setBackGroundImage(pm.spriteUtils.getNodeLayerElementName("number-round", pm.SELECTED_STATE),
		 	ccui.Widget.PLIST_TEXTURE);
		this.text.setColor(cc.color.WHITE)
		this._selected = true;
		this.setTag(ProgramContainerLayer.SELECTED_TAG);
	},

	deSelect: function ()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
		{
			return;
		}
		this.text.setColor(pmui.NodeLayer.CONST_COLOR);
		this.drawBackground();
		this._selected = false;
		this.setTag(ProgramContainerLayer.NOT_SELECTED_TAG);
	},

	isNumber:function()
	{
		return true;
	},

	clone: function ()
	{
		return new VarFunctionButton(FunctionButton.Type.Constant, this._treeNode.value);
	}
});
/**
 * Default width of {@link pmui.TextInput}
 * @const
 * @default
 * @type {Number}
 */
pmui.NumberNode.DEFAULT_WIDTH = 40;
/**
 * Default width of {@link pmui.TextInput}
 * @const
 * @default
 * @type {Number}
 */
pmui.NumberNode.DEFAULT_HEIGHT = 40;

