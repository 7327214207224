/**
 * Created by Nikita Besshaposhnikov on 02.07.15.
 */

/**
 * @class Layer for selecting map layer.
 * @extends ccui.Layout
 * @constructor
 * @param {pm.AbstractLevel} level Level for which maps draw.
 */
var SelectMapMenu = ccui.Layout.extend(/** @lends SelectMapMenu# */{

	_selectedMapIndex: -1,
	_level: null,

	ctor: function(level)
	{
		this._level = level;

		this._super();

		this.setBackGroundImageScale9Enabled(true);
		this.setBackGroundImage("System/SML_Background.png");
		this.setBackGroundImageCapInsets(cc.rect(60, 15, 40, 15));
		this.setCascadeOpacityEnabled(true);

		this.setLayoutType(ccui.Layout.LINEAR_HORIZONTAL);

		this.updateMaps();

		pm.registerCustomEventListener(pm.MAP_DRAGGED_EVENT_STR, function(event)
		{
			var data = event.getUserData();

			if (data.index !== undefined)
				this._mapDragged(this._selectedMapIndex, data.index);

		}.bind(this), this);
	},

	/**
     * Return selected map index.
     * @returns {Number}
     */
	getSelectedMapIndex: function()
	{
		return this._selectedMapIndex;
	},

	updateMaps: function()
	{
		var mapCount = this._level.maps.length;

		if (mapCount < 2)
			this.setOpacity(0);
		else
			this.setOpacity(255);

		this.removeAllChildren(true);

		var circle = pm.spriteUtils.getInterfaceElementSprite("unchecked");

		var width = mapCount * (circle.getContentSize().width + SelectMapMenu.SEPARATOR_SIZE);
		width -= SelectMapMenu.SEPARATOR_SIZE;
		width += SelectMapMenu.LEFT_BOUND;

		this.setContentSize(cc.size(width, SelectMapMenu.HEIGHT));

		var buttonMargin = new ccui.LinearLayoutParameter();
		buttonMargin.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
		buttonMargin.setMargin(SelectMapMenu.SEPARATOR_SIZE, 0, 0, 0);

		var buttonMargin0 = new ccui.LinearLayoutParameter();
		buttonMargin0.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
		buttonMargin0.setMargin(45, 0, 0, 0);

		for(var i = 0; i < mapCount; ++i)
		{
			var icon = "unchecked";

			if(i === this._level.startMapIndex)
			{
				icon = "checked";
				this._selectedMapIndex = i;
			}

			circle = new pmui.Button(pm.spriteUtils.getInterfaceElementName(icon),
				pm.spriteUtils.getInterfaceElementName(icon),
				pm.spriteUtils.getInterfaceElementName(icon),
				ccui.Widget.PLIST_TEXTURE);

			if(i === 0)
				circle.setLayoutParameter(buttonMargin0);
			else
				circle.setLayoutParameter(buttonMargin);

			circle.setTag(i);
			circle.addClickEventListener(this._selectMap.bind(this));

			this.addChild(circle);
			pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAME_PATTERNS.STAR.format(i), circle);
		}
	},

	/**
     * Sets layer enabled.
     * @param {Boolean} flag
     */
	setEnabled: function(flag)
	{
		pmui.OvalLayout.prototype.setEnabled.call(this, flag);

		for(var i = 0; i < this.getChildrenCount(); ++i)
			this.getChildren()[i].setTouchEnabled(flag);
	},

	_mapDragged: function(oldMapIndex, newMapIndex)
	{
		if (oldMapIndex === newMapIndex)
			return;

		var oldCircle = this.getChildByTag(oldMapIndex);
		var newCircle = this.getChildByTag(newMapIndex);

		this._updateTextures(oldCircle, newCircle);
		this._selectedMapIndex = newMapIndex;
	},

	_selectMap: function(sender)
	{
		var index = sender.getTag();

		if (index !== this._selectedMapIndex)
		{
			var oldCircle = this.getChildByTag(this._selectedMapIndex);

			this._updateTextures(oldCircle, sender);

			pm.sendCustomEvent(pm.PROGRAM_RESTART_EVENT_STR);

			this._selectedMapIndex = index;
			pm.sendCustomEvent(pm.MAP_CHANGED_EVENT_STR, {index: index, sendEvent: false, rebaseRobots: true});
		}
	},

	_updateTextures: function(oldCircle, newCircle)
	{
		oldCircle.loadTextures(pm.spriteUtils.getInterfaceElementName("unchecked"),
			pm.spriteUtils.getInterfaceElementName("unchecked"),
			pm.spriteUtils.getInterfaceElementName("unchecked"),
			ccui.Widget.PLIST_TEXTURE);
		oldCircle.setTouchEnabled(true);

		newCircle.loadTextures(pm.spriteUtils.getInterfaceElementName("checked"),
			pm.spriteUtils.getInterfaceElementName("checked"),
			pm.spriteUtils.getInterfaceElementName("checked"),
			ccui.Widget.PLIST_TEXTURE);
		newCircle.setTouchEnabled(false);
	}
});

/**
 * Separator between circles.
 * @const
 * @default
 * @type {Number}
 */
SelectMapMenu.SEPARATOR_SIZE = 10;
SelectMapMenu.HEIGHT = 50;
SelectMapMenu.LEFT_BOUND = 65;
SelectMapMenu.LEFT_MARGIN = -15;
