/**
 * Created by Kirill Mashchenko on 26.10.18.
 */

/**
 * @class Element for select table view in ChooseLessonLayer.
 * @extends ccui.Layout
 * @constructor
 * @param {cc.Node} [node] Node to show in element.
 * @param {Number} [cellWidth] Width of cell.
 * @param {ChooseLessonLayer~actionCallback} [callback] Event callback for buttons.
 * @param {Object} [target] Target to call callbacks.
 */

var ChooseLessonElement = ccui.Layout.extend(/** @lends ChooseLessonElement# */{

	_callback: null,
	_target: null,

	nameOfPage: null,

	ctor: function(node, name, cellWidth, height, callback, target)
	{
		this._super();

		this._callback = callback;
		this._target = target;
		this.nameOfPage = name;

		var nodeScale = ChooseLessonElement.NODE_SCALE;

		if (node._orientation !== undefined && node._orientation === pm.MapLayer2D.Orientation.Ortho)
			 nodeScale *= Math.sqrt(2);

		var scale = height / (nodeScale * node.getBoundingBox().width);
		node.setScale(scale);

		this.setContentSize(cellWidth, height/2 - ChooseLessonElement.HEIGHT_MARGIN);

		node.setPosition(this.width/2 - node.width/2, this.height/2 - node.height/2 + ChooseLessonElement.SEPARATOR + 7);

		this.addChild(node, 0);

		var button = new cc.Scale9Sprite("System/lessonsButton.png");
		button.setCapInsets(cc.rect(14, 14, 14, 14));

		var label = new ccui.Text(LocalizedString(name), pm.settings.fontLightName, 30);
		label.setPosition(ChooseLessonElement.BUTTON_BORDER/2, ChooseLessonElement.BUTTON_BORDER/2);
		label.setAnchorPoint(0,0);

		button.setContentSize(label.width + ChooseLessonElement.BUTTON_BORDER, label.height + ChooseLessonElement.BUTTON_BORDER);
		button.setPosition(this.width/2, button.height/2);
		button.addChild(label);

		this.addChild(button);
	}
});

ChooseLessonElement.SEPARATOR = 15;
ChooseLessonElement.BUTTON_BORDER = 16;
ChooseLessonElement.NODE_SCALE = 2.7;
ChooseLessonElement.HEIGHT_MARGIN = 35;
