/**
 * Created by Antony Orlovsky on 19.07.19.
 */

/**
 * @class 2D level where robot can paint some kinds({@link GrasshopperMapElementType}) of elements.
 * @extends pm.AbstractLevel
 */
pm.data.GrasshopperLevel = pm.AbstractLevel.extend(/** @lends pm.data.GrasshopperLevel# */{
	typeName: "GrasshopperLevel",
	/**
	 * Number of steps to the left.
	 * @type {Number}
	 */
	leftStepCount: 1,
	/**
	 * Number of steps to the right.
	 * @type {Number}
	 */
	rightStepCount: 1,
	/**
	 * If start point is editable.
	 * @type {Boolean}
	 */
	isStartPointEditable: false,

	ctor: function()
	{
		this._super();
	},

	getType: function() { return pm.GrasshopperLevelModule.Type; }
});
