/**
 * @class This class processes and executes functions for robots in Piktomir.
 * @extends cc.Class
 * @property {pm.RobotManager.State} state
 */
pm.RobotManagerP = pm.RobotManager.extend(/** @lends pm.RobotManagerP# */{

	ctor:function ()
	{
		this._super();
	},

	_processRobotProgram: function(robot, program)
	{
		if (program.instructions.length === 0)
			return;

		var instr = program.instructions[program.instrPosition];

		switch(instr.command)
		{
			case pm.Instruction.EXECUTE:
			{
				if(!CORE_BUILD)
					pm.sendCustomEvent(pm.REM_LAST_HIGHLIGHT_EVENT_STR, {robotIndex: robot.id});

				var highlightEvent = pm.HIGHLIGHT_METHOD_EVENT_STR;

				if(instr.data.isNative)
				{
					if (this._state === pm.RobotManager.State.ProcessingForceFunction)
						this.states.push(this._level.getState());

					instr.data.robot.executeNativeFunction(instr.data.methodID, instr.data.funcArgs);

					++program.instrPosition;

					if(robot.isBroken())
						highlightEvent = pm.HIGHLIGHT_BROKEN_METHOD_EVENT_STR;

					program.paused = true;

					if(this._state === pm.RobotManager.State.SteppedWorking)
						this._updateVisualState();

					if (this.state === pm.RobotManager.State.ProcessingForceFunction) {
						++this._executedStepForceInstrCount[this._executedStepForceInstrCount.length - 1];

						for (var i = 0; i < this._level.robots.length; ++i)
						{
							var rob = this._level.robots[i];

							this.states[this.states.length - 1].robotStates[i].animation = rob.getLastAnimation();
						}
					}
				}
				else
				{
					if(program.labels[instr.data.methodID])
					{
						program.stack.push(program.instrPosition + 1);
						program.instrPosition = program.labels[instr.data.methodID];
					}

					if(!CORE_BUILD)
					{
						pm.sendCustomEvent(pm.HIGHLIGHT_METHOD_EVENT_STR, {
							methodPlace: instr.data.methodPlace,
							robotIndex: robot.id
						});
					}
				}

				if(!CORE_BUILD)
				{
					pm.sendCustomEvent(highlightEvent, {
						methodPlace: instr.data.methodPlace,
						robotIndex: robot.id
					});
				}

				break;

			}
			case pm.Instruction.START_LOOP:
			{
				var repeater = instr.data.repeater;

				if(instr.data.robot)
				{
					repeater = instr.data.robot.getRepeaterValue(instr.data.repeater, instr.data.repArgs);

					if(repeater === 0)
					{
						program.instrPosition = program.labels[instr.data.blockEndLabel];
						return;
					}
				}

				pm.variableList.setValue(instr.data.iteratorTag, 0);

				++program.instrPosition;

				if (!CORE_BUILD)
				{
					pm.sendCustomEvent(pm.HIGHLIGHT_POINT_IN_REP_EVENT, {
						iterNum: 1,
						repPlace: instr.data.repPlace,
						robotIndex: robot.id,
						rep: repeater
					});
				}

				break;
			}
			case pm.Instruction.END_LOOP:
			{
				var iterator = pm.variableList.getValue(instr.data.iteratorTag) + 1;

				if(iterator === pm.MAX_REPEATER)
				{
					robot.setStateFlag(pm.RobotState.EndedWork);
					program.ended = true;

					return;
				}

				var repeater = 0;

				if(instr.data.robot)
					repeater = instr.data.robot.getRepeaterValue(instr.data.repeater, instr.data.repArgs);
				else
					repeater = instr.data.repeater;

				if(iterator < repeater)
				{
					pm.variableList.setValue(instr.data.iteratorTag, iterator);
					program.instrPosition = program.labels[instr.data.jumpLabel];

					if(!CORE_BUILD)
					{
						pm.sendCustomEvent(pm.HIGHLIGHT_POINT_IN_REP_EVENT, {
							iterNum: iterator + 1,
							repPlace: instr.data.repPlace,
							robotIndex: robot.id,
							rep: repeater
						});
					}
				}
				else
				{
					++program.instrPosition;

					if(!CORE_BUILD)
					{
						pm.sendCustomEvent(pm.HIGHLIGHT_POINT_IN_REP_EVENT, {
							iterNum: iterator + 1,
							repPlace: instr.data.repPlace,
							robotIndex: robot.id,
							rep: repeater
						});
					}
				}

				break;
			}
			case pm.Instruction.CHECK_CONDITION:
			{
				pm.variableList.setValue(instr.data.valueTag,
					instr.data.robot.checkCondition(instr.data.condition, instr.data.condArgs));
				++program.instrPosition;

				if(!CORE_BUILD)
				{
					pm.sendCustomEvent(pm.REM_LAST_HIGHLIGHT_EVENT_STR, {robotIndex: robot.id});

					pm.sendCustomEvent(pm.HIGHLIGHT_METHOD_EVENT_STR, {
						methodPlace: instr.data.conditionPlace,
						robotIndex: robot.id
					});
				}

				program.paused = true;

				if(this._state === pm.RobotManager.State.SteppedWorking)
					this._updateVisualState();

				break;
			}
			case pm.Instruction.JUMP_IF:
			{
				if(pm.variableList.getValue(instr.data.conditionTag))
					program.instrPosition = program.labels[instr.data.jumpLabel];
				else
					++program.instrPosition;
				break;
			}
			case pm.Instruction.JUMP_NIF:
			{
				if(!pm.variableList.getValue(instr.data.conditionTag))
					program.instrPosition = program.labels[instr.data.jumpLabel];
				else
					++program.instrPosition;
				break;
			}
			case pm.Instruction.JUMP:
			{
				program.instrPosition = program.labels[instr.data.jumpLabel];
				break;
			}
			case pm.Instruction.RETURN:
			{
				if(!CORE_BUILD)
					pm.sendCustomEvent(pm.REM_LAST_HIGHLIGHT_EVENT_STR, {robotIndex: robot.id});

				if(program.stack.length === 0)
				{
					program.ended = true;

					return;
				}

				program.instrPosition = program.stack.pop();
				break;
			}
		}
	}
});
