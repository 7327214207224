/**
 * Created by Nikita Besshaposhnikov on 19.01.16.
 */

/**
 * This callback is used to check can drag button in bar.
 * @callback FunctionButtonBar~canDragFunction
 * @returns {Boolean}
 */

/**
 * Layer that contains select buttons.<br/>
 * Buttons can be clicked and dragged.
 * @extends pmui.DragAndDropLayout
 * @constructor
 * @param {String} dropTarget Drop target for DND
 * @param {pmui.DragAndDropLayout~event} callback Callback fro DND events.
 * @param {FunctionButtonBar~canDragFunction} Callback for check is elements draggable.
 * @param {ccui.Widget} target for callback.
 */
var FunctionButtonBar = pmui.DragAndDropLayout.extend({
	_canDragFunction: null,
	_canDragFunctionTarget: null,

	ctor: function(dropTarget, callback, canDragFunction, target)
	{
		this._super(pmui.DragAndDropLayout.Type.DRAG_ONLY);

		this._canDragFunction = canDragFunction;
		this._canDragFunctionTarget = target;

		this.setLayoutType(ccui.Layout.LINEAR_HORIZONTAL);

		this.addDropTargetName(dropTarget);

		this.addDNDEventListener(callback, target);
	},

	_isElementDraggable: function(element) { return this._canDragFunction.call(this._canDragFunctionTarget); },

	_findElementByTouch: function(touch)
	{
		var children = this.getChildren();
		for(var i = 0; i < this.getChildrenCount(); ++i)
		{
			if(cc.rectContainsPoint(children[i].getBoundingBox(), this.convertTouchToNodeSpace(touch)))
				return children[i];
		}

		return null;
	},

	_cloneElement: function(element)
	{
		return element.cloneImage();
	},

	setEnabled: function(flag)
	{
		ccui.Layout.prototype.setEnabled.call(this, flag);

		for(var i = 0; i < this.getChildrenCount(); ++i)
			this.getChildren()[i].setEnabled(flag);
	}
});
