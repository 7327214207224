/**
 * Created by Nikita Besshaposhnikov on 09.04.15.
 */

/**
 * @class Common interface for dynamic sprites.
 * @interface
 * @extends pm.ObjectSprite2D
 */
pm.DynamicSprite = pm.ObjectSprite2D.extend(/** @lends pm.DynamicSprite# */{

	ctor: function(frame)
	{
		this._super(frame);
	},

	/**
     * Plays animation by type
     * @function
     * @name pm.DynamicSprite#playAnimation
     * @param {function} endCallBack Callback called on end of animation.
     * @param {Object} target A target for end animation callback
     * @param {Number| String} type Type of animation.
     * @param {*} [args] Arguments for animation
     */
	playAnimation: function(endCallBack, target, type, args) {},
	/**
     * Stops animation
     * @function
     * @name pm.DynamicSprite#stopAnimation
     */
	stopAnimation: function()
	{
		if(!CORE_BUILD)
			this.sprite.stopAllActions();
	},
	/**
     * Sets direction on map layer of sprite.
     * @function
     * @name pm.DynamicSprite#setDirection
     * @param {Number} direction
     */
	setDirection: function(direction){}
});
