/**
 * Created by Kirill Mashchenko on 25.01.18.
 */

var FlagLayer = GlobalRobotLayer.extend(/** @lends GreenFlagLayer# */{

	ctor: function (level, robot, robotType)
	{
		switch (robotType)
		{
			case pm.GlobalRobotType.OrangeFlag:
				this._super(LocalizedString("OrangeFlag"), robot, robotType);
				break;
			case pm.GlobalRobotType.GreenFlag:
				this._super(LocalizedString("GreenFlag"), robot, robotType);
				break;
		}
	}
});
