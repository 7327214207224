/**
 * Created by Nikita Besshaposhnikov on 18.12.14.
 */
/**
 * Animation type for repair robot.
 * @enum {String}
 * @readonly
 */
var RepairRobotAnimation = {Repair: "paint"};

/**
 * @class 2D Sprite for {@link pm.data.RepairRobot}
 * @implements pm.IsoRobotSprite
 */
var RepairRobotSprite = pm.IsoRobotSprite.extend(/** @lends RepairRobotSprite# */{
	ctor: function()
	{
    	cc.animationCache.addAnimations("Robot/repair_robot-animations.plist");

		pm.spriteUtils.registerTempSpriteFrames("Robot/repair_robot.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/repair_robot-indicate.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/repair_robot-broken.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/repair_robot-repair.plist");

		this._super(cc.spriteFrameCache.getSpriteFrame(this._getFrameName(0)));

		this._minPoint = cc.p(28, 27);
		this._maxPoint = cc.p(28, -6);
	},

	_getActiveSpritePosition: function()
	{
		return cc.p(26, 85);
	},

	_getCommandsSpritePosition: function()
	{
		return cc.p(26, 115);
	},

	_getFrameName: function(direction)
	{
		return "rep_robot{0}.png".format(4 * direction);
	},

	_getTurnAnimationName: function(oldDirection, curDirection)
	{
		return "rep_robot_{0}->{1}".format(oldDirection, curDirection);
	},

	_getDestroyAnimationName: function(direction)
	{
		return "rep_robot_d_{0}".format(direction);
	},

	_getIndicateAnimationName: function(direction)
	{
		return "rep_robot_i_{0}".format(direction);
	},

	//getRealPosition: function(x,y)
	//{
	//    return this.mapLayer.realPosition(x, y, -35, 35);
	//},

	playAnimation: function(callback, target, type, args)
	{
		switch(type)
		{
			case RepairRobotAnimation.Repair: this._repair(args, callback, target); break;
			default: pm.IsoRobotSprite.prototype.playAnimation.call(this, callback, target, type, args); break;
		}
	},

	_repair: function(direction, endCallback, target)
	{
		var animation = cc.animationCache.getAnimation("rep_robot_r_{0}".format(direction));
		animation.setDelayPerUnit(pm.settings.getAnimationSpeed()/animation.getTotalDelayUnits());
		var animate = new cc.Animate(animation);

		var turnEnd = new cc.CallFunc(endCallback, target);
		this.getSprite().runAction( new cc.Sequence(animate, turnEnd));
	}
});
