/**
 * Created by danilaeremin on 24.02.15.
 */

/**
 * This callback is used by pmui.TextInputDialog on "OK" button pressed.
 * @callback pmui.TextInputDialog~okCallback
 * @param {String} value Value of input.
 */

/**
 * This callback is used by pmui.TextInputDialog on "Cancel" button pressed.
 * @callback pmui.TextInputDialog~cancelCallback
 */

/**
 * @class Dialog for input string value.
 * @extends MovingAfterKeyboardLayout
 * @constructor
 * @param {String} labelStr String of input label.
 * @param {String} defaultValue Default value of input.
 * @param {String} placeholder Placeholder of input.
 */
pmui.TextInputDialog = MovingAfterKeyboardLayout.extend(/** @lends pmui.TextInputDialog#*/{
	_input: null,
	_target: null,
	_okCallback: null,
	_cancelCallback: null,

	ctor: function(labelStr, defaultValue, placeholder)
	{
		this._super(null, null, pmui.OvalLayout.Type.SLIM);

		this.setLayoutType(ccui.Layout.RELATIVE);

		var width = 0;
		var height = 0;

		var label = new ccui.Text(labelStr, pm.settings.fontName, pm.settings.fontSize);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		labelAlign.setMargin(0, pmui.OvalLayout.STANDARD_BORDER_RADIUS, 0, 0);
		labelAlign.setRelativeName("label");
		label.setLayoutParameter(labelAlign);
		this.addChild(label);

		width = label.width;
		height += label.height;

		this._input = new pmui.TextInput(defaultValue, placeholder, 20, pmui.TextInputDialog.INPUT_WIDTH);

		var inputAlign = new ccui.RelativeLayoutParameter();
		inputAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		inputAlign.setMargin(0, pmui.TextInputDialog.SEPARATOR, 0, 0);
		inputAlign.setRelativeName("input");
		inputAlign.setRelativeToWidgetName("label");
		this._input.setLayoutParameter(inputAlign);

		width = Math.max(width, this._input.width);
		height += this._input.height + pmui.TextInputDialog.SEPARATOR;

		this.addChild(this._input);

		this.setInputAttach(this._input);

		var buttonLayout = new ccui.HBox();

		var buttonLayoutAlign = new ccui.RelativeLayoutParameter();
		buttonLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		buttonLayoutAlign.setMargin(0, pmui.TextInputDialog.SEPARATOR, 0, 0);
		buttonLayoutAlign.setRelativeToWidgetName("input");
		buttonLayout.setLayoutParameter(buttonLayoutAlign);

		this.addChild(buttonLayout);

		var okButton = new pmui.InterfaceButton(this._ok, this, LocalizedString("OkButton"));
		buttonLayout.addChild(okButton);

		var cancelButton = new pmui.InterfaceButton(this._cancel, this, LocalizedString("CancelButton"));
		var cancelMargin = new ccui.LinearLayoutParameter();
		cancelMargin.setMargin(pmui.TextInputDialog.SEPARATOR, 0, 0, 0);
		cancelButton.setLayoutParameter(cancelMargin);

		buttonLayout.addChild(cancelButton);

		buttonLayout.setContentSize(okButton.getContentSize().width + cancelButton.getContentSize().width + 10,
			okButton.getContentSize().height);

		width = Math.max(width, buttonLayout.width);
		height += buttonLayout.height + 2 * pmui.TextInputDialog.SEPARATOR + pmui.OvalLayout.STANDARD_BORDER_RADIUS;

		this.setContentSizeWithBorder(width + 2 * pmui.OvalLayout.STANDARD_BORDER_RADIUS, height);

		var loadingLayer = new LoadingLayer(false);
		loadingLayer.setPosition((this.width - pm.settings.getScreenSize().width) / 2,
			(this.height - pm.settings.getScreenSize().height) / 2);

		this.addChild(loadingLayer, -100);

		var listener = cc.EventListener.create({
			event: cc.EventListener.TOUCH_ONE_BY_ONE,
			swallowTouches: true,
			onTouchBegan: function()
			{
				return true;
			},
			onTouchMoved: function() {},
			onTouchEnded: function() {}
		});

		cc.eventManager.addListener(listener, this);
	},

	/**
     * Set callbacks for dialog.
     * @param target Target for callbacks.
     * @param {pmui.TextInputDialog~okCallback} okCallback On "OK" button pressed.
     * @param {pmui.TextInputDialog~cancelCallback} cancelCallback On "Cancel" button pressed.
     */
	setCallback: function (target, okCallback, cancelCallback)
	{
		this._target = target;
		this._okCallback = okCallback;
		this._cancelCallback = cancelCallback;
	},

	_ok: function(sender)
	{
		this.closeAttachKeyboard();

		this._okCallback.call(this._target, this._input.getString());
		this.removeFromParent();
	},

	_cancel: function(sender)
	{
		this.closeAttachKeyboard();

		if (this._cancelCallback)
			this._cancelCallback.call(this._target);
		this.removeFromParent();
	}
});

pmui.TextInputDialog.INPUT_WIDTH = 250;
pmui.TextInputDialog.SEPARATOR = 8.0;
