/**
 * Created by Nikita Besshaposhnikov on 19.01.16.
 */

/**
 * Layer that contains counter an it's functions/conditions/repeaters
 * @extends ccui.Layout
 * @constructor
 * @param {pm.AbstractLevel} level For what level construct.
 */
var CounterRobotLayer = ccui.Layout.extend({

	_counter: null,

	_innerLayout: null,
	_functionButtonLayout: null,
	_colorLayer: null,
	_showButton: null,
	_hideButton: null,

	_hidden: true,

	_controlledMode: false,
	_buttonClickCallback: null,
	_buttonClickCallbackTarget: null,

	ctor: function(counter)
	{
		this._super();

		this._innerLayout = new ccui.Layout();
		this._innerLayout.setLayoutType(ccui.Layout.RELATIVE);

		this._counter = counter;

		this._functionButtonLayout = new ccui.VBox();
		this._functionButtonLayout.setContentSize(200, 200);

		this._functionButtonLayout.setBackGroundImageScale9Enabled(true);
		this._functionButtonLayout.setBackGroundImage("System/CFBL_Frame.png");
		this._functionButtonLayout.setBackGroundImageCapInsets(cc.rect(90, 90, 90, 90));

		var fButtonLayoutAlign = new ccui.RelativeLayoutParameter();
		fButtonLayoutAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
		fButtonLayoutAlign.setMargin(0, 0, -2, 0);
		fButtonLayoutAlign.setRelativeName("fButtonLayout");

		this._functionButtonLayout.setLayoutParameter(fButtonLayoutAlign);

		this._colorLayer = new ccui.Layout();
		this._colorLayer.setContentSize(200, 200);
		this._colorLayer.setBackGroundColorType(ccui.Layout.BG_COLOR_SOLID);
		this._colorLayer.setBackGroundColor(ProgramLayer.PROGRAM_COLOR);

		var colorLayerAlign = new ccui.RelativeLayoutParameter();
		colorLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
		colorLayerAlign.setMargin(0, 0, 20, 0);
		this._colorLayer.setLayoutParameter(colorLayerAlign);

		this._innerLayout.addChild(this._colorLayer, -10);
		this._innerLayout.addChild(this._functionButtonLayout);

		this._showButton = new pmui.Button(pm.spriteUtils.getIconName("cfblShow", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("cfblShow", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("cfblShow", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this._hideButton = new pmui.Button(pm.spriteUtils.getIconName("cfblHide", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("cfblHide", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("cfblHide", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this._hideButton.setVisible(false);

		var showButtonAlign = new ccui.RelativeLayoutParameter();
		showButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_LEFT_OF_TOPALIGN);
		showButtonAlign.setMargin(0, CounterRobotLayer.SH_BUTTON_BG_TOP_MARGIN, CounterRobotLayer.SH_BUTTON_BG_RIGHT_MARGIN, 0);
		showButtonAlign.setRelativeToWidgetName("fButtonLayout");

		this._showButton.setLayoutParameter(showButtonAlign);
		this._hideButton.setLayoutParameter(showButtonAlign.clone());

		this._showButton.addClickEventListener(this._showHide.bind(this));
		this._hideButton.addClickEventListener(this._showHide.bind(this));

		this._innerLayout.addChild(this._showButton);
		this._innerLayout.addChild(this._hideButton);

		pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.COUNTER_SHOW, this._showButton);
		pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.COUNTER_HIDE, this._hideButton);

		var robotSprite = this._counter.generateRobotSprite();

		var robotLayout = new ccui.Layout();
		robotLayout.setContentSize(robotSprite.getSprite().getContentSize());

		var robotLayoutAlign = new ccui.RelativeLayoutParameter();
		robotLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_LEFT_OF_BOTTOMALIGN);
		robotLayoutAlign.setMargin(0, 0, this._showButton.width, CounterRobotLayer.ROBOT_BOTTOM_MARGIN);
		robotLayoutAlign.setRelativeToWidgetName("fButtonLayout");
		robotLayout.setLayoutParameter(robotLayoutAlign);

		robotLayout.addChild(robotSprite.getSprite());

		this._innerLayout.addChild(robotLayout);

		this._drawFunctionButtons();

		var width = this._functionButtonLayout.width + this._showButton.width + robotSprite.getSprite().width;
		var height = Math.max(this._functionButtonLayout.height, robotSprite.getSprite().height);

		this._innerLayout.setContentSize(width, height);
		this.setContentSize(width, height);

		this.addChild(this._innerLayout);

		this._innerLayout.setPosition(this._functionButtonLayout.width + CounterRobotLayer.SH_POSITION_CORRECTION, 0);

		pm.registerCustomEventListener(pm.ENABLE_CONTROLLED_MODE_STR, function(event)
		{
			var data = event.getUserData();
			if(data !== undefined)
				this._enableControlledMode(data);

		}.bind(this), this);

		pm.registerCustomEventListener(pm.DISABLE_CONTROLLED_MODE_STR, this._disableControlledMode.bind(this), this);
	},

	_showHide: function()
	{
		FunctionButton.deselect();

		if(this._hidden)
		{
			var moveLayer = cc.moveBy(pm.SYSTEM_ANIMATION_DELAY, -this._colorLayer.width - 6 + 3*(this._counter.getType() === pm.GlobalRobotType.ExtendedCounter), 0);
			var onShow = cc.callFunc(function()
			{
				this._toggleIcons();
				pm.sendCustomEvent(pm.COUNTER_LAYER_OPENED_EVENT_STR);
			}, this);

			this._innerLayout.runAction(cc.sequence(moveLayer, onShow));
			this._hidden = false;
		}
		else
		{
			var x = this._functionButtonLayout.width + CounterRobotLayer.SH_POSITION_CORRECTION;
			var moveLayer = cc.moveTo(pm.SYSTEM_ANIMATION_DELAY, x, 0);
			var onClose = cc.callFunc(function()
			{
				this._toggleIcons();
				pm.sendCustomEvent(pm.COUNTER_LAYER_CLOSED_EVENT_STR);
			}, this);

			this._innerLayout.runAction(cc.sequence(moveLayer, onClose));
			this._hidden = true;
		}
	},

	_toggleIcons: function()
	{
		this._showButton.setVisible( !this._showButton.isVisible() );
		this._hideButton.setVisible( !this._hideButton.isVisible() );
	},

	_drawFunctionButtons: function()
	{
		var height = 0;
		var width = 0;

		if (this._counter.repeaters.length > 0)
		{
			var repeaterBar = this._createFunctionButtonsBar(FunctionButton.Type.Repeater, this._counter.repeaters);

			height += repeaterBar.height + CounterRobotLayer.COMPONENT_SEPARATOR;
			width = Math.max(width, repeaterBar.width);

			this._functionButtonLayout.addChild(repeaterBar);
		}

		if (this._counter.nativeFunctions.length > 0)
		{
			var methodBar = this._createFunctionButtonsBar(FunctionButton.Type.Method, this._counter.nativeFunctions);

			height += methodBar.height + CounterRobotLayer.COMPONENT_SEPARATOR;
			width = Math.max(width, methodBar.width);

			this._functionButtonLayout.addChild(methodBar);
		}

		if (this._counter.conditions.length > 0)
		{
			var conditionBar = this._createFunctionButtonsBar(FunctionButton.Type.Condition, this._counter.conditions);

			height += conditionBar.height + CounterRobotLayer.COMPONENT_SEPARATOR;
			width = Math.max(width, conditionBar.width);

			this._functionButtonLayout.addChild(conditionBar);
		}

		for(var i = 0; i < this._functionButtonLayout.getChildrenCount(); ++i)
		{
			var align = new ccui.LinearLayoutParameter();

			if(i === 0)
			{
				align.setMargin(CounterRobotLayer.FBL_LEFT_MARGIN + CounterRobotLayer.FBL_BORDER,
					10 + CounterRobotLayer.FBL_TOP_MARGIN + CounterRobotLayer.FBL_BORDER, 0, 0);
			}
			else
			{
				align.setMargin(CounterRobotLayer.FBL_LEFT_MARGIN + CounterRobotLayer.FBL_BORDER,
					CounterRobotLayer.COMPONENT_SEPARATOR, 0, 0);
			}

			this._functionButtonLayout.getChildren()[i].setLayoutParameter(align);
		}

		width += 2 * CounterRobotLayer.FBL_BORDER;
		height += 2 * CounterRobotLayer.FBL_BORDER;

		this._colorLayer.setContentSize(width + 2*CounterRobotLayer.FBL_BORDER, height + 15);

		width += CounterRobotLayer.FBL_LEFT_MARGIN + 27;
		height += CounterRobotLayer.FBL_TOP_MARGIN;

		this._functionButtonLayout.setContentSize(width, height + 20);
	},

	_createFunctionButtonsBar: function(type, values)
	{
		var layout = new FunctionButtonBar(ProgramContainerLayer.DNDNAME, this._dragCallback, this._canDragButtons, this);

		for(var i = 0; i < values.length; ++i)
		{
			var button = pm.appUtils.generateFunctionButton(type, values[i]);

			pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAME_PATTERNS.SELECT.format(values[i]), button, true);

			var buttonMargin = new ccui.LinearLayoutParameter();

			if(i !== 0)
				buttonMargin.setMargin(ProgramLayer.MENU_SEPARATOR, 0, 0, 0);

			button.setLayoutParameter(buttonMargin);

			layout.addChild(button);
		}

		layout.setContentSize(values.length * (ProgramContainerLayer.BUTTON_SIZE + ProgramLayer.MENU_SEPARATOR),
			ProgramContainerLayer.BUTTON_SIZE);

		return layout;
	},

	_dragCallback: function(element, eventType, touch)
	{
		if(eventType === pmui.DragAndDropLayout.Event.CLICKED)
		{
			if(this._controlledMode)
			{
				if(this._buttonClickCallback)
					this._buttonClickCallback.call(this._buttonClickCallbackTarget, element);

				return;
			}

			element.select();
		}
	},

	_canDragButtons: function()
	{
		return !this._controlledMode;
	},

	_enableControlledMode: function(controlledModeData)
	{
		this._controlledMode = true;
		this._buttonClickCallback = controlledModeData.addMethodCallback;
		this._buttonClickCallbackTarget = controlledModeData.callbackTarget;
	},

	_disableControlledMode: function()
	{
		this._controlledMode = false;
		this._buttonClickCallback = null;
		this._buttonClickCallbackTarget = null;
	}
});

CounterRobotLayer.FBL_LEFT_MARGIN = 18;
CounterRobotLayer.FBL_TOP_MARGIN = 20;
CounterRobotLayer.FBL_BORDER = 5;
CounterRobotLayer.SH_BUTTON_BG_TOP_MARGIN = 71.5;
CounterRobotLayer.SH_BUTTON_BG_RIGHT_MARGIN = 0;
CounterRobotLayer.SH_POSITION_CORRECTION = -2;
CounterRobotLayer.ROBOT_BOTTOM_MARGIN = -5;
CounterRobotLayer.COMPONENT_SEPARATOR = 5;
