/**
 * Created by Nikita Besshaposhnikov on 09.04.15.
 */

/**
 * @class Box object for pushing by {@link pm.data.PushRobot}
 * @implements PushObject
 */
pm.data.PushBox = PushObject.extend(/** @lends pm.data.PushBox# */{

	typeName: "PushBox",

	getType: function() { return pm.PushLevelModule.ObjectTypes.PushBox; },

	generateSprite: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			this.sprite = new PushBoxSprite();

			return this.sprite;
		}
	}
});
