
/**
 * This enum contains all types of player robots.
 * @readonly
 * @enum {String}
 */
pm.PlayerRobotType = {
	RepairRobot: "repair_robot",
	PushRobot: "push_robot",
	PhysicalRobot: "lego_robot",
	BLERobot: "ble_robot",
	LightRobot: "light_robot"
};

/**
 * @class Interface for all robots controlled by player.
 * @interface
 * @extends pm.AbstractRobot
 */

pm.PlayerRobot = pm.AbstractRobot.extend(/** @lends pm.PlayerRobot#*/{
	/**
	 * Sprite of robot.
	 * @type {pm.ObjectSprite}
	 */
	sprite: {},

	ctor: function()
	{
		this._addNonEnumerableProps("sprite");
		this._super();
	},

	/**
	 * Plays animation of robot
	 * @param animation
	 * @param animationCallback
	 * @param args
	 * @param forced
	 */
	playAnimation: function(animation, animationCallback, args, forced)
	{
		this._lastAnimation = animation;

		if (animationCallback)
        	this.setStateFlag(pm.RobotState.PlayingAnimation);

		if(!CORE_BUILD && (this.needToPlayAnimation() || forced))
			this.sprite.playAnimation(animationCallback, this, animation, args);
		else if(CORE_BUILD && animationCallback)
			animationCallback.call(this);
		else if(animationCallback)
			setTimeout(animationCallback.bind(this), 0);
	},

	getAnimation: function(animation, animationCallback, args, forced)
	{
		this._lastAnimation = animation;

		if (animationCallback)
			this.setStateFlag(pm.RobotState.PlayingAnimation);

		if(!CORE_BUILD && (this.needToPlayAnimation() || forced))
			return this.sprite.getAnimation(animationCallback, this, animation, args);
	},

	needToPlayAnimation: function()
	{
		return !pm.settings.isAnimationDisabled()
	},

	/**
     * Updates sprite of robot
     */
	updateSprite: function() {},

	/**
	 * Generates robot sprite
	 * @function
	 * @name pm.PlayerRobot#generateRobotSprite
	 * @returns {pm.ObjectSprite}
	 */
	generateRobotSprite: function() {},

	/**
     * Updates robot conditions
     */
	updateConditions: function() {},

	updateMethods: function() {},

	_changeCondition: function(condition, flag)
	{
		var index = this.conditions.indexOf(condition);

		if (flag && index === -1)
			this.conditions.push(condition);
		else if (!flag && index !== -1)
			this.conditions.splice(index, 1);
	},

	_changeConditionOpposite: function(condition, flag)
	{
		var index = this.conditionOpposites.indexOf(condition);

		if (flag && index === -1)
			this.conditionOpposites.push(condition);
		else if (!flag && index !== -1)
			this.conditionOpposites.splice(index, 1);
	},

	_changeMethod: function(method, flag)
	{
		var index = this.nativeFunctions.indexOf(method);

		if (flag && index === -1)
			this.nativeFunctions.push(method);
		else if (!flag && index !== -1)
			this.nativeFunctions.splice(index, 1);
	},

	/**
	 * Returns if robot has changeable conditions in editor
     * @returns {boolean}
     */
	hasChangeableConditions: function() { return false; },

	hasChangeableMethods: function() { return false; },
});
