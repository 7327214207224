/**
 * Created by Diana Agl on 01.06.2019.
 */

/**
 * @extends FunctionButtonK
 * @constructor
 * @param {String} value
 */

var VarFunctionButton = FunctionButtonK.extend(/** @lends VarFunctionButton#*/{
	_title: null,

	ctor: function (type, name, isNative)
	{
		if(!isNative)
			this._super(type, name, false);
		else
			this._super(type, name, true);

		this.setTitleText(LocalizedString(name));
		this.setTitleFontName(ProgramContainerLayer.FONT_NAME);
		this.setTitleColor(pmui.NodeLayer.VAR_COLOR);
		this.setTitleFontSize(ProgramContainerLayer.FONT_SIZE);
		this.setScale9Enabled(true);
		this.setContentSize(Math.max(this.getTitleRenderer().width + 5, ProgramLayer.BUTTON_WIDTH), this.height);
	},

	cloneImage: function ()
	{
		var but = new VarFunctionButton(this._type, this._value);
		but._parentNode = this._parentNode;
		return but;
	},

	clone: function ()
	{
		var but = new VarFunctionButton(this._type, this._value, this._varType);
		but._parentNode = this._parentNode;
		return but;
	},

	_getSpriteName: function (state)
	{
		if (this._type === FunctionButton.Type.Variable)
		{
			if (this.isInt())
				return pm.spriteUtils.getNumberSpriteName(state);
			else if (this.isBool())
				return pm.spriteUtils.getBoolSpriteName(state);
		}
		else if (this._type === FunctionButton.Type.Empty)
		{
			return pm.spriteUtils.getEmptyMethodSpriteName(state);
		}
		else if (this._type === FunctionButton.Type.Method || this._type === FunctionButton.Type.Function)
		{
			return pm.spriteUtils.getEmptyMethodSpriteName(state);
		}
		else if (this._type === FunctionButton.Type.Statement)
		{
			return pm.spriteUtils.getEmptyMethodSpriteName(state);
		}
		else if (this._type === FunctionButton.Type.IntMethod || this._type === FunctionButton.Type.Constant)
		{
			return pm.spriteUtils.getNumberSpriteName(state);
		}
		else if(this._type === FunctionButton.Type.Alloc)
		{
			return pm.spriteUtils.getEmptyMethodSpriteName(state);
		}
	},

	isBool: function ()
	{
		return pmui.AllocNode.boolVals.indexOf(this.value) !== -1;
	},

	isInt: function ()
	{
		return pmui.AllocNode.intVals.indexOf(this.value) !== -1;
	},

	getVarType: function ()
	{
		return pmui.AllocNode.intVals.indexOf(this.value) !== -1 ? pm.AllocVals.INT: pm.AllocVals.BOOL;
	}
});
