/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.ElseBlock = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{

	_elseBody: null,

	ctor: function (treeNode, parent, syntaxModule)
	{
		this.isPartlyClonable = true;

		this.type = FunctionButton.Type.ElseBlock;
		this._super(parent, treeNode, syntaxModule);
		this.setLayoutType(ccui.Layout.RELATIVE);
		this._addTypes = [FunctionButton.Type.Method, FunctionButton.Type.IfStatement, FunctionButton.Type.Repeater,
			FunctionButton.Type.Alloc, FunctionButton.Type.Statement, FunctionButton.Type.Empty, FunctionButton.Type.MethodList, FunctionButton.Type.HorizontalBar];

		pm.syntaxModules.constructElseBlock(this, treeNode, parent);
	},

	getBody: function()
	{
		return this._elseBody;
	},

	calculateSize: function (isPar)
	{
		this._elseBody.calculateSize(true);
		var width = this._elseBody.width + ProgramContainerLayer.SEPARATOR_SIZEX;
		var height = this._elseBody.height + this.getChildByTag(pmui.IfNode.ELSE_WORD_TAG).height - pmui.NodeLayer.SEPARATORY;
		this.setContentSize(width, height);
		if (!isPar)
			this._parentNode.calculateSize();
	},

	clone: function ()
	{
		return pm.appUtils.generateFunctionButton(FunctionButton.Type.ElseBlock, pm.IfVals.IF_ELSE);
	},

	getChildrenNodes: function ()
	{
		return [this._elseBody];
	},

	isElseBlock:function()
	{
		return true;
	},

	addChildNode: function (node, selectedNode)
	{
		this._elseBody.addChildNode(node, selectedNode);
	},

	_removeNode: function (node)
	{
		this._elseBody._removeNode(node);
	}
});
