/**
 * Created by Nikita Besshaposhnikov on 29.03.16.
 */

/**
 * @class Layer for repair task.
 * @extends TaskLayer
 * @constructor
 * @param {pm.AbstractLevel} level
 * @param {pm.data.RepairTask} task
 */

var RepairTaskLayer = TaskLayer.extend(/** @lends RepairTaskLayer# */{

	_repairLabel: null,
	_repairSlider: null,
	_repairValue: null,
	_allRepairValue: null,

	ctor: function(level, task)
	{
		this._super(LocalizedString("RepairTask"), level, task, pm.RepairLevelModule.Type);

		var height = this.height;

		this._repairLabel = new ccui.Text("0", pm.settings.fontName, pm.settings.fontSize);

		var repairLabelAlign = new ccui.LinearLayoutParameter();
		repairLabelAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
		repairLabelAlign.setMargin(LevelSettingsLayer.BORDER_RADIUS + 15, LevelSettingsLayer.SEPARATOR, 0, 0);
		this._repairLabel.setLayoutParameter(repairLabelAlign);

		this.addChild(this._repairLabel);
		height += this._repairLabel.height;

		this._repairSlider = new ccui.Slider();
		this._repairSlider.loadBarTexture(pm.spriteUtils.getInterfaceElementName("sliderTrack"), ccui.Widget.PLIST_TEXTURE);
		this._repairSlider.loadProgressBarTexture(pm.spriteUtils.getInterfaceElementName("sliderProgress"), ccui.Widget.PLIST_TEXTURE);
		this._repairSlider.loadSlidBallTextureNormal(pm.spriteUtils.getInterfaceElementName("sliderThumb"), ccui.Widget.PLIST_TEXTURE);
		this._repairSlider.addEventListener(this._repairSliderValueChanged.bind(this));

		var repairAlign = new ccui.LinearLayoutParameter();
		repairAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
		repairAlign.setMargin(LevelSettingsLayer.BORDER_RADIUS + 15, LevelSettingsLayer.SEPARATOR, 0, 0);
		this._repairSlider.setLayoutParameter(repairAlign);

		this.addChild(this._repairSlider);
		height += this._repairSlider.height + 20;

		pm.registerCustomEventListener(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR, this._updateRepairSliderValue.bind(this), this);

		this.setContentSize(this.width, height);

		var map = this._level.activeMap;

		if (this._task.data[this._level.getActiveMapIndex()])
		{
			this._allRepairValue = 0;

			for (var x = 0; x < map.width; ++x)
			{
				for (var y = 0; y < map.height; ++y)
				{
					if (map.element(cc.p(x, y)).originalType === RepairMapElementType.BrokenGrass)
						++this._allRepairValue;
				}
			}

			this._repairValue = this._task.data[this._level.getActiveMapIndex()];
			this._repairSlider.setPercent(this._repairValue/this._allRepairValue * 100);
			this._repairLabel.setString(LocalizedString("NeedToRepairPattern").format(this._repairValue));
		}
		else
		{
			this._createRepairSliderValue();
		}
	},

	_createRepairSliderValue: function ()
	{
		this._allRepairValue = 0;

		var map =this._level.activeMap;

		for (var x = 0; x < map.width; ++x)
		{
			for (var y = 0; y < map.height; ++y)
			{
				if (map.element(cc.p(x, y)).originalType === RepairMapElementType.BrokenGrass)
					++this._allRepairValue;
			}
		}

		this._repairSlider.setPercent(100);

		this._repairLabel.setString(LocalizedString("NeedToRepairPattern").format(this._allRepairValue));

		this._task.data[this._level.getActiveMapIndex()] = this._allRepairValue;
	},

	_updateRepairSliderValue: function()
	{
		this._allRepairValue = 0;

		var map =this._level.activeMap;

		for (var x = 0; x < map.width; ++x)
		{
			for (var y = 0; y < map.height; ++y)
			{
				if (map.element(cc.p(x, y)).originalType === RepairMapElementType.BrokenGrass)
					++this._allRepairValue;
			}
		}

		if (this._repairSlider.getPercent() === 100)
		{
			this._repairSlider.setPercent(100);
			this._repairLabel.setString(LocalizedString("NeedToRepairPattern").format(this._allRepairValue));
			this._task.data[this._level.getActiveMapIndex()] = this._allRepairValue;
		}
		else
		{
			this._repairSlider.setPercent(Math.floor(this._task.data[this._level.getActiveMapIndex()] / this._allRepairValue * 100));
		}
	},

	_repairSliderValueChanged: function (target, eventType)
	{
		if(eventType === ccui.Slider.EVENT_PERCENT_CHANGED)
		{
			var repairValue = Math.floor(this._repairSlider.getPercent() / 100 * this._allRepairValue);

			if (this._repairSlider.getPercent() !== 0 && repairValue !== this._allRepairValue)
				++repairValue;

			this._repairLabel.setString(LocalizedString("NeedToRepairPattern").format(repairValue));
			this._task.data[this._level.getActiveMapIndex()] = repairValue;
		}
	},

	refresh: function()
	{
		this._updateRepairSliderValue();
	}

});
