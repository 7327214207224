/**
 * Created by Kirill Mashchenko on 12.07.17.
 */

/**
 * @class Common interface for all square maps with walls.
 * @interface
 * @extends SquarableMap
 */
var SquarableMapWithWalls = SquarableMap.extend(/** @lends SquarableMap#*/{

	/**
     * Editing of walls for this level.
     * @type {Boolean}
     */
	editWalls: true,

	hasWalls: function() { return true; }
});
