/**
 * Created by Nikita Besshaposhnikov on 20.11.14.
 */

/**
 * This callback is used when item is selected.
 * @callback SelectWorldLayer~selectCallback
 */

/**
 * @class Two comboboxes for selecting user and world.
 * @extends ccui.Layout
 * @constructor
 * @param {cc.Node} target Target for callback.
 * @param {SelectWorldLayer~selectCallback} [callback] Update callback on layer size changed.
 */
var SelectWorldEditorLayer = ccui.Layout.extend(/** @lends SelectWorldEditorLayer# */{
	_groupSelect: null,
	_worldSelect: null,

	_selectedGroupID: "",
	_selectedWorldID: "",

	_worldList: null,

	_target: null,
	_selectCallback: null,

	_loadingLayer: null,
	_gravity: 0,

	ctor: function(target, callback, loadLocalWorlds, gravity)
	{
		this._super();
		this.setLayoutType(ccui.Layout.RELATIVE);

		if (loadLocalWorlds === undefined)
			loadLocalWorlds = false;

		if (gravity === undefined)
			gravity = SelectWorldEditorLayer.Gravity.HORIZONTAL;

		this._worldList = new WorldList(this._onLoadWorldList, this, loadLocalWorlds);

		this._target = target;
		this._selectCallback = callback;
		this._gravity = gravity;

		this._groupSelect = new pmui.ComboBox([], this, this._selectGroupOnSelect, 15, 0, this._selectGroupOnShow, this._selectGroupOnClose);
		this._worldSelect = new pmui.ComboBox([], this, this._selectWorldOnSelect, 15, 0, this._selectWorldOnShow, this._selectWorldOnClose);

		var groupAlign = new ccui.RelativeLayoutParameter();
		var worldAlign = new ccui.RelativeLayoutParameter();

		if (gravity === SelectWorldEditorLayer.Gravity.HORIZONTAL)
		{
			groupAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_CENTER_VERTICAL);

			worldAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_LEFT_OF_CENTER);
			worldAlign.setMargin(0, 0, SelectWorldEditorLayer.SEPARATOR, 0);
		}
		else
		{
			groupAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);

			worldAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_RIGHTALIGN);
			worldAlign.setMargin(0, SelectWorldEditorLayer.SEPARATOR, 0, 0);
		}

		groupAlign.setRelativeName("group");
		groupAlign.setMargin(0, 0, 0, 0);
		this._groupSelect.setLayoutParameter(groupAlign);

		worldAlign.setRelativeName("world");
		worldAlign.setRelativeToWidgetName("group");
		this._worldSelect.setLayoutParameter(worldAlign);

		this.addChild(this._worldSelect, 1);
		this.addChild(this._groupSelect, 1);

		this._groupSelect.enabled = false;
		this._worldSelect.enabled = false;

		this._updateSize(false);

		this.refresh();
	},

	refresh: function()
	{
		this._selectedGroupID = pm.settings.getSelectedUserGroupID();
		this._selectedWorldID = pm.settings.getSelectedWorldID();

		this._worldList.loadList();
	},

	getWorldIDInSelectedGroup: function(exceptWorldID)
	{
		var selectGraph = this._getSelectGraph();
		var group = selectGraph[this._selectedGroupID];

		for(var i = 0; i < group.worlds.length; ++i)
		{
			if(group.worlds[i].id !== exceptWorldID)
				return group.worlds[i].id;
		}
	},

	_updateSize: function(updateParent)
	{
		if (this._gravity === SelectWorldEditorLayer.Gravity.HORIZONTAL)
		{
			var width = this._groupSelect.getContentSize().width + this._worldSelect.getContentSize().width + SelectWorldEditorLayer.SEPARATOR;
			var height = Math.max(this._worldSelect.getContentSize().height, this._groupSelect.getContentSize().height);
		}
		else
		{
			var width = Math.max(this._groupSelect.getContentSize().width, this._worldSelect.getContentSize().width);
			var height = this._worldSelect.getContentSize().height + this._groupSelect.getContentSize().height + SelectWorldEditorLayer.SEPARATOR;
		}

		this.setContentSize(width, height);

		if(updateParent === undefined || updateParent)
			this.getParent().requestDoLayout();
	},

	_onLoadWorldList: function()
	{
		this._fillComboboxes(true);
	},

	_getSelectGraph: function()
	{
		return this._worldList.getSelectGraph();
	},

	_fillGroupsCombobox: function()
	{
		var selectedGroupID = this._setComboboxValues(this._groupSelect, this._getSelectGraph(), this._selectedGroupID);

		if(this._selectedGroupID !== selectedGroupID)
			this._selectedGroupID = selectedGroupID;
	},

	_fillWorldsCombobox: function()
	{
		var selectedGroup = this._getSelectGraph()[this._selectedGroupID];
		var worldList = selectedGroup.worlds;

		var selectedWorldID = this._setComboboxValues(this._worldSelect, worldList, this._selectedWorldID);

		if(this._selectedWorldID !== selectedWorldID)
		{
			this._selectedWorldID = selectedWorldID;
			this._downloadWorld();
		}
	},

	_fillComboboxes: function(updateParent)
	{
		this._fillGroupsCombobox();
		this._fillWorldsCombobox();

		this._groupSelect.enabled = true;
		this._worldSelect.enabled = true;

		if(updateParent === undefined)
			updateParent = false;

		this._updateSize(updateParent);
	},

	_setComboboxValues: function(combobox, valueList, selectValueID)
	{
		var comboboxValues = [];
		var selectedIndex = 0;

		var returnValue = "";

		if(cc.isArray(valueList))
		{
			for (var i = 0; i < valueList.length; ++i)
			{
				comboboxValues.push(cc.isObject(valueList[i]) ? valueList[i].name : valueList[i]);

				if (valueList[i].id === selectValueID)
					selectedIndex = i;
			}

			returnValue = valueList[selectedIndex].id;
		}
		else
		{
			var i = 0;

			for (var id in valueList)
			{
				comboboxValues.push(cc.isObject(valueList[id]) ? valueList[id].name : valueList[id]);

				if (id === selectValueID)
					selectedIndex = i;

				++i;
			}

			returnValue = Object.keys(valueList)[selectedIndex];
		}

		combobox.setValueList(comboboxValues);

		combobox.selectObject(selectedIndex);

		return returnValue;
	},

	_selectGroupOnShow: function()
	{
		this._worldSelect.enabled = false;
	},

	_selectGroupOnSelect: function(index)
	{

		var id = Object.keys(this._getSelectGraph())[index];

		if (this._selectedGroupID !== id)
		{
			this._selectedGroupID = id;

			this._fillWorldsCombobox();
			this._updateSize(true);
		}

		this._worldSelect.enabled = true;
	},

	_selectGroupOnClose: function()
	{
		this._worldSelect.enabled = true;
	},

	_selectWorldOnShow: function()
	{
		this._groupSelect.enabled = false;
	},

	_selectWorldOnSelect: function(index)
	{
		var selectedGroup = this._getSelectGraph()[this._selectedGroupID];
		var worldList = selectedGroup.worlds;
		var id = worldList[index].id;

		if(this._selectedWorldID !== id)
		{
			this._selectedWorldID = id;

			this._downloadWorld();
		}

		this._groupSelect.enabled = true;
	},

	_selectWorldOnClose: function()
	{
		this._groupSelect.enabled = true;
	},

	_downloadWorld: function()
	{
		this._loadingLayer = new LoadingLayer();
		this._loadingLayer.show();

		pm.worldUtils.loadWorld({
			worldID: this._selectedWorldID,
			callback: this._endDownLoadWorld,
			callbackTarget: this,
			reloadBuiltinOnError: true
		});
	},

	_endDownLoadWorld: function(error, loadedWorldID)
	{
		if(!error && loadedWorldID.length > 0 && loadedWorldID[0])
		{
			pm.settings.setSelectedWorldID(loadedWorldID[0]);
			pm.settings.setSelectedUserGroupID(this._selectedGroupID);

			var selectedGroup = this._getSelectGraph()[this._selectedGroupID];
			var worldList = selectedGroup.worlds;

			// Loaded built-in, so load public group
			if(loadedWorldID[0] !== this._selectedWorldID)
			{
				this._selectedWorldID = loadedWorldID[0];

				if(!pm.settings.isEditorMode)
				{
					this._selectedGroupID = SelectWorldLayer.PUBLIC_GROUP.id;
					pm.settings.setSelectedUserGroupID(this._selectedGroupID);
				}
				else
				{
					this._selectedGroupID = SelectWorldLayer.OWN_GROUP.id;
				}

				var worldIndex = this._setComboboxValues(this._worldSelect, worldList, this._selectedWorldID);
				this._setComboboxValues(this._groupSelect, this._getSelectGraph(), this._selectedGroupID);

				pm.settings.setWorldMetaData(this._selectedWorldID, worldList[worldIndex]);

				this._updateSize(true);
			}
			else
			{
				for (var i = 0; i < worldList.length; ++i) //TODO refactor
				{
					if (worldList[i].id === this._selectedWorldID)
						pm.settings.setWorldMetaData(this._selectedWorldID, worldList[i]);
				}
			}

			if (this._selectCallback)
				this._selectCallback.call(this._target);

			if (!cc.sys.isNative)
				window.location.hash = "editor/worlds/" + this._selectedWorldID;
		}

		this._loadingLayer.remove();
	}
});

SelectWorldEditorLayer.SEPARATOR = 8;
SelectWorldEditorLayer.Gravity = {
	HORIZONTAL: 0,
	VERTICAL: 1
};
