/**
 * Created by Nikita Besshaposhnikov on 12.11.15.
 */

/**
 * @class Common interface for 2D map layers.
 * @interface
 * @extends pm.MapLayer
 */
pm.MapLayer2D = pm.MapLayer.extend(/** @lends pm.MapLayer2D# */{

	/**
     * Size of map element.
     * @type {cc.Size}
     * @protected
     */
	_mapElementSize: cc.size(0, 0),

	/**
     * Layer which contains grass tiles
     * @type {cc.Layer}
     */
	terrainLayer: null,

	/**
     * Layer which contains robot, flag, walls, objects, etc.
     * @type {cc.Layer}
     */
	objectLayer: null,

	depthLayer: null,

	container: null,

	_previewDraw: false,

	/**
     * @private
     * @type pm.MapLayer2D.Orientation
     */
	_orientation: 0,

	/**
     * Shift of layers after transformation
     */
	_containerShift: cc.p(0, 0),

	ctor: function(map)
	{
		this._super(map);
	},

	/**
     * Return map element size.
     * @returns {cc.Size}
     */
	getMapElementSize: function()
	{
		return this._mapElementSize;
	},

	/**
     * Returns height in pixels.
     * @returns {Number}
     */
	getRealHeight: function()
	{
		return this.getMapElementSize().height * this._map.height;
	},

	/**
     * Returns width in pixels.
     * @returns {Number}
     */
	getRealWidth: function()
	{
		return this.getMapElementSize().width * this._map.width;
	},

	/**
     * Adds Terrain Object to the map
     * @param {pm.TerrainSprite} child
     * @param {Number} zOrder
     */
	addTerrainObject: function(child, zOrder)
	{
		if(!child || !(child instanceof pm.TerrainSprite))
			return;

		this.terrainLayer.addChild(child.getSprite(), zOrder);
	},

	/**
     * Removes Terrain Object to the map
     * @param {pm.TerrainSprite} child
     * @param {Boolean} cleanup
     */
	removeTerrainObject: function(child, cleanup)
	{
		if(!child || !(child instanceof pm.TerrainSprite))
			return;

		this.terrainLayer.removeChild(child.getSprite(), cleanup);
	},

	addObject: function(child, zOrder, tag)
	{
		switch(this._orientation)
		{
			case pm.MapLayer2D.Orientation.Iso: this.objectLayer.addObject(child, zOrder, tag); break;
			case pm.MapLayer2D.Orientation.Ortho: this.objectLayer.addChild(child.getSprite(), zOrder, tag); break;
			default:
		}
	},

	removeObject: function(child, cleanup)
	{
		switch(this._orientation)
		{
			case pm.MapLayer2D.Orientation.Iso: this.objectLayer.removeObject(child, cleanup); break;
			case pm.MapLayer2D.Orientation.Ortho: this.objectLayer.removeChild(child.getSprite(), cleanup); break;
			default:
		}
	},

	drawMap: function(previewDraw)
	{
		this._previewDraw = previewDraw;

		switch(this._orientation)
		{
			case pm.MapLayer2D.Orientation.Iso: this._drawIsoLayers(); break;
			case pm.MapLayer2D.Orientation.Ortho: this._drawOrthoLayers(); break;
			default: return;
		}

		this._drawMapElements();

		if (this._orientation === pm.MapLayer2D.Orientation.Iso)
			this._drawDepthLines();

		this._map.spawnObjects();

		if(previewDraw)
			this.bake();

		this.checkPositionTasks();

		if(!previewDraw && this._orientation === pm.MapLayer2D.Orientation.Iso)
			this.objectLayer.createOrderGraph();
	},

	/**
     * Sets orientation of layer. Set before draw of layer.
     * @param {pm.MapLayer2D.Orientation} orientation
     */
	setOrientation: function(orientation)
	{
		this._orientation = orientation;
	},
	/**
     * Return orientation of layer
     * @returns {pm.MapLayer2D.Orientation}
     */
	getOrientation: function()
	{
		return this._orientation;
	},

	_drawOrthoLayers: function(previewDraw)
	{
		this.terrainLayer = new cc.Layer();
		this.objectLayer = new cc.Layer();

		this.setCascadeOpacityEnabled(true);
		this.terrainLayer.setCascadeOpacityEnabled(true);
		this.objectLayer.setCascadeOpacityEnabled(true);

		this.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.terrainLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.objectLayer.setContentSize(this.getRealWidth(), this.getRealHeight());

		this.addChild(this.terrainLayer, 0);
		this.addChild(this.objectLayer, 1);

		this._containerShift = cc.p(this.getMapElementSize().width / 2, -this.getMapElementSize().height / 2);

		this.terrainLayer.setPosition(this._containerShift);
		this.objectLayer.setPosition(this._containerShift);
	},

	_drawIsoLayers: function(previewDraw)
	{
		this.terrainLayer = new cc.Layer();
		this.objectLayer = previewDraw ? new cc.Layer() : new pm.TopologicalSortLayer();
		this.depthLayer = new cc.Layer();

		this.container = new cc.Layer();

		this.setCascadeOpacityEnabled(true);
		this.container.setCascadeOpacityEnabled(true);
		this.terrainLayer.setCascadeOpacityEnabled(true);
		this.objectLayer.setCascadeOpacityEnabled(true);
		this.depthLayer.setCascadeOpacityEnabled(true);

		this.container.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.terrainLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.objectLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.depthLayer.setContentSize(this.getRealWidth(), this.getRealHeight());

		this.terrainLayer.setRotation(45.0);
		this.objectLayer.setRotation(45.0);
		this.container.setScaleY(0.5);

		this.container.addChild(this.terrainLayer, 0);
		this.container.addChild(this.objectLayer, 1);

		this.addChild(this.depthLayer);
		this.addChild(this.container);

		var rect = this.terrainLayer.getBoundingBox();
		this.setContentSize(rect.width, rect.height / 2);

		var elementRect = cc.rect(0, 0, this.getMapElementSize().width, this.getMapElementSize().height);
		elementRect = cc.rectApplyAffineTransform(elementRect, this.terrainLayer.getNodeToParentTransform());

		this._containerShift = cc.p((this.width - this.container.width) / 2,
			(this.height - this.container.height) / 2 - elementRect.height / 4);

		this.container.setPosition(this._containerShift);
		this.depthLayer.setPosition(this._containerShift.x, this._containerShift.y + elementRect.height / 4 + 1);
	},

	_drawDepthLines: function()
	{
		var depthSprite = pm.moduleUtils.getDepthLineSprites(this._map.parentLevel.getType());

		if(depthSprite)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var depthHorizontal = new cc.Sprite(depthSprite.horizontal);

				var pos = cc.p(this.getMapElementSize().width * i - this.getMapElementSize().width * this._map.width/2 + 1.5,
					-this.getMapElementSize().height * this._map.height/2 - 1.5);
				pos = cc.pointApplyAffineTransform(pos, pm.MapLayer2D.ISO_TRANSFORM);

				pos.x += this.getMapElementSize().width * this._map.width/2;
				pos.y += this.getMapElementSize().height * this._map.height/2 + 1;

				depthHorizontal.setAnchorPoint(cc.p(0, 1));
				depthHorizontal.setPosition(pos);

				this.depthLayer.addChild(depthHorizontal);
			}

			for (var j = 0; j < this._map.height; ++j)
			{
				var depthVertical = new cc.Sprite(depthSprite.vertical);

				var pos = cc.p(this.getMapElementSize().width * this._map.width - this.getMapElementSize().width * this._map.width/2,
					this.getMapElementSize().height * (j + 1) - this.getMapElementSize().height * this._map.height/2);
				pos = cc.pointApplyAffineTransform(pos, pm.MapLayer2D.ISO_TRANSFORM);

				pos.x += this.getMapElementSize().width * this._map.width/2 + 1;
				pos.y += this.getMapElementSize().height * this._map.height/2;

				depthVertical.setAnchorPoint(cc.p(1, 1));
				depthVertical.setPosition(pos);

				this.depthLayer.addChild(depthVertical);
			}
		}
	},

	removeRobots: function()
	{
		var level = this._map.parentLevel;

		for(var i = 0; i < level.robots.length; ++i)
		{
			var robot = level.robots[i];

			if (!(robot.sprite instanceof pm.ObjectSprite2D))
				continue;

			robot.sprite.getSprite().retain();
		}

		for(var i = 0; i < level.robots.length; ++i)
		{
			var robot = level.robots[i];

			if (!(robot.sprite instanceof pm.ObjectSprite2D))
				continue;

			this.removeObject(robot.sprite, false);
		}
	},

	centerMap: function()
	{
		var robot = this._map.parentLevel.getCurrentRobot();

		if(robot.sprite === null)
			return;

		if (!robot.isPlayingAnimation())
			return;

		var robotSprite = robot.sprite.getSprite();

		var robotPosition = this.objectLayer.convertToWorldSpace(robotSprite.getPosition());

		var centerX = pm.settings.getMapSize().width/2;
		var centerY = pm.settings.getMapSize().height/2;

		if((robotPosition.x < pm.MapLayer2D.DETECTING_ROBOT_AREA ||
            robotPosition.x > pm.settings.getMapSize().width - pm.MapLayer2D.DETECTING_ROBOT_AREA ||
            robotPosition.y < pm.MapLayer2D.DETECTING_ROBOT_AREA ||
            robotPosition.y > pm.settings.getMapSize().height - pm.MapLayer2D.DETECTING_ROBOT_AREA)
           && this.enabled)
		{
			var moveVector = cc.p(centerX - robotPosition.x, centerY - robotPosition.y);

			var moveDelta = cc.p(0, 0);

			var mapBB = cc.rectApplyAffineTransform(this.getBoundingBox(), this.getParent().getNodeToWorldTransform());

			var minX = mapBB.x + moveVector.x;
			var maxX = mapBB.x + mapBB.width + moveVector.x;

			var minY = mapBB.y + moveVector.y;
			var maxY = mapBB.y + mapBB.height + moveVector.y;

			if (mapBB.width > pm.settings.getMapSize().width)
			{
				if (minX > 0)
					moveDelta.x = -minX;
				else if (maxX < pm.settings.getMapSize().width)
					moveDelta.x = pm.settings.getMapSize().width - maxX;
			}
			else
			{ moveVector.x = 0; }

			if (mapBB.height > pm.settings.getMapSize().height)
			{
				if (minY > 0)
					moveDelta.y = -minY;
				else if (maxY < pm.settings.getMapSize().height)
					moveDelta.y = pm.settings.getMapSize().height - maxY;
			}
			else
			{ moveVector.y = 0; }

			moveVector.x += moveDelta.x;
			moveVector.y += moveDelta.y;

			this.enabled = false;
			pm.sendCustomEvent(pm.FOCUS_TO_ROBOT_IN_MAP, moveVector);
		}
	},

	update: function (dt)
	{
		if(!pm.settings.isAnimationDisabled())
			this.centerMap();
	},

	handleTouch: function(touch)
	{
		if (!this._clicksEnabled)
			return false;

		var level = this._map.parentLevel;

		var handles = false;

		for(var i = 0; i < level.robots.length; ++i)
		{
			var robot = level.robots[i];

			if (!(robot.sprite instanceof pm.ObjectSprite2D))
				continue;

			if (robot.sprite.containsPoint(touch))
			{
				var currentRobot = level.getCurrentRobot();

				if (currentRobot && robot && currentRobot != robot)
				{
					if (pm.settings.isEditorMode)
						this._activeRobot = robot;

					for (var i = 0; i < this._map.parentLevel.robots.length; ++i)
					{
						var rob = this._map.parentLevel.robots[i];

						if (rob.groupID === robot.groupID)
						{
							rob.sprite.setSpriteColor(this.getRobotIndex(robot.groupID));
							rob.sprite.setActive(true);
						}
						else
						{
							rob.sprite.setActive(false);
						}
					}

					var startMarkName = pm.MapLayer2D.START_MARK_NAME_PATTERN.format(currentRobot.id);
					var startMarkSprite = this.terrainLayer.getChildByName(startMarkName);

					if(startMarkSprite)
						startMarkSprite.setOpacity(pm.MapLayer2D.INACTIVE_ROBOT_OPACITY);

					startMarkName = pm.MapLayer2D.START_MARK_NAME_PATTERN.format(robot.id);
					startMarkSprite = this.terrainLayer.getChildByName(startMarkName);

					if(startMarkSprite)
						startMarkSprite.setOpacity(255);

					var posMarkName = pm.MapLayer2D.POSITION_MARK_NAME_PATTERN.format(currentRobot.id);
					var posMarkSprite = this.terrainLayer.getChildByName(posMarkName);

					if(posMarkSprite)
						posMarkSprite.setOpacity(pm.MapLayer2D.INACTIVE_ROBOT_OPACITY);

					posMarkName = pm.MapLayer2D.POSITION_MARK_NAME_PATTERN.format(robot.id);
					posMarkSprite = this.terrainLayer.getChildByName(posMarkName);

					if(posMarkSprite)
						posMarkSprite.setOpacity(255);
				}

				pm.sendCustomEvent(pm.ROBOT_TOUCH_EVENT_STR, robot);

				handles = true;
				break;
			}
		}

		return handles;
	},

	containsPoint: function(point)
	{
		var mapPoint = this.terrainLayer.convertTouchToNodeSpace(point);
		var mapSize = this.terrainLayer.getContentSize();

		return (mapPoint.x >= -this.getMapElementSize().width / 2 && mapPoint.x <= mapSize.width &&
        mapPoint.y >= 0 && mapPoint.y <= mapSize.height + this.getMapElementSize().height / 2);
	},

	checkPositionTasks: function()
	{
		var level = this._map.parentLevel;
		var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
		var mapIndex = level.maps.indexOf(this._map);

		if(task)
		{
			for(var id in task.data[mapIndex])
				this._drawPositionMark(Number(id), task.data[mapIndex][id]);
		}
	},

	removePositionMarks: function()
	{
		var level = this._map.parentLevel;
		var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
		var mapIndex = level.maps.indexOf(this._map);

		if(task)
		{
			for(var id in task.data[mapIndex])
				this._removePositionMark(Number(id));
		}
	},

	_drawMapElements: function()
	{
		for(var x = 0; x < this._map.width; ++x)
		{
			for(var y = 0; y < this._map.height; ++y)
			{
				var p = cc.p(x, y);
				var terrainSprite = this._map.element(p).generateTerrainSprite();

				terrainSprite.getSprite().setPosition(this.realPosition(p, cc.p(0, 0)));
				this.addTerrainObject(terrainSprite, 0);

				if(this._map.element(p).startForRobot !== pm.MapElement.START_FOR_NO_ROBOT)
					this.drawRobotStartPositionMark(this._map.element(p).startForRobot, p);
			}
		}
	},

	/**
     * Spawns objects on map.
     */
	spawnObjects: function()
	{
		for(var i = 0; i < this._map.objects.length; ++i)
		{
			var object = this._map.objects[i];

			object.generateSprite(this._previewDraw);

			if (!(object.sprite instanceof pm.ObjectSprite2D))
				continue;

			object.sprite.setOrientation(this._orientation);
			object.sprite.mapLayer = this;

			this.addObject(object.sprite, object.position.x + object.position.y - 1);

			object.sprite.setRealPosition(object.startPosition);
		}
	},

	placeRobots: function(firstPlace)
	{
		var level = this._map.parentLevel;

		for(var i = 0; i < level.robots.length; ++i)
		{
			var robot = level.robots[i];

			if (firstPlace)
				robot.generateRobotSprite();

			robot.sprite.mapLayer = this;
			robot.sprite.setOrientation(this._orientation);
			robot.sprite.setDirection(robot.direction);

			this.addObject(robot.sprite, robot.position.x + robot.position.y - 1);

			robot.sprite.setRealPosition(robot.position);

			robot.sprite.setSpriteColor(this.getRobotIndex(robot.groupID));

			if(level.robots.length > 1 && robot.groupID === level.getCurrentRobot().groupID)
				robot.sprite.setActive(true);
		}

		if(!firstPlace)
		{
			for(var i = 0; i < level.robots.length; ++i)
			{
				var robot = level.robots[i];

				if (!(robot.sprite instanceof pm.ObjectSprite2D))
					continue;

				robot.sprite.getSprite().release();
			}
		}
	},

	drawRobotStartPositionMark: function(robotID, markPosition)
	{
		var markSprite = pm.spriteUtils.getMapTile("startElement");
		markSprite.setName(pm.MapLayer2D.START_MARK_NAME_PATTERN.format(robotID));

		markSprite.setPosition(this.realPosition(markPosition, cc.p(0, 0)));

		if(robotID !== this._map.parentLevel.getCurrentRobot().id)
			markSprite.setOpacity(pm.MapLayer2D.INACTIVE_ROBOT_OPACITY);

		this.terrainLayer.addChild(markSprite, 10);
	},

	_setRobotStartPositionMarkPosition: function(robotID, markPosition)
	{
		var markSprite = this.terrainLayer.getChildByName(pm.MapLayer2D.START_MARK_NAME_PATTERN.format(robotID));

		if(markSprite)
			markSprite.setPosition(this.realPosition(markPosition, cc.p(0, 0)));
	},

	_removeRobotStartPositionMark: function(robotID)
	{
		var markSprite = this.terrainLayer.getChildByName(pm.MapLayer2D.START_MARK_NAME_PATTERN.format(robotID));

		if(markSprite)
			markSprite.removeFromParent();
	},

	_drawPositionMark: function(robotID, point)
	{
		var markName = pm.MapLayer2D.POSITION_MARK_NAME_PATTERN.format(robotID);
		var markSprite = this.terrainLayer.getChildByName(markName);

		if(!markSprite)
		{
			markSprite = pm.spriteUtils.getMapTile("targetElement");
			markSprite.setName(markName);

			if(robotID !== this._map.parentLevel.getCurrentRobot().id)
				markSprite.setOpacity(pm.MapLayer2D.INACTIVE_ROBOT_OPACITY);

			this.terrainLayer.addChild(markSprite, 10);
		}

		this._setMarkPosition(robotID, point);
	},

	_setMarkPosition: function(robotID, point)
	{
		var markSprite = this.terrainLayer.getChildByName(pm.MapLayer2D.POSITION_MARK_NAME_PATTERN.format(robotID));

		if(markSprite)
			markSprite.setPosition(this.realPosition(point, cc.p(0, 0)));
	},

	_removePositionMark: function(robotID)
	{
		var markSprite = this.terrainLayer.getChildByName(pm.MapLayer2D.POSITION_MARK_NAME_PATTERN.format(robotID));

		if(markSprite)
			markSprite.removeFromParent();
	},

	clean: function()
	{
		var level = this._map.parentLevel;

		if(this._map === level.activeMap)
		{
			for (var i = 0; i < level.robots.length; ++i)
			{
				var robot = level.robots[i];

				if (!(robot.sprite instanceof pm.ObjectSprite2D))
					continue;

				robot.sprite.getSprite().stopAllActions();
				robot.sprite.setRealPosition(robot.position);
				robot.sprite.setDirection(robot.direction);
				robot.sprite.showCommands(false);
			}

			for (var i = 0; i < level.globalRobots.length; ++i)
			{
				var robot = level.globalRobots[i];

				if (!(robot.sprite instanceof pm.ObjectSprite2D))
					continue;

				robot.sprite.showCommands(false);
			}
		}

		for(var j = 0; j < this._map.objects.length; ++j)
		{
			var object = this._map.objects[j];

			if (!(object.sprite instanceof pm.ObjectSprite2D))
				continue;

			object.sprite.getSprite().stopAllActions();
			object.sprite.setRealPosition(object.startPosition);
		}
	},

	getWorldMinimumPoint: function(point)
	{
		return this.terrainLayer.convertToWorldSpace(cc.p());
	},

	getRobotIndex: function(id)
	{
		var index, robotIndex = 0;
		var robots = this._map.parentLevel.robots;
		var firstRobots = [];

		for (var i = 0; i < robots.length; ++i)
		{
			if (robots[i].groupID === id)
			{
				index = i;
				break;
			}

			firstRobots[i] = robots[i].groupID;
		}

		if (index > 0)
			robotIndex = 1;

		firstRobots.sort();

		for (var i = 1; i < index; ++i)
		{
			if (firstRobots[i] !== firstRobots[i-1])
				++robotIndex;
		}

		return robotIndex;
	},

	hasChangeOrientationOption: function()
	{
		return true;
	}
});

/**
 * Opacity of robots tha ane not current (active)
 * @default
 * @const
 * @type {Number}
 */
pm.MapLayer2D.INACTIVE_ROBOT_OPACITY = 255 * 0.5;
/**
 * Enum for map orientation types.
 * @enum {Number}
 */
pm.MapLayer2D.Orientation = {
	Ortho: 0,
	Iso: 1
};
/**
 * Name pattern for start robot position.
 * @default
 * @const
 * @type {String}
 */
pm.MapLayer2D.START_MARK_NAME_PATTERN = "sm_{0}";
/**
 * Name pattern for robot target position.
 * @default
 * @const
 * @type {String}
 */
pm.MapLayer2D.POSITION_MARK_NAME_PATTERN = "pm_{0}";

//debug
pm.MapLayer2D.ENABLE_DEBUG_DRAW_BB = false;

pm.MapLayer2D.ISO_TRANSFORM_INV = cc.affineTransformScale
(
	cc.affineTransformRotate(cc.affineTransformMakeIdentity(), cc.degreesToRadians(45)),
	1, 2
);

pm.MapLayer2D.ISO_TRANSFORM = cc.affineTransformInvert(pm.MapLayer2D.ISO_TRANSFORM_INV);

pm.MapLayer2D.DETECTING_ROBOT_AREA = 100;
