/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class Layer for kumir task.
 * @extends TaskLayer
 * @constructor
 * @param {pm.AbstractLevel} level
 * @param {pm.data.KumirTask} task
 */

var KumirTaskLayer = TaskLayer.extend(/** @lends KumirTaskLayer# */{

	// _paintLabel: null,
	// _paintValue: null,

	ctor: function(level, task)
	{
		this._super(LocalizedString("KumirTask"), level, task, pm.KumirLevelModule.Type);

		var height = this.height;

		// this._paintLabel = new ccui.Text("0", pm.settings.fontName, pm.settings.fontSize);
		//
		// var paintLabelAlign = new ccui.LinearLayoutParameter();
		// paintLabelAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
		// paintLabelAlign.setMargin(LevelSettingsLayer.BORDER_RADIUS + 15, LevelSettingsLayer.SEPARATOR, 0, 0);
		// this._paintLabel.setLayoutParameter(paintLabelAlign);

		// this.addChild(this._paintLabel);
		// height += this._paintLabel.height;

		this.setContentSize(this.width, height);

		pm.registerCustomEventListener(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR, this._updateTaskData.bind(this), this);

		// var map = this._level.activeMap;
		//
		// if (this._task.data[this._level.getActiveMapIndex()])
		// {
		// 	this._paintValue = this._task.data[this._level.getActiveMapIndex()];
		// 	this._paintLabel.setString(LocalizedString("NeedToPaintPattern").format(this._paintValue));
		// }
	},

	_updateTaskData: function()
	{
		this._allRepairValue = 0;

		var map = this._level.activeMap;

		for (var x = 0; x < map.width; ++x)
		{
			for (var y = 0; y < map.height; ++y)
			{
				if (map.element(cc.p(x, y)).originalType === RepairMapElementType.BrokenGrass)
					++this._allRepairValue;
			}
		}

		this._task.data[this._level.getActiveMapIndex()] = this._allRepairValue;
	},

	refresh: function()
	{
		this._updateTaskData();
	}
});
