/**
 * Created by Diana Agliamutdinova
 */

/**
 * @class This layer is used for number input in PikromirK
 * @extends ccui.Layout
 * @constructor
 */
var NumberInputLayer = ccui.Layout.extend(/** @lends NumberInputLayer# */{
	_innerLayer: null,
	_hideButton: null,
	_originalAnimationSpeed: 0,

	ctor: function ()
	{
		this._super();
		NumberInputLayer.isOpened = false;
		this.setLayoutType(ccui.Layout.RELATIVE);
		this.setBackGroundImage("System/interfaceBG.png");
		this.setBackGroundImageScale9Enabled(true);
		this.setCascadeOpacityEnabled(true);
		this.setBackGroundImageCapInsets(cc.rect(80, 90, 100, 70));

		this._innerLayer = new ccui.Layout();
		this._innerLayer.setLayoutType(ccui.Layout.RELATIVE);
		this._innerLayer.setCascadeOpacityEnabled(true);
		this._innerLayer.setBackGroundColorType(ccui.Layout.BG_COLOR_GRADIENT);
		this._innerLayer.setBackGroundColorVector(cc.p(0, -1));
		this._innerLayer.setBackGroundColor(NumberInputLayer.GRAD_START_COLOR, NumberInputLayer.GRAD_END_COLOR);
		this.createALignSome(this._innerLayer, "", "", ccui.RelativeLayoutParameter.CENTER_IN_PARENT);

		var button = null;
		for (var i = 0; i < 9; i++)
		{
			var label = (i + 1) % 10;
			button = new pmui.Button(pm.spriteUtils.getNumberButtonSpriteName(label, pm.NORMAL_STATE),
				pm.spriteUtils.getNumberButtonSpriteName(label, pm.SELECTED_STATE),
				pm.spriteUtils.getNumberButtonSpriteName(label, pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);
			button.number = label;
			if (i === 0)
			{
				this.createALignSome(button, "", NumberInputLayer.BUTTONS_RELATIVE_NAME + i, ccui.RelativeLayoutParameter.PARENT_TOP_LEFT, NumberInputLayer.INNER_MARGIN / 2, NumberInputLayer.INNER_MARGIN / 2, 0, 0);
			}
			else if (i % 3 !== 0)
			{
				this.createALignSome(button, NumberInputLayer.BUTTONS_RELATIVE_NAME + (i - 1), NumberInputLayer.BUTTONS_RELATIVE_NAME + i, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, NumberInputLayer.BUTTONS_MARGIN, 0, 0, 0);
			}
			else
			{
				this.createALignSome(button, NumberInputLayer.BUTTONS_RELATIVE_NAME + (i - 3), NumberInputLayer.BUTTONS_RELATIVE_NAME + i, ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN, 0, NumberInputLayer.BUTTONS_MARGIN, 0, 0);
			}
			button.addClickEventListener(this._onNumberClick.bind(this));
			this._innerLayer.addChild(button);
		}

		var minus = new pmui.Button(pm.spriteUtils.getIconName("minus", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("minus", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("minus", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		minus.addClickEventListener(this._onMinusNumber.bind(this));
		this.createALignSome(minus, "zero", "minus", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, NumberInputLayer.BUTTONS_MARGIN, 0, 0, 0);
		this._innerLayer.addChild(minus);

		var zeroButton = new pmui.Button(pm.spriteUtils.getNumberButtonSpriteName(0, pm.NORMAL_STATE),
			pm.spriteUtils.getNumberButtonSpriteName(0, pm.SELECTED_STATE),
			pm.spriteUtils.getNumberButtonSpriteName(0, pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		zeroButton.number = 0;
		zeroButton.addClickEventListener(this._onNumberClick.bind(this));
		this.createALignSome(zeroButton, "remove", "zero", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, NumberInputLayer.BUTTONS_MARGIN, 0, 0, 0);
		this._innerLayer.addChild(zeroButton);

		this._hideButton = new pmui.Button(pm.spriteUtils.getIconName("cfblHide", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("cfblHide", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("cfblHide", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		this.createALignSome(this._hideButton, "remove", "", ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL, -this._hideButton.width, 0, 0, 0);
		this._hideButton.addClickEventListener(this._hide.bind(this));
		this.addChild(this._hideButton);
		this._hideButton.setVisible(false);

		var remove = new pmui.Button(pm.spriteUtils.getIconName("remove", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("remove", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("remove", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		remove.addClickEventListener(this._onRemoveNumber.bind(this));
		this.createALignSome(remove, NumberInputLayer.BUTTONS_RELATIVE_NAME + 6, "remove", ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN, 0, NumberInputLayer.BUTTONS_MARGIN, 0, 0);
		this._innerLayer.addChild(remove);


		var butCount = 12;
		var butRows = 4;
		this._innerLayer.setContentSize(button.width * butCount / butRows + NumberInputLayer.BUTTONS_MARGIN * (butCount / butRows - 1) + NumberInputLayer.INNER_MARGIN,
			button.height * butRows + NumberInputLayer.BUTTONS_MARGIN * (butRows - 1) + NumberInputLayer.INNER_MARGIN);
		this.setContentSize(this._innerLayer.width + NumberInputLayer.OUTER_MARGIN, this._innerLayer.height + NumberInputLayer.OUTER_MARGIN);
		this.addChild(this._innerLayer, -10);

		pm.registerCustomEventListener(pm.ANIMATE_HIDE_BUTTON, this.animateHideButton.bind(this), this);
		pm.registerCustomEventListener(pm.CHANGE_NUMBER_IN_INPUT_NODE, function (event)
		{
			this._showNumberInput(event.getUserData());
		}.bind(this), this);
		pm.registerCustomEventListener(pm.CLOSE_NUMBER_INPUT_LAYER, function (event)
		{
			this._hide();
		}.bind(this), this);
		this._innerLayer.forceDoLayout();
	},

	_showNumberInput: function (open)
	{
		if (NumberInputLayer.isOpened)
			return;
		NumberInputLayer.isOpened = true;
		var moveLayer = cc.moveBy(pm.SYSTEM_ANIMATION_DELAY, -this.width, 0);
		var fadeLayer = cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY, 255);
		var fadeColorLayer = cc.targetedAction(this._innerLayer, fadeLayer);
		var moveEnd = cc.callFunc(this._showEnded, this);

		this.runAction(cc.sequence(cc.spawn(moveLayer, fadeColorLayer), moveEnd));
	},

	_hide: function (sender)
	{
		if (!NumberInputLayer.isOpened)
				return;
		NumberInputLayer.isOpened = false;
		var moveLayer = cc.moveBy(pm.SYSTEM_ANIMATION_DELAY, this.width, 0);
		var fadeLayer = cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY, 0);
		var fadeColorLayer = cc.targetedAction(this._innerLayer, fadeLayer);
		var moveEnd = cc.callFunc(this._hideEnded, this);
		this.runAction(cc.sequence(cc.spawn(moveLayer, fadeColorLayer), moveEnd));
		ProgramContainerLayer.deselect();
	},

	_toggleIcons: function ()
	{
		this._hideButton.setVisible(!this._hideButton.isVisible());
	},

	_showEnded: function ()
	{
		this._toggleIcons();
		this._originalAnimationSpeed = pm.settings.getAnimationSpeed();

		if (pm.settings.isAnimationDisabled())
			pm.settings.setAnimationSpeed(pm.MIN_ANIMATION_SPEED);
	},

	_hideEnded: function ()
	{
		this._toggleIcons();
		pm.settings.setAnimationSpeed(this._originalAnimationSpeed);
	},

	animateHideButton: function ()
	{
		var showSelected = cc.callFunc(function ()
		{
			this.setHighlighted(true);
		}, this._hideButton);

		var showNormal = cc.callFunc(function ()
		{
			this.setHighlighted(false);
		}, this._hideButton);

		var delay = cc.delayTime(pm.SYSTEM_ANIMATION_DELAY);

		this._hideButton.runAction(cc.repeat(cc.sequence(showSelected, delay, showNormal, delay), 2));
	},

	createALignSome: function (layer, neighbour, name, type, left, top, right, bottom)
	{
		var align = layer.getLayoutParameter();
		if (align === null || align === undefined)
			align = new ccui.RelativeLayoutParameter();
		align.setAlign(type);
		if (neighbour !== "")
		{
			align.setRelativeToWidgetName(neighbour);
		}
		if (name !== "")
		{
			align.setRelativeName(name);
		}
		if (top !== undefined)
			align.setMargin(left, top, right, bottom);
		layer.setLayoutParameter(align);
	},

	_onNumberClick: function (sender)
	{
		pm.sendCustomEvent(pm.CHANGE_NUMBER_NODE,
			{number: sender.number, code: NumberInputLayer.NUMBER_CODE});
	},

	_onRemoveNumber: function (sender)
	{
		pm.sendCustomEvent(pm.CHANGE_NUMBER_NODE,
			{number: 0, code: NumberInputLayer.REMOVE_CODE});
	},

	_onMinusNumber: function (sender)
	{
		pm.sendCustomEvent(pm.CHANGE_NUMBER_NODE,
			{number: 0, code: NumberInputLayer.MINUS_CODE});
	}
});

NumberInputLayer.OUTER_MARGIN = 20;
NumberInputLayer.INNER_MARGIN = 20;
NumberInputLayer.CONTROL_BUTTONS_MARGIN = 40;
NumberInputLayer.GRAD_START_COLOR = cc.color(18, 145, 204);
NumberInputLayer.GRAD_END_COLOR = cc.color(99, 61, 165);
NumberInputLayer.BUTTONS_RELATIVE_NAME = "but";
NumberInputLayer.MINUS = "-";
NumberInputLayer.BUTTONS_MARGIN = 5;
NumberInputLayer.NUMBER_CODE = 0;
NumberInputLayer.REMOVE_CODE = 1;
NumberInputLayer.MINUS_CODE = 2;

NumberInputLayer.isOpened = false;
