/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.StatementNode = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{
	_name: "",

	ctor: function (treeNode, parent, syntaxModule)
	{
		this._withBorder = true;
		this._super(parent, treeNode, syntaxModule);
		this.type = FunctionButton.Type.Statement;

		this.setLayoutType(ccui.Layout.RELATIVE);

		var valReplace = new pmui.ReplaceNode([FunctionButton.Type.Variable, FunctionButton.Type.Number, FunctionButton.Type.Bool, FunctionButton.Type.Expression],
			this);

		valReplace._repId = pm.StatementValueTag;

		var value = valReplace;
		var rightAlign = new ccui.RelativeLayoutParameter();
		rightAlign.setMargin(pmui.NodeLayer.SEPARATORX, 0, pmui.NodeLayer.SEPARATORX, 0);
		rightAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_CENTER_VERTICAL);
		rightAlign.setRelativeName("right");
		value.setLayoutParameter(rightAlign);
		value.setName(pmui.StatementNode.VALUE_TAG);
		this.addChild(value);

		pm.registerCustomEventListener(pm.VARIABLE_DEFINITION_REMOVED, function (event)
		{
			var data = event.getUserData();
			if (data  === this._name)
				this._removeNodeFromTree();
		}.bind(this), this);
	},

	addChildNode: function (node)
	{
		var tag = node._treeNode.getTagName();
		if (tag === pm.StatementVarTag)
		{
			this._name = node.getName();
			node._treeNode.setTagName(pm.StatementVarTag);

			node.setName(pmui.StatementNode.VAR_TAG);
			var leftAlign = new ccui.RelativeLayoutParameter();
			leftAlign.setMargin(pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORY, 0, pmui.NodeLayer.SEPARATORY);
			leftAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
			leftAlign.setRelativeName( "lOp");
			node.setLayoutParameter(leftAlign);
			node._repTypes = [];

			var sign = pm.syntaxModules.getStatementNodeSign(this);
			this._sign = sign;

			this._sign.setSwallowTouches(true);
			this._sign.setPropagateTouchEvents(true);

			var centerAlign = new ccui.RelativeLayoutParameter();
			centerAlign.setMargin(pmui.NodeLayer.SEPARATORX, 0, 0, 0);
			centerAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
			centerAlign.setRelativeName("center");
			centerAlign.setRelativeToWidgetName( "lOp");
			sign.setLayoutParameter(centerAlign);
			this.addChild(sign);
			this.addChild(node);
		}
		else if (tag === pm.StatementValueTag)
		{
			var parent = this._treeNode;
			parent.removeChild(this.getChildByName(pmui.StatementNode.VALUE_TAG)._treeNode);

			var align = this.getChildByName(pmui.StatementNode.VALUE_TAG).getLayoutParameter().clone();
			node.setLayoutParameter(align);
			node._treeNode.setTagName(pm.StatementValueTag);
			this.getChildByName(pmui.StatementNode.VALUE_TAG).removeFromParent();
			node.setName(pmui.StatementNode.VALUE_TAG);
			this.addChild(node);
		}
	},

	_removeNode: function (node)
	{
		if (node._treeNode.getTagName() === pm.StatementValueTag)
		{
			var treeNode = this.getChildByName(pmui.StatementNode.VAR_TAG).isInt()
				? pm.data.SyntaxTree.createNode(this._treeNode, pm.data.Node.Type.Number, 0)
				: pm.data.SyntaxTree.createNode(this._treeNode, pm.data.Node.Type.Bool, pm.BoolTrue);

			treeNode.setTagName(pm.StatementValueTag);
			pm.sendCustomEvent(pm.PARSE_NODE, {node: treeNode, parent: this});
		}
	},

	calculateSize: function (forParent)
	{
		var size = this.sizeOfContainer(this, true);
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
		{
			if(this.getChildByName(pmui.StatementNode.VAR_TAG))
				this.setContentSize(size.width, this.getChildByName(pmui.StatementNode.VAR_TAG).height);
		}
		else
			this.setContentSize(size.width, size.height);
		if(!forParent)
			this._parentNode.calculateSize();
	},

	getChildrenNodes: function ()
	{
		return [this.getChildByName(pmui.StatementNode.VAR_TAG), this.getChildByName(pmui.StatementNode.VALUE_TAG)];
	},

	clone: function ()
	{
		return new VarFunctionButton(this.type, this.getVarName(), this.getChildByName(pmui.StatementNode.VAR_TAG).getVarType());
	},

	canAdd: function(element)
	{
		if(this._addTypes.indexOf(element) !== -1 )
			return true;
		return false;
	},

	getVarName: function ()
	{
		return this.getChildByName(pmui.StatementNode.VAR_TAG)._treeNode.value;
	},

	clickCallback: function ()
	{
		if(this.getChildByName(pmui.StatementNode.VALUE_TAG).isNumber())
		{
			this.createExpression(pm.ArythmMethods.Addition);
		}
		else if(this.getChildByName(pmui.StatementNode.VALUE_TAG).isExpression())
		{
			if (this.getChildByName(pmui.StatementNode.VALUE_TAG)._treeNode.value === pm.ArythmMethods.Addition)
			{
				this.createExpression(pm.ArythmMethods.Substraction);
			}
			else if (this.getChildByName(pmui.StatementNode.VALUE_TAG)._treeNode.value === pm.ArythmMethods.Substraction)
			{
				this._removeNode(this.getChildByName(pmui.StatementNode.VALUE_TAG));
				this.getChildByName(pmui.StatementNode.VALUE_TAG).calculateSize();
			}
		}
	},

	createExpression: function(sign)
	{
		var add = pm.data.SyntaxTree.createNode(this._treeNode, pm.data.Node.Type.Expression, sign);
		add.setTagName(pm.StatementValueTag);

		var num = pm.data.SyntaxTree.createNode(add, pm.data.Node.Type.Identifier, this.getChildByName(pmui.StatementNode.VAR_TAG).getName());
		num.setTagName(pm.ExpressionLeftTag);

		var num2 = pm.data.SyntaxTree.createNode(add, pm.data.Node.Type.Number, 1);
		num2.setTagName(pm.ExpressionRightTag);
		pm.sendCustomEvent(pm.PARSE_NODE,{node: add, parent:this});
	}
});

pmui.StatementNode.VALUE_TAG = "value";
pmui.StatementNode.VAR_TAG = "variable";
pmui.StatementNode.vals = new MapClass();
