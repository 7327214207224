/**
 * Created by Ekaterina Ermishkina on 24.08.16.
 */

/**
 * Possible states of Lamp.
 * @see LightLamp
 * @readonly
 * @enum {Number}
 */

var LightLampState = {
	unLightened: 0,
	lightened: 1
};

/**
 * @class Lamp to turn on
 * @extends pm.Object
 */
pm.data.LightLamp = pm.Object.extend(/** @lends LightLamp#*/{
	typeName: "LightLamp",
	type: "",
	_state: 0,
	countIgnitions: 0,

	ctor: function()
	{
		this.orientation = pm.MapLayer2D.Orientation.Iso;
		this._addNonEnumerableProps("_state");
		this.countIgnitions = 0;
		this._super();
	},
	getType: function() { return pm.LightLevelModule.Lamp; },

	/**
     * Sets position of object by element position.
     * @param {cc.Point} point Point in element coordinates.
     */
	setPosition: function(point)
	{
		if(this.position.x < this.parentMap.width && this.position.y < this.parentMap.height)
		{
			if(this.parentMap.element(this.position).containsObject(this))
				this.parentMap.element(this.position).removeObject(this);
		}

		this.position = point;

		this.parentMap.element(point).addObject(this);
	},

	/**
     * Turn lamp off
     */
	restore: function()
	{
		this.turnOff();
	},

	/**
     * Turn on lamp.
     */
	turnOn: function(callBack)
	{
		this._state = LightLampState.lightened;
		this.playAnimation(LightLampAnimation.TurnOn, callBack);

		this._endAnimation();
	},

	/**
     * Turn off lamp
     */
	turnOff: function(callBack)
	{
		this._state = LightLampState.unLightened;
		this.playAnimation(LightLampAnimation.TurnOff, callBack);

		this._endAnimation();
	},

	/**
     * Checks is element is lightened.
     * @returns {Boolean}
     */
	isLightened: function()
	{
		return this._state === LightLampState.lightened;
	},

	generateSprite: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			this.sprite = new LightLampSprite(this.type);

			return this.sprite;
		}
	},

	updateSprite: function ()
	{
		if (this.isLightened())
			this.sprite._turnOn();
		else
			this.sprite._turnOff();

		this.sprite.setRealPosition(this.position);
	},

	reset: function ()
	{
		this.sprite._turnOff();
		this.countIgnitions = 0;
	},

	getState: function ()
	{
	},

	setState: function (state)
	{

	}
});
