/**
 * Created by danilaeremin on 27.10.15.
 */

/**
 * @class Dialog for edit function settings.
 * @extends pmui.OvalLayout
 * @constructor
 * @param {pm.data.FunctionData} functionData
 */
pme.FunctionSettingsLayer = pmui.OvalLayout.extend(/** @lends pme.FunctionSettingsLayer#*/{
	_maxBlocksInput: null,

	_startDataInput: null,
	_endDataInput: null,

	functionData: null,

	ctor: function(functionData)
	{
		this._super();

		this.functionData = functionData;

		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setContentSizeWithBorder(pme.FunctionSettingsLayer.WIDTH, pme.FunctionSettingsLayer.HEIGHT);

		var buttonLayout = new ccui.HBox();

		var buttonLayoutAlign = new ccui.RelativeLayoutParameter();
		buttonLayoutAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
		buttonLayoutAlign.setMargin(0, 0, 0, pmui.OvalLayout.STANDARD_BORDER_RADIUS);
		buttonLayout.setLayoutParameter(buttonLayoutAlign);

		this.addChild(buttonLayout);

		var okButton = new pmui.InterfaceButton(this._ok, this, LocalizedString("OkButton"));
		buttonLayout.addChild(okButton);

		var cancelButton = new pmui.InterfaceButton(this._cancel, this, LocalizedString("CancelButton"));
		var cancelMargin = new ccui.LinearLayoutParameter();
		cancelMargin.setMargin(LoginLayer.SEPARATOR, 0, 0, 0);
		cancelButton.setLayoutParameter(cancelMargin);

		buttonLayout.addChild(cancelButton);

		buttonLayout.setContentSize(okButton.getContentSize().width + cancelButton.getContentSize().width + pmui.MessageBox.SEPARATOR,
			okButton.getContentSize().height);

		var startDataLabel = new pmui.Text(LocalizedString("GivenText"));
		var endDataLabel = new pmui.Text(LocalizedString("DoText"));

		var startDataLabelAlign = new ccui.RelativeLayoutParameter();
		startDataLabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		startDataLabelAlign.setMargin(pmui.OvalLayout.STANDARD_BORDER_RADIUS, pmui.OvalLayout.STANDARD_BORDER_RADIUS + 10, 0, 0);
		startDataLabelAlign.setRelativeName("startDataLabel");
		startDataLabel.setLayoutParameter(startDataLabelAlign);
		this.addChild(startDataLabel);

		var endDataLabelAlign = new ccui.RelativeLayoutParameter();
		endDataLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		endDataLabelAlign.setMargin(0, 15, 0, 0);
		endDataLabelAlign.setRelativeName("endDataLabel");
		endDataLabelAlign.setRelativeToWidgetName("startDataLabel");
		endDataLabel.setLayoutParameter(endDataLabelAlign);
		this.addChild(endDataLabel);

		this._startDataInput = new pmui.TextInput(String(this.functionData.givenText), LocalizedString("GivenTextDoText"), 20, 250);
		this._endDataInput = new pmui.TextInput(String(this.functionData.doText), LocalizedString("DoText"), 20, 250);

		var startDataInputAlign = new ccui.RelativeLayoutParameter();
		startDataInputAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		startDataInputAlign.setMargin(10, 0, 0, 0);
		startDataInputAlign.setRelativeName("startDataInput");
		startDataInputAlign.setRelativeToWidgetName("startDataLabel");
		this._startDataInput.setLayoutParameter(startDataInputAlign);
		this.addChild(this._startDataInput);

		var endDataInputAlign = new ccui.RelativeLayoutParameter();
		endDataInputAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		endDataInputAlign.setMargin(10, 0, 0, 0);
		endDataInputAlign.setRelativeName("endDataInput");
		endDataInputAlign.setRelativeToWidgetName("endDataLabel");
		this._endDataInput.setLayoutParameter(endDataInputAlign);
		this.addChild(this._endDataInput);

		var listener = cc.EventListener.create({
			event: cc.EventListener.TOUCH_ONE_BY_ONE,
			swallowTouches: true,
			onTouchBegan: function()
			{
				return true;
			},
			onTouchMoved: function() {},
			onTouchEnded: function() {}
		});

		cc.eventManager.addListener(listener, this);
	},

	_ok: function()
	{
		if (this._startDataInput.getString() !== "" &&
            this._endDataInput.getString() !== "")
		{

			this.functionData.givenText = this._startDataInput.getString();
			this.functionData.doText = this._endDataInput.getString();

			this.functionData.hidden = (this.functionData.doText !== "" || this.functionData.givenText !== "");
		}

		this.removeFromParent();
	},

	_cancel: function()
	{
		this.removeFromParent();
	}
});

pme.FunctionSettingsLayer.WIDTH = 430.0;
pme.FunctionSettingsLayer.HEIGHT = 150.0;
pme.FunctionSettingsLayer.BOUND = 10.0;
pme.FunctionSettingsLayer.SEPARATOR = 5.0;
