/**
 * Created by Ekaterina Ermishkina on 19.08.16.
 */

/**
 * Light map element types.
 * @enum {Number}
 * @readonly
 */
var LightMapElementType = {
	None: -1,
	GreenGrass: 0,
	Road: 1,
	StopPoint: 2,
	BottomWater: 3
};

/**
 * @class Elements of {@link pm.data.LightMap}.
 * @implements pm.MapElement2D
 */
pm.data.LightMapElement = pm.MapElement2D.extend(/** @lends pm.data.LightMapElement# */{
	typeName: "LightMapElement",
	wallsHere: [],
	roadsHere: [],
	topWater: null,
	botWater: null,
	mainRoad: null,
	grass: null,

	ctor: function ()
	{
		this._addNonEnumerableProps("wallsHere", "roadsHere", "topWater", "botWater", "mainRoad", "grass");
		this._super();
	}

});
