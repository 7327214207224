/**
 * Created by Nikita Besshaposhnikov on 11.11.14.
 */

/**
 * Enum for all task types.
 * @readonly
 * @see pm.data.TaskList
 * @see pm.Task
 * @enum {Number}
 */
pm.GlobalTaskType = {
	ExCounterMemory: "ex_counter_memory",
	Position4: "position4",
	OutputValue: "output_val"
};

/**
 * @class This is a common interface for all Tasks.
 * @constructor
 * @param {pm.AbstractLevel} [level]
 * @interface
 */
pm.Task = pm.Class.extend(/** @lends pm.Task# */{
	_level: {},

	/**
     * Map "map_index-task_value" container.
     * @type {pm.data.MapClass}
     */
	data: {},

	ctor: function(level)
	{
		this._addNonEnumerableProps("_level");

		this._super();

		if(level !== undefined)
			this._level = level;
	},

	/**
     * Sets level of task.
     * @param {pm.AbstractLevel} level
     */
	setLevel: function(level)
	{
		this._level = level;
	},

	/**
     * Returns array of not completed objects
     * @function
     * @name pm.Task#getNotCompletedObjects
     * @param {Number} mapIndex
     * @return {Array<MapElement>}
     */
	getNotCompletedObjects: function(mapIndex) {},

	/**
     * Fills data of task with initial data
     * @name pm.Task#fillWithInitialData
     * @param {Number} mapIndex
     * @param {*} [data]
     */
	fillWithInitialData: function(mapIndex, data) {},

	moveDown: function(mapIndex) {},

	moveUp: function(mapIndex) {},

	moveLeft: function(mapIndex) {},

	moveRight: function(mapIndex) {}
});
