/**
 * Created by Kirill Mashchenko on 19.07.17.
 */

/**
 * Animation type for light robot.
 * @enum {String}
 * @readonly
 */
var LightRobotAnimation = {
	Turn: "turn",
	Move: "move",
	Play: "play",
	Indicate: "indicate",
	TurnToLamp: "turnToLamp",
	Destroy: "destroy"
};

/**
 * @class 2D sprite of {pm.data.LightRobot}.
 * @implements pm.IsoRobotSprite
 */
var LightRobotSprite = pm.IsoRobotSprite.extend(/** @lends LightRobotSprite# */
	{
		ctor: function ()
		{
			cc.animationCache.addAnimations("Robot/light_robot-animations.plist");

			pm.spriteUtils.registerTempSpriteFrames("Robot/light_robot.plist");
			pm.spriteUtils.registerTempSpriteFrames("Robot/light_robot-indicate.plist");
			pm.spriteUtils.registerTempSpriteFrames("Robot/light_robot-light.plist");
			pm.spriteUtils.registerTempSpriteFrames("Robot/light_robot-broken.plist");

			this._super(cc.spriteFrameCache.getSpriteFrame(this._getFrameName(0)));

			this._minPoint = cc.p(30, 23);
			this._maxPoint = cc.p(30, -4);
		},

		playAnimation: function(callback, target, type, args)
		{
			if(!CORE_BUILD)
			{
				switch (type)
				{
					case LightRobotAnimation.Play:
						this._play(args, callback, target);
						break;
					case LightRobotAnimation.TurnToLamp:
						this._turnToLamp(args[0], args[1], callback, target);
						break;
					default:
						pm.IsoRobotSprite.prototype.playAnimation.call(this, callback, target, type, args);
						break;
				}
			}
		},

		_play: function(wayPeace, endCallback, target)
		{
			var animationList = [];

			var inverseDirection = -1;

			if (wayPeace.length % 2 !== 0 && wayPeace.length !== 1)
			{
				inverseDirection = wayPeace[wayPeace.length - 1];
				wayPeace.pop();
			}

			for (var i = 0; i< wayPeace.length - 1; i += 2)
			{
				var t = i + 1;
				var j = i + 2;

				this._insertMovePeace(animationList, wayPeace[t]);
				if (j < wayPeace.length)
					this._insertTurnAndIndicatePeace(animationList, wayPeace[i], wayPeace[j]);
			}

			if (inverseDirection >= 0)
				this._insertTurnAndIndicatePeace(animationList, wayPeace[wayPeace.length - 2], inverseDirection);

			var callback = cc.callFunc(endCallback, target);
			var moveEnd = cc.callFunc(endCallback, target);
			animationList.push(moveEnd, callback);

			this.getSprite().runAction(cc.sequence(animationList));
		},

		_insertTurnAndIndicatePeace: function(animationList, oldDirection, curDirection)
		{
			this._insertIndicatePeace(animationList, oldDirection);
			this._insertTurnPeace(animationList, oldDirection, curDirection);
			this._insertIndicatePeace(animationList, curDirection);
		},

		_insertIndicatePeace: function(animationList, direction)
		{
			var animation = cc.animationCache.getAnimation(this._getIndicateAnimationName(direction));
			animation.setDelayPerUnit(pm.settings.getAnimationSpeed()/animation.getTotalDelayUnits());
			var animate = new cc.Animate(animation);

			animationList.push(animate);
		},

		_insertMovePeace: function(animationList, pos)
		{
			var targetPos = this.getRealPosition(pos);

			var moveEnd = cc.callFunc(function()
			{
				this._needToUpdateZOrder = false;
			}, this);

			var moveRobot = cc.moveTo(pm.settings.getAnimationSpeed(), targetPos);

			var moveStart = cc.callFunc(function()
			{
				this._needToUpdateZOrder = true;
			}, this);

			animationList.push(moveStart);
			animationList.push(moveRobot);
			animationList.push(moveEnd);
		},

		_insertTurnPeace: function (animationList, oldDirection, curDirection)
		{
			if (Math.abs((8 + curDirection - oldDirection) % 8) < Math.abs((8 - curDirection + oldDirection) % 8))
			{
				for (var i = oldDirection; i !== curDirection; i = (i + 1) % 8)
				{
					var animation = cc.animationCache.getAnimation(this._getTurnAnimationName(i, (i + 1) % 8));
					animation.setDelayPerUnit(pm.settings.getAnimationSpeed() / animation.getTotalDelayUnits());
					var animate = new cc.Animate(animation);
					animationList.push(animate);
				}
			}
			else
			{
				for (var i = oldDirection; i !== curDirection; i = (8 + i - 1) % 8)
				{
					var animation = cc.animationCache.getAnimation(this._getTurnAnimationName(i, (8 + i - 1) % 8));
					animation.setDelayPerUnit(pm.settings.getAnimationSpeed() / animation.getTotalDelayUnits());
					var animate = new cc.Animate(animation);
					animationList.push(animate);
				}
			}
		},

		_turnToLamp: function(oldDirection, direction, endCallBack, target)
		{
			var animations = [];

			var animation = cc.animationCache.getAnimation(this._getLightAnimationName(direction));
			animation.setDelayPerUnit(pm.settings.getAnimationSpeed() / animation.getTotalDelayUnits());

			if (oldDirection !== direction)
				this._insertTurnPeace(animations, oldDirection, direction);

			var lightAnim = new cc.Animate(animation);
			animations.push(lightAnim);

			var turnEnd = new cc.CallFunc(endCallBack, target);
			animations.push(turnEnd);

			this.getSprite().runAction(cc.sequence(animations));
		},

		_getActiveSpritePosition: function()
		{
			return cc.p(30, 106);
		},

		_getCommandsSpritePosition: function()
		{
			return cc.p(30, 135);
		},

		_getFrameName: function(direction)
		{
			return "light_robot{0}.png".format(2 * direction);
		},

		_getTurnAnimationName: function(oldDirection, curDirection)
		{
			return "light_robot_{0}->{1}".format(oldDirection, curDirection);
		},

		_getDestroyAnimationName: function (direction)
		{
			return "light_robot_d_{0}".format(direction);
		},

		_getIndicateAnimationName: function (direction)
		{
			return "light_robot_i_{0}".format(direction);
		},

		_getLightAnimationName: function (direction)
		{
			return "light_robot_l_{0}".format(direction);
		}
	});
