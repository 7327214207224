/**
 * Created by Kirill Mashchenko on 07.07.2018.
 */

/**
 * @class 2D robot
 * @implements PlayerRobot4
 */
pm.data.TrainRobot = PlayerRobot4Walls.extend(/** @lends pm.data.TrainRobot# */{
	typeName: "TrainRobot",

	connectionSprites: {},

	ctor: function()
	{
		this._addNonEnumerableProps("connectionSprites");
		this._super();

		this.nativeFunctionMap[pm.data.TrainRobot.NativeMethod.Carry] = new pm.NativeFunction(this, this._carry);
		this.nativeFunctionMap[pm.data.TrainRobot.NativeMethod.Magnet] = new pm.NativeFunction(this, this._magnet);
		this.nativeFunctionMap[pm.data.TrainRobot.NativeMethod.DisMagnet] = new pm.NativeFunction(this, this._disMagnet);
		this.nativeFunctionMap[pm.data.TrainRobot.NativeMethod.MagnetAll] = new pm.NativeFunction(this, this._magnetAll);
		this.nativeFunctionMap[pm.data.TrainRobot.NativeMethod.DisMagnetAll] = new pm.NativeFunction(this, this._disMagnetAll);

		this.nativeFunctions.push(pm.data.TrainRobot.NativeMethod.Carry);
		this.nativeFunctions.push(pm.data.TrainRobot.NativeMethod.Magnet);
		this.nativeFunctions.push(pm.data.TrainRobot.NativeMethod.DisMagnet);
		this.nativeFunctions.push(pm.data.TrainRobot.NativeMethod.MagnetAll);
		this.nativeFunctions.push(pm.data.TrainRobot.NativeMethod.DisMagnetAll);

		this.conditions.push(pm.data.TrainRobot.Condition.CanLink);
		this.conditionOpposites.push(pm.data.TrainRobot.Condition.CanNotLink);
		this.conditions.push(pm.data.TrainRobot.Condition.CanDislink);
		this.conditionOpposites.push(pm.data.TrainRobot.Condition.CanNotDislink);
	},

	reset: function()
	{
		if(this.getMap().mapLayer)
		{
			this.getMap().mapLayer.objectLayer.stopAllActions();
		}
		PlayerRobot4.prototype.reset.call(this);
	},

	_canMoveTo: function(target, direction)
	{
		var element = this.getMap().element(target);

		return !element.walls[(direction + this.getDirectionCount()/2) % this.getDirectionCount()] &&
            element.getObjectCount() === 0;
	},

	_move: function()
	{
		this._disconnectFromTrain();

		var nextPos = this.getMap().getNextDirectionalElementPosition(this.position, this.direction);
		this._moveTo(nextPos, false);
	},

	_carry: function()
	{
		var nextPos = this.getMap().getNextDirectionalElementPosition(this.position, this.direction);
		var objectPosition = this.position;

		var wasBroken = this.isBroken();

		if (wasBroken || this.isBroken())
			return;

		var train = this._findTrain();

		if (train.length === 0 || train[0].magnetRobotId === null)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		var animations = new pm.AnimationTransaction();

        this._moveTo(nextPos, false, undefined, true);
        if (this.isBroken())
        	return;

		animations.addAnimationToPart(this, RobotAnimation2D.Move, this._endMove, this.position);

        var nextPosType = cc.p(0,0);
        switch(this.direction)
        {
            case MapDirection4.Up: nextPosType.y--; break;
            case MapDirection4.Left: nextPosType.x--; break;
            case MapDirection4.Down: nextPosType.y++; break;
            case MapDirection4.Right: nextPosType.x++; break;
        }

		var car;
        var j = 0;

		for (var i = 0; i < train.length; ++i)
		{
			car = train[i];

			car.magnetRobotId = this.id;

			var oldObjectPosition = car.position;

			car.moveTo(objectPosition);

			if (!(objectPosition.x - oldObjectPosition.x === nextPosType.x
				&& objectPosition.y - oldObjectPosition.y === nextPosType.y))
			{
				nextPosType = cc.p(objectPosition.x - oldObjectPosition.x, objectPosition.y - oldObjectPosition.y);

				animations.addCallToPart(function (target, k) {
					train[k].setConnectionSpritesAtPosition(train[k].position, train[k].oldPosition, false);

					if (k > 0)
					{
						train[k - 1].setConnectionSpritesAtPosition(
							train[k].oldPosition, train[k - 1].oldPosition, false);
					}
					else
					{
						train[0].setConnectionVisible(MapDirection4.Right, false);
						train[0].setConnectionVisible(MapDirection4.Down, false);
					}
				}, this, i);
				animations.addPart();

				animations.addCallToPart(this._recalculateSprites, this, [j, i]);
				animations.addPart();

				j = i;
			}

			animations.addAnimationToPart(car, TrainObjectAnimation.Move, car._endMoving, car.position);

			objectPosition = oldObjectPosition;
		}

		animations.addPart();
		animations.addCallToPart(this._recalculateSprites, this, [j, train.length]);
		animations.addPart();

		animations.playAnimations(this.getMap().mapLayer.objectLayer);
	},

	_reverseCarry: function(target)
	{
		var train = this._findTrain();

		var car = train[train.length - 1];

		var animations = new pm.AnimationTransaction();

		if (train.length === 0 || train[0].magnetRobotId === null || train[0].position === train[0].oldPosition)
		{
			this.position = target;
			train = this._findTrain();
			this.position = this._oldPosition;

			if (train.length === 0 || train[0].magnetRobotId === null)
			{
				this.playAnimation(RobotAnimation2D.Move, this._endMove, target);
				return;
			}

			animations.addAnimationToPart(this, RobotAnimation2D.Move, this._endMove, target);
			animations.addPart();

			animations.addCallToPart(function (target, pos) {
				train[0].setConnectionSpritesAtPosition(pos);
				train[0].setConnectionSpritesAtPositionRightDown(pos);
			}, this, target);
			animations.addPart();

			animations.playAnimations(this.getMap().mapLayer.objectLayer);

			return;
		}

		var objectPosition = car.position;

		var nextPosType = cc.p(car.position.x - car.oldPosition.x, car.position.y - car.oldPosition.y);

		this._reverseRecalculateSprites();

		var j = train.length;

		for (var i = train.length - 1; i >= 0; --i)
		{
			car = train[i];

			var oldObjectPosition = car.oldPosition;

			if (!(objectPosition.x - oldObjectPosition.x === nextPosType.x
				&& objectPosition.y - oldObjectPosition.y === nextPosType.y))
			{
				nextPosType = cc.p(objectPosition.x - oldObjectPosition.x, objectPosition.y - oldObjectPosition.y);

				animations.addCallToPart(function (target, k) {
					train[k].setConnectionSpritesAtPosition(train[k].position, train[k].oldPosition, false);

					if (k + 1 < train.length)
						train[k + 1].setConnectionSpritesAtPosition(
							train[k].oldPosition, train[k + 1].oldPosition, false);
				}, this, i);
				animations.addPart();

				animations.addCallToPart(this._reverseRecalculateSprites, this, [i + 1, j]);
				animations.addPart();

				j = i + 1;
			}

			animations.addAnimationToPart(car, TrainObjectAnimation.Move, car._endMoving, car.position);

			objectPosition = oldObjectPosition;
		}


		if (!(oldObjectPosition.x - this.position.x === nextPosType.x
			&& oldObjectPosition.y - this.position.y === nextPosType.y))
		{
			animations.addCallToPart(function () {
				train[0].setConnectionSpritesAtPosition(this._oldPosition, train[0].oldPosition, false);
				train[0].setConnectionSpritesAtPositionRightDown(this._oldPosition, train[0].oldPosition, false);
			}, this);
			animations.addPart();

			animations.addCallToPart(this._reverseRecalculateSprites, this, [i + 1, j]);
			animations.addPart();
		}

		animations.addAnimationToPart(this, RobotAnimation2D.Move, this._endMove, target);
		animations.addPart();

		animations.addCallToPart(this._reverseRecalculateSprites, this, [0, j]);
		animations.addPart();

		animations.playAnimations(this.getMap().mapLayer.objectLayer);
	},

	_findTrain: function()
	{
		var map = this.getMap();
		var lastCar = null;
		var preLastCar = null;

		var firstCar = null;
		var inverse = false;

		var train = [];

		var x1, y1, x2, y2, x3, y3;

		switch(this.direction)
		{
			case MapDirection4.Up:
				x1 = this.position.x;
				y1 = this.position.y+1;
				x2 = this.position.x+1;
				y2 = this.position.y;
				x3 = this.position.x-1;
				y3 = this.position.y;
				break;
			case MapDirection4.Left:
				x1 = this.position.x+1;
				y1 = this.position.y;
				x2 = this.position.x;
				y2 = this.position.y-1;
				x3 = this.position.x;
				y3 = this.position.y+1;
				break;
			case MapDirection4.Down:
				x1 = this.position.x;
				y1 = this.position.y-1;
				x2 = this.position.x-1;
				y2 = this.position.y;
				x3 = this.position.x+1;
				y3 = this.position.y;
				break;
			case MapDirection4.Right:
				x1 = this.position.x-1;
				y1 = this.position.y;
				x2 = this.position.x;
				y2 = this.position.y+1;
				x3 = this.position.x;
				y3 = this.position.y-1;
				break;
		}

		var trainExists = false;

		for (var i = 0; i < 3; ++i)
		{
			var elem = null;

			if (i === 0)
				elem = map.element(cc.p(x1, y1));
			else if (i === 1)
				elem = map.element(cc.p(x2, y2));
			else
				elem = map.element(cc.p(x3, y3));

			if (!elem)
				continue;

			for (var o in elem._objectsHere)
			{
				var curObject = elem._objectsHere[o];

				if (curObject.firstForRobotId === this.id)
				{
					firstCar = curObject;
					train.push(firstCar);
					trainExists = true;
					i = 4;

					break;
				}
			}
		}

		if(!trainExists)
		{
			for (var i = 0; i < 3; ++i)
			{
				var elem = null;

				if (i === 0)
					elem = map.element(cc.p(x1, y1));
				else if (i === 1)
					elem = map.element(cc.p(x2, y2));
				else
					elem = map.element(cc.p(x3, y3));

				if (!elem)
					continue;

				for (var o in elem._objectsHere)
				{
					var curObject = elem._objectsHere[o];

					if (curObject.magnetRobotId !== null)
						continue;

					if (curObject.parentObject && curObject.childObject)
						continue;

					if (!curObject.childObject && curObject.isMagnet)
					{
						firstCar = curObject;
						train.push(firstCar);
						inverse = true;
						i = 4;
					}
					else if (!curObject.parentObject && curObject.isMagnet)
					{
						firstCar = curObject;
						train.push(firstCar);
						inverse = false;
						i = 4;
					}
				}
			}
		}
		else
		{
			if(!train[0].childObject && train[0].parentObject)
				inverse = true;
		}

		if (firstCar)
		{
			lastCar = firstCar;

			if (!inverse)
			{
				while (firstCar.childObject)
				{
					preLastCar = firstCar;
					lastCar = firstCar.childObject;
					train.push(lastCar);
					firstCar = lastCar;
				}
			}
			else
			{
				while (firstCar.parentObject)
				{
					preLastCar = firstCar;
					lastCar = firstCar.parentObject;
					train.push(lastCar);
					firstCar = lastCar;
				}
			}
		}

		if (inverse && train.length > 0)
		{
			for (var i = 1; i < train.length - 1; ++i)
			{
				train[i].childObject = train[i+1];
				train[i].parentObject = train[i-1];
			}
			if(train.length > 1)
			{
				train[0].childObject = train[1];
				train[train.length - 1].parentObject = train[train.length -2];
			}
			train[0].parentObject = null;
			train[train.length - 1].childObject = null;
		}

		return train;
	},

	_magnet: function(checkCondition)
	{
		if (checkCondition === undefined)
			checkCondition = false;

		var map = this.getMap();
		var lastCar = null;
		var preLastCar = null;

		var train = this._findTrain();

		var x1, x2, x3, y1, y2, y3, dir1, dir2, dir3;

		if (train.length === 0 || train[0].magnetRobotId === null)
		{
			switch(this.direction)
			{
				case MapDirection4.Up:
					x1 = this.position.x;
					y1 = this.position.y+1;
					x2 = this.position.x+1;
					y2 = this.position.y;
					x3 = this.position.x-1;
					y3 = this.position.y;
					dir1 = MapDirection4.Down;
					dir2 = MapDirection4.Right;
					dir3 = MapDirection4.Left;
					break;
				case MapDirection4.Left:
					x1 = this.position.x+1;
					y1 = this.position.y;
					x2 = this.position.x;
					y2 = this.position.y-1;
					x3 = this.position.x;
					y3 = this.position.y+1;
					dir1 = MapDirection4.Right;
					dir2 = MapDirection4.Up;
					dir3 = MapDirection4.Down;
					break;
				case MapDirection4.Down:
					x1 = this.position.x;
					y1 = this.position.y-1;
					x2 = this.position.x-1;
					y2 = this.position.y;
					x3 = this.position.x+1;
					y3 = this.position.y;
					dir1 = MapDirection4.Up;
					dir2 = MapDirection4.Left;
					dir3 = MapDirection4.Right;
					break;
				case MapDirection4.Right:
					x1 = this.position.x-1;
					y1 = this.position.y;
					x2 = this.position.x;
					y2 = this.position.y+1;
					x3 = this.position.x;
					y3 = this.position.y-1;
					dir1 = MapDirection4.Left;
					dir2 = MapDirection4.Down;
					dir3 = MapDirection4.Up;
					break;
			}

			var found = false;

			for (var i = 0; i < 3; ++i)
			{
				var elem = null;
				var dir = -1;

				if (i === 0)
				{
					elem = map.element(cc.p(x1, y1));
					dir = dir1;
				}
				else if (i === 1)
				{
					elem = map.element(cc.p(x2, y2));
					dir = dir2;
				}
				else
				{
					elem = map.element(cc.p(x3, y3));
					dir = dir3;
				}

				if (!elem)
					continue;

				for (var o in elem._objectsHere)
				{
					var object = elem._objectsHere[o];

					if (this.getMap().element(this.position).walls[dir])
						continue;

					if (object.magnetRobotId !== null && object.magnetRobotId !== this.id)
						continue;

					if (object.parentObject && object.childObject)
						continue;

					if (!checkCondition)
					{
						if (train.length === 0)
						{
							object.childObject = null;
							object.parentObject = null;
						}

						object.firstForRobotId = this.id;

						object.isMagnet = true;

						object.setConnectionSpritesAtPosition(this.position);
						object.setConnectionSpritesAtPositionRightDown(this.position);
					}

					found = true;

					i = 4;
					break;
				}
			}

			if (!found)
			{
				if (!checkCondition)
					this.setStateFlag(pm.RobotState.Broken);

				return false;
			}
		}
		else
		{
			lastCar = train[train.length - 1];

			if (train.length === 1)
				preLastCar = this;
			else
				preLastCar = train[train.length - 2];

			var x = lastCar.position.x;
			var y = lastCar.position.y;

			if (preLastCar.position.x === x)
			{
				if (preLastCar.position.y > y)
				{
					x1 = x;
					y1 = y - 1;
					x2 = x - 1;
					y2 = y;
					x3 = x + 1;
					y3 = y;
					dir1 = MapDirection4.Up;
					dir2 = MapDirection4.Left;
					dir3 = MapDirection4.Right;
				}
				else
				{
					x1 = x;
					y1 = y + 1;
					x2 = x + 1;
					y2 = y;
					x3 = x - 1 ;
					y3 = y;
					dir1 = MapDirection4.Down;
					dir2 = MapDirection4.Right;
					dir3 = MapDirection4.Left;
				}
			}
			else if (preLastCar.position.y === y)
			{
				if (preLastCar.position.x > x)
				{
					x1 = x - 1;
					y1 = y;
					x2 = x;
					y2 = y + 1;
					x3 = x;
					y3 = y - 1 ;
					dir1 = MapDirection4.Left;
					dir2 = MapDirection4.Down;
					dir3 = MapDirection4.Up;
				}
				else
				{
					x1 = x + 1 ;
					y1 = y;
					x2 = x ;
					y2 = y - 1;
					x3 = x;
					y3 = y + 1;
					dir1 = MapDirection4.Right;
					dir2 = MapDirection4.Up;
					dir3 = MapDirection4.Down;
				}
			}

			var found = false;

			for (var i = 0; i < 3; ++i)
			{
				var elem = null;

				if (i === 0)
				{
					elem = map.element(cc.p(x1, y1));
					dir = dir1;
				}
				else if (i === 1)
				{
					elem = map.element(cc.p(x2, y2));
					dir = dir2;
				}
				else
				{
					elem = map.element(cc.p(x3, y3));
					dir = dir3;
				}

				if (!elem)
					continue;

				for (var o in elem._objectsHere)
				{
					var object = elem._objectsHere[o];

					if (this.getMap().element(lastCar.position).walls[dir])
						continue;

					if (object.magnetRobotId !== null)
						continue;

					if (object.parentObject && object.childObject)
						continue;

					if (!object.parentObject && !lastCar.childObject)
					{
						if(!checkCondition)
						{
							lastCar.childObject = object;
							object.parentObject = lastCar;

							object.isMagnet = true;

							object.setConnectionSpritesAtPosition(lastCar.position);
							lastCar.setConnectionSpritesAtPosition(object.position);

						}
						found = true;
						i = 4;
						break;
					}
					else if (!object.childObject && !lastCar.parentObject)
					{
						if(!checkCondition)
						{
							lastCar.parentObject = object;
							object.childObject = lastCar;

							object.isMagnet = true;

							object.setConnectionSpritesAtPosition(lastCar.position);
							lastCar.setConnectionSpritesAtPosition(object.position);
						}
						found = true;
						i = 4;
						break;
					}
					else if (object.parentObject && !lastCar.childObject)
					{
						if(!checkCondition)
						{
							var tempTrain = [];
							tempTrain.push(object);
							var tempObject = object.parentObject;

							while (tempObject.parentObject)
							{
								tempTrain.push(tempObject);
								tempObject = tempObject.parentObject;
							}

							tempTrain.push(tempObject);

							for (var k = 0; k < tempTrain.length; ++k)
							{
								var tempCar = tempTrain[k];

								var temp = tempCar.childObject;
								tempCar.childObject = tempCar.parentObject;
								tempCar.parentObject = temp;
							}

							lastCar.childObject = object;
							object.parentObject = lastCar;

							object.isMagnet = true;

							object.setConnectionSpritesAtPosition(lastCar.position);
							lastCar.setConnectionSpritesAtPosition(object.position);
						}
						found = true;
						i = 4;
						break;
					}
					else if (object.childObject && !lastCar.parentObject)
					{
						if(!checkCondition)
						{
							var tempTrain = [];
							tempTrain.push(object);
							var tempObject = object.childObject;

							while (tempObject.childObject)
							{
								tempTrain.push(tempObject);
								tempObject = tempObject.childObject;
							}

							tempTrain.push(tempObject);

							for (var k = 0; k < tempTrain.length; ++k)
							{
								var tempCar = tempTrain[k];

								var temp = tempCar.childObject;
								tempCar.childObject = tempCar.parentObject;
								tempCar.parentObject = temp;
							}

							lastCar.parentObject = object;
							object.childObject = lastCar;

							object.isMagnet = true;

							object.setConnectionSpritesAtPosition(lastCar.position);
							lastCar.setConnectionSpritesAtPosition(object.position);
						}
						found = true;
						i = 4;
						break;
					}

					i = 4;
					break;
				}
			}
			if (!found)
			{
				if (!checkCondition)
					this.setStateFlag(pm.RobotState.Broken);

				return false;
			}
		}

		if(checkCondition)
			return  true;

		train = this._findTrain();

		for (var i = 0; i < train.length; ++i)
		{
			train[i].magnetRobotId = this.id;
			train[i].setActiveVisible(true);
		}

		return true;
	},

	_disconnectFromTrain: function ()
	{
		var train = this._findTrain();

		for (var i = 0; i < train.length; ++i)
		{
			train[i].magnetRobotId = null;
			train[i].setActiveVisible(false);
		}

		if (train.length > 0)
		{
			train[0].setConnectionSpritesAtPosition(this.position, train[0].position, false);
			train[0].setConnectionSpritesAtPositionRightDown(this.position, train[0].position, false);
			train[0].firstForRobotId = null;
		}
	},

	_disMagnet: function(checkCondition)
	{
		if (checkCondition === undefined)
			checkCondition = false;

		var train = this._findTrain();

		if (train.length === 0 || train[0].magnetRobotId === null)
		{
			if(!checkCondition)
				this.setStateFlag(pm.RobotState.Broken);

			return false;
		}

		if(checkCondition)
			return  true;

		var lastCar = train[train.length - 1];

		lastCar.parentObject = null;
		lastCar.childObject = null;
		lastCar.isMagnet = false;
		lastCar.magnetRobotId = null;
		lastCar.firstForRobotId = null;

		lastCar.setConnectionVisible(MapDirection4.Left, false);
		lastCar.setConnectionVisible(MapDirection4.Up, false);
		lastCar.setActiveVisible(false);

		if (train.length >= 2)
		{
			var preLastCar = train[train.length - 2];

			if (preLastCar.childObject && preLastCar.childObject.position.x === lastCar.position.x && preLastCar.childObject.position.y === lastCar.position.y)
				preLastCar.childObject = null;
			else if (preLastCar.parentObject && preLastCar.parentObject.position.x === lastCar.position.x && preLastCar.parentObject.position.y === lastCar.position.y)
				preLastCar.parentObject = null;

			preLastCar.setConnectionSpritesAtPosition(lastCar.position, preLastCar.position, false);
		}
		else
		{
			train[0].setConnectionVisible(MapDirection4.Right, false);
			train[0].setConnectionVisible(MapDirection4.Down, false);
		}
	},

	_magnetAll: function()
	{
		while(this._magnet(true))
			this._magnet();
	},

	_disMagnetAll: function()
	{
		var train = this._findTrain();

		if (train.length === 0 || train[0].magnetRobotId === null)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		for(var i = train.length - 1; i >= 0; --i) {
			train[i].parentObject = null;
			train[i].childObject = null;
			train[i].isMagnet = false;
			train[i].magnetRobotId = null;
			train[i].firstForRobotId = null;

			train[i].setConnectionVisible(MapDirection4.Left, false);
			train[i].setConnectionVisible(MapDirection4.Up, false);
			train[i].setActiveVisible(false);
		}

		train[0].setConnectionVisible(MapDirection4.Right, false);
		train[0].setConnectionVisible(MapDirection4.Down, false);
	},

	_checkConditionInternal: function(condition, args)
	{
		if (condition === pm.data.TrainRobot.Condition.CanNotLink)
		{
			var flag = this._magnet(true);

			if (!flag)
				return true;

			return false;
		}
		else if (condition === pm.data.TrainRobot.Condition.CanLink)
		{
			var flag = this._magnet(true);

			if (flag)
				return true;

			return false;
		}
		else if (condition === pm.data.TrainRobot.Condition.CanDislink)
		{
			var flag = this._disMagnet(true);

			if (flag)
				return true;

			return false;
		}
		else if (condition === pm.data.TrainRobot.Condition.CanNotDislink)
		{
			var flag = this._disMagnet(true);

			if (!flag)
				return true;

			return false;
		}
		else
		{
			return PlayerRobot4Walls.prototype._checkConditionInternal.call(this, condition);
		}
	},

	_recalculateSprites: function(target, indexes)
	{
		if (CORE_BUILD || pm.settings.isAnimationDisabled())
			return;

		var train = this._findTrain();

		if (train.length === 0  || train[0].magnetRobotId === null)
			return;

		var firstIndex;
		var lastIndex;

		if (indexes === undefined)
		{
			firstIndex = 0;
			lastIndex = train.length;
		}
		else
		{
			firstIndex = indexes[0];
			lastIndex = indexes[1];
		}

		if (firstIndex === 0)
		{
			train[0].connectionSprites[MapDirection4.Right].setVisible(false);
			train[0].connectionSprites[MapDirection4.Down].setVisible(false);

			train[0].setConnectionSpritesAtPosition(this._oldPosition, train[0].oldPosition);
			train[0].setConnectionSpritesAtPositionRightDown(this._oldPosition, train[0].oldPosition);
		}
		else
		{
			train[firstIndex - 1].setConnectionSpritesAtPosition(train[firstIndex].position);
			train[firstIndex].setConnectionSpritesAtPosition(train[firstIndex - 1].position);
		}

		if (lastIndex < train.length)
		{
			train[lastIndex].connectionSprites[MapDirection4.Left].setVisible(false);
			train[lastIndex].connectionSprites[MapDirection4.Up].setVisible(false);
		}

		if (lastIndex + 1 < train.length)
			train[lastIndex].setConnectionSpritesAtPosition(
				train[lastIndex + 1].oldPosition, train[lastIndex].oldPosition);
	},

	_reverseRecalculateSprites: function(target, indexes)
	{
		if (CORE_BUILD || pm.settings.isAnimationDisabled())
			return;

		var train = this._findTrain();

		if (train.length === 0 || train[0].magnetRobotId === null)
			return;

		var firstIndex;
		var lastIndex;

		if (indexes === undefined)
		{
			firstIndex = 0;
			lastIndex = train.length;
		}
		else
		{
			firstIndex = indexes[0];
			lastIndex = indexes[1];
		}

		for (var i = lastIndex - 1; i >= firstIndex; --i)
		{
			train[i].connectionSprites[MapDirection4.Left].setVisible(false);
			train[i].connectionSprites[MapDirection4.Up].setVisible(false);

			if (i + 1 < train.length)
				train[i].setConnectionSpritesAtPosition(train[i + 1].oldPosition, train[i].oldPosition);

			if (i > firstIndex)
				train[i].setConnectionSpritesAtPosition(train[i - 1].oldPosition, train[i].oldPosition);
		}

		if (lastIndex < train.length)
			train[lastIndex].setConnectionSpritesAtPosition(train[lastIndex - 1].position);

		if (firstIndex > 0)
		{
			train[firstIndex - 1].connectionSprites[MapDirection4.Left].setVisible(false);
			train[firstIndex - 1].connectionSprites[MapDirection4.Up].setVisible(false);
		}

		if (firstIndex - 1 > 0)
			train[firstIndex - 1].setConnectionSpritesAtPosition(
				train[firstIndex - 2].oldPosition, train[firstIndex - 1].oldPosition);

		if (firstIndex <= 1)
		{
			train[0].connectionSprites[MapDirection4.Right].setVisible(false);
			train[0].connectionSprites[MapDirection4.Down].setVisible(false);

			train[0].setConnectionSpritesAtPosition(this._oldPosition, train[0].oldPosition);
			train[0].setConnectionSpritesAtPositionRightDown(this._oldPosition, train[0].oldPosition);
		}
	},

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new TrainRobotSprite();

			return this.sprite;
		}
	},

	_turn: function(newDirection)
	{
		var train = this._findTrain();

		if(!(train.length === 0 || train[0].magnetRobotId === null))
		{
			if(newDirection === MapDirection4.Up && train[0].position.y < this.position.y ||
				newDirection === MapDirection4.Down && train[0].position.y > this.position.y ||
				newDirection === MapDirection4.Left && train[0].position.x < this.position.x ||
				newDirection === MapDirection4.Right && train[0].position.x > this.position.x)
				this._disconnectFromTrain();
		}

		this.playAnimation(RobotAnimation2D.Turn, this._endTurn, [this.direction, newDirection]);

		this.direction = newDirection;
	},

	_turnLeft: function()
	{
		var newDirection = (this.direction + 1) % this.getDirectionCount();
		this._turn(newDirection);
	},

	_turnRight: function()
	{
		var newDirection = (this.direction + this.getDirectionCount() - 1) % this.getDirectionCount();
		this._turn(newDirection);
	},

	_moveTo: function(target, reverse, initialDirection, skipAnimation)
	{
		if(this.isBroken())
		{
			if(reverse)
				this.clearStateFlag(pm.RobotState.Broken);

			this.updateSprite();
			return;
		}

		var map = this.getMap();

		var move = true;

		if(!target)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		for(var i in map.element(target)._robotsHere)
		{
			var r = map.element(target)._robotsHere[i];

			if(!(r instanceof pm.AbstractRobot))
				continue;

			if(!r.canMoveOn())
			{
				if(!r.interactable())
					this.setStateFlag(pm.RobotState.Broken);

				move = false;
				break;
			}
		}

		if(move)
		{
			if (initialDirection === undefined)
				initialDirection = this.direction;

			var direction = reverse ? (initialDirection + this.getDirectionCount()/2) % this.getDirectionCount() : initialDirection;

			if(!this._canMoveTo(target, direction) && !reverse)
			{
				this.setStateFlag(pm.RobotState.Broken);

				return;
			}

			if(!this.isBroken() && !skipAnimation)
			{
				if (!reverse)
					this.playAnimation(RobotAnimation2D.Move, this._endMove, target);
				else
					this._reverseCarry(target);
			}

			this._oldPosition = this.position;
			this.position = target;

			map.element(this.position).addRobot(this);
		}
		else if(!this.isBroken())
		{
			for(var i in map.element(target)._robotsHere)
			{
				var r = map.element(target)._robotsHere[i];

				if(!(r instanceof pm.AbstractRobot))
					continue;

				r.interact(this, this._endInteract);
			}
		}
	},

	getType: function() { return pm.TrainLevelModule.RobotTypes.TrainRobot; }
});

pm.data.TrainRobot.NativeMethod = {
	Carry: "push_pull",
	Magnet: "train_link",
	DisMagnet: "train_unlink",
	MagnetAll: "train_link_all",
	DisMagnetAll: "train_unlink_all"
};

pm.data.TrainRobot.Condition =
{
	CanLink: "train_can_link",
	CanNotLink: "train_can_not_link",
	CanDislink: "train_can_dislink",
	CanNotDislink: "train_can_not_dislink"
};
