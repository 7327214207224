/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

var KumirMapEditorLayer = KumirMapLayer.extend(SquarableMapEditorLayerHelper).extend({

	createMapElement: function ()
	{
		var el = null;
		el = new pm.data.KumirMapElement();
		el.originalType = KumirMapElementType.LightBlueGrass;
		el.initElement();
		return el;
	},

	_drawMapElement: function (pos)
	{
		if (pm.appUtils.useExtraMethodsInKumirRobot())
		{
			var sprite = this._map.element(pos).generateTerrainSprite(this._previewDraw);
			sprite.getSprite().setPosition(this.realPosition(pos, cc.p(0, 0)));
			this.addTerrainObject(sprite, 0);
		}
		else
		{
			SquarableMapEditorLayerHelper._drawMapElement.call(this, pos);
		}
	},

	_drawMapElements: function()
	{
		for(var x = 0; x < this._map.width; ++x)
		{
			for(var y = 0; y < this._map.height; ++y)
			{
				var p = cc.p(x, y);
				var terrainSprite = this._map.element(p).generateTerrainSprite(this._previewDraw);

				terrainSprite.getSprite().setPosition(this.realPosition(p, cc.p(0, 0)));
				this.addTerrainObject(terrainSprite, 0);

				if (this._map.element(p).walls[MapDirection4.Right])
				{
					var type = this._getWallType(MapDirection4.Right);
					this._drawWall(p, type);
				}
				if (this._map.element(p).walls[MapDirection4.Down])
				{
					var type = this._getWallType(MapDirection4.Down);
					this._drawWall(p, type);
				}

				if(this._map.element(p).startForRobot !== pm.MapElement.START_FOR_NO_ROBOT)
					this.drawRobotStartPositionMark(this._map.element(p).startForRobot, p);

				if (this._map.element(p)._paintTarget)
					this._map.element(p)._notCompletedSprite.setVisible(true);
			}
		}
	},

	_mapElementClicked: function (pos)
	{
		if (this._tapType === EditorTapsType.ChangeMapElement)
		{
			if (this._selectedObjectForTapType === pm.data.KumirMapElement.START_ELEMENT_POS)
			{
				var element = this._map.element(pos);

				if(element._paintTarget)
				{
					element._notCompletedSprite.setVisible(false);
					element._paintTarget = false;
					this._map._paintTargetElements.splice(this._map._paintTargetElements.indexOf(pos), 1);
				}
				else
				{
					element._notCompletedSprite.setVisible(true);
					element._paintTarget = true;
					this._map._paintTargetElements.push(pos);
				}
			}
			else if (!cc.isNumber(this._selectedObjectForTapType) && pm.appUtils.useExtraMethodsInKumirRobot())
			{
				var posY = this._map.height - 1 - pos.y;

				var label = this._selectedObjectForTapType.menu.getChildByTag(pm.data.KumirMapElement.LABEL_TAG);
				label.setString("x: " + pos.x + " y: " + posY);

				var radiation = this._selectedObjectForTapType.menu.getChildByTag(pm.data.KumirMapElement.RADIATION_TAG);

				radiation.setString(this._map.element(pos).getRadiation());
				radiation.setInsertTextCallback(this, function() {
					this._map.element(pos).setRadiation(Number(radiation.getString()));
				}.bind(this));

				var temperature = this._selectedObjectForTapType.menu.getChildByTag(pm.data.KumirMapElement.TEMPERATURE_TAG);

				temperature.setString(this._map.element(pos).getTemperature());
				temperature.setInsertTextCallback(this, function() {
					this._map.element(pos).setTemperature(Number(temperature.getString()));
				}.bind(this));

				this._selectedObjectForTapType.menu.setVisible(true);
			}
			else
			{
				var element = this._map.element(pos);

				element.setType(this._selectedObjectForTapType);
				element.originalType = this._selectedObjectForTapType;
				pm.sendCustomEvent(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR);
			}
		}
		else if (this._tapType === EditorTapsType.AddRobot)
		{
			if (this._map.element(pos).getRobotsCount() !== 0)
				return;

			var robot = pm.moduleUtils.generateRobot(this._map.getType(), this._selectedObjectForTapType);
			this._addRobot(pos, robot);
			this._onAddRobot(robot);

			this._setActiveRobot(robot);
		}
		else if (this._tapType === EditorTapsType.AddObject)
		{
			if (this._map.element(pos).getObjectCount() !== 0)
				return;

			if (!pm.moduleUtils.canNotDragOrAddObject(this._map.getType(), this._map, pos))
				this._addObject(pos, pm.moduleUtils.generateObject(this._map.getType(), this._selectedObjectForTapType));
		}
		else if (this._tapType === EditorTapsType.AddClone)
		{
			if (this._map.element(pos).getRobotsCount() !== 0)
				return;

			var robot = pm.moduleUtils.generateRobot(this._map.getType(), this._activeRobot.getType());
			this._addClone(pos, robot);
			this._onAddRobot(robot);

			this._setActiveRobot(robot);
		}
	},

	moveElementsUp: function ()
	{
		for (var j = this._map.height - 2; j >= 0; --j)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i, j + 1)).setType(elementType);
				this._map.element(cc.p(i, j + 1)).originalType = elementType;

				var type = KumirMapElementType.LightBlueGrass;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	},

	moveElementsDown: function ()
	{
		for (var j = 1; j < this._map.height; ++j)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i, j - 1)).setType(elementType);
				this._map.element(cc.p(i, j - 1)).originalType = elementType;

				var type = KumirMapElementType.LightBlueGrass;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;

			}
		}
	},

	moveElementsLeft: function ()
	{
		for (var i = this._map.width - 2; i >= 0; --i)
		{
			for (var j = 0; j < this._map.height; ++j)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i + 1, j)).setType(elementType);
				this._map.element(cc.p(i + 1, j)).originalType = elementType;

				var type = KumirMapElementType.LightBlueGrass;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	},

	moveElementsRight: function ()
	{
		for (var i = 1; i < this._map.width; ++i)
		{
			for (var j = 0; j < this._map.height; ++j)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i - 1, j)).setType(elementType);
				this._map.element(cc.p(i - 1, j)).originalType = elementType;

				var type = KumirMapElementType.LightBlueGrass;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	}

});
