/**
 * Created by Kirill Mashchenko on 07.07.2018.
 */
/**
 * @class Sprite for {@link pm.data.TrainCar}
 * @implements PushObjectSprite
 */
var TrainCarSprite = TrainObjectSprite.extend(/** @lends TrainCarSprite# */{

	ctor: function()
	{
		this._super("Objects/GreyCar.png", "Objects/GreenCar.png");

		this._minPoint = cc.p(40, 35);
		this._maxPoint = cc.p(this.getSprite().getContentSize().width / 2, 3);
	},

	_getIdNumberSpritePosition: function()
	{
		return cc.p(39, 60);
	}
});
