/**
 * Created by Kirill Mashchenko on 29.06.17.
 */

var PhysicalRobotMapEditorLayer = PhysicalRobotMapLayer.extend(SquarableMapEditorLayerHelper).extend({

	drawMap: function(previewDraw)
	{
		SquarableMapEditorLayerHelper.drawMap.call(this, previewDraw);

		// var useProgramRecognizer = cc.sys.isMobile && pm.settings.getUseProgramRecognizer();

		var useProgramRecognizer = true;

		if(!previewDraw && useProgramRecognizer)
		{
			var recognizeProgramButton = new pmui.Button(pm.spriteUtils.getIconName("photo", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("photo", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("photo", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			recognizeProgramButton.setPosition(0, this.height);

			recognizeProgramButton.addClickEventListener(this._onRecognizeMatsClick.bind(this));
			this.addChild(recognizeProgramButton);

			pm.matsRecognizer.setSuccessCallback(this._onRecognizeMats.bind(this));
			pm.matsRecognizer.setErrorCallback(this._onRecognizeError.bind(this));

		}
	},

	_onRecognizeMatsClick: function()
	{
		pm.matsRecognizer.recognize();
	},

	_onRecognizeMats: function(result)
	{
		try {
			var data = JSON.parse(result);

			this._map.width = data.width || 1;
			this._map.height = data.height || 1;

			this._map.mapElements = [];

			for(var i = 0; i < data.height; ++i)
			{
				this._map.mapElements.push([]);

				for(var j = 0; j < data.width; ++j)
				{
					var el = new pm.data.PhysicalRobotMapElement();
					el.originalType = this._mapRecognizeElementType(data.field[i][j]);

					if (el.originalType === "R")
					{
						el.originalType = PhysicalRobotMapElementType.Green;

						var task = new pm.data.PositionTask4(this._map.parentLevel);
						task.fillWithInitialData(0, cc.p(i, j));

						this._map.parentLevel.taskList.addTask(pm.GlobalTaskType.Position4, task);
					}
					el.initElement();
					this._map.mapElements[i].push(el);
				}
			}

			this.removeAllChildren();
			this.drawMap(false);
		}
		catch(e)
		{
			this._onRecognizeError(e.message);
		}
	},

	_mapRecognizeElementType: function(recType)
	{
		if(!isNaN(parseInt(recType)))
			return Number(recType);

		switch(recType)
		{
			case "green": return PhysicalRobotMapElementType.Green;
			case "yellow": return PhysicalRobotMapElementType.Yellow;
			case "red": return "R";
			default: return PhysicalRobotMapElementType.Green;
		}
	},

	_onRecognizeError: function(error)
	{
		if(error !== "")
		{
			var messageBox = new pmui.MessageBox(this, error);

			var x = pm.settings.getScreenSize().width / 2 - messageBox.getContentSize().width / 2;
			var y = pm.settings.getScreenSize().height / 2 - messageBox.getContentSize().height / 2;
			messageBox.setPosition(x, y);
			cc.director.getRunningScene().addChild(messageBox, 1000);
		}
	},

	createMapElement: function ()
	{
		var el = new pm.data.PhysicalRobotMapElement();
		el.originalType = PhysicalRobotMapElementType.Green;
		el.initElement();
		return el;
	},

	moveElementsUp: function ()
	{
		for (var j = this._map.height - 2; j >= 0; --j)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i, j + 1)).setType(elementType);
				this._map.element(cc.p(i, j + 1)).originalType = elementType;

				var type = PhysicalRobotMapElementType.Green;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	},

	moveElementsDown: function ()
	{
		for (var j = 1; j < this._map.height; ++j)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i, j - 1)).setType(elementType);
				this._map.element(cc.p(i, j - 1)).originalType = elementType;

				var type = PhysicalRobotMapElementType.Green;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;

			}
		}
	},

	moveElementsLeft: function ()
	{
		for (var i = this._map.width - 2; i >= 0; --i)
		{
			for (var j = 0; j < this._map.height; ++j)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i + 1, j)).setType(elementType);
				this._map.element(cc.p(i + 1, j)).originalType = elementType;

				var type = PhysicalRobotMapElementType.Green;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	},

	moveElementsRight: function ()
	{
		for (var i = 1; i < this._map.width; ++i)
		{
			for (var j = 0; j < this._map.height; ++j)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i - 1, j)).setType(elementType);
				this._map.element(cc.p(i - 1, j)).originalType = elementType;

				var type = PhysicalRobotMapElementType.Green;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	}

});
