
pm.data.OutputTask = pm.Task.extend(/** @lends pm.data.OutputTask*/ {
	typeName: "OutputTask",

	ctor: function(level)
	{
		this._super(level);
	},

	getNotCompletedObjects: function (mapIndex)
	{
		var value = this.data[mapIndex];
		var robot = null;

		for(var i =0 ; i < this._level.globalRobots.length; ++i)
		{
			if(this._level.globalRobots[i].getType() === pm.GlobalRobotType.Output)
				robot = this._level.globalRobots[i];
		}

		if(robot.getValue() !== value)
				return  [robot];
		return [];
	},

	fillWithInitialData: function(mapIndex, data)
	{
		if(data === undefined)
			data = 0;

		this.data[mapIndex] = data;
	}
});
