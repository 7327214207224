/**
 * Created by Nikita Besshaposhnikov on 22.03.17.
 */

pm.LOCAL_ADDRESS = 16777343;

pm.GAME_SERVER_PACKET_TYPE = {
	LOAD_LEVEL: "load_level",
	PROGRAM_UPDATED: "program_updated",
	START_GAME: "start_game"
};

pm.GAME_CLIENT_PACKET_TYPE = {
	PROGRAM_UPDATED: "program_updated",
	READY_FOR_GAME: "ready_for_game"
};

pm.TEACHER_SERVER_PACKET_TYPE = {
	START_SERVER: "start_server",
	BECOME_SERVER: "become_server",
	CONNECT_TO_SERVER: "connect",
	RECONNECT_TO_SERVER: "reconnect",
	CHANGE_LEVEL: "change_level",
	STOP_GAME: "stop_game",
	STATUS_REQUEST: "status_request",
	TIME_INFO: "time_info"
};

pm.STUDENT_CLIENT_PACKET_TYPE = {
	STATUS: "status",
	LEVEL_STATUS_UPDATED: "level_status_updated",
	ROBOT_INDEX_MAP: "robot_index_map",
	PROGRAM: "program",
	TIME_INFO: "time_info",
	LEVEL_SCENE_ENTERED: "level_scene_entered"
};

pm.BROADCAST_DATA_PACKET_TYPE = {
	CLIENT: "c",
	SERVER: "s",
	FREE: "f",
	CLEAR_CACHE: "cl",
	CONNECT_TO_TEACHER_SERVER: "cts",
	DEBUG_RECONNECT: "rec"
};
