
var ProgramTree = cc.Class.extend(/** @lends ProgramContainerLayer# */{

	_tree: null,
	_treeCreator: null,
	_mode: 0,

	ctor: function (mode, programTree, robot, parent, outputRobot)
	{
		this.createTree(mode, programTree, robot, parent, outputRobot);
	},

	createTree: function (mode, programTree, robot, parent, outputRobot)
	{
		this._mode = mode;
		if(this._tree)
			this._tree.removeFromParent();

		this._treeCreator = pm.syntaxModules.getTreeCreator(this._mode, programTree, robot, parent, outputRobot);

		if (this._treeCreator.getVisualTree() === null)
		{
			++this._mode;
			this._treeCreator = pm.syntaxModules.getTreeCreator(this._mode, programTree, robot, parent, outputRobot);
		}

		this._tree = this._treeCreator.getVisualTree();
		pm.registerCustomEventListener(pm.PARSE_NODE, function (event)
		{
			var data = event.getUserData();
			this.updateTree(data.node, data.parent);
		}.bind(this), this._tree);

		pm.registerCustomEventListener(pm.HIGHLIGHT_METHOD_EVENT_STR, function (event)
		{
			var data = event.getUserData();
			if (data && robot.id === data.robotIndex && data.nodeView)
				this._treeCreator.highlightMethod(data.nodeView);
		}.bind(this), this._tree);

		pm.registerCustomEventListener(pm.REM_LAST_HIGHLIGHT_EVENT_STR, function (event)
		{
			var data = event.getUserData();
			if (data !== undefined && robot.id === data.robotIndex)
				this._treeCreator.clearLastHighlight();
		}.bind(this), this._tree);

		pm.registerCustomEventListener(pm.HIGHLIGHT_BROKEN_METHOD_EVENT_STR, function (event)
		{
			var data = event.getUserData();
			if (data !== undefined && robot.id === data.robotIndex)
				this._treeCreator.highlightMethodBroken(data.nodeView);
		}.bind(this), this._tree);
		pm.registerCustomEventListener(pm.HIGHLIGHT_POINT_IN_REP_EVENT, function(event)
		{
			var data = event.getUserData();
			if(data && robot.id === data.robotIndex)
				this._treeCreator.switchPointsInRepeater(data.nodeView, data.iterNum, data.rep);
		}.bind(this), this._tree);
	},

	addOutput: function (robot)
	{
		this._treeCreator.addOutput(robot);
	},

	getMode: function ()
	{
		return this._mode;
	},

	getTree: function ()
	{
		return this._tree;
	},

	updateTree: function (node, parent)
	{
		this._treeCreator.updateTree(node, parent);
	},

	pasteNode: function (parent, button, selectedElem)
	{
		return this._treeCreator.pasteNode(parent, button, selectedElem);
	},

	pasteNodeList: function (element, methodList)
	{
		return this._treeCreator.pasteNodeList(element, methodList);
	},

	moveNode: function(node, selectedNode)
	{
		return this._treeCreator.moveNode(node, selectedNode);
	},

	getMethodsCount: function ()
	{
		return this._treeCreator.getMethodsCount();
	},

	getVisualTree: function()
	{
		return this._tree;
	},

	reset: function ()
	{
		this._treeCreator.reset();
	}
});


