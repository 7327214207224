/**
 * Created by Nikita Besshaposhnikov on 13.06.15.
 */

/**
 * @class Layer which provides correct sorting of sprites on it by "see-distance".</br>
 * Uses topological sorting.
 * @extends cc.Layer
 */
pm.TopologicalSortLayer = cc.Layer.extend(/** @lends pm.TopologicalSortLayer#*/{

	_objects: [],

	ctor: function()
	{
		this._super();
		this._objects = [];

		this.scheduleUpdate();
	},

	addObject: function(child, zOrder, tag)
	{
		if(!child || !(child instanceof pm.ObjectSprite2D))
			return;

		this._objects.push(child);

		this.addChild(child.getSprite(), zOrder, tag);
	},

	removeObject: function(child, cleanup)
	{
		if(!child || !(child instanceof pm.ObjectSprite2D))
			return;

		this._objects.splice(this._objects.indexOf(child), 1);
		this.removeChild(child.getSprite(), cleanup);
	},

	_sprOrder: 0,
	_graphCreated: false,
	_forceUpdate: false,

	/**
     * Creates initial order graph for {@link pm.StaticSprite} elements.
     */
	createOrderGraph: function()
	{
		var childCount = this._objects.length;
		var children = this._objects;

		for(var i = 0; i < childCount; ++i)
		{
			if(children[i] instanceof pm.StaticSprite)
			{
				var a_max = children[i].getMaxPoint();

				if(children[i]["spritesBehind"] === undefined)
					children[i]["spritesBehind"] = new Array(childCount);
				else
					children[i]["spritesBehind"].length = childCount;

				var behindCount = 0;

				for(var j=0; j < childCount; ++j)
				{
					if(i!=j && children[j] instanceof pm.StaticSprite)
					{
						var b_min = children[j].getMinPoint();
						if(b_min.x < a_max.x && b_min.y > a_max.y)
							children[i].spritesBehind[behindCount++] = children[j];
					}
				}
				children[i].behindCount = behindCount;
				children[i].visited = false;
			}
		}

		this._graphCreated = true;
	},

	/**
     * Updates order graph for {@link pm.StaticSprite} elements.
     */
	updateOrderGraph: function()
	{
		this._graphCreated = false;

		this.createOrderGraph();

		this._forceUpdate = true;
	},

	/**
     * Force updates z order of all elements.
     */
	forceUpdate: function()
	{
		this._forceUpdate = true;
	},

	update: function(dt)
	{
		if(!this._graphCreated)
			return;

		var children = this._objects;
		var childCount = this._objects.length;

		if(!this._forceUpdate)
		{
			var needToUpdate = false;

			for (var i = 0; i < childCount; ++i)
			{
				if (children[i].needToUpdateZOrder())
				{
					needToUpdate = true;
					break;
				}
			}

			if (!needToUpdate)
				return;
		}
		this._sprOrder = 0;

		for(var i=0; i < childCount; ++i)
		{
			var a_max = children[i].getMaxPoint();

			if(children[i] instanceof pm.StaticSprite)
			{
				for(var j=0; j < childCount; ++j)
				{
					if(i!=j && !(children[j] instanceof pm.StaticSprite))
					{
						var b_min = children[j].getMinPoint();
						if(b_min.x < a_max.x && b_min.y > a_max.y)
							children[i].spritesBehind[children[i].behindCount++] = children[j];
					}
				}
			}
			else
			{
				if(!children[i].hasOwnProperty("spritesBehind"))
				{
					children[i]["spritesBehind"] = new Array(childCount);
					children[i].behindCount = 0;
					children[i].visited = false;
				}

				for(var j=0; j < childCount; ++j)
				{
					if(i!=j)
					{
						var b_min = children[j].getMinPoint();
						if(b_min.x < a_max.x && b_min.y > a_max.y)
							children[i].spritesBehind[children[i].behindCount++] = children[j];
					}
				}
			}
		}

		for(var i=0; i < childCount; ++i)

			this._visitSprite(children[i]);

		for(var i=0; i < childCount; ++i)

			children[i].visited = false;

		this._forceUpdate = false;
	},

	_visitSprite: function(spr)
	{
		if(!spr.visited)
		{
			spr.visited = true;

			var behindCount = spr.behindCount;

			for(var i=0; i < behindCount; ++i)
			{
				if(!spr.spritesBehind[i])
				{
					break;
				}
				else
				{
					this._visitSprite(spr.spritesBehind[i]);

					if(!(spr instanceof pm.StaticSprite))
					{
						--spr.behindCount;
						spr.spritesBehind[i] = null;
					}
					else if(!(spr.spritesBehind[i] instanceof pm.StaticSprite))
					{
						spr.spritesBehind[i] = null;
						--spr.behindCount;
					}
				}
			}

			if(spr.needClearUpdateZOrderFlag())
				spr.clearUpdateZOrderFlags();

			spr.getSprite().setLocalZOrder(this._sprOrder++);
		}
	}
});
