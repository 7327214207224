/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.ForRepeaterNode = pmui.RepeaterNode.extend(/** @lends pmui.NodeLayer# */{
	_iterator: null,
	_begVal: null,
	_endVal: null,
	_step: null,


	ctor: function (treeNode, parent, syntaxModule, type)
	{
		this._super(treeNode, parent, syntaxModule, type);

		this._iterator = new pmui.ReplaceNode([FunctionButton.Type.Variable], this);
		this._begVal = new pmui.ReplaceNode([FunctionButton.Type.Number, FunctionButton.Type.Variable, FunctionButton.Type.Expression], this);
		this._endVal = new pmui.ReplaceNode([FunctionButton.Type.Number, FunctionButton.Type.Variable, FunctionButton.Type.Expression], this);

		pm.syntaxModules.constructForRepeaterNode(this);

		pm.registerCustomEventListener(pm.VARIABLE_DEFINITION_REMOVED, function (event)
		{
			var data = event.getUserData();
			if (data  === this._iterator.getName())
				this._removeNodeFromTree();
		}.bind(this), this);
	},

	addChildNode: function(node)
	{
		var tag = node._treeNode.getTagName();
		var parent = this._treeNode;
		if(tag === pm.ForIteratorTag)
		{
			parent.removeChild(this._iterator._treeNode);
			this.removeChild(this._iterator);

			this.createALignSome(node, pmui.RepeaterNode.FIRST, pm.ForIteratorTag, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER,
				pmui.NodeLayer.SEPARATORX, 0,0,0);
			node._treeNode.setTagName(pm.ForIteratorTag);
			this._iterator = node;
		}
		else if(tag === pm.ForBegTag)
		{
			parent.removeChild(this._begVal._treeNode);
			this.removeChild(this._begVal);

			this.createALignSome(node, pmui.ForRepeaterNode.FROM_TAG, pm.ForBegTag, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER,
				pmui.NodeLayer.SEPARATORX, 0, 0, 0);
			node._treeNode.setTagName(pm.ForBegTag);
			this._begVal = node;
		}
		else if(tag === pm.ForEndTag)
		{
			parent.removeChild(this._endVal._treeNode);
			this.removeChild(this._endVal);

			node._treeNode.setTagName(pm.ForEndTag);
			this.createALignSome(node,  pmui.ForRepeaterNode.TO_TAG,  pm.ForEndTag, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER,
				pmui.NodeLayer.SEPARATORX, 0,0,0);
			this._endVal = node;
		}
		else if(tag === pmui.ForRepeaterNode.STEP_TAG)
		{
			this._step = node;
			node._treeNode.setTagName(pmui.ForRepeaterNode.STEP_TAG);
		}
		else if(tag === pm.BodyTag
			|| node._treeNode.type === pm.data.Node.Type.Body)
		{
			this._bodyNode = node;
			this.createALignSome(this._bodyNode, pmui.RepeaterNode.FIRST,  pmui.RepeaterNode.BODY_TAG, ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		}
		this.addChild(node);
	},

	_removeNode: function(node)
	{
		var tag = node._treeNode.getTagName();
		if(tag === pm.ForIteratorTag)
			return ;
		var treeNode = pm.data.SyntaxTree.createNode(this._treeNode,pm.data.Node.Type.Number, 0);
		treeNode.setTagName(node._treeNode.getTagName());
		pm.sendCustomEvent(pm.PARSE_NODE, {node: treeNode, parent: this});
	},

	calculateSize:function (forPar)
	{
		pm.syntaxModules.calculateSizeOfForRepeaterNode(this);

		if(!forPar)
			this._parentNode.calculateSize();
	},

	getChildrenNodes: function()
	{
		return [this._iterator, this._begVal, this._endVal, this._bodyNode];
	},

	clone: function()
	{
		return pm.appUtils.generateFunctionButton(this.type, pm.LoopVals.for);
	},

	makeCleanUp: function ()
	{
		pmui.AllocNode.intVals.splice(pmui.AllocNode.intVals.indexOf(this._iterator.getName()));
	},

	updateIteratorVal: function (num)
	{
		pm.sendCustomEvent(pm.VARIABLE_VALUE_CHANGED, {name: this._iterator.getName(), val: num});
	}
});

pmui.ForRepeaterNode.FROM_TAG = "from";
pmui.ForRepeaterNode.TO_TAG = "to";

pmui.ForRepeaterNode.STEP_TAG = "step";
pmui.ForRepeaterNode.FOR_TAG = "for";
