/**
 * Created by danilaeremin on 25.06.15.
 */

/**
 * @class Layout for asking user to wait.
 * @extends pmui.OvalLayout
 * @constructor
 */
var WaitLayer = pmui.OvalLayout.extend(/** @lends WaitLayer# */{
	ctor: function()
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setContentSizeWithBorder(WaitLayer.WIDTH, WaitLayer.HEIGHT);

		var label = new ccui.Text(LocalizedString("PleaseWait"), pm.settings.fontName, pm.settings.fontSize);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
		labelAlign.setMargin(0, -40, 0, 0);
		labelAlign.setRelativeName("label");
		label.setLayoutParameter(labelAlign);

		this.addChild(label);

		var listener = cc.EventListener.create({
			event: cc.EventListener.TOUCH_ONE_BY_ONE,
			swallowTouches: true,
			onTouchBegan: function()
			{
				return true;
			},
			onTouchMoved: function() {},
			onTouchEnded: function() {}
		});

		cc.eventManager.addListener(listener, this);
	},

	/**
     * Delete WaitLayer from parent.
     */
	hide: function()
	{
		this.removeFromParent();
	}
});

/**
 * @default
 * @const
 * @type {Number}
 */
WaitLayer.WIDTH = 290.0;
/**
 * @default
 * @const
 * @type {Number}
 */
WaitLayer.HEIGHT = 140.0;
/**
 * @default
 * @const
 * @type {Number}
 */
WaitLayer.SEPARATOR = 5.0;
