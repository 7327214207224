/**
 * Created by Nikita Besshaposhnikov on 24.06.15.
 */

/**
 * This namespace stores convert function for world format versions.
 * @namespace
 */
pm.worldConvertFunctionsP = {
	/**
     * Checks if namespace has a convert chain functions.
     * @param {Number} from Source format version
     * @param {Number} to Destination format version
     * @returns {boolean}
     */
	hasConvertFunction: function (from, to)
	{
		if (Number(from) === Number(to))
			return false;

		var res = true;

		for (var i = Number(from); i < Number(to); ++i)
		{
			if (!this.hasOwnProperty("_" + i + "_to_" + (i + 1)))
				res = false;
		}

		return res;
	},

	/**
     * Converts world levels from one format version to another
     * @param {String | Object} worldPathOrObject Path to world to convert or world Object.
     * @param {Number} to Destination format version
     * @returns {Object}
     */
	convert: function (worldPathOrObject, to)
	{
		var worldObject = cc.isString(worldPathOrObject) ? pm.fileUtils.readObject(worldPathOrObject) : worldPathOrObject;

		for(var i = 0; i < worldObject.games.length; ++i)
		{
			for (var j = 0; j < worldObject.games[i].levels.length; ++j)
			{
				var level = this.convertLevel(worldObject.games[i].levels[j], to);

				if(!level)
					return null;
			}
		}

		return worldObject;
	},

	/**
     * Converts level from one format version to another
     * @param {Object} level A level object
     * @param {Number} to Destination format version
     * @returns {Object}
     */
	convertLevel: function (level, to)
	{
		var fromVersion = Number(level.version);
		var toVersion =  Number(to);

		for (var v = fromVersion; v < toVersion; ++v)
		{
			var fn = this["_" + v + "_to_" + (v + 1)];

			if(!fn)
				return null;

			fn.call(this, level);
		}

		if(fromVersion - toVersion !== 0)
		{
			level.version = to;
			level.revision = 1;
		}

		return level;
	},

	//Added PushBarrel
	_1_to_2: function (level) {},

	//Support of function blocks.
	_2_to_3: function (level)
	{
		for(var k =0; k < level.robots.length; ++k)
		{
			for(var l =0; l < level.robots[k].functionData.length; ++l)
			{
				var f = level.robots[k].functionData[l];

				var newF = {typeName: "FunctionData"};

				newF.functionID = f.functionID;
				newF.hidden = f.hidden;
				newF.givenText = f.givenText;
				newF.doText = f.doText;
				newF.blocks = [];

				var newB = {typeName: "FunctionBlock"};
				newB.width = f.width;
				newB.height = f.height;
				newB.resizable = f.resizable;
				newB.minHeight = f.minHeight;
				newB.maxHeight = f.maxHeight;
				newB.minWidth = 0;
				newB.maxWidth = 0;
				newB.repeater = f.repeater;
				newB.condition = f.condition;
				newB.methods = f.methods;

				newF.blocks.push(newB);

				var newH = {typeName: "FunctionHint"};
				newH.text = f.hint.text;
				newH.funcBlockHints = [];

				if(f.hint.funcHint.length > 0)
				{
					newH.funcBlockHints.push([]);
					for (var h = 0; h < f.hint.funcHint.length; ++h)
						newH.funcBlockHints[0].push(f.hint.funcHint[h]);
				}
				newF.hint = newH;

				level.robots[k].functionData.splice(l, 1, newF);
			}
		}
	},

	//Update method and condition names. && remove method execute type
	_3_to_4: function (level)
	{
		for(var k =0; k < level.robots.length; ++k)
		{
			for (var l = 0; l < level.robots[k].functionData.length; ++l)
			{
				var f = level.robots[k].functionData[l];

				for(var b =0 ; b < f.blocks.length; ++b)
				{
					var block = f.blocks[b];

					switch(block.condition)
					{
						case "r4_w": block.condition = "r4_wall"; break;
						case "r4_nw": block.condition = "r4_clear"; break;
						case "r4_gig": block.condition = "rep_gig"; break;
						case "r4_gib": block.condition = "rep_gib"; break;
						case "push_w":
						case "push_b":
						case "push_c":
							block.condition = "empty"; break;
					}

					var methods = block.methods;

					for(var m=0; m < methods.length; ++m)
					{
						switch(methods[m].methodID)
						{
							case "move": methods[m].methodID = "r4_move"; break;
							case "turn_left": methods[m].methodID = "r4_turn_left"; break;
							case "turn_right": methods[m].methodID = "r4_turn_right"; break;
							case "paint": methods[m].methodID = "rep_repair"; break;
						}

						delete methods[m].executeType;
					}
				}

				f.hint.funcBlockHints = [];
			}
		}
	},

	//Use if-else in function blocks. Also added maxFunctionCount && maxBlockCount;
	_4_to_5: function (level)
	{
		for(var k =0; k < level.robots.length; ++k)
		{
			for (var l = 0; l < level.robots[k].functionData.length; ++l)
			{
				var f = level.robots[k].functionData[l];

				for(var b =0 ; b < f.blocks.length; ++b)
				{
					var block = f.blocks[b];

					block.conditions = {};

					if(block.condition !== "disable")
						block.conditions[0] = block.condition;

					delete block.condition;
				}
			}
		}
	},

	//Added pm.data.ProgramData and maxFunctionCount && maxBlockCount;
	_5_to_6: function (level)
	{
		for(var k =0; k < level.robots.length; ++k)
		{
			level.robots[k].programData = {typeName: "ProgramData"};
			level.robots[k].programData.canEditProgramPattern = false;
			level.robots[k].programData.maxFunctionCount = 0;
			level.robots[k].programData.maxMethodCount = 0;
			level.robots[k].programData.functions = [];

			for (var l = 0; l < level.robots[k].functionData.length; ++l)
			{
				var f = level.robots[k].functionData[l];

				for (var b = 0; b < f.blocks.length; ++b)
				{
					delete f.blocks[b].resizable;
					delete f.blocks[b].maxWidth;
					delete f.blocks[b].maxHeight;
					delete f.blocks[b].minWidth;
					delete f.blocks[b].minHeight;
				}

				level.robots[k].programData.functions.push(f);
			}

			delete level.robots[k].functionData;
		}
	},

	//Added global robots && renamed counter robots && change base robot logic in AL && renamed counters method names
	_6_to_7: function (level)
	{
		level.globalRobotData = {typeName: "map"};

		level.startRobotIndex = level.baseRobotIndex;
		delete level.baseRobotIndex;

		for(var k =0; k < level.robots.length; ++k)
		{
			if(level.robots[k].typeName === "Counter")
			{
				level.globalRobotData["e_counter"] = [];

				for(var m = 0; m < level.maps.length;++ m)
					delete level.maps[m].robotsTaskList[level.robots[k].id];

				level.robots.splice(k, 1);
			}
			else if(level.robots[k].typeName === "LittleCounter")
			{
				level.globalRobotData["counter"] = [];

				for(var m = 0; m < level.maps.length;++ m)
					delete level.maps[m].robotsTaskList[level.robots[k].id];

				level.robots.splice(k, 1);
			}
			else
			{
				level.robots[k].childRobotID = -1;

				for (var l = 0; l < level.robots[k].programData.functions.length; ++l)
				{
					var f = level.robots[k].programData.functions[l];

					for(var b =0 ; b < f.blocks.length; ++b)
					{
						var block = f.blocks[b];

						if(block.repeater === "cnt_left" || block.repeater === "cnt_right")
							block.repeater = 1;

						for(var m = 0; m < block.methods.length; ++m)
						{
							if(block.methods[m].methodID === "inc")
								block.methods[m].methodID = "cnt_inc";
							else if(block.methods[m].methodID === "dec")
								block.methods[m].methodID = "cnt_dec";
							else if(block.methods[m].methodID === "set_left" ||
                                block.methods[m].methodID === "set_right" ||
                                block.methods[m].methodID === "swap_counter")
								block.methods[m].methodID = "empty";
						}
					}
				}

			}
		}

		if(level.globalRobotData["e_counter"])
		{
			level.globalRobotData["e_counter"].push({typeName: "GRobotDataGroup"});
			level.globalRobotData["e_counter"][0].robots = [];
			for(var k =0; k < level.robots.length; ++k)
				level.globalRobotData["e_counter"][0].robots.push(level.robots[k].id);

		}

		if(level.globalRobotData["counter"])
		{
			level.globalRobotData["counter"].push({typeName: "GRobotDataGroup"});
			level.globalRobotData["counter"][0].robots = [];

			for(var k =0; k < level.robots.length; ++k)
				level.globalRobotData["counter"][0].robots.push(level.robots[k].id);

		}
	},

	//Refactor global robot data && add common config for extended counter
	_7_to_8: function (level)
	{
		for(var gRobot in level.globalRobotData)
		{
			if (cc.isArray(level.globalRobotData[gRobot]))
			{
				var oldData = level.globalRobotData[gRobot];
				level.globalRobotData[gRobot] = {typeName: "map"};
				level.globalRobotData[gRobot].robotDataGroups = oldData;

				if (gRobot === "e_counter")
					level.globalRobotData[gRobot].config = {useCompareConditions: true};
			}
		}
	},

	//Block types
	_8_to_9: function (level)
	{
		for(var k =0; k < level.robots.length; ++k)
		{
			for (var l = 0; l < level.robots[k].programData.functions.length; ++l)
			{
				var f = level.robots[k].programData.functions[l];

				for (var b = 0; b < f.blocks.length; ++b)
				{
					var block = f.blocks[b];
					block.type = 0;
					block.headerValue = 0;

					if(block.repeater !== -2 &&
                        Object.keys(block.conditions).length > 0)
					{
						block.type = 3;
						block.headerValue = block.conditions[Object.keys(block.conditions)[0]];
					}
					else if(block.repeater !== -2)
					{
						block.type = 2;

						if(block.repeater !== -1)
							block.headerValue = block.repeater;
						else
							block.headerValue = 1;
					}
					else if(Object.keys(block.conditions).length > 0)
					{
						block.type = 1;
						block.headerValue = block.conditions;
					}

					delete block.repeater;
					delete block.conditions;
				}
			}
		}
	},

	//Move task to level
	_9_to_10: function (level)
	{
		level.taskList = {typeName: "TaskList"};
		level.taskList.tasks = {typeName: "map"};
		level.taskList.type = 0;

		for(var m =0; m < level.maps.length; ++m)
		{
			var map = level.maps[m];
			var tasks = map.robotsTaskList;

			for(var robotID in tasks)
			{
				if(!tasks[robotID].hasOwnProperty("typeName"))
					continue;

				for(var taskType in tasks[robotID].tasks)
				{
					if(!tasks[robotID].tasks[taskType].hasOwnProperty("typeName"))
						continue;

					if(!level.taskList.tasks[taskType])
					{
						switch (Number(taskType))
						{
							case 0:
								level.taskList.tasks[taskType] = {typeName: "RepairTask"};
								level.taskList.tasks[taskType].data = {};
								break;
							case 1:
								level.taskList.tasks[taskType] = {typeName: "PositionTask4"};
								level.taskList.tasks[taskType].data = {};
								break;
							case 2:
								level.taskList.tasks[taskType] = {typeName: "PushTask"};
								level.taskList.tasks[taskType].data = {};
								break;
						}
					}

					switch (Number(taskType))
					{
						case 0:
							if(level.taskList.tasks[taskType].data[m] === undefined)
								level.taskList.tasks[taskType].data[m] = 0;

							level.taskList.tasks[taskType].data[m] += tasks[robotID].tasks[taskType].needToRepair;
							break;
						case 1:
							if(level.taskList.tasks[taskType].data[m] === undefined)
								level.taskList.tasks[taskType].data[m] = {};

							level.taskList.tasks[taskType].data[m][robotID] = tasks[robotID].tasks[taskType].targetPoint;
							break;
					}
				}

			}

			delete map.robotsTaskList;
		}
	},

	//Add tutorials && delete unused property && some validation corrections
	_10_to_11: function (level)
	{
		level.isTutorial = false;
		level.tutorialScenario = {};

		for(var k =0; k < level.robots.length; ++k)
		{
			for (var l = 0; l < level.robots[k].programData.functions.length; ++l)
			{
				var f = level.robots[k].programData.functions[l];

				delete f.funcBlockHints;
			}
		}

		if(level.typeName === "PushLevel")
		{
			for(var m =0; m < level.maps.length; ++m)
			{
				var map = level.maps[m];

				for(var x = 0 ; x < map.width; ++x)
				{
					for(var y = 0 ; y < map.height; ++y)

						map.mapElements[y][x].typeName = "PushMapElement";

				}
			}
		}
	},

	//Rename items to objects && remove separation of network and local games
	_11_to_12: function (level)
	{
		for (var type in level.taskList.tasks)
		{
			switch (type)
			{
				case "0":
					level.taskList.tasks["repair"] = level.taskList.tasks[type];
					delete level.taskList.tasks[type];
					break;
				case "1":
					level.taskList.tasks["position4"] = level.taskList.tasks[type];
					delete level.taskList.tasks[type];
					break;
				case "2":
					level.taskList.tasks["push"] = level.taskList.tasks[type];
					delete level.taskList.tasks[type];
					break;
				case "3":
					level.taskList.tasks["ex_counter_memory"] = level.taskList.tasks[type];
					delete level.taskList.tasks[type];
					break;
				case "4":
					level.taskList.tasks["light"] = level.taskList.tasks[type];
					delete level.taskList.tasks[type];
					break;
			}
		}

		for(var m = 0; m < level.maps.length; ++m)
		{
			var map = level.maps[m];
			map.objects = map.items;
			delete map.items;
		}
	},

	//Remove global robot data added global robot config
	_12_to_13: function (level)
	{
		level.globalRobotConfig = new pm.data.MapClass();

		for (var robot in level.globalRobotData)
		{
			if (cc.isObject(level.globalRobotData[robot]))
				level.globalRobotConfig[robot] = level.globalRobotData[robot].config;
		}

		delete level.globalRobotData;
	},

	_13_to_14: function (level)
	{
		if (level.typeName === "PushLevel")
		{
			for (var taskType in level.taskList.tasks)
			{
				if (taskType === undefined)
				{
					var task = level.taskList.tasks[taskType];

					level.taskList.tasks[pm.PushLevelModule.Type] = task;

					delete level.taskList.tasks[taskType];
				}
				else if (taskType === "undefined")
				{
					var task = level.taskList.tasks[taskType];

					level.taskList.tasks[pm.PushLevelModule.Type] = task;

					delete level.taskList.tasks[taskType];
				}
			}
		}
	},

	_14_to_15: function(level)
	{
		level.useProgramRecognizer = true;

		if (level.robots)
		{
			for (var i = 0; i < level.robots.length; ++i)
			{
				var width = 0;
				var height = 0;

				if (level.robots[i].programData)
				{
					for (var j = 0; j < level.robots[i].programData.functions.length; ++j)
					{
						var func = level.robots[i].programData.functions[j];

						for (var t = 0; t < func.blocks.length; ++t)
						{
							var block = func.blocks[t];

							if (block.type !== 0)
								width = Math.max(width, block.width+1);
							else
								width = Math.max(width, block.width);

							height += block.height;
						}
					}

					height += 2 * (level.robots[i].programData.functions.length - 1);

					var programData = {
						typeName: "ProgramData",
						canEditProgramPattern: false,
						width: 0,
						height: 0,
						symbols: []
					};

					programData.width = width;
					programData.height = height;

					var symbols = [];

					for (var j = 0; j < level.robots[i].programData.functions.length; ++j)
					{
						var func = level.robots[i].programData.functions[j];

						if (j!==0)
						{
							var symbolString = [];

							var symbol = {
								type: pm.SymbolType.Method,
								value: "empty"
							};

							symbol.type = pm.SymbolType.Function;
							symbol.value = func.functionID;

							symbolString.push(symbol);

							for (var f = 0; f < width-1; ++f)
								symbolString.push({type: pm.SymbolType.Empty, value: -1});

							symbols.push(symbolString);
						}

						for (var t = 0; t < func.blocks.length; ++t)
						{
							var block = func.blocks[t];

							if (block.type === 0)
							{
								for (var x = 0; x < block.height; ++x)
								{
									var symbolString = [];

									for (var y = 0; y < block.width; ++y)
									{
										var method = block.methods[x * block.width + y];

										var symbol = {
											type: pm.SymbolType.Method,
											value: "empty"
										};

										symbol.type = pm.SymbolType.Method;
										symbol.value = method.methodID;

										symbolString.push(symbol);
									}

									for (var p = block.width; p < width; ++p)

										symbolString.push({type: pm.SymbolType.Empty, value: -1});

									symbols.push(symbolString);
								}
							}
							else
							{
								var symbol = {
									type: pm.SymbolType.Method,
									value: 0
								};

								symbol.type = block.type;
								symbol.value = block.headerValue;

								if (symbol.type === pm.SymbolType.Condition)
									symbol.value = block.headerValue["0"];

								var symbolString = [];
								symbolString.push(symbol);

								for (var y = 0; y < block.width; ++y)
								{
									var method = block.methods[y];

									var symbol = {
										type: pm.SymbolType.Method,
										value: "empty"
									};

									symbol.type = pm.SymbolType.Method;
									symbol.value = method.methodID;

									symbolString.push(symbol);
								}

								for (var p = block.width; p < width-1; ++p)

									symbolString.push({type: pm.SymbolType.Empty, value: -1});

								symbols.push(symbolString);

								for (var x = 1; x < block.height; ++x)
								{
									var symbolString = [];
									symbolString.push({type: pm.SymbolType.Empty, value: -1});

									for (var y = 0; y < block.width; ++y)
									{
										var symbol = {
											type: pm.SymbolType.Method,
											value: "empty"
										};

										symbol.type = pm.SymbolType.Method;
										symbol.value = method.methodID;

										symbolString.push(symbol);
									}

									for (var p = block.width; p < width; ++p)

										symbolString.push({type: pm.SymbolType.Empty, value: -1});

									symbols.push(symbolString);
								}
							}
						}

						if (j !== level.robots[i].programData.functions.length - 1)
						{
							var symbolString = [];

							for (var f = 0; f < width; ++f)
								symbolString.push({type: pm.SymbolType.Empty, value: -1});

							symbols.push(symbolString);
						}
					}

					programData.symbols = symbols;

					level.robots[i].programData = programData;
				}
			}
		}

		var programDataMap = new pm.data.MapClass();

		for (var i = 0; i < level.robots.length; ++i)
		{
			var programData = level.robots[i].programData;
			var groupID = level.robots[i].id;

			level.robots[i].groupID = groupID;
			programDataMap[groupID] = programData;

			delete level.robots[i].programData;
		}

		level.programData = programDataMap;
	},

	_15_to_16: function(level)
	{
		if (level.typeName === "LegoLevel" || level.typeName === "BLERobotLevel")
		{
			level.typeName = "PhysicalRobotLevel";

			for (var i = 0; i < level.robots.length; ++i)

				level.robots[i].typeName = "PhysicalRobot";

			for (var i = 0; i < level.maps.length; ++i)
			{
				var map = level.maps[i];

				map.typeName = "PhysicalRobotMap";

				for (var x = 0 ; x < map.width; ++x)
				{
					for (var y = 0 ; y < map.height; ++y)
						map.mapElements[y][x].typeName = "PhysicalRobotMapElement";
				}
			}
		}

		delete level.taskList.type;
	},

	_16_to_17: function(level)
	{
		for (var o in level.programData)
		{
			if (cc.isObject(level.programData[o]))
			{
				var newProgramData = {
					typeName: "ProgramInfo",
					currentIndex: 0,
					programDataArray: []
				};

				newProgramData.programDataArray[0] = level.programData[o];

				newProgramData.programDataArray[0].maxFunctionCount = 0;
				newProgramData.programDataArray[0].maxMethodCount =  0;
				newProgramData.programDataArray[0].maxRepeaterCount = 0;
				newProgramData.programDataArray[0].maxConditionCount = 0;
				newProgramData.programDataArray[0].maxCondRepeaterCount = 0;

				delete level.programData[o];
				level.programData[o] = newProgramData;
			}
		}
	},

	_17_to_18: function(level)
	{
		if (level.typeName === "TrainLevel")
		{
			for (var o in level.programData)
			{
				if (cc.isObject(level.programData[o]))
				{
					for (var i = 0; i < level.programData[o].programDataArray.length; ++i)
					{
						var programData = level.programData[o].programDataArray[i];

						for (var x = 0; x < programData.height; ++x)
						{
							for (var y = 0; y < programData.width; ++y)
							{
								var symbol = programData.symbols[x][y];

								if (symbol.value === "rep_gib")
									symbol.value = "train_can_link";
								else if (symbol.value === "rep_ginb")
									symbol.value = "train_can_not_link";
								else if (symbol.value === "light_turn_on")
									symbol.value = "train_link";
								else if (symbol.value === "light_turn_off")
									symbol.value = "train_unlink";
							}
						}
					}
				}
			}
		}
	},

	_18_to_19: function(level)
	{
		for (var o in level.programData)
		{
			if (cc.isObject(level.programData[o]))
			{
				for (var i = 0; i < level.programData[o].programDataArray.length; ++i)
				{
					var programData = level.programData[o].programDataArray[i];

					var oldWidth = programData.width;
					var oldHeight = programData.height;

					programData.width = 6;
					programData.height = 100;

					for (var x = 0; x < oldHeight; ++x)
					{
						var symbolString = programData.symbols[x];

						for (var y = oldWidth; y < programData.width; ++y)
						{
							var symbol = {type: -1, value: -1};

							symbolString.push(symbol);
						}
					}

					for (var x = oldHeight; x < programData.height; ++x)
					{
						var symbolString = [];

						for (var y = 0; y < programData.width; ++y)
						{
							var symbol = {type: -1, value: -1};

							symbolString.push(symbol);
						}

						programData.symbols.push(symbolString);
					}
				}
			}
		}
	},

	_19_to_20: function(level)
	{
		level.checkAllPatterns = false;

		for (var i = 0; i < level.maps.length; ++i)
			level.maps[i].patternsCompleted = {};
	},

	_20_to_21: function(level)
	{
		for (var o in level.programData)
		{
			if (cc.isObject(level.programData[o]))
			{
				var newProgramData = {
					typeName: "ProgramInfo",
					currentIndex: 0,
					programDataArray: []
				};

				newProgramData = level.programData[o];
				
				for(var i = 0; i < newProgramData.programDataArray.length; ++i)
				{
					newProgramData.programDataArray[i].maxDepthConstructCount = 0;
				}

				delete level.programData[o];
				level.programData[o] = newProgramData;
			}
		}
	}
};
