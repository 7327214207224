/**
 * Created by Nikita Besshaposhnikov on 10.05.17.
 */

/**
 * @class Layer which contains buttons for controlling network game process.
 * @extends ccui.Layout
 * @constructor
 */
var NetControlLayer = ccui.Layout.extend(/** @lends NetControlLayer# */{

	_readyButton: null,
	_notReadyButton: null,

	ctor: function ()
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		this._readyButton = new pmui.Button(pm.spriteUtils.getIconName("netReady", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("netReady", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("netReady", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE );

		this._notReadyButton = new pmui.Button(pm.spriteUtils.getIconName("netReady", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("netReady", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("netReady", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this._readyButton.addClickEventListener(this._readyClicked.bind(this));
		this._notReadyButton.addClickEventListener(this._notReadyClicked.bind(this));

		var buttonMargin = new ccui.RelativeLayoutParameter();
		buttonMargin.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);

		this._readyButton.setLayoutParameter(buttonMargin);
		this._notReadyButton.setLayoutParameter(buttonMargin.clone());

		this.setContentSize(Math.max(this._readyButton.width, this._notReadyButton.width), Math.max(this._readyButton.height, this._notReadyButton.height));

		this.addChild(this._readyButton);
		this.addChild(this._notReadyButton);

		this._readyButton.setVisible(false);
	},

	/**
     * Sets enabled state of layer.
     * @param {Boolean} flag
     */
	setEnabled: function(flag)
	{
		ccui.Layout.prototype.setEnabled.call(this, flag);

		for(var i = 0; i < this.getChildrenCount(); ++i)
			this.getChildren()[i].setEnabled(flag);
	},

	_readyClicked: function()
	{
		pm.networkUtils.sendReadyForNetworkGame(false);
		this._readyButton.setVisible(false);
		this._notReadyButton.setVisible(true);
	},

	_notReadyClicked: function()
	{
		pm.networkUtils.sendReadyForNetworkGame(true);
		this._readyButton.setVisible(true);
		this._notReadyButton.setVisible(false);
	}
});

