/**
 * Created by Nikita Besshaposhnikov on 03.11.15.
 */

/**
 * This namespace contains functions to work with global robots.
 * @namespace
 */
pm.globalRobotHelper = {

	/**
     * Creates global robot by type with group data.
     * @param {pm.GlobalRobotType | String} type
     * @param {Object} config - Robot options
     * @returns {pm.GlobalRobot}
     */
	createRobot: function(type, config)
	{
		var robot = null;

		switch(type)
		{
			case pm.GlobalRobotType.Counter: robot = new pm.Counter(); break;
			case pm.GlobalRobotType.ExtendedCounter: robot = new pm.ExtendedCounter(); break;
			case pm.GlobalRobotType.GreenFlag: robot = new pm.GreenFlagRobot(); break;
			case pm.GlobalRobotType.OrangeFlag: robot = new pm.OrangeFlagRobot(); break;
			case pm.GlobalRobotType.Output: robot = new pm.Output(); break;
		}

		if(robot)
			robot.configure(config);

		return robot;
	}
};

/**
 * This enum contains all types of global robots.
 * @readonly
 * @enum {String}
 */
pm.GlobalRobotType = {
	Counter: "counter",
	ExtendedCounter: "e_counter",
	OrangeFlag: "o_flag",
	GreenFlag: "g_flag",
	Output: "output_field"
};
