/**
 * Created by Ekaterina Ermishkina on 29.08.16.
 */

/**
 * @class Layer for light robot settings.
 * @extends PlayerRobotSettingsLayer
 * @constructor
 * @param {pm.AbstractRobot} robot Robot which settings need to display.
 */
var LightRobotSettingsLayer = PlayerRobotSettingsLayer.extend(/** @lends LightRobotSettingsLayer# */{
	ctor: function(robot)
	{
		this._super(robot);

		this._label.setString(LocalizedString("Light") + " " + robot.id + ", " + LocalizedString("Pattern") + " " + robot.parentLevel.programData[robot.groupID].currentIndex);
	}
});
