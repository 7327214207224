/**
 * Created by Nikita Besshaposhnikov on 28.11.18.
 */

/**
 * Starting order of overlay layers.
 * @const
 * @type {number}
 */
var OVERLAY_START_ORDER = 1000;

/**
 * This namespace contains functions to work with running scene.
 * @namespace
 */
pm.runningSceneUtils = {
    _children: [],
    _order: 0,
    _overlayOrder: OVERLAY_START_ORDER,
    _currentScene: null,

    _checkScene: function()
    {
        var runningScene = cc.director.getRunningScene();

        if(!this._currentScene || this._currentScene !== runningScene)
        {
            this._children = [];
            this._order = 0;
            this._overlayOrder = OVERLAY_START_ORDER;
            this._currentScene = runningScene;
        }
    },

    addChild: function(child, tag)
    {
        this._checkScene();
        this._children.push(child);

        this._currentScene.addChild(child, this._order++, tag);
    },

	addChildWithOverlayOrder: function(child, tag)
	{
		this._checkScene();
		this._children.push(child);

		this._currentScene.addChild(child, ++this._overlayOrder, tag);
	},

    removeChild: function(child, cleanup)
    {
        var index = this._children.indexOf(child);

        if(index !== -1)
        {
            this._children.splice(index, 1);
            this._currentScene.removeChild(child, cleanup);
        }
    },

    cleanup: function()
    {
        for(var i = 0; i < this._children.length; ++i)
            this._currentScene.removeChild(this._children[i]);

        this._children = [];
        this._order = 0;
		this._overlayOrder = OVERLAY_START_ORDER;
	}
};
