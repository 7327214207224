"use sctrict";

/**
 * Created by Nikita Besshaposhnikov on 10.11.14.
 */

/**
 * Possible direction on square  maps.
 * @see SquarableMap
 * @readonly
 * @enum {Number}
 */
var MapDirection4 = {
	Down: 0,
	Right: 1,
	Up: 2,
	Left: 3
};

/**
 * @class Common interface for all square maps.
 * @interface
 * @extends Map2D
 */
var SquarableMap = Map2D.extend(/** @lends SquarableMap#*/{

	element: function (pos)
	{
		if (pos.x < 0 || pos.y < 0||pos.x>=this.width||pos.y>=this.height)
			return null;
		else
			return this.mapElements[pos.y][pos.x];
	},

	getNextDirectionalElementPosition: function(pos, direction)
	{
		var retPos = cc.p(pos.x, pos.y);

		switch(direction)
		{
			case MapDirection4.Up: retPos.y--; break;
			case MapDirection4.Left: retPos.x--; break;
			case MapDirection4.Down: retPos.y++; break;
			case MapDirection4.Right: retPos.x++; break;
		}

		if (retPos.x < 0 || retPos.y < 0|| retPos.x >= this.width || retPos.y >= this.height)
			return null;

		return retPos;
	},

	getPrevDirectionalElementPosition: function(pos, direction)
	{
		var retPos = cc.p(pos.x, pos.y);

		switch(direction)
		{
			case MapDirection4.Up: retPos.y++; break;
			case MapDirection4.Left: retPos.x++; break;
			case MapDirection4.Down: retPos.y--; break;
			case MapDirection4.Right: retPos.x--; break;
		}

		return retPos;
	}
});
