/**
 * Created by Antony Orlovsky on 19.07.19.
 */
/**
 * Animation type for paint robot.
 * @enum {String}
 * @readonly
 */
var GrasshopperRobotAnimation = {Paint: "paint"};

/**
 * @class 2D Sprite for {@link pm.data.GrasshopperRobot}
 * @implements pm.IsoRobotSprite
 */
var GrasshopperRobotSprite = pm.IsoRobotSprite.extend(/** @lends GrasshopperRobotSprite# */{

	_robot: null,

	ctor: function(robot)
	{
		this._robot = robot;
		cc.animationCache.addAnimations("Robot/grasshopper_robot-animations.plist");

		pm.spriteUtils.registerTempSpriteFrames("Robot/grasshopper_robot.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/grasshopper_robot-indicate.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/grasshopper_robot-broken.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/grasshopper_robot-paint.plist");

		this._super(cc.spriteFrameCache.getSpriteFrame(this._getFrameName()));

		this._minPoint = cc.p(14, 5);
		this._maxPoint = cc.p(9, 59);

		this.getSprite().setAnchorPoint(cc.p(0.5, 0.5));
	},

	/**
	 * Set orientation of sprite
	 * @param {pm.MapLayer2D.Orientation | Number} orientation
	 */
	setOrientation: function(orientation)
	{
		this._orientation = orientation;

		this.sprite.setRotation(45);
		this.sprite.setScaleX(1);
		this.sprite.setScaleY(2);

		if(pm.MapLayer2D.ENABLE_DEBUG_DRAW_BB)
			this._debugDrawBB();
	},

	_getActiveSpritePosition: function()
	{
		return cc.p(34, 60);
	},

	_getCommandsSpritePosition: function()
	{
		return cc.p(34, 90);
	},

	_getFrameName: function()
	{
		if (this._robot.getLeftDirectionFlag())
			return "grasshopper_robot_left.png";
		else
			return "grasshopper_robot_right.png";
	},

	_getDestroyAnimationName: function()
	{
		if (this._robot.getLeftDirectionFlag())
			return "grasshopper_robot_left_d";
		else
			return "grasshopper_robot_right_d";
	},

	_getIndicateAnimationName: function()
	{
		if (this._robot.getLeftDirectionFlag())
			return "grasshopper_robot_left_i";
		else
			return "grasshopper_robot_right_i";
	},

	_getPaintAnimationName: function()
	{
		if (this._robot.getLeftDirectionFlag())
			return "grasshopper_robot_left_p";
		else
			return "grasshopper_robot_right_p";
	},

	playAnimation: function(callback, target, type, args)
	{
		switch(type)
		{
			case GrasshopperRobotAnimation.Paint: this._paint(args, callback, target); break;
			default: pm.IsoRobotSprite.prototype.playAnimation.call(this, callback, target, type, args); break;
		}
	},

	_move: function(pos, endCallback, target)
	{
		var targetPos = this.getRealPosition(pos);
		var callback = cc.callFunc(endCallback, target);
		var moveEnd = cc.callFunc(function()
		{
			this._needToUpdateZOrder = false;
		}, this);

		var oldPos = this.getSprite().getPosition();
		var mapElementSize = target.parentLevel.activeMap.mapLayer.getMapElementSize();

		var diffPosX = (targetPos.x - oldPos.x) / mapElementSize.width;
		var diffPosY = (targetPos.y - oldPos.y) / mapElementSize.height;
		var jumpLength = Math.sqrt(diffPosX * diffPosX + diffPosY * diffPosY);

		var height = jumpLength * 25.0;

		if (height === 25)
			height = 35;

		var moveRobot = cc.jumpTo(1.5 * pm.settings.getAnimationSpeed(), targetPos, height, 1);

		this._needToUpdateZOrder = true;
		this.getSprite().runAction(cc.sequence(moveRobot, moveEnd, callback));
	},

	_paint: function(direction, endCallback, target)
	{
		var animation = cc.animationCache.getAnimation(this._getPaintAnimationName());
		animation.setDelayPerUnit(pm.settings.getAnimationSpeed()/animation.getTotalDelayUnits());
		var animate = new cc.Animate(animation);

		var turnEnd = new cc.CallFunc(endCallback, target);
		this.getSprite().runAction( new cc.Sequence(animate, turnEnd));
	},

	setActive: function(flag)
	{
		this._activeCircleSprite.setVisible(flag);
		this._activeSprite.setVisible(flag);
	},

	showCommands: function(flag, count)
	{
		if (flag && MethodStackLayer.opened)
			return;

		if (flag)
			this._commandsCount.setString(count);

		this._commandsSprite.setVisible(flag);
	}
});
