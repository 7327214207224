/**
 * Created by Antony Orlovsky on 09.08.19.
 */

/**
 * @class Layer for turtle task.
 * @extends TaskLayer
 * @constructor
 * @param {pm.AbstractLevel} level
 * @param {pm.data.TurtleTask} task
 */

var TurtleTaskLayer = TaskLayer.extend(/** @lends TurtleTaskLayer# */{

	ctor: function(level, task)
	{
		this._super(LocalizedString("TurtleTask"), level, task, pm.TurtleLevelModule.Type);

		var updateButton = new pmui.Button(pm.spriteUtils.getIconName("updateWorld", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("updateWorld", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("updateWorld", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		updateButton.addClickEventListener(this._updateTask.bind(this));

		var updateButtonAlign = new ccui.LinearLayoutParameter();
		updateButtonAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
		updateButtonAlign.setMargin(LevelSettingsLayer.BORDER_RADIUS + 15, LevelSettingsLayer.SEPARATOR, 0, 0);
		updateButton.setLayoutParameter(updateButtonAlign);

		this.addChild(updateButton);
		this.setContentSize(this.width, this.height + updateButton.height + LevelSettingsLayer.SEPARATOR);
	},

	_updateTask: function()
	{
		this._task.fillWithInitialData(this._level.getActiveMapIndex(), this._level.activeMap.lines);

		this._level.activeMap.mapLayer.taskDrawLayer.clear();
		this._level.activeMap.mapLayer.drawTask();
	}
});
