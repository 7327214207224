
/**
 * @class Layer for choosing Mirera course for creating of theme
 * @extends ccui.Layout
 */

var SelectMireraCourseLayer = pmui.OvalLayout.extend(/** @lends SelectMireraCourseLayer# */{

	_courses: [],
	_index: -1,
	_callback: null,
	_target: null,
	chooseLevelLayout: null,

	ctor: function (callback, target, courses)
	{
		var screenSize = pm.settings.getScreenSize();

		var chooseGameLevelWidth = SelectMireraCourseLayer.WIDTH_PERCENT * screenSize.width;
		var chooseGameLevelHeight = SelectMireraCourseLayer.HEIGHT_PERCENT * screenSize.height;

		this._super(cc.size(chooseGameLevelWidth, chooseGameLevelHeight), null, pmui.OvalLayout.Type.THIN);

		this._callback = callback;
		this._target = target;
		this._courses = courses;

		this.setPosition((screenSize.width - this.width) / 2, (screenSize.height - this.height) / 2);

		var label = new pmui.Text(LocalizedString("CourseList"), 30);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setRelativeName("label");
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		labelAlign.setMargin(0, SelectMireraCourseLayer.BORDER, 0, 0);
		label.setLayoutParameter(labelAlign);

		this.addChild(label);

		var cancel = new pmui.InterfaceButton(this._cancel, this, LocalizedString("CancelButton"));

		var cancelAlign = new ccui.RelativeLayoutParameter();
		cancelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
		cancelAlign.setMargin(0, 0, SelectMireraCourseLayer.BORDER, SelectMireraCourseLayer.BORDER);
		cancel.setLayoutParameter(cancelAlign);

		this.addChild(cancel);

		var ok = new pmui.InterfaceButton(this._ok, this, LocalizedString("OK"));

		var okAlign = new ccui.RelativeLayoutParameter();
		okAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
		okAlign.setMargin(SelectMireraCourseLayer.BORDER, 0, 0, SelectMireraCourseLayer.BORDER);
		ok.setLayoutParameter(okAlign);

		this.addChild(ok);

		var courseNameArray = [];

		for(var i = 0 ; i < this._courses.length; ++i)
			courseNameArray.push(this._courses[i].name);

		var chooseCourseList = new pmui.SelectionListView(courseNameArray,
			cc.size(this.width - 2 * SelectMireraCourseLayer.BORDER,
				this.height - cancel.height - label.height - 2 * SelectMireraCourseLayer.BORDER - 2 * SelectMireraCourseLayer.SEPARATOR));

		chooseCourseList.setIndexCallback(this.courseSelected, this);

		var chooseCourseListAlign = new ccui.RelativeLayoutParameter();
		chooseCourseListAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		chooseCourseListAlign.setRelativeToWidgetName("label");
		chooseCourseListAlign.setMargin(SelectMireraCourseLayer.BORDER, SelectMireraCourseLayer.SEPARATOR, 0, 0);
		chooseCourseList.setLayoutParameter(chooseCourseListAlign);

		this.addChild(chooseCourseList);
	},

	_cancel: function ()
	{
		this.removeFromParent();
	},

	courseSelected: function (index)
	{
		this._index = index;
	},

	_ok: function()
	{
		if(this._index < 0)
			return;

		if(this._callback)
			this._callback.call(this._target, this._courses[this._index]);

		this.removeFromParent();
	}

});

SelectMireraCourseLayer.WIDTH_PERCENT = 0.7;
SelectMireraCourseLayer.HEIGHT_PERCENT = 0.53;
SelectMireraCourseLayer.BORDER = 20;
SelectMireraCourseLayer.BORDER_RADIUS = 15;
SelectMireraCourseLayer.SEPARATOR = 10;
