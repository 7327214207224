/**
 * Created by Kirill Mashchenko on 22.07.19.
 */

/**
 * @extends pm.AbstractLevel
 */
pm.data.AquariusLevel = pm.AbstractLevel.extend(/** @lends pm.data.AquariusLevel# */{
	typeName: "AquariusLevel",

	ctor: function()
	{
		this._super();
	},

	getType: function() { return pm.AquariusLevelModule.Type; },

	objectsPlayingAnimation: function ()
	{
		return this.activeMap.tankA.isPlayingAnimation() || this.activeMap.tankB.isPlayingAnimation() || this.activeMap.tankC.isPlayingAnimation();
	}
});
