/**
 * Created by Antony Orlovsky on 09.08.19.
 */

/**
 * @class Layer for {@link pm.data.TurtleMap}.
 * @implements SquarableMapLayer
 */
var TurtleMapLayer = pm.MapLayer2D.extend(/** @lends TurtleMapLayer#*/{

	drawLayer: null,
	badDrawLayer: null,
	taskDrawLayer: null,
	backgroundLayer: null,

	ctor: function(map)
	{
		this._super(map);
		this._orientation = pm.MapLayer2D.Orientation.Ortho;
		this._mapElementSize = cc.size(64, 64);
	},

	hasChangeOrientationOption: function()
	{
		return false;
	},

	realPosition: function(pos, del)
	{
		return cc.p(pos.x + del.x, pos.y + del.y);
	},

	positionFromReal: function(pos)
	{
		var X = Math.floor(pos.x + 0.5);
		if (X < 0) X = 0;
		if (X >= (this._map.width - 1) * this.getMapElementSize().width) X = (this._map.width - 1) * this.getMapElementSize().width;

		var Y = Math.floor(pos.y + 0.5);
		if (Y < this.getMapElementSize().height) Y = this.getMapElementSize().height;
		if (Y >= this._map.height * this.getMapElementSize().height) Y = this._map.height * this.getMapElementSize().height;
		return cc.p(X, Y);
	},

	_drawMapElements: function()
	{
		var robots = this._map.parentLevel.robots;

		for (var i = 0; i < robots.length; ++i)
		{
			var robot = robots[i];
			this.drawRobotStartPositionMark(robot.id, robot.startPosition);
		}

		this.drawTask();
	},

	_drawOrthoLayers: function(previewDraw)
	{
		pm.MapLayer2D.prototype._drawOrthoLayers.call(this, previewDraw);

		this.drawLayer = new cc.Layer();

		this.drawLayer.setCascadeOpacityEnabled(true);
		this.drawLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.drawLayer.setPosition(this._containerShift);

		this.badDrawLayer = new cc.DrawNode();

		this.badDrawLayer.setVisible(false);
		this.badDrawLayer.setCascadeOpacityEnabled(true);
		this.badDrawLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.badDrawLayer.setPosition(this._containerShift);

		this.taskDrawLayer = new cc.DrawNode();

		this.taskDrawLayer.setCascadeOpacityEnabled(true);
		this.taskDrawLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.taskDrawLayer.setPosition(this._containerShift);

		this.backgroundLayer = new cc.LayerColor(TurtleMapLayer.BACKGROUND_COLOR, this.getRealWidth(), this.getRealHeight());
		this.backgroundLayer.setCascadeOpacityEnabled(true);

		this.objectLayer.setLocalZOrder(2);
		this.addChild(this.taskDrawLayer, 1);
		this.addChild(this.drawLayer, 1);
		this.addChild(this.badDrawLayer, 1);
		this.addChild(this.backgroundLayer, -1);
	},

	setNotCompleted: function(flag)
	{
		if (flag)
		{
			for (var i = 0; i < this._map.badLines.length; ++i)
			{
				var line = this._map.badLines[i];
				this.badDrawLayer.drawSegment(line.oldPos, line.newPos, TurtleMapLayer.BAD_LINE_WIDTH, cc.color.RED);
			}
		}
		else
		{
			this.badDrawLayer.clear();
		}

		this.badDrawLayer.setVisible(flag);
	},

	drawTask: function ()
	{
		var task = this._map.parentLevel.taskList.getTask(pm.TurtleLevelModule.Type);

		if (!task)
			return;

		var mapIndex = this._map.parentLevel.getActiveMapIndex();

		for (var i = 0; i < task.data[mapIndex].length; ++i)
		{
			var line = task.data[mapIndex][i];
			this.taskDrawLayer.drawSegment(line.oldPos, line.newPos, TurtleMapLayer.LINE_WIDTH, TurtleMapLayer.TASK_LINE_COLOR);
		}
	},

	clean: function()
	{
		pm.MapLayer2D.prototype.clean.call(this);
		this.drawLayer.removeAllChildren();
	},

	freeBuffer: function ()
	{
		this.taskDrawLayer.clear();
		this.badDrawLayer.clear();
		this.drawLayer.removeAllChildren();
	}
});

TurtleMapLayer.LINE_WIDTH = 4;
TurtleMapLayer.BAD_LINE_WIDTH = 2;
TurtleMapLayer.TASK_LINE_COLOR = cc.color(133, 77, 48);
TurtleMapLayer.BACKGROUND_COLOR = cc.color(255, 239, 210);
