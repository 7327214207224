/**
 * Created by Kirill Mashchenko on 26.10.18.
 */

/**
 * @class ChooseLessonLayer.
 * @extends ccui.Layout
 */

var ChooseLessonLayer = ccui.Layout.extend(/** @lends ChooseLessonLayer# */{

	_lessonTable: null,

	_curWorldID: null,
	_loadingLayer: null,

	ctor: function (worldID)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setContentSize(pm.settings.getScreenSize());

		this._curWorldID = worldID;

		this._downloadDemoWorld();

		this._loadInterface();
	},

	_loadInterface: function()
	{
		var screenBounds = pm.settings.getScreenBounds();

		var goToStartScreen = new pmui.Button(pm.spriteUtils.getIconName("backLevel", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("backLevel", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("backLevel", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		goToStartScreen.addClickEventListener(this._startScreen.bind(this));

		var goToStartScreenAlign = new ccui.RelativeLayoutParameter();
		goToStartScreenAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		goToStartScreenAlign.setRelativeName("back");
		goToStartScreenAlign.setMargin(screenBounds.left, screenBounds.top, 0, 0);
		goToStartScreen.setLayoutParameter(goToStartScreenAlign);

		this.addChild(goToStartScreen);

		var selectPageMenu = new SelectPageMenu(SelectPageMenu.PAGES.CHOOSE_LESSON);

		var selectPageAlign = new ccui.RelativeLayoutParameter();
		selectPageAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		selectPageAlign.setMargin(SelectPageMenu.LEFT_MARGIN, -screenBounds.top, 0, 0);
		selectPageAlign.setRelativeToWidgetName("back");
		selectPageAlign.setRelativeName("pageMenu");
		selectPageMenu.setLayoutParameter(selectPageAlign);

		this.addChild(selectPageMenu);

		var label = new ccui.Text(LocalizedString("Lessons"), pm.settings.fontName, 45);
		label.setAnchorPoint(0, 0.5);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		labelAlign.setMargin((pm.settings.getScreenSize().width - goToStartScreen.width - selectPageMenu.width - label.width)/2, screenBounds.top, 0, 0);
		labelAlign.setRelativeToWidgetName("pageMenu");
		label.setLayoutParameter(labelAlign);
		this.addChild(label);

		var separator = cc.size(20, 20);

		this._lessonTable = new pmui.SelectTableView(cc.size(1, 1), separator, 0, ChooseLessonLayer.MAX_ELEMENTS_IN_ROW);
		this._lessonTable.addSelectEventListener(this._selectPageElementCallback, this);
		this._lessonTable.setSelectFrame(pm.spriteUtils.getInterfaceElementFrame("functionBGInv"), true);
		this._lessonTable.setFitObjectsSizes(true);

		this._lessonTable.setContentSize(pm.settings.getScreenSize().width - 100,
			pm.settings.getScreenSize().height - goToStartScreen.height - screenBounds.top - ChooseLessonLayer.SEPARATOR);

		var scrollLessonAlign = new ccui.RelativeLayoutParameter();
		scrollLessonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
		scrollLessonAlign.setMargin(ChooseLessonLayer.TABLE_MARGIN, 0, 0, 0);
		this._lessonTable.setLayoutParameter(scrollLessonAlign);

		this.addChild(this._lessonTable);

		this._drawLessonTable();
	},

	_drawLessonTable: function()
	{
		var mapLayersArray = pm.moduleUtils.getLessonMapLayers();

		var rows = Math.floor(mapLayersArray.length/ ChooseLessonLayer.MAX_ELEMENTS_IN_ROW) +
			((mapLayersArray.length % ChooseLessonLayer.MAX_ELEMENTS_IN_ROW === 0) ? 0 : 1);

		this._lessonTable.setRowCount(rows);

		var cellHeight = 0;

		for(var i = 0; i < mapLayersArray.length; ++i)
		{
			var spr = mapLayersArray[i];

			var element = new ChooseLessonElement(spr, spr._map.parentLevel.typeName,
				(pm.settings.getScreenSize().width - ChooseLessonLayer.ELEMENT_MARGIN) / ChooseLessonLayer.MAX_ELEMENTS_IN_ROW,
				 this._lessonTable.height,
				 null, this);

			this._lessonTable.setCell(Math.floor(i / ChooseLessonLayer.MAX_ELEMENTS_IN_ROW),
				i % ChooseLessonLayer.MAX_ELEMENTS_IN_ROW, element);

			cellHeight = Math.max(cellHeight, element.height);
		}

		var cellSize = cc.size((pm.settings.getScreenSize().width - ChooseLessonLayer.ELEMENT_MARGIN) / ChooseLessonLayer.MAX_ELEMENTS_IN_ROW,
			cellHeight);

		this._lessonTable.setCellSize(cellSize);
	},

	_selectPageElementCallback: function(selectedPage)
	{
		if (!selectedPage)
		{
			if(cc.director.getRunningScene().getChildByTag(CommonLessonPage.TAG))
				cc.director.getRunningScene().removeChildByTag(CommonLessonPage.TAG);

			return;
		}

		if(cc.director.getRunningScene().getChildByTag(CommonLessonPage.TAG))
			cc.director.getRunningScene().removeChildByTag(CommonLessonPage.TAG);

		var cell = this._lessonTable.getCell(selectedPage);

		var lessonPage = null;

		switch(cell.nameOfPage)
		{
			case pm.data.RepairLevel.prototype.typeName:
				lessonPage = new RepairLessonPage(this, this._closePage);
				break;
			case pm.data.PushLevel.prototype.typeName:
				lessonPage = new PushLessonPage(this, this._closePage);
				break;
			case pm.data.LightLevel.prototype.typeName:
				lessonPage = new LightLessonPage(this, this._closePage);
				break;
			case pm.data.PhysicalRobotLevel.prototype.typeName:
				lessonPage = new PhysicalLessonPage(this, this._closePage);
				break;
		}

		if (lessonPage)
			cc.director.getRunningScene().addChild(lessonPage, 100, CommonLessonPage.TAG);
	},

	_closePage: function()
	{
		this._lessonTable.deselectAll();

		cc.director.getRunningScene().removeChildByTag(CommonLessonPage.TAG);
	},

	_startScreen: function()
	{
		pm.settings.isHelpMode =  false;

		this._downloadWorld(this._curWorldID);

		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new StartMenuScene());
		cc.director.runScene(trans);
	},

	_downloadWorld: function(worldID)
	{
		this._loadingLayer = new LoadingLayer();
		this._loadingLayer.show();

		pm.worldUtils.loadWorld({
			worldID: worldID,
			callback: this._endDownLoadWorld,
			callbackTarget: this,
			reloadBuiltinOnError: true
		});
	},

	_endDownLoadWorld: function(error, loadedWorldID)
	{
		if (!error && loadedWorldID.length > 0 && loadedWorldID[0])
			pm.settings.setSelectedWorldID(loadedWorldID[0]);

		this._loadingLayer.remove();

		pm.settings.setGame(ChooseLessonLayer.GAME_ID);
	},

	_downloadDemoWorld: function()
	{
		this._loadingLayer = new LoadingLayer();
		this._loadingLayer.show();

		pm.worldUtils.loadWorld({
			worldID: pm.appConfig.demoWorldID,
			callback: this._endDownLoadDemoWorld,
			callbackTarget: this,
			reloadBuiltinOnError: true
		});
	},

	_endDownLoadDemoWorld: function(error, loadedWorldID)
	{
		this._loadingLayer.remove();
		pm.settings.setGame(ChooseLessonLayer.GAME_ID);
	},

	getCurrentWorldID: function()
	{
		return this._curWorldID;
	}
});

ChooseLessonLayer.MAX_ELEMENTS_IN_ROW = 2;
ChooseLessonLayer.SEPARATOR = 8;
ChooseLessonLayer.TABLE_MARGIN = 78;
ChooseLessonLayer.ELEMENT_MARGIN = 220;
ChooseLessonLayer.GAME_ID = 1;

/**
 * @class Scene for {@link ChooseLevelLayer}
 * @extends cc.Scene
 * @constructor
 * @param {Number} worldID ID of current world.
 */
var ChooseLessonScene = cc.Scene.extend(/** @lends ChooseLessonScene# */{

	ctor: function(worldID)
	{
		this._super();

		var layer = new ChooseLessonLayer(worldID);
		this.addChild(layer);

		var backLayer = pm.backgroundUtils.generateBackground();
		this.addChild(backLayer, -1);
	}
});
