/**
 * Created by Antony Orlovsky on 28.09.18.
 */

/**
 * @class  Element for sliding menu.
 * @extends ccui.Layout
 */
pmui.SlidingMenuElement = ccui.Layout.extend(/** @lends pmui.SlidingMenuElement# */{

	ctor: function()
	{
		this._super();

		this.setTouchEnabled(true);
	}
});

pmui.SlidingWorldMenuElement = pmui.SlidingMenuElement.extend(/** @lends pmui.SlidingWorldMenuElement# */{

	ctor: function(width, element, isHighlighted)
	{
		this._super();

		this.setContentSize(width, pmui.SlidingWorldMenuElement.HEIGHT);

		var textAreaSize = cc.size(this.width - 2 * element.node.width, 0);

		var name = new ccui.Text(element.label, pm.settings.fontName, pmui.SlidingWorldMenuElement.NAME_SIZE);
		name.setTextColor(pmui.SlidingWorldMenuElement.TEXT_COLOR);
		name.setTextAreaSize(textAreaSize)
		name.setTextHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
		name.setAnchorPoint(cc.p(0.5, 0));

		var text = new ccui.Text(element.description, pm.settings.fontName, pm.settings.fontSize);
		text.setTextColor(pmui.SlidingWorldMenuElement.TEXT_COLOR);
		text.setTextAreaSize(textAreaSize);
		text.setTextHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
		text.setAnchorPoint(cc.p(0.5, 1));

		var texture = isHighlighted ? "System/highlight-on.png" : "System/highlight-off.png";
		var rect = isHighlighted ? pmui.SlidingWorldMenuElement.HIGHLIGHT_ON_RECT : pmui.SlidingWorldMenuElement.HIGHLIGHT_OFF_RECT;
		var highlightSprite = new cc.Scale9Sprite(texture, cc.rect(0, 0, 0, 0), rect);

		highlightSprite.setContentSize(this.width - pmui.SlidingWorldMenuElement.HIGHLIGHT_WIDTH_CORRECTION,
			(name.height + text.height + pmui.SlidingWorldMenuElement.TEXT_SEPARATOR) + pmui.SlidingWorldMenuElement.HIGHLIGHT_HEIGHT_ADDITION);
		highlightSprite.setAnchorPoint(cc.p(0.5, 0.5));

		if (highlightSprite.height > this.height)
			this.setContentSize(this.width, highlightSprite.height);

		highlightSprite.setPosition(this.width / 2, this.height / 2);
		name.setPosition(this.width / 2, (this.height + pmui.SlidingWorldMenuElement.TEXT_SEPARATOR + text.height - name.height) / 2);
		text.setPosition(this.width / 2, (this.height - pmui.SlidingWorldMenuElement.TEXT_SEPARATOR + text.height - name.height) / 2);

		if (element.number % 2 === 0)
		{
			element.node.setAnchorPoint(cc.p(0, 0.5));
			element.node.setPosition(cc.p(0, this.height / 2));
		}
		else
		{
			element.node.setAnchorPoint(cc.p(1, 0.5));
			element.node.setPosition(cc.p(this.width, this.height / 2));
		}

		this.addChild(name, 1);
		this.addChild(text, 1);
		this.addChild(highlightSprite, -1);
		this.addChild(element.node);
	}
});

pmui.SlidingWorldMenuElement.HIGHLIGHT_HEIGHT_ADDITION = 110;
pmui.SlidingWorldMenuElement.TEXT_SEPARATOR = 16;
pmui.SlidingWorldMenuElement.HIGHLIGHT_WIDTH_CORRECTION = 240;
pmui.SlidingWorldMenuElement.NAME_SIZE = 35;
pmui.SlidingWorldMenuElement.HIGHLIGHT_ON_RECT = cc.rect(650, 110, 30, 5);
pmui.SlidingWorldMenuElement.HIGHLIGHT_OFF_RECT = cc.rect(370, 55, 600, 110);
pmui.SlidingWorldMenuElement.TEXT_COLOR = cc.color(63, 63, 150);
pmui.SlidingWorldMenuElement.HEIGHT = 240;

pmui.SlidingLevelMenuElement = pmui.SlidingMenuElement.extend(/** @lends pmui.SlidingLevelMenuElement# */{
	levelID: null,
	_starSprite: null,
	_completed: true,

	ctor: function(node, labelText, number, levelNumber)
	{
		this._super();

		var screenBounds = pm.settings.getScreenBounds();

		var countStars = new ccui.Layout();

		var border = new cc.Sprite("System/Border.png");

		var game = world.games[pm.settings.getGame()];

		var needToDrawDots = true;

		if (number === world.getLevelCount(pm.settings.getGame()))
			needToDrawDots = false;

		if (levelNumber>=0)
		{
			var level = game.levels[levelNumber];

			this.levelID = level.id;

			var completedIcon = "starIncomplete";

			this._completed = true;

			for(var i = 0; i < level.maps.length; ++i)
			{
				if(pm.userCache.getScore(level, i) === -1)
					this._completed = false;
			}

			if (this._completed)
				completedIcon = "star";

			this._starSprite = pm.spriteUtils.getIconSprite(completedIcon);
			this._starSprite.setAnchorPoint(cc.p(1, 1));

			var countLabel = new ccui.Text(levelNumber + 1, pm.settings.fontBoldName, 55);

			var a = 0;
			if (levelNumber === 0 || levelNumber % 10 === 0 || levelNumber % 100 === 0)
				a =- 6;

			countLabel.setAnchorPoint(cc.p(1, 1));
			countLabel.setPosition(cc.p(this._starSprite.width + 60 + 7 + a, Math.max(this._starSprite.height, countLabel.height) + 5));

			this._starSprite.setPosition(this._starSprite.width + 60 + 7 + 45, Math.max(this._starSprite.height, countLabel.height) - 20);

			countStars.addChild(this._starSprite, 4);
			countStars.addChild(countLabel, 4);

			countStars.setContentSize(this._starSprite.width + 60 + 7, Math.max(this._starSprite.height, countLabel.height) + 5);
			// countStars.setContentSize(countLabel.width + 7, countLabel.height + 5);
		}

		this.setContentSize(node.width + 50, node.height * 3 + screenBounds.top);

		var borderHeight = 40;

		if (number % 3 === 1)
		{
			countStars.setAnchorPoint(cc.p(1, 1));

			countStars.setPosition(cc.p(node.width/2 + 40 - 65 - 15, 100));

			node.setAnchorPoint(cc.p(0, 0));
			node.setPosition(cc.p(25, borderHeight + screenBounds.top - 10));

			border.setAnchorPoint(cc.p(0, 0));
			border.setPosition(cc.p(25, borderHeight+ screenBounds.top - 10));

			if (needToDrawDots)
			{
				for (var i = 0; i < 11; ++i)
				{
					var dot = new cc.Sprite("WorldPics/dot.png");

					dot.setAnchorPoint(cc.p(0, 0.5));

					var scale = cc.random0To1()/2+0.5;
					dot.setScale(scale);

					// dot.runAction(cc.repeatForever(cc.sequence(
					//     cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, scale * 0.6), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.5 * 255)),
					//     cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, scale), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 255))
					// )));

					dot.setPosition(cc.p(25 + node.width - 35 + i * 10,
						borderHeight + screenBounds.top + 60 + node.height/2 + 14 * i));
					this.addChild(dot, 0);
				}
			}
		}
		else if (number % 3 === 0)
		{
			node.setAnchorPoint(cc.p(0.5, 1));
			node.setPosition(cc.p(node.width/2-20, this.height*0.55));

			border.setAnchorPoint(cc.p(0.5, 1));
			border.setPosition(cc.p(node.width/2-20, this.height*0.55));

			countStars.setAnchorPoint(cc.p(1, 1));
			countStars.setPosition(cc.p(node.width/2-20 - 50 - 15, this.height*0.55 - node.height - screenBounds.top + 10 + 60));

			if (needToDrawDots)
			{
				for (var i = 1; i <= 7; ++i)
				{
					var dot = new cc.Sprite("WorldPics/dot.png");

					dot.setAnchorPoint(cc.p(0, 0.5));

					var scale = cc.random0To1()/2+0.5;
					dot.setScale(scale);

					// dot.runAction(cc.repeatForever(cc.sequence(
					//     cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, scale * 0.6), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.5 * 255)),
					//     cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, scale), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 255))
					// )));

					dot.setPosition(cc.p(25 + node.width - 50 + i * 13,
						borderHeight + screenBounds.top + 50 + node.height/2 - 5 * i));
					this.addChild(dot, 0);
				}
			}
		}
		else if (number % 3 === 2)
		{
			node.setAnchorPoint(cc.p(0.5, 1));
			node.setPosition(cc.p(25 + node.width/2, this.height));

			border.setAnchorPoint(cc.p(0.5, 1));
			border.setPosition(cc.p(25 + node.width/2, this.height));

			countStars.setAnchorPoint(cc.p(1, 1));
			countStars.setPosition(cc.p(node.width/2 + 25 - 50 - 15, this.height - node.height - screenBounds.top + 10 + 60));

			if (needToDrawDots)
			{
				for (var i = 1; i <= 6; ++i)
				{
					var dot = new cc.Sprite("WorldPics/dot.png");

					dot.setAnchorPoint(cc.p(0, 0.5));

					var scale = cc.random0To1()/2+0.5;
					dot.setScale(scale);

					// dot.runAction(cc.repeatForever(cc.sequence(
					//     cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, scale * 0.6), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.5 * 255)),
					//     cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, scale), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 255))
					// )));

					dot.setPosition(cc.p(25 + node.width - 25 + i * 10,
						borderHeight + screenBounds.top + 225 + node.height/2 - 16* i));
					this.addChild(dot, 0);
				}
			}
		}

		this.setTouchEnabled(true);
		this.addChild(node, 1);

		this.addChild(countStars, 5);
		this.addChild(border, 2);
	},

	setMireraCompleted: function(mireraCompleted) {
		if(this._completed)
		{
			var icon = "star";

			if (mireraCompleted)
				icon = "starMirera";

			var frame = pm.spriteUtils.getIconSpriteFrame(icon);
			this._starSprite.setSpriteFrame(frame);
		}
	}
});

pmui.SlidingGameMenuElement = pmui.SlidingMenuElement.extend(/** @lends pmui.SlidingGameMenuElement# */{

	ctor: function(node, labelText, gameNumber, margin, needToDrawDots)
	{
		this._super();
		this.setCascadeOpacityEnabled(true);

		this.setContentSize(node.width, pm.settings.getScreenSize().height - margin);
		this.setAnchorPoint(cc.p(0.5, 0.5));
		this.setTouchEnabled(true);

		node.setAnchorPoint(cc.p(0.5, 0.5));
		node.setPosition(cc.p(node.width / 2, pm.settings.getScreenSize().height / 2));

		var label = new ccui.Text(labelText, pm.settings.fontName, pmui.SlidingGameMenuElement.TEXT_SIZE);

		label.setAnchorPoint(cc.p(0.5, 1));
		label.setPosition(cc.p(node.width / 2, node.y - label.height / 2 - node.height / 2 + pmui.SlidingGameMenuElement.TEXT_SEPARATOR));
		label.setTextHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
		label.setTextAreaSize(cc.size(node.width, 0));

		this.addChild(label, 1);
		this.addChild(node);

		var count = 0;
		var completedCount = 0;

		var game = world.games[gameNumber];

		var levelMap = {};

		if (game)
		{
			for (var i = 0; i < game.levels.length; ++i)
			{
				var level = game.levels[i];

				var levelType = game.levels[i].typeName;

				if (["RepairLevel", "PushLevel", "LightLevel", "TrainLevel"].indexOf(levelType) !== -1)
				{
					if (levelType === "PushLevel")
					{
						var robotTypes = {};

						for (var r = 0; r < game.levels[i].robots.length; ++r)
							robotTypes[game.levels[i].robots[r].typeName] = 1;

						if ((Object.keys(robotTypes).length) >= 2)
							levelType = "PushPullLevel";
						else if (game.levels[i].robots[0].typeName === "PullRobot")
							levelType = "PullLevel";
					}

					if (levelMap[levelType] === undefined)
						levelMap[levelType] = 1;
					else
						++levelMap[levelType];
				}

				// completedCount += pm.userCache.getCompleteMapCount(level);
				var notCompletedFlag = false;

				for (var j = 0; j < level.maps.length; ++j)
				{
					if (pm.userCache.getScore(level, j) === -1)
						notCompletedFlag = true;
				}

				if (!notCompletedFlag)
					completedCount++;

				count++;
			}
		}

		var levelArray = [];

		for (var elem in levelMap)
			levelArray.push([elem, levelMap[elem]])

		var compareFn = function (a, b)
		{
			return b[1] - a[1];
		};

		levelArray.sort(compareFn);
		
		if (levelArray.length === 0)
			levelArray = [["RepairLevel", 1], ["PushPullLevel", 1], ["LightLevel", 1]];

		while(levelArray.length < 3)
			levelArray.unshift(levelArray[0]);

		var platform_0 = new cc.Sprite("WorldPics/{0}_0.png".format(levelArray[0][0]));
		var platform_1 = new cc.Sprite("WorldPics/{0}_1.png".format(levelArray[1][0]));
		var platform_2 = new cc.Sprite("WorldPics/{0}_2.png".format(levelArray[2][0]));

		platform_0.setAnchorPoint(cc.p(0.5, 0));
		platform_0.setPosition(cc.p(node.width / 2, pm.settings.getScreenSize().height / 2 - 80));

		platform_1.setAnchorPoint(cc.p(0.5, 0));
		platform_1.setPosition(cc.p(node.width / 2 - 80, pm.settings.getScreenSize().height / 2 - 120));

		platform_2.setAnchorPoint(cc.p(0.5, 0));
		platform_2.setPosition(cc.p(node.width / 2 + 80, pm.settings.getScreenSize().height / 2 - 90));

		this.addChild(platform_0);
		this.addChild(platform_1);
		this.addChild(platform_2);

		if (needToDrawDots)
		{
			for (var i = 1; i <= 4; ++i)
			{
				var dot = new cc.Sprite("WorldPics/dot.png");

				dot.setAnchorPoint(cc.p(0, 0.5));

				var scale = cc.random0To1()/2+0.5;
				dot.setScale(scale);

				dot.setPosition(cc.p(node.width - 37 + i * 13,  node.y - node.height/2 + 80 - 6 * i));
				this.addChild(dot, 0);
			}
		}

		var starName;

		if (completedCount === count)
			starName = "star-complete";
		else if (completedCount === 0)
			starName = "star-incomplete";
		else
			starName = "star";

		var star = new cc.Sprite(pm.spriteUtils.getInterfaceElementFrame(starName));
		var countLabel = new ccui.Text(completedCount + "/" + count, pm.settings.fontName, pmui.SlidingGameMenuElement.TEXT_SIZE);
		var starLayout = new ccui.Layout();

		starLayout.setContentSize(star.width + countLabel.width + pmui.SlidingGameMenuElement.LABEL_SEPARATOR,
			Math.max(star.height, countLabel.height));
		starLayout.setAnchorPoint(cc.p(0.5, 1));
		starLayout.setPosition(node.width / 2, label.y - label.height - pmui.SlidingGameMenuElement.STAR_SEPARATOR);

		star.setAnchorPoint(cc.p(0, 0.5));
		star.setPosition(cc.p(0, starLayout.height / 2));

		countLabel.setAnchorPoint(cc.p(0, 0.5));
		countLabel.setPosition(cc.p(star.width + pmui.SlidingGameMenuElement.LABEL_SEPARATOR, star.y));

		starLayout.addChild(star, 1);
		starLayout.addChild(countLabel, 1);
		this.addChild(starLayout);
	}
});

pmui.SlidingGameMenuElement.STAR_SEPARATOR = 5;
pmui.SlidingGameMenuElement.LABEL_SEPARATOR = 10;
pmui.SlidingGameMenuElement.TEXT_SEPARATOR = 15;
pmui.SlidingGameMenuElement.TEXT_SIZE = 18;
