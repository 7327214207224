/**
 * Created by Kirill Mashchenko on 20.08.2018.
 */

/**
 * @class Layer for register.
 * @extends pmui.OvalLayout
 */
var RegisterLayer = pmui.OvalLayout.extend(/** @extends RegisterLayer# */{
	_target: null,
	_cancelCallback: null,

	_emailInput: null,
	_passwordInput: null,
	_confirmPasswordInput: null,
	_nameInput: null,
	_secondNameInput: null,
	_confidenceFlag: false,

	ctor: function(target, cancelCallback)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setContentSizeWithBorder(RegisterLayer.WIDTH, RegisterLayer.HEIGHT);

		this.setTouchEnabled(true);

		this.setAnchorPoint(cc.p(0.5, 0.5));
		this.setPosition(pm.settings.getScreenSize().width / 2, pm.settings.getScreenSize().height / 2);

		this._target = target;
		this._cancelCallback = cancelCallback;

		this._loadInterface();
	},

	_loadInterface: function()
	{
		var closeButton = new pmui.Button(pm.spriteUtils.getIconName("close", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("close", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("close", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		closeButton.addClickEventListener(this._cancelCallback.bind(this._target));

		var closeButtonAlign = new ccui.RelativeLayoutParameter();
		closeButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		closeButtonAlign.setMargin(0, -closeButton.height/4, -closeButton.width/4, 0);
		closeButton.setLayoutParameter(closeButtonAlign);

		this.addChild(closeButton);

		this._emailInput = new pmui.TextInput("", LocalizedString("email"), 20, RegisterLayer.WIDTH - RegisterLayer.WIDTH_DELTA);

		var emailAlign = new ccui.RelativeLayoutParameter();
		emailAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		emailAlign.setMargin(RegisterLayer.BORDER, RegisterLayer.BORDER, 0, 0);
		emailAlign.setRelativeName("login");
		this._emailInput.setLayoutParameter(emailAlign);

		this.addChild(this._emailInput);

		this._passwordInput = new pmui.TextInput("", LocalizedString("Password"), 20, RegisterLayer.WIDTH - RegisterLayer.WIDTH_DELTA);
		this._passwordInput.setPasswordInput(true);

		var passwordAlign = new ccui.RelativeLayoutParameter();
		passwordAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		passwordAlign.setMargin(0, RegisterLayer.SEPARATOR, 0, 0);
		passwordAlign.setRelativeName("password");
		passwordAlign.setRelativeToWidgetName("login");
		this._passwordInput.setLayoutParameter(passwordAlign);

		this.addChild(this._passwordInput);

		this._confirmPasswordInput = new pmui.TextInput("", LocalizedString("ConfirmPassword"), 20, RegisterLayer.WIDTH - RegisterLayer.WIDTH_DELTA);
		this._confirmPasswordInput.setPasswordInput(true);

		var confirmPasswordAlign = new ccui.RelativeLayoutParameter();
		confirmPasswordAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		confirmPasswordAlign.setMargin(0, RegisterLayer.SEPARATOR, 0, 0);
		confirmPasswordAlign.setRelativeName("confirmPassword");
		confirmPasswordAlign.setRelativeToWidgetName("password");
		this._confirmPasswordInput.setLayoutParameter(confirmPasswordAlign);

		this.addChild(this._confirmPasswordInput);

		this._nameInput = new pmui.TextInput("", LocalizedString("Name"), 20, RegisterLayer.WIDTH - RegisterLayer.WIDTH_DELTA);

		var nameInputAlign = new ccui.RelativeLayoutParameter();
		nameInputAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		nameInputAlign.setMargin(0, RegisterLayer.SEPARATOR, 0, 0);
		nameInputAlign.setRelativeName("name");
		nameInputAlign.setRelativeToWidgetName("confirmPassword");
		this._nameInput.setLayoutParameter(nameInputAlign);

		this.addChild(this._nameInput);

		this._secondNameInput = new pmui.TextInput("", LocalizedString("SecondName"), 20, RegisterLayer.WIDTH - RegisterLayer.WIDTH_DELTA);

		var secondNameAlign = new ccui.RelativeLayoutParameter();
		secondNameAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		secondNameAlign.setMargin(0, RegisterLayer.SEPARATOR, 0, 0);
		secondNameAlign.setRelativeName("secondName");
		secondNameAlign.setRelativeToWidgetName("name");
		this._secondNameInput.setLayoutParameter(secondNameAlign);

		this.addChild(this._secondNameInput);

		var confidenceCheckBox =  new pmui.CheckBox(LocalizedString("IAgree"), this._checkConfidence, this, 13, false);

        var confidenceAlign = new ccui.RelativeLayoutParameter();
        confidenceAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
        confidenceAlign.setMargin(0, RegisterLayer.SEPARATOR, 0, 0);
        confidenceAlign.setRelativeName("checkBox");
        confidenceAlign.setRelativeToWidgetName("secondName");
        confidenceCheckBox.setLayoutParameter(confidenceAlign);

        this.addChild(confidenceCheckBox);

		var userLink = new ccui.Text(LocalizedString("UserAgreement"), pm.settings.fontBoldName, 13);
		userLink.setTextColor(cc.color(0, 162, 255));
		userLink.addClickEventListener(this._onUserLink.bind(this));
		userLink.setTouchEnabled(true);

		var userLinkAlign = new ccui.RelativeLayoutParameter();
		userLinkAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_BOTTOMALIGN);
		userLinkAlign.setMargin(0, 0, 0, 0);
		userLinkAlign.setRelativeToWidgetName("checkBox");
		userLink.setLayoutParameter(userLinkAlign);

		this.addChild(userLink);

		var andLabel = new ccui.Text(LocalizedString("And"), pm.settings.fontBoldName, 13);

		var andLabelAlign = new ccui.RelativeLayoutParameter();
		andLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		andLabelAlign.setMargin(RegisterLayer.AND_MARGIN, 2, 0, 0);
		andLabelAlign.setRelativeToWidgetName("checkBox");
		andLabelAlign.setRelativeName("and");
		andLabel.setLayoutParameter(andLabelAlign);

		this.addChild(andLabel);

        var confidenceLink = new ccui.Text(LocalizedString("PrivacyPolicy"), pm.settings.fontBoldName, 13);
        confidenceLink.setTextColor(cc.color(0, 162, 255));
        confidenceLink.addClickEventListener(this._onConfidenceLink.bind(this));
        confidenceLink.setTouchEnabled(true);

        var confidenceLinkAlign = new ccui.RelativeLayoutParameter();
        confidenceLinkAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_BOTTOMALIGN);
        confidenceLinkAlign.setMargin(0, 0, 0, 0);
        confidenceLinkAlign.setRelativeToWidgetName("and");
        confidenceLink.setLayoutParameter(confidenceLinkAlign);

        this.addChild(confidenceLink);

        var register = new pmui.InterfaceButton(this._register, this, LocalizedString("Register"));

		var registerAlign = new ccui.RelativeLayoutParameter();
		registerAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		registerAlign.setMargin(-RegisterLayer.AND_MARGIN, RegisterLayer.SEPARATOR, 0, 0);
		registerAlign.setRelativeName("registerButton");
		registerAlign.setRelativeToWidgetName("and");
		register.setLayoutParameter(registerAlign);

		this.addChild(register);
	},

    _checkConfidence: function (flag)
    {
        this._confidenceFlag = flag;
    },

	_onUserLink: function()
	{
		pm.utils.openURL(RegisterLayer.USER_AGREEMENT_URL);
	},

	_onConfidenceLink: function()
    {
		pm.utils.openURL(RegisterLayer.PRIVACY_POLICY_URL);
    },

	_register: function()
	{
		var message = null;

		var mail = this._emailInput.getString();
		var password = this._passwordInput.getString();
		var passwordConfirm = this._confirmPasswordInput.getString();
		var name = this._nameInput.getString();
		var surname = this._secondNameInput.getString();

		if(mail === "" || passwordConfirm === "" || name === "" || surname === "" || password === "" || !this._confidenceFlag)
			message = LocalizedString("NotAllFieldsFilled");
		else if(password !== passwordConfirm)
			message = LocalizedString("PasswordsNotMath");

		if(message)
		{
			var messageBox = new pmui.MessageBox(this, message);

			var x = pm.settings.getScreenSize().width / 2 - messageBox.getContentSize().width / 2;
			var y = pm.settings.getScreenSize().height / 2 - messageBox.getContentSize().height / 2;
			messageBox.setPosition(x, y);
			cc.director.getRunningScene().addChild(messageBox, RegisterLayer.TAG);

			return;
		}
		var self = this;

		pm.apiServerUtils.register(function(error, response)
		{
			self.removeFromParent();

			var regMessage = LocalizedString("RegisterSuccess");

			if(error)
				regMessage = LocalizedString("RegisterFailurePattern").format(error.text);

			var messageBox = new pmui.MessageBox(this, regMessage);

			var x = pm.settings.getScreenSize().width / 2 - messageBox.getContentSize().width / 2;
			var y = pm.settings.getScreenSize().height / 2 - messageBox.getContentSize().height / 2;
			messageBox.setPosition(x, y);
			cc.director.getRunningScene().addChild(messageBox, RegisterLayer.TAG);

		}, mail, password, name, surname);

	}
});

RegisterLayer.WIDTH = 460;
RegisterLayer.HEIGHT = 390;
RegisterLayer.TAG = 2513;
RegisterLayer.SEPARATOR = 15.0;
RegisterLayer.WIDTH_DELTA = 70.0;
RegisterLayer.BORDER = 45.0;
RegisterLayer.AND_MARGIN = 25.0;
RegisterLayer.USER_AGREEMENT_URL = 'https://piktomir.ru/user-agreement/';
RegisterLayer.PRIVACY_POLICY_URL = 'https://piktomir.ru/privacy-policy/';

