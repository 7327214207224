/**
 * Created by Nikita Besshaposhnikov on 08.07.15.
 */

/**
 * @class Sprite for {@link pm.data.PushBarrel}
 * @implements PushObjectSprite
 */
var PushBarrelSprite = PushObjectSprite.extend(/**@lends PushBarrelSprite#*/{

	ctor: function()
	{
		this._super("Objects/PushBarrel.png");

		this._minPoint = cc.p(34, 26);
		this._maxPoint = cc.p(34, -6);
	}
});
