/**
 * Created by Kirill Madhchenko on 29.06.17.
 */

/**
 * Wall Types
 * @enum {String}
 */

pm.WallType4 = {
	Left: "left",
	Up: "up"
};

/**
 * @class Class of walls.
 * @interface
 * @extends pm.StaticSprite
 */
pm.WallSprite4 = pm.StaticSprite.extend(/** @lends WallSprite4# */{

	ctor: function(type, orientation)
	{
		if (orientation !== undefined)
		{
			this._super(pm.spriteUtils.getMapWallTileFrame(type, orientation));
			switch (type)
			{
				case pm.WallType4.Left:
					if (orientation === pm.MapLayer2D.Orientation.Ortho)
					{
						this._minPoint = cc.p(0, 65);
						this._maxPoint = cc.p(11, 0);
						break;
					}
					else
					{
						this._minPoint = cc.p(13, 61);
						this._maxPoint = cc.p(21, 4);
						break;
					}

				case pm.WallType4.Up:
					if (orientation === pm.MapLayer2D.Orientation.Ortho)
					{
						this._minPoint = cc.p(0, 11);
						this._maxPoint = cc.p(63, 0);
						break;
					}
					else
					{
						this._minPoint = cc.p(17, 7);
						this._maxPoint = cc.p(74, 0);
						break;
					}

				default:
					break;
			}
		}
	}

});
