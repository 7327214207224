/**
 * Created by Nikita Besshaposhnikov on 16.11.15.
 */

/**
 * Types of {@link pm.data.PhysicalRobotMapElement}.
 * @enum {Number}
 * @readonly
 */
var PhysicalRobotMapElementType = {
	Green: "G",
	Blue: "B",
	Yellow: "Y",
	NullElement: 0,
	OneElement: 1,
	TwoElement: 2,
	ThreeElement: 3,
	FourElement: 4,
	FiveElement: 5,
	SixElement: 6,
	SevenElement: 7,
	EightElement: 8,
	NineElement: 9
};

/**
 * @class Elements of {@link pm.data.PhysicalRobotMap}.
 * @implements pm.MapElement
 */
pm.data.PhysicalRobotMapElement = pm.MapElement2D.extend(/** @lends pm.data.PhysicalRobotMapElement# */{
	typeName: "PhysicalRobotMapElement",

	ctor: function ()
	{
		this._super();
	},

	setNotCompleted: function(flag) {}
});
