/**
 * Created by Kirill Mashchenko on 17.05.23.
 */

/**
 * @class 2D sprite of {pm.data.PressRobot}.
 * @implements pm.OrthoRobotSprite
 */
var PressRobotSprite = pm.OrthoRobotSprite.extend(/** @lends PressRobotSprite# */{

	_stateRedIndicator: null,
	_stateGreenIndicator: null,

	_robot: null,

	ctor: function (robot)
	{
		this._robot = robot;

		cc.animationCache.addAnimations("Robot/press_robot-animations.plist");
		cc.animationCache.addAnimations("Robot/physical_robot-animations.plist");

		pm.spriteUtils.registerTempSpriteFrames("Robot/press_robot.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/press_robot-indicate.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/press_robot-broken.plist");

		pm.spriteUtils.addTempSpriteFrames("Robot/physical_robot.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/physical_robot-indicate.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/physical_robot-broken.plist");

		pm.spriteUtils.addTempSpriteFrames("Robot/press_robot_loaded.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/press_robot_loaded-indicate.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/press_robot_loaded-broken.plist");

		pm.spriteUtils.addTempSpriteFrames("Robot/physical_robot_loaded.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/physical_robot_loaded-indicate.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/physical_robot_loaded-broken.plist");

		this._super(cc.spriteFrameCache.getSpriteFrame(this._getFrameName(0)));

		this._stateRedIndicator = new cc.Sprite("Robot/bleRobotLightRed.png");
		this._stateRedIndicator.setAnchorPoint(cc.p(0.5, 0.5));
		this._stateRedIndicator.setPosition(cc.p(26, 50));

		this._stateRedIndicator.runAction(cc.repeatForever(cc.sequence(
			cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.95), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.85 * 255)),
			cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, 1), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 255))
		)));

		this.getSprite().addChild(this._stateRedIndicator, 1);

		this._stateGreenIndicator = new cc.Sprite("Robot/bleRobotLightGreen.png");
		this._stateGreenIndicator.setAnchorPoint(cc.p(0.5, 0.5));
		this._stateGreenIndicator.setPosition(cc.p(59, 50));

		this._stateGreenIndicator.runAction(cc.repeatForever(cc.sequence(
			cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.9), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 0.8 * 255)),
			cc.spawn(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 3, 1), cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY * 3, 255))
		)));

		this.getSprite().addChild(this._stateGreenIndicator, 1);

		this.changeState(pm.PhysicalConnector.STATE.DISCONNECTED);
	},

	changeState: function(state)
	{
		switch (state)
		{
			case pm.PhysicalConnector.STATE.CONNECTED:
				this._stateRedIndicator.setVisible(false);
				this._stateGreenIndicator.setVisible(true);
				break;
			case pm.PhysicalConnector.STATE.DISCONNECTED:
				this._stateRedIndicator.setVisible(true);
				this._stateGreenIndicator.setVisible(false);
				break;
		}
	},

	_getTurnAngle: function(direction)
	{
		return 180 - direction * 90;
	},

	_getFrameName: function (direction)
	{
		if (this._robot.usePressMethod)
		{
			if (this._robot.useLoadingBoxes)
				return "press_robot_loaded.png";
			else
				return "press_robot.png";
		}
		else
		{
			if (this._robot.useLoadingBoxes)
				return "physical_robot_loaded.png";
			else
				return "physical_robot_BLE.png";
		}
	},

	_getDestroyAnimationName: function (direction)
	{
		if (this._robot.usePressMethod)
		{
			if (this._robot.useLoadingBoxes)
				return "press_robot_loaded_d";
			else
				return "press_robot_d";
		}
		else
		{
			if (this._robot.useLoadingBoxes)
				return "physical_robot_loaded_d";
			else
				return "physical_robot_BLE_d";
		}
	},

	_getIndicateAnimationName: function (direction)
	{
		if (this._robot.usePressMethod)
		{
			if (this._robot.useLoadingBoxes)
				return "press_robot_loaded_i";
			else
				return "press_robot_i";
		}
		else
		{
			if (this._robot.useLoadingBoxes)
				return "physical_robot_loaded_i";
			else
				return "physical_robot_BLE_i";
		}
	}
});
