/**
 * Created by Nikita Besshaposhnikov on 13.05.15.
 */
/**
 * This namespace contains functions to control all game audio.
 * @namespace
 *
 */
pm.audioUtils =
{
	/**
     * File path to background sound file
     * @constant
     * @type {String}
     * @default
     */
	BACKGROUND_AUDIO_FILE: "Sounds/background.mp3",
	/**
     * File path to bwin sound file
     * @constant
     * @type {String}
     * @default
     */
	WIN_SOUND_FILE: "res/Sounds/win_sound.mp3",
	/**
     * File path to loose sound file
     * @constant
     * @type {String}
     * @default
     */
	LOOSE_SOUND_FILE: "res/Sounds/loose_sound.mp3",

	/**
     * @default
     * @private
     * @type {Number}
     */
	_backgroundAudioID: -1,

	/**
     * Loads all needed data for pm.gameAudio.
     */
	load: function()
	{

	},

	/**
     * Common function to play audio from file.
     * @param {String} filePath A file path to sound file to play
     * @param {Boolean} loop Loop the audio
     * @param {Number} volume A start volume to play with
     * @private
     */
	_playAudioFile: function(filePath, loop, volume)
	{
		cc.director.getScheduler().performFunctionInCocosThread(
			function()
			{
				pm.playAudio(filePath, loop, volume);
			}
		);
	},

	/**
     * Plays background Music
     */
	playBackgroundMusic: function()
	{
		if(cc.sys.isNative)
		{
			var self = this;

			cc.director.getScheduler().performFunctionInCocosThread(
				function()
				{
					self._backgroundAudioID = pm.playAudio(self.BACKGROUND_AUDIO_FILE, true, pm.settings.getBackgroundVolume());
				}
			);
		}
		else
		{
			cc.audioEngine.playMusic(this.BACKGROUND_AUDIO_FILE, true);
			cc.audioEngine.setMusicVolume(pm.settings.getBackgroundVolume());
		}
	},
	/**
     * Sets background music volume
     * @param {Number} volume Volume value to set.
     */
	setBackgroundMusicVolume: function(volume)
	{
		if(cc.sys.isNative)
		{
			if(pm.getAudioState(this._backgroundAudioID) > 0 )
			{
				var self = this;

				cc.director.getScheduler().performFunctionInCocosThread(
					function()
					{
						pm.setAudioVolume(self._backgroundAudioID, volume);
					}
				);
			}
		}
		else
		{
			cc.audioEngine.setMusicVolume(volume);
		}
	},
	/**
     * Sets effect volume
     * @param {Number} volume  Volume value to set.
     */
	setEffectVolume: function(volume)
	{
		if(!cc.sys.isNative)
			cc.audioEngine.setEffectsVolume(volume);
	},
	/**
     * Plays loose sound
     */
	playLooseSound: function()
	{
		if(cc.sys.isNative)
			this._playAudioFile(this.LOOSE_SOUND_FILE, false, pm.settings.getEffectVolume());
		else
			cc.audioEngine.playEffect(this.LOOSE_SOUND_FILE, false);
	},
	/**
     * Plays win sound
     */
	playWinSound: function()
	{
		if(cc.sys.isNative)
			this._playAudioFile(this.WIN_SOUND_FILE, false, pm.settings.getEffectVolume());
		else
			cc.audioEngine.playEffect(this.WIN_SOUND_FILE, false);
	},
	/**
     * Plays audio from file
     * @param {String} filePath A file path to sound file to play
     */
	playSound: function(filePath)
	{
		if(cc.sys.isNative)
			this._playAudioFile(filePath, false, pm.settings.getEffectVolume());
		else
			cc.audioEngine.playEffect(filePath, false);
	}
};
