/**
 * Created by Nikita Besshaposhnikov on 09.04.15.
 */

/**
 * Types of {@link pm.data.PushMapElement}.
 * @enum {Number}
 * @readonly
 */
var PushMapElementType = {
	Grass: 0,
	BoxTarget: 1,
	BarrelTarget: 2,
	CommonTarget: 3
};

/**
 * @class Elements of {@link pm.data.PushMap}.
 * @implements pm.MapElement
 */
pm.data.PushMapElement = pm.MapElement4WithWalls.extend(/** @lends pm.data.PushMapElement# */{
	typeName: "PushMapElement",

	ctor: function ()
	{
		this._super();
	}
});
