/**
 * Created by Nikita Besshaposhnikov on 30.09.15.
 */

/**
 * @class This is a common interface for global robots.<br/>
 * This robot is created by level requirements and has data groups for level's robots.<br>
 * @interface
 * @extends pm.AbstractRobot
 */
pm.GlobalRobot = pm.AbstractRobot.extend(/** @lends pm.GlobalRobot */{

	_currentRobotID: -1,
	_currentRobotGroup: -1,

	/**
     * Sprite of robot.
     * @type {pm.ObjectSprite}
     */
	sprite: {},

	ctor: function()
	{
		this._super();
		this.id = this.getType();
	},

	/**
     * Virtual method for configure robot. <br/>
     * Called by {@link pm.globalRobotHelper}. Don't call by yourself.
     * @function
     * @param {Object} config
     * @name pm.GlobalRobot#configure
     */
	configure: function(config) {},

	executeNativeFunction: function(functionID, args)
	{
		this.incStepCount();
		if(this.nativeFunctionMap[functionID])
			if(args)
				this.nativeFunctionMap[functionID].execute(args);
			else
				this.nativeFunctionMap[functionID].execute();
	},

	/**
     * Plays animation of robot
     * @param animation
     * @param animationCallback
     * @param args
     * @param forced
     */
	playAnimation: function(animation, animationCallback, args, forced)
	{
		this.setStateFlag(pm.RobotState.PlayingAnimation);

		if(!CORE_BUILD && (!pm.settings.isAnimationDisabled() || forced))
		{
			if(this.sprite instanceof pm.ObjectSprite2D)
				this.sprite.playAnimation(animationCallback, this, animation, args);
		}
		else if(CORE_BUILD && animationCallback)
		{
			animationCallback.call(this);
		}
		else if(animationCallback)
		{
			setTimeout(animationCallback.bind(this), 0);
		}
	},

	/**
     * Updates sprite of robot
     */
	updateSprite: function() {},

	/**
     * Generates robot sprite
     * @function
     * @name pm.GlobalRobot#generateRobotSprite
     * @returns {pm.ObjectSprite}
     */
	generateRobotSprite: function() {},

	getState: function (){},

	setState: function (state){}
});
