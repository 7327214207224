/**
 * Created by Kirill Mashchenko on 19.07.17.
 */

/**
 * Possible robot's courses on light maps.
 * @see SquarableMap
 * @readonly
 * @enum {Number}
 */
var LightRobotCourse = {
	Left: "left",
	Right: "right",
	None: "none"
};

/**
 * * @class 2D robot for {@link pm.data.LightLevel}.
 * @implements PlayerRobot4
 */

pm.data.LightRobot = pm.PlayerRobot2D.extend(/** @lends pm.data.LightRobot# */{
	typeName: "LightRobot",

	_turnLamps: [],
	_beginDirection: null,
	_wasTurn: false,
	_breakFlag: false,
	_stopPoint: false,
	_beginInverseDirections: [],
	_instrStack: [],
	_countLoops: -1,

	ctor: function()
	{
		this._turnLamps = [];
		this._beginInverseDirections = [];
		this._instrStack = [];
		this._super();
		this.countLoops = -1;

		this.nativeFunctionMap[pm.data.LightRobot.NativeMethod.Left] = new pm.NativeFunction(this, this._left);
		this.nativeFunctionMap[pm.data.LightRobot.NativeMethod.Right] = new pm.NativeFunction(this, this._right);
		this.nativeFunctionMap[pm.data.LightRobot.NativeMethod.TurnOn] = new pm.NativeFunction(this, this._turnOn);
		this.nativeFunctionMap[pm.data.LightRobot.NativeMethod.TurnOff] = new pm.NativeFunction(this, this._turnOff);

		this.nativeFunctionMap[pm.data.LightRobot.NativeMethod.LeftReverse] = new pm.NativeFunction(this, this._leftReverse);
		this.nativeFunctionMap[pm.data.LightRobot.NativeMethod.RightReverse] = new pm.NativeFunction(this, this._rightReverse);

		this.nativeFunctions.push(
			pm.data.LightRobot.NativeMethod.Left, pm.data.LightRobot.NativeMethod.Right,
			pm.data.LightRobot.NativeMethod.TurnOn, pm.data.LightRobot.NativeMethod.TurnOff
		);

	},

	getDirectionCount: function () { return 8; },

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new LightRobotSprite();

			return this.sprite;
		}
	},

	_endAnimate: function()
	{
		if(!CORE_BUILD)
			this.clearStateFlag(pm.RobotState.PlayingAnimation);

		if (this._breakFlag)
		{
			this.setStateFlag(pm.RobotState.Broken);
			this._breakFlag = false;
		}
	},

	_moveLoop: function(course, inverse)
	{
		this._stopPoint = false;
		var beginDirection, endDirection;
		var k = 0;

		if (inverse)
			this.direction = (this.direction + 4 + 8) % 8;

		var map = this.getMap();

		var wayPeace = [];

		if (inverse)
		{
			wayPeace.push((this.direction + 4 + 8) % 8);

			var invShift = cc.pAdd(this._getShift(this.direction), this.position);
			var invType = map.element(invShift).getType();
			this._insertStraightPeace(wayPeace, invShift, invType);
		}
		else
		{ wayPeace.push(this.direction); }

		this._breakFlag = false;

		while (!this._stopPoint && !this._breakFlag)
		{
			switch (course)
			{
				case LightRobotCourse.Left:
					beginDirection = this.direction + 2;
					endDirection = this.direction - 2;
					k = -1;
					break;
				case LightRobotCourse.Right:
					beginDirection = this.direction - 2;
					endDirection = this.direction + 2;
					k = 1;
					break;
			}

			this._breakFlag = true;

			for (var i = beginDirection; i !== endDirection + k; i += k)
			{
				var tryDirection = (i + 8) % 8;
				var shift = cc.pAdd(this._getShift(tryDirection), this.position);

				if (shift.x >= map.width || shift.y >= map.height || shift.x < 0 || shift.y < 0)
					continue;

				var curType = map.element(shift).getType();

				if (curType === LightMapElementType.Road || curType === LightMapElementType.StopPoint)
				{
					if (this.direction === tryDirection)
					{
						if (this.direction % 2 !== 0)
						{
							var tryDir = (this.direction + k + 8) % 8;
							var sh = cc.pAdd(this._getShift(tryDir), this.position);

							if (sh.x >= map.width || sh.y >= map.height || sh.x < 0 || sh.y < 0)
								continue;

							var currentType = map.element(sh).getType();

							if (currentType === LightMapElementType.Road || currentType === LightMapElementType.StopPoint)
							{
								if (this._insertTurnPeace(wayPeace, tryDir, inverse))
									break;
							}
							else if (this._insertStraightPeace(wayPeace, shift, curType))
							{ break; }
						}
						else if (this._insertStraightPeace(wayPeace, shift, curType))
						{ break; }
					}
					else if ((Math.abs((tryDirection - this.direction) % 8) === 1 ||
                        Math.abs((tryDirection - this.direction) % 8) === 7) &&
                        (tryDirection % 2 !== 0))
					{
						var straightShift = cc.pAdd(this._getShift(this.direction), this.position);
						var straightType = map.element(straightShift).getType();

						if (straightType === LightMapElementType.Road || straightType === LightMapElementType.StopPoint)
						{
							if (this._insertStraightPeace(wayPeace, straightShift, straightType))
								break;
						}
					}

					if (!this._wasTurn)
					{
						if (this._insertTurnPeace(wayPeace, tryDirection, inverse))
							break;
					}
				}
			}
		}

		if (inverse)
		{
			if (this._beginInverseDirections[this._countLoops] !== (this.direction + 4 + 8) % 8)
			{
				wayPeace.push(this._beginInverseDirections[this._countLoops]);
				this.direction = this._beginInverseDirections[this._countLoops];
			}
			else
			{
				this.direction = this._beginInverseDirections[this._countLoops];
			}

			this._countLoops--;
			this._beginInverseDirections.pop();
		}

		if(!CORE_BUILD)
			this.playAnimation(LightRobotAnimation.Play, this._endAnimate, wayPeace);
	},

	_insertTurnPeace: function (wayPeace, direction, inverse)
	{
		if ((typeof wayPeace[wayPeace.length - 1]) === "number")
			wayPeace.push(this.position);

		if (inverse)
			wayPeace.push((direction + 4 + 8) % 8);
		else
			wayPeace.push(direction);

		this.direction = direction;
		this._breakFlag = false;
		this._wasTurn = true;

		return true;
	},

	_insertStraightPeace: function (wayPeace, shift, type)
	{
		var map = this.getMap();

		if ((typeof wayPeace[wayPeace.length - 1]) === "object")
			wayPeace.pop();

		wayPeace.push(shift);

		this.position = shift;
		this._breakFlag = false;
		this._wasTurn = false;

		if (type === LightMapElementType.StopPoint)
			this._stopPoint = true;

		map.element(this.position).addRobot(this);

		return true;
	},

	_left: function()
	{
		this._lastAnimation = LightRobotAnimation.Move;

		this._beginInverseDirections.push(this.direction);
		this._countLoops++;
		this._instrStack.push(this._leftReverse);

		this._moveLoop(LightRobotCourse.Left, false);
	},

	_right: function()
	{
		this._lastAnimation = LightRobotAnimation.Move;

		this._beginInverseDirections.push(this.direction);
		this._countLoops++;
		this._instrStack.push(this._rightReverse);

		this._moveLoop(LightRobotCourse.Right, false);
	},

	_leftReverse: function ()
	{
		var map = this.getMap();
		var type = map.element(this.position).getType();

		if (type === LightMapElementType.StopPoint)
		{
			if (this.isBroken())
			{
				this.clearStateFlag(pm.RobotState.Broken);
				this._countLoops--;
				this._beginInverseDirections.pop();
				return;
			}
		}

		if (this.isBroken())
			this.clearStateFlag(pm.RobotState.Broken);

		this._moveLoop(LightRobotCourse.Right, true);
	},

	_rightReverse: function ()
	{
		var map = this.getMap();
		var type = map.element(this.position).getType();

		if (type === LightMapElementType.StopPoint)
		{
			if (this.isBroken())
			{
				this.clearStateFlag(pm.RobotState.Broken);
				this._countLoops--;
				this._beginInverseDirections.pop();
				return;
			}
		}

		if (this.isBroken())
			this.clearStateFlag(pm.RobotState.Broken);

		this._moveLoop(LightRobotCourse.Left, true);
	},

	_turnOn: function ()
	{
		this._lastAnimation = LightRobotAnimation.TurnToLamp;

		this._changeLamp(true);
		this._instrStack.push(this._turnOnReverse);
		this.oneTurnToOnLamp();
	},

	_turnOff: function ()
	{
		this._lastAnimation = LightRobotAnimation.TurnToLamp;

		this._changeLamp(false);
		this._instrStack.push(this._turnOffReverse);
		this.oneTurnToOffLamp();
	},

	_turnOnReverse: function ()
	{
		this._changeLamp(false);
		this.oneTurnToOffLamp();
	},

	_turnOffReverse: function ()
	{
		this._changeLamp(true);
		this.oneTurnToOnLamp();
	},

	_changeLamp: function (condition)
	{
		this._turnLamps = [];
		var map = this.getMap();
		this._beginDirection = this.direction;

		var x = this.position.x, y = this.position.y;

		var x1 = x > 0 ? x - 1 : x;
		var x2 = x < map.width - 1 ? x + 1 : x;
		var y1 = y > 0 ? y - 1 : y;
		var y2 = y < map.height - 1 ? y + 1 : y;

		for (var i = x1; i <= x2; ++i)
		{
			for (var j = y1; j <= y2; ++j)
			{
				var el = map.element(cc.p(i, j));

				for (var ob in el._objectsHere)
				{
					if (el._objectsHere[ob].getType() === pm.LightLevelModule.Lamp)
					{
						if (el._objectsHere[ob].isLightened() === condition || el._objectsHere[ob].countIgnitions > 1)
						{
							if (condition === true)
								el._objectsHere[ob].countIgnitions++;
							else
								el._objectsHere[ob].countIgnitions--;

							continue;
						}

						if (condition === true)
							el._objectsHere[ob].countIgnitions++;
						else
							el._objectsHere[ob].countIgnitions--;

						this._turnLamps.push({
							dir: this.getDirectionLamp(this.position, cc.p(i, j)),
							lamp: el._objectsHere[ob]
						});
					}
				}
			}
		}

		var compare = this.compareEl;
		this._turnLamps.sort(compare.bind(this));
	},

	compareEl: function (a, b)
	{
		var da = (this.direction - a.dir + 8) % 8;
		var db = (this.direction - b.dir + 8) % 8;

		return da - db;
	},

	getDirectionLamp: function (pos, point)
	{
		if (point.y === pos.y)
			return -2 * (point.x - pos.x) + 4;
		if (point.x === pos.x)
			return 2 * (pos.y - point.y) + 2;
		if (point.y === pos.y - 1)
			return -(point.x - pos.x) + 4;
		if (point.y === pos.y + 1)
			return -3 * (point.x - pos.x) + 4;
	},

	oneTurnToOnLamp: function ()
	{
		if (this._turnLamps.length === 0)
		{
			var oldDirection = this.direction;
			this.direction = this._beginDirection;

			this.playAnimation(LightRobotAnimation.TurnToLamp, this._endAnimate.bind(this), [oldDirection, this._beginDirection]);
		}
		else
		{
			var direction = this._turnLamps[this._turnLamps.length - 1].dir;
			var oldDirection = this.direction;

			this.direction = direction;
			this.playAnimation(LightRobotAnimation.TurnToLamp, this.turnOnOneLamp.bind(this), [oldDirection, direction]);
		}
	},

	turnOnOneLamp: function ()
	{
		var lamp = this._turnLamps[this._turnLamps.length-1].lamp;
		this._turnLamps.pop();

		lamp.turnOn(this.oneTurnToOnLamp.bind(this));
	},

	oneTurnToOffLamp: function ()
	{
		if (this._turnLamps.length === 0)
		{
			var oldDirection = this.direction;
			this.direction = this._beginDirection;

			this.playAnimation(LightRobotAnimation.TurnToLamp, this._endAnimate.bind(this), [oldDirection, this._beginDirection]);
		}
		else
		{
			var direction = this._turnLamps[this._turnLamps.length - 1].dir;
			var oldDirection = this.direction;

			this.direction = direction;
			this.playAnimation(LightRobotAnimation.TurnToLamp, this.turnOffOneLamp.bind(this), [oldDirection, direction]);
		}
	},

	turnOffOneLamp: function ()
	{
		var lamp = this._turnLamps[this._turnLamps.length-1].lamp;
		this._turnLamps.pop();

		lamp.turnOff(this.oneTurnToOffLamp.bind(this));
	},

	_getShift: function(direction)
	{
		switch(direction)
		{
			case LightDirection.Up: return cc.p(0, -1);
			case LightDirection.UpLeft: return cc.p(-1, -1);
			case LightDirection.Left: return cc.p(-1, 0);
			case LightDirection.DownLeft: return cc.p(-1, 1);
			case LightDirection.Down: return cc.p(0, 1);
			case LightDirection.DownRight: return cc.p(1, 1);
			case LightDirection.Right: return cc.p(1, 0);
			case LightDirection.UpRight: return cc.p(1, -1);
			default:
				cc.log("bad light map");
				break;
		}
	},

	getType: function()
	{
		return pm.LightLevelModule.RobotType;
	},

	setState: function (state)
	{
		var func = this._instrStack[this._instrStack.length - 1];
		this._instrStack.pop();
		func.call(this);
	}
});

pm.data.LightRobot.NativeMethod =
{
	Left: "light_turn_left",
	Right: "light_turn_right",
	TurnOn: "light_turn_on",
	TurnOff: "light_turn_off",
	LeftReverse: "light_turn_left_reverse",
	RightReverse: "light_turn_right_reverse"
};
