
pm.ControlledNetworkGame = cc.Class.extend({
	_pendingGameIndex: 0,
	_pendingLevelIndex: 0,
	_pendingLevelObject: 0,
	_pendingProgramMap: null,
	_robotIndexMap: null,

	_waitLayer: null,

	_gameClient: null,
	_studentClient: null,
	_gameServer: null,

	_teacherHost: 0,
	_connectedHost: 0,

	_reconnecting: false,
	_started: false,
	_inGame: false,
	_isServer: false,

	_stopCallback: null,

	_robotIndex: -1,
	_solvingTimes: null,

	_currentLevelState: null,

	ctor: function(stopCallback)
	{
		this._stopCallback = stopCallback;

		pm.registerCustomEventListener(pm.GAME_ON_HIDE_EVENT, this._gameOnHide.bind(this), 1);
	},

	start: function(teacherHost)
	{
		this._teacherHost = teacherHost;

		if(!this._inGame)
		{
			this._waitLayer = new WaitNetworkGameLayer();
			this._waitLayer.show();

			cc.director.getScheduler().schedule(this._cleanup, this, 0, 1, pm.ControlledNetworkGame.WAIT_TIMEOUT, false, "stopWait");
		}

		if(this._studentClient && this._studentClient.isConnected())
		{
			this._studentClient.clearCallbacks();
			this._studentClient.disconnect();
		}

		this._studentClient = new pm.StudentClientImpl();

		this._studentClient.setEventCallback(this._teacherEventCallback.bind(this));
		this._studentClient.setPacketCallback(this._teacherPacketCallback.bind(this));

		this._studentClient.connect(this._teacherHost, pm.TEACHER_SERVER_PORT);
	},

	sendReadyForNetworkGame: function(ready)
	{
		if(this._gameClient)
			this._gameClient.sendReadyForNetworkGame(ready);
	},

	_gameOnHide: function()
	{
		pm.networkUtils.log("Received event for hide game. Stopping network activity", pm.NetworkDebugSendTypes.OTHER);

		this._cleanup();
	},

	_teacherEventCallback: function(event)
	{
		switch(Number(event))
		{
			case pm.GameClient.CONNECTED_EVENT:
				pm.networkUtils.log("Student client connected to server", pm.NetworkDebugSendTypes.STUDENT_CLIENT);

				this._studentClient.sendStatus(this._gameClient !== null && this._gameClient.isConnected(), this._currentLevelState);

				if (this._gameServer)
					this._studentClient.sendRobotIndexMap(this._gameServer.getRobotIndexMap());

				if (world.runningLevel instanceof pm.AbstractLevel)
					this._studentClient.sendProgram();

				if (this._solvingTimes)
					this._studentClient.sendTimeInfoToTeacher(this._solvingTimes);

				break;

			case pm.GameClient.DISCONNECTED_EVENT:
				pm.networkUtils.log("Student client disconnected from server", pm.NetworkDebugSendTypes.STUDENT_CLIENT);

				if (world.runningLevel instanceof pm.AbstractLevel)
					this._studentClient.sendProgram();

				break;
		}
	},

	_gameClientEventCallback: function(event)
	{
		switch(Number(event))
		{
			case pm.GameClient.CONNECTED_EVENT:
				this._reconnecting = false;

				if(this._studentClient)
				{
					this._studentClient.sendStatus(true, this._currentLevelState);

					if (world.runningLevel instanceof pm.AbstractLevel)
						this._studentClient.sendProgram();
				}

				pm.networkUtils.log("Game client connected to server", pm.NetworkDebugSendTypes.STUDENT_CLIENT);
				break;

			case pm.GameClient.DISCONNECTED_EVENT:
				if(!this._reconnecting && this._studentClient)
				{
					this._studentClient.sendStatus(false, this._currentLevelState);

					if (world.runningLevel instanceof pm.AbstractLevel)
						this._studentClient.sendProgram();
				}

				pm.networkUtils.log("Game client disconnected from server", pm.NetworkDebugSendTypes.STUDENT_CLIENT);
				break;
		}
	},

	_teacherPacketCallback: function(packet)
	{
		var parsedPacket = undefined;

		try
		{
			parsedPacket = JSON.parse(packet);
		}
		catch (e)
		{
			pm.networkUtils.log("Incorrect packet from teacher server: " + packet, pm.NetworkDebugSendTypes.STUDENT_CLIENT, packet);
			return;
		}

		if(parsedPacket !== null && !parsedPacket.type)
		{
			pm.networkUtils.log("Incorrect packet from teacher server(no type field): " + packet, pm.NetworkDebugSendTypes.STUDENT_CLIENT, packet);
			return;
		}

		switch(parsedPacket.type)
		{
			case pm.TEACHER_SERVER_PACKET_TYPE.START_SERVER:
				pm.networkUtils.log("Received start server packet from teacher", pm.NetworkDebugSendTypes.STUDENT_CLIENT, parsedPacket);

				this._isServer = true;

				this._startGameAsServer(parsedPacket.game, parsedPacket.level, parsedPacket.levelObject, parsedPacket.playerCount, parsedPacket.programMap, parsedPacket.robotIndexMap);

				break;
			case pm.TEACHER_SERVER_PACKET_TYPE.BECOME_SERVER:
				pm.networkUtils.log("Received become server packet from teacher", pm.NetworkDebugSendTypes.STUDENT_CLIENT, parsedPacket);

				this._isServer = true;

				this._becomeServer(parsedPacket.playerCount, parsedPacket.robotIndexMap);

				break;
			case pm.TEACHER_SERVER_PACKET_TYPE.CONNECT_TO_SERVER:
				pm.networkUtils.log("Received start client packet from teacher", pm.NetworkDebugSendTypes.STUDENT_CLIENT, parsedPacket);

				this._startGameAsClient(parsedPacket.host, parsedPacket.game, parsedPacket.level, parsedPacket.levelObject, parsedPacket.programMap, parsedPacket.robotIndexMap);

				break;
			case pm.TEACHER_SERVER_PACKET_TYPE.CHANGE_LEVEL:
				pm.networkUtils.log("Received change level packet from teacher", pm.NetworkDebugSendTypes.STUDENT_CLIENT, parsedPacket);

				if (this._gameServer)
					this._gameServer.cleanReadyFlag();

				this._changeLevel(parsedPacket.game, parsedPacket.level, parsedPacket.levelObject);

				break;
			case pm.TEACHER_SERVER_PACKET_TYPE.RECONNECT_TO_SERVER:
				pm.networkUtils.log("Received reconnect packet from teacher to host: " + parsedPacket.host, pm.NetworkDebugSendTypes.STUDENT_CLIENT, parsedPacket);

				this._reconnectToNewServer(parsedPacket.host);

				break;
			case pm.TEACHER_SERVER_PACKET_TYPE.STATUS_REQUEST:
				pm.networkUtils.log("Received request status packet from teacher", pm.NetworkDebugSendTypes.STUDENT_CLIENT, parsedPacket);

				break;
			case pm.TEACHER_SERVER_PACKET_TYPE.STOP_GAME:
				pm.networkUtils.log("Received stop game from teacher", pm.NetworkDebugSendTypes.STUDENT_CLIENT, parsedPacket);
				this._cleanup();

				if(this._stopCallback)
					this._stopCallback();

				var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new StartMenuScene());
				cc.director.runScene(trans);

				break;
			case pm.TEACHER_SERVER_PACKET_TYPE.TIME_INFO:
				pm.networkUtils.log("Received time info from teacher", pm.NetworkDebugSendTypes.STUDENT_CLIENT, parsedPacket);

				if (parsedPacket.solvingTimes)
					this._solvingTimes = parsedPacket.solvingTimes;

				break;
			default:
				pm.networkUtils.log("Unknown packet type: " + parsedPacket.type, pm.NetworkDebugSendTypes.STUDENT_CLIENT, parsedPacket);

		}
	},

	isServer: function()
	{
		return this._gameServer !== null;
	},

	isClient: function()
	{
		return this._gameServer === null && this._gameClient !== null;
	},

	getGameIndex: function()
	{
		return this._pendingGameIndex;
	},

	getLevelIndex: function()
	{
		return this._pendingLevelIndex;
	},

	getConnectedHost: function()
	{
		return this._connectedHost;
	},

	_hideWaitLayer: function()
	{
		if(this._waitLayer)
		{
			this._waitLayer.remove();
			this._waitLayer = null;
		}
	},

	_showWaitLayer: function()
	{
		if(!this._waitLayer)
		{
			this._waitLayer = new WaitNetworkGameLayer();
			this._waitLayer.show();
		}
	},

	_cleanup: function ()
	{
		this._stopGameClient();
		this._stopGameServer();
		this._stopStudentClient();

		this._hideWaitLayer();

		this._started = false;
		this._isServer = false;
		this._inGame = false;
	},

	_startGameAsServer: function(game, level, levelObject, playerCount, programMap, robotIndexMap)
	{
		if(this._gameServer)
			return;

		this._pendingGameIndex = game;
		this._pendingLevelIndex = level;
		this._pendingLevelObject = levelObject;
		this._pendingProgramMap = programMap;
		this._robotIndexMap = robotIndexMap;

		this._showWaitLayer();

		this._startGameServer(playerCount, robotIndexMap);
		this._startGameClient(pm.LOCAL_ADDRESS, pm.GAME_SERVER_PORT);
	},

	_startGameAsClient: function(host, game, level, levelObject, programMap, robotIndexMap)
	{
		if(this._gameServer || this._gameClient)
			return;

		this._pendingGameIndex = game;
		this._pendingLevelIndex = level;
		this._pendingLevelObject = levelObject;
		this._pendingProgramMap = programMap;
		this._robotIndexMap = robotIndexMap;

		this._showWaitLayer();

		this._startGameClient(host, pm.GAME_SERVER_PORT);
	},

	_changeLevel: function(game, level, levelObject)
	{
		this._pendingGameIndex = game;
		this._pendingLevelIndex = level;
		this._pendingLevelObject = null;

		this._currentLevelState = pm.NetworkGameState.Develop;

		var o = new pm.Class();

		var trans = new cc.TransitionFade(
			1.5 * pm.SYSTEM_ANIMATION_DELAY,
			new LevelScene(
				GameType.Net,
				this._robotIndex,
				this._pendingGameIndex,
				this._pendingLevelIndex,
				o.deserialize(levelObject, true)
			)
		);

		cc.director.runScene(trans);
	},

	_becomeServer: function(playerCount, robotIndexMap)
	{
		this._reconnecting = true;
		this._showWaitLayer();

		this._stopGameServer();
		this._stopGameClient();

		this._startGameServer(playerCount, robotIndexMap);
		this._startGameClient(pm.LOCAL_ADDRESS, pm.GAME_SERVER_PORT);
	},

	_reconnectToNewServer: function(host)
	{
		this._reconnecting = true;
		this._showWaitLayer();

		if(this._gameClient && this._gameClient.isConnected())
		{
			this._gameClient.clearCallbacks();
			this._gameClient.disconnect();
		}

		this._gameClient = null;

		this._startGameClient(host, pm.GAME_SERVER_PORT);
	},

	_startGameServer: function(playerCount, robotIndexMap)
	{
		if(!this._gameServer)
			this._gameServer = new pm.GameServerImpl();

		this._gameServer.setPlayersConnectedCallback(this._serverClientsConnectedCallback.bind(this));
		this._gameServer.setPlayersCount(playerCount);

		pm.networkUtils.log("Start robot index map: " + JSON.stringify(robotIndexMap), pm.NetworkDebugSendTypes.GAME_SERVER);

		if (robotIndexMap)
			this._gameServer.setRobotIndexMap(robotIndexMap);

		this._gameServer.start(pm.GAME_SERVER_PORT);
	},

	_startGameClient: function(host, port)
	{
		if(!this._gameClient)
			this._gameClient = new pm.GameClientImpl();

		this._gameClient.setLoadLevelCallback(this._loadLevelCallback.bind(this));
		this._gameClient.setEventCallback(this._gameClientEventCallback.bind(this));

		this._gameClient.connect(host, port);
		this._connectedHost = host;
	},

	_serverClientsConnectedCallback: function()
	{
		pm.networkUtils.log("All connected to server", pm.NetworkDebugSendTypes.GAME_SERVER);
	},

	_loadLevelCallback: function(robotIndex)
	{
		pm.networkUtils.log("All connected to server", pm.NetworkDebugSendTypes.GAME_CLIENT);

		this._robotIndex = robotIndex;

		this._hideWaitLayer();

		if (this._gameServer)
			this._studentClient.sendRobotIndexMap(this._gameServer.getRobotIndexMap());

		if(!this._inGame && this._pendingLevelObject)
		{
			// pm.networkUtils.log("Program map: "+JSON.stringify(this._pendingProgramMap), pm.NetworkDebugSendTypes.GAME_CLIENT);
			pm.networkUtils.log("Robot index map: "+JSON.stringify(this._robotIndexMap), pm.NetworkDebugSendTypes.GAME_CLIENT);

			if (this._pendingProgramMap && this._robotIndexMap)
			{
				for (var host in this._robotIndexMap)
				{
					if (this._pendingProgramMap[host])
					{
						var data = pm.appUtils.generateProgramData(true);
						data.deserialize(this._pendingProgramMap[host], false);

						this._pendingLevelObject.programData[this._robotIndexMap[host]] = data;
					}
				}
			}

			// pm.networkUtils.log("Level: "+JSON.stringify(this._pendingLevelObject), pm.NetworkDebugSendTypes.GAME_CLIENT);

			cc.director.getScheduler().unschedule("stopWait", this);

			var o = new pm.Class();

			var trans = new cc.TransitionFade(
				1.5 * pm.SYSTEM_ANIMATION_DELAY,
				new LevelScene(
					GameType.Net,
					robotIndex,
					this._pendingGameIndex,
					this._pendingLevelIndex,
					o.deserialize(this._pendingLevelObject, true)
				)
			);

			cc.director.runScene(trans);

			this._pendingProgramMap = null;
			this._robotIndexMap = null;
			this._pendingLevelObject = null;

			pm.registerCustomEventListener(pm.ROBOT_WIN_EVENT_STR, this._onWin.bind(this), 10);
			pm.registerCustomEventListener(pm.ROBOT_LOOSE_EVENT_STR, this._onLoose.bind(this), 10);
			pm.registerCustomEventListener(pm.ROBOT_FAILURE_EVENT_STR, this._onFailure.bind(this), 10);
			pm.registerCustomEventListener(pm.PROGRAM_START_EVENT_STR, this._startLevel.bind(this), 10);
			pm.registerCustomEventListener(pm.USER_PROGRAM_UPDATED_STR, this._programChangedCallback.bind(this), 10);
			pm.registerCustomEventListener(pm.READY_TO_CHANGE_LEVEL_STR, this._onReadyToChangeLevel.bind(this), 10);
			pm.registerCustomEventListener(pm.LEVEL_SCENE_ENTERED, this._onLevelSceneEntered.bind(this), 10);

			this._inGame = true;
		}
	},

	_startLevel: function()
	{
		if(!this._studentClient)
			return;

		this._currentLevelState = pm.NetworkGameState.ProcessingLevel;

		this._studentClient.sendCurrentLevelStatus(pm.NetworkGameState.ProcessingLevel);
	},

	_onWin: function()
	{
		if(!this._studentClient)
			return;

		this._currentLevelState = pm.NetworkGameState.Win;

		this._studentClient.sendCurrentLevelStatus(pm.NetworkGameState.Win);
	},

	_onFailure: function()
	{
		if(!this._studentClient)
			return;

		this._currentLevelState = pm.NetworkGameState.Loose;

		this._studentClient.sendCurrentLevelStatus(pm.NetworkGameState.Loose);
	},

	_onLoose: function()
	{
		if(!this._studentClient)
			return;

		this._currentLevelState = pm.NetworkGameState.Broken;

		this._studentClient.sendCurrentLevelStatus(pm.NetworkGameState.Broken);
	},

	_onReadyToChangeLevel: function()
	{
		if(!this._studentClient)
			return;

		this._studentClient.sendCurrentLevelStatus(pm.NetworkGameState.ReadyToChangeLevel);
	},

	_onLevelSceneEntered: function()
	{
		if(!this._studentClient)
			return;

		this._studentClient.sendLevelSceneEntered();
	},

	_programChangedCallback: function()
	{
		if(!this._studentClient)
			return;

		this._currentLevelState = pm.NetworkGameState.Develop;

		this._studentClient.sendCurrentLevelStatus(pm.NetworkGameState.Develop);

        if (world.runningLevel instanceof pm.AbstractLevel)
            this._studentClient.sendProgram();
    },

	_stopGameClient: function()
	{
		if(this._gameClient)
		{
			this._gameClient.clearCallbacks();
			this._gameClient.disconnect();
			this._gameClient = null;
		}
	},

	_stopGameServer: function()
	{
		if(this._gameServer)
		{
			this._gameServer.stop();
			this._gameServer = null;
		}
	},

	_stopStudentClient: function()
	{
		if(this._studentClient)
		{
			this._studentClient.clearCallbacks();
			this._studentClient.disconnect();
			this._studentClient = null;
		}
	}
});

pm.ControlledNetworkGame.WAIT_TIMEOUT = 20;
