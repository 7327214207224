/**
 * Created by Nikita Besshaposhnikov on 23.07.15.
 */

/**
 * @class Colored layout with circle bounds.
 * @extends ccui.Layout
 * @constructor
 * @param {cc.Size} [size] Size of layer.
 * @param {Number} [borderRadius=pmui.OvalLayout.STANDARD_BORDER_RADIUS] Border radius.
 */
pmui.OvalLayout = ccui.Layout.extend(/** @lends pmui.OvalLayout# */{

	_borderRadius: 0,

	_gradientLayer: null,

	ctor: function(size, borderRadius, type)
	{
		this._super();

		//because of new design
		this.setLayoutType(ccui.Layout.RELATIVE);

		if (type === undefined)
			type = pmui.OvalLayout.Type.THIN;

		if(borderRadius === undefined || borderRadius === null || borderRadius === 0)
			borderRadius = pmui.OvalLayout.STANDARD_BORDER_RADIUS;

		this._borderRadius = borderRadius;

		var image = "";
		if (type === pmui.OvalLayout.Type.SLIM)
		{
			image = "System/interfaceSmallBG.png";
			this._borderRadius = 2;
			this.setBackGroundImageCapInsets(cc.rect(14, 14, 14, 14));
		}
		else
		{
			image = "System/interfaceBG.png";
			this.setBackGroundImageCapInsets(cc.rect(89, 89, 72, 72));
		}

		this._gradientLayer = new ccui.Layout();
		this._gradientLayer.setContentSize(this.width - 2 * this._borderRadius, this.height - 2 * this._borderRadius);
		this._gradientLayer.setBackGroundColorType(ccui.Layout.BG_COLOR_GRADIENT);
		this._gradientLayer.setBackGroundColorVector(cc.p(0, -1));
		this._gradientLayer.setBackGroundColor(pmui.OvalLayout.GRAD_START_COLOR, pmui.OvalLayout.GRAD_END_COLOR);

		var gradientLayerAlign = new ccui.RelativeLayoutParameter();
		gradientLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		gradientLayerAlign.setRelativeName("gradient");
		gradientLayerAlign.setMargin(this._borderRadius, this._borderRadius, 0, 0);
		this._gradientLayer.setLayoutParameter(gradientLayerAlign);

		this.addChild(this._gradientLayer, -1);

		this.setBackGroundImage(image);
		this.setBackGroundImageScale9Enabled(true);
		this.setCascadeOpacityEnabled(true);

		if(size)
			this.setContentSizeWithBorder(size);
	},

	setContentSizeWithBorder: function(size, height)
	{
		var width = size;
		if (height === undefined)
		{
			width = size.width + 2 * this._borderRadius;
			height = size.height + 2 * this._borderRadius;
		}
		else
		{
			width += 2 * this._borderRadius;
			height += 2 * this._borderRadius;
		}

		this.setContentSize(width, height);

		this._gradientLayer.setContentSize(this.width - 2 * this._borderRadius, this.height - 2 * this._borderRadius);
	}
});

/**
 * Standard border radius.
 * @const
 * @default
 * @type {Number}
 */
pmui.OvalLayout.STANDARD_BORDER_RADIUS = 14;
pmui.OvalLayout.SLIM_BORDER_RADIUS = 2;
pmui.OvalLayout.GRAD_START_COLOR = cc.color(18, 145, 204);
pmui.OvalLayout.GRAD_END_COLOR = cc.color(99, 61, 165);
pmui.OvalLayout.Type = {
	SLIM: 0,
	THIN: 1
};
