/**
 * Created by Kirill Mashchenko on 23.09.2017.
 */

var ExtendedCounterLayer = GlobalRobotLayer.extend(/** @lends ExtendedCounterLayer# */{

	_level: null,
	_robot: null,

	ctor: function (level, robot, robotType)
	{
		this._super(LocalizedString("DoubleCounter"), robot, robotType);
		this._level = level;
		this._robot = robot;

		var check = new pmui.CheckBox(LocalizedString("UseCompare"), this._useCompare, this, 15, this._level.globalRobotConfig[pm.GlobalRobotType.ExtendedCounter].useCompareConditions);

		var checkAlign = new ccui.LinearLayoutParameter();
		checkAlign.setGravity(ccui.LinearLayoutParameter.TOP);
		checkAlign.setMargin(30, 10, 0, 0);
		check.setLayoutParameter(checkAlign);
		this.addChild(check);

		this.setContentSize(this.width, this.height + check.height + 10);
	},

	_useCompare: function (flag)
	{
		this._level.globalRobotConfig[pm.GlobalRobotType.ExtendedCounter].useCompareConditions = flag;
		this._robot.reconfigure(this._level.globalRobotConfig[pm.GlobalRobotType.ExtendedCounter]);

		pm.sendCustomEvent(pme.REDRAW_GLOBAL_ROBOT, this._robot);

	}
});
