/**
 * Created by antonded on 10.11.14.
 */

/**
 * @class Preview layer for certain map in level.
 * @extends ccui.Layout
 * @constructor
 * @param {Number | pm.AbstractLevel} game Index of game or level object.</br>
 * If level object specified second param not needed.
 * @param {Number} [level] Index of level.
 * @param {Number} width
 * @param {Number} height
 */
var LevelPreview = ccui.Layout.extend(/** @lends LevelPreview# */{
	_levelClass: null,
	_mapSprite: null,
	_width: 0,
	_height: 0,

	ctor: function (game, level, width, height)
	{
		this._super();

		this._width = width || LevelPreview.WIDTH;
		this._height = height || LevelPreview.HEIGHT;

		this.setBackGroundColorType(ccui.Layout.BG_COLOR_SOLID);
		this.setBackGroundColorOpacity(0);

		this.setContentSize(this._width, this._height);

		if (game && level === undefined)
			this._initWithPreview(game);

		else if (game !== undefined && level !== undefined)
			this._initWithGameAndLevel(game, level);
	},

	_initWithPreview: function (preview)
	{
		this._levelClass = preview;

		this._levelClass.load(true);

		if(this._levelClass !== null)
			this._loadLevelView();

	},

	_initWithGameAndLevel: function(game, level)
	{
		this._levelClass = world.loadLevel(true, game, level);

		if(this._levelClass !== null)
			this._loadLevelView();
	},

	_loadLevelView: function()
	{
		var activeMapLayer = this._levelClass.activeMap.generateMapLayer(true);

		var scale = activeMapLayer.width > activeMapLayer.height ?
			this._width / activeMapLayer.width :
			this._height / activeMapLayer.height;

		scale /= 1.2;

		if (activeMapLayer instanceof pm.MapLayer2D)
		{
			switch (activeMapLayer.getOrientation())
			{
				case pm.MapLayer2D.Orientation.Iso:
					break;
				case pm.MapLayer2D.Orientation.Ortho:
					scale /= Math.sqrt(2);
					break;
			}
		}
		else
		{
			scale *= 1.7;
		}

		activeMapLayer.setScale(scale);

		activeMapLayer.setAnchorPoint(cc.p());

		var delta = pm.moduleUtils.getLayerPositionDelta(this._levelClass.getType()); // для кузнечика

		activeMapLayer.setPosition((this._width - activeMapLayer.getBoundingBox().width) / 2 + delta.x * scale,
			(this._height - activeMapLayer.getBoundingBox().height) / 2 + delta.y * scale);

		var renderTexture = new cc.RenderTexture(this._width, this._height);

		if(!cc.sys.isNative)
			renderTexture.setVirtualViewport(cc.p(0, 0), cc.director.getWinSize(), cc.director.getWinSizeInPixels());

		this._beforeRenderToTextureBegin();
		renderTexture.begin();
		activeMapLayer.visit();
		renderTexture.end();
		this._afterRenderToTextureEnd();

		this._mapSprite = new cc.Sprite(renderTexture.getSprite().getTexture());

		//Magic for converting from OpenGL buffer rendering to cocos format
		this._mapSprite.setScaleY(-1);
		this._mapSprite.setPosition(this._width / 2, this._height / 2);

		this.addChild(this._mapSprite);
	},

	//TODO Temporary fix for correct Render cache state restore after render to texture.
	_beforeRenderToTextureBegin: function()
	{
		if(!cc.sys.isNative)
			this._cacheFlag = cc._renderType === cc.game.RENDER_TYPE_CANVAS ? cc.renderer._isCacheToCanvasOn : cc.renderer._isCacheToBufferOn;
	},

	_afterRenderToTextureEnd: function()
	{
		if(cc.sys.isNative)
			return;

		if(cc._renderType === cc.game.RENDER_TYPE_CANVAS)
			cc.renderer._isCacheToCanvasOn = this._cacheFlag;
		else
			cc.renderer._isCacheToBufferOn = this._cacheFlag;
	}
});

/**
 * Default width of {@link LevelPreview}
 * @const
 * @default
 * @type {Number}
 */
LevelPreview.WIDTH = 150;
/**
 * Default height of {@link LevelPreview}
 * @const
 * @default
 * @type {Number}
 */
LevelPreview.HEIGHT = 150;
