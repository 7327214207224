/**
 * Created by Nikita Besshaposhnikov on 11.11.14.
 */

/**
 * @class This task is used for last robot position checking.
 * @implements pm.Task
 * @constructor
 * @param {pm.AbstractLevel} level
 */
pm.data.PositionTask4 = pm.Task.extend(/**@lends pm.data.PositionTask4# */{
	typeName: "PositionTask4",

	ctor: function(level)
	{
		this._super(level);
	},

	getNotCompletedObjects: function (mapIndex)
	{
		var retArray = [];

		for(var id in this.data[mapIndex])
		{
			var point = this.data[mapIndex][id];

			if(!this._level.maps[mapIndex].element(point).containsRobotID(id))
				retArray.push(this._level.maps[mapIndex].element(point));
		}

		return retArray;
	},

	fillWithInitialData: function(mapIndex, data)
	{
		if(data === undefined)
			data = cc.p(2, 2);

		this.data[mapIndex] = {};

		for(var i = 0; i < this._level.robots.length; ++i)
			this.data[mapIndex][this._level.robots[i].id] = cc.clone(data);
	}

});
