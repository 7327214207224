/**
 * Created by Nikita Besshaposhnikov on 18.12.14.
 */

/**
 * Repair map element types.
 * @enum {Number}
 * @readonly
 */
var RepairMapElementType = {
	None: -1,
	LightBlueGrass: 0,
	BlueGrass: 1,
	BrokenGrass: 2,
	RepairedGrass: 3
};

/**
 * @class Elements for {@link pm.data.RepairMap}
 * @implements pm.MapElement
 */
pm.data.RepairMapElement = pm.MapElement4WithWalls.extend(/** @lends pm.data.RepairMapElement# */{
	typeName: "RepairMapElement",

	_repairCount: 0,

	ctor: function()
	{
		this._addNonEnumerableProps("_repairCount");
		this._super();
	},

	restore: function()
	{
		this._repairCount = 0;
		pm.MapElement.prototype.restore.call(this);
	},

	/**
     * Repairs element.
     */
	repair: function()
	{
		if(this._type === RepairMapElementType.LightBlueGrass)
			this.setType(RepairMapElementType.BlueGrass);
		else if(this._type === RepairMapElementType.BrokenGrass)
			this.setType(RepairMapElementType.RepairedGrass);

		++this._repairCount;
	},

	/**
     * Returns element to broken state.
     */
	disrepair: function()
	{
		--this._repairCount;

		if(this._repairCount === 0)
		{
			if (this._type === RepairMapElementType.BlueGrass)
				this.setType(RepairMapElementType.LightBlueGrass);
			else if (this._type === RepairMapElementType.RepairedGrass)
				this.setType(RepairMapElementType.BrokenGrass);
		}
	},

	/**
     * Checks is element is repaired.
     * @returns {Boolean}
     */
	isRepaired: function()
	{
		return this.originalType === RepairMapElementType.BrokenGrass && this._type === RepairMapElementType.RepairedGrass;
	},

	isBadRepaired: function()
	{
		return this.originalType === RepairMapElementType.LightBlueGrass && this._type === RepairMapElementType.BlueGrass;
	},

	getState: function ()
	{
		return this._type;
	},

	setState: function (state)
	{
		this.setType(state);
	}
});
