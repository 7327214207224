/**
 * Created by Nikita Besshaposhnikov on 15.03.17.
 */

/**
 * This namespace contains functions for network features
 * @namespace
 *
 */
pm.networkUtils =
{
	_broadcastServerStarted: false,
	_broadcastClientStarted: false,

	_ignoreRequests: false,

	isNetworkGame: false,
	inGame: false,

	_networkGame: null,

	_networkDebugClient: null,

	init: function()
	{
		if(cc.sys.isNative && pm.appConfig.useNetworkFeatures)
		{
			this.startDebug();

			this._broadcastServerStarted = pm.broadcastServer.start();
			pm.networkUtils.log("Discover server started :" + this._broadcastServerStarted, pm.NetworkDebugSendTypes.OTHER);

			this._broadcastClientStarted = pm.broadcastClient.start();
			pm.networkUtils.log("Discover client started :" + this._broadcastClientStarted, pm.NetworkDebugSendTypes.OTHER);

			if(this._broadcastClientStarted)
			{
				pm.broadcastClient.setDataCallback(this._dataCallback.bind(this));
				pm.broadcastClient.setDiscoverCallback(this._onDiscoverRequest.bind(this));
			}

			pm.registerCustomEventListener(pm.GAME_ON_HIDE_EVENT, this._gameOnHide.bind(this), 1);
			pm.registerCustomEventListener(pm.GAME_ON_SHOW_EVENT, this._gameOnShow.bind(this), 1);
		}
	},

	startDebug: function()
	{
		var debugHost = pm.convertIPAddress(pm.appConfig.networkDebugAddress || pm.LOCAL_ADDRESS);

		if(this._networkDebugClient && this._networkDebugClient.isConnected())
		{
			this._networkDebugClient.clearCallbacks();
			this._networkDebugClient.disconnect();
		}

		this._networkDebugClient = new pm.NetworkDebugClientImpl();

		this._networkDebugClient.connect(debugHost, 18943);
	},

	log: function(message, sendType, packet, host)
	{
		if (sendType === undefined)
			sendType = -1;

		var username = "";

		if (pm.settings.getUserData().username !== "")
			username = pm.settings.getUserData().username;
		else
			username = pm.settings.getUserDeviceName();

		if (packet === undefined)
			packet = {};

		if (host === undefined)
			host = null;

		cc.log("PM Network: " + sendType +", "+ message+", host: "+host);

		var time = +new Date();

		if (this._networkDebugClient)
		{
			this._networkDebugClient.sendPacket(JSON.stringify({
				name: username,
				type: sendType,
				timestamp: time,
				message: message,
				packet: packet,
				host: host
			}))
		}
	},

	_gameOnHide: function()
	{
		pm.broadcastClient.setPaused(true);
	},

	_gameOnShow: function()
	{
		pm.broadcastClient.setPaused(false);
	},

	startDiscover: function(discoverServerCallback, priority)
	{
		if(!this._broadcastServerStarted)
			return;

		if(this._broadcastClientStarted)
			pm.broadcastClient.setHidden(true);

		if(priority === undefined)
			priority = pm.BROADCAST_PACKET_PRIORITY.LOW;

		pm.broadcastServer.setPacketPriority(priority);
		pm.broadcastServer.setDiscoverCallback(discoverServerCallback);

		pm.broadcastServer.startDiscover();
	},

	stopDiscover: function()
	{
		if(!this._broadcastServerStarted)
			return;

		if(this._broadcastClientStarted)
			pm.broadcastClient.setHidden(false);

		pm.broadcastServer.stopDiscover();
	},

	sendConnectRequest: function(host)
	{
		if(!this._broadcastServerStarted)
			return;

		pm.broadcastServer.sendPacket(host, "connect_request");
	},

	sendReadyForNetworkGame: function(ready)
	{
		if(!this._networkGame)
			return;

		this._networkGame.sendReadyForNetworkGame(ready);
	},

	_onDiscoverRequest: function (host, port)
	{
		var type = pm.BROADCAST_DATA_PACKET_TYPE.FREE;

		if(this._networkGame)
		{
			if (this._networkGame.isServer())
				type = pm.BROADCAST_DATA_PACKET_TYPE.SERVER;
			else if (this._networkGame.isClient())
				type = pm.BROADCAST_DATA_PACKET_TYPE.CLIENT;
		}

		var clientPacket = {};

		var username = "";

		if (pm.settings.getUserData().username !== "")
			username = pm.settings.getUserData().username;
		else
			username = pm.settings.getUserDeviceName();

		switch (type)
		{
			case pm.BROADCAST_DATA_PACKET_TYPE.CLIENT:
				clientPacket = {
					t: type,
					u: username,
					h: this._networkGame.getConnectedHost(),
					g: this._networkGame.getGameIndex(),
					l: this._networkGame.getLevelIndex()
				};

				break;
			case pm.BROADCAST_DATA_PACKET_TYPE.SERVER:
				clientPacket = {
					t: type,
					u: username,
					g: this._networkGame.getGameIndex(),
					l: this._networkGame.getLevelIndex()
				};

				break;
			case pm.BROADCAST_DATA_PACKET_TYPE.FREE:
				clientPacket = {
					t: type,
					u: username
				};

				break;
		}

		var clientSendPacket = JSON.stringify(clientPacket);

		pm.broadcastClient.sendPacket(host, port, pm.BROADCAST_PACKET_TYPE.DISCOVER_INFO, clientSendPacket);
	},

	_dataCallback: function(host, port, packet)
	{
		// if(this._ignoreRequests)
		//     return;
		//
		// this._ignoreRequests = true;

		var parsedPacket = JSON.parse(packet);

		// this._requestedHost = parsedPacket.sh;
		// this._requestedPort = pm.GAME_SERVER_PORT;

		switch (parsedPacket.t)
		{
			case pm.BROADCAST_DATA_PACKET_TYPE.CONNECT_TO_TEACHER_SERVER:

				if(!this._networkGame)
					this._networkGame = new pm.ControlledNetworkGame(this._netControlledGameStopped.bind(this));

				this._networkGame.start(host);

				break;

			case pm.BROADCAST_DATA_PACKET_TYPE.CLEAR_CACHE:
				pm.sendCustomEvent(pm.CLEAR_CACHE);
				break;
			case pm.BROADCAST_DATA_PACKET_TYPE.DEBUG_RECONNECT:
				this.startDebug();
				break;
		}
	},

	_netControlledGameStopped: function()
	{
		this._networkGame = null;
	}

};

pm.NetworkGameState = {
	ProcessingLevel: "NetProcessingLevel",
	Broken: "NetRobotBroken",
	Win: "NetTaskCompleted",
	Loose: "NetTaskFailed",
	Develop: "NetDevelopProgram",
	ReadyToChangeLevel: "NetReadyToChange"
};

pm.NetworkDebugSendTypes = {
	DISCOVER: 0,
	BROADCAST_DATA: 1,
	TEACHER_SERVER: 2,
	STUDENT_CLIENT: 3,
	GAME_SERVER: 4,
	GAME_CLIENT: 5,
	OTHER: 6
};
