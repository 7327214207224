"use strict";

/**
 * Created by Nikita Besshaposhnikov on 09.04.15.
 */

/**
 * @class 2D Level where robots can push objects.
 * @implements SquarableLevel
 */
pm.data.PushLevel = SquarableLevel.extend(/** @lends pm.data.PushLevel# */{
	typeName: "PushLevel",
	/**
	 * Robots can be indicated.
	 * @type {Boolean}
	 */
	robotsCanBeIndicated: false,

	ctor: function()
	{
		this._super();
	},

	getType: function() { return pm.PushLevelModule.Type; }
});
