/**
 * Created by danilaeremin on 14.11.14.
 */

/**
 * @class Sprite for extended counter robot.
 * @extends pm.CounterSprite
 */
pm.ExtendedCounterSprite = pm.CounterSprite.extend(/** @lends pm.ExtendedCounterSprite# */{
	_memoryLabel: null,

	ctor: function()
	{
		this._super();

		this._memoryLabel = new ccui.Text("0", pm.CounterSprite.FONT_NAME, pm.CounterSprite.FONT_SIZE);
		this._memoryLabel.setTextColor(pm.settings.levelFontColor);
		this._memoryLabel.setPosition(this._getValueLabelX(), this._getValueLabelY());

		this.getSprite().addChild(this._memoryLabel, 3);

		this._commandsSprite = new cc.Sprite(pm.spriteUtils.getInterfaceElementFrame("commandsNumber"));

		this._commandsSprite.setColor(RobotColorList2D.Orange);
		this._commandsSprite.setPosition(cc.p(71, 195));
		this._commandsSprite.setVisible(false);

		this.sprite.addChild(this._commandsSprite);

		this._commandsCount = new ccui.Text("", pm.settings.fontBoldName, pm.settings.fontSize);
		this._commandsCount.setColor(cc.color(1, 1, 180));
		this._commandsCount.setAnchorPoint(cc.p(0.5, 0.5));

		if (cc.sys.isNative)
			this._commandsCount.setNormalizedPosition(cc.p(0.5, 0.5));
		else
			this._commandsCount.setNormalizedPosition(cc.p(0.5, 0.42));

		this._commandsCount.setNormalizedPosition(cc.p(0.5, 0.46));
		this._commandsSprite.addChild(this._commandsCount);
	},

	_getSpriteFramesFile: function()
	{
		return "Robot/extended_counter.plist";
	},

	_getBackFrameName: function()
	{
		return "extended_counter_back.png";
	},

	_getFrontFrameName: function()
	{
		return "extended_counter_front.png";
	},

	_getLightFrameName: function()
	{
		return "extended_counter_light.png";
	},

	_getCenterX: function() { return 71; },
	_getValueLabelX: function() { return 21; },
	_getValueLabelY: function() { return 111; },
	_getLightX: function() { return this.getSprite().getContentSize().width / 2 - 1; },
	_getLightY: function() { return 0; },

	playAnimation: function(endCallBack, target, type, args)
	{
		switch(type)
		{
			case pm.CounterSprite.Animation.Set: this._setValues(args[0], args[1]); break;
			case pm.ExtendedCounterSprite.Animation.MemSet: this._memSet(args, endCallBack, target); break;
			default: pm.CounterSprite.prototype.playAnimation.call(this, endCallBack, target, type, args); break;
		}
	},

	_memSet: function(memory, callback, target)
	{
		this._memoryLabel.setString("{0}".format(memory));

		this._lightAnimation(function() { callback.call(target); });
	},

	_setValues: function(value, memory)
	{
		this._countLabel.setString("{0}".format(value));
		this._memoryLabel.setString("{0}".format(memory));

		this._setStonesByValue(value);
	},

	_reset: function()
	{
		this._countLabel.setString("0");
		this._memoryLabel.setString("0");

		this._setStonesByValue(0);
	}
});

/**
 * Enum for extended counter robot animations.
 * @readonly
 * @enum {Number}
 */
pm.ExtendedCounterSprite.Animation = {MemSet: "mem_set"};
