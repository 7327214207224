/**
 * Created by Kirill Mashchenko on 21.09.2018.
 */

/**
 * @class Layer for edit log info.
 * @extends pmui.OvalLayout
 */
var EditLogInfoLayer = pmui.OvalLayout.extend(/** @extends EditLogInfoLayer# */{
	_target: null,
	_cancelCallback: null,
	_changesListView: null,

	ctor: function(target, cancelCallback)
	{
		this._super(null, null, pmui.OvalLayout.Type.SLIM);

		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setContentSizeWithBorder(EditLogInfoLayer.WIDTH, EditLogInfoLayer.HEIGHT);

		this.setTouchEnabled(true);

		this.setAnchorPoint(cc.p(0.5, 0.5));
		this.setPosition(pm.settings.getScreenSize().width / 2, pm.settings.getScreenSize().height / 2);

		this._target = target;
		this._cancelCallback = cancelCallback;

		this._loadInterface();
	},

	_loadInterface: function()
	{
		var closeButton = new pmui.Button(pm.spriteUtils.getIconName("close", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("close", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("close", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		closeButton.addClickEventListener(this._cancelCallback.bind(this._target));

		var closeButtonAlign = new ccui.RelativeLayoutParameter();
		closeButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		closeButtonAlign.setMargin(0, -closeButton.height/4, -closeButton.width/4, 0);
		closeButton.setLayoutParameter(closeButtonAlign);

		this.addChild(closeButton);

		this._changesListView = new ccui.ListView();
		this._changesListView.setContentSize(EditLogInfoLayer.WIDTH - 2 * EditLogInfoLayer.BORDER, EditLogInfoLayer.HEIGHT - 2 * EditLogInfoLayer.BORDER);
		this._changesListView.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._changesListView.setScrollBarOpacity(255 * 0.9);
		this._changesListView.setScrollBarWidth(5);
		this._changesListView.setItemsMargin(5);

		var listAlign = new ccui.RelativeLayoutParameter();
		listAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		listAlign.setMargin(EditLogInfoLayer.BORDER, EditLogInfoLayer.BORDER, 0, 0);
		this._changesListView.setLayoutParameter(listAlign);

		this.addChild(this._changesListView);

		this._fillChangesList();
	},

	_fillChangesList: function()
	{
		var changedGames = world.getEditLog().getChangedGames();
		var changedLevels = world.getEditLog().getChangedLevels();

		for (var i = 0; i < world.getGameCount(); ++i)
		{
			var game = world.games[i];
			var gameId = game.id;

			if (changedGames && changedGames[gameId])
			{
				var renamedString = (changedGames[gameId].renamed) ? LocalizedString("renamed") : "";
				var reorderedString = (changedGames[gameId].reordered) ? LocalizedString("reorderedF") : "";

				var string = game.name + " ----- ";

				if (changedGames[gameId].renamed && changedGames[gameId].reordered)
					string += renamedString + ", " + reorderedString;
				else
					string += renamedString + reorderedString;

				var child = new ccui.Text(string, pm.settings.fontName, pm.settings.fontSize);
				this._changesListView.pushBackCustomItem(child);
			}

			for (var j = 0; j < world.getLevelCount(i); ++j)
			{
				var level = game.levels[j];
				var levelId = level.id;

				if (changedLevels && changedLevels[levelId])
				{
					var updatedString = (changedLevels[levelId].updated) ? LocalizedString("updated") : "";
					var reorderedString = (changedLevels[levelId].reordered) ? LocalizedString("reorderedM") : "";

					var string = game.name + ". " + level.name + " ----- ";

					if (changedLevels[levelId].updated && changedLevels[levelId].reordered)
						string += updatedString + ", " + reorderedString;
					else
						string += updatedString + reorderedString;

					var child = new ccui.Text(string, pm.settings.fontName, pm.settings.fontSize);
					this._changesListView.pushBackCustomItem(child);
				}
			}
		}
	}
});

EditLogInfoLayer.WIDTH = 650;
EditLogInfoLayer.HEIGHT = 500;
EditLogInfoLayer.TAG = 8317;
EditLogInfoLayer.SEPARATOR = 15.0;
EditLogInfoLayer.WIDTH_DELTA = 70.0;
EditLogInfoLayer.BORDER = 15.0;
