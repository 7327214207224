/**
 * Created by Kirill Mashchenko on 29.06.17.
 */

var RepairMapEditorLayer = RepairMapLayer.extend(SquarableMapEditorLayerHelper).extend({

	createMapElement: function ()
	{
		var el = null;
		el = new pm.data.RepairMapElement();
		el.originalType = RepairMapElementType.LightBlueGrass;
		el.initElement();
		return el;
	},

	_addClone: function(pos, robot)
	{
		robot.childRobot = null;
		robot.childRobotID = -1;
		robot.parentLevel = this._map.parentLevel;

		this._map.parentLevel.robots.push(robot);
		robot.id = this._map.parentLevel.robots.indexOf(robot);

		robot.groupID = this._activeRobot.groupID;

		robot.useGreyCondition = this._activeRobot.useGreyCondition;
		robot.useLightBlueCondition = this._activeRobot.useLightBlueCondition;
		robot.useBlueCondition = this._activeRobot.useBlueCondition;

		robot.updateConditions();

		robot.startPosition = pos;
		robot.setPosition(robot.startPosition, this._activeRobot.direction);

		this._map.element(pos).startForRobot = robot.id;
		this._map.element(pos).startRobotData.direction = robot.direction;

		robot.generateRobotSprite();

		robot.sprite.mapLayer = this._map.mapLayer;
		robot.sprite.setDirection(robot.direction);
		robot.sprite.setOrientation(this._orientation);

		this.addObject(robot.sprite);
		robot.sprite.setRealPosition(robot.position);

		this.drawRobotStartPositionMark(robot.id, robot.position);

		robot.sprite.setSpriteColor(this.getRobotIndex(robot.groupID));
	},

	moveElementsUp: function ()
	{
		for (var j = this._map.height - 2; j >= 0; --j)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i, j + 1)).setType(elementType);
				this._map.element(cc.p(i, j + 1)).originalType = elementType;

				var type = RepairMapElementType.LightBlueGrass;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	},

	moveElementsDown: function ()
	{
		for (var j = 1; j < this._map.height; ++j)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i, j - 1)).setType(elementType);
				this._map.element(cc.p(i, j - 1)).originalType = elementType;

				var type = RepairMapElementType.LightBlueGrass;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;

			}
		}
	},

	moveElementsLeft: function ()
	{
		for (var i = this._map.width - 2; i >= 0; --i)
		{
			for (var j = 0; j < this._map.height; ++j)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i + 1, j)).setType(elementType);
				this._map.element(cc.p(i + 1, j)).originalType = elementType;

				var type = RepairMapElementType.LightBlueGrass;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	},

	moveElementsRight: function ()
	{
		for (var i = 1; i < this._map.width; ++i)
		{
			for (var j = 0; j < this._map.height; ++j)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i - 1, j)).setType(elementType);
				this._map.element(cc.p(i - 1, j)).originalType = elementType;

				var type = RepairMapElementType.LightBlueGrass;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	}

});
