/**
 * Created by Nikita Besshaposhnikov on 09.02.15.
 */
/**
 * @class This robot can store some value, inc && dec && clear this value.</br>
 * Provides own conditions, repeaters and methods.
 * @implements pm.GlobalRobot
 */
pm.Counter = pm.GlobalRobot.extend(/** @lends pm.Counter# */{
	typeName: "Counter",

	_counter: 0,

	ctor: function()
	{
		this._super();

		this.nativeFunctionMap[pm.Counter.NativeMethod.Inc] = new pm.NativeFunction(this, this._inc);
		this.nativeFunctionMap[pm.Counter.NativeMethod.Dec] = new pm.NativeFunction(this, this._dec);
		this.nativeFunctionMap[pm.Counter.NativeMethod.Empty] = new pm.NativeFunction(this, this._empty);

		this.nativeFunctions.push(pm.Counter.NativeMethod.Inc, pm.Counter.NativeMethod.Dec,
			pm.Counter.NativeMethod.Empty);

		this.conditions.push(pm.Counter.Condition.Empty, pm.Counter.Condition.NotEmpty);

		this.repeaters.push(pm.Counter.Repeater.Value);
	},

	_inc: function()
	{
		++this._counter;

		this.playAnimation(pm.CounterSprite.Animation.IncValue, this._endPlayingAnimation, this._counter);
	},

	_dec: function()
	{
		if (this._counter > 0)
			--this._counter;

		this.playAnimation(pm.CounterSprite.Animation.DecValue, this._endPlayingAnimation, this._counter);
	},

	_empty: function()
	{
		this._counter = 0;

		this.playAnimation(pm.CounterSprite.Animation.Empty, this._endPlayingAnimation);
	},

	reset: function()
	{
		this._counter = 0;
		this.stepCount = 0;
		this.playAnimation(pm.CounterSprite.Animation.Reset, this._endPlayingAnimation);
		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_endPlayingAnimation: function()
	{
		this.clearStateFlag(pm.RobotState.PlayingAnimation);

		if (!CORE_BUILD && pm.settings.isAnimationDisabled())
			this._updateVisualState();
	},

	_updateVisualState: function ()
	{
		this.sprite._setStonesByValue(this._counter);
		this.sprite._countLabel.setString("{0}".format(this._counter));
	},

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new pm.CounterSprite();

			return this.sprite;
		}
	},

	_checkConditionInternal: function(condition, args)
	{
		switch(condition)
		{
			case pm.Counter.Condition.Empty: return this._counter === 0;
			case pm.Counter.Condition.NotEmpty: return this._counter !== 0;
		}

		return false;
	},

	_getRepeaterValueInternal: function(repeater, args)
	{
		if(repeater === pm.Counter.Repeater.Value)
			return this._counter;

		return 0;
	},

	updateSprite: function()
	{
		this.playAnimation(pm.CounterSprite.Animation.Set, null, this._counter, true);
		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	getType: function() { return pm.GlobalRobotType.Counter; },

	getState: function ()
	{
		return this._counter;
	},

	setState: function (state)
	{
		if (state !== this._counter)
		{
			if (state === this._counter - 1)
			{
				this._dec();
			}
			else if (state === this._counter + 1)
			{
				this._inc();
			}
			else if (state === 0)
			{
				this._counter = 0;
				this.playAnimation(pm.CounterSprite.Animation.Empty, this._endPlayingAnimation);
			}
			else
			{
				this._counter = state;
				this.playAnimation(pm.CounterSprite.Animation.IncValue, this._endPlayingAnimation, this._counter);
			}
		}
	}
});

pm.Counter.Condition = {
	Empty: "cnt_empty",
	NotEmpty: "cnt_not_empty"
};

pm.Counter.Repeater = {Value: "cnt_value"};

pm.Counter.NativeMethod =
{
	Inc: "cnt_inc",
	Dec: "cnt_dec",
	Empty: "empty_counter",
	Fill: "fill_counter"
};
