/**
 * Created by Nikita Besshaposhnikov on 23.07.15.
 */

/**
 * Wrapper for cocos UIButton.
 * @class
 * @extends ccui.Button
 * @constructor
 * @param {String} normalImage
 * @param {String} [selectedImage=""]
 * @param {String} [disableImage=""]
 * @param {Number} [texType=ccui.Widget.LOCAL_TEXTURE]
 */
pmui.Button = ccui.Button.extend(/** @lends pmui.Button# */{

	_locked: false,
	_originalState: true,

	ctor: function(normalImage, selectedImage, disableImage, texType)
	{
		this._super(normalImage, selectedImage, disableImage, texType);
	},

	setEnabled: function(flag)
	{
		this.setBright(flag);

		this._originalState = flag;

		if(this._locked)
			return;

		ccui.Button.prototype.setEnabled.call(this, flag);
	},

	/**
     * Sets click on button enabled.
     * @param {Boolean} flag
     * @private
     */
	_setClickEnabled: function(flag)
	{
		if(this._locked)
			return;

		var isHighlighted = this.isHighlighted();
		var isBright = this.isBright();

		ccui.Button.prototype.setEnabled.call(this, flag);

		this.setBright(isBright);
		this.setHighlighted(isHighlighted);

	},

	/**
     * Locks layout click state. Layout don't changes it's state until 'unlock' is called
     */
	lock: function()
	{
		this._originalState = this.enabled;

		this._setClickEnabled(false);

		this._locked = true;
	},

	/**
     * Unlocks layout clcik state && restores original state
     */
	unlock: function()
	{
		this._locked = false;

		this._setClickEnabled(this._originalState);
	}
});
