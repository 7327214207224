/**
 * Created by Diana Agliamutdinova on 01.05.2019.
 */

/**
 * @class Represents program of one robot.
 */
var TextualTree = cc.Class.extend(/** @lends ProgramContainerLayer# */{
	_robot: null,
	_outputRobot: null,
	_syntaxModule: null,
	_programContainer: null,

	_buttonClickCallback: null,
	_buttonClickCallbackTarget: null,

	_nodeTree: null,
	_dynamicObjects: new MapClass(),
	_dynamicParams: new MapClass(),
	_programData: null,
	_highlightedMethodsStack: [],

	ctor: function (programTree, robot, parent, outputRobot, syntaxModule)
	{
		pmui.StatementNode.vals.clear();
		pmui.FunctionNode.vals.clear();
		this._robot = robot;
		if(outputRobot)
			this._outputRobot = outputRobot;
		this._syntaxModule = syntaxModule;

		this._programContainer = parent;
		this._programData = robot.getProgramData();
		this._highlightedMethodsStack = [];

		this._createVisualTree(programTree, parent);
	},

	addOutputRobot: function (robot)
	{
		this._outputRobot = robot;
	},

	changeParamsValues: function ()
	{
		this._dynamicParams.values().forEach(function (param){
			pm.sendCustomEvent(pm.VARIABLE_VALUE_CHANGED, {
				name: param.getVarName(),
				val: 0
			});
		});

	},

	highlightMethod: function (nodeView)
	{
		nodeView.highlight();
		nodeView._parentNode.highlight();
		this._highlightedMethodsStack.push(nodeView);
	},

	highlightMethodBroken: function (nodeView)
	{
		nodeView.highlightBroken();
		nodeView._parentNode.highlight();
		this._highlightedMethodsStack.push(nodeView);
	},

	clearLastHighlight: function ()
	{
		var elem = this._highlightedMethodsStack.pop();
		if (elem)
		{
			elem.removeHighlight();
			elem._parentNode.removeHighlight();
		}
	},

	clearAllHighlight: function ()
	{
		this._highlightedMethodsStack.forEach(function(elem) {
			if (elem)
			{
				elem.removeHighlight();
				elem._parentNode.removeHighlight();
			}
		});
		this._highlightedMethodsStack = [];
	},

	switchPointsInRepeater: function (nodeView, num, rep)
	{
		nodeView.updateIteratorVal(num);
	},
	/** makes Visual tree from ProgramData
	 * adds it to ProgramContainer
	 * @param programTree syntax tree from ProgramData
	 * @private
	 */
	_createVisualTree: function (programTree, parent)
	{
		this._nodeTree = this.parseTree(programTree.head);
		parent.addChild(this._nodeTree);
	},

	/**
	 * parses node to visual Layer and adds it to vTree
	 * @param node node of syntax tree
	 * @param vTree parent of node in visual tree
	 * @returns {number}
	 */
	parseTree: function (node, vTree, selectedNode)
	{
		if(!node)
			return;
		var visualNode = null;
		if(vTree)
			node.parentNode = vTree._treeNode;
		switch (node.type)
		{
			case pm.data.Node.Type.Prog:
				visualNode = new pmui.StartNode(node, vTree, this._syntaxModule, this._robot.parentLevel.getType(), this._robot.getType());

				for (var i = 0; i < node.getChildren().length; i++)
				{
					var parsed = this.parseTree(node.getChildren()[i], visualNode);
					if(!parsed) i--;
				}
				visualNode.calculateSize();
				return visualNode;

			case pm.data.Node.Type.MainAlg:
				visualNode = new pmui.FunctionNode(node, vTree, this._syntaxModule);
				vTree.setMain(visualNode);

				for (var i = 0; i < node.getChildren().length; i++)
				{
					this.parseTree(node.getChildren()[i], visualNode);
				}
				break;
			//
			// case pm.data.Node.Type.Globals:
			//
			// 	visualNode = vTree.setGlobals(node);
			//
			// 	for (var i = 0; i < node.getChildren().length; i++)
			// 	{
			// 		this.parseTree(node.getChildren()[i], globals);
			// 	}
			// 	globals.calculateSize(true);
			// 	return globals;

			case pm.data.Node.Type.Func:
				visualNode = new pmui.FunctionNode(node, vTree, this._syntaxModule, node.value);
				var body = null;

				for (var i = 0; i < node.getChildren().length; i++)
				{
					if (node.children[i].type === pm.data.Node.Type.Body)
						body = node.children[i];
					else if(node.children[i].type === pm.data.Node.Type.Alloc)
						this.parseTree(node.children[i], visualNode);
				}
				this.parseTree(body, visualNode);
				this._addFunc(node.value);
				break;

			case pm.data.Node.Type.Body:
				if (node.getTagName() === pm.IFThenTag)
					visualNode = new pmui.BodyNode(node, vTree, this._syntaxModule);
				else if (node.getTagName() === pm.IFElseTag)
					visualNode = new pmui.ElseBlock(node, vTree, this._syntaxModule);
				else
					visualNode = new pmui.BodyNode(node, vTree, this._syntaxModule);

				var child = null;
				for (var i = 0; i < node.getChildren().length; i++)
				{
					child = this.parseTree(node.getChildren()[i], visualNode);
					if(!child)
						i--;
				}
				if(node.getChildren().length === 0)
				{
					pm.data.SyntaxTree.createNode(node, pm.data.Node.Type.Empty, "");
					this.parseTree(node.getChildren()[0], visualNode);
				}
				break;

			case pm.data.Node.Type.Empty:
				visualNode = new pmui.ReplaceNode(vTree._addTypes, vTree, node, this._syntaxModule);
				break;

			case pm.data.Node.Type.Alloc:
				var variable =  node.getChildren().find(function (elem){
					return elem.type === pm.data.Node.Type.Identifier;
				});
				var varType = node.getChildren().find(function (elem){
					return elem.type === pm.data.Node.Type.Type;
				});

				visualNode = new pmui.AllocNode(node, vTree, this._syntaxModule);

				visualNode.setTypeName(varType.value);
				if(vTree.isFunction())
					this._dynamicParams.set(variable.value, visualNode);
				else
					this._dynamicObjects.set(variable.value, visualNode);
				this.parseTree(variable, visualNode);

				this._programData.alloc.filter(function (allocVal, index, arr){
					if(allocVal === variable.value)
					{
						arr.splice(index, 1);
						return true;
					}
					return false;
				})

				if(varType.value === pm.AllocVals.INT)
				{
					if (pmui.AllocNode.intVals.indexOf(variable.value) === -1)
						pmui.AllocNode.intVals.push(variable.value);
				}
				else
				{
					if (pmui.AllocNode.boolVals.indexOf(variable.value) === -1)
						pmui.AllocNode.boolVals.push(variable.value);
				}

				if(!vTree.isFunction())
					pmui.StatementNode.vals.set(variable.value, varType.value);

				break;

			case pm.data.Node.Type.Statement:

				var variable = null;
				var value = null;

				for (var i = 0; i < node.getChildren().length; i++)
				{
					if (node.getChildren()[i].getTagName() === pm.StatementVarTag)
						variable = node.getChildren()[i];
					else if(node.getChildren()[i].getTagName() === pm.StatementValueTag)
						value = node.getChildren()[i];
				}
				if(!this._dynamicObjects.has(variable.value)
					|| !this._isAllocationHigher(node, variable.value))
				{
					break;
				}

				visualNode = new pmui.StatementNode(node, vTree, this._syntaxModule);

				this.parseTree(variable, visualNode);
				this.parseTree(value, visualNode);

				break;

			case pm.data.Node.Type.Identifier:
				if(selectedNode) node.setTagName(selectedNode.getTagName());
				if(!vTree.isAlloc())
					if(!this._isAllocationHigher(vTree._treeNode, node.value))
					{
						vTree._treeNode.removeChild(node);
						var syntTreeNode = pm.data.SyntaxTree.createNode(vTree._treeNode, pm.data.Node.Type.Number, 0);
						syntTreeNode.setTagName(node.getTagName());
						return this.parseTree(syntTreeNode, vTree)
					}
				var typeName = null;
				if(this._dynamicObjects.has(node.value))
					typeName = this._dynamicObjects.get(node.value);
				else
					typeName = this._dynamicParams.get(node.value);

				visualNode =  new pmui.VariableNode(node, vTree, this._syntaxModule, node.value, typeName.getTypeName());
				break;

			case pm.data.Node.Type.Bool:
				visualNode = new pmui.BoolNode(node, vTree, this._syntaxModule);
				break;

			case pm.data.Node.Type.Number:
				if(selectedNode) node.setTagName(selectedNode.getTagName());
				visualNode = new pmui.NumberNode(node, vTree, this._syntaxModule);
				break;

			case pm.data.Node.Type.Expression:
				if(selectedNode) node.setTagName(selectedNode.getTagName());
				visualNode = new pmui.NumericExpressionNode(node, vTree, this._syntaxModule, node.value);

				for (var i = 0; i < node.getChildren().length; i++)
				{
					var op = this.parseTree(node.getChildren()[i], visualNode);
				}
				break;

			case pm.data.Node.Type.IfStatement:
				visualNode = new pmui.IfNode(node, vTree, this._syntaxModule, node.value);

				for (var i = 0; i < node.children.length; i++)
				{
					this.parseTree(node.getChildren()[i], visualNode);
				}
				break;

			case pm.data.Node.Type.Condition:
				if(selectedNode)
					node.setTagName(selectedNode.getTagName());

				visualNode = new pmui.ConditionNode(node, vTree, this._syntaxModule, node.value, this._robot.parentLevel.getType());
				break;

			case pm.data.Node.Type.LogicExpression:
				var leftOp;
				var rightOp;
				if(selectedNode) node.setTagName(selectedNode.getTagName());
				visualNode = new pmui.LogicExpressionNode(node, vTree, this._syntaxModule, node.value);

				for (var i = 0; i < node.children.length; i++)
				{
					if (node.children[i].getTagName() === pm.ExpressionLeftTag)
						leftOp = node.children[i];
					else if (node.children[i].getTagName() === pm.ExpressionRightTag)
						rightOp = node.children[i];
					else
						this.parseTree(node.children[i], visualNode);
				}
				if (rightOp)
					this.parseTree(rightOp, visualNode);
				if (leftOp)
					this.parseTree(leftOp, visualNode);

				break;

			case pm.data.Node.Type.AndOrExpression:
				var leftOp;
				var rightOp;
				if(selectedNode) node.setTagName(selectedNode.getTagName());
				visualNode = new pmui.AndOrExpressionNode(node, vTree, this._syntaxModule, node.value);

				for (var i = 0; i < node.children.length; i++)
				{
					if (node.children[i].getTagName() === pm.ExpressionLeftTag)
						leftOp = node.children[i];
					else if (node.children[i].getTagName() === pm.ExpressionRightTag)
						rightOp = node.children[i];
					else
						this.parseTree(node.children[i], visualNode);
				}
				if (leftOp)
					this.parseTree(leftOp, visualNode);
				if (rightOp)
					this.parseTree(rightOp, visualNode);

				break;

			case pm.data.Node.Type.NotExpression:
				var rightOp;
				if(selectedNode) node.setTagName(selectedNode.getTagName());
				visualNode = new pmui.NotExpressionNode(node, vTree, this._syntaxModule, node.value);

				for (var i = 0; i < node.children.length; i++)
				{
					if (node.children[i].getTagName() === pm.ExpressionRightTag)
						rightOp = node.children[i];
					else
						this.parseTree(node.children[i], visualNode);
				}
				if (rightOp)
					this.parseTree(rightOp, visualNode);

				break;

			case pm.data.Node.Type.Loop:

				switch (node.value)
				{
					case pm.LoopVals.nTimes:

						var repVal = null;
						var repBody = null;

						visualNode = new pmui.NTimesRepeaterNode(node, vTree, this._syntaxModule, node.value);
						repVal = node.children[1];
						repBody = node.children[0];

						this.parseTree(repVal, visualNode);
						this.parseTree(repBody, visualNode);

						break;

					case pm.LoopVals.for:

						var repIndex = null;
						var repBeg = null;
						var repVal = null;
						var repStep = null;
						var repBody = null;

						for (var i = 0; i < node.children.length; i++)
						{
							if (node.children[i].getTagName() === pm.ForIteratorTag)
							{
								repIndex = node.children[i];
							}
							else if (node.children[i].getTagName() === pm.ForBegTag)
							{
								repBeg = node.children[i];
							}
							else if (node.children[i].getTagName() === pm.ForEndTag)
							{
								repVal = node.children[i];
							}
							else if (node.children[i].getTagName() === pm.ForStepTag)
							{
								repStep = node.children[i];
							}
							else
							{
								repBody = node.children[i];
							}
						}
						if(!this._dynamicObjects.has(repIndex.value)
							|| !this._isAllocationHigher(node, repIndex.value))
						{
							vTree._treeNode.removeChild(node);
							break;
						}
						visualNode = new pmui.ForRepeaterNode(node, vTree, this._syntaxModule, node.value);

						if (repStep !== null)
						{
							this.parseTree(repStep, visualNode);
						}

						this.parseTree(repBeg, visualNode);
						this.parseTree(repVal, visualNode);
						this.parseTree(repIndex, visualNode);
						this.parseTree(repBody, visualNode);

						break;

					case pm.LoopVals.whileC:

						var body = null;

						visualNode = new pmui.WhileRepeaterNode(node, vTree, this._syntaxModule, node.value);

						for (var i = 0; i < node.children.length; i++)
						{
							if (node.children[i].type === pm.data.Node.Type.Body)
							{
								body = node.children[i];
							}
							else this.parseTree(node.children[i], visualNode);
						}

						this.parseTree(body, visualNode);

						break;

					case pm.LoopVals.until:
						break;
				}
				break;

			case pm.data.Node.Type.Action:

				var robot = this._outputRobot && this._outputRobot.nativeFunctionMap[node.value]
					? this._outputRobot
					: this._robot;

				if(robot.nativeFunctionMap[node.value])
				{
					if (robot.nativeFunctionMap[node.value].returnArgs())
					{
						if(selectedNode) node.setTagName(selectedNode.getTagName());
						visualNode = new pmui.CommandNode(node, vTree, this._syntaxModule, robot.getRobotMethodName(node.value), robot.getType(), true);
					}
				}
				if(!visualNode)
				{
					visualNode = new pmui.CommandNode(node, vTree, this._syntaxModule, robot.getRobotMethodName(node.value), robot.getType(), false);
				}

				for (var i = 0; i < node.children.length; i++)
				{
					this.parseTree(node.children[i], visualNode);
				}
				break;
		}
		if(!visualNode) {
			vTree._treeNode.removeChild(node);
			return null;
		}

		visualNode.calculateSize(true);
		vTree.addChildNode(visualNode, selectedNode);
		return visualNode;
	},

	updateTree: function (node, parent)
	{
		this.parseTree(node, parent);
		parent.calculateSize();
	},

	getVisualTree: function()
	{
		return this._nodeTree;
	},

	_addFunc: function(value)
	{
		if (this._programData.funcs.indexOf(value) !== -1)
			this._programData.funcs.splice(this._programData.funcs.indexOf(value), 1);
		pmui.FunctionNode.vals.add(value);
	},

	_isAllocationHigher: function(treeNode, varName)
	{
		var curParent = treeNode.parentNode;
		var curElem = treeNode;
		while(curParent)
		{
			for(var i = 0;i < curParent.getChildren().length;i++)
			{
				if(curParent.getChildren()[i].type === pm.data.Node.Type.Alloc
					&& curParent.getChildren()[i].getNodeView().getVarName() === varName)
					return true;

				if(curParent.getChildren()[i] === curElem)
				{
					break;
				}
			}
			curElem = curParent;
			curParent = curElem.parentNode;
		}
		return false;
	}
});

