/**
 * Created by Nikita Besshaposhnikov on 10.11.14.
 */

/**
 * @class Common interface for square map layers.
 * @interface
 * @extends pm.MapLayer2D
 */
var SquarableMapLayer = pm.MapLayer2D.extend(/** @lends SquarableMapLayer# */{

	ctor: function(map)
	{
		this._super(map);
	},

	realPosition: function(pos, del)
	{
		return cc.p(this.getMapElementSize().width * pos.x + del.x, this.getRealHeight() - this.getMapElementSize().height * pos.y + del.y);
	},

	positionFromReal: function(pos)
	{
		var X = Math.floor(pos.x/this.getMapElementSize().width + 0.5);
		if (X < 0) X = 0;
		if (X >= this._map.width) X = this._map.width - 1;

		var Y = Math.floor((this.getRealHeight() - pos.y)/this.getMapElementSize().height + 0.5);
		if (Y < 0) Y = 0;
		if (Y >= this._map.height) Y = this._map.height - 1;
		return cc.p(X, Y);
	}
});
