/**
 * Created by Aleksandra Karavaeva 18.04.2022
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.NotExpressionNode = pmui.ExpressionNode.extend(/** @lends pmui.NodeLayer# */{
	_child: null,

	ctor: function (treeNode, parent, syntaxModule, sign)
	{
		this._super(treeNode, parent, syntaxModule, sign);
		this.type = FunctionButton.Type.NotExpression;

		var centerAlign = new ccui.RelativeLayoutParameter();
		centerAlign.setMargin(15, 2, -3, 0);
		centerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
		centerAlign.setRelativeName("center");
		this._sign.setLayoutParameter(centerAlign);

		this.drawBackground();
		this._repTypes = [FunctionButton.Type.Condition, FunctionButton.Type.LogicExpression,
			FunctionButton.Type.Variable, FunctionButton.Type.AndOrExpression, FunctionButton.Type.NotExpression];
	},

	getNodeMargin: function()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return 2;
		return 13;
	},

	select: function()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
			return;
		if(!this._selected)
		{
			this.setBackGroundImage("PiktomirK/select-romb-border.png");
			this.setBackGroundImageCapInsets(cc.rect(28, 22, 70, 6));
			this._selected = true;
			this.setTag(ProgramContainerLayer.SELECTED_TAG);
		}
	},

	highlight: function()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
		{
			//this._sign.setColor(cc.BLUE);
			return;
		}
		this.setBackGroundImage("PiktomirK/select-romb-border.png");
		this.setBackGroundImageCapInsets(cc.rect(28, 22, 70, 6));
	},

	removeHighlight: function()
	{
		this.drawBackground();
	},

	clickCallback: function () {},

	_removeNode: function (node)
	{
		var treeNode = pm.data.SyntaxTree.createNode(this._treeNode,pm.data.Node.Type.Bool, pm.BoolTrue);
		treeNode.setTagName(node._treeNode.getTagName());
		pm.sendCustomEvent(pm.PARSE_NODE, {node: treeNode, parent: this});
	},

	addChildNode: function (node)
	{
		var tag = node._treeNode.getTagName();
		if (tag === pm.ExpressionRightTag)
		{
			if(this._rOp)
			{
				var parent = this._treeNode;
				parent.removeChild(this._rOp._treeNode);

				var align = this._rOp.getLayoutParameter().clone();
				node.setLayoutParameter(align);
				this.removeChild(this._rOp);
			}
			else
			{
				var rightAlign = new ccui.RelativeLayoutParameter();
				rightAlign.setMargin(5, 2, this.getNodeMargin(), 2);
				rightAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
				rightAlign.setRelativeName( "right");
				rightAlign.setRelativeToWidgetName("center");
				node.setLayoutParameter(rightAlign);
			}

			this._rOp = node;
			this.addChild(node);
		}
		this.calculateSize(true);
	},

	drawBackground: function()
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
		{
			return;
		}
		if(!this.isEditable())
			this.setBackGroundImage("PiktomirK/romb-blocked.png");
		else
			this.setBackGroundImage("PiktomirK/romb-border.png");
		this.setBackGroundImageScale9Enabled(true);
		this.setCascadeOpacityEnabled(false);
		this.setBackGroundImageCapInsets(cc.rect(28, 22, 70, 6));
	},

	getChildrenNodes: function ()
	{
		return [this._rOp];
	},

	clone: function ()
	{
		return pm.appUtils.generateFunctionButton(this.type, pm.NotFunction.Not);
	},

});

pmui.NotExpressionNode.values = [
	pm.NotFunction.Not
];


