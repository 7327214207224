/**
 * Created by Diana Agliamutdinova 01.2019
 */

/**
 * @class RepeaterButton used by {@link RobotManager} and {@link ProgramLayer}.
 * @extends FunctionButtonP
 * @constructor
 * @param {String} value
 */
var RepeaterButton = FunctionButtonP.extend(/** @lends RepeaterButton#*/{

	ctor: function(value, drawBackground)
	{
		this._super(FunctionButton.Type.Repeater, value, drawBackground);
	},

	cloneImage: function()
	{
		return new ccui.ImageView(pm.spriteUtils.getRepeaterSpriteName(this._value), ccui.Widget.PLIST_TEXTURE);
	},

	switchPointsNumber: function(iterNumber)
	{
		this.loadTextureNormal(this._getSpriteName(pm.NORMAL_STATE, iterNumber), ccui.Widget.PLIST_TEXTURE);
		this.loadTexturePressed(this._getSpriteName(pm.NORMAL_STATE, iterNumber), ccui.Widget.PLIST_TEXTURE);
		this.loadTextureDisabled(this._getSpriteName(pm.NORMAL_STATE, iterNumber), ccui.Widget.PLIST_TEXTURE);
	},

	_getSpriteName: function(state, iterNumber)
	{
		if(iterNumber === 0 || iterNumber === undefined)
			return pm.spriteUtils.getRepeaterSpriteName(this._value, state);

		return pm.spriteUtils.getRepeaterSpriteName("{0}_{1}".format(this._value, iterNumber), state);
	}
});
