/**
 * Created by Antony Orlovsky on 19.07.19.
 */

/**
 * @class Functions for GrasshopperLevel module.
 * @interface
 * @extends pm.ModuleInfo
 */

pm.GrasshopperLevelModule = pm.ModuleInfo.extend(/** @lends pm.GrasshopperLevelModule# */{

	name: "Grasshopper",

	getType: function()
	{
		return pm.GrasshopperLevelModule.Type;
	},

	getBackgroundParameters: function ()
	{
		return{
			color: cc.color(97, 156, 34),
			drawStars: false,
			scaleFactor: 1.5,
			centerImage: "Background/Levels/grasshopper_center.png"
		};
	},

	generateEmptyMap: function (level)
	{
		var map = new pm.data.GrasshopperMap();

		map.parentLevel = level;

		var width = Math.max(3, level.robots.length);

		map.setSize(width, 1);

		for (var x = 0; x < width; ++x)
			map.element(cc.p(x, 0)).originalType = GrasshopperMapElementType.LightBlueGrass;

		map.element(cc.p(1, 0)).startForRobot = level.robots[0].id;
		map.element(cc.p(1, 0)).startRobotData.direction = 0;

		var x = 0;

		for (var i = 1; i < level.robots.length; ++i)
		{
			map.element(cc.p(x, 0)).startForRobot = level.robots[i].id;
			map.element(cc.p(x, 0)).startRobotData.direction = 0;

			++x;

			if (x === 1)
				++x;
		}

		return map;
	},

	generateEmptyLevel: function ()
	{
		var level = new pm.data.GrasshopperLevel();

		level.name = "Новый Кузнечик";
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";
		level.robots.push(new pm.data.GrasshopperRobot());

		this._fillLevelData(level);

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();
		level.taskList.tasks[pm.GrasshopperLevelModule.Type] = new pm.data.GrasshopperTask(level);
		level.taskList.tasks[pm.GrasshopperLevelModule.Type].data[0] = 0;

		return level;
	},

	getObjectsInfo: function()
	{
		return {};
	},

	getRobotsInfo: function ()
	{
		var ret = {};
		ret[pm.GrasshopperLevelModule.RobotType] = "grasshopper_robot_left.png";
		return ret;
	},

	getRobotSpriteSheet: function (robotType)
	{
		return "Robot/grasshopper_robot.plist";
	},

	getSettings: function (robotType, object)
	{
		return new GrasshopperRobotSettingsLayer(object);
	},

	getExtraLevelSettings: function (level)
	{
		var layout = new ccui.Layout();
		layout.setLayoutType(ccui.Layout.RELATIVE);

		var leftStepCountLabel = new pmui.Text(LocalizedString("LeftStepCount"), LevelSettings.FONT_SIZE);

		var leftStepCountLabelAlign = new ccui.RelativeLayoutParameter();
		leftStepCountLabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		leftStepCountLabelAlign.setRelativeName("leftStepCountLabel");
		leftStepCountLabel.setLayoutParameter(leftStepCountLabelAlign);

		var leftStepCount = new pmui.TextInput(level.leftStepCount, "1", LevelSettings.FONT_SIZE, 40);

		var leftStepCountAlign = new ccui.RelativeLayoutParameter();
		leftStepCountAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		leftStepCountAlign.setRelativeToWidgetName("leftStepCountLabel");
		leftStepCountAlign.setMargin(5, 0, 0, 0);
		leftStepCount.setLayoutParameter(leftStepCountAlign);

		leftStepCount.setInsertTextCallback(this, function() {
			level.leftStepCount = Number(leftStepCount.getString());
		}.bind(this));

		var rightStepCountLabel = new pmui.Text(LocalizedString("RightStepCount"), LevelSettings.FONT_SIZE);

		var rightStepCountLabelAlign = new ccui.RelativeLayoutParameter();
		rightStepCountLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		rightStepCountLabelAlign.setRelativeName("rightStepCountLabel");
		rightStepCountLabelAlign.setRelativeToWidgetName("leftStepCountLabel");
		rightStepCountLabelAlign.setMargin(0, 15, 0, 0);
		rightStepCountLabel.setLayoutParameter(rightStepCountLabelAlign);

		var rightStepCount = new pmui.TextInput(level.rightStepCount, "1", LevelSettings.FONT_SIZE, 40);

		var rightStepCountAlign = new ccui.RelativeLayoutParameter();
		rightStepCountAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		rightStepCountAlign.setRelativeToWidgetName("rightStepCountLabel");
		rightStepCountAlign.setMargin(5, 0, 0, 0);
		rightStepCount.setLayoutParameter(rightStepCountAlign);

		rightStepCount.setInsertTextCallback(this, function() {
			level.rightStepCount = Number(rightStepCount.getString());
		}.bind(this));

		var editableStartPoint = new pmui.CheckBox(LocalizedString("EditableStartPoint"), function (flag)
		{
			level.isStartPointEditable = flag;
		}, this, LevelSettings.FONT_SIZE, level.isStartPointEditable);

		var editableStartPointAlign = new ccui.RelativeLayoutParameter();
		editableStartPointAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		editableStartPointAlign.setRelativeToWidgetName("rightStepCountLabel");
		editableStartPointAlign.setMargin(0, 15, 0, 0);
		editableStartPoint.setLayoutParameter(editableStartPointAlign);

		layout.addChild(leftStepCountLabel);
		layout.addChild(leftStepCount);
		layout.addChild(rightStepCountLabel);
		layout.addChild(rightStepCount);
		layout.addChild(editableStartPoint);
		layout.setContentSize(Math.max(leftStepCountLabel.width + 5 + leftStepCount.width, editableStartPoint.width),
			2 * leftStepCount.height + editableStartPoint.height + 7);

		return layout;
	},

	getExtraMapSettings: function (target)
	{
		var separator = cc.size(5, 5);
		var cellSize = cc.size(64, 64);

		var startElementPos = new pmui.SelectTableView(cellSize, separator, 1, 1);
		startElementPos.addSelectEventListener(function(selectedMapElement)
		{
			target.mapElementsTable.deselectAll();

			if (selectedMapElement)
				pm.sendCustomEvent(pme.MAP_ELEMENT_SELECT_EVENT_STR, pm.data.GrasshopperMapElement.START_ELEMENT_POS);
			else
				pm.sendCustomEvent(pme.MAP_ELEMENT_SELECT_EVENT_STR, undefined);
		}.bind(this), this);

		startElementPos.setSelectFrame(pm.spriteUtils.getInterfaceElementFrame("functionBG"), true);
		startElementPos.setFitObjectsSizes(true);

		var element = new ccui.ImageView(pm.spriteUtils.getMapGrassTileName(1), ccui.Widget.PLIST_TEXTURE);

		startElementPos.setCell(0, 0, element);
		startElementPos.setContentSize(cellSize.width + 2 * separator.width, cellSize.height + 2 * separator.height);

		return {
			layout: startElementPos,
			callback: function() {
				startElementPos.deselectAll();
			}
		}
	},

	getExtraInfo: function (level)
	{
		var layout = new pmui.OvalLayout(null, null, pmui.OvalLayout.Type.SLIM);

		var leftStepCountLabel = new pmui.Text(LocalizedString("LeftStepCount") + ": " + level.leftStepCount, pm.settings.fontSize);
		var width = leftStepCountLabel.width;
		var height = leftStepCountLabel.height + 5;

		var leftStepCountLabelAlign = new ccui.RelativeLayoutParameter();
		leftStepCountLabelAlign.setRelativeName("leftStepCountLabel");

		if (level.isStartPointEditable)
		{
			var taskLabel = new pmui.Text(LocalizedString("FindStartPoint"), 24);
			width = Math.max(taskLabel.width, width);
			height += taskLabel.height + 14;

			var taskLabelAlign = new ccui.RelativeLayoutParameter();
			taskLabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
			taskLabelAlign.setRelativeName("taskLabel");
			taskLabelAlign.setMargin(pmui.OvalLayout.SLIM_BORDER_RADIUS + 5, pmui.OvalLayout.SLIM_BORDER_RADIUS + 5, 0, 0);
			taskLabel.setLayoutParameter(taskLabelAlign);

			leftStepCountLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			leftStepCountLabelAlign.setRelativeToWidgetName("taskLabel");
			leftStepCountLabelAlign.setMargin(0, 14, 0, 0);

			layout.addChild(taskLabel);
		}
		else
		{
			leftStepCountLabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
			leftStepCountLabelAlign.setMargin(pmui.OvalLayout.SLIM_BORDER_RADIUS + 5, pmui.OvalLayout.SLIM_BORDER_RADIUS + 5, 0, 0);
		}

		leftStepCountLabel.setLayoutParameter(leftStepCountLabelAlign);

		var rightStepCountLabel = new pmui.Text(LocalizedString("RightStepCount") + ": " + level.rightStepCount, pm.settings.fontSize);
		width = Math.max(rightStepCountLabel.width, width);
		height += rightStepCountLabel.height + 12;

		var rightStepCountLabelAlign = new ccui.RelativeLayoutParameter();
		rightStepCountLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		rightStepCountLabelAlign.setRelativeName("rightStepCountLabel");
		rightStepCountLabelAlign.setRelativeToWidgetName("leftStepCountLabel");
		rightStepCountLabelAlign.setMargin(0, 7, 0, 0);
		rightStepCountLabel.setLayoutParameter(rightStepCountLabelAlign);

		layout.addChild(leftStepCountLabel);
		layout.addChild(rightStepCountLabel);
		layout.setContentSizeWithBorder(width + 10, height);

		return layout;
	},

	getMapElementsType: function()
	{
		return GrasshopperMapElementType;
	},

	getRobotDisplayedName: function (robotType)
	{
		return LocalizedString("Grasshopper");
	},

	getDisplayedNames: function()
	{
		return LocalizedString(this.name);
	},

	getMapTileset: function (tileset)
	{
		return "MapsPics/grasshopper_level-tileset{0}.plist".format(tileset);
	},

	getResources: function ()
	{
		return [
			"Robot Methods/kumir_robot-methods.plist"
		];
	},

	generateRobot: function (robotType)
	{
		return new pm.data.GrasshopperRobot();
	},

	stopResizingHeight: function (map, side)
	{
		return true;
	},

	canNotDragOrAddObject: function (map, point)
	{
		return false;
	},

	getAffineVector: function(dif)
	{
		return cc.p(dif.x / Math.sqrt(2) + dif.y / (Math.sqrt(2) * 2), dif.x / Math.sqrt(2) - dif.y / (Math.sqrt(2) * 2));
	},

	getLocalization: function(language)
	{
		switch (language)
		{
			case cc.sys.LANGUAGE_ENGLISH:
				return {
					"Grasshopper": "Grasshopper",
					"GrasshopperLevelType": "Grasshopper level",
					"GrasshopperTask": "Grasshopper task",
					"EditableStartPoint": "Editable start point",
					"FindStartPoint": "Find start point",
					"GrasshopperLevel": "Grasshopper",
					"LeftStepCount" : "Left step count",
					"RightStepCount" : "Right step count"
				};
			case cc.sys.LANGUAGE_RUSSIAN:
				return {
					"Grasshopper": "Кузнечик",
					"GrasshopperLevelType": "Кузнечик",
					"GrasshopperTask": "Задание покраски",
					"EditableStartPoint": "Ред. точки старта",
					"FindStartPoint": "Отметить точку старта",
					"GrasshopperLevel": "Кузнечик",
					"LeftStepCount" : "Шаг влево",
					"RightStepCount" : "Шаг вправо"
				};
		}
	},

	getResourcesPlist: function ()
	{
		return "PlayerRobots/GrasshopperLevel.plist";
	},

	generateTaskLayer: function (level, task)
	{
		return new GrasshopperTaskLayer(level, task);
	},

	getTaskLabel: function ()
	{
		return "GrasshopperTask";
	},

	needToRotateRobot: function()
	{
		return false;
	},

	addLevelTask: function (level)
	{
		var task = new pm.data.GrasshopperTask(level);

		for (var i = 0; i < level.maps.length;++i)
		{
			var paintValue = 0;
			var map = level.maps[i];

			for (var x = 0; x < map.width; ++x)
			{
				if (map.element(cc.p(x, 0)).originalType === GrasshopperMapElementType.UnpaintedGrass)
					++paintValue;
			}

			task.fillWithInitialData(i, paintValue);
		}

		level.taskList.addTask(pm.GrasshopperLevelModule.Type, task);

		return task;
	},

	addExtraMethods: function(robot, functions) {},

	getDepthLineSprites: function()
	{
		return {
			horizontal: pm.spriteUtils.getMapTileFrame("grasshopper-depth_horizontal"),
			vertical: pm.spriteUtils.getMapTileFrame("grasshopper-depth_vertical")
		};
	},

	getChangeableConditions: function(robot, callback, target) {},

	compareConditions: function(a, b) {},

	getLessonMapLayer: function()
	{
		return null;
	},

	getLayerPositionDelta: function()
	{
		return cc.p(Math.sqrt(2) * 64/2, Math.sqrt(2) * 64/4);
	}
});

pm.GrasshopperLevelModule.RobotType = "grasshopper-robot";
pm.GrasshopperLevelModule.Type = "grasshopper";
pm.GrasshopperLevelModule.ObjectTypes = {};
