/**
 * This namespace stores functions for world with files. <br/>
 * For html5 version uses localStorage
 * @namespace
 */
pm.fileUtils = {

	stubCallback: function() {},

	/**
     * This function saves object as json string in plist file's "data" section.
     * @param {String} path A path where to save object.
     * @param {Object} object An object to save.
     * @param {Function?} callback Callback for saving.
     */
	saveObject: function (path, object, callback)
	{
		if (cc.sys.isNative)
		{
			var saveValue = JSON.stringify({data: object});
			jsb.fileUtils.writeStringToFile(saveValue, path, callback || this.stubCallback);
		}
		else
		{
			var encodedValue = JSON.stringify({data: Base64.encode((LZString.compress(JSON.stringify(object)))), encoded: true});
			cc.sys.localStorage.setItem(path, encodedValue);
		}
	},
	/**
     * This function reads object from a plist file's "data" section.
     * @param {String} path A path to file to read.
     * @returns {Object}
     */
	readObject: function (path)
	{
		var readData = null;

		if (cc.sys.isNative)
			readData = jsb.fileUtils.getStringFromFile(path);
		else
			readData = cc.sys.localStorage.getItem(path);

		if(readData)
			readData = JSON.parse(readData);

		if (readData.data === undefined)
			return readData;

		var data = readData.data;

		if (readData.encoded)
			data = JSON.parse(LZString.decompress(Base64.decode(data)));

		return data;
	},
	/**
     * Returns a path where files can be stored
     * @returns {String}
     */
	getWritablePath: function ()
	{
		var appFolder = pm.appConfig.appType === 'pm' ? "Piktomir" : "Piktomir-K";

		if(pm.appConfig.writablePathPrefix !== "")
			appFolder = pm.appConfig.writablePathPrefix + '/' + appFolder;

		if (cc.sys.isNative)
			return jsb.fileUtils.getWritablePath() + appFolder;
		else
			return appFolder;
	},
	/**
     * Checks if file exists at path
     * @param {String} path A file path to check
     * @returns {Boolean}
     */
	isFileExist: function (path)
	{
		if (cc.sys.isNative)
			return jsb.fileUtils.isFileExist(path);
		else
			return cc.sys.localStorage.getItem(path) !== null;
	},
	/**
     * Removes file by path
     * @param {String} path A path where to remove a file
     */
	removeFile: function (path)
	{
		if (cc.sys.isNative)
			jsb.fileUtils.removeFile(path);
		else
			cc.sys.localStorage.removeItem(path);
	},
	/**
     * Creates a directory structure by path.
     * @param {String} path A full path to folder to create.
     * @returns {Boolean}
     */
	createDirectory: function (path)
	{
		if (cc.sys.isNative)
			return jsb.fileUtils.createDirectory(path);

		return true;
	},
	/**
     * Returns a full path from relative.
     * @param {String} path Relative path to file
     * @returns {String}
     */
	fullPathForFileName: function (path)
	{
		if (cc.sys.isNative)
			return jsb.fileUtils.fullPathForFilename(path);
		else
			return "res/" + path;
	},
	/**
     * Copies file from resource folder to some path.
     * @param {String} source A file path which is in resource folder.
     * @param {String} dest WHere to store a copy of file.
     */
	copyFileFromRes: function (source, dest)
	{
		if (cc.sys.isNative)
		{
			var data = jsb.fileUtils.getStringFromFile(source);
			if(data)
				jsb.fileUtils.writeStringToFile(data, dest);
		}
		else
		{
			var resData = cc.loader.getRes(source);
			if(resData)
				cc.sys.localStorage.setItem(dest, JSON.stringify(resData));
		}
	},
	/**
     * Just copies files.
     * @param {String} source A file path to source file
     * @param {String} dest A file path where to copy
     */
	copyFile: function (source, dest)
	{
		if (cc.sys.isNative)
			jsb.fileUtils.writeStringToFile(jsb.fileUtils.getStringFromFile(source), dest);
		else
			cc.sys.localStorage.setItem(dest, cc.sys.localStorage.getItem(source));
	},
	/**
     * Returns a file list in directory with full path.
     * @param {String} dir A directory where to get a file list.
     * @returns {Array<String>}
     */
	getFileList: function (dir)
	{
		var fileList = [];

		if (cc.sys.isNative)
		{
			fileList = pm.getFileList(dir);

			for (var i = 0; i < fileList.length; ++i)
				fileList[i] = dir + "/" + fileList[i];
		}
		else
		{
			for (var key in cc.sys.localStorage)
			{
				if (cc.isString(key) && key.indexOf(dir) === 0)
					fileList.push(key);
			}
		}

		return fileList;
	},
	/**
     * This function reads content form plist file in Object format
     * @param {String} file A file path to file to read.
     * @returns {Object}
     */
	getFileContent: function (file)
	{
		return cc.loader.getRes(file);
	}
};
