/**
 * Created by Nikita Besshaposhnikov on 29.03.16.
 */

/**
 * @class Layer for position4 task.
 * @extends TaskLayer
 * @constructor
 * @param {pm.AbstractLevel} level
 * @param {pm.data.PositionTask4} task
 */

var PositionTask4Layer = TaskLayer.extend(/** @lends PositionTask4Layer# */{

	ctor: function(level, task)
	{
		this._super(LocalizedString("EndPoint"), level, task, pm.GlobalTaskType.Position4);

		pm.registerCustomEventListener(pme.ADD_ROBOT, this._robotAdded.bind(this), this);
		pm.registerCustomEventListener(pme.REMOVE_ROBOT, this._robotRemoved.bind(this), this);
	},

	_onTaskRemoved: function()
	{
		for(var i = 0; i < this._level.maps.length; ++i)
			this._level.maps[i].mapLayer.removePositionMarks();
	},

	_robotRemoved: function(event)
	{
		var robot = event.getUserData();

		for(var i = 0 ; i < this._level.maps.length; ++i)
			delete this._task.data[i][robot.id];
	},

	_robotAdded: function(event)
	{
		var robot = event.getUserData();

		for(var i = 0 ; i < this._level.maps.length; ++i)
		{
			var mapWidth = this._level.maps[i].width;
			var mapHeight = this._level.maps[i].height;

			this._task.data[i][robot.id] = cc.p(mapWidth-1, mapHeight-1);
			this._level.maps[i].mapLayer.checkPositionTasks();
		}
	}
});
