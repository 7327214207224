/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * Painr map element types.
 * @enum {Number}
 * @readonly
 */
var KumirMapElementType = {
	None: -1,
	LightBlueGrass: 0,
	BlueGrass: 1,
	UnpaintedGrass: 2
};

/**
 * @class Elements for {@link pm.data.KumirMap}
 * @implements pm.MapElement
 */
pm.data.KumirMapElement = pm.MapElement4WithWalls.extend(/** @lends pm.data.KumirMapElement# */{
	typeName: "KumirMapElement",

	_radiation: 0,
	_radiationLabel: null,
	_temperature: 0,
	_temperatureLabel: null,
	_paintCount: 0,
	_paintTarget: false,

	ctor: function()
	{
		this._addNonEnumerableProps("_paintCount", "_radiationLabel", "_temperatureLabel", "_paintTarget");

		this._super();
	},

	generateTerrainSprite: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (pm.appUtils.useExtraMethodsInKumirRobot())
			{
				this.sprite = new pm.TerrainSprite(pm.spriteUtils.getMapGrassTileFrame(this._type));

				this._notCompletedSprite = new cc.Sprite("MapsPics/mapElement_error_selection.png");

				this._notCompletedSprite.setPosition(cc.p(this.sprite.getSprite().getContentSize().width / 2, this.sprite.getSprite().getContentSize().height / 2));
				this._notCompletedSprite.setAnchorPoint(cc.p(0.5, 0.5));
				this._notCompletedSprite.setVisible(false);

				this.sprite.getSprite().addChild(this._notCompletedSprite, 10);

				this._radiationLabel = new ccui.Text(this._radiation, pm.settings.fontName, 16);

				this._radiationLabel.setAnchorPoint(cc.p(0, 1));
				this._radiationLabel.setPosition(pm.data.KumirMapElement.TEXT_MARGIN, this.sprite.getSprite().height - pm.data.KumirMapElement.TEXT_MARGIN);

				this._temperatureLabel = new ccui.Text(this._temperature, pm.settings.fontName, 16);

				this._temperatureLabel.setAnchorPoint(cc.p(1, 1));
				this._temperatureLabel.setPosition(this.sprite.getSprite().width - pm.data.KumirMapElement.TEXT_MARGIN, this.sprite.getSprite().height - pm.data.KumirMapElement.TEXT_MARGIN);

				if (pm.settings.isEditorMode && !previewDraw)
				{
					this.sprite.getSprite().addChild(this._radiationLabel, 10);
					this.sprite.getSprite().addChild(this._temperatureLabel, 10);
				}

				return this.sprite;
			}
			else
			{
				return pm.MapElement2D.prototype.generateTerrainSprite.call(this)
			}
		}
	},

	restore: function()
	{
		this._paintCount = 0;
		pm.MapElement.prototype.restore.call(this);
	},

	/**
     * Paints element.
     */
	paint: function()
	{
		if(this._type === KumirMapElementType.UnpaintedGrass)
			this.setType(KumirMapElementType.BlueGrass);
		else if(this._type === KumirMapElementType.LightBlueGrass)
			this.setType(KumirMapElementType.BlueGrass);

		++this._paintCount;
	},

	/**
     * Returns element to broken state.
     */
	unpaint: function()
	{
		--this._paintCount;

		if(this._paintCount === 0)
		{
			if (this._type === KumirMapElementType.BlueGrass)
				this.setType(KumirMapElementType.UnpaintedGrass);
			else if (this._type === KumirMapElementType.BlueGrass)
				this.setType(KumirMapElementType.LightBlueGrass);
		}
	},

	/**
     * Checks is element is painted.
     * @returns {Boolean}
     */
	isPainted: function()
	{
		return this.originalType === KumirMapElementType.UnpaintedGrass && this._type === KumirMapElementType.BlueGrass;
	},

	/**
	 * Checks is element is bad painted.
	 * @returns {Boolean}
	 */
	isBadPainted: function()
	{
		return this.originalType === KumirMapElementType.LightBlueGrass && this._type === KumirMapElementType.BlueGrass && !this._paintTarget;
	},

	isTargetPainted: function()
	{
		return this._paintTarget && this._type === KumirMapElementType.BlueGrass;
	},

	getRadiation: function()
	{
		return this._radiation;
	},

	setRadiation: function(radiation)
	{
		this._radiation = radiation;
		this._radiationLabel.setString(radiation);
	},

	getTemperature: function()
	{
		return this._temperature;
	},

	setTemperature: function(temperature)
	{
		this._temperature = temperature;
		this._temperatureLabel.setString(temperature);
	},

	getState: function ()
	{
		return this._type;
	},

	setState: function (state)
	{
		this.setType(state);
	}
});

pm.data.KumirMapElement.TEXT_MARGIN = 8;
pm.data.KumirMapElement.SELECT_ELEMENT_PARAMS = "select_element_params";
pm.data.KumirMapElement.LABEL_TAG = 10;
pm.data.KumirMapElement.RADIATION_TAG = 11;
pm.data.KumirMapElement.TEMPERATURE_TAG = 12;
pm.data.KumirMapElement.START_ELEMENT_POS = "not_completed_element_pos";
