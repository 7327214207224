"use strict";

/**
 * Created by Nikita Besshaposhnikov on 15.07.19.
 */

/**
 * @class Magnet physical robot. Works only with {@link PlayerRobot4} subclasses.
 * (Supports repair by {@link pm.data.RepairRobot})
 * @extends pm.PhysicalConnector
 */
pm.MagnetPhysicalConnector = pm.PhysicalConnector.extend(/** @lends pm.MagnetPhysicalConnector# */{

	_client: null,
	_magnetHost: 0,
	_sendConnectTime: null,
	_lastPacketTime: null,
	_lastStatusPacketTime: null,
	_reconnectCount: 0,

	_packetQueue: [],
	_packetsProcessedConfirm: {},
	_magnetCommands: [],
	_packetNumber: 0,
	_waitingForPacketProcessed: false,
	_paused: false,

	_level: null,
	_robotMap: null,
	_objectMap: null,
	_mapCoordinatesTable: {},
	_mapCoordinatedReceived: false,
	_levelInitialized: false,

	_logList: null,

	ctor: function(level)
	{
		this._super(level);
		this._level = level;

		this._robotMap = {};

		for(var r = 0; r < level.robots.length; ++r)
		{
			this._robotMap[level.robots[r].id] = {
				robot: level.robots[r],
				hasCommand: false
			};
		}

		this._objectMap = {};

		for(var o = 0; o < level.activeMap.objects.length; ++o)
		{
			this._objectMap[level.activeMap.objects[o].id] = {
				object: level.activeMap.objects[o],
				hasAnimation: false
			};
		}

		this._mapCoordinatesTable = {};
		this._magnetCommands = [];
		this._packetQueue = [];
		this._packetsProcessedConfirm = {};

		if(pm.appConfig.showMagnetLog)
			this._addDebugWindow();
	},

	_addDebugWindow: function()
	{
		this._logList = new ccui.ListView();
		this._logList.setContentSize(800, 200);
		this._logList.setPosition(100, 100);
		this._logList.setBackGroundColorType(ccui.Layout.BG_COLOR_SOLID);
		this._logList.setBackGroundColor(cc.color.WHITE);

		this._logList.retain();

		setTimeout(function() {
			cc.director.getRunningScene().addChild(this._logList, 2000, 2000);
			this._logList.release();
		}.bind(this), 1000);
	},

	connect: function()
	{
		this._client = new pm.MagnetClient();
		var started = this._client.start(pm.appConfig.magnetUDPPort);

		this._log("UDP client started: " + started);

		if(started)
		{
			this._packetNumber = pm.MagnetPhysicalConnector.START_PACKET_NUMBER;

			this._client.setDataCallback(this._dataCallback.bind(this));
			this._sendConnectPacket();

			cc.director.getScheduler().schedule(
				this._loop, this, pm.MagnetPhysicalConnector.LOOP_INTERVAL,
				cc.REPEAT_FOREVER, 0, false, pm.MagnetPhysicalConnector.LOOP_NAME
			);
		}
	},

	isConnected: function()
	{
		return this._connected && this._mapCoordinatedReceived;
	},

	_sendPacket: function(ip, packet, number)
	{
		var sendPacket = packet;

		if(number !== undefined)
			sendPacket += String.fromCharCode(number);

		sendPacket = String.fromCharCode(pm.MagnetPhysicalConnector.BYTES.SR_START) +
			sendPacket +
			String.fromCharCode(pm.MagnetPhysicalConnector.BYTES.SEND_END);

		this._client.sendPacket(
			ip,
			pm.appConfig.magnetUDPPort,
			pm.BROADCAST_PACKET_TYPE.DISCOVER_INFO,
			sendPacket
		);
	},

	_requestMapCoordinates: function()
	{
		this._client.sendPacket(
			this._magnetHost,
			pm.appConfig.magnetUDPPort,
			pm.BROADCAST_PACKET_TYPE.DATA_REQUEST,
			"MAPREQ"
		);
	},

	_sendStatusPacket: function()
	{
		this._client.sendPacket(
			this._magnetHost,
			pm.appConfig.magnetUDPPort,
			pm.BROADCAST_PACKET_TYPE.DATA_REQUEST,
			"STS"
		);
	},

	_extractReceivedPacketData: function(bytes)
	{
		if(bytes[0] === pm.MagnetPhysicalConnector.BYTES.CONNECTED_START)
		{
			this._packetNumber = bytes[2] + 1;

			return {
				packet: "WHO",
				number: this._packetNumber,
				bytes: [],
				type: pm.MagnetPhysicalConnector.PACKET_TYPE.CONNECTED
			};
		}

		if(bytes[0] !== pm.MagnetPhysicalConnector.BYTES.SR_START &&
			bytes[0] !== pm.MagnetPhysicalConnector.BYTES.STATUS_START &&
			bytes[0] !== pm.MagnetPhysicalConnector.BYTES.SYSTEM &&
			bytes[0] !== pm.MagnetPhysicalConnector.BYTES.MAP_START)
			return null;

		var checkSize = bytes.length;

		if(bytes[0] === pm.MagnetPhysicalConnector.BYTES.STATUS_START)
		{
			// search for terminating byte
			for (var b = checkSize - 1; b > 0; --b)
			{
				if(bytes[b] === pm.MagnetPhysicalConnector.BYTES.STATUS_END)
				{
					checkSize = b + 1;
					break;
				}
			}
		}
		else if(bytes[0] !== pm.MagnetPhysicalConnector.BYTES.MAP_START)
		{
			checkSize -= 2;
		}

		if(bytes[checkSize - 1] !== pm.MagnetPhysicalConnector.BYTES.DELIVERED_END &&
			bytes[checkSize - 1] !== pm.MagnetPhysicalConnector.BYTES.STATUS_END &&
			bytes[checkSize - 1] !== pm.MagnetPhysicalConnector.BYTES.SYSTEM &&
			bytes[checkSize - 1] !== pm.MagnetPhysicalConnector.BYTES.MAP_END)
			return null;

		--checkSize; // skip last byte

		var parsedPacket = {
			packet: "",
			number: -1,
			bytes: [],
			type: pm.MagnetPhysicalConnector.PACKET_TYPE.DELIVER_CONFIRM
		};

		var lastByte = checkSize;

		switch(bytes[checkSize])
		{
			case pm.MagnetPhysicalConnector.BYTES.SYSTEM:
				parsedPacket.type =  pm.MagnetPhysicalConnector.PACKET_TYPE.SYSTEM;
				break;
			case pm.MagnetPhysicalConnector.BYTES.STATUS_END:
				parsedPacket.type =  pm.MagnetPhysicalConnector.PACKET_TYPE.STATUS;
				break;
			case pm.MagnetPhysicalConnector.BYTES.MAP_END:
				parsedPacket.type =  pm.MagnetPhysicalConnector.PACKET_TYPE.MAP;
				break;
			case pm.MagnetPhysicalConnector.BYTES.DELIVERED_END:
				parsedPacket.type =  pm.MagnetPhysicalConnector.PACKET_TYPE.DELIVER_CONFIRM;
				//Get number of packet
				lastByte = checkSize - 1;
				parsedPacket.number = bytes[lastByte];
				break;
		}

		for (var i = 1; i < lastByte; ++i) {
			parsedPacket.packet += String.fromCharCode(bytes[i]);
			parsedPacket.bytes.push(bytes[i])
		}

		return parsedPacket;
	},

	_sendConnectPacket: function()
	{
		this._sendConnectTime = new Date();

		this._sendPacket(pm.convertIPAddress("255.255.255.255"), "WHO", 1);
		this._stateCallback(pm.PhysicalConnector.STATE.CONNECTING);
	},

	disconnect: function()
	{
		this._sendPacket(this._magnetHost, "INI", 1);// Reset
		this._sendPacket(this._magnetHost, "Off", 1);// Turn off lights

		this._client.clearCallback();
		this._client.stop();
		this._client = null;

		if(this._stateCallback)
			this._stateCallback(pm.PhysicalConnector.STATE.DISCONNECTED);

		cc.director.getScheduler().unschedule(pm.MagnetPhysicalConnector.LOOP_NAME, this);
	},

	_dataCallback: function(host, port, bytes)
	{
		this._lastPacketTime = new Date();

		var string = "";
		var stringBytes = "";

		for(var i = 0 ; i < bytes.length; ++i)
			string += String.fromCharCode(bytes[i]);

		for(var j = 0 ; j < bytes.length; ++j)
		{
			var stringByte = bytes[j].toString(16);

			if(stringByte.length === 1)
				stringByte = "0" + stringByte;
			stringBytes += stringByte + " ";
		}

		if(string.startsWith("I=") || string.startsWith("Health"))
			return;

		// bytes.length = 6; // remove trailing trash bytes

		var packetData = this._extractReceivedPacketData(bytes);

		if(!packetData)
		{
			this._log(
				"Received incorrect packet from magnet host: " + host +
				";\ndata(string): " + string +
				";\ndata(bytes): " + stringBytes +
				";\nlength: " + bytes.length
			);
			this._udpLog("Wrong answer received bytes:" + stringBytes + " str:" + string);

			return;
		}

		this._log(
			"Received string data from magnet host: " + host +
			";\ndata(string): " + packetData.packet + " number: " + packetData.number +
			" type: " + packetData.type + " bytes(length): " + packetData.bytes.length +
			";\ndata(bytes): " + stringBytes +
			";\nlength: " + bytes.length
		);

		if (!this._connected && packetData.type === pm.MagnetPhysicalConnector.PACKET_TYPE.CONNECTED)
		{
			this._connected = true;

			this._log("Received connected data from magnet host: " + host);

			this._magnetHost = host;

			if(this._stateCallback)
				this._stateCallback(pm.PhysicalConnector.STATE.CONNECTED);

			if(!this._levelInitialized)
			{
				pm.registerCustomEventListener(pm.PROGRAM_RESTART_EVENT_STR, this._programRestart.bind(this), 100);
				this._requestMapCoordinates();
			}
		}

		if(this._connected)
		{
			switch(packetData.type)
			{
				case pm.MagnetPhysicalConnector.PACKET_TYPE.MAP:
					this._parseMapCoordinates(packetData.packet);
					this._initLevel();

					break;
				case pm.MagnetPhysicalConnector.PACKET_TYPE.DELIVER_CONFIRM:
					if (this._packetQueue.length > 0)
						this._processPacketQueue(packetData);
					break;
				case pm.MagnetPhysicalConnector.PACKET_TYPE.SYSTEM:
					switch(packetData.packet)
					{
						case "ERR":
							this._log("Received error packet");
							this._cleanup();
							this.disconnect();
							break;
						case "OFF":
							this._log("Received of packet");
							this._cleanup();
							this.disconnect();
							break;
						case "PAU":
							this._log("Received pause packet");
							this._paused = true;
							break;
						case "PLA":
							this._log("Received play packet");
							this._paused = false;
							break;
						case "PAS":
							this._log("Received pass packet");
							this._finalizeRunningCommands();
							break;
					}
					break;
				case pm.MagnetPhysicalConnector.PACKET_TYPE.STATUS:
					if(this._waitingForPacketProcessed)
					{
						for (var b = 0; b < packetData.bytes.length; ++b)
						{
							var donePacketNumber = packetData.bytes[b];

							if (this._packetsProcessedConfirm[donePacketNumber] === undefined)
								continue;

							if (this._packetsProcessedConfirm[donePacketNumber])
								continue;

							this._packetsProcessedConfirm[donePacketNumber] = true;
							this._log("Packet with number {0} processed!".format(donePacketNumber));
						}

						this._endRunningCommands();
					}


					// if (packetData.packet === "-Ok" && packetData.number !== 1)
					// {
					// 	var donePacketNumber = packetData.number - pm.MagnetPhysicalConnector.PACKET_NUMBER_OFFSET;
					//
					// 	if (this._packetsProcessedConfirm[donePacketNumber] === undefined)
					// 		return;
					//
					// 	this._packetsProcessedConfirm[donePacketNumber] = true;
					//
					// 	this._log("Packet with number {0} processed!".format(donePacketNumber));
					// 	this._endRunningCommands();
					// 	return;
					// }
					break;
			}
		}
	},

	_loop: function()
	{
		if(this._paused)
			return;

		var curTime = new Date();

		if(!this._connected && this._sendConnectTime)
		{
			var diff = curTime - this._sendConnectTime;

			if(this._reconnectCount < pm.MagnetPhysicalConnector.MAX_RECONNECT_COUNT &&
				diff > pm.MagnetPhysicalConnector.RESEND_CONNECT_TIMEOUT)
			{
				this._log("Reconnect to magnet host due to timeout");
				this._sendConnectPacket();
				++this._reconnectCount;
			}
		}
		else if(curTime - this._lastPacketTime > pm.MagnetPhysicalConnector.RECONNECT_TIMEOUT)
		{
			this._log("Disconnected(no packets from host) due to timeout");

			this._connected = false;

			this._sendConnectPacket();
		}
		else
		{
			this._checkPacketQueue(curTime);
		}
	},

	_checkPacketQueue: function(curTime)
	{
		if(this._packetQueue.length > 0)
		{
			if(!this._packetQueue[0].sent)
				return;

			var sendTime = this._packetQueue[0].sendTime;
			var diff = curTime - sendTime;

			if(diff >= pm.MagnetPhysicalConnector.RESEND_PACKET_TIMEOUT)
			{
				this._log("Resend due to timeout(" + diff + ") packet: " + this._packetQueue[0].packet);

				this._packetQueue[0].retryCount += 1;

				this._sendQueuedPacket();
			}
		}
		else if(this._waitingForPacketProcessed)
		{
			var diff = curTime - this._lastStatusPacketTime;

			if(diff >= pm.MagnetPhysicalConnector.STATUS_SEND_TIMEOUT)
			{
				this._sendStatusPacket();

				this._lastStatusPacketTime = curTime;
			}
		}
	},

	_processPacketQueue: function(packetData)
	{
		var packetNumber = this._packetQueue[0].packetNumber;
		var donePacketNumber = packetData.number;

		if(packetData.packet === this._packetQueue[0].packet && packetNumber === donePacketNumber)
		{
			this._packetsProcessedConfirm[packetNumber] = false;

			this._packetQueue.splice(0, 1);
			this._udpLog("                                       # DONE");
			this._udpLog("Command was sent:" + packetData.packet);

			if (this._packetQueue.length > 0)
			{
				this._log("Sending queued packet: " + this._packetQueue[0].packet);

				this._sendQueuedPacket();
			}
			else // here all packets sent, start waiting for execution(senddig status packets)
			{
				this._log("Waiting for commands are processed");

				this._waitingForPacketProcessed = true;
			}
		}
	},

	_endRunningCommands: function()
	{
		if(!this._waitingForPacketProcessed)
			return;

		var allConfirmed = true;

		for(var p in this._packetsProcessedConfirm)
		{
			if(!this._packetsProcessedConfirm[p])
			{
				allConfirmed = false;
				break;
			}
		}

		if(allConfirmed)
		{
			this._log("Ended run commands");
			this._finalizeRunningCommands();
		}
	},

	_finalizeRunningCommands: function()
	{
		this._cleanup();
		this._busy = false;
		this._waitingForPacketProcessed = false;
		this._packetsProcessedConfirm = {}
	},

	_cleanup: function()
	{
		for(var r in this._robotMap)
			this._robotMap[r].hasCommand = false;

		for(var o in this._objectMap)
			this._objectMap[o].hasAnimation = false;

		this._magnetCommands = [];
		this._packetQueue = [];
	},

	_programRestart: function()
	{
		this._log("Program restarted");
		this._finalizeRunningCommands();

		this._queuePacket("RST");
		this._initLevel();
	},

	_queuePacket: function(packet)
	{
		if(this._packetNumber > pm.MagnetPhysicalConnector.END_PACKET_NUMBER)
			this._packetNumber = pm.MagnetPhysicalConnector.START_PACKET_NUMBER;

		if(this._packetNumber >= pm.MagnetPhysicalConnector.EXCLUDE_PACKET_NUMBER.START &&
			this._packetNumber < pm.MagnetPhysicalConnector.EXCLUDE_PACKET_NUMBER.END)
		{
			this._packetNumber = pm.MagnetPhysicalConnector.EXCLUDE_PACKET_NUMBER.END;
		}

		this._packetQueue.push({
			packet: packet,
			sent: false,
			sendTime: new Date(),
			retryCount: 0,
			packetNumber: this._packetNumber
		});

		++this._packetNumber;

		if(this._packetQueue.length === 1) // first packet send immediately
		{
			this._log("Sending immediately packet: " + packet);
			this._sendQueuedPacket();
		}
	},

	_sendQueuedPacket: function()
	{
		var number = this._packetQueue[0].packetNumber;
		var packet = this._packetQueue[0].packet;

		this._packetQueue[0].sent = true;
		this._packetQueue[0].sendTime = new Date();

		this._sendPacket(this._magnetHost, packet, number);
		this._udpLog("                                    #    Sent");
	},

	_addMagnetStepCommands: function(commands)
	{
		for(var i = 0; i < commands.length; ++i)
			this._magnetCommands.push(commands[i]);

		var sendCommands = true;

		for (var r in this._robotMap)
		{
			if (!this._robotMap[r].hasCommand)
			{
				sendCommands = false;
				break;
			}
		}

		if(sendCommands)
		{
			var passiveObjectsCommands = this._getPassiveObjectsCommands();

			for(var j = 0; j < passiveObjectsCommands.length; ++j)
				this._magnetCommands.push(passiveObjectsCommands[j]);

			this._sendMagnetCommands();
		}
	},

	_sendMagnetCommands: function()
	{
		for (var i = 0; i < this._magnetCommands.length; ++i)
		{
			this._log("Sending magnet commands " + this._magnetCommands[i]);
			this._queuePacket(this._magnetCommands[i]);
		}

		this._queuePacket("GO!");
	},

	executeRobotCommand: function(id, command, state)
	{
		if(!this._connected)
			return;

		this._busy = true;

		var magnetCommands = this._getMagnetRobotCommands(this._robotMap[id].robot, command, state);

		this._log("Sending robot command to Magnet Physical robot: " + command);

		this._robotMap[id].hasCommand = true;
		this._addMagnetStepCommands(magnetCommands);
	},

	playObjectAnimation: function(id, animation)
	{
		if(!this._connected)
			return;

		this._busy = true;

		var magnetCommands = this._getMagnetObjectCommands(this._objectMap[id].object, animation);

		this._log("Sending object animation to Magnet Physical robot: " + animation);

		this._objectMap[id].hasAnimation = true;
		this._addMagnetStepCommands(magnetCommands);
	},

	_getMagnetRobotCommands: function(robot, robotCommand, state)
	{
		var direction = state.direction;
		var position = state.position; //robot already moved need to calculate prev position
		var nextPos = this._level.activeMap.getNextDirectionalElementPosition(position, direction);

		if(!nextPos)
			nextPos = position;

		//inverting robot coordinates
		var magnetPos = this._getMagnetCoordinatesFromMap(position);
		var magnetNextPos = this._getMagnetCoordinatesFromMap(nextPos);
		var magnetDirection = this._getMagnetRobotDirection(direction);

		var commands = [];

		switch (robotCommand)
		{
			case PlayerRobot4.NativeMethod.Move:
				var method = "M";

				if (robot.getType() ===  pm.PushLevelModule.RobotTypes.PullRobot)
						method = "T";

				commands.push(magnetPos + method + magnetDirection);
				commands.push(magnetNextPos + method + magnetDirection);
				break;
			case pm.data.PullRobot.NativeMethod.Pull:
				commands.push(magnetPos + "T" + magnetDirection);
				commands.push(magnetNextPos + "T" + magnetDirection);
				break;
			case PlayerRobot4.NativeMethod.TurnLeft:
				var leftDirection = this._getMagnetRobotDirection((direction + 1) % 4);
				commands.push(magnetPos + magnetDirection + leftDirection);
				break;
			case PlayerRobot4.NativeMethod.TurnRight:
				var rightDirection = this._getMagnetRobotDirection((direction + 3) % 4);
				commands.push(magnetPos + magnetDirection + rightDirection);
				break;
			case pm.CMD_INDICATE:
				commands.push(magnetPos + "FW");
				commands.push(magnetPos + "S" + magnetDirection);
				break;
			case pm.data.RepairRobot.NativeMethod.Repair:
				commands.push(magnetPos + "PB");
				commands.push(magnetPos + "S" + magnetDirection);
				break;
		}

		return commands;
	},

	_getMagnetObjectCommands: function(object, objectAnimation)
	{
		var position = object.oldPosition;
		var nextPos = object.position;

		var magnetPos = this._getMagnetCoordinatesFromMap(position);
		var magnetNextPos = this._getMagnetCoordinatesFromMap(nextPos);
		var magnetDirection = this._getMagnetObjectDirection(position, nextPos);

		var commands = [];

		if(objectAnimation === PushObjectAnimation.Move)
		{
			commands.push(magnetPos + "m" + magnetDirection);
			commands.push(magnetNextPos + "m" + magnetDirection);
		}

		return commands;
	},

	_getPassiveObjectsCommands: function()
	{
		var commands = [];

		for (var o in this._objectMap)
		{
			if (!this._objectMap[o].hasAnimation)
			{
				var position = this._objectMap[o].object.position;

				var magnetPos = this._getMagnetCoordinatesFromMap(position);
				commands.push(magnetPos + "SS");
			}
		}

		return commands;
	},

	_initLevel: function()
	{
		var map = this._level.activeMap;

		for(var i = 0; i < map.objects.length; ++i)
		{
			var object = map.objects[i];
			var objectType = "";

			switch (object.getType())
			{
				case pm.PushLevelModule.ObjectTypes.PushBarrel:
					objectType = "O";
					break;
				case pm.PushLevelModule.ObjectTypes.PushBox:
					objectType = "X";
					break;
			}

			var objMagnetPos = this._getMagnetCoordinatesFromMap(object.startPosition);

			this._magnetCommands.push(objMagnetPos + "s" + objectType);
		}

		var task = this._level.taskList.getTask(pm.GlobalTaskType.Position4);
		var mapIndex = this._level.getActiveMapIndex();

		if(task)
		{
			for(var id in task.data[mapIndex])
			{
				var targetPos = this._getMagnetCoordinatesFromMap(task.data[mapIndex][id]);
				this._magnetCommands.push(targetPos + "IF");
			}
		}

		for (var x = 0; x < map.width; ++x)
		{
			for (var y = 0; y < map.height; ++y)
			{
				var element = map.element(cc.p(x, y));
				var magnetPos = this._getMagnetCoordinatesFromMap(cc.p(x, y));

				var leftWall = element.walls[MapDirection4.Left];
				var upWall = element.walls[MapDirection4.Up];

				if (leftWall && upWall)
					this._magnetCommands.push(magnetPos + "NE");
				else if (leftWall)
					this._magnetCommands.push(magnetPos + "EE");
				else if (upWall)
					this._magnetCommands.push(magnetPos + "NN");

				if(element.startForRobot !== pm.MapElement.START_FOR_NO_ROBOT)
				{
					var robot = this._robotMap[element.startForRobot].robot;
					var robotType = "";

					var direction = this._getMagnetRobotDirection(element.startRobotData.direction);

					switch (robot.getType())
					{
						case pm.RepairLevelModule.RobotType:
							robotType = "v";
							break;
						case pm.PushLevelModule.RobotTypes.PushRobot:
							robotType = "d";
							break;
						case pm.PushLevelModule.RobotTypes.PullRobot:
							robotType = "t";
							break;
					}

					this._magnetCommands.push(magnetPos + "IF");
					this._magnetCommands.push(magnetPos + robotType + direction);
				}

				if(element instanceof pm.data.RepairMapElement)
				{
					switch (element.originalType)
					{
						case RepairMapElementType.BlueGrass:
							this._magnetCommands.push(magnetPos + "IB");
							break;
						case RepairMapElementType.BrokenGrass:
							this._magnetCommands.push(magnetPos + "IQ");
							break;
						case RepairMapElementType.RepairedGrass:
							this._magnetCommands.push(magnetPos + "IV");
							break;
					}
				}

				if(element instanceof pm.data.PushMapElement)
				{
					switch (element.originalType)
					{
						case PushMapElementType.BarrelTarget:
							this._magnetCommands.push(magnetPos + "IO");
							break;
						case PushMapElementType.BoxTarget:
							this._magnetCommands.push(magnetPos + "IX");
							break;
						case PushMapElementType.CommonTarget:
							this._magnetCommands.push(magnetPos + "IY");
							break;
					}
				}
			}
		}

		this._sendMagnetCommands();

		this._magnetCommands = [];

		this._levelInitialized = true;
	},

	_parseMapCoordinates: function(packet)
	{
		var coordinates = packet.split(';');

		for(var i = 0; i < coordinates.length; ++i)
		{
			var data = coordinates[i].split(',');

			if(data.length > 2)
			{
				var x = Number(data[0]);
				var y = Number(data[1]);
				var mapSymbol = data[2];

				if(this._mapCoordinatesTable[x] === undefined)
					this._mapCoordinatesTable[x] = {};

				this._mapCoordinatesTable[x][y] = mapSymbol;
			}
		}

		this._mapCoordinatedReceived = true;
	},

	_getMagnetCoordinatesFromMap: function(p)
	{
		return this._mapCoordinatesTable[p.x][p.y];
	},

	_getMagnetRobotDirection: function(robotDirection)
	{
		switch(robotDirection)
		{
			case MapDirection4.Up: return "U";
			case MapDirection4.Down: return "D";
			case MapDirection4.Left: return "L";
			case MapDirection4.Right: return "R";
		}

		return "";
	},

	_getMagnetObjectDirection: function(position, nextPos)
	{

		if(nextPos.y > position.y)
			return "D";

		if(nextPos.y < position.y)
			return "U";

		if(nextPos.x > position.x)
			return "R";

		if(nextPos.x < position.x)
			return "L";

		return "";
	},

	_log: function(message)
	{
		cc.log("Magnet Connector: "+ message);

		if(this._logList)
		{
			var text = new ccui.Text(message, pm.settings.fontBoldName, 12);
			text.setTextColor(cc.color.BLACK);
			this._logList.pushBackCustomItem(text);

			this._logList.jumpToBottom();
		}
	},

	_udpLog: function(message)
	{
		if(this._client)
		{
			this._client.sendPacket(
				pm.convertIPAddress(pm.appConfig.magnetLogUDPAddress),
				pm.appConfig.magnetLogUDPPort,
				pm.BROADCAST_PACKET_TYPE.DISCOVER_INFO,
				message
			);
		}
	}
});

pm.MagnetPhysicalConnector.RECONNECT_TIMEOUT = 5000;
pm.MagnetPhysicalConnector.RESEND_CONNECT_TIMEOUT = 2000;
pm.MagnetPhysicalConnector.RESEND_PACKET_TIMEOUT = 500;
pm.MagnetPhysicalConnector.MAX_RECONNECT_COUNT = 20;
pm.MagnetPhysicalConnector.STATUS_SEND_TIMEOUT = 80;
pm.MagnetPhysicalConnector.LOOP_INTERVAL = 0.01;
pm.MagnetPhysicalConnector.LOOP_NAME = "magnet_connector_loop";
pm.MagnetPhysicalConnector.START_PACKET_NUMBER = 0x10;
pm.MagnetPhysicalConnector.END_PACKET_NUMBER = 125;
pm.MagnetPhysicalConnector.EXCLUDE_PACKET_NUMBER = {START: 0x39, END: 0x55};

pm.MagnetPhysicalConnector.BYTES = {
	SR_START: 8,
	CONNECTED_START: 2,
	SEND_END: 9,
	DELIVERED_END: 3,
	STATUS_START: 126,
	STATUS_END: 5,
	SYSTEM: 95,
	MAP_START: 0x21,
	MAP_END: 0x3F
};

pm.MagnetPhysicalConnector.PACKET_TYPE = {
	STATUS: 0,
	DELIVER_CONFIRM: 1,
	CONNECTED: 2,
	SYSTEM: 3,
	MAP: 4
};
