/**
 * Created by Nikita Besshaposhnikov on 09.04.15.
 */

/**
 * @class 2D robot which can push no more than two objects in one line.
 * @implements PlayerRobot4
 */
pm.data.PushRobot = PlayerRobot4Walls.extend(/** @lends pm.data.PushRobot# */{
	typeName: "PushRobot",

	ctor: function()
	{
		this._super();

		this.conditions.push(pm.data.PushRobot.Condition.CanMoveForward);
		this.conditionOpposites.push(pm.data.PushRobot.Condition.CanNotMoveForward);
	},

	reset: function()
	{
		PlayerRobot4.prototype.reset.call(this);
	},

	_canMoveTo: function (pos, direction)
	{
		var map = this.getMap();

		var lastObjectPosition = pos;
		var objectBlockLength = 0;

		direction = (direction + 2) % 4;

		while (map.element(lastObjectPosition).getObjectCount() > 0 &&
		!map.element(lastObjectPosition).walls[direction])
		{
			for (var o in map.element(lastObjectPosition)._objectsHere)
			{
				var object = map.element(lastObjectPosition)._objectsHere[o];

				if (!(object instanceof PushObject))
				{
					continue;
				}

				if (object.isMoving())
				{
					return false;
				}
			}

			++objectBlockLength;

			var nextPos = map.getNextDirectionalElementPosition(lastObjectPosition, this.direction);

			if (nextPos)
			{
				lastObjectPosition = nextPos;
			}
			else
			{
				return false;
			}
		}

		return objectBlockLength <= 2 && !map.element(lastObjectPosition).walls[direction];
	},

	_moveTo: function(pos, reverse)
	{
		var map = this.getMap();

		var lastObjectPosition = pos;
		var objectBlockLength = 0;

		if(!pos || !map.element(lastObjectPosition))
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		while(map.element(lastObjectPosition).getObjectCount() > 0)
		{
			++objectBlockLength;

			var nextPos = map.getNextDirectionalElementPosition(lastObjectPosition, this.direction);

			if (nextPos)
				lastObjectPosition = nextPos;
			else
				break;
		}

		if (lastObjectPosition.x !== pos.x || lastObjectPosition.y !== pos.y)
		{
			for (var r in map.element(lastObjectPosition)._robotsHere)
			{
				var robot = map.element(lastObjectPosition)._robotsHere[r];

				if (!(robot instanceof pm.AbstractRobot))
					continue;

				this.setStateFlag(pm.RobotState.Broken);

				return;
			}
		}

		var wasBroken = this.isBroken();

		PlayerRobot4.prototype._moveTo.call(this, pos, reverse);

		if(wasBroken || this.isBroken() || reverse)
			return;

		var objectNextPos = lastObjectPosition;

		for (var j = 0; j < objectBlockLength; ++j)
		{
			lastObjectPosition = map.getPrevDirectionalElementPosition(lastObjectPosition, this.direction);
			var element = map.element(lastObjectPosition);

			for (var i in element._objectsHere)
			{
				var object = element._objectsHere[i];

				if (!(object instanceof PushObject) || object.isMoving())
					continue;

				object.moveTo(objectNextPos);
			}
			objectNextPos = lastObjectPosition;
		}

	},

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new PushRobotSprite();

			return this.sprite;
		}
	},

	_isClearForward: function()
	{
		var map = this.getMap();
		var pos = map.getNextDirectionalElementPosition(this.position, this.direction);

		if (!pos)
			return false;

		var lastObjectPosition = pos;
		var objectBlockLength = 0;

		var direction = (this.direction + 2) % 4;

		while(map.element(lastObjectPosition).getObjectCount() > 0 &&
		     !map.element(lastObjectPosition).walls[direction])
		{
			var hasObjects = true;

			for (var o in map.element(lastObjectPosition)._objectsHere)
			{
				var object = map.element(lastObjectPosition)._objectsHere[o];

				if (!(object instanceof PushObject))
					continue;

				if(object.oldPosition.x !== lastObjectPosition.x || object.oldPosition.y !== lastObjectPosition.y)
				{
					hasObjects = false;
					break;
				}
			}

			if(!hasObjects)
				break;

			++objectBlockLength;

			var nextPos = map.getNextDirectionalElementPosition(lastObjectPosition, this.direction);

			if (nextPos)
				lastObjectPosition = nextPos;
			else
				return false;
		}

		return objectBlockLength <= 2 && !map.element(lastObjectPosition).walls[direction];
	},

	_checkConditionInternal: function(condition, args)
	{
		var map = this.getMap();

		if (condition === pm.data.PushRobot.Condition.CanMoveForward)
		{
			return this._isClearForward();
		}
		else if (condition === pm.data.PushRobot.Condition.CanNotMoveForward)
		{
			return !this._isClearForward();
		}
		else
		{
			return PlayerRobot4Walls.prototype._checkConditionInternal.call(this, condition);
		}
	},

	getType: function() { return pm.PushLevelModule.RobotTypes.PushRobot; }
});

pm.data.PushRobot.Condition = {
	CanMoveForward: "push_mf",
	CanNotMoveForward: "push_nmf"
};
