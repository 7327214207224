/**
 * Created by danilaeremin on 11.08.15.
 */

/**
 * @class Layer for common player robot settings.
 * @extends ccui.Layout
 * @constructor
 * @param {pm.AbstractRobot} robot Robot which settings need to display.
 */
var PlayerRobotInterfaceLayerK = ccui.Layout.extend(/** @lends PlayerRobotSettingsLayer# */{
	_robot: null,

	_useTabs: null,
	_useCustomFuncs: null,
	_maxMethods: null,

	ctor: function(robot)
	{
		this._super();

		this._robot = robot;

		this.setLayoutType(ccui.Layout.RELATIVE);

		var maxMethodLabel = new pmui.Text(LocalizedString("MaxMethods"), 15);
		var maxMethodLabelAlign = new ccui.RelativeLayoutParameter();
		maxMethodLabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		maxMethodLabelAlign.setRelativeName("maxMethods");
		maxMethodLabelAlign.setMargin(0, 15, 0, 0);
		maxMethodLabel.setLayoutParameter(maxMethodLabelAlign);
		this.addChild(maxMethodLabel);

		this._maxMethods = new pmui.TextInput(this._robot.getProgramData().maxMethodCount, "0", 16, 40);
		var height = this._maxMethods.height + LevelSettingsLayer.SEPARATOR;

		var maxMethodsAlign = new ccui.RelativeLayoutParameter();
		maxMethodsAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		maxMethodsAlign.setRelativeToWidgetName("maxMethods");
		maxMethodsAlign.setMargin(5, 0, 0, 0);
		this._maxMethods.setLayoutParameter(maxMethodsAlign);
		this.addChild(this._maxMethods);

		this._maxMethods.setInsertTextCallback(this, this._maxMethodsChanged);

		this._useTabs = new pmui.CheckBox(LocalizedString("useGroupButtons"), this._useTabsChanged, this, 15, this._robot.getProgramData().useGroupButtons);
		height += this._useTabs.height + LevelSettingsLayer.SEPARATOR;

		var useTabsAlign = new ccui.RelativeLayoutParameter();
		useTabsAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		useTabsAlign.setRelativeToWidgetName("maxMethods");
		useTabsAlign.setRelativeName("useTabs");
		useTabsAlign.setMargin(0, 15, 0, 0);
		this._useTabs.setLayoutParameter(useTabsAlign);
		this.addChild(this._useTabs);

		this._useCustomFuncs = new pmui.CheckBox(LocalizedString("useCustomFuncs"), this._useCustomFuncsChanged, this, 15, this._robot.getProgramData().useCustomFuncs);
		height += this._useCustomFuncs.height + LevelSettingsLayer.SEPARATOR;

		var useCustomFuncs = new ccui.RelativeLayoutParameter();
		useCustomFuncs.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		useCustomFuncs.setRelativeToWidgetName("useTabs");
		useCustomFuncs.setRelativeName("useCustomFuncs");
		useCustomFuncs.setMargin(0, 15, 0, 0);
		this._useCustomFuncs.setLayoutParameter(useCustomFuncs);
		this.addChild(this._useCustomFuncs);

		var funcs = this.drawChangeableButs([pm.CMD_A, pm.CMD_B, pm.CMD_C, pm.CMD_D, pm.CMD_E],
			robot.getProgramData().funcs, "useCustomFuncs", "funcs",
			pm.spriteUtils.getRobotMethodSpriteName, 10);
		this.addChild(funcs);

		var prevLayoutName = "funcs";
		var condHeight = 0;
		if(robot.conditions.length)
		{
			var conds = this.drawChangeableButs(robot.conditions, robot.getProgramData().conds, "funcs", "conds",
				pm.spriteUtils.getRobotConditionSpriteName, 5);
			this.addChild(conds);

			var condsOpp = this.drawChangeableButs(robot.conditionOpposites, robot.getProgramData().condsOpp, "conds", "condsOpp",
				pm.spriteUtils.getRobotConditionSpriteName, 0);
			this.addChild(condsOpp);
			prevLayoutName = "condsOpp";
			condHeight += + conds.height + condsOpp.height;
		}

		var logs = this.drawGroupChangeableButs(pmui.LogicExpressionNode.values.slice(0, 4), robot.getProgramData().logicOperatorsLessGreater, prevLayoutName, "logs",
			pm.spriteUtils.getRobotConditionSpriteName, 5);
		var logsAlign = new ccui.RelativeLayoutParameter();
		logsAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		logsAlign.setRelativeName("logs");
		logsAlign.setRelativeToWidgetName(prevLayoutName);
		logs.setLayoutParameter(logsAlign);
		this.addChild(logs);

		var logsEq = this.drawGroupChangeableButs(pmui.LogicExpressionNode.values.slice(4, 6),robot.getProgramData().logicOperatorsEquals, "logs", "logsEq",
			pm.spriteUtils.getRobotConditionSpriteName, 5);
		var logsEqAlign = new ccui.RelativeLayoutParameter();
		logsEqAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		logsEqAlign.setRelativeName("logsEq");
		logsEqAlign.setRelativeToWidgetName("logs");
		logsEq.setLayoutParameter(logsEqAlign);
		this.addChild(logsEq);

		var logsAndOr = this.drawGroupChangeableButs(pmui.AndOrExpressionNode.values, robot.getProgramData().logicOperatorsAndOr, "logsEq", "logsAndOr",
			pm.spriteUtils.getRobotConditionSpriteName, 5);
		var logsAndOrAlign = new ccui.RelativeLayoutParameter();
		logsAndOrAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		logsAndOrAlign.setRelativeName("logsAndOr");
		logsAndOrAlign.setRelativeToWidgetName("logsEq");
		logsAndOr.setLayoutParameter(logsAndOrAlign);
		this.addChild(logsAndOr);

		var logsNot = this.drawGroupChangeableButs(pmui.NotExpressionNode.values, robot.getProgramData().logicOperatorsNot, "logsAndOr", "logsNot",
			pm.spriteUtils.getRobotConditionSpriteName, 5);
		var logsNotAlign = new ccui.RelativeLayoutParameter();
		logsNotAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		logsNotAlign.setRelativeName("");
		logsNotAlign.setRelativeToWidgetName("logsAndOr");
		logsNot.setLayoutParameter(logsNotAlign);
		this.addChild(logsNot);

		var loops = this.drawChangeableButs(pmui.RepeaterNode.vals, robot.getProgramData().loops, "logs", "loops",
			pm.spriteUtils.getRepeaterSpriteName, 5);
		this.addChild(loops);

		var ifSts = this.drawChangeableButs(pmui.IfNode.vals, robot.getProgramData().ifConstructions, "loops", "ifs",
			pm.spriteUtils.getConditionStatementSpriteName, 0);
		this.addChild(ifSts);

		var allocs = this.drawChangeableButs(pmui.AllocNode.intVals,robot.getProgramData().alloc, "ifs", "allocs",
			pm.spriteUtils.getAllocSpriteName, 5);
		for(var i = 0;i < pmui.AllocNode.intVals.length; i++)
		{//todo new buts with pics
			allocs.getChildren()[i].setTitleText(LocalizedString(pmui.AllocNode.intVals[i]));
			allocs.getChildren()[i].setTitleColor(cc.color(92, 108, 118));
		}
		this.addChild(allocs);

		var exprs = this.drawGroupChangeableButs(pmui.NumericExpressionNode.values, robot.getProgramData().expressions, "allocs", "exprs",
			pm.spriteUtils.getExprSpriteName, 5);
		this.addChild(exprs);

		var consts = this.drawChangeableButs(pmui.RepeaterNode.Constants,robot.getProgramData().constants, "exprs", "consts",
			pm.spriteUtils.getRepeaterSpriteName, 5);
		this.addChild(consts);

		var intMethods = [];
		for(var i = 0;i < this._robot.nativeFunctions.length; i++)
		{
			if(this._robot.nativeFunctionMap[this._robot.nativeFunctions[i]].returnArgs())
			{
				intMethods.push(this._robot.nativeFunctions[i]);
			}
		}
		var methods = this.drawChangeableButs(intMethods, robot.getProgramData().intMethods, "consts", "methods",
			pm.spriteUtils.getIntMethodSpriteName, 5);
		this.addChild(methods);

		height += loops.height + ifSts.height + allocs.height + exprs.height + logs.height  + funcs.height + condHeight + consts.height + methods.height;
		height += LevelSettingsLayer.SEPARATOR + LevelSettingsLayer.BORDER_RADIUS ;

		this.setContentSize(LevelSettingsLayer.WIDTH, height + LevelSettingsLayer.BORDER_RADIUS);
	},

	drawChangeableButs: function(values, levelVals, prevLayoutName, layoutName, getSprite, topSeparator)
	{
		var LoopsLayerLayout = new ccui.Layout();
		LoopsLayerLayout.setLayoutType(ccui.Layout.LINEAR_HORIZONTAL);

		var LoopsLayerLayoutAlign = new ccui.RelativeLayoutParameter();
		LoopsLayerLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		LoopsLayerLayoutAlign.setMargin(0, topSeparator, 0, 0);
		LoopsLayerLayoutAlign.setRelativeName(layoutName);
		LoopsLayerLayoutAlign.setRelativeToWidgetName(prevLayoutName);
		LoopsLayerLayout.setLayoutParameter(LoopsLayerLayoutAlign);

		var width = 0;
		var height = 0;

		for(var j = 0;j < values.length;j++)
		{
			var rep = new pmui.SelectionButton(getSprite(values[j], pm.NORMAL_STATE),
				getSprite(values[j], pm.SELECTED_STATE),
				getSprite(values[j], pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			if (levelVals.indexOf(values[j]) !== -1)
				rep.setSelectedTexture();
			else
				rep.setNormalTexture();
			rep.value = values[j];
			rep.vals = levelVals;
			LoopsLayerLayout.addChild(rep);
			rep.addClickEventListener(function ()
			{
				var value = this.value;
				var chosenVals = this.vals;
				if (chosenVals.indexOf(value) !== -1)
				{
					chosenVals.splice(chosenVals.indexOf(value), 1);
					this.setNormalTexture();
				}
				else
				{
					this.setSelectedTexture();
					chosenVals.push(value);
				}
				pm.sendCustomEvent(pm.COMMANDS_CHANGED);
			});

			width += rep.width;
			height = Math.max(height, rep.height);
		}

		LoopsLayerLayout.setContentSize(width, height + topSeparator);
		return LoopsLayerLayout;
	},

	drawGroupChangeableButs: function(values, programDataValues, prevLayoutName, layoutName, getSprite, topSeparator)
	{
		var LoopsLayerLayout = new ccui.Layout();
		LoopsLayerLayout.setLayoutType(ccui.Layout.LINEAR_HORIZONTAL);

		var LoopsLayerLayoutAlign = new ccui.RelativeLayoutParameter();
		LoopsLayerLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		LoopsLayerLayoutAlign.setMargin(0, topSeparator, 0, 0);
		LoopsLayerLayoutAlign.setRelativeName(layoutName);
		LoopsLayerLayoutAlign.setRelativeToWidgetName(prevLayoutName);
		LoopsLayerLayout.setLayoutParameter(LoopsLayerLayoutAlign);

		var width = 0;
		var height = 0;

		var groupButton = new pmui.SelectionButton(getSprite(values[0], pm.NORMAL_STATE),
			getSprite(values[0], pm.SELECTED_STATE),
			getSprite(values[0], pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		if (programDataValues.indexOf(values[0]) !== -1)
		{
			groupButton.setSelectedTexture();
		}

		groupButton.groupValues = values;
		groupButton.programVals = programDataValues;
		LoopsLayerLayout.addChild(groupButton);
		groupButton.addClickEventListener(function ()
		{
			var groupValues = this.groupValues;
			var chosenVals = this.programVals;
			if(this.selected)
			{
				for (var j = 0; j < groupValues.length; j++)
				{
					if(chosenVals.indexOf(groupValues[j]) !== -1)
						chosenVals.splice(chosenVals.indexOf(groupValues[j]), 1);
				}
				this.setNormalTexture();
			}
			else
			{
				this.setSelectedTexture();
				for (var j = 0; j < groupValues.length; j++)
				{
					chosenVals.push(groupValues[j]);
				}
			}
			pm.sendCustomEvent(pm.COMMANDS_CHANGED);
		});

		width += groupButton.width;
		height = Math.max(height, groupButton.height);

		LoopsLayerLayout.setContentSize(width, height + topSeparator);
		return LoopsLayerLayout;
	},

	_useTabsChanged: function (flag)
	{
		this._robot.getProgramData().useGroupButtons = flag;
		pm.sendCustomEvent(pm.COMMANDS_CHANGED);
	},

	_useCustomFuncsChanged: function (flag)
	{
		this._robot.getProgramData().useCustomFuncs = flag;
		pm.sendCustomEvent(pm.COMMANDS_CHANGED);
	},

	_maxMethodsChanged: function ()
	{
		this._robot.getProgramData().maxMethodCount = Number(this._maxMethods.getString());
	}
});

PlayerRobotSettingsLayer.MAX_PATTERN_COUNT = 12;
