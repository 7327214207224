/**
 * Created by VK on 25.02.21.
 */

pm.kumirModule = pm.abstractSyntaxModule.extend(/** @lends pm.kumirModule# */{

    _dotSpriteSize: 10,

    getTreeCreator: function (programTree, robot, parent, outputRobot, syntaxModule)
    {
        return  new TextualTreeCreator(programTree, robot, parent, outputRobot, syntaxModule);
    },

    isTextual: function ()
    {
        return true;
    },

    constructIfNode: function(node)
    {
        var ifText = node.createText("if", pmui.NodeLayer.CONSTR_COLOR);
        ifText.setTag(pmui.IfNode.IF_TEXT_TAG);
        node.createALignSome(ifText, "", "if_text", ccui.RelativeLayoutParameter.PARENT_TOP_LEFT, pmui.NodeLayer.SEPARATORX, 2 * pmui.NodeLayer.SEPARATORY, 0, 0);
        node.addChild(ifText);

        var thenName = node.createText("then", pmui.NodeLayer.CONSTR_COLOR);
        thenName.setTag(pmui.IfNode.THEN_TEXT_TAG);
        node.createALignSome(thenName, "if_text", pm.IFThenTag, ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN, 0, 0, 0, 0);
        node.addChild(thenName);

        node.createALignSome(node._condition, "if_text", "cond", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
        node.addChild(node._condition);

        var end = node.createText("endif", pmui.NodeLayer.CONSTR_COLOR);
        end.setTag(pmui.IfNode.END_TAG);
        node.createALignSome(end, "elseBlock", "end", ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN, 0, 0, 0, 0);
        node.addChild(end);

        pm.abstractSyntaxModule.prototype.constructIfNode.call(this, node);
    },

    calculateSizeOfIfNode: function (node)
    {
        var data = pm.abstractSyntaxModule.prototype.calculateSizeOfIfNode.call(this, node);

        var width = Math.max(data.bodyWidth + this.calculateTabulationSize(),
            node.getChildByTag(pmui.IfNode.IF_TEXT_TAG).width + pmui.NodeLayer.SEPARATORX + node._condition.width);
        width = Math.max(width, node.getChildByName(pmui.IfNode.ELSE_BLOCK_NAME).width);

        data.height += node.getChildByName(pmui.IfNode.ELSE_BLOCK_NAME).height
                + node.getChildByTag(pmui.IfNode.THEN_TEXT_TAG).height
                + node.getChildByTag(pmui.IfNode.END_TAG).height + pmui.NodeLayer.SEPARATORY;
        node.setContentSize(width + 2 * pmui.NodeLayer.SEPARATORX, data.height + 2 * pmui.NodeLayer.SEPARATORY);
    },

    constructElseBlock: function (node, treeNode, parent)
    {
        pm.abstractSyntaxModule.prototype.constructElseBlock.call(this, node, treeNode, parent);

        var elseName = node.getChildByTag(pmui.IfNode.ELSE_WORD_TAG);
        elseName.setString(LocalizedString("else"));
    },

    constructRepeaterNode: function (node)
    {
        var text = node.createText("cb", pmui.NodeLayer.CONSTR_COLOR);
        var end = node.createText("cend", pmui.NodeLayer.CONSTR_COLOR);

        text.setTag(pmui.RepeaterNode.FIRST_TAG);
        node.createALignSome(text, "", pmui.RepeaterNode.FIRST, ccui.RelativeLayoutParameter.PARENT_TOP_LEFT, pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORY * 2, 0, 0);
        node.createALignSome(end, pmui.RepeaterNode.BODY_TAG, pmui.RepeaterNode.END_TAG, ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN,
            -ProgramContainerLayer.SEPARATOR_SIZEX, -pmui.NodeLayer.SEPARATORY, 0, 0);

        node.addChild(text);
        node.addChild(end);
    },

    constructForRepeaterNode: function (node)
    {
        var for_text = node.getChildByTag(pmui.RepeaterNode.FIRST_TAG);
        for_text.setString(for_text.getString() + " " + LocalizedString("for"));

        var text2 = node.createText("from", pmui.NodeLayer.CONSTR_COLOR);
        var text3 = node.createText("to", pmui.NodeLayer.CONSTR_COLOR);

        node.addChild(node._iterator);
        node.createALignSome(node._iterator, pmui.RepeaterNode.FIRST, pm.ForIteratorTag, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);

        node.addChild(text2);
        node.createALignSome(text2, pm.ForIteratorTag, pmui.ForRepeaterNode.FROM_TAG, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);

        node.addChild(node._begVal);
        node.createALignSome(node._begVal, pmui.ForRepeaterNode.FROM_TAG, pm.ForBegTag, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);

        node.addChild(text3);
        node.createALignSome(text3, pm.ForBegTag, pmui.ForRepeaterNode.TO_TAG, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);

        node.addChild(node._endVal);
        node.createALignSome(node._endVal, pmui.ForRepeaterNode.TO_TAG, pm.ForEndTag, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
    },

    calculateSizeOfForRepeaterNode: function (node)
    {
        var data = this.calculateSizeOfRepeaterNode(node);

        node.setContentSize(Math.max(data.bodyWidth + this.calculateTabulationSize(),
            node.createText("cb").width + node.createText("for").width + node.createText("from").width + node.createText("to").width
            + node._iterator.width + node._begVal.width + node._endVal.width + pmui.NodeLayer.SEPARATORX * 6) + pmui.NodeLayer.SEPARATORX * 2,
            node._iterator.height + data.bodyHeight + node.createText("cend").height - data.separator);
    },

    constructWhileRepeaterNode: function (node)
    {
        var while_text = node.getChildByTag(pmui.RepeaterNode.FIRST_TAG);
        while_text.setString(while_text.getString() + " " + LocalizedString("while"));

        this.constructWhileRepeaterCondition(node);
    },

    calculateSizeOfWhileRepeaterNode: function (node)
    {
        var data = this.calculateSizeOfRepeaterNode(node);

        node.setContentSize(Math.max(data.bodyWidth + this.calculateTabulationSize(),
            node.createText("cb").width + node.createText("while").width + node._condition.width + pmui.NodeLayer.SEPARATORX * 2) + pmui.NodeLayer.SEPARATORX * 2,
            node._condition.height + data.bodyHeight + node.createText("cend").height - data.separator + 0.5 * pmui.NodeLayer.SEPARATORY);
    },

    constructNTimesRepeaterNode: function (node)
    {
        this.constructNTimesRepeaterNumber(node);

        var text = node.createText("times", pmui.NodeLayer.CONSTR_COLOR);
        node.createALignSome(text, pm.NTimesNumTag, "", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
        node.addChild(text);
    },

    calculateSizeOfNTimesRepeaterNode: function (node)
    {
        var data = this.calculateSizeOfRepeaterNode(node);

        node.setContentSize(Math.max(data.bodyWidth + this.calculateTabulationSize(),
            node.createText("cb").width + node._number.width + node.createText("times").width + pmui.NodeLayer.SEPARATORX * 2) + pmui.NodeLayer.SEPARATORX * 2,
            Math.max(node.createText("cb").height, node._number.height) + data.bodyHeight + node.createText("cend").height - data.separator);
    },

    getStatementNodeSign: function (node)
    {
        return  node.createText(":=");
    },

    addChildNodeToAllocNode: function (node)
    {
        var typeName = node.createText(node._typeName, pmui.NodeLayer.ALLOC_COLOR);

        node.createALignSome(typeName, "", "type", ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL, pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORY, 0, pmui.NodeLayer.SEPARATORY);
        node.createALignSome(node._name, "type", "", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORY, pmui.NodeLayer.SEPARATORX, pmui.NodeLayer.SEPARATORY);

        node.addChild(typeName);
        node.addChild(node._name);
    },

    constructBodyNode: function (node)
    {
        pm.abstractSyntaxModule.prototype.constructBodyNode.call(this, node);

        var firstElemAlignType;
        if(pm.syntaxModules.isTextual(node.getSyntaxModule()))
            firstElemAlignType = ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN;
        else
            firstElemAlignType = ccui.RelativeLayoutParameter.PARENT_TOP_LEFT;

        node.createALignSome(node._firstElem, "dots", node._id + pm.BodyFirstChildTag, firstElemAlignType,
            0, pmui.NodeLayer.SEPARATORY, 0, 0);

        if(pm.syntaxModules.isTextual(node.getSyntaxModule()))
            node._dotSprite.addChild(node.createText("."));
    },

    resizeBodyNodeDotSprite: function (node)
    {
        pm.abstractSyntaxModule.prototype.resizeBodyNodeDotSprite.call(this, node);

        var dot = node.createText(".");
        for (var i = 0; i < Math.round(node.height / dot.height); i++)
            node._dotSprite.addChild(dot.clone());
    },

    constructStartNodeBox: function (node, robotName)
    {
        var hBox = new ccui.HBox();
        var sep = new ccui.Layout();
        sep.setContentSize(5,5);

        var robotUsageText = node.createText("use");
    	hBox.addChild(robotUsageText);
    	hBox.addChild(sep);
    	hBox.addChild(robotName);

        return hBox;
    },

    getFunctionNodeSeparatorX: function ()
    {
        return 0;
    },

    getFunctionNodeSeparatorY: function ()
    {
        return 0;
    },

    constructFunctionNode: function (node)
    {
        var text = node.createText("def",pmui.NodeLayer.CONSTR_COLOR);
        node.createALignSome(text, "", "def", ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
        node._cont.addChild(text);


        if (node._name !== undefined)
        {
            var but = node.createText(LocalizedString(node._name));
            node.createALignSome(but, "def", "name", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
            node._cont.addChild(but);
        }

        var text1 = node.createText("beg", pmui.NodeLayer.CONSTR_COLOR);

        node.createALignSome(node._cont, "", "begin_", ccui.RelativeLayoutParameter.PARENT_TOP_LEFT, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
        node.createALignSome(text1, "begin_", "beg_", ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);

        node.addChild(text1);
    },

    addChildNodeToFunctionNode: function (node, childNode)
    {
        if(childNode._treeNode.getTagName() === pm.BodyTag
            || childNode._treeNode.type === pm.data.Node.Type.Body)
        {
            node._bodyNode = childNode;

            var text = node.createText("end", pmui.NodeLayer.CONSTR_COLOR);
            node.createALignSome(node._bodyNode, "beg_", "body_", ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
            node.addChild(node._bodyNode);

            node.createALignSome(text, "body_", "end_", ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN, -ProgramContainerLayer.SEPARATOR_SIZEX, 0, 0, pmui.NodeLayer.SEPARATORY);
            node.addChild(text);

            return;
        }
        if (node._paramsNumber === 0)
        {
            node._params.pop();
            node._lBrace = node.createText("(");
            node.createALignSome(node._lBrace, "name", pmui.FunctionNode.L_BRACE_TAG, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, 0, 0, 0, 0);
            node.createALignSome(childNode, pmui.FunctionNode.L_BRACE_TAG, pm.FuncParamTag + "0", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER,
                this.getFunctionNodeSeparatorX(), pmui.NodeLayer.SEPARATORY, 0, pmui.NodeLayer.SEPARATORY);

            node._rBrace = node.createText(")");
            node._rBrace.setName(pmui.FunctionNode.R_BRACE_TAG);
            node.createALignSome(node._rBrace, "", pmui.FunctionNode.R_BRACE_TAG, ccui.RelativeLayoutParameter.PARENT_RIGHT_CENTER_VERTICAL,
                pmui.NodeLayer.SEPARATORX, 0, 0, 0);

            node._cont.addChild(node._lBrace);
            node._cont.addChild(node._rBrace);
        }
        else//first adding of the second and else elems ", 0"
        {
            var comma = node.createText(",");
            node.createALignSome(comma, pm.FuncParamTag + (node._paramsNumber - 1), pmui.CommandNode.COMMAND_COMMA_TAG + node._paramsNumber, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, 0, 0, 0, 0);
            node._cont.addChild(comma);

            node.createALignSome(childNode, pmui.CommandNode.COMMAND_COMMA_TAG + node._paramsNumber, pm.FuncParamTag + node._paramsNumber, ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER,
                pmui.NodeLayer.SEPARATORX * 3, pmui.NodeLayer.SEPARATORY, 0, pmui.NodeLayer.SEPARATORY);
        }

        pm.abstractSyntaxModule.prototype.addChildNodeToFunctionNode.call(this, node, childNode);
    },

    removeChildNodeFromFunctionNode: function (node)
    {
        var text = node.createText("def", pmui.NodeLayer.CONSTR_COLOR);
        node.createALignSome(text, "", "def", ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
        node._cont.addChild(text);

        var but = node.createText(LocalizedString(node._treeNode.value));
        node.createALignSome(but, "def", "name", ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER, pmui.NodeLayer.SEPARATORX, 0, 0, 0);
        node._cont.addChild(but);
    },

    constructEmptyParameter: function (node)
    {
        var first = pm.abstractSyntaxModule.prototype.constructEmptyParameter.call(this, node);

        first.setContentSize(100,40);
        node.createALignSome(first, "name", "first", ccui.RelativeLayoutParameter.PARENT_TOP_LEFT, 50, 0, 0, -40);
    },

    getLocalization: function (language)
    {
        switch (language)
        {
            case cc.sys.LANGUAGE_ENGLISH:
                return {
                    "A": "A",
                    "B" : "B",
                    "C" : "C",
                    "D" : "D",
                    "E" : "E",
                    "F" : "F",
                    "int" : "int",
                    "bool" : "bool",
                    "yes" : "true",
                    "no" : "false",
                    "cb" : "bc",
                    "for" : "for",
                    "from" : "from",
                    "to" : "to",
                    "beg": "beg",
                    "end" : "end",
                    "def" : "def",
                    "if" : "if",
                    "then" : "then",
                    "else" : "else",
                    "endif" : "endif",
                    "cend" : "ec",
                    "use" : "use",
                    "times" : "times",
                    "while" : "while",
                    "and" : "and",
                    "or" : "or",
                    "not" : "not",
                };
            case cc.sys.LANGUAGE_RUSSIAN:
                return {
                    "A": "А",
                    "B" : "Б",
                    "C" : "В",
                    "D" : "Г",
                    "E" : "Д",
                    "F" : "Е",
                    "int" : "цел",
                    "bool" : "лог",
                    "yes" : "да",
                    "no" : "нет",
                    "cb" : "нц",
                    "for" : "для",
                    "from" : "от",
                    "to" : "до",
                    "beg": "нач",
                    "end" : "кон",
                    "def" : "алг",
                    "if" : "если",
                    "then" : "то",
                    "else" : "иначе",
                    "endif" : "все",
                    "cend" : "кц",
                    "use" : "использовать",
                    "times" : "раз",
                    "while" : "пока",
                    "and" : "и",
                    "or" : "или",
                    "not" : "не",
                };
        }
    }
});

pm.kumirEditableModule = pm.kumirModule.extend({

    isTextual: function ()
    {
        return false;
    },

    addChildNodeToAllocNode: function (node)
    {
        pm.kumirModule.prototype.addChildNodeToAllocNode.call(this, node);

        node.createALignSome(node._variableValue, "", "", ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL, -pmui.AllocNode.DEBUG_WIDTH, 0, pmui.NodeLayer.SEPARATORX, 0);
        node.addChild(node._variableValue);
    },

    getFunctionNodeSeparatorX: function ()
    {
        return pmui.NodeLayer.SEPARATORX * 3;
    },

    getFunctionNodeSeparatorY: function ()
    {
        return pmui.NodeLayer.SEPARATORY * 3;
    },

    getLocalization: function (language) {}
});

pm.syntaxModules.KUMIR_EDITABLE_MODULE_ORDER = 0;
pm.syntaxModules.KUMIR_TEXTUAL_MODULE_ORDER = 1;

pm.syntaxModules.register(new pm.kumirEditableModule, pm.syntaxModules.KUMIR_EDITABLE_MODULE_ORDER);
pm.syntaxModules.register(new pm.kumirModule, pm.syntaxModules.KUMIR_TEXTUAL_MODULE_ORDER);
