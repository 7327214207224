/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.RepeaterNode = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{
	_type: null,

	ctor: function (treeNode, parent, syntaxModule, type)
	{
		this.type = FunctionButton.Type.Repeater;
		this.isPartlyClonable = true;
		this._withBorder = true;
		this._super(parent, treeNode, syntaxModule);

		this.setLayoutType(ccui.Layout.RELATIVE);

		this._type = type;

		pm.syntaxModules.constructRepeaterNode(this);
	},

	updateIteratorVal: function () {}
});

pmui.RepeaterNode.FIRST = "first_cycle_elem";
pmui.RepeaterNode.FIRST_TAG = 439855772532; // "first";
pmui.RepeaterNode.BODY_TAG = "cycle_body";
pmui.RepeaterNode.END_TAG = "end_cycle";
pmui.RepeaterNode.vals = [
	pm.LoopVals.for,
	pm.LoopVals.nTimes,
	pm.LoopVals.whileC
];

pmui.RepeaterNode.Constants = [
	pm.Constants.One,
	pm.Constants.Two,
	pm.Constants.Three,
	pm.Constants.Four,
	pm.Constants.Five,
	pm.Constants.Six
];
