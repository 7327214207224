/** Created by Kirill Mashchenko on 09.08.17. ...*/

/**
 * @class Layer for controlling class.
 * @extends ccui.Layout
 */

var ClassControlLayer = ccui.Layout.extend(/** @lends ClassControlLayer# */{

	_freeStudentsList: null,
	_teamList: null,

	_freeStudents: [],
	_freeStudentsSelectedMap: {},

	_server: null,

	chooseGameLevelLayout: null,
	_changeLevelMenu: null,

	_unionInput: null,

	_commonAutoChange: true,

	_unionCount: 0,
	_unionGameIndex: 0,
	_unionLevelIndex: 0,

	_timeLayout: null,
	_workHeight: 0,

	ctor: function()
	{
		this._super();
		this._teams = [];

		this._freeStudentsSelectedMap = {};
		this._freeStudents = [];

		this.setLayoutType(ccui.Layout.RELATIVE);

		if(cc.sys.isNative)
			cc.textureCache.removeAllTextures();

		this.setContentSize(pm.settings.getScreenSize());

		pm.broadcastClient.setPaused(true);
		pm.broadcastServer.setDiscoverTimeout(2000);
		pm.broadcastServer.setDataCallback(this._dataCallback.bind(this));
		pm.networkUtils.startDiscover(this._discoverServerCallback.bind(this), pm.BROADCAST_PACKET_PRIORITY.HIGH);

		this._server = new pm.TeacherServerImpl();
		this._server.start(pm.TEACHER_SERVER_PORT);
		this._server.setRefreshCallback(this._refreshTeam.bind(this));

		this._loadInterface();

		pm.registerCustomEventListener(pm.UPDATE_TIME_TABLE, function()
		{
			this._showTime(null, true);
		}.bind(this), this);
	},

	onExit: function()
	{
		pm.settings.isEditorMode = false;

		this._super();
		pm.broadcastClient.setPaused(false);
		pm.networkUtils.stopDiscover();
	},

	_loadInterface: function()
	{
		var screenBounds = pm.settings.getScreenBounds();
		var screenSize = pm.settings.getScreenSize();

		pm.settings.isEditorMode = true;
		this._commonAutoChange = true;

		var autoChangeCheckbox = new pmui.CheckBox(LocalizedString("AutoChangeForAll"), this._autoChangeAll, this, 18, this._commonAutoChange);

		var autoChangeAlign = new ccui.RelativeLayoutParameter();
		autoChangeAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		autoChangeAlign.setRelativeName("autoChange");
		autoChangeAlign.setMargin(screenBounds.left + ClassControlLayer.BORDER, screenBounds.top + ClassControlLayer.BORDER, 0, 0);
		autoChangeCheckbox.setLayoutParameter(autoChangeAlign);
		this.addChild(autoChangeCheckbox);

		var showTime = new pmui.Button(pm.spriteUtils.getIconName("time", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("time", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("time", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		showTime.addClickEventListener(this._showTime.bind(this));

		var showTimeAlign = new ccui.RelativeLayoutParameter();
		showTimeAlign.setRelativeName("time");
		showTimeAlign.setRelativeToWidgetName("autoChange");
		showTimeAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		showTimeAlign.setMargin(ClassControlLayer.BORDER, 0, 0, 0);
		showTime.setLayoutParameter(showTimeAlign);

		this.addChild(showTime);

		var editLevel = new pmui.Button(pm.spriteUtils.getIconName("edit", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("edit", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("edit", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		editLevel.addClickEventListener(this._editLevel.bind(this));
		editLevel.setScale(0.8);

		var editLevelAlign = new ccui.RelativeLayoutParameter();
		editLevelAlign.setRelativeName("edit");
		editLevelAlign.setRelativeToWidgetName("time");
		editLevelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		editLevelAlign.setMargin(ClassControlLayer.BORDER, 0, 0, 0);
		editLevel.setLayoutParameter(editLevelAlign);

		this.addChild(editLevel);

		this._changeLevelMenu = new ChangeLevelClassControl(this);

		var changeLevelAlign = new ccui.RelativeLayoutParameter();
		changeLevelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		changeLevelAlign.setMargin(ClassControlLayer.BORDER, 0, 0, 0);
		changeLevelAlign.setRelativeName("changeLevel");
		changeLevelAlign.setRelativeToWidgetName("edit");
		this._changeLevelMenu.setLayoutParameter(changeLevelAlign);

		this._changeLevelMenu.setScale(0.8);
		this._refreshChangeLevelMenu();

		this.addChild(this._changeLevelMenu);

		var selectWorldLayer = new SelectWorldEditorLayer(this, null, true, SelectWorldEditorLayer.Gravity.HORIZONTAL);

		var worldLayerAlign = new ccui.RelativeLayoutParameter();
		worldLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		worldLayerAlign.setRelativeName("worldSelect");
		worldLayerAlign.setMargin(0, screenBounds.top, screenBounds.right + ClassControlLayer.BORDER, 0);
		selectWorldLayer.setLayoutParameter(worldLayerAlign);

		this.addChild(selectWorldLayer, 1);

		var workSpaceWidth = screenSize.width - ClassControlLayer.SEPARATOR_NUMBER * ClassControlLayer.SEPARATOR;
		var classLayoutWidth = workSpaceWidth * ClassControlLayer.CLASS_WIDTH_PERCENT;
		var gameLayoutWidth = workSpaceWidth * ClassControlLayer.GAME_WIDTH_PERCENT;

		var workSpaceHeight = screenSize.height - 2 * ClassControlLayer.BORDER - selectWorldLayer.height - ClassControlLayer.BOTTOM_MARGIN;
		this._workHeight = workSpaceHeight;

		var classLayout = new ccui.Layout();
		classLayout.setLayoutType(ccui.Layout.RELATIVE);
		classLayout.setContentSize(classLayoutWidth, workSpaceHeight);

		var image = "System/interfaceBG.png";
		classLayout.setBackGroundImageCapInsets(cc.rect(89, 89, 72, 72));
		classLayout.setBackGroundImage(image);
		classLayout.setBackGroundImageScale9Enabled(true);
		classLayout.setCascadeOpacityEnabled(true);

		var staticClassLayer = new ccui.Layout();
		staticClassLayer.setContentSize(classLayoutWidth - 2 * ClassControlLayer.BORDER_RADIUS, workSpaceHeight - 2 * ClassControlLayer.BORDER_RADIUS);
		staticClassLayer.setBackGroundColorType(ccui.Layout.BG_COLOR_SOLID);
		staticClassLayer.setBackGroundColor(cc.color.RED);
		staticClassLayer.setOpacity(50);

		var staticLayerAlign = new ccui.RelativeLayoutParameter();
		staticLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		staticLayerAlign.setMargin(ClassControlLayer.BORDER_RADIUS, ClassControlLayer.BORDER_RADIUS, 0, 0);

		staticClassLayer.setLayoutParameter(staticLayerAlign);
		classLayout.addChild(staticClassLayer, -2);

		var classLayoutAlign = new ccui.RelativeLayoutParameter();
		classLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		classLayoutAlign.setRelativeName("class");
		classLayoutAlign.setRelativeToWidgetName("autoChange");
		classLayoutAlign.setMargin(0, ClassControlLayer.BORDER + (selectWorldLayer.height - autoChangeCheckbox.height) / 2, 0, 0);
		classLayout.setLayoutParameter(classLayoutAlign);

		var label = new ccui.Text(LocalizedString("Class"), pm.settings.fontName, pm.settings.fontSize);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		labelAlign.setRelativeName("classLabel");
		labelAlign.setMargin(0, ClassControlLayer.BORDER + ClassControlLayer.BORDER_RADIUS, 0, 0);
		label.setLayoutParameter(labelAlign);

		classLayout.addChild(label);

		var unionIntoGameN = new ccui.Layout();
		unionIntoGameN.setLayoutType(ccui.Layout.RELATIVE);

		var unionIntoGameNAlign = new ccui.RelativeLayoutParameter();
		unionIntoGameNAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		unionIntoGameNAlign.setRelativeName("unionN");
		unionIntoGameNAlign.setRelativeToWidgetName("class");
		unionIntoGameNAlign.setMargin(0, ClassControlLayer.BORDER, 0, 0);
		unionIntoGameN.setLayoutParameter(unionIntoGameNAlign);

		var unionLabel = new ccui.Text("Объединить по: ", pm.settings.fontName, pm.settings.fontSize);

		var unionLabelAlign = new ccui.RelativeLayoutParameter();
		unionLabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
		unionLabelAlign.setRelativeName("unionNLabel");
		unionLabel.setLayoutParameter(unionLabelAlign);

		var unionWidth = unionLabel.width;

		this._unionInput = new pmui.TextInput(2, " ", pm.settings.fontSize, 25);

		var unionInputAlign = new ccui.RelativeLayoutParameter();
		unionInputAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		unionInputAlign.setMargin(ClassControlLayer.SMALL_SEPARATOR, 0, 0, 0);
		unionInputAlign.setRelativeName("unionNInput");
		unionInputAlign.setRelativeToWidgetName("unionNLabel");
		this._unionInput.setLayoutParameter(unionInputAlign);

		unionWidth += ClassControlLayer.SMALL_SEPARATOR + this._unionInput.width;

		var unionButton = new pmui.InterfaceButton(this._unionIntoGameN, this, "OK");

		var unionButtonAlign = new ccui.RelativeLayoutParameter();
		unionButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		unionButtonAlign.setRelativeName("OK");
		unionButtonAlign.setRelativeToWidgetName("unionNInput");
		unionButtonAlign.setMargin(ClassControlLayer.MEDIUM_SEPARATOR, 0, 0, 0);
		unionButton.setLayoutParameter(unionButtonAlign);

		unionWidth += ClassControlLayer.MEDIUM_SEPARATOR + unionButton.width;

		unionIntoGameN.setContentSize(unionWidth, unionButton.height);

		unionIntoGameN.addChild(unionLabel);
		unionIntoGameN.addChild(this._unionInput);
		unionIntoGameN.addChild(unionButton);

		this.addChild(unionIntoGameN);

		var unionIntoGame = new pmui.InterfaceButton(this._unionIntoGame, this, LocalizedString("UnionIntoGame"));

		var unionIntoGameAlign = new ccui.RelativeLayoutParameter();
		unionIntoGameAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		unionIntoGameAlign.setRelativeName("union");
		unionIntoGameAlign.setRelativeToWidgetName("unionN");
		unionIntoGameAlign.setMargin(ClassControlLayer.BORDER, 0, 0, 0);
		unionIntoGame.setLayoutParameter(unionIntoGameAlign);

		this.addChild(unionIntoGame);

		var reconnectButton = new pmui.InterfaceButton(this._reconnectAll, this, "Переподключить всех");

		var reconnectButtonAlign = new ccui.RelativeLayoutParameter();
		reconnectButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		reconnectButtonAlign.setRelativeName("reconnect");
		reconnectButtonAlign.setRelativeToWidgetName("union");
		reconnectButtonAlign.setMargin(ClassControlLayer.BORDER, 0, 0, 0);
		reconnectButton.setLayoutParameter(reconnectButtonAlign);

		this.addChild(reconnectButton);

		var classListHeight = workSpaceHeight - label.height - 4 * ClassControlLayer.BORDER;

		this._freeStudentsList = new ccui.ListView();
		this._freeStudentsList.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._freeStudentsList.setContentSize(classLayoutWidth - 2 * ClassControlLayer.BORDER - 6, classListHeight);
		this._freeStudentsList.setItemsMargin(5);
		this._freeStudentsList.setGravity(3);

		var listAlign = new ccui.RelativeLayoutParameter();
		listAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		listAlign.setRelativeToWidgetName("class");
		listAlign.setMargin(ClassControlLayer.BORDER_RADIUS + ClassControlLayer.BORDER, label.height + 2 * ClassControlLayer.BORDER, 0, 0);
		this._freeStudentsList.setLayoutParameter(listAlign);

		classLayout.addChild(this._freeStudentsList);
		this.addChild(classLayout);

		var gameLayout = new ccui.Layout();
		gameLayout.setLayoutType(ccui.Layout.RELATIVE);
		gameLayout.setContentSize(gameLayoutWidth, workSpaceHeight);

		var image = "System/interfaceBG.png";
		gameLayout.setBackGroundImageCapInsets(cc.rect(89, 89, 72, 72));
		gameLayout.setBackGroundImage(image);
		gameLayout.setBackGroundImageScale9Enabled(true);
		gameLayout.setCascadeOpacityEnabled(true);

		var staticGameLayer = new ccui.Layout();
		staticGameLayer.setContentSize(gameLayoutWidth - 2 * ClassControlLayer.BORDER_RADIUS, workSpaceHeight - 2 * ClassControlLayer.BORDER_RADIUS);
		staticGameLayer.setBackGroundColorType(ccui.Layout.BG_COLOR_SOLID);
		staticGameLayer.setBackGroundColor(cc.color.BLUE);
		staticGameLayer.setOpacity(50);

		staticGameLayer.setLayoutParameter(staticLayerAlign.clone());
		gameLayout.addChild(staticGameLayer, -2);

		var gameLayoutAlign = new ccui.RelativeLayoutParameter();
		gameLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		gameLayoutAlign.setRelativeName("game");
		gameLayoutAlign.setRelativeToWidgetName("class");
		gameLayoutAlign.setMargin(ClassControlLayer.SEPARATOR, 0, 0, 0);
		gameLayout.setLayoutParameter(gameLayoutAlign);

		var gameListHeight = workSpaceHeight - 4 * ClassControlLayer.BORDER;

		this._teamList = new ccui.ListView();
		this._teamList.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._teamList.setContentSize(gameLayoutWidth - 2 * ClassControlLayer.BORDER, gameListHeight);
		this._teamList.setItemsMargin(15);
		this._teamList.setGravity(3);

		var teamsAlign = new ccui.RelativeLayoutParameter();
		teamsAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		teamsAlign.setMargin(ClassControlLayer.BORDER_RADIUS + ClassControlLayer.BORDER, ClassControlLayer.BORDER_RADIUS + ClassControlLayer.BORDER, 0, 0);
		this._teamList.setLayoutParameter(teamsAlign);

		gameLayout.addChild(this._teamList);
		this.addChild(gameLayout);

		var toPiktomirButton = new pmui.Button( pm.spriteUtils.getIconName("toPiktomir", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("toPiktomir", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("toPiktomir", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		toPiktomirButton.addClickEventListener(this._piktomir.bind(this));

		var toPiktomirAlign = new ccui.RelativeLayoutParameter();
		toPiktomirAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_RIGHTALIGN);
		toPiktomirAlign.setRelativeName("toPiktomir");
		toPiktomirAlign.setRelativeToWidgetName("game");
		toPiktomirAlign.setMargin(0, ClassControlLayer.MEDIUM_SEPARATOR, 0, 0);
		toPiktomirButton.setLayoutParameter(toPiktomirAlign);

		this.addChild(toPiktomirButton);

		var clearAllCache = new pmui.Button( pm.spriteUtils.getIconName("clearAllProgress", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("clearAllProgress", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("clearAllProgress", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		clearAllCache.addClickEventListener(this._clearAllCache.bind(this));

		var clearAllCacheAlign = new ccui.RelativeLayoutParameter();
		clearAllCacheAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_LEFT_OF_CENTER);
		clearAllCacheAlign.setMargin(0, 0, ClassControlLayer.BORDER, 0);
		clearAllCacheAlign.setRelativeName("clearAllCache");
		clearAllCacheAlign.setRelativeToWidgetName("toPiktomir");
		clearAllCache.setLayoutParameter(clearAllCacheAlign);

		this.addChild(clearAllCache);

		var destroyAllTeams = new pmui.Button( pm.spriteUtils.getIconName("deleteAllTeams", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("deleteAllTeams", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("deleteAllTeams", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		destroyAllTeams.addClickEventListener(this._destroyAllTeamsConfirm.bind(this));

		var destroyAllTeamsAlign = new ccui.RelativeLayoutParameter();
		destroyAllTeamsAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_LEFT_OF_CENTER);
		destroyAllTeamsAlign.setMargin(0, 0, ClassControlLayer.BORDER, 0);
		destroyAllTeamsAlign.setRelativeToWidgetName("clearAllCache");
		destroyAllTeams.setLayoutParameter(destroyAllTeamsAlign);

		this.addChild(destroyAllTeams);
	},

	_refreshChangeLevelMenu: function()
	{
		var noPrevLevel = false;
		for (var i = 0; i < this._server.teams.length; ++i)
		{
			var team = this._server.teams[i];
			if (team.level !== 0)
			{
				noPrevLevel = true;
				break;
			}
		}
		this._changeLevelMenu.setEnabledPrevLevelButton(noPrevLevel);

		var noNextLevel = false;
		for (var i = 0; i < this._server.teams.length; ++i)
		{
			var team = this._server.teams[i];
			if (team.level !== world.getLevelCount(team.game) - 1)
			{
				noNextLevel = true;
				break;
			}
		}
		this._changeLevelMenu.setEnabledNextLevelButton(noNextLevel);
	},

	_autoChangeAll: function(flag)
	{
		this._commonAutoChange = flag;

		for (var i = 0; i < this._teamList.getChildrenCount(); ++i)
		{
			var layout = this._teamList.getItem(i);
			layout.setAutoConnect(flag);
		}
	},

	_clearAllCache: function ()
	{
		var packet = {
			t: pm.BROADCAST_DATA_PACKET_TYPE.CLEAR_CACHE
		};

		var sendPacket = JSON.stringify(packet);

		for (var j = 0; j < this._freeStudents.length; ++j)
			pm.broadcastServer.sendPacket(this._freeStudents[j].host, sendPacket);
	},

	_reconnectAll: function()
	{
		var packet = {
			t: pm.BROADCAST_DATA_PACKET_TYPE.DEBUG_RECONNECT
		};

		var sendPacket = JSON.stringify(packet);

		for (var j = 0; j < this._freeStudents.length; ++j)
			pm.broadcastServer.sendPacket(this._freeStudents[j].host, sendPacket);

		pm.networkUtils.startDebug();
	},

	_destroyAllTeamsConfirm: function()
	{
		if (!this.getChildByTag(ClassControlLayer.CONFIRM_LAYER_TAG))
		{
			var input = new pmui.ConfirmWindow(LocalizedString("DestroyAllTeams"));

			input.setCallback(this, this._destroyAllTeams, function () {});

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
			input.setLayoutParameter(align);

			this.addChild(input, 100, ClassControlLayer.CONFIRM_LAYER_TAG);
		}
	},

	_destroyAllTeams: function()
	{
		for (var i = 0; i < this._server.teams.length; ++i)
			this._teamLayerCallback(TeamGameLayout.EVENT.DESTROY_TEAM, i);
	},

	_discoverServerCallback: function(clients)
	{
		var oldHeight = this._freeStudentsList.getInnerContainerSize().height;
		var currentFreeStudents = [];

		this._freeStudentsList.removeAllChildren(false);

		var createdTeams = [];

		var ifDifferent = false;

		for (var i = 0; i < clients.length; ++i)
		{
			var parsedPacket = JSON.parse(clients[i].getData());

			cc.log("Recieved broadcast discover from client: " + clients[i].getHost() + " data: \n" + clients[i].getData());

			var student = null;
			var team = null;
			var teamIndex = -1;

			for(var t = 0; t < this._server.teams.length; ++t)
			{
				if(this._server.teams[t].hostStudent.host === clients[i].getHost())
				{
					team = this._server.teams[t];
					teamIndex = t;
					student = this._server.teams[t].hostStudent;
					break;
				}

				for(var c = 0; c < this._server.teams[t].students.length; ++c)
				{
					if(this._server.teams[t].students[c].host === clients[i].getHost())
					{
						team = this._server.teams[t];
						teamIndex = t;
						student = this._server.teams[t].students[c];
						break;
					}
				}
			}

			if (student)
				student.discoverDate = new Date();

			switch (parsedPacket.t)
			{
				case pm.BROADCAST_DATA_PACKET_TYPE.SERVER:

					if(!student)
					{
						team = new pm.ControlledTeam(clients[i].getHost(), parsedPacket.u, parsedPacket.g, parsedPacket.l);
						this._server.teams.push(team);
						createdTeams.push(team);
						this._refreshChangeLevelMenu();
					}
					else
					{
						student.name = parsedPacket.u;

						if(!team.markDeleted)
							this._handleStudentConnection(student, teamIndex)
					}

					break;
				case pm.BROADCAST_DATA_PACKET_TYPE.CLIENT:

					if(!student)
					{
						for(var t = 0; t < this._server.teams.length; ++t)
						{
							if(this._server.teams[t].hostStudent.host === parsedPacket.h)
							{
								team = this._server.teams[t];
								break;
							}
						}

						if(!team)
						{
							team = new pm.ControlledTeam(parsedPacket.h, "", parsedPacket.g, parsedPacket.l);
							this._server.teams.push(team);
							createdTeams.push(team);
							this._refreshChangeLevelMenu();
						}

						student = new pm.ControlledStudent(
							clients[i].getHost(),
							parsedPacket.u,
							pm.ControlledStudent.State.Disconnected
						);

						team.students.push(student);
					}

					if(!team.markDeleted)
						this._handleStudentConnection(student, teamIndex);

					break;
				case pm.BROADCAST_DATA_PACKET_TYPE.FREE:

					if(!student)
					{
						if (!team || !team.markDeleted)
						{
							var freeStudent = new pm.ControlledStudent(
								clients[i].getHost(),
								parsedPacket.u,
								pm.ControlledStudent.State.Free
							);
							currentFreeStudents.push(freeStudent);
						}
					}
					else
					{
						if (student.name !== parsedPacket.name)
						{
							student.name = parsedPacket.u;
							ifDifferent = true;
						}

						if(!team.markDeleted)
							this._handleStudentConnection(student, teamIndex)
					}

					break;
			}
		}

		var date = new Date();

		for(var i = this._server.teams.length - 1; i >= 0 ; --i)
		{
			if(this._server.teams[i].markDeleted)
			{
				var deleteTeam = (this._server.teams[i].hostStudent.state === pm.ControlledStudent.State.Disconnected ||
					(date - this._server.teams[i].hostStudent.discoverDate >= pm.STUDENT_LOST_TIMEOUT && this._server.teams[i].hostStudent.state === pm.ControlledStudent.State.Connecting));

				if(deleteTeam)
				{
					for(var c = 0; c < this._server.teams[i].students.length; ++c)
					{
						if (!(this._server.teams[i].students[c].state === pm.ControlledStudent.State.Disconnected ||
							(date - this._server.teams[i].students[c].discoverDate >= pm.STUDENT_LOST_TIMEOUT && this._server.teams[i].students[c].state === pm.ControlledStudent.State.Connecting)))
						{
							deleteTeam = false;
							break;
						}
					}
				}

				if(deleteTeam)
				{
					this._server.teams.splice(i, 1);
					this._teamList.removeItem(i);

					if(i < this._server.teams.length)
					{
						for (var index = i; index < this._server.teams.length; ++index)
							--this._server.teams[index].index;
					}
					this._refreshChangeLevelMenu();
				}
			}
		}

		var compareTeams = this._compareTeams;
		createdTeams.sort(compareTeams.bind(this));

		for(var i = 0; i < createdTeams.length; ++i)
		{
			var teamGameLayout = new TeamGameLayout(createdTeams[i], this._teamList.getChildrenCount(), this._commonAutoChange);
			teamGameLayout.setIndexCallback(this._teamLayerCallback.bind(this));
			this._teamList.pushBackCustomItem(teamGameLayout);
		}

		for (var i = 0; i < currentFreeStudents.length; ++i)
		{
			var found = false;

			for (var j = 0; j < this._freeStudents.length; ++j)
			{
				if (this._freeStudents[j].host === currentFreeStudents[i].host)
				{
					this._freeStudents[j].name = currentFreeStudents[i].name;
					this._freeStudents[j].discoverDate = new Date();
					found = true;
					break;
				}
			}

			if (!found)
			{
				this._freeStudents.push(currentFreeStudents[i]);
				ifDifferent = true;
			}
		}

		var removeList = [];
		var now = new Date();

		for (var i = 0; i < this._freeStudents.length; ++i)
		{
			if (now - this._freeStudents[i].discoverDate >= pm.DISCOVER_EXPIRE_TIMEOUT)
				removeList.push(i);
		}

		for (var i = 0; i < removeList.length; ++i)
		{
			this._freeStudents.splice(removeList[i]-i, 1);
			ifDifferent = true;
		}

		var compare = this._compareElement;

		if (ifDifferent)
			this._freeStudents.sort(compare.bind(this));

		for (var j = 0; j < this._freeStudents.length; ++j)
		{
			var child = new pmui.CheckBox(
				this._freeStudents[j].name,
				this._childSelected,
				this,
				18,
				this._freeStudentsSelectedMap[this._freeStudents[j].host]
			);

			this._freeStudentsList.pushBackCustomItem(child);
		}

		var oldY = this._freeStudentsList.getInnerContainer().y;

		this._freeStudentsList.forceDoLayout();

		var curY = this._freeStudentsList.getInnerContainer().y;

		var percent = 0;

		if (curY !== 0)
		{
			percent = ((curY - oldY) / curY) * 100;
			percent = Math.min(Math.max(percent, 0), 100);
		}

		if (oldHeight > this._workHeight)
			this._freeStudentsList.jumpToPercentVertical(percent);
	},

	_compareElement: function (a, b)
	{
		var k1 = 0, k2 = 0;

		for(var i = a.name.length-1; i >= 0; i--)
		{
			if (a.name[i] >= "0" && a.name[i] <= "9")
				++k1;
			else
				break;
		}

		for(var i = b.name.length-1; i >= 0; i--)
		{
			if (b.name[i] >= "0" && b.name[i] <= "9")
				++k2;
			else
				break;
		}

		if (k1 === 0 || k2 === 0)
		{
			if (a.name >= b.name)
				return 1;
			else
				return -1;
		}

		var number1 = Number(a.name.substring(a.name.length - k1, a.name.length));
		var number2 = Number(b.name.substring(b.name.length - k2, b.name.length));

		if (number1 >= number2)
			return 1;
		else
			return -1;
	},

	_compareTeams: function(a, b)
	{
		if (a.index > b.index)
			return 1;
		else
			return -1;
	},

	_handleStudentConnection: function (student, teamIndex)
	{
		if (student.state === pm.ControlledStudent.State.Disconnected)
		{
			student.state = pm.ControlledStudent.State.Connecting;
			this._refreshTeam(teamIndex);
			this._sendConnectToTeacherServerPacket(student);
		}
		else if (student.state === pm.ControlledStudent.State.Connecting)
		{
			var date = new Date();

			if (date - student.lastConnectPacketDate >= pm.STUDENT_CONNECT_RETRY_TIMEOUT)
			{
				this._refreshTeam(teamIndex);
				this._sendConnectToTeacherServerPacket(student);
			}
		}
	},

	_sendConnectToTeacherServerPacket: function (student)
	{
		student.lastConnectPacketDate = new Date();

		var packetData = {t: pm.BROADCAST_DATA_PACKET_TYPE.CONNECT_TO_TEACHER_SERVER};

		pm.networkUtils.log("Sending connect to teacher to " + student.host, pm.NetworkDebugSendTypes.BROADCAST_DATA, packetData, student.host);

		pm.broadcastServer.sendPacket(student.host, JSON.stringify(packetData));
	},

	_refreshTeam: function(teamIndex)
	{
		var item = this._teamList.getItem(teamIndex);

		if(item)
			item.refresh();
	},

	_childSelected: function (flag)
	{
		this._freeStudentsSelectedMap = {};

		for(var i = 0; i < this._freeStudents.length; ++i)
		{
			if (this._freeStudentsList.getItem(i))
				this._freeStudentsSelectedMap[this._freeStudents[i].host] = this._freeStudentsList.getItem(i).getChecked();
		}
	},

	_dataCallback: function (host, packet) {},

	_piktomir: function()
	{
		pm.settings.isEditorMode = false;
		if(this._server)
			this._server.stop();

		pm.broadcastServer.stopDiscover();
		pm.broadcastClient.setPaused(false);

		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new StartMenuScene());
		cc.director.runScene(trans);
	},

	_unionIntoGame: function ()
	{
		this.chooseGameLevelLayout = new ChooseLevelLayer(this._okLevel, this);
		cc.director.getRunningScene().addChild(this.chooseGameLevelLayout, 1000);
	},

	_unionIntoGameN: function()
	{
		var numberInTeam = Number(this._unionInput.getString());

		if (!numberInTeam || numberInTeam < 1)
			return;

		this.chooseGameLevelLayout = new ChooseLevelLayer(this._okUnionLevel, this);
		cc.director.getRunningScene().addChild(this.chooseGameLevelLayout, 1000);
	},

	_okLevel: function ()
	{
		var sendStudents = [];

		var removeList = [];

		for (var i = 0; i < this._freeStudents.length; ++i)
		{
			if (this._freeStudentsList.getItem(i).getChecked())
			{
				sendStudents.push(this._freeStudents[i]);
				removeList.push(i);
			}
		}

		for (var i = 0; i < removeList.length; ++i)
		{
			this._freeStudents.splice(removeList[i] - i, 1);
			this._freeStudentsList.removeChild(this._freeStudentsList.getItem(removeList[i] - i));
		}

		if (world.games[this.chooseGameLevelLayout.gameIndex].levels[this.chooseGameLevelLayout.levelIndex].robots.length !== sendStudents.length)
			return;

		var server = sendStudents[0];

		var team = new pm.ControlledTeam(server.host, server.name, this.chooseGameLevelLayout.gameIndex, this.chooseGameLevelLayout.levelIndex);

		for (var i = 1; i < sendStudents.length; ++i)
			team.students.push(sendStudents[i]);

		this._server.teams.push(team);
		this._refreshChangeLevelMenu();

		var teamGameLayout = new TeamGameLayout(team, this._teamList.getChildrenCount(), this._commonAutoChange);
		teamGameLayout.setIndexCallback(this._teamLayerCallback.bind(this));

		this._teamList.pushBackCustomItem(teamGameLayout);

		this.chooseGameLevelLayout.chooseLevelLayout.removeFromParent();
		this.chooseGameLevelLayout.removeFromParent();
		this.chooseGameLevelLayout.chooseLevelLayout = null;
		this.chooseGameLevelLayout = null;

		team.hostStudent.state = pm.ControlledStudent.State.Connecting;

		for (var i = 0; i < sendStudents.length; ++i)
		{
			delete this._freeStudentsSelectedMap[sendStudents[i].host];

			sendStudents[i].state = pm.ControlledStudent.State.Connecting;
			this._sendConnectToTeacherServerPacket(sendStudents[i]);
		}

		this._refreshTeam(this._server.teams.length-1);
	},

	_okUnionLevel: function ()
	{
		var numberInTeam = Number(this._unionInput.getString());

		this._unionCount = Math.floor(this._freeStudents.length/numberInTeam);

		this._unionGameIndex = this.chooseGameLevelLayout.gameIndex;
		this._unionLevelIndex = this.chooseGameLevelLayout.levelIndex;

		this.chooseGameLevelLayout.chooseLevelLayout.removeFromParent();
		this.chooseGameLevelLayout.removeFromParent();
		this.chooseGameLevelLayout.chooseLevelLayout = null;
		this.chooseGameLevelLayout = null;

		this._unionTeam();
	},

	_unionTeam: function()
	{
		if (this._unionCount <= 0)
			return;

		var numberInTeam = Number(this._unionInput.getString());

		var sendStudents = [];

		var removeList = [];

		for (var i = 0; i < numberInTeam; ++i)
		{
			sendStudents.push(this._freeStudents[i]);
			removeList.push(i);
		}

		for (var i = 0; i < removeList.length; ++i)
		{
			this._freeStudents.splice(removeList[i] - i, 1);
			this._freeStudentsList.removeChild(this._freeStudentsList.getItem(removeList[i] - i));
		}

		var server = sendStudents[0];

		var team = new pm.ControlledTeam(server.host, server.name, this._unionGameIndex, this._unionLevelIndex);

		for (var i = 1; i < sendStudents.length; ++i)
			team.students.push(sendStudents[i]);

		this._server.teams.push(team);
		this._refreshChangeLevelMenu();

		var teamGameLayout = new TeamGameLayout(team, this._teamList.getChildrenCount(), this._commonAutoChange);
		teamGameLayout.setIndexCallback(this._teamLayerCallback.bind(this));

		this._teamList.pushBackCustomItem(teamGameLayout);

		team.hostStudent.state = pm.ControlledStudent.State.Connecting;

		for (var i = 0; i < sendStudents.length; ++i)
		{
			delete this._freeStudentsSelectedMap[sendStudents[i].host];

			sendStudents[i].state = pm.ControlledStudent.State.Connecting;
			this._sendConnectToTeacherServerPacket(sendStudents[i]);
		}

		this._refreshTeam(this._server.teams.length-1);

		--this._unionCount;

		setTimeout(this._unionTeam.bind(this), pm.UNION_GAME_TIMEOUT);
	},

	_editLevel: function()
	{
		this.chooseGameLevelLayout = new ChooseLevelLayer(this._okEditLevel, this);
		cc.director.getRunningScene().addChild(this.chooseGameLevelLayout, 1000);
	},

	_showTime: function(sender, redraw)
	{
		if (redraw === undefined)
			redraw = false;

		if (!this._server.teams || this._server.teams.length === 0)
			return;

		var game = this._server.teams[0].game;
		var width = world.getLevelCount(game);
		var height = this._server.teams.length;

		var matrix = [];
		var string = [];

		string.push("Ком.");

		for (var i = 0; i < world.getLevelCount(game); ++i)
		{
			string.push(i+1 + ' ');
		}

		string.push("Ит.");
		string.push("All");

		matrix.push(string);

		for (var i = 0; i < height; ++i)
		{
			string = [];

			var team = this._server.teams[i];

			var nameString = "";

			nameString += team.hostStudent.name.substring(0, 3);

			for (var st = 0; st < this._server.teams[i].students.length; ++st)
				nameString += ', ' + this._server.teams[i].students[st].name.substring(0, 3);

			string.push(nameString);

			var totalTime = 0;

			for (var j = 0; j < width; ++j)
			{
				if (!team.solvingTimes)
				{
					string.push("0:0:0");
					continue;
				}

				var timeOfSolving = team.solvingTimes[j];

				if (!timeOfSolving)
					timeOfSolving = 0;

				var solvingSeconds = timeOfSolving % 60;

				var timeOfSolvingMinutes = Math.floor(timeOfSolving / 60);
				var solvingMinutes = timeOfSolvingMinutes % 60;

				var timeOfSolvingHours = Math.floor(timeOfSolvingMinutes / 60);
				var solvingHours = timeOfSolvingHours % 60;

				var tempStr = solvingHours+":"+solvingMinutes+":"+solvingSeconds;

				string.push(tempStr);
				totalTime += timeOfSolving;
			}

			solvingSeconds = totalTime % 60;

			timeOfSolvingMinutes = Math.floor(totalTime / 60);
			solvingMinutes = timeOfSolvingMinutes % 60;

			timeOfSolvingHours = Math.floor(timeOfSolvingMinutes / 60);
			solvingHours = timeOfSolvingHours % 60;

			var tempStr = solvingHours+":"+solvingMinutes+":"+solvingSeconds;

			string.push(tempStr);
			string.push(totalTime);

			matrix.push(string);
		}

		this._sortMatrix(matrix, matrix.length, matrix[0].length);

		this._drawTimeLayout(matrix, redraw);
	},

	_drawTimeLayout: function (matrix, redraw)
	{
		if (this._timeLayout)
		{
			this._timeLayout.removeFromParent();
			this._timeLayout = null;

			if (redraw)
				this._drawTimeTable(matrix);
		}
		else
		{
			if (!redraw)
				this._drawTimeTable(matrix);
		}
	},

	_drawTimeTable: function(matrix)
	{
		this._timeLayout = new pmui.OvalLayout(null, null, pmui.OvalLayout.Type.SLIM);

		var timeAlign = new ccui.RelativeLayoutParameter();
		timeAlign.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
		this._timeLayout.setLayoutParameter(timeAlign);

		var maxWidth = 0;
		var maxHeight = 0;
		var completedLevels = [];

		var game = this._server.teams[0].game;
		var levelCount = world.getLevelCount(game);
		for (var i = 0; i < matrix.length; ++i)
		{
			var notCompletedLevels = 0;
			for (var j = 0; j < matrix[i].length; ++j)
			{
				var child = new ccui.Text(matrix[i][j], pm.settings.fontName, 18);
				maxWidth = Math.max(maxWidth, child.width);
				maxHeight = Math.max(maxHeight, child.height);

				if (matrix[i][j] === '0:0:0' && j !== matrix[i].length - 2)
					++notCompletedLevels;
			}

			completedLevels[i] = levelCount - notCompletedLevels;
		}

		var cellSize = cc.size(maxWidth, maxHeight);
		var separatorSize = cc.size(5, 5);
		this.timeTable = new pmui.TableView(cellSize, separatorSize, matrix.length, matrix[0].length + 1, false);

		var timeTableAlign = new ccui.RelativeLayoutParameter();
		timeTableAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		timeTableAlign.setMargin(0, pmui.OvalLayout.SLIM_BORDER_RADIUS, 0, 0);
		this.timeTable.setLayoutParameter(timeTableAlign);

		for (var i = 0; i < matrix.length; ++i)
		{
			var elem = "";
			elem += matrix[i][0];

			var child = new ccui.Text(elem,  pm.settings.fontName, 18);
			this.timeTable.setCell(i, 0, child);

			if (i === 0)
				elem = "№";
			else
				elem = i;

			child = new ccui.Text(elem,  pm.settings.fontName, 18);
			this.timeTable.setCell(i, 1, child);

			if (i === 0)
				elem = "Кол.";
			else
				elem = completedLevels[i];

			child = new ccui.Text(elem,  pm.settings.fontName, 18);
			this.timeTable.setCell(i, 2, child);

			for (var j = 1; j < matrix[i].length - 1; ++j)
			{
				elem = matrix[i][j];

				child = new ccui.Text(elem,  pm.settings.fontName, 18);
				this.timeTable.setCell(i, j + 2, child);
			}
		}

		this.timeTable.setContentSize((cellSize.width + separatorSize.width) * (matrix[0].length + 1) + separatorSize.width,
			(cellSize.height + separatorSize.height) * matrix.length);
		this._timeLayout.setContentSizeWithBorder(Math.min(this.timeTable.getContentSize().width, pm.settings.getScreenSize().width), this._workHeight);

		this._timeLayout.addChild(this.timeTable);
		this.addChild(this._timeLayout, 100);
	},

	_compareStrings: function(a, n, m, i1, i2)
	{
		var notCompletedLevels1 = 0;
		var notCompletedLevels2 = 0;

		for (var j = 0; j < m; ++j)
		{
			if (a[i1][j] === '0:0:0')
				++notCompletedLevels1;
			if (a[i2][j] === '0:0:0')
				++notCompletedLevels2;
		}

		if (notCompletedLevels1 < notCompletedLevels2)
			return 0;
		else if (notCompletedLevels1 > notCompletedLevels2)
			return 1;
		else
			return a[i1][m-1] > a[i2][m-1];
	},

	_sortMatrix: function(a, n, m)
	{
		for (var i = 0; i < n-1; ++i)
		{
			for (var j = 1; j < n-1-i; ++j)
			{
				if (this._compareStrings(a, n, m, j, j+1))
				{
					for (var k = 0; k < m; ++k)
					{
						var t = a[j][k];
						a[j][k] = a[j+1][k];
						a[j+1][k] = t;
					}
				}
			}
		}
	},

	_okEditLevel: function()
	{
		var game = this.chooseGameLevelLayout.gameIndex;
		var level = this.chooseGameLevelLayout.levelIndex;

		this.chooseGameLevelLayout.removeFromParent();

		for (var i = 0; i < this._server.teams.length; ++i)
		{
			var team = this._server.teams[i];

			team.game = game;
			team.level = level;

			var item = this._teamList.getItem(i);

			item.setGameName(game);
			item.setLevelName(game, level);

			team.state = pm.NetworkGameState.Develop;
			team.statesMap = {};

			this._teamLayerCallback(TeamGameLayout.EVENT.EDIT_LEVEL, i);
		}
	},

	_prevLevel: function()
	{
		for (var i = 0; i < this._server.teams.length; ++i)
			this._teamLayerCallback(TeamGameLayout.EVENT.PREV_LEVEL, i);
	},

	_nextLevel: function()
	{
		for (var i = 0; i < this._server.teams.length; ++i)
			this._teamLayerCallback(TeamGameLayout.EVENT.NEXT_LEVEL, i);
	},

	_teamLayerCallback: function (event, t)
	{
		var team = this._server.teams[t];

		if(!team)
			return;

		switch (event)
		{
			case TeamGameLayout.EVENT.DESTROY_TEAM:

				if (team.hostStudent.client)
					this._server.sendStopGame(team.hostStudent.client);

				for (var c = 0; c < this._server.teams[t].students.length; ++c)
				{
					if (this._server.teams[t].students[c].client)
						this._server.sendStopGame(this._server.teams[t].students[c].client);
				}

				team.markDeleted = true;

				this._refreshTeam(t);

				break;

			case TeamGameLayout.EVENT.NEXT_LEVEL:
				if (team.level === world.getLevelCount(team.game) - 1)
					break;
				else
					++team.level;

				var item = this._teamList.getItem(t);

				item.setGameName(team.game);
				item.setLevelName(team.game, team.level);

				team.state = pm.NetworkGameState.Develop;
				team.statesMap = {};

				team.transitionFinished = false;
				this._changeLevelMenu.setEnabledPrevLevelButton(false);
				this._changeLevelMenu.setEnabledNextLevelButton(false);

				if (team.hostStudent.client)
					this._server.sendChangeLevel(team.hostStudent.client, team.game, team.level);

				for (var c = 0; c < this._server.teams[t].students.length; ++c)
				{
					if (this._server.teams[t].students[c].client)
						this._server.sendChangeLevel(this._server.teams[t].students[c].client, team.game, team.level);
				}

				team.clearPrograms();
				this._refreshTeam(t);

				break;
			case TeamGameLayout.EVENT.PREV_LEVEL:
				if (team.level === 0)
					break;
				else
					--team.level;

				var item = this._teamList.getItem(t);

				item.setGameName(team.game);
				item.setLevelName(team.game, team.level);

				team.state = pm.NetworkGameState.Develop;
				team.statesMap = {};

				team.transitionFinished = false;
				this._changeLevelMenu.setEnabledPrevLevelButton(false);
				this._changeLevelMenu.setEnabledNextLevelButton(false);

				if (team.hostStudent.client)
					this._server.sendChangeLevel(team.hostStudent.client, team.game, team.level);

				for (var c = 0; c < this._server.teams[t].students.length; ++c)
				{
					if (this._server.teams[t].students[c].client)
						this._server.sendChangeLevel(this._server.teams[t].students[c].client, team.game, team.level);
				}

				team.clearPrograms();
				this._refreshTeam(t);

				break;
			case TeamGameLayout.EVENT.EDIT_LEVEL:
				if (team.hostStudent.client)
					this._server.sendChangeLevel(team.hostStudent.client, team.game, team.level);

				for (var c = 0; c < this._server.teams[t].students.length; ++c)
				{
					if (this._server.teams[t].students[c].client)
						this._server.sendChangeLevel(this._server.teams[t].students[c].client, team.game, team.level);
				}

				team.clearPrograms();
				this._refreshTeam(t);
				this._refreshChangeLevelMenu();

				break;
			case TeamGameLayout.EVENT.SEND_TIME_INFO:
				if (team.hostStudent.client)
					this._server.sendTimeInfo(team.hostStudent.client, team.solvingTimes);

				for (var c = 0; c < this._server.teams[t].students.length; ++c)
				{
					if (this._server.teams[t].students[c].client)
						this._server.sendTimeInfo(this._server.teams[t].students[c].client, team.solvingTimes);
				}

				break;
			case TeamGameLayout.EVENT.TRANSITION_FINISHED:
				this._refreshChangeLevelMenu();

				break;
		}
	}
});

var ClassControlScene = cc.Scene.extend({
	onEnterTransitionDidFinish: function ()
	{
		this._super();
		var layer = new ClassControlLayer();
		this.addChild(layer);

		var backLayer = pm.backgroundUtils.generateBackground();
		this.addChild(backLayer, -1);
	}
});

pm.DISCOVER_EXPIRE_TIMEOUT = 3000;
pm.UNION_GAME_TIMEOUT = 1000;
pm.STUDENT_CONNECT_RETRY_TIMEOUT = 30000;
pm.STUDENT_LOST_TIMEOUT = 15000;

ClassControlLayer.BORDER = 15;
ClassControlLayer.SEPARATOR = 25;
ClassControlLayer.SMALL_SEPARATOR = 3;
ClassControlLayer.MEDIUM_SEPARATOR = 7;
ClassControlLayer.UPPER_MARGIN = 80;
ClassControlLayer.BOTTOM_MARGIN = 68;
ClassControlLayer.SEPARATOR_NUMBER = 3;
ClassControlLayer.CLASS_WIDTH_PERCENT = 0.25;
ClassControlLayer.GAME_WIDTH_PERCENT = 0.75;
ClassControlLayer.BORDER_RADIUS = 14;
ClassControlLayer.CONFIRM_LAYER_TAG = 4766;
