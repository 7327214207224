/**
 * Created by Kirill Mashchenko on 28.06.17.
 */

/**
 * @class This a interface for 2D map elements.
 * @interface
 * @extends pm.MapElement
 */
pm.MapElement2D = pm.MapElement.extend(/** @lends pm.MapElement2D#*/{

	ctor: function()
	{
		this._super();
	},

	generateTerrainSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new pm.TerrainSprite(pm.spriteUtils.getMapGrassTileFrame(this._type));

			this._notCompletedSprite = new cc.Sprite("MapsPics/mapElement_error_selection.png");

			this._notCompletedSprite.setPosition(cc.p(this.sprite.getSprite().getContentSize().width/2, this.sprite.getSprite().getContentSize().height/2));
			this._notCompletedSprite.setAnchorPoint(cc.p(0.5, 0.5));
			this._notCompletedSprite.setVisible(false);

			this.sprite.getSprite().addChild(this._notCompletedSprite, 10);

			return this.sprite;
		}
	},

	setType: function (type)
	{
		this._type = type;

		if(this.sprite && this.sprite.getSprite())
			this.sprite.getSprite().setSpriteFrame(pm.spriteUtils.getMapGrassTileFrame(type));
	},

	getType: function ()
	{
		return this._type;
	}
});
