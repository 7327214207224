/**
 * Created by Antony Orlovsky on 19.07.19.
 */

var GrasshopperMapEditorLayer = GrasshopperMapLayer.extend(SquarableMapEditorLayerHelper).extend({

	createMapElement: function ()
	{
		var el = null;
		el = new pm.data.GrasshopperMapElement();
		el.originalType = GrasshopperMapElementType.LightBlueGrass;
		el.initElement();
		return el;
	},

	_drawMapElement: function (pos)
	{
		var sprite = this._map.element(pos).generateTerrainSprite(pos.x - this._map.getStartElementPos(), this._previewDraw);
		sprite.getSprite().setPosition(this.realPosition(pos, cc.p(0, 0)));

		this.addTerrainObject(sprite, 0);
	},

	_drawMapElements: function()
	{
		for (var x = 0; x < this._map.width; ++x)
		{
			var p = cc.p(x, 0);
			var terrainSprite = this._map.element(p).generateTerrainSprite(x - this._map.getStartElementPos(), this._previewDraw);

			terrainSprite.getSprite().setPosition(this.realPosition(p, cc.p(0, 0)));
			this.addTerrainObject(terrainSprite, 0);

			if (this._map.element(p).startForRobot !== pm.MapElement.START_FOR_NO_ROBOT)
				this.drawRobotStartPositionMark(this._map.element(p).startForRobot, p);
		}
	},

	_mapElementClicked: function (pos)
	{
		if (this._tapType === EditorTapsType.ChangeMapElement)
		{
			if (this._selectedObjectForTapType === pm.data.GrasshopperMapElement.START_ELEMENT_POS)
			{
				this._map.setStartElementPos(pos.x);

				for (var i = 0; i < this._map.width; ++i)
				{
					var element = this._map.element(cc.p(i, 0));
					element.updateElementNumber(i - pos.x);
				}
			}
			else
			{
				var element = this._map.element(pos);
				element.setType(this._selectedObjectForTapType);
				element.originalType = this._selectedObjectForTapType;
				pm.sendCustomEvent(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR);
			}
		}
		else if (this._tapType === EditorTapsType.AddRobot)
		{
			if (this._map.element(pos).getRobotsCount() !== 0)
				return;

			var robot = pm.moduleUtils.generateRobot(this._map.getType(), this._selectedObjectForTapType);
			this._addRobot(pos, robot);
			this._onAddRobot(robot);

			this._setActiveRobot(robot);
		}
		else if (this._tapType === EditorTapsType.AddObject)
		{
			if (this._map.element(pos).getObjectCount() !== 0)
				return;

			if (!pm.moduleUtils.canNotDragOrAddObject(this._map.getType(), this._map, pos))
				this._addObject(pos, pm.moduleUtils.generateObject(this._map.getType(), this._selectedObjectForTapType));
		}
		else if (this._tapType === EditorTapsType.AddClone)
		{
			if (this._map.element(pos).getRobotsCount() !== 0)
				return;

			var robot = pm.moduleUtils.generateRobot(this._map.getType(), this._activeRobot.getType());
			this._addClone(pos, robot);
			this._onAddRobot(robot);

			this._setActiveRobot(robot);
		}
	},

	moveElementsUp: function () {},

	moveElementsDown: function () {},

	moveElementsLeft: function ()
	{
		for (var i = this._map.width - 2; i >= 0; --i)
		{
			var elementType = this._map.element(cc.p(i, 0)).getType();

			this._map.element(cc.p(i + 1, 0)).setType(elementType);
			this._map.element(cc.p(i + 1, 0)).originalType = elementType;

			var type = GrasshopperMapElementType.LightBlueGrass;
			this._map.element(cc.p(i, 0)).setType(type);
			this._map.element(cc.p(i, 0)).originalType = type;
		}
	},

	moveElementsRight: function ()
	{
		for (var i = 1; i < this._map.width; ++i)
		{
			var elementType = this._map.element(cc.p(i, 0)).getType();

			this._map.element(cc.p(i - 1, 0)).setType(elementType);
			this._map.element(cc.p(i - 1, 0)).originalType = elementType;

			var type = GrasshopperMapElementType.LightBlueGrass;
			this._map.element(cc.p(i, 0)).setType(type);
			this._map.element(cc.p(i, 0)).originalType = type;
		}
	}
});
