/**
 * Updated by Mashchenko Kirill on 04.03.17.
 */

/**
 * Push object sprite animation types.
 * @enum {Number}
 */
var LightLampAnimation =
    {
    	TurnOn: 0,
    	TurnOff: 1
    };

/**
 * @class Sprite for {@link pm.data.LightLamp}
 * @interface
 * @extends pm.DynamicSprite
 */

var LightLampSprite = pm.StaticSprite.extend(/** @lends LightLampSprite#*/
	{
		lights: [],

		ctor: function(type)
		{
			this.lights=[];

			this._super("Objects/{0}.png".format(type));

			switch (type)
			{
				case pm.LightLevelModule.ObjectTypes.LightLamp0:
					this._minPoint = cc.p(42, 20);
					this._maxPoint = cc.p(42, 0);
					break;
				case pm.LightLevelModule.ObjectTypes.LightLamp1:
					this._minPoint = cc.p(12, 12);
					this._maxPoint = cc.p(12, 0);
					break;
			}
			var light;
			var coordinates = [cc.p(13, 142), cc.p(74, 142), cc.p(13, 172), cc.p(74, 172), cc.p(43, 175)];

			if (type === pm.LightLevelModule.ObjectTypes.LightLamp0)
			{
				for (var i = 0; i < 5; ++i)
				{
					light = new cc.ParticleSystem("Particles/light.plist");
					light.setPositionType(cc.ParticleSystem.TYPE_GROUPED);

					light.setPosition(coordinates[i]);
					light.setVisible(false);

					this.lights.push(light);
					this.getSprite().addChild(light, -1);
				}
			}
			else if (type === pm.LightLevelModule.ObjectTypes.LightLamp1)
			{
				light = new cc.ParticleSystem("Particles/light.plist");
				light.setPositionType(cc.ParticleSystem.TYPE_GROUPED);

				light.setPosition(cc.p(13.5, 34));
				light.setVisible(false);

				this.lights.push(light);
				this.getSprite().addChild(light, -1);
			}
		},

		playAnimation: function(endCallBack, target, type, args)
		{
			if(!CORE_BUILD)
			{
				switch(type)
				{
					case LightLampAnimation.TurnOn:
						this._turnOn();
						break;
					case LightLampAnimation.TurnOff:
						this._turnOff();
						break;
				}
				if(endCallBack)
					endCallBack();
			}
		},

		_turnOn: function()
		{
			for (var i = 0; i < this.lights.length; ++i)
				this.lights[i].setVisible(true);
		},

		_turnOff: function()
		{
			for (var i = 0; i < this.lights.length; ++i)
				this.lights[i].setVisible(false);
		}
	});
