/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.GlobalBodyNode = pmui.BodyNode.extend(/** @lends pmui.NodeLayer# */{

	ctor: function (parent, syntaxModule)
	{
		pmui.NodeLayer.prototype.ctor.call(this, parent, syntaxModule);
		this._repTypes = [FunctionButton.Type.Alloc, FunctionButton.Type.Statement, FunctionButton.Type.Empty];
		this._cont = new ccui.Layout();
		this._cont.setLayoutType(ccui.Layout.RELATIVE);
		this._repTypes = [FunctionButton.Type.Alloc, FunctionButton.Type.Statement, FunctionButton.Type.Empty];

		this._firstElem = new pmui.NodeLayer(this, null);
		var firstAlign = new ccui.RelativeLayoutParameter();
		firstAlign.setMargin(0,0,0,0);
		firstAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		firstAlign.setRelativeName(this._id + pm.BodyFirstChildTag);
		this._firstElem.setLayoutParameter(firstAlign);
		this._firstElem._id = this._id + pm.BodyFirstChildTag;
		this._firstElem.setContentSize(100,20);

		this._cont.addChild(this._firstElem);
		this.addChild(this._cont);
		this.calculateSize(true);
	},

	getNodeId: function()
	{
		return "global_body";
	},

	getChildrenNodes: function()
	{
		return [];
	}
});

