/**
 * Created by Kirill Mashchenko on 24.04.20.
 */

var FunctionButtonP = FunctionButton.extend(/** @lends FunctionButtonP#*/{

	_drawBackground: false,

	ctor: function(type, value, drawBackground)
	{
		this._value = value;

		if (drawBackground === undefined)
			drawBackground = false;

		this._drawBackground = drawBackground;

		this._super(type);
	},

	getValue: function()
	{
		return this._value;
	},

	setValue: function(value)
	{
		this._value = value;
		this._valueChanged();
	},

	getType: function()
	{
		return this._type;
	},

	setType: function(type, value)
	{
		if (type === FunctionButton.Type.Empty)
		{
			this._type = type;
			return;
		}

		if (this._type === FunctionButton.Type.CondRepeater)
		{
			this.removeChildByTag(FunctionButton.BACKGROUND_SPRITE_TAG);
			this.getVirtualRenderer().setScale(1.0);
		}

		this._type = type;

		if (value === undefined)
			this.setValue(this._value);
		else
			this.setValue(value);

		if (this._type === FunctionButton.Type.CondRepeater)
		{
			var sprite = pm.spriteUtils.getRepeaterSprite("cond");
			sprite.setPosition(0, this.height);
			sprite.setAnchorPoint(cc.p(0, 1));

			this.addChild(sprite, -1, FunctionButton.BACKGROUND_SPRITE_TAG);

			this.getVirtualRenderer().setScale(FunctionButton.COND_REPEATER_SCALE);
		}

		if(this._backSprite)
		{
			this._backSprite.removeFromParent();
			this._backSprite = null;
		}

		if (this._drawBackground)
		{
			this._backSprite = this._getBackgroundSprite();

			if (this._backSprite !== "")
			{
				this._backSprite.setPosition(cc.p(0, this.height));
				this._backSprite.setAnchorPoint(cc.p(0, 1));
				this.addChild(this._backSprite, -2);
			}
		}
	},

	/**
	 * Clones button normal image.
	 * @returns {ccui.ImageView}
	 */
	cloneImage: function()
	{
		return new ccui.ImageView(this._getSpriteName(pm.NORMAL_STATE), ccui.Widget.PLIST_TEXTURE);
	},

	/**
	 * Selects function button and writes to {@link FunctionButton.selectedButton}
	 */
	select: function()
	{
		if(FunctionButton.selectedButton !== this)
		{
			if(FunctionButton.selectedButton)
				FunctionButton.selectedButton.stopAnimation();

			FunctionButton.selectedButton = this;
			this.startAnimation();
		}
		else
		{
			FunctionButton.deselect();
		}
	},

	_valueChanged: function()
	{
		if(this._type === FunctionButton.Type.Empty)
			return;

		this.loadTextureNormal(this._getSpriteName(pm.NORMAL_STATE), ccui.Widget.PLIST_TEXTURE);
		this.loadTexturePressed(this._getSpriteName(pm.SELECTED_STATE), ccui.Widget.PLIST_TEXTURE);
		this.loadTextureDisabled(this._getSpriteName(pm.DISABLED_STATE), ccui.Widget.PLIST_TEXTURE);
	},

	_getSpriteName: function(state)
	{
		switch (this._type)
		{
			case FunctionButton.Type.Repeater:
				return pm.spriteUtils.getRepeaterSpriteName(this._value, state);
			case FunctionButton.Type.Condition:
			case FunctionButton.Type.CondRepeater:
				return pm.spriteUtils.getRobotConditionSpriteName(this._value, state);
			case FunctionButton.Type.Method:
				return pm.spriteUtils.getRobotMethodSpriteName(this._value, state);
		}
		return "";
	},

	_isEmpty: function()
	{
		switch (this._type)
		{
			case FunctionButton.Type.Repeater:
				return (this._value === pm.REPEATER_EMPTY);
			case FunctionButton.Type.Condition:
			case FunctionButton.Type.CondRepeater:
				return (this._value === pm.CONDITION_EMPTY);
			case FunctionButton.Type.Method:
				return (this._value === pm.EMPTY_METHOD);
		}
	},

	_getBackgroundSprite: function()
	{
		switch (this._type)
		{
			case FunctionButton.Type.Repeater: return pm.spriteUtils.getRepeaterSprite("shadow");
			case FunctionButton.Type.Condition: return pm.spriteUtils.getRobotConditionSprite("shadow");
			case FunctionButton.Type.CondRepeater: return pm.spriteUtils.getRepeaterSprite("shadow");
			case FunctionButton.Type.Method: return pm.spriteUtils.getRobotMethodSprite("shadow");
		}

		return "";
	}
});

var _p = FunctionButtonP.prototype;

/** @expose */
_p.value;
cc.defineGetterSetter(_p, "value", _p.getValue, _p.setValue);

/** @expose */
_p.type;
cc.defineGetterSetter(_p, "type", _p.getType, _p.setType);
