/**
 * Created by Kirill Mashchenko on 17.05.23.
 */

/**
 * @class Layer for light task.
 * @extends TaskLayer
 * @constructor
 * @param {pm.AbstractLevel} level
 * @param {pm.data.PushTask} task
 */

var PressTaskLayer = TaskLayer.extend(/** @lends LightTaskLayer# */{

	ctor: function(level, task)
	{
		this._super(LocalizedString("PressTask"), level, task, pm.PressRobotLevelModule.Type);

		pm.registerCustomEventListener(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR, this._updateTaskData.bind(this), this);
	},

	_updateTaskData: function()
	{
		this._level.activeMap.updatePressTargetElements();
	}
});
