/**
 * Created by Kirill Mashchenko on 11.08.17.
 */

pmui.SelectionListView = ccui.Layout.extend({

	_selectedIndex: -1,
	_selectedLayer: null,
	_callback: null,
	_target: null,
	_items: [],
	_listView: null,

	ctor: function (items, layoutSize)
	{
		this._super();
		this._items = items;

		this._listView = new ccui.ListView();
		this._listView.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._listView.setItemsMargin(pmui.SelectionListView.LIST_ITEM_SEPARATOR);
		this._listView.setScrollBarEnabled(true);
		this._listView.setScrollBarOpacity(255 * 0.9);
		this._listView.setScrollBarWidth(pmui.SelectionListView.SCROLL_BAR_WIDTH);

		this.setContentSize(layoutSize.width, layoutSize.height);
		this._listView.setContentSize(layoutSize.width, layoutSize.height);

		this.addChild(this._listView);

		this._fillList();

		this._selectedLayer = new cc.LayerColor(cc.color(144, 144, 144, 144));

		this._selectedLayer.setVisible(false);
		this.addChild(this._selectedLayer, -1);

		this._listView.addEventListener(this._onSelectStep.bind(this));
	},

	setIndexCallback: function (callback, target)
	{
		this._callback = callback;
		this._target = target;
	},

	getSelectedItemIndex: function()
	{
		return this._selectedIndex;
	},

	addItem: function(item)
	{
		this._items.push(item);

		var cutNumber = item.length;
		while ((new ccui.Text(item.substring(0, cutNumber), pm.settings.fontName, pm.settings.fontSize)).width > this.width)
			cutNumber--;

		var child = new ccui.Text(item.substring(0, cutNumber), pm.settings.fontName, pm.settings.fontSize);

		var width = Math.max(this.width, child.width);

		child.setTouchEnabled(true);
		this._listView.pushBackCustomItem(child);

		this.setContentSize(width, this.height);
		this._listView.setContentSize(width, this.height);
	},

	removeItem: function(index)
	{
		this._items.splice(index, 1);
		this._listView.removeItem(index);
	},

	_fillList: function()
	{
		var width = this.width;

		for(var i = 0 ; i < this._items.length; ++i)
		{
			var cutNumber = this._items[i].length;
			while ((new ccui.Text(this._items[i].substring(0, cutNumber), pm.settings.fontName, pm.settings.fontSize)).width > this.width)
				cutNumber--;

			var child = new ccui.Text(this._items[i].substring(0, cutNumber), pm.settings.fontName, pm.settings.fontSize);

			width = Math.max(width, child.width);

			child.setTouchEnabled(true);
			this._listView.pushBackCustomItem(child);
		}

		this.setContentSize(width, this.height);
		this._listView.setContentSize(width, this.height);
	},

	_onSelectStep: function(sender, event)
	{
		if(event === ccui.ListView.ON_SELECTED_ITEM_END)
		{
			if (this._selectedIndex !== this._listView.getCurSelectedIndex())
			{
				this._selectedIndex = this._listView.getCurSelectedIndex();

				this._moveSelectionLayer();
			}
		}
	},

	_moveSelectionLayer: function()
	{
		if(this._selectedIndex === -1)
			return;

		var item = this._listView.getItem(this._selectedIndex);

		this._selectedLayer.setContentSize(cc.size(
			this.width,
			item.height + pmui.SelectionListView.LIST_ITEM_SEPARATOR
		));

		var itemPosInLayout = this.convertToNodeSpace(item.convertToWorldSpace(cc.p(0, 0)));

		this._selectedLayer.setPosition(cc.p(
			0,
			itemPosInLayout.y - pmui.SelectionListView.LIST_ITEM_SEPARATOR/2
		));

		this._selectedLayer.setVisible(true);

		if(this._callback)
			this._callback.call(this._target, this._selectedIndex);
	},

	clearSelection: function()
	{
		this._selectedIndex = -1;

		this._selectedLayer.setVisible(false);
	}
});

pmui.SelectionListView.SCROLL_BAR_WIDTH = 5;
pmui.SelectionListView.LIST_ITEM_SEPARATOR = 10;
pmui.SelectionListView.START_CUT_NUMBER = 45;
pmui.SelectionListView.BORDER = 30;
