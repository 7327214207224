/**
 * Created by Nikita Besshaposhnikov on 24.10.14.
 */

/**
 * This callback is used when some element is clicked.
 * @callback pmui.SlidingMenu~clickCallback
 * @param {Number} index Index of selected element.
 */

/**
 * @class Layer for sliding menu.
 * @example
 * var menu = new pmui.SlidingMenu(clickCallback, this);
 * menu.addMenuElement(new cc.Layer(), "New Element 1");
 * menu.addMenuElement(new cc.Layer(), "New Element 2");
 * menu.setCurrentElement(1);
 * this.addChild(menu);
 *
 * @extends ccui.ListView
 * @constructor
 * @param {Number} width Width of menu.
 * @param {pmui.SlidingMenu~clickCallback} callback Callback on click some element.
 * @param {cc.Node} target Callback target
 * @param {ccui.ScrollView.DIR_NONE | ccui.ScrollView.DIR_VERTICAL | ccui.ScrollView.DIR_HORIZONTAL | ccui.ScrollView.DIR_BOTH} direction
 */
pmui.SlidingMenu = ccui.ListView.extend(/** @lends pmui.SlidingMenu# */{

	ctor: function (width, height, callback, target, direction)
	{
		this._super();

		this._elementCallback = callback;
		this._elementCallbackTarget = target;

		this._viewWidth = width;
		this._viewHeight = height;

		this.setTouchEnabled(true);
		this.setBounceEnabled(true);
		this.setInertiaScrollEnabled(true);
		this.setClippingEnabled(false);

		this.setScrollBarEnabled(false);
		this.setDirection(direction);

		this.addEventListener(this._onSelectItem.bind(this));
	},

	_viewWidth: 0.0,
	_viewHeight: 0.0,

	_elementCallback: null,
	_elementCallbackTarget: null,

	_onSelectItem: function(sender, event)
	{
		if(event === ccui.ListView.ON_SELECTED_ITEM_END)
		{
			var clickedItem = this.getCurSelectedIndex();
			this._elementCallback.call(this._elementCallbackTarget, clickedItem);
		}
	},

	/**
	 * Adds element to menu with label.
	 * @param {pmui.SlidingMenuElement} element
     */
	addMenuElement: function(element)
	{
		this.pushBackCustomItem(element);
		this._updateContainer();
	},
	/**
     * Inserts element to menu with label.
     * @param {pmui.SlidingMenuElement} element
	 * @param {Number} index
     */
	insertMenuElement: function(element, index)
	{
		this.insertCustomItem(element, index);
		this._updateContainer();
	},
	/**
	 * Sets focus on element by index.
	 * @param {Number} index Index of new focused element.
	 * @param {Boolean} animated Animate scroll action.
	 */
	setCurrentElement: function(index, animated)
	{
		if(!animated)
		{
			setTimeout(function()
			{
				this.jumpToItem(index, cc.p(0.5, 0.5), cc.p(0.5, 0.5));
			}.bind(this), 0);
		}
		else
		{
			this.scrollToItem(index, cc.p(0.5, 0.5), cc.p(0.5, 0.5));
		}
	},

	_updateContainer: function()
	{
		var childCount = this.getChildrenCount();
		var children = this.getChildren();

		for(var i = 0; i < childCount; ++i)
			this._viewHeight = Math.max(this._viewHeight, children[i].getContentSize().height);

		this.setContentSize(this._viewWidth, this._viewHeight);
	}
});

pmui.SlidingGameMenu = pmui.SlidingMenu.extend(/** @lends pmui.SlidingGameMenu# */{

	_selectedItem: null,

	ctor: function(width, height, callback, target)
	{
		this._super(width, height, callback, target, ccui.ScrollView.DIR_HORIZONTAL);

		this.setGravity(ccui.ListView.GRAVITY_CENTER_VERTICAL);
		this.setMagneticType(ccui.ListView.MAGNETIC_CENTER);
		this.setItemsMargin(pmui.SlidingGameMenu.ITEMS_MARGIN);
		this.addEventListener(this._onSelectItem.bind(this));

		if (cc.sys.isNative)
			ccui.ScrollView.prototype._addEventListener.call(this, this._selectCenterItem.bind(this));
		else
			ccui.ScrollView.prototype.addEventListener.call(this, this._selectCenterItem.bind(this));

	},

	_selectCenterItem: function(target, event)
	{
		var curElem = this.getCenterItemInCurrentView();

		if (this._selectedItem !== curElem)
		{
			if (this._selectedItem)
				this._selectedItem.runAction(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY / 2, 1));

			this._selectedItem = curElem;

			if (this._selectedItem)
				this._selectedItem.runAction(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY / 2, 1.3));
		}
	}
});

pmui.SlidingGameMenu.ITEMS_MARGIN = 50;

pmui.SlidingLevelMenu = pmui.SlidingMenu.extend(/** @lends pmui.SlidingGameMenu# */{

	ctor: function(width, callback, target)
	{
		this._super(width, 0, callback, target, ccui.ScrollView.DIR_HORIZONTAL);
	},

	_onSelectItem: function(sender, event)
	{
		if(event === ccui.ListView.ON_SELECTED_ITEM_END)
		{
			var clickedItem = this.getCurSelectedIndex()-1;
			if (clickedItem >= 0)
				this._elementCallback.call(this._elementCallbackTarget, clickedItem);
		}
	}
});
