/**
 * Created by Antony Orlovsky on 07.11.18.
 */

/**
 * @class Page for help about counter.
 * @extends ccui.Layout
 */
var CounterLessonLayer = ccui.Layout.extend(/** @lends CounterLessonLayer# */{

	_curWorldID: null,
	_loadingLayer: null,

	ctor: function(worldID)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);
		this.setContentSize(pm.settings.getScreenSize());

		this._curWorldID = worldID;
		pm.settings.setLevel(CounterLessonLayer.TUTORIAL_LEVEL_ID);

		this._loadInterface();
		pm.registerCustomEventListener(pm.TUTORIAL_ENDED, this._drawTutorialLevel.bind(this), this);

	},

	_loadInterface: function()
	{
		var screenBounds = pm.settings.getScreenBounds();

		var backButton = new pmui.Button(pm.spriteUtils.getIconName("backLevel", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("backLevel", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("backLevel", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		backButton.addClickEventListener(this._startScreen.bind(this));

		var backButtonAlign = new ccui.RelativeLayoutParameter();
		backButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		backButtonAlign.setRelativeName("back");
		backButtonAlign.setMargin(screenBounds.left, screenBounds.top, 0, 0);
		backButton.setLayoutParameter(backButtonAlign);

		this.addChild(backButton);

		var selectPageMenu = new SelectPageMenu(SelectPageMenu.PAGES.COUNTER, this._stopTutorial.bind(this), this);

		var selectPageAlign = new ccui.RelativeLayoutParameter();
		selectPageAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		selectPageAlign.setMargin(SelectPageMenu.LEFT_MARGIN, -screenBounds.top, 0, 0);
		selectPageAlign.setRelativeName("selectPage");
		selectPageAlign.setRelativeToWidgetName("back");
		selectPageMenu.setLayoutParameter(selectPageAlign);

		this.addChild(selectPageMenu);

		var counterLabel = new ccui.Text(LocalizedString("Magic counter"), pm.settings.fontName,
			CounterLessonLayer.TITLE_FONT_SIZE);
		counterLabel.setAnchorPoint(cc.p(0, 0.5));

		var counterLabelAlign = new ccui.RelativeLayoutParameter();
		counterLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		counterLabelAlign.setRelativeName("counterLabel");
		counterLabelAlign.setRelativeToWidgetName("selectPage");
		counterLabelAlign.setMargin((pm.settings.getScreenSize().width - backButton.width - selectPageMenu.width - counterLabel.width)/2, screenBounds.top, 0, 0);
		counterLabel.setLayoutParameter(counterLabelAlign);

		this.addChild(counterLabel);

		var counterDescription = LocalizedString("CounterDescriptionHelp");

		var counterDescriptionLabel = new ccui.Text(counterDescription, pm.settings.fontName,
			CounterLessonLayer.DESCRIPTION_FONT_SIZE);

		counterDescriptionLabel.setTextAreaSize(cc.size(this.width * (1 - CounterLessonLayer.TUTORIAL_SCALE)
			- CounterLessonLayer.TEXT_AREA_WIDTH, 0));

		var counterDescriptionAlign = new ccui.RelativeLayoutParameter();
		counterDescriptionAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		counterDescriptionAlign.setRelativeName("counterDescription");
		counterDescriptionAlign.setRelativeToWidgetName("back");
		counterDescriptionAlign.setMargin(CounterLessonLayer.STANDARD_SEPARATOR, CounterLessonLayer.STANDARD_SEPARATOR, 0, 0);
		counterDescriptionLabel.setLayoutParameter(counterDescriptionAlign);

		this.addChild(counterDescriptionLabel);

		this._drawTutorialLevel();

		var commandsLabel = new ccui.Text(LocalizedString("COMMANDS"), pm.settings.fontName, pm.settings.fontSize);

		var commandsLabelAlign = new ccui.RelativeLayoutParameter();
		commandsLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		commandsLabelAlign.setRelativeName("commands");
		commandsLabelAlign.setRelativeToWidgetName("counterDescription");
		commandsLabelAlign.setMargin(CounterLessonLayer.TITLE_MARGIN, 2*CounterLessonLayer.STANDARD_SEPARATOR, 0, 0);
		commandsLabel.setLayoutParameter(commandsLabelAlign);

		this.addChild(commandsLabel);

		var counterMethodsBar = new ccui.VBox();

		var width = 0;
		var height = CounterLessonLayer.STANDARD_SEPARATOR;

		var methods = this._getCounterMethods();
		pm.spriteUtils.addTempSpriteFrames("Robot Methods/counter-methods.plist");
		pm.spriteUtils.addTempSpriteFrames("Conditions/counter-conditions.plist");
		pm.spriteUtils.addTempSpriteFrames("Repeaters/counter-repeaters.plist");

		for (var method in methods)
		{
			var methodContainer = new ccui.HBox();

			var containerMargin = new ccui.LinearLayoutParameter();
			containerMargin.setMargin(0, CounterLessonLayer.STANDARD_SEPARATOR, 0, 0);
			methodContainer.setLayoutParameter(containerMargin);

			counterMethodsBar.addChild(methodContainer);

			var button = pm.appUtils.generateFunctionButton(FunctionButton.Type.Method, methods[method]);
			methodContainer.addChild(button);

			var label = new ccui.Text(this._getCommandDescription(methods[method]), pm.settings.fontName, pm.settings.fontSize);

			var labelMargin = new ccui.LinearLayoutParameter();
			labelMargin.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
			labelMargin.setMargin(CounterLessonLayer.STANDARD_SEPARATOR, 0, 0, 0);
			label.setLayoutParameter(labelMargin);

			methodContainer.addChild(label);

			methodContainer.setContentSize(button.width + CounterLessonLayer.STANDARD_SEPARATOR + label.width,
				Math.max(button.height, label.height));

			width = Math.max(methodContainer.width, width);
			height += CounterLessonLayer.STANDARD_SEPARATOR + methodContainer.height;
		}

		counterMethodsBar.setContentSize(width, height);

		var methodsBarAlign = new ccui.RelativeLayoutParameter();
		methodsBarAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		methodsBarAlign.setRelativeName("methodsBar");
		methodsBarAlign.setRelativeToWidgetName("commands");
		methodsBarAlign.setMargin(-CounterLessonLayer.TITLE_MARGIN, 0, 0, 0);
		counterMethodsBar.setLayoutParameter(methodsBarAlign);

		this.addChild(counterMethodsBar);

		var conditionsLabel = new ccui.Text(LocalizedString("CONDITIONS"), pm.settings.fontName, pm.settings.fontSize);

		var conditionsLabelAlign = new ccui.RelativeLayoutParameter();
		conditionsLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		conditionsLabelAlign.setRelativeName("conditions");
		conditionsLabelAlign.setRelativeToWidgetName("methodsBar");
		conditionsLabelAlign.setMargin(CounterLessonLayer.TITLE_MARGIN, CounterLessonLayer.STANDARD_SEPARATOR, 0, 0);
		conditionsLabel.setLayoutParameter(conditionsLabelAlign);

		this.addChild(conditionsLabel);

		var counterCondRepBar = new ccui.VBox();

		width = 0;
		height = CounterLessonLayer.STANDARD_SEPARATOR;

		var conditions = this._getCounterConditions();

		for (var condition in conditions)
		{
			var conditionContainer = new ccui.HBox();

			var containerMargin = new ccui.LinearLayoutParameter();
			containerMargin.setMargin(0, CounterLessonLayer.STANDARD_SEPARATOR, 0, 0);
			conditionContainer.setLayoutParameter(containerMargin);

			counterCondRepBar.addChild(conditionContainer);

			var button = pm.appUtils.generateFunctionButton(FunctionButton.Type.Condition, conditions[condition]);
			conditionContainer.addChild(button);

			var label = new ccui.Text(this._getCommandDescription(conditions[condition]), pm.settings.fontName, pm.settings.fontSize);

			var labelMargin = new ccui.LinearLayoutParameter();
			labelMargin.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
			labelMargin.setMargin(CounterLessonLayer.STANDARD_SEPARATOR, 0, 0, 0);
			label.setLayoutParameter(labelMargin);

			conditionContainer.addChild(label);

			conditionContainer.setContentSize(button.width + CounterLessonLayer.STANDARD_SEPARATOR + label.width,
				Math.max(button.height, label.height));

			width = Math.max(conditionContainer.width, width);
			height += CounterLessonLayer.STANDARD_SEPARATOR + conditionContainer.height;
		}

		var repeater = this._getCounterRepeater();

		var repeaterContainer = new ccui.HBox();

		var containerMargin = new ccui.LinearLayoutParameter();
		containerMargin.setMargin(0, CounterLessonLayer.STANDARD_SEPARATOR, 0, 0);
		repeaterContainer.setLayoutParameter(containerMargin);

		counterCondRepBar.addChild(repeaterContainer);

		var button = new RepeaterButton(repeater);
		repeaterContainer.addChild(button);

		var label = new ccui.Text(this._getCommandDescription(repeater), pm.settings.fontName, pm.settings.fontSize);

		var labelMargin = new ccui.LinearLayoutParameter();
		labelMargin.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
		labelMargin.setMargin(CounterLessonLayer.STANDARD_SEPARATOR, 0, 0, 0);
		label.setLayoutParameter(labelMargin);

		repeaterContainer.addChild(label);

		repeaterContainer.setContentSize(button.width + CounterLessonLayer.STANDARD_SEPARATOR + label.width,
			Math.max(button.height, label.height));

		width = Math.max(repeaterContainer.width, width);
		height += CounterLessonLayer.STANDARD_SEPARATOR + repeaterContainer.height;

		counterCondRepBar.setContentSize(width, height);

		var condRepBarAlign = new ccui.RelativeLayoutParameter();
		condRepBarAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		condRepBarAlign.setRelativeName("condRepBar");
		condRepBarAlign.setRelativeToWidgetName("conditions");
		condRepBarAlign.setMargin(-CounterLessonLayer.TITLE_MARGIN, 0, 0, 0);
		counterCondRepBar.setLayoutParameter(condRepBarAlign);

		this.addChild(counterCondRepBar);
	},

	_drawTutorialLevel: function()
	{
		if (this.getChildByTag(CounterLessonLayer.TUTORIAL_ID))
			this.removeChildByTag(CounterLessonLayer.TUTORIAL_ID);

		var tutorialData = {
			show: true,
			scale: CounterLessonLayer.TUTORIAL_SCALE
		};

		var levelContainer = new ccui.Layout();
		levelContainer.setContentSize(this.width * tutorialData.scale, this.height * tutorialData.scale);

		var levelContainerAlign = new ccui.RelativeLayoutParameter();
		levelContainerAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		levelContainerAlign.setMargin(CounterLessonLayer.STANDARD_SEPARATOR, 0, 0, 0);
		levelContainerAlign.setRelativeName("level");
		levelContainerAlign.setRelativeToWidgetName("counterDescription");
		levelContainer.setLayoutParameter(levelContainerAlign);

		this.addChild(levelContainer, 0, CounterLessonLayer.TUTORIAL_ID);

		var levelLayer = new LevelLayer(GameType.Local, null, pm.settings.getGame(), pm.settings.getLevel(), null, tutorialData);

		levelContainer.addChild(levelLayer);
	},

	_stopTutorial: function()
	{
		pm.tutorialUtils.stop();
		pm.sendCustomEvent(pm.PROGRAM_RESTART_EVENT_STR);
	},

	_getCounterMethods: function()
	{
		return {
			Inc: "cnt_inc",
			Dec: "cnt_dec",
			Empty: "empty_counter",
		};
	},

	_getCounterConditions: function()
	{
		return {
			Empty: "cnt_empty",
			NotEmpty: "cnt_not_empty"
		}
	},

	_getCounterRepeater: function()
	{
		return "cnt_value";
	},

	_getCommandDescription: function(name)
	{
		switch (name)
		{
			case pm.Counter.NativeMethod.Inc:
				return LocalizedString("put stone");
			case pm.Counter.NativeMethod.Dec:
				return LocalizedString("take stone");
			case pm.Counter.NativeMethod.Empty:
				return LocalizedString("take all");
			case pm.Counter.Condition.Empty:
				return LocalizedString("counter is empty");
			case pm.Counter.Condition.NotEmpty:
				return LocalizedString("counter is not empty");
			case pm.Counter.Repeater.Value:
				return LocalizedString("number of stones");
		}
	},

	_startScreen: function()
	{
		pm.settings.isHelpMode =  false;

		this._stopTutorial();

		this._downloadWorld(this._curWorldID);

		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new StartMenuScene());
		cc.director.runScene(trans);
	},

	_downloadWorld: function(worldID)
	{
		this._loadingLayer = new LoadingLayer();
		this._loadingLayer.show();

		pm.worldUtils.loadWorld({
			worldID: worldID,
			callback: this._endDownLoadWorld,
			callbackTarget: this,
			reloadBuiltinOnError: true
		});
	},

	_endDownLoadWorld: function(error, loadedWorldID)
	{
		if (!error && loadedWorldID.length > 0 && loadedWorldID[0])
			pm.settings.setSelectedWorldID(loadedWorldID[0]);

		this._loadingLayer.remove();
	},

	getCurrentWorldID: function()
	{
		return this._curWorldID;
	}
});

CounterLessonLayer.STANDARD_SEPARATOR = 8;
CounterLessonLayer.TEXT_AREA_WIDTH = 100;
CounterLessonLayer.TITLE_FONT_SIZE = 45;
CounterLessonLayer.DESCRIPTION_FONT_SIZE = 17;
CounterLessonLayer.TUTORIAL_SCALE = 0.55;
CounterLessonLayer.TITLE_MARGIN = 45;
CounterLessonLayer.TUTORIAL_LEVEL_ID = 5;
CounterLessonLayer.TUTORIAL_ID = 1001;

/**
 * @class Scene for {@link CounterLessonLayer}
 * @extends cc.Scene
 * @constructor
 */
var CounterLessonScene = cc.Scene.extend(/** @lends CounterLessonScene# */{
	ctor:function (worldID)
	{
		this._super();

		var layer = new CounterLessonLayer(worldID);
		this.addChild(layer);

		var backLayer = pm.backgroundUtils.generateBackground();
		this.addChild(backLayer, -1);
	}
});
