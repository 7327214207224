/**
 * Created by Kirill Mashchenko on 30.06.17.
 */

/**
 * @class This a interface for map elements with walls.
 * @interface
 * @extends pm.MapElement2D
 */

pm.MapElement4WithWalls = pm.MapElement2D.extend(/** @lends pm.MapElement4WithWalls#*/{

	wallUpSprite: null,
	wallLeftSprite: null,

	/**
	 * Walls all directions of map element.</br> If "walls[direction] === true" then there is wall in "direction".
	 * @type {Array<Boolean>}
	 */
	walls: [],

	ctor: function ()
	{
		this._addNonEnumerableProps("wallUpSprite", "wallLeftSprite");
		this._super();

		this.setDimension(4);
	},

	/**
	 * Initializes wal array for dimension.
	 * @param {Number} dimension Target dimension
	 */
	setDimension: function(dimension)
	{
		this.walls.length = dimension;

		for(var i = 0; i < dimension; ++i)
			this.walls[i] = false;
	},

	/**
     * Generates Wall Left Sprite
     */
	generateWallLeftSprite: function(orientation)
	{
		if(!CORE_BUILD)
		{
			if(this.walls[MapDirection4.Right])
				this.wallLeftSprite = new pm.WallSprite4(pm.WallType4.Left, orientation);
		}
	},

	/**
     * Generates Wall Up Sprite
     */
	generateWallUpSprite: function(orientation)
	{
		if(!CORE_BUILD)
		{
			if(this.walls[MapDirection4.Down])
				this.wallUpSprite = new pm.WallSprite4(pm.WallType4.Up, orientation);
		}
	}

});
