/**
 * Created by Nikita Besshaposhnikov on 20.03.17.
 */

pm.GameServerImpl = pm.GameServer.extend({

	_clients: [],
	_playersConnectedCallback: null,
	_requiredPlayersCount: 0,

	_robotIndexMap: {},

	ctor: function()
	{
		this._super();
		this._clients = [];
		this._robotIndexMap = {};

		this.setEventCallback(this._eventCallback.bind(this));
		this.setPacketCallback(this._packetCallback.bind(this));
	},

	setPlayersConnectedCallback: function(callback)
	{
		this._playersConnectedCallback = callback;
	},

	setPlayersCount: function(count)
	{
		this._requiredPlayersCount = count;
	},

	_sendLoadLevel: function(client, robotIndex)
	{
		var packet = {
			type: pm.GAME_SERVER_PACKET_TYPE.LOAD_LEVEL,
			robotIndex: robotIndex
		};

		pm.networkUtils.log("Sending load level packet to client with id: " + client.getId(), pm.NetworkDebugSendTypes.GAME_SERVER, packet);

		this.sendPacketToClient(client.getId(), JSON.stringify(packet));
	},

	_eventCallback: function(event, client)
	{
		switch(Number(event))
		{
			case pm.GameServer.CLIENT_CONNECTED_EVENT:
				pm.networkUtils.log("Client connected with id: " + client.getId() + ", host: " + client.getHost(), pm.NetworkDebugSendTypes.GAME_SERVER);

				if(this._clients.length === this._requiredPlayersCount)
					return;

				this._clients.push(client);

				if(this._clients.length === this._requiredPlayersCount)
				{
					if(this._playersConnectedCallback)
						this._playersConnectedCallback.call();

					pm.networkUtils.log("Send load level packet to all clients", pm.NetworkDebugSendTypes.GAME_SERVER);

					for(var i = 0 ; i < this._clients.length; ++i)
					{
						if(!this._clients[i].loaded)
						{
							var robotIndex = this._robotIndexMap[this._clients[i].getHost()];

							if(robotIndex === undefined)
							{
								robotIndex = i;
								this._robotIndexMap[this._clients[i].getHost()] = i;
							}

							this._sendLoadLevel(this._clients[i], robotIndex);
							this._clients[i].loaded = true;
						}
					}

					pm.networkUtils.log("Final robot index map: " + JSON.stringify(this._robotIndexMap), pm.NetworkDebugSendTypes.GAME_SERVER);
				}

				break;

			case pm.GameServer.CLIENT_DISCONNECTED_EVENT:
				pm.networkUtils.log("Client disconnected with id: " + client.getId(), pm.NetworkDebugSendTypes.GAME_SERVER);

				var clientIndex = this._clients.indexOf(client);

				if (this._clients[clientIndex])
				{
					this._clients[clientIndex].loaded = false;
					this._clients.splice(clientIndex, 1);
				}
				break;
		}
	},

	cleanReadyFlag: function()
	{
		for(var i = 0; i < this._clients.length; ++i)
			this._clients[i].ready = false;
	},

	stop: function ()
	{
		this._clients = [];
		pm.GameServer.prototype.stop.call(this);
	},

	_packetCallback: function(client, packet)
	{
		var parsedPacket = undefined;

		try
		{
			parsedPacket = JSON.parse(packet);
		}
		catch (e)
		{
			pm.networkUtils.log("Incorrect packet from client: " + client.getId() + ". Packet data: \n" + packet, pm.NetworkDebugSendTypes.GAME_SERVER, packet);
			return;
		}

		if(parsedPacket !== null && !parsedPacket.type)
		{
			pm.networkUtils.log("Incorrect packet from client(no type field): " + client.getId() + ". Packet data: \n" + packet, pm.NetworkDebugSendTypes.GAME_SERVER, packet);
			return;
		}

		switch(parsedPacket.type)
		{
			case pm.GAME_CLIENT_PACKET_TYPE.PROGRAM_UPDATED:
				pm.networkUtils.log("Received program packet from client " + client.getId(), pm.NetworkDebugSendTypes.GAME_SERVER, parsedPacket);

				parsedPacket.type = pm.GAME_SERVER_PACKET_TYPE.PROGRAM_UPDATED;
				parsedPacket.robotIndex = this._robotIndexMap[client.getHost()];

				pm.networkUtils.log("Sending program packet to all clients", pm.NetworkDebugSendTypes.GAME_SERVER, parsedPacket);

				this.sendBroadcastPacket(JSON.stringify(parsedPacket));

				break;
			case pm.GAME_CLIENT_PACKET_TYPE.READY_FOR_GAME:
				pm.networkUtils.log("Received ready for game packet from client " + client.getId(), pm.NetworkDebugSendTypes.GAME_SERVER, parsedPacket);

				client.ready = parsedPacket.ready;

				var send = true;

				for(var i = 0; i < this._clients.length; ++i)
					send = send && this._clients[i].ready;

				if(send)
				{
					var startPacket = {type: pm.GAME_SERVER_PACKET_TYPE.START_GAME};

					pm.networkUtils.log("Send start game packet to all clients", pm.NetworkDebugSendTypes.GAME_SERVER, startPacket);

					this.sendBroadcastPacket(JSON.stringify(startPacket));
				}

				break;
		}
	},

	setRobotIndexMap: function (robotIndexMap)
	{
		this._robotIndexMap = robotIndexMap;
	},

	getRobotIndexMap: function ()
	{
		return this._robotIndexMap;
	}
});

