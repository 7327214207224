/**
 * Created by Nikita Besshaposhnikov on 11.11.14.
 */

/**
 * @class pm.Task for repairing elements.</br>
 * Contains number of elements to repair.
 * @implements pm.Task
 * @constructor
 * @param {pm.AbstractLevel} level
 */
pm.data.RepairTask = pm.Task.extend(/** @lends pm.data.RepairTask*/ {
	typeName: "RepairTask",

	ctor: function(level)
	{
		this._super(level);
	},

	getNotCompletedObjects: function (mapIndex)
	{
		var repaired = 0;
		var badRepaired = 0;

		var map = this._level.maps[mapIndex];

		var retArray = [];

		for (var x = 0; x < map.width; ++x)
		{
			for (var y = 0; y < map.height; ++y)
			{
				if(map.element(cc.p(x, y)).isRepaired())
					++repaired;
				else if(map.element(cc.p(x, y)).isBadRepaired())
					++badRepaired;
			}
		}

		if (!(badRepaired === 0 && repaired === this.data[mapIndex]))
		{
			for (var x = 0; x < map.width; ++x)
			{
				for (var y = 0; y < map.height; ++y)
				{
					if (map.element(cc.p(x, y)).getType() === RepairMapElementType.BrokenGrass)
						retArray.push(map.element(cc.p(x, y)));
					else if (map.element(cc.p(x, y)).isBadRepaired())
						retArray.push(map.element(cc.p(x, y)));
				}
			}
		}

		return retArray;
	},

	fillWithInitialData: function(mapIndex, data)
	{
		if(data === undefined)
			data = 0;

		this.data[mapIndex] = data;
	}

});
