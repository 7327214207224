/**
 * Created by Diana Aglyamutdinova 22.04.2019
 */

/**
 * @class start view of program container Layer
 * @extends pmui.NodeLayer
 * @constructor
 * @param {Number} [hideDuration=pmui.PopupLayer.STANDARD_HIDE_DURATION] Delay before self removing.
 */

pmui.VariableNode = pmui.NodeLayer.extend(/** @lends pmui.NodeLayer# */{

	_name: null,
	_varName: "",
	_typeName: "",

	ctor: function (treeNode, parent, syntaxModule, name, typeName)
	{
		this._withRoundBorder = true;
		this._super(parent, treeNode, syntaxModule);
		this.type = FunctionButton.Type.Variable;
		this._repTypes = [FunctionButton.Type.Number, FunctionButton.Type.Variable, FunctionButton.Type.Expression,
			FunctionButton.Type.Constant, FunctionButton.Type.IntMethod];
		this.setLayoutType(ccui.Layout.RELATIVE);

		this._varName = name;
		this._typeName = typeName;

		var nameText = this.createText(name, pmui.NodeLayer.CONSTR_COLOR);
		this.createALignSome(nameText,"","",ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
		this.addChild(nameText);

		if(!pm.syntaxModules.isTextual(this.getSyntaxModule()))
			this.setBackGroundImage(pm.spriteUtils.getNodeLayerElementName("number-round", pm.SELECTED_STATE));

		this.calculateSize(true);
		pm.registerCustomEventListener(pm.VARIABLE_DEFINITION_REMOVED, function (event)
		{
			var data = event.getUserData();
			if (data  === this._varName && !(this._parentNode instanceof pmui.AllocNode))
				this._removeNodeFromTree();
		}.bind(this), this);
		pm.registerCustomEventListener(pm.DESELECT_FUNCTIONBUTTON, function (event)
		{
			if(this.getChildByTag(pmui.VariableNode.VARTAG))
				this.getChildByTag(pmui.VariableNode.VARTAG).removeFromParent();
		}.bind(this), this);

	},

	calculateSize: function(notCalculateSize)
	{
		if(pm.syntaxModules.isTextual(this.getSyntaxModule()))
		{
			this.setContentSize(this.createText(this._varName).width, this.createText(this._varName).height);
		}
		else
		{
			this.setContentSize(ProgramLayer.BUTTON_HEIGHT, ProgramLayer.BUTTON_HEIGHT);

		}
		if(!notCalculateSize)
			this._parentNode.calculateSize();
	},

	clone: function()
	{
		var but = new VarFunctionButton(FunctionButton.Type.Variable, this._varName, this._typeName, this);
		return but;
	},

	getName: function ()
	{
		return this._varName;
	},

	isBool: function ()
	{
		return pmui.AllocNode.boolVals.indexOf(this._varName) !== -1;
	},

	isInt: function ()
	{
		return pmui.AllocNode.intVals.indexOf(this._varName) !== -1;
	},

	getVarType: function ()
	{
		return this._typeName;
	},

	makeCleanUp: function ()
	{
		if(this.getChildByTag(pmui.VariableNode.VARTAG) === FunctionButton.selectedButton && FunctionButton.selectedButton)
			FunctionButton.deselect();
	},

	deSelect: function ()
	{
		if(this._selected)
		{
			this.drawBackground();
			this._selected = false;
			this.setTag(ProgramContainerLayer.NOT_SELECTED_TAG);
		}
	},

	clickCallback: function ()
	{
		if(!this.getChildByTag(pmui.VariableNode.VARTAG)) {
			var name = new VarFunctionButton(FunctionButton.Type.Variable, this._varName, this._typeName);
			name.setTag(pmui.VariableNode.VARTAG);
			name.select();
			this.addChild(name);
		}
		else
		{
			this.getChildByTag(pmui.VariableNode.VARTAG).removeFromParent();
			FunctionButton.selectedButton = null;
		}
	}
});

pmui.VariableNode.VARTAG = 1;
