/**
 * Created by Kirill Mashchenko on 12.07.19.
 */
/**
 * Animation type for paint robot.
 * @enum {String}
 * @readonly
 */
var KumirRobotAnimation = {Paint: "paint"};

/**
 * @class 2D Sprite for {@link pm.data.KumirRobot}
 * @implements pm.IsoRobotSprite
 */
var KumirRobotSprite = pm.IsoRobotSprite.extend(/** @lends KumirRobotSprite# */{
	ctor: function()
	{
		cc.animationCache.addAnimations("Robot/kumir_robot-animations.plist");

		pm.spriteUtils.registerTempSpriteFrames("Robot/kumir_robot.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/kumir-indicate.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/kumir_robot-broken.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/kumir_robot-paint.plist");

		this._super(cc.spriteFrameCache.getSpriteFrame(this._getFrameName(0)));

		this._minPoint = cc.p(24, 26);
		this._maxPoint = cc.p(24, 26);

		this._commandsSprite.setOpacity(0);
	},

	_getActiveSpritePosition: function()
	{
		return cc.p(24, 57);
	},

	_getCommandsSpritePosition: function()
	{
		return cc.p(24, 25);
	},

	_getFrameName: function(direction)
	{
		return "kumir_robot0.png";
	},

	_getDestroyAnimationName: function(direction)
	{
		return "kumir_robot_d_{0}".format(direction);
	},

	_getIndicateAnimationName: function(direction)
	{
		return "kumir_robot_i_0";
	},

	//getRealPosition: function(x,y)
	//{
	//    return this.mapLayer.realPosition(x, y, -35, 35);
	//},

	playAnimation: function(callback, target, type, args)
	{
		switch(type)
		{
			case KumirRobotAnimation.Paint: this._paint(args, callback, target); break;
			default: pm.IsoRobotSprite.prototype.playAnimation.call(this, callback, target, type, args); break;
		}
	},

	_paint: function(direction, endCallback, target)
	{
		var animation = cc.animationCache.getAnimation("kumir_robot_p_0");
		animation.setDelayPerUnit(pm.settings.getAnimationSpeed()/animation.getTotalDelayUnits());
		var animate = new cc.Animate(animation);

		var turnEnd = new cc.CallFunc(endCallback, target);
		this.getSprite().runAction( new cc.Sequence(animate, turnEnd));
	},

	setActive: function(flag)
	{
		this._activeCircleSprite.setVisible(flag);
		this._activeSprite.setVisible(flag);
	},

	showCommands: function(flag, count)
	{
		if (flag && MethodStackLayer.opened)
			return;

		if (flag)
			this._commandsCount.setString(count);

		this._commandsSprite.setVisible(flag);
	}
});
