
var OutputTaskLayer = TaskLayer.extend(/** @lends ExCounterMemoryTaskLayer# */{

	_memoryValue: null,

	ctor: function(level, task)
	{
		this._super(LocalizedString("OutputTask"), level, task, pm.GlobalTaskType.OutputValue);

		var layout = new ccui.HBox();
		var layoutAlign = new ccui.LinearLayoutParameter();
		layoutAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
		layoutAlign.setMargin(15, 5, 0, 0);
		layoutAlign.setMargin(LevelSettingsLayer.BORDER_RADIUS + 15, LevelSettingsLayer.SEPARATOR, 0, 0);

		layout.setLayoutParameter(layoutAlign);

		var valueLabel = new pmui.Text(LocalizedString("Output"), pm.settings.fontSize);
		layout.addChild(valueLabel);

		//TODO Support robot groups
		var value = this._task.data[this._level.getActiveMapIndex()];

		this._memoryValue = new pmui.TextInput(String(value), "0", 15, 40);

		var memoryValueAlign = new ccui.LinearLayoutParameter();
		memoryValueAlign.setMargin(5, 0, 0, 0);
		this._memoryValue.setLayoutParameter(memoryValueAlign);
		layout.addChild(this._memoryValue);

		this._memoryValue.setInsertTextCallback(this, this._memoryValueChanged);

		layout.setContentSize(this.width, Math.max(valueLabel.height, this._memoryValue.height));
		this.addChild(layout);

		var height = this.height + layout.height + LevelSettingsLayer.BORDER_RADIUS;

		this.setContentSize(this.width, height);
	},

	_memoryValueChanged: function()
	{
		this._task.data[this._level.getActiveMapIndex()] = Number(this._memoryValue.getString());
	},

	refresh: function(mapIndex)
	{
		var value = this._task.data[mapIndex];

		this._memoryValue.setString(String(value));
	}
});
