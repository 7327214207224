/**
 * Created by Kirill Mashchenko on 26.06.19.
 */

pm.NetworkDebugClientImpl = pm.GameClient.extend({

	ctor: function()
	{
		this._super();
	}
});

