/**
 * Created by Nikita Besshaposhnikov on 09.04.15.
 */

/**
 * @class 2D sprite of {pm.data.PushRobot}.
 * @implements pm.IsoRobotSprite
 */
var PushRobotSprite = pm.IsoRobotSprite.extend(/** @lends PushRobotSprite# */{
	ctor: function()
	{
		cc.animationCache.addAnimations("Robot/push_robot-animations.plist");

		pm.spriteUtils.registerTempSpriteFrames("Robot/push_robot.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/push_robot-indicate.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/push_robot-broken.plist");

		this._super(cc.spriteFrameCache.getSpriteFrame(this._getFrameName(0)));

		this._minPoint = cc.p(44, 29);
		this._maxPoint = cc.p(44, -2);
	},

	_getActiveSpritePosition: function()
	{
		return cc.p(48, 84);
	},

	_getCommandsSpritePosition: function()
	{
		return cc.p(48, 115);
	},

	_getFrameName: function(direction)
	{
		return "push_robot{0}.png".format(4 * direction);
	},

	_getTurnAnimationName: function(oldDirection, curDirection)
	{
		return "push_robot_{0}->{1}".format(oldDirection, curDirection);
	},

	_getDestroyAnimationName: function(direction)
	{
		return "push_robot_d_{0}".format(direction);
	},

	_getIndicateAnimationName: function(direction)
	{
		return "push_robot_i_{0}".format(direction);
	}
});
