/**
 * Created by Kirill Mashchenko on 07.07.2018.
 */
/**
 * Push object sprite animation types.
 * @enum {Number}
 */
var TrainObjectAnimation = {Move: 0};

/**
 * @class Common sprite interface for train objects.
 * @interface
 * @extends pm.DynamicSprite
 */
var TrainObjectSprite = pm.DynamicSprite.extend(/** @lends TrainObjectSprite#*/{

	_idNumber: null,
	_idSprite: null,

	activeSprite: null,

	ctor: function(frame, activeFrame)
	{
		this._super(frame);

		this.activeSprite = new cc.Sprite(activeFrame);

		this.activeSprite.setPosition(cc.p(0,0));
		this.activeSprite.setAnchorPoint(cc.p(0,0));
		this.activeSprite.setVisible(false);

		this.sprite.addChild(this.activeSprite);

		this._idSprite = new cc.Sprite(pm.spriteUtils.getInterfaceElementFrame("commandsNumber"));

		this._idSprite.setScaleY(0.35);
		this._idSprite.setScaleX(0.75);
		this._idSprite.setPosition(this._getIdNumberSpritePosition());
		this._idSprite.setAnchorPoint(cc.p(0.6, 0.6));
		this._idSprite.setColor(RobotColorList2D.Green);

		this.sprite.addChild(this._idSprite);

		this._idNumber = new ccui.Text("", pm.settings.fontBoldName, pm.settings.fontSize);
		this._idNumber.setColor(cc.color(1, 1, 180));
		this._idNumber.setAnchorPoint(cc.p(0.5, 0.5));

		if (cc.sys.isNative)
			this._idNumber.setNormalizedPosition(cc.p(0.5, 0.5));
		else
			this._idNumber.setNormalizedPosition(cc.p(0.5, 0.42));

		this._idSprite.addChild(this._idNumber);
	},

	updateNumber: function(id)
	{
		this._idNumber.setString(id)
	},

	setIdVisible: function()
	{
		this._idSprite.setVisible(false);
	},

	playAnimation: function(endCallBack, target, type, args)
	{
		switch(type)
		{
			case TrainObjectAnimation.Move: this._move(args, endCallBack, target); break;
		}
	},

	getAnimation: function(endCallBack, target, type, args)
	{
		switch(type)
		{
			case TrainObjectAnimation.Move: return this._getMoveAnimation(args, endCallBack, target);
		}
	},

	_move: function(target, endCallback, callbackTarget)
	{
		var sequenceAnimation = this._getMoveAnimation(target, endCallback, callbackTarget);

		this.sprite.runAction(sequenceAnimation);
	},

	_getMoveAnimation: function(target, endCallback, callbackTarget)
	{
		var targetPos = this.getRealPosition(target);

		var moveObject = cc.moveTo(pm.settings.getAnimationSpeed(), targetPos);

		var moveEnd = cc.callFunc(function() { this._needToUpdateZOrder = false; endCallback.call(callbackTarget); }, this);

		this._needToUpdateZOrder = true;

		return cc.sequence(moveObject, moveEnd);
	},
});
