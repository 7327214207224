/**
 * Created by Kirill Mashchenko on 17.05.23.
 */

/**
 * @class pm.Task for light elements.</br>
 * @implements pm.Task
 * @constructor
 * @param {pm.AbstractLevel} level
 */

pm.data.PressTask = pm.Task.extend(/** @lends pm.data.LightTask*/ {
	typeName: "PressTask",

	ctor: function(level)
	{
		this._super(level);
	},

	getNotCompletedObjects: function(mapIndex)
	{
		var map = this._level.maps[mapIndex];

		var completed = true;

		var retArray = [];

		var point = cc.p();
		var type = 0;

		for(var i = 0; i < map._pressTargetElements.length; ++i)
		{
			point = map._pressTargetElements[i].point;
			type = map._pressTargetElements[i].type;

			if(map.element(point).getObjectCount() === 1)
			{
				var elementObject = map.element(point).getObjectByKeyIndex(0);

				completed = type === elementObject.visualId;
			}
			else
			{
				completed = false;
			}

			if(!completed)
				retArray.push(map.element(point));
		}

		return retArray;
	},

	moveDown: function(mapIndex)
	{
		var map = this._level.maps[mapIndex];

		for (var i = 0; i < map._pressTargetElements.length; ++i)
			map._pressTargetElements[i].point.y--;
	},

	moveUp: function(mapIndex)
	{
		var map = this._level.maps[mapIndex];

		for (var i = 0; i < map._pressTargetElements.length; ++i)
			map._pressTargetElements[i].point.y++;
	},

	moveRight: function(mapIndex)
	{
		var map = this._level.maps[mapIndex];

		for (var i = 0; i < map._pressTargetElements.length; ++i)
			map._pressTargetElements[i].point.x--;
	},

	moveLeft: function(mapIndex)
	{
		var map = this._level.maps[mapIndex];

		for (var i = 0; i < map._pressTargetElements.length; ++i)
			map._pressTargetElements[i].point.x++;
	}
});
