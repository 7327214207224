/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class 2D robot for {@link pm.data.KumirLevel}.</br>
 * Has a paint element native function.
 * @implements PlayerRobot4
 */
pm.data.KumirRobot = pm.PlayerRobot2D.extend(/** @lends pm.data.KumirRobot# */{
	typeName: "KumirRobot",

	ctor: function()
	{
		this._super();

		this.nativeFunctionMap[pm.data.KumirRobot.NativeMethod.Paint] = new pm.NativeFunction(this, this._paint);

		this.nativeFunctionMap[pm.data.KumirRobot.NativeMethod.MoveUp] = new pm.NativeFunction(this, this._moveUp);
		this.nativeFunctionMap[pm.data.KumirRobot.NativeMethod.MoveDown] = new pm.NativeFunction(this, this._moveDown);
		this.nativeFunctionMap[pm.data.KumirRobot.NativeMethod.MoveLeft] = new pm.NativeFunction(this, this._moveLeft);
		this.nativeFunctionMap[pm.data.KumirRobot.NativeMethod.MoveRight] = new pm.NativeFunction(this, this._moveRight);

		if (pm.appUtils.useExtraMethodsInKumirRobot())
		{
			this.nativeFunctionMap[pm.data.KumirRobot.NativeMethod.Temperature] = new pm.NativeFunction(this, this._getTemperature, 0, 1);
			this.nativeFunctionMap[pm.data.KumirRobot.NativeMethod.Radiation] = new pm.NativeFunction(this, this._getRadiation, 0, 1);
		}

		this.nativeFunctions.push(pm.data.KumirRobot.NativeMethod.MoveUp, pm.data.KumirRobot.NativeMethod.MoveDown, pm.data.KumirRobot.NativeMethod.MoveLeft,
			pm.data.KumirRobot.NativeMethod.MoveRight, pm.data.KumirRobot.NativeMethod.Paint);

		if (pm.appUtils.useExtraMethodsInKumirRobot())
			this.nativeFunctions.push(pm.data.KumirRobot.NativeMethod.Temperature, pm.data.KumirRobot.NativeMethod.Radiation);

		this.conditions.push(pm.data.KumirRobot.Condition.GrassIsPainted);
		this.conditions.push(pm.data.KumirRobot.Condition.ClearUp);
		this.conditions.push(pm.data.KumirRobot.Condition.ClearDown);
		this.conditions.push(pm.data.KumirRobot.Condition.ClearLeft);
		this.conditions.push(pm.data.KumirRobot.Condition.ClearRight);

		this.conditionOpposites.push(pm.data.KumirRobot.Condition.GrassIsNotPainted);
		this.conditionOpposites.push(pm.data.KumirRobot.Condition.WallUp);
		this.conditionOpposites.push(pm.data.KumirRobot.Condition.WallDown);
		this.conditionOpposites.push(pm.data.KumirRobot.Condition.WallLeft);
		this.conditionOpposites.push(pm.data.KumirRobot.Condition.WallRight);
	},

	getDirectionCount: function() { return 4; },

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new KumirRobotSprite();

			return this.sprite;
		}
	},

	_paint: function()
	{
		this.playAnimation(KumirRobotAnimation.Paint, this._endPaint, 0);
	},

	_unpaint: function()
	{
		this.playAnimation(KumirRobotAnimation.Paint, this._endUnpaint, 0);
	},

	_endPaint: function()
	{
		var map = this.getMap();

		map.paintElement(this.position);

		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_endUnpaint: function()
	{
		var map = this.getMap();

		map.unpaintElement(this.position);

		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_moveUp: function()
	{
		this.direction = 2;

		var target = cc.p(this.position.x, this.position.y - 1);

		if (target.x < 0 || target.y < 0 || target.x >= this.getMap().width || target.y >= this.getMap().height)
			target = null;

		this._moveTo(target, false, MapDirection4.Up);
	},

	_moveDown: function()
	{
		this.direction = 0;

		var target = cc.p(this.position.x, this.position.y + 1);

		if (target.x < 0 || target.y < 0 || target.x >= this.getMap().width || target.y >= this.getMap().height)
			target = null;

		this._moveTo(target, false, MapDirection4.Down);
	},

	_moveLeft: function()
	{
		this.direction = 3;

		var target = cc.p(this.position.x - 1, this.position.y);

		if (target.x < 0 || target.y < 0 || target.x >= this.getMap().width || target.y >= this.getMap().height)
			target = null;

		this._moveTo(target, false, MapDirection4.Left);
	},

	_moveRight: function()
	{
		this.direction = 1;

		var target = cc.p(this.position.x + 1, this.position.y);

		if (target.x < 0 || target.y < 0 || target.x >= this.getMap().width || target.y >= this.getMap().height)
			target = null;

		this._moveTo(target, false, MapDirection4.Right);
	},

	_getTemperature: function()
	{
		return this.parentLevel.activeMap.element(this.position).getTemperature();
	},

	_getRadiation: function()
	{
		return this.parentLevel.activeMap.element(this.position).getRadiation();
	},

	_canMoveTo: function(target, direction)
	{
		var map = this.getMap();

		return !map.element(target).walls[(direction + this.getDirectionCount()/2) % this.getDirectionCount()];
	},

	_checkConditionInternal: function(condition, args)
	{
		var map = this.getMap();

		if (condition === pm.data.KumirRobot.Condition.GrassIsPainted)
		{
			return map.element(this.position).getType() === KumirMapElementType.BlueGrass
		}
		else if (condition === pm.data.KumirRobot.Condition.GrassIsNotPainted)
		{
			return map.element(this.position).getType() === KumirMapElementType.LightBlueGrass
				|| map.element(this.position).getType() === KumirMapElementType.UnpaintedGrass
		}
		else if (condition === pm.data.KumirRobot.Condition.WallUp)
		{
			return this._isNearWall(MapDirection4.Up);
		}
		else if (condition === pm.data.KumirRobot.Condition.ClearUp)
		{
			return !this._isNearWall(MapDirection4.Up);
		}
		else if (condition === pm.data.KumirRobot.Condition.WallDown)
		{
			return this._isNearWall(MapDirection4.Down);
		}
		else if (condition === pm.data.KumirRobot.Condition.ClearDown)
		{
			return !this._isNearWall(MapDirection4.Down);
		}
		else if (condition === pm.data.KumirRobot.Condition.WallLeft)
		{
			return this._isNearWall(MapDirection4.Left);
		}
		else if (condition === pm.data.KumirRobot.Condition.ClearLeft)
		{
			return !this._isNearWall(MapDirection4.Left);
		}
		else if (condition === pm.data.KumirRobot.Condition.WallRight)
		{
			return this._isNearWall(MapDirection4.Right);
		}
		else if (condition === pm.data.KumirRobot.Condition.ClearRight)
		{
			return !this._isNearWall(MapDirection4.Right);
		}

		return false;
	},

	_isNearWall: function (direction)
	{
		return this.getMap().element(this.position).walls[direction];
	},

	getState: function ()
	{
		return {
			position: this.position,
			direction: this.direction,
			animation: this.getLastAnimation()
		};
	},

	setState: function (state)
	{
		if (state.direction !== this.direction)
			this.direction = state.direction;

		if (state.position !== this.position || this.isBroken())
			this._moveTo(state.position, true);
		else if (state.animation === KumirRobotAnimation.Paint)
			this._unpaint();
	},

	getType: function() { return pm.KumirLevelModule.RobotType; }
});

pm.data.KumirRobot.Condition =
{
	GrassIsPainted: "kumir_gib",
	GrassIsNotPainted: "kumir_ginb",

	WallUp: "kumir_wall_up",
	WallDown: "kumir_wall_down",
	WallLeft: "kumir_wall_left",
	WallRight: "kumir_wall_right",

	ClearUp: "kumir_clear_up",
	ClearDown: "kumir_clear_down",
	ClearLeft: "kumir_clear_left",
	ClearRight: "kumir_clear_right"
};

pm.data.KumirRobot.NativeMethod =
{
	Paint: "kumir_paint",
	Unpaint: "kumir_unpaint",
	MoveUp: "kumir_move_up",
	MoveDown: "kumir_move_down",
	MoveLeft: "kumir_move_left",
	MoveRight: "kumir_move_right",
	Radiation: "kumir_radiation",
	Temperature: "kumir_temperature"
};
