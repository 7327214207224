/**
 * Created by Kirill Mashchenko on 22.07.19.
 */

/**
 * @class Layer for aquarius task.
 * @extends TaskLayer
 * @constructor
 * @param {pm.AbstractLevel} level
 * @param {pm.data.AquariusTask} task
 */

var AquariusTaskLayer = TaskLayer.extend(/** @lends AquariusTaskLayer# */{

	_literInput: null,

	ctor: function(level, task)
	{
		this._super(LocalizedString("AquariusTask"), level, task, pm.AquariusLevelModule.Type);

		var height = this.height;

		this._literInput = new pmui.TextInput("", LocalizedString("0"), 20, AquariusTaskLayer.TASK_WIDTH);
		this._literInput.setAttachCallback(this._updateTaskFromInput, this);

		var literAlign = new ccui.LinearLayoutParameter();
		literAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
		// literAlign.setMargin(LevelSettingsLayer.BORDER_RADIUS + 15, LevelSettingsLayer.SEPARATOR, 0, 0);
		this._literInput.setLayoutParameter(literAlign);

		this.addChild(this._literInput);

		if (this._task.data[this._level.getActiveMapIndex()] !== undefined && this._task.data[this._level.getActiveMapIndex()] !== null)
			this._literInput.setString(this._task.data[this._level.getActiveMapIndex()]);

		height += this._literInput.height + 20;

		this.setContentSize(this.width, height);
	},

	_updateTaskFromInput: function()
	{
		this._task.data[this._level.getActiveMapIndex()] = Number(this._literInput.getString());
	}
});

AquariusTaskLayer.TASK_WIDTH = 50.0;
