/**
 * Created by danilaeremin on 14.11.14.
 */

/**
 * @class This robot can store some value, inc && dec && clear this value.</br>
 * Also can store two additional static values </br>
 * Provides own conditions, repeaters and methods.
 * @extends pm.Counter
 */
pm.ExtendedCounter = pm.Counter.extend(/** @lends pm.ExtendedCounter# */{
	typeName: "ExtendedCounter",

	_memory: 0,

	_useCompareConditions: true,

	ctor: function()
	{
		this._super();

		this.nativeFunctionMap[pm.ExtendedCounter.NativeMethod.MemClear] = new pm.NativeFunction(this, this._memClear);
		this.nativeFunctionMap[pm.ExtendedCounter.NativeMethod.MemAdd] = new pm.NativeFunction(this, this._memAdd);
		this.nativeFunctionMap[pm.ExtendedCounter.NativeMethod.MemSub] = new pm.NativeFunction(this, this._memSub);
		this.nativeFunctionMap[pm.ExtendedCounter.NativeMethod.MemRestore] = new pm.NativeFunction(this, this._memRestore);

		this.nativeFunctions.push(pm.ExtendedCounter.NativeMethod.MemAdd,
			pm.ExtendedCounter.NativeMethod.MemSub, pm.ExtendedCounter.NativeMethod.MemClear);

		this.repeaters.push(pm.ExtendedCounter.Repeater.Memory)
			// pm.ExtendedCounter.Repeater.Random);
	},

	reconfigure: function(config)
	{
		var index = this.conditions.indexOf(pm.ExtendedCounter.Condition.LeftLRight);
		if (index >= 0)
			this.conditions.splice(index, 1);
		index = this.conditions.indexOf(pm.ExtendedCounter.Condition.RightLLeft);
		if (index >= 0)
			this.conditions.splice(index, 1);
		index = this.conditions.indexOf(pm.ExtendedCounter.Condition.Equal);
		if (index >= 0)
			this.conditions.splice(index, 1);
		index = this.conditions.indexOf(pm.ExtendedCounter.Condition.NotEqual);
		if (index >= 0)
			this.conditions.splice(index, 1);

		this.configure(config);
	},

	configure: function(config)
	{
		if(config.useCompareConditions === undefined)
			this._useCompareConditions = true;
		else
			this._useCompareConditions = config.useCompareConditions;

		if(this._useCompareConditions)
		{
			this.conditions.push(pm.ExtendedCounter.Condition.LeftLRight, pm.ExtendedCounter.Condition.RightLLeft,
				pm.ExtendedCounter.Condition.Equal, pm.ExtendedCounter.Condition.NotEqual);
		}
	},

	_memClear: function()
	{
		this._memory = 0;

		this.playAnimation(pm.ExtendedCounterSprite.Animation.MemSet, this._endPlayingAnimation, 0);
	},

	_memRestore: function()
	{
		this._memory = 0;

		this.playAnimation(pm.ExtendedCounterSprite.Animation.MemSet, this._endPlayingAnimation, this._memory);
	},

	_memAdd: function()
	{
		if(this._counter === 0)
			return;

		this._memory += this._counter;

		this.playAnimation(pm.ExtendedCounterSprite.Animation.MemSet, this._endPlayingAnimation, this._memory);
	},

	_memSub: function()
	{
		if(this._counter === 0)
			return;

		this._memory -= this._counter;
		this._memory = Math.max(0, this._memory);

		this.playAnimation(pm.ExtendedCounterSprite.Animation.MemSet, this._endPlayingAnimation, this._memory);
	},

	reset: function()
	{
		this._counter = 0;
		this._memory = 0;
		this.stepCount = 0;
		this.playAnimation(pm.CounterSprite.Animation.Reset, this._endPlayingAnimation);
		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_endPlayingAnimation: function()
	{
		this.clearStateFlag(pm.RobotState.PlayingAnimation);

		if (!CORE_BUILD && pm.settings.isAnimationDisabled())
			this._updateVisualState();
	},

	_updateVisualState: function ()
	{
		this.sprite._setStonesByValue(this._counter);
		this.sprite._countLabel.setString("{0}".format(this._counter));
		this.sprite._memoryLabel.setString("{0}".format(this._memory));
	},

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new pm.ExtendedCounterSprite();

			return this.sprite;
		}
	},

	_checkConditionInternal: function(condition, args)
	{
		switch(condition)
		{
			case pm.Counter.Condition.Empty: return this._counter === 0;
			case pm.Counter.Condition.NotEmpty: return this._counter !== 0;
			case pm.ExtendedCounter.Condition.LeftLRight: return this._memory < this._counter;
			case pm.ExtendedCounter.Condition.Equal: return this._memory === this._counter;
			case pm.ExtendedCounter.Condition.RightLLeft: return this._counter < this._memory;
			case pm.ExtendedCounter.Condition.NotEqual: return this._counter !== this._memory;
		}

		return false;
	},

	_getRepeaterValueInternal: function(repeater, args)
	{
		switch(repeater)
		{
			case pm.Counter.Repeater.Value: return this._counter;
			case pm.ExtendedCounter.Repeater.Memory: return this._memory;
			// case pm.ExtendedCounter.Repeater.Random: return 1 + Math.round(Math.random() * (this._counter - 1));

		}

		return 0;
	},

	updateSprite: function()
	{
		this.playAnimation(
			pm.CounterSprite.Animation.Set,
			null,
			[this._counter, this._memory],
			true
		);

		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	getType: function() { return pm.GlobalRobotType.ExtendedCounter; },

	getState: function ()
	{
		return {
			counter: this._counter,
			memory: this._memory
		};
	},

	setState: function (state)
	{
		var counter = state.counter;

		if (counter !== this._counter)
		{
			if (counter === this._counter - 1)
			{
				this._dec();
			}
			else if (counter === this._counter + 1)
			{
				this._inc();
			}
			else if (counter === 0)
			{
				this._counter = 0;
				this.playAnimation(pm.CounterSprite.Animation.Empty, this._endPlayingAnimation);
			}
			else
			{
				this._counter = state.counter;
				this.playAnimation(pm.CounterSprite.Animation.IncValue, this._endPlayingAnimation, this._counter);
			}
		}

		if (this._memory !== state.memory)
		{
			this._memory = state.memory;

			this.playAnimation(pm.ExtendedCounterSprite.Animation.MemSet, this._endPlayingAnimation, this._memory);
		}
	}
});

pm.ExtendedCounter.Condition = {
	LeftLRight: "cnt_llr",
	RightLLeft: "cnt_rll",
	Equal: "cnt_equal",
	NotEqual: "cnt_not_equal"
};

pm.ExtendedCounter.Repeater = {Memory: "cnt_mem", Random: "cnt_rand"};

pm.ExtendedCounter.NativeMethod = {
	MemClear: "cnt_mem_clear",
	MemAdd: "cnt_mem_add",
	MemSub: "cnt_mem_sub",
	MemRestore: "cnt_mem_restore"
};
