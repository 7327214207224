/**
 * Created by Nikita Besshaposhnikov on 21.05.15.
 */

/**
 * @class pm.Task for pushing objects on needed elements.
 * @implements pm.Task
 * @constructor
 * @param {pm.AbstractLevel} level
 */
pm.data.PushTask = pm.Task.extend(/** @ends pm.data.PushTask# */{
	typeName: "PushTask",

	ctor: function(level)
	{
		this._super(level);
	},

	getNotCompletedObjects: function(mapIndex)
	{
		var map = this._level.maps[mapIndex];

		var completed = true;

		var retArray = [];

		var point = cc.p();
		var type = 0;
		var elementObjectType = 0;

		for(var i = 0; i < map._pushTargetElements.length; ++i)
		{
			point = map._pushTargetElements[i].point;
			type = map._pushTargetElements[i].type;

			if(map.element(point).getObjectCount() === 1)
			{
				elementObjectType = map.element(point).getObjectByKeyIndex(0).getType();

				switch(type)
				{
					case PushMapElementType.BoxTarget:
						completed = elementObjectType === pm.PushLevelModule.ObjectTypes.PushBox;
						break;

					case PushMapElementType.BarrelTarget:
						completed = elementObjectType === pm.PushLevelModule.ObjectTypes.PushBarrel;
						break;

					case PushMapElementType.CommonTarget:
						break;
				}
			}
			else
			{
				completed = false;
			}

			if(!completed)
				retArray.push(map.element(point));
		}

		return retArray;
	},

	moveDown: function(mapIndex)
	{
		var map = this._level.maps[mapIndex];

		for (var i = 0; i < map._pushTargetElements.length; ++i)
			map._pushTargetElements[i].point.y--;
	},

	moveUp: function(mapIndex)
	{
		var map = this._level.maps[mapIndex];

		for (var i = 0; i < map._pushTargetElements.length; ++i)
			map._pushTargetElements[i].point.y++;
	},

	moveRight: function(mapIndex)
	{
		var map = this._level.maps[mapIndex];

		for (var i = 0; i < map._pushTargetElements.length; ++i)
			map._pushTargetElements[i].point.x--;
	},

	moveLeft: function(mapIndex)
	{
		var map = this._level.maps[mapIndex];

		for (var i = 0; i < map._pushTargetElements.length; ++i)
			map._pushTargetElements[i].point.x++;
	}
});
