/**
 * Created by Kirill Mashchenko on 25.01.18.
 */

pm.FlagRobot = pm.GlobalRobot.extend(/** @lends pm.FlagRobot# */{

	_flagState: "down",

	ctor: function()
	{
		this._super();
	},

	_up: function ()
	{
		this._flagState = pm.FlagRobot.State.Up;

		this.playAnimation(pm.FlagRobotSprite.Animation.Up, this._endPlayingAnimation);
	},

	_down: function ()
	{
		this._flagState = pm.FlagRobot.State.Down;

		this.playAnimation(pm.FlagRobotSprite.Animation.Down, this._endPlayingAnimation);
	},

	_endPlayingAnimation: function()
	{
		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	reset: function ()
	{
		this.stepCount = 0;
		this._down();
		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	getType: function (){},

	getState: function ()
	{
		return this._flagState;
	},

	setState: function (state)
	{
		if (state !== this._flagState)
		{
			if (state === pm.FlagRobot.State.Up)
				this._up();
			else
				this._down();
		}
	}
});

pm.FlagRobot.State = {
	Up: "up",
	Down: "down"
};
