"use strict";

/**
 * Created by Nikita Besshaposhnikov on 07.04.17.
 */

/**
 * @class BLE physical robot.
 * @extends pm.PhysicalConnector
 */
pm.BLEPhysicalConnector = pm.PhysicalConnector.extend(/** @lends pm.BLEPhysicalRobot# */{

	_currentCommand: "",

	connect: function()
	{
		if(pm.btleManager && pm.btleManager.isBTLEAvailable())
		{
			pm.btleManager.setReceiveDataCallback(this._dataReceiveCallback.bind(this));
			pm.btleManager.setConnectCallback(this._connectedCallback.bind(this));
			pm.btleManager.setDisconnectCallback(this._disconnectedCallback.bind(this));

			pm.btleManager.scan();
		}
		else
		if(this._stateCallback)
		{ this._stateCallback(pm.PhysicalConnector.STATE.DISCONNECTED); }
	},

	disconnect: function()
	{
		pm.btleManager.cleanup();
	},

	_connectedCallback: function()
	{
		this._connected = true;

		if(this._stateCallback)
			this._stateCallback(pm.PhysicalConnector.STATE.CONNECTED);
	},

	_disconnectedCallback: function()
	{
		this._connected = false;

		if(this._stateCallback)
			this._stateCallback(pm.PhysicalConnector.STATE.DISCONNECTED);
	},

	executeRobotCommand: function(id, command, args)
	{
		if(!this._connected)
			return;

		var realCommand = true;
		var timeout = 0;

		switch (command)
		{
			case pm.data.PressRobot.NativeMethod.LoadBox:
				pm.audioUtils.playSound("res/Sounds/press_load_box.wav");
				timeout = 14000;
				realCommand = false;
				break;
			case pm.data.PressRobot.NativeMethod.UnloadBox:
				pm.audioUtils.playSound("res/Sounds/press_unload_box.wav");
				timeout = 14000;
				realCommand = false;
				break;
		}

		this._busy = true;

		if (realCommand)
		{
			this._currentCommand = command;

			cc.log("Sending command to BLE Physical robot:" + command);

			pm.btleManager.sendData(command);
		}
		else
		{
			setTimeout(function()
			{
				this._currentCommand = "";
				this._busy = false;
			}.bind(this), timeout);
		}
	},

	_dataReceiveCallback: function(data)
	{
		cc.log("Received response from BLE Physical robot:" + data);

		if(this._currentCommand && ( data.indexOf("com_" + this._currentCommand + "_done") !== -1 || data.indexOf("ok_" + this._currentCommand) !== -1))
		{
			this._currentCommand = "";
			this._busy = false;
		}
	}
});
