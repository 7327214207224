/**
 * Created by Nikita Besshaposhnikov on 10.03.16.
 */

/**
 * @class MethodListButtonK used by {@link MethodStackLayer}.
 * @extends FunctionButtonK
 * @constructor
 * @param {Number} value
 */
var MethodListButtonK = FunctionButtonK.extend(/** @lends MethodListButtonK#*/{

	_label: null,
	_getMethodListCallback: null,
	_getMethodListCallbackTarget: null,

	ctor: function(value)
	{
		this._label = new cc.LabelTTF(String(value), pm.settings.fontName, pm.settings.fontSize);

		this._super(FunctionButton.Type.MethodList, value);

		this.loadTextureNormal(this._getSpriteName(pm.NORMAL_STATE), ccui.Widget.PLIST_TEXTURE);
		this.loadTexturePressed(this._getSpriteName(pm.SELECTED_STATE), ccui.Widget.PLIST_TEXTURE);
		this.loadTextureDisabled(this._getSpriteName(pm.DISABLED_STATE), ccui.Widget.PLIST_TEXTURE);

		this._label.setPosition(this.width / 2, this.height / 2);
		this._label.setFontFillColor(pm.settings.levelFontColor);

		this.addChild(this._label);
	},

	select: function()
	{
		if(this.selected)
		{
			this.stopAnimation();
			this.selected = false;
		}
		else
		{
			pm.sendCustomEvent(pm.DESELECT_FUNCTIONBUTTON);
			FunctionButton.selectedButton = this.clone();
			this.selected = true;
			this.startAnimation();
		}
	},

	clone: function()
	{
		var but =  new MethodListButtonK(this.value);
		but.getMethodList = this.getMethodList;
		return but;
	},

	cloneImage: function()
	{
		return this.clone();
	},

	/**
	 * Sets method list
	 * @param callback
	 * @param target
	 */
	setMethodListCallback: function(callback, target)
	{
		this._getMethodListCallback = callback;
		this._getMethodListCallbackTarget = target;
	},

	_valueChanged: function()
	{
		this._label.setString(String(this._value));
	},

	_getSpriteName: function(state)
	{
		return pm.spriteUtils.getIconName("mslDrag", state);
	}
});
