/**
 * Created by ADB
 */

/**
 * @class This is wrapper for one function in format for {@link pm.RobotManager}.</br>
 * @extends cc.Class
 */
pm.RobotProgramK = pm.RobotProgram.extend({

	/*
	memory to store begining of block in stack
	 */
	register: [],
	/*
	associative array of global variables
	 */
	memory: {},
	/*
	register to store function arguments
	 */
	argRegister: [],

	ctor: function()
	{
		this._super();
		this.register = [];
		this.argRegister = [];
		this.memory = {};

	},

	setForceInstruction: function (methodId)
	{
		this.ended = false;
		this.instrPosition = this.labels[methodId];
		this.stack.push(this.instrPosition + 1);
		this.register.push(this.stack.length);
	}
});

pm.programUtilsK = {

	_level: null,

	compileRobotProgram: function (robot, level)
	{
		var program = new pm.RobotProgramK();
		var programTree = robot.getProgramData();
		pm.programUtils.totalLabelCount = 0;
		this._level = level;

		this.parseProgram(program, programTree.programTree, robot);

		program.ended = false;

		if (program.labels[pm.CMD_GLOBALS] !== undefined)
		{
			program.instrPosition = program.labels[pm.CMD_GLOBALS];
		}
		else if (program.labels[pm.CMD_MAIN] !== undefined)
		{
			program.instrPosition = program.labels[pm.CMD_MAIN];
		}

		pm.programUtils.totalLabelCount = 0;
		this._level = null;

		return program;
	},

	parseProgram: function (program, programTree, robot)
	{
		this.parseTree(programTree.head, robot, program);
	},

	parseTree: function (node, robot, program)
	{
		var instrList = program.instructions;
		switch (node.type)
		{
			case pm.data.Node.Type.Prog:
				for (var i = 0; i < node.children.length; i++)
				{
					this.parseTree(node.children[i], robot, program);
				}
				break;

			case pm.data.Node.Type.MainAlg:
				var instLength = 0;
				program.labels[pm.CMD_MAIN] = program.instructions.length;

				var instr = new pm.Instruction(pm.Instruction.EXECUTE);
				instr.data.paramQuantity = 0;
				instr.data.isNative = false;
				instr.data.methodID = pm.CMD_MAIN;
				instr.data.nodeView = node.getNodeView();
				program.instructions.push(instr);
				instLength++;

				for (var i = 0; i < node.children.length; i++)
				{
					if (node.children[i].type === pm.data.Node.Type.Body)
					{
						instLength += this.parseTree(node.children[i], robot, program);
					}
				}

				var retInstr = new pm.Instruction(pm.Instruction.RETURN);
				retInstr.data.paramQuantity = 0;
				instrList.push(retInstr);
				instLength++;

				return instLength;

			case pm.data.Node.Type.Globals:
				var blockLength = 0;
				program.labels[pm.CMD_GLOBALS] = program.instructions.length;
				for (var i = 0; i < node.children.length; i++)
				{
					this.parseTree(node.children[i], robot, program);
				}
				var jmpInstr = new pm.Instruction(pm.Instruction.JUMP);

				jmpInstr.data.jumpLabel = pm.CMD_MAIN;
				program.instructions.push(jmpInstr);
				++blockLength;

				return blockLength;

			case pm.data.Node.Type.Func:
				var instLength = 0;
				var body = null;
				var argNum = 0;
				program.labels[node.value] = instrList.length;

				for (var i = node.children.length - 1; i >= 0; i--)
				{
					if (node.children[i].type === pm.data.Node.Type.Body)
						body = node.children[i];
					else if(node.children[i].type === pm.data.Node.Type.Alloc)
						instLength += this.compileArg(node.children[i],robot, program);
				}
				instLength += this.parseTree(body, robot, program);
				var retInstr = new pm.Instruction(pm.Instruction.RETURN);
				retInstr.data.paramQuantity = argNum;
				instrList.push(retInstr);
				instLength++;

				return instLength;

			case pm.data.Node.Type.Body:
				var instLength = 0;
				for (var i = 0; i < node.children.length; i++)
				{
					instLength += this.parseTree(node.children[i], robot, program);
				}

				return instLength;

			case pm.data.Node.Type.Alloc:
				var instLength = 0;
				var varName;
				var varType;
				for (var i = 0; i < node.children.length; i++)
				{
					if (node.children[i].type === pm.data.Node.Type.Identifier)
						varName = node.children[i].value;
					else if (node.children[i].type === pm.data.Node.Type.Type)
						varType = node.children[i].value;
				}
				this.allocateInstr(varType, varName, 0, program, node.getNodeView());
				instLength++;

				return instLength;

			case pm.data.Node.Type.Statement:
				var instLength = 0;
				var varName = null;
				for (var i = 0; i < node.children.length; i++)
				{
					if (node.children[i].getTagName() === pm.StatementVarTag)
						varName = node.children[i];
					else if(node.children[i].getTagName() === pm.StatementValueTag)
					{
						instLength += this.parseTree(node.children[i], robot, program);
					}
				}
				this.storeInstr(varName.value, false, program, node.getNodeView());
				return instLength;

			case pm.data.Node.Type.Identifier:
				instr = new pm.Instruction(pm.Instruction.FETCH);
				instr.data.varName = node.value;
				instrList.push(instr);
				return 1;

			case pm.data.Node.Type.Bool:
			case pm.data.Node.Type.Number:
				instr = new pm.Instruction(pm.Instruction.PUSH);
				instr.data.value = node.value;
				instrList.push(instr);
				return 1;

			case pm.data.Node.Type.AndOrExpression:
			case pm.data.Node.Type.LogicExpression:
			case pm.data.Node.Type.Expression:
				var instLength = 0;
				var instr = new pm.Instruction(pm.Instruction.CALC);
				instr.data.operation = node.value;
				instr.data.nodeView = node.getNodeView();
				var leftOp;
				var rightOp;
				for (var i = 0; i < node.children.length; i++)
				{
					if (node.children[i].getTagName() === pm.ExpressionLeftTag)
						leftOp = node.children[i];
					if (node.children[i].getTagName() === pm.ExpressionRightTag)
						rightOp = node.children[i];
				}
				if (rightOp !== null)
					blockLength += this.parseTree(rightOp, robot, program);
				blockLength += this.parseTree(leftOp, robot, program);

				instrList.push(instr);
				instLength++;

				return instLength;

			case pm.data.Node.Type.NotExpression:
				var instLength = 0;
				var instr = new pm.Instruction(pm.Instruction.CALC);
				instr.data.operation = node.value;
				instr.data.nodeView = node.getNodeView();
				var rightOp;
				for (var i = 0; i < node.children.length; i++)
				{
					if (node.children[i].getTagName() === pm.ExpressionRightTag)
						rightOp = node.children[i];
				}

				blockLength += this.parseTree(rightOp, robot, program);

				instrList.push(instr);
				instLength++;

				return instLength;

			case pm.data.Node.Type.IfStatement:
				return this.compileIFStatement(node, robot, program);

			case pm.data.Node.Type.Condition:
				return this.compileSimpleCondition(node, robot, program);

			case pm.data.Node.Type.Loop:
				var instLength = 0;
				switch (node.value)
				{
					case pm.LoopVals.nTimes:
					case pm.LoopVals.for:
						instLength += this.compileSimpleLoop(node, robot, program);
						break;

					case pm.LoopVals.whileC:
						instLength += this._compileWhileBlock(node, robot, program);
						break;

					case pm.LoopVals.until:
						instLength += this._compileUntilBlock(node, robot, program);
						break;
				}
				return instLength;

			case pm.data.Node.Type.HorizontalBar:
				for (var i = 0; i < node.children.length; i++)
				{
					var tag = pmui.HorizontalBarNode.CHILD_TAG.format(i);
					for (var j = 0; j < node.children.length; j++)
					{
						if (node.children[j].getTagName() === tag)
							this.parseTree(node.children[j], robot, program);
					}
				}
				break;
			// compileFunctionCall: function (node, robot, program)
			// {
			// 	var instLength = 0;
			// 	var res = null;
			// 	var argres = null;
			// 	var paramsNum = 0;
			// 	for (var i = 0; i < node.children.length; i++)
			// 	{
			// 		if (node.children[i].value === pm.data.Node.Type.Arg)
			// 		{
			// 			instLength += this.parseTree(node.children[i], robot, program);
			// 			paramsNum += node.children[i].children.length;
			// 		}
			// 		else if (node.children[i].value === pm.data.Node.Type.Res)
			// 		{
			// 			res = node.children[i];
			// 		}
			// 		else if (node.children[i].value === pm.data.Node.Type.ArgRes)
			// 		{
			// 			instLength += this.parseTree(node.children[i], robot, program);
			// 			paramsNum += node.children[i].children.length;
			// 			argres = node.children[i];
			// 		}
			// 	}
			//
			// 	var instr = new pm.Instruction(pm.Instruction.EXECUTE);
			// 	instr.data.paramQuantity = paramsNum;
			// 	instr.data.isNative = false;
			// 	instr.data.methodID = node.value;
			// 	instr.data.nodeView = node.getNodeView();
			//
			// 	program.instructions.push(instr);
			// 	instLength++;
			// 	if (res)
			// 		instLength += this.parseTree(res, robot, program);
			//
			// 	if (argres)
			// 	{
			// 		argres.value = "CallRes";
			// 		instLength += this.parseTree(argres, robot, program);
			// 	}
			//
			// 	return instLength;
			// },
			case pm.data.Node.Type.Action:
				var instLength = 0;
				var instr = new pm.Instruction(pm.Instruction.EXECUTE);
				var paramCount = 0;
				var nativeFunc = pm.programUtils.searchNativeRobotFunction(robot, node.value);

				instr.data.isNative = false;

				if (nativeFunc)
				{
					instr.data.robot = nativeFunc.robot;
					instr.data.isNative = true;
				}
				else
				{
					nativeFunc = pm.programUtils.searchGlobalNativeFunction(this._level, node.value);
					if (nativeFunc)
					{
						instr.data.robot = nativeFunc.robot;
						instr.data.isNative = true;
					}
					else
					{
						instr.data.isNative = false;
						instr.data.methodID = node.value;
						instr.data.nodeView = node.getNodeView();
					}
				}
				var params = [];
				for (var i = node.children.length - 1; i >= 0; i--)
				{
					var tag = pm.CommanParamTag + i;
					for (var j = 0; j < node.children.length; j++)
					{
						if (node.children[j].getTagName() === tag)
							paramCount += this.parseTree(node.children[j], robot, program);
					}
				}

				instr.data.methodID = node.value;
				instr.data.paramCount = paramCount;
				instr.data.nodeView = node.getNodeView();
				program.instructions.push(instr);
				instLength++;
				instLength += paramCount;
				return instLength;
		}
	},

	allocateInstr: function (varType, varName, value, program, nodeView)
	{
		var instr = new pm.Instruction(pm.Instruction.ALLOC);
		instr.data.varType = varType;
		instr.data.varName = varName;
		instr.data.value = value;
		instr.data.nodeView = nodeView;
		program.instructions.push(instr);
	},

	storeInstr: function (varName, fromReg, program, nodeView)
	{
		var instr = new pm.Instruction(pm.Instruction.STORE);
		instr.data.varName = varName;
		instr.data.fromReg = fromReg;
		instr.data.nodeView = nodeView;
		program.instructions.push(instr);
	},

	compileArg: function(node, robot, program)
	{
		var instLength = 0;
		var varName;
		var varType;
		for (var i = 0; i < node.children.length; i++)
		{
			if (node.children[i].type === pm.data.Node.Type.Identifier)
				varName = node.children[i].value;
			else if (node.children[i].type === pm.data.Node.Type.Type)
				varType = node.children[i].value;
		}
		this.allocateInstr(varType, varName, 0, program, node.getNodeView());
		instLength++;
		this.storeInstr(varName, true, program, node.getNodeView());
		instLength++;
		return instLength;

	},

	_compileUntilBlock: function (node, robot, program)
	{
		var blockLength = 0;
		var condition = node.children[0];
		var body = node.children[1];
		var blockStartInstr = program.instructions.length;

		blockLength += this.parseTree(body, robot, program);

		blockLength += this.parseTree(condition, robot, program);
		var NIFInstr = program.instructions[program.instructions.length - 1];

		if (condition.value !== pm.CONDITION_EMPTY)
			program.labels[NIFInstr.data.jumpLabel] = blockStartInstr;

		return blockLength;
	},

	_compileWhileBlock: function (node, robot, program)
	{
		var blockLength = 0;
		if (node.children[0].getTagName() === pm.WhileCondTag)
		{
			var condition = node.children[0];
			var body = node.children[1];
		}
		else
		{
			var condition = node.children[1];
			var body = node.children[0];
		}
		var blockStartLabel = pm.programUtils.getLabel();
		var condEndLabel = pm.programUtils.getLabel();

		program.labels[blockStartLabel] = program.instructions.length;

		blockLength += this.parseTree(condition, robot, program);

		var instr = new pm.Instruction(pm.Instruction.JUMP_NIF);
		instr.data.jumpLabel = condEndLabel;
		program.instructions.push(instr);
		++blockLength;

		var popInstr = new pm.Instruction(pm.Instruction.POP);
		program.instructions.push(popInstr);
		++blockLength;

		blockLength += this.parseTree(body, robot, program);

		var jmpInstr = new pm.Instruction(pm.Instruction.JUMP);
		jmpInstr.data.jumpLabel = blockStartLabel;
		program.instructions.push(jmpInstr);
		++blockLength;

		program.labels[instr.data.jumpLabel] = program.instructions.length;

		popInstr = new pm.Instruction(pm.Instruction.POP);
		program.instructions.push(popInstr);
		++blockLength;

		return blockLength;
	},

	compileSimpleLoop: function (node, robot, program)
	{
		var blockLength = 0;
		var repInstr;

		var loopBodyStartLabel = pm.programUtils.getLabel();
		var blockEndLabel = pm.programUtils.getLabel();
		var repIndex = null;
		var repBeg = null;
		var repVal = null;
		var repStep = null;
		var repBody = null;

		if (node.value === pm.LoopVals.nTimes)
		{
			for (var i = 0; i < node.children.length; i++)
			{
				if (node.children[i].getTagName() === pm.NTimesNumTag)
				{
					repVal = node.children[i];
				}
				else
				{
					repBody = node.children[i];
				}
			}
			var instr = new pm.Instruction(pm.Instruction.PUSH);
			instr.data.value = 1;
			program.instructions.push(instr);
			this.parseTree(repVal, robot, program);
		}
		else if (node.value === pm.LoopVals.for)
		{
			for (var i = 0; i < node.children.length; i++)
			{
				if (node.children[i].getTagName() === pm.ForIteratorTag)
				{
					repIndex = node.children[i];
				}
				else if (node.children[i].getTagName() === pm.ForBegTag)
				{
					repBeg = node.children[i];
				}
				else if (node.children[i].getTagName() === pm.ForEndTag)
				{
					repVal = node.children[i];
				}
				else if (node.children[i].getTagName() === pmui.ForRepeaterNode.STEP_TAG)
				{
					repStep = node.children[i];
				}
				else
				{
					repBody = node.children[i];
				}
			}
			if (repStep !== null)
			{
				this.parseTree(repStep, robot, program);
			}
			this.parseTree(repBeg, robot, program);
			this.parseTree(repVal, robot, program);
		}

		repInstr = new pm.Instruction(pm.Instruction.START_LOOP);

		if (repStep !== null)
		{
			repInstr.data.stepTag = pm.variableList.addVariable(false);
		}
		repInstr.data.repType = node.value;
		repInstr.data.blockEndLabel = blockEndLabel;
		repInstr.data.repeaterTag = pm.variableList.addVariable(false);
		repInstr.data.iteratorTag = pm.variableList.addVariable(false);
		repInstr.data.repPlace = cc.p(node._lineUp, node._lineDown);
		repInstr.data.nodeView = node.getNodeView();
		repInstr.data.robot = robot;
		program.instructions.push(repInstr);
		program.labels[loopBodyStartLabel] = program.instructions.length;

		if (repIndex)
		{
			this.storeInstr(repIndex.value, false, program);
		}
		else
		{
			var popInstr = new pm.Instruction(pm.Instruction.POP);
			program.instructions.push(popInstr);
		}
		blockLength += this.parseTree(repBody, robot, program);

		var endInstr = new pm.Instruction(pm.Instruction.END_LOOP);
		if (repInstr.data.robot)
			endInstr.data.robot = repInstr.data.robot;

		if (repInstr.data.repArgs !== undefined)
			endInstr.data.repArgs = repInstr.data.repArgs;

		endInstr.data.jumpLabel = loopBodyStartLabel;
		endInstr.data.iteratorTag = repInstr.data.iteratorTag;
		endInstr.data.repeaterTag = repInstr.data.repeaterTag;
		endInstr.data.repPlace = repInstr.data.repPlace;
		endInstr.data.nodeView = node.getNodeView();

		if (repStep !== null)
		{
			endInstr.data.stepTag = repInstr.data.stepTag;
		}
		program.instructions.push(endInstr);
		++blockLength;

		program.labels[blockEndLabel] = program.instructions.length;

		return blockLength;
	},

	compileIFStatement: function (node, robot, program)
	{
		var instLength = 0;
		var condition = null;
		var elseBlock = null;
		var thenBlock = null;
		for (var i = 0; i < node.children.length; i++)
		{
			if (node.children[i].value === pm.IFThenTag)
				thenBlock = node.children[i];
			else if (node.children[i].value === pm.IFElseTag)
				elseBlock = node.children[i];
			else if (node.children[i].getTagName() === pm.IFCondTag)
				condition = node.children[i];
		}

		var condEndLabel = pm.programUtils.getLabel();
		instLength += this.parseTree(condition, robot, program);

		var instr = new pm.Instruction(pm.Instruction.JUMP_NIF);
		instr.data.jumpLabel = condEndLabel;
		program.instructions.push(instr);
		++instLength;

		instLength += this.parseTree(thenBlock, robot, program);
		program.labels[instr.data.jumpLabel] = program.instructions.length;

		if (node.children.length === 3)
		{
			var instr = new pm.Instruction(pm.Instruction.JUMP_IF);
			var elseLabel = pm.programUtils.getLabel();

			instr.data.jumpLabel = elseLabel;

			program.instructions.push(instr);
			++instLength;

			instLength += this.parseTree(elseBlock, robot, program);

			program.labels[elseLabel] = program.instructions.length;
		}
		var popInstr = new pm.Instruction(pm.Instruction.POP);
		program.instructions.push(popInstr);

		return instLength;
	},

	compileSimpleCondition: function (node, robot, program)
	{
		var blockLength = 0;
		var condInstr = new pm.Instruction(pm.Instruction.CHECK_CONDITION);

		condInstr.data.condition = node.value;
		condInstr.data.nodeView = node.getNodeView();
		condInstr.data.isNative = true;
		var condRobot = pm.programUtils.searchRobotForCondition(robot, condInstr.data.condition);

		if (!condRobot)
		{
			condRobot = pm.programUtils.searchGlobalRobotForCondition(this._level, condInstr.data.condition);
			condInstr.data.condArgs = robot.id;
		}
		condInstr.data.robot = condRobot;
		program.instructions.push(condInstr);
		blockLength++;
		return blockLength;
	},

	compileFunctionCall: function (node, robot, program)
	{
		var instLength = 0;
		var res = null;
		var argres = null;
		var paramsNum = 0;
		for (var i = 0; i < node.children.length; i++)
		{
			if (node.children[i].value === pm.data.Node.Type.Arg)
			{
				instLength += this.parseTree(node.children[i], robot, program);
				paramsNum += node.children[i].children.length;
			}
			else if (node.children[i].value === pm.data.Node.Type.Res)
			{
				res = node.children[i];
			}
			else if (node.children[i].value === pm.data.Node.Type.ArgRes)
			{
				instLength += this.parseTree(node.children[i], robot, program);
				paramsNum += node.children[i].children.length;
				argres = node.children[i];
			}
		}

		var instr = new pm.Instruction(pm.Instruction.EXECUTE);
		instr.data.paramQuantity = paramsNum;
		instr.data.isNative = false;
		instr.data.methodID = node.value;
		instr.data.nodeView = node.getNodeView();

		program.instructions.push(instr);
		instLength++;
		if (res)
			instLength += this.parseTree(res, robot, program);

		if (argres)
		{
			argres.value = "CallRes";
			instLength += this.parseTree(argres, robot, program);
		}

		return instLength;
	}

};

