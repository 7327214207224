/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class pm.Task for painting elements.</br>
 * Contains number of elements to paint.
 * @implements pm.Task
 * @constructor
 * @param {pm.AbstractLevel} level
 */
pm.data.DraftsmanTask = pm.Task.extend(/** @lends pm.data.DraftsmanTask*/ {
	typeName: "DraftsmanTask",

	ctor: function(level)
	{
		this._super(level);
	},

	getNotCompletedObjects: function (mapIndex)
	{
		var drawnLines = this._level.maps[mapIndex].lines;
		var taskLines = this.data[mapIndex];

		var retArray = [];
		var badLines = [];

		if (taskLines.length === 0 && drawnLines.length === 0)
		{
			return retArray;
		}
		else if (drawnLines.length > 0 && taskLines.length === 0)
		{
			badLines = drawnLines;
		}
		else if (drawnLines.length === 0 && taskLines.length > 0)
		{
			badLines = taskLines;
		}
		else
		{
			var digitsNum = 5;

			for (var i = 0; i < taskLines.length; ++i)
			{
				var from = taskLines[i].oldPos;
				var to = taskLines[i].newPos;

				var result = drawnLines.filter(function(el)
				{
					return el.oldPos.x.toFixed(digitsNum) === from.x.toFixed(digitsNum)
						&& el.oldPos.y.toFixed(digitsNum) === from.y.toFixed(digitsNum)
						&& el.newPos.x.toFixed(digitsNum) === to.x.toFixed(digitsNum)
						&& el.newPos.y.toFixed(digitsNum) === to.y.toFixed(digitsNum);
				}.bind(this));

				if (result.length === 0)
					badLines.push(taskLines[i]);
			}

			for (var i = 0; i < drawnLines.length; ++i)
			{
				var from = drawnLines[i].oldPos;
				var to = drawnLines[i].newPos;

				var result = taskLines.filter(function(el)
				{
					return el.oldPos.x.toFixed(digitsNum) === from.x.toFixed(digitsNum)
						&& el.oldPos.y.toFixed(digitsNum) === from.y.toFixed(digitsNum)
						&& el.newPos.x.toFixed(digitsNum) === to.x.toFixed(digitsNum)
						&& el.newPos.y.toFixed(digitsNum) === to.y.toFixed(digitsNum);
				}.bind(this));

				if (result.length === 0)
					badLines.push(drawnLines[i]);
			}
		}

		this._level.maps[mapIndex].badLines = badLines;

		if (badLines.length > 0)
			retArray.push(this._level.maps[mapIndex]);

		return retArray;
	},

	fillWithInitialData: function(mapIndex, data)
	{
		if (data === undefined)
			data = [];

		this.data[mapIndex] = data;
	}
});
