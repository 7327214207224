/**
 * Created by antonded on 13.11.14.
 */

/**
 * @class Menu for changing level.</br>
 * Can change to previous of next level. If new level in another game, changes game.
 * @extends ccui.HBox
 * @constructor
 * @param {Number} width Width of layer.
 */
var ChangeLevelMenu = ccui.HBox.extend(/**@lends ChangeLevelMenu#*/{
	_nextLevelButton: null,
	_prevLevelButton: null,
	_menu: null,
	_state: false,
	_processingAnimation: false,

	ctor: function ()
	{
		this._super();

		this._nextLevelButton = new pmui.Button(pm.spriteUtils.getIconName("nextLevel", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("nextLevel", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("nextLevel", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this._prevLevelButton = new pmui.Button(pm.spriteUtils.getIconName("prevLevel", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("prevLevel", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("prevLevel", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this._prevLevelButton.addClickEventListener(this._prevLevel.bind(this));

		var rightAlign = new ccui.LinearLayoutParameter();
		rightAlign.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
		rightAlign.setMargin(ChangeLevelMenu.BUTTON_SEPARATOR, 0, 0, 0);
		this._nextLevelButton.setLayoutParameter(rightAlign);
		this._nextLevelButton.addClickEventListener(this._nextLevel.bind(this));

		var height = Math.max(this._nextLevelButton.height, this._prevLevelButton.height);
		var width = this._nextLevelButton.width + this._prevLevelButton.width + ChangeLevelMenu.BUTTON_SEPARATOR;
		this.setContentSize(width, height);

		this.addChild(this._prevLevelButton);
        this._addLevelCountLabel();
        this.addChild(this._nextLevelButton);

		this._setButtonsStates();

		pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.NEXT_LEVEL, this._nextLevelButton);
		pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.PREV_LEVEL, this._prevLevelButton);
	},

    /**
     * Adds label with number of current level.
     */
    _addLevelCountLabel: function()
	{
        var level = pm.settings.getLevel() + 1;
        var levelCount = world.getLevelCount(pm.settings.getGame());

        var countLabel = new ccui.Text("{0}/{1}".format(level, levelCount), pm.settings.fontName, 20);

        var countLabelAlign = new ccui.LinearLayoutParameter();
        countLabelAlign.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);
        countLabelAlign.setMargin(ChangeLevelMenu.BUTTON_SEPARATOR, 0, 0, 0);

        countLabel.setLayoutParameter(countLabelAlign);

        this.addChild(countLabel);

        this.setContentSize(this.width + countLabel.width, Math.max(this.height, countLabel.height));
    },

	_setButtonsStates: function()
	{
		var game = pm.settings.getGame();
		var level = pm.settings.getLevel();

		if(level === 0 && game === 0 || pm.settings.blockExit)
			this._prevLevelButton.setEnabled(false);

		if(level === world.getLevelCount(game) - 1 && game === world.getGameCount() - 1 || pm.settings.blockExit)
			this._nextLevelButton.setEnabled(false);
	},

	/**
     * Sets enabled state of layer.
     * @param {Boolean} flag
     */
	setEnabled: function(flag)
	{
		ccui.Layout.prototype.setEnabled.call(this, flag);

		for(var i = 0; i < this.getChildrenCount(); ++i)
			this.getChildren()[i].setEnabled(flag);

		if(flag)
			this._setButtonsStates();
	},

	_nextLevel: function(sender)
	{
		var level = pm.settings.getLevel();
		var game = pm.settings.getGame();

		++level;

		if (level === world.getLevelCount(game))
		{
			++game;

			while(world.getLevelCount(game) === 0 && game < world.getGameCount())
				++game;

			if(game === world.getGameCount())
			{
				game = pm.settings.getGame();
				level = pm.settings.getLevel();
			}
			else
			{
				level = 0;
			}
		}

		pm.settings.setGame(game);
		pm.settings.setLevel(level);

		this._transLevel();
	},

	_prevLevel: function(sender)
	{
		var level = pm.settings.getLevel();
		var game = pm.settings.getGame();

		--level;

		if (level < 0)
		{
			--game;

			while(world.getLevelCount(game) === 0 && game >= 0)
				--game;

			if(game < 0)
			{
				game = pm.settings.getGame();
				level = pm.settings.getLevel();
			}
			else
			{
				level = world.getLevelCount(game) - 1;
			}
		}

		pm.settings.setGame(game);
		pm.settings.setLevel(level);

		this._transLevel();
	},

	_transLevel: function()
	{
		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY,
			new LevelScene(GameType.Local, null, pm.settings.getGame(), pm.settings.getLevel()));
		cc.director.runScene(trans);
	},

	/**
     * Animates next level button.
     */
	animateNextLevelButton: function ()
	{
		if (this._processingAnimation)
			return;

		this._processingAnimation = true;

		var showSelected = cc.callFunc(function()
		{
			this.setHighlighted(true);
		}, this._nextLevelButton);

		var showNormal = cc.callFunc(function()
		{
			this.setHighlighted(false);
		}, this._nextLevelButton);

		var delay = cc.delayTime(pm.SYSTEM_ANIMATION_DELAY);

		this._nextLevelButton.runAction(cc.repeatForever(cc.sequence(showSelected, delay, showNormal, delay)));
	},

	/**
     * Stop animation of next level button.
     */
	stopAnimateNextLevelButton: function()
	{
		this._processingAnimation = false;

		this._nextLevelButton.stopAllActions();

		this._nextLevelButton.setHighlighted(false);
	}
});

ChangeLevelMenu.BUTTON_SEPARATOR = 10;
