/**
 * Created by Nikita Besshaposhnikov on 29.03.16.
 */

/**
 * @class Layer for task settings.
 * @extends LevelSettingsLayer
 * @constructor
 * @param {pm.AbstractLevel} level
 */
var TaskSettingsLayer = LevelSettingsLayer.extend(/** @lends TaskSettingsLayer# */{

	_taskList: null,
	_availableTaskList: null,
	_addTaskSelect: null,

	ctor: function(level)
	{
		this._super(level);

		var label = new ccui.Text(LocalizedString("Tasks"), pm.settings.fontName, pm.settings.fontSize);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		labelAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		labelAlign.setRelativeName("label");
		label.setLayoutParameter(labelAlign);

		this.addChild(label);

		var addLayout = new ccui.HBox();

		var addLayoutAlign = new ccui.RelativeLayoutParameter();
		addLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		addLayoutAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		addLayoutAlign.setRelativeName("select");
		addLayoutAlign.setRelativeToWidgetName("label");
		addLayout.setLayoutParameter(addLayoutAlign);

		this._addTaskSelect = new pmui.ComboBox([], this, this._selectTaskOnSelect, 20);

		var addButton = new pmui.Button(pm.spriteUtils.getIconName("add", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("add", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("add", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		// addButton.setScale(0.7);
		addButton.addClickEventListener(this._addTask.bind(this));

		var addButtonAlign = new ccui.LinearLayoutParameter();
		addButtonAlign.setMargin(5, 5, 0, 0);
		addButton.setLayoutParameter(addButtonAlign);

		addLayout.addChild(this._addTaskSelect);
		addLayout.addChild(addButton);
		addLayout.setContentSize(addButton.width + this._addTaskSelect.width,
			Math.max(addButton.height, this._addTaskSelect.height));

		this.addChild(addLayout, 1);

		var height = (TaskSettingsLayer.HEIGHT - label.height - addLayout.height) - 10;

		this._taskList = new ccui.ListView();
		this._taskList.setContentSize(LevelSettingsLayer.WIDTH, height);
		this._taskList.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._taskList.setScrollBarOpacity(255 * 0.9);
		this._taskList.setScrollBarWidth(5);
		this._taskList.setItemsMargin(5);

		var listAlign = new ccui.RelativeLayoutParameter();
		listAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		listAlign.setRelativeToWidgetName("select");
		this._taskList.setLayoutParameter(listAlign);

		this.addChild(this._taskList);

		this._fillTaskList();
		this._generateAvailableTaskList();

		this.updateSize(LevelSettingsLayer.WIDTH, TaskSettingsLayer.HEIGHT);

		pm.registerCustomEventListener(pme.ADD_GLOBAL_ROBOT, this._onAddGlobalRobot.bind(this), this);
		pm.registerCustomEventListener(pme.REMOVE_GLOBAL_ROBOT, this._onRemoveGlobalRobot.bind(this), this);

		pm.registerCustomEventListener(pm.MAP_DRAGGED_EVENT_STR, function (event) {
			var data = event.getUserData();
			if(data)
				this._refreshTasks(data.index);
		}.bind(this), this);

		pm.registerCustomEventListener(pm.MAP_CHANGED_EVENT_STR,function (event) {
			var data = event.getUserData();
			if(data)
				this._refreshTasks(data.index);
		}.bind(this), this);
	},

	_fillTaskList: function()
	{
		for(var taskType in this._level.taskList.tasks)
		{
			var task = this._level.taskList.tasks[taskType];

			if(!(task instanceof pm.Task))
				continue;

			var layer = this._generateLayerForTask(taskType, task);

			if(layer)
			{
				this._taskList.pushBackCustomItem(layer);
				layer.addDeleteCallback(this._removeTask, this);
			}
		}
	},

	_generateLayerForTask: function(taskType, task)
	{
		switch (taskType)
		{
			case pm.GlobalTaskType.Position4: return new PositionTask4Layer(this._level, task);
			case pm.GlobalTaskType.ExCounterMemory: return new ExCounterMemoryTaskLayer(this._level, task);
			case pm.GlobalTaskType.OutputValue: return new OutputTaskLayer(this._level, task);
			default:
				return pm.moduleUtils.generateTaskLayer(taskType, this._level, task);
		}
	},

	_generateAvailableTaskList: function()
	{
		var labels = [];
		this._availableTaskList = [];

		for(var i = 0; i < this._level.maps.length; ++i)
		{
			if (this._level.maps[i] instanceof SquarableMap)
			{
				this._availableTaskList.push(pm.GlobalTaskType.Position4);
				labels.push(LocalizedString("EndPoint"));
				break;
			}
		}

		var type = pm.moduleUtils.getModuleType(this._level.getType());
		this._availableTaskList.push(type);

		var label = pm.moduleUtils.getTaskLabel(this._level.getType());
		labels.push(LocalizedString(label));

		var extendedCounter = this._level.globalRobotConfig[pm.GlobalRobotType.ExtendedCounter];

		if(extendedCounter)
		{
			this._availableTaskList.push(pm.GlobalTaskType.ExCounterMemory);
			labels.push(LocalizedString("ExCounterMemoryTask"));
		}
		var outputRobot = this._level.globalRobotConfig[pm.GlobalRobotType.Output];

		if(outputRobot)
		{
			this._availableTaskList.push(pm.GlobalTaskType.OutputValue);
			labels.push(LocalizedString("OutputTask"));
		}

		var parent = this._addTaskSelect.getParent();
		var parentWidth = this._addTaskSelect.getParent().width;
		parentWidth -= this._addTaskSelect.width;

		this._addTaskSelect.setValueList(labels);
		parentWidth += this._addTaskSelect.width;

		this._addTaskSelect.getParent().setContentSize(parentWidth, parent.height);

		if(parent.getParent())
			parent.getParent().requestDoLayout();
	},

	_addTask: function()
	{
		var taskType = this._availableTaskList[this._addTaskSelect.getSelectedIndex()];

		var items = this._taskList.getItems();
		var found = false;

		for(var i = 0 ; i < items.length; ++i)
		{
			if(items[i].getTaskType() === taskType)
			{
				found = true;
				break;
			}
		}

		if(!found)
		{
			var task = null;

			switch (taskType)
			{
				case pm.GlobalTaskType.Position4:
					task = new pm.data.PositionTask4(this._level);

					for(var i = 0; i < this._level.maps.length; ++i)
					{
						var mapWidth = this._level.maps[i].width;
						var mapHeight = this._level.maps[i].height;

						task.fillWithInitialData(i, cc.p(mapWidth-1, mapHeight-1));
					}

					this._level.taskList.addTask(taskType, task);

					for(var i = 0; i < this._level.maps.length; ++i)
						this._level.maps[i].mapLayer.checkPositionTasks();

					break;
				case pm.GlobalTaskType.ExCounterMemory:
					task = new pm.data.ExCounterMemoryTask(this._level);

					for(var i = 0; i < this._level.maps.length; ++i)
						task.fillWithInitialData(i, 0);

					this._level.taskList.addTask(taskType, task);
					break;
				case pm.GlobalTaskType.OutputValue:
					task = new pm.data.OutputTask(this._level);

					for(var i = 0; i < this._level.maps.length; ++i)
						task.fillWithInitialData(i, 0);

					this._level.taskList.addTask(taskType, task);
					break;
				default:
					task = pm.moduleUtils.addLevelTask(taskType, this._level);

			}

			var layer = this._generateLayerForTask(taskType, task);

			if(layer)
			{
				this._taskList.pushBackCustomItem(layer);
				layer.addDeleteCallback(this._removeTask, this);
			}
		}
	},

	_onAddGlobalRobot: function(robot)
	{
		if(robot.getUserData().getType() === pm.GlobalRobotType.ExtendedCounter || robot.getUserData().getType() === pm.GlobalRobotType.Output)
			this._generateAvailableTaskList();
	},

	_onRemoveGlobalRobot: function(robot)
	{
		if(robot.getUserData().getType() === pm.GlobalRobotType.ExtendedCounter)
		{
			this._generateAvailableTaskList();

			if(this._level.taskList[pm.GlobalTaskType.ExCounterMemory])
				delete this._level.taskList[pm.GlobalTaskType.ExCounterMemory];

			var items = this._taskList.getItems();

			for(var i = 0 ; i < items.length; ++i)
			{
				if(items[i].getTaskType() === pm.GlobalTaskType.ExCounterMemory)
				{
					this._taskList.removeItem(i);
					break;
				}
			}
		}
	},

	_refreshTasks: function(mapIndex)
	{
		var items = this._taskList.getItems();

		for(var i = 0 ; i < items.length; ++i)
			items[i].refresh(mapIndex);
	},

	_removeTask: function(sender)
	{
		this._level.taskList.deleteTask(sender.getTaskType());
		this._taskList.removeChild(sender);
	},

	_selectTaskOnSelect: function(index) {}
});

TaskSettingsLayer.HEIGHT = 260;
