pm.AnimationTransaction = cc.Class.extend({
    _sequence: [],
    _spawn: [],

    ctor: function ()
    {
        this._sequence = [];
        this._spawn = [];
    },

    addPart: function ()
    {
        if (CORE_BUILD || pm.settings.isAnimationDisabled())
            return;

        if(this._spawn.length > 0)
            this._sequence.push(cc.spawn(this._spawn));

        this._spawn = [];
    },

    addAnimationToPart: function (target, animation, animationCallback, args)
    {
        if (!CORE_BUILD && !pm.settings.isAnimationDisabled())
        {
            var sequenceAnimation = target.getAnimation(animation, animationCallback, args);
            if (sequenceAnimation)
                this._spawn.push(cc.targetedAction(target.sprite.getSprite(), sequenceAnimation));
        }
        else if(CORE_BUILD && animationCallback)
        {
            animationCallback.call(target);
        }
        else if(animationCallback)
        {
            setTimeout(animationCallback.call(target), 0);
        }
    },

    addActionToPart: function (target, action)
    {
        if (!CORE_BUILD && !pm.settings.isAnimationDisabled())
            this._spawn.push(cc.targetedAction(target.sprite.getSprite(), action));
    },

    addCallToPart: function (selector, target, args, forced)
    {
        if (!CORE_BUILD && !pm.settings.isAnimationDisabled())
            this._spawn.push(cc.callFunc(selector, target, args));
        else if(CORE_BUILD && forced)
            selector.call(target, args);
        else if (forced)
            setTimeout(selector.call(target, args), 0);
    },

    playAnimations: function (target)
    {
        if (!CORE_BUILD && !pm.settings.isAnimationDisabled())
            target.runAction(cc.sequence(this._sequence));
    }

});