/**
 * Created by Kirill Mashchenko on 22.07.19.
 */

/**
 * @class pm.Task for painting elements.</br>
 * Contains number of elements to paint.
 * @implements pm.Task
 * @constructor
 * @param {pm.AbstractLevel} level
 */
pm.data.AquariusTask = pm.Task.extend(/** @lends pm.data.AquariusTask*/ {
	typeName: "AquariusTask",

	ctor: function(level)
	{
		this._super(level);
	},

	getNotCompletedObjects: function (mapIndex)
	{
		var map = this._level.maps[mapIndex];
		var retArray = [];

		if (!(map.tankA.currentCapacity === this.data[mapIndex] && map.tankB.currentCapacity === 0 && map.tankC.currentCapacity === 0
			|| map.tankB.currentCapacity === this.data[mapIndex] && map.tankA.currentCapacity === 0 && map.tankC.currentCapacity === 0
			|| map.tankC.currentCapacity === this.data[mapIndex] && map.tankA.currentCapacity === 0 && map.tankB.currentCapacity === 0))
		{
			retArray.push(map.tankA);
			retArray.push(map.tankB);
			retArray.push(map.tankC);
		}

		return retArray;
	},

	fillWithInitialData: function(mapIndex, data)
	{
		if(data === undefined)
			data = 0;

		this.data[mapIndex] = data;
	}

});
