/**
 * Created by Nikita Besshaposhnikov on 28.07.15.
 */

/**
 * @class FUnctionButton used by {@link ProgramContainerLayer} and {@link ProgramLayer}.
 * @extends pmui.Button
 * @property {FunctionButton.Type} type
 * @property {*} value
 * @constructor
 * @param {FunctionButton.Type} type
 * @param {*} value
 */
var FunctionButton = pmui.Button.extend(/** @lends FunctionButton#*/{

	_type: 0,
	_value: null,
	_backSprite: null,
	_drawBackground: false,

	ctor: function(type)
	{
		this._super();

		pm.appUtils.getFunctionButtonPrototype().setType.call(this, type);

		this.setTouchEnabled(false);
		this.setCascadeOpacityEnabled(true);
	},

	getValue: function() {},

	setValue: function(value) {},

	getType: function() {},

	setType: function(type, value) {},

	/**
     * Clones button normal image.
     * @returns {ccui.ImageView}
     */
	cloneImage: function() {},

	/**
	 * Clones button disabled image.
	 * @returns {ccui.ImageView}
	 */
	cloneDisabledImage: function()
	{
		return new ccui.ImageView(this._getSpriteName(pm.DISABLED_STATE), ccui.Widget.PLIST_TEXTURE);
	},

	/**
     * @returns {boolean}
     */
	isMethod: function() { return this._type === FunctionButton.Type.Method; },

	/**
     * @returns {boolean}
     */
	isCondition: function() { return this._type === FunctionButton.Type.Condition; },

	/**
     * @returns {boolean}
     */
	isRepeater: function() { return this._type === FunctionButton.Type.Repeater; },

	/**
     * @returns {boolean}
     */
	isCondRepeater: function() { return this._type === FunctionButton.Type.CondRepeater; },

	/**
     * Selects function button and writes to {@link FunctionButton.selectedButton}
     */
	select: function() {},

	/**
     * Start endless scale animation of button.
     */
	startAnimation: function()
	{
		var scaleTo = new cc.ScaleBy(0.8 * pm.SYSTEM_ANIMATION_DELAY, 0.90);
		var scaleFrom = new cc.ScaleBy(0.8 * pm.SYSTEM_ANIMATION_DELAY, 1/0.90);

		this.runAction( new cc.RepeatForever(new cc.Sequence(scaleTo, scaleFrom)) );
	},

	/**
     * Plays scale animation once.
     */
	animateOnce: function()
	{
		var scaleTo = new cc.ScaleBy(0.8 * pm.SYSTEM_ANIMATION_DELAY, 0.90);
		var scaleFrom = new cc.ScaleBy(0.8 * pm.SYSTEM_ANIMATION_DELAY, 1/0.90);

		this.runAction( new cc.Sequence(scaleTo, scaleFrom) );
	},

	/**
     * Stops endless scale animation.
     */
	stopAnimation: function()
	{
		this.stopAllActions();

		var initial = new cc.ScaleBy(0.4 * pm.SYSTEM_ANIMATION_DELAY, 1 / this.getScale());

		this.runAction(initial);
	},

	_valueChanged: function() {},

	_getSpriteName: function(state) {},

	_isEmpty: function() {},

	_getBackgroundSprite: function() {}

});

FunctionButton.BACKGROUND_SPRITE_TAG = 1;
FunctionButton.COND_REPEATER_SCALE = 0.9;

FunctionButton.selectedButton = null;