/**
 * Created by Antony Orlovsky on 08.08.18.
 */

/**
 * @class Main editor layer.
 * @extends ccui.Layout
 * @param {Number} game Index of current game.
 */

var MenuEditorLayer = ccui.Layout.extend(/** @lends MenuEditorLayer# */{

	_selectWorldLayer: null,
	_gameTable: null,
	_levelTable: null,

	_selectedGameIndex: -1,
	_selectedLevelIndex: -1,

	_selectLevelTypeLayer: null,

	_pastingAvailable: false,

	_changingAvailable: false,
	_changePublicityWorldButton: null,

	ctor: function (game)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setContentSize(pm.settings.getScreenSize());

		this._loadInterface();

		this._redrawWorld(false, game);

		pm.registerCustomEventListener(pm.UPDATE_SCENE, function()
		{
			cc.director.runScene(new MenuEditorScene());
		}, this);
	},

	_loadInterface: function()
	{
		var screenBounds = pm.settings.getScreenBounds();

		var goToStartScreen = new pmui.Button(pm.spriteUtils.getIconName("backLevel", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("backLevel", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("backLevel", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		goToStartScreen.addClickEventListener(this._startScreen.bind(this));

		var goToStartScreenAlign = new ccui.RelativeLayoutParameter();
		goToStartScreenAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		goToStartScreenAlign.setRelativeName("back");
		goToStartScreenAlign.setMargin(screenBounds.left, screenBounds.top, 0, 0);
		goToStartScreen.setLayoutParameter(goToStartScreenAlign);

		this.addChild(goToStartScreen);

		var menuButton = new pmui.Button(pm.spriteUtils.getIconName("menuEditor", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("menuEditor", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("menuEditor", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		menuButton.addClickEventListener(this._showMenu.bind(this));

		var menuButtonAlign = new ccui.RelativeLayoutParameter();
		menuButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		menuButtonAlign.setRelativeName("menu");
		menuButtonAlign.setMargin(0, screenBounds.top, screenBounds.right, 0);
		menuButton.setLayoutParameter(menuButtonAlign);

		this.addChild(menuButton);

		var addGame = new pmui.Button(pm.spriteUtils.getIconName("addGame", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("addGame", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("addGame", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		addGame.addClickEventListener(this._addGame.bind(this));

		var addGameAlign = new ccui.RelativeLayoutParameter();
		addGameAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_RIGHTALIGN);
		addGameAlign.setRelativeName("addGame");
		addGameAlign.setRelativeToWidgetName("addWorld");
		addGameAlign.setMargin(0, MenuEditorLayer.SEPARATOR, 0, 0);
		addGame.setLayoutParameter(addGameAlign);

		this.addChild(addGame);

		var renameGame = new pmui.Button(pm.spriteUtils.getIconName("renameGame", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("renameGame", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("renameGame", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		renameGame.addClickEventListener(this._renameGame.bind(this));

		var renameGameAlign = new ccui.RelativeLayoutParameter();
		renameGameAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		renameGameAlign.setRelativeName("renameGame");
		renameGameAlign.setRelativeToWidgetName("addGame");
		renameGameAlign.setMargin(MenuEditorLayer.SEPARATOR, 0, 0, 0);
		renameGame.setLayoutParameter(renameGameAlign);

		this.addChild(renameGame);

		var addLevel = new pmui.Button(pm.spriteUtils.getIconName("addLevel", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("addLevel", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("addLevel", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		addLevel.addClickEventListener(this._addLevel.bind(this));

		var addLevelAlign = new ccui.RelativeLayoutParameter();
		addLevelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		addLevelAlign.setRelativeName("addLevel");
		addLevelAlign.setRelativeToWidgetName("renameGame");
		addLevelAlign.setMargin(MenuEditorLayer.SEPARATOR, 0, 0, 0);
		addLevel.setLayoutParameter(addLevelAlign);

		this.addChild(addLevel);

		var paste = new pmui.Button(pm.spriteUtils.getIconName("paste", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("paste", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("paste", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		paste.addClickEventListener(this._paste.bind(this));

		var pasteAlign = new ccui.RelativeLayoutParameter();
		pasteAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		pasteAlign.setRelativeToWidgetName("addLevel");
		pasteAlign.setRelativeName("paste");
		pasteAlign.setMargin(MenuEditorLayer.SEPARATOR, 0, 0, 0);
		paste.setLayoutParameter(pasteAlign);

		this.addChild(paste);

		var changing = new pmui.SelectionButton(pm.spriteUtils.getIconName("changing", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("changing", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("changing", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		changing.addClickEventListener(this._changing.bind(this));

		var changingAlign = new ccui.RelativeLayoutParameter();
		changingAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		changingAlign.setRelativeToWidgetName("paste");
		changingAlign.setRelativeName("changing");
		changingAlign.setMargin(MenuEditorLayer.SEPARATOR, 0, 0, 0);
		changing.setLayoutParameter(changingAlign);

		this.addChild(changing);
		if(!cc.sys.isNative) {
			var downloadWorld = new pmui.SelectionButton(pm.spriteUtils.getIconName("downloadWorld", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("downloadWorld", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("downloadWorld", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE
			);

			downloadWorld.addClickEventListener(this._downloadWorld.bind(this));

			var downloadWorldAlign = new ccui.RelativeLayoutParameter();
			downloadWorldAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
			downloadWorldAlign.setRelativeToWidgetName("changing");
			downloadWorldAlign.setRelativeName("download");
			downloadWorldAlign.setMargin(MenuEditorLayer.SEPARATOR, 0, 0, 0);
			downloadWorld.setLayoutParameter(downloadWorldAlign);

			this.addChild(downloadWorld);

			var uploadWorld = new pmui.SelectionButton(pm.spriteUtils.getIconName("uploadWorld", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("uploadWorld", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("uploadWorld", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE
			);

			uploadWorld.addClickEventListener(this._uploadWorld.bind(this));

			var uploadWorldAlign = new ccui.RelativeLayoutParameter();
			uploadWorldAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
			uploadWorldAlign.setRelativeToWidgetName("download");
			uploadWorldAlign.setMargin(MenuEditorLayer.SEPARATOR, 0, 0, 0);
			uploadWorld.setLayoutParameter(uploadWorldAlign);

			this.addChild(uploadWorld);
		}
		var addWorld = new pmui.Button(pm.spriteUtils.getIconName("addWorld", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("addWorld", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("addWorld", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		addWorld.addClickEventListener(this._addWorld.bind(this));

		var addWorldAlign = new ccui.RelativeLayoutParameter();
		addWorldAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		addWorldAlign.setRelativeName("addWorld");
		addWorldAlign.setRelativeToWidgetName("back");
		addWorldAlign.setMargin(1.5*MenuEditorLayer.SEPARATOR, 0, 0, 0);
		addWorld.setLayoutParameter(addWorldAlign);

		this.addChild(addWorld);

		var downloadFromServer = new pmui.SelectionButton(pm.spriteUtils.getIconName("downloadFromServer", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("downloadFromServer", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("downloadFromServer", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		downloadFromServer.addClickEventListener(this._confirmDownloadFromServer.bind(this));

		var downloadALign = new ccui.RelativeLayoutParameter();
		downloadALign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		downloadALign.setRelativeToWidgetName("addWorld");
		downloadALign.setRelativeName("downloadFromServer");
		downloadALign.setMargin(MenuEditorLayer.SEPARATOR, 0, 0, 0);
		downloadFromServer.setLayoutParameter(downloadALign);

		this.addChild(downloadFromServer);

		var uploadToServer = new pmui.SelectionButton(pm.spriteUtils.getIconName("uploadToServer", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("uploadToServer", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("uploadToServer", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		uploadToServer.addClickEventListener(this._confirmUploadToServer.bind(this));

		var uploadAlign = new ccui.RelativeLayoutParameter();
		uploadAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		uploadAlign.setRelativeToWidgetName("downloadFromServer");
		uploadAlign.setRelativeName("uploadToServer");
		uploadAlign.setMargin(MenuEditorLayer.SEPARATOR, 0, 0, 0);
		uploadToServer.setLayoutParameter(uploadAlign);

		this.addChild(uploadToServer);

		var normal, selected, disabled;

		if (world.linkedWithMirera)
		{
			normal = pm.spriteUtils.getIconName("mireraNew", pm.SELECTED_STATE);
			selected = pm.spriteUtils.getIconName("mireraNew", pm.NORMAL_STATE);
			disabled = pm.spriteUtils.getIconName("mireraNew", pm.DISABLED_STATE);
		}
		else
		{
			normal = pm.spriteUtils.getIconName("mireraNew", pm.NORMAL_STATE);
			selected = pm.spriteUtils.getIconName("mireraNew", pm.SELECTED_STATE);
			disabled = pm.spriteUtils.getIconName("mireraNew", pm.DISABLED_STATE);
		}

		this._linkedWithMireraButton = new pmui.Button(normal, selected, disabled, ccui.Widget.PLIST_TEXTURE);

		this._linkedWithMireraButton.addClickEventListener(this._confirmLinkWorldWithMirera.bind(this));

		var mireraAlign = new ccui.RelativeLayoutParameter();
		mireraAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		mireraAlign.setMargin(MenuEditorLayer.SEPARATOR, 0, 0, 0);
		mireraAlign.setRelativeName("linkedWithMirera");
		mireraAlign.setRelativeToWidgetName("uploadToServer");
		this._linkedWithMireraButton.setLayoutParameter(mireraAlign);

		this.addChild(this._linkedWithMireraButton);

		var renameWorld = new pmui.Button(pm.spriteUtils.getIconName("renameWorld", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("renameWorld", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("renameWorld", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);
		renameWorld.addClickEventListener(this._renameWorld.bind(this));

		var renameWorldAlign = new ccui.RelativeLayoutParameter();
		renameWorldAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		renameWorldAlign.setRelativeName("rename");
		renameWorldAlign.setRelativeToWidgetName("linkedWithMirera");
		renameWorldAlign.setMargin(MenuEditorLayer.SEPARATOR, 0, 0, 0);
		renameWorld.setLayoutParameter(renameWorldAlign);

		this.addChild(renameWorld);

		var deleteWorld = new pmui.Button(pm.spriteUtils.getIconName("deleteWorld", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("deleteWorld", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("deleteWorld", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);
		deleteWorld.addClickEventListener(this._confirmDeletingWorld.bind(this));

		var deleteWorldAlign = new ccui.RelativeLayoutParameter();
		deleteWorldAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		deleteWorldAlign.setRelativeName("deleteWorld");
		deleteWorldAlign.setRelativeToWidgetName("rename");
		deleteWorldAlign.setMargin(MenuEditorLayer.SEPARATOR, 0, 0, 0);
		deleteWorld.setLayoutParameter(deleteWorldAlign);

		this.addChild(deleteWorld);

		if (world.public)
		{
			normal = pm.spriteUtils.getIconName("public", pm.NORMAL_STATE);
			selected = pm.spriteUtils.getIconName("public", pm.SELECTED_STATE);
			disabled = pm.spriteUtils.getIconName("public", pm.DISABLED_STATE);
		}
		else
		{
			normal = pm.spriteUtils.getIconName("notPublic", pm.NORMAL_STATE);
			selected = pm.spriteUtils.getIconName("notPublic", pm.SELECTED_STATE);
			disabled = pm.spriteUtils.getIconName("notPublic", pm.DISABLED_STATE);
		}

		this._changePublicityWorldButton = new pmui.Button(normal, selected, disabled, ccui.Widget.PLIST_TEXTURE);

		this._changePublicityWorldButton.addClickEventListener(this._changePublicityWorld.bind(this));

		var isPublicWorldAlign = new ccui.RelativeLayoutParameter();
		isPublicWorldAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		isPublicWorldAlign.setRelativeToWidgetName("deleteWorld");
		isPublicWorldAlign.setMargin(MenuEditorLayer.SEPARATOR, 0, 0, 0);
		this._changePublicityWorldButton.setLayoutParameter(isPublicWorldAlign);

		this.addChild(this._changePublicityWorldButton);

		var updateWorldList = new pmui.Button(pm.spriteUtils.getIconName("updateWorld", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("updateWorld", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("updateWorld", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);
		updateWorldList.addClickEventListener(this._updateWorldList.bind(this));

		var updateWorldListAlign = new ccui.RelativeLayoutParameter();
		updateWorldListAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_LEFT_OF_BOTTOMALIGN);
		updateWorldListAlign.setRelativeToWidgetName("worldSelect");
		updateWorldListAlign.setMargin(0, 0, MenuEditorLayer.SEPARATOR, 0);
		updateWorldList.setLayoutParameter(updateWorldListAlign);

		this.addChild(updateWorldList);

		this._selectWorldLayer = new SelectWorldEditorLayer(this, this._redrawWorld, false, SelectWorldEditorLayer.Gravity.VERTICAL);

		var worldLayerAlign = new ccui.RelativeLayoutParameter();
		worldLayerAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_LEFT_OF_TOPALIGN);
		worldLayerAlign.setRelativeName("worldSelect");
		worldLayerAlign.setRelativeToWidgetName("menu");
		worldLayerAlign.setMargin(0, 5, 1.5*MenuEditorLayer.SEPARATOR, 0);
		this._selectWorldLayer.setLayoutParameter(worldLayerAlign);

		this.addChild(this._selectWorldLayer, 1);

		var showInfoButton = new pmui.Button(pm.spriteUtils.getIconName("infoWorld", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("infoWorld", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("infoWorld", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		var infoAlign = new ccui.RelativeLayoutParameter();
		infoAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		infoAlign.setMargin(0, 1.5*MenuEditorLayer.SEPARATOR, 0, 0);
		infoAlign.setRelativeName("info");
		infoAlign.setRelativeToWidgetName("menu");
		showInfoButton.setLayoutParameter(infoAlign);

		showInfoButton.addClickEventListener(this._showInfo.bind(this));
		this.addChild(showInfoButton, 1);

		var separator = cc.size(20, 20);

		this._gameTable = new pmui.SelectTableView(cc.size(1, 1), separator, 0, MenuEditorLayer.MAX_ELEMENTS_IN_ROW);
		this._gameTable.addSelectEventListener(this._selectGameElementCallback, this);
		this._gameTable.setSelectFrame(pm.spriteUtils.getInterfaceElementFrame("functionBG"), true);
		this._gameTable.setFitObjectsSizes(true);

		this._gameTable.setContentSize(pm.settings.getScreenSize().width/2,
			pm.settings.getScreenSize().height - goToStartScreen.height - 3*MenuEditorLayer.SEPARATOR - addGame.height
                - screenBounds.top);

		var scrollGameAlign = new ccui.RelativeLayoutParameter();
		scrollGameAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
		this._gameTable.setLayoutParameter(scrollGameAlign);

		this.addChild(this._gameTable);

		this._levelTable = new pmui.SelectTableView(cc.size(1, 1), separator, 0, MenuEditorLayer.MAX_ELEMENTS_IN_ROW);
		this._levelTable.addSelectEventListener(this._selectLevelElementCallback, this);
		this._levelTable.setSelectFrame(pm.spriteUtils.getInterfaceElementFrame("functionBG"), true);
		this._levelTable.setFitObjectsSizes(true);

		this._levelTable.setContentSize(pm.settings.getScreenSize().width/2,
			this._gameTable.getContentSize().height);

		var scrollLevelAlign = new ccui.RelativeLayoutParameter();
		scrollLevelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
		this._levelTable.setLayoutParameter(scrollLevelAlign);

		this.addChild(this._levelTable);

		var boundsY = screenBounds.top + screenBounds.bottom;

		var margin = (pm.settings.getScreenSize().height - this._selectWorldLayer.getContentSize().height - boundsY)/MenuEditorLayer.DOTS_INTENSITY;

		for (var i = 0; i < MenuEditorLayer.DOTS_INTENSITY; ++i)
		{
			var dot = new cc.Sprite("WorldPics/dot.png");

			dot.setAnchorPoint(cc.p(0, 0.5));

			var scale = cc.random0To1()*0.4+0.6;
			dot.setScale(scale);

			dot.setPosition(pm.settings.getScreenSize().width/2 - 20, margin*i);

			this.addChild(dot);
		}
	},

	_drawGameTable: function(needToJump)
	{
		if (needToJump === undefined)
			needToJump = false;

		var oldY = this._gameTable._scroll.getInnerContainer().y;

		this._gameTable.deselectAll();

		var rowCount = this._gameTable.getRowCount();

		for (var i = rowCount - 1; i >= 0; --i)
			this._gameTable.deleteRow(i);

		var rows = Math.floor(world.getGameCount() / MenuEditorLayer.MAX_ELEMENTS_IN_ROW) +
            ((world.getGameCount() % MenuEditorLayer.MAX_ELEMENTS_IN_ROW === 0) ? 0 : 1);

		this._gameTable.setRowCount(rows);

		var cellHeight = 0;

		for(var i = 0; i < world.getGameCount(); ++i)
		{
			var spr = new ccui.ImageView("WorldPics/game{0}.png".format(i % 4));
			var element = new MenuEditorElement(i, world.getGameCount(), spr,
				(pm.settings.getScreenSize().width/2 - 120) / MenuEditorLayer.MAX_ELEMENTS_IN_ROW,
				world.getGameName(i), null,
				this._gameEventCallback, this._changingAvailable, this._pastingAvailable, this);

			this._gameTable.setCell(Math.floor(i / MenuEditorLayer.MAX_ELEMENTS_IN_ROW),
				i % MenuEditorLayer.MAX_ELEMENTS_IN_ROW, element);

			cellHeight = Math.max(cellHeight, element.height);
		}

		var cellSize = cc.size((pm.settings.getScreenSize().width/2 - 120) / MenuEditorLayer.MAX_ELEMENTS_IN_ROW,
			cellHeight);
		this._gameTable.setCellSize(cellSize);

		var curY = this._gameTable._scroll.getInnerContainer().y;

		var percent = 0;

		if (curY !== 0 && needToJump)
			percent = ((curY - oldY) / curY) * 100;

		this._gameTable._scroll.jumpToPercentVertical(percent);
	},

	_drawLevelTable: function(needToJump)
	{
		if (needToJump === undefined)
			needToJump = false;

		var oldY = this._levelTable._scroll.getInnerContainer().y;

		this._levelTable.deselectAll();

		var rowCount = this._levelTable.getRowCount();

		for (var i = rowCount - 1; i >= 0; --i)
			this._levelTable.deleteRow(i);

		if (this._selectedGameIndex === -1)
			return;

		var rows = Math.floor(world.getLevelCount(this._selectedGameIndex) / MenuEditorLayer.MAX_ELEMENTS_IN_ROW) +
            ((world.getLevelCount(this._selectedGameIndex) % MenuEditorLayer.MAX_ELEMENTS_IN_ROW === 0) ? 0 : 1);

		this._levelTable.setRowCount(rows);

		var cellHeight = 0;

		var cellWidth = (pm.settings.getScreenSize().width/2 - 120) / MenuEditorLayer.MAX_ELEMENTS_IN_ROW;

		for(var i = 0; i < world.getLevelCount(this._selectedGameIndex); ++i)
		{
			var spr = new LevelPreview(this._selectedGameIndex, i, cellWidth, cellWidth);
			var element = new MenuEditorElement(i, world.getLevelCount(this._selectedGameIndex), spr,
				cellWidth,
				(i+1) + ". " + world.getLevelName(this._selectedGameIndex, i),
				world.games[this._selectedGameIndex].levels[i].maps.length,
				this._levelEventCallback, this._changingAvailable, this._pastingAvailable, this);

			this._levelTable.setCell(Math.floor(i / MenuEditorLayer.MAX_ELEMENTS_IN_ROW),
				i % MenuEditorLayer.MAX_ELEMENTS_IN_ROW, element);

			cellHeight = Math.max(cellHeight, element.height);
		}

		var cellSize = cc.size((pm.settings.getScreenSize().width/2 - 120) / MenuEditorLayer.MAX_ELEMENTS_IN_ROW,
			cellHeight);
		this._levelTable.setCellSize(cellSize);

		var curY = this._levelTable._scroll.getInnerContainer().y;

		var percent = 0;

		if (curY !== 0 && needToJump)
			percent = ((curY - oldY) / curY) * 100;

		this._levelTable._scroll.jumpToPercentVertical(percent);
	},

	_gameEventCallback: function(eventType, index)
	{
		switch (eventType)
		{
			case MenuEditorLayer.EventType.Remove:
				this._removeGame(index);
				break;
			case MenuEditorLayer.EventType.Copy:
				this._copyGame(index);
				break;
			case MenuEditorLayer.EventType.ShiftLeft:
				this._shiftLeftGame(index);
				break;
			case MenuEditorLayer.EventType.ShiftRight:
				this._shiftRightGame(index);
				break;
			case MenuEditorLayer.EventType.PasteLeft:
				this._pasteLeftGame(index);
				break;
			case MenuEditorLayer.EventType.PasteRight:
				this._pasteRightGame(index);
				break;
		}
	},

	_levelEventCallback: function(eventType, index)
	{
		switch (eventType)
		{
			case MenuEditorLayer.EventType.Remove:
				this._removeLevel(index);
				break;
			case MenuEditorLayer.EventType.Copy:
				this._copyLevel(index);
				break;
			case MenuEditorLayer.EventType.ShiftLeft:
				this._shiftLeftLevel(index);
				break;
			case MenuEditorLayer.EventType.ShiftRight:
				this._shiftRightLevel(index);
				break;
			case MenuEditorLayer.EventType.PasteLeft:
				this._pasteLeftLevel(index);
				break;
			case MenuEditorLayer.EventType.PasteRight:
				this._pasteRightLevel(index);
				break;
		}
	},

	_selectGameElementCallback: function(selectedGameElement)
	{
		if (selectedGameElement &&
            selectedGameElement.x * MenuEditorLayer.MAX_ELEMENTS_IN_ROW + selectedGameElement.y < world.getGameCount())
		{
			this._selectedGameIndex = selectedGameElement.x * MenuEditorLayer.MAX_ELEMENTS_IN_ROW + selectedGameElement.y;

			if (!cc.sys.isNative)
				window.location.hash = "editor/worlds/" + world.id + "/games/" + this._selectedGameIndex;
		}
		else
		{
			this._selectedGameIndex = -1;
			this._selectedLevelIndex = -1;

			if (!cc.sys.isNative)
				window.location.hash = "editor/worlds/" + world.id;
		}

		this._drawLevelTable();
	},

	_selectLevelElementCallback: function(selectedLevelElement)
	{
		if (selectedLevelElement &&
            selectedLevelElement.x * MenuEditorLayer.MAX_ELEMENTS_IN_ROW + selectedLevelElement.y < world.getLevelCount(this._selectedGameIndex))
		{
			this._selectedLevelIndex = selectedLevelElement.x * MenuEditorLayer.MAX_ELEMENTS_IN_ROW + selectedLevelElement.y;

			pm.settings.setGame(this._selectedGameIndex);
			pm.settings.setLevel(this._selectedLevelIndex);
			var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY,
				new LevelEditorScene(this._selectedGameIndex, this._selectedLevelIndex));
			cc.director.runScene(trans);
		}
		else
		{ this._selectedLevelIndex = -1; }
	},

	_redrawWorld: function(needToJump, game)
	{
		if (needToJump === undefined)
			needToJump = false;

		if (game === undefined)
			game = -1;

		this._selectedGameIndex = game;
		this._selectedLevelIndex = -1;

		this._drawGameTable(needToJump);

		if (this._selectedGameIndex !== -1)
		{
			this._gameTable.selectCell(Math.floor(this._selectedGameIndex / MenuEditorLayer.MAX_ELEMENTS_IN_ROW),
				this._selectedGameIndex % MenuEditorLayer.MAX_ELEMENTS_IN_ROW);
		}

		this._drawLevelTable();
		this._loadChangePublicityWorldTexture();
		this._loadChangeLinkedWithMireraTexture();
	},

	_startScreen: function()
	{
		pm.settings.isEditorMode = false;

		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new StartMenuScene());
		cc.director.runScene(trans);
	},

	_showMenu: function()
	{
		if(cc.director.getRunningScene().getChildByTag(SettingsLayer.TAG))
			return;

		var settingsLayer = new SettingsLayer(this, this._closeMenu);

		cc.director.getRunningScene().addChild(settingsLayer, 100, SettingsLayer.TAG);
	},

	_closeMenu: function()
	{
		cc.director.getRunningScene().removeChildByTag(SettingsLayer.TAG);
	},

	_addWorld: function()
	{
		if (!this.getChildByTag(MenuEditorLayer.POPUP_LAYER_TAG))
		{
			var newWorldLayer = new NewWorldLayer(this, this._onWorldAdded);

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
			newWorldLayer.setLayoutParameter(align);

			this.addChild(newWorldLayer, 100, MenuEditorLayer.POPUP_LAYER_TAG);
		}
	},

	_uploadWorld: function () {
		if (!this.getChildByTag(MenuEditorLayer.POPUP_LAYER_TAG)) {

			var newWorldLayer = new UploadWorldLayer(this, this._onWorldAdded);

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
			newWorldLayer.setLayoutParameter(align);

			this.addChild(newWorldLayer, 100, MenuEditorLayer.POPUP_LAYER_TAG);
		}
	},

	_onWorldAdded: function (error, worldId) {
		var self = this;

		var loadingLayer = new LoadingLayer();
		loadingLayer.show();

		if(!error)
		{
			pm.worldUtils.loadWorld({
				worldID: worldId,
				callback: function (error, result)
				{
					if(!error)
					{
						pm.settings.setSelectedWorldID(result[0]);
						self._redrawWorld();
						this._selectWorldLayer.refresh();
						loadingLayer.remove();

						if (!cc.sys.isNative)
							window.location.hash = "editor/worlds/" + result[0];
					}
					else
					{
						cc.log(error);
					}
				},
				callbackTarget: this,
				reloadBuiltinOnError: true
			});
		}
		else
		{
			var messageBox = new pmui.MessageBox(self, LocalizedString("ErrorWhileAddingWorld"), this._uploadError);

			cc.log(JSON.stringify(error));

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
			messageBox.setLayoutParameter(align);

			self.addChild(messageBox, 100);

			loadingLayer.remove();
		}
	},

	_uploadError: function(object) {},

	_confirmLinkWorldWithMirera: function()
	{
		if (!this.getChildByTag(MenuEditorLayer.CONFIRM_LAYER_TAG))
		{
			var linkedWithMirera = pm.settings.getWorldRecord().linkedWithMirera;

			if(linkedWithMirera === undefined)
				linkedWithMirera = false;

			var string = linkedWithMirera ? LocalizedString("UnlinkWorldWithMirera") : LocalizedString("LinkWorldWithMirera")

			var input = new pmui.ConfirmWindow(string);

			input.setCallback(this, this._linkWorldWithMirera, function () {});

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
			input.setLayoutParameter(align);

			this.addChild(input, 100, MenuEditorLayer.CONFIRM_LAYER_TAG);
		}
	},

	_linkWorldWithMirera: function()
	{
		var loadingLayer = new LoadingLayer();
		loadingLayer.show();

		var self = this;

		var callback = function (error, result)
		{
			loadingLayer.remove();

			if(error)
			{
				var messageBox = new pmui.MessageBox(self, LocalizedString("ErrorWhileSync").format(error.text), self._syncError);

				cc.log(error);

				var align = new ccui.RelativeLayoutParameter();
				align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
				messageBox.setLayoutParameter(align);

				self.addChild(messageBox, 100);
			}
			else {
				var linkedWithMirera = pm.settings.getWorldRecord().linkedWithMirera;

				linkedWithMirera = !linkedWithMirera;

				world.linkedWithMirera = linkedWithMirera;
				world.save();

				pm.settings.setWorldLinkedWithMirera(world.id, linkedWithMirera);
				self._loadChangeLinkedWithMireraTexture();
			}
		};

		var linkedWithMirera = pm.settings.getWorldRecord().linkedWithMirera;

		if(linkedWithMirera === undefined)
			linkedWithMirera = false;

		if(!linkedWithMirera)
		{
			pm.apiServerUtils.getMireraCourses(function(error, result) {
				loadingLayer.remove();

				if(error)
				{
					var messageBox = new pmui.MessageBox(self, LocalizedString("ErrorWhileSync").format(error.text), self._syncError);

					cc.log(error);

					var align = new ccui.RelativeLayoutParameter();
					align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
					messageBox.setLayoutParameter(align);

					self.addChild(messageBox, 100);
				}
				else
				{
					var selectLayer = new SelectMireraCourseLayer(function(course)
					{
						loadingLayer = new LoadingLayer();
						loadingLayer.show();

						pm.apiServerUtils.linkWorldWithMirera(callback, pm.settings.getSelectedWorldID(), course._id, pm.appUtils.getSupportedLevelFormatVersion());
					}, this, result.courses);

					cc.director.getRunningScene().addChild(selectLayer, 100);
				}
			}.bind(this));
		}
		else
		{
			pm.apiServerUtils.unlinkWorldWithMirera(callback, pm.settings.getSelectedWorldID());
		}
	},

	_loadChangeLinkedWithMireraTexture: function()
	{
		var normal, selected, disabled;

		if (world.linkedWithMirera)
		{
			normal = pm.spriteUtils.getIconName("mireraNew", pm.SELECTED_STATE);
			selected = pm.spriteUtils.getIconName("mireraNew", pm.NORMAL_STATE);
			disabled = pm.spriteUtils.getIconName("mireraNew", pm.DISABLED_STATE);
		}
		else
		{
			normal = pm.spriteUtils.getIconName("mireraNew", pm.NORMAL_STATE);
			selected = pm.spriteUtils.getIconName("mireraNew", pm.SELECTED_STATE);
			disabled = pm.spriteUtils.getIconName("mireraNew", pm.DISABLED_STATE);
		}

		this._linkedWithMireraButton.loadTextureNormal(normal, ccui.Widget.PLIST_TEXTURE);
		this._linkedWithMireraButton.loadTexturePressed(selected, ccui.Widget.PLIST_TEXTURE);
		this._linkedWithMireraButton.loadTextureDisabled(disabled, ccui.Widget.PLIST_TEXTURE);
	},

	_syncError: function(object) {},

	_renameWorld: function ()
	{
		if (!this.getChildByTag(MenuEditorLayer.POPUP_LAYER_TAG))
		{
			var input = new pmui.ChangeWorldNameDialog();

			input.setCallback(this, this._setWorldName, function ()
			{
			});

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
			input.setLayoutParameter(align);

			this.addChild(input, 100, MenuEditorLayer.POPUP_LAYER_TAG);
		}
	},

	_setWorldName: function (newName, newDescription)
	{
		if (world.name === newName && world.description === newDescription)
			return;

		var self = this;

		if(world.name !== newName)
		{
			world.rename(newName);
			pm.settings.renameWorld(world.id, newName);
		}

		if(world.description !== newDescription)
		{
			world.changeDescription(newDescription);
			pm.settings.changeWorldDescription(world.id, newDescription);
		}
	},

	_confirmDeletingWorld: function()
	{
		if (!this.getChildByTag(MenuEditorLayer.CONFIRM_LAYER_TAG))
		{
			var input = new pmui.ConfirmWindow(LocalizedString("DeleteWorld"));

			input.setCallback(this, this._deleteWorld, function () {});

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
			input.setLayoutParameter(align);

			this.addChild(input, 100, MenuEditorLayer.CONFIRM_LAYER_TAG);
		}
	},

	_deleteWorld: function()
	{
		var self = this;
		var deleteWorldID = pm.settings.getSelectedWorldID();

		var availableWorlds = pm.settings.getAvailableWorlds();
		var user = pm.settings.getUserData();

		var userWorldCount = 0;

		for(var worldID in availableWorlds)
		{
			if(availableWorlds[worldID].owner && availableWorlds[worldID].owner.id === user.id)
				++userWorldCount;
		}

		if(userWorldCount > 1)
		{
			var loadingLayer = new LoadingLayer();
			loadingLayer.show();

			pm.apiServerUtils.deleteWorld(function(error)
			{
				if (error)
				{
					var messageBox = new pmui.MessageBox(self, LocalizedString("ErrorWhileDeletingWorld"), self._syncError);

					cc.log(JSON.stringify(error));

					var align = new ccui.RelativeLayoutParameter();
					align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
					messageBox.setLayoutParameter(align);

					self.addChild(messageBox, 100);
				}
				else
				{
					pm.userCache.remove();
					pm.fileUtils.removeFile(pm.settings.getWorldPath());
					pm.fileUtils.removeFile(pm.settings.getWorldEditLogPath());
					pm.settings.deleteWorld(pm.settings.getSelectedWorldID());

					pm.worldUtils.loadWorld({
						worldID: self._selectWorldLayer.getWorldIDInSelectedGroup(deleteWorldID),
						callback: function (error, result)
						{
							if(!error)
							{
								pm.settings.setSelectedWorldID(result[0]);
								self._redrawWorld();
								loadingLayer.remove();
								self._updateWorldList();

								if (!cc.sys.isNative)
									window.location.hash = "editor/worlds/" + result[0];
							}
							else
							{
								cc.log(error);
							}
						},
						callbackTarget: this,
						reloadBuiltinOnError: true
					});
				}
			}, deleteWorldID);
		}
	},

	_updateWorldList: function()
	{
		this._selectWorldLayer.refresh();
	},

	_changePublicityWorld: function()
	{
		if (world.public)
			world.changePublicity(false);
		else
			world.changePublicity(true);

		this._loadChangePublicityWorldTexture();
	},

	_loadChangePublicityWorldTexture: function()
	{
		var normal, selected, disabled;

		if (world.public)
		{
			normal = pm.spriteUtils.getIconName("public", pm.NORMAL_STATE);
			selected = pm.spriteUtils.getIconName("public", pm.SELECTED_STATE);
			disabled = pm.spriteUtils.getIconName("public", pm.DISABLED_STATE);
		}
		else
		{
			normal = pm.spriteUtils.getIconName("notPublic", pm.NORMAL_STATE);
			selected = pm.spriteUtils.getIconName("notPublic", pm.SELECTED_STATE);
			disabled = pm.spriteUtils.getIconName("notPublic", pm.DISABLED_STATE);
		}

		this._changePublicityWorldButton.loadTextureNormal(normal, ccui.Widget.PLIST_TEXTURE);
		this._changePublicityWorldButton.loadTexturePressed(selected, ccui.Widget.PLIST_TEXTURE);
		this._changePublicityWorldButton.loadTextureDisabled(disabled, ccui.Widget.PLIST_TEXTURE);
	},

	_addGame: function ()
	{
		world.addGame();
		pm.settings.setGame(world.getGameCount() - 1);
		this._redrawWorld();
	},

	_renameGame: function()
	{
		if (!this.getChildByTag(MenuEditorLayer.POPUP_LAYER_TAG) && this._selectedGameIndex !== -1)
		{
			var input = new pmui.TextInputDialog(LocalizedString("EnterNewGameName"), world.getGameName(this._selectedGameIndex),
				LocalizedString("NewName"));

			input.setCallback(this, this._setGameName, function ()
			{
			});

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
			input.setLayoutParameter(align);

			this.addChild(input, 100, MenuEditorLayer.POPUP_LAYER_TAG);
		}
	},

	_setGameName: function (newName)
	{
		if (world.getGameName(this._selectedGameIndex) === newName)
			return;

		world.renameGame(this._selectedGameIndex, newName);
		this._redrawWorld();
	},

	_removeGame: function(index)
	{
		world.removeGame(index);

		var game = world.getGameCount() - 1;

		if (pm.settings.getGame() > game)
			pm.settings.setGame(game);

		this._redrawWorld(true);
	},

	_copyGame: function(index)
	{
		world.copy(index);
	},

	_addLevel: function ()
	{
		if (this._selectLevelTypeLayer === null && this._selectedGameIndex !== -1)
		{
			this._selectLevelTypeLayer = new SelectLevelTypeLayer(this);

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
			this._selectLevelTypeLayer.setLayoutParameter(align);

			this.addChild(this._selectLevelTypeLayer, 100);
		}
	},

	_addLevelWithType: function (levelType)
	{
		this.removeChild(this._selectLevelTypeLayer);
		this._selectLevelTypeLayer = null;
		world.addLevel(this._selectedGameIndex, levelType);

		pm.settings.setLevel(world.getLevelCount(this._selectedGameIndex) - 1);

		this._drawLevelTable();
	},

	_cancelAddLevelWithType: function ()
	{
		this.removeChild(this._selectLevelTypeLayer);
		this._selectLevelTypeLayer = null;
	},

	_removeLevel: function(index)
	{
		world.removeLevel(this._selectedGameIndex, index);

		var level = world.getLevelCount(this._selectedGameIndex) - 1;

		if(level < 0)
			level = 0;

		if (pm.settings.getLevel() > level)
			pm.settings.setLevel(level);

		this._drawLevelTable(true);
	},

	_copyLevel: function(index)
	{
		world.copy(this._selectedGameIndex, index);
	},

	_paste: function(sender)
	{
		if (world._clipboard instanceof pm.data.Game)
		{
			if(world.games.length > 0)
			{
				this._pastingAvailable = !this._pastingAvailable;
				this._setVisiblePasting(this._gameTable, this._pastingAvailable);
				this._setVisiblePasting(this._levelTable, false);
			}
			else
			{
				this._pasteRightGame(-1);
			}
		}
		else if (world._clipboard instanceof pm.AbstractLevel && this._selectedGameIndex !== -1)
		{
			if(world.games[this._selectedGameIndex].levels.length > 0)
			{
				this._pastingAvailable = !this._pastingAvailable;
				this._setVisiblePasting(this._gameTable, false);
				this._setVisiblePasting(this._levelTable, this._pastingAvailable);
			}
			else
			{
				this._pasteRightLevel(-1);
			}
		}
	},

	_setVisiblePasting: function(table, flag)
	{
		for (var x = 0; x < table.getRowCount(); ++x)
		{
			for (var y = 0; y < table.getColumnCount(); ++y)
			{
				var cell = table.getCell(x, y);

				if (!cell)
					continue;

				if (cell._pasteLeftButton)
					cell._pasteLeftButton.setVisible(flag);

				if (cell._pasteRightButton)
					cell._pasteRightButton.setVisible(flag);
			}
		}
	},

	_changing: function(sender)
	{
		if (this._changingAvailable)
		{
			sender.setNormalTexture();
			this._changingAvailable = false;
		}
		else
		{
			sender.setSelectedTexture();
			this._changingAvailable = true;
		}

		this._setVisibleChanging(this._gameTable, this._changingAvailable);
		this._setVisibleChanging(this._levelTable, this._changingAvailable);
	},

	_downloadWorld: function () {
		var world = pm.worldUtils.downloadWorld({
			worldID: pm.settings.getSelectedWorldID()
		});
		var blob = new Blob([JSON.stringify(world)], {type: "application/json"});
		FileSaver.saveAs(blob, "{0}.json".format(world.name));
	},

	_confirmDownloadFromServer: function()
	{
		if (!this.getChildByTag(MenuEditorLayer.CONFIRM_LAYER_TAG))
		{
			var input = new pmui.ConfirmWindow(LocalizedString("DownloadWorldFromServer"));

			input.setCallback(this, this._downloadFromServer, function () {});

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
			input.setLayoutParameter(align);

			this.addChild(input, 100, MenuEditorLayer.CONFIRM_LAYER_TAG);
		}
	},

	_downloadFromServer: function ()
	{
		console.log("downloading world from server");

		var loadingLayer = new LoadingLayer();
		loadingLayer.show();

		pm.worldUtils.downloadWorldFromServer({
			worldID: pm.settings.getSelectedWorldID(),
			callbackTarget: this,
			loadLocalOnError: false,
			reloadBuiltinOnError: true,
			callback: function (error, result)
			{
				loadingLayer.remove();

				if(!error && result.length > 0 && result[0])
				{
					pm.settings.setSelectedWorldID(result[0]);

					world.getEditLog().clear();

					this._redrawWorld();
					this._updateWorldList();
				}
				else
				{
					this._handleNetworkError(error, LocalizedString("DownloadFromServerError").format(error[0].text), this._syncError);

					cc.log(error);
				}
			}
		});
	},

	_confirmUploadToServer: function()
	{
		if (!this.getChildByTag(MenuEditorLayer.CONFIRM_LAYER_TAG))
		{
			var input = new pmui.ConfirmWindow(LocalizedString("UploadWorldToServer"));

			input.setCallback(this, this._uploadToServer, function () {});

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
			input.setLayoutParameter(align);

			this.addChild(input, 100, MenuEditorLayer.CONFIRM_LAYER_TAG);
		}
	},

	_uploadToServer: function ()
	{
		console.log("uploading world to server");

		var loadingLayer = new LoadingLayer();
		loadingLayer.show();

		pm.worldUtils.uploadWorldToServer({
			worldID: pm.settings.getSelectedWorldID(),
			loadLocalOnError: false,
			reloadBuiltinOnError: true,
			callbackTarget: this,
			callback: function (error, result)
			{
				loadingLayer.remove();

				if(!error)
				{
					world.getEditLog().clear();

					this._redrawWorld();
					this._updateWorldList();
				}
				else
				{
					this._handleNetworkError(error, LocalizedString("UploadToServerError").format(error[0].text), this._syncError);

					cc.log(error);
				}
			}
		});
	},

	_handleNetworkError: function (error, message, callback)
	{
		var messageBox = new pmui.MessageBox(this, message, callback);

		var align = new ccui.RelativeLayoutParameter();
		align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
		messageBox.setLayoutParameter(align);

		this.addChild(messageBox, 100);
	},

	_setVisibleChanging: function(table, flag)
	{
		for (var x = 0; x < table.getRowCount(); ++x)
		{
			for (var y = 0; y < table.getColumnCount(); ++y)
			{
				var cell = table.getCell(x, y);

				if (!cell)
					continue;

				if (cell._shiftLeftButton)
					cell._shiftLeftButton.setVisible(flag);

				if (cell._shiftRightButton)
					cell._shiftRightButton.setVisible(flag);
			}
		}
	},

	_shiftRightLevel: function(index)
	{
		world.swapLevels(this._selectedGameIndex, index, index+1);

		this._drawLevelTable(true);
	},

	_shiftLeftLevel: function(index)
	{
		world.swapLevels(this._selectedGameIndex, index, index-1);

		this._drawLevelTable(true);
	},

	_shiftRightGame: function(index)
	{
		world.swapGames(index, index+1);
		this._redrawWorld(true);
	},

	_shiftLeftGame: function(index)
	{
		world.swapGames(index, index-1);
		this._redrawWorld(true);
	},

	_pasteRightLevel: function(index)
	{
		world.paste(this._selectedGameIndex, index+1);

		this._drawLevelTable(true);

		this._setVisiblePasting(this._levelTable, false);
		this._pastingAvailable = false;
	},

	_pasteLeftLevel: function(index)
	{
		world.paste(this._selectedGameIndex, index);

		this._drawLevelTable(true);

		this._setVisiblePasting(this._levelTable, false);
		this._pastingAvailable = false;
	},

	_pasteRightGame: function(index)
	{
		world.paste(this._selectedGameIndex, index+1);

		this._redrawWorld(true);

		this._setVisiblePasting(this._gameTable, false);
		this._pastingAvailable = false;
	},

	_pasteLeftGame: function(index)
	{
		world.paste(this._selectedGameIndex, index);

		this._redrawWorld(true);

		this._setVisiblePasting(this._gameTable, false);
		this._pastingAvailable = false;
	},

	_showInfo: function(sender)
	{
		var INFO_LAYER_TAG = 1;

		if(!this.getChildByTag(INFO_LAYER_TAG))
		{
			var layer = new WorldInfoLayer();

			var infoAlign = new ccui.RelativeLayoutParameter();
			infoAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_RIGHTALIGN);
			infoAlign.setMargin(0, 2*MenuEditorLayer.SEPARATOR, 0, 0);
			infoAlign.setRelativeToWidgetName("info");
			layer.setLayoutParameter(infoAlign);

			this.addChild(layer, 0, INFO_LAYER_TAG);
			this.requestDoLayout();
		}
		else
		{
			this.removeChildByTag(INFO_LAYER_TAG);
		}
	}
});

MenuEditorLayer.SEPARATOR = 8;
MenuEditorLayer.MAX_ELEMENTS_IN_ROW = 3;
MenuEditorLayer.POPUP_LAYER_TAG = 100;
MenuEditorLayer.CONFIRM_LAYER_TAG = 1523;

MenuEditorLayer.EventType = {
	Remove: 0,
	Copy: 1,
	ShiftLeft: 2,
	ShiftRight: 3,
	PasteLeft: 4,
	PasteRight: 5
};

MenuEditorLayer.DOTS_INTENSITY = 50;

/**
 * @class Scene for {@link MenuEditorLayer}
 * @extends cc.Scene
 * @constructor
 * @param {Number} game Index of current game.
 */
var MenuEditorScene = cc.Scene.extend(/** @lends MenuEditorScene# */{
	ctor: function(game)
	{
		this._super();

		if (game === undefined)
			game = -1;

		var layer = new MenuEditorLayer(game);
		this.addChild(layer);

		var backLayer = pm.backgroundUtils.generateBackground();
		this.addChild(backLayer, -1);

		if (!cc.sys.isNative)
		{
			window.location.hash = "editor/worlds/" + world.id;

			if (game >= 0)
				window.location.hash += "/games/" + game;
		}
	}
});
