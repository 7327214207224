/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

var DraftsmanMapEditorLayer = DraftsmanMapLayer.extend(SquarableMapEditorLayerHelper).extend({

	createMapElement: function ()
	{
		var el = null;
		el = new pm.data.DraftsmanMapElement();
		el.originalType = DraftsmanMapElementType.Green;
		el.initElement();
		return el;
	},

	resizeContainer: function()
	{
		SquarableMapEditorLayerHelper.resizeContainer.call(this);

		var task = this._map.parentLevel.taskList.getTask(pm.DraftsmanLevelModule.Type);

		if (task)
		{
			this._map.lines = [];
			task.data[this._map.parentLevel.getActiveMapIndex()] = this._map.lines;

			this.taskDrawLayer.clear();
			this.drawTask();
		}
	},

	_mapElementClicked: function (pos)
	{
		if (this._tapType === EditorTapsType.ChangeMapElement)
		{
			if (this._selectedObjectForTapType === pm.data.DraftsmanMapElement.START_ELEMENT_POS)
			{
				this._map.setStartElementPos(pos);

				for (var x = 0; x < this._map.width; ++x)
				{
					for (var y = 0; y < this._map.height; ++y)
					{
						var element = this._map.element(cc.p(x, y));
						element.updateElementNumber(cc.p(x - pos.x, pos.y - y));
					}
				}
			}
			else
			{
				var element = this._map.element(pos);
				element.setType(this._selectedObjectForTapType);
				element.originalType = this._selectedObjectForTapType;
				pm.sendCustomEvent(pme.MAP_GRASS_COUNT_CHANGED_EVENT_STR);
			}
		}
		else if (this._tapType === EditorTapsType.AddRobot)
		{
			if (this._map.element(pos).getRobotsCount() !== 0)
				return;

			var robot = pm.moduleUtils.generateRobot(this._map.getType(), this._selectedObjectForTapType);
			this._addRobot(pos, robot);
			pm.sendCustomEvent(pme.ADD_ROBOT, robot);

			this._setActiveRobot(robot);
		}
		else if (this._tapType === EditorTapsType.AddObject)
		{
			if (this._map.element(pos).getObjectCount() !== 0)
				return;

			if (!pm.moduleUtils.canNotDragOrAddObject(this._map.getType(), this._map, pos))
				this._addObject(pos, pm.moduleUtils.generateObject(this._map.getType(), this._selectedObjectForTapType));
		}
		else if (this._tapType === EditorTapsType.AddClone)
		{
			if (this._map.element(pos).getRobotsCount() !== 0)
				return;

			var robot = pm.moduleUtils.generateRobot(this._map.getType(), this._activeRobot.getType());
			this._addClone(pos, robot);
			pm.sendCustomEvent(pme.ADD_ROBOT, robot);

			this._setActiveRobot(robot);
		}
	},

	moveElementsUp: function ()
	{
		for (var j = this._map.height - 2; j >= 0; --j)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i, j + 1)).setType(elementType);
				this._map.element(cc.p(i, j + 1)).originalType = elementType;

				var type = DraftsmanMapElementType.Green;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	},

	moveElementsDown: function ()
	{
		for (var j = 1; j < this._map.height; ++j)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i, j - 1)).setType(elementType);
				this._map.element(cc.p(i, j - 1)).originalType = elementType;

				var type = DraftsmanMapElementType.Green;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;

			}
		}
	},

	moveElementsLeft: function ()
	{
		for (var i = this._map.width - 2; i >= 0; --i)
		{
			for (var j = 0; j < this._map.height; ++j)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i + 1, j)).setType(elementType);
				this._map.element(cc.p(i + 1, j)).originalType = elementType;

				var type = DraftsmanMapElementType.Green;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	},

	moveElementsRight: function ()
	{
		for (var i = 1; i < this._map.width; ++i)
		{
			for (var j = 0; j < this._map.height; ++j)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i - 1, j)).setType(elementType);
				this._map.element(cc.p(i - 1, j)).originalType = elementType;

				var type = DraftsmanMapElementType.Green;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	}

});
