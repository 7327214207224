/**
 * @class Interface for all 2D robots controlled by player.
 * @interface
 * @extends pm.PlayerRobot
 */

pm.PlayerRobot2D = pm.PlayerRobot.extend(/** @lends pm.PlayerRobot2D#*/{

	_oldPosition: cc.p(),

	/**
	 * Current position of robot
	 * @type {cc.Point}
	 */
	position: cc.p(0, 0),
	/**
	 * Current direction of robot
	 * @type {Number}
	 */
	direction: 0,

	ctor: function()
	{
		this._addNonEnumerableProps("position", "direction", "_oldPosition");

		this._super();

		this.nativeFunctionMap[pm.CMD_INDICATE] = new pm.NativeFunction(this, this._indicate);
	},

	/**
	 * Checks condition for this robot.
	 * @param {String} condition
	 * @param {*} [args] Additional arguments of checking condition.
	 * @returns {Boolean}
	 */
	checkCondition: function(condition, args)
	{
		this.playAnimation(RobotAnimation2D.Indicate, this._endIndicate, this.direction);

		return pm.AbstractRobot.prototype.checkCondition.call(this, condition, args);
	},

	getDirectionCount: function() {},

	_removeFromOldPosition: function()
	{
		var map = this.getMap();

		if(this._oldPosition.x < map.width && this._oldPosition.y < map.height)
		{
			if(map.element(this._oldPosition).containsRobot(this))
				map.element(this._oldPosition).removeRobot(this);
		}
	},

	_removeFromCurrentPosition: function()
	{
		var map = this.getMap();

		if(this.position.x < map.width && this.position.y < map.height)
		{
			if(map.element(this.position).containsRobot(this))
				map.element(this.position).removeRobot(this);
		}
	},

	/**
     * Sets robot position and direction.
     * @param {cc.Point} point Position in element points.
     * @param {Number} direction
     */
	setPosition: function(point, direction)
	{
		var map = this.getMap();

		this._removeFromCurrentPosition();

		this._oldPosition = point;
		this.position = point;
		this.direction = direction;

		map.element(point).addRobot(this);
	},

	_turnLeft: function()
	{
		var newDirection = (this.direction + 1) % this.getDirectionCount();

		this.playAnimation(RobotAnimation2D.Turn, this._endTurn, [this.direction, newDirection]);

		this.direction = newDirection;
	},

	_turnRight: function()
	{
		var newDirection = (this.direction + this.getDirectionCount() - 1) % this.getDirectionCount();

		this.playAnimation(RobotAnimation2D.Turn, this._endTurn, [this.direction, newDirection]);

		this.direction = newDirection;
	},

	_indicate: function()
	{
		this.playAnimation(RobotAnimation2D.Indicate, this._endIndicate, this.direction);
	},

	destroy: function()
	{
		this.setStateFlag(pm.RobotState.Broken);

		if(!CORE_BUILD)
			this.sprite.stopAnimation();
		this.playAnimation(RobotAnimation2D.Destroy, this._endIndicate, this.direction, true);

	},

	_canMoveTo: function(target, direction)
	{
		return true;
	},

	_moveTo: function(target, reverse, initialDirection, skipAnimation)
	{
		if(this.isBroken())
		{
			if(reverse)
				this.clearStateFlag(pm.RobotState.Broken);

			if(!CORE_BUILD)
				this.updateSprite();
			return;
		}

		var map = this.getMap();

		var move = true;

		if(!target)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		for(var i in map.element(target)._robotsHere)
		{
			var r = map.element(target)._robotsHere[i];

			if(!(r instanceof pm.AbstractRobot))
				continue;

			if(!r.canMoveOn())
			{
				if(!r.interactable())
					this.setStateFlag(pm.RobotState.Broken);

				move = false;
				break;
			}
		}

		if(move)
		{
			if (initialDirection === undefined)
				initialDirection = this.direction;

			var direction = reverse ? (initialDirection + this.getDirectionCount()/2) % this.getDirectionCount() : initialDirection;

			if(!this._canMoveTo(target, direction) && !reverse)
			{
				this.setStateFlag(pm.RobotState.Broken);

				return;
			}

			this._oldPosition = this.position;
			this.position = target;

			map.element(this.position).addRobot(this);

			if(!this.isBroken() && !skipAnimation)
				this.playAnimation(RobotAnimation2D.Move, this._endMove, this.position);
		}
		else if(!this.isBroken())
		{
			for(var i in map.element(target)._robotsHere)
			{
				var r = map.element(target)._robotsHere[i];

				if(!(r instanceof pm.AbstractRobot))
					continue;

				r.interact(this, this._endInteract);
			}
		}
	},

	_endMove: function()
	{
		var map = this.getMap();

		for(var i in map.element(this.position)._robotsHere)
		{
			var r = map.element(this.position)._robotsHere[i];

			if(!(r instanceof pm.AbstractRobot))
				continue;

			r.interact(this, this._endInteract);
		}

		this._removeFromOldPosition();

		this._oldPosition = this.position;

		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_endTurn: function()
	{
		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_endInteract: function(robot)
	{
	},

	_endIndicate: function()
	{
		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_move: function()
	{
		var nextPos = this.getMap().getNextDirectionalElementPosition(this.position, this.direction);
		this._moveTo(nextPos, false);
	},

	_moveReverse: function()
	{
		var prevPos = this.getMap().getPrevDirectionalElementPosition(this.position, this.direction);
		this._moveTo(prevPos, true);
	},

	updateSprite: function(isBroken)
	{
		if (isBroken === undefined)
			isBroken = false;

		if(!CORE_BUILD)
			if (this.sprite instanceof pm.ObjectSprite2D)
			{
				if (!isBroken)
					this.sprite.setRealPosition(this.position);
				else
					this.sprite.setRealPosition(this._oldPosition);

				this.sprite.setDirection(this.direction);
			}
	},

	getState: function ()
	{
		return {
			direction: this.direction,
			position: this.position,
			animation: this.getLastAnimation()
		};
	},

	setState: function (state)
	{

	}
});
