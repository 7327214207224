/**
 * Created by Kirill Mashchenko on 30.10.18.
 */

/**
 * @class Layout for Push Lesson page.
 * @extends CommonLessonPage
 */

var PushLessonPage = CommonLessonPage.extend(/** @lends PushLessonPage# */{

	ctor: function(target, cancelCallback)
	{
		this._super(target, cancelCallback);

		this._loadExtraInterface();
	},

	_loadExtraInterface: function()
	{
		pm.spriteUtils.addTempSpriteFrames(pm.moduleUtils.getRobotSpriteSheet(pm.PushLevelModule.Type, pm.PushLevelModule.RobotTypes.PushRobot));
		pm.spriteUtils.addTempSpriteFrames(pm.moduleUtils.getRobotSpriteSheet(pm.PushLevelModule.Type, pm.PushLevelModule.RobotTypes.PullRobot));

		var pushSprite = new ccui.ImageView("push_robot3.png", ccui.Widget.PLIST_TEXTURE);
		var pullSprite = new ccui.ImageView("pull_robot0.png", ccui.Widget.PLIST_TEXTURE);

		pushSprite.setAnchorPoint(cc.p(0, 1));
		pullSprite.setAnchorPoint(cc.p(1, 1));

		pushSprite.setScale(2);
		pullSprite.setScale(2);

		var pushAlign = new ccui.RelativeLayoutParameter();
		pushAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		pushAlign.setMargin(0, CommonLessonPage.SEPARATOR, 0, 0);
		pushAlign.setRelativeName("pushSprite");
		pushSprite.setLayoutParameter(pushAlign);

		this._container.addChild(pushSprite);

		var pullAlign = new ccui.RelativeLayoutParameter();
		pullAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		pullAlign.setMargin(0, CommonLessonPage.SEPARATOR - 7, 0, 0);
		pullAlign.setRelativeName("pullSprite");
		pullSprite.setLayoutParameter(pullAlign);

		this._container.addChild(pullSprite);

		var pushLabel = new ccui.Text(LocalizedString("Push"), pm.settings.fontName, 30);
		var pullLabel = new ccui.Text(LocalizedString("Pull"), pm.settings.fontName, 30);

		var pushLabelAlign = new ccui.RelativeLayoutParameter();
		pushLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		pushLabelAlign.setMargin(pushSprite.width-pushLabel.width/2, (cc.sys.isNative) ? 0 : pushSprite.height, 0, 0);
		pushLabelAlign.setRelativeName("pushLabel");
		pushLabelAlign.setRelativeToWidgetName("pushSprite");
		pushLabel.setLayoutParameter(pushLabelAlign);

		this._container.addChild(pushLabel);

		var pullLabelAlign = new ccui.RelativeLayoutParameter();
		pullLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_RIGHTALIGN);
		pullLabelAlign.setMargin(0, (cc.sys.isNative) ? 0 : pullSprite.height - 7, pullSprite.width - pullLabel.width/2, 0);
		pullLabelAlign.setRelativeToWidgetName("pullSprite");
		pullLabel.setLayoutParameter(pullLabelAlign);

		this._container.addChild(pullLabel);

		var description = "Двигун \"двигает\" ящики и бочки на космическом складе. Он не может отодвинуть груз от стены. А вот Тягун может \"оттащить\" груз от стены и перетащить его на нужное место.";
		var commonTextLabel = new ccui.Text(description, pm.settings.fontName, 25);
		commonTextLabel.setTextAreaSize(cc.size(this._container.width - 2 * CommonLessonPage.SEPARATOR - 2 * pushSprite.width - 2 * pullSprite.width, 0));

		var commonTextAlign = new ccui.RelativeLayoutParameter();
		commonTextAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		commonTextAlign.setMargin(0, 2 * CommonLessonPage.SEPARATOR, 0, 0);
		commonTextAlign.setRelativeName("description");
		commonTextLabel.setLayoutParameter(commonTextAlign);

		this._container.addChild(commonTextLabel);

		pm.spriteUtils.addTempSpriteFrames(pm.moduleUtils.getMapTileset(pm.PushLevelModule.Type, 0));

		var tableInfo = new ccui.HBox();

		var tableAlign = new ccui.RelativeLayoutParameter();
		tableAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		tableAlign.setRelativeName("tableInfo");

		// var spriteHeight = (cc.sys.isNative)?pushSprite.height:pushSprite.height*2;
		var margin = Math.max(2 * pushSprite.height + pushLabel.height + CommonLessonPage.SEPARATOR, commonTextLabel.height + 3 * CommonLessonPage.SEPARATOR);

		tableAlign.setMargin(0, margin, 0, 0);
		tableInfo.setLayoutParameter(tableAlign);

		var height = 0;

		var finishBox = new ccui.VBox();

		var finishLabel = new ccui.Text("Нужные места", pm.settings.fontName, 30);

		var finishLabelAlign = new ccui.LinearLayoutParameter();
		finishLabelAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
		finishLabelAlign.setMargin(0, CommonLessonPage.SEPARATOR, 0, 0);
		finishLabel.setLayoutParameter(finishLabelAlign);

		finishBox.addChild(finishLabel);

		for (var i = 1; i <= 4; ++i)
		{
			var finishContainer = new ccui.HBox();

			var containerMargin = new ccui.LinearLayoutParameter();
			containerMargin.setMargin(CommonLessonPage.TABLE_MARGIN, CommonLessonPage.SEPARATOR, 0, 0);
			finishContainer.setLayoutParameter(containerMargin);

			finishBox.addChild(finishContainer);

			if (i === 4)
				var image = new ccui.ImageView("targetElement.png", ccui.Widget.PLIST_TEXTURE);
			else
				var image = new ccui.ImageView("mapElement{0}.png".format(i), ccui.Widget.PLIST_TEXTURE);

			var imageAlign = new ccui.LinearLayoutParameter();
			imageAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
			image.setLayoutParameter(imageAlign);

			image.setAnchorPoint(cc.p(0, 1));
			image.setScale(CommonLessonPage.CELL_HEIGHT / image.height);

			finishContainer.addChild(image);

			var label = new ccui.Text(this._getObjectDescription(i), pm.settings.fontName, 25);

			var labelMargin = new ccui.LinearLayoutParameter();
			labelMargin.setGravity(ccui.LinearLayoutParameter.LEFT);
			var margin = (cc.sys.isNative) ? 2 * CommonLessonPage.SEPARATOR : 2 * CommonLessonPage.SEPARATOR + 16;
			labelMargin.setMargin(margin, CommonLessonPage.CELL_HEIGHT / 2 - label.height/2, 0, 0);
			label.setLayoutParameter(labelMargin);

			finishContainer.addChild(label);

			finishContainer.setContentSize(image.width + CommonLessonPage.SEPARATOR + label.width, CommonLessonPage.CELL_HEIGHT);

			height += CommonLessonPage.SEPARATOR + finishContainer.height;
		}

		finishBox.setContentSize(this._container.width / 2, height);

		height = 0;

		var commandBox = new ccui.VBox();

		var commandLabel = new ccui.Text("Команды", pm.settings.fontName, 30);

		var commandLabelAlign = new ccui.LinearLayoutParameter();
		commandLabelAlign.setGravity(ccui.LinearLayoutParameter.CENTER_HORIZONTAL);
		commandLabelAlign.setMargin(0, CommonLessonPage.SEPARATOR, 0, 0);
		commandLabel.setLayoutParameter(commandLabelAlign);

		commandBox.addChild(commandLabel);

		for (var i = 0; i < 4; ++i)
		{
			var commandContainer = new ccui.HBox();

			var containerMargin = new ccui.LinearLayoutParameter();
			containerMargin.setMargin(CommonLessonPage.TABLE_MARGIN, CommonLessonPage.SEPARATOR, 0, 0);
			commandContainer.setLayoutParameter(containerMargin);

			commandBox.addChild(commandContainer);

			var name;

			pm.spriteUtils.initRobotTextures(pm.PushLevelModule.Type);

			switch(i)
			{
				case 0:
					name = "r4_move-normal.png";
					break;
				case 1:
					name = "r4_turn_left-normal.png";
					break;
				case 2:
					name = "r4_turn_right-normal.png";
					break;
				case 3:
					name = "push_pull-normal.png";
					break;
			}

			var image = new ccui.ImageView(name, ccui.Widget.PLIST_TEXTURE);

			var imageAlign = new ccui.LinearLayoutParameter();
			imageAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
			image.setLayoutParameter(imageAlign);

			image.setAnchorPoint(cc.p(0, 1));
			image.setScale(CommonLessonPage.CELL_HEIGHT / image.height);
			commandContainer.addChild(image);

			var label = new ccui.Text(this._getCommandDescription(i), pm.settings.fontName, 25);

			var labelMargin = new ccui.LinearLayoutParameter();
			labelMargin.setGravity(ccui.LinearLayoutParameter.LEFT);
			var margin = (cc.sys.isNative) ? 2 * CommonLessonPage.SEPARATOR : 2 * CommonLessonPage.SEPARATOR + 40;
			labelMargin.setMargin(margin, CommonLessonPage.CELL_HEIGHT / 2 - label.height/2, 0, 0);
			label.setLayoutParameter(labelMargin);

			commandContainer.addChild(label);

			commandContainer.setContentSize(image.width + CommonLessonPage.SEPARATOR + label.width, CommonLessonPage.CELL_HEIGHT);

			height += CommonLessonPage.SEPARATOR + commandContainer.height;
		}

		commandBox.setContentSize(this._container.width / 2, height);

		tableInfo.setContentSize(this._container.width, Math.max(finishBox.height, commandBox.height));

		tableInfo.addChild(finishBox);
		tableInfo.addChild(commandBox);
		this._container.addChild(tableInfo);


		var conditionLabel = new ccui.Text("Команды-вопросы", pm.settings.fontName, 30);

		var conditionLabelAlign = new ccui.RelativeLayoutParameter();
		conditionLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		conditionLabelAlign.setRelativeToWidgetName("tableInfo");
		conditionLabelAlign.setRelativeName("conditionLabel");

		var margin = 2 * CommonLessonPage.SEPARATOR + 40;

		conditionLabelAlign.setMargin(0, margin, 0, 0);
		conditionLabel.setLayoutParameter(conditionLabelAlign);

		this._container.addChild(conditionLabel);

		var conditionInfo = new ccui.HBox();

		var conditionAlign = new ccui.RelativeLayoutParameter();
		conditionAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		conditionAlign.setRelativeToWidgetName("conditionLabel");

		var margin = CommonLessonPage.SEPARATOR;

		conditionAlign.setMargin(0, margin, 0, 0);
		conditionInfo.setLayoutParameter(conditionAlign);

		height = 0;

		var conditionBox1 = new ccui.VBox();

		for (var i = 0; i < 2; ++i)
		{
			var conditionContainer = new ccui.HBox();

			var conditionContainerMargin = new ccui.LinearLayoutParameter();
			conditionContainerMargin.setMargin(CommonLessonPage.TABLE_MARGIN, CommonLessonPage.SEPARATOR, 0, 0);
			conditionContainer.setLayoutParameter(conditionContainerMargin);

			conditionBox1.addChild(conditionContainer);

			var name;

			pm.spriteUtils.initRobotTextures(pm.RepairLevelModule.Type);

			switch(i)
			{
				case 0:
					name = "cond-r4_clear-normal.png";
					break;
				case 1:
					name = "cond-push_mf-normal.png";
					break;
			}

			var image = new ccui.ImageView(name, ccui.Widget.PLIST_TEXTURE);

			var imageAlign = new ccui.LinearLayoutParameter();
			imageAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
			image.setLayoutParameter(imageAlign);

			image.setAnchorPoint(cc.p(0, 1));
			image.setScale(CommonLessonPage.CELL_HEIGHT / image.height);
			conditionContainer.addChild(image);

			var label = new ccui.Text(this._getConditionDescription(i), pm.settings.fontName, 25);
			label.setTextAreaSize(cc.size(this._container.width / 2 - image.width - 2 * CommonLessonPage.TABLE_MARGIN, 0));

			var labelMargin = new ccui.LinearLayoutParameter();
			labelMargin.setGravity(ccui.LinearLayoutParameter.LEFT);
			var margin = (cc.sys.isNative) ? 2 * CommonLessonPage.SEPARATOR : 2 * CommonLessonPage.SEPARATOR + 40;
			labelMargin.setMargin(margin, CommonLessonPage.CELL_HEIGHT / 2 - label.height/2, 0, 0);
			label.setLayoutParameter(labelMargin);

			conditionContainer.addChild(label);

			conditionContainer.setContentSize(image.width + CommonLessonPage.SEPARATOR + label.width, CommonLessonPage.CELL_HEIGHT);

			height += CommonLessonPage.SEPARATOR + conditionContainer.height;
		}

		conditionBox1.setContentSize(this._container.width / 2, height);

		height = 0;

		var conditionBox2 = new ccui.VBox();

		for (var i = 0; i < 2; ++i)
		{
			var conditionContainer = new ccui.HBox();

			var conditionContainerMargin = new ccui.LinearLayoutParameter();
			conditionContainerMargin.setMargin(CommonLessonPage.TABLE_MARGIN, CommonLessonPage.SEPARATOR, 0, 0);
			conditionContainer.setLayoutParameter(conditionContainerMargin);

			conditionBox2.addChild(conditionContainer);

			var name;

			pm.spriteUtils.initRobotTextures(pm.RepairLevelModule.Type);

			switch(i)
			{
				case 0:
					name = "cond-r4_wall-normal.png";
					break;
				case 1:
					name = "cond-push_nmf-normal.png";
					break;
			}

			var image = new ccui.ImageView(name, ccui.Widget.PLIST_TEXTURE);

			var imageAlign = new ccui.LinearLayoutParameter();
			imageAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
			image.setLayoutParameter(imageAlign);

			image.setAnchorPoint(cc.p(0, 1));
			image.setScale(CommonLessonPage.CELL_HEIGHT / image.height);
			conditionContainer.addChild(image);

			var label = new ccui.Text(this._getConditionDescription(2 + i), pm.settings.fontName, 25);
			label.setTextAreaSize(cc.size(this._container.width / 2 - image.width - 2 * CommonLessonPage.TABLE_MARGIN, 0));

			var labelMargin = new ccui.LinearLayoutParameter();
			labelMargin.setGravity(ccui.LinearLayoutParameter.LEFT);
			var margin = (cc.sys.isNative) ? 2 * CommonLessonPage.SEPARATOR : 2 * CommonLessonPage.SEPARATOR + 40;
			labelMargin.setMargin(margin, CommonLessonPage.CELL_HEIGHT / 2 - label.height/2, 0, 0);
			label.setLayoutParameter(labelMargin);

			conditionContainer.addChild(label);

			conditionContainer.setContentSize(image.width + CommonLessonPage.SEPARATOR + label.width, CommonLessonPage.CELL_HEIGHT);

			height += CommonLessonPage.SEPARATOR + conditionContainer.height;
		}

		conditionBox2.setContentSize(this._container.width / 2, height);

		conditionInfo.setContentSize(this._container.width, Math.max(conditionBox1.height, conditionBox2.height));

		conditionInfo.addChild(conditionBox1);
		conditionInfo.addChild(conditionBox2);
		this._container.addChild(conditionInfo);

		this._container.setContentSize(this._container.width, Math.max(2 * pushSprite.height + pushLabel.height + CommonLessonPage.SEPARATOR,
			commonTextLabel.height + 4 * CommonLessonPage.SEPARATOR) + tableInfo.height + conditionInfo.height +
			conditionLabel.height + 8 * CommonLessonPage.SEPARATOR);
	},

	_getObjectDescription: function(number)
	{
		switch(number)
		{
			case 1:
				return "для ящика";
			case 2:
				return "для бочки";
			case 3:
				return "для бочки или ящика";
			case 4:
				return "финиш";
		}
	},

	_getCommandDescription: function(number)
	{
		switch(number)
		{
			case 0:
				return "вперед";
			case 1:
				return "налево";
			case 2:
				return "направо";
			case 3:
				return "тащить";
		}
	},

	_getConditionDescription: function(number)
	{
		switch(number)
		{
			case 0:
				return "впереди свободно";
			case 1:
				return "можно двигаться вперед";
			case 2:
				return "впереди стена";
			case 3:
				return "нельзя двигаться вперед";
		}
	}
});
