/**
 * Created by Kirill Mashchenko on 17.05.23.
 */

var PressRobotMapEditorLayer = PressRobotMapLayer.extend(SquarableMapEditorLayerHelper).extend({

	drawMap: function(previewDraw)
	{
		SquarableMapEditorLayerHelper.drawMap.call(this, previewDraw);

		// var useProgramRecognizer = cc.sys.isMobile && pm.settings.getUseProgramRecognizer();

		var useProgramRecognizer = true;

		if(!previewDraw && useProgramRecognizer)
		{
			var recognizeProgramButton = new pmui.Button(pm.spriteUtils.getIconName("photo", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("photo", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("photo", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			recognizeProgramButton.setPosition(0, this.height);

			recognizeProgramButton.addClickEventListener(this._onRecognizeMatsClick.bind(this));
			this.addChild(recognizeProgramButton);

			pm.matsRecognizer.setSuccessCallback(this._onRecognizeMats.bind(this));
			pm.matsRecognizer.setErrorCallback(this._onRecognizeError.bind(this));

		}
	},

	_addClone: function(pos, robot)
	{
		robot.childRobot = null;
		robot.childRobotID = -1;
		robot.parentLevel = this._map.parentLevel;

		this._map.parentLevel.robots.push(robot);
		robot.id = this._map.parentLevel.robots.indexOf(robot);

		robot.groupID = this._activeRobot.groupID;

		robot.useClearCondition = this._activeRobot.useClearCondition;
		robot.useCanPressCondition = this._activeRobot.useCanPressCondition;
		robot.useBoxCondition = this._activeRobot.useBoxCondition;
		robot.usePressMethod = this._activeRobot.usePressMethod;
		robot.useLoadingBoxes = this._activeRobot.useLoadingBoxes;

		robot.updateConditions();
		robot.updateMethods()

		robot.startPosition = pos;
		robot.setPosition(robot.startPosition, this._activeRobot.direction);

		this._map.element(pos).startForRobot = robot.id;
		this._map.element(pos).startRobotData.direction = robot.direction;

		robot.generateRobotSprite();

		robot.sprite.mapLayer = this._map.mapLayer;
		robot.sprite.setDirection(robot.direction);
		robot.sprite.setOrientation(this._orientation);

		this.addObject(robot.sprite);
		robot.sprite.setRealPosition(robot.position);

		this.drawRobotStartPositionMark(robot.id, robot.position);

		robot.sprite.setSpriteColor(this.getRobotIndex(robot.groupID));
	},

	_dragObjectMoved: function (touch, pEvent)
	{
		var level = this._map.parentLevel;
		var object = level.activeMap.objects[this._dragedObject];

		var point = this.objectLayer.convertTouchToNodeSpace(touch);
		var normalPoint = this.positionFromReal(point);

		if (pm.moduleUtils.canNotDragOrAddObject(this._map.getType(), this._map, normalPoint))
			return;

		var startPosition = this.positionFromReal(this._startDragPoint);

		if (this._map.element(normalPoint).getObjectCount() !== 0 &&
			(startPosition.x !== normalPoint.x || startPosition.y !== normalPoint.y))
			return;

		object.setPosition(normalPoint);
		object.startPosition = normalPoint;
		object.position = normalPoint;
		object.sprite.setRealPosition(object.startPosition);

		if (this._positionOutOfMap(point))
			object.sprite.getSprite().setOpacity(100);
		else
			object.sprite.getSprite().setOpacity(255);
	},

	_dragRobotMoved: function (touch, pEvent)
	{
		var level = this._map.parentLevel;
		var robot = level.robots[this.dragedRobot];

		if (level.robotsEndedWork())
			return;

		var p = this.objectLayer.convertTouchToNodeSpace(touch);
		var p_normal = this.positionFromReal(p);

		var startPosition = this.positionFromReal(this._startDragPoint);

		if (this._map.element(p_normal).getRobotsCount() !== 0 &&
			(startPosition.x !== p_normal.x || startPosition.y !== p_normal.y))
			return;

		if (!(robot.direction >= 0 && robot.direction < robot.getDirectionCount()))
		{
			cc.log("Incorrect robot4 direction");
			robot.direction = 0;
		}

		robot.setPosition(p_normal, robot.direction);
		robot.sprite.setRealPosition(robot.position);

		this._setRobotStartPositionMarkPosition(robot.id, robot.position);

		var opacity = 255;

		if (this._positionOutOfMap(p))
			opacity = 50;

		robot.sprite.getSprite().setOpacity(opacity);
	},

	_onRecognizeMatsClick: function()
	{
		pm.matsRecognizer.recognize();
	},

	_onRecognizeMats: function(result)
	{
		try {
			var data = JSON.parse(result);

			this._map.width = data.width || 1;
			this._map.height = data.height || 1;

			this._map.mapElements = [];

			for(var i = 0; i < data.height; ++i)
			{
				this._map.mapElements.push([]);

				for(var j = 0; j < data.width; ++j)
				{
					var el = new pm.data.PressRobotMapElement();
					el.originalType = this._mapRecognizeElementType(data.field[i][j]);

					if (el.originalType === "R")
					{
						el.originalType = PressRobotMapElementType.Green;

						var task = new pm.data.PositionTask4(this._map.parentLevel);
						task.fillWithInitialData(0, cc.p(i, j));

						this._map.parentLevel.taskList.addTask(pm.GlobalTaskType.Position4, task);
					}
					el.initElement();
					this._map.mapElements[i].push(el);
				}
			}

			this.removeAllChildren();
			this.drawMap(false);
		}
		catch(e)
		{
			this._onRecognizeError(e.message);
		}
	},

	_mapRecognizeElementType: function(recType)
	{
		if(!isNaN(parseInt(recType)))
			return Number(recType);

		switch(recType)
		{
			case "green": return PressRobotMapElementType.Green;
			case "yellow": return PressRobotMapElementType.Yellow;
			case "red": return "R";
			default: return PressRobotMapElementType.Green;
		}
	},

	_onRecognizeError: function(error)
	{
		if(error !== "")
		{
			var messageBox = new pmui.MessageBox(this, error);

			var x = pm.settings.getScreenSize().width / 2 - messageBox.getContentSize().width / 2;
			var y = pm.settings.getScreenSize().height / 2 - messageBox.getContentSize().height / 2;
			messageBox.setPosition(x, y);
			cc.director.getRunningScene().addChild(messageBox, 1000);
		}
	},

	createMapElement: function ()
	{
		var el = new pm.data.PressRobotMapElement();
		el.originalType = PressRobotMapElementType.Green;
		el.initElement();
		return el;
	},

	moveElementsUp: function ()
	{
		for (var j = this._map.height - 2; j >= 0; --j)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i, j + 1)).setType(elementType);
				this._map.element(cc.p(i, j + 1)).originalType = elementType;

				var type = PressRobotMapElementType.Green;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	},

	moveElementsDown: function ()
	{
		for (var j = 1; j < this._map.height; ++j)
		{
			for (var i = 0; i < this._map.width; ++i)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i, j - 1)).setType(elementType);
				this._map.element(cc.p(i, j - 1)).originalType = elementType;

				var type = PressRobotMapElementType.Green;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;

			}
		}
	},

	moveElementsLeft: function ()
	{
		for (var i = this._map.width - 2; i >= 0; --i)
		{
			for (var j = 0; j < this._map.height; ++j)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i + 1, j)).setType(elementType);
				this._map.element(cc.p(i + 1, j)).originalType = elementType;

				var type = PressRobotMapElementType.Green;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	},

	moveElementsRight: function ()
	{
		for (var i = 1; i < this._map.width; ++i)
		{
			for (var j = 0; j < this._map.height; ++j)
			{
				var elementType = this._map.element(cc.p(i, j)).getType();

				this._map.element(cc.p(i - 1, j)).setType(elementType);
				this._map.element(cc.p(i - 1, j)).originalType = elementType;

				var type = PressRobotMapElementType.Green;
				this._map.element(cc.p(i, j)).setType(type);
				this._map.element(cc.p(i, j)).originalType = type;
			}
		}
	},

	_addObject: function (pos, object)
	{
		object.visualId = this._map.objects.length+1;

		SquarableMapEditorLayerHelper._addObject.call(this, pos, object);
	},

	_removeObject: function (object)
	{
		SquarableMapEditorLayerHelper._removeObject.call(this, object);

		for (var i = this._dragedObject; i < this._map.parentLevel.activeMap.objects.length; ++i)
			this._map.objects[i].visualId--;

		for (i = 0; i < this._map.parentLevel.activeMap.objects.length; ++i)
			this._map.objects[i].updateNumberSprite()
	}

});
