/**
 * Created by Kirill Mashchenko on 30.10.18.
 */

/**
 * @class Common Layout for Lessons pages .
 * @extends pmui.OvalLayout
 */
var CommonLessonPage = pmui.OvalLayout.extend(/** @lends CommonLessonPage# */{
	_target: null,
	_cancelCallback: null,

	_scroll: null,
	_container: null,

	ctor: function(target, cancelCallback)
	{
		this._super();
		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setContentSizeWithBorder(pm.settings.getScreenSize().width * CommonLessonPage.SCALE_X, pm.settings.getScreenSize().height * CommonLessonPage.SCALE_Y);

		this.setAnchorPoint(cc.p(0.5, 0.5));
		this.setPosition(pm.settings.getScreenSize().width / 2, pm.settings.getScreenSize().height / 2 - CommonLessonPage.MARGIN);

		this.setTouchEnabled(true);

		this._target = target;
		this._cancelCallback = cancelCallback;

		if(!CORE_BUILD)
			pm.spriteUtils.clear();

		this._loadInterface();
	},

	_loadInterface: function()
	{
		var closeButton = new pmui.Button(pm.spriteUtils.getIconName("close", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("close", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("close", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		closeButton.addClickEventListener(this._cancelCallback.bind(this._target));

		var closeButtonAlign = new ccui.RelativeLayoutParameter();
		closeButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		closeButtonAlign.setMargin(0, -closeButton.height/4, -closeButton.width/4, 0);
		closeButton.setLayoutParameter(closeButtonAlign);

		this.addChild(closeButton, 1);

		this._scroll = new ccui.ListView();

		this._scroll.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._scroll.setScrollBarEnabled(true);
		this._scroll.setScrollBarWidth(CommonLessonPage.SCROLL_BAR_WIDTH);
		this._scroll.setScrollBarOpacity(255 * 0.9);

		this._scroll.setContentSize(this.width - 2 * pmui.OvalLayout.STANDARD_BORDER_RADIUS,
			this.height - 2 * pmui.OvalLayout.STANDARD_BORDER_RADIUS);

		var scrollAlign = new ccui.RelativeLayoutParameter();
		scrollAlign.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
		this._scroll.setLayoutParameter(scrollAlign);

		this.addChild(this._scroll);

		this._container = new ccui.Layout();
		this._container.setLayoutType(ccui.Layout.RELATIVE);

		this._container.setContentSize(this._scroll.getContentSize());

		this._scroll.pushBackCustomItem(this._container);
	}
});

CommonLessonPage.TAG = 1741;
CommonLessonPage.SCROLL_BAR_WIDTH = 5;
CommonLessonPage.SEPARATOR = 10;
CommonLessonPage.CELL_HEIGHT = 70;
CommonLessonPage.TABLE_MARGIN = 50;
CommonLessonPage.SCALE_X = 0.87;
CommonLessonPage.SCALE_Y = 0.85;
CommonLessonPage.MARGIN = 30;
