/**
 * Created by Ekaterina Ermishkina on 29.08.16.
 */

/**
 * @class Common interface for objects Lighted by {@link pm.data.:LightRobot}
 * @interface
 * @extends pm.Object
 */

pm.data.LightObject = pm.Object.extend(/** @lends LightObject#*/{
	typeName: "LightObject",

	type: "",

	getType: function() { return pm.LightLevelModule.Object; },

	ctor: function()
	{
		this._addNonEnumerableProps("ObjectType");
		this._super();
	},

	/**
     * Sets position of object by element position.
     * @param {cc.Point} point Point in element coordinates.
     */
	setPosition: function(point)
	{
		if(this.position.x < this.parentMap.width && this.position.y < this.parentMap.height)
		{
			if(this.parentMap.element(this.position).containsObject(this))
				this.parentMap.element(this.position).removeObject(this);
		}

		this.position = point;

		this.parentMap.element(point).addObject(this);
	},

	generateSprite: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			this.sprite = new LightObjectSprite(this.type);

			return this.sprite;
		}
	},

	updateSprite: function()
	{
		this.sprite.setRealPosition(this.position);
	}
});
