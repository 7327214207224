/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class 2D map where robot can paint some kinds({@link KumirMapElementType}) of elements.
 * @implements SquarableMapWithWalls
 */
pm.data.KumirMap = SquarableMapWithWalls.extend(/** @lends pm.data.KumirMap# */{
	typeName: "KumirMap",

	_paintTargetElements: [],

	ctor: function()
	{
		this._addNonEnumerableProps("_paintTargetElements");
		this._super();
	},

	getType: function() { return pm.KumirLevelModule.Type; },

	generateMapLayer: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (!pm.settings.isEditorMode)
				this.mapLayer = new KumirMapLayer(this);
			else
				this.mapLayer = new KumirMapEditorLayer(this);

			this.mapLayer.drawMap(previewDraw);

			return this.mapLayer;
		}
	},

	setSize: function(width, height)
	{
		this.mapElements.length = 0;

		for(var i = 0; i < height; ++i)
		{
			this.mapElements.push([]);

			for(var j = 0; j < width; ++j)
				this.mapElements[i].push(new pm.data.KumirMapElement());
		}

		this.width = width;
		this.height = height;
	},

	/**
     * Paints element by element coordinate.
     * @param {Number} position in elements.
     */
	paintElement: function(pos)
	{
		var element = this.element(pos);
		element.paint();
	},

	/**
     * Returns element to broken state by element coordinate.
     * @param {Number} x X-coordinate in elements.
     * @param {Number} y Y-coordinate in elements.
     */
	unpaintElement: function(pos)
	{
		var element = this.element(pos);
		element.unpaint();

	}
});
